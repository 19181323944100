import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'common/messages/messages';
import { DialogComponent } from 'common/components';
import { Typography } from '@material-ui/core';

interface IAddPaymentMethodModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const CancelInvoiceModal = (props: IAddPaymentMethodModalProps): JSX.Element => {
  const { onClose, onSubmit, isOpen, isLoading } = props;

  return (
    <DialogComponent
      size="xs"
      title={<FormattedMessage {...messages.cancelInvoice} />}
      submitBtnTitle={<FormattedMessage {...messages.cancelInvoice} />}
      cancelBtnTitle={<FormattedMessage {...messages.notNowBtn} />}
      isOpen={isOpen}
      onSubmit={onSubmit}
      onClose={onClose}
      disableFullScreenBehavior
      loading={isLoading}
    >
      <Typography variant="body1">
        <FormattedMessage {...messages.cancelInvoiceBody} />
      </Typography>
    </DialogComponent>
  );
};

export default CancelInvoiceModal;
