import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';

import { actionTypes } from './constants';
import { ActionPrimaryInfoTypes } from './actions';
import {
  IInitPersonsFoundStateImt,
  SearchPersonsActionsType,
} from 'common/state/newPerson/primaryInfo/interfaces';

const initialState = fromJS({
  result: {},
  persons: [],
  error: null,
  loading: false,
  actionCreateResult: null,
  actionUpdateResult: null,
});

function reducer(state = initialState, { type, payload }: ActionPrimaryInfoTypes) {
  switch (type) {
    case actionTypes.SET_PRIMARY_INFO:
      return state.set('result', fromJS(payload));
    case actionTypes.SET_CREATE_PERSON_ACTION_RESULT:
      return state.set('actionCreateResult', payload);
    case actionTypes.RESET_CREATE_PERSON_RESULT:
      return state.set('actionCreateResult', null);
    case actionTypes.SET_UPDATE_PERSON_ACTION_RESULT:
      return state.set('actionUpdateResult', payload);
    case actionTypes.RESET_UPDATE_PERSON_RESULT:
      return state.set('actionUpdateResult', null);
    case actionTypes.GET_PERSONS:
      return state.set('persons', fromJS(payload));
    case actionTypes.SET_ERROR_VALUE:
      return state.set('error', payload);
    case actionTypes.SET_LOADING_VALUE:
      return state.set('loading', payload);
    case actionTypes.CLEAR_NEW_PERSON_DATA:
      return initialState;
    default:
      return state;
  }
}

export const initPersonsFoundState = fromJS({
  result: [],
  isLoading: false,
  error: null,
});

const personsFoundReducer = <T extends Array<T>>(
  state: IInitPersonsFoundStateImt<T> = initPersonsFoundState,
  action: SearchPersonsActionsType<T>,
): IInitPersonsFoundStateImt<T> => {
  switch (action.type) {
    case actionTypes.FETCH_PERSONS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_PERSONS_SUCCESS:
      return state.set('result', fromJS(action.payload)).set('error', null);
    case actionTypes.FETCH_PERSONS_ERROR:
      return state.set('error', fromJS(action.payload));
    case actionTypes.RESET_PERSONS_FOUND:
      return initPersonsFoundState;
    default:
      return state;
  }
};

export default combineReducers({
  primaryInfo: reducer,
  personsFound: personsFoundReducer,
});
