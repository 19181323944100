import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';

import { actionTypes } from 'common/state/newPerson/membership/constants';

import { ActionPrimaryInfoTypes } from 'common/state/newPerson/primaryInfo/actions';
import { initialState, initReqStateImt } from 'common/constants/initialState';

const initialStateMembershipsList = fromJS({
  list: [],
  isLoading: false,
});

const membershipsListReducer = (
  state = initialStateMembershipsList,
  { type, payload }: ActionPrimaryInfoTypes,
) => {
  switch (type) {
    case actionTypes.FETCH_MEMBERSHIP_PACKAGES_LIST:
      return state.set('list', fromJS(payload));
    case actionTypes.FETCH_MEMBERSHIP_PACKAGES_LIST_IS_LOADING:
      return state.set('isLoading', payload);
    default:
      return state;
  }
};

const initialStateMembershipItem = fromJS({
  membership: {},
  isLoading: false,
  membershipActionsStatus: initialState,
});

const membershipItemReducer = (state = initialStateMembershipItem, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MEMBERSHIP_PACKAGE:
      return state.set('membership', fromJS(action.payload));
    case actionTypes.FETCH_MEMBERSHIP_PACKAGE_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_UPDATE_MEMBERSHIP_INFO_LOADING:
      return state.setIn(['membershipActionsStatus', 'isLoading'], action.payload);
    case actionTypes.FETCH_UPDATE_MEMBERSHIP_INFO_RESULT:
      return state.setIn(['membershipActionsStatus', 'result'], fromJS(action.payload));
    case actionTypes.RESET_MEMBERSHIP_PACKAGE_ITEM:
      return initialStateMembershipItem;
    default:
      return state;
  }
};

const personMembership = fromJS({
  membership: {},
  isLoading: false,
});

const personMembershipReducer = (state = personMembership, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PERSON_MEMBERSHIP:
      return state.set('membership', fromJS(action.payload));
    case actionTypes.FETCH_PERSON_MEMBERSHIP_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_PERSON_MEMBERSHIP:
      return initialStateMembershipItem;
    default:
      return state;
  }
};

const membershipInventoryReducer = (state = initReqStateImt, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MEMBERSHIP_PACKAGE_INVENTORY:
      return state.set('result', fromJS(action.payload));
    case actionTypes.FETCH_MEMBERSHIP_PACKAGE_INVENTORY_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_MEMBERSHIP_PACKAGE_INVENTORY:
      return initReqStateImt;
    default:
      return state;
  }
};

export default combineReducers({
  membershipsList: membershipsListReducer,
  membershipItem: membershipItemReducer,
  membershipInventory: membershipInventoryReducer,
  personMembership: personMembershipReducer,
});
