import { List } from 'immutable';
import {
  IEmployeeDtoImt,
  IEmployeeJobPositionImt,
  IEmployeeProfileDtoImt,
  ISecurityRoleToClubListDtoImt,
} from 'common/interfaces/employee';
import { IAttachmentImt } from 'common/interfaces/uploadFile';
import { IPageMetaImt } from 'common/interfaces/pagination';
import { ITimeclockHistoryItemImt } from 'modules/timeclock/interfaces/timeclock';
import { AppStoreTypeImt } from 'store';

const myProfileDomain = ['myProfile', 'profile'];

export const selectMyProfileView = (state: AppStoreTypeImt): IEmployeeProfileDtoImt | null => {
  return state.getIn([...myProfileDomain, 'profileView', 'result']);
};
export const selectMyProfileViewIsLoading = (state: AppStoreTypeImt): boolean => {
  return state.getIn([...myProfileDomain, 'profileView', 'isLoading']);
};
export const selectMyProfile = (state: AppStoreTypeImt): IEmployeeDtoImt | null => {
  return state.getIn([...myProfileDomain, 'profile', 'result']);
};
export const selectMyProfileIsLoading = (state: AppStoreTypeImt): boolean => {
  return state.getIn([...myProfileDomain, 'profile', 'isLoading']);
};
export const selectMyProfilePermissions = (
  state: AppStoreTypeImt,
): List<ISecurityRoleToClubListDtoImt> | null => {
  return state.getIn([...myProfileDomain, 'profilePermissions', 'result']);
};
export const selectMyProfileAttachments = (state: AppStoreTypeImt): List<IAttachmentImt> | null => {
  return state.getIn([...myProfileDomain, 'profileAttachments', 'result']);
};
export const selectMyProfileJobPositions = (
  state: AppStoreTypeImt,
): List<IEmployeeJobPositionImt> | null => {
  return state.getIn([...myProfileDomain, 'profileJobPositions', 'result']);
};
export const selectMyProfileJobPositionsHistory = (
  state: AppStoreTypeImt,
): List<IEmployeeJobPositionImt> | null => {
  return state.getIn([...myProfileDomain, 'profileJobPositionsHistory', 'result']);
};
export const selectMyProfileTimeclockHistory = (
  state: AppStoreTypeImt,
): List<ITimeclockHistoryItemImt> | null => {
  return state.getIn([...myProfileDomain, 'profileTimeclockHistory', 'result', 'data']);
};
export const selectMyProfileTimeclockHistoryMeta = (state: AppStoreTypeImt): IPageMetaImt => {
  return state.getIn([...myProfileDomain, 'profileTimeclockHistory', 'result', 'meta']);
};
export const selectMyProfileRecentTimeclockHistory = (
  state: AppStoreTypeImt,
): List<ITimeclockHistoryItemImt> | null => {
  return state.getIn([...myProfileDomain, 'profileRecentTimeclockHistory', 'result', 'data']);
};
export const selectMyProfilePermissionsIsLoading = (state: AppStoreTypeImt): boolean => {
  return state.getIn([...myProfileDomain, 'profilePermissions', 'isLoading']);
};
export const selectMyProfileAttachmentsIsLoading = (state: AppStoreTypeImt): boolean => {
  return state.getIn([...myProfileDomain, 'profileAttachments', 'isLoading']);
};
export const selectMyProfileJobPositionsIsLoading = (state: AppStoreTypeImt): boolean => {
  return state.getIn([...myProfileDomain, 'profileJobPositions', 'isLoading']);
};
export const selectMyProfileJobPositionsHistoryIsLoading = (state: AppStoreTypeImt): boolean => {
  return state.getIn([...myProfileDomain, 'profileJobPositionsHistory', 'isLoading']);
};
export const selectMyProfileTimeclockHistoryIsLoading = (state: AppStoreTypeImt): boolean => {
  return state.getIn([...myProfileDomain, 'profileTimeclockHistory', 'isLoading']);
};
export const selectMyProfileRecentTimeclockHistoryIsLoading = (state: AppStoreTypeImt): boolean => {
  return state.getIn([...myProfileDomain, 'profileRecentTimeclockHistory', 'isLoading']);
};
