/* eslint no-param-reassign: 0 */
import grapesjs from 'grapesjs';
import { DocumentClubTags, DocumentCustomerTags } from 'common/constants/documentTemplate';
import { IObject } from 'common/interfaces/common';

const { CKEDITOR } = window as any;

// PRM-3577 block ckeditor version check
(function(open) {
  XMLHttpRequest.prototype.open = function(...args) {
    const url = args[1];

    if (url.includes('cke4.ckeditor.com/ckeditor4-secure-version')) {
      return;
    }

    open.apply(this, args);
  };
})(XMLHttpRequest.prototype.open);

(grapesjs as IObject).plugins.add('gjs-plugin-ckeditor', (editor, opts: any = {}) => {
  const c = opts;
  let resizeObserver: ResizeObserver | null = null;

  const defaults = {
    options: {},
    position: 'left',
  };

  Object.keys(defaults).forEach(key => {
    if (!(key in c)) c[key] = defaults[key];
  });

  const wrapTemplateTag = (tag: string): string => {
    return `<span class="email-template-tag">{{${tag}}}</span>`;
  };

  editor.setCustomRte({
    enable(el, rte) {
      if (rte && rte.status !== 'destroyed') {
        this.focus(el, rte);
        return rte;
      }

      el.contentEditable = true;

      const rteToolbar = editor.RichTextEditor.getToolbarEl();
      [].forEach.call(rteToolbar.children, child => {
        child.style.display = 'none';
      });

      const opt = c.options;
      const plgName = 'sharedspace';

      if (opt.extraPlugins) {
        if (typeof opt.extraPlugins === 'string') opt.extraPlugins += `,${plgName}`;
        else opt.extraPlugins.push(plgName);
      } else {
        opt.extraPlugins = plgName;
      }

      c.options.sharedSpaces = { top: rteToolbar };

      rte = CKEDITOR.inline(el, c.options);

      rte.getContent = rte.getData;

      rte.on('contentDom', () => {
        const editable = rte.editable();
        editable.attachListener(editable, 'click', () => {
          el.click();
        });
      });

      rte.on('instanceReady', () => {
        const toolbar = rteToolbar.querySelector(`#cke_${rte.name}`);
        if (toolbar) {
          toolbar.style.display = 'block';
        }
        editor.trigger('canvasScroll');
      });

      const dialogComponent = document.getElementsByClassName('MuiDialog-container');

      rte.on('dialogHide', () => {
        if (dialogComponent.length > 0) {
          dialogComponent[0].setAttribute('tabindex', '-1');
        }
      });

      rte.on('dialogShow', () => {
        const editorEls = (grapesjs as any).$('.cke_dialog_background_cover, .cke_dialog');
        ['off', 'on'].forEach(m => {
          editorEls[m]('touchstart', e => e.stopPropagation());
          editorEls[m]('touchend', e => e.stopPropagation());
          editorEls[m]('mousedown', e => e.stopPropagation());
          if (dialogComponent.length > 0) {
            dialogComponent[0].removeAttribute('tabindex');
          }
        });
      });

      CKEDITOR.on('dialogDefinition', ev => {
        ev.data.definition.resizable = CKEDITOR.DIALOG_RESIZE_NONE;
      });

      this.focus(el, rte);

      return rte;
    },

    disable(el, rte) {
      el.contentEditable = false;
      if (rte && rte.focusManager) rte.focusManager.blur(true);
    },

    focus(el, rte) {
      if (rte && rte.focusManager.hasFocus) {
        return;
      }
      el.contentEditable = true;
      if (rte) rte.focus();
    },
  });

  editor.on('rte:enable', element => {
    if (resizeObserver) {
      resizeObserver.unobserve(element.el);
    }

    resizeObserver = new ResizeObserver(() => {
      const iframe = document.querySelector<HTMLIFrameElement>('.gjs-frame');

      if (iframe) {
        const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
        const cke = iframeDoc.querySelector<HTMLDivElement>('.cke_editable.gjs-selected');
        const controls = document.querySelector<HTMLDivElement>('.gjs-toolbar');
        const toolbar = document.querySelector<HTMLDivElement>('.gjs-rte-toolbar');

        if (cke && controls && toolbar) {
          const iframeRect = iframe.getBoundingClientRect();
          const ckeRect = cke.getBoundingClientRect();
          const controlsRect = controls.getBoundingClientRect();
          const toolbarRect = toolbar.getBoundingClientRect();

          if (ckeRect.bottom + toolbarRect.height <= iframeRect.height) {
            toolbar.style.top = `${ckeRect.height}px`;
          } else if (ckeRect.bottom <= iframeRect.height) {
            toolbar.style.top = '0';
          }

          if (ckeRect.bottom + controlsRect.height <= iframeRect.height) {
            controls.style.top = `${ckeRect.height}px`;
          } else {
            controls.style.top = toolbar.style.top;
          }
        }
      }
    });

    resizeObserver.observe(element.el);
  });

  editor.on('rte:disable', element => {
    if (resizeObserver) {
      resizeObserver.unobserve(element.el);
    }
  });

  editor.on('rteToolbarPosUpdate', pos => {
    switch (c.position) {
      case 'center':
        const diff = pos.elementWidth / 2 - pos.targetWidth / 2;
        pos.left = pos.elementLeft + diff;
        break;
      case 'right':
        const width = pos.targetWidth;
        pos.left = pos.elementLeft + pos.elementWidth - width;
        break;
      default:
    }

    if (pos.top <= pos.canvasTop) {
      pos.top = pos.elementTop + pos.elementHeight;
    }

    if (pos.left < pos.canvasLeft) {
      pos.left = pos.canvasLeft;
    }
  });

  if (!CKEDITOR.plugins.registered.customer) {
    CKEDITOR.plugins.add('customer', {
      requires: ['richcombo'],
      init(editorInit) {
        // Gets the list of insertable strings from the settings.
        const strings = Object.keys(DocumentCustomerTags).reduce((acc, tag) => {
          if (
            DocumentCustomerTags[tag] === DocumentCustomerTags.waiverLink ||
            DocumentCustomerTags[tag] === DocumentCustomerTags.waiver ||
            DocumentCustomerTags[tag] === DocumentCustomerTags.contractLink ||
            DocumentCustomerTags[tag] === DocumentCustomerTags.contract
          ) {
            return acc;
          }

          return [
            ...acc,
            {
              name: DocumentCustomerTags[tag],
              value: ` ${wrapTemplateTag(tag)} `,
            },
          ];
        }, []);
        // add the menu to the editor
        editorInit.ui.addRichCombo('customer', {
          label: 'Customer tags',
          title: 'Customer tags',
          voiceLabel: 'Customer tags',
          toolbar: 'insert',
          className: 'cke_format',
          multiSelect: false,
          panel: {
            css: [editorInit.config.contentsCss, CKEDITOR.skin.getPath('editor')],
            voiceLabel: editorInit.lang.panelVoiceLabel,
          },
          init() {
            for (let i = 0, len = strings.length; i < len; i += 1) {
              const string: any = strings[i];
              // If there is no value, make a group header using the name.
              if (!string.value) {
                this.startGroup(string.name);
              }
              // If we have a value, we have a string insert row.
              else {
                // If no name provided, use the value for the name.
                if (!string.name) {
                  string.name = string.value;
                }
                // If no label provided, use the name for the label.
                if (!string.label) {
                  string.label = string.name;
                }
                this.add(string.value, string.name, string.label);
              }
            }
          },

          onClick(value) {
            editorInit.focus();
            editorInit.fire('saveSnapshot');
            editorInit.insertHtml(value);
            editorInit.fire('saveSnapshot');
          },
        });
      },
    });
  }

  if (!CKEDITOR.plugins.registered.club) {
    CKEDITOR.plugins.add('club', {
      requires: ['richcombo'],
      init(editorInit) {
        // Gets the list of insertable strings from the settings.
        const strings = Object.keys(DocumentClubTags).map(tag => {
          return {
            name: DocumentClubTags[tag],
            value: ` ${wrapTemplateTag(tag)} `,
          };
        });
        // add the menu to the editor
        editorInit.ui.addRichCombo('club', {
          label: 'Club tags',
          title: 'Club tags',
          voiceLabel: 'Club tags',
          toolbar: 'insert',
          className: 'cke_format',
          multiSelect: false,
          panel: {
            css: [editorInit.config.contentsCss, CKEDITOR.skin.getPath('editor')],
            voiceLabel: editorInit.lang.panelVoiceLabel,
          },

          init() {
            for (let i = 0, len = strings.length; i < len; i += 1) {
              const string: any = strings[i];
              // If there is no value, make a group header using the name.
              if (!string.value) {
                this.startGroup(string.name);
              }
              // If we have a value, we have a string insert row.
              else {
                // If no name provided, use the value for the name.
                if (!string.name) {
                  string.name = string.value;
                }
                // If no label provided, use the name for the label.
                if (!string.label) {
                  string.label = string.name;
                }
                this.add(string.value, string.name, string.label);
              }
            }
          },

          onClick(value) {
            editorInit.focus();
            editorInit.fire('saveSnapshot');
            editorInit.insertHtml(value);
            editorInit.fire('saveSnapshot');
          },
        });
      },
    });
  }
});

export const CKEEditorOptions = {
  options: {
    language: 'en',
    startupFocus: true,
    extraAllowedContent: '*(*);*{*}',
    allowedContent: true,
    enterMode: CKEDITOR.ENTER_P,
    extraPlugins: 'sharedspace,justify,colorbutton,panelbutton,font,customer,club',
    toolbar: [
      { name: 'styles', items: ['FontSize'] },
      { name: 'basicstyles', items: ['Roboto', 'Bold', 'Italic', 'Underline', 'Strike'] },
      { name: 'colors', items: ['TextColor', 'BGColor', '-'] },
      { name: 'basicstyles', items: ['RemoveFormat'] },
      {
        name: 'paragraph',
        items: ['NumberedList', 'BulletedList', 'Outdent', 'Indent'],
      },
      {
        name: 'paragraph',
        items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'Blockquote', '-'],
      },
      { name: 'links', items: ['Link'] },
      { name: 'custom', items: ['customer', 'club'] },
    ],
  },
};

export default 'gjs-plugin-ckeditor';
