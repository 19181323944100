import * as yup from 'yup';

import { insuranceValidationSchema } from 'common/validationSchemas/InsuranceValidationSchema';
import { getEmergencyContactValidationSchema } from 'common/validationSchemas/emergencyContactValidationSchema';
import { TShortProfileFieldInfoImt } from 'modules/corporate-settings/interfaces';
import { getAllergiesValidationSchema } from 'common/validationSchemas/allergiesValidationSchema';

export const AdditionalInfoStepValidationSchema = (requiredFields: TShortProfileFieldInfoImt) => {
  return yup.object().shape({
    insuranceDto: yup.object().shape(insuranceValidationSchema(requiredFields)),
    emergencyContactDto: yup.object().shape(getEmergencyContactValidationSchema(requiredFields)),
    ...getAllergiesValidationSchema(requiredFields),
  });
};
