import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { actionTypes } from '../../constants/usedSessionsReport';

const initStateUsedSessionsReport = fromJS({
  list: [],
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
  isLoading: false,
  totals: {},
  sessionsTotalsLoading: false,
  sessionUnredeemLoading: false,
  sessionUnredeemResult: null,
});

function paymentUserSessionsReducer(state = initStateUsedSessionsReport, action) {
  switch (action.type) {
    case actionTypes.SESSION_UNREDEEM_LOADING:
      return state.set('sessionUnredeemLoading', fromJS(action.payload));
    case actionTypes.SESSION_UNREDEEM:
      return state.set('sessionUnredeemResult', action.payload);
    case actionTypes.RESET_UNREDEEM_SUCCESS:
      return state.set('sessionUnredeemResult', null);
    case actionTypes.FETCH_USED_SESSIONS_TOTALS:
      return state.set('totals', fromJS(action.payload));
    case actionTypes.FETCH_USED_SESSIONS_TOTALS_LOADING:
      return state.set('sessionsTotalsLoading', action.payload);
    case actionTypes.FETCH_USED_SESSIONS_REPORT_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_USED_SESSIONS_REPORT:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta))
        .set('listLoading', false);
    default:
      return state;
  }
}

export default combineReducers({
  usedSessionsList: paymentUserSessionsReducer,
});
