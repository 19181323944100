import { useSelector } from 'react-redux';

import { selectProfileRecommendedFields } from 'common/state/settings/selectors';

import { TShortProfileFieldInfoImt } from 'modules/corporate-settings/interfaces';

import { RequiredProfileInfo } from 'modules/corporate-settings/constants/requiredFields';

const fieldsMap = {
  [RequiredProfileInfo.Photo]: 'image',
  [RequiredProfileInfo.Email]: 'email',
  [RequiredProfileInfo.PhoneNumber]: 'phones',
  [RequiredProfileInfo.Allergies]: 'allergies',
  [RequiredProfileInfo.Salesperson]: 'salesperson',
  [RequiredProfileInfo.Birthday]: 'birthday',
  // Group fields
  [RequiredProfileInfo.BillingInfo]: 'paymentMethods', // TODO: Add for member creation first step Payment methods
  [RequiredProfileInfo.Address]: 'address',
  [RequiredProfileInfo.EmergencyContactInfo]: 'emergencyContactDto',
  [RequiredProfileInfo.InsuranceInfo]: 'insuranceDto',
};

export const useRecommendedFields = (): {
  profileRecommendedFields: TShortProfileFieldInfoImt;
  checkRecommendedFieldsAreNotEmpty: <T extends object>(data: T) => boolean;
} => {
  const profileRecommendedFields = useSelector(selectProfileRecommendedFields);

  const checkRecommendedFieldsAreNotEmpty = <T extends object>(data: T) => {
    const checkFieldsOnEmptyValue = (field: string, excludedFields = []) => {
      const clonedData = { ...data[field] };

      if (excludedFields.length) {
        excludedFields.forEach(value => {
          if (value in clonedData) {
            delete clonedData[value];
          }
        });
      }

      return Object.values(clonedData).some((value: string | null | undefined) => {
        if (typeof value === 'string') {
          return value.trim() === '';
        }

        return value === null;
      });
    };

    const checkFieldOnEmptyValue = (field: string) => {
      if (typeof data[field] === 'string') {
        return data[field].trim() === '';
      }

      return data[field] === null;
    };

    const emptyRecommendedFields = profileRecommendedFields
      .keySeq()
      .filter(fieldType => {
        if (typeof fieldsMap[fieldType] === 'string' && data[fieldsMap[fieldType]] === undefined) {
          return false;
        }

        if (
          // fieldType === RequiredProfileInfo.BillingInfo ||
          fieldType === RequiredProfileInfo.Address ||
          fieldType === RequiredProfileInfo.EmergencyContactInfo ||
          fieldType === RequiredProfileInfo.InsuranceInfo
        ) {
          if (fieldsMap[RequiredProfileInfo.Address] in data) {
            return checkFieldsOnEmptyValue(fieldsMap[RequiredProfileInfo.Address], ['line2']);
          }

          if (fieldsMap[RequiredProfileInfo.BillingInfo] in data) {
            return checkFieldsOnEmptyValue(fieldsMap[RequiredProfileInfo.BillingInfo]);
          }

          if (fieldsMap[RequiredProfileInfo.EmergencyContactInfo] in data) {
            return checkFieldsOnEmptyValue(fieldsMap[RequiredProfileInfo.EmergencyContactInfo], [
              'emergencyAddress2',
            ]);
          }

          if (fieldsMap[RequiredProfileInfo.InsuranceInfo] in data) {
            return checkFieldsOnEmptyValue(fieldsMap[RequiredProfileInfo.InsuranceInfo]);
          }
        }

        if (
          fieldType === RequiredProfileInfo.Allergies &&
          fieldsMap[RequiredProfileInfo.Allergies] in data &&
          data[fieldsMap[RequiredProfileInfo.Allergies]]
        ) {
          const hasEmptyField = !data[fieldsMap[RequiredProfileInfo.Allergies]].length;

          if (hasEmptyField) {
            return true;
          }
        }

        if (
          fieldType === RequiredProfileInfo.PhoneNumber &&
          fieldsMap[RequiredProfileInfo.PhoneNumber] in data &&
          data[fieldsMap[RequiredProfileInfo.PhoneNumber]]
        ) {
          const hasEmptyField = data[fieldsMap[RequiredProfileInfo.PhoneNumber]].some(
            item => !item.phone || !item.phone.trim(),
          );

          if (hasEmptyField) {
            return true;
          }
        }

        return checkFieldOnEmptyValue(fieldsMap[fieldType]);
      })
      .toJS();

    return emptyRecommendedFields.length !== 0;
  };

  return { profileRecommendedFields, checkRecommendedFieldsAreNotEmpty };
};
