import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { AdditionalInfoStep } from 'common/components';
import { IAdditionalInfo } from 'modules/front-desk/interfaces';
import { IImmutablePrimaryMemberInfo } from 'common/components/PersonProfile/interfaces';
import { ActionResult } from 'common/constants/globalConstants';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import {
  saveAdditionalInfo,
  updateAdditionalInfoResult,
} from 'common/state/newPerson/additionalInfo/actions';
import {
  selectAdditionalInfo,
  selectAdditionalInfoForUpdateResult,
  selectUpdateAdditionalInfoLoading,
} from 'common/state/newPerson/additionalInfo/selectors';
import { useAppDispatch } from 'store/hooks';
import { ILeadProfileImt } from 'modules/crm/interfaces/leads';
import { selectCreatedLead } from 'modules/crm/state/leads/selectors';
import { PeakModules } from 'common/constants/peakModules';
import { selectPrimaryInfo } from 'common/state/newPerson/primaryInfo/selectors';

interface IAdditionalInfoStepContainerProps {
  module: PeakModuleForNewPersonType;
}

const AdditionalInfoStepContainer = (props: IAdditionalInfoStepContainerProps): JSX.Element => {
  const { module } = props;

  const dispatch = useAppDispatch();

  const primaryInfo: IImmutablePrimaryMemberInfo = useSelector(selectPrimaryInfo);
  const additionalInfoForUpdateResult = useSelector(selectAdditionalInfoForUpdateResult);
  const additionalInfo = useSelector(selectAdditionalInfo);
  const isLoading = useSelector(selectUpdateAdditionalInfoLoading);
  const leadPrimaryInfo: ILeadProfileImt = useSelector(selectCreatedLead);

  const isCrmOrPTCrmModule =
    module === PeakModules.Crm ||
    module === PeakModules.PersonalTrainingCrm ||
    module === PeakModules.Members;

  const isResponseSuccess = additionalInfoForUpdateResult === ActionResult.SUCCESS_ACTION;

  const personId = isCrmOrPTCrmModule ? leadPrimaryInfo?.get('id') : primaryInfo?.get('id');

  useEffect(() => {
    if (isResponseSuccess) {
      dispatch(updateAdditionalInfoResult(null));
    }
  }, [isResponseSuccess, dispatch]);

  const handleOnSubmitAdditionalInfo = (data: IAdditionalInfo) => {
    if (personId) {
      const additionalFields = data.additionalFields || [];

      dispatch(
        saveAdditionalInfo(
          personId,
          {
            ...data,
            additionalFields: additionalFields.filter(item => Boolean(item.value.trim())),
          },
          module,
        ),
      );
    }
  };

  return (
    <AdditionalInfoStep
      additionalInfo={additionalInfo}
      personId={personId}
      isResponseSuccess={isResponseSuccess}
      handleOnSubmit={handleOnSubmitAdditionalInfo}
      isLoading={isLoading}
      module={module}
    />
  );
};

export default AdditionalInfoStepContainer;
