import { IRoutes } from 'common/interfaces/routes';
import messages from 'modules/services/messages/templates';

export const routes: IRoutes[] = [
  {
    type: 'link',
    path: '/services/document-templates/contract',
    message: messages.contracts,
  },
  {
    type: 'link',
    path: '/services/document-templates/waiver',
    message: messages.waivers,
  },
];
