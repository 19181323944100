import React from 'react';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core';

import { IKidZoneAutocompleteCustomerImt } from 'modules/kid-zone/interfaces';

import { CheckInBlock } from 'common/components/PersonProfile/components';

import messages from 'modules/kid-zone/messages';
import menuItems from 'common/messages/menuItems';
import GuardianBlock from 'modules/kid-zone/components/GuardiansBlock/GuardianBlock';
import OtherBlock from 'modules/kid-zone/components/GuardiansBlock/OtherBlock';

const useStyles = makeStyles((theme: Theme) => ({
  guardiansBlock: {
    height: 'initial',
  },
  header: {
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '.875rem',
    opacity: 0.3,
    fontWeight: 'bold',
    lineHeight: 1.2,
    textTransform: 'uppercase',
  },
  other: {
    paddingBottom: theme.spacing(3),

    '&:last-child': {
      paddingBottom: 0,
    },
  },
}));

interface IProps {
  guardians: ImmutableList<IKidZoneAutocompleteCustomerImt>;
  otherMembers: ImmutableList<IKidZoneAutocompleteCustomerImt>;
  isSmallScreen: boolean;
  isCheckInPanel: boolean;
  isActionLoading?: boolean;
  onCheckInKid: (guardianId: string) => void;
  onAddBirthday: (id: string) => void;
  onCheckOutKid?: (guardianId: string) => void;
}

const GuardiansBlock = ({
  guardians,
  otherMembers,
  isSmallScreen,
  isCheckInPanel,
  isActionLoading,
  onCheckOutKid,
  onCheckInKid,
  onAddBirthday,
}: IProps): JSX.Element => {
  const classes = useStyles();

  return (
    <CheckInBlock
      title={<FormattedMessage {...messages.guardiansTitle} />}
      className={classes.guardiansBlock}
    >
      {guardians?.size ? (
        <Grid container spacing={3}>
          {guardians.map(guardian => {
            return (
              <GuardianBlock
                isCheckInPanel={isCheckInPanel}
                isActionLoading={isActionLoading}
                onCheckInKid={onCheckInKid}
                onCheckOutKid={onCheckOutKid}
                isSmallScreen={isSmallScreen}
                member={guardian}
              />
            );
          })}
        </Grid>
      ) : (
        <Typography color="textSecondary">
          <FormattedMessage {...messages.noGuardiansText} />
        </Typography>
      )}

      {Boolean(otherMembers?.size) && (
        <>
          <Box className={classes.header}>
            <Typography className={classes.title}>
              <FormattedMessage {...menuItems.other} />
            </Typography>
          </Box>
          {otherMembers.map(item => {
            return (
              <OtherBlock
                onAddBirthday={onAddBirthday}
                className={classes.other}
                isSmallScreen={isSmallScreen}
                member={item}
              />
            );
          })}
        </>
      )}
    </CheckInBlock>
  );
};

export default GuardiansBlock;
