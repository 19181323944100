import { IDictionaryCommonItem } from 'modules/dictionaries/interfaces/interfaces';
import { ImmutableObject } from 'common/state/interfaces';
import { PermissionLevels } from 'common/constants/permissions';

export enum ContentType {
  VIDEO = 'VIDEO',
  DOCUMENT = 'DOCUMENT',
  HTML_PAGE = 'HTML_PAGE',
}

export enum ModuleType {
  FRONT_DESK = 'FRONT_DESK',
  EMPLOYEES = 'EMPLOYEES',
  MEMBERS = 'MEMBERS',
  REPORTS = 'REPORTS',
  CRM = 'CRM',
  BOOKING = 'BOOKING',
  SERVICES = 'SERVICES',
  POS_CONTENT = 'POS_CONTENT',
  DICTIONARIES = 'DICTIONARIES',
  PERMISSIONS = 'PERMISSIONS',
  CLUBS = 'CLUBS',
  CORPORATE_SETTINGS = 'CORPORATE_SETTINGS',
  ORGANIZATIONS = 'ORGANIZATIONS',
  SETTINGS = 'SETTINGS',
  KID_ZONE = 'KID_ZONE',
  POS = 'POS',
}

export interface IHelpInfoItem extends IDictionaryCommonItem {
  contentType: ContentType;
  levelType: PermissionLevels.PEAK | PermissionLevels.CORPORATE;
  moduleType: ModuleType;
  url: string;
  description: string;
}

export interface IHelpInfoItemImt extends ImmutableObject<IHelpInfoItem> {
  get<K extends keyof IHelpInfoItem>(key: K): IHelpInfoItem[K];
}
