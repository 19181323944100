import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { actionTypes } from '../../constants/services';

const initStateServicesList = fromJS({
  list: [],
  meta: {},
  isLoading: false,
  changeServiceStatusLoading: false,
  changeServiceStatusResult: undefined,
});

function servicesListReducer(state = initStateServicesList, action) {
  switch (action.type) {
    case actionTypes.FETCH_SERVICES_LIST_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_SERVICES_LIST:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta));
    case actionTypes.RESET_SERVICES_LIST:
      return state
        .set('list', initStateServicesList.get('list'))
        .set('meta', initStateServicesList.get('meta'));
    case actionTypes.CHANGE_SERVICE_STATUS_LOADING:
      return state.set('changeServiceStatusLoading', fromJS(action.payload));
    case actionTypes.CHANGE_SERVICE_STATUS:
      return state.set('changeServiceStatusResult', fromJS(action.payload));
    case actionTypes.RESET_DELETE_SUCCESS:
      return state.set('deleteServiceResult', fromJS({}));
    default:
      return state;
  }
}

const initialStateService = fromJS({
  service: {},
  isCreateUpdateServiceLoading: false,
  isServiceLoading: false,
  createUpdateServiceActionResult: null,
});

function serviceReducer(state = initialStateService, action) {
  switch (action.type) {
    case actionTypes.FETCH_SERVICE:
      return state.set('service', fromJS(action.payload));
    case actionTypes.CREATE_UPDATE_SERVICE_LOADING:
      return state.set('isCreateUpdateServiceLoading', action.payload);
    case actionTypes.CREATE_UPDATE_SERVICE_RESULT_ACTION:
      return state.set('createUpdateServiceActionResult', action.payload);
    case actionTypes.RESET_SERVICE:
      return initialStateService;
    default:
      return state;
  }
}

export default combineReducers({
  servicesList: servicesListReducer,
  serviceItem: serviceReducer,
});
