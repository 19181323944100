import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { MenuItem } from '@material-ui/core';

import { Controller, useForm } from 'react-hook-form';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

// selectors
import { selectAllEvent, selectAllEventLoading } from 'modules/timeclock/state/selectors';

// components
import { DialogComponent, Select } from 'common/components';

// actions
import {
  clockInEmployeePosition,
  clockOutEmployeePosition,
  getAllEvents,
} from 'modules/timeclock/state/actions';

// interfaces
import { ITimeclockEventsRequestData } from 'modules/timeclock/interfaces/timeclock';

// constants
import { getRequiredMessage } from 'common/constants/globalConstants';
import inputLabels from 'common/messages/inputLabels';
import timeclockMessages from 'modules/timeclock/messages';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  selectedPosition: any;
  employeeId: string;
  isProfile: boolean;
}

const getValidationSchema = yup.object().shape({
  eventId: yup
    .string()
    .nullable()
    .required(getRequiredMessage),
});

const UnitEventModal = ({
  onClose,
  isOpen,
  selectedPosition,
  employeeId,
  isProfile,
}: IProps): JSX.Element => {
  const dispatch = useDispatch();
  const formId = 'unit-positions-form-id';
  const [timezoneMoment] = useTimezoneMoment();
  const renderIntlMessage = useRenderIntlMessage();

  const allEvents = useSelector(selectAllEvent);
  const allEventLoading = useSelector(selectAllEventLoading);

  const formMethods = useForm({
    defaultValues: {
      eventId: null,
    },
    resolver: yupResolver(getValidationSchema),
    mode: 'onBlur',
  });

  const { control, errors, handleSubmit } = formMethods;

  useEffect(() => {
    const requestData: ITimeclockEventsRequestData = {
      clubIds: selectedPosition.club ? [selectedPosition.club.id] : [],
      rangeEndDate: timezoneMoment()
        .endOf('day')
        .utc()
        .format(),
      rangeStartDate: timezoneMoment()
        .startOf('day')
        .utc()
        .format(),
    };
    dispatch(getAllEvents(requestData, isProfile));
  }, [dispatch, selectedPosition, isProfile, timezoneMoment]);

  const submit = data => {
    const selectedEvent = allEvents.find(event => event.id === data.eventId);
    const clockData = {
      jobPositionId: selectedPosition.id,
      eventId: data.eventId,
      eventDate: timezoneMoment(selectedEvent.date)
        .utc()
        .format(),
    };
    if (!selectedPosition.clockEdIn) {
      dispatch(clockInEmployeePosition(employeeId, clockData));
    } else {
      dispatch(clockOutEmployeePosition(employeeId, clockData));
    }
    onClose();
  };

  return (
    <DialogComponent
      isOpen={isOpen}
      loading={allEventLoading}
      onClose={onClose}
      title={<FormattedMessage {...timeclockMessages.unitOption} />}
      size="xs"
      supressBottomShadow
      hasShadowsOnScroll
      onSubmit={handleSubmit(submit)}
      formId={formId}
    >
      <form onSubmit={handleSubmit(submit)}>
        <Controller
          control={control}
          name="eventId"
          render={({ value, onChange }) => (
            <Select
              label={<FormattedMessage {...inputLabels.eventLabel} />}
              variant="outlined"
              fullWidth
              value={value}
              onChange={onChange}
              disabled={allEventLoading}
              error={!!errors.eventId}
              helperText={renderIntlMessage(errors.eventId?.message)}
            >
              {allEvents?.map(event => (
                <MenuItem key={event.id} value={event.id}>
                  {event.title}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </form>
    </DialogComponent>
  );
};

export default UnitEventModal;
