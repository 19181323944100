export const actionTypes = {
  // Errors
  PUSH_REQUEST_ERROR: 'PUSH_REQUEST_ERROR',
  RESET_REQUEST_ERRORS: 'RESET_REQUEST_ERRORS',
  // Register data
  FETCH_REGISTER_DATA_LOADING: 'FETCH_REGISTER_DATA_LOADING',
  FETCH_REGISTER_DATA: 'FETCH_REGISTER_DATA',
  RESET_REGISTER_DATA: 'RESET_REGISTER_DATA',
  SET_SELECTED_REGISTER_ID: 'SET_SELECTED_REGISTER_ID',
  RESET_SELECTED_REGISTER_ID: 'RESET_SELECTED_REGISTER_ID',
  UPDATE_REGISTER_INVENTORIES_DATA: 'UPDATE_REGISTER_INVENTORIES_DATA',
  // Register totals
  FETCH_REGISTER_TOTALS_LOADING: 'FETCH_REGISTER_TOTALS_LOADING',
  FETCH_REGISTER_TOTALS: 'FETCH_REGISTER_TOTALS',
  RESET_REGISTER_TOTALS: 'RESET_REGISTER_TOTALS',
  // Register cash desk info
  FETCH_REGISTER_CASH_DESK_INFO_LOADING: 'FETCH_REGISTER_CASH_DESK_INFO_LOADING',
  FETCH_REGISTER_CASH_DESK_INFO: 'FETCH_REGISTER_CASH_DESK_INFO',
  // Registers list
  FETCH_REGISTERS_LIST_LOADING: 'FETCH_REGISTERS_LIST_LOADING',
  FETCH_REGISTERS_LIST: 'FETCH_REGISTERS_LIST',
  RESET_REGISTERS_LIST: 'RESET_REGISTERS_LIST',
  // Open/close/reconcile Register
  OPEN_REGISTER_ITEM_LOADING: 'OPEN_REGISTER_ITEM_LOADING',
  OPEN_REGISTER_ITEM: 'OPEN_REGISTER_ITEM',
  RESET_OPEN_REGISTER_ITEM_ACTION_RESULT: 'RESET_OPEN_REGISTER_ITEM_ACTION_RESULT',

  CLOSE_REGISTER_INVOICES_LOADING: 'CLOSE_REGISTER_INVOICES_LOADING',
  CLOSE_REGISTER_INVOICES_RESULT: 'CLOSE_REGISTER_INVOICES_RESULT',
  RESET_CLOSE_REGISTER_INVOICES: 'RESET_CLOSE_REGISTER_INVOICES',

  CLOSE_REGISTER_ITEM_LOADING: 'CLOSE_REGISTER_ITEM_LOADING',
  CLOSE_REGISTER_ITEM: 'CLOSE_REGISTER_ITEM',
  RESET_CLOSE_REGISTER_ITEM_ACTION_RESULT: 'RESET_CLOSE_REGISTER_ITEM_ACTION_RESULT',

  RECONCILE_REGISTER_ITEM_LOADING: 'RECONCILE_REGISTER_ITEM_LOADING',
  RECONCILE_REGISTER_ITEM: 'RECONCILE_REGISTER_ITEM',
  RESET_RECONCILE_REGISTER_ITEM_ACTION_RESULT: 'RESET_RECONCILE_REGISTER_ITEM_ACTION_RESRECONCILE',
  // register selection process
  REGISTER_SELECTION_PROCESS_LOADING: 'REGISTER_SELECTION_PROCESS_LOADING',
};

export enum Denominations {
  OneHundredDollar = 'oneHundredDollar',
  FiftyDollar = 'fiftyDollar',
  TwentyDollar = 'twentyDollar',
  TenDollar = 'tenDollar',
  FiveDollar = 'fiveDollar',
  TwoDollar = 'twoDollar',
  OneDollar = 'oneDollar',
  FiftyCent = 'fiftyCent',
  TwentyFiveCent = 'twentyFiveCent',
  TenCent = 'tenCent',
  FiveCent = 'fiveCent',
  OneCent = 'oneCent',
}

export const denominations = [
  { key: Denominations.OneHundredDollar, label: '100’s', value: 100 },
  { key: Denominations.FiftyDollar, label: '50’s', value: 50 },
  { key: Denominations.TwentyDollar, label: '20’s', value: 20 },
  { key: Denominations.TenDollar, label: '10’s', value: 10 },
  { key: Denominations.FiveDollar, label: '5’s', value: 5 },
  { key: Denominations.TwoDollar, label: '2’s', value: 2 },
  { key: Denominations.OneDollar, label: '1’s', value: 1 },
  { key: Denominations.FiftyCent, label: '50¢', value: 0.5 },
  { key: Denominations.TwentyFiveCent, label: '25¢', value: 0.25 },
  { key: Denominations.TenCent, label: '10¢', value: 0.1 },
  { key: Denominations.FiveCent, label: '5¢', value: 0.05 },
  { key: Denominations.OneCent, label: '1¢', value: 0.01 },
];

export enum ReconcileSteps {
  ReconcileBills = 'reconcileBills',
  ReconcileChecks = 'reconcileChecks',
  ReconcileTotals = 'reconcileTotals',
}

export enum OpenRegisterSteps {
  ReconcileBills = 'reconcileBills',
  ReconcileChecks = 'reconcileChecks',
}

export enum CloseRegisterSteps {
  ReconcileBills = 'reconcileBills',
  ReconcileChecks = 'reconcileChecks',
  ReconcileTotals = 'reconcileTotals',
  CloseConfirmation = 'closeConfirmation',
  LinkToReport = 'linkToReport',
}
