import axios from 'axios';

import { ITableParams } from 'common/interfaces/table';
import ApiService from 'services/network/ApiService';
import { IPaginatedCRMTemplate, ICRMTemplate } from 'modules/crm/interfaces/crmTemplates';
import { TemplatePurpose, TemplateTypes } from 'common/constants/documentTemplate';
import {
  ICreateDocumentTemplateDto,
  IDocumentTemplateDto,
  IUpdateDocumentTemplateStatusRequest,
} from 'common/interfaces/documentTemplate';

export default class PtCRMTemplatesService extends ApiService {
  public getTemplates = (
    type: TemplateTypes,
    purpose: TemplatePurpose,
    requestOptions?: ITableParams,
  ): Promise<IPaginatedCRMTemplate> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    const params = {
      documentTypeList: type,
      purposeTypeList: purpose,
      ...searchParams,
    };

    return axios.get('/api/v1/pt-crm/document-template', { params });
  };

  public getDocumentTemplate = (id: string): Promise<IDocumentTemplateDto> => {
    return axios.get(`/api/v1/pt-crm/document-template/${id}`);
  };

  public saveDocumentTemplate = (
    requestData: ICreateDocumentTemplateDto,
  ): Promise<IDocumentTemplateDto> => {
    return axios.post('/api/v1/pt-crm/document-template', requestData);
  };

  public updateDocumentTemplate = (
    requestData: IDocumentTemplateDto,
  ): Promise<IDocumentTemplateDto> => {
    return axios.put(`/api/v1/pt-crm/document-template/${requestData.id}`, requestData);
  };

  public updateDocumentStatus = ({
    id,
    active,
  }: IUpdateDocumentTemplateStatusRequest): Promise<ICRMTemplate> => {
    return axios.patch(`/api/v1/pt-crm/document-template/${id}/active`, {
      active,
    });
  };
}
