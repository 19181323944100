import axios from 'axios';

import ApiService from './ApiService';

// interfaces
import { EventUpdateType, IBookingEvent, IShortResource } from 'modules/booking/interfaces';
import { IPaginatedCommon } from 'modules/pos-settings/interfaces/common';
import { ITableParams } from 'common/interfaces/table';
import { IPaginatedData } from 'common/interfaces/pagination';
import { INamedEntity } from 'common/interfaces/common';
import { IDictionaryCommonItem } from 'modules/dictionaries/interfaces/interfaces';

export default class MemberPortalBookingService extends ApiService {
  public getBookingEvents = (
    tableParams?: ITableParams,
  ): Promise<IPaginatedCommon<IBookingEvent>> => {
    const searchParams = this.getSearchFromTableParams(tableParams, true);

    return axios.get('/api/v1/member-portal/events', { params: searchParams });
  };

  public getBookingEvent = (eventId: string, eventDate: string): Promise<IBookingEvent> => {
    return axios.get(`/api/v1/member-portal/events/${eventId}`, {
      params: { eventDate },
    });
  };

  public cancelBookingEventParticipation = (
    eventId: string,
    date: string,
    updateType?: EventUpdateType,
  ): Promise<IBookingEvent> => {
    return axios.post(`/api/v1/member-portal/events/${eventId}/cancel-participation`, null, {
      params: { updateType, date },
    });
  };

  public confirmBookingEventParticipation = (
    eventId: string,
    date: string,
    updateType?: EventUpdateType,
  ): Promise<IBookingEvent> => {
    return axios.post(`/api/v1/member-portal/events/${eventId}/approve`, null, {
      params: { updateType, date },
    });
  };

  public joinBookingEventClass = (
    eventId: string,
    date: string,
    updateType?: EventUpdateType,
  ): Promise<IBookingEvent> => {
    return axios.post(`/api/v1/member-portal/events/${eventId}/join`, null, {
      params: { updateType, date },
    });
  };

  public getClubsList = (): Promise<IPaginatedData<INamedEntity>> => {
    return axios.get('/api/v1/member-portal/events/clubs');
  };

  public getResourcesList = (): Promise<IPaginatedData<IShortResource>> => {
    return axios.get('/api/v1/member-portal/events/resources');
  };

  public getEventTagsList = (): Promise<IPaginatedData<IDictionaryCommonItem>> => {
    return axios.get('/api/v1/member-portal/events/event-tags');
  };
}
