import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { actionTypes } from 'modules/reports/constants/salesPOS';
import { initialState } from 'common/constants/initialState';

const initStateSalesPOSSummaryReducer = fromJS({
  data: {},
  isLoading: false,
});

function salesPOSSummaryReducer(state = initStateSalesPOSSummaryReducer, action) {
  switch (action.type) {
    case actionTypes.FETCH_SALES_POS_SUMMARY_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_SALES_POS_SUMMARY:
      return state.set('data', fromJS(action.payload));
    default:
      return state;
  }
}

const initStateCashSummaryReducer = fromJS({
  list: [],
  totals: {},
  isLoading: false,
});

function cashSummaryReducer(state = initStateCashSummaryReducer, action) {
  switch (action.type) {
    case actionTypes.FETCH_CASH_SUMMARY_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_CASH_SUMMARY:
      return state
        .set('list', fromJS([]))
        .set('totals', fromJS({}))
        .set('isLoading', false);
    default:
      return state;
  }
}

const initStatePaymentsSummaryReducer = fromJS(initialState);

function paymentsSummaryReducer(state = initStatePaymentsSummaryReducer, action) {
  switch (action.type) {
    case actionTypes.FETCH_PAYMENTS_SUMMARY_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_PAYMENTS_SUMMARY:
      return state.set('result', fromJS(action.payload));
    default:
      return state;
  }
}

const initStateServiceSummaryReducer = fromJS({
  list: [],
  totals: {},
  isLoading: false,
});

function serviceSummaryReducer(state = initStateServiceSummaryReducer, action) {
  switch (action.type) {
    case actionTypes.FETCH_SERVICE_SUMMARY_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_SERVICE_SUMMARY:
      return state
        .set('list', fromJS([]))
        .set('totals', fromJS({}))
        .set('isLoading', false);
    default:
      return state;
  }
}

const initStateInvoicesSummaryReducer = fromJS({
  list: [],
  isLoading: false,
});

function invoicesSummaryReducer(state = initStateInvoicesSummaryReducer, action) {
  switch (action.type) {
    case actionTypes.FETCH_INVOICES_SUMMARY_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_INVOICES_SUMMARY:
      return state.set('list', fromJS(action.payload.statistics)).set('isLoading', false);
    default:
      return state;
  }
}

const initStateSalesSummaryReducer = fromJS({
  list: [],
  isLoading: false,
});

function salesSummaryReducer(state = initStateSalesSummaryReducer, action) {
  switch (action.type) {
    case actionTypes.FETCH_SALES_SUMMARY_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_SALES_SUMMARY:
      return state.set('list', fromJS(action.payload.statistics)).set('isLoading', false);
    default:
      return state;
  }
}

const initStateRegistersSummaryReducer = fromJS({
  list: [],
  isLoading: false,
});

function registersSummaryReducer(state = initStateRegistersSummaryReducer, action) {
  switch (action.type) {
    case actionTypes.FETCH_REGISTERS_SUMMARY_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_REGISTERS_SUMMARY:
      return state.set('list', fromJS(action.payload.statistics)).set('isLoading', false);
    default:
      return state;
  }
}

const initStateTaxSummaryReducer = fromJS({
  list: [],
  isLoading: false,
});

function taxSummaryReducer(state = initStateTaxSummaryReducer, action) {
  switch (action.type) {
    case actionTypes.FETCH_TAX_SUMMARY_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_TAX_SUMMARY:
      return state.set('list', fromJS(action.payload.statistics)).set('isLoading', false);
    default:
      return state;
  }
}

const initStateRevenueSummaryReducer = fromJS({
  list: [],
  isLoading: false,
});

function revenueSummaryReducer(state = initStateRevenueSummaryReducer, action) {
  switch (action.type) {
    case actionTypes.FETCH_REVENUE_SUMMARY_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_REVENUE_SUMMARY:
      return state.set('list', fromJS(action.payload.statistics)).set('isLoading', false);
    default:
      return state;
  }
}

const exportInitialState = fromJS({
  result: null,
  error: null,
  loading: false,
});
function exportReducer(state = exportInitialState, action) {
  switch (action.type) {
    case actionTypes.EXPORT_SALES_POS_REPORT:
      return state.set('result', fromJS(action.payload));
    case actionTypes.EXPORT_SALES_POS_REPORT_LOADING:
      return state.set('loading', action.payload);
    case actionTypes.RESET_EXPORT_SALES_POS_REPORT:
      return exportInitialState;
    default:
      return state;
  }
}

export default combineReducers({
  salesPOSSummary: salesPOSSummaryReducer,
  cashSummary: cashSummaryReducer,
  paymentsSummary: paymentsSummaryReducer,
  serviceSummary: serviceSummaryReducer,
  invoicesSummary: invoicesSummaryReducer,
  salesSummary: salesSummaryReducer,
  registersSummary: registersSummaryReducer,
  taxSummary: taxSummaryReducer,
  revenueSummary: revenueSummaryReducer,
  export: exportReducer,
});
