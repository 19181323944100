import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { actionTypes } from '../../constants/vendors';

const initStateVendorsList = fromJS({
  list: [],
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
  isListLoading: false,
});

function vendorsListReducer(state = initStateVendorsList, action) {
  switch (action.type) {
    case actionTypes.FETCH_VENDORS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_VENDORS:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta))
        .set('listLoading', false);
    default:
      return state;
  }
}

const requestInitialState = fromJS({
  result: null,
  isLoading: false,
  error: null,
});

function changeVendorStatusReducer(state = requestInitialState, action) {
  switch (action.type) {
    case actionTypes.VENDOR_CHANGE_STATUS_SUCCESS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.VENDOR_CHANGE_STATUS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_VENDOR_CHANGE_STATUS_RESULT:
      return requestInitialState;
    default:
      return state;
  }
}

const initStateVendor = fromJS({
  vendor: {},
  isLoading: false,
  isSaveLoading: false,
  saveVendorResult: {},
});

function vendorByIdReducer(state = initStateVendor, action) {
  switch (action.type) {
    case actionTypes.FETCH_VENDOR_BY_ID_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_VENDOR_BY_ID:
      return state.set('vendor', fromJS(action.payload));
    case actionTypes.CREATE_VENDOR_LOADING:
      return state.set('isSaveLoading', action.payload);
    case actionTypes.CREATE_VENDOR:
      return state.set('saveVendorResult', fromJS(action.payload));
    case actionTypes.UPDATE_VENDOR_LOADING:
      return state.set('isSaveLoading', action.payload);
    case actionTypes.UPDATE_VENDOR:
      return state.set('saveVendorResult', fromJS(action.payload));
    case actionTypes.RESET_UPDATE_VENDOR_RESULT:
      return state.set('saveVendorResult', fromJS({}));
    case actionTypes.RESET_VENDOR_BY_ID:
      return initStateVendor;
    default:
      return state;
  }
}

export default combineReducers({
  vendorsList: vendorsListReducer,
  changeVendorStatusAction: changeVendorStatusReducer,
  vendorItem: vendorByIdReducer,
});
