import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 167,
      height: 59,
      position: 'relative',
      borderRadius: 3,
      border: `1px dashed ${theme.palette.primary.main}`,
      backgroundColor: 'rgba(102, 205, 0, 0.08)',
      padding: theme.spacing(4, 0.5, 1),
      display: 'inline-block',
      '& >span': {
        opacity: 0,
      },
    },
    bottomLine: {
      position: 'absolute',
      width: `calc(100% - ${theme.spacing(1)}px)`,
      borderBottom: `1px solid ${theme.palette.text.primary}`,
      bottom: theme.spacing(1),
    },
    name: {
      color: theme.palette.primary.main,
      fontSize: 10,
      fontWeight: 'bold',
      lineHeight: 1,
      letterSpacing: 'normal',
      textTransform: 'uppercase',
      position: 'absolute',
      top: theme.spacing(1),
      left: theme.spacing(0.5),
    },
  }),
);

interface IProps {
  offsetKey: string;
  children?: any;
}

const EditorSignature = (props: IProps) => {
  const { offsetKey, children } = props;
  const classes = useStyles();

  return (
    <div className={classes.root} data-offset-key={offsetKey}>
      <div className={classes.name}>Customer Signature</div>
      <div className={classes.bottomLine} />
      {children}
    </div>
  );
};

export default EditorSignature;
