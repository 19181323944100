// libraries
import React from 'react';
import cx from 'classnames';
import { Box, LinearProgress, Typography, makeStyles } from '@material-ui/core';
// custom interfaces
import { CustomTheme } from 'common/ui/interfaces';

import { getProgressDescriptionLabel } from 'common/utils/displayServiceQuantity';

// constants
import { DurationUnit } from 'common/interfaces/common';
import { RedeemType } from 'common/constants/service';
import { MINUTES_IN_DAY, MINUTES_IN_HOUR, MINUTES_IN_WEEK } from 'common/constants/times';
import { colors } from 'common/ui/theme/default';

// components
import { StepProgress } from 'common/components/index';

interface IRedeemProgressProps {
  availableToRedeem: number;
  totalToRedeem: number;
  limited: boolean;

  redeemType?: RedeemType;
  redeemUnit?: DurationUnit;

  small?: boolean;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  linearProgress: {
    height: ({ small }: IRedeemProgressProps) => theme.spacing(small ? 0.125 : 0.25),

    '&.isFull > .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.error.main,
    },
  },
  progressDescription: {
    color: colors.primary,

    '&.isFull': {
      color: theme.palette.error.main,
    },
  },
}));

const transformMinutesToRedeemUnit = (
  availableToRedeem: number,
  redeemType: RedeemType,
  redeemUnit: DurationUnit,
): number => {
  if (redeemType === RedeemType.Duration) {
    if (redeemUnit === DurationUnit.Mins) {
      return availableToRedeem;
    }
    if (redeemUnit === DurationUnit.Hours) {
      return availableToRedeem / MINUTES_IN_HOUR;
    }
    if (redeemUnit === DurationUnit.Days) {
      return availableToRedeem / MINUTES_IN_DAY;
    }

    return availableToRedeem / MINUTES_IN_WEEK;
  }

  return availableToRedeem;
};

const RedeemProgress: React.FC<IRedeemProgressProps> = (
  props: IRedeemProgressProps,
): JSX.Element => {
  const { availableToRedeem, totalToRedeem, redeemType, redeemUnit, limited, small } = props;

  const classes = useStyles(props);

  const linearProgressValue: number = limited
    ? (transformMinutesToRedeemUnit(availableToRedeem, redeemType, redeemUnit) / totalToRedeem) *
      100
    : 100;
  const isFullyUsed: boolean = limited && availableToRedeem === 0;

  return (
    <>
      <Typography
        variant="caption"
        component="p"
        className={cx(classes.progressDescription, {
          isFull: isFullyUsed,
        })}
      >
        {getProgressDescriptionLabel(
          redeemType,
          redeemUnit,
          availableToRedeem,
          totalToRedeem,
          limited,
        )}
      </Typography>
      {!limited || redeemType === RedeemType.Duration ? (
        <Box>
          <LinearProgress
            variant="determinate"
            className={cx(classes.linearProgress, 'MuiLinearProgress-colorSecondary', {
              isFull: isFullyUsed,
            })}
            value={linearProgressValue}
          />
        </Box>
      ) : (
        <StepProgress
          size={small ? 'small' : 'medium'}
          done={availableToRedeem}
          total={totalToRedeem}
          error={isFullyUsed}
        />
      )}
    </>
  );
};

export default React.memo(RedeemProgress);
