import React from 'react';
import { Grid, Typography } from '@material-ui/core';

interface IProps {
  className?: string;
  firstName: string;
  lastName: string;
}

const SalespersonName = (props: IProps) => {
  const { firstName, lastName, className } = props;

  return (
    <Grid container wrap="nowrap" alignItems="center" spacing={1}>
      <Grid item>
        <Typography className={className}>{`${firstName} ${lastName}`}</Typography>
      </Grid>
    </Grid>
  );
};

export default React.memo(SalespersonName);
