import React from 'react';
import moment from 'moment-timezone';
import { FormattedMessage } from 'react-intl';
import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';

import { getInitials } from 'helpers/common';

import { CustomTheme } from 'common/ui/interfaces';
import { IKidZoneAutocompleteCustomerImt } from 'modules/kid-zone/interfaces';

import { AvatarWithInitials } from 'common/components';

import kidZoneMessages from 'modules/kid-zone/messages';
import { DEFAULT_DATE_FORMAT } from 'common/constants/dateFormats';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    childName: {
      marginBottom: theme.spacing(1),
    },
  }),
);

interface IProps {
  child: IKidZoneAutocompleteCustomerImt;
  className?: string;
}

const ChildCard = ({ child, className }: IProps): JSX.Element => {
  const classes = useStyles();

  const initials = getInitials(child.get('firstName'), child.get('lastName'));

  return (
    <Box display="flex" alignItems="center" className={className}>
      <AvatarWithInitials
        initials={initials}
        imageUrl={child.get('imageUrl')}
        borderRadius="3px"
        width="56px"
        height="56px"
        marginRight={1.5}
        fontSize="16px"
      />

      <Box>
        <Typography className={classes.childName}>
          {`${child.get('firstName')} ${child.get('lastName')}`}
        </Typography>

        <Typography color="textSecondary">
          <FormattedMessage
            {...kidZoneMessages.childAge}
            values={{
              year: moment().diff(moment(child.get('birthday')), 'years'),
              birthday: moment(child.get('birthday')).format(DEFAULT_DATE_FORMAT),
            }}
          />
        </Typography>
      </Box>
    </Box>
  );
};

export default ChildCard;
