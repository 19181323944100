import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { actionTypes } from 'modules/pos-settings/constants/paymentMethods';
import { initialState } from 'common/constants/initialState';

const initStatePaymentMethodsDefaultPos = fromJS({
  list: [],
  isLoading: false,
});

function paymentMethodsPosReducer(state = initStatePaymentMethodsDefaultPos, action) {
  switch (action.type) {
    case actionTypes.FETCH_PAYMENT_METHODS_POS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_PAYMENT_METHODS_POS:
      return state.set('list', fromJS(action.payload.data)).set('listLoading', false);
    case actionTypes.RESET_PAYMENT_METHODS_POS:
      return initStatePaymentMethodsDefaultPos;
    default:
      return state;
  }
}

const initStatePaymentMethodsCustomPos = fromJS({
  list: [],
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
});

function paymentMethodsCustomPosReducer(state = initStatePaymentMethodsCustomPos, action) {
  switch (action.type) {
    case actionTypes.FETCH_PAYMENT_METHODS_CUSTOM_POS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_PAYMENT_METHODS_CUSTOM_POS:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta));
    case actionTypes.RESET_PAYMENT_METHODS_POS:
      return initStatePaymentMethodsCustomPos;
    default:
      return state;
  }
}

const initStatePaymentMethodActions = fromJS({
  editPaymentMethodRequestStatus: initialState,
  createPaymentMethodRequestStatus: initialState,
});

function paymentMethodItemActionsPosReducer(state = initStatePaymentMethodActions, action) {
  switch (action.type) {
    case actionTypes.EDIT_PAYMENT_METHOD_POS_LOADING:
      return state.setIn(['editPaymentMethodRequestStatus', 'isLoading'], action.payload);
    case actionTypes.EDIT_PAYMENT_METHOD_POS:
      return state.setIn(['editPaymentMethodRequestStatus', 'result'], fromJS(action.payload));
    case actionTypes.RESET_EDIT_PAYMENT_METHOD_POS_RESULT:
      return state.set('editPaymentMethodRequestStatus', fromJS(initialState));
    case actionTypes.CREATE_PAYMENT_METHOD_POS_LOADING:
      return state.setIn(['createPaymentMethodRequestStatus', 'isLoading'], action.payload);
    case actionTypes.CREATE_PAYMENT_METHOD_POS:
      return state.setIn(['createPaymentMethodRequestStatus', 'result'], fromJS(action.payload));
    case actionTypes.RESET_CREATE_PAYMENT_METHOD_POS_RESULT:
      return state.set('createPaymentMethodRequestStatus', fromJS(initialState));
    default:
      return state;
  }
}

const initStatePaymentMethodItemPos = fromJS({
  title: '',
  list: [],
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
  isLoading: false,
  blockPaymentMethodItemLoading: false,
  blockPaymentMethodItemResult: {},
  multiEditLoading: false,
  multiEdit: [],
});

function paymentMethodItemPosReducer(state = initStatePaymentMethodItemPos, action) {
  switch (action.type) {
    case actionTypes.FETCH_PAYMENT_METHOD_CUSTOM_POS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_PAYMENT_METHODS_CUSTOM_TITLE_POS:
      return state.set('title', action.payload.title);
    case actionTypes.FETCH_PAYMENT_METHOD_CUSTOM_POS:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta))
        .set('listLoading', false);
    case actionTypes.FETCH_BLOCK_PAYMENT_METHOD_CUSTOM_ITEM_POS_LOADING:
      return state.set('blockPaymentMethodItemLoading', fromJS(action.payload));
    case actionTypes.FETCH_BLOCK_PAYMENT_METHOD_CUSTOM_ITEM_POS:
      return state.set('blockPaymentMethodItemResult', fromJS(action.payload));
    case actionTypes.RESET_BLOCK_PAYMENT_METHOD_CUSTOM_ITEM_POS_SUCCESS:
      return state.set('blockPaymentMethodItemResult', fromJS({}));
    case actionTypes.FETCH_PAYMENT_METHOD_CUSTOM_ITEM_MULTI_EDIT_LOADING:
      return state.set('multiEditLoading', action.payload);
    case actionTypes.FETCH_PAYMENT_METHOD_CUSTOM_ITEM_MULTI_EDIT:
      return state.set('multiEdit', fromJS(action.payload));
    case actionTypes.RESET_PAYMENT_METHOD_CUSTOM_ITEM_MULTI_EDIT:
      return state.set('multiEdit', fromJS([]));
    case actionTypes.RESET_PAYMENT_METHODS_POS:
      return initStatePaymentMethodItemPos;
    default:
      return state;
  }
}

const initStateNewPaymentMethodCustomItem = fromJS({
  isItemLoading: false,
  actionResult: {},
  actionCreateResult: {},
  actionDeleteResult: {},
  list: [],
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
  multiEditClubs: [],
  unavailable: [],
});

function paymentMethodCustomItemPosReducer(state = initStateNewPaymentMethodCustomItem, action) {
  switch (action.type) {
    case actionTypes.FETCH_PAYMENT_METHOD_CUSTOM_ITEM_POS_LOADING:
      return state.set('isItemLoading', action.payload);
    case actionTypes.FETCH_PAYMENT_METHOD_CUSTOM_ITEM_POS_PAGINATE:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta))
        .set('unavailable', fromJS(action.payload.unavailable));
    case actionTypes.CREATE_PAYMENT_METHOD_CUSTOM_ITEM_POS_CLUBS:
      return state.set('actionCreateResult', fromJS(action.payload));
    case actionTypes.DELETE_PAYMENT_METHOD_CUSTOM_ITEM_POS_CLUBS:
      return state.set('actionDeleteResult', fromJS(action.payload));
    case actionTypes.RESET_PAYMENT_METHOD_CUSTOM_ITEM_POS_CLUBS_ACTION:
      return state.set('actionCreateResult', fromJS({})).set('actionDeleteResult', fromJS({}));
    case actionTypes.CREATE_PAYMENT_METHOD_CUSTOM_ITEM_POS:
      return state.set('actionResult', fromJS(action.payload));
    case actionTypes.FETCH_PAYMENT_METHOD_CUSTOM_ITEM_POS_CLUBS_MULTI_EDIT:
      return state.set('multiEditClubs', fromJS(action.payload));
    case actionTypes.RESET_PAYMENT_METHOD_CUSTOM_ITEM_POS_CLUBS_MULTI_EDIT:
      return state.set('multiEditClubs', fromJS([]));
    case actionTypes.RESET_PAYMENT_METHOD_CUSTOM_ITEM_POS:
      return initStateNewPaymentMethodCustomItem;
    default:
      return state;
  }
}

const initStatePaymentMethodCustomItemClubsConfiguration = fromJS({
  paymentMethodCustomItemConfiguration: {},
  paymentMethodCustomItemConfigurationLoading: false,
  listClubs: [],
  listClubsLoading: false,
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
  unavailable: [],
  totals: {},
  actionCreateResult: {},
  actionDeleteResult: {},
  isActionLoading: false,
  multiEditClubsLoading: false,
  multiEditClubs: [],
  error: null,
});

function paymentMethodCustomItemClubsConfigurationReducer(
  state = initStatePaymentMethodCustomItemClubsConfiguration,
  action,
) {
  switch (action.type) {
    case actionTypes.FETCH_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_POS_LOADING:
      return state.set('paymentMethodCustomItemConfigurationLoading', action.payload);
    case actionTypes.FETCH_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_POS:
      return state.set('paymentMethodCustomItemConfiguration', fromJS(action.payload));
    case actionTypes.FETCH_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_CLUBS_POS_LOADING:
      return state.set('listClubsLoading', action.payload);
    case actionTypes.FETCH_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_CLUBS_POS:
      return state
        .set('listClubs', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta))
        .set('unavailable', fromJS(action.payload.unavailableClubIds))
        .set('listClubsLoading', false);
    case actionTypes.FETCH_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_CLUBS_ACTION_LOADING:
      return state.set('isActionLoading', action.payload);
    case actionTypes.CREATE_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_CLUBS:
      return state.set('actionCreateResult', fromJS(action.payload));
    case actionTypes.DELETE_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_CLUBS:
      return state.set('actionDeleteResult', fromJS(action.payload));
    case actionTypes.RESET_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_CLUBS_RESULT:
      return state.set('actionCreateResult', fromJS({})).set('actionDeleteResult', fromJS({}));
    case actionTypes.FETCH_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_CLUBS_MULTI_EDIT_LOADING:
      return state.set('multiEditClubsLoading', action.payload);
    case actionTypes.FETCH_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_CLUBS_MULTI_EDIT:
      return state.set('multiEditClubs', fromJS(action.payload));
    case actionTypes.RESET_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_CLUBS_MULTI_EDIT:
      return state.set('multiEditClubs', fromJS([]));
    case actionTypes.RESET_PAYMENT_METHOD_CUSTOM_ITEM_CONFIGURATION_POS:
      return initStatePaymentMethodCustomItemClubsConfiguration;
    default:
      return state;
  }
}

export default combineReducers({
  paymentMethodsList: paymentMethodsPosReducer,
  paymentMethodsCustomList: paymentMethodsCustomPosReducer,
  paymentMethodItemActions: paymentMethodItemActionsPosReducer,
  paymentMethodItemList: paymentMethodItemPosReducer,
  paymentMethodCustomItem: paymentMethodCustomItemPosReducer,
  paymentMethodCustomItemClubsConfiguration: paymentMethodCustomItemClubsConfigurationReducer,
});
