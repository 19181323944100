// Libraries
import React, { FC, useEffect } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  SvgIcon,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import cx from 'classnames';
import { ReactComponent as CloseIcon } from 'img/times.svg';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as ResetIcon } from 'img/icons/reset.svg';
// Custom components
import { Button, ButtonWithCircularProgress } from 'common/components';
// Interfaces
import { CustomTheme } from 'common/ui/interfaces';
// Messages
import messages from './messages';
import ScrollBox from '../ScrollBox/ScrollBox';
import useShowAlert from 'common/hooks/useShowAlert';
import { ReasonType } from 'common/interfaces/dialog';

const useStyles = makeStyles((theme: CustomTheme) => ({
  header: {
    padding: theme.spacing(2),
  },
  title: {
    letterSpacing: 0,
    lineHeight: '21px',
    textTransform: 'capitalize',
    paddingRight: theme.spacing(3),
  },
  footer: {
    padding: theme.spacing(2),
  },
  containerResetInfo: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.main,
    height: '100%',
  },
  clicableResetInfo: {
    cursor: 'pointer',
  },
  textReset: {
    fontSize: theme.typography.body2.fontSize,
  },
  closeCross: {
    position: 'absolute',
    padding: theme.spacing(1.25),
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.text.secondary,
    opacity: 0.2,
  },
  dotsIcon: {
    position: 'absolute',
    padding: theme.spacing(1.25),
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.text.secondary,
  },
  closeIcon: {
    width: 15,
    height: 15,
  },
  padding: {
    padding: theme.spacing(0, 2, 1, 2),
    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .MuiDialogActions-root': {
      paddingRight: 0,
    },
  },
  disablePadding: {
    padding: 0,
    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .MuiDialogActions-root': {
      padding: 0,
    },
  },
  buttonsWrapper: {
    flex: 1,
    display: 'flex',
    '& .MuiButton-root': {
      minWidth: 95,
      marginLeft: 8,
    },
    '& .MuiButton-root:first-child': {
      marginLeft: 0,
    },
  },
  buttonsAlignEnd: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonsAlignStart: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
  },
}));

interface IModalProps {
  isOpen: boolean;
  keepMounted?: boolean;
  title?: JSX.Element | string | React.ReactNode;
  submitBtnTitle?: JSX.Element | string;
  cancelBtnTitle?: JSX.Element | string;

  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;

  // Custom dialog header element
  header?: JSX.Element;
  subHeader?: JSX.Element;
  // Custom dialog footer element
  footer?: JSX.Element;
  hasCustomFooter?: boolean;

  // Hide shadow block in the Scroll component
  hasShadowsOnScroll?: boolean;
  supressBottomShadow?: boolean;
  hideFooterButtons?: boolean;

  children?: JSX.Element | JSX.Element[] | React.ReactNode;

  className?: string;

  closeCross?: boolean;
  cancelBtn?: boolean;
  submitBtn?: boolean;
  formId?: string;

  loading?: boolean;
  disabled?: boolean;
  disableFullScreenBehavior?: boolean;
  enableBackdropClick?: boolean;
  enableEscapeKeyDown?: boolean;
  disableBodyPadding?: boolean;
  isDirty?: boolean;
  actionSuccess?: boolean;

  onSubmit?: (e?: React.BaseSyntheticEvent) => void;
  onClose: (e: React.BaseSyntheticEvent | unknown, reason: ReasonType) => void;
  onReset?: () => void;

  scroll?: 'paper' | 'body';
  showResetInfo?: boolean;
  isResetInfoClicable?: boolean;
  onClickResetInfo?: () => void;
  additionalActionButtons?: JSX.Element | JSX.Element[];
  additionalLeftAlignedActionButtons?: JSX.Element | JSX.Element[];
  additionalHeaderSettings?: JSX.Element;
}

const defaultProps: IModalProps = {
  isOpen: false,

  submitBtnTitle: <FormattedMessage {...messages.dialogDefaultSubmitTitle} />,
  cancelBtnTitle: <FormattedMessage {...messages.dialogDefaultCancelTitle} />,

  hideFooterButtons: false,
  closeCross: true,
  cancelBtn: true,
  submitBtn: true,
  loading: false,
  disabled: false,
  showResetInfo: false,
  hasCustomFooter: false,
  hasShadowsOnScroll: true,
  supressBottomShadow: false,

  size: 'sm',

  onSubmit: () => {},
  onClose: () => {},
};

const DialogComponent: FC<IModalProps> = React.memo(props => {
  const classes = useStyles();
  const {
    isOpen,
    children,
    className,
    size,
    submitBtn,
    submitBtnTitle,
    disabled,
    footer,
    header,
    hideFooterButtons,
    subHeader,
    title,
    closeCross,
    cancelBtn,
    cancelBtnTitle,
    loading,
    formId,
    disableFullScreenBehavior,
    disableBodyPadding,
    enableBackdropClick,
    enableEscapeKeyDown = true,
    hasCustomFooter,
    onSubmit,
    onClose,
    showResetInfo,
    additionalActionButtons,
    additionalLeftAlignedActionButtons,
    isResetInfoClicable,
    hasShadowsOnScroll,
    onClickResetInfo,
    isDirty,
    actionSuccess,
    keepMounted,
    supressBottomShadow,
    additionalHeaderSettings,
  } = props;
  const theme = useTheme();
  const fullScreen = !useMediaQuery(theme.breakpoints.up('laptop'), { noSsr: true });
  const onCloseDialog = useShowAlert(isDirty, onClose, actionSuccess);

  useEffect(() => {
    if (actionSuccess) onCloseDialog({}, 'actionSuccess');
  }, [actionSuccess, onCloseDialog]);

  const renderCancelButton = () => {
    if (!cancelBtn) {
      return null;
    }

    return (
      <Button color="primary" disabled={loading} onClick={e => onCloseDialog(e, 'cancelBtnClick')}>
        {cancelBtnTitle}
      </Button>
    );
  };

  const renderSubmitButton = () => {
    if (!submitBtn) {
      return null;
    }

    return (
      <ButtonWithCircularProgress
        // TODO set autoFocus={true} when material will be V5
        autoFocus={false}
        type="submit"
        disabled={disabled}
        isSubmitting={!!loading}
        form={formId}
        onClick={onSubmit}
      >
        {submitBtnTitle}
      </ButtonWithCircularProgress>
    );
  };

  const renderHeader = (): JSX.Element => {
    if (header) {
      return header;
    }

    return (
      <DialogTitle className={classes.header} disableTypography>
        <Typography variant="h4" className={classes.title}>
          {title}
        </Typography>
        {additionalHeaderSettings && (
          <Box mr={2} mb={1} className={classes.dotsIcon}>
            {additionalHeaderSettings}
          </Box>
        )}
        {closeCross && (
          <IconButton
            autoFocus={false}
            aria-label="close"
            className={classes.closeCross}
            onClick={e => onCloseDialog(e, 'closeIconClick')}
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        )}
        {subHeader || null}
      </DialogTitle>
    );
  };

  const renderFooter = () => {
    if (footer) {
      return footer;
    }

    if (hasCustomFooter) {
      return null;
    }

    return (
      <DialogActions>
        <Grid container spacing={1}>
          {showResetInfo && (
            <Grid item xs={12} sm="auto">
              <Box
                className={cx(classes.containerResetInfo, {
                  [classes.clicableResetInfo]: isResetInfoClicable,
                })}
                onClick={onClickResetInfo}
              >
                <Typography color={isResetInfoClicable ? 'primary' : 'textSecondary'}>
                  <SvgIcon color="inherit" fontSize="inherit">
                    <ResetIcon />
                  </SvgIcon>
                </Typography>
                &nbsp;
                <Typography className={classes.textReset} color="textSecondary">
                  Reset to original value
                </Typography>
              </Box>
            </Grid>
          )}
          {!hideFooterButtons && (
            <Grid item className={classes.buttonsWrapper}>
              {additionalLeftAlignedActionButtons && (
                <Grid item className={classes.buttonsAlignStart}>
                  {additionalLeftAlignedActionButtons}
                </Grid>
              )}
              <Grid item className={classes.buttonsAlignEnd}>
                {renderCancelButton()}
                {additionalActionButtons}
                {renderSubmitButton()}
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogActions>
    );
  };

  return (
    <Dialog
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          if (enableBackdropClick) {
            onClose(event, reason);
          }
        } else if (reason === 'escapeKeyDown') {
          if (enableEscapeKeyDown) {
            onClose(event, reason);
          }
        } else {
          onClose(event, reason);
        }
      }}
      className={className}
      fullWidth
      maxWidth={size}
      fullScreen={fullScreen && !disableFullScreenBehavior}
      transitionDuration={0}
      keepMounted={keepMounted}
    >
      {renderHeader()}
      <ScrollBox
        hasShadowsOnScroll={hasShadowsOnScroll}
        suppressBottomShadow={supressBottomShadow}
        className={disableBodyPadding ? classes.disablePadding : classes.padding}
      >
        {children}
      </ScrollBox>
      {renderFooter()}
    </Dialog>
  );
});
DialogComponent.defaultProps = defaultProps;

export default DialogComponent;
