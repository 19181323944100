import { fromJS } from 'immutable';

import { actionTypes } from 'modules/crm/constants/campaigns';

const initStateCampaigns = fromJS({
  list: [],
  meta: {
    page: 0,
    perPage: 25,
    total: 0,
  },
  listLoading: false,
});

export const campaignsTableReducer = (state = initStateCampaigns, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CAMPAIGNS_LIST_LOADING:
      return state.set('listLoading', action.payload);

    case actionTypes.FETCH_CAMPAIGNS_LIST_SUCCESS:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta));
    default:
      return state;
  }
};

const initStateCampaignCreateForm = fromJS({
  campaignCreating: false,
  createdCampaign: null,
});

export const createCampaignReducer = (state = initStateCampaignCreateForm, action) => {
  switch (action.type) {
    case actionTypes.RESET_CAMPAIGN_FORM:
      return initStateCampaignCreateForm;

    case actionTypes.CREATE_CAMPAIGN_LOADING:
      return state.set('campaignCreating', action.payload);

    case actionTypes.CREATE_CAMPAIGN_SUCCESS:
      return state.set('createdCampaign', fromJS(action.payload)).set('campaignCreating', false);

    default:
      return state;
  }
};

const initStateCampaignUpdateForm = fromJS({
  campaign: {},
  campaignLoading: false,
  updateCampaignResult: null,
  campaignUpdating: false,
});

export const updateCampaignReducer = (state = initStateCampaignUpdateForm, action) => {
  switch (action.type) {
    case actionTypes.RESET_CAMPAIGN_FORM:
      return initStateCampaignUpdateForm;
    case actionTypes.FETCH_CAMPAIGN_LOADING:
      return state.set('campaignLoading', action.payload);
    case actionTypes.FETCH_CAMPAIGN_SUCCESS:
      return state.set('campaign', fromJS(action.payload)).set('campaignLoading', false);
    case actionTypes.UPDATE_CAMPAIGN_LOADING:
      return state.set('campaignUpdating', action.payload);
    case actionTypes.UPDATE_CAMPAIGN_SUCCESS:
      return state.set('updateCampaignResult', action.payload);
    default:
      return state;
  }
};
