import React from 'react';
import { snackbar } from './snackbarUtils';
import { FormattedMessage } from 'react-intl';
import commonMessages from 'common/messages/messages';

export const copyToClipboard = (text: string, onSuccess?: () => void) => {
  if (navigator.clipboard) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        snackbar.toast(<FormattedMessage {...commonMessages.copyToClipboardSuccess} />, {
          preventDuplicate: true,
          autoHideDuration: 1000,
        });

        if (onSuccess) {
          onSuccess();
        }
      })
      .catch(() => {
        snackbar.toast(<FormattedMessage {...commonMessages.copyToClipboardError} />, {
          preventDuplicate: true,
          autoHideDuration: 1000,
        });
      });
  } else {
    snackbar.toast(<FormattedMessage {...commonMessages.copyToClipboardError} />, {
      preventDuplicate: true,
      autoHideDuration: 1000,
    });
  }
};
