import { defineMessages } from 'react-intl';

export default defineMessages({
  // Campaign Form
  runningConditions: {
    id: 'app.modules.crm.campaigns.runningConditions',
    defaultMessage: 'Running Conditions',
  },
  targetAudienceFiltering: {
    id: 'app.modules.crm.campaigns.targetAudienceFiltering',
    defaultMessage: 'Target audience filtering',
  },
  newCampaign: {
    id: 'app.modules.crm.campaigns.newCampaign',
    defaultMessage: 'New {type} Campaign',
  },
  editCampaign: {
    id: 'app.modules.crm.campaigns.editCampaign',
    defaultMessage: 'Edit {type} Campaign',
  },
  campaignCreatedMessage: {
    id: 'app.modules.crm.campaigns.campaignCreatedMessage',
    defaultMessage: 'New campaign has been created',
  },
  archiveCampaignQuestion: {
    id: 'app.modules.crm.campaigns.archiveCampaignQuestion',
    defaultMessage: 'Archive this campaign?',
  },
  archiveSelectedCampaignQuestion: {
    id: 'app.modules.crm.campaigns.archiveSelectedCampaignQuestion',
    defaultMessage: 'Archive selected campaigns?',
  },
  emailCampaignOption: {
    id: 'app.modules.crm.campaigns.emailCampaignOption',
    defaultMessage: 'Email campaign',
  },
  phoneCampaignOption: {
    id: 'app.modules.crm.campaigns.phoneCampaignOption',
    defaultMessage: 'Phone campaign',
  },
  SMSCampaignOption: {
    id: 'app.modules.crm.campaigns.SMSCampaignOption',
    defaultMessage: 'SMS campaign',
  },
  leadStatusFilter: {
    id: 'app.modules.crm.campaigns.leadStatus',
    defaultMessage: 'Lead status',
  },
  locationsFilter: {
    id: 'app.modules.crm.campaigns.locations',
    defaultMessage: 'Locations',
  },
  genderFilter: {
    id: 'app.modules.crm.campaigns.gender',
    defaultMessage: 'Gender',
  },
  ageGroupFilter: {
    id: 'app.modules.crm.campaigns.ageGroup',
    defaultMessage: 'Age group',
  },
  cameFromFilter: {
    id: 'app.modules.crm.campaigns.cameFromFilter',
    defaultMessage: 'Came from',
  },
  servicesFilter: {
    id: 'app.modules.crm.campaigns.services',
    defaultMessage: 'Services',
  },
});
