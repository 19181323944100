import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { List as ImmutableList } from 'immutable';
import {
  Avatar,
  Box,
  Divider,
  InputBase,
  makeStyles,
  Popover,
  Theme,
  Typography,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

import { GrapesCustomCommands } from '../constants';
import { DEFAULT_TABLE_PARAMS, DictionaryList } from 'common/constants';
import { fetchDictionaryList, resetDictionaryListAction } from 'common/state/dictionary/actions';
import {
  selectDictionaryList,
  selectDictionaryListMeta,
  selectIsDictionaryListLoading,
} from 'common/state/dictionary/selectors';

import { useAppDispatch } from 'store/hooks';

import {
  IEmployeeListItem,
  IEmployeeListItemImt,
  IProductListItem,
  IProductListItemImt,
  IServiceListItem,
  IServiceListItemImt,
} from 'common/interfaces/dictionary';
import { IPageMetaImt } from 'common/interfaces/pagination';
import { Loader } from 'common/components';
import templateLabels from '../../../messages/templates';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  search: {
    height: 32,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchIcon: {
    width: '17px',
    height: '17px',
    color: theme.palette.secondary.contrastText,
    opacity: 0.7,
  },
  searchInputRoot: {
    width: '100%',
  },
  searchInput: {
    padding: theme.spacing(1),
  },

  row: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0, 2),
    cursor: 'pointer',
  },
  rowAvatar: {
    width: '25px',
    height: '25px',
  },
  rowTitle: {
    padding: theme.spacing(0, 1),
  },
  rowPrice: {
    color: theme.palette.text.secondary,
  },
  rowEmpty: {
    padding: theme.spacing(4),
  },
  scroll: {
    flex: '1 1',
    overflowY: 'auto',
    margin: 0,
    listStyle: 'none',
    padding: 0,
    height: 200,
    position: 'relative',
  },
}));

interface ISearchComponents {
  anchorEl: Element;
  commandType: GrapesCustomCommands;
  onClose: () => void;
  onSelect: (value: IEmployeeListItem | IServiceListItem) => void;
}

interface IItemResponse {
  name: string;
  photo: string;
  subTitle?: string;
}

const SearchComponents = (props: ISearchComponents): JSX.Element => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const listRef = useRef<HTMLDivElement>(null);
  const [searchStr, setSearchStr] = useState('');
  const { anchorEl, commandType, onClose, onSelect } = props;
  const dataImt: ImmutableList<
    IEmployeeListItemImt | IProductListItemImt | IServiceListItemImt
  > = useSelector(selectDictionaryList(DictionaryList.CRM_TEMPLATE_ITEMS));
  const meta: IPageMetaImt = useSelector(
    selectDictionaryListMeta(DictionaryList.CRM_TEMPLATE_ITEMS),
  );
  const loading: boolean = useSelector(
    selectIsDictionaryListLoading(DictionaryList.CRM_TEMPLATE_ITEMS),
  );

  const data = dataImt.toJS();

  const getItemDisplay = (
    item: IEmployeeListItem | IServiceListItem | IProductListItem,
  ): IItemResponse => {
    switch (commandType) {
      case GrapesCustomCommands.SearchEmployees: {
        const { title, imageUrl } = item as IEmployeeListItem;
        return { name: title, photo: imageUrl };
      }
      case GrapesCustomCommands.SearchProducts: {
        const { title, imageUrl } = item as IProductListItem;
        return { name: title, photo: imageUrl };
      }
      case GrapesCustomCommands.SearchServices: {
        const { title } = item as IServiceListItem;
        return { name: title, photo: '' };
      }
      default: {
        return { name: '', photo: '' };
      }
    }
  };

  const getComponents = useDebouncedCallback(search => {
    dispatch(
      fetchDictionaryList(DictionaryList.CRM_TEMPLATE_ITEMS, {
        page: DEFAULT_TABLE_PARAMS.page,
        perPage: DEFAULT_TABLE_PARAMS.perPage,
        commandType,
        search,
      }),
    );
  }, 500);

  const searchComponents = (search: string) => {
    setSearchStr(search);
    getComponents(search);
  };

  const getMoreHandler = useCallback(() => {
    if (meta.get('total') > (meta.get('page') + 1) * meta.get('perPage') && !loading) {
      dispatch(
        fetchDictionaryList(DictionaryList.CRM_TEMPLATE_ITEMS, {
          page: meta.get('page') + 1,
          perPage: meta.get('perPage'),
          commandType,
        }),
      );
    }
  }, [meta, loading, dispatch, commandType]);

  const scrollHandler = (e: React.UIEvent<HTMLElement>) => {
    const el = e.target as HTMLElement;
    if (el.scrollHeight - (el.scrollTop + el.offsetHeight) < 70) {
      getMoreHandler();
    }
  };

  useEffect(() => {
    if (commandType) {
      dispatch(
        fetchDictionaryList(DictionaryList.CRM_TEMPLATE_ITEMS, {
          page: DEFAULT_TABLE_PARAMS.page,
          perPage: DEFAULT_TABLE_PARAMS.perPage,
          commandType,
        }),
      );
    }
    return () => {
      dispatch(resetDictionaryListAction({ dictionary: DictionaryList.CRM_TEMPLATE_ITEMS }));
    };
  }, [dispatch, commandType]);

  return (
    <Popover
      id="select-product"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box className={classes.root}>
        <Box className={classes.search}>
          <SearchIcon className={classes.searchIcon} />
          <InputBase
            placeholder="Search"
            classes={{
              root: classes.searchInputRoot,
              input: classes.searchInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
            value={searchStr}
            onChange={e => searchComponents(e.target.value)}
          />
        </Box>
        <Divider />
        <div className={classes.scroll} onScroll={scrollHandler} ref={listRef}>
          {!!loading && <Loader />}
          {!!data && !!data.length ? (
            data.map(item => {
              const { name, photo, subTitle } = getItemDisplay(item);
              return (
                <Box
                  key={item.id}
                  className={classes.row}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  onClick={() => onSelect(item)}
                >
                  <Avatar className={classes.rowAvatar} variant="rounded" src={photo}>
                    {name?.charAt(0)}
                  </Avatar>
                  <Typography className={classes.rowTitle} variant="body2">
                    {name}
                  </Typography>
                  {subTitle && (
                    <Typography className={classes.rowPrice} variant="body2">
                      {subTitle}
                    </Typography>
                  )}
                </Box>
              );
            })
          ) : (
            <Typography className={classes.rowEmpty}>
              <FormattedMessage {...templateLabels.nothingFound} />
            </Typography>
          )}
        </div>
      </Box>
    </Popover>
  );
};

export default SearchComponents;
