import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Grid, makeStyles } from '@material-ui/core';

import useTimezoneMoment from 'common/hooks/useTimezoneMoment';

import { DialogComponent, FieldInfo, LoadingBackdrop } from 'common/components/index';

import messages from 'modules/crm/messages/tasks';
import inputLabels from 'common/messages/inputLabels';
import { DEFAULT_DATE_FORMAT } from 'common/constants/dateFormats';
import {
  selectPersonReminder,
  selectPersonReminderLoading,
} from 'common/components/PersonProfile/state/selectors';
import {
  fetchPersonReminder,
  resetPersonReminderAction,
} from 'common/components/PersonProfile/state/actions';
import { PeakModules } from 'common/constants/peakModules';
import { useAppDispatch } from 'store/hooks';

interface IProps {
  isOpen: boolean;
  personId: string;
  module: PeakModules;
  onClose: () => void;
  reminderId?: string;
}

const useStyles = makeStyles({
  description: {
    wordWrap: 'break-word',
  },
});

const ReminderDetailsModal = ({
  isOpen,
  onClose,
  personId,
  reminderId,
  module,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const [timezoneMoment] = useTimezoneMoment();

  const reminder = useSelector(selectPersonReminder(personId));
  const isLoading = useSelector(selectPersonReminderLoading(personId));

  useEffect(() => {
    if (reminderId) {
      dispatch(fetchPersonReminder(personId, reminderId, module));
    }

    return () => {
      dispatch(resetPersonReminderAction(personId, null));
    };
  }, [personId, reminderId, module, dispatch]);

  const classes = useStyles();

  return (
    <DialogComponent
      title={<FormattedMessage {...messages.reminder} />}
      size="xs"
      isOpen={isOpen}
      onClose={onClose}
    >
      <Grid container spacing={2}>
        <LoadingBackdrop isLoading={isLoading} />
        <Grid item xs={6}>
          <FieldInfo
            label={<FormattedMessage {...inputLabels.date} />}
            description={
              reminder
                ? timezoneMoment(
                    `${reminder.get('dueDate')} ${reminder.get('dueTime')}`,
                    'YYYY-MM-DD HH:mm',
                    true,
                  ).format(DEFAULT_DATE_FORMAT)
                : '-'
            }
          />
        </Grid>
        <Grid item xs={6}>
          <FieldInfo
            label={<FormattedMessage {...inputLabels.time} />}
            description={
              reminder
                ? timezoneMoment(
                    `${reminder.get('dueDate')} ${reminder.get('dueTime')}`,
                    'YYYY-MM-DD HH:mm',
                    true,
                  ).format('h:mm A')
                : '-'
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FieldInfo
            label={<FormattedMessage {...inputLabels.text} />}
            description={reminder?.get('text') || '-'}
            descriptionClassName={classes.description}
            hasNotEllipsesDescription
          />
        </Grid>
      </Grid>
    </DialogComponent>
  );
};

export default ReminderDetailsModal;
