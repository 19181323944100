import { UPDATE_PROFILE_AFTER_EDIT_PERSON_ACTION } from 'common/constants/actionTypes';

export const actionTypes = {
  SET_PRIMARY_INFO: 'SET_PRIMARY_INFO',
  GET_PERSONS: 'GET_PERSONS',
  SET_ERROR_VALUE: 'SET_ERROR_VALUE',
  SET_LOADING_VALUE: 'SET_LOADING_VALUE',
  CLEAR_NEW_PERSON_DATA: 'CLEAR_NEW_PERSON_DATA',

  SET_CREATE_PERSON_ACTION_RESULT: 'SET_CREATE_PERSON_ACTION_RESULT',
  RESET_CREATE_PERSON_RESULT: 'RESET_CREATE_PERSON_RESULT',

  SET_UPDATE_PERSON_ACTION_RESULT: 'SET_UPDATE_PERSON_ACTION_RESULT',
  RESET_UPDATE_PERSON_RESULT: 'RESET_UPDATE_PERSON_RESULT',

  [UPDATE_PROFILE_AFTER_EDIT_PERSON_ACTION]: UPDATE_PROFILE_AFTER_EDIT_PERSON_ACTION,

  // search persons
  FETCH_PERSONS_LOADING: 'FETCH_PERSONS_LOADING',
  FETCH_PERSONS_SUCCESS: 'FETCH_PERSONS_SUCCESS',
  FETCH_PERSONS_ERROR: 'FETCH_PERSONS_ERROR',
  RESET_PERSONS_FOUND: 'RESET_PERSONS_FOUND',
};
