export enum CommunicationRoutes {
  COMMUNICATION = '/',
  BANNER_ADS_FOR_CLUB_LIFE = 'banner-ads-for-club-life',
  APP_PUSH_NOTIFICATION = 'app-push-notifications',
}

export const actionTypes = {
  // Banners Advertisement
  FETCH_BANNERS_ADVERTISEMENT_LIST: 'FETCH_BANNERS_ADVERTISEMENT_LIST',
  FETCH_BANNERS_ADVERTISEMENT_LIST_LOADING: 'FETCH_BANNERS_ADVERTISEMENT_LIST_LOADING',
  SET_IS_BANNERS_ADVERTISEMENT_LIST_UPDATED: 'SET_IS_BANNERS_ADVERTISEMENT_LIST_UPDATED',

  CREATE_BANNER_ADVERTISEMENT: 'CREATE_BANNER_ADVERTISEMENT',
  UPDATE_BANNER_ADVERTISEMENT: 'UPDATE_BANNER_ADVERTISEMENT',
  UPDATE_CREATE_BANNER_ADVERTISEMENT_LOADING: 'UPDATE_CREATE_BANNER_ADVERTISEMENT_LOADING',
  UPDATE_BANNER_RESULT: 'UPDATE_BANNER_RESULT',

  UPDATE_BANNER_ADVERTISEMENT_STATUS: 'UPDATE_BANNER_ADVERTISEMENT_STATUS',
  UPDATE_BANNER_ADVERTISEMENT_STATUS_LOADING: 'UPDATE_BANNER_ADVERTISEMENT_STATUS_LOADING',

  // Push Notification
  FETCH_PUSH_NOTIFICATIONS_LIST: 'FETCH_PUSH_NOTIFICATIONS_LIST',
  FETCH_PUSH_NOTIFICATIONS_LIST_LOADING: 'FETCH_PUSH_NOTIFICATIONS_LIST_LOADING',
  SET_IS_PUSH_NOTIFICATIONS_LIST_UPDATED: 'SET_IS_PUSH_NOTIFICATIONS_LIST_UPDATED',

  CREATE_PUSH_NOTIFICATION: 'ADD_PUSH_NOTIFICATION',
  UPDATE_PUSH_NOTIFICATION: 'ADD_PUSH_NOTIFICATION',
  UPDATE_CREATE_PUSH_NOTIFICATION_LOADING: 'UPDATE_CREATE_PUSH_NOTIFICATION_LOADING',
  UPDATE_PUSH_NOTIFICATION_RESULT: 'UPDATE_PUSH_NOTIFICATION_RESULT',

  UPDATE_PUSH_NOTIFICATION_STATUS: 'UPDATE_PUSH_NOTIFICATION_STATUS',
  UPDATE_PUSH_NOTIFICATION_STATUS_LOADING: 'UPDATE_PUSH_NOTIFICATION_STATUS_LOADING',
};
