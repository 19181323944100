import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';

import { TCustomerInsuranceDtoImt } from 'common/interfaces/additionalInfo';

import { ReactComponent as EditIcon } from 'img/icons/pencil_deprecated.svg';
import { CheckInBlock } from 'common/components/PersonProfile/components';
import { FieldInfo } from 'common/components';

import messages from 'common/components/PersonProfile/messages';
import inputLabels from 'common/messages/inputLabels';
import commonMessages from 'common/messages/messages';
import { Button } from 'common/components/index';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface IInsuranceInfoProps {
  insuranceInfo?: TCustomerInsuranceDtoImt;
  onOpenModal: () => void;
}

const useStyles = makeStyles(() => ({
  editIcon: {
    width: '15px',
    height: '15px',
  },
}));

const InsuranceInfo = ({ onOpenModal, insuranceInfo }: IInsuranceInfoProps): JSX.Element => {
  const classes = useStyles();

  return (
    <CheckInBlock
      title={<FormattedMessage {...messages.insuranceInfoBlockTitle} />}
      buttonTitle={
        <Button hasHoverStyle color="primary">
          <EditIcon className={classes.editIcon} />
        </Button>
      }
      onClickButton={onOpenModal}
    >
      {insuranceInfo?.size ? (
        <Grid container spacing={2}>
          <Grid xs={12} item>
            <FieldInfo
              label={<FormattedMessage {...inputLabels.insuranceCompany} />}
              description={insuranceInfo.getIn(['insuranceCompany', 'title'])}
            />
          </Grid>

          <Grid xs={12} item>
            <FieldInfo
              label={<FormattedMessage {...inputLabels.id} />}
              description={insuranceInfo.get('insuranceMemberId')}
            />
          </Grid>
        </Grid>
      ) : (
        <Typography variant="body2" color="textSecondary">
          <FormattedMessage {...commonMessages.notSpecified} />
        </Typography>
      )}
    </CheckInBlock>
  );
};

export default InsuranceInfo;
