export const actionTypes = {
  FETCH_MEMBERS_LOADING: 'FETCH_MEMBERS_LOADING',
  FETCH_MEMBERS: 'FETCH_MEMBERS',

  CREATE_MEMBER_LOADING: 'CREATE_MEMBER_LOADING',
  CREATE_MEMBER: 'CREATE_MEMBER',
  RESET_MEMBER_FORM: 'RESET_MEMBER_FORM',

  UPDATE_MEMBER_LOADING: 'UPDATE_MEMBER_LOADING',
  UPDATE_MEMBER: 'UPDATE_MEMBER',
};

export enum MembersPaths {
  MEMBERS = '/',
  MEMBER = '/:id',
  NEW_MEMBER = '/new',
}
