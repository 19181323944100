import { fromJS } from 'immutable';
import { actionTypes } from 'modules/members/state/emergencyContact/constants';

const emergencyContactInitState = fromJS({
  result: {},
  isLoading: false,
});

const emergencyContactReducer = (state = emergencyContactInitState, action) => {
  switch (action.type) {
    case actionTypes.SET_EMERGENCY_CONTACT_IS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_EMERGENCY_CONTACT:
    case actionTypes.UPDATE_EMERGENCY_CONTACT:
      return state.set('result', fromJS(action.payload));
    case actionTypes.RESET_EMERGENCY_CONTACT:
      return emergencyContactInitState;
    default:
      return state;
  }
};

export default emergencyContactReducer;
