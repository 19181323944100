import { MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { ITableMultiSelectParams } from 'common/interfaces/table';
import { ISuccessResponse } from 'common/interfaces/http';
import { DEFAULT_TABLE_PARAMS } from 'common/constants';
import { IObject } from '../interfaces/common';

export interface IMultipleSelectTableControlProps {
  selected?: Array<string>;
  excluded?: Array<string>;
  numSelected?: number;
  isAllSelected?: boolean;
  setSelected?: (values) => void;
  setNumSelected?: (values) => void;
  setAllSelected?: (total: number) => void;
  handleSelectClick?: (selectedIndex: string) => void;
  singleChangeSelect?: (selectedIndex: string) => void;
  updateSelected?: (newSelected: Array<string>) => void;
  resetSelected?: () => void;
  isSelected?: (itemKey: string) => boolean;
  isExcluded?: (itemKey: string) => boolean;
  tableParams?: ITableMultiSelectParams;
  ref?: MutableRefObject<IMultipleSelectTableControlProps>;
  onChangeTableParamsSubscriber?: (params: IMultipleSelectTableControlProps) => void;
}

interface IUseMultipleSelectTableControlProps {
  createResult?: ISuccessResponse;
  updateResult?: ISuccessResponse;
  deleteResult?: ISuccessResponse;
  closeCreateModal?: () => void;
  closeUpdateModal?: () => void;
  closeDeleteModal?: () => void;
  updateTable?: (data: IUpdateTableByAction) => void;
  onChangeTableParamsSubscriber?: (params: IMultipleSelectTableControlProps) => void;
}

export interface IUpdateTableByAction {
  isActionDelete?: boolean;
  isActionUpdate?: boolean;
  isActionCreate?: boolean;
}

const useMultipleSelectTableControl = ({
  // Input parameters for closing the modal window on successful creation, update, deletion
  createResult,
  updateResult,
  deleteResult,
  closeCreateModal,
  closeUpdateModal,
  closeDeleteModal,
  onChangeTableParamsSubscriber,
  updateTable = () => {},
}: IUseMultipleSelectTableControlProps): IMultipleSelectTableControlProps => {
  // ref
  const ref = useRef<IObject>({
    selected: [],
    numSelected: 0,
    isAllSelected: false,
    excluded: [],
    setSelected: () => {},
    setNumSelected: () => {},
    handleSelectClick: () => {},
    setAllSelected: () => {},
    updateSelected: () => {},
    resetSelected: () => {},
    isSelected: () => false,
    isExcluded: () => false,
    tableParams: {
      searchStr: '',
      page: DEFAULT_TABLE_PARAMS.page,
      perPage: DEFAULT_TABLE_PARAMS.perPage,
      sortBy: String(DEFAULT_TABLE_PARAMS.orderBy),
      sortDirection: DEFAULT_TABLE_PARAMS.order.toUpperCase(),
      tableFilters: [],
    },
    onChangeTableParamsSubscriber,
  });
  const {
    selected,
    numSelected,
    isAllSelected,
    excluded,
    handleSelectClick,
    setAllSelected,
    setNumSelected,
    updateSelected,
    resetSelected,
    isSelected,
    isExcluded,
    tableParams,
  } = ref.current;

  // effects
  const resetSelectedItems = useCallback(() => {
    resetSelected();
    setNumSelected(0);
    setAllSelected(0);
  }, [resetSelected, setAllSelected, setNumSelected]);

  useEffect(() => {
    if (createResult && createResult.success) {
      resetSelectedItems();
      closeCreateModal();
      updateTable({ isActionCreate: true });
    }
    if (updateResult && updateResult.success) {
      resetSelectedItems();
      closeUpdateModal();
      updateTable({ isActionUpdate: true });
    }
    if (deleteResult && deleteResult.success) {
      resetSelectedItems();
      closeDeleteModal();
      updateTable({ isActionDelete: true });
    }
  }, [
    closeCreateModal,
    closeDeleteModal,
    closeUpdateModal,
    createResult,
    deleteResult,
    handleSelectClick,
    resetSelected,
    updateTable,
    resetSelectedItems,
    selected,
    setAllSelected,
    setNumSelected,
    updateResult,
  ]);

  // hook result

  // output parameters to control the internal state of the multi row selection in the table. Call
  // functions to update the selected rows, to update the inscription of the number of selected
  // rows. Getting data such as tableParams, if necessary, you can get table multi select data. And
  // also output the reference parameter to associate our hook with table.
  return {
    selected,
    numSelected,
    isAllSelected,
    excluded,
    singleChangeSelect: handleSelectClick,
    setAllSelected,
    updateSelected,
    resetSelected,
    isSelected,
    isExcluded,
    tableParams,
    ref,
  };
};

export default useMultipleSelectTableControl;
