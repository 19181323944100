import React from 'react';
import cx from 'classnames';
import { Box, makeStyles, Typography } from '@material-ui/core';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import { IssueFrequencyType, IssueFrequencyTypes } from 'modules/services/constants/packages';

import { CustomTheme } from 'common/ui/interfaces';

import { TooltipTypography } from 'common/components/index';

const useStyles = makeStyles((theme: CustomTheme) => ({
  frequencyType: {
    marginLeft: theme.spacing(0.5),
    whiteSpace: 'nowrap',
  },
  includedItemName: {
    '&:before': {
      content: '"\\2022"',
      fontSize: '1rem',
      marginRight: theme.spacing(1),
    },
  },
  noPriceMarker: {
    flex: 1,
    marginLeft: theme.spacing(1),
    textAlign: 'end',
  },
}));

interface IInventoryInfoItem {
  title: string;
  number: number;
  frequency: IssueFrequencyType;
  isIncludedItem?: boolean;
}

const InventoryInfoItem = ({ title, number, frequency, isIncludedItem }: IInventoryInfoItem) => {
  const renderIntlMessage = useRenderIntlMessage();

  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" marginY={2}>
      <TooltipTypography
        ellipsized
        className={cx({
          [classes.includedItemName]: isIncludedItem,
        })}
      >
        {`${number} x ${title}`}
      </TooltipTypography>

      <Typography component="p" color="textSecondary" className={classes.frequencyType}>
        {renderIntlMessage(IssueFrequencyTypes.message(frequency))}
      </Typography>

      <Typography component="p" className={classes.noPriceMarker}>
        -
      </Typography>
    </Box>
  );
};

export default React.memo(InventoryInfoItem);
