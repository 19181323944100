import React from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Box, makeStyles, Tooltip } from '@material-ui/core';

import commonMessages from 'common/messages/messages';
import errorMessages from 'common/errors/messages';
import { Button } from 'common/components/index';
import { CustomTheme } from 'common/ui/interfaces';
import { ReactComponent as AddIcon } from 'img/icons/add.svg';
import { ReactComponent as WarningIcon } from 'img/icons/warning.svg';

interface ITicketSectionActionsProps {
  onAddNewClick: () => void;
  disabled: boolean;
  showWarning: boolean;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  warningIcon: {
    color: theme.palette.warning.main,
  },
  icon: {
    width: 20,
    height: 20,
  },
}));

const TicketSectionActions = (props: ITicketSectionActionsProps): JSX.Element => {
  const { onAddNewClick, disabled, showWarning } = props;

  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      {showWarning && (
        <Tooltip title={<FormattedMessage {...errorMessages.zendeskIsDisabledError} />}>
          <WarningIcon className={cx(classes.icon, classes.warningIcon)} />
        </Tooltip>
      )}
      <Tooltip title={<FormattedMessage {...commonMessages.addBtn} />}>
        <Button
          disabled={disabled}
          type="button"
          onClick={onAddNewClick}
          hasHoverStyle
          color="primary"
        >
          <AddIcon className={classes.icon} />
        </Button>
      </Tooltip>
    </Box>
  );
};

export default TicketSectionActions;
