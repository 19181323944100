import GrapesJS from 'grapesjs';

import servicesIcon from 'img/editor-icons/block-services.svg';
import { GrapesCustomCommands, GrapesCustomTypes } from '../constants';
import { defaultToolbar } from './TextPlugin';
import { removeActions } from '../utils';
import { IObject } from 'common/interfaces/common';

(GrapesJS as IObject).plugins.add(GrapesCustomTypes.ServicesList, editor => {
  function addCommands() {
    editor.Commands.add(GrapesCustomCommands.SearchServices, {
      run() {},
      stop(commandEditor, sender, data) {
        const component = commandEditor.getSelected();

        if (data && data.component) {
          component.components().add({
            type: GrapesCustomTypes.ServiceItem,
            attributes: {
              service: data.component,
            },
          });
        }
      },
    });
  }

  function addComponents() {
    const defaultType = editor.DomComponents.getType('default');

    const { initToolbar } = defaultType.model.prototype;

    editor.DomComponents.addType(GrapesCustomTypes.ServicesList, {
      model: {
        ...defaultType.model,
        defaults: {
          droppable: false,
        },
        initToolbar(args) {
          initToolbar.apply(this, args);

          const toolbar = [
            {
              attributes: { class: 'fa fa-plus' },
              command: GrapesCustomCommands.SearchServices,
            },
            ...defaultToolbar,
          ];

          this.set('toolbar', toolbar);
        },
        isComponent(el) {
          if (
            el.hasAttribute === 'function' &&
            el.hasAttribute('data-gjs-type') === GrapesCustomTypes.ServicesList
          ) {
            return { type: GrapesCustomTypes.ServicesList };
          }
          return false;
        },
      },
      view: {
        ...defaultType.view,
      },
    });

    editor.DomComponents.addType(GrapesCustomTypes.ServiceItem, {
      model: {
        ...defaultType.model,
        defaults: {
          draggable: false,
          droppable: false,
          highlightable: false,
        },
        init() {
          const attrs = this.getAttributes();
          const service = attrs.service || {};
          const components = this.get('components');

          components.reset(`
            <div class="services-info">
              <div class="services-name" data-package-template-id=${service.id}>${service.title}</div>
            </div>
          `);

          components.forEach(component => removeActions(component));
          this.addClass(GrapesCustomTypes.ServiceItem);
        },
        isComponent(el) {
          if (
            el.hasAttribute === 'function' &&
            el.hasAttribute('data-gjs-type') === GrapesCustomTypes.ServiceItem
          ) {
            return { type: GrapesCustomTypes.ServiceItem };
          }
          return false;
        },
      },
      view: {
        ...defaultType.view,
        tagName: 'div',
      },
    });
  }

  function addBlocks() {
    editor.BlockManager.add(GrapesCustomTypes.ServicesList, {
      label: `
        <img class="gjs-block-icon" src="${servicesIcon}" alt="Services" />
        <div class="gjs-block-title">Services</div>
      `,
      content: `
        <div class="services" data-gjs-type="services-list"></div>
        <style>
          .services {
            padding: 8px;
            min-height: 60px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
          }
          .service-item {
            margin: 8px;
            padding: 12px;
            flex: 1;
            flex-basis: 45%;
            display: flex;
            flex-direction: row;
            align-items: center;
            border: solid 1px #d6d6d6;
            border-radius: 4px;
          }
          .services-name {
            margin-bottom: 5px;
            font-size: 14px;
            font-weight: bold;
          }
          .services-price {
            font-size: 14px;
            color: #323232;
            opacity: 0.7;
          }
        </style>
      `,
    });
  }

  addCommands();
  addComponents();
  addBlocks();
});

export default GrapesCustomTypes.ServicesList;
