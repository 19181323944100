import * as yup from 'yup';

import {
  getDisablePastTimeSchema,
  getFutureDateTimeSchema,
} from 'common/validationSchemas/dateSchemas';

import { getRequiredMessage } from 'common/constants/globalConstants';

export const NewCallValidationSchema = yup.object().shape({
  leadPhone: yup
    .object()
    .nullable()
    .required(getRequiredMessage),
  date: getFutureDateTimeSchema('time'),
  time: getDisablePastTimeSchema('date'),
  callScriptSteps: yup.array(
    yup.object({
      note: yup.string().when(['completed', 'allowInput'], {
        is: true,
        then: yup.string().required(getRequiredMessage),
      }),
    }),
  ),
  /* event: yup.object().when('appointment', {
    is: true,
    then: yup.object().shape({
      date: yup.date().nullable(),
      time: yup
        .mixed()
        .nullable()
        .test('date-test', 'Time must be in the future', function(val) {
          const date = moment(this.parent.date);
          const time = moment(val);
          if (
            date
              .set('h', time.get('h'))
              .set('m', time.get('m'))
              .isSameOrBefore(moment())
          ) {
            return false;
          }

          return true;
        })
        .required('Required field'),
      salesperson: yup.object().shape({
        id: yup.string().required(requiredMessage),
      }),
      reminderNum: yup
        .number()
        .min(1, 'Count must be greater than or equal to 1')
        .max(1000, 'Count must be less than or equal to 1000')
        .typeError('Count must be greater than or equal to 1'),
      description: yup.string().max(1000, 'Description must be 1000 characters or less'),
    }),
  }), */
});
