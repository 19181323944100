import { createSelector, OutputSelector } from 'reselect';
import { PoliciesPermissionSetItems } from 'common/constants/permissions';

type SelectPermissions = OutputSelector<any, PoliciesPermissionSetItems[], (res: any) => any>;

const selectPermissionsDomain = (state: any) => {
  return state.get('permission');
};

export const selectPermissions: SelectPermissions = createSelector(
  selectPermissionsDomain,
  state => {
    return state.get('list').toJS();
  },
);
