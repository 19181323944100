import React, { Dispatch, SetStateAction } from 'react';
import { Box, Divider, makeStyles, Theme, Typography } from '@material-ui/core';
import { List } from 'immutable';

import { IShortChildImt } from 'modules/kid-zone/interfaces';

import { ReactComponent as BarcodeIcon } from 'img/icons/barcode.svg';
import { Checkbox } from 'common/components';
import MemberInfo from './MemberInfo';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';

interface IChildrenProps {
  childrenList: List<IShortChildImt>;
  selectedChildren: Partial<Record<string, string>>;
  setSelectedChildren: Dispatch<SetStateAction<Partial<Record<string, string>>>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  checkInTime: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),

    '& > svg': {
      width: '28px',
      height: '28px',
    },
  },
}));

const Children = (props: IChildrenProps): JSX.Element => {
  const { childrenList, selectedChildren, setSelectedChildren } = props;

  const [timezoneMoment] = useTimezoneMoment();

  const classes = useStyles();

  return (
    <>
      {childrenList.map((item, i) => {
        const onChange = () => {
          if (selectedChildren[item.get('id')]) {
            setSelectedChildren(prevState => {
              const copyPrevState = { ...prevState };
              delete copyPrevState[item.get('id')];

              return copyPrevState;
            });
          } else {
            setSelectedChildren(prevState => {
              return { ...prevState, [item.get('id')]: item.get('id') };
            });
          }
        };

        return (
          <>
            {i === 0 && <Divider className={classes.divider} />}

            <Box display="flex" alignItems="center">
              <Checkbox
                size="small"
                value={item.get('id')}
                onChange={onChange}
                disabled={item.get('checkedIn')}
              />

              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                ml={1}
              >
                <MemberInfo
                  width={45}
                  height={45}
                  titleVariant="subtitle2"
                  firstName={item.get('firstName')}
                  lastName={item.get('lastName')}
                  imageUrl={item.get('imageUrl')}
                />

                <Typography color="textSecondary" className={classes.checkInTime}>
                  <Typography variant="h5" component="span" color="inherit">
                    {timezoneMoment(item.get('date')).format('h:mm A')}
                  </Typography>

                  <BarcodeIcon />
                </Typography>
              </Box>
            </Box>

            <Divider className={classes.divider} />
          </>
        );
      })}
    </>
  );
};

export default Children;
