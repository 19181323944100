export const services = [
  {
    id: '1',
    name: 'Gym class',
  },
  {
    id: '2',
    name: 'Personal training',
  },
  {
    id: '3',
    name: 'Massage',
  },
  {
    id: '4',
    name: 'Boxing class',
  },
  {
    id: '5',
    name: 'Tanning',
  },
];
