import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Grid, useMediaQuery } from '@material-ui/core';

import {
  FilterTypes,
  FilterViewTypes,
  IDateRangeFilterValue,
  IFilter,
  IFilterSettings,
  IFilterValue,
  IMultipleFilterValue,
  ISingleFilterValue,
} from '../../../interfaces/filter';

import SingleFilter from '../SingleFilter/SingleFilter';
import MultipleFilter from '../MultipleFilter/MultipleFilter';
import DateRangeFilter from '../DateRangeFilter/DateRangeFilter';
import MultipleFilterWithPaginate from '../MultipleFilterWithPaginate/MultipleFilterWithPaginate';

interface FilterProps {
  settings: IFilterSettings;
  viewType: FilterViewTypes;
  value: IFilterValue;
  handleFilterChange: (newFilter: IFilter, changedFilterName?: string) => void;
}

const Filter = ({ settings, value, viewType, handleFilterChange }: FilterProps): JSX.Element => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  if (settings.hidden) return null;

  switch (settings.type) {
    case FilterTypes.SINGLE:
      return (
        <Grid item key={settings.name}>
          <SingleFilter
            viewType={viewType}
            settings={settings}
            value={value as ISingleFilterValue}
            dialog={isSmallScreen}
            onChange={handleFilterChange}
          />
        </Grid>
      );

    case FilterTypes.MULTIPLE:
      return (
        <Grid item key={settings.name}>
          <MultipleFilter
            viewType={viewType}
            settings={settings}
            value={value as IMultipleFilterValue}
            dialog={isSmallScreen}
            onChange={handleFilterChange}
          />
        </Grid>
      );
    case FilterTypes.MULTIPLE_WITH_PAGINATE:
      return (
        <Grid item key={settings.name}>
          <MultipleFilterWithPaginate
            viewType={viewType}
            settings={settings}
            value={value as IMultipleFilterValue}
            dialog={isSmallScreen}
            onChange={handleFilterChange}
          />
        </Grid>
      );
    case FilterTypes.DATE_RANGE:
      return (
        <Grid item key={settings.name}>
          <DateRangeFilter
            viewType={viewType}
            settings={settings}
            value={value as IDateRangeFilterValue}
            dialog={isSmallScreen}
            onChange={handleFilterChange}
          />
        </Grid>
      );

    default:
      return null;
  }
};

export default React.memo(Filter);
