export const actionTypes = {
  FETCH_CHECK_IN_HISTORY_STATISTICS: 'REPORTS/FETCH_CHECK_IN_HISTORY_STATISTICS',
  FETCH_CHECK_IN_HISTORY_STATISTICS_LOADING: 'REPORTS/FETCH_CHECK_IN_HISTORY_STATISTICS_LOADING',
  RESET_CHECK_IN_HISTORY_STATISTICS: 'REPORTS/RESET_CHECK_IN_HISTORY_STATISTICS',

  FETCH_CHECK_IN_HISTORY_ACTIVITY: 'REPORTS/FETCH_CHECK_IN_HISTORY_ACTIVITY',
  SET_CHECK_IN_HISTORY_CHART_DATA: 'REPORTS/SET_CHECK_IN_HISTORY_CHART_DATA',
  FETCH_CHECK_IN_HISTORY_ACTIVITY_LOADING: 'REPORTS/FETCH_CHECK_IN_HISTORY_ACTIVITY_LOADING',
  RESET_CHECK_IN_HISTORY_ACTIVITY: 'REPORTS/RESET_CHECK_IN_HISTORY_ACTIVITY',

  FETCH_CHECK_IN_HISTORY_REPORT_ITEMS: 'REPORTS/FETCH_CHECK_IN_HISTORY_REPORT_ITEMS',
  FETCH_CHECK_IN_HISTORY_REPORT_ITEMS_LOADING:
    'REPORTS/FETCH_CHECK_IN_HISTORY_REPORT_ITEMS_LOADING',
  RESET_CHECK_IN_HISTORY_REPORT_ITEMS: 'REPORTS/RESET_CHECK_IN_HISTORY_REPORT_ITEMS',
};
