export const actionTypes = {
  FETCH_RECENT_PERSON_APPOINTMENTS: 'MEMBER_PORTAL/FETCH_RECENT_PERSON_APPOINTMENTS',
  FETCH_RECENT_PERSON_APPOINTMENTS_LOADING:
    'MEMBER_PORTAL/FETCH_RECENT_PERSON_APPOINTMENTS_LOADING',
  FETCH_PERSON_APPOINTMENTS_HISTORY: 'MEMBER_PORTAL/FETCH_PERSON_APPOINTMENTS_HISTORY',
  FETCH_PERSON_APPOINTMENTS_HISTORY_LOADING:
    'MEMBER_PORTAL/FETCH_PERSON_APPOINTMENTS_HISTORY_LOADING',
  FETCH_PERSON_APPOINTMENT_DETAILS: 'MEMBER_PORTAL/FETCH_PERSON_APPOINTMENT_DETAILS',
  FETCH_PERSON_APPOINTMENT_DETAILS_LOADING:
    'MEMBER_PORTAL/FETCH_PERSON_APPOINTMENT_DETAILS_LOADING',
  RESET_PERSON_APPOINTMENT_DETAILS: 'MEMBER_PORTAL/RESET_PERSON_APPOINTMENT_DETAILS',
  RESET_APPOINTMENT_ACTION_RESULT: 'MEMBER_PORTAL/RESET_APPOINTMENT_ACTION_RESULT',
};
