import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
// icons
import { ReactComponent as ArrowLeft } from 'img/icons/arrow-back-thin.svg';
// interfaces
import { CartUnitTypes, IInvoiceDetailsImt, MenuItemTypes } from 'common/interfaces/invoices';
import { IRegisterProductItem } from 'modules/pos-settings/interfaces/register';
import { IPosKioskRegisterDataImt } from 'modules/pos-kiosk/interfaces/registers';
// state
import * as actions from 'common/state/invoice/actions';
import * as selectors from 'common/state/invoice/selectors';
import {
  selectPosKioskRegisterData,
  selectSelectedRegisterId,
} from 'modules/pos-kiosk/state/register/selectors';
// components
import SearchInputInvoice from './SearchInput';
import InvoiceProductItem from '../../InvoiceProductItem/InvoiceProductItem';
import { BreadcrumbsComponent, LoadingBackdrop } from 'common/components';
import {
  convertPosCategoryIntoMenuItems,
  convertSearchInventoryItemsIntoIntoMenuItems,
} from 'modules/pos-settings/constants/register';
import commonMessages from 'common/messages/messages';
import { ISimpleClubInventoryItem } from 'modules/pos-settings/interfaces/inventoryItems';
import { PeakModules } from 'common/constants/peakModules';
import inputLabels from 'common/messages/inputLabels';
import { useAppDispatch } from 'store/hooks';
import { useSearchInvoiceProductsState } from 'common/createContext/searchInvoiceProductsContext';

const useStyles = makeStyles((theme: Theme) => ({
  newInvoiceButton: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.h6.fontSize,
    '& .MuiSvgIcon-root': {
      width: 16,
      height: 16,
    },
  },
  wrapProductsList: {
    '& >div:first-child': {
      borderTop: `1px solid ${theme.palette.secondary.light}`,
    },
  },
  wrapCategories: {
    marginBottom: theme.spacing(2),
  },
  wrapCategoryPaper: {
    height: 50,
    cursor: 'pointer',
  },
  arrowCategory: {
    width: 24,
    height: 24,
  },
}));

interface ISearchInventoriesProps {
  module: PeakModules;
  isPaymentStep?: boolean;
}

export default function SearchInventories({
  module,
  isPaymentStep,
}: ISearchInventoriesProps): JSX.Element {
  // state
  const dispatch = useAppDispatch();

  const selectedRegisterId = useSelector(selectSelectedRegisterId);
  const register: IPosKioskRegisterDataImt = useSelector(selectPosKioskRegisterData);
  const searchInventoryData: Array<ISimpleClubInventoryItem> = useSelector(
    selectors.selectSearchInventoryData,
  )?.toJS();
  const invoice: IInvoiceDetailsImt = useSelector(selectors.selectCurrentInvoice);
  const isSearchInventoryLoading = useSelector(selectors.selectSearchInventoryLoading);

  const classes = useStyles();

  const transformedInvoice = useMemo(() => invoice?.toJS(), [invoice]);
  const transformedRegister = useMemo(() => register?.toJS(), [register]);

  // TODO Create interface after API integration services
  const searchItems: any[] = convertSearchInventoryItemsIntoIntoMenuItems(
    searchInventoryData,
    MenuItemTypes.Item,
  );

  const [isShowBreadcrumbs, toggleShowBreadcrumbs] = useState<boolean>(true);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [displayedItems, setDisplayedItems] = useState<Array<IRegisterProductItem>>([]);

  const { toggleShowAddInventories } = useSearchInvoiceProductsState();

  const items = transformedRegister?.registerPosButton
    ? convertPosCategoryIntoMenuItems(transformedRegister.registerPosButton)
    : [];

  const getItems = useCallback((v, breadcrumbsValue) => {
    if (breadcrumbsValue?.length > 0) {
      return getItems(v.subItems[breadcrumbsValue[0].index], breadcrumbsValue.slice(1));
    }
    return v;
  }, []);

  const addedProducts = useMemo(
    () =>
      transformedInvoice.invoiceUnits.reduce(
        (acc, invoiceUnit) =>
          invoiceUnit.type === CartUnitTypes.INVENTORY ? [...acc, invoiceUnit.inventory] : acc,
        [],
      ),
    [transformedInvoice],
  );

  const selectCategory = (itemIndex, product) => {
    const newBreadcrumbs = [
      ...breadcrumbs,
      { id: product.id, title: product.title, index: product.order },
    ];
    setBreadcrumbs(newBreadcrumbs);
  };

  const onSearchProductsStringChange = searchStr => {
    if (!searchStr) {
      toggleShowBreadcrumbs(true);
      dispatch(actions.resetSearchInventoryResult());
    } else {
      toggleShowBreadcrumbs(false);
      dispatch(
        actions.searchRegisterInventory(module, selectedRegisterId, isPaymentStep, searchStr),
      );
    }
  };

  const handleAddInventory = productItem => {
    toggleShowAddInventories(false);

    const existedInvoiceUnit = transformedInvoice.invoiceUnits.find(
      invoiceUnit =>
        invoiceUnit.type === CartUnitTypes.INVENTORY &&
        invoiceUnit.inventory.inventorySnapshotSourceId === productItem.inventoryId,
    );

    if (existedInvoiceUnit) {
      dispatch(
        actions.changeInvoiceUnitNumber(
          transformedInvoice.id,
          existedInvoiceUnit.id,
          existedInvoiceUnit.inventory.number + 1,
          module,
          isPaymentStep,
        ),
      );
    } else {
      dispatch(
        actions.addInvoiceUnit(
          transformedInvoice.id,
          {
            inventoryClubs: [{ ...productItem, inventoryClubId: productItem.id, number: 1 }],
            memberId: transformedInvoice.customer?.id ?? null,
          },
          CartUnitTypes.INVENTORY,
          module,
          isPaymentStep,
        ),
      );
    }
  };

  const handleDeleteInventory = (invoiceUnitId: string) => {
    dispatch(
      actions.deleteInvoiceUnit(transformedInvoice.id, invoiceUnitId, module, isPaymentStep),
    );
  };

  useEffect(() => {
    setDisplayedItems(getItems({ subItems: items }, breadcrumbs).subItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbs, register, getItems]);

  const categories = displayedItems?.filter(item => item.type === MenuItemTypes.Category) || [];

  const inventories = displayedItems?.filter(item => item.type === MenuItemTypes.Item) || [];

  const renderedCategories = categories.map((item, index) => (
    <Grid item key={item.id}>
      <Paper
        elevation={2}
        onClick={() => selectCategory(index, item)}
        className={classes.wrapCategoryPaper}
      >
        <Box p={2} height="100%" display="flex" alignItems="center" justifyContent="center">
          <Typography align="center" variant="h5">
            {item.title.toUpperCase()}
          </Typography>
        </Box>
      </Paper>
    </Grid>
  ));

  const renderedInventories = (isShowBreadcrumbs ? inventories : searchItems)?.map(productItem => {
    const currentItem = transformedInvoice?.invoiceUnits?.find(
      item =>
        item.type === CartUnitTypes.INVENTORY &&
        item.inventory.inventoryId === productItem.inventoryId,
    );

    const isAlreadyAdded = !!currentItem;

    return (
      <Grid item xs={12} key={productItem.id}>
        <InvoiceProductItem
          onAdd={() => handleAddInventory(productItem)}
          onDelete={() => handleDeleteInventory(currentItem.id)}
          product={productItem}
          isAddItems
          isAlreadyAdded={isAlreadyAdded}
          isOutOfStock={!productItem.onHandAmount}
          addedProducts={[...(addedProducts || [])]}
        />
      </Grid>
    );
  });

  const isEmptyData = !renderedInventories.length;

  return (
    <Box display="flex" flexDirection="column" height="100%" flex="1" pt={1}>
      <SearchInputInvoice
        placeholder={inputLabels.searchItems}
        callback={onSearchProductsStringChange}
      />

      {isShowBreadcrumbs && (
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ height: 35 }}>
            <BreadcrumbsComponent
              breadcrumb={breadcrumbs}
              onBreadcrumbClick={setBreadcrumbs}
              fontSize={0.875}
              hideMarginArrow
            />
          </Grid>
          <Grid item xs={12} className={classes.wrapCategories}>
            <Grid container spacing={1} wrap="wrap">
              <Grid item style={breadcrumbs.length === 0 ? { display: 'none' } : {}}>
                <Paper
                  elevation={2}
                  onClick={() => {
                    setBreadcrumbs(breadcrumbs.slice(0, -1));
                  }}
                  className={classes.wrapCategoryPaper}
                >
                  <Box
                    p={2}
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography align="center" variant="inherit" style={{ display: 'flex' }}>
                      <ArrowLeft className={classes.arrowCategory} />
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
              {renderedCategories}
            </Grid>
          </Grid>
        </Grid>
      )}
      {!isSearchInventoryLoading && isEmptyData && (
        <Box display="flex" flex="1" alignItems="center" justifyContent="center">
          <Typography variant="body1" color="textSecondary">
            <FormattedMessage {...commonMessages.noItemsFound} />
          </Typography>
        </Box>
      )}
      {isSearchInventoryLoading && <LoadingBackdrop isLoading />}
      <Grid
        container
        className={cx({
          [classes.wrapProductsList]:
            searchItems.length || (isShowBreadcrumbs && inventories.length),
        })}
      >
        {renderedInventories}
      </Grid>
    </Box>
  );
}
