import React from 'react';
import { Box } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Editor } from 'react-draft-wysiwyg';
import cx from 'classnames';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import {
  TextSizeIcon,
  BoldIcon,
  ItalicIcon,
  UnderlineIcon,
  AlignLeftIcon,
  AlignCenterIcon,
  AlignRightIcon,
  AlignJustifyIcon,
  SortedListIcon,
  ListIcon,
  IndentIcon,
  OutdentIcon,
  StrikethroughIcon,
  RemoveFormatIcon,
  Pipette,
} from 'img/editor-icons';

import ImageTool from './ImageTool';
import LinkTool from './LinkTool';
import { colorsPicker, fontSizes, styleMap } from './constants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',

    '& .draftEditorWrapper': {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflow: 'hidden',
      border: '1px solid #d6d6d6',
      borderRadius: '3px',

      '&.error': {
        border: `1px solid ${theme.palette.error.main}`,
      },
    },

    '& .draftEditorToolbar': {
      margin: '0px',
      position: 'relative',
      border: 'none',
      borderBottom: '1px solid #d6d6d6',
      borderRadius: '3px',
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px',
    },

    '& .draftEditorInner': {
      fontSize: '14px',
      backgroundColor: 'white',
    },

    '& .rdw-option-wrapper': {
      margin: '0 3px',
      width: '24px',
      height: '25px',
      border: 'none',
      flexDirection: 'column',

      '& img': {
        width: '16px',
        height: '16px',
        opacity: '0.7',
      },

      '&:hover': {
        boxShadow: 'none',
      },
    },
    '& .rdw-option-active': {
      boxShadow: 'none',
    },
    '& .rdw-dropdown-wrapper': {
      width: '40px',
      height: '24px',
      border: 'none',

      '& .rdw-dropdownoption-default': {
        height: '24px',

        '& img': {
          height: '16px',
        },
      },
      '& .rdw-dropdown-selectedtext': {
        '& img': {
          height: '16px',
        },
        '& .rdw-dropdown-carettoopen, .rdw-dropdown-carettoclose': {
          top: '45%',
          borderLeft: '3px solid transparent',
          borderRight: '3px solid transparent',
        },
        '& .rdw-dropdown-carettoopen': {
          borderTop: '4px solid black',
        },
        '& .rdw-dropdown-carettoclose': {
          borderBottom: '4px solid black',
        },
      },
    },
    '& .rdw-justify-aligned-block>div': {
      textAlign: 'justify',
    },
    '& .rdw-right-aligned-block>div': {
      textAlign: 'right',
    },
    '& .rdw-left-aligned-block>div': {
      textAlign: 'left',
    },
    '& .rdw-center-aligned-block>div': {
      textAlign: 'center',
    },
    '& .rdw-remove-wrapper, .rdw-inline-wrapper': {
      border: '1px solid #d6d6d6',
      borderBottomWidth: '0px',
      borderTopWidth: '0px',
    },
    '& .rdw-colorpicker-modal': {
      height: 'auto',
      width: 183,
      borderRadius: theme.spacing(0.5),
      '& .rdw-colorpicker-modal-options': {
        overflow: 'unset',
      },
      '& .rdw-option-wrapper': {
        boxShadow: 'none',
      },
    },
    '& .rdw-link-decorator-wrapper': {
      textDecoration: 'underline',
      color: theme.palette.info.main,
    },
  },
}));

const toolbarOptions = {
  options: ['fontSize', 'inline', 'colorPicker', 'textAlign', 'list', 'remove', 'image', 'link'],
  fontSize: {
    icon: TextSizeIcon,
    options: fontSizes,
  },
  inline: {
    inDropdown: false,
    options: ['bold', 'italic', 'underline', 'strikethrough'],
    bold: { icon: BoldIcon },
    italic: { icon: ItalicIcon },
    underline: { icon: UnderlineIcon },
    strikethrough: { icon: StrikethroughIcon },
  },
  textAlign: {
    inDropdown: true,
    options: ['left', 'center', 'right', 'justify'],
    left: { icon: AlignLeftIcon },
    center: { icon: AlignCenterIcon },
    right: { icon: AlignRightIcon },
    justify: { icon: AlignJustifyIcon },
  },
  list: {
    inDropdown: false,
    options: ['unordered', 'ordered', 'indent', 'outdent'],
    unordered: { icon: ListIcon },
    ordered: { icon: SortedListIcon },
    indent: { icon: IndentIcon },
    outdent: { icon: OutdentIcon },
  },
  remove: { icon: RemoveFormatIcon },
  colorPicker: {
    icon: Pipette,
    colors: colorsPicker,
  },
  link: {
    component: LinkTool,
  },
  image: {
    component: ImageTool,
  },
};

interface IProps {
  editorState?: any;
  contentState?: any;
  onEditorStateChange?: (state) => void;
  decorators?: Array<any>;
  toolbarConfig?: any;
  toolbarCustomButtons?: Array<any>;
  disabled?: boolean;
  hasError?: boolean;
  onBlur?: () => void;
}

const DraftEditor = (props: IProps): JSX.Element => {
  const classes = useStyles();
  const {
    editorState,
    onEditorStateChange,
    decorators = [],
    toolbarCustomButtons = [],
    toolbarConfig = {},
    disabled,
    hasError,
    onBlur,
    ...editorProps
  } = props;

  const toolbar = {
    ...toolbarOptions,
    ...toolbarConfig,
  };

  return (
    <Box className={classes.root}>
      <Editor
        className={hasError && 'error'}
        defaultEditorState={editorState}
        toolbar={toolbar}
        toolbarClassName="draftEditorToolbar"
        wrapperClassName={cx('draftEditorWrapper', { error: hasError })}
        editorClassName="draftEditorInner"
        onEditorStateChange={onEditorStateChange}
        toolbarCustomButtons={toolbarCustomButtons}
        customDecorators={decorators}
        customStyleMap={styleMap}
        handlePastedText={() => false}
        readOnly={disabled}
        {...editorProps}
        onBlur={onBlur}
      />
    </Box>
  );
};

export default React.memo(DraftEditor);
