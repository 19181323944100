import React from 'react';
import { useSelector } from 'react-redux';
import useSilentPrintHTML from 'common/hooks/useSilentPrintHTML';
import { selectToSilentPrintHTML } from 'common/state/printHTML/selectors';
import SetPrinterModal from 'common/containers/PrintHTML/SetPrinterModal';

const SilentPrintHTML = (): JSX.Element => {
  const { html } = useSelector(selectToSilentPrintHTML);

  const { setPrinter } = useSilentPrintHTML(html);

  return <SetPrinterModal onSetPrinter={setPrinter} />;
};

export default React.memo(SilentPrintHTML);
