import { defineMessages } from 'react-intl';

export default defineMessages({
  reconcileSales: {
    id: 'app.modules.pos.pos-actions.reconcileSales',
    defaultMessage: 'Reconcile Sales',
  },
  closeRegister: {
    id: 'app.modules.pos.pos-actions.closeRegister',
    defaultMessage: 'Close Register',
  },
  openRegister: {
    id: 'app.modules.pos.pos-actions.openRegister',
    defaultMessage: 'Open Register',
  },
  closedRegisterPosPanelText: {
    id: 'app.modules.posPanel.closedRegisterPosPanelText',
    defaultMessage: 'Register is currently closed. You need to open it in order to make sales ',
  },
  closedRegisterText: {
    id: 'app.modules.posPanel.closedRegisterText',
    defaultMessage: 'Register is currently closed',
  },
  youNeedToOpenRegisterText: {
    id: 'app.modules.posPanel.youNeedToOpenRegisterText',
    defaultMessage: 'You need to open it in order to make sales.',
  },
  checksTotalTitle: {
    id: 'app.modules.pos.pos-actions.checksTotalTitle',
    defaultMessage: 'Please, enter the total sum of all checks present in the drawer right now',
  },
  openDrawer: {
    id: 'app.modules.pos.pos-actions.openDrawer',
    defaultMessage: 'OPEN DRAWER',
  },
  cantCloseRegister: {
    id: 'app.modules.pos.pos-actions.cantCloseRegisterValues',
    defaultMessage:
      'To be able to close register please be sure that partially paid payments are not left. (Please void {paidInvoiceCount} invoice(s)).',
  },
  cantCloseRegisterDialogTitle: {
    id: 'app.modules.pos.pos-actions.cantCloseRegisterDialogTitle',
    defaultMessage: 'Impossible to Close Register',
  },
  registerClosedTitle: {
    id: 'app.modules.pos.pos-actions.registerClosedTitle',
    defaultMessage: 'Register Closed',
  },
  checks: {
    id: 'app.modules.pos.modals.checks',
    defaultMessage: 'CHECKS',
  },
  creditCard: {
    id: 'app.modules.pos.modals.creditCard',
    defaultMessage: 'CREDIT CARD',
  },
  enterVarianceText: {
    id: 'app.modules.pos.modals.enterVarianceText',
    defaultMessage:
      'Due to a variance between expected and actual amounts, please enter a note explaining that difference to reconcile sales.',
  },
  closeRegisterText: {
    id: 'app.modules.pos.modals.closeRegisterText',
    defaultMessage:
      'You have {emptyInvoiceCount} empty invoice(s). We will cancel them automatically. Are you sure you want to close register? You wan‘t be able to make any sales afterwards',
  },
  changeRegisterText: {
    id: 'app.modules.pos.modals.changeRegisterText',
    defaultMessage:
      'Are you sure you want to switch a register?\n' +
      '\n' +
      'Please choose items for transferring from your current invoice #{invoiceNumber}.\n' +
      'Note: If you will transfer all items empty invoice will be canceled.',
  },
  pos: {
    id: 'app.modules.pos.posText',
    defaultMessage: 'POS',
  },
  register: {
    id: 'app.modules.pos.register',
    defaultMessage: 'Register',
  },
  selectRegisterText: {
    id: 'app.modules.pos.modals.selectRegisterText',
    defaultMessage: 'Please select a Register',
  },
  invoicesCountText: {
    id: 'app.modules.pos.registerSelector.invoicesCountText',
    defaultMessage: '{invoicesCount} Open, {inProgressInvoiceCount} In progress invoices',
  },
  useOnOtherDeviceText: {
    id: 'app.modules.pos.registerSelector.useOnOtherDevice',
    defaultMessage: 'Already in use on other device',
  },
});
