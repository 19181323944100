import { createAction } from '@reduxjs/toolkit';

import { ITableParams } from 'common/interfaces/table';
import { actionTypes } from './constants';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import Services from 'services/network';
import { IFee } from '../../interfaces/fees';
import { IPaginatedData } from 'common/interfaces/pagination';
import { GeneralThunkAction } from 'common/state/interfaces';

// get list

const fetchFeesListAction = createAction<IPaginatedData<IFee>>(actionTypes.FETCH_FEES_LIST);
const fetchFeesListLoadingAction = createAction<boolean>(actionTypes.FETCH_FEES_LIST_LOADING);
export const fetchFeesList = (requestOptions?: ITableParams): GeneralThunkAction => {
  return async dispatch => {
    try {
      dispatch(fetchFeesListLoadingAction(true));

      const result = await Services.Fees.getFeesList(requestOptions);

      dispatch(fetchFeesListAction(result));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(fetchFeesListLoadingAction(false));
    }
  };
};
export const resetFeesList = createAction(actionTypes.RESET_FEES_LIST);

// get details

const fetchFeesDetailsAction = createAction<IFee>(actionTypes.FETCH_FEE_DETAILS);
const fetchFeesDetailsLoadingAction = createAction<boolean>(actionTypes.FETCH_FEE_DETAILS_LOADING);
export const fetchFeesDetails = (id: string): GeneralThunkAction => {
  return async dispatch => {
    try {
      dispatch(fetchFeesDetailsLoadingAction(true));

      const result = await Services.Fees.getFeeDetails(id);

      dispatch(fetchFeesDetailsAction(result));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(fetchFeesDetailsLoadingAction(false));
    }
  };
};
export const resetFeesDetails = createAction(actionTypes.RESET_FEE_DETAILS);

// create fee

const createFeeAction = createAction<IFee>(actionTypes.CREATE_FEE_RESULT);
const createFeeLoadingAction = createAction<boolean>(actionTypes.CREATE_FEE_LOADING);
export const createFee = (newFee: IFee): GeneralThunkAction => {
  return async dispatch => {
    try {
      dispatch(createFeeLoadingAction(true));

      const result = await Services.Fees.createFee(newFee);

      dispatch(createFeeAction(result));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(createFeeLoadingAction(false));
    }
  };
};
export const resetCreateFee = createAction(actionTypes.RESET_CREATE_FEE);

// update fee

const updateFeeAction = createAction<IFee>(actionTypes.UPDATE_FEE_RESULT);
const updateFeeLoadingAction = createAction<boolean>(actionTypes.UPDATE_FEE_LOADING);
export const updateFee = (id: string, updatedData: IFee): GeneralThunkAction => {
  return async dispatch => {
    try {
      dispatch(updateFeeLoadingAction(true));

      const result = await Services.Fees.updateFee(id, updatedData);

      dispatch(updateFeeAction(result));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(updateFeeLoadingAction(false));
    }
  };
};
export const resetUpdateFee = createAction(actionTypes.RESET_UPDATE_FEE);

// change status

const changeFeeStatusAction = createAction<IFee>(actionTypes.CHANGE_FEE_STATUS_RESULT);
const changeFeeStatusLoadingAction = createAction<boolean>(actionTypes.CHANGE_FEE_STATUS_LOADING);
export const changeFeeStatus = (id: string, active: boolean): GeneralThunkAction => {
  return async dispatch => {
    try {
      dispatch(changeFeeStatusLoadingAction(true));

      const result = await Services.Fees.changeFeeStatus(id, active);

      dispatch(changeFeeStatusAction(result));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(changeFeeStatusLoadingAction(false));
    }
  };
};
export const resetChangeFeeStatus = createAction(actionTypes.RESET_CHANGE_FEE_STATUS);
