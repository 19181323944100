import React from 'react';

import { Status } from 'common/components';
import { IConstOption } from 'common/constants/classes';
import { LeadStatuses } from 'modules/crm/constants/leads';

interface IProps {
  status: string;
}

const LeadStatus = (props: IProps): JSX.Element => {
  const { status } = props;
  const leadStatus: IConstOption = LeadStatuses.find(status);

  return <Status color={leadStatus.color} label={LeadStatuses.translate(status)} />;
};

export default LeadStatus;
