import { http, afterResolve } from 'helpers';
import { fromJS } from 'immutable';

export const STATE_KEY = 'activeClient';

export const get = (id: any, include: any) => (
  dispatch: any,
  getState: any
) => {
  return http()
    .get(`clients/${id}`, {
      params: { include }
    })
    .then(body => afterResolve(body))
    .then((response: any) => {
      return response;
    })
    .catch((error: Error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    });
};

export const patch = (id: any, data: any) => (
  dispatch: any,
  getState: any,
  { serialize }: any
) => {
  const request = serialize('client', data, id);
  return http()
    .patch(`clients/${id}`, request)
    .then(body => afterResolve(body))
    .then((response: any) => {
      return response.meta;
    });
};

export const updateClient = (client: any) => {
  return {
    type: 'UPDATE_CLIENT',
    payload: client
  };
};

export const clearClient = () => {
  window.localStorage.removeItem('activeClient');
  return {
    type: 'CLEAR_CLIENT'
  };
};

const initialState = fromJS(null);

export default function reducer(state: any = initialState, action: any) {
  switch (action.type) {
    case 'UPDATE_CLIENT':
      const clientObj = {
        clientName: action.payload.client_name,
        id: action.payload.id,
        timezone: action.payload.timeZone,
        color: action.payload.color,
        logo: action.payload.logo
      };
      return state === null ? fromJS(clientObj) : state.mergeDeep(clientObj);
    case 'CLEAR_CLIENT':
      window.localStorage.removeItem('client');
      return initialState;
    default:
      return state;
  }
}
