import moment from 'moment-timezone';

const sortRegisters = (registers, statistics) => {
  const registersWithOrder = registers.map(el => {
    const order = statistics.toJS().find(stat => stat.register.id === el.register.id)?.order;

    return { id: el.register.id, order, name: el.register.title, ...el.statistics };
  });

  return registersWithOrder.sort((a, b) => a.order - b.order);
};

export const prepareChartData = (data, stats) => {
  const modifiedData = Object.entries(data.activity)
    .sort((a, b) => moment(a as any, 'YYYY-MM-DD').diff(moment(b as any, 'YYYY-MM-DD'), 'days'))
    .map(item => {
      const date = item[0];
      const registersFromData = item[1];

      const registers = sortRegisters(registersFromData, stats);

      return { date, registers };
    });

  return modifiedData;
};
