import React, { FC, memo } from 'react';
import uniq from 'lodash/uniq';
import {
  FRONT_DESK_IDS,
  HEADER_IDS,
  SIDEBAR_IDS,
  PROFILE_ACTION_IDS,
} from 'common/constants/discardChanges';
import useInitial from 'common/hooks/useInitial';
import DiscardChangesModal, { IDiscardChangesModal } from './DiscardChangesModal';

export interface IDiscardChangesModalProvider extends Partial<IDiscardChangesModal> {
  headerIds?: boolean;
  sidebarIds?: boolean;
  frontDeskIds?: boolean;
  profileActionIds?: boolean;
}

const DiscardChangesModalProvider: FC<IDiscardChangesModalProvider> = ({
  headerIds,
  sidebarIds,
  frontDeskIds,
  profileActionIds,
  targetIds: modalTargetIds,
  onSubmit,
  onClose,
}) => {
  const targetIds = useInitial((): string[] => {
    const ids: string[] = [];

    if (headerIds) {
      ids.push(...HEADER_IDS);
    }

    if (sidebarIds) {
      ids.push(...SIDEBAR_IDS);
    }

    if (frontDeskIds) {
      ids.push(...FRONT_DESK_IDS);
    }

    if (profileActionIds) {
      ids.push(...PROFILE_ACTION_IDS);
    }

    if (modalTargetIds) {
      ids.push(...modalTargetIds);
    }

    return uniq<string>(ids);
  });

  return <DiscardChangesModal targetIds={targetIds} onSubmit={onSubmit} onClose={onClose} />;
};

export default memo(DiscardChangesModalProvider);
