import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { List as ImmutableList } from 'immutable';
import { Box, Button, Divider, makeStyles, Typography } from '@material-ui/core';

import { CheckInBlock } from 'common/components/PersonProfile/components';
import { IProfileInfoImt, IReferralCreateDto } from 'common/components/PersonProfile/interfaces';
import messages from 'common/components/PersonProfile/messages';
import * as actions from 'common/components/PersonProfile/state/referrals/actions';
import * as selectors from 'common/components/PersonProfile/state/referrals/selectors';
import { ActionResult } from 'common/constants';
import { PeakModules } from 'common/constants/peakModules';
import { useAppDispatch } from 'store/hooks';
import ReferralSectionActions from './ReferralSectionActions/ReferralSectionActions';
import ReferralCreateModal from './ReferralCreateModal/ReferralCreateModal.';
import { selectReferralData } from '../../state/referrals/selectors';
import { usePersonSelectorTemplate } from '../../hooks/usePersonSelector';
import { IReferralListInfoImt } from 'common/interfaces/referrals';
import ReferralListItem from './ReferralListItem/ReferralListItem';
import {
  resetPersonsFound,
  searchReferralMembers,
} from 'common/state/newPerson/primaryInfo/actions';
import PotentialReferralsModal from './PotentialReferralsModal/PotentialReferralsModal';
import {
  selectPersonsFoundList,
  selectPersonsFoundListLoading,
} from 'common/state/newPerson/primaryInfo/selectors';
import { ILeadProfile } from 'modules/crm/interfaces/leads';
import { INamedEntityImt } from 'common/interfaces/common';
import AvatarWithInitials from 'common/components/AvatarWithInitials/AvatarWithInitials';
import { getInitials } from 'helpers/common';

interface IReferralSectionProps {
  module: PeakModules;
  profile: IProfileInfoImt;
}

const useStyles = makeStyles(() => ({
  referralTitle: {
    fontWeight: 700,
  },
}));

const ReferralSection = (props: IReferralSectionProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { module, profile } = props;
  const profileId = profile.get('id');

  const usePersonSelector = usePersonSelectorTemplate(profileId);

  const referrals: ImmutableList<IReferralListInfoImt> = usePersonSelector(selectReferralData);
  const referredBy = usePersonSelector(selectors.selectReferredBy)?.toJS();
  const potentialReferrals: ImmutableList<ILeadProfile | INamedEntityImt> = useSelector(
    selectPersonsFoundList,
  );
  const isPotentialReferralsLoading: boolean = useSelector(selectPersonsFoundListLoading);
  const addReferralResult = useSelector(selectors.selectAddPersonReferralActionResult(profileId));
  const addReferralLoading = useSelector(selectors.selectAddPersonReferralLoading(profileId));
  const setReferralResult = useSelector(selectors.selectSetReferredByActionResult(profileId));

  const [referralsViewModal, setReferralsViewModal] = useState(false);
  const [referralCreateModalOpen, setReferralCreateModalOpen] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    dispatch(actions.fetchPersonReferrals(profileId));
  }, [dispatch, profileId]);

  useEffect(() => {
    if (setReferralResult === ActionResult.SUCCESS_ACTION) {
      dispatch(actions.setReferralAction(null, profileId));
      setReferralsViewModal(false);
      dispatch(actions.fetchPersonReferrals(profileId));
    }
  }, [setReferralResult, dispatch, profileId, setReferralsViewModal]);

  useEffect(() => {
    if (addReferralResult === ActionResult.SUCCESS_ACTION) {
      dispatch(actions.addPersonReferralAction(null, profileId));
      setReferralCreateModalOpen(false);
      dispatch(actions.fetchPersonReferrals(profileId));
    }
  }, [addReferralResult, dispatch, profileId, setReferralCreateModalOpen]);

  useEffect(() => {
    return () => {
      dispatch(actions.resetPersonReferrals(null, profileId));
      dispatch(actions.setPersonReferralsErrorAction(null, profileId));
    };
  }, [dispatch, profileId]);

  const getPotentialReferralsByValue = useCallback(
    (searchString: string) => {
      dispatch(searchReferralMembers(searchString));
    },
    [dispatch],
  );

  const clearSearchResults = useCallback(() => {
    dispatch(resetPersonsFound());
  }, [dispatch]);

  const onAddNewClick = () => {
    setReferralCreateModalOpen(true);
  };

  const onSendReferral = (value: string) => {
    dispatch(actions.setReferral(profileId, { referredBy: value }));
  };

  const onCreateReferral = (dto: IReferralCreateDto) => {
    dispatch(actions.addPersonReferral(profileId, dto));
  };

  return (
    <CheckInBlock
      title={<FormattedMessage {...messages.referralsBlockTitle} />}
      buttonGroup={<ReferralSectionActions onAddNewClick={onAddNewClick} />}
    >
      <Box>
        <Box>
          <Typography variant="caption" color="textSecondary" className={classes.referralTitle}>
            <FormattedMessage {...messages.referralCameFrom} />
          </Typography>
        </Box>
        {referredBy ? (
          <Box display="flex" alignItems="center" py={1}>
            <Box marginRight={1} display="flex">
              <AvatarWithInitials
                initials={getInitials(
                  referredBy.title.split(/\s(.*)/)[0],
                  referredBy.title.split(/\s(.*)/)[1],
                )}
                imageUrl={referredBy.imageUrl}
                width="35px"
                height="35px"
                marginRight={0}
              />
            </Box>

            <Typography className={classes.referralTitle} component="span">
              {referredBy.title}
            </Typography>
          </Box>
        ) : (
          <Box mt={1} mb={2}>
            <Button onClick={() => setReferralsViewModal(true)} color="primary" fullWidth>
              <FormattedMessage {...messages.selectReferral} />
            </Button>
          </Box>
        )}
        <Divider />

        <Box mt={1}>
          <Typography variant="caption" color="textSecondary" className={classes.referralTitle}>
            <FormattedMessage {...messages.sentStatus} />
          </Typography>
        </Box>
        {referrals?.size && referrals.filter(item => !item.get('accepted')).size ? (
          <Box>
            {referrals
              .filter(item => !item.get('accepted'))
              .map(item => {
                return (
                  <React.Fragment key={item.get('id')}>
                    <ReferralListItem item={item} />
                    <Divider />
                  </React.Fragment>
                );
              })}
          </Box>
        ) : (
          <Box className="empty-section-placeholder" p={2}>
            <Typography className="empty-text">
              <FormattedMessage {...messages.emptyReferralsListMessage} />
            </Typography>
          </Box>
        )}
      </Box>
      <Box>
        <Box mt={1}>
          <Typography variant="caption" color="textSecondary" className={classes.referralTitle}>
            <FormattedMessage {...messages.acceptedStatus} />
          </Typography>
        </Box>
        {referrals?.size && referrals.filter(item => item.get('accepted')).size ? (
          <Box>
            {referrals
              .filter(item => item.get('accepted'))
              .map(item => {
                return (
                  <React.Fragment key={item.get('id')}>
                    <ReferralListItem item={item} />
                    <Divider />
                  </React.Fragment>
                );
              })}
          </Box>
        ) : (
          <Box className="empty-section-placeholder" p={2}>
            <Typography className="empty-text">
              <FormattedMessage {...messages.emptyReferralsListMessage} />
            </Typography>
          </Box>
        )}
      </Box>
      {referralsViewModal && (
        <PotentialReferralsModal
          isOpen
          isLoading={isPotentialReferralsLoading}
          referrals={potentialReferrals as ImmutableList<INamedEntityImt>}
          getReferralByValue={getPotentialReferralsByValue}
          clearSearchResults={clearSearchResults}
          onSendReferral={onSendReferral}
          onClose={() => {
            setReferralsViewModal(false);
          }}
        />
      )}
      {referralCreateModalOpen && (
        <ReferralCreateModal
          onCreateReferral={onCreateReferral}
          module={module}
          isLoading={addReferralLoading}
          isOpen
          onClose={() => {
            setReferralCreateModalOpen(false);
          }}
        />
      )}
    </CheckInBlock>
  );
};

export default ReferralSection;
