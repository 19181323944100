// TODO: move styles, use material breakpoints
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CustomTheme } from 'common/ui/interfaces';
import { GrapesCustomTypes } from './constants';

export const useEmailEditorStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .gjs-editor': {
        flex: 1,
        display: 'flex',
        flexDirection: 'row-reverse',

        '@media (max-width:780px)': {
          flexDirection: 'column-reverse',
        },

        '& .gjs-pn-panels': {
          flex: 1,
          marginRight: theme.spacing(2),

          '@media (max-width:780px)': {
            flex: 0,
            marginRight: '0 !important',
          },
        },

        '& .gjs-cv-canvas': {
          flex: 1,
          position: 'relative',
          top: 0,
          height: '100%',
          minHeight: '450px',
          width: '100%',

          '@media (max-width:780px)': {
            height: 'auto',
          },

          '& .gjs-cv-canvas__frames': {
            height: '100%',

            '& .gjs-frame': {
              position: 'static',

              '& .gjs-dashed': {
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
              },
            },
          },
        },
      },

      '& .gjs-pn-commands, .gjs-pn-options, .gjs-pn-views, .gjs-pn-buttons, .gjs-pn-devices-c, .gjs-sm-header': {
        display: 'none !important',
      },

      '& .gjs-pn-views-container': {
        width: '100%',
        padding: 0,
        position: 'static',
        overflow: 'visible',
        boxShadow: 'none',
      },

      '& .gjs-blocks-c': {
        marginRight: '-12px',

        '@media (max-width:780px)': {
          marginRight: 0,
          flexWrap: 'nowrap',
          overflowX: 'auto',

          '&::-webkit-scrollbar': {
            display: 'none',
          },
        },
      },

      '& .gjs-block': {
        flex: 1,
        flexBasis: '30%',
        height: '90px',
        margin: '0 12px 12px 0',
        justifyContent: 'center',
        borderRadius: '3px',
        border: 'none',
        boxShadow: 'none',
        backgroundColor: '#f5f5f5 !important',

        '@media (max-width:780px)': {
          maxHeight: '50px !important',
          minHeight: '50px',
          margin: '0 2px 12px 0',
          '&:last-child': {
            marginRight: 0,
          },
        },

        '&::before': {
          display: 'none',
        },

        '& .gjs-block-label': {
          fontFamily: 'Roboto',
          fontSize: '12px',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          color: theme.palette.text.primary,
        },
        '& .gjs-block-title': {
          fontFamily: 'Roboto',
          fontSize: '12px',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          color: theme.palette.text.primary,

          '@media (max-width:780px)': {
            display: 'none',
          },
        },
        '& .gjs-block-icon': {
          width: '18px',
          height: '18px',
          marginBottom: '10px',
          '@media (max-width:780px)': {
            marginBottom: 0,
          },
        },
      },

      '& .gjs-am-file-uploader > form': {
        background: 'none',
        borderColor: theme.palette.primary.main,
      },
      '& .gjs-am-assets-cont': {
        background: 'none',
      },
      '& .gjs-field': {
        backgroundColor: '#f5f5f5',
      },

      '& .gjs-one-bg': {
        backgroundColor: '#ffffff',
      },
      '& .gjs-two-color': {
        color: theme.palette.text.primary,
      },
      '& .gjs-toolbar': {
        backgroundColor: theme.palette.primary.main,
      },
      '& .gjs-comp-selected, .gjs-highlighter': {
        outlineColor: theme.palette.primary.main,
      },
      '& .gjs-resizer-h': {
        border: `3px solid ${theme.palette.primary.main} !important`,
      },
      '& .gjs-four-color-h:hover': {
        color: 'inherit',
      },
      '& .gjs-rte-toolbar': {
        marginRight: '140px',
      },
      '& .cke_top': {
        padding: '2px',
        border: 'none',
      },
      '& .cke_combo_text': {
        width: 'auto',
        paddingLeft: '5px',
      },
      '& .cke_combopanel__customer': { maxWidth: 130, height: 260 },
      '& .cke_combopanel__club': { maxWidth: 130, height: 53 },
    },
  }),
);

export const useEmailEditorIframeStyles = (theme: CustomTheme): string =>
  `
    .employees {
      padding: ${theme.spacing(1)}px;
      min-height: 60px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .employee-item {
      margin: ${theme.spacing(1)}px;
      padding: ${theme.spacing(1.5)}px;
      flex: 1;
      flex-basis: 45%;
      display: flex;
      flex-direction: row;
      align-items: center;
      border: solid 1px #d6d6d6;
      border-radius: ${theme.spacing(0.5)}px;
    }
    .employees-photo {
      margin-right: ${theme.spacing(1.5)}px;
      width: 66px;
      height: 66px;
      background-color: lightgrey;
      border-radius: ${theme.spacing(0.35)}px;
    }
    .employees-name {
      margin-bottom: 5px;
      font-size: 14px;
      font-weight: bold;
    }
    .employees-price {
      font-size: 13px;
      color: #323232;
      opacity: 0.7;
    }
    body {
      font-family: 'Roboto','Helvetica', 'Arial', sans-serif;
      font-size: 14px;
    }
    .gjs-selected {
      outline: 2px solid ${theme.palette.primary.main} !important;
    }
    .gjs-resizer-h {
      border: 3px solid ${theme.palette.primary.main} !important;
    }

    [data-gjs-type="${GrapesCustomTypes.EmbeddedIframe}"] {
      min-height: 100px;
    }
  `;
