// libraries
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { List as ImmutableList } from 'immutable';
import { FormattedMessage } from 'react-intl';
import { Box, makeStyles } from '@material-ui/core';
// interfaces
import { IHeadCell, ITableParams, ITableRow } from 'common/interfaces/table';
import { IPageMetaImt } from 'common/interfaces/pagination';
import { FilterTypes, IFilterSettings } from 'common/interfaces/filter';
import { ICRMTemplateImt } from 'modules/crm/interfaces/crmTemplates';
// hooks
import { useAppDispatch } from 'store/hooks';
// constants
import {
  ActionResult,
  ActiveInactiveStatus,
  QueryPageList,
  TableOrderByParams,
} from 'common/constants';
import { PeakModules } from 'common/constants/peakModules';
import { PtCrmRoutes } from 'modules/personal-training-crm/routes';
import { CRMTemplatesRoutes } from 'modules/crm/routes';
import { crmTemplateRoutes, ptCrmTemplateRoutes } from 'modules/crm/constants/routes';
import { TemplatePurpose, TemplateTypes } from 'common/constants/documentTemplate';
// components
import {
  ActionItem,
  ActionsMenu,
  ModuleSidebar,
  ScrollBox,
  StatusLabel,
  Table,
} from 'common/components';
import { ServicesPageWrapper } from 'modules/services/components';
import ChangeActiveStatusModal from 'common/modals/ChangeActiveStatusModal/ChangeActiveStatusModal';
// icons
import { ReactComponent as XDeleteIcon } from 'img/icons/times.svg';
import { ReactComponent as CheckIcon } from 'img/icons/check.svg';
import { ReactComponent as EditIcon } from 'img/icons/edit.svg';

// utils
import { updateQueryParams } from 'common/state/queryPage-lists/actions';
import { makeTableParams, pushQueryToUrl } from 'common/utils/http';
// state
import {
  selectCRMTemplatesList,
  selectCRMTemplatesListMeta,
  selectCRMTemplatesLoading,
  selectUpdateCrmTemplateStatusActionResult,
  selectUpdateCrmTemplateStatusIsLoading,
} from 'modules/crm/state/crmTemplates/selectors';
import {
  fetchCRMTemplates,
  resetCRMTemplates,
  updateDocumentTemplateStatus,
  updateCrmTemplateStatusActionResult,
} from 'modules/crm/state/crmTemplates/actions';
// messages
import tableHeaders from 'common/messages/tableHeaders';
import commonMessages from 'common/messages/messages';
import menuItems from 'common/messages/menuItems';
import messages from 'modules/crm/messages/common';
import tableFiltersMessages from 'common/messages/tableFilters';
import ptCrmMessages from 'modules/personal-training-crm/messages/messages';

const tableFilters: IFilterSettings[] = [
  {
    name: 'active',
    title: <FormattedMessage {...tableFiltersMessages.status} />,
    type: FilterTypes.SINGLE,
    defaultValue: ActiveInactiveStatus[0],
    options: ActiveInactiveStatus.map(status => ({
      key: status.key,
      label: status.label,
      value: status.value,
    })),
  },
];

const headerOptions: IHeadCell[] = [
  {
    id: 'title',
    label: <FormattedMessage {...tableHeaders.name} />,
    sort: true,
    padding: 'none',
  },
  { id: 'status', label: <FormattedMessage {...tableHeaders.status} />, sort: true },
  {
    id: 'campaignStatus',
    label: <FormattedMessage {...tableHeaders.campaignStatus} />,
    sort: false,
  },
  {
    id: 'actions',
    label: <FormattedMessage {...tableHeaders.actions} />,
    sort: false,
    padding: 'none',
    align: 'center',
  },
];

const useStyles = makeStyles({
  icon: {
    width: 16,
    height: 16,
  },
});

const CRMTemplates = (): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const templatesList: ImmutableList<ICRMTemplateImt> = useSelector(selectCRMTemplatesList);
  const templatesListMeta: IPageMetaImt = useSelector(selectCRMTemplatesListMeta);
  const isTemplatesListLoading: boolean = useSelector(selectCRMTemplatesLoading);
  const isLoadingUpdateStatus: boolean = useSelector(selectUpdateCrmTemplateStatusIsLoading);
  const documentStatusActionResult: ActionResult = useSelector(
    selectUpdateCrmTemplateStatusActionResult,
  );

  const location = useLocation();
  const navigate = useNavigate();
  const module =
    location.pathname.indexOf('personal-training-crm') === -1
      ? PeakModules.Crm
      : PeakModules.PersonalTrainingCrm;
  const isCrmModule = module === PeakModules.Crm;

  const [tableParams, setTableParams] = useState(() =>
    makeTableParams(tableFilters, location.search, {
      orderBy: TableOrderByParams.TITLE,
    }),
  );
  const [documentTemplate, setDocumentTemplate] = useState<ICRMTemplateImt>(null);

  const path = location.pathname;
  const backPath = `/${isCrmModule ? CRMTemplatesRoutes.CRM : PtCrmRoutes.PT_CRM}`;
  const redirectPath = `${path}/new`;
  const templateType = path.includes('/sms') ? TemplateTypes.SMS : TemplateTypes.Email;

  const classes = useStyles();

  // lifecycle

  useEffect(() => {
    dispatch(
      fetchCRMTemplates(templateType, TemplatePurpose.CampaignTemplate, tableParams, module),
    );
  }, [dispatch, module, tableParams, templateType]);

  useEffect(() => {
    if (documentStatusActionResult === ActionResult.SUCCESS_ACTION && documentTemplate) {
      setDocumentTemplate(null);
      dispatch(updateCrmTemplateStatusActionResult(null));
    }
  }, [dispatch, documentStatusActionResult, documentTemplate]);

  useEffect(() => {
    return () => {
      dispatch(resetCRMTemplates());
    };
  }, [dispatch]);

  // handlers

  const updateQueryFunction = useCallback(
    query => dispatch(updateQueryParams({ page: QueryPageList.CRM_TEMPLATES, query })),
    [dispatch],
  );

  const handleChangeTableProps = useCallback(
    (tableProps: ITableParams): void => {
      setTableParams(tableProps);
      pushQueryToUrl(navigate, tableProps, updateQueryFunction);
    },
    [navigate, updateQueryFunction],
  );

  const onCloseModal = useCallback(() => {
    setDocumentTemplate(null);
  }, []);

  const applyChangedStatus = useCallback(() => {
    dispatch(
      updateDocumentTemplateStatus(
        {
          id: documentTemplate.get('id'),
          active: !documentTemplate.get('active'),
        },
        module,
      ),
    );
  }, [dispatch, documentTemplate, module]);

  // rows

  const tableRows = useMemo(
    () =>
      templatesList
        .map(
          (template: ICRMTemplateImt): ITableRow => ({
            id: template.get('id'),
            cells: [
              {
                label: template.get('title'),
                width: '33%',
                variant: 'h5',
              },
              {
                label: 'status',
                width: '33%',
                cellComponent: <StatusLabel isActive={template.get('active')} />,
              },
              {
                label: 'campaignStatus',
                width: '33%',
                cellComponent: (
                  <StatusLabel
                    isActive={template.get('campaignStatus')}
                    activeOptionMessage={commonMessages.inUseOption}
                    inactiveOptionMessage={commonMessages.notInUse}
                  />
                ),
              },
              {
                label: 'actions',
                padding: 'none',
                align: 'center',
                width: '54px',
                cellComponent: (
                  <ActionsMenu horizontal tableActionsMode>
                    <ActionItem
                      key="1"
                      icon={<EditIcon className={classes.icon} />}
                      onClick={() => {
                        navigate(`${path}/${template.get('id')}`);
                      }}
                    >
                      <FormattedMessage {...menuItems.edit} />
                    </ActionItem>
                    <ActionItem
                      key="2"
                      icon={
                        template.get('active') ? (
                          <XDeleteIcon className={classes.icon} />
                        ) : (
                          <CheckIcon className={classes.icon} />
                        )
                      }
                      onClick={() => setDocumentTemplate(template)}
                    >
                      <FormattedMessage
                        {...(template.get('active') ? menuItems.deactivate : menuItems.activate)}
                      />
                    </ActionItem>
                  </ActionsMenu>
                ),
              },
            ],
          }),
        )
        .toJS(),
    [classes.icon, navigate, path, templatesList],
  );

  return (
    <>
      <ServicesPageWrapper
        backRedirectLink={backPath}
        titleMessage={isCrmModule ? messages.templates : ptCrmMessages.ptDocumentTemplates}
      >
        <Box display="flex">
          <Box width="190px" paddingRight="16px">
            <ModuleSidebar routes={isCrmModule ? crmTemplateRoutes : ptCrmTemplateRoutes} />
          </Box>
          <ScrollBox suppressScrollY>
            <Box minWidth="500px" height="100%" overflow="hidden">
              <Table
                addButtonRedirect={redirectPath}
                suppressSmartBehavior
                suppressFiltersMobilePadding
                filters={tableFilters}
                headerOptions={headerOptions}
                isLoading={isTemplatesListLoading}
                showPerPageSelect
                rows={tableRows}
                totalRows={templatesListMeta.get('total') || 0}
                onChangeParams={handleChangeTableProps}
                tableParams={tableParams}
              />
            </Box>
          </ScrollBox>
        </Box>
      </ServicesPageWrapper>

      {documentTemplate && (
        <ChangeActiveStatusModal
          isOpen
          isActive={documentTemplate.get('active')}
          isLoading={isLoadingUpdateStatus}
          title={documentTemplate.get('title')}
          onClose={onCloseModal}
          onSubmit={applyChangedStatus}
        />
      )}
    </>
  );
};

export default CRMTemplates;
