/* eslint @typescript-eslint/no-unused-vars: 0 */
import React, { PureComponent } from 'react';
import { fromJS } from 'immutable';
import { createStyles, Grid, Theme, withStyles } from '@material-ui/core';
// state
import {
  changeLeadStatus,
  deleteLeadAvatar,
  fetchLeadProfile,
  fetchLeadProfileSuccessAction,
  resetLeadProfile,
  updateLeadAvatar,
} from 'modules/crm/state/leads/actions';
import { setMainPanelPersonAction } from 'modules/front-desk/state/actions';

import { selectPersonFallthroughCaptureForClass } from 'common/components/PersonProfile/state/selectors';
import * as selectors from 'modules/crm/state/leads/selectors';
// interfaces
import { DispatchFunction, ImmutableObject } from 'common/state/interfaces';
import { IStoredFileSimpleDto } from 'common/interfaces/uploadFile';
import { IFallthroughCaptureImt } from 'common/components/PersonProfile/interfaces';
// components
import { Connect } from 'common/decorators';
import PersonProfile, { IPersonProfileProps } from 'common/components/PersonProfile/PersonProfile';
import {
  CheckInInfo,
  DocumentsSection,
  FallthroughBlock,
  MembershipBlock,
  ProfileGeneralInfo,
  ServicesSection,
} from 'common/components/PersonProfile/components';
import { BlocksSection, EditLead } from 'modules/crm/containers';
import { PeakModules } from 'common/constants/peakModules';
import { LoadingBackdrop } from 'common/components';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';

const styles = (theme: Theme) =>
  createStyles({
    section: {
      padding: theme.spacing(1),
    },
    checkIcon: {
      width: '16px',
      height: '16px',
      marginRight: '6px',
      color: theme.palette.primary.main,
    },
    statusText: {
      fontWeight: 500,
    },
    btn: {
      marginBottom: '12px',
      backgroundColor: 'white',
    },
  });

interface ILeadsState {
  isOpenEditProfile: boolean;
}

interface IProps extends IPersonProfileProps {
  fetchLeadProfileAction?: (
    personId: number | string,
    module: PeakModules,
    isEditLead?: boolean,
  ) => void;
  changeLeadSalesStatusAction?: (
    leadId: string,
    status: string,
    module: PeakModuleForNewPersonType,
  ) => void;
  changeLeadProfileStatus?: (profile: any) => void;
  updateLeadAvatarAction?: (
    leadId: string,
    image: IStoredFileSimpleDto,
    module: PeakModuleForNewPersonType,
  ) => void;
  deleteLeadAvatarAction?: (leadId: string, module: PeakModuleForNewPersonType) => void;
  setMainPanelPerson?: (personId: string) => void;
  fallthroughCapture?: IFallthroughCaptureImt; // TODO recheck after store changed
  resetProfile?: () => void;
}

@(Connect({
  mapStateToProps: () => ({
    profileLoading: selectors.selectLeadLoading,
    profile: selectors.selectLeadForm,
    fallthroughCapture: selectPersonFallthroughCaptureForClass(),
  }),
  mapDispatchToProps: (dispatch: DispatchFunction) => ({
    fetchLeadProfileAction: (personId: string, module: PeakModules, isEditLead?: boolean) =>
      dispatch(fetchLeadProfile(personId, module, isEditLead)),
    changeLeadSalesStatusAction: (
      leadId: string,
      status: string,
      module: PeakModuleForNewPersonType,
    ) => dispatch(changeLeadStatus(leadId, status, module)),
    changeLeadProfileStatus: (profile: any) => dispatch(fetchLeadProfileSuccessAction(profile)),
    updateLeadAvatarAction: (
      leadId: string,
      image: IStoredFileSimpleDto,
      module: PeakModuleForNewPersonType,
    ) => {
      dispatch(updateLeadAvatar(leadId, image, module));
    },
    deleteLeadAvatarAction: (leadId: string, module: PeakModuleForNewPersonType) =>
      dispatch(deleteLeadAvatar(leadId, module)),
    setMainPanelPerson: (personId: string) => dispatch(setMainPanelPersonAction(personId)),
    resetProfile: () => dispatch(resetLeadProfile()),
  }),
}) as any)
@(withStyles(styles) as any)
class LeadProfile extends PureComponent<IProps, ILeadsState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isOpenEditProfile: false,
    };
  }

  handleCloseEditModal = () => {
    this.setState({ isOpenEditProfile: false });
    const { personId, setMainPanelPerson } = this.props;
    setMainPanelPerson(personId);
  };

  handleOpenEditModal = () => {
    this.setState({ isOpenEditProfile: true });
  };

  componentDidMount(): void {
    const { personId, fetchLeadProfileAction, setMainPanelPerson, module } = this.props;

    setMainPanelPerson(personId);
    fetchLeadProfileAction(personId, module);
  }

  componentWillUnmount(): void {
    const { setMainPanelPerson, resetProfile } = this.props;
    // TODO recheck after store will be changed
    setMainPanelPerson(null);
    resetProfile();
  }

  onChangeLeadStatus = (newStatus: string): void => {
    const { personId, changeLeadSalesStatusAction, module } = this.props;
    changeLeadSalesStatusAction(personId, newStatus, module);
  };

  renderServicesSection = ({ profile }): JSX.Element => {
    const { clientId, fallthroughCapture, module } = this.props;

    const membershipPackageExists = !!profile.get('membership');
    const fallthroughCaptureExists = !!fallthroughCapture;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CheckInInfo homeClub={profile.get('homeClub')} barcode={profile.get('barcode')} />
        </Grid>

        {/* Hide MembershipBlock if Fallthrough capture exists   */}
        {!fallthroughCaptureExists && (
          <Grid item xs={12}>
            <MembershipBlock profile={profile} module={module} />
          </Grid>
        )}

        {/* Hide FallthroughBlock if Membership package exists */}
        {!membershipPackageExists && (
          <Grid item xs={12}>
            <FallthroughBlock
              module={module}
              clientId={clientId}
              profileId={profile.get('id')}
              fallthroughCapture={fallthroughCapture}
            />
          </Grid>
        )}

        {membershipPackageExists && (
          <Grid item xs={12}>
            <ServicesSection
              readOnly
              module={module}
              profileId={profile.get('id')}
              profileFirstName={profile.get('firstName')}
              profileLastName={profile.get('lastName')}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <DocumentsSection profileId={profile.get('id')} module={module} />
        </Grid>
      </Grid>
    );
  };

  // TODO: currently we support this map profile, just because front-desk is not yet integrated with backend.
  //  Remove after Front-desk integration completed
  mapProfile = (profile: ImmutableObject<any>): any => {
    if (!profile) return null;
    const data = profile.toJS();
    return {
      id: data.id,
      personalId: data.personalId,
      firstName: data.firstName,
      lastName: data.lastName,
      birthday: data.birthday,
      gender: data.gender,
      image: data.image,
      barcode: data.barcode,
      personalCode: data.personalCode,
      balance: data.balance,
      reward: 0,
      email: data.email,
      joined: data.createdAt,
      type: data.type,
      address: data.address,
      phones: data.phones,
      homeClub: data.homeClub,
      packages: [],
      alerts: [],
      tags: data.tags,
      additional: data.additional,
      assignedSalesperson: data.assignedSalesperson,
      status: data.salesStatus,
      salesType: data.salesType,
      preferableWayOfCommunication: data.preferableWayOfCommunication,
      doNotContactHours: data.doNotContactHours,
      membership: data.membership,
      club: data.club,
    };
  };

  render(): JSX.Element {
    const {
      classes,
      profile,
      additionalFields,
      onStatusChange,
      updateLeadAvatarAction,
      deleteLeadAvatarAction,
      module,
      ...profileProps
    } = this.props;
    const mappedProfile = this.mapProfile(profile);

    const { isOpenEditProfile } = this.state;

    return (
      <>
        {mappedProfile ? (
          <PersonProfile
            ServicesSection={this.renderServicesSection}
            BlocksSection={BlocksSection}
            additionalFields={additionalFields}
            ProfileDataSection={ProfileGeneralInfo}
            profile={fromJS(mappedProfile)}
            updatePersonPhoto={updateLeadAvatarAction}
            deletePersonPhoto={deleteLeadAvatarAction}
            onStatusChange={this.onChangeLeadStatus}
            openEditProfile={this.handleOpenEditModal}
            module={module}
            {...profileProps}
          />
        ) : (
          <LoadingBackdrop isLoading />
        )}
        {isOpenEditProfile && (
          <EditLead isOpen={isOpenEditProfile} onClose={this.handleCloseEditModal} />
        )}
      </>
    );
  }
}

export default LeadProfile;
