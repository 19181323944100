import { fromJS } from 'immutable';
import { actionTypes } from 'modules/reports/constants/checkInHistory';
import { combineReducers } from 'redux-immutable';
import { initReqStateImt } from 'common/constants/initialState';

function checkInHistoryStatisticsReducer(state = initReqStateImt, action) {
  switch (action.type) {
    case actionTypes.FETCH_CHECK_IN_HISTORY_STATISTICS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.FETCH_CHECK_IN_HISTORY_STATISTICS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_CHECK_IN_HISTORY_STATISTICS:
      return initReqStateImt;
    default:
      return state;
  }
}

const initialStateCheckInHistoryActivity = fromJS({
  result: {},
  chartData: [],
  isLoading: false,
});

function checkInHistoryActivityReducer(state = initialStateCheckInHistoryActivity, action) {
  switch (action.type) {
    case actionTypes.FETCH_CHECK_IN_HISTORY_ACTIVITY:
      return state.set('result', fromJS(action.payload));
    case actionTypes.SET_CHECK_IN_HISTORY_CHART_DATA:
      return state.set('chartData', fromJS(action.payload));
    case actionTypes.FETCH_CHECK_IN_HISTORY_ACTIVITY_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_CHECK_IN_HISTORY_ACTIVITY:
      return initReqStateImt;
    default:
      return state;
  }
}

const initialStateCheckInHistoryReportItems = fromJS({
  list: [],
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
  isLoading: false,
});

function checkInHistoryReportItemsReducer(state = initialStateCheckInHistoryReportItems, action) {
  switch (action.type) {
    case actionTypes.FETCH_CHECK_IN_HISTORY_REPORT_ITEMS:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta));
    case actionTypes.FETCH_CHECK_IN_HISTORY_REPORT_ITEMS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_CHECK_IN_HISTORY_REPORT_ITEMS:
      return initialStateCheckInHistoryReportItems;
    default:
      return state;
  }
}

export default combineReducers({
  checkInHistoryStatistics: checkInHistoryStatisticsReducer,
  checkInHistoryActivity: checkInHistoryActivityReducer,
  checkInHistoryReportItems: checkInHistoryReportItemsReducer,
});
