import { createAction } from '@reduxjs/toolkit';

import Services from 'services/network';
// constants
import {
  FETCH_SENDER_AVAILABILITY,
  FETCH_SENDER_AVAILABILITY_LOADING,
  RESET_SENDER_AVAILABILITY,
} from 'modules/booking/constants';
import { SenderAvailabilityTypeList } from 'modules/booking/constants/senderAvailability';
import { getSenderAvailabilityByNotificationType } from 'modules/booking/constants/notificationType';
// state
import { GeneralThunkAction } from 'common/state/interfaces';
import {
  FetchSenderAvailabilityLoadingPayload,
  FetchSenderAvailabilitySuccessPayload,
  ResetSenderAvailabilityPayload,
} from './reducers';
// interfaces
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { IFetchSenderAvailabilityData } from 'modules/booking/interfaces';

const fetchSenderAvailabilityLoading = createAction<FetchSenderAvailabilityLoadingPayload>(
  FETCH_SENDER_AVAILABILITY_LOADING,
);
const fetchSenderAvailability = createAction<FetchSenderAvailabilitySuccessPayload>(
  FETCH_SENDER_AVAILABILITY,
);
export const resetBookingEvents = createAction<ResetSenderAvailabilityPayload>(
  RESET_SENDER_AVAILABILITY,
);

export const fetchSenderAvailabilityThunk = (
  types: SenderAvailabilityTypeList[],
  options?: IFetchSenderAvailabilityData,
): GeneralThunkAction => {
  return async dispatch => {
    types.forEach(type => {
      dispatch(fetchSenderAvailabilityLoading({ type, isLoading: true }));
    });

    try {
      const senderAvailability = await Services.Booking.getSenderAvailability(options);

      senderAvailability.availabilityItemDtoList.forEach(data => {
        const typeSenderAvailability = getSenderAvailabilityByNotificationType(data.type);
        dispatch(fetchSenderAvailability({ type: typeSenderAvailability, data }));
      });
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      types.forEach(type => {
        dispatch(fetchSenderAvailabilityLoading({ type, isLoading: false }));
      });
    }
  };
};
