import React from 'react';
import { Grid, Typography } from '@material-ui/core';

interface IProps {
  title?: string | JSX.Element;
  children: React.ReactNode;
}

class FormSectionContainer extends React.PureComponent<IProps> {
  render(): JSX.Element {
    const { title, children } = this.props;
    return (
      <Grid item xs={12}>
        <Grid container spacing={1}>
          {title && (
            <Grid item xs={12}>
              <Typography variant="button" color="textSecondary" gutterBottom>
                {title}
              </Typography>
            </Grid>
          )}
          {children}
        </Grid>
      </Grid>
    );
  }
}

export default FormSectionContainer;
