import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Tooltip } from '@material-ui/core';

import commonMessages from 'common/messages/messages';

import { ReactComponent as HistoryIcon } from 'img/icons/clock-rotate.svg';
import { ReactComponent as AddIcon } from 'img/icons/add.svg';
import { Button } from 'common/components';

interface IProps {
  onAddClick: () => void;
  onHistoryClick: () => void;
}

const useStyles = makeStyles({
  icon: {
    width: 20,
    height: 20,
  },
});

const RemindersBlockSectionActions: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { onAddClick, onHistoryClick } = props;

  const classes = useStyles(props);

  return (
    <Box>
      <Tooltip title={<FormattedMessage {...commonMessages.historyBtn} />}>
        <Button type="button" onClick={onHistoryClick} hasHoverStyle color="primary">
          <HistoryIcon className={classes.icon} />
        </Button>
      </Tooltip>
      <Tooltip title={<FormattedMessage {...commonMessages.addBtn} />}>
        <Button type="button" onClick={onAddClick} hasHoverStyle color="primary">
          <AddIcon className={classes.icon} />
        </Button>
      </Tooltip>
    </Box>
  );
};

export default RemindersBlockSectionActions;
