export const PUSH_REQUEST_ERROR = 'REQUEST_ERRORS';
export const RESET_REQUEST_ERRORS = 'RESET_REQUEST_ERRORS';
export const RESET_EMPLOYEE_FORM = 'RESET_EMPLOYEE_FORM';

export const FETCH_DICTIONARY_LIST_SUCCESS = 'FETCH_DICTIONARY_LIST_SUCCESS';
export const FETCH_DICTIONARY_LIST_LOADING = 'FETCH_DICTIONARY_LIST_LOADING';
export const RESET_DICTIONARY_LIST = 'RESET_DICTIONARY_LIST';

export const FETCH_DICTIONARY_ITEM_SUCCESS = 'FETCH_DICTIONARY_ITEM_SUCCESS';
export const FETCH_DICTIONARY_ITEM_LOADING = 'FETCH_DICTIONARY_ITEM_LOADING';
export const RESET_DICTIONARY_ITEM = 'RESET_DICTIONARY_ITEM';

export const CHANGE_QUERY_PARAMS = 'CHANGE_QUERY_PARAMS';

export const UPDATE_PROFILE_AFTER_EDIT_PERSON_ACTION = 'UPDATE_PROFILE_AFTER_EDIT_PERSON_ACTION';
