import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as ReplayIcon } from 'img/icons/reset.svg';
// components
import { Box, makeStyles, Theme } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import { isBulkConstant } from 'common/constants/bulkConstants';
import { TextField } from 'common/components/index';

const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    position: 'relative',
  },
  replayContainer: {
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    zIndex: 1,
    right: 0,
    borderRadius: 3,
    cursor: 'pointer',
    height: 18,
    width: 20,
    textAlign: 'center',
  },
  iconReplay: {
    fill: theme.palette.background.paper,
    height: 16,
    width: 16,
    padding: '2px 2px 0px',
  },
  input: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '& :hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

type TextFieldWithResetProps = Omit<TextFieldProps, 'onChange'> & {
  reset?: string | number;
  multi?: boolean;
  inputComponent?: JSX.Element;
  isDefaultCanResetFlag?: boolean;
  onChange?: (val: string | number) => void;
  recommended?: boolean;
};

const TextFieldWithReset = ({
  InputProps,
  multi,
  reset,
  isDefaultCanResetFlag,
  recommended,
  ...textFieldProps
}: TextFieldWithResetProps): JSX.Element => {
  const classes = useStyles();
  const { name, value, onChange, error, variant } = textFieldProps;
  const [canReset, setCanReset] = useState(false);
  const refInput = useRef(null);

  // handlers

  const handleResetBtnClick = () => {
    setCanReset(false);
    onChange(reset);
  };

  const handleInputFocus = () => {
    if (multi && !canReset && reset !== undefined) {
      setCanReset(true);

      if (isBulkConstant(value)) {
        onChange('');
        setTimeout(() => refInput.current.focus());
      }
    }
  };

  // effects

  useEffect(() => {
    if (isDefaultCanResetFlag && canReset) {
      setCanReset(false);
    }
  }, [isDefaultCanResetFlag, setCanReset, canReset]);

  const shouldShowResetButton = canReset && reset !== value;
  const multipleValsInputProps =
    multi && isBulkConstant(value)
      ? {
          ...InputProps,
          inputComponent: undefined,
          inputProps: undefined,
        }
      : InputProps;

  // renders

  return (
    <Box className={classes.mainContainer}>
      {shouldShowResetButton && (
        <Box className={classes.replayContainer} onClick={handleResetBtnClick}>
          <ReplayIcon className={classes.iconReplay} />
        </Box>
      )}

      <TextField
        name={name}
        inputRef={refInput}
        {...textFieldProps}
        variant={variant}
        onChange={onChange as any}
        InputProps={multipleValsInputProps}
        value={multi && isBulkConstant(value) ? 'Multiple' : value}
        onFocus={handleInputFocus}
        className={`${shouldShowResetButton && !error ? classes.input : ''}`}
        recommended={recommended}
      />
    </Box>
  );
};

export default TextFieldWithReset;
