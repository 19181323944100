import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext, Controller } from 'react-hook-form';
import { List as ImmutableList } from 'immutable';
import { Button, Grid, Typography } from '@material-ui/core';
import { MultipleSelect } from 'common/components';
// interfaces
import { IEmployeeRoleImt } from 'common/interfaces/employee';
// redux
import { selectRoles } from 'modules/employees/state/selectors';
import { fetchRolesList } from 'modules/employees/state/actions';
// components
import ViewRoleModal from 'modules/permissions/components/ViewRoleModal/ViewRoleModal';
// hooks
import { useAppDispatch } from 'store/hooks';
// messages
import { FormattedMessage } from 'react-intl';
import inputLabels from 'common/messages/inputLabels';
import employeesMessages from 'common/messages/employeesMessages';
import { IPolicyListItem } from 'modules/permissions/interfaces/permissions';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

interface IPeakEmployeePermissionsSection {
  isVisibleTitle?: boolean;
}

function PeakEmployeePermissionsSection(props: IPeakEmployeePermissionsSection): JSX.Element {
  const { isVisibleTitle = true } = props;

  const dispatch = useAppDispatch();
  const { control, errors } = useFormContext();
  const [selectedRow, setSelectedRow] = useState<IPolicyListItem | null>(null);

  const renderIntlMessage = useRenderIntlMessage();

  const handleViewDialogShow = useCallback((roleItem: IPolicyListItem) => {
    setSelectedRow(roleItem);
  }, []);

  const handleViewDialogClose = useCallback(() => {
    setSelectedRow(null);
  }, []);

  const availableRoles: ImmutableList<IEmployeeRoleImt> = useSelector(selectRoles);

  useEffect(() => {
    dispatch(fetchRolesList());
  }, [dispatch]);

  return (
    <>
      <Grid container spacing={1}>
        {isVisibleTitle && (
          <Grid item xs={12}>
            <Typography variant="button" color="textSecondary" gutterBottom>
              <FormattedMessage {...employeesMessages.permissions} />
            </Typography>
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <Controller
            name="securityRole"
            control={control}
            render={({ value, onChange, onBlur }) => (
              <>
                <MultipleSelect
                  fullWidth
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                  options={availableRoles.toJS()}
                  label={<FormattedMessage {...inputLabels.role} />}
                  error={!!errors.securityRole}
                  helperText={renderIntlMessage(errors.securityRole?.message)}
                />
                {value && (
                  <Button
                    variant="text"
                    className="btn-text"
                    type="button"
                    onClick={() => handleViewDialogShow(value)}
                  >
                    <Typography variant="body1" style={{ textTransform: 'none' }}>
                      <FormattedMessage {...employeesMessages.viewPermissions} />
                    </Typography>
                  </Button>
                )}
              </>
            )}
          />
        </Grid>
      </Grid>

      <ViewRoleModal
        selectedRow={selectedRow}
        isOpen={!!selectedRow}
        onClose={handleViewDialogClose}
      />
    </>
  );
}

export default React.memo(PeakEmployeePermissionsSection);
