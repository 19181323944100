import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import AppService from 'services/application/AppService';

import { actionTypes } from 'modules/front-desk/constants';
import checkInsReducer from './checkins/reducers';
import manualCheckInReducer from '../containers/ManualCheckIn/reducer';

const initialStateFrontDeskPanel = fromJS({
  mainPanelPersonId: null,
  rightPanelPersonId: null,
  appointmentId: null,
});

const initialClientMemberTags = fromJS({
  clientMemberTags: [],
  createTagLoading: false,
  createTagSuccess: null,
});

function clientMemberTagsReducer(state = initialClientMemberTags, action) {
  switch (action.type) {
    case actionTypes.FETCH_CLIENT_MEMBER_TAGS:
      return state.set('clientMemberTags', fromJS(action.payload));
    case actionTypes.CREATE_CLIENT_MEMBER_TAG:
      return state.set('createTagSuccess', fromJS(action.payload));
    case actionTypes.CREATE_CLIENT_MEMBER_TAG_LOADING:
      return state.set('createTagLoading', action.payload);
    case actionTypes.RESET_CREATE_CLIENT_MEMBER_TAG_SUCCESS:
      return state.set('createTagSuccess', fromJS(action.payload));
    default:
      return state;
  }
}

function frontDeskPanelReducer(state = initialStateFrontDeskPanel, action) {
  switch (action.type) {
    case actionTypes.SET_MAIN_PANEL_PERSON:
      return state.set('mainPanelPersonId', action.payload);
    case actionTypes.SET_RIGHT_PANEL_PERSON:
      return state.set('rightPanelPersonId', action.payload);
    case actionTypes.RESET_PANEL_PERSON:
      return initialStateFrontDeskPanel;
    default:
      return state;
  }
}

const selectedEntranceId = AppService.getAppSelectedEntrance();

const initialEntrancesState = fromJS({
  data: [],
  isLoading: false,

  selectedEntranceId,
});
function frontDeskClubEntrancesReducer(state = initialEntrancesState, action) {
  switch (action.type) {
    case actionTypes.FETCH_CLUB_ENTRANCES:
      return state.set('data', fromJS(action.payload));
    case actionTypes.FETCH_CLUB_ENTRANCES_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.SET_SELECTED_ENTRANCE_ID:
      return state.set('selectedEntranceId', action.payload);
    case actionTypes.RESET_SELECTED_ENTRANCE_ID:
      return state.set('selectedEntranceId', null);
    default:
      return state;
  }
}

export default combineReducers({
  panels: frontDeskPanelReducer,
  memberTags: clientMemberTagsReducer,
  checkins: checkInsReducer,
  manualCheckIn: manualCheckInReducer,
  clubEntrances: frontDeskClubEntrancesReducer,
});
