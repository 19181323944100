import { IRoutes } from 'common/interfaces/routes';

import commonMessages from 'common/messages/messages';

export const crmTemplateRoutes: IRoutes[] = [
  {
    type: 'link',
    path: '/crm/templates/sms',
    message: commonMessages.smsLabel,
  },
  {
    type: 'link',
    path: '/crm/templates/email',
    message: commonMessages.emailsLabel,
  },
];

export const personalTrainingCrm = '/personal-training-crm';

export const ptCrmTemplateRoutes: IRoutes[] = [
  {
    type: 'link',
    path: `${personalTrainingCrm}/templates/sms`,
    message: commonMessages.smsLabel,
  },
  {
    type: 'link',
    path: `${personalTrainingCrm}/templates/email`,
    message: commonMessages.emailsLabel,
  },
];
