import { defineMessages } from 'react-intl';

export default defineMessages({
  modalWebcamImageTitle: {
    id: 'common.modals.webcam.image.modal.title',
    defaultMessage: 'Take Photo',
  },
  modalWebcamImageRetakePhotoTitle: {
    id: 'common.modals.webcam.image.retake.photo.title',
    defaultMessage: 'Retake Photo',
  },
});
