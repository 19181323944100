export const actionTypes = {
  // Inventory items
  FETCH_INVENTORY_ITEMS_POS_LOADING: 'FETCH_INVENTORY_ITEMS_POS_LOADING',
  FETCH_INVENTORY_ITEMS_POS: 'FETCH_INVENTORY_ITEMS_POS',
  FETCH_INVENTORY_ITEMS_POS_CHANGE_STATUS_LOADING:
    'FETCH_INVENTORY_ITEMS_POS_CHANGE_STATUS_LOADING',
  FETCH_INVENTORY_ITEMS_POS_CHANGE_STATUS: 'FETCH_INVENTORY_ITEMS_POS_CHANGE_STATUS',
  RESET_CHANGE_STATUS_SUCCESS: 'RESET_CHANGE_STATUS_SUCCESS',
  // Inventory items by id
  FETCH_INVENTORY_ITEMS_POS_BY_ID_LOADING: 'FETCH_INVENTORY_ITEMS_POS_BY_ID_LOADING',
  FETCH_INVENTORY_ITEMS_POS_BY_ID: 'FETCH_INVENTORY_ITEMS_POS_BY_ID',
  FETCH_INVENTORY_ITEMS_POS_BY_ID_PAGINATE: 'FETCH_INVENTORY_ITEMS_POS_BY_ID_PAGINATE',
  CREATE_INVENTORY_ITEMS_POS_BY_ID: 'CREATE_INVENTORY_ITEMS_POS_BY_ID',
  UPDATE_INVENTORY_ITEMS_POS_BY_ID: 'UPDATE_INVENTORY_ITEMS_POS_BY_ID',
  UPDATE_INVENTORY_ITEMS_POS_BY_ID_LOADING: 'UPDATE_INVENTORY_ITEMS_POS_BY_ID_LOADING',
  CREATE_INVENTORY_ITEMS_POS_BY_ID_CLUBS: 'CREATE_INVENTORY_ITEMS_POS_BY_ID_CLUBS',
  UPDATE_INVENTORY_ITEMS_POS_BY_ID_CLUBS: 'UPDATE_INVENTORY_ITEMS_POS_BY_ID_CLUBS',
  DELETE_INVENTORY_ITEMS_POS_BY_ID_CLUBS: 'DELETE_INVENTORY_ITEMS_POS_BY_ID_CLUBS',
  RESET_INVENTORY_ITEMS_POS_BY_ID_CLUBS_ACTION: 'RESET_INVENTORY_ITEMS_POS_BY_ID_CLUBS_ACTION',
  RESET_INVENTORY_ITEMS_BY_ID: 'RESET_INVENTORY_ITEMS_BY_ID',
  FETCH_INVENTORY_ITEMS_POS_BY_ID_CLUBS_MULTI_EDIT:
    'FETCH_INVENTORY_ITEMS_POS_BY_ID_CLUBS_MULTI_EDIT',
  RESET_INVENTORY_ITEMS_POS_BY_ID_CLUBS_MULTI_EDIT:
    'RESET_INVENTORY_ITEMS_POS_BY_ID_CLUBS_MULTI_EDIT',
  // Inventory items by id configuration
  FETCH_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_LOADING:
    'FETCH_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_LOADING',
  FETCH_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION: 'FETCH_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION',
  FETCH_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_CLUBS_LOADING:
    'FETCH_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_CLUBS_LOADING',
  FETCH_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_CLUBS:
    'FETCH_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_CLUBS',
  FETCH_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_CLUBS_ACTION_LOADING:
    'FETCH_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_CLUBS_ACTION_LOADING',
  CREATE_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_CLUBS:
    'CREATE_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_CLUBS',
  UPDATE_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_CLUBS:
    'UPDATE_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_CLUBS',
  DELETE_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_CLUBS:
    'DELETE_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_CLUBS',
  RESET_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_CLUBS_RESULT:
    'RESET_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_CLUBS_RESULT',
  RESET_INVENTORY_ITEMS_BY_ID_CONFIGURATION: 'RESET_INVENTORY_ITEMS_BY_ID_CONFIGURATION',
  FETCH_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_CLUBS_MULTI_EDIT_LOADING:
    'FETCH_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_CLUBS_MULTI_EDIT_LOADING',
  FETCH_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_CLUBS_MULTI_EDIT:
    'FETCH_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_CLUBS_MULTI_EDIT',
  RESET_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_CLUBS_MULTI_EDIT:
    'RESET_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_CLUBS_MULTI_EDIT',
};
