import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';
import { actionTypes } from './constants';
import { initReqStateImt } from 'common/constants/initialState';

const initFeeListState = fromJS({
  list: [],
  meta: {},
  isLoading: false,
  changeServiceStatusLoading: false,
  changeServiceStatusResult: undefined,
});

function feesListReducer(state = initFeeListState, action) {
  switch (action.type) {
    case actionTypes.FETCH_FEES_LIST:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta));
    case actionTypes.FETCH_FEES_LIST_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_FEES_LIST:
      return initFeeListState;
    default:
      return state;
  }
}

function feeDetailsReducer(state = initReqStateImt, action) {
  switch (action.type) {
    case actionTypes.FETCH_FEE_DETAILS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.FETCH_FEE_DETAILS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_FEE_DETAILS:
      return initReqStateImt;
    default:
      return state;
  }
}

function feeActionReducer(state = initReqStateImt, action) {
  switch (action.type) {
    case actionTypes.CHANGE_FEE_STATUS_RESULT:
    case actionTypes.CREATE_FEE_RESULT:
    case actionTypes.UPDATE_FEE_RESULT:
      return state.set('result', fromJS(action.payload));
    case actionTypes.CHANGE_FEE_STATUS_LOADING:
    case actionTypes.CREATE_FEE_LOADING:
    case actionTypes.UPDATE_FEE_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_CHANGE_FEE_STATUS:
    case actionTypes.RESET_CREATE_FEE:
    case actionTypes.RESET_UPDATE_FEE:
      return initReqStateImt;
    default:
      return state;
  }
}

export default combineReducers({
  feesList: feesListReducer,
  feeDetails: feeDetailsReducer,
  action: feeActionReducer,
});
