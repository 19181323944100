import { actionTypes } from './constants';
import { fromJS, List } from 'immutable';
import { ICheckInHistoryImt } from 'common/components/PersonProfile/interfaces';

const allergiesInitState = fromJS({
  recentCheckInsHistory: List<ICheckInHistoryImt>(),
  recentCheckInsHistoryLoading: false,
  checkInsHistory: List<ICheckInHistoryImt>(),
  checkInsHistoryMeta: fromJS({ page: 0, perPage: 5, total: 0 }),
  checkInsHistoryLoading: false,
});

const documentsReducer = (state = allergiesInitState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PERSON_DOCUMENTS_LOADING:
      return state.set('isDocumentsLoading', action.payload);
    case actionTypes.FETCH_PERSON_DOCUMENTS:
      return state.set('documentsData', fromJS(action.payload));
    case actionTypes.RESET_PERSON_DOCUMENTS:
      return state.set('documentsData', null);
    default:
      return state;
  }
};

export default documentsReducer;
