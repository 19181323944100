import { useCallback } from 'react';
import { Howl } from 'howler';

type IPlaySoundReturnFunc = (soundSrc: string | string[]) => void;

const usePlaySound = (): IPlaySoundReturnFunc =>
  useCallback((soundSrc: string | string[]) => {
    const audioElement = new Howl({
      src: soundSrc,
      format: ['mp3'],
      autoplay: true,
      html5: true,
    });

    audioElement.play();
  }, []);

export default usePlaySound;
