import React, { useEffect, useMemo, useState } from 'react';
import { Autocomplete as MuiAutocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { Grid, Avatar, Typography, CircularProgress, Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';

import profilePlaceholder from 'img/photo-placeholder.png';
import Services from 'services/network';
import { getInitials } from 'helpers/common';
import { PeakModules } from 'common/constants/peakModules';
import { debounce } from 'common/utils';
import { INamedEntity } from 'common/interfaces/common';
import { TextField } from 'common/components/index';

interface UserAutocompleteProps {
  label: string | JSX.Element;
  fullWidth: boolean;
  error?: boolean;
  helperText?: React.ReactNode;
  value: any;
  onChange: (value) => void;
  renderOption?: (params) => React.ReactNode;
  module: PeakModules;
  requestOptions?: any;
  recommended?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  textFieldRoot: {
    '&.MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
      padding: '0 65px 0 0',
    },
    '& > .MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input': {
      padding: '22px 12px 10px 12px',
    },
  },
  avatarWrap: {
    position: 'relative',
    flex: 'none',
    width: 40,
    height: 40,
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.light,
  },
  avatarInitial: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    fontSize: '16px',
    fontWeight: 700,
    color: theme.palette.darkBackground?.light,
  },
}));

const UserAutocomplete = (props: UserAutocompleteProps): JSX.Element => {
  const {
    label,
    fullWidth,
    error,
    helperText,
    value,
    onChange,
    renderOption,
    module,
    requestOptions,
    recommended,
  } = props;

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<INamedEntity[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  const loadEmployees = useMemo(() => {
    return debounce(async () => {
      setIsLoading(true);

      const employees = await Services.DictionaryLists.getEmployeesList({
        module,
        ...requestOptions,
      });

      setOptions(employees.data);

      setIsLoading(false);
    });
  }, [module, requestOptions]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    } else {
      loadEmployees();
    }
  }, [open, loadEmployees]);

  const renderUserOption = ({ imageUrl, title }: INamedEntity) => {
    const [first, last] = title.split(' ');

    const initials = getInitials(first, last);

    return (
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <Box className={cx(classes.avatarWrap)}>
            {initials && !imageUrl ? (
              <Box className={classes.avatarInitial}>{initials}</Box>
            ) : (
              <Avatar src={imageUrl || profilePlaceholder} alt={first} />
            )}
          </Box>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">{title}</Typography>
        </Grid>
      </Grid>
    );
  };
  //
  return (
    <MuiAutocomplete
      open={open}
      value={value}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      getOptionSelected={(option: INamedEntity, valueEmployee: INamedEntity) => {
        return option.id === valueEmployee.id;
      }}
      getOptionLabel={(employee: INamedEntity | null) => {
        if (employee) {
          return employee.title;
        }
        return '';
      }}
      renderOption={renderOption || renderUserOption}
      onChange={(e, data) => onChange(data)}
      options={options}
      loading={isLoading}
      renderInput={(params: AutocompleteRenderInputParams) => {
        return (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            fullWidth={fullWidth}
            error={error}
            helperText={helperText}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
              form: {
                autoComplete: 'off',
              },
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              classes: {
                root: classes.textFieldRoot,
              },
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            recommended={recommended}
          />
        );
      }}
    />
  );
};

export default UserAutocomplete;
