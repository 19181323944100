import * as yup from 'yup';
import { getRequiredMessage } from 'common/constants/globalConstants';

export const SMSValidationSchema = yup.object().shape({
  targetPersonPhone: yup
    .object()
    .nullable()
    .required(getRequiredMessage),
  text: yup.string().required(getRequiredMessage),
});
