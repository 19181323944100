import { LocalStorageService } from '../storage/WebStorageService';

class PrintKeysService {
  private static DIGITAL_CERTIFICATE_KEY = 'digitalCertificateKey';

  private static PRIVATE_KEY = 'privateKey';

  private localStorageService: LocalStorageService;

  constructor() {
    this.localStorageService = new LocalStorageService({ prefix: 'print.' });
  }

  public setDigitalCertificateKey(key: string): void {
    this.localStorageService.set(PrintKeysService.DIGITAL_CERTIFICATE_KEY, key);
  }

  public getDigitalCertificateKey(): string | void {
    return this.localStorageService.get(PrintKeysService.DIGITAL_CERTIFICATE_KEY);
  }

  public clearDigitalCertificateKey(): void {
    this.localStorageService.remove(PrintKeysService.DIGITAL_CERTIFICATE_KEY);
  }

  public setPrivateKey(key: string): void {
    this.localStorageService.set(PrintKeysService.PRIVATE_KEY, key);
  }

  public getPrivateKey(): string | void {
    return this.localStorageService.get(PrintKeysService.PRIVATE_KEY);
  }

  public clearPrivateKey(): void {
    this.localStorageService.remove(PrintKeysService.PRIVATE_KEY);
  }
}

export default new PrintKeysService();
