import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';
import { v4 as uuidv4 } from 'uuid';

import { actionTypes } from './constants';

export const initCameFromIndicatorsState = fromJS({
  list: [],
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
  isLoading: false,
  error: null,
});

function cameFromIndicatorsReducer(state = initCameFromIndicatorsState, action) {
  switch (action.type) {
    case actionTypes.FETCH_CAME_FROM_INDICATORS_IS_LOADING:
      return state.set('isLoading', action.payload).set('error', null);
    case actionTypes.FETCH_CAME_FROM_INDICATORS:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta));
    case actionTypes.FETCH_CAME_FROM_INDICATORS_ERROR:
      return state.set('error', action.payload);
    case actionTypes.RESET_CAME_FROM_INDICATORS:
      return initCameFromIndicatorsState;
    default:
      return state;
  }
}

const initCameFromIndicatorState = fromJS({
  result: {},
  transformedOptions: [],
  actionResult: null,
  isLoading: false,
  isLoadingCreateOrUpdate: false,
  error: null,
});

function dictionaryItemReducer(state = initCameFromIndicatorState, action) {
  switch (action.type) {
    case actionTypes.FETCH_CAME_FROM_INDICATOR_IS_LOADING:
      return state.set('isLoading', action.payload).set('error', null);
    case actionTypes.CREATE_OR_UPDATE_CAME_FROM_INDICATOR_IS_LOADING:
      return state.set('isLoadingCreateOrUpdate', action.payload).set('error', null);
    case actionTypes.FETCH_CAME_FROM_INDICATOR:
      const transformedOptions = action.payload.options.map((title: string) => ({
        id: uuidv4(),
        title,
      }));
      return state
        .set('result', fromJS(action.payload))
        .set('transformedOptions', fromJS(transformedOptions));
    case actionTypes.SET_CAME_FROM_INDICATOR_ACTION_RESULT:
      return state.set('actionResult', action.payload);
    case actionTypes.FETCH_CAME_FROM_INDICATOR_ERROR:
      return state.set('error', fromJS(action.payload));
    case actionTypes.RESET_CAME_FROM_INDICATOR:
      return initCameFromIndicatorState;
    default:
      return state;
  }
}

export default combineReducers({
  cameFromIndicators: cameFromIndicatorsReducer,
  cameFromIndicator: dictionaryItemReducer,
});
