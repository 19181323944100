import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const useStyles = makeStyles(theme => ({
  label: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 18,
    minWidth: 18,
    marginLeft: 4,
    borderRadius: 3,
    fontSize: 12,
    lineHeight: 'normal',
    fontWeight: 'bold',
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.primary.main,
  },
  inverseColors: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
  },
}));

interface IProps {
  count: number;
  inverseColor?: boolean;
}

const FiltersCounter = ({ count, inverseColor }: IProps): JSX.Element => {
  const classes = useStyles();
  //
  // if (count === 0) {
  //   return <span>All</span>;
  // }

  return count > 1 ? (
    <span
      className={classnames(classes.label, {
        [classes.inverseColors]: inverseColor,
      })}
    >
      {count}
    </span>
  ) : (
    <></>
  );
};

export default FiltersCounter;
