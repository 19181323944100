import { Box, makeStyles, Popper, Tooltip, Typography } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import React from 'react';

const useStyles = makeStyles(theme => ({
  infoIcon: {
    color: theme.palette.darkBackground?.light,
    '&:hover': {
      color: theme.palette.darkBackground?.main,
    },
  },
  popper: {
    backgroundColor: theme.palette.darkBackground?.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1.25),
    borderRadius: 3,
  },
}));

interface IInfoTooltipProps {
  text: string | JSX.Element;
}

const InfoTooltip = ({ text }: IInfoTooltipProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Box display="inline-flex" alignItems="center">
      <Tooltip
        title="info"
        PopperComponent={props => (
          <Popper {...props}>
            <Box className={classes.popper}>
              <Typography color="inherit">{text}</Typography>
            </Box>
          </Popper>
        )}
      >
        <Help color="inherit" className={classes.infoIcon} fontSize="small" />
      </Tooltip>
    </Box>
  );
};

export default React.memo(InfoTooltip);
