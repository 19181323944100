import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import cx from 'classnames';

import useScrollIntoView from 'common/hooks/useScrollIntoView';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import { IssueFrequencyTypes } from 'modules/services/constants/packages';

import { IEditablePackageConfiguration } from 'common/interfaces/membership';

import { TooltipTypography } from 'common/components/index';
import { ReactComponent as AngleDownIcon } from 'img/icons/angle-down.svg';
import { ReactComponent as AngleRightIcon } from 'img/icons/angle-right.svg';
import { ReactComponent as DefaultIcon } from 'img/icons/default-icon.svg';
import { ReactComponent as InfoIcon } from 'img/icons/info.svg';

import personProfileMessages from 'common/components/PersonProfile/messages';

const useStyles = makeStyles((theme: Theme) => ({
  sectionBtn: {
    padding: 0,
    color: theme.palette.text.secondary,

    '& svg': {
      width: '0.75rem',
      height: '0.75rem',
      marginRight: theme.spacing(1),
    },
  },
  inventoryItem: {
    display: 'flex',
    alignItems: 'center',
  },
  inventoryImage: {
    width: '36px',
    height: '36px',
    marginRight: theme.spacing(1),
  },
  frequencyType: {
    fontSize: '0.75rem',
  },
  sectionContent: {
    marginTop: theme.spacing(1),
  },
  infoIcon: {
    width: '1rem',
    height: '1rem',
  },
  infoIconBtn: {
    alignSelf: 'flex-start',
    marginLeft: theme.spacing(0.5),
  },
  inventoryTitle: {
    paddingBottom: theme.spacing(0.25),
    paddingTop: theme.spacing(0.25),
  },
}));

interface IProps {
  membershipPackage: IEditablePackageConfiguration;
  onViewInventoryInfo?: (packageId: string, inventoryId: string) => void;
}

const IncludedInventorySectionView: FC<IProps> = ({ membershipPackage, onViewInventoryInfo }) => {
  const [showContent, setShowContent] = useState<boolean>(true);

  const { scrollRef } = useScrollIntoView(showContent);

  const renderIntlMessage = useRenderIntlMessage();

  const classes = useStyles();

  if (!membershipPackage.inventories?.length) {
    return null;
  }

  return (
    <Box mt={2}>
      <Box>
        <Button
          onClick={() => setShowContent(!showContent)}
          startIcon={showContent ? <AngleDownIcon /> : <AngleRightIcon />}
          className={classes.sectionBtn}
        >
          <FormattedMessage {...personProfileMessages.includedInventoryTitle} />
        </Button>
      </Box>

      <Collapse in={showContent} ref={scrollRef}>
        <Grid container spacing={2} className={classes.sectionContent}>
          {membershipPackage.inventories.map(inventoryItem => (
            <Grid item xs={6} key={inventoryItem.id} className={classes.inventoryItem}>
              <Avatar
                className={classes.inventoryImage}
                src={inventoryItem.inventory.imageUrl}
                alt={inventoryItem.inventory.title}
                variant="rounded"
              >
                <DefaultIcon />
              </Avatar>

              <Box display="flex" flexDirection="column" overflow="auto" gridGap="4px">
                <TooltipTypography className={classes.inventoryTitle} variant="h5" ellipsized>
                  {`${inventoryItem.number}x ${inventoryItem.inventory.title}`}
                </TooltipTypography>

                <Typography
                  color="textSecondary"
                  className={cx(classes.frequencyType, classes.inventoryTitle)}
                >
                  {renderIntlMessage(IssueFrequencyTypes.message(inventoryItem.frequency))}
                </Typography>
              </Box>
              {!!onViewInventoryInfo && (
                <IconButton
                  className={classes.infoIconBtn}
                  size="small"
                  type="button"
                  color="primary"
                  onClick={() => onViewInventoryInfo(membershipPackage.id, inventoryItem.id)}
                >
                  <InfoIcon className={classes.infoIcon} />
                </IconButton>
              )}
            </Grid>
          ))}
        </Grid>
      </Collapse>
    </Box>
  );
};

export default IncludedInventorySectionView;
