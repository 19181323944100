import { defineMessages } from 'react-intl';

export default defineMessages({
  contracts: {
    id: 'app.modules.services.documentTemplates.Contracts',
    defaultMessage: 'Contracts',
  },
  waivers: {
    id: 'app.modules.services.documentTemplates.Waivers',
    defaultMessage: 'Waivers',
  },
  editDocumentTemplatePageTitle: {
    id: 'modules.services.editDocumentTemplatePageTitle',
    defaultMessage: 'Edit {title} Template',
  },
  newDocumentTemplatePageTitle: {
    id: 'modules.services.newDocumentTemplatePageTitle',
    defaultMessage: 'New {title} Template',
  },
});
