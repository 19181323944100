import React from 'react';
import { Grid, SvgIconTypeMap, Typography } from '@material-ui/core';
import { RouteBackground } from 'components';
import MainPageMenuItem from '../MainPageMenuItem/MainPageMenuItem';
import { Message } from 'common/interfaces/common';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

export interface IMainModulePageRouteItem {
  id: string;
  message: Message;
  link: string;
  iconComponent: OverridableComponent<SvgIconTypeMap>;
  viewBox?: string;
}

interface IMainModulePageProps {
  routes: IMainModulePageRouteItem[];
  pageTitle: string;
}

const MainModulePage = ({ routes, pageTitle }: IMainModulePageProps): JSX.Element => {
  return (
    <RouteBackground hasMobilePadding>
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h2">
            {pageTitle}
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Grid container alignItems="flex-start" spacing={2}>
            {routes.map(({ id, message, link, iconComponent, viewBox }) => (
              <MainPageMenuItem
                key={id}
                title={message}
                path={link}
                icon={iconComponent}
                viewBox={viewBox}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </RouteBackground>
  );
};

export default MainModulePage;
