import React from 'react';
import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Markdown from 'react-markdown';

import messages from 'common/components/PersonProfile/messages';
import commonMessages from 'common/messages/messages';
import { CustomTheme } from 'common/ui/interfaces';
import { ReactComponent as DownloadIcon } from 'img/icons/download-doc.svg';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { IZendeskCommentDtoImt } from 'common/components/PersonProfile/interfaces';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';

const useStyles = makeStyles((theme: CustomTheme) => ({
  commentBlock: {
    backgroundColor: theme.palette.background.default,
  },
  label: {
    paddingBottom: theme.spacing(0.5),
  },
  internalNoteWrapper: {
    padding: theme.spacing(0.25, 1),
    borderRadius: 10,
    backgroundColor: theme.palette.text.secondary,
  },
  internalNoteTitle: {
    fontWeight: 700,
    color: theme.palette.primary.contrastText,
  },
  messageContent: {
    whiteSpace: 'pre-wrap',
  },
  fileName: {
    fontWeight: 600,
  },
  iconButton: {
    padding: 0,
    marginLeft: theme.spacing(0.5),
  },
  iconButtonLink: {
    lineHeight: '14px',
  },
}));

interface ICommentBlockProps {
  comment: IZendeskCommentDtoImt;
  index: number;
}

const CommentBlock = (props: ICommentBlockProps): JSX.Element => {
  const { comment, index } = props;

  const [timezoneMoment] = useTimezoneMoment();

  const classes = useStyles();

  const attachments = comment.get('attachments');

  const isVisibleAttachments = Boolean(attachments?.size);

  return (
    <Box className={classes.commentBlock} mb={2} p={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="subtitle1" color="textSecondary">
          <FormattedMessage
            {...messages.reply}
            values={{
              id: index,
              date: `${timezoneMoment(comment.get('createdDate')).format(
                DEFAULT_DATE_TIME_FORMAT,
              )}`,
            }}
          />
          &nbsp;-&nbsp;{comment.get('author')}
        </Typography>
        {comment.get('internalNote') && (
          <Box className={classes.internalNoteWrapper}>
            <Typography className={classes.internalNoteTitle} variant="body2">
              <FormattedMessage {...messages.internalNote} />
            </Typography>
          </Box>
        )}
      </Box>
      <Markdown className={classes.messageContent}>{comment.get('htmlBody')}</Markdown>
      {isVisibleAttachments && (
        <Box mt={2}>
          <Typography variant="h6">
            <FormattedMessage {...commonMessages.attachmentsLabel} />:
          </Typography>
          <Box display="flex" flexWrap="wrap" gridGap={1}>
            {attachments.map((item, i, items) => {
              return (
                <Box
                  key={item.get('id')}
                  display="flex"
                  alignItems="center"
                  mr={i !== items.size ? 1 : 0}
                >
                  <Typography variant="body2" color="primary" className={classes.fileName}>
                    {item.get('fileName')}
                  </Typography>
                  <a download href={item.get('url')} className={classes.iconButtonLink}>
                    <IconButton color="primary" className={classes.iconButton}>
                      <DownloadIcon width={16} height={16} />
                    </IconButton>
                  </a>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CommentBlock;
