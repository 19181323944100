import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentAdapter from '@date-io/moment';

import { getRequiredMessage } from 'common/constants/globalConstants';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import { pastDateSchema } from 'common/validationSchemas/dateSchemas';

import { IProfileInfo } from 'common/components/PersonProfile/interfaces';
import { AlertCondition } from 'common/interfaces/alerts';

import { AdaptiveDatePicker, DialogComponent } from 'common/components';

import inputLabels from 'common/messages/inputLabels';
import { useSelector } from 'react-redux';
import { selectProfileRequiredFields } from 'common/state/settings/selectors';
import { selectIsSubmittedRecommendedFieldsModal } from 'common/state/modals/selectors';
import { useRecommendedFields } from 'common/hooks/useRecommendedFields';
import { showRecommendedFieldsModal } from 'common/state/modals/actions';
import { useAppDispatch } from 'store/hooks';
import {
  FieldNecessity,
  RequiredProfileInfo,
} from 'modules/corporate-settings/constants/requiredFields';

interface IProps {
  alert: AlertCondition | null;
  isOpen: boolean;
  onSubmit: (info: IProfileInfo, alertId: string) => void;
  switchingModal: (alertCondition: AlertCondition) => void;
}

const BirthdayModal = ({ isOpen, switchingModal, onSubmit, alert }: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const profileRequiredFields = useSelector(selectProfileRequiredFields);
  const isSubmitted = useSelector(selectIsSubmittedRecommendedFieldsModal);

  const { checkRecommendedFieldsAreNotEmpty, profileRecommendedFields } = useRecommendedFields();

  const formMethods = useForm({
    defaultValues: {
      birthday: null,
    },
    resolver: yupResolver(
      yup.object().shape({
        birthday: pastDateSchema.when(RequiredProfileInfo.Birthday, {
          is: () => {
            return (
              profileRequiredFields.get(RequiredProfileInfo.Birthday) === FieldNecessity.Required
            );
          },
          then: schema => schema.required(getRequiredMessage),
          otherwise: schema => schema,
        }),
      }),
    ),
    mode: 'onChange',
  });

  const { control, handleSubmit, errors, getValues } = formMethods;

  const renderIntlMessage = useRenderIntlMessage();

  useEffect(() => {
    if (isSubmitted) {
      const values = getValues();

      onSubmit(values, alert);
      switchingModal(alert);

      dispatch(showRecommendedFieldsModal(false));
    }
  }, [alert, dispatch, getValues, isSubmitted, onSubmit, switchingModal]);

  const onSubmitForm = (values: Partial<IProfileInfo>): void => {
    const hasEmptyRecommendedFields = checkRecommendedFieldsAreNotEmpty(values);

    if (hasEmptyRecommendedFields) {
      dispatch(showRecommendedFieldsModal(true));
    } else {
      onSubmit(values, alert);
      switchingModal(alert);
    }
  };

  return (
    <DialogComponent
      size="xs"
      title={<FormattedMessage {...inputLabels.dateOfBirth} />}
      isOpen={isOpen}
      onClose={() => switchingModal(alert)}
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <MuiPickersUtilsProvider utils={MomentAdapter}>
        <Controller
          name="birthday"
          control={control}
          render={({ value, onBlur, onChange }) => (
            <AdaptiveDatePicker
              label={<FormattedMessage {...inputLabels.dateOfBirth} />}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              fullWidth
              error={!!errors.birthday}
              helperText={renderIntlMessage(errors.birthday?.message)}
              recommended={Boolean(profileRecommendedFields?.get(RequiredProfileInfo.Birthday))}
            />
          )}
        />
      </MuiPickersUtilsProvider>
    </DialogComponent>
  );
};

export default BirthdayModal;
