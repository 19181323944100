const peakSettingsSectionPath = ['peakSettings', 'peakSettingsSection'];

export const selectPeakSettingsSectionInfo = state =>
  state.getIn([...peakSettingsSectionPath, 'peakSettingsSectionInfo']);

export const selectPeakSettingsSectionInfoLoading = state =>
  state.getIn([...peakSettingsSectionPath, 'isPeakSettingsSectionInfoLoading']);

const updatePeakSettingsSectionPath = [
  'peakSettings',
  'peakSettingsSection',
  'updatePeakSettingsSectionInfo',
];

const generalPeakSettingsPath = ['peakSettings', 'peakSettingsSection'];

export const selectUpdatePeakSettingsSectionInfoResult = state =>
  state.getIn([...updatePeakSettingsSectionPath, 'result']);

export const selectUpdatePeakSettingsSectionInfoLoading = state =>
  state.getIn([...updatePeakSettingsSectionPath, 'isLoading']);

export const selectPeakPrimaryColor = state =>
  state.getIn([...generalPeakSettingsPath, 'primaryColor']);

export const selectPeakPrimaryColorLoading = state =>
  state.getIn([...generalPeakSettingsPath, 'primaryColorLoading']);
