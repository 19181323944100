import { useCallback } from 'react';

import { useAppDispatch } from 'store/hooks';
import { ISocketMessage } from 'common/components/SocketListener/interfaces';
import { updateSocketPersonRecentEmailsAction } from 'common/components/PersonProfile/state/actions';
import { IEmail } from 'common/components/PersonProfile/interfaces';

export const useEmailsHandler = (): ((msg: ISocketMessage<IEmail>) => void) => {
  const dispatch = useAppDispatch();

  return useCallback(
    (msg: ISocketMessage<IEmail>) => {
      const email = msg.data;

      dispatch(updateSocketPersonRecentEmailsAction(email, email.lead.id));
    },
    [dispatch],
  );
};
