import React, { FC } from 'react';
import { Box } from '@material-ui/core';
// interfaces
import { PeakModules } from 'common/constants/peakModules';
import SearchServicePackages from './SearchServicePackages';
import SearchInventories from './SearchInventories';
import SearchGiftCards from './SearchGiftCards';
import { useSearchInvoiceProductsState } from 'common/createContext/searchInvoiceProductsContext';

interface IAddCartItemsProps {
  module: PeakModules;
  isPaymentStep?: boolean;
}

const AddCartItems: FC<IAddCartItemsProps> = ({ module, isPaymentStep }) => {
  const {
    showSearchProducts: { isShowAddInventories, isShowAddServicePackages, isShowAddGiftCards },
  } = useSearchInvoiceProductsState();

  const isSearchProductsActive =
    isShowAddInventories || isShowAddServicePackages || isShowAddGiftCards;

  if (!isSearchProductsActive) return <></>;

  return (
    <Box display="flex" flexDirection="column" flex="1" pt={2} height="100%">
      {isShowAddInventories && <SearchInventories module={module} isPaymentStep={isPaymentStep} />}

      {isShowAddServicePackages && (
        <SearchServicePackages module={module} isPaymentStep={isPaymentStep} />
      )}

      {isShowAddGiftCards && <SearchGiftCards module={module} isPaymentStep={isPaymentStep} />}
    </Box>
  );
};

export default AddCartItems;
