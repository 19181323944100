import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { NavLink, NavLinkProps } from 'react-router-dom';
import cx from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
  activeNavigationLink: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const CustomNavLink = React.forwardRef<HTMLAnchorElement>(
  ({ to, className, ...otherProps }: NavLinkProps, ref) => {
    const classes = useStyles();

    return (
      <NavLink
        ref={ref}
        to={to}
        {...otherProps}
        className={({ isActive }) =>
          cx(className as string, { [classes.activeNavigationLink]: isActive })
        }
      />
    );
  },
);

export default CustomNavLink;
