import React from 'react';
import moment, { Moment } from 'moment-timezone';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { KeyboardDatePicker, DatePickerProps } from '@material-ui/pickers';

import { CustomTheme } from 'common/ui/interfaces';
import { FormattedMessage } from 'react-intl';
import inputErrors from 'common/messages/inputErrors';

const useStyles = makeStyles((theme: CustomTheme) => ({
  datePicker: {
    '& .MuiFormLabel-root.MuiInputLabel-marginDense.MuiInputLabel-shrink': {
      display: 'none',
    },
    '& .MuiOutlinedInput-adornedEnd, & .MuiInputAdornment-positionEnd': {
      paddingRight: 0,
      margin: 0,
      marginLeft: 0,
    },
    '& .MuiInputAdornment-root': {
      '& .MuiIconButton-root': {
        padding: theme.spacing(1),
      },
    },
    '& .MuiInputBase-marginDense': {
      '& .MuiInputAdornment-root': {
        '& .MuiIconButton-root': {
          padding: theme.spacing(0.5),
        },
      },
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: ({ size }: IProps) => {
        return size === 'small'
          ? `translate(${theme.spacing(1.75)}px, ${theme.spacing(1)}px) scale(1)`
          : undefined;
      },
    },
    '& .MuiFormHelperText-root.MuiFormHelperText-contained': {
      color: ({ recommended, error }: IProps) => {
        if (recommended && !error) {
          return theme.palette.warning.main;
        }

        if (error) {
          return theme.palette.error.main;
        }

        return 'none';
      },
      opacity: 1,
    },
  },
}));

interface IProps extends DatePickerProps {
  onChange: (d) => void;
  recommended?: boolean;
}

function AdaptiveDatePicker(props: IProps): JSX.Element {
  const { onChange, recommended, error, ...pickerProps } = props;
  const theme = useTheme();
  const classes = useStyles(props);
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <KeyboardDatePicker
      minDate={new Date('1930-01-01')}
      className={classes.datePicker}
      variant={isMobile ? 'dialog' : 'inline'}
      placeholder="mm/dd/yyyy"
      format="MM/DD/YYYY"
      inputVariant="outlined"
      onChange={(date: any) => {
        if (moment(date).isValid()) {
          onChange((date as Moment)?.format('YYYY-MM-DD'));
        } else {
          onChange(null);
        }
      }}
      views={['year', 'date']}
      openTo={pickerProps.openTo ?? 'date'}
      inputProps={{
        autoComplete: 'off',
      }}
      autoOk
      fullWidth
      orientation="portrait"
      {...pickerProps}
      helperText={
        recommended && !error ? (
          <FormattedMessage {...inputErrors.recommendedFieldLabel} />
        ) : (
          pickerProps.helperText
        )
      }
      value={pickerProps.value}
      error={error}
    />
  );
}

export default AdaptiveDatePicker;
