import React, { useEffect, useState } from 'react';
import { List } from 'immutable';

import { Box, makeStyles, TextField, Typography } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { ITag, ITagImt } from 'common/components/PersonProfile/interfaces';

import { ReactComponent as EditIcon } from 'img/icons/pencil_deprecated.svg';
import { CustomAutocomplete } from 'common/components';
import { useSelector } from 'react-redux';
import { selectCreateClientMemberTagSuccess } from 'modules/front-desk/state/selectors';
import {
  fetchClientMemberTags,
  resetCreateClientMemberTagActionSuccess,
} from 'modules/front-desk/state/actions';
import { selectDictionaryList } from 'common/state/dictionary/selectors';
import { DictionaryList } from 'common/constants';
import { useAppDispatch } from 'store/hooks';
import { Button } from 'common/components/index';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      background: '#fff!important',
    },
    wrapper: {
      maxWidth: '400px',
      width: isActive => (isActive ? '100%' : 'auto'),
      position: 'relative',
    },
    hiddenAutocomplete: {
      '& input, & fieldset': {
        display: 'none',
      },
      '& .MuiInputBase-root, &.MuiAutocomplete-inputRoot': {
        padding: '0px !important',
      },
      '& .MuiInputLabel-root': {
        display: 'none',
      },
      '& .MuiAutocomplete-endAdornment': {
        display: 'none',
      },
      '& .MuiChip-deleteIcon': {
        display: 'none',
      },
    },
    closeIcon: {
      '&.closeIconHidden': {
        display: 'none',
      },
    },
    editIcon: {
      minWidth: '16px',
      width: '16px',
      cursor: 'pointer',
      marginLeft: '3px',
      color: theme.palette.primary.main,
    },
    hoverButton: {
      '&.MuiButton-root': {
        padding: theme.spacing(1),
        minWidth: '30px',
      },
    },
  }),
);

interface IProps {
  clientId: string;
  profileTags: List<string>;
  handleCreateTag: (tag: Partial<ITag>) => void;
  handleChangeTags: (tags: Array<ITag>) => void;
}

const Tags: React.FC<IProps> = ({
  clientId,
  profileTags,
  handleCreateTag,
  handleChangeTags,
}: IProps) => {
  const [isActive, setIsActive] = useState(false);
  const [wasTagsChanged, setWasTagsChanged] = useState(false);
  const [activeTags, setActiveTags] = useState<Array<ITag>>([]);
  const dispatch = useAppDispatch();
  const classes = useStyles(isActive);

  const createClientMemberTagResult: ITagImt = useSelector(selectCreateClientMemberTagSuccess());
  const tags: List<ITagImt> = useSelector(selectDictionaryList(DictionaryList.FRONTDESK_PROFILES));

  useEffect(() => {
    if (createClientMemberTagResult && createClientMemberTagResult.get('id')) {
      const createdTag = createClientMemberTagResult.toJS();
      const newClentMemberTagsArray = activeTags.filter(
        (tag: ITag) => tag.name !== createdTag.name,
      );

      newClentMemberTagsArray.push(createdTag);
      handleChangeTags(newClentMemberTagsArray);
      dispatch(resetCreateClientMemberTagActionSuccess());
      dispatch(fetchClientMemberTags(clientId));
      setActiveTags(newClentMemberTagsArray);
    }
  }, [clientId, createClientMemberTagResult, activeTags, dispatch, handleChangeTags]);

  useEffect(() => {
    if (wasTagsChanged) {
      handleChangeTags(activeTags);
      dispatch(resetCreateClientMemberTagActionSuccess());
      dispatch(fetchClientMemberTags(clientId));
      setActiveTags(activeTags);
      setWasTagsChanged(false);
    }
  }, [activeTags, wasTagsChanged, clientId, handleChangeTags, dispatch]);

  const onBlur = () => {
    if (wasTagsChanged) {
      handleChangeTags(activeTags);
      setIsActive(false);
      setWasTagsChanged(false);
    }
  };

  const getCreatingTag = tagsArray =>
    tagsArray.find(
      (tag: Partial<ITag>) =>
        !tags.toJS().find(existingTag => existingTag.name === tag.name) &&
        !activeTags.find(selectedTag => selectedTag.name === tag.name),
    );

  const handleChange = (e: React.ChangeEvent, tagsArray: Array<ITag>) => {
    setWasTagsChanged(true);
    const newTag = getCreatingTag(tagsArray);
    if (newTag) {
      handleCreateTag(newTag);
    }
    setActiveTags(tagsArray);
  };

  useEffect(() => {
    setActiveTags(profileTags?.toJS() || []);
  }, [profileTags]);

  const filteredTags = tags
    ?.map(tag => ({ id: tag.get('id'), name: tag.get('name') }))
    .filter((tag: Partial<ITag>) => !activeTags.find(activeTag => activeTag.id === tag.id));

  return (
    <>
      <ClickAwayListener onClickAway={() => setIsActive(false)}>
        <Box
          onClick={() => setIsActive(true)}
          display="flex"
          alignItems="center"
          className={classes.wrapper}
        >
          {!isActive && !activeTags.length && (
            <Typography color="textSecondary" style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>
              No tags selected
            </Typography>
          )}
          <CustomAutocomplete
            multiple
            id="tags-standard"
            options={filteredTags?.toArray() || []}
            className={!isActive ? classes.hiddenAutocomplete : ''}
            value={activeTags}
            renderInput={params => <TextField {...params} label="Tags" variant="outlined" />}
            onBlur={onBlur}
            onChange={handleChange}
          />
          {!isActive && (
            <Button size="small" className={classes.hoverButton} color="primary">
              <EditIcon className={classes.editIcon} />
            </Button>
          )}
        </Box>
      </ClickAwayListener>
    </>
  );
};

export default Tags;
