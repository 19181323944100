import { IBaseInvoicePackageImt, IPackageInventoryViewDtoImt } from 'common/interfaces/membership';
import { AppStoreTypeImt } from 'store';

const membershipsListPath = ['newPerson', 'membership', 'membershipsList'];

export const selectMembershipPackages = state => state.getIn([...membershipsListPath, 'list']);
export const selectMembershipPackagesLoading = state => {
  return state.getIn([...membershipsListPath, 'isLoading']);
};

const membershipItemPath = ['newPerson', 'membership', 'membershipItem'];

export const selectMembershipItem = state => state.getIn([...membershipItemPath, 'membership']);

export const selectMembershipItemLoading = state =>
  state.getIn([...membershipItemPath, 'isLoading']);

const membershipItemActionStatusPath = [
  'newPerson',
  'membership',
  'membershipItem',
  'membershipActionsStatus',
];

export const selectMembershipInfoActionLoading = state =>
  state.getIn([...membershipItemActionStatusPath, 'isLoading']);
export const selectMembershipInfoActionResult = state =>
  state.getIn([...membershipItemActionStatusPath, 'result']);

const personMembershipPath = ['newPerson', 'membership', 'personMembership'];

export const selectPersonMembershipLoading = state =>
  state.getIn([...personMembershipPath, 'isLoading']);
export const selectPersonMembership = (state): IBaseInvoicePackageImt =>
  state.getIn([...personMembershipPath, 'membership']);

const membershipInventoryPath = ['newPerson', 'membership', 'membershipInventory'];

export const selectMembershipInventoryLoading = (state: AppStoreTypeImt): boolean => {
  return state.getIn([...membershipInventoryPath, 'isLoading']);
};
export const selectMembershipInventory = (state: AppStoreTypeImt): IPackageInventoryViewDtoImt => {
  return state.getIn([...membershipInventoryPath, 'result']);
};
