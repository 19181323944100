import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Box, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { PaymentField } from 'common/components/index';
import inputLabels from 'common/messages/inputLabels';
import commonMessages from 'common/messages/messages';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import Button from 'common/components/Button/Button';
import { formatPrice } from 'common/utils';
import { IInvoicePaymentSplit, PaymentsType } from 'common/interfaces/invoices';
import { useAppDispatch } from 'store/hooks';
import { useUpdatePaymentData } from '../useUpdatePaymentData';
import {
  selectMemberInvoice,
  selectUpdatedInvoiceLoading,
} from 'memberPortalModules/MemberProfile/state/invoice/selectors';
import { getOnAccountValidationSchema } from 'common/components/InvoiceOperating/InvoicePaymentWizard/steps/onAccount/onAccountValidationSchema';
import { updateInvoiceWithSync } from 'memberPortalModules/MemberProfile/state/invoice/actions';

interface IOnAccountStepProps {
  paymentTypeId: string;
  leftToPay: number;
  balance: number;
  onClose: () => void;
}

const OnAccountStep = (props: IOnAccountStepProps): JSX.Element => {
  const { paymentTypeId, onClose, balance, leftToPay } = props;

  const dispatch = useAppDispatch();

  const currentInvoice = useSelector(selectMemberInvoice);
  const isUpdatingInvoiceLoading = useSelector(selectUpdatedInvoiceLoading);

  const renderIntlMessage = useRenderIntlMessage();

  const { errors, handleSubmit, control } = useForm({
    defaultValues: {
      amount: balance > leftToPay ? leftToPay : balance,
    },
    resolver: yupResolver(getOnAccountValidationSchema(leftToPay, balance)),
  });

  useUpdatePaymentData(onClose);

  const handleProceed = data => {
    const invoiceData = currentInvoice.toJS();
    const invoicePaymentSplit: IInvoicePaymentSplit = {
      paymentMethodId: paymentTypeId,
      type: PaymentsType.ON_ACCOUNT,
      paymentAmount: data.amount,
    };

    dispatch(updateInvoiceWithSync(invoiceData, invoicePaymentSplit));
  };

  return (
    <Box>
      <Box pb={2} display="flex" justifyContent="space-between">
        <Typography color="primary">
          <FormattedMessage {...commonMessages.onAccountLabel} />
        </Typography>
        <Typography color="primary">{formatPrice(balance)}</Typography>
      </Box>
      <Box pt={1} pb={3}>
        <Controller
          control={control}
          name="amount"
          render={({ name, value, onChange, onBlur }) => {
            return (
              <PaymentField
                fullWidth
                variant="outlined"
                name={name}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                defaultValue={0}
                label={<FormattedMessage {...inputLabels.amountToPay} />}
                error={!!errors.amount}
                helperText={renderIntlMessage(errors.amount?.message)}
              />
            );
          }}
        />
      </Box>
      <Box pb={2}>
        <Button
          disabled={isUpdatingInvoiceLoading}
          fullWidth
          color="primary"
          variant="contained"
          onClick={handleSubmit(handleProceed)}
        >
          <FormattedMessage {...commonMessages.proceedBtn} />
        </Button>
      </Box>
    </Box>
  );
};

export default OnAccountStep;
