import React, { useEffect, useRef, useState } from 'react';
import { defaultPriceNumberProps } from 'common/components/NumberController/NumberController';
import { NumberTextField } from 'common/components/index';
import { NumberTextFieldProps } from 'common/components/NumberTextField/NumberTextField';
import { NumberFormatProps } from 'react-number-format';

type PaymentFieldProps = Omit<
  NumberTextFieldProps,
  'onChange' | 'onBlur' | 'numberFormatProps' | 'value'
> & {
  onChange: (value: number) => void;
  onBlur?: (value: number) => void;
  defaultValue?: string | number;
  numberFormatProps?: NumberFormatProps;
  value?: string | number;
};

type EventData = {
  eventType: 'change' | 'blur';
  value: string | number;
};

const MIN_PRICE = '0.01';

const getFieldValue = (fieldValue?: string | number, takeIntoAccountMinPrice = true) => {
  const num = Number(fieldValue) || 0;

  if (takeIntoAccountMinPrice) {
    return num > Number(MIN_PRICE) ? num.toFixed(2) : MIN_PRICE;
  }

  return num.toFixed(2);
};

const PaymentField = (props: PaymentFieldProps): JSX.Element => {
  const { defaultValue, onChange, onBlur, numberFormatProps, value: fieldValue } = props;
  const { max } = numberFormatProps || {};

  const [eventData, setEventData] = useState<EventData>(() => {
    return { eventType: 'change', value: getFieldValue(defaultValue) };
  });

  const onChangeRef = useRef<(val: string | number) => void>(onChange);
  const onBlurRef = useRef(onBlur);
  onChangeRef.current = onChange;
  onBlurRef.current = onBlur;

  useEffect(() => {
    setEventData({ eventType: 'change', value: getFieldValue(fieldValue) });
  }, [fieldValue, setEventData]);

  useEffect(() => {
    const { eventType, value } = eventData;
    if (eventType === 'change') {
      onChangeRef.current(Number(value));
    }

    if (eventType === 'blur' && onBlurRef.current) {
      onBlurRef.current(Number(value));
    }
  }, [eventData]);

  const handleChange = (val: string | number) => {
    if ((max || max === 0) && val > max) {
      setEventData({ eventType: 'change', value: eventData.value });
    } else {
      setEventData({ eventType: 'change', value: getFieldValue(val, false) });
    }
  };

  const handleBlur = () => {
    setEventData({ eventType: 'blur', value: getFieldValue(eventData.value) });
  };

  return (
    <NumberTextField
      {...props}
      value={eventData.value}
      onChange={handleChange}
      onBlur={handleBlur}
      numberFormatProps={numberFormatProps || defaultPriceNumberProps}
    />
  );
};

export default PaymentField;
