import { List, fromJS } from 'immutable';
import { actionTypes } from './constants';
import { IBookingEventImt, IRecentAppointmentItemImt } from 'modules/booking/interfaces';
import { v4 as uuid } from 'uuid';
import { initReqStateImt } from 'common/constants/initialState';

const emergencyContactInitState = fromJS({
  result: {},
  isLoading: false,

  appointmentsHistory: List<IBookingEventImt>(),
  appointmentsHistoryLoading: false,
  appointmentsHistoryMeta: fromJS({ page: 0, perPage: 5, total: 0 }),
  recentAppointments: List<IRecentAppointmentItemImt>(),
  recentAppointmentsLoading: false,
});

const appointmentsReducer = (state = emergencyContactInitState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PERSON_APPOINTMENTS_HISTORY: {
      const appointmentsWithUniqueId = action.payload.data?.map(item => {
        return { ...item, appointmentId: uuid() };
      });
      return state
        .set('appointmentsHistory', fromJS(appointmentsWithUniqueId))
        .set('appointmentsHistoryMeta', fromJS(action.payload.meta));
    }
    case actionTypes.FETCH_PERSON_APPOINTMENTS_HISTORY_LOADING:
      return state.set('appointmentsHistoryLoading', action.payload);
    case actionTypes.FETCH_RECENT_PERSON_APPOINTMENTS: {
      const appointmentsWithUniqueId = action.payload?.map(item => {
        return { ...item, appointmentId: uuid() };
      });
      return state.set('recentAppointments', fromJS(appointmentsWithUniqueId));
    }
    case actionTypes.FETCH_RECENT_PERSON_APPOINTMENTS_LOADING:
      return state.set('recentAppointmentsLoading', action.payload);
    case actionTypes.FETCH_PERSON_APPOINTMENT_DETAILS:
      return state.set('appointment', fromJS(action.payload));
    case actionTypes.FETCH_PERSON_APPOINTMENT_DETAILS_LOADING:
      return state.set('isAppointmentLoading', action.payload);
    case actionTypes.RESET_PERSON_APPOINTMENT_DETAILS:
      return state.set('appointment', fromJS({}));

    case actionTypes.RESET_APPOINTMENT_ACTION_RESULT:
      return state.set('appointmentActionStatus', initReqStateImt);

    default:
      return state;
  }
};

export default appointmentsReducer;
