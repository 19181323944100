import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import { List as ImmutableList } from 'immutable';
import { Box, Typography } from '@material-ui/core';

import { IModificationHistoryImt, RevisionType } from 'common/components/PersonProfile/interfaces';
import { ITableRow } from 'common/interfaces/table';

import { Table } from 'common/components';

import messages from 'modules/booking/messages';
import { DEFAULT_DATE_FORMAT } from 'common/constants/dateFormats';

interface IProps {
  eventHistory: ImmutableList<IModificationHistoryImt>;
}

const ModificationHistory = ({ eventHistory }: IProps): JSX.Element => {
  const createRows = (): ITableRow[] =>
    eventHistory
      .map(
        (historyItem): ITableRow => ({
          id: `${historyItem.get('revisionId')}`,
          cells: [
            {
              label: '',
              cellComponent: (
                <Typography variant="h5">
                  <FormattedMessage
                    {...(historyItem.get('revisionType') === RevisionType.ADD
                      ? messages.eventCreateChangeTypeLabel
                      : messages.eventEditChangeTypeLabel)}
                  />
                </Typography>
              ),
            },
            { label: `${moment(historyItem.get('timestamp')).format(DEFAULT_DATE_FORMAT)}` },
            {
              label: historyItem.getIn(['lastModifiedBy', 'title']),
            },
          ],
        }),
      )
      ?.toJS();

  return (
    <Box>
      <Typography variant="button" color="textSecondary">
        <FormattedMessage {...messages.modificationHistory} />
      </Typography>

      <Table rows={createRows()} isLoading={false} hidePagination hideToolbar hideSearchInput />
    </Box>
  );
};

export default ModificationHistory;
