import React from 'react';
import { useSelector } from 'react-redux';
import { List as ImmutableList } from 'immutable';

import { ICorporationItemImt } from 'modules/authentication/interfaces';

import * as selectors from 'modules/authentication/state/selectors';

import { Login } from 'modules/authentication/components';

const SignIn = (): JSX.Element => {
  const signInError = useSelector(selectors.selectSignInError);
  const isLoading: boolean = useSelector(selectors.selectSignInLoading);
  const corporationsForSignIn: ImmutableList<ICorporationItemImt> = useSelector(
    selectors.selectCorporationsForSignIn,
  );
  const isCorporationsForSignInLoading: boolean = useSelector(
    selectors.selectCorporationsForSignInLoading,
  );

  return (
    <Login
      signInError={signInError}
      isLoading={isLoading || isCorporationsForSignInLoading}
      corporations={corporationsForSignIn}
    />
  );
};

export default SignIn;
