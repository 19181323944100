import messages from './messages';
import { SignType } from 'common/constants/contract';

export const SIGNATURE_Y_SIZE = 177;

export const signingTypeOptions = [
  { value: SignType.ViaTopaz, messageDescriptor: messages.signViaTopaz },
  { value: SignType.InPerson, messageDescriptor: messages.signInPerson },
  {
    value: SignType.SendViaEmail,
    messageDescriptor: messages.signViaEmail,
    name: 'emailAvailable',
  },
  { value: SignType.SendViaSMS, messageDescriptor: messages.signViaSMS, name: 'smsAvailable' },
];
