import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography, Box, ListItem, Theme, makeStyles } from '@material-ui/core';

import useTimezoneMoment from 'common/hooks/useTimezoneMoment';

import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';

import { ICampaignDtoImt } from 'common/interfaces/campaign';

import messages from 'common/messages/messages';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'block',
    padding: theme.spacing(0, 2, 0, 0),
  },
  itemInner: {
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
    padding: theme.spacing(1, 0, 1.5),
  },
  itemTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    fontWeight: 500,
  },
  itemDate: {
    fontSize: '0.75rem',
  },
  itemType: {
    fontSize: '0.625rem',
    color: theme.palette.text.secondary,
  },
  actionIcon: {
    padding: 0,
    marginLeft: theme.spacing(1),
    opacity: 0.4,
  },
}));

interface IProps {
  campaign: ICampaignDtoImt;
}

const CampaignsItem = ({ campaign }: IProps): JSX.Element => {
  const [timezoneMoment] = useTimezoneMoment();

  const classes = useStyles();

  return (
    <ListItem className={classes.root}>
      <Box
        className={classes.itemInner}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        gridGap={4}
      >
        <Typography className={classes.itemTitle}>
          <Typography component="span" variant="inherit">
            {campaign.get('title')}
          </Typography>

          {campaign.get('createdDate') ? (
            <Typography component="span" color="textSecondary" className={classes.itemDate}>
              {timezoneMoment(campaign.get('createdDate')).format(DEFAULT_DATE_TIME_FORMAT)}
            </Typography>
          ) : (
            <>-</>
          )}
        </Typography>

        <Typography variant="button" className={classes.itemType}>
          <FormattedMessage
            {...(campaign.get('automatic') ? messages.automaticLabel : messages.manualLabel)}
          />
        </Typography>
      </Box>
    </ListItem>
  );
};

export default CampaignsItem;
