import { List } from 'immutable';
import { IPageMetaImt } from 'common/interfaces/pagination';
import { IFeeImt } from '../../interfaces/fees';

const feesDomain = ['services', 'fees'];

export const selectFeesList = (state): List<IFeeImt> =>
  state.getIn([...feesDomain, 'feesList', 'list']);
export const selectFeesListLoading = (state): boolean =>
  state.getIn([...feesDomain, 'feesList', 'isLoading']);
export const selectFeesListMeta = (state): IPageMetaImt =>
  state.getIn([...feesDomain, 'feesList', 'meta']);

export const selectFeeDetails = (state): IFeeImt =>
  state.getIn([...feesDomain, 'feeDetails', 'result']);
export const selectFeeDetailsLoading = (state): boolean =>
  state.getIn([...feesDomain, 'feeDetails', 'isLoading']);

export const selectFeeActionResult = (state): IFeeImt =>
  state.getIn([...feesDomain, 'action', 'result']);
export const selectFeeActionLoading = (state): boolean =>
  state.getIn([...feesDomain, 'action', 'isLoading']);
