// Cancel membership
export const CANCEL_PERSON_MEMBERSHIP = 'CANCEL_PERSON_MEMBERSHIP';
export const CANCEL_PERSON_MEMBERSHIP_LOADING = 'CANCEL_PERSON_MEMBERSHIP_LOADING';
export const RESET_CANCEL_PERSON_MEMBERSHIP = 'RESET_CANCEL_PERSON_MEMBERSHIP';

// Freeze membership
export const FETCH_FREEZING_ITEMS = 'FETCH_FREEZING_ITEMS';
export const FETCH_FREEZING_ITEMS_LOADING = 'FETCH_FREEZING_ITEMS_LOADING';
export const RESET_FREEZING_ITEMS = 'RESET_FREEZING_ITEMS';

export const FETCH_FREEZE_HISTORY = 'FETCH_FREEZE_HISTORY';
export const FETCH_FREEZE_HISTORY_LOADING = 'FETCH_FREEZE_HISTORY_LOADING';

export const FREEZE_PERSONS_MEMBERSHIP = 'FREEZE_PERSONS_MEMBERSHIP';
export const FREEZE_PERSONS_MEMBERSHIP_LOADING = 'FREEZE_PERSONS_MEMBERSHIP_LOADING';
export const FREEZE_PERSONS_MEMBERSHIP_ERROR = 'FREEZE_PERSONS_MEMBERSHIP_ERROR';
export const RESET_FREEZE_PERSONS_MEMBERSHIP = 'RESET_FREEZE_PERSONS_MEMBERSHIP';

export const FETCH_REACTIVATE_ITEMS = 'FETCH_REACTIVATE_ITEMS';
export const FETCH_REACTIVATE_ITEMS_LOADING = 'FETCH_REACTIVATE_ITEMS_LOADING';
export const RESET_REACTIVATE_ITEMS = 'RESET_REACTIVATE_ITEMS';

export const FETCH_CANCEL_ITEMS = 'FETCH_CANCEL_ITEMS';
export const FETCH_CANCEL_ITEMS_LOADING = 'FETCH_CANCEL_ITEMS_LOADING';
export const RESET_CANCEL_ITEMS = 'RESET_CANCEL_ITEMS';

export const FETCH_SUB_MEMBERS_LIST = 'FETCH_SUB_MEMBERS_LIST';
export const FETCH_SUB_MEMBERS_LIST_LOADING = 'FETCH_SUB_MEMBERS_LIST_LOADING';

// membership actions fetchAvailableMembershipPackageActions

export const FETCH_AVAILABLE_MEMBERSHIP_PACKAGE_ACTIONS =
  'FETCH_AVAILABLE_MEMBERSHIP_PACKAGE_ACTIONS';
export const FETCH_AVAILABLE_MEMBERSHIP_PACKAGE_ACTIONS_LOADING =
  'FETCH_AVAILABLE_MEMBERSHIP_PACKAGE_ACTIONS_LOADING';
export const RESET_AVAILABLE_MEMBERSHIP_PACKAGE_ACTIONS =
  'RESET_AVAILABLE_MEMBERSHIP_PACKAGE_ACTIONS';

export const FETCH_MEMBERSHIP_SUCCESS = 'FETCH_MEMBERSHIP_SUCCESS';
export const FETCH_MEMBERSHIP_LOADING = 'FETCH_MEMBERSHIP_LOADING';
export const RESET_MEMBERSHIP = 'RESET_MEMBERSHIP';

export const FETCH_PERSON_MEMBERSHIP_PACKAGE_INVENTORY_SUCCESS =
  'FETCH_PERSON_MEMBERSHIP_PACKAGE_INVENTORY_SUCCESS';
export const FETCH_PERSON_MEMBERSHIP_PACKAGE_INVENTORY_LOADING =
  'FETCH_PERSON_MEMBERSHIP_PACKAGE_INVENTORY_LOADING';
export const RESET_PERSON_MEMBERSHIP_PACKAGE_INVENTORY =
  'RESET_PERSON_MEMBERSHIP_PACKAGE_INVENTORY';
