import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { DialogComponent } from 'common/components/index';

import timeclockMessages from 'modules/timeclock/messages';
import AddUnitDataForm from './AddUnitDataForm';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAddUnitResult,
  selectPayrollJobPositionsLoading,
} from 'modules/timeclock/state/selectors';
import {
  addClockInManuallyData,
  addClockInUnitManuallyDataAction,
  getTimeclockJobPositions,
} from 'modules/timeclock/state/actions';
import { IAddClockInManuallyData } from 'modules/timeclock/interfaces/timeclock';

interface IProps {
  isOpen: boolean;
  isLoading?: boolean;
  employeeId: string;
  onClose: () => void;
  isProfile?: boolean;
}

const AddUnitDataModal = ({
  isOpen,
  isLoading = false,
  employeeId,
  onClose,
  isProfile = false,
}: IProps): JSX.Element => {
  const dispatch = useDispatch();
  const formId = 'add-unit-data-form';

  const jobPositionsOptionsLoading = useSelector(selectPayrollJobPositionsLoading);
  const addUnitResult = useSelector(selectAddUnitResult);

  useEffect(() => {
    dispatch(getTimeclockJobPositions(employeeId, isProfile, true));
  }, [dispatch, employeeId, isProfile]);

  useEffect(() => {
    if (addUnitResult) {
      onClose();
      dispatch(addClockInUnitManuallyDataAction(null));
    }
  }, [addUnitResult, onClose, dispatch]);

  const addClockIn = (data: IAddClockInManuallyData) => {
    dispatch(addClockInManuallyData(employeeId, true, data, isProfile));
  };

  return (
    <DialogComponent
      cancelBtn
      submitBtn
      isOpen={isOpen}
      loading={isLoading || jobPositionsOptionsLoading}
      onClose={onClose}
      title={<FormattedMessage {...timeclockMessages.addUnitTitle} />}
      size="xs"
      supressBottomShadow
      hasShadowsOnScroll={false}
      formId={formId}
    >
      <AddUnitDataForm onSubmit={addClockIn} formId={formId} employeeId={employeeId} />
    </DialogComponent>
  );
};

export default AddUnitDataModal;
