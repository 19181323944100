import { ActionResult } from 'common/constants';
import { IServerError } from 'common/interfaces/http';
import { IPaginatedData } from 'common/interfaces/pagination';
import { IBookingEvent, IBookingEventImt } from 'modules/booking/interfaces';
import { IPaginatedPayPeriods, IPeriodItem } from 'modules/corporations/interfaces';
import {
  IClockedInEmployee,
  IPaginatedClockInData,
  IPaginatedMyPayrollReportList,
  IPaginatedPayrollReportList,
  IPaginatedUnitData,
  IPayrollStatistics,
  ITimeclockListItem,
} from '../interfaces/timeclock';

const timeclockDomain = ['timeclock', 'timeclockActions'];

const selectTimeclockJobPositions = state => {
  return state.getIn([...timeclockDomain, 'timeclockJobPositions', 'result']);
};

const selectTimeclockJobPositionsLoading = (state): boolean => {
  return state.getIn([...timeclockDomain, 'timeclockJobPositions', 'isLoading']);
};

const selectDeleteTimeclockResult = (state): ActionResult => {
  return state.getIn([...timeclockDomain, 'deleteTimeclockByIdResult']);
};

const selectTimeclockList = state => {
  return state.getIn([...timeclockDomain, 'timeclockList', 'result']);
};

const selectTimeclockListLoading = (state): boolean => {
  return state.getIn([...timeclockDomain, 'timeclockList', 'isLoading']);
};

const selectClockedInActionResult = state => {
  return state.getIn([...timeclockDomain, 'clockedInActionResult']);
};

const selectUnlockModeTimeclockResult = state => {
  return state.getIn([...timeclockDomain, 'unlockModeTimeclockResult']);
};

const selectUnlockModeTimeclockLoading = (state): boolean => {
  return state.getIn([...timeclockDomain, 'isUnlockModeTimeclockLoading']);
};

const selectUnlockModeTimeclockError = state => {
  return state.getIn([...timeclockDomain, 'unlockModeTimeclockError']);
};

const selectClockInEmployeeActionResult = (state): IClockedInEmployee => {
  return state.getIn([...timeclockDomain, 'clockInEmployeeActionResult']);
};

const selectClockInEmployeeError = (state): IServerError => {
  return state.getIn([...timeclockDomain, 'clockInEmployeeError']);
};

const selectEventDetails = (state): IBookingEventImt => {
  return state.getIn([...timeclockDomain, 'eventDetails']);
};

const selectEventDetailsLoading = (state): boolean => {
  return state.getIn([...timeclockDomain, 'eventDetailsLoading']);
};

const selectAllEvent = state => {
  return state.getIn([...timeclockDomain, 'allEvents']);
};

const selectAllEventLoading = (state): boolean => {
  return state.getIn([...timeclockDomain, 'allEventsLoading']);
};

// Payroll report

const payrollReportDomain = ['timeclock', 'payrollReportActions'];

const selectPayrollReportData = (state): IPaginatedPayrollReportList => {
  return state.getIn([...payrollReportDomain, 'payrollReportList', 'result']);
};

const selectPayrollReportLoading = (state): boolean => {
  return state.getIn([...payrollReportDomain, 'payrollReportList', 'isLoading']);
};

const selectClockInDataList = (state): IPaginatedClockInData => {
  return state.getIn([...payrollReportDomain, 'clockInDataList', 'result']);
};

const selectClockInDataLoading = (state): boolean => {
  return state.getIn([...payrollReportDomain, 'clockInDataList', 'isLoading']);
};

const selectUnitDataList = (state): IPaginatedUnitData => {
  return state.getIn([...payrollReportDomain, 'unitDataList', 'result']);
};

const selectUnitDataLoading = (state): boolean => {
  return state.getIn([...payrollReportDomain, 'unitDataList', 'isLoading']);
};

const selectMyPayrollReportData = (state): IPaginatedMyPayrollReportList => {
  return state.getIn([...payrollReportDomain, 'myPayrollReportList', 'result']);
};

const selectMyPayrollReportLoading = (state): boolean => {
  return state.getIn([...payrollReportDomain, 'myPayrollReportList', 'isLoading']);
};

const selectPayrollStatsData = (state): IPayrollStatistics => {
  return state.getIn([...payrollReportDomain, 'payrollStatistics', 'result']);
};

const selectPayrollStatsLoading = (state): boolean => {
  return state.getIn([...payrollReportDomain, 'payrollStatistics', 'isLoading']);
};

const selectAddClockInResult = (state): ITimeclockListItem => {
  return state.getIn([...payrollReportDomain, 'addClockInResult']);
};

const selectAddUnitResult = (state): ITimeclockListItem => {
  return state.getIn([...payrollReportDomain, 'addUnitResult']);
};

const selectAllTimeclockEvents = (state): IPaginatedData<IBookingEvent> => {
  return state.getIn([...payrollReportDomain, 'getAllTimeclockEvents', 'result']);
};

const selectAllTimeclockEventsLoading = (state): boolean => {
  return state.getIn([...payrollReportDomain, 'getAllTimeclockEvents', 'isLoading']);
};

const selectPayrollPayPeriods = (state): IPaginatedPayPeriods => {
  return state.getIn([...payrollReportDomain, 'payrollPayPeriods', 'result']);
};

const selectCurrentPayrollPayPeriod = (state): IPeriodItem | null => {
  return state.getIn([...payrollReportDomain, 'currentPayPeriod']);
};

const selectPayrollJobPositions = state => {
  return state.getIn([...payrollReportDomain, 'payrollJobPositions', 'result']);
};

const selectPayrollJobPositionsLoading = (state): boolean => {
  return state.getIn([...payrollReportDomain, 'payrollJobPositions', 'isLoading']);
};

const selectDeletePayrollItemResult = state => {
  return state.getIn([...payrollReportDomain, 'deletePayrollItemResult']);
};

const selectDeleteClockInItemResult = state => {
  return state.getIn([...payrollReportDomain, 'deleteClockInItemResult']);
};

const selectDeleteUnitDataItemResult = state => {
  return state.getIn([...payrollReportDomain, 'deleteUnitDataItemResult']);
};

const selectEmployeeInPayrollReport = state => {
  return state.getIn([...payrollReportDomain, 'selectedEmployeePayrollReport']);
};

const selectPayrollReportActivity = state =>
  state.getIn([...payrollReportDomain, 'payrollReportActivity', 'result']);

const selectPayrollReportActivityLoading = state =>
  state.getIn([...payrollReportDomain, 'payrollReportActivity', 'isLoading']);

export {
  selectEmployeeInPayrollReport,
  selectTimeclockJobPositions,
  selectTimeclockJobPositionsLoading,
  selectTimeclockList,
  selectTimeclockListLoading,
  selectDeleteTimeclockResult,
  selectClockedInActionResult,
  selectUnlockModeTimeclockLoading,
  selectUnlockModeTimeclockResult,
  selectUnlockModeTimeclockError,
  selectClockInEmployeeError,
  selectEventDetails,
  selectEventDetailsLoading,
  selectAllEvent,
  selectAllEventLoading,
  selectClockInEmployeeActionResult,
  selectPayrollReportData,
  selectPayrollReportLoading,
  selectClockInDataList,
  selectClockInDataLoading,
  selectUnitDataList,
  selectUnitDataLoading,
  selectMyPayrollReportData,
  selectMyPayrollReportLoading,
  selectPayrollStatsData,
  selectPayrollStatsLoading,
  selectAddClockInResult,
  selectAddUnitResult,
  selectAllTimeclockEventsLoading,
  selectAllTimeclockEvents,
  selectPayrollPayPeriods,
  selectPayrollJobPositions,
  selectPayrollJobPositionsLoading,
  selectDeletePayrollItemResult,
  selectDeleteClockInItemResult,
  selectDeleteUnitDataItemResult,
  selectCurrentPayrollPayPeriod,
  selectPayrollReportActivity,
  selectPayrollReportActivityLoading,
};
