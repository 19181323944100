import React from 'react';
import {
  FormControlLabel,
  Checkbox,
  createStyles,
  makeStyles,
  Typography,
  withStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    checkbox: {
      padding: 0,
    },
    label: {
      fontWeight: 'bold',
    },
  }),
);

const StyledCheckbox = withStyles({
  checked: {
    color: '#3b99fc',
  },
})(Checkbox);

interface ISalesChartFilterProps {
  label: string | JSX.Element;
  color: string;
  isSelected?: boolean;
  onChange?: (name, value) => void;
}

const ChartFilter: React.FC<ISalesChartFilterProps> = ({
  label,
  color,
  isSelected,
  onChange,
}: ISalesChartFilterProps) => {
  const classes = useStyles();

  const handleChange = e => {
    onChange(label, e.target.checked);
  };

  return (
    <FormControlLabel
      control={
        <StyledCheckbox
          className={classes.checkbox}
          checked={isSelected}
          onClick={handleChange}
          size="small"
          style={{ color }}
        />
      }
      label={
        <Typography className={classes.label} style={{ color }}>
          {label}
        </Typography>
      }
    />
  );
};

export default ChartFilter;
