import React, { useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { FamilyMembersStep } from 'common/components';
import {
  fetchFamilyMembersThunk,
  resetCreateFamilyMemberResult,
} from 'common/state/newPerson/familyMembers/actions';
import {
  selectAddFamilyMemberActionLoading,
  selectAddFamilyMemberResult,
  selectCreateMemberForFamilyMemberStepLoading,
  selectCreateMemberForFamilyMemberStepResult,
  selectFamilyMembers,
  selectFetchFamilyMembersLoading,
} from 'common/state/newPerson/familyMembers/selectors';
import { ActionResult } from 'common/constants';
import { ILeadProfile } from 'modules/crm/interfaces/leads';
import { ImmutableObject } from 'common/state/interfaces';
import { selectPrimaryInfo } from 'common/state/newPerson/primaryInfo/selectors';
import { StepContext } from 'common/createContext/stepContext';
import { deepEquals } from 'common/utils';
import * as memberActions from 'common/state/newPerson/familyMembers/actions';
import { IFamilyMemberEditDTO } from 'common/components/PersonProfile/interfaces';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { useAppDispatch } from 'store/hooks';
import { selectUserSelectedClubId } from 'modules/authentication/state/selectors';

interface IFamilyMembersStepContainerProps {
  leadPrimaryInfo?: ImmutableObject<ILeadProfile>;
  module: PeakModuleForNewPersonType;
}

const FamilyMembersStepContainer = (props: IFamilyMembersStepContainerProps): JSX.Element => {
  const { leadPrimaryInfo, module } = props;

  const dispatch = useAppDispatch();

  const actionCreateFamilyMemberResult = useSelector(selectAddFamilyMemberResult);
  const primaryInfo = useSelector(selectPrimaryInfo);
  const familyMembersList = useSelector(selectFamilyMembers);
  const createMemberResult = useSelector(selectCreateMemberForFamilyMemberStepResult);
  const createMemberLoading = useSelector(selectCreateMemberForFamilyMemberStepLoading);
  const isLoading = useSelector(selectFetchFamilyMembersLoading);
  const isAddFamilyMembersActionLoading: boolean = useSelector(selectAddFamilyMemberActionLoading);
  const clubId = useSelector(selectUserSelectedClubId);

  const { onNext } = useContext(StepContext);

  const convertedFamilyMembersList = useMemo(() => familyMembersList.toJS(), [familyMembersList]);

  const personId = leadPrimaryInfo?.get('id') || primaryInfo?.get('id');
  const firstName = leadPrimaryInfo?.get('firstName') || primaryInfo?.get('firstName');

  const isResponseSuccess = actionCreateFamilyMemberResult === ActionResult.SUCCESS_ACTION;

  useEffect(() => {
    if (personId) {
      dispatch(fetchFamilyMembersThunk(personId, module));
    }
  }, [dispatch, personId, module]);

  useEffect(() => {
    if (isResponseSuccess) {
      onNext({ familyMembers: convertedFamilyMembersList });
    }
  }, [isResponseSuccess, convertedFamilyMembersList, onNext]);

  useEffect(() => {
    return () => {
      dispatch(resetCreateFamilyMemberResult());
    };
  }, [dispatch]);

  const onHandleSubmit = (data: IFamilyMemberEditDTO) => {
    if (data && !deepEquals(data, convertedFamilyMembersList)) {
      dispatch(memberActions.saveFamilyMembers(personId, data, module));
    } else {
      onNext({ familyMembers: convertedFamilyMembersList });
    }
  };

  return (
    <FamilyMembersStep
      module={module}
      clubId={clubId}
      memberInfoId={primaryInfo?.get('id')}
      createMemberResult={createMemberResult}
      createMemberLoading={createMemberLoading}
      initialFamilyMemberId={personId}
      familyMembers={convertedFamilyMembersList}
      memberFirstName={firstName}
      onHandleSubmit={onHandleSubmit}
      isLoading={isLoading}
      isSubmitting={isAddFamilyMembersActionLoading}
    />
  );
};

export default FamilyMembersStepContainer;
