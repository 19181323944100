import { createAction } from '@reduxjs/toolkit';
import Services from 'services/network';
import { actionTypes } from '../../constants';

import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from 'common/state/notifications/actions';

import { ITableParams } from 'common/interfaces/table';
import { GeneralThunkAction } from 'common/state/interfaces';
import { IRoleFormData } from 'modules/permissions/interfaces/roles';

import messages from 'modules/permissions/messages/messages';

const fetchRolesListLoading = createAction<boolean>(actionTypes.FETCH_ROLES_LIST_LOADING);
const fetchRolesListAction = createAction<any>(actionTypes.FETCH_ROLES_LIST);
export const resetRolesList = createAction(actionTypes.RESET_ROLES_LIST);

export const fetchRolesList = (requestOptions?: ITableParams): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchRolesListLoading(true));

    try {
      const paginatedData = await Services.Permissions.getRolesList(requestOptions);
      dispatch(fetchRolesListAction(paginatedData));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchRolesListLoading(false));
    }
  };
};

const fetchRoleItemLoading = createAction<boolean>(actionTypes.FETCH_ROLE_ITEM_LOADING);
const fetchRoleItemAction = createAction<any>(actionTypes.FETCH_ROLE_ITEM);

export const fetchRoleItem = (id: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchRoleItemLoading(true));

    try {
      const response = await Services.Permissions.getRoleItem(id);
      dispatch(fetchRoleItemAction(response));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchRoleItemLoading(false));
    }
  };
};

export const resetRoleItemData = createAction(actionTypes.RESET_ROLE_ITEM_DATA);

const editRoleItemAction = createAction<any>(actionTypes.EDIT_ROLE_ITEM_SUCCESS);

export const editRoleItem = (id: string, data: IRoleFormData): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchRoleItemLoading(true));

    try {
      const response = await Services.Permissions.editRoleItem(id, data);
      dispatch(editRoleItemAction({ success: !!response }));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchRoleItemLoading(false));
    }
  };
};

export const changeRoleItemStatus = (id: string, active: boolean): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchRoleItemLoading(true));

    try {
      const response = await Services.Permissions.changeRoleItemStatus(id, active);
      dispatch(editRoleItemAction({ success: !!response }));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchRoleItemLoading(false));
    }
  };
};

export const resetRoleItemActionResult = createAction(actionTypes.RESET_ROLE_ITEM_ACTION_RESULT);

const createRoleItemAction = createAction<any>(actionTypes.CREATE_ROLE_ITEM_SUCCESS);
const createRoleItemLoading = createAction<any>(actionTypes.CREATE_ROLE_ITEM_LOADING);

export const createRoleItem = (data: IRoleFormData): GeneralThunkAction => {
  return async dispatch => {
    dispatch(createRoleItemLoading(true));

    try {
      const response = await Services.Permissions.createRoleItem(data);
      dispatch(createRoleItemAction({ success: !!response }));
      dispatch(enqueueSuccessNotification(messages.roleItemSuccessCreatedMessage));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(createRoleItemLoading(false));
    }
  };
};

export const changeStatusRoleItem = (id: string, active: boolean): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchRoleItemLoading(true));

    try {
      const response = await Services.Permissions.changeRoleItemStatus(id, active);
      dispatch(editRoleItemAction({ success: !!response }));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchRoleItemLoading(false));
    }
  };
};
