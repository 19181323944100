import * as actions from 'common/state/printHTML/actions';
import { RequestActions } from 'common/state/interfaces';

const initialState = {
  htmlResult: { html: null },
  silentPrintHTMLResult: { html: null },
  setPrinterModalState: { isOpenSetPrinterModal: false, printSessionExists: false },
  isLoading: false,
};

export const printHTMLReducer = (state = initialState, action: RequestActions) => {
  switch (action.type) {
    case actions.printHTML.type:
      return { ...state, htmlResult: { html: action.payload } };
    case actions.silentPrintHTML.type:
      return { ...state, silentPrintHTMLResult: { html: action.payload } };
    case actions.printHTMLLoading.type:
      return { ...state, isLoading: action.payload };
    case actions.setPrinterModalState.type:
      return {
        ...state,
        setPrinterModalState: { ...state.setPrinterModalState, ...action.payload },
      };
    default:
      return state;
  }
};
