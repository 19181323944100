import React, { useState } from 'react';
import {
  Box,
  createStyles,
  FormControlLabel,
  FormHelperText,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import Tree, { TreeNodeProps } from 'rc-tree';
import { DataNode } from 'rc-tree/lib/interface';
import { IPermissionItem } from 'modules/permissions/interfaces/permissions';
import { Checkbox } from 'common/components';
// icons
import { ChevronRight, ExpandMore } from '@material-ui/icons';
import check from 'img/icons/check.svg';
import checked from 'img/icons/checkbox-checked.svg';
import checkboxBlank from 'img/icons/checkbox-blank.svg';
import checkedIndeterminate from 'img/icons/checkbox_indeterminate.svg';
import { CustomTheme } from 'common/ui/interfaces';
// messages
import { FormattedMessage } from 'react-intl';
import permissionsMessages from '../../messages/messages';
import inputLabels from 'common/messages/inputLabels';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    treeWrapper: {},
    expand: {
      width: 20,
      height: 20,
      cursor: 'pointer',
    },
    treeNode: {
      display: 'flex',
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.borderColor.main}`,
      '& .rc-tree-indent': {
        display: 'flex',
        alignItems: 'center',
      },
      '& .rc-tree-indent-unit': {
        width: 20,
        display: 'block',
      },
      '& .rc-tree-switcher-noop': {
        opacity: 0,
      },
      '& .rc-tree-node-content-wrapper': {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1.25, 0, 1.25, 1),
        position: 'relative',
        '& .rc-tree-title': {
          fontSize: '0.75rem',
        },
      },
      '& .rc-tree-checkbox': {
        display: 'block',
        width: 20,
        height: 20,
        maskSize: 'contain',
        maskRepeat: 'no-repeat',
        maskPosition: 'center',
        backgroundColor: theme.palette.darkBackground.main,
        position: 'absolute',
        right: 0,
        zIndex: 10,
        '-webkit-mask': `url(${checkboxBlank})`,
        mask: `url(${checkboxBlank})`,
        '&.rc-tree-checkbox-checked': {
          '-webkit-mask': `url(${checked})`,
          mask: `url(${checked})`,
          backgroundColor: theme.palette.primary.main,
        },
        '&.rc-tree-checkbox-indeterminate': {
          '-webkit-mask': `url(${checkedIndeterminate})`,
          mask: `url(${checkedIndeterminate})`,
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    treeNodeNotCheckable: {
      '& .rc-tree-node-content-wrapper': {
        padding: theme.spacing(1, 1, 1, 0),
      },
    },
    treeNodeChecked: {
      '& .rc-tree-node-content-wrapper': {
        padding: theme.spacing(1, 4, 1, 0),
        '&:before': {
          content: "''",
          display: 'block',
          position: 'absolute',
          right: theme.spacing(1),
          width: 18,
          height: 12,
          maskSize: 'contain',
          maskRepeat: 'no-repeat',
          maskPosition: 'center',
          backgroundColor: theme.palette.primary.main,
          mask: `url(${check})`,
        },
      },
    },
  }),
);
interface ICollapsedComponentProps {
  permissions: Array<IPermissionItem>;
  permissionSet: Array<string>;
  isCheckable?: boolean;
  onChange?: (permissionSet: Array<string>) => void;
  onHideUnchecked?: (isChecked: boolean) => void;
  hideUnchecked?: boolean;
  error?: boolean;
  helperText?: string;
}

export default function CollapsedComponent({
  permissions,
  isCheckable,
  permissionSet,
  hideUnchecked,
  error,
  helperText,
  onHideUnchecked,
  onChange,
}: ICollapsedComponentProps): JSX.Element {
  const classes = useStyles();
  const [expandedKeys, setExpandedKeys] = useState(permissionSet);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const onExpand = keys => {
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded chilren keys.
    setExpandedKeys(keys);
    setAutoExpandParent(false);
  };

  const renderExpand = (props: TreeNodeProps) => {
    const { expanded } = props;
    return expanded ? (
      <ExpandMore className={classes.expand} />
    ) : (
      <ChevronRight className={classes.expand} />
    );
  };

  const makeTreeData = (array: Array<IPermissionItem>): Array<DataNode> =>
    array?.map((item: IPermissionItem) => ({
      ...item,
      children: makeTreeData(item.children),
      className: clsx(classes.treeNode, {
        [classes.treeNodeNotCheckable]: !isCheckable,
        [classes.treeNodeChecked]: !isCheckable && permissionSet?.includes(item.key),
      }),
    }));

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography color="textSecondary" variant="button">
            <FormattedMessage {...permissionsMessages.permissions} />
          </Typography>
          {!isCheckable && (
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={hideUnchecked}
                  onChange={e => {
                    onHideUnchecked(e.target.checked);
                  }}
                />
              }
              label={<FormattedMessage {...inputLabels.hideUnchecked} />}
            />
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        {/* TODO add search */}
        {/* <ToolbarSearchInput
        value=""
        hasBackground
        onChange={() => {}}
        placeholder={intl.formatMessage(permissionsMessages.searchPermissions)}
      /> */}
        <Tree
          className={classes.treeWrapper}
          checkable={isCheckable}
          onExpand={onExpand}
          expandedKeys={expandedKeys}
          autoExpandParent={autoExpandParent}
          onCheck={onChange}
          checkedKeys={permissionSet}
          switcherIcon={renderExpand}
          treeData={makeTreeData(permissions)}
        />
      </Grid>
      {error && (
        <Grid item xs={12}>
          <FormHelperText error>{helperText}</FormHelperText>
        </Grid>
      )}
    </Grid>
  );
}
