import React from 'react';
import { DialogComponent } from 'common/components';
import { FormattedMessage } from 'react-intl';
// messages
import messages from 'common/messages/messages';

interface IChangeScriptStatusModalProps {
  scriptTitle: string;
  isOpen: boolean;
  isLoading: boolean;
  scriptNewStatus: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const ChangeScriptStatusModal = (props: IChangeScriptStatusModalProps): JSX.Element => {
  const { scriptTitle, scriptNewStatus, isOpen, onClose, onSubmit, isLoading } = props;

  return (
    <DialogComponent
      size="xs"
      title={
        scriptNewStatus ? (
          <FormattedMessage {...messages.activateItem} values={{ name: scriptTitle }} />
        ) : (
          <FormattedMessage {...messages.deactivateItem} values={{ name: scriptTitle }} />
        )
      }
      submitBtnTitle={
        scriptNewStatus ? (
          <FormattedMessage {...messages.activateBtn} />
        ) : (
          <FormattedMessage {...messages.deactivateBtn} />
        )
      }
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      disableFullScreenBehavior
      disabled={isLoading}
    />
  );
};

export default ChangeScriptStatusModal;
