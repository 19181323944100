import React from 'react';
import { Box, createStyles, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import cx from 'classnames';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    symbols: {
      position: 'absolute',
      bottom: error => (error ? '23px' : '8px'),
      right: '8px',
    },
    root: {
      '& .MuiOutlinedInput-multiline': {
        paddingBottom: theme.spacing(3),
      },
    },
  }),
);

interface IProps extends Omit<TextFieldProps, ''> {
  value: string;
  className?: string;
  maxSymbols?: number;
  onInputRef?: (ref) => void;
}

const TextArea = ({
  value,
  variant,
  className,
  maxSymbols,
  error = false,
  onInputRef,
  ...props
}: IProps): JSX.Element => {
  const classes = useStyles(error);

  return (
    <Box position="relative" display="flex" flex={1} flexDirection="column">
      <TextField
        inputRef={onInputRef}
        className={cx(classes.root, className)}
        value={value}
        inputProps={{ maxLength: maxSymbols }}
        variant={variant}
        multiline
        error={error}
        {...props}
      />

      {maxSymbols && (
        <Box className={classes.symbols}>
          <Typography color="textSecondary" variant="body2">
            {`${value?.length || 0}/${maxSymbols}`}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default TextArea;
