import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import {
  Box,
  createStyles,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

import { selectCurrentUserSelectedLevel } from 'modules/authentication/state/selectors';

import { PermissionLevels } from 'common/constants/permissions';

import { IRoutes } from 'common/interfaces/routes';

import commonMessages from 'common/messages/messages';
import { snackbar } from 'common/utils/snackbarUtils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    listItem: {
      padding: theme.spacing(1.25, 0),
    },
    listItemLink: {
      padding: theme.spacing(1.25, 1.5),
      '&:hover': {
        textDecoration: 'underline',
      },
      '& .MuiListItemText-root': {
        margin: 0,
      },
    },
    activeLink: {
      backgroundColor: theme.palette.primary.light,
      '&:hover': {
        textDecoration: 'none',
      },

      '& .MuiListItemText-primary': {
        color: `${theme.palette.primary.main} !important`,
      },
    },
    listItemText: {
      '& .MuiListItemText-primary': {
        color: theme.palette.secondary.main,
        fontWeight: 500,
      },
    },
  }),
);

interface IModuleSidebarProps {
  routes: Array<IRoutes>;
}

const CustomNavLink = React.forwardRef<HTMLAnchorElement>(
  ({ to, className, ...otherProps }: NavLinkProps, ref) => {
    const classes = useStyles();

    return (
      <NavLink
        ref={ref}
        to={to}
        {...otherProps}
        className={({ isActive }) => cx(className as string, { [classes.activeLink]: isActive })}
      />
    );
  },
);

const Sidebar = ({ routes }: IModuleSidebarProps): JSX.Element => {
  const currentUserLevel: PermissionLevels = useSelector(selectCurrentUserSelectedLevel);

  const classes = useStyles();
  return (
    <Box className={classes.root}>
      {routes.map(({ type, path, title, disabled, message, levels }) => {
        if (levels?.length && !levels.includes(currentUserLevel)) {
          return null;
        }

        return type === 'bookmark' ? (
          <Typography
            variant="h5"
            color="textSecondary"
            key={message ? message.id : title}
            className={classes.listItem}
          >
            {message ? <FormattedMessage {...message} /> : title}
          </Typography>
        ) : (
          <ListItem
            // TODO MATERIAL fix property component and activeClassName in MUI V5
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            component={CustomNavLink}
            to={path}
            key={message ? message.id : title}
            title={title}
            className={classes.listItemLink}
            onClick={event => {
              if (disabled) {
                event.preventDefault();
                event.stopPropagation();
                snackbar.warning(<FormattedMessage {...commonMessages.notImplementedYet} />);
              }
            }}
          >
            <ListItemText className={classes.listItemText}>
              {message ? <FormattedMessage {...message} /> : title}
            </ListItemText>
          </ListItem>
        );
      })}
    </Box>
  );
};

export default Sidebar;
