import { createAction } from '@reduxjs/toolkit';

import { ActionResult } from 'common/constants';
import { actionTypes } from 'common/state/newPerson/additionalInfo/constants';
import { IAdditionalInfo } from 'modules/front-desk/interfaces';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { PeakModules } from 'common/constants/peakModules';
import Services from 'services/network';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { GeneralThunkAction } from 'common/state/interfaces';

export const updateAdditionalInfoResult = createAction<ActionResult>(
  actionTypes.UPDATE_ADDITIONAL_INFO_RESULT,
);

const updateAdditionalInfoLoading = createAction<boolean>(
  actionTypes.UPDATE_ADDITIONAL_INFO_LOADING,
);

export const saveAdditionalInfo = (
  personId: string,
  data: IAdditionalInfo,
  module: PeakModuleForNewPersonType = PeakModules.FrontDesk,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updateAdditionalInfoLoading(true));

    try {
      switch (module) {
        case PeakModules.FrontDesk:
          await Services.FrontDesk.saveAdditionalInfoForMember(personId, data);
          break;
        case PeakModules.Members:
          await Services.Members.saveAdditionalInfoForMember(personId, data);
          break;
        case PeakModules.Crm:
          await Services.Leads.saveAdditionalInfoForLead(personId, data);
          break;
        case PeakModules.PersonalTrainingCrm:
          await Services.PTLeads.saveAdditionalInfoForLead(personId, data);
          break;
        default:
      }

      dispatch(updateAdditionalInfoResult(ActionResult.SUCCESS_ACTION));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(updateAdditionalInfoLoading(false));
    }
  };
};

const fetchAdditionalInfoLoading = createAction<boolean>(actionTypes.FETCH_ADDITIONAL_INFO_LOADING);
export const updateAdditionalInfo = createAction<IAdditionalInfo>(
  actionTypes.FETCH_ADDITIONAL_INFO,
);

export const fetchAdditionalInfo = (
  personId: string,
  module: PeakModuleForNewPersonType = PeakModules.FrontDesk,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchAdditionalInfoLoading(true));
    try {
      let response: IAdditionalInfo;

      switch (module) {
        case PeakModules.FrontDesk:
          response = await Services.FrontDesk.getAdditionalInfoForMember(personId);
          break;
        case PeakModules.Members:
          response = await Services.Members.getAdditionalInfoForMember(personId);
          break;
        case PeakModules.Crm:
          response = await Services.Leads.getAdditionalInfoForLead(personId);
          break;
        case PeakModules.PersonalTrainingCrm:
          response = await Services.PTLeads.getAdditionalInfoForLead(personId);
          break;
        default:
      }

      dispatch(updateAdditionalInfo(response));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchAdditionalInfoLoading(false));
    }
  };
};
