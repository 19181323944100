import React from 'react';
import { useFormContext } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import { makeStyles } from '@material-ui/core';

import {
  CountryCodeSelect,
  CustomPhoneNumberInput,
} from 'common/components/PhoneNumberInput/index';
import { IPhoneNumberInputProps } from 'common/components/PhoneNumberInput/interfaces';

const useStyles = makeStyles(() => ({
  phoneInputWrapper: {
    position: 'relative',
  },
}));

const PhoneNumberInput = ({
  onChange,
  onBlur,
  name,
  value,
  error,
  helperText,
  onRef,
  onFocus,
  label,
  disabled,
  recommended,
}: IPhoneNumberInputProps): JSX.Element => {
  const { control } = useFormContext();
  const classes = useStyles();

  return (
    <PhoneInput
      disabled={disabled}
      ref={onRef}
      name={name}
      value={value}
      numberInputProps={{ error, helperText, name, label, recommended }}
      onBlur={onBlur}
      onFocus={onFocus}
      control={control}
      onChange={onChange}
      international
      className={classes.phoneInputWrapper}
      defaultCountry="US"
      countryCallingCodeEditable={false}
      limitMaxLength
      autoComplete="off"
      withCountryCallingCode
      inputComponent={CustomPhoneNumberInput}
      countrySelectComponent={CountryCodeSelect}
    />
  );
};

PhoneNumberInput.defaultProps = {
  name: 'phone',
};

export default PhoneNumberInput;
