import axios from 'axios';

import ApiService from './ApiService';

import { IPaginatedData } from 'common/interfaces/pagination';
import { ITableParams } from 'common/interfaces/table';
import { IDictionaryCommonItem } from 'modules/dictionaries/interfaces/interfaces';

export default class NoteCodesService extends ApiService {
  public getNoteCodes = (
    requestOptions?: ITableParams,
  ): Promise<IPaginatedData<IDictionaryCommonItem>> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get('/api/v1/note-code', { params: searchParams });
  };

  public createNoteCode = (data: IDictionaryCommonItem): Promise<IDictionaryCommonItem> => {
    return axios.post('/api/v1/note-code', data);
  };

  public updateNoteCode = (data: IDictionaryCommonItem): Promise<IDictionaryCommonItem> => {
    return axios.put(`/api/v1/note-code/${data.id}`, data);
  };

  public updateStatusNoteCode = (id: string, isActive: boolean): Promise<IDictionaryCommonItem> => {
    return axios.patch(`/api/v1/note-code/${id}/change-status`, { active: isActive });
  };
}
