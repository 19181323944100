import React from 'react';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { DialogComponent } from 'common/components';

import messages from 'modules/pos-kiosk/messages/messages';
import commonMessages from 'common/messages/messages';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  paidInvoiceCount: number;
}

const ImpossibleToCloseRegisterModal = ({
  isOpen,
  onClose,
  onSubmit,
  paidInvoiceCount,
}: IProps): JSX.Element => {
  return (
    <DialogComponent
      size="xs"
      title={<FormattedMessage {...messages.cantCloseRegisterDialogTitle} />}
      submitBtnTitle={<FormattedMessage {...commonMessages.okBtn} />}
      cancelBtn={false}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <Typography>
        <FormattedMessage
          {...messages.cantCloseRegister}
          values={{ paidInvoiceCount: paidInvoiceCount ?? 0 }}
        />
      </Typography>
    </DialogComponent>
  );
};

export default ImpossibleToCloseRegisterModal;
