import { createAction } from '@reduxjs/toolkit';
import { PoliciesPermissionSetItems } from 'common/constants/permissions';
import { GET_PERMISSIONS, GET_PERMISSIONS_LOADING } from './constants';
import { GeneralThunkAction } from '../interfaces';
import { enqueueErrorNotification } from '../notifications/actions';
import Services from 'services/network';

export const getPermissions = createAction<PoliciesPermissionSetItems[]>(GET_PERMISSIONS);
export const getPermissionsLoading = createAction<boolean>(GET_PERMISSIONS_LOADING);

export const fetchPermissions = (): GeneralThunkAction => {
  return async dispatch => {
    dispatch(getPermissionsLoading(true));

    try {
      const permissions = await Services.Permissions.getPermissions();
      dispatch(getPermissions(permissions));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(getPermissionsLoading(false));
    }
  };
};
