import messages from 'common/messages/messages';
import { ConstOptions } from 'common/constants/classes';
import campaignsMessages from '../messages/campaigns';
import { CampaignStatus, CampaignType } from 'common/constants/campaign';

const NAMESPACE = `@campaigns/`;

export const actionTypes = {
  // Campaigns action types
  FETCH_CAMPAIGNS_LIST_LOADING: `${NAMESPACE}FETCH_CAMPAIGNS_LOADING`,
  FETCH_CAMPAIGNS_LIST_SUCCESS: `${NAMESPACE}FETCH_CAMPAIGNS_SUCCESS`,

  RESET_CAMPAIGN_FORM: `${NAMESPACE}RESET_CAMPAIGN_FORM`,

  FETCH_CAMPAIGN_LOADING: `${NAMESPACE}FETCH_CAMPAIGN_LOADING`,
  FETCH_CAMPAIGN_SUCCESS: `${NAMESPACE}FETCH_CAMPAIGN_SUCCESS`,

  CREATE_CAMPAIGN_LOADING: `${NAMESPACE}CREATE_CAMPAIGN_LOADING`,
  CREATE_CAMPAIGN_SUCCESS: `${NAMESPACE}CREATE_CAMPAIGN_SUCCESS`,
  CREATE_CAMPAIGN_ERROR: `${NAMESPACE}CREATE_CAMPAIGN_ERROR`,

  UPDATE_CAMPAIGN_LOADING: `${NAMESPACE}UPDATE_CAMPAIGN_LOADING`,
  UPDATE_CAMPAIGN_SUCCESS: `${NAMESPACE}UPDATE_CAMPAIGN_SUCCESS`,
  UPDATE_CAMPAIGN_ERROR: `${NAMESPACE}UPDATE_CAMPAIGN_ERROR`,
};

export const CampaignTypes = new ConstOptions([
  {
    key: CampaignType.EMAIL,
    value: CampaignType.EMAIL,
    message: messages.emailLabel,
  },
  {
    key: CampaignType.SMS,
    message: messages.smsLabel,
    value: CampaignType.SMS,
  },
  {
    key: CampaignType.PHONE,
    value: CampaignType.PHONE,
    message: messages.phoneLabel,
  },
]);

export const campaignTypesOptions = new ConstOptions([
  {
    key: CampaignType.EMAIL,
    message: campaignsMessages.emailCampaignOption,
    value: CampaignType.EMAIL,
  },
  {
    key: CampaignType.SMS,
    value: CampaignType.SMS,
    message: campaignsMessages.SMSCampaignOption,
  },
  {
    key: CampaignType.PHONE,
    value: CampaignType.PHONE,
    message: campaignsMessages.phoneCampaignOption,
  },
]);

export const CampaignStatuses = new ConstOptions([
  {
    key: CampaignStatus.Active,
    value: CampaignStatus.Active,
    message: messages.activeOption,
  },
  {
    key: CampaignStatus.Completed,
    value: CampaignStatus.Completed,
    message: messages.completedOption,
  },
  {
    key: CampaignStatus.Canceled,
    value: CampaignStatus.Canceled,
    message: messages.canceledOption,
  },
]);
