import React from 'react';
import { Typography, TypographyProps } from '@material-ui/core';

import { CampaignStatuses } from 'modules/crm/constants/campaigns';
import { CampaignStatus as CampaignStatusType } from 'common/constants/campaign';

interface IProps extends TypographyProps {
  status: CampaignStatusType;
}

const getColor = (status: CampaignStatusType) => {
  switch (status) {
    case CampaignStatusType.Active:
      return '#66cd00';
    case CampaignStatusType.Canceled:
      return '#ff2634';
    case CampaignStatusType.Completed:
      return '#2eabff';
    default:
      return '#323232';
  }
};

export default function CampaignStatus({ status, ...other }: IProps): JSX.Element {
  const color = getColor(status);
  return (
    <Typography {...other} style={{ color }}>
      {CampaignStatuses.translate(status)}
    </Typography>
  );
}
