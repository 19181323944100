import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonGroup, createStyles, makeStyles } from '@material-ui/core';
import { PermissionLevels } from 'common/constants/permissions';
import { CustomTheme } from 'common/ui/interfaces';
import messages from 'common/messages/messages';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    group: {
      padding: 2,
      backgroundColor: theme.palette.secondary.light,
      borderRadius: 3,
    },
  }),
);

interface IRoleLevelSwitcherProps {
  onChange: (level: PermissionLevels) => void;
  value: PermissionLevels;
  isDisabled?: boolean;
}

const PolicyLevelSwitcher = ({
  onChange,
  value,
  isDisabled,
}: IRoleLevelSwitcherProps): JSX.Element => {
  const classes = useStyles();
  return (
    <ButtonGroup disabled={isDisabled} disableElevation fullWidth className={classes.group}>
      <Button
        onClick={() => onChange(PermissionLevels.CLIENT)}
        fullWidth
        color={value === PermissionLevels.CLIENT ? 'primary' : null}
        variant={value === PermissionLevels.CLIENT ? 'contained' : 'text'}
      >
        <FormattedMessage {...messages.clubTitle} />
      </Button>
      <Button
        onClick={() => onChange(PermissionLevels.CORPORATE)}
        fullWidth
        color={value === PermissionLevels.CORPORATE ? 'primary' : null}
        variant={value === PermissionLevels.CORPORATE ? 'contained' : 'text'}
      >
        <FormattedMessage {...messages.corporateTitle} />
      </Button>
    </ButtonGroup>
  );
};

export default PolicyLevelSwitcher;
