import { ConstOptions } from 'common/constants/classes';

import messages from 'modules/services/messages/messages';

export enum RequiredProfileInfo {
  Photo = 'PHOTO',
  FirstName = 'FIRST_NAME',
  PhoneNumber = 'PHONE_NUMBER',
  LastName = 'LAST_NAME',
  Email = 'EMAIL',
  Allergies = 'ALLERGIES',
  Salesperson = 'SALESPERSON',
  Birthday = 'BIRTHDAY',
  // Group fields
  BillingInfo = 'BILLING_INFO',
  Address = 'ADDRESS',
  EmergencyContactInfo = 'EMERGENCY_CONTACT_INFO',
  InsuranceInfo = 'INSURANCE_INFO',
}

export const RequiredProfileInfos = new ConstOptions([
  { value: RequiredProfileInfo.Photo, message: messages.photoLabel },
  { value: RequiredProfileInfo.BillingInfo, message: messages.billingInfoLabel },
  { value: RequiredProfileInfo.PhoneNumber, message: messages.phoneNumberLabel },
  { value: RequiredProfileInfo.InsuranceInfo, message: messages.insuranceInfoLabel },
  { value: RequiredProfileInfo.Email, message: messages.emailLabel },
  { value: RequiredProfileInfo.Allergies, message: messages.allergiesLabel },
  { value: RequiredProfileInfo.Address, message: messages.addressLabel },
  { value: RequiredProfileInfo.Salesperson, message: messages.salespersonLabel },
  { value: RequiredProfileInfo.Birthday, message: messages.birthdayLabel },
  { value: RequiredProfileInfo.EmergencyContactInfo, message: messages.emergencyContactInfoLabel },
]);

export enum FieldNecessity {
  Required = 'REQUIRED',
  Recommended = 'RECOMMENDED',
  Optional = 'OPTIONAL',
}

export const NecessityFieldOptions = new ConstOptions([
  { value: FieldNecessity.Required, message: messages.requiredOption },
  { value: FieldNecessity.Recommended, message: messages.recommendedOption },
  { value: FieldNecessity.Optional, message: messages.optionalOption },
]);
