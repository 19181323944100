import { FormControlLabel } from '@material-ui/core';
import { Checkbox } from 'common/components/index';
import { Control, Controller } from 'react-hook-form';
import React from 'react';

interface ICheckboxLabeled {
  control: Control;
  defaultValue: boolean;
  name: string;
  label: JSX.Element;
}

const CheckboxLabeled = ({ control, defaultValue, name, label }: ICheckboxLabeled): JSX.Element => {
  return (
    <Controller
      render={({ onChange, value }) => (
        <FormControlLabel
          control={
            <Checkbox size="small" checked={value} onChange={e => onChange(e.target.checked)} />
          }
          label={label}
        />
      )}
      defaultValue={defaultValue}
      control={control}
      name={name}
      variant="outlined"
    />
  );
};

export default CheckboxLabeled;
