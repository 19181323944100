import { fromJS } from 'immutable';
import { actionTypes } from './constants';

const initialMembershipState = fromJS({
  result: null,
  isLoading: false,
});

const membershipReducer = (state = initialMembershipState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MEMBERSHIP_SUCCESS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.FETCH_MEMBERSHIP_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_MEMBERSHIP:
      return state.set('package', null);

    default:
      return state;
  }
};

export default membershipReducer;
