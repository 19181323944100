import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Grid } from '@material-ui/core';

// components
import { ScrollBox } from 'common/components';
// interfaces
import { CustomTheme } from 'common/ui/interfaces';
// helpers
import { replaceSignatureImg, replaceSignatureTag } from '../helpers';

interface IContract {
  canClearSignature: boolean;
  documentText?: string;
  signatureId?: string;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  contract: {
    height: '100%',
    border: `1px solid ${theme.palette.borderColor.main}`,
    borderRadius: '3px',
    padding: '6px',
    overflowY: 'auto',

    '& a': {
      textDecoration: 'underline',
      color: theme.palette.info.main,
    },
  },
  scrollContainer: {
    padding: '6px 15px',
  },
  signature: {
    height: '100px',
    paddingTop: 0,
    paddingBottom: 0,

    '&>img': {
      width: '161px',
      height: '64px',
    },
  },
}));

const Contract = (props: IContract) => {
  const { documentText, canClearSignature, signatureId } = props;

  const classes = useStyles();

  const html =
    canClearSignature && signatureId
      ? replaceSignatureImg(documentText, signatureId)
      : replaceSignatureTag(documentText);

  return (
    <Grid className={classes.contract}>
      <ScrollBox suppressScrollX>
        <div
          id="contract-template"
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      </ScrollBox>
    </Grid>
  );
};

export default Contract;
