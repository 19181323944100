import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCheckinNotifications } from 'common/state/notifications/selectors';
import { FRONT_DESK_PATH } from 'common/constants/globalConstants';
import { ICheckIn } from 'modules/front-desk/interfaces';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import {
  clearCheckinNotificationById,
  clearCheckinNotifications,
} from 'common/state/notifications/actions';
import { Box, Button, Grid, Popper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { fromJS } from 'immutable';
import { ArrowBackRounded, CloseRounded } from '@material-ui/icons';
import cx from 'classnames';
import { CurrentCheckIn } from 'common/components/CheckInList/components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      zIndex: 10,
      padding: theme.spacing(1.75),
      right: `${theme.spacing(2)}px !important`,
      top: '80px !important',
      left: 'unset!important',
      borderRadius: 10,
      backgroundColor: theme.palette.text.primary,
      boxShadow: theme.shadows[5],
    },
    closeCross: {
      padding: 0,
      color: theme.palette.primary.contrastText,
      opacity: 0.3,
    },
    icon: {
      width: 16,
      height: 16,
    },
    arrowRight: {
      transform: 'scaleX(-1)',
      marginLeft: `${theme.spacing(0.75)}px !important`,
      color: theme.palette.primary.contrastText,
      opacity: 1,
    },
    arrowLeft: {
      marginRight: `${theme.spacing(0.75)}px !important`,
      color: theme.palette.primary.contrastText,
      opacity: 1,
    },
    isDisabled: {
      color: `${theme.palette.primary.contrastText}!important`,
      opacity: '0.3!important',
    },
    arrows: {
      display: 'flex',
      color: theme.palette.text.hint,
      padding: 0,
    },
    checkinImageButton: {
      marginTop: theme.spacing(1),
    },
  }),
);

const CheckinNotifications = (): JSX.Element => {
  const dispatch = useDispatch();
  const [checkin, setCheckin] = useState<ICheckIn[]>([]);
  const [currentCheckin, setCurrentCheckin] = useState<ICheckIn>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const classes = useStyles();

  const checkinNotifications = useSelector(selectCheckinNotifications);

  const [timezoneMoment] = useTimezoneMoment();

  useEffect(() => {
    if (checkinNotifications?.length > 0) {
      const lastCheckinSelectorIndex = checkinNotifications.length - 1;

      const isCheckinAlreadyExists =
        checkin.findIndex(el => checkinNotifications[lastCheckinSelectorIndex].id === el.id) !== -1;

      const timeDifferenceMs: number = timezoneMoment()
        .add(1, 'minute')
        .diff(timezoneMoment(checkinNotifications[checkinNotifications.length - 1].checkInTime));

      if (!isCheckinAlreadyExists) {
        setIsOpen(true);
        setTimeout(
          () =>
            dispatch(
              clearCheckinNotificationById(
                checkinNotifications[checkinNotifications.length - 1].id,
              ),
            ),
          timeDifferenceMs,
        );
      }

      setCheckin(checkinNotifications);
    } else {
      setIsOpen(false);
    }
  }, [checkin, checkinNotifications, currentCheckin, dispatch, timezoneMoment]);

  useEffect(() => {
    setCurrentCheckin(checkin[checkin.length - 1]);
  }, [checkin]);

  const onClose = () => {
    dispatch(clearCheckinNotifications());
  };

  const onCurrentCheckinClick = () => {
    dispatch(clearCheckinNotifications());
    window.location.replace(FRONT_DESK_PATH);
  };

  const isLeftArrowDisabled = useCallback(() => {
    /* check whether there is only one element in checkin or currentCheckin is the first element */
    return checkin[0] && checkin[0].id === currentCheckin?.id;
  }, [checkin, currentCheckin]);

  const leftArrowClickHandler = useCallback(() => {
    const index = checkin.findIndex(v => v.id === currentCheckin?.id);
    if (index > 0) {
      setCurrentCheckin(checkin[index - 1]);
    }
  }, [checkin, currentCheckin]);

  const isRightArrowDisabled = useCallback(() => {
    /* check whether there is only one element in checkin or currentCheckin is the last element */
    return checkin.length > 0 && checkin[checkin.length - 1].id === currentCheckin?.id;
  }, [checkin, currentCheckin]);

  const rightArrowClickHandler = useCallback(() => {
    const index = checkin.findIndex(v => v.id === currentCheckin?.id);
    if (index >= 0 && index < checkin.length - 1) {
      setCurrentCheckin(checkin[index + 1]);
    }
  }, [checkin, currentCheckin]);

  return (
    <Popper open={isOpen} className={classes.dialog} disablePortal={false} placement="right-start">
      <Box>
        <Grid container direction="column">
          <Grid item xs={12}>
            <Box mb={1} display="flex" justifyContent="space-between">
              <Box className={classes.arrows}>
                <Button
                  variant="text"
                  type="button"
                  color="secondary"
                  disabled={isLeftArrowDisabled()}
                  className={cx('btn-text', classes.arrowLeft, {
                    [classes.isDisabled]: isLeftArrowDisabled(),
                  })}
                  onClick={leftArrowClickHandler}
                >
                  <ArrowBackRounded fontSize="small" className={classes.icon} />
                </Button>
                <Button
                  variant="text"
                  type="button"
                  color="secondary"
                  disabled={isRightArrowDisabled()}
                  className={cx('btn-text', classes.arrowRight, {
                    [classes.isDisabled]: isRightArrowDisabled(),
                  })}
                  onClick={rightArrowClickHandler}
                >
                  <ArrowBackRounded className={classes.icon} />
                </Button>
              </Box>
              <Box display="flex" alignItems="center">
                <Button
                  variant="text"
                  type="button"
                  color="secondary"
                  className={`btn-text ${classes.closeCross}`}
                  onClick={onClose}
                >
                  <CloseRounded className={classes.icon} />
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} className={classes.checkinImageButton}>
            <CurrentCheckIn
              checkIn={currentCheckin && fromJS(currentCheckin)}
              onClick={onCurrentCheckinClick}
              selectedCheckInId={currentCheckin && currentCheckin.id}
            />
          </Grid>
        </Grid>
      </Box>
    </Popper>
  );
};

export default CheckinNotifications;
