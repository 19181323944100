// library
import React, { useMemo, useState } from 'react';
import { List as ImmutableList } from 'immutable';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import { Button, makeStyles, Menu, MenuItem, Theme, Typography } from '@material-ui/core';
// icons
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
// constants
// state
import * as actions from 'modules/authentication/state/actions';
import { resetSelectedEntranceId } from 'modules/front-desk/state/actions';
import * as selectors from 'modules/authentication/state/selectors';
// hooks
import { useAppDispatch } from 'store/hooks';
import useComponentUpdate from 'common/hooks/useComponentUpdate';
// interfaces
import { IUserOrganization, IUserOrganizationImt } from 'common/interfaces/clients';
import { IUserProfileInfoImt } from 'modules/authentication/interfaces';
import { HeaderId } from 'common/enums/html.enums';
// messages
import messages from 'common/messages/messages';

const useStyles = makeStyles((theme: Theme) => ({
  organizationOption: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(0.5),
  },
  organizationTitle: {
    textTransform: 'none',
    fontWeight: 600,
  },
  dropDownIcon: {
    marginLeft: theme.spacing(1),
  },
}));

function ClubSelector(): JSX.Element {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // state

  const dispatch = useAppDispatch();

  const currentUser: IUserProfileInfoImt = useSelector(selectors.selectCurrentUser);
  const selectedOrg: IUserOrganizationImt = useSelector(selectors.selectUserSelectedOrganization);
  const organizations: ImmutableList<IUserOrganizationImt> = useSelector(
    selectors.selectCurrentUserOrganizationsByRoles,
  );

  useComponentUpdate();

  // constants and handlers

  const hasMultipleOrganizations = useMemo(() => !!organizations?.size, [organizations]);

  const onMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (hasMultipleOrganizations) setAnchorEl(event.currentTarget);
  };

  const onMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChangeSelectedOrg = (organization: IUserOrganization): void => {
    dispatch(actions.selectCurrentUserOrganization(currentUser.get('id'), organization));
    dispatch(resetSelectedEntranceId());
  };

  // renders

  const getTimezoneOffset = (timezone: string) => {
    const timezoneLabel = timezone
      ?.split('/')
      ?.slice(1)
      ?.map(labelItem => ` ${labelItem.replaceAll('_', ' ')}`);

    return `${moment.tz(timezone).format('h:mm A')} (GMT ${moment
      .tz(timezone)
      .format('Z')}) ${timezoneLabel}`;
  };

  return (
    <>
      <Button id={HeaderId.HeaderBtnClub} onClick={onMenuOpen}>
        <Typography component="span" className={classes.organizationOption}>
          <Typography component="span" color="secondary" className={classes.organizationTitle}>
            {selectedOrg?.get('title') || <FormattedMessage {...messages.chooseClubTitle} />}
          </Typography>

          <Typography component="span" variant="body2" color="secondary">
            {getTimezoneOffset(selectedOrg?.get('timezone'))}
          </Typography>
        </Typography>

        {hasMultipleOrganizations && anchorEl ? (
          <ArrowDropUp color="secondary" className={classes.dropDownIcon} />
        ) : (
          <ArrowDropDown color="secondary" className={classes.dropDownIcon} />
        )}
      </Button>

      <Menu
        id="actions-menu"
        keepMounted
        getContentAnchorEl={null}
        title={selectedOrg?.get('title')}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        {organizations?.map(organization => (
          <MenuItem
            key={organization.get('id')}
            value={organization.get('id')}
            onClick={() => {
              onMenuClose();
              handleChangeSelectedOrg(organization.toJS());
            }}
          >
            <Typography className={classes.organizationOption}>
              <Typography component="span" color="secondary" className={classes.organizationTitle}>
                {organization.get('title')}
              </Typography>

              <Typography component="span" variant="body2" color="secondary">
                {getTimezoneOffset(organization?.get('timezone'))}
              </Typography>
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default ClubSelector;
