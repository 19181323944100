export const GET_DS_TOTAL_BY_DATE_RANGE = 'GET_DS_TOTAL_BY_DATE_RANGE';
export const GET_DS_TOTAL_BY_DATE_RANGE_LOADING = 'GET_DS_TOTAL_BY_DATE_RANGE_LOADING';
export const CLEAR_DS_TOTAL_BY_DATE_RANGE = 'CLEAR_DS_TOTAL_BY_DATE_RANGE';

export const GET_DS_TOTAL_BY_EVENT = 'GET_DS_TOTAL_BY_EVENT';
export const GET_DS_TOTAL_BY_EVENT_LOADING = 'GET_DS_TOTAL_BY_EVENT_LOADING';
export const CLEAR_DS_TOTAL_BY_EVENT = 'CLEAR_DS_TOTAL_BY_EVENT';

export const GET_DS_TOTAL_BY_SALESPERSON = 'GET_DS_TOTAL_BY_SALESPERSON';
export const GET_DS_TOTAL_BY_SALESPERSON_LOADING = 'GET_DS_TOTAL_BY_SALESPERSON_LOADING';
export const CLEAR_DS_TOTAL_BY_SALESPERSON = 'CLEAR_DS_TOTAL_BY_SALESPERSON';

export const GET_DS_TOTAL_BY_DAY_OF_WEEK = 'GET_DS_TOTAL_BY_DAY_OF_WEEK';
export const GET_DS_TOTAL_BY_DAY_OF_WEEK_LOADING = 'GET_DS_TOTAL_BY_DAY_OF_WEEK_LOADING';
export const CLEAR_DS_TOTAL_BY_DAY_OF_WEEK = 'CLEAR_DS_TOTAL_BY_DAY_OF_WEEK';

export const GET_DS_MARKET_SOURCE_TOTAL = 'GET_DS_MARKET_SOURCE_TOTAL';
export const GET_DS_MARKET_SOURCE_TOTAL_LOADING = 'GET_DS_MARKET_SOURCE_TOTAL_LOADING';
export const CLEAR_DS_MARKET_SOURCE_TOTAL = 'CLEAR_DS_MARKET_SOURCE_TOTAL';

export const GET_DS_MEMBER_TYPE_TOTAL = 'GET_DS_MEMBER_TYPE_TOTAL';
export const GET_DS_MEMBER_TYPE_TOTAL_LOADING = 'GET_DS_MEMBER_TYPE_TOTAL_LOADING';
export const CLEAR_DS_MEMBER_TYPE_TOTAL = 'CLEAR_DS_MEMBER_TYPE_TOTAL';

export const GET_DS_PAYMENT_METHOD_TOTAL = 'GET_DS_PAYMENT_METHOD_TOTAL';
export const GET_DS_PAYMENT_METHOD_TOTAL_LOADING = 'GET_DS_PAYMENT_METHOD_TOTAL_LOADING';
export const CLEAR_DS_PAYMENT_METHOD_TOTAL = 'CLEAR_DS_PAYMENT_METHOD_TOTAL';

export const GET_DS_EVENT_KEYS = 'GET_DS_EVENT_KEYS';
export const GET_DS_EVENT_KEYS_LOADING = 'GET_DS_EVENT_KEYS_LOADING';
export const CLEAR_DS_EVENT_KEYS = 'CLEAR_DS_EVENT_KEYS';

export const GET_DS_STATUS_KEYS = 'GET_DS_STATUS_KEYS';
export const GET_DS_STATUS_KEYS_LOADING = 'GET_DS_STATUS_KEYS_LOADING';
export const CLEAR_DS_STATUS_KEYS = 'CLEAR_DS_STATUS_KEYS';
