import { defineMessages } from 'react-intl';

export default defineMessages({
  // unknown
  oopsError: {
    id: 'app.common.errors.unknown.oopsError',
    defaultMessage: 'Oops!',
  },
  somethingWentWrongError: {
    id: 'app.common.errors.unknown.somethingWentWrong',
    defaultMessage: 'Something went wrong on server.',
  },
  somethingWentWrongErrorOnFrontEnd: {
    id: 'app.common.errors.unknown.somethingWentWrongOnFrontEnd',
    defaultMessage: 'Something went wrong on site.',
  },
  errorCode: {
    id: 'app.common.errors.unknown.errorCode',
    defaultMessage: 'Error code: {code}',
  },
  // devices
  deviceConnectionError: {
    id: 'app.common.errors.devices.deviceConnectionError',
    defaultMessage: 'Device connection error',
  },
  deviceIsNotFoundError: {
    id: 'app.common.errors.devices.deviceIsNotFoundError',
    defaultMessage: 'Device is not found',
  },
  browserNotSupportHidApi: {
    id: 'app.common.errors.devices.browserNotSupportHidApi',
    defaultMessage: 'Your browser does not support WebHID API',
  },
  updateYourVersionSigWeb: {
    id: 'app.common.errors.devices.updateYourVersionSigWeb',
    defaultMessage: 'Update your version of SigWeb.',
  },
  unsignedDocumentsError: {
    id: 'app.common.errors.devices.unsignedDocumentsError',
    defaultMessage: 'You have unsigned documents',
  },
  unsignedDocumentError: {
    id: 'app.common.errors.devices.unsignedDocumentError',
    defaultMessage: 'You have unsigned document',
  },
  unableCommunicateWithSigWeb: {
    id: 'app.common.errors.devices.unableCommunicateWithSigWeb',
    defaultMessage:
      'Unable to communicate with SigWeb. Please confirm that SigWeb is installed and running on this PC.',
  },
  noCorrespondingSignaturePad: {
    id: 'app.common.errors.devices.noCorrespondingSignaturePad',
    defaultMessage: 'You do not have the appropriate signature pad connected.',
  },
  jobPositionNotFound: {
    id: 'app.common.errors.timeclock.jobPositionNotFound',
    defaultMessage: 'Job position with id not found.',
  },
  timeclockMaxNumberExceeded: {
    id: 'app.common.errors.timeclock.timeclockMaxNumberExceeded',
    defaultMessage:
      "You can't track time in 2 salary/hourly tasks simultaneously. Please clock out previous task.",
  },
  timeClockAlreadyExists: {
    id: 'app.common.errors.timeclock.timeClockAlreadyExists',
    defaultMessage: 'Job position with id already has got in progress clock in.',
  },
  InProgressTimeclockNotFound: {
    id: 'app.common.errors.timeclock.InProgressTimeclockNotFound',
    defaultMessage: 'Time clock with job position id not found.',
  },

  // actions
  refresh: {
    id: 'app.common.errors.actions.refresh',
    defaultMessage: 'Refresh',
  },
  report: {
    id: 'app.common.errors.actions.report',
    defaultMessage: 'Report',
  },
  serverNotRespondingError: {
    id: 'app.common.errors.unknown.serverNotResponding',
    defaultMessage: 'Server not responding.',
  },
  badRequestError: {
    id: 'app.common.errors.base.badRequest',
    defaultMessage: 'There is something wrong with your request.',
  },
  notFoundError: {
    id: 'app.common.errors.base.notFoundError',
    defaultMessage: 'The server could not find your request',
  },
  // 400 errors
  employeeMultiCorpLevelRoles: {
    id: 'app.common.errors.400.employeeMultiCorpLevelRoles',
    defaultMessage: "Can't assign more than 1 corporate level role",
  },
  initialClubIdOrInitialMemberIdMissingError: {
    id: 'app.common.errors.400.INITIAL_CLUB_ID_OR_INITIAL_MEMBER_ID_MISSING_ERROR',
    defaultMessage: 'Can not set initial home club, check that you selected club in header.',
  },
  requestValidationError: {
    id: 'app.common.errors.400.requestValidationError',
    defaultMessage: 'Request validation error',
  },
  emailAlreadyExistsError: {
    id: 'app.common.errors.400.emailAlreadyExists',
    defaultMessage: 'Email already exists',
  },
  dateTimeValidationError: {
    id: 'app.common.errors.400.dateTimeValidationError',
    defaultMessage: 'The start date/time must be before the End date/time',
  },
  paramMissingError: {
    id: 'app.common.errors.400.paramMissingError',
    defaultMessage: 'Missing required parameter',
  },
  tagAlreadyExistsError: {
    id: 'app.common.errors.400.tagAlreadyExistsError',
    defaultMessage: 'The tag already exists.',
  },
  scriptStepUniqueIndexError: {
    id: 'app.common.errors.400.scriptStepUniqueIndexError',
    defaultMessage: 'Script steps contains not unique indexes or steps is empty.',
  },
  resourceTimeAvailableValidationError: {
    id: 'app.common.errors.400.resourceTimeAvailableValidationError',
    defaultMessage: 'The start time must be before the end time',
  },
  resourceAvailableDateValidationError: {
    id: 'app.common.errors.400.resourceAvailableDateValidationError',
    defaultMessage: 'The start date must be before the end date',
  },
  phoneToSendSMSIsNotValidError: {
    id: 'app.common.errors.400.phoneToSendSMSIsNotValidError',
    defaultMessage: 'This phone is not found or cannot receive SMS',
  },
  stompHeaderMissingError: {
    id: 'app.common.errors.400.stompHeaderMissingError',
    defaultMessage: 'Stomp header is missing',
  },
  corruptedFileError: {
    id: 'app.common.errors.400.corruptedFileError',
    defaultMessage: 'File is empty or corrupted',
  },
  eventTimeRestrictionExpiredError: {
    id: 'app.common.errors.400.eventTimeRestrictionExpiredError',
    defaultMessage: 'The event has already expired',
  },
  bulkFilterIdsValidationError: {
    id: 'app.common.errors.400.bulkFilterIdsValidationError',
    defaultMessage: 'Bulk filter invalid.',
  },
  unableToChangePolicyTypeError: {
    id: 'app.common.errors.400.unableToChangePolicyTypeError',
    defaultMessage: 'Unable to change policy type',
  },
  securityRoleShouldContainUniqueGroupsError: {
    id: 'app.common.errors.400.securityRoleShouldContainUniqueGroupsError',
    defaultMessage: 'Security role should contain more than 1, and only unique groups',
  },
  clubIdMissingError: {
    id: 'app.common.errors.400.clubIdMissingError',
    defaultMessage: 'Club Id is missing',
  },
  taxBundleOverrideTypeError: {
    id: 'app.common.errors.400.taxBundleOverrideTypeError',
    defaultMessage: 'The type of tax rate bundle override, should be bundle',
  },
  taxRateValueError: {
    id: 'app.common.errors.400.taxRateValueError',
    defaultMessage: 'Tax rate should not be null',
  },
  paymentSplitsSumValidationError: {
    id: 'app.common.errors.400.paymentSplitsSumValidationError',
    defaultMessage: 'The sum of payment splits must equal the billing amount',
  },
  membershipError: {
    id: 'app.common.errors.400.membershipError',
    defaultMessage: 'Membership section does not available.',
  },
  securityRoleShouldContainPoliciesFromOneGroupTypeError: {
    id: 'app.common.errors.400.securityRoleShouldContainPoliciesFromOneGroupTypeError',
    defaultMessage: "Security role can't contain more than 1 permission group level",
  },
  unableToChangeRoleLevelError: {
    id: 'app.common.errors.400.unableToChangeRoleLevelError',
    defaultMessage: 'Unable to change security role level',
  },
  policyLevelTypeError: {
    id: 'app.common.errors.400.policyLevelTypeError',
    defaultMessage: 'Current user can not create such policy.',
  },
  roleLevelTypeError: {
    id: 'app.common.errors.400.roleLevelTypeError',
    defaultMessage: 'Role assignment error, wrong level.',
  },
  passwordValidationError: {
    id: 'app.common.errors.400.passwordValidationError',
    defaultMessage: 'Password or verbal password not valid',
  },
  typeOfTaxInBundleOnlySingleError: {
    id: 'app.common.errors.400.typeOfTaxInBundleOnlySingle',
    defaultMessage: 'The type of tax rate in the bundle should only be a single',
  },
  typeOfTaxInBundleOverrideShouldBeBundle: {
    id: 'app.common.errors.400.typeOfTaxInBundleOverrideShouldBeBundle',
    defaultMessage: 'The type of tax rate bundle override, should be bundle',
  },
  emptyPolicyError: {
    id: 'app.common.errors.400.emptyPolicyError',
    defaultMessage: 'Policy is empty or contain permissions from different groups',
  },
  roleAssignClubRolesCountError: {
    id: 'app.common.errors.400.roleAssignClubRolesCountError',
    defaultMessage: "Can't assign more than 1 role to 1 club",
  },
  documentTemplateTypesValidationError: {
    id: 'app.common.errors.400.documentTemplateTypesValidationError',
    defaultMessage: 'Document template settings validation error.',
  },
  corporationEmailSettingValidationError: {
    id: 'app.common.errors.400.corporationEmailSettingValidationError',
    defaultMessage: 'Corporation email settings validation error.',
  },
  corporationSmsSettingsValidationError: {
    id: 'app.common.errors.400.corporationSmsSettingsValidationError',
    defaultMessage: 'Corporation sms settings validation error.',
  },
  taxBundleItemsTypeError: {
    id: 'app.common.errors.400.taxBundleItemsTypeError',
    defaultMessage: 'Tax bundle should contain only Single taxes',
  },
  corporationPayeezySettingsValidationError: {
    id: 'app.common.errors.400.corporationPayeezySettingsValidationError',
    defaultMessage: 'Corporation payeezy settings validation error.',
  },
  clubNotAllowedError: {
    id: 'app.common.errors.400.clubNotAllowedError',
    defaultMessage: "Club is not included in the member's membership",
  },
  corporationZendeskSettingsValidationError: {
    id: 'app.common.errors.400.corporationZendeskSettingsValidationError',
    defaultMessage: 'Corporation zendesk settings validation error. {variable_1}',
  },
  methodArgumentTypeMismatchError: {
    id: 'app.common.errors.400.methodArgumentTypeMismatchError',
    defaultMessage: 'Invalid argument: <{variable_0}={variable_1}>.',
  },
  // 401 errors
  employeeInactiveError: {
    id: 'app.common.errors.401.employeeInactive',
    defaultMessage: 'Employee is not active',
  },
  accessTokenGenerationError: {
    id: 'app.common.errors.401.accessTokenGenerationError',
    defaultMessage: 'Error due to access token generation',
  },
  tokenExpiredError: {
    id: 'app.common.errors.401.tokenExpiredError',
    defaultMessage: 'Token expired',
  },
  tokenValidationError: {
    id: 'app.common.errors.401.tokenValidationError',
    defaultMessage: 'Token is invalid',
  },
  wrongCredentialsError: {
    id: 'app.common.errors.401.wrongCredentialsError',
    defaultMessage: 'The password is incorrect or such user does not exist',
  },
  refreshTokenGenerationError: {
    id: 'app.common.errors.401.refreshTokenGenerationError',
    defaultMessage: 'Refresh token generation error',
  },
  haveNotActiveClubOrCorporatePermissionsError: {
    id: 'app.common.errors.401.haveNotActiveClubOrCorporatePermissionsError',
    defaultMessage: 'Employee have not active club or corporate permissions',
  },
  // 403 errors
  accessForbiddenError: {
    id: 'app.common.errors.403.accessForbiddenError',
    defaultMessage: 'Access forbidden',
  },
  // 422 errors
  entityNotFoundError: {
    id: 'app.common.errors.422.entityNotFoundError',
    defaultMessage: 'Entity with this ids not found',
  },
  employeeNotFoundError: {
    id: 'app.common.errors.422.employeeNotFoundError',
    defaultMessage: 'Employee with this ids not found',
  },
  wrongUsernameOrPasswordError: {
    id: 'app.common.errors.422.wrongUsernameOrPasswordError',
    defaultMessage: 'Wrong Username or Password',
  },
  shouldBeMoreThanZero: {
    id: 'app.common.errors.422.shouldBeMoreThanZero',
    defaultMessage: 'Should be more than 0',
  },
  incorrectOldPasswordError: {
    id: 'app.common.errors.422.incorrectOldPasswordError',
    defaultMessage: 'The old password value is incorrect',
  },
  wrongConfirmPassword: {
    id: 'app.common.errors.422.wrongConfirmPassword',
    defaultMessage: 'The confirm password value is incorrect',
  },
  pinCodeMismatchError: {
    id: 'app.common.errors.422.pinCodeMismatchError',
    defaultMessage: "Employee's pin code doesn't match with entered one",
  },
  redeemNumberExceeded: {
    id: 'app.common.errors.422.redeemNumberExceeded',
    defaultMessage: 'Available redeem number exceeded',
  },
  familyMemberNotFound: {
    id: 'app.common.errors.422.familyMemberNotFound',
    defaultMessage: 'Family member not found',
  },
  registerStatusHistoryNotFound: {
    id: 'app.common.errors.422.registerStatusHistoryNotFound',
    defaultMessage: 'Register status history not found',
  },
  payeezyDisabledSettingsError: {
    id: 'app.common.errors.422.payeezyDisabledSettingsError',
    defaultMessage: 'Payeezy disabled.',
  },
  payeezyMissingSettingsError: {
    id: 'app.common.errors.422.payeezyMissingSettingsError',
    defaultMessage: 'Payeezy actions missing some configuration, check them.',
  },
  creditCardPaymentFailureError: {
    id: 'app.common.errors.422.creditCardPaymentFailureError',
    defaultMessage: 'An error occurred during the transaction.',
  },
  clubNotFoundError: {
    id: 'app.common.errors.422.clubNotFoundError',
    defaultMessage: 'Club with ids not found',
  },
  serviceNotFoundError: {
    id: 'app.common.errors.422.serviceNotFoundError',
    defaultMessage: 'Service with ids not found',
  },
  serviceArchivedError: {
    id: 'app.common.errors.422.serviceArchivedError',
    defaultMessage: 'Service with ids is archived',
  },
  documentTemplateNotFoundError: {
    id: 'app.common.errors.422.documentTemplateNotFoundError',
    defaultMessage: 'Document template with ids not found',
  },
  vendorNotFoundError: {
    id: 'app.common.errors.422.vendorNotFoundError',
    defaultMessage: 'Vendor with such uuid was not found',
  },
  generalNotImplementedYetError: {
    id: 'app.common.errors.422.generalNotImplementedYetError',
    defaultMessage: '{variable_0} not implemented yet.',
  },
  vendorShouldNotHasOriginalVendorError: {
    id: 'app.common.errors.422.vendorShouldNotHasOriginalVendorError',
    defaultMessage: 'Vendor on corporate level should not has original vendor',
  },
  vendorAlreadyArchivedError: {
    id: 'app.common.errors.422.vendorAlreadyArchivedError',
    defaultMessage: 'Vendor is already archived',
  },
  overrideVendorArchivedError: {
    id: 'app.common.errors.422.overrideVendorArchivedError',
    defaultMessage: "Override vendor can't be archived",
  },
  taxRateNotFoundError: {
    id: 'app.common.errors.422.taxRateNotFoundError',
    defaultMessage: 'Tax rate with this uuid not found',
  },
  revenueCodeNotFoundError: {
    id: 'app.common.errors.422.revenueCodeNotFoundError',
    defaultMessage: 'Revenue code with uuids not found',
  },
  allActiveInventoryItemsModifiedLinkedError: {
    id: 'app.common.errors.422.allActiveInventoryItemsModifiedLinkedError',
    defaultMessage:
      'All active inventory items must be modified and linked to another revenue code first.',
  },
  documentTemplateNotSupportedYetError: {
    id: 'app.common.errors.422.documentTemplateNotSupportedYetError',
    defaultMessage: 'Document template with type = %s not supported yet',
  },
  scriptNotFoundError: {
    id: 'app.common.errors.422.scriptNotFoundError',
    defaultMessage: 'Script with uuids not found',
  },
  paymentMethodNotFoundError: {
    id: 'app.common.errors.422.paymentMethodNotFoundError',
    defaultMessage: 'Payment method with this uuid not found',
  },
  paymentMethodAlreadyArchivedError: {
    id: 'app.common.errors.422.paymentMethodAlreadyArchivedError',
    defaultMessage: 'Payment method with this uuid is already archived',
  },
  resourceNotFoundError: {
    id: 'app.common.errors.422.resourceNotFoundError',
    defaultMessage: 'Resource with uuids not found',
  },
  receiptNotFoundError: {
    id: 'app.common.errors.422.receiptNotFoundError',
    defaultMessage: 'Receipt with uuids not found',
  },
  receiptFieldNotFoundError: {
    id: 'app.common.errors.422.receiptFieldNotFoundError',
    defaultMessage: 'Receipt field with uuids not found',
  },
  receiptClubNotFoundError: {
    id: 'app.common.errors.422.receiptClubNotFoundError',
    defaultMessage: 'Receipt club with uuids not found',
  },
  inventoryNotFoundError: {
    id: 'app.common.errors.422.inventoryNotFoundError',
    defaultMessage: 'Inventory with this uuid not found',
  },
  inventoryClubNotFoundError: {
    id: 'app.common.errors.422.inventoryClubNotFoundError',
    defaultMessage: 'Inventory club with this uuid not found',
  },
  ordersNotUniqueError: {
    id: 'app.common.errors.422.ordersNotUniqueError',
    defaultMessage: 'Orders not unique',
  },
  registerNotFoundError: {
    id: 'app.common.errors.422.registerNotFoundError',
    defaultMessage: 'Register with ids not found',
  },
  registerParentButtonNotFoundError: {
    id: 'app.common.errors.422.registerParentButtonNotFoundError',
    defaultMessage: 'Register parent button not found',
  },
  customerNotFoundError: {
    id: 'app.common.errors.422.customerNotFoundError',
    defaultMessage: 'Customer with {variable_0} = {variable_1} not found',
  },
  clubNotInstalledMobilePhoneError: {
    id: 'app.common.errors.422.clubNotInstalledMobilePhoneError',
    defaultMessage: 'Club with ids has not installed a mobile phone',
  },
  smsNotFoundError: {
    id: 'app.common.errors.422.smsNotFoundError',
    defaultMessage: 'SMS with ids not found',
  },
  callNoteNotFoundError: {
    id: 'app.common.errors.422.callNoteNotFoundError',
    defaultMessage: 'Call Note with ids not found',
  },
  objectArchivedError: {
    id: 'app.common.errors.422.objectArchivedError',
    defaultMessage: 'Object with ids is archived',
  },
  objectMustHaveInactiveStatusError: {
    id: 'app.common.errors.422.objectMustHaveInactiveStatusError',
    defaultMessage: 'Object with ids must have inactive status.',
  },
  allActiveCampaignsModifiedLinkedError: {
    id: 'app.common.errors.422.allActiveCampaignsModifiedLinkedError',
    defaultMessage: 'All active campaigns must be modified and linked to another template first.',
  },
  activeTemplateMustBeOneError: {
    id: 'app.common.errors.422.activeTemplateMustBeOneError',
    defaultMessage: 'Active template with type must be one',
  },
  dictionaryTypeShouldNotBeNullError: {
    id: 'app.common.errors.422.dictionaryTypeShouldNotBeNullError',
    defaultMessage: 'Dictionary type should not be null',
  },
  dictionaryNotFoundError: {
    id: 'app.common.errors.422.dictionaryNotFoundError',
    defaultMessage: 'Dictionary with ids not found',
  },
  dictionaryDeletedError: {
    id: 'app.common.errors.422.dictionaryDeletedError',
    defaultMessage: 'Dictionary is active and can not be deleted',
  },
  fileUrlMalformedError: {
    id: 'app.common.errors.422.fileUrlMalformedError',
    defaultMessage: 'File url is malformed',
  },
  crmTaskNotFoundError: {
    id: 'app.common.errors.422.crmTaskNotFoundError',
    defaultMessage: 'CRM task with id not found',
  },
  crmTaskAlreadyCompletedError: {
    id: 'app.common.errors.422.crmTaskAlreadyCompletedError',
    defaultMessage: 'CRM task with id already is completed',
  },
  alertSoundConditionCreatedError: {
    id: 'app.common.errors.422.alertSoundConditionCreatedError',
    defaultMessage: "Alert sound condition can't be created",
  },
  alertNotFoundError: {
    id: 'app.common.errors.422.alertNotFoundError',
    defaultMessage: 'Alert with id not found',
  },
  alertConditionAlreadyExistsError: {
    id: 'app.common.errors.422.alertConditionAlreadyExistsError',
    defaultMessage: 'Alert condition already exists',
  },
  alertConditionNotCustomError: {
    id: 'app.common.errors.422.alertConditionNotCustomError',
    defaultMessage: 'Alert condition is not custom',
  },
  alertSoundNotFoundError: {
    id: 'app.common.errors.422.alertSoundNotFoundError',
    defaultMessage: 'Alert sound with id not found',
  },
  eventNotFoundError: {
    id: 'app.common.errors.422.eventNotFoundError',
    defaultMessage: 'Event with ids not found',
  },
  eventAlreadyRestoredError: {
    id: 'app.common.errors.422.eventAlreadyRestoredError',
    defaultMessage: 'Event with ids already restored',
  },
  eventStartDateHasAlreadyExpiredError: {
    id: 'app.common.errors.422.eventStartDateHasAlreadyExpiredError',
    defaultMessage: 'Event start date has already expired',
  },
  registerAlreadyOpenedError: {
    id: 'app.common.errors.422.registerAlreadyOpenedError',
    defaultMessage: 'Register with id already opened',
  },
  registerAlreadyClosedError: {
    id: 'app.common.errors.422.registerAlreadyClosedError',
    defaultMessage: 'Register with id already closed',
  },
  paymentMethodAlreadyBlockedError: {
    id: 'app.common.errors.422.paymentMethodAlreadyBlockedError',
    defaultMessage: 'Payment method instance with this uuid already blocked',
  },
  paymentMethodInstanceNotFoundError: {
    id: 'app.common.errors.422.paymentMethodInstanceNotFoundError',
    defaultMessage: 'Payment method instance with this uuid not found',
  },
  alertConditionIgnoreError: {
    id: 'app.common.errors.422.alertConditionIgnoreError',
    defaultMessage: "Alert condition can't be ignored",
  },
  fallthroughReasonNotFoundError: {
    id: 'app.common.errors.422.fallthroughReasonNotFoundError',
    defaultMessage: 'Fallthrough reason with id not found',
  },
  fallthroughLeadCaptureNotFoundError: {
    id: 'app.common.errors.422.fallthroughLeadCaptureNotFoundError',
    defaultMessage: 'Fallthrough lead capture with id not found',
  },
  tagNotFoundError: {
    id: 'app.common.errors.422.tagNotFoundError',
    defaultMessage: 'Tag with id not found',
  },
  dictionaryUpdateError: {
    id: 'app.common.errors.422.dictionaryIsInactiveCantBeUpdatedError',
    defaultMessage: 'Dictionary is inactive and can not be updated',
  },
  dictionaryTypeWithoutActiveError: {
    id: 'app.common.errors.422.dictionaryTypeWithoutActiveError',
    defaultMessage: 'Can not create dictionary, invalid request.',
  },
  invoiceNotFoundError: {
    id: 'app.common.errors.422.invoiceNotFoundError',
    defaultMessage: 'Invoice not found.',
  },
  invoiceSubtotalAmountError: {
    id: 'app.common.errors.422.invoiceSubtotalAmountError',
    defaultMessage: 'Invoice sub totale error, expected and actual values not matched',
  },
  invoiceTaxAmountError: {
    id: 'app.common.errors.422.invoiceTaxAmountError',
    defaultMessage: 'Invoice tax amount error, expected and actual values not matched',
  },
  invoiceTotalAmountError: {
    id: 'app.common.errors.422.invoiceTotalAmountError',
    defaultMessage: 'Invoice total amount error, expected and actual values not matched',
  },
  invoicePaymentAmountError: {
    id: 'app.common.errors.422.invoicePaymentAmountError',
    defaultMessage: 'Invoice payments amount error, expected and actual values not matched',
  },
  paymentMethodInstanceLeftAmountError: {
    id: 'app.common.errors.422.paymentMethodInstanceLeftAmountError',
    defaultMessage: 'Payments method instance left amount is not enough',
  },
  revenueCodeTaxRateNotFoundError: {
    id: 'app.common.errors.422.revenueCodeTaxRateNotFoundError',
    defaultMessage: 'Revenue code tax rate not found',
  },
  salesStatusNullError: {
    id: 'app.common.errors.422.salesStatusNullError',
    defaultMessage: "Sales status can't be null",
  },
  fileNotFoundError: {
    id: 'app.common.errors.422.fileNotFoundError',
    defaultMessage: 'File not found',
  },
  tmpFileNotFoundError: {
    id: 'app.common.errors.422.tmpFileNotFoundError',
    defaultMessage: 'Temp file not found',
  },
  openRegisterDeactivatedError: {
    id: 'app.common.errors.422.openRegisterDeactivatedError',
    defaultMessage: "Register is opened and can't change active status",
  },
  securityPolicyNotFoundError: {
    id: 'app.common.errors.422.securityPolicyNotFoundError',
    defaultMessage: 'Security policy not found',
  },
  securityPoliciesNotFoundError: {
    id: 'app.common.errors.422.securityPoliciesNotFoundError',
    defaultMessage: 'Some of security policies not found',
  },
  securityRoleNotFoundError: {
    id: 'app.common.errors.422.securityRoleNotFoundError',
    defaultMessage: 'Security role not found',
  },
  allergyNotFoundError: {
    id: 'app.common.errors.422.allergyNotFoundError',
    defaultMessage: 'Allergy with not found',
  },
  packageTemplateNotFoundError: {
    id: 'app.common.errors.422.packageTemplateNotFoundError',
    defaultMessage: 'Bundle template not found',
  },
  addingFamilyMemberError: {
    id: 'app.common.errors.422.addingFamilyMemberError',
    defaultMessage: "Can't add family member when corporations not equal",
  },
  personNotFoundError: {
    id: 'app.common.errors.422.personNotFoundError',
    defaultMessage: 'Person not found',
  },
  personDocumentsNotFoundError: {
    id: 'app.common.errors.422.personDocumentsNotFoundError',
    defaultMessage: 'Nothing to sign',
  },
  customerClubNotFoundError: {
    id: 'app.common.errors.422.customerClubNotFoundError',
    defaultMessage: "Customer with doesn't have a club",
  },
  corporationNotFoundError: {
    id: 'app.common.errors.422.corporationNotFoundError',
    defaultMessage: 'Corporation not found',
  },
  payPeriodSettingsDateRangeError: {
    id: 'app.common.errors.422.payPeriodSettingsDateRangeError',
    defaultMessage: 'Corporation pay period settings on this date range already exists',
  },
  payPeriodSettingsNotFound: {
    id: 'app.common.errors.422.payPeriodSettingsNotFound',
    defaultMessage: 'Corporation pay period settings with id not found',
  },
  payPeriodSettingsTitleError: {
    id: 'app.common.errors.422.payPeriodSettingsTitleError',
    defaultMessage: 'Corporation pay period settings with title already exists',
  },
  payPeriodPassedExistsOnDateRangeError: {
    id: 'app.common.errors.422.payPeriodPassedExistsOnDateRangeError',
    defaultMessage: 'Passed pay period on this settings date range already exists',
  },
  payPeriodLockedError: {
    id: 'app.common.errors.422.payPeriodLockedError',
    defaultMessage: 'Locked pay period cant be changed',
  },
  payPeriodPassedError: {
    id: 'app.common.errors.422.payPeriodPassedError',
    defaultMessage: 'Passed pay period cant be changed',
  },
  payPeriodInvalidDateRangeError: {
    id: 'app.common.errors.422.payPeriodInvalidDateRangeError',
    defaultMessage: 'Pay period on this date range already exists',
  },
  payPeriodNotFound: {
    id: 'app.common.errors.422.payPeriodNotFound',
    defaultMessage: 'Pay period with id not found',
  },
  cancelMembershipExistsError: {
    id: 'app.common.errors.422.cancelMembershipExistError',
    defaultMessage: 'Cancel of this membership package already exists',
  },
  openedRegisterCanNotBeUpdated: {
    id: 'app.common.errors.422.openedRegisterCanNotBeUpdated',
    defaultMessage: "Opened register can't be updated",
  },
  checkingSavingsExistError: {
    id: 'app.common.errors.422.checkingSavingsExistError',
    defaultMessage: 'Checking/savings with such credentials already exists',
  },
  checkingSavingsInactiveError: {
    id: 'app.common.errors.422.checkingSavingsInactiveError',
    defaultMessage: 'Checking/savings is not active',
  },
  notificationEmptyBodyError: {
    id: 'app.common.errors.422.notificationEmptyBodyError',
    defaultMessage: 'Message body should not be empty',
  },
  onAccountPaymentMethodInvalidAmountError: {
    id: 'app.common.errors.422.onAccountPaymentMethodInvalidAmountError',
    defaultMessage: 'On account payment amount exceeded customer balance',
  },
  onAccountPaymentFailureError: {
    id: 'app.common.errors.422.onAccountPaymentFailureError',
    defaultMessage: 'An error occurred during on account payment',
  },
  duplicateSubpackageError: {
    id: 'app.common.errors.422.duplicateSubpackageError',
    defaultMessage: 'Package(s) are duplicated in parent bundle',
  },
  activeMembershipError: {
    id: 'app.common.errors.422.activeMembershipError',
    defaultMessage: 'Member has already active membership',
  },
  membershipInProgressError: {
    id: 'app.common.errors.422.membershipInProgressError',
    defaultMessage:
      'Found not finished change plan action in invoice #{variable_0} in "{variable_1}" register. Finish or void it first of all.',
  },
  invalidTimezoneError: {
    id: 'app.common.errors.422.invalidTimezoneError',
    defaultMessage: 'Invalid timezone',
  },
  invalidRegionError: {
    id: 'app.common.errors.422.invalidRegionError',
    defaultMessage: 'Invalid region',
  },
  invalidImageError: {
    id: 'app.common.errors.422.invalidImageError',
    defaultMessage: 'Invalid image',
  },
  revenueCodesNotFoundError: {
    id: 'app.common.errors.422.revenueCodesNotFoundError',
    defaultMessage: 'Some of revenue codes is not found',
  },
  clubDuplicationError: {
    id: 'app.common.errors.422.clubDuplicationError',
    defaultMessage: 'Club duplications error',
  },
  alertPreferenceNotFoundError: {
    id: 'app.common.errors.422.alertPreferenceNotFoundError',
    defaultMessage: "Can not found any employee's or predefined alert preference.",
  },
  incorrectBarcodeError: {
    id: 'app.common.errors.422.incorrectBarcodeError',
    defaultMessage: 'Invalid barcode',
  },
  paymentMethodInstanceNotFoundByBarcodeError: {
    id: 'app.common.errors.422.paymentMethodInstanceNotFoundByBarcodeError',
    defaultMessage: 'Payment method instance by barcode not found',
  },
  peakEmployeeNotFoundError: {
    id: 'app.common.errors.422.peakEmployeeNotFoundError',
    defaultMessage: 'Peak employee not found',
  },
  packageContractNotFoundError: {
    id: 'app.common.errors.422.packageContractNotFoundError',
    defaultMessage: "Bundle doesn't have contract template",
  },
  personContractNotFoundError: {
    id: 'app.common.errors.422.personContractNotFoundError',
    defaultMessage: "Person doesn't have contract",
  },
  corporateSuEmployeeNotFoundError: {
    id: 'app.common.errors.422.corporateSuEmployeeNotFoundError',
    defaultMessage: 'Corporation superuser not found',
  },
  inventoryClubOnHandExceeded: {
    id: 'app.common.errors.422.inventoryClubOnHandExceeded',
    defaultMessage: 'Inventory club invoice number exceeds on hand amount: {variable_0}.',
  },
  seederTypeIsNotSupportedError: {
    id: 'app.common.errors.422.seederTypeIsNotSupportedError',
    defaultMessage: 'Seed type is not supported',
  },
  clubCodeShouldBeUniqueError: {
    id: 'app.common.errors.422.clubCodeShouldBeUniqueError',
    defaultMessage: 'Club code should be unique',
  },
  invoiceCustomerNotProvidedError: {
    id: 'app.common.errors.422.invoiceCustomerNotProvidedError',
    defaultMessage: 'Invoice customer not provided',
  },
  timeclockInProgressExistError: {
    id: 'app.common.errors.422.timeclockInProgressExistError',
    defaultMessage:
      'The job position you are trying to edit is currently clocked in. Please do a clock out (in timeclock section or ask employee to clock out by themself) and try again.',
  },
  personWaiverNotFoundError: {
    id: 'app.common.errors.422.personWaiverNotFoundError',
    defaultMessage: "Person doesn't have related waiver",
  },
  notificationNotFoundError: {
    id: 'app.common.errors.422.notificationNotFoundError',
    defaultMessage: 'Selected notification not found',
  },
  serviceUsedInMemberMembershipError: {
    id: 'app.common.errors.422.serviceUsedInMemberMembershipError',
    defaultMessage: "Service is used in member's membership and cannot be edited.",
  },
  cameFromCanNotUpdateTypeAndReduceOptionsError: {
    id: 'app.common.errors.422.cameFromCanNotUpdateTypeAndReduceOptionsError',
    defaultMessage: "Can't update type and reduce options in used came from.",
  },
  cameFromIndicatorNotFoundError: {
    id: 'app.common.errors.422.cameFromIndicatorNotFoundError',
    defaultMessage: 'Came from indicator not found.',
  },
  cameFromIndicatorNameNotUniqueError: {
    id: 'app.common.errors.422.cameFromIndicatorNameNotUniqueError',
    defaultMessage: 'Came from indicator name not unique.',
  },
  dropdownSelectHasNotAnyOptionError: {
    id: 'app.common.errors.422.dropdownSelectHasNotAnyOptionError',
    defaultMessage: "Dropdown select hasn't got any option.",
  },
  cameFromMustContainPlaceholderError: {
    id: 'app.common.errors.422.cameFromMustContainPlaceholderError',
    defaultMessage: 'Came from must contain placeholder.',
  },
  canNotUpdateTypeAndReduceOptionsError: {
    id: 'app.common.errors.422.canNotUpdateTypeAndReduceOptionsError',
    defaultMessage: "Can't update type and reduce options in used came from.",
  },
  noteCodeNotFoundError: {
    id: 'app.common.errors.422.noteCodeNotFoundError',
    defaultMessage: 'Note code not found.',
  },
  departmentNotFoundError: {
    id: 'app.common.errors.422.departmentNotFoundError',
    defaultMessage: 'Department not found.',
  },
  additionalFieldNotFoundError: {
    id: 'app.common.errors.422.additionalFieldNotFoundError',
    defaultMessage: 'Additional field not found.',
  },
  cameFromIndicatorNotOptionalError: {
    id: 'app.common.errors.422.cameFromIndicatorNotOptionalError',
    defaultMessage: 'Came from indicator not optional but value not found.',
  },
  notificationForCorporationNotFoundError: {
    id: 'app.common.errors.422.notificationForCorporationNotFoundError',
    defaultMessage: 'Notification for corporation not found.',
  },
  cameFromMustContainOptionalFieldError: {
    id: 'app.common.errors.422.cameFromMustContainOptionalFieldError',
    defaultMessage: 'Came from must contain optional field.',
  },
  emailHistoryNotFoundError: {
    id: 'app.common.errors.422.emailHistoryNotFoundError',
    defaultMessage: 'Email history not found.',
  },
  leadActivityAlreadyExistsError: {
    id: 'app.common.errors.422.leadActivityAlreadyExistsError',
    defaultMessage: 'Lead activity with title already exists.',
  },
  clubGroupNotFoundError: {
    id: 'app.common.errors.422.clubGroupNotFoundError',
    defaultMessage: 'Club group with id not found.',
  },
  clubGroupAlreadyExistError: {
    id: 'app.common.errors.422.clubGroupAlreadyExistError',
    defaultMessage: 'Club group with title already exists.',
  },
  invoiceTotalExceedsError: {
    id: 'app.common.errors.422.invoiceTotalExceedsError',
    defaultMessage: 'Invoice total exceeds max NOS amount.',
  },
  timeAccessNotFoundError: {
    id: 'app.common.errors.422.timeAccessNotFoundError',
    defaultMessage: 'Time access with id not found.',
  },
  timeAccessAlreadyExistsError: {
    id: 'app.common.errors.422.timeAccessAlreadyExistsError',
    defaultMessage: 'Time access with title already exists.',
  },
  helpInfoNotFoundError: {
    id: 'app.common.errors.422.helpInfoNotFoundError',
    defaultMessage: 'Help info with id not found.',
  },
  helpInfoAlreadyExistsError: {
    id: 'app.common.errors.422.helpInfoAlreadyExistsError',
    defaultMessage: 'Help info with title already exists.',
  },
  insuranceCompanyNotFoundError: {
    id: 'app.common.errors.422.insuranceCompanyNotFoundError',
    defaultMessage: 'Insurance company with id not found.',
  },
  insuranceCompanyAlreadyExistsError: {
    id: 'app.common.errors.422.insuranceCompanyAlreadyExistsError',
    defaultMessage: 'Insurance company with title already exists.',
  },
  profileRequiredFieldNotFound: {
    id: 'app.common.errors.422.profileRequiredFieldNotFound',
    defaultMessage: 'Profile required field not found.',
  },
  serviceInstanceNotFound: {
    id: 'app.common.errors.422.serviceInstanceNotFound',
    defaultMessage: 'Service instance not found.',
  },
  personDocumentNotFound: {
    id: 'app.common.errors.422.personDocumentNotFound',
    defaultMessage: 'Person document not found.',
  },
  feeNameNotUnique: {
    id: 'app.common.errors.422.feeNameNotUniqueError',
    defaultMessage: 'Fee with such name already exists.',
  },
  feeTypeNotFound: {
    id: 'app.common.errors.422.feeTypeNotFound',
    defaultMessage: 'Fee type not found.',
  },
  clubEntranceNotFoundError: {
    id: 'app.common.errors.422.clubEntranceNotFoundError',
    defaultMessage: 'Club entrance not found.',
  },
  entranceClosedError: {
    id: 'app.common.errors.422.entranceClosedError',
    defaultMessage: 'Entrance is already closed or not opened yet.',
  },
  eventStartDateMismatch: {
    id: 'app.common.errors.422.eventStartDateMismatch',
    defaultMessage: "Event doesn't start on selected date",
  },
  eventValidationError: {
    id: 'app.common.errors.422.eventValidationError',
    defaultMessage: 'Event validation error.',
  },
  eventParticipationError: {
    id: 'app.common.errors.422.eventParticipationError',
    defaultMessage: "Selected person doesn't participate in event",
  },
  titleNotUnique: {
    id: 'app.common.errors.422.titleNotUniqueError',
    defaultMessage: 'Item with such name already exist.',
  },
  barcodeNotUnique: {
    id: 'app.common.errors.422.barcodeNotUniqueError',
    defaultMessage: 'Barcode is not unique.',
  },
  dependentPackageCyclicError: {
    id: 'app.common.errors.422.dependentPackageCyclicError',
    defaultMessage: 'Some included packages can not be added because of cyclic dependency issue.',
  },
  lineMaxLengthError: {
    id: 'app.common.errors.422.lineMaxLengthError',
    defaultMessage: 'Line is too long.',
  },
  leadActivityNotFound: {
    id: 'app.common.errors.422.leadActivityNotFound',
    defaultMessage: 'Lead activity not found',
  },
  customerNotCheckedIn: {
    id: 'app.common.errors.422.customerNotCheckedIn',
    defaultMessage: 'Customer not checked in.',
  },
  employeeIdNotUnique: {
    id: 'app.common.errors.422.employeeIdNotUnique',
    defaultMessage: 'Employee id is not unique.',
  },
  userIdNotUnique: {
    id: 'app.common.errors.422.userIdNotUnique',
    defaultMessage: 'User id is not unique.',
  },
  canNotCheckoutGuardian: {
    id: 'app.common.errors.422.canNotCheckoutGuardian',
    defaultMessage: 'You can not checkout Guardian.',
  },
  kidZoneMemberAlreadyCheckedIn: {
    id: 'app.common.errors.422.kidZoneMemberAlreadyCheckedIn',
    defaultMessage: 'Selected KidZone member already checked in.',
  },
  corporationCampaignMustBeEnabled: {
    id: 'app.common.errors.422.corporationCampaignMustBeEnabled',
    defaultMessage: 'Corporation campaign must be enabled to edit settings.',
  },
  passedEventCanNotBeUpdated: {
    id: 'app.common.errors.422.passedEventCanNotBeUpdated',
    defaultMessage: "Passed event can't be updated.",
  },
  cashPaymentFieldsNotProvided: {
    id: 'app.common.errors.422.cashPaymentFieldsNotProvided',
    defaultMessage: 'Not all required fields for CASH payment provided.',
  },
  cashPaymentAmountsDontMatch: {
    id: 'app.common.errors.422.cashPaymentAmountsDontMatch',
    defaultMessage: "Payment amounts doesn't math.",
  },
  freezePackageOverlapsAlreadyFrozenPackage: {
    id: 'app.common.errors.422.freezePackageOverlapsAlreadyFrozenPackage',
    defaultMessage:
      "Some of selected services/bundles already frozen or planned to be. Check 'Freeze History' to ge more details" +
      ' and correct selected range for freeze.',
  },
  alertWithConditionNotFound: {
    id: 'app.common.errors.422.alertWithConditionNotFound',
    defaultMessage: 'Alert with selected condition not found.',
  },
  inactivePackageNotFound: {
    id: 'app.common.errors.422.inactivePackageNotFound',
    defaultMessage: 'Inactive selected package not found.',
  },
  customerHasActiveMembership: {
    id: 'app.common.errors.422.customerHasActiveMembership',
    defaultMessage: 'Customer already has active membership.',
  },
  registerWrongDevice: {
    id: 'app.common.errors.422.registerWrongDevice',
    defaultMessage: 'Register already active in other place.',
  },
  terminationDateAbsenceError: {
    id: 'app.common.errors.422.terminationDateAbsenceError',
    defaultMessage: 'Termination date should be set on.',
  },
  payeezyFailedRequest: {
    id: 'app.common.errors.422.payeezyFailedRequest',
    defaultMessage: 'Something went wrong on Payeezy side. Check Payeezy configuration settings.',
  },
  payeezyWrongCreditCardTypeRequest: {
    id: 'app.common.errors.422.payeezyWrongCreditCardTypeRequest',
    defaultMessage: "Credit card number doesn't matches VISA/MasterCard/Amex/Discover",
  },
  billingOptionNotFound: {
    id: 'app.common.errors.422.billingOptionNotFound',
    defaultMessage: 'Billing option not found.',
  },
  inventoryInstanceNotFound: {
    id: 'app.common.errors.422.inventoryInstanceNotFound',
    defaultMessage: 'Inventory instance not found.',
  },
  packageInventoryMaxNumberExceeded: {
    id: 'app.common.errors.422.packageInventoryMaxNumberExceeded',
    defaultMessage: 'Inventory amount exceeded.',
  },
  billingOptionsCanNotBeDeleted: {
    id: 'app.common.errors.422.billingOptionsCanNotBeDeleted',
    defaultMessage: "Billing options are chosen as included package's option and can't be deleted.",
  },
  cancelMembershipValidationError: {
    id: 'app.common.errors.422.cancelMembershipValidationError',
    defaultMessage: 'Cancel membership validation error.',
  },
  membershipNotFound: {
    id: 'app.common.errors.422.membershipNotFound',
    defaultMessage: 'Membership not found.',
  },
  resourceNotAvailableError: {
    id: 'app.common.errors.422.resourceNotAvailableError',
    defaultMessage: 'Resource not available at chosen time.',
  },
  kidZoneRedeemNotAvailableError: {
    id: 'app.common.errors.422.kidZoneRedeemNotAvailableError',
    defaultMessage: 'KidZone redeem not available at this datetime due bundle limitation.',
  },
  creditCardIsNotActiveError: {
    id: 'app.common.errors.422.creditCardIsNotActiveError',
    defaultMessage: 'Credit card is not active.',
  },

  occurredDuringCustomPaymentError: {
    id: 'app.common.errors.422.occurredDuringCustomPaymentError',
    defaultMessage: 'An error occurred during custom payment.',
  },
  alreadyUsedGiftCardError: {
    id: 'app.common.errors.422.alreadyUsedGiftCardError',
    defaultMessage: 'Gift card already used in current invoice.',
  },
  notProvidedCustomPaymentMethodError: {
    id: 'app.common.errors.422.notProvidedCustomPaymentMethodError',
    defaultMessage: 'Custom payment method not provided.',
  },
  invoicePercentageExceededMaxAvailableError: {
    id: 'app.common.errors.422.invoicePercentageExceededMaxAvailableError',
    defaultMessage: 'Custom payment invoice percentage exceeded max available.',
  },
  invoicePaymentIsFinishedError: {
    id: 'app.common.errors.422.invoicePaymentIsFinishedError',
    defaultMessage: 'Invoice payment is finished, can not add new Invoice payment splits.',
  },
  duplicateJobPositionError: {
    id: 'app.common.errors.422.duplicateJobPositionError',
    defaultMessage:
      'Employee can have only one job position with same club, department and pay type.',
  },
  employeeCanNotDeactivateError: {
    id: 'app.common.errors.422.employeeCanNotDeactivateError',
    defaultMessage: "Employee can't deactivate by his own.",
  },
  enlargePackageLengthError: {
    id: 'app.common.errors.422.enlargePackageLengthError',
    defaultMessage: 'Enlarge package length or correct billing option payment schedule.',
  },
  enlargePackageFeeLengthError: {
    id: 'app.common.errors.422.enlargePackageFeeLengthError',
    defaultMessage: 'Enlarge package length or correct fee payment schedule.',
  },
  maxFinancedBillingPaymentScheduleError: {
    id: 'app.common.errors.422.maxFinancedBillingScheduleError',
    defaultMessage: "Max financed billing option's paymentSchedule.",
  },
  maxRegularFeePaymentScheduleError: {
    id: 'app.common.errors.422.maxRegularFeePaymentScheduleError',
    defaultMessage: "Max regular fee's paymentSchedule.",
  },
  packageNotFoundError: {
    id: 'app.common.errors.422.packageNotFoundError',
    defaultMessage: 'Package not found.',
  },
  billingScheduleNotFoundError: {
    id: 'app.common.errors.422.billingScheduleNotFoundError',
    defaultMessage: 'Billing schedule not found.',
  },
  fieldTitleLengthError: {
    id: 'app.common.errors.422.fieldTitleLengthError',
    defaultMessage: 'Title field length should not be greater than 60 characters.',
  },
  checkInNotFoundError: {
    id: 'app.common.errors.422.checkInNotFoundError',
    defaultMessage: 'CheckIn with id = {variable_0} not found.',
  },
  iTransactSettingsNotConfiguredError: {
    id: 'app.common.errors.422.iTransactSettingsNotConfiguredError',
    defaultMessage: 'iTransact settings configuration error: {variable_0}',
  },
  iTransactFailedToAddPaymentSourceError: {
    id: 'app.common.errors.422.iTransactFailedToAddPaymentSourceError',
    defaultMessage: 'Failed to add {variable_0} as payment source to iTransact',
  },
  paymentProcessorDisabledError: {
    id: 'app.common.errors.422.paymentProcessorDisabledError',
    defaultMessage: 'Payment processors disabled',
  },
  iTransactFailedRequestError: {
    id: 'app.common.errors.422.iTransactFailedRequestError',
    defaultMessage:
      'Something went wrong on iTransact side. Http code: <{variable_0}>. Message: <{variable_1}>',
  },
  payeezyDoesNotSupportOperationError: {
    id: 'app.common.errors.422.payeezyDoesNotSupportOperationError',
    defaultMessage: 'Payeezy does not support {variable_0}.',
  },
  zendeskIsDisabledError: {
    id: 'app.common.errors.422.zendeskIsDisabledError',
    defaultMessage: 'Zendesk is disabled or some settings required field are missing',
  },
  zendeskCustomFieldNotFoundError: {
    id: 'app.common.errors.422.zendeskCustomFieldNotFoundError',
    defaultMessage: 'Zendesk custom field {variable_0} not found',
  },
  zendeskValidationException: {
    id: 'app.common.errors.422.zendeskValidationException',
    defaultMessage: 'Zendesk validation exception. {variable_0}',
  },
  paymentMethodIsNotActiveException: {
    id: 'app.common.errors.422.paymentMethodIsNotActiveException',
    defaultMessage: 'Payment method {variable_0} is not active.',
  },
  fieldIsRequiredException: {
    id: 'app.common.errors.422.fieldIsRequiredException',
    defaultMessage: 'Field {variable_0} is required and should be set.',
  },
  invoicePaymentSplitExceedAmountException: {
    id: 'app.common.errors.422.invoicePaymentSplitExceedAmountException',
    defaultMessage:
      'Invoice payment splits amount is more then invoice amount. Invoice amount: {variable_0}, Invoice splits total:{variable_1}',
  },
  roleCanNotBeChanged: {
    id: 'app.common.errors.422.roleCanNotBeChanged',
    defaultMessage: "Auto generated security role can't be changed.",
  },
  policyCanNotBeChanged: {
    id: 'app.common.errors.422.policyCanNotBeChanged',
    defaultMessage: "Auto generated security policy can't be changed.",
  },
  overbookingResourcesMissing: {
    id: 'app.common.errors.422.overbookingResourcesMissing',
    defaultMessage: 'Overbooking resources missing.',
  },
  // 500
  internalServerError: {
    id: 'app.common.errors.500.internalServerError',
    defaultMessage: 'Server failed unexpectedly with your request.',
  },
  objectMappingError: {
    id: 'app.common.errors.500.objectMappingError',
    defaultMessage: 'Error during object parsing',
  },
  responseTimeoutError: {
    id: 'app.common.errors.500.responseTimeoutError',
    defaultMessage: 'Something went wrong. Server response timeout.',
  },
  cameFromIndicatorReferralListTypeIsAlreadyActive: {
    id: 'app.common.errors.422.cameFromIndicatorReferralListTypeIsAlreadyActive',
    defaultMessage: 'Came from indicator referral list type is already active',
  },
  outOfTermCanNotBeDefault: {
    id: 'app.common.errors.422.outOfTermCanNotBeDefault',
    defaultMessage: `Out of term option can't be chosen as default option`,
  },
  freezePackageDayAmountExceededAllowAmount: {
    id: 'app.common.errors.422.freezePackageDayAmountExceededAllowAmount',
    defaultMessage: 'Freeze package day amount exceeded allow amount',
  },
  bannerAdvertisementWithIdNotFound: {
    id: 'app.common.errors.422.bannerAdvertisementWithIdNotFound',
    defaultMessage: 'Banner advertisement with id = {variable_0} not found',
  },
  feeProcessingNotFound: {
    id: 'app.common.errors.422.feeProcessingNotFound',
    defaultMessage:
      'Fee processing with id= {variable_0}, type = {variable_1} and category = {variable_2} not found',
  },
  feeSingleNotFound: {
    id: 'app.common.errors.422.feeSingleNotFound',
    defaultMessage: 'Fee single with id = {variable_0} and type = {variable_0} not found',
  },
  feeTransactionNotFound: {
    id: 'app.common.errors.422.feeTransactionNotFound',
    defaultMessage:
      'Fee transaction with id = {variable_0} and type = {variable_0}  and category = {variable_2} not found',
  },
  feeLateNotFound: {
    id: 'app.common.errors.422.feeLateNotFound',
    defaultMessage: 'Fee late with id = {variable_0}  and whichever = {variable_0} not found',
  },
  feeMonthlyNotFound: {
    id: 'app.common.errors.422.feeMonthlyNotFound',
    defaultMessage: 'Fee monthly with id = {variable_0} and type = {variable_0} not found',
  },
  feeReturnNotFound: {
    id: 'app.common.errors.422.feeReturnNotFound',
    defaultMessage: 'Fee return with id = {variable_0} and type = {variable_0} not found',
  },
  payPeriodAlreadyStarted: {
    id: 'app.common.errors.422.payPeriodAlreadyStarted',
    defaultMessage: 'Corporation pay period with id = {variable_0} already started',
  },
});
