import messages from 'common/messages/messages';
import { useCallback, useEffect, useRef } from 'react';
import { MessageDescriptor } from 'react-intl';
import { useRenderIntlMessage } from './useRenderIntlMessage';

const useShowAlert = (
  shouldBlockCancelFunction: boolean,
  cancelFunction: (...args) => void,
  actionSuccess?: boolean,
  message: MessageDescriptor = messages.leavePageWarning,
): ((...args) => void) => {
  const renderIntlMessage = useRenderIntlMessage();
  const messageString = renderIntlMessage(message);

  const blockRefresh = e => {
    e.preventDefault();
    e.returnValue = '';
  };

  const blockRefreshRef = useRef(blockRefresh);
  // const blockRouteChangeRef = useRef(null);

  // listen when route is change
  useEffect(() => {
    // TODO block() was removed in react-router-dom v6, need to find a new solution
    /* blockRouteChangeRef.current = history.block((): any => {
      if (shouldBlockCancelFunction) {
        // eslint-disable-next-line no-alert
        return window.confirm(messageString);
      }
      return true;
    }); */
  }, [shouldBlockCancelFunction, messageString]);

  // listen when page unload
  useEffect(() => {
    if (shouldBlockCancelFunction) window.addEventListener('beforeunload', blockRefreshRef.current);
  }, [shouldBlockCancelFunction]);

  // remove listener before unmount
  useEffect(
    () => () => {
      window.removeEventListener('beforeunload', blockRefreshRef.current);
      // blockRouteChangeRef.current();
    },
    [],
  );

  return useCallback(
    (...args) => {
      // eslint-disable-next-line no-alert
      if (!shouldBlockCancelFunction || actionSuccess || window.confirm(messageString)) {
        // remove route change prompt
        // blockRouteChangeRef.current();
        window.removeEventListener('beforeunload', blockRefreshRef.current);
        cancelFunction(...args);
      }
    },
    [shouldBlockCancelFunction, actionSuccess, cancelFunction, messageString],
  );
};
export default useShowAlert;
