import profilePlaceholder from 'img/photo-placeholder.png';
import styled from 'styled-components';

export default styled.div`
  width: 48px;
  min-width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 0.875rem;
  background-image: url(${(props: any) => props.avatarUrl || profilePlaceholder});
  background-size: cover;
`;
