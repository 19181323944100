import axios from 'axios';

import ApiService from 'services/network/ApiService';

import { IHelpInfoSearchResult } from 'common/interfaces/search';

export default class HelpInfoSearchService extends ApiService {
  public getHelpInfoSearchResults = (search: string): Promise<IHelpInfoSearchResult> =>
    axios.get('/api/v1/help', { params: { search, perPage: 0, page: 0 } });
}
