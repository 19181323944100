import * as actionTypes from './constants';
import { PeakModules } from 'common/constants/peakModules';
import { GeneralThunkAction } from 'common/state/interfaces';
import Services from 'services/network';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import {
  ITicketCreateDto,
  ITicketDetailedDto,
  ITicketShortDto,
} from 'common/components/PersonProfile/interfaces';
import { createPersonPrepAction } from 'common/components/PersonProfile/state/actions';
import { ActionResult } from 'common/constants';

export const setPersonTicketsErrorAction = createPersonPrepAction<Error>(
  actionTypes.SET_PERSON_TICKETS_ERROR,
);

const fetchPersonTicketsSuccessAction = createPersonPrepAction<ITicketShortDto[]>(
  actionTypes.FETCH_PERSON_TICKETS_SUCCESS,
);
const fetchPersonTicketsLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_TICKETS_LOADING,
);
export const resetPersonTickets = createPersonPrepAction(actionTypes.RESET_PERSON_TICKETS);

export const fetchPersonTickets = (personId: string, module: PeakModules): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPersonTicketsLoadingAction(true, personId));

    try {
      let tickets: ITicketShortDto[] = [];

      switch (module) {
        case PeakModules.FrontDesk:
          tickets = await Services.FrontDesk.getPersonTickets(personId);
          break;
        case PeakModules.Members:
          tickets = await Services.Members.getPersonTickets(personId);
          break;
        default:
      }

      dispatch(fetchPersonTicketsSuccessAction(tickets, personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
      dispatch(setPersonTicketsErrorAction(e, personId));
    } finally {
      dispatch(fetchPersonTicketsLoadingAction(false, personId));
    }
  };
};

const fetchPersonTicketSuccessAction = createPersonPrepAction<ITicketDetailedDto>(
  actionTypes.FETCH_PERSON_TICKET_SUCCESS,
);
const fetchPersonTicketLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_TICKET_LOADING,
);
export const resetPersonTicket = createPersonPrepAction(actionTypes.RESET_PERSON_TICKET);

export const fetchPersonTicket = (
  personId: string,
  ticketId: number,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPersonTicketLoadingAction(true, personId));

    try {
      let ticket: ITicketDetailedDto = {} as ITicketDetailedDto;

      switch (module) {
        case PeakModules.FrontDesk:
          ticket = await Services.FrontDesk.getPersonTicket(personId, ticketId);
          break;
        case PeakModules.Members:
          ticket = await Services.Members.getPersonTicket(personId, ticketId);
          break;
        default:
      }

      dispatch(fetchPersonTicketSuccessAction(ticket, personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
      dispatch(setPersonTicketsErrorAction(e, personId));
    } finally {
      dispatch(fetchPersonTicketLoadingAction(false, personId));
    }
  };
};

export const addPersonTicketSuccessAction = createPersonPrepAction<ActionResult | null>(
  actionTypes.ADD_PERSON_TICKET_SUCCESS,
);
const addPersonTicketLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.ADD_PERSON_TICKET_LOADING,
);

export const addPersonTicket = (
  personId: string,
  attachments: File[],
  dto: ITicketCreateDto,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(addPersonTicketLoadingAction(true, personId));

    try {
      switch (module) {
        case PeakModules.FrontDesk:
          await Services.FrontDesk.createPersonTicket(personId, attachments, dto);
          break;
        case PeakModules.Members:
          await Services.Members.createPersonTicket(personId, attachments, dto);
          break;
        default:
      }

      dispatch(addPersonTicketSuccessAction(ActionResult.SUCCESS_ACTION, personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
      dispatch(setPersonTicketsErrorAction(e, personId));
    } finally {
      dispatch(addPersonTicketLoadingAction(false, personId));
    }
  };
};
