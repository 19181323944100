import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';

import { IActionRequestStatusImt } from 'common/state/interfaces';
import { IFilterSettings } from 'common/interfaces/filter';
import { IHeadCell, ITableParams, ITableRow } from 'common/interfaces/table';
import { IResourceListItem, IResourceListItemImt } from 'modules/booking/interfaces';
import { IPageMetaImt } from 'common/interfaces/pagination';
import { selectQueryPageList } from 'common/state/queryPage-lists/selectors';
import { QueryPageList } from 'common/constants';
import { ReactComponent as XDeleteIcon } from 'img/icons/times.svg';
import { ReactComponent as CheckIcon } from 'img/icons/check.svg';
import { ReactComponent as EditIcon } from 'img/icons/edit.svg';
import { ActionItem, ActionsMenu, ChangeStatusModal, Table } from 'common/components';
import { TableOrderByParams } from 'common/constants/table';
import EmployeeCell from './EmployeeCell/EmployeeCell';
import SmallScreenCell from './SmallScreenCell/SmallScreenCell';
import tableHeaders from 'common/messages/tableHeaders';
import commonMessages from 'common/messages/messages';
import menuItems from 'common/messages/menuItems';

interface IProps {
  tableParams: ITableParams;
  tableFilterSettings: IFilterSettings[];
  items: ImmutableList<IResourceListItemImt>;
  meta: IPageMetaImt;
  isLoading: boolean;
  onChangeParams: (tableParams: ITableParams) => void;
  changeStatusResourceAction: IActionRequestStatusImt;
  changeResourceStatus: (resourceId: string, isActive: boolean) => void;
  resetResourceChangeStatus: () => void;
}

const headerOptions: IHeadCell[] = [
  {
    id: TableOrderByParams.NAME,
    label: <FormattedMessage {...tableHeaders.name} />,
    sort: true,
    padding: 'none',
  },
  {
    id: TableOrderByParams.EMPLOYEE,
    label: <FormattedMessage {...tableHeaders.employee} />,
    sort: true,
  },
  { id: TableOrderByParams.CLUB, label: <FormattedMessage {...tableHeaders.clubs} />, sort: true },
  {
    id: TableOrderByParams.ACTIVE,
    label: <FormattedMessage {...tableHeaders.status} />,
    sort: true,
  },
  {
    id: 'actions',
    label: <FormattedMessage {...tableHeaders.actions} />,
    sort: false,
    align: 'center',
    padding: 'none',
  },
];

const useStyles = makeStyles({
  icon: {
    width: 16,
    height: 16,
  },
});

const ResourcesTable = (props: IProps): JSX.Element => {
  const {
    isLoading,
    items,
    meta,
    onChangeParams,
    tableParams,
    tableFilterSettings,
    changeResourceStatus,
    changeStatusResourceAction,
    resetResourceChangeStatus,
  } = props;
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState<IResourceListItem | null>(null);
  const [showChangeStatusDialog, setShowChangeStatusDialog] = useState<boolean>(false);
  const isStatusChangeLoading = changeStatusResourceAction.get('isLoading');

  const queryBooking: string = useSelector(selectQueryPageList(QueryPageList.BOOKING));

  const classes = useStyles();

  const handleEditClick = useCallback(
    (resourceId: string) => {
      navigate(`/booking/resources/${resourceId}/edit`);
    },
    [navigate],
  );

  const handleChangeStatusDialogShow = (resource: IResourceListItem) => {
    setSelectedRow(resource);
    setShowChangeStatusDialog(true);
  };

  const handleChangeStatusResource = () => {
    changeResourceStatus(selectedRow.id, !selectedRow.active);
  };

  const handleChangeStatusDialogClose = () => {
    setShowChangeStatusDialog(false);
  };

  useEffect(() => {
    if (changeStatusResourceAction.getIn(['result', 'success'])) {
      resetResourceChangeStatus();
      setShowChangeStatusDialog(false);
    }
  }, [changeStatusResourceAction, resetResourceChangeStatus]);

  const createRows = (): ITableRow[] => {
    return items
      .map(
        (resource: IResourceListItemImt): ITableRow => ({
          id: resource.get('id'),
          cells: [
            {
              padding: 'none',
              variant: 'subtitle2',
              label: resource.get('label'),
            },
            {
              label: '-',
              cellComponent: resource.get('employee') ? (
                <EmployeeCell
                  firstName={resource.getIn(['employee', 'firstName']) || ''}
                  lastName={resource.getIn(['employee', 'lastName']) || ''}
                  imageUrl={resource.getIn(['employee', 'imageUrl']) || ''}
                  onClick={() => navigate('/employees')}
                />
              ) : null,
            },
            {
              label: '-',
              cellComponent: (
                <>
                  {resource.get('clubs').map(club => (
                    <Typography key={club}>{club}</Typography>
                  ))}
                </>
              ),
            },
            {
              label: '',
              cellComponent: (
                <Typography
                  variant="body1"
                  color={resource.get('active') ? 'primary' : 'textSecondary'}
                >
                  <FormattedMessage
                    {...(resource.get('active')
                      ? commonMessages.activeOption
                      : commonMessages.inactiveOption)}
                  />
                </Typography>
              ),
            },
            {
              label: 'actions',
              align: 'center',
              padding: 'none',
              width: '54px',
              cellComponent: (
                <ActionsMenu horizontal tableActionsMode>
                  <ActionItem
                    key="1"
                    icon={<EditIcon className={classes.icon} />}
                    onClick={() => handleEditClick(resource.get('id'))}
                  >
                    <FormattedMessage {...commonMessages.editBtn} />
                  </ActionItem>
                  <ActionItem
                    key="3"
                    icon={
                      resource.get('active') ? (
                        <XDeleteIcon className={classes.icon} />
                      ) : (
                        <CheckIcon className={classes.icon} />
                      )
                    }
                    onClick={() => handleChangeStatusDialogShow(resource.toJS())}
                  >
                    <FormattedMessage
                      {...(resource.get('active') ? menuItems.deactivate : menuItems.activate)}
                    />
                  </ActionItem>
                </ActionsMenu>
              ),
            },
          ],
          cellsMini: [
            {
              label: '-',
              cellComponent: (
                <SmallScreenCell resource={resource.toJS()} clubs={resource.get('clubs')} />
              ),
            },
            {
              label: '-',
              align: 'center',
              padding: 'none',
              cellComponent: (
                <ActionsMenu horizontal tableActionsMode>
                  <ActionItem
                    key="1"
                    icon={<EditIcon className={classes.icon} />}
                    onClick={() => handleEditClick(resource.get('id'))}
                  >
                    <FormattedMessage {...commonMessages.editBtn} />
                  </ActionItem>
                  <ActionItem
                    key="3"
                    icon={
                      resource.get('active') ? (
                        <XDeleteIcon className={classes.icon} />
                      ) : (
                        <CheckIcon className={classes.icon} />
                      )
                    }
                    onClick={() => handleChangeStatusDialogShow(resource.toJS())}
                  >
                    <FormattedMessage
                      {...(resource.get('active') ? menuItems.deactivate : menuItems.activate)}
                    />
                  </ActionItem>
                </ActionsMenu>
              ),
            },
          ],
        }),
      )
      .toJS();
  };

  const renderModals = (): JSX.Element => {
    return (
      <ChangeStatusModal
        itemTitle={selectedRow && selectedRow.label}
        isOpen={showChangeStatusDialog}
        onClose={handleChangeStatusDialogClose}
        onSubmit={handleChangeStatusResource}
        isActive={selectedRow && selectedRow.active}
        isLoading={isStatusChangeLoading}
      />
    );
  };

  return (
    <>
      <Table
        title={<FormattedMessage {...tableHeaders.resources} />}
        backRedirectLink={`/booking${queryBooking}`}
        addButtonRedirect="/booking/resources/new"
        headerOptions={headerOptions}
        showPerPageSelect
        isLoading={isLoading}
        rows={createRows()}
        filters={tableFilterSettings}
        tableParams={tableParams}
        totalRows={meta.get('total')}
        onChangeParams={onChangeParams}
      />
      {renderModals()}
    </>
  );
};

export default ResourcesTable;
