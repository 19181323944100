// libraries
import React, { useEffect } from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import { Grid, Typography } from '@material-ui/core';
// custom interfaces
import { MultipleSelect } from 'common/components';
import { IEmployeeListItem, IEmployeeListItemImt } from 'common/interfaces/dictionary';
// messages
import messages from 'modules/services/messages/packages';
import inputLabels from 'common/messages/inputLabels';
import { fetchDictionaryList } from 'common/state/dictionary/actions';
import { DictionaryList } from 'common/constants';
import { selectDictionaryList } from 'common/state/dictionary/selectors';
import { ServicesSubModules } from 'common/constants/peakModules';
import { useAppDispatch } from 'store/hooks';

interface IEmployeesSelectorProps {
  control: Control;
}

const EmployeesSelector: React.FC<IEmployeesSelectorProps> = (
  props: IEmployeesSelectorProps,
): JSX.Element => {
  const { control } = props;

  // form

  const selectedEmployees: Array<IEmployeeListItem> = useWatch({
    control,
    name: 'employees',
  });

  // state
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      fetchDictionaryList(DictionaryList.EMPLOYEES, { module: ServicesSubModules.ServicePackages }),
    );
  }, [dispatch]);

  const employeesList: ImmutableList<IEmployeeListItemImt> = useSelector(
    selectDictionaryList(DictionaryList.EMPLOYEES),
  );

  // renders

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="subtitle2">
          <FormattedMessage {...messages.limitEmployees} />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="employees"
          control={control}
          render={({ value, onChange, onBlur }) => (
            <MultipleSelect
              multiple
              fullWidth
              value={value || []}
              onChange={onChange}
              options={employeesList?.toJS()}
              onBlur={onBlur}
              label={
                <FormattedMessage
                  {...(selectedEmployees?.length > 0
                    ? inputLabels.employeesLabel
                    : messages.limitEmployeesAllAllowed)}
                />
              }
            />
          )}
        />
      </Grid>
    </>
  );
};

export default EmployeesSelector;
