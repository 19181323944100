import { createSelector } from 'reselect';

const selectFrontDeskPanelsDomain = state => state.getIn(['frontDesk', 'panels']);
const selectClientMemberTagsDomain = state => state.getIn(['frontDesk', 'memberTags']);

// Tags
export const selectClientMemberTags = () =>
  createSelector(selectClientMemberTagsDomain, state => state.get('clientMemberTags'));
export const selectCreateClientMemberTagsLoading = () =>
  createSelector(selectClientMemberTagsDomain, state => state.get('createTagLoading'));
export const selectCreateClientMemberTagSuccess = () =>
  createSelector(selectClientMemberTagsDomain, state => state.get('createTagSuccess'));

export const selectMainPanelPersonId = createSelector(selectFrontDeskPanelsDomain, state =>
  state.get('mainPanelPersonId'),
);

export const selectRightPanelPersonId = createSelector(selectFrontDeskPanelsDomain, state =>
  state.get('rightPanelPersonId'),
);

const clubEntrancesDataPath = ['frontDesk', 'clubEntrances'];

export const selectClubEntrancesList = state => state.getIn([...clubEntrancesDataPath, 'data']);

export const selectClubEntrancesLoading = state =>
  state.getIn([...clubEntrancesDataPath, 'isLoading']);

export const selectCurrentEntranceId = state =>
  state.getIn([...clubEntrancesDataPath, 'selectedEntranceId']);
