import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { List as ImmutableList } from 'immutable';

import { IScriptListItemImt } from 'modules/crm/interfaces/scripts';
import { FilterTypes, IFilterSettings } from 'common/interfaces/filter';

import { IPageMetaImt } from 'common/interfaces/pagination';
import { ITableParams } from 'common/interfaces/table';
import { ISuccessResponseImt } from 'common/interfaces/http';

import { RouteBackground } from 'components';
import { ScriptsTable } from 'modules/crm/components';

import { makeTableParams, pushQueryToUrl } from 'common/utils/http';
import { ActiveInactiveStatus, QueryPageList } from 'common/constants';

import * as actions from 'modules/crm/state/scripts/actions';
import { updateQueryParams } from 'common/state/queryPage-lists/actions';

import { useAppDispatch } from 'store/hooks';

import * as selectors from 'modules/crm/state/scripts/selectors';
// messages
import { FormattedMessage } from 'react-intl';
import tableFiltersMessages from 'common/messages/tableFilters';
import { PeakModules } from 'common/constants/peakModules';

const tableFilters: IFilterSettings[] = [
  {
    name: 'active',
    title: <FormattedMessage {...tableFiltersMessages.status} />,
    type: FilterTypes.SINGLE,
    options: ActiveInactiveStatus.map(status => ({
      key: status.key,
      label: status.label,
      value: status.value,
    })),
    defaultValue: ActiveInactiveStatus[0],
  },
  {
    name: 'inUse',
    title: <FormattedMessage {...tableFiltersMessages.inUse} />,
    type: FilterTypes.SINGLE,
    options: [
      { key: 'YES', label: 'Yes', value: true },
      { key: 'NO', label: 'No', value: false },
    ],
  },
];

const Scripts = (): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const isScriptsLoading: boolean = useSelector(selectors.selectScriptsLoading());
  const scripts: ImmutableList<IScriptListItemImt> = useSelector(selectors.selectScripts());
  const scriptsMeta: IPageMetaImt = useSelector(selectors.selectScriptsMeta());
  const isChangeScriptStatusLoading: boolean = useSelector(
    selectors.selectChangeScriptStatusLoading(),
  );
  const changeScriptStatusResult: ISuccessResponseImt = useSelector(
    selectors.selectChangeScriptStatusResult(),
  );

  const location = useLocation();
  const navigate = useNavigate();
  const module =
    location.pathname.indexOf('personal-training-crm') === -1
      ? PeakModules.Crm
      : PeakModules.PersonalTrainingCrm;

  const [tableParams, setTableParams] = useState(() =>
    makeTableParams(tableFilters, location.search),
  );

  // lifecycle

  useEffect(() => {
    dispatch(actions.fetchScripts(tableParams, module));
  }, [dispatch, module, tableParams]);

  useEffect(() => {
    if (changeScriptStatusResult.get('success')) {
      dispatch(actions.fetchScripts(tableParams, module));
    }
  }, [changeScriptStatusResult, dispatch, module, tableParams]);

  // handlers

  const updateQueryFunction = useCallback(
    query => dispatch(updateQueryParams({ page: QueryPageList.SCRIPTS, query })),
    [dispatch],
  );

  const handleChangeTableProps = useCallback(
    (tableProps: ITableParams): void => {
      setTableParams(tableProps);
      pushQueryToUrl(navigate, tableProps, updateQueryFunction);
    },
    [navigate, updateQueryFunction],
  );

  const onChangeScriptStatus = useCallback(
    (data, isActive) => {
      dispatch(actions.changeScriptStatus(data, isActive, module));
    },
    [dispatch, module],
  );

  const onResetChangeScriptStatusResult = useCallback(() => {
    dispatch(actions.resetChangeScriptStatusResult());
  }, [dispatch]);

  return (
    <RouteBackground>
      <ScriptsTable
        items={scripts}
        totalRows={scriptsMeta.get('total')}
        isLoading={isScriptsLoading || isChangeScriptStatusLoading}
        changeScriptStatusResult={changeScriptStatusResult.toJS()}
        changeScriptStatus={onChangeScriptStatus}
        onChangeParams={handleChangeTableProps}
        resetChangeScriptStatusResult={onResetChangeScriptStatusResult}
        tableFilterSettings={tableFilters}
        mainTableParams={tableParams}
        module={module}
      />
    </RouteBackground>
  );
};

export default Scripts;
