import { defineMessages } from 'react-intl';

export default defineMessages({
  // New/Edit service

  newServicePageTitle: {
    id: 'app.modules.services.editService.page.new.title',
    defaultMessage: 'New Service',
  },
  editServicePageTitle: {
    id: 'app.modules.services.editService.page.edit.title',
    defaultMessage: 'Edit Service',
  },
  // form inputs
  editServiceFormTitleLabel: {
    id: 'app.modules.services.editService.form.title.label',
    defaultMessage: 'Service name',
  },
  editServiceFormRedeemTypeLabel: {
    id: 'app.modules.services.editService.form.redeemType.label',
    defaultMessage: 'Type of service redeem',
  },
  editServiceFormRedeemTypeSessionsOptionLabel: {
    id: 'app.modules.services.editService.form.redeemType.options.session',
    defaultMessage: 'Sessions',
  },
  editServiceFormRedeemTypeDurationOptionLabel: {
    id: 'app.modules.services.editService.form.redeemType.options.duration',
    defaultMessage: 'Duration',
  },
  editServiceFormRedeemTypeAmountOptionLabel: {
    id: 'app.modules.services.editService.form.redeemType.options.amount',
    defaultMessage: 'Amount',
  },
  editServiceFormRedeemDurationUnitLabel: {
    id: 'app.modules.services.editService.form.redeemDurationUnit.label',
    defaultMessage: 'Duration unit',
  },
  editServiceFormDurationUnitMins: {
    id: 'app.modules.services.editService.form.redeemDurationUnit.options.mins',
    defaultMessage: 'Minutes',
  },
  editServiceFormDurationUnitHours: {
    id: 'app.modules.services.editService.form.redeemDurationUnit.options.hours',
    defaultMessage: 'Hours',
  },
  editServiceFormDurationUnitDays: {
    id: 'app.modules.services.editService.form.redeemDurationUnit.options.days',
    defaultMessage: 'Days',
  },
  editServiceFormDurationUnitWeeks: {
    id: 'app.modules.services.editService.form.redeemDurationUnit.options.weeks',
    defaultMessage: 'Weeks',
  },
  editServiceFormSchedulingTypeLabel: {
    id: 'app.modules.services.editService.form.schedulingType.label',
    defaultMessage: 'Service scheduling type',
  },
  editServiceFormSchedulingTrueOptionLabel: {
    id: 'app.modules.services.editService.form.schedulingType.options.scheduling',
    defaultMessage: 'Scheduled',
  },
  editServiceFormSchedulingFalseOptionLabel: {
    id: 'app.modules.services.editService.form.schedulingType.options.notScheduling',
    defaultMessage: 'Not Scheduled',
  },
  editServiceFormServiceAvailabilityLabel: {
    id: 'app.modules.services.editService.form.serviceAvailability.label',
    defaultMessage: 'Service availability',
  },
  editServiceFormSignWaiverLabel: {
    id: 'app.modules.services.editService.form.signWaiver.label',
    defaultMessage: 'Need to sign a waiver',
  },
  editServiceFormWaiverTemplateLabel: {
    id: 'app.modules.services.editService.form.waiverTemplate.label',
    defaultMessage: 'Waiver template',
  },
  editServiceFormClubConfigsLabel: {
    id: 'app.modules.services.editService.form.clubConfigs.label',
    defaultMessage: 'Per club configuration',
  },
  titleMaxLengthError: {
    id: 'app.modules.services.editService.form.titleMaxLengthError',
    defaultMessage: 'Title should be at less than 120 symbols',
  },
});
