import React, { useState } from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';

const Input: React.FC<TextFieldProps> = (props: TextFieldProps) => {
  const { error, ...otherProps } = props;
  const [isFocused, setFocused] = useState<boolean>(false);
  const [isTouched, setTouched] = useState<boolean>(false);

  return (
    <TextField
      error={isFocused || isTouched ? error : false}
      onFocus={() => setFocused(true)}
      onBlur={() => {
        setTouched(true);
        setFocused(false);
      }}
      {...otherProps}
    />
  );
};

export default Input;
