import { IFamilyMember, IFamilyMemberCreateDTO } from 'common/components/PersonProfile/interfaces';
import { PeakModules } from 'common/constants/peakModules';
import { GeneralThunkAction } from 'common/state/interfaces';
import Services from 'services/network';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import * as actionTypes from 'common/components/PersonProfile/state/familyMembers/constants';
import { createPersonPrepAction } from 'common/components/PersonProfile/state/actions';

// Family members
const fetchPersonFamilyMembersAction = createPersonPrepAction<IFamilyMember[]>(
  actionTypes.FETCH_PERSON_FAMILY_MEMBERS,
);
const fetchPersonFamilyMembersLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_FAMILY_MEMBERS_LOADING,
);
export const fetchPersonFamilyMembers = (
  personId: string,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPersonFamilyMembersLoadingAction(true, personId));

    try {
      let familyMembers: IFamilyMember[];

      switch (module) {
        case PeakModules.FrontDesk:
          familyMembers = await Services.FrontDesk.getFamilyMembers(personId);
          break;
        case PeakModules.Members:
          familyMembers = await Services.Members.getFamilyMembers(personId);
          break;
        case PeakModules.Crm:
          familyMembers = await Services.Leads.getFamilyMembers(personId);
          break;
        case PeakModules.PersonalTrainingCrm:
          familyMembers = await Services.PTLeads.getFamilyMembers(personId);
          break;
        default:
          familyMembers = [];
      }

      dispatch(fetchPersonFamilyMembersAction(familyMembers, personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(fetchPersonFamilyMembersLoadingAction(false, personId));
    }
  };
};

const deletePersonFamilyMemberAction = createPersonPrepAction<string>(
  actionTypes.DELETE_PERSON_FAMILY_MEMBER,
);

export const deleteFamilyMember = (
  personId: string,
  familyMemberId: string,
  module: PeakModules.FrontDesk | PeakModules.Members,
): GeneralThunkAction => {
  return async dispatch => {
    try {
      dispatch(fetchPersonFamilyMembersLoadingAction(true, personId));

      if (module === PeakModules.FrontDesk) {
        await Services.FrontDesk.deleteFamilyMember(personId, familyMemberId);
      } else {
        await Services.Members.deleteFamilyMember(personId, familyMemberId);
      }

      dispatch(deletePersonFamilyMemberAction(familyMemberId, personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(fetchPersonFamilyMembersLoadingAction(false, personId));
    }
  };
};

const addPersonFamilyMemberAction = createPersonPrepAction(actionTypes.ADD_PERSON_FAMILY_MEMBER);
export const resetAddPersonFamilyMemberActionResult = createPersonPrepAction(
  actionTypes.RESET_ADD_PERSON_FAMILY_MEMBER_ACTION_RESULT,
);
const addPersonFamilyMemberLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.ADD_PERSON_FAMILY_MEMBER_LOADING,
);

export const addFamilyMember = (
  memberId: string,
  data: IFamilyMemberCreateDTO,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    try {
      dispatch(addPersonFamilyMemberLoadingAction(true, memberId));

      switch (module) {
        case PeakModules.FrontDesk:
          await Services.FrontDeskRedeem.addFamilyMember(memberId, data);
          break;
        case PeakModules.Members:
          await Services.MembersRedeem.addFamilyMember(memberId, data);
          break;
        case PeakModules.Crm:
          await Services.LeadsRedeem.addFamilyMember(memberId, data);
          break;
        case PeakModules.PersonalTrainingCrm:
          await Services.PTLeadsRedeem.addFamilyMember(memberId, data);
          break;
        default:
      }

      dispatch(addPersonFamilyMemberAction(null, memberId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(addPersonFamilyMemberLoadingAction(false, memberId));
    }
  };
};
