import React from 'react';
import { Controller } from 'react-hook-form';
import { UseFormMethods } from 'react-hook-form/dist/types/form';
import { Grid } from '@material-ui/core';
import { List as ImmutableList } from 'immutable';

import { ITagImt } from 'modules/booking/interfaces';

import { MultipleSelect } from 'common/components';

import { FormattedMessage } from 'react-intl';
import inputLabels from 'common/messages/inputLabels';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

interface IProps {
  resourceTags: ImmutableList<ITagImt>;
  eventTags: ImmutableList<ITagImt>;
  formMethods: UseFormMethods<any>;
}

const TagsSection = ({ resourceTags, eventTags, formMethods }: IProps): JSX.Element => {
  const { control, errors } = formMethods;
  const renderIntlMessage = useRenderIntlMessage();

  return (
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Controller
            name="resourceTag"
            control={control}
            render={({ value, onChange }) => (
              <MultipleSelect
                onChange={onChange}
                label={<FormattedMessage {...inputLabels.resourcesTags} />}
                value={value}
                multiple
                options={resourceTags.toJS()}
                fullWidth
                error={!!errors.resourceTag?.message}
                helperText={renderIntlMessage(errors?.resourceTag?.message)}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="eventTag"
            control={control}
            render={({ value, onChange }) => (
              <MultipleSelect
                onChange={onChange}
                label={<FormattedMessage {...inputLabels.eventsTags} />}
                value={value}
                multiple
                options={eventTags.toJS()}
                fullWidth
                error={!!errors.eventTag?.message}
                helperText={renderIntlMessage(errors.eventTag?.message)}
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(TagsSection);
