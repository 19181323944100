import * as yup from 'yup';
import inputErrors from 'common/messages/inputErrors';
import { regExp } from 'common/constants';
import { phoneSchema } from 'common/validationSchemas/phoneSchema';
import { getRequiredMessage } from 'common/constants/globalConstants';
import { TShortProfileFieldInfoImt } from 'modules/corporate-settings/interfaces';
import {
  FieldNecessity,
  RequiredProfileInfo,
} from 'modules/corporate-settings/constants/requiredFields';

export const getEmergencyContactValidationSchema = (requiredFields: TShortProfileFieldInfoImt) => ({
  emergencyName: yup
    .string()
    .max(50, () => inputErrors.emergencyNameLengthError)
    .matches(regExp.NAME, () => inputErrors.emergencyNameMatchesError)
    .nullable()
    .when('emergencyName1', {
      is: () => {
        return (
          requiredFields.get(RequiredProfileInfo.EmergencyContactInfo) === FieldNecessity.Required
        );
      },
      then: schema => schema.required(getRequiredMessage),
      otherwise: schema => schema,
    }),
  emergencyPhone: phoneSchema.nullable().when('emergencyPhone1', {
    is: () => {
      return (
        requiredFields.get(RequiredProfileInfo.EmergencyContactInfo) === FieldNecessity.Required
      );
    },
    then: schema => schema.required(getRequiredMessage),
    otherwise: schema => schema.trim(),
  }),
  emergencyAddress1: yup
    .string()
    .max(1000, () => inputErrors.emergencyAddressLengthError)
    .nullable()
    .when('emergencyAddress2', {
      is: () => {
        return (
          requiredFields.get(RequiredProfileInfo.EmergencyContactInfo) === FieldNecessity.Required
        );
      },
      then: schema => schema.required(getRequiredMessage),
      otherwise: schema => schema,
    }),
  emergencyAddress2: yup
    .string()
    .max(1000, () => inputErrors.emergencyAddressLengthError)
    .nullable(),
});
