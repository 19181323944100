const policiesStatePath = ['permissions', 'policies', 'policiesList'];

const selectPoliciesListLoading = state => state.getIn([...policiesStatePath, 'isLoading']);

const selectPoliciesList = state => state.getIn([...policiesStatePath, 'list']);

// active policies
const selectPoliciesDictionaryList = state => state.getIn([...policiesStatePath, 'dictionaryList']);

const selectPoliciesListMeta = state => state.getIn([...policiesStatePath, 'meta']);

const policyItemPath = ['permissions', 'policies', 'policyItem'];

const selectPolicyItemLoading = state => state.getIn([...policyItemPath, 'isLoading']);

const selectPolicyItemData = state => state.getIn([...policyItemPath, 'policyItemData']);

const selectPolicyItemRequestState = state => state.getIn([...policyItemPath, 'request']);

export {
  // Policy
  selectPoliciesListLoading,
  selectPoliciesList,
  selectPoliciesListMeta,
  selectPoliciesDictionaryList,
  // Policy item
  selectPolicyItemLoading,
  selectPolicyItemData,
  selectPolicyItemRequestState,
};
