export const actionTypes = {
  FETCH_DOCUMENT_TEMPLATES: 'FETCH_DOCUMENT_TEMPLATES',
  FETCH_DOCUMENT_TEMPLATES_LOADING: 'FETCH_DOCUMENT_TEMPLATES_LOADING',
  RESET_DOCUMENT_TEMPLATES: 'RESET_DOCUMENT_TEMPLATES',
  // get template
  GET_TEMPLATE_LOADING: 'GET_TEMPLATE_LOADING',
  GET_TEMPLATE: 'GET_TEMPLATE',
  RESET_TEMPLATE: 'RESET_TEMPLATE',
  // save template
  SAVE_TEMPLATE_LOADING: 'SAVE_TEMPLATE_LOADING',
  SAVE_TEMPLATE_ACTION_RESULT: 'SAVE_TEMPLATE_ACTION_RESULT',
  RESET_SAVE_TEMPLATE_RESULT: 'RESET_SAVE_TEMPLATE_RESULT',
  // update template status
  SET_UPDATE_DOCUMENT_STATUS_IS_LOADING: 'SET_UPDATE_DOCUMENT_STATUS_IS_LOADING',
  UPDATE_DOCUMENT_STATUS: 'UPDATE_DOCUMENT_STATUS',
  UPDATE_DOCUMENT_STATUS_ACTION_RESULT: 'UPDATE_DOCUMENT_STATUS_ACTION_RESULT',
};
