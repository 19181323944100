import React, { FC } from 'react';
import AccessDenied from 'routes/AccessDenied';

interface IAccessRoute {
  hasPermission: boolean;
  children: JSX.Element;
}

const AccessRoute: FC<IAccessRoute> = ({ hasPermission, children }) => {
  if (!hasPermission) {
    return <AccessDenied />;
  }

  return children;
};

export default AccessRoute;
