/* eslint @typescript-eslint/no-unused-vars: 0 */
import axios from 'axios';
import { IFamilyMember, ITag } from 'common/components/PersonProfile/interfaces';
import { tagsList } from 'modules/front-desk/mockData';
import { ISuccessResponse } from 'common/interfaces/http';
import ApiService from './ApiService';
import { ISimpleClubInventoryItem } from 'modules/pos-settings/interfaces/inventoryItems';

interface IFamilyMembers {
  [key: string]: Array<IFamilyMember>;
}

export default class PersonsService extends ApiService {
  private commonTags: Array<ITag> = [];

  private familyMembers: IFamilyMembers = {};

  public async getClientMemberTags(clientId: number | string): Promise<ITag[]> {
    const res: any = await new Promise(resolve => {
      setTimeout(() => {
        resolve(tagsList);
      }, 500);
    });
    const data = await res.data;
    this.commonTags = data;
    return this.commonTags;
  }

  public getProducts = (clubId: string): Promise<ISimpleClubInventoryItem[]> =>
    axios.get(`api/v1/front-desk/club/${clubId}/suggested-products`);

  public async addFamilyMember(personId: string, member: IFamilyMember): Promise<IFamilyMember[]> {
    return new Promise(resolve => {
      setTimeout(() => {
        if (
          this.familyMembers[personId] &&
          !this.familyMembers[personId].find(item => item.id === member.id)
        ) {
          this.familyMembers[personId] = [...this.familyMembers[personId], member];
        }
        if (!this.familyMembers[personId]) {
          this.familyMembers[personId] = [member];
        }
        resolve(this.familyMembers[personId]);
      }, 500);
    });
  }

  public fetchSubMembersList = (
    personId: string,
    reactivatableItemsIds: string[],
  ): Promise<IFamilyMember[]> => {
    return new Promise(resolve => setTimeout(() => resolve([]), 500));
  };

  public signWaiver(
    personId: string,
    waiverId: string,
    signingType: 'sms' | 'email' | 'now',
    signatureImage?: any,
    attachmentId?: string,
  ): Promise<ISuccessResponse> {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({ success: true });
      }, 1000);
    });
  }
}
