import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { List as ImmutableList } from 'immutable';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import {
  Box,
  InputAdornment,
  makeStyles,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';

import {
  IPackageInstanceSubscription,
  IPaymentAccount,
  IPaymentAccountImt,
} from 'common/components/PersonProfile/interfaces';
import { ReactComponent as PaperIcon } from 'img/icons/big-paper.svg';
import { getAccessByPropPath } from 'common/utils/errorObject';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { CustomTheme } from 'common/ui/interfaces';
import commonMessages from 'common/messages/messages';
import { FormattedMessage } from 'react-intl';

interface ICheckingFieldProps {
  checkingSavingsData: ImmutableList<IPaymentAccountImt>;
  subscription: IPackageInstanceSubscription;
  index: number;
  setIsOpenCheckingSavingsModal: (isOpen: boolean) => void;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  paymentMethodOptionTitle: {
    marginLeft: theme.spacing(1),
  },
  optionIcon: {
    width: '16px',
    height: '16px',
    color: theme.palette.darkBackground?.light,
  },
  autocompleteInput: {
    '& .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
      padding: theme.spacing(0.375, 1),
    },
  },
  inputStartIcon: {
    alignSelf: 'center',
    color: theme.palette.darkBackground?.light,

    '& svg': {
      width: '1rem',
      height: '1rem',
    },
  },
  addNewBtn: {
    padding: theme.spacing(1.5),
    '& p': {
      width: '100%',
      textAlign: 'center',
    },
  },
}));

const CheckingField = (props: ICheckingFieldProps) => {
  const { checkingSavingsData, index, subscription, setIsOpenCheckingSavingsModal } = props;

  const { errors, control } = useFormContext();

  const renderIntlMessage = useRenderIntlMessage();

  const classes = useStyles();

  const transformedCheckingSavingsData = useMemo(() => {
    return checkingSavingsData?.toJS() || [];
  }, [checkingSavingsData]);

  return (
    <Controller
      control={control}
      name={`subscriptions.${index}.checking`}
      defaultValue={subscription.paymentAccount}
      render={({ onBlur, value, onChange }) => (
        <MuiAutocomplete
          multiple={false}
          value={value}
          onChange={(_, item: IPaymentAccount) => onChange(item)}
          onBlur={onBlur}
          disableClearable
          size="small"
          options={transformedCheckingSavingsData}
          renderOption={(option: IPaymentAccount) => {
            return (
              <Box display="flex">
                <PaperIcon className={classes.optionIcon} />
                <Typography className={classes.paymentMethodOptionTitle}>
                  {option.routingNumber}
                </Typography>
              </Box>
            );
          }}
          getOptionLabel={(option: IPaymentAccount) => option.routingNumber}
          getOptionSelected={(option: IPaymentAccount, selectedOption: IPaymentAccount) => {
            return selectedOption?.id === option?.id;
          }}
          renderInput={params => {
            return (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment className={classes.inputStartIcon} position="start">
                      <PaperIcon />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                className={classes.autocompleteInput}
                label={null}
                placeholder={renderIntlMessage(commonMessages.routingNumberLabel)}
                error={!!getAccessByPropPath(errors, `subscriptions.${index}.checking`)}
                helperText={renderIntlMessage(
                  getAccessByPropPath(errors, `subscriptions.${index}.checking`)?.message,
                )}
              />
            );
          }}
          PaperComponent={({ children, ...other }) => {
            return (
              <Paper {...other}>
                <MenuItem
                  className={classes.addNewBtn}
                  onClick={() => setIsOpenCheckingSavingsModal(true)}
                  onMouseDown={e => e.preventDefault()}
                >
                  <Typography>
                    <FormattedMessage {...commonMessages.addNewBtn} />
                  </Typography>
                </MenuItem>
                {children}
              </Paper>
            );
          }}
        />
      )}
    />
  );
};

export default CheckingField;
