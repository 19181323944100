import { createSelector } from 'reselect';

const membershipDomainPath = ['persons', 'membership'];

const selectFrontDeskMembershipDomain = state => state.getIn(['persons', 'membership']);

const cancelMembershipPath = ['persons', 'membership', 'cancelMembership'];

export const selectCancelMembershipActionResult = state =>
  state.getIn([...cancelMembershipPath, 'result']);

export const selectCancelMembershipActionLoading = state =>
  state.getIn([...cancelMembershipPath, 'isLoading']);

export const selectFreezeMembership = () =>
  createSelector(selectFrontDeskMembershipDomain, state => state.get('freezeMembership'));

export const selectFreezeMembershipResult = () =>
  createSelector(selectFrontDeskMembershipDomain, state =>
    state.getIn(['freezeMembership', 'result']),
  );
export const selectFreezeMembershipLoading = createSelector(
  selectFrontDeskMembershipDomain,
  state => state.getIn(['freezeMembership', 'isLoading']),
);

export const selectFreezeMembershipError = () =>
  createSelector(selectFrontDeskMembershipDomain, state =>
    state.getIn(['freezeMembership', 'error']),
  );

export const selectFreezableItems = () =>
  createSelector(selectFrontDeskMembershipDomain, state => state.get('freezableItems'));
export const selectFreezableItemsLoading = () =>
  createSelector(selectFrontDeskMembershipDomain, state => state.get('freezableItemsLoading'));
export const selectFreezeHistory = () =>
  createSelector(selectFrontDeskMembershipDomain, state => state.get('freezeHistory'));
export const selectFreezeHistoryLoading = () =>
  createSelector(selectFrontDeskMembershipDomain, state => state.get('freezeHistoryLoading'));

export const selectReactivateItems = state =>
  state.getIn([...membershipDomainPath, 'reactivateItems']);
export const selectReactivateItemsLoading = state =>
  state.getIn([...membershipDomainPath, 'reactivateItemsLoading']);

export const selectCancelItems = state => state.getIn([...membershipDomainPath, 'cancelItems']);
export const selectCancelItemsLoading = state =>
  state.getIn([...membershipDomainPath, 'cancelItemsLoading']);

export const selectSubMembersList = () =>
  createSelector(selectFrontDeskMembershipDomain, state => state.get('subMembersList'));
export const selectSubMembersListLoading = () =>
  createSelector(selectFrontDeskMembershipDomain, state => state.get('subMembersListLoading'));

const availableMembershipPackageActionsPath = [
  'persons',
  'membership',
  'availableMembershipActions',
];
export const selectAvailableMembershipPackageActions = state =>
  state.getIn([...availableMembershipPackageActionsPath, 'result']);
export const selectAvailableMembershipPackageActionsLoading = state =>
  state.getIn([...availableMembershipPackageActionsPath, 'isLoading']);

export const selectMembershipPackage = state =>
  state.getIn([...membershipDomainPath, 'package', 'result']);
export const selectMembershipPackageLoading = state =>
  state.getIn([...membershipDomainPath, 'package', 'isLoading']);

export const selectPersonMembershipPackageInventory = state =>
  state.getIn([...membershipDomainPath, 'packageInventory', 'result']);
export const selectPersonMembershipPackageInventoryLoading = state =>
  state.getIn([...membershipDomainPath, 'packageInventory', 'isLoading']);
