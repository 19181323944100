import React, { forwardRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// validationSchema
import { servicesSchema } from './servicesStepValidation';
// interfaces
import { IPackageStepProps, IServicesSection } from 'modules/services/interfaces/packages';
// components
import { ScrollBox } from 'common/components';
import ServicesStepForm from './ServicesStepForm';
// constants
import { useStepsLogic } from 'modules/services/hooks/useStepLogics';
import { defaultEditPackageFormData } from 'modules/services/constants/packages';
import { IDayTimeAvailabilityDto } from 'common/interfaces/common';

interface IServicesStepProps extends IPackageStepProps<IServicesSection> {
  packageId?: string;
  validAvailabilityRange?: IDayTimeAvailabilityDto[];
}

const ServicesStep = forwardRef(
  (
    {
      editPackageSectionFormData,
      renderFooter,
      handleChange,
      onPackageSubmit,
      formErrors,
      submitAttempted,
      validAvailabilityRange,
      packageId,
    }: IServicesStepProps,
    ref,
  ): JSX.Element => {
    // form

    const formMethods = useForm<IServicesSection>({
      defaultValues: {
        ...defaultEditPackageFormData.serviceSection,
        ...editPackageSectionFormData,
      },
      resolver: yupResolver(servicesSchema),
      mode: 'all',
    });

    const { trigger, getValues } = formMethods;

    // effects

    const { onSubmit, onNext, onBack } = useStepsLogic(
      'serviceSection',
      ref,
      getValues,
      trigger,
      formErrors,
      onPackageSubmit,
      handleChange,
      submitAttempted,
    );

    // renders

    return (
      <FormProvider {...formMethods}>
        <ScrollBox hasShadowsOnScroll>
          <form autoComplete="none">
            <ServicesStepForm
              packageId={packageId}
              formMethods={formMethods}
              validAvailabilityRange={validAvailabilityRange}
            />
          </form>
        </ScrollBox>
        {renderFooter(onSubmit, onNext, onBack)}
      </FormProvider>
    );
  },
);

export default React.memo(ServicesStep);
