import React from 'react';
import { Button } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import commonMessages from 'common/messages/messages';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 1.5),
    lineHeight: 'normal',
    outline: 'none',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
      '& .MuiSvgIcon-root': {
        fontSize: 14,
      },
    },
  },
  smallButton: {
    fontSize: '12px',
    padding: theme.spacing(0.625, 1.625),
  },
  smallIcon: {
    fontSize: '14px !important',
  },
}));

interface IProps {
  title?: JSX.Element;
  disabled?: boolean;
  small?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ClearFilterButton = ({ disabled, small, onClick }: IProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Button
      disableRipple
      aria-label="reset"
      variant={small ? 'text' : 'contained'}
      color={small ? 'primary' : 'inherit'}
      className={classnames(classes.root, {
        [classes.smallButton]: small,
      })}
      startIcon={
        <CancelIcon
          className={classnames({
            [classes.smallIcon]: small,
          })}
        />
      }
      disabled={disabled}
      onClick={onClick}
    >
      <FormattedMessage {...commonMessages.clearBtn} />
    </Button>
  );
};

export default React.memo(ClearFilterButton);
