import { actionTypes } from './constants';
import { fromJS, List } from 'immutable';
import { ICheckInHistoryImt } from 'common/components/PersonProfile/interfaces';

const allergiesInitState = fromJS({
  recentCheckInsHistory: List<ICheckInHistoryImt>(),
  recentCheckInsHistoryLoading: false,
  checkInsHistory: List<ICheckInHistoryImt>(),
  checkInsHistoryMeta: fromJS({ page: 0, perPage: 5, total: 0 }),
  checkInsHistoryLoading: false,
});

const checkInsHistoryReducer = (state = allergiesInitState, action) => {
  switch (action.type) {
    case actionTypes.SET_PERSON_CHECK_INS_HISTORY_LOADING:
      return state.set('checkInsHistoryLoading', action.payload);
    case actionTypes.FETCH_PERSON_CHECK_INS_HISTORY:
      return state
        .set('checkInsHistory', fromJS(action.payload.data))
        .set('checkInsHistoryMeta', fromJS(action.payload.meta));
    case actionTypes.SET_PERSON_RECENT_CHECK_INS_HISTORY_LOADING:
      return state.set('recentCheckInsHistoryLoading', action.payload);
    case actionTypes.FETCH_PERSON_RECENT_CHECK_INS_HISTORY:
      return state.set('recentCheckInsHistory', fromJS(action.payload));

    default:
      return state;
  }
};

export default checkInsHistoryReducer;
