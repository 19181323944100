import * as yup from 'yup';

import { getRequiredMessage } from 'common/constants/globalConstants';

import inputErrors from 'common/messages/inputErrors';

export const getMainLoginValidationSchema = (isCorporationRequired: boolean) =>
  yup.object().shape({
    username: yup
      .string()
      .trim()
      .email(() => inputErrors.emailInvalidError)
      .required(getRequiredMessage),
    corporation: isCorporationRequired
      ? yup
          .object()
          .nullable()
          .required(getRequiredMessage)
      : yup.object().nullable(),
    password: yup
      .string()
      .trim()
      .when('corporation', {
        is: value => !!value,
        then: yup.string().required(getRequiredMessage),
        otherwise: yup.string().notRequired(),
      }),
  });
