import axios from 'axios';

import ApiService from 'services/network/ApiService';
import { IPeakMessagingSettings } from 'modules/peak-settings/interfaces';
import { PaymentProcessorType } from 'modules/corporate-settings/interfaces';

export default class CorporationsService extends ApiService {
  public getPeakMessageSettings = (): Promise<IPeakMessagingSettings> =>
    axios.get('/api/v1/peak/messaging-settings');

  public updatePeakZendeskApiSettings = (
    data: Partial<IPeakMessagingSettings>,
  ): Promise<IPeakMessagingSettings> =>
    axios.put('/api/v1/peak/messaging-settings/zendesk/api', data);

  public changeZendeskApiStatus = (isActive: boolean): Promise<IPeakMessagingSettings> =>
    axios.put('/api/v1/peak/messaging-settings/zendesk/api/change-status', {
      active: isActive,
    });

  public updatePeakZendeskWidgetSettings = (
    data: Partial<IPeakMessagingSettings>,
  ): Promise<IPeakMessagingSettings> =>
    axios.put('/api/v1/peak/messaging-settings/zendesk/widget', data);

  public changeZendeskWidgetStatus = (isActive: boolean): Promise<IPeakMessagingSettings> =>
    axios.put('/api/v1/peak/messaging-settings/zendesk/widget/change-status', {
      active: isActive,
    });

  public changePayeezyStatus = (isActive: boolean): Promise<IPeakMessagingSettings> =>
    axios.put('/api/v1/peak/messaging-settings/payeezy/change-status', {
      active: isActive,
    });

  public updatePeakPayeezySettings = (
    data: Partial<IPeakMessagingSettings>,
  ): Promise<IPeakMessagingSettings> => axios.put('/api/v1/peak/messaging-settings/payeezy', data);

  public updatePeakITransactSettings = (
    data: Partial<IPeakMessagingSettings>,
  ): Promise<IPeakMessagingSettings> =>
    axios.put('/api/v1/peak/messaging-settings/i-transact', data);

  public updateCommercehubSettings = (
    data: Partial<IPeakMessagingSettings>,
  ): Promise<IPeakMessagingSettings> =>
    axios.put('/api/v1/peak/messaging-settings/commercehub', data);

  public updatePaymentProcessorType = (
    paymentProcessorType: PaymentProcessorType,
  ): Promise<IPeakMessagingSettings> =>
    axios.put('/api/v1/peak/messaging-settings/payment-processor-type', null, {
      params: { paymentProcessorType },
    });

  public getPeakPrimaryColor = (): Promise<string> => axios.get('/api/v1/peak/primary-color');

  public getGeneralSettings = (): Promise<string> =>
    axios.get('/api/v1/peak/general/primary-color');

  public updateGeneralSettings = (color: string): Promise<string> =>
    axios.put('/api/v1/peak/general/primary-color', null, { params: { color } });
}
