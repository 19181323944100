import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';
import { actionTypes } from 'modules/reports/constants/kidzoneCheckInHistory';
import { initReqStateImt } from 'common/constants/initialState';

function kidzoneCheckInHistoryStatisticsReducer(state = initReqStateImt, action) {
  switch (action.type) {
    case actionTypes.FETCH_KIDZONE_CHECK_IN_HISTORY_STATISTICS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.FETCH_KIDZONE_CHECK_IN_HISTORY_STATISTICS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_KIDZONE_CHECK_IN_HISTORY_STATISTICS:
      return initReqStateImt;
    default:
      return state;
  }
}

const initialStateKidzoneCheckInHistoryActivity = fromJS({
  result: {},
  chartData: [],
  isLoading: false,
});

function kidzoneCheckInHistoryActivityReducer(
  state = initialStateKidzoneCheckInHistoryActivity,
  action,
) {
  switch (action.type) {
    case actionTypes.FETCH_KIDZONE_CHECK_IN_HISTORY_ACTIVITY:
      return state.set('result', fromJS(action.payload));
    case actionTypes.SET_KIDZONE_CHECK_IN_HISTORY_CHART_DATA:
      return state.set('chartData', fromJS(action.payload));
    case actionTypes.FETCH_KIDZONE_CHECK_IN_HISTORY_ACTIVITY_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_KIDZONE_CHECK_IN_HISTORY_ACTIVITY:
      return initReqStateImt;
    default:
      return state;
  }
}

const initialStateKidzoneCheckInHistoryReportItems = fromJS({
  list: [],
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
  isLoading: false,
});

function kidzoneCheckInHistoryReportItemsReducer(
  state = initialStateKidzoneCheckInHistoryReportItems,
  action,
) {
  switch (action.type) {
    case actionTypes.FETCH_KIDZONE_CHECK_IN_HISTORY_REPORT_ITEMS:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta));
    case actionTypes.FETCH_KIDZONE_CHECK_IN_HISTORY_REPORT_ITEMS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_KIDZONE_CHECK_IN_HISTORY_REPORT_ITEMS:
      return initialStateKidzoneCheckInHistoryReportItems;
    default:
      return state;
  }
}

export default combineReducers({
  kidzoneCheckInHistoryStatistics: kidzoneCheckInHistoryStatisticsReducer,
  kidzoneCheckInHistoryActivity: kidzoneCheckInHistoryActivityReducer,
  kidzoneCheckInHistoryReportItems: kidzoneCheckInHistoryReportItemsReducer,
});
