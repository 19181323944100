import { fromJS } from 'immutable';
import { PoliciesPermissionSetItems } from 'common/constants/permissions';
import * as actionTypes from './constants';
import { ImmutableObject } from '../interfaces';

interface IPermissionInitialState {
  list: PoliciesPermissionSetItems[];
  isLoading: boolean;
}

type PermissionInitialStateImt = ImmutableObject<IPermissionInitialState>;

const initialState: PermissionInitialStateImt = fromJS({
  list: [],
  isLoading: false,
});

function permissionReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_PERMISSIONS: {
      return state.set('list', fromJS(action.payload));
    }
    case actionTypes.GET_PERMISSIONS_LOADING: {
      return state.set('isLoading', action.payload);
    }
    default: {
      return state;
    }
  }
}

export default permissionReducer;
