import { fromJS } from 'immutable';
import { actionTypes } from './constants';

const insuranceInfoInitState = fromJS({
  result: {},
  isLoading: false,
  addFamilyMemberLoading: false,
  addFamilyMemberActionResult: {},
});

const familyMembersReducer = (state = insuranceInfoInitState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PERSON_FAMILY_MEMBERS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.FETCH_PERSON_FAMILY_MEMBERS_LOADING:
      return state.set('isLoading', fromJS(action.payload));

    default:
      return state;
  }
};

export default familyMembersReducer;
