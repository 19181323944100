export const actionTypes = {
  PUSH_REQUEST_ERROR: 'PUSH_REQUEST_ERROR',
  RESET_REQUEST_ERRORS: 'RESET_REQUEST_ERRORS',
  FETCH_Z_OUT_REPORT_LIST_LOADING: 'FETCH_Z_OUT_REPORT_LIST_LOADING',
  FETCH_Z_OUT_REPORT_LIST: 'FETCH_Z_OUT_REPORT_LIST',
  EXPORT_Z_OUT_REPORT_LIST_LOADING: 'EXPORT_Z_OUT_REPORT_LIST_LOADING',
  RESET_EXPORT_LIST_SUCCESS: 'RESET_EXPORT_LIST_SUCCESS',
  EXPORT_Z_OUT_REPORT_LIST: 'EXPORT_Z_OUT_REPORT_LIST',
  FETCH_Z_OUT_REPORT_LOADING: 'FETCH_Z_OUT_REPORT_LOADING',
  FETCH_Z_OUT_REPORT: 'FETCH_Z_OUT_REPORT',
  RESET_Z_OUT_REPORT: 'RESET_Z_OUT_REPORT',
  EXPORT_Z_OUT_REPORT_LOADING: 'EXPORT_Z_OUT_REPORT_LOADING',
  RESET_EXPORT_SUCCESS: 'RESET_EXPORT_SUCCESS',
  EXPORT_Z_OUT_REPORT: 'EXPORT_Z_OUT_REPORT',
  FETCH_Z_OUT_REPORT_PAYMENTS_LOADING: 'FETCH_Z_OUT_REPORT_PAYMENTS_LOADING',
  FETCH_Z_OUT_REPORT_PAYMENTS: 'FETCH_Z_OUT_REPORT_PAYMENTS',
  RESET_Z_OUT_REPORT_PAYMENTS: 'RESET_Z_OUT_REPORT_PAYMENTS',
  FETCH_Z_OUT_REPORT_LIST_STATISTICS_LOADING: 'FETCH_Z_OUT_REPORT_LIST_STATISTICS_LOADING',
  FETCH_Z_OUT_REPORT_LIST_STATISTICS_RESULT: 'FETCH_Z_OUT_REPORT_LIST_STATISTICS_RESULT',
  RESET_Z_OUT_REPORT_LIST_STATISTICS: 'RESET_Z_OUT_REPORT_LIST_STATISTICS',
};
