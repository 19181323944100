export const actionTypes = {
  FETCH_MEMBERSHIP_PACKAGES_LIST_IS_LOADING: 'FETCH_MEMBERSHIP_PACKAGES_LIST_IS_LOADING',
  FETCH_MEMBERSHIP_PACKAGES_LIST: 'FETCH_MEMBERSHIP_PACKAGES_LIST',

  FETCH_MEMBERSHIP_PACKAGE_LOADING: 'FETCH_MEMBERSHIP_PACKAGE_LOADING',
  FETCH_MEMBERSHIP_PACKAGE: 'FETCH_MEMBERSHIP_PACKAGE',

  FETCH_MEMBERSHIP_PACKAGE_INVENTORY: 'FETCH_MEMBERSHIP_PACKAGE_INVENTORY',
  FETCH_MEMBERSHIP_PACKAGE_INVENTORY_LOADING: 'FETCH_MEMBERSHIP_PACKAGE_INVENTORY_LOADING',
  RESET_MEMBERSHIP_PACKAGE_INVENTORY: 'RESET_MEMBERSHIP_PACKAGE_INVENTORY',

  FETCH_UPDATE_MEMBERSHIP_INFO_LOADING: 'FETCH_UPDATE_MEMBERSHIP_INFO_LOADING',
  FETCH_UPDATE_MEMBERSHIP_INFO_RESULT: 'FETCH_UPDATE_MEMBERSHIP_INFO_RESULT',

  RESET_MEMBERSHIP_PACKAGE_ITEM: 'RESET_MEMBERSHIP_PACKAGE_ITEM',

  FETCH_PERSON_MEMBERSHIP: 'FETCH_PERSON_MEMBERSHIP',
  FETCH_PERSON_MEMBERSHIP_LOADING: 'FETCH_PERSON_MEMBERSHIP_LOADING',
  RESET_PERSON_MEMBERSHIP: 'RESET_PERSON_MEMBERSHIP',
};
