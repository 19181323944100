// libraries
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'store/hooks';

import {
  selectPackageInfo,
  selectPackageInfoLoading,
} from 'modules/services/state/packages/selectors';
import { fetchPackageInfo, resetPackageInfo } from 'modules/services/state/packages/actions';

import { IBundleView } from 'modules/services/interfaces/packages';

import { DialogComponent } from 'common/components';

import BundleView from 'common/components/BundleView/BundleView';

interface IProps {
  open: boolean;
  packageId: string;
  onClose: () => void;
}

const PackageInfoDialog: React.FC<IProps> = ({ open, packageId, onClose }: IProps): JSX.Element => {
  // state

  const dispatch = useAppDispatch();

  const packageInfo: IBundleView = useSelector(selectPackageInfo);
  const isLoading: boolean = useSelector(selectPackageInfoLoading);

  // effects

  useEffect(() => {
    if (open && packageId) {
      dispatch(fetchPackageInfo(packageId));
    }
  }, [dispatch, open, packageId]);

  useEffect(() => {
    if (!open) {
      dispatch(resetPackageInfo());
    }
  }, [dispatch, open]);

  return (
    <DialogComponent
      isOpen={open}
      loading={isLoading}
      onClose={onClose}
      submitBtn={null}
      cancelBtn={null}
      enableBackdropClick
      supressBottomShadow
      size="md"
      title={packageInfo?.title}
    >
      {!!packageInfo && <BundleView bundle={packageInfo} />}
    </DialogComponent>
  );
};

export default PackageInfoDialog;
