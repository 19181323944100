import { ConstOptions } from 'common/constants/classes';
import messages from 'modules/services/messages/messages';
import serviceMessages from 'modules/services/messages/services';
import { RedeemType, ServiceType } from 'common/constants/service';

export const actionTypes = {
  // List
  FETCH_SERVICES_LIST_LOADING: 'FETCH_SERVICES_LIST_LOADING',
  FETCH_SERVICES_LIST: 'FETCH_SERVICES_LIST',
  RESET_SERVICES_LIST: 'RESET_SERVICES_LIST',

  CHANGE_SERVICE_STATUS_LOADING: 'CHANGE_SERVICE_STATUS_LOADING',
  CHANGE_SERVICE_STATUS: 'CHANGE_SERVICE_STATUS',
  RESET_DELETE_SUCCESS: 'RESET_DELETE_SUCCESS',

  // New/Edit Service
  FETCH_SERVICE: 'FETCH_SERVICE',
  FETCH_SERVICE_LOADING: 'FETCH_SERVICE_LOADING',
  CREATE_UPDATE_SERVICE_LOADING: 'CREATE_UPDATE_SERVICE_LOADING',
  CREATE_UPDATE_SERVICE_RESULT_ACTION: 'CREATE_UPDATE_SERVICE_RESULT_ACTION',
  RESET_SERVICE: 'RESET_SERVICE',
};

export const ServiceTypeOptions = new ConstOptions([
  { value: ServiceType.Custom, message: messages.customOption },
  { value: ServiceType.FamilyMember, message: messages.familyMemberOption },
  { value: ServiceType.PersonalTraining, message: messages.personalTrainingOption },
  { value: ServiceType.DayCare, message: messages.dayCareOption },
]);

export const RedeemTypeOptions = new ConstOptions([
  { value: RedeemType.Amount, message: serviceMessages.editServiceFormRedeemTypeAmountOptionLabel },
  {
    value: RedeemType.Duration,
    message: serviceMessages.editServiceFormRedeemTypeDurationOptionLabel,
  },
  {
    value: RedeemType.Session,
    message: serviceMessages.editServiceFormRedeemTypeSessionsOptionLabel,
  },
]);
