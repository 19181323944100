import React from 'react';
import { FormattedMessage } from 'react-intl';
import { v4 as uuid } from 'uuid';
import { Box, alpha, IconButton, makeStyles, Typography } from '@material-ui/core';
import moment from 'moment-timezone';
import cx from 'classnames';

import Alert from '../Alert/Alert';
import { ReactComponent as EditIcon } from 'img/icons/pencil_deprecated.svg';

import { IDayTimeAvailabilityDto, Weekdays } from 'common/interfaces/common';
import { CustomTheme } from 'common/ui/interfaces';
import { AlertTypes } from 'common/interfaces/alerts';

import { getAlertColor, getFadeValue } from '../Alert/helper';
import { sortAvailabilities } from '../../../modules/services/utils/servicePackage';
// messages
import inputLabels from 'common/messages/inputLabels';

interface IProps {
  availabilities: IDayTimeAvailabilityDto[];
  variant?: 'info' | 'error';
  errorMessage?: string | JSX.Element;
  className?: string;
  onEdit?: () => void;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    background: ({ variant }: IProps) =>
      variant === 'error'
        ? alpha(theme.palette[getAlertColor(AlertTypes.Danger)].main, getFadeValue())
        : 'none',
    padding: ({ variant }: IProps) => (variant === 'error' ? theme.spacing(2) : 0),
    color: theme.palette.text.primary,
  },
  weekday: {
    flex: 0.5,
    fontWeight: 500,
  },
  hours: {
    paddingLeft: theme.spacing(1),
    fontWeight: 400,
  },
  editButton: {
    alignSelf: 'flex-start',

    '& svg': {
      width: 16,
      height: 16,
    },
  },
}));

const AvailabilitiesResultBlock: React.FC<IProps> = (props: IProps): JSX.Element => {
  const classes = useStyles(props);
  const { availabilities = [], errorMessage, onEdit, className } = props;
  const sortedAvailabilities = sortAvailabilities(availabilities);

  return (
    <Box className={cx(classes.container, className)}>
      {!!errorMessage && <Alert severity={AlertTypes.Danger} title={errorMessage} />}

      <Box display="flex" alignItems="center">
        <Box flex="1">
          {sortedAvailabilities.map((availability, index) => (
            <Box display="flex" key={availability.id || index}>
              <Typography component="p" className={classes.weekday}>
                {availability.weekday ? Weekdays.translate(availability.weekday) : ''}
              </Typography>

              <Box flex="2">
                {availability.allDay ? (
                  <Typography component="p">
                    <FormattedMessage {...inputLabels.allDay} />
                  </Typography>
                ) : (
                  availability.timeRanges?.map(({ startTime, endTime }) => (
                    <Typography component="p" key={uuid()}>
                      {moment(startTime, 'HH:mm').format('LT')}&nbsp;-&nbsp;
                      {moment(endTime, 'HH:mm').format('LT')}
                    </Typography>
                  ))
                )}
              </Box>
            </Box>
          ))}
        </Box>

        {!!onEdit && (
          <IconButton onClick={onEdit} size="small" color="primary" className={classes.editButton}>
            <EditIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default React.memo(AvailabilitiesResultBlock);
