// libraries
import React from 'react';
import { Typography, ListItem, makeStyles, Theme } from '@material-ui/core';
// custom interfaces
import { IInventoryListInfoImt, IServiceListInfoImt } from 'common/interfaces/service';
// custom components
import RedeemProgress from '../RedeemProgress/RedeemProgress';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'block',
    cursor: 'pointer',
    borderRadius: theme.spacing(0.5),

    // hovering styles
    width: `calc(100% + ${theme.spacing(1.5)}px)`,
    margin: theme.spacing(0, -0.75),
    '& > *': {
      padding: theme.spacing(0, 0.75),
    },
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
}));

interface IProps {
  service?: IServiceListInfoImt;
  item?: IInventoryListInfoImt;

  onClick: (service: IServiceListInfoImt | IInventoryListInfoImt) => void;
}

const ServiceListItem: React.FC<IProps> = ({ service, item, onClick }: IProps): JSX.Element => {
  const classes = useStyles();

  // constants

  const title = service?.get('title') ?? item?.get('title');

  const totalToRedeem = service?.get('totalNumber') ?? item?.get('totalNumber');
  const availableNumber = (service?.get('leftNumber') ?? item?.get('leftNumber')) || 0;

  // handlers

  const handleOnClick = () => {
    onClick(service ?? item);
  };

  // renders

  return (
    <ListItem disableGutters className={classes.root} onClick={handleOnClick}>
      <Typography component="h4" variant="h5">
        {title}
      </Typography>

      <RedeemProgress
        small
        limited={!!item || service?.get('limited')}
        availableToRedeem={availableNumber}
        totalToRedeem={totalToRedeem}
        redeemType={service?.get('redeemType')}
        redeemUnit={service?.get('redeemDurationUnit')}
      />
    </ListItem>
  );
};

export default React.memo(ServiceListItem);
