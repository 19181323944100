import { fromJS } from 'immutable';
import { combineReducers } from 'redux';

import { actionTypes } from '../../constants/zOutReport';

const initStateZOutReportsList = fromJS({
  list: [],
  meta: {},
  isLoading: false,
  totals: {},
  exportZOutReportsListLoading: false,
  exportZOutReportsListResult: {},
  statistics: {},
  statisticsLoading: false,
});

function zOutReportsListReducer(state = initStateZOutReportsList, action) {
  switch (action.type) {
    case actionTypes.FETCH_Z_OUT_REPORT_LIST_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_Z_OUT_REPORT_LIST:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta))
        .set('totals', fromJS(action.payload.totals));
    case actionTypes.EXPORT_Z_OUT_REPORT_LIST_LOADING:
      return state.set('exportZOutReportsListLoading', fromJS(action.payload));
    case actionTypes.EXPORT_Z_OUT_REPORT_LIST:
      return state.set('exportZOutReportsListResult', fromJS(action.payload));
    case actionTypes.RESET_EXPORT_LIST_SUCCESS:
      return state.set('exportZOutReportsListResult', fromJS({}));
    case actionTypes.FETCH_Z_OUT_REPORT_LIST_STATISTICS_RESULT:
      return state.set('statistics', fromJS(action.payload));
    case actionTypes.FETCH_Z_OUT_REPORT_LIST_STATISTICS_LOADING:
      return state.set('statisticsLoading', action.payload);
    default:
      return state;
  }
}

const initStateZOutReport = fromJS({
  zOutReport: {},
  payments: [],
  paymentsLoading: false,
  isLoading: false,
  exportZOutReportLoading: false,
  exportZOutReportResult: {},
});

function zOutReportReducer(state = initStateZOutReport, action) {
  switch (action.type) {
    case actionTypes.FETCH_Z_OUT_REPORT_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_Z_OUT_REPORT:
      return state.set('zOutReport', fromJS(action.payload));
    case actionTypes.FETCH_Z_OUT_REPORT_PAYMENTS:
      return state.set('payments', fromJS(action.payload)).set('paymentsLoading', false);
    case actionTypes.FETCH_Z_OUT_REPORT_PAYMENTS_LOADING:
      return state.set('paymentsLoading', action.payload);
    case actionTypes.EXPORT_Z_OUT_REPORT_LOADING:
      return state.set('exportZOutReportLoading', fromJS(action.payload));
    case actionTypes.EXPORT_Z_OUT_REPORT:
      return state.set('exportZOutReportResult', fromJS(action.payload));
    case actionTypes.RESET_EXPORT_SUCCESS:
      return state.set('exportZOutReportResult', fromJS({}));
    case actionTypes.RESET_Z_OUT_REPORT:
      return initStateZOutReport;
    default:
      return state;
  }
}

export default combineReducers({
  zOutReportsList: zOutReportsListReducer,
  zOutReportItem: zOutReportReducer,
});
