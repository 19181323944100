import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { actionTypes } from '../../constants/campaignsReport';

const initStateCampaignsReport = fromJS({
  list: [],
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
  isLoading: false,
  totals: {},
  exportReportLoading: false,
  exportReportResult: {},
});

function campaignsReportReducer(state = initStateCampaignsReport, action) {
  switch (action.type) {
    case actionTypes.FETCH_CAMPAIGNS_REPORT_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_CAMPAIGNS_REPORT:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta))
        .set('listLoading', false);
    case actionTypes.EXPORT_CAMPAIGNS_REPORT_LOADING:
      return state.set('exportReportLoading', fromJS(action.payload));
    case actionTypes.EXPORT_CAMPAIGNS_REPORT:
      return state.set('exportReportResult', fromJS(action.payload));
    case actionTypes.RESET_CAMPAIGNS_EXPORT_SUCCESS:
      return state.set('exportReportResult', fromJS({}));
    default:
      return state;
  }
}

const initStateCampaign = fromJS({
  campaign: {},
  isCampaignLoading: false,
  recipients: [],
  recipientsMeta: {},
  recipientsLoading: false,
  exportReportLoading: false,
  exportReportResult: {},
});

function campaignReducer(state = initStateCampaign, action) {
  switch (action.type) {
    case actionTypes.FETCH_CAMPAIGN_LOADING:
      return state.set('isCampaignLoading', action.payload);
    case actionTypes.FETCH_CAMPAIGN:
      return state.set('campaign', fromJS(action.payload)).set('listLoading', false);
    case actionTypes.FETCH_CAMPAIGN_RECEPIENTS_LOADING:
      return state.set('recipientsLoading', action.payload);
    case actionTypes.FETCH_CAMPAIGN_RECEPIENTS:
      return state
        .set('recipients', fromJS(action.payload.data))
        .set('recipientsMeta', fromJS(action.payload.meta))
        .set('recipientsLoading', false);
    case actionTypes.EXPORT_CAMPAIGN_LOADING:
      return state.set('exportReportLoading', fromJS(action.payload));
    case actionTypes.EXPORT_CAMPAIGN:
      return state.set('exportReportResult', fromJS(action.payload));
    case actionTypes.RESET_EXPORT_SUCCESS:
      return state.set('exportReportResult', fromJS({}));
    case actionTypes.RESET_CAMPAIGN:
      return initStateCampaign;
    default:
      return state;
  }
}

export default combineReducers({
  campaignsList: campaignsReportReducer,
  campaignItem: campaignReducer,
});
