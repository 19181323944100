import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Box, Button, Divider, makeStyles, Typography } from '@material-ui/core';

import messages from 'common/components/PersonProfile/messages';
import { CheckInBlock } from 'common/components/PersonProfile/components';
import TicketSectionActions from './TicketSectionActions/TicketSectionActions';
import TicketListItem from './TicketListItem/TicketListItem';
import TicketViewModal from './TicketViewModal/TicketViewModal';
import TicketsViewModal from './TicketsViewModal/TicketsViewModal';
import TicketCreateModal from './TicketCreateModal/TicketCreateModal';
import permissionsMessages from 'modules/permissions/messages/messages';
import { CustomTheme } from 'common/ui/interfaces';
import * as selectors from 'common/components/PersonProfile/state/tickets/selectors';
import * as actions from 'common/components/PersonProfile/state/tickets/actions';
import { PeakModules } from 'common/constants/peakModules';
import { useAppDispatch } from 'store/hooks';
import { IProfileInfoImt, ITicketCreateDto } from 'common/components/PersonProfile/interfaces';
import { ActionResult } from 'common/constants';
import { fetchPeakSystemSettings } from 'common/state/settings/actions';
import { selectPeakSettings } from 'common/state/settings/selectors';

const useStyles = makeStyles((theme: CustomTheme) => ({
  viewAllButton: {
    margin: theme.spacing(0, 0, -1, 0),
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
}));

interface ITicketSectionProps {
  module: PeakModules;
  profile: IProfileInfoImt;
}

const TicketsSection = (props: ITicketSectionProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { module, profile } = props;
  const profileId = profile.get('id');

  const tickets = useSelector(selectors.selectPersonTickets(profileId));
  const actionResult = useSelector(selectors.selectAddPersonTicketActionResult(profileId));
  const addPersonTicketLoading = useSelector(selectors.selectAddPersonTicketLoading(profileId));
  const personTicketsLoading = useSelector(selectors.selectPersonTicketsLoading(profileId));
  const personTicket = useSelector(selectors.selectPersonTicket(profileId));
  const personTicketLoading = useSelector(selectors.selectPersonTicketLoading(profileId));
  const peakSettings = useSelector(selectPeakSettings);
  const error = useSelector(selectors.selectTicketsError(profileId));

  const [ticketViewModalOpen, setTicketViewModalOpen] = useState(false);
  const [ticketsViewModalOpen, setTicketsViewModalOpen] = useState(false);
  const [ticketCreateModalOpen, setTicketCreateModalOpen] = useState(false);

  const zendeskApiEnabled = peakSettings?.get('zendeskApiEnabled');

  useEffect(() => {
    if (zendeskApiEnabled) {
      dispatch(actions.fetchPersonTickets(profileId, module));
    }
  }, [dispatch, profileId, module, zendeskApiEnabled]);

  useEffect(() => {
    if (actionResult === ActionResult.SUCCESS_ACTION) {
      dispatch(actions.addPersonTicketSuccessAction(null, profileId));
      setTicketCreateModalOpen(false);
    }
  }, [actionResult, dispatch, profileId, setTicketCreateModalOpen]);

  useEffect(() => {
    return () => {
      dispatch(actions.resetPersonTickets(null, profileId));
      dispatch(actions.setPersonTicketsErrorAction(null, profileId));
    };
  }, [dispatch, profileId]);

  useEffect(() => {
    if (error) {
      dispatch(fetchPeakSystemSettings());
    }
  }, [error, dispatch]);

  const classes = useStyles();

  const onAddNewClick = () => {
    setTicketCreateModalOpen(true);
  };

  const onOpenTicketViewModal = useCallback(
    (ticketId: number) => {
      dispatch(actions.fetchPersonTicket(profileId, ticketId, module));
      setTicketViewModalOpen(true);
    },
    [dispatch, module, profileId],
  );

  const handleViewAll = () => {
    dispatch(actions.fetchPersonTickets(profileId, module));
    setTicketsViewModalOpen(true);
  };

  const onCloseTicketViewModal = () => {
    setTicketViewModalOpen(false);
    dispatch(actions.resetPersonTicket(null, profileId));
  };

  const onCreateTicket = (attachments: File[], dto: ITicketCreateDto) => {
    dispatch(actions.addPersonTicket(profileId, attachments, dto, module));
  };

  return (
    <CheckInBlock
      headerDivider
      title={<FormattedMessage {...messages.ticketsBlockTitle} />}
      buttonGroup={
        <TicketSectionActions
          showWarning={!zendeskApiEnabled}
          disabled={!zendeskApiEnabled}
          onAddNewClick={onAddNewClick}
        />
      }
    >
      {tickets?.size ? (
        <>
          <Box mt={1}>
            {tickets.map(item => {
              return (
                <React.Fragment key={item.get('id')}>
                  <TicketListItem
                    handleOnClick={() => onOpenTicketViewModal(item.get('id'))}
                    ticket={item}
                  />
                  <Divider />
                </React.Fragment>
              );
            })}
          </Box>
          <Box mt={1}>
            <Button
              onClick={handleViewAll}
              color="primary"
              fullWidth
              disabled={!zendeskApiEnabled}
              className={classes.viewAllButton}
            >
              <FormattedMessage {...permissionsMessages.viewAll} />
            </Button>
          </Box>
        </>
      ) : (
        <Box className="empty-section-placeholder" p={2}>
          <Typography className="empty-text">
            <FormattedMessage {...messages.emptyTicketsListMessage} />
          </Typography>
        </Box>
      )}
      {ticketViewModalOpen && (
        <TicketViewModal
          isOpen
          ticket={personTicket}
          isLoading={personTicketLoading}
          onClose={onCloseTicketViewModal}
        />
      )}
      {ticketsViewModalOpen && (
        <TicketsViewModal
          onOpenTicketViewModal={onOpenTicketViewModal}
          isOpen
          isLoading={personTicketsLoading}
          tickets={tickets}
          onOpenTicketCreateModal={() => {
            setTicketCreateModalOpen(true);
          }}
          onClose={() => {
            setTicketsViewModalOpen(false);
          }}
        />
      )}
      {ticketCreateModalOpen && (
        <TicketCreateModal
          onCreateTicket={onCreateTicket}
          module={module}
          profile={profile}
          isLoading={addPersonTicketLoading}
          isOpen
          onClose={() => {
            setTicketCreateModalOpen(false);
          }}
        />
      )}
    </CheckInBlock>
  );
};

export default TicketsSection;
