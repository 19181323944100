import { ConstOptions } from 'common/constants/classes';
import primaryInfoMessage from 'common/components/Steps/MemberPrimaryInfoStep/messages';

import dictionariesMessages from 'modules/dictionaries/messages/messages';
import menuItems from 'common/messages/menuItems';

export enum CameFromType {
  ReferralList = 'REFERRAL_LIST',
  DropdownSelect = 'DROPDOWN_SELECT',
  SingleInput = 'SINGLE_INPUT',
  NoInput = 'NO_INPUT',
}

export const CameFromTypes = new ConstOptions([
  {
    key: CameFromType.ReferralList,
    value: CameFromType.ReferralList,
    message: dictionariesMessages.referralListOption,
  },
  {
    key: CameFromType.DropdownSelect,
    value: CameFromType.DropdownSelect,
    message: dictionariesMessages.dropdownSelectOption,
  },
  {
    key: CameFromType.SingleInput,
    value: CameFromType.SingleInput,
    message: dictionariesMessages.singleInputOption,
  },
  {
    key: CameFromType.NoInput,
    value: CameFromType.NoInput,
    message: dictionariesMessages.noInputOption,
  },
]);

export enum SocialMediaType {
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  OTHER = 'OTHER',
}

export const SocialMediaTypes = new ConstOptions([
  {
    key: SocialMediaType.FACEBOOK,
    value: SocialMediaType.FACEBOOK,
    message: primaryInfoMessage.newMemberPrimaryInfoFacebookSelect,
  },
  {
    key: SocialMediaType.INSTAGRAM,
    value: SocialMediaType.INSTAGRAM,
    message: primaryInfoMessage.newMemberPrimaryInfoInstagramSelect,
  },
  {
    key: SocialMediaType.OTHER,
    value: SocialMediaType.OTHER,
    message: menuItems.other,
  },
]);
