import React, { FC, useMemo } from 'react';
import { IDiscardChangesModalProvider } from 'common/modals/DiscardChangesModal/DiscardChangesModalProvider';
import DiscardChangesContext from 'common/contexts/DiscardChangesContext';

interface IDiscardChangesContextProvider extends IDiscardChangesModalProvider {
  children: JSX.Element;
}

const DiscardChangesContextProvider: FC<IDiscardChangesContextProvider> = ({
  children,
  ...rest
}) => {
  const contextValue = useMemo((): IDiscardChangesModalProvider => {
    return rest;
  }, [rest]);

  return (
    <DiscardChangesContext.Provider value={contextValue}>{children}</DiscardChangesContext.Provider>
  );
};

export default DiscardChangesContextProvider;
