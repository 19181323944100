import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// selectors
import { selectCurrentUser } from 'modules/authentication/state/selectors';
import {
  selectClockedInActionResult,
  selectTimeclockJobPositions,
  selectTimeclockJobPositionsLoading,
} from 'modules/timeclock/state/selectors';

// components
import TimeClockSideMenu from 'modules/timeclock/containers/TimeclockSideMenu/TimeClockSideMenu';
import TimeClockJobPositionList from '../TimeclockJobPositionList/TimeclockJobPositionList';

// actions
import {
  clockInEmployeePositionAction,
  getEmployeeTimeclockJobPositions,
} from 'modules/timeclock/state/actions';

// interfaces
import { ITimeClockEmployeeJobPosition } from 'common/interfaces/employee';
import { IUserProfileInfoImt } from 'modules/authentication/interfaces';

// constants
import { ActionResult } from 'common/constants';
import messages from 'modules/timeclock/messages';

interface IProps {
  anchorEl: null | Element;
  onClose: () => void;
}

const ClockInModal = ({ anchorEl, onClose }: IProps): JSX.Element => {
  const dispatch = useDispatch();

  const currentUser: IUserProfileInfoImt = useSelector(selectCurrentUser);
  const jobPositions: ITimeClockEmployeeJobPosition[] = useSelector(selectTimeclockJobPositions);
  const jobPositionsLoading: boolean = useSelector(selectTimeclockJobPositionsLoading);
  const clockedInResult: ActionResult = useSelector(selectClockedInActionResult);

  useEffect(() => {
    dispatch(getEmployeeTimeclockJobPositions(currentUser?.get('id')));
  }, [dispatch, currentUser]);

  useEffect(() => {
    if (clockedInResult === ActionResult.SUCCESS_ACTION) {
      dispatch(clockInEmployeePositionAction(null));
      dispatch(getEmployeeTimeclockJobPositions(currentUser?.get('id')));
    }
  }, [dispatch, currentUser, clockedInResult]);

  return (
    <TimeClockSideMenu
      anchorEl={anchorEl}
      onClose={onClose}
      title={<FormattedMessage {...messages.myTimeclockLabel} />}
      isLoading={jobPositionsLoading}
    >
      <TimeClockJobPositionList
        jobPositions={jobPositions}
        employeeId={currentUser?.get('id')}
        isProfile
      />
    </TimeClockSideMenu>
  );
};

export default ClockInModal;
