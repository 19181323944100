import { createAction } from '@reduxjs/toolkit';

import Services from 'services/network';

import { enqueueErrorNotification } from 'common/state/notifications/actions';

import { actionTypes } from 'common/state/globalSearch/constants';

import { GeneralThunkAction } from 'common/state/interfaces';
import { IGlobalSearchResult } from 'common/interfaces/search';

export type ISearchEntity = 'global' | 'help';

const fetchSearchResultsAction = createAction<IGlobalSearchResult>(actionTypes.GET_SEARCH_RESULTS);
const fetchSearchResultsLoadingAction = createAction<boolean>(
  actionTypes.GET_SEARCH_RESULTS_LOADING,
);
export const resetSearchResultsAction = createAction(actionTypes.RESET_SEARCH_RESULTS);

export const fetchSearchResults = (
  search: string,
  searchEntity: ISearchEntity,
  isPeakLevel?: boolean,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchSearchResultsLoadingAction(true));

    try {
      let searchResults;

      if (searchEntity === 'global') {
        searchResults = isPeakLevel
          ? await Services.GlobalSearch.getGlobalSearchPeakResults(search)
          : await Services.GlobalSearch.getGlobalSearchResults(search);
      } else {
        searchResults = await Services.HelpInfoSearch.getHelpInfoSearchResults(search);
      }

      dispatch(fetchSearchResultsAction(searchResults));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchSearchResultsLoadingAction(false));
    }
  };
};
