import React from 'react';
import { FormattedMessage } from 'react-intl';
// messages
import messages from 'modules/booking/messages';
// interfaces
import { TSenderAvailabilityImt } from 'modules/booking/interfaces';
// state
import NotifyControlFields from 'common/components/ReactHookForm/NotifyControlFields/NotifyControlFields';
import { makeStyles, Theme } from '@material-ui/core';

interface INotifyVariant {
  senderAvailability: TSenderAvailabilityImt;
}

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    marginBottom: theme.spacing(1),
  },
}));

const NotifyVariant = ({ senderAvailability }: INotifyVariant): JSX.Element => {
  const classes = useStyles();

  return (
    <NotifyControlFields
      label={<FormattedMessage {...messages.notifyParticipantsLabel} />}
      labelClassName={classes.label}
      emailFieldName="notifyViaEmail"
      smsFieldName="notifyViaSms"
      notificationName="notifyViaNotification"
      senderAvailability={senderAvailability}
    />
  );
};

export default NotifyVariant;
