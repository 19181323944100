import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography, Box, ListItem, Avatar, SvgIcon } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';

import useTimezoneMoment from 'common/hooks/useTimezoneMoment';

import { ReactComponent as ArrowForwardIcon } from 'img/arrow-right.svg';
import { ISMSImt } from 'common/components/PersonProfile/interfaces';
import { ReactComponent as PhoneIcon } from 'img/icons/mobile.svg';
import { SMSStatusMessages } from 'common/components/PersonProfile/constants';
import messages from 'common/components/PersonProfile/messages';
import { convertPhoneNumber } from 'helpers/common';
import ActionItem from 'common/components/ActionsMenu/ActionItem';
import ActionsMenu from 'common/components/ActionsMenu/ActionsMenu';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    cursor: 'pointer',
    display: 'block',
    padding: theme.spacing(0, 2, 0, 0),
  },
  itemInner: {
    display: 'flex',
    borderTop: `1px solid ${theme.palette.secondary.light}`,
    padding: theme.spacing(1.5, 0, 2, 0),
  },
  phoneLabel: {
    fontWeight: 500,
  },
  phoneIcon: {
    width: '18px',
    height: '18px',
    marginRight: theme.spacing(0.5),
    color: theme.palette.text.secondary,
  },
  arrowIcon: {
    width: '12px',
    height: '12px',
    margin: '0 5px',
    color: theme.palette.text.secondary,
  },
  avatar: {
    width: '16px',
    height: '16px',
    marginRight: theme.spacing(1),
  },
  description: {
    lineHeight: '1.29',
  },
  replyBtn: {
    opacity: 0.4,
    marginLeft: theme.spacing(1),
  },
  status: {
    whiteSpace: 'nowrap',
    fontSize: '0.625rem',
    textTransform: 'uppercase',
  },
}));

interface ISMSItemProps {
  sms: ISMSImt;
  fromPhone: string;
  onReply: (sms: ISMSImt) => void;
  onClick: (sms: ISMSImt) => void;
}

const SMSItem = ({ sms, onReply, fromPhone, onClick }: ISMSItemProps): JSX.Element => {
  const [timezoneMoment] = useTimezoneMoment();

  const classes = useStyles();

  const onReplyClick = () => {
    onReply(sms);
  };

  const onSMSClick = () => {
    onClick(sms);
  };

  return (
    <ListItem className={classes.root}>
      <Box className={classes.itemInner}>
        <Box onClick={onSMSClick} flex="1">
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={0.5}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              flex={1}
            >
              <Box display="flex" alignItems="center">
                <Avatar src={sms.getIn(['clubPerson', 'imageUrl'])} className={classes.avatar} />
                <Typography component="span" variant="body2">
                  {`${sms.getIn(['targetPerson', 'title'])}`}
                </Typography>
              </Box>

              <Typography variant="body2" color="textSecondary">
                {timezoneMoment(sms.get('date')).format('h:mm A, MMM D')}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box display="flex" alignItems="center" flexWrap="wrap" mb={1}>
              <PhoneIcon className={classes.phoneIcon} />
              <Typography
                className={classes.phoneLabel}
                component="span"
                variant="body2"
                color="secondary"
              >
                {convertPhoneNumber(fromPhone)}
              </Typography>
              <SvgIcon className={classes.arrowIcon}>
                <ArrowForwardIcon />
              </SvgIcon>
              <Typography
                className={classes.phoneLabel}
                component="span"
                variant="body2"
                color="secondary"
              >
                {convertPhoneNumber(sms.getIn(['targetPersonPhone', 'title']))}
              </Typography>
            </Box>
            <Typography className={classes.status} component="span" color="textSecondary">
              <FormattedMessage {...SMSStatusMessages[sms.get('smsStatus')]} />
            </Typography>
          </Box>
          <Typography className={classes.description} component="span" variant="body2">
            {sms.get('text')}
          </Typography>
        </Box>
        <Box pt={0.25}>
          <ActionsMenu horizontal>
            <ActionItem onClick={onSMSClick}>
              <FormattedMessage {...messages.viewTitle} />
            </ActionItem>
            <ActionItem onClick={onReplyClick}>
              <FormattedMessage {...messages.replyTitle} />
            </ActionItem>
          </ActionsMenu>
        </Box>
      </Box>
    </ListItem>
  );
};

export default SMSItem;
