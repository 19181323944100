import * as yup from 'yup';

import { getRequiredMessage } from 'common/constants/globalConstants';

import inputErrors from 'common/messages/inputErrors';
import { TShortProfileFieldInfoImt } from 'modules/corporate-settings/interfaces';
import {
  FieldNecessity,
  RequiredProfileInfo,
} from 'modules/corporate-settings/constants/requiredFields';

export const insuranceValidationSchema = (requiredFields: TShortProfileFieldInfoImt) => {
  return {
    insuranceCompany: yup
      .object()
      .nullable()
      .when('insuranceCompany1', {
        is: () => {
          return requiredFields.get(RequiredProfileInfo.InsuranceInfo) === FieldNecessity.Required;
        },
        then: schema => schema.required(getRequiredMessage),
        otherwise: schema => schema,
      }),
    insuranceMemberId: yup
      .string()
      .trim()
      .max(50, () => inputErrors.insuranceIdLengthError)
      .nullable()
      .when('insuranceMemberId1', {
        is: () => requiredFields.get(RequiredProfileInfo.InsuranceInfo) === FieldNecessity.Required,
        then: schema => schema.required(getRequiredMessage),
        otherwise: schema => schema,
      }),
  };
};
