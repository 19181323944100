// libraries
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
// interfaces
import { IServerError } from '../../interfaces/http';
import { IKeyed, Message } from '../../interfaces/common';
// components
import { Button } from 'common/components';
import { Close } from '@material-ui/icons';
// state
import {
  selectErrorNotifications,
  selectSuccessNotifications,
} from '../../state/notifications/selectors';
import { closeErrorNotification } from '../../state/notifications/actions';
// messages
import { FormattedMessageByCode } from '../../errors/serverErrors';
import { makeStyles, Theme } from '@material-ui/core';
import { closeSuccessNotification } from 'common/state/notifications/actions';
import { parseErrorArgs } from 'common/utils/http';

const useStyles = makeStyles((theme: Theme) => ({
  muiSnackbarSuccess: {
    backgroundColor: `${theme.palette.primary.main}!important`,
  },
  muiSnackbarButton: {
    minWidth: 'auto',
    color: theme.palette.primary.contrastText,
  },
}));

let displayedErrors = [];
let displayedSuccessNotifics = [];

const SnackbarNotifications = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const errorNotifications: Array<IServerError & IKeyed> = useSelector(selectErrorNotifications);
  const successNotifications: Array<Message & IKeyed> = useSelector(selectSuccessNotifications);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayedErrors = id => {
    displayedErrors = [...displayedErrors, id];
  };

  const removeDisplayedErrors = id => {
    displayedErrors = [...displayedErrors.filter(key => id !== key)];
  };

  useEffect(() => {
    errorNotifications.forEach(({ key, message, codes, args, jsErrorMessage, jsErrorStack }) => {
      if (displayedErrors.includes(key)) return;

      let errorMessageValues: { [key: string]: string };

      if (args?.length) {
        errorMessageValues = parseErrorArgs(args);
      }

      if (jsErrorMessage && jsErrorStack) {
        // eslint-disable-next-line no-console
        console.error(jsErrorMessage);
        // eslint-disable-next-line no-console
        console.error(jsErrorStack);
      }

      const snackbarMessage =
        codes && codes.every(code => !!FormattedMessageByCode(code)) ? (
          <>{codes.map(code => FormattedMessageByCode(code, errorMessageValues))}</>
        ) : (
          message
        );

      enqueueSnackbar(snackbarMessage, {
        autoHideDuration: 7000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        action: snackKey => (
          <Button style={{ minWidth: 'auto' }} onClick={() => closeSnackbar(snackKey)} size="small">
            <Close color="secondary" />
          </Button>
        ),
        onExited: (event, snackKey) => {
          dispatch(closeErrorNotification(key));
          removeDisplayedErrors(snackKey);
        },
      });

      // keep track of snackbars that we've displayedErrors
      storeDisplayedErrors(key);
    });
  }, [errorNotifications, closeSnackbar, enqueueSnackbar, dispatch]);

  const storeDisplayedSuccessNotifics = id => {
    displayedSuccessNotifics = [...displayedSuccessNotifics, id];
  };

  const removeDisplayedSuccessNotifics = id => {
    displayedSuccessNotifics = [...displayedSuccessNotifics.filter(key => id !== key)];
  };

  useEffect(() => {
    successNotifications.forEach(({ key, id, defaultMessage }) => {
      if (displayedSuccessNotifics.includes(key)) return;
      const snackbarMessage = <FormattedMessage id={id} defaultMessage={defaultMessage} />;

      enqueueSnackbar(snackbarMessage, {
        autoHideDuration: 7000,
        className: classes.muiSnackbarSuccess,
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        action: snackKey => (
          <Button
            variant="text"
            className={classes.muiSnackbarButton}
            onClick={() => closeSnackbar(snackKey)}
            size="small"
          >
            <Close color="inherit" />
          </Button>
        ),
        onExited: (event, snackKey) => {
          dispatch(closeSuccessNotification(key));
          removeDisplayedSuccessNotifics(snackKey);
        },
      });

      // keep track of snackbars that we've displayedSuccessNotifics
      storeDisplayedSuccessNotifics(key);
    });
  }, [successNotifications, closeSnackbar, enqueueSnackbar, dispatch, classes]);

  return <></>;
};

export default SnackbarNotifications;
