export const actionTypes = {
  SET_SELECTED_SERVICE_ITEM: 'SET_SELECTED_SERVICE_ITEM',

  SET_DETAILS_MODAL_SHOWN: 'SET_DETAILS_MODAL_SHOWN',
  FETCH_SERVICE_ITEM_DETAILS: 'FETCH_SERVICE_ITEM_DETAILS',
  FETCH_SERVICE_ITEM_DETAILS_LOADING: 'FETCH_SERVICE_ITEM_DETAILS_LOADING',
  RESET_SERVICE_ITEM_DETAILS: 'RESET_SERVICE_ITEM_DETAILS',

  SET_REDEEM_MODAL_SHOWN: 'SET_REDEEM_MODAL_SHOWN',
  REDEEM_SERVICE_ITEM: 'REDEEM_SERVICE_ITEM',
  REDEEM_SERVICE_ITEM_LOADING: 'REDEEM_SERVICE_ITEM_LOADING',
  RESET_REDEEM_SERVICE_ITEM: 'RESET_REDEEM_SERVICE_ITEM',

  FETCH_SERVICE_EVENTS: 'FETCH_SERVICE_EVENTS',
  FETCH_SERVICE_EVENTS_LOADING: 'FETCH_SERVICE_EVENTS_LOADING',
  RESET_SERVICE_EVENTS: 'RESET_SERVICE_EVENTS',

  FETCH_SERVICE_EVENT_DETAILS: 'FETCH_SERVICE_EVENT_DETAILS',
  FETCH_SERVICE_EVENT_DETAILS_LOADING: 'FETCH_SERVICE_EVENT_DETAILS_LOADING',
  RESET_SERVICE_EVENT_DETAILS: 'RESET_SERVICE_EVENT_DETAILS',

  FETCH_SERVICE_RESOURCE_TAGS: 'FETCH_SERVICE_RESOURCE_TAGS',
  FETCH_SERVICE_RESOURCE_TAGS_LOADING: 'FETCH_SERVICE_RESOURCE_TAGS_LOADING',
  RESET_SERVICE_RESOURCE_TAGS: 'RESET_SERVICE_RESOURCE_TAGS',

  FETCH_SERVICE_REDEEM_HISTORY_SUCCESS: 'FETCH_SERVICE_REDEEM_HISTORY_SUCCESS',
  FETCH_SERVICE_REDEEM_HISTORY_LOADING: 'FETCH_SERVICE_REDEEM_HISTORY_LOADING',
  RESET_SERVICE_REDEEM_HISTORY: 'RESET_SERVICE_REDEEM_HISTORY',

  FETCH_SERVICE_REDEEM_RECENT_HISTORY_SUCCESS: 'FETCH_SERVICE_REDEEM_RECENT_HISTORY_SUCCESS',
  FETCH_SERVICE_REDEEM_RECENT_HISTORY_LOADING: 'FETCH_SERVICE_REDEEM_RECENT_HISTORY_LOADING',
  RESET_SERVICE_REDEEM_RECENT_HISTORY: 'RESET_SERVICE_REDEEM_RECENT_HISTORY',

  // inventory
  SET_SELECTED_INVENTORY_ID: 'SET_SELECTED_INVENTORY_ID',

  SET_INVENTORY_DETAILS_MODAL_SHOWN: 'SET_INVENTORY_DETAILS_MODAL_SHOWN',
  FETCH_INVENTORY_DETAILS_SUCCESS: 'FETCH_INVENTORY_DETAILS_SUCCESS',
  FETCH_INVENTORY_DETAILS_LOADING: 'FETCH_INVENTORY_DETAILS_LOADING',
  RESET_INVENTORY_DETAILS: 'RESET_INVENTORY_DETAILS',

  SET_REDEEM_INVENTORY_MODAL_SHOWN: 'SET_REDEEM_INVENTORY_MODAL_SHOWN',
  REDEEM_INVENTORY: 'REDEEM_INVENTORY',
  REDEEM_INVENTORY_LOADING: 'REDEEM_INVENTORY_LOADING',
  RESET_REDEEM_INVENTORY: 'RESET_REDEEM_INVENTORY',

  FETCH_INVENTORY_REDEEM_RECENT_HISTORY_SUCCESS: 'FETCH_INVENTORY_REDEEM_RECENT_HISTORY_SUCCESS',
  FETCH_INVENTORY_REDEEM_RECENT_HISTORY_LOADING: 'FETCH_INVENTORY_REDEEM_RECENT_HISTORY_LOADING',
  RESET_INVENTORY_REDEEM_RECENT_HISTORY: 'RESET_INVENTORY_REDEEM_RECENT_HISTORY',

  FETCH_INVENTORY_REDEEM_HISTORY_SUCCESS: 'FETCH_INVENTORY_REDEEM_HISTORY_SUCCESS',
  FETCH_INVENTORY_REDEEM_HISTORY_LOADING: 'FETCH_INVENTORY_REDEEM_HISTORY_LOADING',
  RESET_INVENTORY_REDEEM_HISTORY: 'RESET_INVENTORY_REDEEM_HISTORY',

  // family member service
  SET_SELECTED_FAMILY_MEMBER_SERVICE_ID: 'SET_SELECTED_FAMILY_MEMBER_SERVICE_ID',

  SET_FAMILY_MEMBER_SERVICE_DETAILS_MODAL_SHOWN: 'SET_FAMILY_MEMBER_SERVICE_DETAILS_MODAL_SHOWN',
  FETCH_FAMILY_MEMBER_SERVICE_DETAILS_SUCCESS: 'FETCH_FAMILY_MEMBER_SERVICE_DETAILS_SUCCESS',
  FETCH_FAMILY_MEMBER_SERVICE_DETAILS_LOADING: 'FETCH_FAMILY_MEMBER_SERVICE_DETAILS_LOADING',
  RESET_FAMILY_MEMBER_SERVICE_DETAILS: 'RESET_FAMILY_MEMBER_SERVICE_DETAILS',

  FETCH_FAMILY_MEMBERS_TO_REDEEM_SUCCESS: 'FETCH_FAMILY_MEMBERS_TO_REDEEM_SUCCESS',
  FETCH_FAMILY_MEMBERS_TO_REDEEM_LOADING: 'FETCH_FAMILY_MEMBERS_TO_REDEEM_LOADING',
  RESET_FAMILY_MEMBERS_TO_REDEEM: 'RESET_FAMILY_MEMBERS_TO_REDEEM',

  SET_REDEEM_FAMILY_MEMBER_SERVICE_MODAL_SHOWN: 'SET_REDEEM_FAMILY_MEMBER_SERVICE_MODAL_SHOWN',
  RESET_REDEEM_FAMILY_MEMBERS: 'RESET_REDEEM_FAMILY_MEMBERS',
  REDEEM_FAMILY_MEMBERS_LOADING: 'REDEEM_FAMILY_MEMBERS_LOADING',
  REDEEM_FAMILY_MEMBERS_ACTION_RESULT: 'REDEEM_FAMILY_MEMBERS_ACTION_RESULT',
};
