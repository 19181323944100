export const actionTypes = {
  SET_FAMILY_MEMBERS: 'SET_FAMILY_MEMBERS',
  ADD_FAMILY_MEMBERS_ACTION_RESULT: 'ADD_FAMILY_MEMBERS_ACTION_RESULT',
  ADD_FAMILY_MEMBERS_ACTION_LOADING: 'ADD_FAMILY_MEMBERS_ACTION_LOADING',
  RESET_ADD_FAMILY_MEMBERS_ACTION_RESULT: 'RESET_ADD_FAMILY_MEMBERS_ACTION_RESULT',
  CREATE_MEMBER_FOR_FAMILY_MEMBER_LOADING: 'CREATE_MEMBER_FOR_FAMILY_MEMBER_LOADING',
  CREATE_MEMBER_FOR_FAMILY_MEMBER_RESULT: 'CREATE_MEMBER_FOR_FAMILY_MEMBER_RESULT',
  CREATE_MEMBER_FOR_FAMILY_MEMBER_STEP_LOADING: 'CREATE_MEMBER_FOR_FAMILY_MEMBER_STEP_LOADING',
  CREATE_MEMBER_FOR_FAMILY_MEMBER_STEP_RESULT: 'CREATE_MEMBER_FOR_FAMILY_MEMBER_STEP_RESULT',
  RESET_CREATE_FAMILY_MEMBER_RESULT: 'RESET_CREATE_FAMILY_MEMBER_RESULT',
  FETCH_FAMILY_MEMBERS_LOADING: 'FETCH_FAMILY_MEMBERS_LOADING',
};
