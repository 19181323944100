import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import { Box, Typography, Theme, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { updatePersonSalesInfo } from 'common/components/PersonProfile/state/actions';
import {
  selectPersonSalesInfo,
  selectPersonSalesInfoLoading,
} from 'common/components/PersonProfile/state/selectors';

import { useAppDispatch } from 'store/hooks';

import { FieldInfo, LoadingBackdrop } from 'common/components';
import { EditSalesInfoModal } from 'common/components/PersonProfile/modals';
import { ReactComponent as EditIcon } from 'img/icons/pencil_deprecated.svg';
import { Button } from 'common/components/index';

import { IPersonSalesInfoImt } from 'common/components/PersonProfile/interfaces';
import { ILeadProfileImt, INewLeadProfileData } from 'modules/crm/interfaces/leads';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { IDictionaryCommonItem } from 'modules/dictionaries/interfaces/interfaces';

import { CommunicationsTypes, LeadValueOptions } from 'modules/crm/constants/leads';

import messages from 'common/components/PersonProfile/messages';
import memberMessages from 'common/components/Steps/MemberPrimaryInfoStep/messages';
import inputLabels from 'common/messages/inputLabels';
import tableFilters from 'common/messages/tableFilters';
import { DEFAULT_DATE_FORMAT } from 'common/constants/dateFormats';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: '3px',
  },
  title: {
    fontSize: '.875rem',
    fontWeight: 'bold',
    lineHeight: 1.2,
    textTransform: 'uppercase',
  },
  editIcon: {
    width: '15px',
    height: '15px',
  },
}));

interface IProps {
  module: PeakModuleForNewPersonType;
  personId: string;
  primaryInfo: ILeadProfileImt;
}

const SalespersonInfo = React.memo(
  ({ module, personId, primaryInfo }: IProps): JSX.Element => {
    const dispatch = useAppDispatch();

    const salesInfo: IPersonSalesInfoImt = useSelector(selectPersonSalesInfo(personId));
    const isSalesInfoLoading: boolean = useSelector(selectPersonSalesInfoLoading(personId));

    const [isEditSalesInfoModalOpen, setIsEditSalesInfoModalOpen] = useState(false);

    const classes = useStyles();

    const handleUpdateSalesInfo = useCallback(
      (data: INewLeadProfileData) => {
        const { referralMember, activitiesIds, assignedSalesperson, ...otherFields } = data;

        const transformedActivitiesIds = (activitiesIds as IDictionaryCommonItem[]).map(
          activityItem => activityItem.id,
        ) as string[];

        dispatch(
          updatePersonSalesInfo(personId, module, {
            ...otherFields,
            activitiesIds: transformedActivitiesIds,
            referralMemberId: referralMember?.id,
            assignedSalespersonId: assignedSalesperson?.id,
          }),
        );
      },
      [dispatch, module, personId],
    );

    const assignedSalesperson = salesInfo?.getIn(['assignedSalesperson', 'title']);
    const salesValue = salesInfo?.get('salesValue');
    const referralMember = salesInfo?.getIn(['referralMember', 'title']);
    const cameFromTitle = salesInfo?.getIn(['cameFromIndicator', 'title']);

    const cameFrom =
      (referralMember && `${cameFromTitle} (${referralMember})`) ||
      (salesInfo?.get('cameFromValue')
        ? `${cameFromTitle} (${salesInfo?.get('cameFromValue')})`
        : cameFromTitle);
    const createdDate = salesInfo?.get('createdDate');
    const createdDateString = moment(createdDate).isValid()
      ? moment(createdDate).format(DEFAULT_DATE_FORMAT)
      : '';

    const wayOfContact = salesInfo?.get('preferableWayOfCommunication');
    const doNotContactHours = salesInfo?.get('doNotContactHours');
    const doNotContactFrom = doNotContactHours?.get('from');
    const doNotContactTo = doNotContactHours?.get('to');
    const activities = salesInfo?.get('activities');
    const potentialClub = salesInfo?.getIn(['potentialClub', 'title']);
    const limitations = salesInfo?.get('limitations');

    const activitiesString = activities
      ? activities.reduce(
          (accString, activityItem, index) =>
            index === 0 ? activityItem.get('title') : `${accString}, ${activityItem.get('title')}`,
          '',
        )
      : '-';

    const doNotContactString = `${
      moment(doNotContactFrom).isValid() ? moment(doNotContactFrom).format('hh:mm A') : ''
    } - ${moment(doNotContactTo).isValid() ? moment(doNotContactTo).format('hh:mm A') : ''}`;

    return (
      <Box className={classes.root} p={2} position="relative">
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <Typography color="textSecondary" className={classes.title}>
            <FormattedMessage {...memberMessages.newMemberPrimaryInfoSalesInfo} />
          </Typography>

          <Button hasHoverStyle color="primary" onClick={() => setIsEditSalesInfoModalOpen(true)}>
            <EditIcon className={classes.editIcon} />
          </Button>
        </Box>

        <Grid container spacing={4}>
          <Grid item>
            <FieldInfo
              label={<FormattedMessage {...messages.assignee} />}
              description={assignedSalesperson || '-'}
            />
          </Grid>

          <Grid item>
            <FieldInfo
              label={<FormattedMessage {...inputLabels.value} />}
              description={
                salesValue ? <FormattedMessage {...LeadValueOptions.message(salesValue)} /> : '-'
              }
            />
          </Grid>

          <Grid item>
            <FieldInfo
              label={<FormattedMessage {...inputLabels.cameFrom} />}
              description={cameFrom || '-'}
            />
          </Grid>

          <Grid item>
            <FieldInfo
              label={<FormattedMessage {...tableFilters.created} />}
              description={createdDateString}
            />
          </Grid>

          <Grid item>
            <FieldInfo
              label={<FormattedMessage {...messages.wayOfContact} />}
              description={
                wayOfContact ? (
                  <FormattedMessage {...CommunicationsTypes.message(wayOfContact)} />
                ) : (
                  '-'
                )
              }
            />
          </Grid>

          <Grid item>
            <FieldInfo
              label={<FormattedMessage {...messages.doNotContact} />}
              description={doNotContactString || '-'}
            />
          </Grid>

          <Grid item>
            <FieldInfo
              label={<FormattedMessage {...messages.interests} />}
              description={activitiesString || '-'}
            />
          </Grid>

          <Grid item>
            <FieldInfo
              label={<FormattedMessage {...messages.potentialClub} />}
              description={potentialClub || '-'}
            />
          </Grid>

          <Grid item>
            <FieldInfo
              label={<FormattedMessage {...inputLabels.limitations} />}
              description={limitations || '-'}
            />
          </Grid>
        </Grid>

        <LoadingBackdrop isLoading={isSalesInfoLoading} />

        {isEditSalesInfoModalOpen && (
          <EditSalesInfoModal
            isOpen={isEditSalesInfoModalOpen}
            onClose={() => setIsEditSalesInfoModalOpen(false)}
            onSubmit={handleUpdateSalesInfo}
            salesInfo={salesInfo}
            primaryInfo={primaryInfo}
            module={module}
          />
        )}
      </Box>
    );
  },
);

export default SalespersonInfo;
