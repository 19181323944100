// libraries
import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
// components
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import TextFieldWithReset from '../TextFieldWithReset/TextFieldWithReset';

interface NumberFormatCustomProps extends Omit<NumberFormatProps, 'onChange'> {
  inputRef: (instance: NumberFormat<number> | null) => void;
  onChange: (value: string | number) => void;
  name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...numberFormatProps } = props;

  return (
    <NumberFormat
      {...numberFormatProps}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange(values.floatValue || values.value);
      }}
    />
  );
}

export type NumberTextFieldProps = Omit<TextFieldProps, 'onChange'> & {
  numberFormatProps: NumberFormatProps;
  reset?: string | number;
  multi?: boolean;
  isDefaultCanResetFlag?: boolean;
  onChange?: (v: string | number) => void;
};

const NumberTextField: React.FC<NumberTextFieldProps> = ({
  numberFormatProps,
  InputProps,
  ...textFieldProps
}: NumberTextFieldProps): JSX.Element => (
  <TextFieldWithReset
    {...textFieldProps}
    InputProps={{
      ...InputProps,
      inputComponent: NumberFormatCustom as any,
      inputProps: numberFormatProps,
    }}
  />
);

export default NumberTextField;
