import moment from 'moment-timezone';
import messages from 'modules/crm/messages/conditions';
import { CampaignCounterType, ConditionType } from 'common/constants/campaign';
import { TConditionSettings } from 'common/interfaces/campaign';

export const conditionList: TConditionSettings[] = [
  {
    type: ConditionType.POINT_OF_SALE_PURCHASE,
    message: messages.pointSalePurchase,
  },
  {
    type: ConditionType.MEMBERS_CARDS_EXPIRING_IN_N_DAYS,
    message: messages.membersCardsExpiringIn,
    counter: 1,
    counterType: CampaignCounterType.MONTH,
  },
  {
    type: ConditionType.SESSION_REDEEMED,
    message: messages.sessionRedeemed,
  },
  {
    type: ConditionType.MEMBERS_EXPIRING_IN_N_DAYS,
    message: messages.membersExpiringIn,
    counter: 1,
    counterType: CampaignCounterType.DAY,
  },
  {
    type: ConditionType.REGULAR_EACH_DAY_OR_NUMBER_OF_DAYS,
    message: messages.regular,
    counter: 1,
    counterType: CampaignCounterType.DAY,
  },
  {
    type: ConditionType.NEW_GUEST_SIGNS_UP,
    message: messages.newGuestSignsUp,
  },
  {
    type: ConditionType.NEW_APPOINTMENT_SCHEDULED,
    message: messages.newAppointmentScheduled,
  },
  {
    type: ConditionType.LOW_USAGE_MEMBERS_CONTACTED_AFTER_XX,
    message: messages.lowUsageMembersContactedAfter,
    counter: 1,
    counterType: CampaignCounterType.DAY,
  },
  {
    type: ConditionType.CANCELLATION_OF_APPOINTMENT,
    message: messages.cancellationOfAppointment,
  },
  {
    type: ConditionType.UPCOMING_APPOINTMENTS_IN_N_DAYS_N_HOURS,
    message: messages.upcomingAppointmentsIn,
    counter: 1,
    counterType: CampaignCounterType.DAY,
  },
  {
    type: ConditionType.UPDATE_OF_SCHEDULED_APPOINTMENT,
    message: messages.updateOfScheduledAppointment,
  },
  {
    type: ConditionType.CHECK_IN,
    message: messages.checkIn,
  },
  {
    type: ConditionType.NEW_MEMBER_SIGNS_UP,
    message: messages.newMemberSignsUp,
  },
  {
    type: ConditionType.USED_THEIR_PASS_LESS_THAN_X_NUMBER_OF_TIMES_WITHIN_THEIR_PASS_LIMITATIONS,
    message: messages.usedTheirPassLessThan,
    counter: 1,
  },
  {
    type: ConditionType.SPECIFIC_DATE_AND_TIME,
    message: messages.specificDateAndTime,
    specificDate: moment()
      .add(1, 'days')
      .toDate(),
  },
  {
    type: ConditionType.APPOINTMENT_MISSED,
    message: messages.appointmentMissed,
  },
  {
    type: ConditionType.TAG_ADDED,
    message: messages.tagAdded,
  },
  {
    type: ConditionType.GUEST_PASS_TRIAL_ISSUED,
    message: messages.guestPassIssued,
  },
];
