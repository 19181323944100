import ApiService from './ApiService';
import axios from 'axios';
import { IResource, IResourceForm } from 'modules/booking/interfaces';
import { ITableParams } from 'common/interfaces/table';
import { IPaginatedData } from 'common/interfaces/pagination';

export type IPaginatedResources = IPaginatedData<IResource>;

export default class ResourcesService extends ApiService {
  public getResources = (tableParams?: ITableParams): Promise<IPaginatedResources> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get('/api/v1/booking-resources', { params: searchParams });
  };

  public getResourceById = (resourceId: string): Promise<IResource> => {
    return axios.get(`/api/v1/booking-resources/${resourceId}`);
  };

  public createResource = (requestData: IResourceForm): Promise<IResource> => {
    return axios.post('/api/v1/booking-resources', requestData);
  };

  public changeResourceStatus = (resourceId: string, isActive: boolean): Promise<IResource> => {
    return axios.patch(`api/v1/booking-resources/${resourceId}/change-status`, {
      active: isActive,
    });
  };

  public updateResource = (resourceId: string, requestData: IResourceForm): Promise<IResource> => {
    return axios.put(`/api/v1/booking-resources/${resourceId}`, requestData);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public deleteResourceById = (id: string): Promise<IResource> => {
    return new Promise(resolve => setTimeout(() => resolve({} as IResource), 500));
  };
}
