import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { actionTypes } from '../../constants/invoiceHistory';
import { initialState, initReqStateImt } from 'common/constants/initialState';

const initStateInvoiceHistory = fromJS({
  list: [],
  meta: {},
  isLoading: false,
  totals: {},
  voidInvoiceAction: initialState,
  assignInvoiceLoading: false,
  assignInvoiceResult: null,
  exportInvoiceHistoryLoading: false,
  exportInvoiceHistoryResult: {},
  statistics: {},
  statisticsLoading: false,
});

function invoiceHistoryReducer(state = initStateInvoiceHistory, action) {
  switch (action.type) {
    case actionTypes.FETCH_INVOICE_HISTORY_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_INVOICE_HISTORY:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta))
        .set('totals', fromJS(action.payload.totals));
    // Invoice History Items Actions
    // Void
    case actionTypes.VOID_INVOICE_ITEM_ACTION_LOADING:
      return state.setIn(['voidInvoiceAction', 'isLoading'], action.payload);
    case actionTypes.VOID_INVOICE_ITEM_ACTION_RESULT:
      return state.setIn(['voidInvoiceAction', 'result'], action.payload);
    case actionTypes.RESET_VOID_INVOICE_ITEM_ACTION_RESULT:
      return state.set('voidInvoiceAction', initReqStateImt);
    // Assign
    case actionTypes.ASSIGN_INVOICE_LOADING:
      return state.set('assignInvoiceLoading', fromJS(action.payload));
    case actionTypes.ASSIGN_INVOICE:
      return state.set('assignInvoiceResult', action.payload);
    case actionTypes.RESET_ASSIGN_INVOICE_SUCCESS:
      return state.set('assignInvoiceResult', null);
    // Export Invoice History
    case actionTypes.EXPORT_INVOICE_HISTORY_LOADING:
      return state.set('exportInvoiceHistoryLoading', fromJS(action.payload));
    case actionTypes.EXPORT_INVOICE_HISTORY:
      return state.set('exportInvoiceHistoryResult', fromJS(action.payload));
    case actionTypes.RESET_EXPORT_INVOICE_HISTORY_SUCCESS:
      return state.set('exportInvoiceHistoryResult', fromJS({}));
    // Statistics
    case actionTypes.RESET_INVOICE_STATISTICS_SUCCESS:
      return state.set('statistics', fromJS({}));
    case actionTypes.INVOICE_STATISTICS_LOADING:
      return state.set('statisticsLoading', fromJS(action.payload));
    case actionTypes.INVOICE_STATISTICS_ACTION:
      return state.set('statistics', fromJS(action.payload));
    default:
      return state;
  }
}

const initialStateInvoice = fromJS({
  invoice: {},
  createdMember: initialState,
  isInvoiceLoading: false,
  isAdjustInvoiceLoading: false,
  adjustInvoiceResult: false,

  inventoryDetailedInfo: null,
  isInventoryDetailedInfoLoading: false,

  packageDetailedInfo: null,
  isPackageDetailedInfoLoading: false,

  invoiceTransactions: null,
  isInvoiceTransactionsLoading: false,

  isInvoiceHTMLLoading: false,
});

function invoiceReducer(state = initialStateInvoice, action) {
  switch (action.type) {
    case actionTypes.FETCH_INVOICE_LOADING:
      return state.set('isInvoiceLoading', action.payload);
    case actionTypes.FETCH_INVOICE_ITEM:
      return state.set('invoice', fromJS(action.payload));
    case actionTypes.ADJUST_INVOICE_LOADING:
      return state.set('isAdjustInvoiceLoading', action.payload);
    case actionTypes.ADJUST_INVOICE:
      return state.set('adjustInvoiceResult', true);
    case actionTypes.RESET_ADJUST_INVOICE_SUCCESS:
      return state.set('adjustInvoiceResult', false);
    case actionTypes.CREATE_MEMBER_SUCCESS:
      return state.setIn(['createdMember', 'result'], fromJS(action.payload));
    case actionTypes.CREATE_MEMBER_LOADING:
      return state.setIn(['createdMember', 'isLoading'], action.payload);
    case actionTypes.RESET_CREATE_MEMBER:
      return state.setIn(['createdMember', 'result'], null);
    case actionTypes.RESET_INVOICE_ITEM:
      return initialStateInvoice;

    case actionTypes.FETCH_INVOICE_INVENTORY_DETAILED_INFO:
      return state.set('inventoryDetailedInfo', fromJS(action.payload));
    case actionTypes.FETCH_INVOICE_INVENTORY_DETAILED_INFO_LOADING:
      return state.set('isInventoryDetailedInfoLoading', action.payload);
    case actionTypes.RESET_INVOICE_INVENTORY_DETAILED_INFO:
      return state.set('inventoryDetailedInfo', null);

    case actionTypes.FETCH_INVOICE_PACKAGE_DETAILED_INFO:
      return state.set('packageDetailedInfo', fromJS(action.payload));
    case actionTypes.FETCH_INVOICE_PACKAGE_DETAILED_INFO_LOADING:
      return state.set('isPackageDetailedInfoLoading', action.payload);
    case actionTypes.RESET_INVOICE_PACKAGE_DETAILED_INFO:
      return state.set('packageDetailedInfo', null);

    case actionTypes.FETCH_INVOICE_TRANSACTIONS_DETAILED_INFO:
      return state.set('invoiceTransactions', fromJS(action.payload.data));
    case actionTypes.FETCH_INVOICE_TRANSACTIONS_DETAILED_INFO_LOADING:
      return state.set('isInvoiceTransactionsLoading', action.payload);
    case actionTypes.RESET_INVOICE_TRANSACTIONS_DETAILED_INFO:
      return state.set('invoiceTransactions', null);
    case actionTypes.FETCH_INVOICE_HTML_LOADING:
      return state.set('isInvoiceHTMLLoading', action.payload);
    default:
      return state;
  }
}

const initialStateInvoiceSnapshots = fromJS({
  isSnapshotsLoading: false,
  snapshotsResult: {},
});

function snapshotsReducer(state = initialStateInvoiceSnapshots, action) {
  switch (action.type) {
    case actionTypes.INVOICE_HISTORY_SNAPSHOTS_LOADING:
      return state.set('isSnapshotsLoading', action.payload);
    case actionTypes.INVOICE_HISTORY_SNAPSHOTS_ACTION:
      return state.set('snapshotsResult', fromJS(action.payload.data));
    case actionTypes.RESET_INVOICE_HISTORY_SNAPSHOTS_SUCCESS:
      return initialStateInvoice;
    default:
      return state;
  }
}

export default combineReducers({
  invoiceHistoryList: invoiceHistoryReducer,
  invoiceItem: invoiceReducer,
  snapshots: snapshotsReducer,
});
