import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { ClubSettingsPaths } from 'modules/club-settings/constants/constants';

import { Button, UnavailableModule } from 'common/components';
import { ReactComponent as NoEntranceIcon } from 'img/icons/no-entrance.svg';

import messages from 'modules/front-desk/messages/messages';
import commonMessages from 'common/messages/messages';

const EntranceNotCreated = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <UnavailableModule
      lockIcon={<NoEntranceIcon />}
      title={<FormattedMessage {...messages.entranceNotCreatedLabel} />}
      additionalButton={
        <Button
          color="primary"
          variant="contained"
          onClick={() =>
            navigate(
              `/${ClubSettingsPaths.CLUB_SETTINGS}/${ClubSettingsPaths.CLUB_SETTINGS_WORKING_HOURS}`,
            )
          }
        >
          <FormattedMessage {...commonMessages.configureBtn} />
        </Button>
      }
    />
  );
};

export default EntranceNotCreated;
