import { useEffect, useRef } from 'react';

const useFirst = (): boolean => {
  const first = useRef(true);

  useEffect(() => {
    first.current = false;
  }, []);

  return first.current;
};

export default useFirst;
