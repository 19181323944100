import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';

import { initReqStateImt } from 'common/constants/initialState';
import { actionTypes } from 'modules/my-profile/state/profile/constants';

const profileReducer = (state = initReqStateImt, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MY_PROFILE_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_MY_PROFILE_SUCCESS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.CLEAR_MY_PROFILE:
      return initReqStateImt;
    default:
      return state;
  }
};

const profileViewReducer = (state = initReqStateImt, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MY_PROFILE_VIEW_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_MY_PROFILE_VIEW_SUCCESS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.CLEAR_MY_PROFILE:
      return initReqStateImt;
    default:
      return state;
  }
};

const profilePermissionsReducer = (state = initReqStateImt, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MY_PROFILE_PERMISSIONS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_MY_PROFILE_PERMISSIONS_SUCCESS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.CLEAR_MY_PROFILE:
      return initReqStateImt;
    default:
      return state;
  }
};

const profileAttachmentsReducer = (state = initReqStateImt, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MY_PROFILE_ATTACHMENTS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_MY_PROFILE_ATTACHMENTS_SUCCESS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.CLEAR_MY_PROFILE:
      return initReqStateImt;
    default:
      return state;
  }
};

const profileJobPositionsReducer = (state = initReqStateImt, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MY_PROFILE_JOB_POSITIONS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_MY_PROFILE_JOB_POSITIONS_SUCCESS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.CLEAR_MY_PROFILE:
      return initReqStateImt;
    default:
      return state;
  }
};

const profileJobPositionsHistoryReducer = (state = initReqStateImt, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MY_PROFILE_JOB_POSITIONS_HISTORY_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_MY_PROFILE_JOB_POSITIONS_HISTORY_SUCCESS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.RESET_MY_PROFILE_JOB_POSITIONS_HISTORY:
      return initReqStateImt;
    default:
      return state;
  }
};

const profileTimeclockHistoryReducer = (state = initReqStateImt, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MY_PROFILE_TIMECLOCK_HISTORY_SUCCESS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.FETCH_MY_PROFILE_TIMECLOCK_HISTORY_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.CLEAR_MY_PROFILE:
      return initReqStateImt;
    default:
      return state;
  }
};

const profileRecentTimeclockHistoryReducer = (state = initReqStateImt, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MY_PROFILE_RECENT_TIMECLOCK_HISTORY_SUCCESS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.FETCH_MY_PROFILE_RECENT_TIMECLOCK_HISTORY_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.CLEAR_MY_PROFILE:
      return initReqStateImt;
    default:
      return state;
  }
};

export default combineReducers({
  profile: profileReducer,
  profileView: profileViewReducer,
  profilePermissions: profilePermissionsReducer,
  profileAttachments: profileAttachmentsReducer,
  profileJobPositions: profileJobPositionsReducer,
  profileJobPositionsHistory: profileJobPositionsHistoryReducer,
  profileTimeclockHistory: profileTimeclockHistoryReducer,
  profileRecentTimeclockHistory: profileRecentTimeclockHistoryReducer,
});
