import { batch } from 'react-redux';
import { createAction } from '@reduxjs/toolkit';

import { actionTypes } from 'common/state/newPerson/familyMembers/constants';
import {
  IFamilyMember,
  IFamilyMemberCreateDTO,
  IFamilyMemberEditDTO,
  IPrimaryMemberInfo,
} from 'common/components/PersonProfile/interfaces';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { PeakModules } from 'common/constants/peakModules';
import Services from 'services/network';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { ActionResult } from 'common/constants';
import { GeneralThunkAction } from 'common/state/interfaces';
import { CustomerStatus } from 'modules/front-desk/constants/common/constants';

export const setFamilyMembersAction = createAction<IFamilyMember[]>(actionTypes.SET_FAMILY_MEMBERS);
export const resetCreateFamilyMemberResult = createAction<void>(
  actionTypes.RESET_CREATE_FAMILY_MEMBER_RESULT,
);
export const addFamilyMemberActionResult = createAction<ActionResult>(
  actionTypes.ADD_FAMILY_MEMBERS_ACTION_RESULT,
);
const addFamilyMemberActionLoading = createAction<boolean>(
  actionTypes.ADD_FAMILY_MEMBERS_ACTION_LOADING,
);
export const resetAddFamilyMemberActionResult = createAction<ActionResult>(
  actionTypes.RESET_ADD_FAMILY_MEMBERS_ACTION_RESULT,
);

const createMemberForFamilyMemberStepLoadingAction = createAction<boolean>(
  actionTypes.CREATE_MEMBER_FOR_FAMILY_MEMBER_STEP_LOADING,
);

const fetchFamilyMembersLoadingAction = createAction<boolean>(
  actionTypes.FETCH_FAMILY_MEMBERS_LOADING,
);
export const createMemberForFamilyMemberStepResultAction = createAction<IPrimaryMemberInfo>(
  actionTypes.CREATE_MEMBER_FOR_FAMILY_MEMBER_STEP_RESULT,
);
export const createMemberForFamilyMemberStep = (
  data: IPrimaryMemberInfo,
  initialFamilyMemberId: string = null,
  module: PeakModuleForNewPersonType = PeakModules.FrontDesk,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(createMemberForFamilyMemberStepLoadingAction(true));

    try {
      let response: IPrimaryMemberInfo;

      const createMemberDTO = {
        ...data,
        initialFamilyMemberId,
        type: CustomerStatus.Guest,
      };

      if (module === PeakModules.FrontDesk) {
        response = await Services.FrontDesk.createMember(createMemberDTO);
      } else {
        response = await Services.Members.createMember(createMemberDTO);
      }

      dispatch(createMemberForFamilyMemberStepResultAction(response));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(createMemberForFamilyMemberStepLoadingAction(false));
    }
  };
};

export const fetchFamilyMembersThunk = (
  memberId: string,
  module: PeakModuleForNewPersonType = PeakModules.FrontDesk,
): GeneralThunkAction => {
  return async dispatch => {
    try {
      dispatch(fetchFamilyMembersLoadingAction(true));

      let response: IFamilyMember[];

      switch (module) {
        case PeakModules.FrontDesk:
          response = await Services.FrontDesk.getFamilyMembers(memberId);
          break;
        case PeakModules.Members:
          response = await Services.Members.getFamilyMembers(memberId);
          break;
        case PeakModules.Crm:
          response = await Services.Leads.getFamilyMembers(memberId);
          break;
        case PeakModules.PersonalTrainingCrm:
          response = await Services.PTLeads.getFamilyMembers(memberId);
          break;
        default:
      }

      dispatch(setFamilyMembersAction(response));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchFamilyMembersLoadingAction(false));
    }
  };
};

export const addFamilyMember = (
  memberId: string,
  formData: IFamilyMemberCreateDTO,
  module: PeakModuleForNewPersonType = PeakModules.FrontDesk,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(addFamilyMemberActionLoading(true));

    try {
      let response: any;

      if (module === PeakModules.FrontDesk) {
        response = await Services.FrontDesk.addFamilyMember(memberId, formData);
      } else {
        response = await Services.Members.addFamilyMember(memberId, formData);
      }

      dispatch(setFamilyMembersAction(response));
      dispatch(addFamilyMemberActionResult(ActionResult.SUCCESS_ACTION));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(addFamilyMemberActionLoading(false));
    }
  };
};

export const saveFamilyMembers = (
  memberId: string,
  data: IFamilyMemberEditDTO,
  module: PeakModuleForNewPersonType = PeakModules.FrontDesk,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(addFamilyMemberActionLoading(true));

    try {
      let response: IFamilyMember[];

      switch (module) {
        case PeakModules.FrontDesk:
          response = await Services.FrontDesk.saveFamilyMembers(memberId, data);
          break;
        case PeakModules.Crm:
          response = await Services.Leads.saveFamilyMembers(memberId, data);
          break;
        case PeakModules.PersonalTrainingCrm:
          response = await Services.PTLeads.saveFamilyMembers(memberId, data);
          break;
        default:
          response = await Services.Members.saveFamilyMembers(memberId, data);
      }

      batch(() => {
        dispatch(setFamilyMembersAction(response));
        dispatch(addFamilyMemberActionResult(ActionResult.SUCCESS_ACTION));
      });
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(addFamilyMemberActionLoading(false));
    }
  };
};

const createMemberForFamilyMemberLoadingAction = createAction<boolean>(
  actionTypes.CREATE_MEMBER_FOR_FAMILY_MEMBER_LOADING,
);
export const createMemberForFamilyMemberResultAction = createAction<IPrimaryMemberInfo>(
  actionTypes.CREATE_MEMBER_FOR_FAMILY_MEMBER_RESULT,
);
export const createMemberForFamilyMember = (
  data: IPrimaryMemberInfo,
  initialFamilyMemberId: string,
  module: PeakModuleForNewPersonType = PeakModules.FrontDesk,
  isServicesSection?: boolean,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(createMemberForFamilyMemberLoadingAction(true));

    try {
      let response: IPrimaryMemberInfo;

      const createMemberDTO = {
        ...data,
        initialFamilyMemberId,
        type: CustomerStatus.Guest,
      };

      if (isServicesSection) {
        switch (module) {
          case PeakModules.FrontDesk:
            response = await Services.FrontDeskRedeem.createMember(
              createMemberDTO,
              initialFamilyMemberId,
            );
            break;
          default:
            response = await Services.MembersRedeem.createMember(
              createMemberDTO,
              initialFamilyMemberId,
            );
        }
      } else {
        switch (module) {
          case PeakModules.FrontDesk:
            response = await Services.FrontDesk.createMember(createMemberDTO);
            break;
          default:
            response = await Services.Members.createMember(createMemberDTO);
        }
      }

      dispatch(createMemberForFamilyMemberResultAction(response));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(createMemberForFamilyMemberLoadingAction(false));
    }
  };
};
