import { PoliciesPermissionSetItems, PermissionLevels } from 'common/constants/permissions';
import { IEmployeeRole } from 'common/interfaces/employee';
import {
  PermissionGroupsClubLevel,
  PermissionGroupsCorporateLevel,
  permissionsArray,
} from 'modules/permissions/constants';
import { IPermissionItem, IPolicyFormData } from 'modules/permissions/interfaces/permissions';

export const filterPermissionSet = (data: IPolicyFormData): IPolicyFormData => {
  const { permissionSet } = data;

  const filteredPermissionSet = permissionSet.filter(permission =>
    Object.keys(PoliciesPermissionSetItems).includes(permission),
  );

  return {
    ...data,
    permissionSet: filteredPermissionSet,
  };
};

export const getOrderPolicyList = (
  roleItem: IEmployeeRole,
  permissionGroupItem: PermissionGroupsClubLevel | PermissionGroupsCorporateLevel,
): IEmployeeRole => {
  const { securityPolicyDtoSet } = roleItem;

  const orderedPolicyList = Object.keys(permissionGroupItem).map(policy =>
    securityPolicyDtoSet.find(({ permissionGroup }) => permissionGroup === policy),
  );

  return {
    ...roleItem,
    securityPolicyDtoSet: orderedPolicyList,
  };
};

export const getPermissionsFilteredArray = (
  permissionLevel: PermissionLevels,
  filter?: boolean,
  permissionGoups?: Array<string>,
): Array<IPermissionItem> => {
  const permissions = permissionsArray[permissionLevel];
  if (!permissions) return null;
  return filter && permissionGoups
    ? Object.values(permissions)
        .flat()
        .filter((item: IPermissionItem) => permissionGoups.includes(item.key))
    : Object.values(permissions).flat();
};
