import { useState, useEffect } from 'react';

const useSmallScreen = (screenWidth: number): boolean => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [isSmallScreen, setSmallScreen] = useState<boolean>(false);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      const isNewSizeSmall = window.innerWidth < screenWidth;

      if (isSmallScreen !== isNewSizeSmall) {
        setSmallScreen(isNewSizeSmall);
      }
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [isSmallScreen, screenWidth]); // Empty array ensures that effect is only run on mount

  return isSmallScreen;
};

export default useSmallScreen;
