import startCase from 'lodash/startCase';

export const firstLetterToUppercase = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const toSnakeCase = (str: string, toUpperSnakeCase = false): string => {
  const snakeCaseString = str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
  return toUpperSnakeCase ? snakeCaseString.toUpperCase() : snakeCaseString;
};

export function publicCase(value: string): string {
  return startCase(value.toLowerCase());
}
