import { fromJS } from 'immutable';
import { actionTypes } from '../../constants/crmTemplates';
import { combineReducers } from 'redux-immutable';

const initialPackagesListState = fromJS({
  list: [],
  meta: {},
  isLoading: false,
  updateStatusIsLoading: false,
  updateStatusActionResult: null,
});

function documentTemplatesReducer(state = initialPackagesListState, action) {
  switch (action.type) {
    case actionTypes.FETCH_CRM_TEMPLATES_LIST:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta));
    case actionTypes.FETCH_CRM_TEMPLATES_LIST_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_CRM_TEMPLATES_LIST:
      return state.set('list', fromJS([]));
    case actionTypes.SET_UPDATE_CRM_TEMPLATE_STATUS_IS_LOADING:
      return state.set('updateStatusIsLoading', action.payload);
    case actionTypes.UPDATE_CRM_TEMPLATE_STATUS_ACTION_RESULT:
      return state.set('updateStatusActionResult', action.payload);
    case actionTypes.UPDATE_CRM_TEMPLATE_STATUS:
      return state.update('list', list => {
        return list.map(item =>
          item.get('id') === action.payload.id ? item.set('active', action.payload.active) : item,
        );
      });
    default:
      return state;
  }
}

const initialTemplateItemState = fromJS({
  result: {},
  isLoading: false,
  saveTemplateActionResult: null,
  saveTemplateLoading: false,
});

function templateItemReducer(state = initialTemplateItemState, action) {
  switch (action.type) {
    case actionTypes.GET_CRM_TEMPLATE_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.GET_CRM_TEMPLATE:
      return state.set('result', fromJS(action.payload));
    case actionTypes.RESET_CRM_TEMPLATE:
      return state.set('result', fromJS({}));
    case actionTypes.SAVE_CRM_TEMPLATE_LOADING:
      return state.set('saveTemplateLoading', action.payload);
    case actionTypes.SAVE_CRM_TEMPLATE_ACTION_RESULT:
      return state.set('saveTemplateActionResult', action.payload);
    default:
      return state;
  }
}

export default combineReducers({
  templates: documentTemplatesReducer,
  template: templateItemReducer,
});
