export const actionTypes = {
  FETCH_MY_PROFILE_VIEW_SUCCESS: 'MY-PROfiLE/FETCH_MY_PROFILE_VIEW_SUCCESS',
  FETCH_MY_PROFILE_VIEW_LOADING: 'MY-PROfiLE/FETCH_MY_PROFILE_VIEW_LOADING',

  FETCH_MY_PROFILE_SUCCESS: 'MY-PROfiLE/FETCH_PROFILE_SUCCESS',
  FETCH_MY_PROFILE_LOADING: 'MY-PROfiLE/FETCH_PROFILE_LOADING',

  FETCH_MY_PROFILE_PERMISSIONS_SUCCESS: 'MY-PROfiLE/FETCH_PROFILE_PERMISSIONS_SUCCESS',
  FETCH_MY_PROFILE_PERMISSIONS_LOADING: 'MY-PROfiLE/FETCH_PROFILE_PERMISSIONS_LOADING',

  FETCH_MY_PROFILE_ATTACHMENTS_LOADING: 'MY-PROfiLE/FETCH_PROFILE_ATTACHMENTS_LOADING',
  FETCH_MY_PROFILE_ATTACHMENTS_SUCCESS: 'MY-PROfiLE/FETCH_PROFILE_ATTACHMENTS_SUCCESS',

  FETCH_MY_PROFILE_JOB_POSITIONS_LOADING: 'MY-PROfiLE/FETCH_PROFILE_JOB_POSITIONS_LOADING',
  FETCH_MY_PROFILE_JOB_POSITIONS_SUCCESS: 'MY-PROfiLE/FETCH_PROFILE_JOB_POSITIONS_SUCCESS',

  RESET_MY_PROFILE_JOB_POSITIONS_HISTORY: 'MY-PROfiLE/RESET_MY_PROFILE_JOB_POSITIONS_HISTORY',
  FETCH_MY_PROFILE_JOB_POSITIONS_HISTORY_SUCCESS:
    'MY-PROfiLE/FETCH_MY_PROFILE_JOB_POSITIONS_HISTORY_SUCCESS',
  FETCH_MY_PROFILE_JOB_POSITIONS_HISTORY_LOADING:
    'MY-PROfiLE/FETCH_MY_PROFILE_JOB_POSITIONS_HISTORY_LOADING',

  FETCH_MY_PROFILE_TIMECLOCK_HISTORY_SUCCESS:
    'MY-PROfiLE/FETCH_MY_PROFILE_TIMECLOCK_HISTORY_SUCCESS',
  FETCH_MY_PROFILE_TIMECLOCK_HISTORY_LOADING:
    'MY-PROfiLE/FETCH_MY_PROFILE_TIMECLOCK_HISTORY_LOADING',

  FETCH_MY_PROFILE_RECENT_TIMECLOCK_HISTORY_SUCCESS:
    'MY-PROfiLE/FETCH_MY_PROFILE_RECENT_TIMECLOCK_HISTORY_SUCCESS',
  FETCH_MY_PROFILE_RECENT_TIMECLOCK_HISTORY_LOADING:
    'MY-PROfiLE/FETCH_MY_PROFILE_RECENT_TIMECLOCK_HISTORY_LOADING',

  CLEAR_MY_PROFILE: 'MY-PROfiLE/CLEAR_MY_PROFILE',
};
