import { ConstOptions } from 'common/constants/classes';
import inputErrors from 'common/messages/inputErrors';

export enum PasswordError {
  PASSWORD_COMMON_ERROR = 'PASSWORD_COMMON_ERROR',
  PASSWORD_LENGTH_ERROR = 'PASSWORD_LENGTH_ERROR',
  PASSWORD_DIGIT_ERROR = 'PASSWORD_DIGIT_ERROR',
  PASSWORD_LOWERCASE_ERROR = 'PASSWORD_LOWERCASE_ERROR',
  PASSWORD_UPPERCASE_ERROR = 'PASSWORD_UPPERCASE_ERROR',
  PASSWORD_SPECIAL_CHARACTER_ERROR = 'PASSWORD_SPECIAL_CHARACTER_ERROR',
}

export const PasswordErrors = new ConstOptions([
  {
    key: PasswordError.PASSWORD_LENGTH_ERROR,
    value: PasswordError.PASSWORD_LENGTH_ERROR,
    message: inputErrors.passwordLengthError,
  },
  {
    key: PasswordError.PASSWORD_DIGIT_ERROR,
    value: PasswordError.PASSWORD_DIGIT_ERROR,
    message: inputErrors.passwordDigitError,
  },
  {
    key: PasswordError.PASSWORD_LOWERCASE_ERROR,
    value: PasswordError.PASSWORD_LOWERCASE_ERROR,
    message: inputErrors.passwordLowercaseCharacterError,
  },
  {
    key: PasswordError.PASSWORD_UPPERCASE_ERROR,
    value: PasswordError.PASSWORD_UPPERCASE_ERROR,
    message: inputErrors.passwordUppercaseCharacterError,
  },
  {
    key: PasswordError.PASSWORD_SPECIAL_CHARACTER_ERROR,
    value: PasswordError.PASSWORD_SPECIAL_CHARACTER_ERROR,
    message: inputErrors.passwordSpecialCharacterError,
  },
]);
