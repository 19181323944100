import React from 'react';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import inputLabels from 'common/messages/inputLabels';
import { Select } from 'common/components';
import { ActiveInactiveStatus } from 'common/constants';

const EmailFormFields = (): JSX.Element => {
  const { control, errors } = useFormContext();

  return (
    <Grid container spacing={2}>
      <Grid container item spacing={1}>
        <Grid item xs={9}>
          <Controller
            name="name"
            control={control}
            render={({ value, onChange }) => (
              <TextField
                label={<FormattedMessage {...inputLabels.templateName} />}
                variant="outlined"
                value={value}
                onChange={onChange}
                error={!!errors.name}
                helperText={errors.name?.message}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name="status"
            control={control}
            render={({ name, value, onChange, onBlur }) => (
              <Select
                fullWidth
                label={<FormattedMessage {...inputLabels.status} />}
                variant="outlined"
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              >
                {ActiveInactiveStatus.map(item => (
                  <MenuItem key={item.key} value={`${item.value}`}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="from"
          control={control}
          render={({ value, onChange }) => (
            <TextField
              label={<FormattedMessage {...inputLabels.from} />}
              variant="outlined"
              value={value}
              onChange={onChange}
              error={!!errors.from}
              helperText={errors.from?.message}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="fromName"
          control={control}
          render={({ value, onChange }) => (
            <TextField
              label={<FormattedMessage {...inputLabels.fromName} />}
              variant="outlined"
              value={value}
              onChange={onChange}
              error={!!errors.fromName}
              helperText={errors.fromName?.message}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="subject"
          control={control}
          render={({ value, onChange }) => (
            <TextField
              label={<FormattedMessage {...inputLabels.subject} />}
              variant="outlined"
              value={value}
              onChange={onChange}
              error={!!errors.subject}
              helperText={errors.subject?.message}
              fullWidth
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default EmailFormFields;
