import { fromJS } from 'immutable';
import { AnyAction } from 'redux';

import { UPDATE_CURRENTLY_CHECKING_IN } from 'modules/front-desk/constants';
import {
  RESET_MEMBERS,
  GET_MEMBERS,
  GET_MEMBERS_ERROR,
  GET_MEMBERS_LOADING,
  GET_MEMBER_CHECKED_IN,
} from './constants';

const initialState = fromJS({
  members: [],
  error: '',
  loading: false,
});

function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case GET_MEMBERS:
      return state.set('members', fromJS(action.payload)).set('loading', false);
    case RESET_MEMBERS:
      return state.set('members', fromJS([]));
    case UPDATE_CURRENTLY_CHECKING_IN: {
      const index = state
        .get('members')
        .findIndex(item => item.get('id') === action.payload?.personId);
      return index === -1
        ? state
        : state
            .setIn(['members', index, 'checkInType'], action.payload.checkInType)
            .setIn(['members', index, 'checkInTime'], action.payload.checkInTime);
    }
    case GET_MEMBERS_LOADING:
      return state.set('loading', action.payload);
    case GET_MEMBERS_ERROR:
      return state.set('error', action.payload);
    case GET_MEMBER_CHECKED_IN: {
      const index = state
        .get('members')
        .findIndex(item => item.get('id') === action.payload?.personId);

      return index === -1
        ? state
        : state.setIn(
            ['members', index],
            fromJS({ ...action.payload, ...action.payload?.customer }),
          );
    }
    default:
      return state;
  }
}

export default reducer;
