/* eslint @typescript-eslint/no-unused-vars: 0 */
import React, { Component } from 'react';
import { createStyles, StyledComponentProps, withStyles } from '@material-ui/core';
import { List } from 'immutable';
import { FormattedMessage } from 'react-intl';

// Components
import { Connect } from 'common/decorators';
import TableSection, {
  ITableHeaderColumn,
  ITableSectionColumn,
} from 'modules/reports/components/TableSection/TableSection';
// Selectors
import {
  selectInvoicesSummary,
  selectInvoicesSummaryLoading,
} from 'modules/reports/state/salesPOS/selectors';
// Actions
import { fetchInvoicesSummary } from 'modules/reports/state/salesPOS/actions';
// Constants
import { DEFAULT_TABLE_PARAMS } from 'common/constants/table';
// Interfaces
import { IInvoicesSummaryItem, IInvoicesSummaryItemImt } from 'modules/reports/interfaces/salesPOS';
import { DispatchFunction } from 'common/state/interfaces';
import { ITableParams, Order } from 'common/interfaces/table';
// Utils
import { formatPrice } from 'common/utils';
// Messages
import messages from 'modules/reports/messages/messages';
import inputLabels from 'common/messages/inputLabels';

const styles = () => createStyles({});

interface IInvoicesSummaryProps extends StyledComponentProps {
  mainTableParams: ITableParams;
  invoicesSummary?: List<IInvoicesSummaryItemImt>;
  invoicesSummaryLoading?: boolean;

  fetchInvoicesSummaryData?: (params?) => void;
}

@(withStyles(styles) as any)
@(Connect({
  mapStateToProps: () => ({
    invoicesSummary: selectInvoicesSummary(),
    invoicesSummaryLoading: selectInvoicesSummaryLoading(),
  }),
  mapDispatchToProps: (dispatch: DispatchFunction) => ({
    fetchInvoicesSummaryData: (requestOptions?) => dispatch(fetchInvoicesSummary(requestOptions)),
  }),
}) as any)
class InvoicesSummary extends Component<IInvoicesSummaryProps, ITableParams<IInvoicesSummaryItem>> {
  private readonly columnSettings: ITableSectionColumn[];

  private readonly headerSettings: ITableHeaderColumn<IInvoicesSummaryItem>[];

  constructor(props: IInvoicesSummaryProps) {
    super(props);

    this.state = {
      page: 0,
      perPage: 5,
      orderBy: 'amount',
      order: 'desc',
      filters: [],
    };

    this.columnSettings = [
      {
        name: 'type',
        label: <FormattedMessage {...inputLabels.type} />,
        transform: value => `${value[0]}${value?.slice(1).toLowerCase()}`,
      },
      {
        name: 'quantity',
        label: <FormattedMessage {...messages.qty} />,
        align: 'right',
      },
      {
        name: 'percent',
        label: '%',
        align: 'right',
      },
      {
        name: 'amount',
        label: <FormattedMessage {...inputLabels.amount} />,
        align: 'right',
        transform: value => formatPrice(value),
      },
    ];

    this.headerSettings = [
      {
        name: 'type',
        label: <FormattedMessage {...inputLabels.type} />,
        sort: true,
      },
      {
        name: 'quantity',
        label: <FormattedMessage {...messages.qty} />,
        sort: true,
        align: 'right',
      },
      {
        name: 'percent',
        label: '%',
        sort: true,
        align: 'right',
      },
      {
        name: 'amount',
        label: <FormattedMessage {...inputLabels.amount} />,
        align: 'right',
        sort: true,
      },
    ];
  }

  componentDidMount(): void {
    const { fetchInvoicesSummaryData, mainTableParams } = this.props;
    const { page, perPage, orderBy, order } = this.state;

    this.setState({ filters: mainTableParams.filters });
    fetchInvoicesSummaryData({
      ...DEFAULT_TABLE_PARAMS,
      filters: mainTableParams.filters,
      page,
      perPage,
      orderBy,
      order,
    });
  }

  componentDidUpdate(): void {
    const { mainTableParams, fetchInvoicesSummaryData } = this.props;
    const { page, perPage, orderBy, order, filters } = this.state;

    if (JSON.stringify(filters) !== JSON.stringify(mainTableParams.filters)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ filters: mainTableParams.filters });
      fetchInvoicesSummaryData({
        ...DEFAULT_TABLE_PARAMS,
        filters: mainTableParams.filters,
        page,
        perPage,
        orderBy,
        order,
      });
    }
  }

  handleChangeTableProps = (page: number, orderBy, order: Order): void => {
    this.updateTableParams({ ...this.state, page, orderBy, order });
  };

  updateTableParams = (tableParams: ITableParams<IInvoicesSummaryItem>): void => {
    this.setState(tableParams);
    const { fetchInvoicesSummaryData } = this.props;
    fetchInvoicesSummaryData(tableParams);
  };

  render(): JSX.Element {
    const { invoicesSummary, invoicesSummaryLoading } = this.props;
    const { page, perPage, orderBy, order } = this.state;

    return (
      <TableSection<IInvoicesSummaryItem, IInvoicesSummaryItemImt>
        id="invoices-summary-section"
        title={<FormattedMessage {...messages.invoicesSummaryTitle} />}
        columns={this.columnSettings}
        headerColumns={this.headerSettings}
        data={invoicesSummary}
        dataLoading={invoicesSummaryLoading}
        page={page}
        total={invoicesSummary?.size}
        order={order}
        orderBy={orderBy}
        perPage={perPage}
        hidePagination
        onSettingsChanged={this.handleChangeTableProps}
      />
    );
  }
}

export default InvoicesSummary;
