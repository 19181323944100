import axios from 'axios';
import { IApiPackage } from 'modules/day-sheet/interfaces/package';
import ApiService from './ApiService';

export default class PackageService extends ApiService {
  public getServicePackages(): Promise<IApiPackage> {
    return axios.get('/api/v1/commission-rules/packages/service');
  }

  public getMembershipPackages(): Promise<IApiPackage> {
    return axios.get('/api/v1/commission-rules/packages/membership');
  }
}
