import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import { Box, Button, makeStyles, Theme, Typography } from '@material-ui/core';
import { Search, Settings, Add as AddIcon } from '@material-ui/icons';
import { resetKioskModeActionResult, unlockKioskMode } from 'common/state/kioskMode/actions';
import {
  selectUnlockKioskModeActionError,
  selectUnlockKioskModeActionLoading,
  selectUnlockKioskModeActionResult,
} from 'common/state/kioskMode/selectors';

import { NavigationContext } from 'common/createContext/navigationContext';
import { PeakModules } from 'common/constants/peakModules';
import { ActionResult } from 'common/constants';

import useToggleFullScreen from 'common/hooks/useFullScreen';
import { useAppDispatch } from 'store/hooks';

import { FDeskSplitViewHistory } from 'modules/front-desk/interfaces';
import { IUnlockCredentials } from 'modules/authentication/interfaces';
import { IEntranceItemImt } from 'modules/clubs/interfaces';

import { EntranceSelector } from 'modules/front-desk/components/index';
import { UnlockKioskModeModal } from 'common/components';
import { ReactComponent as LockOpenIcon } from 'img/icons/lock-open.svg';
import { ReactComponent as LockClosedIcon } from 'img/icons/lock-closed.svg';
import { ReactComponent as StoreIcon } from 'img/icons/cash-register.svg';

import commonMessages from 'common/messages/messages';
import messages from 'modules/front-desk/messages/messages';
import { FrontDeskId } from 'common/enums/html.enums';
import { selectSelectedRegisterId } from 'modules/pos-kiosk/state/register/selectors';
import useFetchInvoicesList from 'common/httpHooks/useFetchInvoicesList';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.secondary.main,
    height: '32px',

    '& svg': {
      width: '16px',
      height: '16px',
    },
  },
  buttonsWrapper: {
    marginLeft: 'auto',
  },
  buttonItem: {
    paddingLeft: '6px',
    paddingRight: '6px',
    '&:lastof-type': {
      paddingRight: 0,
    },
  },
  headerButtonActive: {
    height: '32px',
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.dark} !important`,
      color: theme.palette.primary.contrastText,
    },
  },
  headerButton: {
    height: '32px',
    color: theme.palette.secondary.main,
  },
}));

interface IFrontDeskHeaderProps {
  historyOpenPanel: string[];
  isOpenEditProfile: boolean;
  toggleAlertSettingsModal: (isOpen: boolean) => void;
  onCloseEditProfilePanel: () => void;
  openManualCheckInPanel: () => void;
  openPOSPanel: () => void;
  selectedEntranceId: string;
  entrances: ImmutableList<IEntranceItemImt>;
  isEntrancesLoading: boolean;
  disablePosBtn: boolean;
  isOpenNewMemberPanel: boolean;
  switchNewMemberPanel: () => void;
}

const FrontDeskHeader = ({
  historyOpenPanel,
  disablePosBtn,
  isOpenEditProfile,
  toggleAlertSettingsModal,
  onCloseEditProfilePanel,
  openManualCheckInPanel,
  openPOSPanel,
  selectedEntranceId,
  entrances,
  isEntrancesLoading,
  isOpenNewMemberPanel,
  switchNewMemberPanel,
}: IFrontDeskHeaderProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const unlockKioskModeActionResult: ActionResult = useSelector(selectUnlockKioskModeActionResult);
  const isUnlockKioskModeActionLoading: boolean = useSelector(selectUnlockKioskModeActionLoading);
  const unlockKioskModeActionError: boolean = useSelector(selectUnlockKioskModeActionError);
  const selectedRegisterId = useSelector(selectSelectedRegisterId);

  const [isUnlockKioskModalOpen, setIsUnlockKioskModalOpen] = useState(false);
  const [isInvoiceListLoading, setIsInvoiceListLoading] = useState(false);

  const fetchInvoicesList = useFetchInvoicesList({
    module: PeakModules.FrontDesk,
    registerId: selectedRegisterId,
    isMembershipStep: false,
    shouldFocus: true,
  });

  const { showNavigation, toggleShowNavigation } = useContext(NavigationContext);

  const { toggleFullScreenMode } = useToggleFullScreen();

  const classes = useStyles();

  useEffect(() => {
    if (unlockKioskModeActionResult === ActionResult.SUCCESS_ACTION) {
      dispatch(resetKioskModeActionResult());

      setIsUnlockKioskModalOpen(false);
      toggleShowNavigation(true);
    }
  }, [dispatch, toggleShowNavigation, unlockKioskModeActionResult]);

  const toggleLockMode = async () => {
    if (showNavigation) {
      toggleShowNavigation(false);
      await toggleFullScreenMode(true);
    } else {
      setIsUnlockKioskModalOpen(true);
    }
  };

  const turnOffLockMode = (data: IUnlockCredentials) => {
    dispatch(unlockKioskMode(data, PeakModules.FrontDesk));
  };

  const onOpenAlertSettings = (): void => {
    toggleAlertSettingsModal(true);
  };

  const handleOpenPosPanel = async () => {
    setIsInvoiceListLoading(true);
    const success = await fetchInvoicesList();
    setIsInvoiceListLoading(false);

    if (!success) {
      return;
    }

    openPOSPanel();
  };

  const onNewMemberCLick = () => {
    if (!isOpenNewMemberPanel) {
      switchNewMemberPanel();
    }
  };

  return (
    <>
      <Box width="100%" display="flex" pr={2}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" pb={2}>
          <Box>
            {!entrances.size && !isEntrancesLoading ? (
              <Typography variant="h2">
                <FormattedMessage {...commonMessages.frontDesk} />
              </Typography>
            ) : (
              <EntranceSelector
                entrancesList={entrances}
                isEntrancesListLoading={isEntrancesLoading}
              />
            )}
          </Box>

          {selectedEntranceId && (
            <Box className={classes.buttonsWrapper} display="flex">
              <Box className={classes.buttonItem}>
                <Button
                  id={FrontDeskId.FrontDeskBtnPos}
                  variant="contained"
                  className={`${classes.root} iconButton--contained ${
                    historyOpenPanel[0] === FDeskSplitViewHistory.POS
                      ? classes.headerButtonActive
                      : classes.headerButton
                  }`}
                  onClick={handleOpenPosPanel}
                  startIcon={<StoreIcon />}
                  disabled={isInvoiceListLoading || disablePosBtn}
                >
                  <FormattedMessage {...commonMessages.pos} />
                </Button>
              </Box>
              <Box className={classes.buttonItem}>
                <Button
                  id={FrontDeskId.FrontDeskBtnLock}
                  className={`${classes.root} iconButton--contained`}
                  variant="contained"
                  onClick={toggleLockMode}
                  startIcon={showNavigation ? <LockOpenIcon /> : <LockClosedIcon />}
                >
                  <FormattedMessage
                    {...(showNavigation ? commonMessages.lockBtn : commonMessages.unlockBtn)}
                  />
                </Button>
              </Box>
              <Box className={classes.buttonItem}>
                <Button
                  id={FrontDeskId.FrontDeskBtnSettings}
                  className={`${classes.root} iconButton--contained`}
                  variant="contained"
                  onClick={onOpenAlertSettings}
                  disabled={!selectedEntranceId}
                >
                  <Settings />
                </Button>
              </Box>
              <Box className={classes.buttonItem}>
                <Button
                  className={`iconButton--contained ${
                    historyOpenPanel[0] === FDeskSplitViewHistory.NEW_MEMBER
                      ? classes.headerButtonActive
                      : classes.headerButton
                  }`}
                  variant="contained"
                  fullWidth
                  startIcon={<AddIcon />}
                  onClick={onNewMemberCLick}
                >
                  <FormattedMessage {...messages.createMemberText} />
                </Button>
              </Box>
              <Box className={classes.buttonItem}>
                <Button
                  className={`iconButton--contained ${
                    historyOpenPanel[0] === FDeskSplitViewHistory.MANUAL_CHECKIN
                      ? classes.headerButtonActive
                      : classes.headerButton
                  }`}
                  variant="contained"
                  onClick={() => {
                    if (isOpenEditProfile) onCloseEditProfilePanel();
                    openManualCheckInPanel();
                  }}
                  startIcon={<Search />}
                  disabled={!selectedEntranceId || isOpenNewMemberPanel}
                >
                  <FormattedMessage {...messages.checkInManuallyText} />
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      <UnlockKioskModeModal
        isLoading={isUnlockKioskModeActionLoading}
        isOpen={isUnlockKioskModalOpen}
        onClose={() => setIsUnlockKioskModalOpen(false)}
        onSubmit={turnOffLockMode}
        hasError={Boolean(unlockKioskModeActionError)}
      />
    </>
  );
};

export default FrontDeskHeader;
