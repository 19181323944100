import { array, boolean, object, string } from 'yup';
import { getRequiredMessage } from 'common/constants/globalConstants';
import {
  getMinMaxNumberSchema,
  minMaxNumberSchema,
} from 'common/validationSchemas/minMaxNumberSchema';
import { FrequencyType } from '../../../constants/packages';
import { FeeType, IFee } from '../../../interfaces/fees';

export const includedFeeTypeSchema = object().shape({
  id: string().required(getRequiredMessage),
  type: string().oneOf(Object.values(FeeType)),
});

export const includedFeeSchema = object().shape({
  id: string().nullable(),
  excludable: boolean().required(getRequiredMessage),
  fee: includedFeeTypeSchema.required(getRequiredMessage),
  totalAmount: getMinMaxNumberSchema(1).required(getRequiredMessage),
  chargeAfterDays: minMaxNumberSchema.required(getRequiredMessage),
  allowSplitting: boolean()
    .nullable()
    .when('fee', {
      is: fee => fee.type === FeeType.OneTime,
      then: boolean().required(getRequiredMessage),
    }),
  paymentSchedule: string()
    .nullable()
    .when(['fee', 'allowSplitting'], {
      is: (fee: IFee, allowSplitting: boolean): boolean =>
        fee.type === FeeType.Regular || (fee.type === FeeType.OneTime && allowSplitting),
      then: string()
        .oneOf(Object.values(FrequencyType))
        .required(getRequiredMessage),
    }),
  paymentsNumber: object()
    .nullable()
    .when(['fee', 'allowSplitting'], {
      is: (fee: IFee, allowSplitting: boolean): boolean =>
        fee.type === FeeType.OneTime && allowSplitting,
      then: minMaxNumberSchema.required(getRequiredMessage),
    }),
});

export const feesSchema = object().shape({
  includedFees: array()
    .of(includedFeeSchema)
    .notRequired(),
});
