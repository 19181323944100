import { createSelector } from 'reselect';

const selectUploadImageDomain = (state: any) => state.get('uploadImage');

export const selectUploadedImage = () =>
  createSelector(selectUploadImageDomain, state => state.get('image'));

export const selectUploadedImageDialogId = () =>
  createSelector(selectUploadImageDomain, state => state.get('dialogId'));

export const selectUploadImageLoading = () =>
  createSelector(selectUploadImageDomain, state => state.get('loading'));

export const selectUploadImageError = () =>
  createSelector(selectUploadImageDomain, state => state.get('error'));
