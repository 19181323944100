import { defineMessages } from 'react-intl';

export default defineMessages({
  completed: {
    id: 'app.modules.crm.tasks.completed',
    defaultMessage: 'Completed',
  },
  notCompleted: {
    id: 'app.modules.crm.tasks.notCompleted',
    defaultMessage: 'Not completed',
  },
  email: {
    id: 'app.modules.crm.tasks.email',
    defaultMessage: 'Email',
  },
  call: {
    id: 'app.modules.crm.tasks.call',
    defaultMessage: 'Call',
  },
  leadProfileCreation: {
    id: 'app.modules.crm.tasks.leadProfileCreation',
    defaultMessage: 'Lead profile creation',
  },
  smsReceived: {
    id: 'app.modules.crm.tasks.smsReceived',
    defaultMessage: 'SMS received',
  },
  emailReceived: {
    id: 'app.modules.crm.tasks.emailReceived',
    defaultMessage: 'Email received',
  },
  appointment: {
    id: 'app.modules.crm.tasks.appointment',
    defaultMessage: 'Appointment',
  },
  reminder: {
    id: 'app.modules.crm.tasks.reminder',
    defaultMessage: 'Reminder',
  },
  assignSalesPersonTitle: {
    id: 'app.modules.crm.tasks.assignSalesPersonTitle',
    defaultMessage: 'Assign Salesperson',
  },
  completeTasksLabel: {
    id: 'app.modules.crm.tasks.completeTasksLabel',
    defaultMessage: 'Complete this tasks?',
  },
});
