import { useCallback } from 'react';

import { useAppDispatch } from 'store/hooks';

import { ISocketMessage } from 'common/components/SocketListener/interfaces';
import { updateSocketInventoriesListAction } from 'modules/pos-kiosk/state/register/actions';
import { IRegisterPosClubItem } from 'modules/pos-settings/interfaces/register';
import { batch } from 'react-redux';
import { updateSocketSuggestedProductsAction } from 'common/components/PersonProfile/state/actions';

export const useInventoriesHandler = (): ((
  msg: ISocketMessage<IRegisterPosClubItem[]>,
) => void) => {
  const dispatch = useAppDispatch();

  return useCallback(
    (msg: ISocketMessage<IRegisterPosClubItem[]>) => {
      const inventories = msg.data as IRegisterPosClubItem[];
      batch(() => {
        dispatch(updateSocketInventoriesListAction(inventories));
        dispatch(updateSocketSuggestedProductsAction(inventories));
      });
    },
    [dispatch],
  );
};
