import { useSelector } from 'react-redux';
import { selectUserSelectedOrganizationId } from 'modules/authentication/state/selectors';
import { useEffect } from 'react';
import { resetInvoiceData } from 'common/state/invoice/actions';
import useComponentDidUpdate from 'common/hooks/useComponentDidUpdate';
import { useAppDispatch } from 'store/hooks';

export const useResetInvoiceData = () => {
  const dispatch = useAppDispatch();
  const selectedOrganizationId = useSelector(selectUserSelectedOrganizationId);

  useEffect(() => {
    return () => {
      dispatch(resetInvoiceData());
    };
  }, [dispatch]);

  useComponentDidUpdate(() => {
    dispatch(resetInvoiceData());
  }, [dispatch, selectedOrganizationId]);
};
