import React, { FC } from 'react';
import { Route, Routes as Switch } from 'react-router-dom';

import {
  Campaigns,
  CRMTemplates,
  EditCampaign,
  EditEmail,
  EditLead,
  EditScript,
  EditSMS,
  LeadProfile,
  Leads,
  MainPage,
  NewCampaign,
  NewLead,
  NewScript,
  Scripts,
  Tasks,
} from 'modules/crm/containers';
import CatchAll from 'routes/CatchAll';
import CustomRoute from 'modules/authentication/containers/CustomRoute/CustomRoute';

import { PermissionLevels } from 'common/constants/permissions';
import usePermissionContext from 'common/hooks/context/usePermissionContext';
import AccessRoute from 'routes/AccessRoute';
import DiscardChangesContextProvider from 'common/components/DiscardChangesContextProvider/DiscardChangesContextProvider';

export enum CRMTemplatesRoutes {
  CRM = 'crm',
  SMS_TEMPLATES = 'templates/sms',
  SMS_TEMPLATES_NEW = 'templates/sms/new',
  SMS_TEMPLATES_EDIT = 'templates/sms/:id',
  EMAIL_TEMPLATES = 'templates/email',
  EMAIL_TEMPLATES_NEW = 'templates/email/new',
  EMAIL_TEMPLATES_EDIT = 'templates/email/:id',
  TASKS = 'tasks',
  SCRIPTS = 'scripts',
  SCRIPTS_NEW = 'scripts/new',
  LEADS = 'leads',
  LEADS_NEW = 'leads/new',
  LEADS_ID = 'leads/:id',
  LEADS_EDIT = 'leads/edit/:id',
  CAMPAIGNS = 'campaigns',
  CAMPAIGNS_NEW = 'campaigns/new',
  CAMPAIGNS_EDIT = 'campaigns/:id/edit',
  SCRIPTS_EDIT = 'scripts/:id/edit',
}

const Routes: FC = () => {
  const {
    CRM_TASK_VIEW,
    CRM_SCRIPT_VIEW,
    CRM_SCRIPT_CREATE,
    CRM_SCRIPT_EDIT,
    CRM_LEAD_VIEW,
    CRM_LEAD_CREATE,
    CRM_LEAD_EDIT,
    CRM_CAMPAIGN_VIEW,
    CRM_CAMPAIGN_CREATE,
    CRM_CAMPAIGN_EDIT,
  } = usePermissionContext();

  return (
    <Switch>
      <Route
        path="/"
        element={
          <CustomRoute secure levels={[PermissionLevels.CLIENT, PermissionLevels.CORPORATE]}>
            <AccessRoute
              hasPermission={CRM_TASK_VIEW || CRM_LEAD_VIEW || CRM_SCRIPT_VIEW || CRM_CAMPAIGN_VIEW}
            >
              <MainPage />
            </AccessRoute>
          </CustomRoute>
        }
      />

      <Route
        path={CRMTemplatesRoutes.TASKS}
        element={
          <CustomRoute secure levels={[PermissionLevels.CLIENT, PermissionLevels.CORPORATE]}>
            <AccessRoute hasPermission={CRM_TASK_VIEW}>
              <Tasks />
            </AccessRoute>
          </CustomRoute>
        }
      />

      <Route
        path={CRMTemplatesRoutes.SCRIPTS}
        element={
          <CustomRoute secure levels={[PermissionLevels.CLIENT, PermissionLevels.CORPORATE]}>
            <AccessRoute hasPermission={CRM_SCRIPT_VIEW}>
              <Scripts />
            </AccessRoute>
          </CustomRoute>
        }
      />

      <Route
        path={CRMTemplatesRoutes.SCRIPTS_NEW}
        element={
          <CustomRoute secure levels={[PermissionLevels.CLIENT, PermissionLevels.CORPORATE]}>
            <AccessRoute hasPermission={CRM_SCRIPT_VIEW && CRM_SCRIPT_CREATE}>
              <NewScript />
            </AccessRoute>
          </CustomRoute>
        }
      />

      <Route
        path={CRMTemplatesRoutes.SCRIPTS_EDIT}
        element={
          <CustomRoute secure levels={[PermissionLevels.CLIENT, PermissionLevels.CORPORATE]}>
            <AccessRoute hasPermission={CRM_SCRIPT_VIEW && CRM_SCRIPT_EDIT}>
              <EditScript />
            </AccessRoute>
          </CustomRoute>
        }
      />

      <Route
        path={CRMTemplatesRoutes.LEADS}
        element={
          <CustomRoute secure levels={[PermissionLevels.CLIENT, PermissionLevels.CORPORATE]}>
            <AccessRoute hasPermission={CRM_LEAD_VIEW}>
              <Leads />
            </AccessRoute>
          </CustomRoute>
        }
      />

      <Route
        path={CRMTemplatesRoutes.LEADS_NEW}
        element={
          <CustomRoute secure levels={[PermissionLevels.CLIENT, PermissionLevels.CORPORATE]}>
            <AccessRoute hasPermission={CRM_LEAD_VIEW && CRM_LEAD_CREATE}>
              <DiscardChangesContextProvider headerIds sidebarIds>
                <NewLead />
              </DiscardChangesContextProvider>
            </AccessRoute>
          </CustomRoute>
        }
      />

      <Route
        path={CRMTemplatesRoutes.LEADS_EDIT}
        element={
          <CustomRoute secure levels={[PermissionLevels.CLIENT, PermissionLevels.CORPORATE]}>
            <AccessRoute hasPermission={CRM_LEAD_VIEW && CRM_LEAD_EDIT}>
              <DiscardChangesContextProvider headerIds sidebarIds>
                <EditLead />
              </DiscardChangesContextProvider>
            </AccessRoute>
          </CustomRoute>
        }
      />

      <Route
        path={CRMTemplatesRoutes.LEADS_ID}
        element={
          <CustomRoute secure levels={[PermissionLevels.CLIENT, PermissionLevels.CORPORATE]}>
            <AccessRoute hasPermission={CRM_LEAD_VIEW}>
              <LeadProfile />
            </AccessRoute>
          </CustomRoute>
        }
      />

      <Route
        path={CRMTemplatesRoutes.CAMPAIGNS}
        element={
          <CustomRoute secure levels={[PermissionLevels.CLIENT, PermissionLevels.CORPORATE]}>
            <AccessRoute hasPermission={CRM_CAMPAIGN_VIEW}>
              <Campaigns />
            </AccessRoute>
          </CustomRoute>
        }
      />

      <Route
        path={CRMTemplatesRoutes.CAMPAIGNS_NEW}
        element={
          <CustomRoute secure levels={[PermissionLevels.CLIENT, PermissionLevels.CORPORATE]}>
            <AccessRoute hasPermission={CRM_CAMPAIGN_VIEW && CRM_CAMPAIGN_CREATE}>
              <NewCampaign />
            </AccessRoute>
          </CustomRoute>
        }
      />

      <Route
        path={CRMTemplatesRoutes.CAMPAIGNS_EDIT}
        element={
          <CustomRoute secure levels={[PermissionLevels.CLIENT, PermissionLevels.CORPORATE]}>
            <AccessRoute hasPermission={CRM_CAMPAIGN_VIEW && CRM_CAMPAIGN_EDIT}>
              <EditCampaign />
            </AccessRoute>
          </CustomRoute>
        }
      />

      <Route
        path={CRMTemplatesRoutes.SMS_TEMPLATES}
        element={
          <CustomRoute secure levels={[PermissionLevels.CLIENT, PermissionLevels.CORPORATE]}>
            <CRMTemplates />
          </CustomRoute>
        }
      />

      <Route
        path={CRMTemplatesRoutes.EMAIL_TEMPLATES}
        element={
          <CustomRoute secure levels={[PermissionLevels.CLIENT, PermissionLevels.CORPORATE]}>
            <CRMTemplates />
          </CustomRoute>
        }
      />

      <Route
        path={CRMTemplatesRoutes.SMS_TEMPLATES_NEW}
        element={
          <CustomRoute secure levels={[PermissionLevels.CLIENT, PermissionLevels.CORPORATE]}>
            <EditSMS />
          </CustomRoute>
        }
      />

      <Route
        path={CRMTemplatesRoutes.EMAIL_TEMPLATES_NEW}
        element={
          <CustomRoute secure levels={[PermissionLevels.CLIENT, PermissionLevels.CORPORATE]}>
            <EditEmail />
          </CustomRoute>
        }
      />

      <Route
        path={CRMTemplatesRoutes.SMS_TEMPLATES_EDIT}
        element={
          <CustomRoute secure levels={[PermissionLevels.CLIENT, PermissionLevels.CORPORATE]}>
            <EditSMS />
          </CustomRoute>
        }
      />

      <Route
        path={CRMTemplatesRoutes.EMAIL_TEMPLATES_EDIT}
        element={
          <CustomRoute secure levels={[PermissionLevels.CLIENT, PermissionLevels.CORPORATE]}>
            <EditEmail />
          </CustomRoute>
        }
      />

      <Route element={<CatchAll />} />
    </Switch>
  );
};

export default Routes;
