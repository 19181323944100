import { IScriptDetailed, IScriptListItem } from 'modules/crm/interfaces/scripts';
import { IMultipleSelectData, ITableParams } from 'common/interfaces/table';
import ApiService from './ApiService';
import axios from 'axios';

interface IPaginatedData {
  meta: {
    page: number;
    perPage: number;
    total: number;
  };
}

export interface IPaginatedScripts extends IPaginatedData {
  data: IScriptListItem[];
}

export default class PtScriptsService extends ApiService {
  public getScripts = (
    requestOptions?: ITableParams<IScriptListItem>,
  ): Promise<IPaginatedScripts> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get(`/api/v1/pt-script`, { params: searchParams });
  };

  public getActiveScripts = (): Promise<IPaginatedScripts> => {
    return axios.get(`/api/v1/pt-script`, { params: { active: true } });
  };

  public getScriptById = (id: string): Promise<IScriptDetailed> =>
    axios.get(`/api/v1/pt-script/${id}`);

  public createScript = (requestData: IScriptDetailed): Promise<IScriptDetailed> =>
    axios.post(`/api/v1/pt-script`, requestData);

  public updateScript = (id: string, requestData: IScriptDetailed): Promise<IScriptDetailed> =>
    axios.put(`/api/v1/pt-script/${id}`, requestData);

  public changeScriptStatus = (
    multipleSelectData: IMultipleSelectData,
    status: boolean,
  ): Promise<void> => {
    const requestUrl = status ? 'activate' : 'deactivate';
    return axios.put(
      `/api/v1/pt-script/${requestUrl}`,
      this.transformMultiselectDataToBulkDTO(multipleSelectData),
    );
  };
}
