export const actionTypes = {
  // Vendors
  FETCH_VENDORS_LOADING: 'FETCH_VENDORS_LOADING',
  FETCH_VENDORS: 'FETCH_VENDORS',

  VENDOR_CHANGE_STATUS_SUCCESS: 'VENDOR_CHANGE_STATUS_SUCCESS',
  VENDOR_CHANGE_STATUS_LOADING: 'VENDOR_CHANGE_STATUS_LOADING',
  RESET_VENDOR_CHANGE_STATUS_RESULT: 'RESET_VENDOR_CHANGE_STATUS_RESULT',
  // Vendors item
  FETCH_VENDOR_BY_ID_LOADING: 'FETCH_VENDOR_BY_ID_LOADING',
  FETCH_VENDOR_BY_ID: 'FETCH_VENDOR_BY_ID',
  RESET_VENDOR_BY_ID: 'RESET_VENDOR_BY_ID',
  UPDATE_VENDOR_LOADING: 'UPDATE_VENDOR_LOADING',
  UPDATE_VENDOR: 'UPDATE_VENDOR',
  CREATE_VENDOR_LOADING: 'CREATE_VENDOR_LOADING',
  CREATE_VENDOR: 'CREATE_VENDOR',
  RESET_UPDATE_VENDOR_RESULT: 'RESET_UPDATE_VENDOR_RESULT',
};
