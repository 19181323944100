/* eslint @typescript-eslint/no-unused-vars: 0 */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import { AdaptiveDatePicker, Alert, Checkbox, DialogComponent } from 'common/components';
import {
  Box,
  createStyles,
  FormControlLabel,
  StyledComponentProps,
  Theme,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { AlertTypes } from 'common/interfaces/alerts';
import { IBilling } from 'common/components/PersonProfile/interfaces';

import messages from 'common/components/PersonProfile/messages';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentAdapter from '@date-io/moment';
import { DEFAULT_DATE_FORMAT } from 'common/constants/dateFormats';

interface IBillingDateChangeDialogProps extends StyledComponentProps {
  isOpen: boolean;

  billing?: IBilling;

  onSubmit: (data: any) => void;
  onCancel: () => void;
}

interface IBillingDateChangeDialogState {
  billingDate: moment.Moment;
  pushOutRenewal: boolean;
  updateFutureDates: boolean;
  collectProtectedAmount: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    dialogContainer: {
      width: '100%',
    },
    calendarContainer: {
      margin: 'auto',
      '& .MuiPickersBasePicker-pickerView': {
        margin: '0 auto',
      },

      [theme.breakpoints.down('sm')]: {
        '& .MuiPickersStaticWrapper-staticWrapperRoot, & .MuiPickersBasePicker-pickerView': {
          minWidth: 280,
          width: '100%',
        },
      },
    },
    changesRecap: {
      marginBottom: theme.spacing(2),
    },
    prevDate: {
      textDecoration: 'line-through',
      marginRight: '10px',
    },
    newDate: {
      color: theme.palette.primary.main,
    },
    alertBlock: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    additionalOptions: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
    },
    checkBoxes: {
      margin: theme.spacing(1, 0),
    },
  });

@(withStyles(styles) as any)
export default class BillingDateChangeDialog extends React.Component<
  IBillingDateChangeDialogProps,
  IBillingDateChangeDialogState
> {
  constructor(props: IBillingDateChangeDialogProps) {
    super(props);

    const { billing } = props;

    this.state = {
      billingDate: billing && billing.paymentDate ? moment(props.billing?.paymentDate) : moment(),
      pushOutRenewal: false,
      updateFutureDates: false,
      collectProtectedAmount: false,
    };
  }

  // lifecycle

  componentDidUpdate(prevProps: Readonly<IBillingDateChangeDialogProps>): void {
    const { billing, isOpen } = this.props;
    if (prevProps.billing !== billing) {
      this.onBillingUpdated(billing);
    }

    if (prevProps.isOpen && !isOpen) {
      this.resetState();
    }
  }

  private resetState = (): void =>
    this.setState({
      billingDate: moment(),
      pushOutRenewal: false,
      updateFutureDates: false,
      collectProtectedAmount: false,
    });

  private onBillingUpdated = (newBilling: IBilling): void => {
    this.setState({
      billingDate: newBilling && newBilling.paymentDate ? moment(newBilling.paymentDate) : moment(),
    });
  };

  // handlers

  private handleBillingDateChanged = (newDate: Date): void =>
    this.setState({ billingDate: moment(newDate) });

  private handlePushOutRenewalClick = (): void =>
    this.setState(prevState => ({ pushOutRenewal: !prevState.pushOutRenewal }));

  private handleUpdateFutureDatesClick = (): void =>
    this.setState(prevState => ({ updateFutureDates: !prevState.updateFutureDates }));

  private handleCollectProtectedAmountClick = (): void =>
    this.setState(prevState => ({ collectProtectedAmount: !prevState.collectProtectedAmount }));

  private handleSubmit = (): void => {
    const { onSubmit } = this.props;
    onSubmit(this.state);
  };

  // renders

  private renderDialogTitle = (): JSX.Element | null => {
    const { billing } = this.props;
    const dateFormat = 'MMM D, YYYY';

    if (!billing) return null;

    // TODO refactor payment schedule
    return (
      <>
        <span>{billing.packageInfo.title}</span>
        <span style={{ fontWeight: 'normal' }}>
          {` $${billing.packageInfo.pricePerBilling.toFixed(2)}/mo`}
          {` (${moment(billing.packageInfo.startDate).format(dateFormat)} -
          ${moment(billing.packageInfo.endDate).format(dateFormat)})`}
        </span>
      </>
    );
  };

  render(): JSX.Element {
    const { isOpen, classes, billing, onCancel } = this.props;
    const { billingDate, collectProtectedAmount, pushOutRenewal, updateFutureDates } = this.state;

    return (
      <DialogComponent
        size="xs"
        isOpen={isOpen}
        onClose={onCancel}
        onSubmit={this.handleSubmit}
        title={this.renderDialogTitle()}
      >
        {!!billing && (
          <Box className={classes?.dialogContainer}>
            <Box className={classes?.calendarContainer}>
              <MuiPickersUtilsProvider utils={MomentAdapter}>
                <AdaptiveDatePicker
                  style={{ margin: 'auto' }}
                  disableToolbar
                  value={billingDate}
                  onChange={this.handleBillingDateChanged}
                  label={
                    <FormattedMessage {...messages.billingScheduleModalNextPaymentDateLabel} />
                  }
                />
              </MuiPickersUtilsProvider>
            </Box>

            <Box className={classes?.changesRecap}>
              <Typography variant="h5" color="textSecondary">
                Next payment date
              </Typography>
              <Box>
                <span className={classes?.prevDate}>
                  {moment(billing.paymentDate).format(DEFAULT_DATE_FORMAT)}
                </span>

                <span className={classes?.newDate}>
                  {moment(billingDate).format(DEFAULT_DATE_FORMAT)}
                </span>
              </Box>
            </Box>

            <Box className={classes?.alertBlock}>
              <Alert
                noIcon
                title={
                  <span style={{ fontWeight: 'normal' }}>
                    <FormattedMessage
                      {...messages.billingScheduleModalDateAdjustmentWarningMessage}
                    />
                  </span>
                }
                severity={AlertTypes.Warning}
              />
            </Box>

            <Box className={classes?.additionalOptions}>
              <FormControlLabel
                className={classes?.checkBoxes}
                control={
                  <Checkbox checked={pushOutRenewal} onClick={this.handlePushOutRenewalClick} />
                }
                label={<FormattedMessage {...messages.billingScheduleModalPushOutRenewalOption} />}
              />

              <FormControlLabel
                className={classes?.checkBoxes}
                control={
                  <Checkbox
                    checked={updateFutureDates}
                    onClick={this.handleUpdateFutureDatesClick}
                  />
                }
                label={
                  <FormattedMessage
                    {...messages.billingScheduleModalUpdateAllFutureBillingsOption}
                  />
                }
              />

              <FormControlLabel
                className={classes?.checkBoxes}
                control={
                  <Checkbox
                    checked={collectProtectedAmount}
                    onClick={this.handleCollectProtectedAmountClick}
                  />
                }
                label={
                  <FormattedMessage
                    {...messages.billingScheduleModalCollectProtectedAmountOption}
                  />
                }
              />
            </Box>
          </Box>
        )}
      </DialogComponent>
    );
  }
}
