import axios from 'axios';
import ApiService from 'services/network/ApiService';

import { ITableParams } from 'common/interfaces/table';
import { IPaginatedData } from 'common/interfaces/pagination';
import {
  IBankProcessorConfiguration,
  IBankRemittanceInfo,
  IBankViewInfoDto,
  IClubBankInfo,
  IClubBillingInfo,
  IClubEntrances,
  IClubFeeLate,
  IClubFeeMonthly,
  IClubFeeRatesData,
  IClubFeeReturn,
  IClubFeeSingle,
  IClubFeeTransaction,
  IClubGeneralInfo,
  IClubGeneralInfoView,
  IClubKidZoneInfo,
  IClubListItem,
  IClubOtherInfo,
  IClubProcessingFeeRates,
  IClubProcessorConfigInfo,
  IClubTaxpayerInfo,
  IEntranceItem,
  IPaymentBankInfo,
} from 'modules/clubs/interfaces';
import { INamedEntity } from 'common/interfaces/common';

export type IPaginatedClubs = IPaginatedData<IClubListItem>;

export default class ClubsService extends ApiService {
  public getClubsList = (requestOptions?: ITableParams): Promise<IPaginatedClubs> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get('/api/v1/clubs', { params: searchParams });
  };

  public createClub = (requestData: IClubGeneralInfo): Promise<IClubGeneralInfo> =>
    axios.post('/api/v1/clubs', requestData);

  public getClubGeneralInfo = (clubId: string): Promise<IClubGeneralInfo> =>
    axios.get(`/api/v1/clubs/${clubId}`);

  public getClubGeneralInfoView = (clubId: string): Promise<IClubGeneralInfoView> =>
    axios.get(`/api/v1/clubs/${clubId}/view`);

  public getClubEntrances = (clubId: string): Promise<IClubEntrances> =>
    axios.get(`/api/v1/clubs/${clubId}/club-entrance`);

  public getClubBankInfo = (clubId: string): Promise<IClubBankInfo> =>
    axios.get(`/api/v1/clubs/${clubId}/bank-info`);

  public getClubBankInfoView = (clubId: string): Promise<IBankViewInfoDto> =>
    axios.get(`/api/v1/clubs/${clubId}/bank-info/view`);

  public getClubBillingInfo = (clubId: string): Promise<IClubBillingInfo> =>
    axios.get(`/api/v1/clubs/${clubId}/billing-info`);

  public getClubProcessorConfigInfo = (clubId: string): Promise<IClubProcessorConfigInfo> =>
    axios.get(`/api/v1/clubs/${clubId}/processor-config`);

  public getClubTaxpayerInfo = (clubId: string): Promise<IClubTaxpayerInfo> =>
    axios.get(`/api/v1/clubs/${clubId}/taxpayer-info`);

  public getClubOtherInfo = (clubId: string): Promise<IClubOtherInfo> =>
    axios.get(`/api/v1/clubs/${clubId}/other-info`);

  public getClubKidZoneSettingsInfo = (clubId: string): Promise<IClubKidZoneInfo> =>
    axios.get(`/api/v1/clubs/${clubId}/kid-zone`);

  public updateClubGeneralInfo = (
    clubId: string,
    requestData: IClubGeneralInfo,
  ): Promise<IClubGeneralInfo> => axios.put(`/api/v1/clubs/${clubId}`, requestData);

  public updateClubEntrances = (
    clubId: string,
    requestData: INamedEntity[],
  ): Promise<INamedEntity[]> => axios.put(`/api/v1/clubs/${clubId}/club-entrance`, requestData);

  public updateClubBankInfo = (
    clubId: string,
    requestData: IClubBankInfo,
  ): Promise<IClubBankInfo> => axios.put(`/api/v1/clubs/${clubId}/bank-info`, requestData);

  public updateClubBillingInfo = (
    clubId: string,
    requestData: IClubBillingInfo,
  ): Promise<IClubBillingInfo> => axios.put(`/api/v1/clubs/${clubId}/billing-info`, requestData);

  public updateClubProcessorConfigInfo = (
    clubId: string,
    requestData: IClubProcessorConfigInfo,
  ): Promise<IClubProcessorConfigInfo> =>
    axios.put(`/api/v1/clubs/${clubId}/processor-config`, requestData);

  public updateClubTaxpayerInfo = (
    clubId: string,
    requestData: IClubTaxpayerInfo,
  ): Promise<IClubTaxpayerInfo> => axios.put(`/api/v1/clubs/${clubId}/taxpayer-info`, requestData);

  public updateClubOtherInfo = (
    clubId: string,
    requestData: IClubOtherInfo,
  ): Promise<IClubOtherInfo> => axios.put(`/api/v1/clubs/${clubId}/other-info`, requestData);

  public updateClubBankRemittanceInfo = (
    clubId: string,
    requestData: IBankRemittanceInfo,
  ): Promise<IBankRemittanceInfo> =>
    axios.put(`/api/v1/clubs/${clubId}/bank-info/remittance-info`, requestData);

  public updateClubCreditCardBankInfo = (
    clubId: string,
    requestData: IPaymentBankInfo,
  ): Promise<IPaymentBankInfo> =>
    axios.put(`/api/v1/clubs/${clubId}/bank-info/credit-card-bank-info`, requestData);

  public updateClubEcheckBankInfo = (
    clubId: string,
    requestData: IPaymentBankInfo,
  ): Promise<IPaymentBankInfo> =>
    axios.put(`/api/v1/clubs/${clubId}/bank-info/echeck-bank-info`, requestData);

  public updateClubBankProcessorConfigInfo = (
    clubId: string,
    requestData: IBankProcessorConfiguration,
  ): Promise<IBankProcessorConfiguration> =>
    axios.put(`/api/v1/clubs/${clubId}/bank-info/processor-config`, requestData);

  public updateClubKidZoneSettingsInfo = (
    clubId: string,
    requestData: IEntranceItem,
  ): Promise<IClubKidZoneInfo> => axios.put(`/api/v1/clubs/${clubId}/kid-zone`, requestData);

  public changeClubKidZoneSettingsStatus = (
    clubId: string,
    isActive: boolean,
  ): Promise<IClubKidZoneInfo> =>
    axios.patch(`/api/v1/clubs/${clubId}/kid-zone/change-status`, { active: isActive });

  public changeClubStatus = (clubId: string, isActive: boolean): Promise<IClubGeneralInfo> =>
    axios.patch(`/api/v1/clubs/${clubId}/change-status`, { active: isActive });

  public getClubFeeProcessingRates = (clubId: string): Promise<IClubProcessingFeeRates> =>
    axios.get(`/api/v1/clubs/${clubId}/fees/fee-processing-info`);

  public updateClubProcessingFeeRates = (
    clubId: string,
    requestData: IClubProcessingFeeRates,
  ): Promise<IClubProcessingFeeRates> =>
    axios.put(`/api/v1/clubs/${clubId}/fees/fee-processing-info`, requestData);

  public getCorporationProcessingFeeRates = (clubId: string): Promise<IClubProcessingFeeRates> =>
    axios.get(`/api/v1/clubs/${clubId}/fees/fee-processing-info/corporation`);

  public getClubFeeSingleRates = (clubId: string): Promise<IClubFeeSingle> =>
    axios.get(`/api/v1/clubs/${clubId}/fees/fee-single-info`);

  public updateClubFeeSingleRates = (
    clubId: string,
    requestData: IClubFeeSingle,
  ): Promise<IClubFeeSingle> =>
    axios.put(`/api/v1/clubs/${clubId}/fees/fee-single-info`, requestData);

  public getCorporationFeeSingleRates = (clubId: string): Promise<IClubFeeSingle> =>
    axios.get(`/api/v1/clubs/${clubId}/fees/fee-single-info/corporation`);

  public getClubFeeLateRates = (clubId: string): Promise<IClubFeeLate> =>
    axios.get(`/api/v1/clubs/${clubId}/fees/fee-late-info`);

  public updateClubFeeLateRates = (
    clubId: string,
    requestData: IClubFeeLate,
  ): Promise<IClubFeeLate> => axios.put(`/api/v1/clubs/${clubId}/fees/fee-late-info`, requestData);

  public getCorporationFeeLateRates = (clubId: string): Promise<IClubFeeLate> =>
    axios.get(`/api/v1/clubs/${clubId}/fees/fee-late-info/corporation`);

  public getClubFeeReturnRates = (clubId: string): Promise<IClubFeeReturn> =>
    axios.get(`/api/v1/clubs/${clubId}/fees/fee-return-info`);

  public updateClubFeeReturnRates = (
    clubId: string,
    requestData: IClubFeeReturn,
  ): Promise<IClubFeeReturn> =>
    axios.put(`/api/v1/clubs/${clubId}/fees/fee-return-info`, requestData);

  public getCorporationFeeReturnRates = (clubId: string): Promise<IClubFeeReturn> =>
    axios.get(`/api/v1/clubs/${clubId}/fees/fee-return-info/corporation`);

  public getClubFeeMonthlyRates = (clubId: string): Promise<IClubFeeMonthly> =>
    axios.get(`/api/v1/clubs/${clubId}/fees/fee-monthly-info`);

  public updateClubFeeMonthlyRates = (
    clubId: string,
    requestData: IClubFeeMonthly,
  ): Promise<IClubFeeMonthly> =>
    axios.put(`/api/v1/clubs/${clubId}/fees/fee-monthly-info`, requestData);

  public getCorporationFeeMonthlyRates = (clubId: string): Promise<IClubFeeMonthly> =>
    axios.get(`/api/v1/clubs/${clubId}/fees/fee-monthly-info/corporation`);

  public getClubFeeTransactionRates = (corporationId: string): Promise<IClubFeeTransaction> =>
    axios.get(`/api/v1/clubs/${corporationId}/fees/fee-transaction-info`);

  public updateClubFeeTransactionRates = (
    corporationId: string,
    requestData: IClubFeeTransaction,
  ): Promise<IClubFeeMonthly> =>
    axios.put(`/api/v1/clubs/${corporationId}/fees/fee-transaction-info`, requestData);

  public getCorporationFeeTransactionRates = (
    corporationId: string,
  ): Promise<IClubFeeTransaction> =>
    axios.get(`/api/v1/clubs/${corporationId}/fees/fee-transaction-info/corporation`);

  public updateClubFeeRates = (
    corporationId: string,
    requestData: IClubFeeRatesData,
  ): Promise<IClubFeeRatesData> => axios.put(`/api/v1/clubs/${corporationId}/fees`, requestData);
}
