import * as yup from 'yup';
import inputErrors from 'common/messages/inputErrors';
import { getRequiredMessage } from 'common/constants/globalConstants';

export const redeemInventorySchema = yup.object().shape({
  club: yup
    .object()
    .nullable()
    .required(getRequiredMessage),
  redeemedNumber: yup.number().required(getRequiredMessage),
  pinCode: yup
    .string()
    .nullable()
    .min(4, () => inputErrors.pinCodeLengthError)
    .required(getRequiredMessage),
});
