import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MuiCheckbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      '& .checkedIcon': {
        fontSize: '18px',
        color: theme.palette.primary.main,
      },
    },
    icon: {
      borderRadius: '2px',
      margin: '2px',
      width: '14px',
      height: '14px',
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#c5c8c9',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
  }),
);

interface IProps extends CheckboxProps {
  icon?: React.ReactNode;
  checkedIcon?: React.ReactNode;
  indeterminateIcon?: React.ReactNode;
  disableRipple?: boolean;
}

const Checkbox = (props: IProps): JSX.Element => {
  const classes = useStyles();
  const { icon, checkedIcon, indeterminateIcon, disableRipple } = props;

  return (
    <MuiCheckbox
      disableRipple={disableRipple}
      color="primary"
      size="small"
      className={classes.root}
      icon={icon || <span className={classes.icon} />}
      indeterminateIcon={indeterminateIcon || <IndeterminateCheckBoxIcon className="checkedIcon" />}
      checkedIcon={checkedIcon || <CheckBoxIcon className="checkedIcon" />}
      inputProps={{ 'aria-label': 'custom checkbox' }}
      {...props}
    />
  );
};

export default Checkbox;
