import { createAction } from '@reduxjs/toolkit';
import { actionTypes } from 'common/state/userRole/constants';
import { GeneralThunkAction } from 'common/state/interfaces';
import AppService from 'services/application/AppService';
import { UserRole } from 'common/interfaces/common';

export const setRole = createAction<UserRole>(actionTypes.USER_ROLE);
export const resetRole = createAction(actionTypes.RESET_USER_ROLE);

export const setUserRole = (role: UserRole): GeneralThunkAction => {
  return async dispatch => {
    dispatch(setRole(role));
    AppService.setUserRole(role);
  };
};

export const resetUserRole = (): GeneralThunkAction => {
  return async dispatch => {
    dispatch(resetRole());
    AppService.clearUserRole();
  };
};
