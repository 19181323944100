import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as UserIcon } from 'img/icons/user.svg';
import { ReactComponent as CheckIcon } from 'img/icons/check.svg';
import { ActionItem, ActionsMenu } from 'common/components';
import commonMessages from 'common/messages/messages';
import messages from 'modules/crm/messages/tasks';

const useStyles = makeStyles({
  icon: {
    width: 16,
    height: 16,
  },
});

interface IProps {
  taskId: string;
  completeBtnDisabled?: boolean;
  onReassign: (taskId: string) => void;
  onComplete: (taskId: string) => void;
  hasEditSalespersonPermission: boolean;
  hasEditStatusPermission: boolean;
}

const TasksActionsMenu = (props: IProps): JSX.Element => {
  const {
    taskId,
    onReassign,
    onComplete,
    completeBtnDisabled,
    hasEditSalespersonPermission,
    hasEditStatusPermission,
  } = props;

  const classes = useStyles();

  const handleReassignClick = () => {
    onReassign(taskId);
  };

  const handleCompleteClick = () => {
    onComplete(taskId);
  };

  if (!hasEditSalespersonPermission && !hasEditStatusPermission) {
    return null;
  }

  return (
    <ActionsMenu horizontal tableActionsMode>
      {hasEditSalespersonPermission ? (
        <ActionItem
          key="1"
          icon={<UserIcon className={classes.icon} />}
          onClick={handleReassignClick}
        >
          <FormattedMessage {...messages.assignSalesPersonTitle} />
        </ActionItem>
      ) : null}

      {hasEditStatusPermission ? (
        <ActionItem
          key="2"
          icon={<CheckIcon className={classes.icon} />}
          onClick={handleCompleteClick}
          disabled={completeBtnDisabled}
        >
          <FormattedMessage {...commonMessages.completeBtn} />
        </ActionItem>
      ) : null}
    </ActionsMenu>
  );
};

export default TasksActionsMenu;
