export const actionTypes = {
  // item
  FETCH_NOTE_CODE_ERROR: 'DICTIONARIES/FETCH_NOTE_CODE_ERROR',
  RESET_NOTE_CODE: 'DICTIONARIES/RESET_NOTE_CODE',
  SET_NOTE_CODE_ACTION_RESULT: 'DICTIONARIES/SET_NOTE_CODE_ACTION_RESULT',
  UPDATE_STATUS_NOTE_CODE: 'DICTIONARIES/UPDATE_STATUS_NOTE_CODE',
  CREATE_OR_UPDATE_NOTE_CODE_IS_LOADING: 'DICTIONARIES/CREATE_OR_UPDATE_NOTE_CODE_IS_LOADING',

  // list
  FETCH_NOTE_CODES_LIST: 'DICTIONARIES/FETCH_NOTE_CODES_LIST',
  FETCH_NOTE_CODES_LIST_IS_LOADING: 'DICTIONARIES/FETCH_NOTE_CODES_LIST_IS_LOADING',
  FETCH_NOTE_CODES_LIST_ERROR: 'DICTIONARIES/FETCH_NOTE_CODES_LIST_ERROR',
  RESET_NOTE_CODES_LIST: 'DICTIONARIES/RESET_NOTE_CODES_LIST',
};
