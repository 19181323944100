export const actionTypes = {
  // item
  FETCH_END_REASON_ERROR: 'DICTIONARIES/FETCH_END_REASON_ERROR',
  RESET_END_REASON: 'DICTIONARIES/RESET_END_REASON',
  SET_END_REASON_ACTION_RESULT: 'DICTIONARIES/SET_END_REASON_ACTION_RESULT',
  UPDATE_STATUS_END_REASON: 'DICTIONARIES/UPDATE_STATUS_END_REASON',
  CREATE_OR_UPDATE_END_REASON_IS_LOADING: 'DICTIONARIES/CREATE_OR_UPDATE_END_REASON_IS_LOADING',

  // list
  FETCH_END_REASONS_LIST: 'DICTIONARIES/FETCH_END_REASONS_LIST',
  FETCH_END_REASONS_LIST_IS_LOADING: 'DICTIONARIES/FETCH_END_REASONS_LIST_IS_LOADING',
  FETCH_END_REASONS_LIST_ERROR: 'DICTIONARIES/FETCH_END_REASONS_LIST_ERROR',
  RESET_END_REASONS_LIST: 'DICTIONARIES/RESET_END_REASONS_LIST',
};
