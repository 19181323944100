import { fromJS } from 'immutable';

import { actionTypes } from 'modules/booking/constants';

const initStateResources = fromJS({
  list: [],
  meta: {
    page: 0,
    perPage: 25,
    total: 0,
  },
  listLoading: false,
  actionDeleteResult: {},
  deleteResourceLoading: false,
});

export const resourcesTableReducer = (state = initStateResources, action) => {
  switch (action.type) {
    case actionTypes.FETCH_RESOURCES_LIST_LOADING:
      return state.set('listLoading', action.payload);
    case actionTypes.FETCH_RESOURCES_LIST_SUCCESS:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta))
        .set('listLoading', false);
    case actionTypes.DELETE_RESOURCE_LOADING:
      return state.set('deleteResourceLoading', action.payload);
    case actionTypes.DELETE_RESOURCE_SUCCESS:
      return state.set('actionDeleteResult', fromJS({ success: true }));
    case actionTypes.RESET_RESOURCE_ACTION_RESULT:
      return state.set('actionDeleteResult', fromJS({}));
    case actionTypes.RESET_RESOURCES_LIST:
      return initStateResources;
    default:
      return state;
  }
};

const initStateResourceCreateForm = fromJS({
  resourceCreating: false,
  createdResource: null,
});

export const createResourceReducer = (state = initStateResourceCreateForm, action) => {
  switch (action.type) {
    case actionTypes.RESET_RESOURCE_FORM:
      return initStateResourceCreateForm;
    case actionTypes.CREATE_RESOURCE_LOADING:
      return state.set('resourceCreating', action.payload);
    case actionTypes.CREATE_RESOURCE_SUCCESS:
      return state.set('createdResource', fromJS(action.payload)).set('resourceCreating', false);
    default:
      return state;
  }
};

const initStateResourceUpdateForm = fromJS({
  resource: {},
  resourceLoading: false,
  updatedResource: null,
  resourceUpdating: false,
});

export const updateResourceReducer = (state = initStateResourceUpdateForm, action) => {
  switch (action.type) {
    case actionTypes.RESET_RESOURCE_FORM:
      return initStateResourceUpdateForm;
    case actionTypes.FETCH_RESOURCE_LOADING:
      return state.set('resourceLoading', action.payload);
    case actionTypes.FETCH_RESOURCE_SUCCESS:
      return state.set('resource', fromJS(action.payload)).set('resourceLoading', false);
    case actionTypes.UPDATE_RESOURCE_LOADING:
      return state.set('resourceUpdating', action.payload);
    case actionTypes.UPDATE_RESOURCE_SUCCESS:
      return state.set('updatedResource', fromJS(action.payload)).set('resourceUpdating', false);
    default:
      return state;
  }
};

const initialChangeStatusState = fromJS({
  result: null,
  isLoading: false,
  error: null,
});

export const resourceChangeStatusReducer = (state = initialChangeStatusState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_RESOURCE_STATUS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.CHANGE_RESOURCE_STATUS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_RESOURCE_CHANGE_STATUS:
      return initialChangeStatusState;
    default:
      return state;
  }
};
