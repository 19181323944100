import React from 'react';
import { Typography, TypographyProps } from '@material-ui/core';

interface IProps extends TypographyProps {
  value?: boolean;
}

const BooleanLabel = ({ value, ...other }: IProps): JSX.Element => {
  return (
    <Typography {...other} style={{ color: value ? '#66cd00' : 'rgba(50, 50, 50, 0.4)' }}>
      {value ? 'Yes' : 'No'}
    </Typography>
  );
};

export default React.memo(BooleanLabel);
