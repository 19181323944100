import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import useTimezoneMoment from 'common/hooks/useTimezoneMoment';

import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';
import { IPageMetaImt } from 'common/interfaces/pagination';

import { IHeadCell, ITableParams, ITableRow, Order } from 'common/interfaces/table';

import { Table } from 'common/components';
import tableHeaders from 'common/messages/tableHeaders';
import commonMessages from 'common/messages/messages';
import { TableOrderByParams } from 'common/constants';
import { KidzoneCheckInHistoryReportItemsImt } from 'modules/reports/interfaces/kidzoneCheckInHistory';

interface IProps {
  checkInHistoryReportItems: KidzoneCheckInHistoryReportItemsImt;
  isCheckInHistoryReportItemsLoading: boolean;
  checkInHistoryReportItemsMeta: IPageMetaImt;
  onChangeTableParams: (tableParams: ITableParams) => void;
  currentPage: number;
  defaultOrderBy: TableOrderByParams;
  defaultOrder: Order;
}

const headerOptions: IHeadCell[] = [
  { id: 'club', label: <FormattedMessage {...tableHeaders.club} />, sort: true },
  { id: 'check_in_date', label: <FormattedMessage {...commonMessages.checkInBtn} />, sort: true },
  { id: 'child', label: <FormattedMessage {...commonMessages.childLabel} />, sort: true },
  { id: 'brought', label: <FormattedMessage {...tableHeaders.dropOff} />, sort: true },
  { id: 'check_out_date', label: <FormattedMessage {...tableHeaders.checkOut} />, sort: true },
  { id: 'picked_up', label: <FormattedMessage {...tableHeaders.pickedUp} />, sort: true },
];

const KidzoneCheckInHistoryReportItemsTable = ({
  checkInHistoryReportItems,
  isCheckInHistoryReportItemsLoading,
  checkInHistoryReportItemsMeta,
  onChangeTableParams,
  currentPage,
  defaultOrderBy,
  defaultOrder,
}: IProps): JSX.Element => {
  const [timezoneMoment] = useTimezoneMoment();

  const handleChangeTableProps = useCallback(
    (tableProps: ITableParams): void => {
      onChangeTableParams(tableProps);
    },
    [onChangeTableParams],
  );

  const rows = useMemo(
    () =>
      checkInHistoryReportItems
        .map(
          (item): ITableRow => ({
            id: item.get('id'),
            cells: [
              {
                label: item.get('club') ? item.getIn(['club', 'title']) : '-',
              },
              {
                label: item.get('checkinDate')
                  ? timezoneMoment(item.get('checkinDate')).format(DEFAULT_DATE_TIME_FORMAT)
                  : '-',
              },
              {
                label: item.get('customer')
                  ? `${item.getIn(['customer', 'firstName'])} ${item.getIn([
                      'customer',
                      'lastName',
                    ])}`
                  : '-',
              },
              {
                label: item.get('broughtCustomer')
                  ? `${item.getIn(['broughtCustomer', 'firstName'])} ${item.getIn([
                      'broughtCustomer',
                      'lastName',
                    ])}`
                  : '-',
              },
              {
                label: item.get('pickedUpDate')
                  ? timezoneMoment(item.get('pickedUpDate')).format(DEFAULT_DATE_TIME_FORMAT)
                  : '-',
              },
              {
                label: item.get('pickedUpCustomer')
                  ? `${item.getIn(['pickedUpCustomer', 'firstName'])} ${item.getIn([
                      'pickedUpCustomer',
                      'lastName',
                    ])}`
                  : '-',
              },
            ],
          }),
        )
        .toJS(),
    [checkInHistoryReportItems, timezoneMoment],
  );

  return (
    <Table
      defaultOrderBy={defaultOrderBy}
      defaultOrder={defaultOrder}
      showPerPageSelect
      rows={rows}
      headerOptions={headerOptions}
      hideSearchInput
      isLoading={isCheckInHistoryReportItemsLoading}
      page={currentPage}
      totalRows={checkInHistoryReportItemsMeta?.get('total')}
      onChangeParams={handleChangeTableProps}
    />
  );
};

export default KidzoneCheckInHistoryReportItemsTable;
