import React, { useEffect, useRef, useState } from 'react';

import { Tooltip, Typography as MaterialTypography, TypographyProps } from '@material-ui/core';
import useResizeObserver from 'use-resize-observer';

interface ITooltipTypographyProps extends TypographyProps {
  /**
   * Indicates that this component should be truncated with an ellipsis if it overflows its container.
   * The `children` attribute will also be added when content overflows to show the full text of the children on hover.
   * @default false
   */
  ellipsized?: boolean;
  className?: string;
}

const TooltipTypography: React.FC<ITooltipTypographyProps> = ({
  ellipsized,
  className,
  children,
  ...tooltipProps
}: ITooltipTypographyProps): JSX.Element => {
  const textElementRef = useRef<HTMLSpanElement>(null);

  const { width } = useResizeObserver<HTMLSpanElement>({ ref: textElementRef });
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    setShowTooltip(
      ellipsized && textElementRef.current.scrollWidth > textElementRef.current.clientWidth,
    );
  }, [ellipsized, width]);

  return (
    <Tooltip title={children} disableHoverListener={!showTooltip}>
      <MaterialTypography
        className={className}
        ref={textElementRef}
        {...tooltipProps}
        noWrap={ellipsized}
      >
        {children}
      </MaterialTypography>
    </Tooltip>
  );
};

export default TooltipTypography;
