import React from 'react';
import { makeStyles, SvgIcon } from '@material-ui/core';

import { ReactComponent as DeleteIcon } from 'img/times.svg';
import { CustomTheme } from 'common/ui/interfaces';

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    fontSize: '1.125rem',
    color: theme.palette.text.primary,
    opacity: 0.5,
    backgroundColor: 'transparent',
  },
}));

const RemoveIcon = (): JSX.Element => {
  const classes = useStyles();
  return (
    <SvgIcon fontSize="small" className={classes.root}>
      <DeleteIcon />
    </SvgIcon>
  );
};

export default RemoveIcon;
