import moment, { MomentZoneOffset } from 'moment-timezone';

export const timezoneNames: Record<string, string> = {
  '-05:00': 'Eastern Time - US & Canada',
  '-06:00': 'Central Time - US & Canada',
  '-07:00': 'Mountain Time - US & Canada',
  '-08:00': 'Pacific Time - US & Canada',
  '-09:00': 'Alaska Time',
  '-10:00': 'Hawaii-Aleutian Time',
  '-04:00': 'Atlantic Time - Puerto Rico & US Virgin Islands',
  '-11:00': 'Samoa Time - American Samoa',
  '+10:00': 'Chamorro Time - Guam & Northern Mariana Islands',
};

export const USTimezones: MomentZoneOffset[] = moment.tz.zonesForCountry('US', true);
