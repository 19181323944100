import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';

import { List as ImmutableList } from 'immutable';
import * as yup from 'yup';
import { Box, debounce, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { yupResolver } from '@hookform/resolvers/yup';

import { AvatarWithInitials, DialogComponent, ListboxComponent } from 'common/components';
import messages from 'common/components/PersonProfile/messages';
import inputLabels from 'common/messages/inputLabels';
import { INamedEntity, INamedEntityImt } from 'common/interfaces/common';
import { getInitials } from 'helpers/common';
import { getRequiredMessage } from 'common/constants/globalConstants';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import inputErrors from 'common/messages/inputErrors';

const validationSchema = yup.object().shape({
  referredBy: yup
    .mixed()
    .notOneOf(['', null], getRequiredMessage)
    .required(() => inputErrors.requiredFieldError),
});

interface IPotentialReferralModalProps {
  isOpen: boolean;
  isLoading: boolean;
  referrals: ImmutableList<INamedEntityImt>;
  getReferralByValue: (value: string) => void;
  clearSearchResults: () => void;
  onSendReferral: (id: string) => void;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  optionName: {
    fontSize: '0.9rem',
    fontWeight: 500,
  },
}));

const PotentialReferralsModal = (props: IPotentialReferralModalProps): JSX.Element => {
  const {
    isOpen,
    isLoading,
    referrals,
    getReferralByValue,
    clearSearchResults,
    onSendReferral,
    onClose,
  } = props;
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const classes = useStyles();
  const renderIntlMessage = useRenderIntlMessage();

  const { handleSubmit, control, errors } = useForm({
    defaultValues: {
      referredBy: null,
    },
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
  });

  const renderUserOption = ({ id, title, imageUrl }: INamedEntity) => {
    const [firstName, lastName] = title.split(/\s(.*)/);

    const initials = getInitials(firstName, lastName);

    return (
      <Box display="flex" key={id}>
        <AvatarWithInitials
          imageUrl={imageUrl}
          width="36px"
          height="36px"
          fontSize="1rem"
          initials={initials}
        />

        <Box display="flex" alignItems="center">
          <Typography className={classes.optionName}>{title}</Typography>
        </Box>
      </Box>
    );
  };

  const onChangeSearch = useMemo(() => {
    return debounce((value: string) => {
      getReferralByValue(value);
    }, 500);
  }, [getReferralByValue]);

  const onSubmit = value => {
    onSendReferral(value.referredBy?.id);
  };

  const ListboxProps = {
    itemSize: 45,
    limitCount: 8,
  };

  return (
    <DialogComponent
      isOpen={isOpen}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
      title={<FormattedMessage {...messages.selectReferral} />}
      size="sm"
    >
      <form>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="referredBy"
            defaultValue=""
            render={({ value, onChange, onBlur }) => {
              return (
                <Autocomplete
                  open={isOpenDropdown}
                  onOpen={() => setIsOpenDropdown(true)}
                  onClose={() => setIsOpenDropdown(false)}
                  onChange={(e, val) => {
                    onChange(val);
                  }}
                  value={value}
                  getOptionLabel={option => (option ? option.title : '')}
                  renderOption={renderUserOption}
                  onInputChange={(e, val) => {
                    onChangeSearch(val || '');
                  }}
                  onFocus={(e: React.BaseSyntheticEvent) => {
                    onChangeSearch(e.target.value || '');
                  }}
                  onBlur={() => {
                    onBlur();
                    clearSearchResults();
                  }}
                  ListboxProps={ListboxProps}
                  ListboxComponent={ListboxComponent}
                  options={referrals.toJS()}
                  loading={isLoading}
                  renderInput={params => {
                    return (
                      <TextField
                        {...params}
                        label={<FormattedMessage {...inputLabels.referral} />}
                        variant="outlined"
                        fullWidth
                        error={!!errors.referredBy}
                        helperText={renderIntlMessage(errors.referredBy?.message)}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'off',
                        }}
                      />
                    );
                  }}
                />
              );
            }}
          />
        </Grid>
      </form>
    </DialogComponent>
  );
};

export default PotentialReferralsModal;
