import * as yup from 'yup';
import { regExp } from 'common/constants/regExp';
import inputErrors from 'common/messages/inputErrors';

const ZIP_CODE = yup
  .string()
  .nullable()
  .matches(regExp.ZIP_CODE, () => inputErrors.invalidZipCode);

export const validators = {
  ZIP_CODE,
};
