import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { transformPackageFromDTO } from 'common/components/MembershipFormView/utils';
import { checkIsGracePeriodAllow } from 'common/components/InvoiceOperating/utils';

import { initialValues } from 'common/components/Steps/TrialMembershipStep/initialValues';

import {
  IMembershipFormNew,
  IMembershipTransformed,
  IPackageConfiguration,
} from 'common/interfaces/membership';

import { ServicePackageValidationSchema } from 'common/components/Steps/TrialMembershipStep/ValidationSchema';
import { DialogComponent } from 'common/components';
import MembershipFormView from 'common/components/MembershipFormView/MembershipFormView';
import QuestionnaireFirstPaymentModal from '../QuestionnaireFirstPaymentModal/QuestionnaireFirstPaymentModal';

import messages from 'common/messages/messages';
import { fromJS } from 'immutable';
import { PeakModules } from 'common/constants/peakModules';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      '& .MuiDialog-paper': {
        maxWidth: '480px',
      },
    },
    formWrapper: {
      padding: theme.spacing(0, 1),
    },
  }),
);

interface IServiceConfigureModalProps {
  configureService: IPackageConfiguration | null;
  onClose: () => void;
  onSubmit: (data: IMembershipTransformed) => void;
  module: PeakModules;
}

const ServiceConfigureModal = ({
  onClose,
  onSubmit,
  configureService,
  module,
}: IServiceConfigureModalProps): JSX.Element => {
  const [configurableService, setConfigurableService] = useState<IMembershipTransformed | null>(
    null,
  );

  const formMethods = useForm<IMembershipFormNew>({
    defaultValues: initialValues,
    resolver: yupResolver(ServicePackageValidationSchema),
    mode: 'onBlur',
    shouldUnregister: false,
  });

  const classes = useStyles();

  const { handleSubmit, reset } = formMethods;

  const isOpen = Boolean(configureService);

  const handleFormSubmit = data => {
    const isPaymentGraceAllow = checkIsGracePeriodAllow(data.packageConfiguration);

    if (isPaymentGraceAllow) {
      setConfigurableService(data);
    } else {
      onSubmit({
        ...data,
        packageConfiguration: {
          ...data.packageConfiguration,
          makeFirstPayment: true,
        },
      });
    }
  };

  const handleSubmitFirstPaymentModal = () => {
    onSubmit({
      ...configurableService,
      packageConfiguration: { ...configurableService.packageConfiguration, makeFirstPayment: true },
    });
    setConfigurableService(null);
  };

  const handleCloseFirstPaymentModal = () => {
    onSubmit({
      ...configurableService,
      packageConfiguration: {
        ...configurableService.packageConfiguration,
        makeFirstPayment: false,
      },
    });
    setConfigurableService(null);
  };

  const resetConfiguration = () => {
    reset({ packageConfiguration: transformPackageFromDTO(configureService) });
  };

  useEffect(() => {
    if (configureService) {
      reset({
        packageConfiguration: transformPackageFromDTO(configureService),
      });
    }
  }, [configureService, reset]);

  return (
    <>
      <DialogComponent
        className={classes.dialog}
        size="xs"
        title={isOpen ? configureService?.title : ''}
        submitBtnTitle={<FormattedMessage {...messages.addBtn} />}
        isOpen={isOpen}
        onSubmit={handleSubmit(handleFormSubmit)}
        onClose={onClose}
        disableFullScreenBehavior
        loading={false}
      >
        <FormProvider {...formMethods}>
          <form id="service-package-form" className={classes.formWrapper} autoComplete="none">
            {!!configureService && (
              <MembershipFormView
                module={module}
                onResetConfiguration={resetConfiguration}
                initialMembershipPackage={fromJS(configureService)}
              />
            )}
          </form>
        </FormProvider>
      </DialogComponent>

      {!!configurableService && (
        <QuestionnaireFirstPaymentModal
          isOpen={!!configurableService}
          onClose={handleCloseFirstPaymentModal}
          onSubmit={handleSubmitFirstPaymentModal}
        />
      )}
    </>
  );
};

export default ServiceConfigureModal;
