import { LocalStorageService } from '../storage/WebStorageService';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import moment from 'moment-timezone';
import { IObject } from 'common/interfaces/common';

class TokenService {
  private static ACCESS_TOKEN_KEY = 'access_token';

  private static REFRESH_TOKEN_KEY = 'refresh_token';

  private localStorageService: LocalStorageService;

  constructor() {
    this.localStorageService = new LocalStorageService({ prefix: 'auth.' });
  }

  public getAccessToken(): string {
    return this.localStorageService.get(TokenService.ACCESS_TOKEN_KEY);
  }

  public setAccessToken(token: string): void {
    this.localStorageService.set(TokenService.ACCESS_TOKEN_KEY, token);
  }

  public getRefreshToken(): string {
    return this.localStorageService.get(TokenService.REFRESH_TOKEN_KEY);
  }

  public setRefreshToken(token: string): void {
    this.localStorageService.set(TokenService.REFRESH_TOKEN_KEY, token);
  }

  public isTokenExpired(token: string): boolean {
    if (!token) return true;

    const tokenData: JwtPayload = jwt_decode(token);
    return moment.unix(tokenData.exp).isBefore(moment());
  }

  public getTokenData(token: string): IObject | null | undefined {
    return jwt_decode(token);
  }

  public clearTokens(): void {
    this.localStorageService.remove(TokenService.ACCESS_TOKEN_KEY);
    this.localStorageService.remove(TokenService.REFRESH_TOKEN_KEY);
  }
}

const service = new TokenService();

export default service;
