import React from 'react';
import { CircularProgress, WithStyles, withStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import { CustomTheme } from '../../ui/interfaces';

const styles = (theme: CustomTheme) =>
  createStyles({
    root: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.action.disabled,
      opacity: 0.3,
    },
    progress: {},
  });

const Loader = withStyles(styles)(({ classes }: WithStyles<typeof styles>) => (
  <div className={classes.root}>
    <CircularProgress color="secondary" className={classes.progress} />
  </div>
));

export default Loader;
