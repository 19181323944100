import { fromJS } from 'immutable';

import { initialState } from 'common/constants/initialState';
import { actionTypes } from 'common/state/kioskMode/constants';

const kioskModeInitialState = fromJS(initialState);

export const kioskModeReducer = (state = kioskModeInitialState, action) => {
  switch (action.type) {
    case actionTypes.UNLOCK_KIOSK_MODE:
      return state.set('result', action.payload);
    case actionTypes.UNLOCK_KIOSK_MODE_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.UNLOCK_KIOSK_MODE_ERROR:
      return state.set('error', action.payload);
    case actionTypes.RESET_UNLOCK_KIOSK_MODE_RESULT:
      return kioskModeInitialState;
    default:
      return state;
  }
};
