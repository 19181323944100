import React from 'react';
import { makeStyles, Menu, Theme, Typography } from '@material-ui/core';
import cx from 'classnames';

import useMenuState from 'common/hooks/useMenuState';

import { PeriodFilterTypeLabels } from 'modules/reports/constants/overviewReport';

import { PeriodFilterType } from 'modules/reports/interfaces/common';

import { ReactComponent as CaretDownIcon } from 'img/icons/carret-down.svg';
import { Button } from 'common/components';

const useStyles = makeStyles((theme: Theme) => ({
  filterButton: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2),

    textTransform: 'none',
    fontSize: '1rem',
  },
  caretDownIcon: {
    width: 12,
    height: 12,
    marginLeft: theme.spacing(0.5),

    '&.inverted': {
      transform: 'rotate(180deg)',
    },
  },
}));

interface IProps {
  period: PeriodFilterType;
  onPeriodChange: (period: PeriodFilterType) => void;
}

const ChartPeriodFilter = ({ onPeriodChange, period }: IProps): JSX.Element => {
  const { menuAnchorEl, handleShowMenu, handleCloseMenu } = useMenuState();

  const classes = useStyles();

  const handleSelectPeriodOption = (option: PeriodFilterType) => {
    onPeriodChange(option);
    handleCloseMenu();
  };

  return (
    <>
      <Menu
        anchorEl={menuAnchorEl}
        open={!!menuAnchorEl}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
      >
        {PeriodFilterTypeLabels.getSelectOptions({
          onClick: handleSelectPeriodOption,
          value: period,
        })}
      </Menu>

      <Button color="primary" onClick={handleShowMenu} className={classes.filterButton}>
        <Typography variant="inherit" component="span">
          {PeriodFilterTypeLabels.translate(period)}
        </Typography>

        <CaretDownIcon className={cx(classes.caretDownIcon, { inverted: !!menuAnchorEl })} />
      </Button>
    </>
  );
};

export default ChartPeriodFilter;
