// libraries
import React, { useState } from 'react';
// components
import { Box, ClickAwayListener, IconButton, makeStyles } from '@material-ui/core';
import { ReactComponent as EditIcon } from 'img/icons/pencil_deprecated.svg';
import { CustomTheme } from 'common/ui/interfaces';

interface IEditableLabelProps {
  labelComponent: JSX.Element;
  inputComponent: JSX.Element;

  onBlur?: () => void;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  editButton: {
    marginLeft: theme.spacing(1),

    '& svg': {
      width: 16,
      height: 16,
    },
  },
}));

const EditableLabel = ({
  labelComponent,
  inputComponent,
  onBlur,
}: IEditableLabelProps): JSX.Element => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const classes = useStyles();

  const handleInputClickAway = () => {
    setIsEditing(false);
    if (onBlur) {
      onBlur();
    }
  };

  if (!isEditing) {
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        maxWidth="95px"
        minWidth="85px"
      >
        {labelComponent}

        <IconButton
          color="primary"
          size="small"
          onClick={() => setIsEditing(true)}
          className={classes.editButton}
        >
          <EditIcon />
        </IconButton>
      </Box>
    );
  }

  return (
    <ClickAwayListener onClickAway={handleInputClickAway}>
      <Box>{inputComponent}</Box>
    </ClickAwayListener>
  );
};

export default EditableLabel;
