import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { IScheduledBillingActivity } from 'modules/reports/interfaces/scheduledBillingsReport';

import { initReqStateImt } from 'common/constants/initialState';
import { actionTypes } from 'modules/reports/constants/scheduledBillingsReport';

const transformBillingsChartData = (data: IScheduledBillingActivity) => {
  return Object.keys(data.activity).map(fieldKey => ({
    date: fieldKey,
    ...data.activity[fieldKey].reduce(
      (acc, item) => ({
        ...acc,
        [item.type]: item.amount,
      }),
      {},
    ),
  }));
};

function totalScheduledBillingsStatisticsReducer(state = initReqStateImt, action) {
  switch (action.type) {
    case actionTypes.FETCH_TOTAL_SCHEDULED_BILLINGS_STATISTICS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.FETCH_TOTAL_SCHEDULED_BILLINGS_STATISTICS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_TOTAL_SCHEDULED_BILLINGS_STATISTICS:
      return initReqStateImt;
    default:
      return state;
  }
}

function scheduledBillingsActivityReportReducer(state = initReqStateImt, action) {
  switch (action.type) {
    case actionTypes.FETCH_SCHEDULED_BILLINGS_ACTIVITY_REPORT:
      return state.set('result', fromJS(transformBillingsChartData(action.payload)));
    case actionTypes.FETCH_SCHEDULED_BILLINGS_ACTIVITY_REPORT_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_SCHEDULED_BILLINGS_ACTIVITY_REPORT:
      return initReqStateImt;
    default:
      return state;
  }
}

const initialStateScheduledBillingsReportItems = fromJS({
  list: [],
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
  isLoading: false,
});

function scheduledBillingsReportItemsReducer(
  state = initialStateScheduledBillingsReportItems,
  action,
) {
  switch (action.type) {
    case actionTypes.FETCH_SCHEDULED_BILLING_REPORT_ITEMS:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta));
    case actionTypes.FETCH_SCHEDULED_BILLING_REPORT_ITEMS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_SCHEDULED_BILLING_REPORT_ITEMS:
      return initialStateScheduledBillingsReportItems;
    default:
      return state;
  }
}

export default combineReducers({
  totalScheduledBillingsStatistics: totalScheduledBillingsStatisticsReducer,
  scheduledBillingsActivityReport: scheduledBillingsActivityReportReducer,
  scheduledBillingsReportItems: scheduledBillingsReportItemsReducer,
});
