import { createSelector } from 'reselect';
import { IInventoryDetailedInfoImt } from 'modules/reports/interfaces/invoiceHistory';
import { AppStoreTypeImt } from 'store';

// InvoiceHistory
const selectInvoiceHistoryDomain = (state: AppStoreTypeImt) =>
  state.getIn(['reports', 'invoiceHistory', 'invoiceHistoryList']);

const invoiceHistoryPath = ['reports', 'invoiceHistory', 'invoiceHistoryList'];

const selectInvoiceHistoryLoading = () =>
  createSelector(selectInvoiceHistoryDomain, state => state.get('isLoading'));

const selectInvoiceHistory = () =>
  createSelector(selectInvoiceHistoryDomain, state => state.get('list'));

// Statistics

const selectInvoiceStatisticsLoading = () =>
  createSelector(selectInvoiceHistoryDomain, state => state.get('statisticsLoading'));

const selectInvoiceStatistics = () =>
  createSelector(selectInvoiceHistoryDomain, state => state.get('statistics'));

const selectInvoiceHistoryMeta = () =>
  createSelector(selectInvoiceHistoryDomain, state => state.get('meta'));
// Void
const selectVoidInvoiceActionLoading = state =>
  state.getIn([...invoiceHistoryPath, 'voidInvoiceAction', 'isLoading']);

const selectVoidInvoiceActionResult = state =>
  state.getIn([...invoiceHistoryPath, 'voidInvoiceAction', 'result']);
// Assign
const selectAssignInvoiceLoading = () =>
  createSelector(selectInvoiceHistoryDomain, state => state.get('assignInvoiceLoading'));

const selectAssignInvoiceResult = () =>
  createSelector(selectInvoiceHistoryDomain, state => state.get('assignInvoiceResult'));
// Export
const selectExportInvoiceLoading = () =>
  createSelector(selectInvoiceHistoryDomain, state => state.get('exportInvoiceHistoryLoading'));

const selectExportInvoiceResult = () =>
  createSelector(selectInvoiceHistoryDomain, state => state.get('exportInvoiceHistoryResult'));

// Invoice Item
const invoiceItemPath = ['reports', 'invoiceHistory', 'invoiceItem'];

const selectInvoiceItemLoading = state => state.getIn([...invoiceItemPath, 'isInvoiceLoading']);
const selectInvoiceItem = state => state.getIn([...invoiceItemPath, 'invoice']);
const selectAdjustInvoiceItemLoading = state =>
  state.getIn([...invoiceItemPath, 'isAdjustInvoiceLoading']);
const selectAdjustInvoiceItemResult = state =>
  state.getIn([...invoiceItemPath, 'adjustInvoiceResult']);
const selectInventoryDetailedInfo = (state: AppStoreTypeImt): IInventoryDetailedInfoImt => {
  return state.getIn([...invoiceItemPath, 'inventoryDetailedInfo']);
};
const selectInventoryDetailedInfoLoading = state =>
  state.getIn([...invoiceItemPath, 'isInventoryDetailedInfoLoading']);
const selectPackageDetailedInfo = state => state.getIn([...invoiceItemPath, 'packageDetailedInfo']);
const selectPackageDetailedInfoLoading = state =>
  state.getIn([...invoiceItemPath, 'isPackageDetailedInfoLoading']);

const selectInvoiceTransactions = state => state.getIn([...invoiceItemPath, 'invoiceTransactions']);

const selectInvoiceTransactionsLoading = state =>
  state.getIn([...invoiceItemPath, 'isInvoiceTransactionsLoading']);

const selectCreatedMember = state => {
  return state.getIn(['reports', 'invoiceHistory', 'invoiceItem', 'createdMember', 'result']);
};

const selectCreateMemberLoading = state => {
  return state.getIn(['reports', 'invoiceHistory', 'invoiceItem', 'createdMember', 'isLoading']);
};

// Snapshots
const selectInvoiceHistorySnapshotsDomain = (state: AppStoreTypeImt) =>
  state.getIn(['reports', 'invoiceHistory', 'snapshots']);

const selectInvoiceHistorySnapshotsLoading = () =>
  createSelector(selectInvoiceHistorySnapshotsDomain, state => state.get('isSnapshotsLoading'));

const selectInvoiceHistorySnapshotsResult = () =>
  createSelector(selectInvoiceHistorySnapshotsDomain, state => state.get('snapshotsResult'));

// invoice html
const selectInvoiceHTMLLoading = state => {
  return state.getIn(['reports', 'invoiceHistory', 'invoiceItem', 'isInvoiceHTMLLoading']);
};

export {
  // InvoiceHistory
  selectInvoiceHistoryLoading,
  selectInvoiceHistory,
  selectInvoiceHistoryMeta,
  selectVoidInvoiceActionLoading,
  selectVoidInvoiceActionResult,
  selectAssignInvoiceLoading,
  selectAssignInvoiceResult,
  // Invoice item
  selectInvoiceItemLoading,
  selectExportInvoiceLoading,
  selectExportInvoiceResult,
  selectInvoiceItem,
  selectCreatedMember,
  selectCreateMemberLoading,
  selectInventoryDetailedInfo,
  selectInventoryDetailedInfoLoading,
  selectPackageDetailedInfo,
  selectPackageDetailedInfoLoading,
  selectInvoiceHTMLLoading,
  // Transactions
  selectInvoiceTransactions,
  selectInvoiceTransactionsLoading,
  // Adjustment
  selectAdjustInvoiceItemLoading,
  selectAdjustInvoiceItemResult,
  // Snapshots
  selectInvoiceHistorySnapshotsLoading,
  selectInvoiceHistorySnapshotsResult,
  // Statistics
  selectInvoiceStatisticsLoading,
  selectInvoiceStatistics,
};
