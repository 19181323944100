import { defineMessages } from 'react-intl';

export default defineMessages({
  editProfileCommonTitle: {
    id: 'modules.frontDesk.editProfile.common.title',
    defaultMessage: 'Edit Profile',
  },
  changePackagePlanCommonTitle: {
    id: 'modules.frontDesk.changePackagePlan.common.title',
    defaultMessage: 'Change bundle plan',
  },
  newMemberButtonNext: {
    id: 'modules.frontDesk.newMember.button.next',
    defaultMessage: 'Next',
  },
  newMemberButtonBack: {
    id: 'modules.frontDesk.newMember.button.back',
    defaultMessage: 'Back',
  },
  newMemberButtonCancel: {
    id: 'modules.frontDesk.newMember.button.cancel',
    defaultMessage: 'Cancel',
  },
  newMemberButtonComplete: {
    id: 'modules.frontDesk.newMember.button.complete',
    defaultMessage: 'Complete',
  },
  entranceNotCreatedLabel: {
    id: 'modules.frontDesk.entranceNotCreated.label',
    defaultMessage: 'Entrance not created yet',
  },

  // Steps
  newMemberPrimaryInfoStepTitle: {
    id: 'modules.frontDesk.newMember.primaryInfo.title',
    defaultMessage: 'Primary Information',
  },
  changeMembershipStepTitle: {
    id: 'modules.frontDesk.member.changePlan.title',
    defaultMessage: 'Change Membership',
  },
  newMemberMembershipStepTitle: {
    id: 'modules.frontDesk.newMember.membership.title',
    defaultMessage: 'Membership',
  },
  newMemberAdditionalInfoStepTitle: {
    id: 'modules.frontDesk.newMember.additionalInfo.title',
    defaultMessage: 'Additional Information',
  },
  newMemberPaymentStepTitle: {
    id: 'modules.frontDesk.newMember.payment.title',
    defaultMessage: 'Payment',
  },
  newMemberFamilyMembersStepTitle: {
    id: 'modules.frontDesk.newMember.familyMembers.title',
    defaultMessage: 'Add Family Members',
  },
  newMemberWaiversStepTitle: {
    id: 'modules.frontDesk.newMember.waivers.title',
    defaultMessage: 'Waivers',
  },
  newMemberSignContractStepTitle: {
    id: 'modules.frontDesk.newMember.signContract.title',
    defaultMessage: 'Sign Contract',
  },
  newMemberScheduleTourStepTitle: {
    id: 'modules.frontDesk.newMember.scheduleTour.title',
    defaultMessage: 'Book a tour',
  },
  // Modals
  noteTitle: {
    id: 'app.modules.frontDesk.modals.note.title',
    defaultMessage: 'Note',
  },
  alertSettingsTitle: {
    id: 'app.modules.frontDesk.modals.alertSettings.title',
    defaultMessage: 'My Alerts Settings',
  },
  // Tabs
  predefinedAlertsTabTitle: {
    id: 'modules.frontDesk.tabs.predefinedAlerts.title',
    defaultMessage: 'Predefined Alerts',
  },
  customAlertsTabTitle: {
    id: 'modules.frontDesk.tabs.customAlerts.title',
    defaultMessage: 'Custom Alerts',
  },
  preferencesTabTitle: {
    id: 'modules.frontDesk.tabs.preferences.title',
    defaultMessage: 'Preferences',
  },
  // labels
  reasonLabel: {
    id: 'app.modules.frontDesk.modals.reason.label',
    defaultMessage: 'Reason',
  },
  daysTillExpiration: {
    id: 'app.modules.frontDesk.modals.daysTillExpiration',
    defaultMessage: 'Days Till Expiration',
  },
  beforeAppointment: {
    id: 'app.modules.frontDesk.modals.beforeAppointment',
    defaultMessage: 'Before Appointment',
  },
  afterAppointment: {
    id: 'app.modules.frontDesk.modals.afterAppointment',
    defaultMessage: 'After Appointment',
  },
  // unsigned waiver modal
  unsignedWaiverModalTitle: {
    id: 'app.modules.frontDesk.sections.profile.alerts.unsignedWaiver.title',
    defaultMessage: 'Waiver Signing',
  },
  unsignedWaiverModalWaiverCodeLabel: {
    id: 'app.modules.frontDesk.sections.profile.alerts.unsignedWaiver.code',
    defaultMessage: 'Waiver Code',
  },
  unsignedWaiverSignNowOption: {
    id: 'app.modules.frontDesk.sections.profile.alerts.unsignedWaiver.signNow',
    defaultMessage: 'Sign now',
  },
  unsignedWaiverSignViaEmailOption: {
    id: 'app.modules.frontDesk.sections.profile.alerts.unsignedWaiver.signViaEmail',
    defaultMessage: 'Sign via email',
  },
  unsignedWaiverSignViaSMSOption: {
    id: 'app.modules.frontDesk.sections.profile.alerts.unsignedWaiver.signViaSms',
    defaultMessage: 'Sign via SMS',
  },
  unsignedWaiverAttachSignedWaiverLabel: {
    id: 'app.modules.frontDesk.sections.profile.alerts.unsignedWaiver.attachWaiver',
    defaultMessage: 'Attach Signed Waiver',
  },
  // memberStatusView
  memberStatusActiveMember: {
    id: 'app.modules.frontDesk.memberStatus.activeMember',
    defaultMessage: 'Active',
  },
  memberStatusGuest: {
    id: 'app.modules.frontDesk.memberStatus.guest',
    defaultMessage: 'Guest',
  },
  memberStatusFrozenMember: {
    id: 'app.modules.frontDesk.memberStatus.frozenMember',
    defaultMessage: 'Frozen',
  },
  memberStatusExpiredMember: {
    id: 'app.modules.frontDesk.memberStatus.expiredMember',
    defaultMessage: 'Expired',
  },
  memberStatusDeletedMember: {
    id: 'app.modules.frontDesk.memberStatus.deletedMember',
    defaultMessage: 'Deleted',
  },
  memberStatusEmpty: {
    id: 'app.modules.frontDesk.memberStatus.Empty',
    defaultMessage: 'Empty',
  },

  memberRecentCheckInsStatusAll: {
    id: 'app.modules.frontDesk.memberRecentCheckIns.status.all',
    defaultMessage: 'All statuses',
  },
  memberRecentCheckInsStatusGuest: {
    id: 'app.modules.frontDesk.memberRecentCheckIns.status.guest',
    defaultMessage: 'Guest',
  },
  memberRecentCheckInsStatusActive: {
    id: 'app.modules.frontDesk.memberRecentCheckIns.status.active',
    defaultMessage: 'Active',
  },
  memberRecentCheckInsStatusFrozen: {
    id: 'app.modules.frontDesk.memberRecentCheckIns.status.frozen',
    defaultMessage: 'Frozen',
  },
  memberRecentCheckInsStatusExpired: {
    id: 'app.modules.frontDesk.memberRecentCheckIns.status.expired',
    defaultMessage: 'Expired',
  },
  memberRecentCheckInsStatusDeleted: {
    id: 'app.modules.frontDesk.memberRecentCheckIns.status.deleted',
    defaultMessage: 'Deleted',
  },

  memberRecentCheckInsAlertStatusAll: {
    id: 'app.modules.frontDesk.memberRecentCheckIns.alertType.all',
    defaultMessage: 'All alerts',
  },
  memberRecentCheckInsAlertStatusWarnings: {
    id: 'app.modules.frontDesk.memberRecentCheckIns.alertType.warnings',
    defaultMessage: 'Warning Alerts',
  },
  memberRecentCheckInsAlertStatusErrors: {
    id: 'app.modules.frontDesk.memberRecentCheckIns.alertType.errors',
    defaultMessage: 'Danger Alerts',
  },
  memberRecentCheckInsAlertStatusInfos: {
    id: 'app.modules.frontDesk.memberRecentCheckIns.alertType.infos',
    defaultMessage: 'Info Alerts',
  },
  noOpenRegisterText: {
    id: 'app.modules.frontDesk.RegisterSelection.noOpenRegisterText',
    defaultMessage: 'No open registers.',
  },
  noActiveRegisterText: {
    id: 'app.modules.frontDesk.RegisterSelection.noActiveRegisterText',
    defaultMessage: 'No active registers, go to the POS settings',
  },
  pageText: {
    id: 'app.modules.frontDesk.RegisterSelection.pageText',
    defaultMessage: 'page',
  },
  checkingInNowText: {
    id: 'app.modules.frontDesk.currentCheckIn.checkingInNowText',
    defaultMessage: 'Checking in now',
  },
  checkInManuallyText: {
    id: 'app.modules.frontDesk.currentCheckIn.checkInManuallyText',
    defaultMessage: 'Check-in Manually',
  },
  noRecentCheckInsText: {
    id: 'app.modules.frontDesk.checkInList.noRecentCheckInsText',
    defaultMessage: 'No recent check-ins',
  },
  createMemberText: {
    id: 'app.modules.frontDesk.manualCheckInPanel.createMemberText',
    defaultMessage: 'Create Guest/Member',
  },
  membersNotFoundText: {
    id: 'app.modules.frontDesk.manualCheckInPanel.membersNotFoundText',
    defaultMessage: 'Members not found',
  },
  typeInCredentialsText: {
    id: 'app.modules.frontDesk.manualCheckInPanel.typeInCredentialsText',
    defaultMessage: 'Type in credentials to search members/guests',
  },
});
