import { fromJS } from 'immutable';
import * as actionTypes from '../../constants/commissionRuleActionTypes';
import { combineReducers } from 'redux-immutable';

const initialCommissionRulesState = fromJS({
  list: [],
  isGetAllLoading: false,
  isPostLoading: false,
  isDeleteLoading: false,
});

export function commissionRulesReducer(state = initialCommissionRulesState, action) {
  switch (action.type) {
    case actionTypes.GET_COMMISSION_RULES: {
      return state.set('list', fromJS(action.payload));
    }
    case actionTypes.GET_COMMISSION_RULES_LOADING: {
      return state.set('isGetAllLoading', action.payload);
    }
    case actionTypes.POST_COMMISSION_RULE_LOADING: {
      return state.set('isPostLoading', action.payload);
    }
    case actionTypes.DELETE_COMMISSION_RULE_LOADING: {
      return state.set('isDeleteLoading', action.payload);
    }
    case actionTypes.REMOVE_COMMISSION_RULE: {
      return state.update('list', list => {
        return list.filter(item => item.get('id') !== action.payload);
      });
    }
    case actionTypes.CLEAR_COMMISSION_RULES: {
      return initialCommissionRulesState;
    }
    default: {
      return state;
    }
  }
}

const initialCommissionRuleState = fromJS({
  data: null,
  isGetOneLoading: false,
  isPutLoading: false,
});

function commissionRuleReducer(state = initialCommissionRuleState, action) {
  switch (action.type) {
    case actionTypes.GET_COMMISSION_RULE: {
      return state.set('data', fromJS(action.payload));
    }
    case actionTypes.GET_COMMISSION_RULE_LOADING: {
      return state.set('isGetOneLoading', action.payload);
    }
    case actionTypes.PUT_COMMISSION_RULE_LOADING: {
      return state.set('isPutLoading', action.payload);
    }
    case actionTypes.CLEAR_COMMISSION_RULE: {
      return initialCommissionRuleState;
    }
    default: {
      return state;
    }
  }
}

const initialCommissionRulesHistoryState = fromJS({
  list: [],
  isLoading: false,
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
});

export function commissionRulesHistoryReducer(state = initialCommissionRulesHistoryState, action) {
  switch (action.type) {
    case actionTypes.GET_COMMISSION_RULES_HISTORY: {
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta));
    }
    case actionTypes.GET_COMMISSION_RULES_HISTORY_LOADING: {
      return state.set('isLoading', action.payload);
    }
    case actionTypes.CLEAR_COMMISSION_RULES_HISTORY: {
      return initialCommissionRulesHistoryState;
    }
    default: {
      return state;
    }
  }
}

export default combineReducers({
  rules: commissionRulesReducer,
  rule: commissionRuleReducer,
  history: commissionRulesHistoryReducer,
});
