import { createAction } from '@reduxjs/toolkit';
import { actionTypes } from './constants';
import Services from 'services/network';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { GeneralThunkAction } from 'common/state/interfaces';
import { ITableParams } from 'common/interfaces/table';
import { IPaginatedData } from 'common/interfaces/pagination';
import { IPayment } from 'common/interfaces/service';
import { PeakModules } from 'common/constants/peakModules';
import { IInvoiceDetailsDto } from 'common/interfaces/invoices';
import { ActionResult } from 'common/constants';

const fetchPersonPaymentsAction = createAction<IPaginatedData<IPayment>>(
  actionTypes.FETCH_PERSON_PAYMENTS,
);
const fetchPersonPaymentsLoadingAction = createAction<boolean>(
  actionTypes.FETCH_PERSON_PAYMENTS_LOADING,
);

export const fetchPersonPayments = (tableParams: ITableParams<IPayment>): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPersonPaymentsLoadingAction(true));
    try {
      const payments = await Services.MemberPortalProfile.getPersonPayments(tableParams);

      dispatch(fetchPersonPaymentsAction(payments));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPersonPaymentsLoadingAction(false));
    }
  };
};

export const voidPersonPaymentActionResult = createAction<ActionResult | null>(
  actionTypes.VOID_PERSON_INVOICE_ACTION_RESULT,
);
const voidPersonPaymentLoading = createAction<boolean>(actionTypes.VOID_PERSON_INVOICE_LOADING);

export const voidPersonInvoice = (
  personId: string,
  invoiceId: string,
  reason: string,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(voidPersonPaymentLoading(true));
    try {
      switch (module) {
        case PeakModules.FrontDesk:
          await Services.FrontDesk.voidPersonInvoice(personId, invoiceId, reason);
          break;
        default:
          await Services.Members.voidPersonInvoice(personId, invoiceId, reason);
      }

      dispatch(fetchRecentPersonPayments());

      dispatch(voidPersonPaymentActionResult(ActionResult.SUCCESS_ACTION));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(voidPersonPaymentLoading(false));
    }
  };
};

const fetchRecentPersonPaymentsAction = createAction<IPayment[]>(
  actionTypes.FETCH_RECENT_PERSON_PAYMENTS,
);
const fetchRecentPersonPaymentsLoadingAction = createAction<boolean>(
  actionTypes.FETCH_RECENT_PERSON_PAYMENTS_LOADING,
);

export const fetchRecentPersonPayments = (): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchRecentPersonPaymentsLoadingAction(true));

    try {
      const payments = await Services.MemberPortalProfile.getRecentPersonPayments();

      dispatch(fetchRecentPersonPaymentsAction(payments));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchRecentPersonPaymentsLoadingAction(false));
    }
  };
};

const fetchPaymentDetailsAction = createAction<IInvoiceDetailsDto>(
  actionTypes.FETCH_PAYMENT_DETAILS,
);
const fetchPaymentDetailsLoadingAction = createAction<boolean>(
  actionTypes.FETCH_PAYMENT_DETAILS_LOADING,
);

export const fetchPaymentDetails = (invoiceId: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPaymentDetailsLoadingAction(true));

    try {
      const paymentDetails = await Services.MemberPortalProfile.getPaymentDetails(invoiceId);

      dispatch(fetchPaymentDetailsAction(paymentDetails));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPaymentDetailsLoadingAction(false));
    }
  };
};
