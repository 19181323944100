import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { actionTypes } from '../constants';
import { initialState } from 'common/constants/initialState';
import profileReducer from './peakProfile/reducers';

const initStatePeakEmployees = fromJS({
  list: [],
  meta: {},
  listLoading: false,
  changeStatusRequestState: initialState,
});

function employeesReducer(state = initStatePeakEmployees, action) {
  switch (action.type) {
    case actionTypes.FETCH_PEAK_EMPLOYEES_LOADING:
      return state.set('listLoading', action.payload);
    case actionTypes.FETCH_PEAK_EMPLOYEES:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta));
    case actionTypes.PEAK_EMPLOYEE_CHANGE_STATUS_LOADING:
      return state.setIn(['changeStatusRequestState', 'isLoading'], action.payload);
    case actionTypes.PEAK_EMPLOYEE_CHANGE_STATUS:
      return state.setIn(['changeStatusRequestState', 'result'], fromJS(action.payload));
    case actionTypes.RESET_PEAK_EMPLOYEE_CHANGE_STATUS_RESULT:
      return state.set('changeStatusRequestState', fromJS(initialState));
    default:
      return state;
  }
}

const initStateEmployee = fromJS({
  employee: {},
  isEmployeeLoading: false,
  employeeActionsState: initialState,
});

function updateEmployeeReducer(state = initStateEmployee, action) {
  switch (action.type) {
    case actionTypes.RESET_PEAK_EMPLOYEE_FORM:
      return initStateEmployee;
    case actionTypes.FETCH_PEAK_EMPLOYEE_LOADING:
      return state.set('isEmployeeLoading', action.payload);
    case actionTypes.FETCH_PEAK_EMPLOYEE:
      return state.set('employee', fromJS(action.payload));
    case actionTypes.UPDATE_PEAK_EMPLOYEE_LOADING:
    case actionTypes.CREATE_PEAK_EMPLOYEE_LOADING:
      return state.setIn(['employeeActionsState', 'isLoading'], action.payload);
    case actionTypes.UPDATE_PEAK_EMPLOYEE_RESULT:
    case actionTypes.CREATE_PEAK_EMPLOYEE_RESULT:
      return state.setIn(['employeeActionsState', 'result'], fromJS(action.payload));
    case actionTypes.RESET_UPDATE_PEAK_EMPLOYEE_RESULT:
    case actionTypes.RESET_CREATE_PEAK_EMPLOYEE_RESULT:
      return state.setIn(['employeeActionsState', 'result'], fromJS(initialState));
    default:
      return state;
  }
}

export default combineReducers({
  peakEmployeeProfile: profileReducer,
  employees: employeesReducer,
  employeeItem: updateEmployeeReducer,
});
