import { actionTypes } from './constants';
import { initReqStateImt } from 'common/constants/initialState';

function peakContactsReducer(state = initReqStateImt, action) {
  switch (action.type) {
    case actionTypes.ADD_TICKET_SUCCESS:
      return state.set('result', action.payload);
    case actionTypes.ADD_TICKET_LOADING:
      return state.set('isLoading', action.payload);
    default:
      return state;
  }
}

export default peakContactsReducer;
