import React from 'react';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CustomTheme } from 'common/ui/interfaces';

interface IStatusProps {
  color: string;
  label: React.ReactNode;
}

const useStyles = makeStyles<CustomTheme, IStatusProps>(theme => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    height: '1rem',
    color: theme.palette.info.contrastText,
    borderRadius: 3,
    fontWeight: 'bold',
    border: 'none',
  },
  label: {
    padding: theme.spacing(0, 0.5),
    fontSize: '0.6875rem',
    whiteSpace: 'normal',
    textTransform: 'uppercase',
  },
}));

const Status = (props: IStatusProps): JSX.Element => {
  const classes = useStyles(props);
  const { label, color } = props;

  return (
    <Chip
      classes={classes}
      variant="outlined"
      size="small"
      label={label}
      style={{ backgroundColor: color }}
    />
  );
};

export default Status;
