import axios from 'axios';
import ApiService from 'services/network/ApiService';

import { IAlertConfigurations, IAlertSettings, IPreference } from 'common/interfaces/alerts';
import { ISuccessResponse } from 'common/interfaces/http';

export default class AlertsSettingsService extends ApiService {
  public getAlertsList = (): Promise<IAlertConfigurations> => {
    return axios.get('/api/v1/alert-configurations');
  };

  public createAlert = (requestData: IAlertSettings): Promise<IAlertSettings> => {
    return axios.post('/api/v1/alert-configurations/alert-settings', requestData);
  };

  public updateAlertSettings = (
    alertId: string,
    requestData: IAlertSettings,
  ): Promise<IAlertSettings> => {
    return axios.put(`/api/v1/alert-configurations/alert-settings/${alertId}`, requestData);
  };

  public updatePreference = (requestData: Partial<IPreference>): Promise<Partial<IPreference>> => {
    return axios.put('/api/v1/alert-configurations/alert-preferences', requestData);
  };

  public deleteAlert = (alertId: string): Promise<ISuccessResponse> => {
    return axios.delete(`/api/v1/alert-configurations/alert-settings/${alertId}`);
  };
}
