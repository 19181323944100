import { useEffect, useState } from 'react';

import Services from 'services/network';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { IEditPackageDetails } from 'modules/services/interfaces/packages';
import { useAppDispatch } from 'store/hooks';

interface IFetchPackageState {
  isLoading: boolean;
  data: Partial<IEditPackageDetails>;
}

export const useFetchPackage = (packageId: string): IFetchPackageState => {
  const dispatch = useAppDispatch();

  const [state, setState] = useState<IFetchPackageState>({
    isLoading: false,
    data: {},
  });

  useEffect(() => {
    (async () => {
      try {
        setState(prevState => ({ ...prevState, isLoading: true }));
        const response = await Services.ServicesList.getPackageById(packageId);

        if (response) {
          setState(prevState => ({ ...prevState, data: response }));
        }
      } catch (error) {
        dispatch(enqueueErrorNotification(error));
      } finally {
        setState(prevState => ({ ...prevState, isLoading: false }));
      }
    })();
  }, [setState, packageId, dispatch]);

  return state;
};
