import React from 'react';
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps,
  createFilterOptions,
} from '@material-ui/lab';
import { Box, makeStyles } from '@material-ui/core';

import { ITag } from '../PersonProfile/interfaces';
import { CustomTheme } from 'common/ui/interfaces';

interface IProps extends AutocompleteProps<any, any, any, any> {
  className?: string;
  onChange?: (e: any, arrTags: Array<Partial<ITag>>) => void;
  optionLabelProp?: 'name' | 'title';
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  wrapper: {
    position: 'relative',
    width: '100%',

    '& .MuiAutocomplete-popper': {
      background: '#fff',
      '& .MuiAutocomplete-paper': {
        margin: 0,
      },
    },

    '& .MuiAutocomplete-tag': {
      backgroundColor: theme.palette.text.secondary,
      color: theme.palette.primary.contrastText,
    },
  },
}));

const CustomAutocomplete = ({
  multiple,
  id,
  options,
  value,
  renderInput,
  onBlur,
  onChange,
  className,
  optionLabelProp,
}: IProps): JSX.Element => {
  const classes = useStyles();

  const filter = createFilterOptions();

  return (
    <Box className={classes.wrapper}>
      <MuiAutocomplete
        className={className}
        multiple={multiple}
        id={id}
        options={options}
        getOptionLabel={option => option[optionLabelProp]}
        value={value}
        renderInput={renderInput}
        onBlur={onBlur}
        onChange={(e, newValue) => {
          onChange(
            e,
            newValue.map(tag => {
              if (typeof tag === 'string') return { [optionLabelProp]: tag };
              if (tag.inputValue) return { [optionLabelProp]: tag.inputValue };
              return tag;
            }),
          );
        }}
        fullWidth
        disablePortal
        disableCloseOnSelect
        filterOptions={(autocompleteOptions, params) => {
          const filtered = filter(autocompleteOptions, params);

          // Suggest the creation of a new value
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              [optionLabelProp]: `Add "${params.inputValue}"`,
            });
          }
          return filtered;
        }}
        freeSolo
      />
    </Box>
  );
};

CustomAutocomplete.defaultProps = {
  optionLabelProp: 'name',
};

export default CustomAutocomplete;
