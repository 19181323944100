import React, { useMemo } from 'react';
import cx from 'classnames';
import { makeStyles, Box, Grid, Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
// components
import InvoiceTotals from '../InvoiceTotals/InvoiceTotals';
import { ButtonWithCircularProgress } from 'common/components';
import { CustomTheme } from 'common/ui/interfaces';
// state
import * as selectors from 'common/state/invoice/selectors';
import * as actions from 'common/state/invoice/actions';
import { selectPosKioskRegisterNOSAmount } from 'modules/pos-kiosk/state/register/selectors';
import { useSearchInvoiceProductsState } from 'common/createContext/searchInvoiceProductsContext';
// hooks
import { useAppDispatch } from 'store/hooks';
// messages
import messages from 'common/messages/messages';
import { IInvoiceDetailsImt } from 'common/interfaces/invoices';
import { PeakModules } from 'common/constants/peakModules';
import { Message } from 'common/interfaces/common';
import { priceRounding } from 'common/utils';
import { AS_PAID_INVOICE_PAYMENT_SPLIT_STATUS } from '../constants';

const useStyles = makeStyles((theme: CustomTheme) => ({
  footerDivider: {
    borderTop: `1px solid ${theme.palette.borderColor?.light}`,
  },
  footer: {
    flex: '1 1 auto',
    paddingTop: theme.spacing(3),
    margin: theme.spacing(0, 2, 2, 2),
  },
  actionButton: {
    width: '100%',
    padding: theme.spacing(1),
  },
}));

interface IInvoiceFooterProps {
  invoice: IInvoiceDetailsImt;
  clubId: string;
  handleCancelInvoice: () => void;
  handleVoidInvoice: () => void;
  handleAddMember: () => void;
  handleInvoiceActionClick: () => void;
  module: PeakModules;
  isPaymentStep: boolean;
  memberId: string;
}

export const InvoiceFooter = ({
  invoice,
  handleInvoiceActionClick,
  handleAddMember,
  handleCancelInvoice,
  handleVoidInvoice,
  module,
  memberId,
  clubId,
  isPaymentStep,
}: IInvoiceFooterProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  // selectors

  const loadingCheckOut: boolean = useSelector(selectors.selectUpdatedInvoiceLoading);
  const registerNosValue = useSelector(selectPosKioskRegisterNOSAmount);

  // constants

  const {
    toggleShowAddInventories,
    showSearchProducts: { isShowAddInventories, isShowAddServicePackages, isShowAddGiftCards },
  } = useSearchInvoiceProductsState();

  const convertedInvoice = useMemo(() => invoice?.toJS(), [invoice]);

  const totalInvoicePayments =
    convertedInvoice?.invoicePaymentSplits
      ?.filter(p => AS_PAID_INVOICE_PAYMENT_SPLIT_STATUS.includes(p.status))
      .reduce((acc, value) => {
        return acc + Number(value.paymentAmount);
      }, 0) || 0;

  const leftToPay = priceRounding(convertedInvoice.totalAmount - totalInvoicePayments);

  const isSearchProductsActive =
    isShowAddInventories || isShowAddServicePackages || isShowAddGiftCards;

  const isEmptyCart = !convertedInvoice.invoiceUnits?.length;

  const isAmountExceedNOS = convertedInvoice?.totalAmount > registerNosValue;
  const hasNosError = isAmountExceedNOS && !convertedInvoice?.customer;
  const shouldVisibleAddPaymentBtnCondition = !isAmountExceedNOS;
  const atLeastOneIsNotEmpty = convertedInvoice.invoiceUnits?.length;
  const shouldVisibleProceedBtn = atLeastOneIsNotEmpty && !convertedInvoice?.totalAmount;

  const hasServiceError =
    !convertedInvoice?.customer && atLeastOneIsNotEmpty && !shouldVisibleAddPaymentBtnCondition;
  const hasSalesPersonError = !convertedInvoice?.salesperson;
  const hasPaymentMethods = !!convertedInvoice?.invoicePaymentSplits?.length;

  const isAddPaymentActionDisabled = isEmptyCart || hasNosError || hasSalesPersonError;

  // handlers

  const handleBackToCartClick = () => {
    toggleShowAddInventories(false);
  };

  const handleProceed = () => {
    dispatch(
      actions.proceedFreeInvoice(convertedInvoice.id, module, clubId, memberId, isPaymentStep),
    );
  };

  const getSubmitBtnTitle = (): Message => {
    if (hasServiceError) {
      return messages.newMemberCommonTitle;
    }
    if (shouldVisibleProceedBtn) {
      return messages.proceedBtn;
    }
    return messages.addPayMethod;
  };

  const getSubmitBtnHandler = (): (() => void) => {
    if (hasServiceError) {
      return handleAddMember;
    }
    if (shouldVisibleProceedBtn) {
      return handleProceed;
    }
    return handleInvoiceActionClick;
  };

  // renders

  return (
    <Box
      className={cx(classes.footer, {
        [classes.footerDivider]: !isEmptyCart && !isSearchProductsActive,
      })}
    >
      {!isEmptyCart && !isSearchProductsActive ? (
        <InvoiceTotals
          payments={convertedInvoice?.invoicePaymentSplits}
          total={convertedInvoice.totalAmount}
          subtotal={convertedInvoice.subtotalAmount}
          tax={convertedInvoice.taxAmount}
          discount={convertedInvoice?.discount}
          leftToPay={leftToPay}
        />
      ) : null}

      <Box pt={2}>
        <Grid container spacing={1}>
          {isSearchProductsActive ? (
            <Grid item xs={12}>
              <Button
                className={classes.actionButton}
                color="primary"
                type="button"
                variant="contained"
                onClick={handleBackToCartClick}
              >
                <FormattedMessage {...messages.backToCartBtn} />
              </Button>
            </Grid>
          ) : (
            <>
              <Grid item xs={12} md={6}>
                <Button
                  className={classes.actionButton}
                  color="primary"
                  type="button"
                  onClick={hasPaymentMethods ? handleVoidInvoice : handleCancelInvoice}
                >
                  <FormattedMessage
                    {...(hasPaymentMethods ? messages.voidInvoice : messages.cancelInvoice)}
                  />
                </Button>
              </Grid>

              <Grid item xs={12} md={6}>
                <ButtonWithCircularProgress
                  className={classes.actionButton}
                  type="button"
                  disabled={hasServiceError && !isEmptyCart ? false : isAddPaymentActionDisabled}
                  isSubmitting={loadingCheckOut}
                  onClick={getSubmitBtnHandler()}
                >
                  <FormattedMessage {...getSubmitBtnTitle()} />
                </ButtonWithCircularProgress>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
