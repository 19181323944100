import axios from 'axios';

import {
  IEmployeeCredentialsUpdateDto,
  IEmployeeDto,
  IEmployeeJobPosition,
  IEmployeeProfile,
  IEmployeeProfileDto,
  IEmployeeRole,
} from 'common/interfaces/employee';
import { IPaginatedEmployees } from 'modules/employees/interfaces';
import { ITableParams } from 'common/interfaces/table';
import ApiService from './ApiService';
import { ISuccessResponse } from 'common/interfaces/http';
import { IPeakEmployeeFormData, IPeakEmployeeProfile } from 'modules/peakEmployees/interfaces';
import { IAttachment } from 'common/interfaces/uploadFile';

export default class EmployeesService extends ApiService {
  public getPeakEmployees = (requestOptions?: ITableParams): Promise<IPaginatedEmployees> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get('/api/v1/peak-employees', { params: searchParams });
  };

  public getProfile = (id: string): Promise<IEmployeeDto> => {
    return axios.get(`/api/v1/peak-employees/${id}/profile`);
  };

  public getProfileView = (id: string): Promise<IEmployeeProfileDto> => {
    return axios.get(`/api/v1/peak-employees/${id}/profile/view`);
  };

  public updateProfile = (requestData: IEmployeeDto): Promise<IEmployeeProfileDto> => {
    return axios.put(`/api/v1/peak-employees/${requestData.id}/profile`, requestData);
  };

  public getEmployeePermissions = (id: string): Promise<IEmployeeRole> => {
    return axios.get(`/api/v1/peak-employees/${id}/permissions`);
  };

  public updateEmployeePermissions = (id: string, role: IEmployeeRole): Promise<void> => {
    return axios.put(`/api/v1/peak-employees/${id}/permissions`, role);
  };

  public getEmployeeAttachments = (id: string): Promise<IAttachment[]> => {
    return axios.get(`/api/v1/peak-employees/${id}/attachments`);
  };

  public updateEmployeeAttachments = (id: string, attachments: IAttachment[]): Promise<void> => {
    return axios.put(`/api/v1/peak-employees/${id}/attachments`, attachments);
  };

  public getEmployeeJobPositions = (id: string): Promise<IEmployeeJobPosition[]> => {
    return axios.get(`/api/v1/peak-employees/${id}/job-positions`);
  };

  public getEmployeeJobPositionsHistory = (id: string): Promise<IEmployeeJobPosition[]> => {
    return axios.get(`/api/v1/peak-employees/${id}/job-positions`);
  };

  public updateEmployeeJobPositions = (
    id: string,
    data: { jobPositions: IEmployeeJobPosition[] },
  ): Promise<void> => {
    return axios.put(`/api/v1/peak-employees/${id}/job-positions`, data);
  };

  public updateEmployeeCredentials = (
    id: string,
    credentials: IEmployeeCredentialsUpdateDto,
  ): Promise<void> => {
    return axios.put(`/api/v1/peak-employees/${id}/credentials`, credentials);
  };

  public deletePhoto = (id: string): Promise<void> => {
    return axios.delete(`/api/v1/peak-employees/${id}/avatar`);
  };

  public uploadPhoto = async (id: string, image: IAttachment): Promise<void> => {
    await axios.put(`/api/v1/peak-employees/${id}/avatar`, image);
  };

  public deleteEmployeeAttachment = (id: string, attachmentId: string): Promise<void> => {
    return axios.delete(`/api/v1/peak-employees/${id}/attachments/${attachmentId}`);
  };

  // public getEmployeesListWithSearch = (search: {
  //   searchStr: string;
  // }): Promise<IPaginatedEmployees> => {
  //   return axios.get('/api/v1/employees', { params: { searchStr } });
  // };

  public getPeakEmployeeById = (id: string): Promise<IEmployeeProfile> =>
    axios.get(`/api/v1/peak-employees/${id}`);

  public getSecurityRoles = (): Promise<Array<IEmployeeRole>> =>
    axios.get(`/api/v1/security/role`, { params: { active: true } });

  // public updateEmployeeRoles = (
  //   id: string,
  //   requestData: Array<IEmployeePermissionSectionItem>,
  // ): Promise<any> => axios.post(`/api/v1/security/employee/${id}`, requestData);

  public createPeakEmployee = (requestData: IPeakEmployeeFormData): Promise<IPeakEmployeeProfile> =>
    axios.post(`/api/v1/peak-employees`, requestData);

  public updatePeakEmployee = (
    id: string,
    requestData: IPeakEmployeeFormData,
  ): Promise<IPeakEmployeeProfile> => axios.put(`/api/v1/peak-employees/${id}`, requestData);

  public changePeakEmployeeStatus = (
    employeeId: string,
    active: boolean,
  ): Promise<ISuccessResponse> =>
    axios.patch(`/api/v1/peak-employees/${employeeId}/change-status`, { active });
}
