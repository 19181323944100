import React from 'react';
import { NavigateNext } from '@material-ui/icons';
import {
  Breadcrumbs,
  Button,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
// interfaces
import { IRegisterProductItem } from 'modules/pos-settings/interfaces/register';

interface IStyleProps {
  fontSize?: number | string;
  hideMarginArrow?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    breadcrumbs: {
      opacity: 1,
      '& ol': {
        minHeight: theme.spacing(2.25),
        '& >li': {
          margin: ({ hideMarginArrow }: IStyleProps) => (hideMarginArrow ? 0 : undefined),
        },
      },
    },
    breadcrumb: {
      fontSize: ({ fontSize }: IStyleProps) =>
        fontSize ? `${fontSize}rem` : theme.typography.h4.fontSize,
      lineHeight: ({ fontSize }: IStyleProps) =>
        fontSize ? `${fontSize}rem` : theme.typography.h4.fontSize,
      fontWeight: 'normal',
      '&:hover': {
        textDecoration: 'none',
      },
      display: 'inline-flex',
    },
    selectedBreadcrumb: {
      color: theme.palette.text.primary,
    },
    separatorBreadcrumb: {
      height: theme.typography.h4.fontSize,
      opacity: 0.4,
    },
  }),
);

interface IBreadcrumbsComponentProps extends IStyleProps {
  onBreadcrumbClick: (category: IRegisterProductItem[]) => void;
  breadcrumb: IRegisterProductItem[];
}

const BreadcrumbsComponent: React.FC<IBreadcrumbsComponentProps> = ({
  breadcrumb,
  onBreadcrumbClick,
  fontSize,
  hideMarginArrow,
}: IBreadcrumbsComponentProps): JSX.Element => {
  const classes = useStyles({ fontSize, hideMarginArrow });

  return (
    <Breadcrumbs
      className={classes.breadcrumbs}
      separator={<NavigateNext className={classes.separatorBreadcrumb} />}
      aria-label="breadcrumb"
    >
      {breadcrumb.length === 0 ? (
        <Typography className={`${classes.breadcrumb} ${classes.selectedBreadcrumb}`}>
          Home
        </Typography>
      ) : (
        <Button
          variant="text"
          color="primary"
          type="button"
          className={`btn-text ${classes.breadcrumb}`}
          onClick={() => onBreadcrumbClick([])}
        >
          Home
        </Button>
      )}
      {breadcrumb.map((item, index) => {
        if (index === breadcrumb.length - 1)
          return (
            <Typography
              className={`${classes.breadcrumb} ${classes.selectedBreadcrumb}`}
              key={item.id}
            >
              {item.title}
            </Typography>
          );
        return (
          <Button
            variant="text"
            color="primary"
            type="button"
            className={`btn-text ${classes.breadcrumb}`}
            onClick={() => onBreadcrumbClick(breadcrumb.slice(0, index + 1))}
            key={item.id}
          >
            {item.title}
          </Button>
        );
      })}
    </Breadcrumbs>
  );
};

export default React.memo(BreadcrumbsComponent);
