import { defineMessages } from 'react-intl';

export default defineMessages({
  clubsTitle: {
    id: 'app.modules.corporations.Clubs.clubsTitle',
    defaultMessage: 'Clubs',
  },

  // New club
  newClubTitle: {
    id: 'app.modules.clubs.newClub.title',
    defaultMessage: 'New Club',
  },
  // Edit club
  editClubTitle: {
    id: 'app.modules.clubs.editClub.title',
    defaultMessage: 'Edit Club',
  },

  // Steps
  workingHoursLabel: {
    id: 'app.modules.clubs.newClub.step.workingHoursLabel',
    defaultMessage: 'Working Hours',
  },
  otherLabel: {
    id: 'app.modules.clubs.newClub.step.otherLabel',
    defaultMessage: 'Other',
  },
  kidZoneSettingsLabel: {
    id: 'app.modules.clubs.newClub.step.kidZoneSettingsLabel',
    defaultMessage: 'KidZone settings',
  },

  // Billing options
  couponBillingLabel: {
    id: 'app.modules.clubs.newClub.step.billing.couponBillingLabel',
    defaultMessage: 'Coupon Billing',
  },
  paymentSplitsLabel: {
    id: 'app.modules.clubs.newClub.step.billing.paymentSplitsLabel',
    defaultMessage: 'Payment Splits',
  },
  duesLabel: {
    id: 'app.modules.clubs.newClub.step.billing.duesLabel',
    defaultMessage: 'Dues',
  },
  downPaymentsLabel: {
    id: 'app.modules.clubs.newClub.step.billing.downPaymentsLabel',
    defaultMessage: 'Down Payments',
  },
  memberServicesLabel: {
    id: 'app.modules.clubs.newClub.step.billing.memberServicesLabel',
    defaultMessage: 'Member Services handled by Peak',
  },
});
