import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';

import { initialState, initReqStateImt } from 'common/constants/initialState';
import { actionTypes } from 'modules/peakEmployees/state/peakProfile/constants';
import { ActionResult } from 'common/constants';

const profileReducer = (state = initReqStateImt, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MY_PEAK_PROFILE_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_MY_PEAK_PROFILE_SUCCESS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.CLEAR_MY_PEAK_PROFILE:
      return initReqStateImt;
    default:
      return state;
  }
};

const profileViewReducer = (state = initReqStateImt, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MY_PEAK_PROFILE_VIEW_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_MY_PEAK_PROFILE_VIEW_SUCCESS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.CLEAR_MY_PEAK_PROFILE:
      return initReqStateImt;
    default:
      return state;
  }
};

const initPermissionsState = fromJS({
  permissions: initialState,
  updatePermissionsResult: null,
});

const profilePermissionsReducer = (state = initPermissionsState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MY_PEAK_PROFILE_PERMISSIONS_LOADING:
      return state.setIn(['permissions', 'isLoading'], action.payload);
    case actionTypes.FETCH_MY_PEAK_PROFILE_PERMISSIONS_SUCCESS:
      return state.setIn(['permissions', 'result'], fromJS(action.payload));
    case actionTypes.UPDATE_PEAK_PROFILE_PERMISSIONS_RESULT:
      return state.set('updatePermissionsResult', ActionResult.SUCCESS_ACTION);
    case actionTypes.RESET_UPDATE_PEAK_PROFILE_PERMISSIONS_RESULT:
      return state.set('updatePermissionsResult', null);
    case actionTypes.CLEAR_MY_PEAK_PROFILE:
      return initReqStateImt;
    default:
      return state;
  }
};

const profileAttachmentsReducer = (state = initReqStateImt, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MY_PEAK_PROFILE_ATTACHMENTS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_MY_PEAK_PROFILE_ATTACHMENTS_SUCCESS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.CLEAR_MY_PEAK_PROFILE:
      return initReqStateImt;
    default:
      return state;
  }
};

const initJobPositionsState = fromJS({
  jobPositions: initialState,
  jobPositionsHistory: initialState,
  updateJobPositionsResult: null,
});

const profileJobPositionsReducer = (state = initJobPositionsState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MY_PEAK_PROFILE_JOB_POSITIONS_LOADING:
      return state.setIn(['jobPositions', 'isLoading'], action.payload);
    case actionTypes.FETCH_PEAK_PROFILE_JOB_POSITIONS_HISTORY_LOADING:
      return state.setIn(['jobPositionsHistory', 'isLoading'], action.payload);
    case actionTypes.FETCH_PEAK_PROFILE_JOB_POSITIONS_HISTORY_SUCCESS:
      return state.setIn(['jobPositionsHistory', 'result'], fromJS(action.payload));
    case actionTypes.RESET_PEAK_PROFILE_JOB_POSITIONS_HISTORY:
      return state.set('jobPositionsHistory', fromJS(initialState));
    case actionTypes.FETCH_MY_PEAK_PROFILE_JOB_POSITIONS_SUCCESS:
      return state.setIn(['jobPositions', 'result'], fromJS(action.payload));
    case actionTypes.UPDATE_PEAK_PROFILE_JOB_POSITIONS_RESULT:
      return state.set('updateJobPositionsResult', ActionResult.SUCCESS_ACTION);
    case actionTypes.RESET_UPDATE_PEAK_PROFILE_JOB_POSITIONS_RESULT:
      return state.set('updateJobPositionsResult', null);
    case actionTypes.CLEAR_MY_PEAK_PROFILE:
      return initReqStateImt;
    default:
      return state;
  }
};

export default combineReducers({
  profile: profileReducer,
  profileView: profileViewReducer,
  profilePermissions: profilePermissionsReducer,
  profileAttachments: profileAttachmentsReducer,
  profileJobPositions: profileJobPositionsReducer,
});
