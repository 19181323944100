import { combineReducers } from 'redux-immutable';
import daySheetReducers from './daySheet/reducers';
import commissionRuleReducers from './commissionRule/reducers';
import packageReducers from './package/reducers';

export default combineReducers({
  daySheets: daySheetReducers,
  commissionRules: commissionRuleReducers,
  packages: packageReducers,
});
