export const actionTypes = {
  FETCH_PERSON_TICKETS_SUCCESS: 'MEMBER_PORTAL/FETCH_PERSON_TICKETS_SUCCESS',
  FETCH_PERSON_TICKETS_LOADING: 'MEMBER_PORTAL/FETCH_PERSON_TICKETS_LOADING',
  RESET_PERSON_TICKETS: 'MEMBER_PORTAL/RESET_PERSON_TICKETS',

  FETCH_PERSON_TICKET_SUCCESS: 'MEMBER_PORTAL/FETCH_PERSON_TICKET_SUCCESS',
  FETCH_PERSON_TICKET_LOADING: 'MEMBER_PORTAL/FETCH_PERSON_TICKET_LOADING',
  RESET_PERSON_TICKET: 'MEMBER_PORTAL/RESET_PERSON_TICKET',

  ADD_PERSON_TICKET_SUCCESS: 'MEMBER_PORTAL/ADD_PERSON_TICKET_SUCCESS',
  ADD_PERSON_TICKET_LOADING: 'MEMBER_PORTAL/ADD_PERSON_TICKET_LOADING',
  RESET_ADD_PERSON_TICKET_ACTION_RESULT: 'MEMBER_PORTAL/RESET_ADD_PERSON_TICKET_ACTION_RESULT',

  SET_PERSON_TICKETS_ERROR: 'SET_PERSON_TICKETS_ERROR',
};
