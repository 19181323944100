import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { TextField } from '@material-ui/core';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { DeviceActionButton } from 'common/components';
import inputLabels from 'common/messages/inputLabels';
import { DeviceType } from 'common/constants/scanner';

interface IBarcodeScannerFieldProps {
  name: string;
}

const BarcodeScannerField = (props: IBarcodeScannerFieldProps) => {
  const { name } = props;
  const renderIntlMessage = useRenderIntlMessage();

  const { control, errors, setValue } = useFormContext();

  const onScanningSuccess = useCallback(
    (scannerString: string) => {
      setValue(name, scannerString, { shouldValidate: true });
    },
    [setValue, name],
  );

  return (
    <Controller
      control={control}
      name={name}
      variant="outlined"
      render={({ value, onChange }) => (
        <TextField
          InputProps={{
            endAdornment: (
              <DeviceActionButton
                deviceType={DeviceType.Barcode}
                onScanningSuccess={onScanningSuccess}
              />
            ),
          }}
          variant="outlined"
          label={<FormattedMessage {...inputLabels.barcode} />}
          fullWidth
          value={value}
          onChange={event => onChange(event.target.value)}
          error={!!errors.barcode && !!errors.barcode.message}
          helperText={errors.barcode ? renderIntlMessage(errors.barcode.message) : ''}
        />
      )}
    />
  );
};

export default BarcodeScannerField;
