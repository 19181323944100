export const actionTypes = {
  // Errors
  PUSH_REQUEST_ERROR: 'PUSH_REQUEST_ERROR',
  RESET_REQUEST_ERRORS: 'RESET_REQUEST_ERRORS',
  // Campaigns report
  FETCH_PAYMENT_TRANSACTIONS_REPORT_LOADING: 'FETCH_PAYMENT_TRANSACTIONS_REPORT_LOADING',
  FETCH_PAYMENT_TRANSACTIONS_REPORT: 'FETCH_PAYMENT_TRANSACTIONS_REPORT',
  // Export
  EXPORT_PAYMENT_TRANSACTIONS_REPORT_LIST_LOADING:
    'EXPORT_PAYMENT_TRANSACTIONS_REPORT_LIST_LOADING',
  EXPORT_PAYMENT_TRANSACTIONS_REPORT_LIST: 'EXPORT_PAYMENT_TRANSACTIONS_REPORT_LIST',
  RESET_PAYMENT_TRANSACTIONS_EXPORT_SUCCESS: 'RESET_PAYMENT_TRANSACTIONS_EXPORT_SUCCESS',
};
