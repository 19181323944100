export enum KidZonePaths {
  KID_ZONE = '/kid-zone',
  KID_ZONE_HISTORY = 'history',
}

export const actionTypes = {
  KID_ZONE_STATUS: 'KID-ZONE/STATUS',
  KID_ZONE_STATUS_LOADING: 'KID-ZONE/STATUS_LOADING',
  RESET_KID_ZONE_STATUS: 'KID-ZONE/RESET_KID_ZONE_STATUS',

  GET_MEMBERS: 'KID-ZONE/GET_MEMBERS',
  RESET_MEMBERS: 'KID-ZONE/RESET_MEMBERS',
  GET_MEMBERS_LOADING: 'KID-ZONE/GET_MEMBERS_LOADING',

  FETCH_CHECK_INS: 'KID-ZONE/FETCH_CHECK_INS',
  FETCH_CHECK_INS_LOADING: 'KID-ZONE/FETCH_CHECK_INS_LOADING',
  SET_CURRENT_CHECK_IN: 'KID-ZONE/SET_CURRENT_CHECK_IN',
  SET_SELECTED_CHECK_IN: 'KID-ZONE/SET_SELECTED_CHECK_IN',
  CHECK_OUT_KID: 'KID-ZONE/CHECK_OUT_KID',
  RESET_CURRENT_CHECK_IN: 'KID-ZONE/RESET_CURRENT_CHECK_IN',

  CHECK_IN_CHILD: 'KID-ZONE/CHECK_IN_CHILD',
  CHECK_IN_CHILD_LOADING: 'KID-ZONE/CHECK_IN_CHILD_LOADING',
  RESET_CHECK_IN_ACTION_STATUS: 'KID-ZONE/RESET_CHECK_IN_ACTION_STATUS',

  FETCH_MEMBERS_TO_CHECK_IN_BY_BARCODE: 'KID-ZONE/FETCH_MEMBERS_TO_CHECK_IN_BY_BARCODE',
  FETCH_MEMBERS_TO_CHECK_IN_BY_BARCODE_LOADING:
    'KID-ZONE/FETCH_MEMBERS_TO_CHECK_IN_BY_BARCODE_LOADING',
  FETCH_MEMBERS_TO_CHECK_IN_BY_BARCODE_ERROR: 'KID-ZONE/FETCH_MEMBERS_TO_CHECK_IN_BY_BARCODE_ERROR',
  RESET_MEMBERS_TO_CHECK_IN_BY_BARCODE: 'KID-ZONE/RESET_MEMBERS_TO_CHECK_IN_BY_BARCODE',

  CHECK_IN_ALL_CHILDREN: 'KID-ZONE/CHECK_IN_ALL_CHILDREN',
  CHECK_IN_ALL_CHILDREN_LOADING: 'KID-ZONE/CHECK_IN_ALL_CHILDREN_LOADING',
  RESET_CHECK_IN_ALL_CHILDREN_ACTION_RESULT: 'KID-ZONE/RESET_CHECK_IN_ALL_CHILDREN_ACTION_RESULT',

  SET_MAIN_PANEL_PERSON: 'KID-ZONE/SET_MAIN_PANEL_PERSON',
  SET_RIGHT_PANEL_PERSON: 'KID-ZONE/SET_RIGHT_PANEL_PERSON',

  FETCH_CHECK_INS_HISTORY: 'KID-ZONE/FETCH_CHECK_INS_HISTORY',
  FETCH_CHECK_INS_HISTORY_LOADING: 'KID-ZONE/FETCH_CHECK_INS_HISTORY_LOADING',

  SET_ALLERGIES_IS_LOADING: 'KID-ZONE/SET_ALLERGIES_IS_LOADING',
  FETCH_ALLERGIES: 'KID-ZONE/FETCH_ALLERGIES',
  RESET_ALLERGIES: 'KID-ZONE/RESET_ALLERGIES',

  UPDATE_ALLERGIES: 'KID-ZONE/UPDATE_ALLERGIES',

  SET_EMERGENCY_CONTACT_LOADING: 'KID-ZONE/SET_EMERGENCY_CONTACT_LOADING',
  FETCH_EMERGENCY_CONTACT: 'KID-ZONE/FETCH_EMERGENCY_CONTACT',
  RESET_EMERGENCY_CONTACT: 'KID-ZONE/RESET_EMERGENCY_CONTACT',

  UPDATE_EMERGENCY_CONTACT: 'KID-ZONE/UPDATE_EMERGENCY_CONTACT',

  FETCH_GUARDIAN_DAYCARE_SERVICES: 'FETCH_GUARDIAN_DAYCARE_SERVICES',
  FETCH_GUARDIAN_DAYCARE_SERVICES_LOADING: 'FETCH_GUARDIAN_DAYCARE_SERVICES_LOADING',
  RESET_GUARDIAN_DAYCARE_SERVICES: 'RESET_GUARDIAN_DAYCARE_SERVICES',

  ADD_DATE_OF_BIRTHDAY_ACTION: 'ADD_DATE_OF_BIRTHDAY_ACTION',
  ADD_DATE_OF_BIRTHDAY_ACTION_RESULT: 'ADD_DATE_OF_BIRTHDAY_ACTION_RESULT',
  ADD_DATE_OF_BIRTHDAY_ACTION_LOADING: 'ADD_DATE_OF_BIRTHDAY_ACTION_LOADING',
  RESET_ADD_DATE_OF_BIRTHDAY_ACTION_RESULT: 'RESET_ADD_DATE_OF_BIRTHDAY_ACTION_RESULT',
};

export enum KidZoneStatusEnum {
  NOT_LOADED,
  ACTIVE,
  NOT_ACTIVE,
  NOT_CONFIGURED,
  OUT_OF_WORKING_HOURS,
}
