export const actionTypes = {
  FETCH_MY_PEAK_PROFILE_SUCCESS: 'PEAK-EMPLOYEES/FETCH_PEAK_PROFILE_SUCCESS',
  FETCH_MY_PEAK_PROFILE_LOADING: 'PEAK-EMPLOYEES/FETCH_PEAK_PROFILE_LOADING',

  FETCH_MY_PEAK_PROFILE_VIEW_SUCCESS: 'PEAK-EMPLOYEES/FETCH_MY_PEAK_PROFILE_VIEW_SUCCESS',
  FETCH_MY_PEAK_PROFILE_VIEW_LOADING: 'PEAK-EMPLOYEES/FETCH_MY_PEAK_PROFILE_VIEW_LOADING',

  FETCH_MY_PEAK_PROFILE_PERMISSIONS_SUCCESS:
    'PEAK-EMPLOYEES/FETCH_PEAK_PROFILE_PERMISSIONS_SUCCESS',
  FETCH_MY_PEAK_PROFILE_PERMISSIONS_LOADING:
    'PEAK-EMPLOYEES/FETCH_PEAK_PROFILE_PERMISSIONS_LOADING',

  UPDATE_PEAK_PROFILE_PERMISSIONS_RESULT: 'PEAK-EMPLOYEES/UPDATE_PEAK_PROFILE_PERMISSIONS_RESULT',
  RESET_UPDATE_PEAK_PROFILE_PERMISSIONS_RESULT:
    'PEAK-EMPLOYEES/RESET_UPDATE_PEAK_PROFILE_PERMISSIONS_RESULT',

  FETCH_MY_PEAK_PROFILE_ATTACHMENTS_LOADING:
    'PEAK-EMPLOYEES/FETCH_PEAK_PROFILE_ATTACHMENTS_LOADING',
  FETCH_MY_PEAK_PROFILE_ATTACHMENTS_SUCCESS:
    'PEAK-EMPLOYEES/FETCH_PEAK_PROFILE_ATTACHMENTS_SUCCESS',

  FETCH_MY_PEAK_PROFILE_JOB_POSITIONS_LOADING:
    'PEAK-EMPLOYEES/FETCH_PEAK_PROFILE_JOB_POSITIONS_LOADING',
  FETCH_MY_PEAK_PROFILE_JOB_POSITIONS_SUCCESS:
    'PEAK-EMPLOYEES/FETCH_PEAK_PROFILE_JOB_POSITIONS_SUCCESS',

  FETCH_PEAK_PROFILE_JOB_POSITIONS_HISTORY_LOADING:
    'PEAK-EMPLOYEES/FETCH_PEAK_PROFILE_JOB_POSITIONS_HISTORY_LOADING',
  FETCH_PEAK_PROFILE_JOB_POSITIONS_HISTORY_SUCCESS:
    'PEAK-EMPLOYEES/FETCH_PEAK_PROFILE_JOB_POSITIONS_HISTORY_SUCCESS',
  RESET_PEAK_PROFILE_JOB_POSITIONS_HISTORY:
    'PEAK-EMPLOYEES/RESET_PEAK_PROFILE_JOB_POSITIONS_HISTORY',

  UPDATE_PEAK_PROFILE_JOB_POSITIONS_RESULT:
    'PEAK-EMPLOYEES/UPDATE_PEAK_PROFILE_JOB_POSITIONS_RESULT',
  RESET_UPDATE_PEAK_PROFILE_JOB_POSITIONS_RESULT:
    'PEAK-EMPLOYEES/RESET_UPDATE_PEAK_PROFILE_JOB_POSITIONS_RESULT',

  CLEAR_MY_PEAK_PROFILE: 'PEAK-EMPLOYEES/CLEAR_MY_PEAK_PROFILE',
};
