import React from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { Box, createStyles, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { formatNumberToPrice } from 'common/utils';

import { FeeTypeOptions } from 'modules/services/constants/fees';

import { IPackageFee } from 'modules/services/interfaces/packages';

import { TooltipTypography } from 'common/components/index';
import { ReactComponent as InfoIcon } from 'img/icons/info.svg';

import messages from 'common/messages/messages';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    includedItemName: {
      '&:before': {
        content: '"\\2022"',
        fontSize: '1rem',
        marginRight: theme.spacing(1),
      },
    },
    feeType: {
      marginLeft: theme.spacing(0.5),
      whiteSpace: 'nowrap',
      color: theme.palette.secondary.dark,
    },
    noPriceMarker: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      flex: 1,
      marginLeft: theme.spacing(1),
      textAlign: 'end',
    },
    infoIcon: {
      width: '1rem',
      height: '1rem',
      color: theme.palette.darkBackground?.light,
      pointerEvents: 'auto',
      marginRight: theme.spacing(1),
    },
  }),
);

interface IInvoicePackageFeeItem {
  packageFee: IPackageFee;
  isIncludedItem?: boolean;
}

const InvoicePackageFeeItem = ({
  packageFee,
  isIncludedItem,
}: IInvoicePackageFeeItem): JSX.Element => {
  const renderIntlMessage = useRenderIntlMessage();

  const classes = useStyles();

  const totalAmount = (packageFee.amountToPay ??
    packageFee.totalAmount.value ??
    packageFee.totalAmount) as number;
  const chargeAfterDays = (packageFee.chargeAfterDays?.value ??
    packageFee.chargeAfterDays) as number;

  return (
    <Box display="flex " alignItems="center" marginY={2}>
      <TooltipTypography
        ellipsized
        className={cx({
          [classes.includedItemName]: isIncludedItem,
        })}
      >
        {`${packageFee.fee.title}`}
      </TooltipTypography>

      <Typography component="p" className={classes.feeType}>
        {renderIntlMessage(FeeTypeOptions.message(packageFee.fee.type))}
      </Typography>

      <Typography component="p" className={classes.noPriceMarker}>
        {!!packageFee.chargeAfterDays && (
          <Tooltip
            title={
              <FormattedMessage
                {...messages.feeWillBeChargedBody}
                values={{ price: `$${formatNumberToPrice(totalAmount)}`, period: chargeAfterDays }}
              />
            }
          >
            <InfoIcon className={classes.infoIcon} />
          </Tooltip>
        )}

        <Typography component="span">
          {`+$${formatNumberToPrice(chargeAfterDays ? 0 : totalAmount)}`}
        </Typography>
      </Typography>
    </Box>
  );
};

export default React.memo(InvoicePackageFeeItem);
