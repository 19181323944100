import moment from 'moment-timezone';
import { addMethod, AnyObjectSchema, array, boolean, object, string } from 'yup';
import { IDayTimeAvailabilityDto } from 'common/interfaces/common';
import inputErrors from 'common/messages/inputErrors';
import { validateAvailabilityRanges } from './availabilityRangeValidation';
import { endTimeErrorMessage, getRequiredMessage } from 'common/constants/globalConstants';
import { dateSchema } from 'common/validationSchemas/dateSchemas';
import * as yup from 'yup';

const notInPackageRangeErrorMessage = () => inputErrors.notInPackageRangeErrorMessage;

const checkIsTimeSlotValid = (slots, editableRangeIndex: number) => {
  const { startTime, endTime } = slots[editableRangeIndex];

  let isValid = true;

  for (let i = 0; i < slots.length; i += 1) {
    if (i === editableRangeIndex) {
      // eslint-disable-next-line no-continue
      continue;
    }

    if (
      moment(slots[i].startTime, 'HH:mm').isSameOrBefore(moment(endTime, 'HH:mm')) &&
      moment(slots[i].endTime, 'HH:mm').isSameOrAfter(moment(startTime, 'HH:mm'))
    ) {
      isValid = false;
    }
  }

  return isValid;
};

export const availabilityValidationSchema = object().shape({
  weekday: string(),
  allDay: boolean(),
  timeRanges: array()
    .of(object().shape({}))
    .when('allDay', {
      is: true,
      then: array().of(
        object().shape({
          startTime: string().nullable(),
          endTime: string().nullable(),
        }),
      ),
      otherwise: array().of(
        object().shape({
          startTime: dateSchema.required(getRequiredMessage),
          endTime: dateSchema
            .required(getRequiredMessage)
            .test('endTime', endTimeErrorMessage, function(endTime) {
              const { startTime } = this.parent;

              if (!startTime || !endTime) {
                return true;
              }

              if (moment(startTime, 'HH:mm').isSame(moment(endTime, 'HH:mm'))) {
                return this.createError({
                  message: inputErrors.endTimeEqualStartTimeError,
                  type: 'same',
                });
              }

              if (moment(startTime, 'HH:mm').isAfter(moment(endTime, 'HH:mm'))) {
                return this.createError({ message: endTimeErrorMessage(), type: 'min' });
              }

              return true;
            }),
        }),
      ),
    })
    .test(
      'timeRanges',
      () => inputErrors.intervalsOverlapError,
      function(ranges) {
        const { allDay, editableRangeIndex } = this.parent;

        if (allDay || ranges.length === 1 || !editableRangeIndex) {
          return true;
        }

        if (!checkIsTimeSlotValid(ranges, editableRangeIndex)) {
          return this.createError({ message: inputErrors.intervalsOverlapError, type: 'overlap' });
        }

        return true;
      },
    ),
});

export const VALID_RANGE_ERROR_TYPE = 'isValidRange';

addMethod(
  array,
  'availabilityRangeValidation',
  (message = 'Availability exceeds its valid range.', validAvailabilityRanges = []) =>
    array().test(VALID_RANGE_ERROR_TYPE, message, (value: IDayTimeAvailabilityDto[]) =>
      validateAvailabilityRanges(value, validAvailabilityRanges),
    ),
);

export const generateAvailabilityScheduleSchema = (
  validityRange: IDayTimeAvailabilityDto[],
  notInRangeErrorMessage = notInPackageRangeErrorMessage,
): AnyObjectSchema =>
  object().shape({
    availabilitySchedule: (array() as any)
      .availabilityRangeValidation(notInRangeErrorMessage, validityRange)
      .of(availabilityValidationSchema),
    weekdays: yup
      .array()
      .of(yup.object().shape({}))
      .min(1, () => inputErrors.weekdaysMinLengthError),
  });
