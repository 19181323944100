import { fromJS } from 'immutable';

import { ActionPrimaryInfoTypes } from 'common/state/newPerson/primaryInfo/actions';
import { actionTypes } from 'common/state/newPerson/contactInfo/constants';

const initialContactInfoState = fromJS({
  result: {},
  error: null,
  isLoading: false,
});

const contactInfoReducer = (
  state = initialContactInfoState,
  { type, payload }: ActionPrimaryInfoTypes,
) => {
  switch (type) {
    case actionTypes.SET_CONTACT_INFO_IS_LOADING:
      return state.set('isLoading', payload);
    case actionTypes.GET_CONTACT_INFO:
      return state.set('result', payload);
    case actionTypes.SET_CONTACT_INFO_ERROR:
      return state.set('error', payload);
    case actionTypes.CLEAR_CONTACT_INFO:
      return initialContactInfoState;
    default:
      return state;
  }
};

export default contactInfoReducer;
