import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { List as ImmutableList } from 'immutable';
import { Box } from '@material-ui/core';

import { DictionaryList, QueryPageList } from 'common/constants';

import { transformCampaignFormValuesToDTO } from 'modules/crm/components/CampaignForm/utils';

import { IScriptListItemImt } from 'modules/crm/interfaces/scripts';
import { ICampaignForm } from 'modules/crm/interfaces/campaigns';
import { TDocumentTemplateListItemImt } from 'common/interfaces/dictionary';

import { RouteBackground } from 'components';
import { CampaignForm } from 'modules/crm/components';

import { selectQueryPageList } from 'common/state/queryPage-lists/selectors';
import { selectDictionaryList } from 'common/state/dictionary/selectors';

import { fetchDictionaryList } from 'common/state/dictionary/actions';
import { fetchServicesList } from 'modules/services/state/services/actions';
import * as campaignSelectors from 'modules/crm/state/campaigns/selectors';

import * as campaignActions from 'modules/crm/state/campaigns/actions';
import * as scriptsSelectors from 'modules/crm/state/scripts/selectors';
import * as scriptsActions from 'modules/crm/state/scripts/actions';

import { useAppDispatch } from 'store/hooks';

import { TemplatePurpose, TemplateTypes } from 'common/constants/documentTemplate';
import { CrmSubModules, PeakModules } from 'common/constants/peakModules';
import { ICampaignDto } from 'common/interfaces/campaign';
import { CampaignType } from 'common/constants/campaign';

const NewCampaign = (): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const scripts: ImmutableList<IScriptListItemImt> = useSelector(scriptsSelectors.selectScripts());
  const documentTemplates: ImmutableList<TDocumentTemplateListItemImt> = useSelector(
    selectDictionaryList(DictionaryList.DOCUMENT_TEMPLATES),
  );
  const createdCampaign: ICampaignDto = useSelector(campaignSelectors.selectCreatedCampaign());
  const isCampaignCreating: boolean = useSelector(campaignSelectors.selectCampaignCreating());
  const queryCampaigns: string = useSelector(selectQueryPageList(QueryPageList.CAMPAIGNS));

  const navigate = useNavigate();
  const location = useLocation();
  const module =
    location.pathname.indexOf('personal-training-crm') === -1
      ? PeakModules.Crm
      : PeakModules.PersonalTrainingCrm;

  // lifecycle

  useEffect(() => {
    dispatch(scriptsActions.fetchActiveScripts(module));
    dispatch(fetchServicesList());
    dispatch(fetchDictionaryList(DictionaryList.CAME_FROM, { module: CrmSubModules.Campaigns }));
  }, [dispatch, module]);

  useEffect(() => {
    if (createdCampaign !== null) {
      navigate(`/${location.pathname.split('/')[1]}/campaigns${queryCampaigns}`);
    }
  }, [createdCampaign, location.pathname, navigate, queryCampaigns]);

  useEffect(() => {
    return () => {
      dispatch(campaignActions.resetCampaignForm());
    };
  }, [dispatch]);

  // handlers

  const handleSubmitForm = useCallback(
    (formValues: ICampaignForm): void => {
      dispatch(
        campaignActions.createCampaign(transformCampaignFormValuesToDTO(formValues), module),
      );
    },
    [dispatch, module],
  );

  const handleCampaignTypeChange = useCallback(
    (type: CampaignType): void => {
      if (type === CampaignType.PHONE) return;
      dispatch(
        fetchDictionaryList(DictionaryList.DOCUMENT_TEMPLATES, {
          type: type === CampaignType.EMAIL ? TemplateTypes.Email : TemplateTypes.SMS,
          purpose: TemplatePurpose.CampaignTemplate,
          module,
        }),
      );
    },
    [dispatch, module],
  );

  return (
    <RouteBackground>
      <Box display="flex" flexDirection="column" height="100%" pt={1}>
        <CampaignForm
          scripts={scripts}
          documentTemplates={documentTemplates}
          onCampaignTypeChange={handleCampaignTypeChange}
          onSubmit={handleSubmitForm}
          isLoading={isCampaignCreating}
        />
      </Box>
    </RouteBackground>
  );
};

export default NewCampaign;
