import { DurationType, FrequencyType } from '../constants/packages';
import { IEditable } from 'modules/services/interfaces/packages';
import { getPriceFromNumber } from 'common/utils/calculate';
import moment from 'moment-timezone';

class Days {
  // Unclear right now, what should be it a number, in case if we pass wrong duration or frequency
  static DEFAULT_DAYS_AMOUNT = 0;

  static values = [
    {
      amount: 1,
      duration: DurationType.Days,
      frequency: null,
    },
    {
      amount: 7,
      duration: DurationType.Weeks,
      frequency: FrequencyType.Weekly,
    },
    {
      amount: 14,
      duration: DurationType.BiWeeks,
      frequency: FrequencyType.BiWeekly,
    },
    {
      amount: 30,
      duration: DurationType.Months,
      frequency: FrequencyType.Monthly,
    },
    {
      amount: 90,
      duration: DurationType.Quarters,
      frequency: FrequencyType.Quarterly,
    },
    {
      amount: 180,
      duration: DurationType.SemiAnnually,
      frequency: FrequencyType.SemiAnnually,
    },
    {
      amount: 360,
      duration: DurationType.Years,
      frequency: FrequencyType.Annually,
    },
  ];

  static getDurationDays(durationType: DurationType): number {
    return this.values.find(el => el.duration === durationType)?.amount || this.DEFAULT_DAYS_AMOUNT;
  }

  static getFrequencyDays(frequencyType: FrequencyType): number {
    return (
      this.values.find(el => el.frequency === frequencyType)?.amount || this.DEFAULT_DAYS_AMOUNT
    );
  }
}

export const countTaxedAmount = (amount: number, taxPercentage: number): number =>
  parseFloat((amount * (1 + taxPercentage / 100)).toFixed(2));

export const deductTaxAmount = (amount: number, taxPercentage: number): number =>
  parseFloat(((amount * taxPercentage) / (taxPercentage + 100)).toFixed(2));

export const removeTaxAmount = (amount: number, taxPercentage: number): number =>
  parseFloat((amount / (1 + taxPercentage / 100)).toFixed(2));

export interface IDurationOptions {
  type?: DurationType;
  amount?: number;
  fromDate?: string;
  toDate?: string;
}

export const countPricePerBilling = (
  totalAmount?: number | string,
  taxPercentage?: number | string,
  durationData?: IDurationOptions,
  paymentFrequencyType?: FrequencyType,
): number | null => {
  const convertedTotalAmount = Number(totalAmount);
  const convertedTaxPercentage = Number(taxPercentage);

  if (
    (!convertedTotalAmount && convertedTotalAmount !== 0) ||
    (!convertedTaxPercentage && convertedTaxPercentage !== 0)
  ) {
    return null;
  }

  if (!paymentFrequencyType && convertedTaxPercentage) {
    return countTaxedAmount(convertedTotalAmount, convertedTaxPercentage);
  }

  const durationInDays: number =
    durationData.type === DurationType.Custom
      ? moment(`${durationData.toDate}Z`).diff(moment(`${durationData.fromDate}Z`), 'days') + 1
      : Days.getDurationDays(durationData.type) * durationData.amount;
  const paymentAmounts: number = Math.ceil(
    durationInDays / Days.getFrequencyDays(paymentFrequencyType),
  );

  return countTaxedAmount(convertedTotalAmount / paymentAmounts, convertedTaxPercentage);
};

const getSplitPaymentPrice = (index, splitNumber, amount, perPaymentAmount): number => {
  const result =
    index + 1 === Number(splitNumber)
      ? Number(amount) - (Number(splitNumber) - 1) * perPaymentAmount
      : perPaymentAmount;

  return getPriceFromNumber(result);
};

export const getSplitsPayment = (
  splitNumber: string | number,
  amount?: string | number,
  editable?: boolean,
): IEditable[] => {
  if (!splitNumber || Number.isNaN(splitNumber)) return [];

  const perPaymentAmount: number =
    amount && !Number.isNaN(amount)
      ? Math.floor((Number(amount) * 100) / Number(splitNumber)) / 100
      : 0;

  return Array.from({ length: Number(splitNumber) }, (splitItem, index) => ({
    editable: !!editable,
    minValue: null,
    maxValue: null,
    order: index,
    value: getSplitPaymentPrice(index, splitNumber, amount, perPaymentAmount),
  }));
};

export const checkIsPaymentScheduleOptionDisabled = (
  durationType: DurationType,
  durationEditableNumber: number,
  frequencyType: FrequencyType,
): boolean => {
  const durationDays = Days.getDurationDays(durationType) * durationEditableNumber;
  const frequencyDays = Days.getFrequencyDays(frequencyType);

  return frequencyDays > durationDays;
};
