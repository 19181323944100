export const actionTypes = {
  // errors
  ENQUEUE_ERROR_NOTIFIC: 'ENQUEUE_ERROR_NOTIFIC',
  CLOSE_ERROR_NOTIFIC: 'CLOSE_ERROR_NOTIFIC',
  REMOVE_ERROR_NOTIFICS: 'REMOVE_ERROR_NOTIFICS',
  // successes
  ENQUEUE_SUCCESS_NOTIFIC: 'ENQUEUE_SUCCESS_NOTIFIC',
  CLOSE_SUCCESS_NOTIFIC: 'CLOSE_SUCCESS_NOTIFIC',
  REMOVE_SUCCESS_NOTIFICS: 'REMOVE_SUCCESS_NOTIFICS',
  // checkins notifications
  ENQUEUE_CHECKIN_NOTIFIC: 'ENQUEUE_CHECKIN_NOTIFIC',
  REMOVE_CHECKIN_NOTIFIC: 'REMOVE_CHECKIN_NOTIFIC',
  REMOVE_CHECKIN_NOTIFIC_BY_ID: 'REMOVE_CHECKIN_NOTIFIC_BY_ID',
};
