import { AppStoreTypeImt } from 'store';

import { SenderAvailabilityTypeList } from 'modules/booking/constants/senderAvailability';
import { TSenderAvailabilityImt } from 'modules/booking/interfaces';

export const dictionaryListsPath = ['bookingModule', 'senderAvailability'];

const selectSenderAvailability = (type: SenderAvailabilityTypeList) => (
  state: AppStoreTypeImt,
): TSenderAvailabilityImt => state.getIn([...dictionaryListsPath, type, 'data']);

const selectSenderAvailabilityLoading = (type: SenderAvailabilityTypeList) => (
  state: AppStoreTypeImt,
): boolean => state.getIn([...dictionaryListsPath, type, 'isLoading']);

export { selectSenderAvailability, selectSenderAvailabilityLoading };
