export const selectFamilyMembers = state => state.getIn(['newPerson', 'familyMembers', 'result']);
export const selectAddFamilyMemberResult = state =>
  state.getIn(['newPerson', 'familyMembers', 'addFamilyMemberResult']);
export const selectAddFamilyMemberActionLoading = state =>
  state.getIn(['newPerson', 'familyMembers', 'addFamilyMembersActionLoading']);
export const selectCreateMemberForFamilyMemberLoading = state =>
  state.getIn(['newPerson', 'familyMembers', 'createMemberForFamilyMemberLoading']);
export const selectCreateMemberForFamilyMemberResult = state =>
  state.getIn(['newPerson', 'familyMembers', 'createMemberForFamilyMemberResult']);
export const selectCreateMemberForFamilyMemberStepLoading = state =>
  state.getIn(['newPerson', 'familyMembers', 'createMemberForFamilyMemberStepLoading']);
export const selectFetchFamilyMembersLoading = state =>
  state.getIn(['newPerson', 'familyMembers', 'fetchFamilyMembersLoading']);
export const selectCreateMemberForFamilyMemberStepResult = state =>
  state.getIn(['newPerson', 'familyMembers', 'createMemberForFamilyMemberStepResult']);
