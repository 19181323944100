import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Box, Typography } from '@material-ui/core';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { getQueueColor } from 'common/utils';

import { IChartLineSettings, PeriodFilterType } from 'modules/reports/interfaces/common';

import { BarChart, ChartPeriodFilter, LoadingBackdrop } from 'common/components';
import { CustomAxisTick } from 'modules/reports/components/index';

import messages from 'modules/reports/messages/messages';
import commonMessages from 'common/messages/messages';
import {
  CheckInHistoryActivityItemImtList,
  ICheckinHistoryStatisticsDtoImt,
  CheckinHistoryChartDataImtList,
  ICheckInHistoryActivityDtoImt,
} from 'modules/reports/interfaces/checkInHistory';
import { colors } from 'common/ui/theme/default';
import { IBarChartData } from 'common/interfaces/barChart';
import { DEFAULT_DATE_FORMAT } from 'common/constants/dateFormats';

interface IProps {
  checkInHistoryChartData: CheckinHistoryChartDataImtList;
  statistics: ICheckinHistoryStatisticsDtoImt;
  checkInHistoryActivityData: ICheckInHistoryActivityDtoImt;
  isChartDataLoading: boolean;
  selectedPeriod: PeriodFilterType;
  onPeriodChange: (period: PeriodFilterType) => void;
}

const CheckInHistoryChart = ({
  checkInHistoryChartData,
  checkInHistoryActivityData,
  isChartDataLoading,
  selectedPeriod,
  onPeriodChange,
  statistics,
}: IProps): JSX.Element => {
  const [timezoneMoment] = useTimezoneMoment();

  const renderIntlMessage = useRenderIntlMessage();

  const chartLineData = useMemo(() => {
    return checkInHistoryChartData?.toJS() || [];
  }, [checkInHistoryChartData]);

  const chartLineSettings: IChartLineSettings[] = useMemo(() => {
    const data: CheckInHistoryActivityItemImtList = checkInHistoryActivityData
      ?.get('activity')
      ?.first();

    return data?.size
      ? data
          .map((item, index) => {
            return {
              key: item.getIn(['club', 'id']),
              name: item.getIn(['club', 'title']),
              isHidden: false,
              dataKey: item.getIn(['club', 'id']),
              fillColor: getQueueColor(index),
            };
          })
          .toJS()
      : [];
  }, [checkInHistoryActivityData]);

  const barChartData = useMemo<IBarChartData[]>(() => {
    return (
      statistics
        ?.get('statistics')
        ?.map(item => {
          return {
            percentage: item.get('percentage'),
            title: item.getIn(['club', 'title']),
          };
        })
        .toJS() || []
    );
  }, [statistics]);

  const getTickFormatter = () => {
    switch (selectedPeriod) {
      case PeriodFilterType.DAY:
        return (date: string) => timezoneMoment(date, 'YYYY-MM-DD').format('MMM D');
      case PeriodFilterType.WEEK:
        return (date: string) =>
          `${renderIntlMessage(commonMessages.weekLabel)} ${Number(date.slice(4))}`;
      case PeriodFilterType.MONTH:
        return (date: string) => timezoneMoment(date, 'MMM YYYY').format('MMM');
      default:
        return (date: string) => date;
    }
  };

  const getTooltipFormatter = (date: string) => {
    switch (selectedPeriod) {
      case PeriodFilterType.DAY:
        return timezoneMoment(date, 'YYYY-MM-DD').format(DEFAULT_DATE_FORMAT);
      case PeriodFilterType.WEEK:
        return `${renderIntlMessage(commonMessages.weekLabel)} ${Number(date.slice(4))}`;
      default:
        return date;
    }
  };

  const handleChangePeriodFilter = useCallback(
    (period: PeriodFilterType) => {
      onPeriodChange(period);
    },
    [onPeriodChange],
  );

  return (
    <Box p={2} position="relative">
      <Box display="flex" alignItems="center">
        <Typography variant="h4" noWrap>
          <FormattedMessage {...messages.membersCheckIn} />
        </Typography>

        <ChartPeriodFilter onPeriodChange={handleChangePeriodFilter} period={selectedPeriod} />
      </Box>

      <ResponsiveContainer width="100%" minHeight="300px">
        <AreaChart
          width={500}
          height={368}
          data={chartLineData}
          margin={{
            top: 24,
            right: 8,
            left: 0,
            bottom: 6,
          }}
          style={{ fontSize: 12 }}
        >
          <CartesianGrid fill={colors.input.disabled.backgroundColor} strokeDasharray="3 3" />

          <YAxis axisLine={false} tick={<CustomAxisTick axisLine={false} />} />
          <XAxis
            dataKey="date"
            axisLine={false}
            interval="preserveStartEnd"
            tickFormatter={getTickFormatter()}
          />

          <Tooltip labelFormatter={getTooltipFormatter} />

          {chartLineSettings.map(({ dataKey, name, fillColor, isHidden }, index) => (
            <Area
              type="monotone"
              dataKey={dataKey}
              name={name}
              stackId={index}
              strokeWidth="2px"
              activeDot={{ r: 5 }}
              hide={isHidden}
              fill={fillColor}
              stroke={getQueueColor(index)}
              key={dataKey}
            />
          ))}
        </AreaChart>
      </ResponsiveContainer>
      <BarChart data={barChartData} title={<FormattedMessage {...messages.kidZoneCheckIn} />} />
      <LoadingBackdrop isLoading={isChartDataLoading} />
    </Box>
  );
};

export default CheckInHistoryChart;
