import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Box, Button, Typography } from '@material-ui/core';

import { GlobalSearchGroups } from 'components/GlobalSearch/constants';

import { ModuleType } from 'modules/dictionaries/interfaces/helpInfo';

import permissionsMessages from 'modules/permissions/messages/messages';

interface IProps {
  sectionId: GlobalSearchGroups | ModuleType;
  viewAllPath?: string;
  sectionTitle: string | JSX.Element;
  children: JSX.Element[];
}

const searchState = {
  isNavigateFromGlobalSearch: true,
};

const SearchResultSection = ({
  sectionId,
  sectionTitle,
  viewAllPath,
  children,
}: IProps): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Box id={sectionId} mb={2}>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
        <Typography variant="button" color="textSecondary">
          {sectionTitle}
        </Typography>

        {!!viewAllPath && (
          <Button color="primary" onClick={() => navigate(viewAllPath, { state: searchState })}>
            <FormattedMessage {...permissionsMessages.viewAll} />
          </Button>
        )}
      </Box>

      {children}
    </Box>
  );
};

export default SearchResultSection;
