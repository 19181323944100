import { fromJS } from 'immutable';

import { actionTypes } from './constants';
import {
  ActionWaiverTypes,
  IAppliedSignWaiverPayload,
} from 'common/state/newPerson/waiver/interfaces';

const initialState = fromJS({
  result: [],
  signActionResult: null,
  applyActionResult: null,
  appliedSignWaiver: null,
  isLoading: false,
  isWaiverHTMLLoading: false,
  error: null,
});

const waiverReducer = (state = initialState, { type, payload }: ActionWaiverTypes) => {
  switch (type) {
    case actionTypes.GET_WAIVERS:
    case actionTypes.SIGN_WAIVERS:
      return state.set('result', fromJS(payload));
    case actionTypes.SET_IS_LOADING:
      return state.set('isLoading', payload);
    case actionTypes.FETCH_WAIVER_HTML_LOADING:
      return state.set('isWaiverHTMLLoading', payload);
    case actionTypes.APPLY_WAIVERS:
      return state
        .set('result', fromJS((payload as IAppliedSignWaiverPayload).waivers))
        .set('appliedSignWaiver', fromJS((payload as IAppliedSignWaiverPayload).appliedSignWaiver));
    case actionTypes.SET_SIGN_WAIVERS_ACTION_RESULT:
      return state.set('signActionResult', payload);
    case actionTypes.SET_APPLY_WAIVER_ACTION_RESULT:
      return state.set('applyActionResult', payload);
    case actionTypes.RESET_APPLY_WAIVER_ACTION_RESULT:
      return state.set('applyActionResult', null);
    case actionTypes.RESET_WAIVERS:
      return initialState;
    default:
      return state;
  }
};

export default waiverReducer;
