import { ITotalsItem } from '../interfaces/campaignsReport';
import messages from 'modules/reports/messages/messages';
import { AudienceFilterType, CampaignCounterType } from 'common/constants/campaign';
import commonMessages from 'common/messages/messages';
import campaigns from 'modules/crm/messages/campaigns';

export const actionTypes = {
  // Errors
  PUSH_REQUEST_ERROR: 'PUSH_REQUEST_ERROR',
  RESET_REQUEST_ERRORS: 'RESET_REQUEST_ERRORS',
  // Campaigns report
  FETCH_CAMPAIGNS_REPORT_LOADING: 'FETCH_CAMPAIGNS_REPORT_LOADING',
  FETCH_CAMPAIGNS_REPORT: 'FETCH_CAMPAIGNS_REPORT',
  EXPORT_CAMPAIGNS_REPORT_LOADING: 'EXPORT_CAMPAIGNS_REPORT_LOADING',
  EXPORT_CAMPAIGNS_REPORT: 'EXPORT_CAMPAIGNS_REPORT',
  RESET_CAMPAIGNS_EXPORT_SUCCESS: 'RESET_CAMPAIGNS_EXPORT_SUCCESS',

  // Campaign item
  FETCH_CAMPAIGN_LOADING: 'FETCH_CAMPAIGN_LOADING',
  FETCH_CAMPAIGN: 'FETCH_CAMPAIGN',
  EXPORT_CAMPAIGN_LOADING: 'EXPORT_CAMPAIGN_LOADING',
  EXPORT_CAMPAIGN: 'EXPORT_CAMPAIGN',
  EXPORT_CAMPAIGN_SUCCESS: 'EXPORT_CAMPAIGN_SUCCESS',
  RESET_EXPORT_SUCCESS: 'RESET_EXPORT_SUCCESS',
  RESET_CAMPAIGN: 'RESET_CAMPAIGN',

  // Campaign recipients
  FETCH_CAMPAIGN_RECEPIENTS_LOADING: 'FETCH_CAMPAIGN_RECEPIENTS_LOADING',
  FETCH_CAMPAIGN_RECEPIENTS: 'FETCH_CAMPAIGN_RECEPIENTS',
};

export enum StatisticsNames {
  RECIPIENTS = 'RECIPIENTS',
  SUCCESSFUL_DELIVERIES = 'SUCCESSFUL_DELIVERIES',
  OPEN_RATE = 'OPEN_RATE',
  TOTAL_OPENS = 'TOTAL_OPENS',
  CLICK_RATE = 'CLICK_RATE',
  TOTAL_CLICKS = 'TOTAL_CLICKS',
  BOUNCED = 'BOUNCED',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
}

export const reportStatistics: ITotalsItem[] = [
  {
    title: messages.recipientsTotalsTitle,
    id: StatisticsNames.RECIPIENTS,
    amount: 0,
    units: '',
  },
  {
    title: messages.successfulDeliveriesTotalsTitle,
    id: StatisticsNames.SUCCESSFUL_DELIVERIES,
    amount: 0,
    units: '%',
  },
  {
    title: messages.openRateTotalsTitle,
    id: StatisticsNames.OPEN_RATE,
    amount: 0,
    units: '%',
  },
  {
    title: messages.totalOpensTotalsTitle,
    id: StatisticsNames.TOTAL_OPENS,
    amount: 0,
    units: '',
  },
  {
    title: messages.clickRateTotalsTitle,
    id: StatisticsNames.CLICK_RATE,
    amount: 0,
    units: '%',
  },
  {
    title: messages.totalClicksTotalsTitle,
    id: StatisticsNames.TOTAL_CLICKS,
    amount: 0,
    units: '',
  },
  {
    title: messages.bouncedTotalsTitle,
    id: StatisticsNames.BOUNCED,
    amount: 0,
    units: '%',
  },
  {
    title: messages.unsubscribedTotalsTitle,
    id: StatisticsNames.UNSUBSCRIBED,
    amount: 0,
    units: '%',
  },
];

export const audienceTypesValues = [
  {
    title: campaigns.leadStatusFilter,
    type: AudienceFilterType.LEAD_STATUS,
  },
  {
    title: campaigns.locationsFilter,
    type: AudienceFilterType.LOCATIONS,
  },
  {
    title: campaigns.genderFilter,
    type: AudienceFilterType.GENDER,
  },
  {
    title: campaigns.ageGroupFilter,
    type: AudienceFilterType.AGE_GROUP,
  },
  {
    title: campaigns.servicesFilter,
    type: AudienceFilterType.BASED_ON_SERVICES,
  },
  {
    title: campaigns.cameFromFilter,
    type: AudienceFilterType.CAME_FROM,
  },
];

export const durations = [
  {
    message: commonMessages.minutesOption,
    value: CampaignCounterType.MINUTE,
  },
  {
    message: commonMessages.daysOption,
    value: CampaignCounterType.DAY,
  },
  {
    message: commonMessages.weeksOption,
    value: CampaignCounterType.WEEK,
  },
  {
    message: commonMessages.monthsOption,
    value: CampaignCounterType.MONTH,
  },
];
