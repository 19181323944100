import axios from 'axios';
import ApiService from './ApiService';
import { IDictionaryOptions, TAllergyListItem } from 'common/interfaces/dictionary';
import { IPageMeta, IPaginatedData } from 'common/interfaces/pagination';
import { DictionaryList } from 'common/constants/dictionaryConstants';
import { IDictionaryCommonItem } from 'modules/dictionaries/interfaces/interfaces';

export type ResultListItems = IDictionaryCommonItem[] | TAllergyListItem[];

export interface IResult {
  data: ResultListItems;
  meta?: IPageMeta;
}

export default class MemberPortalDictionaryListsService extends ApiService {
  public fetchDictionaryByName = async (
    dictionary: DictionaryList,
    options?: IDictionaryOptions,
  ): Promise<IResult> => {
    switch (dictionary) {
      case DictionaryList.INSURANCE_COMPANY:
        return this.getInsuranceCompanies(options);
      case DictionaryList.ALLERGIES:
        return this.getAllergies(options);

      default:
        throw new Error(`Unsupported dictionary list: ${dictionary}`);
    }
  };

  public getAllergies = (options?: IDictionaryOptions): Promise<IPaginatedData<TAllergyListItem>> =>
    axios.get(`/api/v1/member-portal/profile/allergies/dictionary`, { params: options });

  public getInsuranceCompanies = (
    options?: IDictionaryOptions,
  ): Promise<IPaginatedData<IDictionaryCommonItem>> =>
    axios.get('/api/v1/member-portal/profile/insurance-company/dictionary', { params: options });
}
