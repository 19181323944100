import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Box, makeStyles, Theme, Typography } from '@material-ui/core';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import cx from 'classnames';

import { PeakModules } from 'common/constants/peakModules';

import { selectLeadsAssignLoading } from 'modules/crm/state/leads/selectors';

import { ISalesPerson } from 'modules/crm/interfaces/tasks';
import { AlertTypes } from 'common/interfaces/alerts';

import { getInitials } from 'helpers/common';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import useCheckEmployeeAccess from 'modules/crm/hooks/useCheckEmployeeAccess';
import { getRequiredMessage } from 'common/constants/globalConstants';

import { Alert, DialogComponent, UserAutocomplete } from 'common/components';
import { AvatarGroup } from '@material-ui/lab';
import { List as ImmutableList } from 'immutable';
import { ILeadImt } from '../../../interfaces/leads';
// messages
import { FormattedMessage } from 'react-intl';
import inputLabels from 'common/messages/inputLabels';
import messages from 'common/messages/messages';
import leadsMessages from 'modules/crm/messages/leads';
import { useRecommendedFields } from 'common/hooks/useRecommendedFields';
import { selectProfileRequiredFields } from 'common/state/settings/selectors';
import {
  FieldNecessity,
  RequiredProfileInfo,
} from 'modules/corporate-settings/constants/requiredFields';
import { showRecommendedFieldsModal } from 'common/state/modals/actions';
import { useAppDispatch } from 'store/hooks';
import { selectIsSubmittedRecommendedFieldsModal } from 'common/state/modals/selectors';

interface IProps {
  openModal: boolean;

  selectedLeads: ImmutableList<ILeadImt>;

  onClose: () => void;
  onSubmit: (salesperson: ISalesPerson) => void;
  module: PeakModules.Crm | PeakModules.PersonalTrainingCrm;
}

const initFormValues = {
  salesperson: null,
};

const useStyles = makeStyles((theme: Theme) => ({
  avatarRow: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  avatarGroup: {
    flexWrap: 'wrap',
  },
  avatar: {
    width: '30px',
    height: '30px',
  },
  avatarWrap: {
    position: 'relative',
    flex: 'none',
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.light,
  },
  avatarInitial: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    fontSize: '0.675rem',
    fontWeight: 700,
    color: theme.palette.darkBackground.light,
  },
  name: {
    marginLeft: theme.spacing(1),
  },
}));

const ChangeSalespersonModal = (props: IProps): JSX.Element => {
  const { openModal, onClose, selectedLeads, onSubmit, module } = props;

  const dispatch = useAppDispatch();

  const isLoading = useSelector(selectLeadsAssignLoading());
  const profileRequiredFields = useSelector(selectProfileRequiredFields);
  const isSubmitted = useSelector(selectIsSubmittedRecommendedFieldsModal);

  const classes = useStyles();

  const { checkRecommendedFieldsAreNotEmpty, profileRecommendedFields } = useRecommendedFields();

  const handleFormSubmit = formValues => {
    const hasEmptyRecommendedFields = checkRecommendedFieldsAreNotEmpty(formValues);

    if (hasEmptyRecommendedFields) {
      dispatch(showRecommendedFieldsModal(true));
    } else {
      onSubmit(formValues.salesperson);
    }
  };

  const formMethods = useForm({
    defaultValues: initFormValues,
    resolver: yupResolver(
      yup.object().shape({
        salesperson: yup
          .object()
          .nullable()
          .when('salesperson1', {
            is: () =>
              profileRequiredFields.get(RequiredProfileInfo.Salesperson) ===
              FieldNecessity.Required,
            then: schema => schema.required(getRequiredMessage),
            otherwise: schema => schema,
          }),
      }),
    ),
    mode: 'onBlur',
  });

  const { control, handleSubmit, errors, getValues } = formMethods;

  useEffect(() => {
    if (isSubmitted) {
      const { salesperson } = getValues();

      onSubmit(salesperson);
      dispatch(showRecommendedFieldsModal(false));
    }
  }, [dispatch, getValues, isSubmitted, onSubmit]);

  const renderIntlMessage = useRenderIntlMessage();

  const {
    handleChangeSalesperson,
    isFetchEmployeeAccessInfoLoading,
    isEmployeeHasNoPermissions,
  } = useCheckEmployeeAccess(module, true);

  return (
    <DialogComponent
      title={<FormattedMessage {...leadsMessages.changeSalesTitle} />}
      submitBtnTitle={<FormattedMessage {...messages.changeBtn} />}
      size="xs"
      isOpen={openModal}
      onClose={onClose}
      onSubmit={handleSubmit(handleFormSubmit)}
      loading={isLoading || isFetchEmployeeAccessInfoLoading}
    >
      <form>
        <Box className={classes.avatarRow}>
          {!!selectedLeads && !!selectedLeads.size && (
            <>
              <AvatarGroup className={classes.avatarGroup} max={selectedLeads.size}>
                {selectedLeads.map(leadItem => {
                  const initials = getInitials(leadItem.get('firstName'), leadItem.get('lastName'));
                  const imageUrl =
                    leadItem.getIn(['image', 'filePath']) || leadItem.get('imageUrl');

                  return (
                    <Box className={cx(classes.avatar, classes.avatarWrap)}>
                      {initials && !imageUrl ? (
                        <Box className={classes.avatarInitial}>{initials}</Box>
                      ) : (
                        <Avatar
                          key={leadItem.get('id')}
                          className={classes.avatar}
                          alt={`${leadItem.get('firstName')} ${leadItem.get('lastName')}`}
                          src={imageUrl}
                        />
                      )}
                    </Box>
                  );
                })}
              </AvatarGroup>
              {selectedLeads.size === 1 && (
                <Typography className={classes.name}>
                  {`${selectedLeads.get(0).get('firstName')} ${selectedLeads
                    .get(0)
                    .get('lastName')}`}
                </Typography>
              )}
            </>
          )}
        </Box>

        <Controller
          control={control}
          name="salesperson"
          render={({ value, onChange }) => (
            <UserAutocomplete
              module={module}
              label={<FormattedMessage {...inputLabels.salesperson} />}
              fullWidth
              value={value}
              onChange={option => {
                onChange(option);

                if (option) {
                  handleChangeSalesperson(option);
                }
              }}
              error={!!errors.salesperson}
              helperText={renderIntlMessage(errors.salesperson?.message)}
              recommended={Boolean(profileRecommendedFields?.get(RequiredProfileInfo.Salesperson))}
            />
          )}
        />

        {isEmployeeHasNoPermissions && (
          <Box mt={2}>
            <Alert
              severity={AlertTypes.Warning}
              title={<FormattedMessage {...leadsMessages.employeeHasNoPermissions} />}
            />
          </Box>
        )}
      </form>
    </DialogComponent>
  );
};

export default React.memo(ChangeSalespersonModal);
