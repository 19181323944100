import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Grid, SvgIcon, makeStyles } from '@material-ui/core';

import { ReactivateMembershipSteps } from './constants';

import { CustomTheme } from 'common/ui/interfaces';

import { ArrowCircleIcon, ArrowCircleLeftIcon } from 'img/icons';
import { ButtonWithCircularProgress } from 'common/components/index';

import messages from 'common/messages/messages';

const useStyles = makeStyles((theme: CustomTheme) => ({
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 0),
  },
  btnIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
}));

interface IProps {
  currentStep: ReactivateMembershipSteps;
  onBack: () => void;
  onNext: () => void;
  goBack: () => void;
  disabled: boolean;
}

const Footer = ({ currentStep, onNext, goBack, onBack, disabled }: IProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid container spacing={1} className={classes.footer}>
      <Grid item>
        <Button color="primary" onClick={goBack}>
          <FormattedMessage {...messages.cancelBtn} />
        </Button>
      </Grid>

      {currentStep !== ReactivateMembershipSteps.PackagesSelection && (
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={onBack}
            startIcon={
              <SvgIcon className={classes.btnIcon}>
                <ArrowCircleLeftIcon />
              </SvgIcon>
            }
          >
            <FormattedMessage {...messages.backBtn} />
          </Button>
        </Grid>
      )}

      <Grid item>
        {currentStep === ReactivateMembershipSteps.PastDues ? (
          <ButtonWithCircularProgress type="submit" onClick={onNext} isSubmitting={false}>
            <FormattedMessage {...messages.proceedToPaymentBtn} />
          </ButtonWithCircularProgress>
        ) : (
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={onNext}
            endIcon={
              <SvgIcon className={classes.btnIcon}>
                <ArrowCircleIcon />
              </SvgIcon>
            }
            disabled={disabled}
          >
            <FormattedMessage {...messages.nextBtn} />
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default Footer;
