import { defineMessages } from 'react-intl';

export default defineMessages({
  signatureLineLabel: {
    id: 'app.modules.pos-settings.receipt.labels.signatureLine',
    defaultMessage: 'Signature line',
  },
  distributionMethodLabel: {
    id: 'app.modules.pos-settings.receipt.labels.distributionMethod',
    defaultMessage: 'Receipt distribution method',
  },
  topFieldsLabel: {
    id: 'app.modules.pos-settings.receipt.labels.topFields',
    defaultMessage: 'Top fields',
  },
  bottomFieldsLabel: {
    id: 'app.modules.pos-settings.receipt.labels.bottomFields',
    defaultMessage: 'Bottom fields',
  },
  // field labels
  textFieldLabel: {
    id: 'app.modules.pos-settings.receipt.labels.textField',
    defaultMessage: 'Text',
  },
  imageFieldLabel: {
    id: 'app.modules.pos-settings.receipt.labels.fields.imageField',
    defaultMessage: 'Upload image',
  },
  discountQrFieldLabel: {
    id: 'app.modules.pos-settings.receipt.labels.fields.discountQrField',
    defaultMessage: 'Discount code/QR code',
  },
  customPaymentMethodsFieldLabel: {
    id: 'app.modules.pos-settings.receipt.labels.fields.customPaymentMethodsField',
    defaultMessage: 'Custom payment method/Gift card/Gift certificate barcode',
  },
  clubNameFieldLabel: {
    id: 'app.modules.pos-settings.receipt.labels.fields.clubNameField',
    defaultMessage: 'Club name',
  },
  clubAddressFieldLabel: {
    id: 'app.modules.pos-settings.receipt.labels.fields.clubAddressField',
    defaultMessage: 'Club address',
  },
  customerFieldLabel: {
    id: 'app.modules.pos-settings.receipt.labels.fields.customerField',
    defaultMessage: 'Customer',
  },
  emailFieldLabel: {
    id: 'app.modules.pos-settings.receipt.labels.fields.emailField',
    defaultMessage: 'Email',
  },
  phoneFieldLabel: {
    id: 'app.modules.pos-settings.receipt.labels.fields.phoneField',
    defaultMessage: 'Phone',
  },
  addressFieldLabel: {
    id: 'app.modules.pos-settings.receipt.labels.fields.addressField',
    defaultMessage: 'Address',
  },
  salespersonFieldLabel: {
    id: 'app.modules.pos-settings.receipt.labels.fields.salespersonField',
    defaultMessage: 'Salesperson',
  },
  insuranceCompanyFieldLabel: {
    id: 'app.modules.pos-settings.receipt.labels.fields.insuranceCompanyField',
    defaultMessage: 'Insurance company',
  },
  insuranceMemberFieldLabel: {
    id: 'app.modules.pos-settings.receipt.labels.fields.insuranceMemberField',
    defaultMessage: 'Insurance member',
  },
  membershipFieldLabel: {
    id: 'app.modules.pos-settings.receipt.labels.fields.membershipField',
    defaultMessage: 'Membership',
  },
  waiverFieldLabel: {
    id: 'app.modules.pos-settings.receipt.labels.fields.waiverField',
    defaultMessage: 'Waiver',
  },
  noAvailabilitiesFoundMsg: {
    id: 'app.modules.pos-settings.receipt.availabilities.noDataFoundMsg',
    defaultMessage: 'No clubs found',
  },
});
