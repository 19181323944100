import { AppStoreTypeImt } from 'store';
import { DictionaryItem, DictionaryList } from 'common/constants/dictionaryConstants';
import { IPageMetaImt } from 'common/interfaces/pagination';
import { ImmutableObject } from 'common/state/interfaces';
import { CrmCampaignDto } from 'common/interfaces/dictionary';

export const dictionaryListsPath = ['dictionary', 'dictionaryLists'];

export const selectDictionaryList = (dictionary: DictionaryList) => (state: AppStoreTypeImt) =>
  state.getIn([...dictionaryListsPath, dictionary, 'list']);

export const selectIsDictionaryListLoading = (dictionary: DictionaryList) => (
  state: AppStoreTypeImt,
): boolean => state.getIn([...dictionaryListsPath, dictionary, 'isLoading']);

export const selectDictionaryListMeta = (dictionary: DictionaryList) => (
  state: AppStoreTypeImt,
): IPageMetaImt => state.getIn([...dictionaryListsPath, dictionary, 'meta']);

// Dictionary items

export const dictionaryItemsPath = ['dictionary', 'dictionaryItems'];

export const selectDictionaryItem = (dictionary: DictionaryItem) => (
  state: AppStoreTypeImt,
): ImmutableObject<CrmCampaignDto> => state.getIn([...dictionaryItemsPath, dictionary, 'item']);

export const selectDictionaryItemLoading = (dictionary: DictionaryItem) => (
  state: AppStoreTypeImt,
): boolean => state.getIn([...dictionaryItemsPath, dictionary, 'isLoading']);
