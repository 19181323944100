import { fromJS } from 'immutable';
import { ActionPrimaryInfoTypes } from 'common/state/newPerson/primaryInfo/actions';
import { actionTypes } from 'common/state/newPerson/scheduleTour/constants';

const initialState = fromJS({
  isLoading: false,
  saveTourActionResult: null,
});

const scheduleTourReducer = (state = initialState, { type, payload }: ActionPrimaryInfoTypes) => {
  switch (type) {
    case actionTypes.SET_IS_LOADING:
      return state.set('isLoading', payload);
    case actionTypes.SAVE_TOUR_ACTION_RESULT:
      return state.set('saveTourActionResult', payload);
    case actionTypes.RESET_SAVE_TOUR_ACTION_RESULT:
      return state.set('saveTourActionResult', null);
    default:
      return state;
  }
};

export default scheduleTourReducer;
