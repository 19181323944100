import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import { Box, Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';

import messages from 'common/modals/BarcodeScanningModal/messages';
import { DialogComponent, Input, LoadingBackdrop } from 'common/components';
import WebHidApi from 'services/webHidApi';
import commonMessages from 'common/messages/messages';
import { ReactComponent as BarcodeIcon } from 'img/icons/barcode.svg';
import { getRequiredMessage } from 'common/constants/globalConstants';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

interface IDeviceScanningFormValues {
  barcode: string;
}

interface IDeviceScanningModalProps {
  isOpen: boolean;
  setIsOpenModal: Dispatch<SetStateAction<boolean>>;
  setScannerStr: Dispatch<SetStateAction<string>>;
}

const { BarcodeScanner } = WebHidApi;

const useStyles = makeStyles(() => ({
  barcodeScanningModal: {
    minHeight: '175px',
  },
  loader: {
    backgroundColor: 'inherit !important',
  },
  loaderWrapper: {
    minHeight: '50px',
    width: '100%',
    position: 'relative',
  },
  waitingForScanTitle: {
    paddingTop: '20px',
  },
  barcodeIcon: {
    width: '150px',
    height: '90px',
    paddingBottom: '10px',
  },
  infoTitle: {
    paddingTop: '10px',
  },
}));

const validationSchema = yup.object().shape({
  barcode: yup
    .string()
    .trim()
    .required(getRequiredMessage),
});

const BARCODE_SCANNING_FORM_ID = 'barcode-scanning-form';

const BarcodeScanningModal = (props: IDeviceScanningModalProps): JSX.Element => {
  const { isOpen, setScannerStr, setIsOpenModal } = props;

  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();

  const renderIntlMessage = useRenderIntlMessage();

  const formMethods = useForm({
    defaultValues: {
      barcode: '',
    },
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
    mode: 'onChange',
  });

  const {
    handleSubmit,
    control,
    setValue,
    errors,
    formState: { isValid },
  } = formMethods;

  const setIsDisabledAutoCheckin = useCallback(
    (isDisableAutoCheckin: boolean) => {
      if (location.pathname.includes('/front-desk')) {
        BarcodeScanner.setIsDisabledAutoCheckin = isDisableAutoCheckin;
      }
    },
    [location],
  );

  const classes = useStyles();

  useEffect(() => {
    const onDisconnectBarcodeScanner = () => {
      const isOpenedDevice = BarcodeScanner.getOpenedDevices.length;
      if (!isOpenedDevice) {
        setIsDisabledAutoCheckin(false);
        setIsOpenModal(false);
      }
    };

    const onBarcodeScannerString = (scannerString: string) => {
      setIsLoading(false);
      setValue('barcode', scannerString);
    };

    setIsDisabledAutoCheckin(true);

    BarcodeScanner.subscribeOnScannerString(onBarcodeScannerString);
    BarcodeScanner.subscribeOnDisconnectDevice(onDisconnectBarcodeScanner);

    return () => {
      BarcodeScanner.unsubscribeOnScannerString(onBarcodeScannerString);
      BarcodeScanner.unsubscribeOnDisconnectDevice(onDisconnectBarcodeScanner);
      setIsDisabledAutoCheckin(false);
    };
  }, [setValue, setIsDisabledAutoCheckin, setIsLoading, setIsOpenModal]);

  const onApplyScannerString = (values: IDeviceScanningFormValues) => {
    if (isValid) {
      setScannerStr(values.barcode);
      setIsOpenModal(false);
    }
  };

  const onCloseDeviceScanningModal = () => {
    setIsOpenModal(false);
  };

  return (
    <DialogComponent
      formId={BARCODE_SCANNING_FORM_ID}
      size="xs"
      isOpen={isOpen}
      submitBtnTitle={<FormattedMessage {...commonMessages.applyBtn} />}
      title={
        <FormattedMessage
          {...messages.deviceScanningModalTitle}
          values={{ deviceName: BarcodeScanner.getOpenedDevices[0]?.productName }}
        />
      }
      onSubmit={handleSubmit(onApplyScannerString)}
      onClose={onCloseDeviceScanningModal}
    >
      <form id={BARCODE_SCANNING_FORM_ID}>
        <Box
          className={classes.barcodeScanningModal}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          {isLoading ? (
            <>
              <Box className={classes.loaderWrapper}>
                <LoadingBackdrop extraClassName={classes.loader} isLoading={isLoading} />
              </Box>
              <Typography
                className={classes.waitingForScanTitle}
                variant="subtitle1"
                color="textPrimary"
              >
                <FormattedMessage {...commonMessages.waitingForScan} />
              </Typography>
            </>
          ) : (
            <>
              <BarcodeIcon className={classes.barcodeIcon} viewBox="0 0 1200 500" />
              <Controller
                name="barcode"
                control={control}
                render={({ value, onChange }) => (
                  <Input
                    autoFocus
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={value}
                    onChange={onChange}
                    error={Boolean(errors.barcode)}
                    helperText={renderIntlMessage(errors.barcode?.message)}
                  />
                )}
              />
              <Typography className={classes.infoTitle} variant="body1" color="textSecondary">
                <FormattedMessage {...commonMessages.contactTechnicalSupport} />
              </Typography>
            </>
          )}
        </Box>
      </form>
    </DialogComponent>
  );
};

export default BarcodeScanningModal;
