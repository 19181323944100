import React from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import moment from 'moment-timezone';
import { Box, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';

import { formatPersonalCode } from 'common/utils';

import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import useTimeFromCheckIn from 'modules/kid-zone/hooks/useTimeFromCheckIn';

import { IKidProfileImt } from 'modules/kid-zone/interfaces';

import { ReactComponent as ClockIcon } from 'img/icons/clock.svg';
import { ReactComponent as Barcode } from 'img/icons/barcode.svg';
import { ReactComponent as EditIcon } from 'img/icons/pencil_deprecated.svg';
import { Button } from 'common/components';

import kidZoneMessages from 'modules/kid-zone/messages';
import componentMessages from 'common/components/PersonProfile/messages';
import frontDeskMessages from 'modules/front-desk/messages';
import messages from 'common/messages/messages';
import { DEFAULT_DATE_FORMAT } from 'common/constants/dateFormats';

const useStyles = makeStyles((theme: Theme) => ({
  profileName: {
    fontSize: ({ isLessThanLaptop }: Partial<IKidProfileDataProps>) =>
      isLessThanLaptop ? '1.25rem' : '1.375rem',
  },
  additionalInfo: {
    marginTop: theme.spacing(1),
  },
  smallBarcodeIcon: {
    width: 14,
    height: 14,
  },
  checkInTitle: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary,

    '&:first-child': {
      marginRight: ({ isLessThanLaptop }: Partial<IKidProfileDataProps>) =>
        theme.spacing(!isLessThanLaptop ? 3 : 0),
    },

    '&:first-child span': {
      marginRight: theme.spacing(1),
    },

    '&.checkinTitle__small': {
      fontSize: '0.75rem',
    },
  },
  checkInTime: {
    margin: theme.spacing(0, 1, 0, 0.5),
  },
  editIcon: {
    marginRight: theme.spacing(0.75),

    '& svg': {
      width: 16,
      height: 16,
    },
  },
}));

export interface IKidProfileDataProps {
  profile: IKidProfileImt;
  openEditProfile?: (id: string) => void;
  isLessThanTablet?: boolean;
  isLessThanXs?: boolean;
  isLessThanLaptop?: boolean;
}

const KidProfileGeneralInfo = ({
  profile,
  openEditProfile,
  isLessThanTablet,
  isLessThanLaptop,
  isLessThanXs,
}: IKidProfileDataProps): JSX.Element => {
  const [timezoneMoment] = useTimezoneMoment();
  const { elapsedTimeLabel } = useTimeFromCheckIn(profile.getIn(['lastCheckinDto', 'date']));

  const classes = useStyles({ isLessThanTablet, isLessThanLaptop });

  const onEditProfileClick = () => openEditProfile(profile.get('id'));

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      pl={isLessThanTablet && !isLessThanXs ? '116px' : 0}
      minHeight={isLessThanTablet && !isLessThanXs ? '102px' : 'initial'}
    >
      <Box>
        <Typography variant="h3" component="h4" className={classes.profileName}>
          {profile.get('firstName')} {profile.get('lastName')}
        </Typography>

        <Typography color="textSecondary" className={classes.additionalInfo}>
          <FormattedMessage
            {...frontDeskMessages.ID}
            values={{
              personId: formatPersonalCode(profile.get('personalCode')),
            }}
          />
        </Typography>

        <Typography color="textSecondary" className={classes.additionalInfo}>
          <FormattedMessage
            {...kidZoneMessages.childAge}
            values={{
              year: moment().diff(timezoneMoment(profile.get('birthday'), 'YYYY-MM-DD'), 'years'),
              birthday: timezoneMoment(profile.get('birthday'), 'YYYY-MM-DD').format(
                DEFAULT_DATE_FORMAT,
              ),
            }}
          />
        </Typography>
      </Box>

      <Box display="flex" flexDirection="column" alignItems="flex-end" gridGap="8px">
        <Box
          display="flex"
          flexDirection={isLessThanLaptop ? 'column-reverse' : 'initial'}
          alignItems="flex-end"
        >
          {elapsedTimeLabel && (
            <Typography
              className={cx(classes.checkInTitle, {
                checkinTitle__small: isLessThanLaptop,
              })}
              component="p"
              variant="button"
            >
              <Typography component="span" variant="inherit">
                {elapsedTimeLabel}
              </Typography>

              <ClockIcon className={cx({ [classes.smallBarcodeIcon]: isLessThanLaptop })} />
            </Typography>
          )}

          <Typography
            className={cx(classes.checkInTitle, {
              checkinTitle__small: isLessThanLaptop,
            })}
            variant="button"
            component="p"
          >
            {!isLessThanLaptop && (
              <Typography component="span" variant="inherit">
                <FormattedMessage {...componentMessages.checkedIn} />
              </Typography>
            )}

            <Typography component="span" variant="inherit" className={classes.checkInTime}>
              {timezoneMoment(profile.getIn(['lastCheckinDto', 'date'])).format('h:mm A')}
            </Typography>

            <Barcode className={cx({ [classes.smallBarcodeIcon]: isLessThanLaptop })} />
          </Typography>
        </Box>

        <>
          {!isLessThanLaptop ? (
            <Button
              color="primary"
              classes={{ startIcon: classes.editIcon }}
              startIcon={<EditIcon />}
              onClick={onEditProfileClick}
            >
              <FormattedMessage {...messages.editProfileBtn} />
            </Button>
          ) : (
            <IconButton
              component={EditIcon}
              color="primary"
              size="small"
              onClick={onEditProfileClick}
            />
          )}
        </>
      </Box>
    </Box>
  );
};

export default KidProfileGeneralInfo;
