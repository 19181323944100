import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';

import { formatNumberToPrice } from 'common/utils';

import { CustomTheme } from 'common/ui/interfaces';

interface IRenderTotalsItemProps {
  title: string | JSX.Element;
  amount: number | string;
  isBoldFont?: boolean;
  isErrorFont?: boolean;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  dotsWrapper: {
    flex: 1,
  },
  dots: {
    flex: 1,
    margin: theme.spacing(0, 1.5),
    borderBottom: `1px dashed ${theme.palette.borderColor.main}`,
  },
}));

const TotalsItem = ({
  title,
  amount,
  isBoldFont,
  isErrorFont,
}: IRenderTotalsItemProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Box display="flex">
      <Typography variant={isBoldFont ? 'h5' : 'body1'}>{title}</Typography>

      <Box className={classes.dots} />

      <Typography
        color={isErrorFont ? 'error' : 'initial'}
        variant={isBoldFont && !isErrorFont ? 'h5' : 'body1'}
      >
        {amount < 0
          ? `-$${formatNumberToPrice(Math.abs(Number(amount)))}`
          : `$${formatNumberToPrice(amount)}`}
      </Typography>
    </Box>
  );
};

export default TotalsItem;
