import { defineMessages } from 'react-intl';

export default defineMessages({
  fileCountError: {
    id: 'app.common.fileErrors.fileCountError',
    defaultMessage: 'File must be 1 or more',
  },
  fileTooLargeError: {
    id: 'app.common.fileErrors.fileTooLargeError',
    defaultMessage: 'File too large',
  },
  fileTooLargeWithSizeError: {
    id: 'app.common.fileErrors.fileTooLargeWithSizeError',
    defaultMessage: 'File is larger than 30MB',
  },
  fileTooSmallError: {
    id: 'app.common.fileErrors.fileTooSmallError',
    defaultMessage: 'File too small',
  },
  tooManyFilesError: {
    id: 'app.common.fileErrors.tooManyFilesError',
    defaultMessage: 'Too many files',
  },
  fileInvalidTypeError: {
    id: 'app.common.fileErrors.fileInvalidTypeError',
    defaultMessage: 'File invalid type',
  },
  duplicateFileError: {
    id: 'app.common.fileErrors.duplicateFileError',
    defaultMessage: 'Duplicate file',
  },
});
