import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { actionTypes } from '../../constants/registerStatusReport';
import { initialState } from 'common/constants/initialState';

const initStateRegisterStatusReport = fromJS({
  list: [],
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
  isLoading: false,
  totals: {},
  exportReportLoading: false,
  exportReportResult: {},
});

function registerStatusReportReducer(state = initStateRegisterStatusReport, action) {
  switch (action.type) {
    case actionTypes.FETCH_REGISTER_STATUS_REPORT_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_REGISTER_STATUS_REPORT:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta))
        .set('listLoading', false);
    case actionTypes.EXPORT_REGISTER_STATUS_REPORT_LOADING:
      return state.set('exportReportLoading', fromJS(action.payload));
    case actionTypes.EXPORT_REGISTER_STATUS_REPORT:
      return state.set('exportReportResult', fromJS(action.payload));
    case actionTypes.RESET_REGISTER_STATUS_EXPORT_SUCCESS:
      return state.set('exportReportResult', fromJS({}));
    default:
      return state;
  }
}

const initStateRegisterActionsStatus = fromJS({
  invoicesCountFromRegister: initialState,

  closeRegisterAction: initialState,
  reconcileRegisterSalesAction: initialState,
});

function registerItemReducer(state = initStateRegisterActionsStatus, action) {
  switch (action.type) {
    // close register
    case actionTypes.CLOSE_REGISTER_ITEM_LOADING:
      return state.setIn(['closeRegisterAction', 'isLoading'], action.payload);
    case actionTypes.CLOSE_REGISTER_ITEM:
      return state.setIn(['closeRegisterAction', 'result'], fromJS(action.payload));
    case actionTypes.RESET_CLOSE_REGISTER_ITEM_ACTION_RESULT:
      return state.set('closeRegisterAction', fromJS(initialState));
    // fetch invoices count from register
    case actionTypes.FETCH_INVOICES_COUNT_FROM_REGISTER_LOADING:
      return state.setIn(['invoicesCountFromRegister', 'isLoading'], action.payload);
    case actionTypes.FETCH_INVOICES_COUNT_FROM_REGISTER_SUCCESS:
      return state.setIn(['invoicesCountFromRegister', 'result'], fromJS(action.payload));
    case actionTypes.RESET_INVOICES_COUNT_FROM_REGISTER:
      return state.set('invoicesCountFromRegister', fromJS(initialState));
    // reconcile register sales
    case actionTypes.RECONCILE_REGISTER_ITEM_LOADING:
      return state.setIn(['reconcileRegisterSalesAction', 'isLoading'], action.payload);
    case actionTypes.RECONCILE_REGISTER_ITEM:
      return state.setIn(['reconcileRegisterSalesAction', 'result'], fromJS(action.payload));
    case actionTypes.RESET_RECONCILE_REGISTER_ITEM_ACTION_RESULT:
      return state.set('reconcileRegisterSalesAction', fromJS(initialState));
    default:
      return state;
  }
}

export default combineReducers({
  registerStatusList: registerStatusReportReducer,
  registerItem: registerItemReducer,
});
