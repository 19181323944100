import { defineMessages } from 'react-intl';

export default defineMessages({
  notificationsTitle: {
    id: 'app.modules.notifications.Notifications.notificationsTitle',
    defaultMessage: 'Notifications',
  },

  newNotificationTitle: {
    id: 'app.modules.notifications.Notifications.newNotificationTitle',
    defaultMessage: 'New Trigger',
  },
  editNotificationTitle: {
    id: 'app.modules.notifications.Notifications.editNotificationTitle',
    defaultMessage: 'Edit {trigger} Trigger',
  },

  deliveryTypesLabel: {
    id: 'app.modules.notifications.Notifications.deliveryTypesLabel',
    defaultMessage: 'Delivery Types',
  },

  // Triggers
  newEventLabel: {
    id: 'app.modules.notifications.trigger.newEventLabel',
    defaultMessage: 'New Event',
  },
  editEventLabel: {
    id: 'app.modules.notifications.trigger.editEventLabel',
    defaultMessage: 'Edit Event',
  },
  cancelEventLabel: {
    id: 'app.modules.notifications.trigger.cancelEventLabel',
    defaultMessage: 'Cancel Event',
  },
  redeemEventLabel: {
    id: 'app.modules.notifications.trigger.redeemEventLabel',
    defaultMessage: 'Redeem Event',
  },
  remindEventLabel: {
    id: 'app.modules.notifications.trigger.remindEventLabel',
    defaultMessage: 'Remind Event',
  },
  deleteEventLabel: {
    id: 'app.modules.notifications.trigger.deleteEventLabel',
    defaultMessage: 'Delete Event',
  },
  invoiceLabel: {
    id: 'app.modules.notifications.trigger.invoiceLabel',
    defaultMessage: 'Invoice',
  },
  contractLabel: {
    id: 'app.modules.notifications.trigger.contractLabel',
    defaultMessage: 'Contract',
  },
  waiverLabel: {
    id: 'app.modules.notifications.trigger.waiverLabel',
    defaultMessage: 'Waiver',
  },

  frontdeskAppointmentEventNewLabel: {
    id: 'app.modules.notifications.trigger.frontdeskAppointmentEventNewLabel',
    defaultMessage: 'Frontdesk appointment event new',
  },
  frontdeskAppointmentEventEditLabel: {
    id: 'app.modules.notifications.trigger.frontdeskAppointmentEventEditLabel',
    defaultMessage: 'Frontdesk appointment event edit',
  },
  frontdeskAppointmentEventCancelLabel: {
    id: 'app.modules.notifications.trigger.frontdeskAppointmentEventCancelLabel',
    defaultMessage: 'Frontdesk appointment event cancel',
  },
  frontdeskAppointmentEventRedeemLabel: {
    id: 'app.modules.notifications.trigger.frontdeskAppointmentEventRedeemLabel',
    defaultMessage: 'Frontdesk appointment event redeem',
  },
  frontdeskAppointmentEventDeleteLabel: {
    id: 'app.modules.notifications.trigger.frontdeskAppointmentEventDeleteLabel',
    defaultMessage: 'Frontdesk appointment event delete',
  },
  frontdeskPaymentMethodInfoUpdated: {
    id: 'app.modules.notifications.trigger.frontdeskPaymentMethodInfoUpdated',
    defaultMessage: 'Frontdesk payment method info updated',
  },

  BookingEventNewLabel: {
    id: 'app.modules.notifications.trigger.BookingEventNewLabel',
    defaultMessage: 'Booking event new',
  },
  BookingEventEditLabel: {
    id: 'app.modules.notifications.trigger.BookingEventEditLabel',
    defaultMessage: 'Booking event edit',
  },
  BookingEventCancelLabel: {
    id: 'app.modules.notifications.trigger.BookingEventCancelLabel',
    defaultMessage: 'Booking event cancel',
  },
  BookingEventRedeemLabel: {
    id: 'app.modules.notifications.trigger.BookingEventRedeemLabel',
    defaultMessage: 'Booking event redeem',
  },
  BookingEventDeleteLabel: {
    id: 'app.modules.notifications.trigger.BookingEventDeleteLabel',
    defaultMessage: 'Booking event delete',
  },

  FrontdeskReceiptSendingLabel: {
    id: 'app.modules.notifications.trigger.FrontdeskReceiptSendingLabel',
    defaultMessage: 'Frontdesk receipt sending',
  },
  FrontdeskWaiverSendingLabel: {
    id: 'app.modules.notifications.trigger.FrontdeskWaiverSendingLabel',
    defaultMessage: 'Frontdesk waiver sending',
  },
  FrontdeskContractSendingLabel: {
    id: 'app.modules.notifications.trigger.FrontdeskContractSendingLabel',
    defaultMessage: 'Frontdesk contract sending',
  },
  FrontdeskBookATourCreatedLabel: {
    id: 'app.modules.notifications.trigger.FrontdeskBookATourCreatedLabel',
    defaultMessage: 'Frontdesk book a tour created',
  },
  FrontdeskPosReceiptSendingLabel: {
    id: 'app.modules.notifications.trigger.FrontdeskPosReceiptSendingLabel',
    defaultMessage: 'Frontdesk pos receipt sending',
  },

  crmReceiptSendingLabel: {
    id: 'app.modules.notifications.trigger.crmReceiptSendingLabel',
    defaultMessage: 'CRM receipt sending',
  },
  crmWaiverSendingLabel: {
    id: 'app.modules.notifications.trigger.crmWaiverSendingLabel',
    defaultMessage: 'CRM waiver sending',
  },
  crmContractSendingLabel: {
    id: 'app.modules.notifications.trigger.crmContractSendingLabel',
    defaultMessage: 'CRM contract sending',
  },
  crmBookATourCreatedLabel: {
    id: 'app.modules.notifications.trigger.crmBookATourCreatedLabel',
    defaultMessage: 'CRM book a tour created',
  },

  memberWaiverSendingLabel: {
    id: 'app.modules.notifications.trigger.memberWaiverSendingLabel',
    defaultMessage: 'Member waiver sending',
  },
  memberContractSendingLabel: {
    id: 'app.modules.notifications.trigger.memberContractSendingLabel',
    defaultMessage: 'Member contract sending',
  },
  memberBookATourCreatedLabel: {
    id: 'app.modules.notifications.trigger.memberBookATourCreatedLabel',
    defaultMessage: 'Member book a tour created',
  },
  memberReceiptSendingLabel: {
    id: 'app.modules.notifications.trigger.memberReceiptSendingLabel',
    defaultMessage: 'Member receipt sending',
  },

  posModuleReceiptSendingLabel: {
    id: 'app.modules.notifications.trigger.posModuleReceiptSendingLabel',
    defaultMessage: 'Pos module receipt sending',
  },

  ptcrmReceiptSendingLabel: {
    id: 'app.modules.notifications.trigger.ptcrmReceiptSendingLabel',
    defaultMessage: 'PT CRM receipt sending',
  },
  ptcrmWaiverSendingLabel: {
    id: 'app.modules.notifications.trigger.ptcrmWaiverSendingLabel',
    defaultMessage: 'PT CRM waiver sending',
  },
  ptcrmContractSendingLabel: {
    id: 'app.modules.notifications.trigger.ptcrmContractSendingLabel',
    defaultMessage: 'PT CRM contract sending',
  },
  ptcrmBookATourCreatedLabel: {
    id: 'app.modules.notifications.trigger.ptcrmBookATourCreatedLabel',
    defaultMessage: 'PT CRM book a tour created',
  },
});
