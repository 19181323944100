import { createAction } from '@reduxjs/toolkit';
import { SnackbarKey } from 'notistack';

import { actionTypes } from './constants';
import { IServerError } from '../../interfaces/http';
import { Message } from 'common/interfaces/common';
import { ICheckIn } from 'modules/front-desk/interfaces';

export const enqueueErrorNotification = createAction<IServerError>(
  actionTypes.ENQUEUE_ERROR_NOTIFIC,
);
export const closeErrorNotification = createAction<SnackbarKey>(actionTypes.CLOSE_ERROR_NOTIFIC);
export const clearErrorNotifics = createAction(actionTypes.REMOVE_ERROR_NOTIFICS);

export const enqueueSuccessNotification = createAction<Message>(
  actionTypes.ENQUEUE_SUCCESS_NOTIFIC,
);
export const closeSuccessNotification = createAction<SnackbarKey>(
  actionTypes.CLOSE_SUCCESS_NOTIFIC,
);
export const clearSuccessNotifics = createAction(actionTypes.REMOVE_SUCCESS_NOTIFICS);

export const enqueueCheckinNotification = createAction<ICheckIn>(
  actionTypes.ENQUEUE_CHECKIN_NOTIFIC,
);
export const clearCheckinNotifications = createAction(actionTypes.REMOVE_CHECKIN_NOTIFIC);
export const clearCheckinNotificationById = createAction<string>(
  actionTypes.REMOVE_CHECKIN_NOTIFIC_BY_ID,
);
