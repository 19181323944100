import { SenderAvailabilityTypeList } from './senderAvailability';

export enum ENotificationType {
  // all events remind
  EVENT_REMIND = 'EVENT_REMIND',

  APPOINTMENT_EVENT_NEW = 'APPOINTMENT_EVENT_NEW',
  APPOINTMENT_EVENT_EDIT = 'APPOINTMENT_EVENT_EDIT',
  APPOINTMENT_EVENT_CANCEL = 'APPOINTMENT_EVENT_CANCEL',
  APPOINTMENT_EVENT_REDEEM = 'APPOINTMENT_EVENT_REDEEM',
  APPOINTMENT_EVENT_DELETE = 'APPOINTMENT_EVENT_DELETE',

  INVOICE = 'INVOICE',
  CONTRACT = 'CONTRACT',
  WAIVER = 'WAIVER',

  // appointment
  FRONTDESK_APPOINTMENT_EVENT_NEW = 'FRONTDESK_APPOINTMENT_EVENT_NEW',
  FRONTDESK_APPOINTMENT_EVENT_EDIT = 'FRONTDESK_APPOINTMENT_EVENT_EDIT',
  FRONTDESK_APPOINTMENT_EVENT_CANCEL = 'FRONTDESK_APPOINTMENT_EVENT_CANCEL',
  FRONTDESK_APPOINTMENT_EVENT_REDEEM = 'FRONTDESK_APPOINTMENT_EVENT_REDEEM',
  FRONTDESK_APPOINTMENT_EVENT_DELETE = 'FRONTDESK_APPOINTMENT_EVENT_DELETE',
  // Signing documents
  FRONTDESK_WAIVER_SENDING = 'FRONTDESK_WAIVER_SENDING',
  FRONTDESK_CONTRACT_SENDING = 'FRONTDESK_CONTRACT_SENDING',
  // book a tour
  FRONTDESK_BOOK_A_TOUR_CREATED = 'FRONTDESK_BOOK_A_TOUR_CREATED',
  // pos
  FRONTDESK_RECEIPT_SENDING = 'FRONTDESK_RECEIPT_SENDING',
  FRONTDESK_POS_RECEIPT_SENDING = 'FRONTDESK_POS_RECEIPT_SENDING',

  FRONTDESK_PAYMENT_METHOD_INFO_UPDATED = 'FRONTDESK_PAYMENT_METHOD_INFO_UPDATED',

  // events
  BOOKING_EVENT_NEW = 'BOOKING_EVENT_NEW',
  BOOKING_EVENT_EDIT = 'BOOKING_EVENT_EDIT',
  BOOKING_EVENT_CANCEL = 'BOOKING_EVENT_CANCEL',
  BOOKING_EVENT_REDEEM = 'BOOKING_EVENT_REDEEM',
  BOOKING_EVENT_DELETE = 'BOOKING_EVENT_DELETE',

  // pos: /api/v1/pos/sender-availability
  POS_MODULE_RECEIPT_SENDING = 'POS_MODULE_RECEIPT_SENDING',

  // pos
  CRM_LEAD_RECEIPT_SENDING = 'CRM_LEAD_RECEIPT_SENDING',
  // Signing documents
  CRM_LEAD_WAIVER_SENDING = 'CRM_LEAD_WAIVER_SENDING',
  CRM_LEAD_CONTRACT_SENDING = 'CRM_LEAD_CONTRACT_SENDING',
  // book a tour
  CRM_LEAD_BOOK_A_TOUR_CREATED = 'CRM_LEAD_BOOK_A_TOUR_CREATED',

  // pos
  PT_CRM_LEAD_RECEIPT_SENDING = 'PT_CRM_LEAD_RECEIPT_SENDING',
  // Signing documents
  PT_CRM_LEAD_WAIVER_SENDING = 'PT_CRM_LEAD_WAIVER_SENDING',
  PT_CRM_LEAD_CONTRACT_SENDING = 'PT_CRM_LEAD_CONTRACT_SENDING',
  // book a tour
  PT_CRM_LEAD_BOOK_A_TOUR_CREATED = 'PT_CRM_LEAD_BOOK_A_TOUR_CREATED',

  MEMBER_CONTRACT_SENDING = 'MEMBER_CONTRACT_SENDING',
  MEMBER_WAIVER_SENDING = 'MEMBER_WAIVER_SENDING',
  // pos
  MEMBER_RECEIPT_SENDING = 'MEMBER_RECEIPT_SENDING',
  // book a tour
  MEMBER_BOOK_A_TOUR_CREATED = 'MEMBER_BOOK_A_TOUR_CREATED',
}

export enum EShortNotificationType {
  New,
  Edit,
  Cancel,
  Redeem,
  Remind,
  Delete,
  WaiverSending,
  ContractSending,
  ReceiptSending,
  Created,
}

export const FrontDeskAppointmentNotificationsEvents = {
  [EShortNotificationType.New]: ENotificationType.FRONTDESK_APPOINTMENT_EVENT_NEW,
  [EShortNotificationType.Edit]: ENotificationType.FRONTDESK_APPOINTMENT_EVENT_EDIT,
  [EShortNotificationType.Cancel]: ENotificationType.FRONTDESK_APPOINTMENT_EVENT_CANCEL,
  [EShortNotificationType.Redeem]: ENotificationType.FRONTDESK_APPOINTMENT_EVENT_REDEEM,
  [EShortNotificationType.Remind]: ENotificationType.EVENT_REMIND,
  [EShortNotificationType.Delete]: ENotificationType.FRONTDESK_APPOINTMENT_EVENT_DELETE,
};

export const BookingNotificationsEvents = {
  [EShortNotificationType.New]: ENotificationType.BOOKING_EVENT_NEW,
  [EShortNotificationType.Edit]: ENotificationType.BOOKING_EVENT_EDIT,
  [EShortNotificationType.Cancel]: ENotificationType.BOOKING_EVENT_CANCEL,
  [EShortNotificationType.Redeem]: ENotificationType.BOOKING_EVENT_REDEEM,
  [EShortNotificationType.Remind]: ENotificationType.EVENT_REMIND,
  [EShortNotificationType.Delete]: ENotificationType.BOOKING_EVENT_DELETE,
};

export const DefaultNotificationsEvents = {
  [EShortNotificationType.New]: ENotificationType.APPOINTMENT_EVENT_NEW,
  [EShortNotificationType.Edit]: ENotificationType.APPOINTMENT_EVENT_EDIT,
  [EShortNotificationType.Cancel]: ENotificationType.APPOINTMENT_EVENT_CANCEL,
  [EShortNotificationType.Redeem]: ENotificationType.APPOINTMENT_EVENT_REDEEM,
  [EShortNotificationType.Remind]: ENotificationType.EVENT_REMIND,
  [EShortNotificationType.Delete]: ENotificationType.APPOINTMENT_EVENT_DELETE,
};

export const getSenderAvailabilityByNotificationType = (
  type: ENotificationType,
): SenderAvailabilityTypeList => {
  switch (type) {
    case ENotificationType.FRONTDESK_APPOINTMENT_EVENT_NEW:
    case ENotificationType.FRONTDESK_APPOINTMENT_EVENT_EDIT:
    case ENotificationType.FRONTDESK_APPOINTMENT_EVENT_CANCEL:
    case ENotificationType.FRONTDESK_APPOINTMENT_EVENT_REDEEM:
    case ENotificationType.FRONTDESK_APPOINTMENT_EVENT_DELETE:
    case ENotificationType.FRONTDESK_PAYMENT_METHOD_INFO_UPDATED:
    case ENotificationType.BOOKING_EVENT_NEW:
    case ENotificationType.BOOKING_EVENT_EDIT:
    case ENotificationType.BOOKING_EVENT_CANCEL:
    case ENotificationType.BOOKING_EVENT_REDEEM:
    case ENotificationType.BOOKING_EVENT_DELETE:
    case ENotificationType.APPOINTMENT_EVENT_CANCEL:
    case ENotificationType.APPOINTMENT_EVENT_NEW:
    case ENotificationType.APPOINTMENT_EVENT_DELETE:
    case ENotificationType.APPOINTMENT_EVENT_EDIT:
    case ENotificationType.APPOINTMENT_EVENT_REDEEM:
      return SenderAvailabilityTypeList.APPOINTMENT;
    case ENotificationType.EVENT_REMIND:
      return SenderAvailabilityTypeList.REMIND;
    case ENotificationType.FRONTDESK_WAIVER_SENDING:
    case ENotificationType.FRONTDESK_CONTRACT_SENDING:
    case ENotificationType.CRM_LEAD_WAIVER_SENDING:
    case ENotificationType.CRM_LEAD_CONTRACT_SENDING:
    case ENotificationType.PT_CRM_LEAD_WAIVER_SENDING:
    case ENotificationType.PT_CRM_LEAD_CONTRACT_SENDING:
    case ENotificationType.MEMBER_WAIVER_SENDING:
    case ENotificationType.MEMBER_CONTRACT_SENDING:
      return SenderAvailabilityTypeList.DOCUMENTS;
    case ENotificationType.FRONTDESK_BOOK_A_TOUR_CREATED:
    case ENotificationType.CRM_LEAD_BOOK_A_TOUR_CREATED:
    case ENotificationType.PT_CRM_LEAD_BOOK_A_TOUR_CREATED:
    case ENotificationType.MEMBER_BOOK_A_TOUR_CREATED:
      return SenderAvailabilityTypeList.TOUR;
    case ENotificationType.FRONTDESK_RECEIPT_SENDING:
    case ENotificationType.FRONTDESK_POS_RECEIPT_SENDING:
    case ENotificationType.POS_MODULE_RECEIPT_SENDING:
    case ENotificationType.CRM_LEAD_RECEIPT_SENDING:
    case ENotificationType.PT_CRM_LEAD_RECEIPT_SENDING:
    case ENotificationType.MEMBER_RECEIPT_SENDING:
      return SenderAvailabilityTypeList.POS;
    default:
      return SenderAvailabilityTypeList.COMMON;
  }
};
