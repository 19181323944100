import { createSelector } from 'reselect';
import { AppStoreTypeImt } from 'store';

// Sales POS Summary
const selectSalesPOSSummaryDomain = (state: AppStoreTypeImt) =>
  state.getIn(['reports', 'salesPOSReport', 'salesPOSSummary']);

const selectSalesPOSSummaryLoading = () =>
  createSelector(selectSalesPOSSummaryDomain, state => state.get('isLoading'));

const selectSalesPOSSummary = () =>
  createSelector(selectSalesPOSSummaryDomain, state => state.get('data'));

// Cash Summary
const selectCashSummaryDomain = (state: AppStoreTypeImt) =>
  state.getIn(['reports', 'salesPOSReport', 'cashSummary']);

const selectCashSummaryLoading = () =>
  createSelector(selectCashSummaryDomain, state => state.get('isLoading'));

const selectCashSummary = () => createSelector(selectCashSummaryDomain, state => state.get('list'));

const selectCashSummaryTotals = () =>
  createSelector(selectCashSummaryDomain, state => state.get('totals'));

// Payments Summary
const selectPaymentsSummaryDomain = ['reports', 'salesPOSReport', 'paymentsSummary'];

const selectPaymentsSummaryLoading = () => state =>
  state.getIn([...selectPaymentsSummaryDomain, 'isLoading']);

const selectPaymentsSummary = () => state =>
  state.getIn([...selectPaymentsSummaryDomain, 'result']);

// Service Summary
const selectServiceSummaryDomain = (state: AppStoreTypeImt) =>
  state.getIn(['reports', 'salesPOSReport', 'serviceSummary']);

const selectServiceSummaryLoading = () =>
  createSelector(selectServiceSummaryDomain, state => state.get('isLoading'));

const selectServiceSummary = () =>
  createSelector(selectServiceSummaryDomain, state => state.get('list'));

const selectServiceSummaryTotals = () =>
  createSelector(selectServiceSummaryDomain, state => state.get('totals'));

// Invoices Summary
const selectInvoicesSummaryDomain = (state: AppStoreTypeImt) =>
  state.getIn(['reports', 'salesPOSReport', 'invoicesSummary']);

const selectInvoicesSummaryLoading = () =>
  createSelector(selectInvoicesSummaryDomain, state => state.get('isLoading'));

const selectInvoicesSummary = () =>
  createSelector(selectInvoicesSummaryDomain, state => state.get('list'));

// Sales Category Summary
const selectSalesSummaryDomain = (state: AppStoreTypeImt) =>
  state.getIn(['reports', 'salesPOSReport', 'salesSummary']);

const selectSalesSummaryLoading = () =>
  createSelector(selectSalesSummaryDomain, state => state.get('isLoading'));

const selectSalesSummary = () =>
  createSelector(selectSalesSummaryDomain, state => state.get('list'));

// Registers Summary
const selectRegistersSummaryDomain = (state: AppStoreTypeImt) =>
  state.getIn(['reports', 'salesPOSReport', 'registersSummary']);

const selectRegistersSummaryLoading = () =>
  createSelector(selectRegistersSummaryDomain, state => state.get('isLoading'));

const selectRegistersSummary = () =>
  createSelector(selectRegistersSummaryDomain, state => state.get('list'));

// Tax Summary
const selectTaxSummaryDomain = (state: AppStoreTypeImt) =>
  state.getIn(['reports', 'salesPOSReport', 'taxSummary']);

const selectTaxSummaryLoading = () =>
  createSelector(selectTaxSummaryDomain, state => state.get('isLoading'));

const selectTaxSummary = () => createSelector(selectTaxSummaryDomain, state => state.get('list'));

// Revenue Code Summary
const selectRevenueSummaryDomain = (state: AppStoreTypeImt) =>
  state.getIn(['reports', 'salesPOSReport', 'revenueSummary']);

const selectRevenueSummaryLoading = () =>
  createSelector(selectRevenueSummaryDomain, state => state.get('isLoading'));

const selectRevenueSummary = () =>
  createSelector(selectRevenueSummaryDomain, state => state.get('list'));

// Export
const selectExportSalesPOSReportDomain = state =>
  state.getIn(['reports', 'salesPOSReport', 'export']);

const selectExportSalesPOSReportResult = () =>
  createSelector(selectExportSalesPOSReportDomain, state => state.get('result'));
const selectExportSalesPOSReportLoading = () =>
  createSelector(selectExportSalesPOSReportDomain, state => state.get('loading'));
const selectExportSalesPOSReportError = () =>
  createSelector(selectExportSalesPOSReportDomain, state => state.get('error'));

export {
  // Sales POS Summary
  selectSalesPOSSummaryLoading,
  selectSalesPOSSummary,
  // Cash Summary
  selectCashSummaryLoading,
  selectCashSummary,
  selectCashSummaryTotals,
  // Payments Summary
  selectPaymentsSummaryLoading,
  selectPaymentsSummary,
  // Service Summary
  selectServiceSummaryLoading,
  selectServiceSummary,
  selectServiceSummaryTotals,
  // Invoices Summary
  selectInvoicesSummaryLoading,
  selectInvoicesSummary,
  // Sales Category Summary
  selectSalesSummaryLoading,
  selectSalesSummary,
  // Registers Summary
  selectRegistersSummaryLoading,
  selectRegistersSummary,
  // Tax Summary
  selectTaxSummaryLoading,
  selectTaxSummary,
  // Revenue Summary
  selectRevenueSummaryLoading,
  selectRevenueSummary,
  // Exporting
  selectExportSalesPOSReportResult,
  selectExportSalesPOSReportLoading,
  selectExportSalesPOSReportError,
};
