import React from 'react';
import { Typography } from '@material-ui/core';

import { formatPrice } from 'common/utils';

interface IPriceCellProps {
  content: string | number;
  bold?: boolean;
}

export default function PriceCell({ content, bold }: IPriceCellProps): JSX.Element {
  return content !== null ? (
    <Typography
      component="span"
      variant={bold ? 'h5' : 'body1'}
      style={{ whiteSpace: 'nowrap', textAlign: 'right' }}
      color={content < 0 ? 'error' : 'textPrimary'}
    >
      {formatPrice(content)}
    </Typography>
  ) : (
    <Typography variant={bold ? 'h5' : 'body1'}>-</Typography>
  );
}
