import React, { useRef } from 'react';
import { Box } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FormattedMessage } from 'react-intl';

import { CustomTheme } from 'common/ui/interfaces';
import { ButtonWithCircularProgress } from 'common/components';
import messages from 'common/components/TopazSignature/messages';
import { setSignatureConfig } from 'helpers';

interface ITopazSignatureProps {
  width: number;
  height: number;
  isLoading: boolean;
  isConnectedTopaz: boolean;
  onStartSigning: () => void;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  topazSignature: {
    position: 'relative',
    border: `1px dashed ${theme.palette.primary.main}`,
    borderRadius: '5px',
    margin: 'auto',

    '&>canvas': {
      position: 'absolute',
      left: 0,
      top: 0,
    },
  },
  startSigningBtn: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

/*
 * The width and height must match displayX and displayY from signatureConfig
 * path helpers/sigWeb
 * */
const TopazSignature = (props: ITopazSignatureProps) => {
  const { width, height, onStartSigning, isConnectedTopaz, isLoading } = props;

  const ref = useRef<HTMLCanvasElement>();

  const classes = useStyles();

  const onStartSigningTopaz = () => {
    const config = { canvasRef: ref.current };
    setSignatureConfig({ config, shouldCallReject: false });
    onStartSigning();
  };

  return (
    <Box className={classes.topazSignature} width={width} height={height} p={5}>
      <canvas ref={ref} width={width} height={height} />
      {isConnectedTopaz || (
        <ButtonWithCircularProgress
          onClick={onStartSigningTopaz}
          className={classes.startSigningBtn}
          isSubmitting={isLoading}
          variant="contained"
          type="button"
        >
          <FormattedMessage {...messages.startSigning} />
        </ButtonWithCircularProgress>
      )}
    </Box>
  );
};

export default TopazSignature;
