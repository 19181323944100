import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { Alert } from 'common/components';
import commonMessages from 'common/messages/messages';
import errorMessages from 'common/errors/messages';
import { AlertTypes } from 'common/interfaces/alerts';
import WebHidApi from 'services/webHidApi';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { ServerError } from 'common/errors/serverErrors';

interface IDeviceListenerProps {
  onScanningBarcodeSuccess: (scannerString: string) => void;
  successMessage?: MessageDescriptor;
  errorMessage?: MessageDescriptor;
}

const { hid } = navigator as Navigator & HID;

const { BarcodeScanner } = WebHidApi;

const useStyles = makeStyles(() => ({
  alert: {
    flexWrap: 'wrap',
  },
  alertTitle: {
    fontWeight: 500,
    width: '220px',
    display: 'inline-block',
  },
}));

const DeviceListener = memo((props: IDeviceListenerProps) => {
  const { onScanningBarcodeSuccess, successMessage, errorMessage } = props;

  const dispatch = useDispatch();

  const [message, setMessage] = useState<MessageDescriptor | null>(() =>
    BarcodeScanner.getOpenedDevices.length
      ? successMessage ?? commonMessages.barcodeScannerConnected
      : errorMessage ?? commonMessages.barcodeScannerIsNotConnected,
  );
  const [scannerStr, setScannerStr] = useState<string>();

  const classes = useStyles();

  useEffect(() => {
    if (!hid) {
      return setMessage(errorMessages.browserNotSupportHidApi);
    }

    const isOpenedDevices = () => Boolean(BarcodeScanner.getOpenedDevices.length);

    if (isOpenedDevices()) {
      setMessage(successMessage ?? commonMessages.barcodeScannerConnected);
    }

    const onBarcodeScannerString = (scannerString: string) => {
      if (!BarcodeScanner.getIsDisabledAutoCheckin) {
        setScannerStr(scannerString);
      }
    };

    // const onConnectDevice = async (device: HIDDevice) => {
    //   await BarcodeScanner.openDevice(device);
    // };

    const onDisconnectDevice = () => {
      setMessage(
        isOpenedDevices()
          ? successMessage ?? commonMessages.barcodeScannerConnected
          : errorMessage ?? commonMessages.barcodeScannerIsNotConnected,
      );
    };

    const onOpenBarcodeScanner = () => {
      setMessage(successMessage ?? commonMessages.barcodeScannerConnected);
    };

    const onOpenScannerError = () => {
      dispatch(
        enqueueErrorNotification({
          codes: [ServerError.DEVICE_CONNECTION_ERROR],
          message: undefined,
        }),
      );
    };

    BarcodeScanner.subscribeOnScannerString(onBarcodeScannerString);
    // BarcodeScanner.subscribeOnConnectDevice(onConnectDevice);
    BarcodeScanner.subscribeOnDisconnectDevice(onDisconnectDevice);
    BarcodeScanner.subscribeOnOpenBarcodeScanner(onOpenBarcodeScanner);
    BarcodeScanner.subscribeOnOpenBarcodeScannerError(onOpenScannerError);

    return () => {
      BarcodeScanner.unsubscribeOnScannerString(onBarcodeScannerString);
      // BarcodeScanner.unsubscribeOnConnectDevice(onConnectDevice);
      BarcodeScanner.unsubscribeOnDisconnectDevice(onDisconnectDevice);
      BarcodeScanner.unsubscribeOnOpenBarcodeScanner(onOpenBarcodeScanner);
      BarcodeScanner.unsubscribeOnOpenBarcodeScannerError(onOpenScannerError);
    };
  }, [setScannerStr, setMessage, dispatch, successMessage, errorMessage]);

  useEffect(() => {
    if (scannerStr) {
      onScanningBarcodeSuccess(scannerStr);
      setScannerStr(undefined);
    }
  }, [scannerStr, onScanningBarcodeSuccess, setScannerStr]);

  const isConnectionSuccess = message?.id === commonMessages.barcodeScannerConnected.id;

  const onConnect = () => {
    BarcodeScanner.init();
  };

  return message ? (
    <Alert
      extraClassName={classes.alert}
      onConnect={isConnectionSuccess ? undefined : onConnect}
      title={
        <span className={classes.alertTitle}>
          <FormattedMessage {...message} />
        </span>
      }
      variant="filled"
      severity={isConnectionSuccess ? AlertTypes.Success : AlertTypes.Danger}
    />
  ) : (
    <></>
  );
});

export default DeviceListener;
