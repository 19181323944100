import React from 'react';
import { Box, Typography, makeStyles, Theme } from '@material-ui/core';

import { formatPrice } from 'common/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 140,
    padding: theme.spacing(0.75, 1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    alignItems: 'center',
    borderRadius: '3px',
    backgroundColor: 'white',
    borderRight: ({ hideBorder }: { hideBorder: boolean }) => {
      return hideBorder ? 'none' : `1px solid ${theme.palette.secondary.light}`;
    },
  },
  label: {
    textAlign: 'center',
    marginBottom: theme.spacing(1),
    opacity: '0.7',
  },
  value: {
    fontSize: '1.125rem',
    lineHeight: '1.125rem',
    fontWeight: 500,
  },
}));

interface IProps {
  label: string | JSX.Element;
  value?: number | string;
  isPrice?: boolean;
  hideBorder?: boolean;
}

const StatsBlock: React.FC<IProps> = ({ label, value, isPrice, hideBorder }: IProps) => {
  const classes = useStyles({ hideBorder });

  return (
    <Box className={classes.root}>
      <Typography className={classes.label} variant="body2">
        {label}
      </Typography>
      <Typography className={classes.value} color="primary">
        {isPrice ? formatPrice(value, 2) : value}
      </Typography>
    </Box>
  );
};

export default StatsBlock;
