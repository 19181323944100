import { boolean, object } from 'yup';

import { nullableStringNumberSchema } from 'common/validationSchemas/stringNumberSchema';

import {
  getMaxErrorMessage,
  getMinErrorMessage,
  getRequiredMessage,
} from 'common/constants/globalConstants';
import inputErrors from 'common/messages/inputErrors';

export const getMinMaxNumberSchema = (min = 0, max = Infinity) =>
  object().shape(
    {
      value: nullableStringNumberSchema.when('editable', {
        is: true,
        then: nullableStringNumberSchema.when(['minValue', 'maxValue'], (minValue, maxValue) =>
          nullableStringNumberSchema
            .min(Number(minValue) ? Number(minValue) : Number(min), getMinErrorMessage)
            .max(Number.isInteger(maxValue) ? Number(maxValue) : Number(max), getMaxErrorMessage)
            .required(getRequiredMessage),
        ),
        otherwise: nullableStringNumberSchema
          .min(Number(min), getMinErrorMessage)
          .required(getRequiredMessage),
      }),
      editable: boolean(),
      minValue: nullableStringNumberSchema.when('editable', {
        is: true,
        then: nullableStringNumberSchema.required(getRequiredMessage).when('maxValue', maxValue =>
          nullableStringNumberSchema
            .required(getRequiredMessage)
            .min(Number(min), getMinErrorMessage)
            .max(Number(maxValue) ? maxValue : Infinity, getMaxErrorMessage),
        ),
      }),
      maxValue: nullableStringNumberSchema.when('editable', {
        is: true,
        then: nullableStringNumberSchema
          .required(getRequiredMessage)
          .when(['minValue', 'maxValue'], (minValue, maxValue) =>
            nullableStringNumberSchema
              .required(getRequiredMessage)
              .min(Number(min), getMinErrorMessage)
              .max(Number(maxValue) ? maxValue : Infinity, getMaxErrorMessage)
              .min(Number(minValue), () => inputErrors.minMaxError),
          ),
      }),
    },
    [['maxValue', 'maxValue']],
  );

export const minMaxNumberSchema = getMinMaxNumberSchema();
