import axios from 'axios';

import ApiService from './ApiService';

import { IPaginatedData } from 'common/interfaces/pagination';
import { ITableParams } from 'common/interfaces/table';
import { IDictionaryCommonItem } from 'modules/dictionaries/interfaces/interfaces';

export default class DepartmentsService extends ApiService {
  public getDepartments = (
    requestOptions?: ITableParams,
  ): Promise<IPaginatedData<IDictionaryCommonItem>> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get('/api/v1/departments', { params: searchParams });
  };

  public createDepartment = (data: IDictionaryCommonItem): Promise<IDictionaryCommonItem> => {
    return axios.post('/api/v1/departments', data);
  };

  public updateDepartment = (data: IDictionaryCommonItem): Promise<IDictionaryCommonItem> => {
    return axios.put(`/api/v1/departments/${data.id}`, data);
  };

  public updateStatusDepartment = (
    id: string,
    isActive: boolean,
  ): Promise<IDictionaryCommonItem> => {
    return axios.patch(`/api/v1/departments/${id}/change-status`, { active: isActive });
  };

  public getDepartmentsDictionaryList = (
    requestOptions?: ITableParams,
  ): Promise<IPaginatedData<IDictionaryCommonItem>> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get('/api/v1/departments/dictionary', { params: searchParams });
  };
}
