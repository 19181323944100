import React from 'react';
import { Grid } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { AddressBlock, PhoneNumberInput } from 'common/components/index';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

const ContactSection = (): JSX.Element => {
  const renderIntlMessage = useRenderIntlMessage();

  const { errors, control } = useFormContext();

  return (
    <AddressBlock profileMode name="address">
      <Grid item xs={12} sm={6}>
        <Controller
          control={control}
          name="phone"
          render={({ onChange, onBlur, value }) => (
            <PhoneNumberInput
              error={!!errors.phone?.phone}
              helperText={renderIntlMessage(errors.phone?.message)}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            />
          )}
        />
      </Grid>
    </AddressBlock>
  );
};

export default ContactSection;
