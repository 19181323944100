import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import { FieldInfo } from 'common/components/index';

import personProfileMessages from 'common/components/PersonProfile/messages';
import inputLabels from 'common/messages/inputLabels';
import { IFreezeSector } from 'modules/services/interfaces/packages';

interface IProps {
  freeze: IFreezeSector;
}

const FreezeSectionView = ({ freeze }: IProps): JSX.Element => {
  const renderIntlMessage = useRenderIntlMessage();

  const {
    editableDaysNumber: { value: editableDaysNumber },
  } = freeze;

  return (
    <>
      <Grid item xs={12}>
        <Typography component="p" variant="h5">
          <FormattedMessage {...personProfileMessages.freezeMembershipModalSuccessBtn} />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <FieldInfo
          label={<FormattedMessage {...inputLabels.duration} />}
          description={`${editableDaysNumber} ${renderIntlMessage(inputLabels.daysAmount)}`}
        />
      </Grid>
    </>
  );
};

export default FreezeSectionView;
