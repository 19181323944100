import * as yup from 'yup';
import { stringNumberSchema } from 'common/validationSchemas/stringNumberSchema';
import inputErrors from 'common/messages/inputErrors';

export const getOnAccountValidationSchema = (
  leftToPay: number,
  balance: number,
): yup.AnyObjectSchema => {
  const maxValidValue = leftToPay > balance ? balance : leftToPay;
  const onErrorMessage =
    leftToPay > balance
      ? () => inputErrors.amountHigherThanBalance
      : () => inputErrors.amountHigherThanLeftToPay;
  return yup.object().shape({
    amount: stringNumberSchema
      .max(maxValidValue, onErrorMessage)
      .required(() => inputErrors.requiredFieldError),
  });
};
