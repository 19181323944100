import React from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import {
  Grid,
  makeStyles,
  createStyles,
  Divider,
  FormControlLabel,
  Theme,
  FormHelperText,
} from '@material-ui/core';

// interfaces
import { ITagImt } from 'modules/booking/interfaces';
// constants
import { DictionaryList } from 'common/constants';
import { SenderAvailabilityTypeList } from 'modules/booking/constants/senderAvailability';
import { defaultNumberFormatProps } from 'common/components/NumberController/NumberController';
// state
import { selectDictionaryList } from 'common/state/dictionary/selectors';
// components
import { Checkbox, MultipleSelect, NumberTextField, TextArea } from 'common/components';
import NotifyVariant from 'modules/booking/components/Modals/NotifyVariant';
import ReminderSection from '../ReminderSection/ReminderSection';
// hooks
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
// messages
import inputLabels from 'common/messages/inputLabels';
import messages from 'modules/booking/messages';
import { selectSenderAvailability } from 'modules/booking/state/senderAvailability/selectors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    additionalWrapper: {
      padding: theme.spacing(1, 0),
    },
    dateInput: {
      '& .MuiOutlinedInput-input': {
        padding: theme.spacing(1.25, 1.5),
      },
    },
    notifyGroupLabel: {
      margin: theme.spacing(1, 0),
    },
    notifyItem: {
      marginRight: theme.spacing(1),
    },
  }),
);

interface IAdditionalSection {
  type: SenderAvailabilityTypeList;
}

const AdditionalSection = ({ type }: IAdditionalSection): JSX.Element => {
  const classes = useStyles();
  const renderErrorMessage = useRenderIntlMessage();
  const { watch, errors, control } = useFormContext();

  const tags: ImmutableList<ITagImt> = useSelector(selectDictionaryList(DictionaryList.EVENT_TAGS));
  const senderAvailability = useSelector(selectSenderAvailability(type));

  const values = watch([
    'preventJoiningAfterDays',
    'preventCancelingAfterDays',
    'preventEditingAfterDays',
    'repeated',
  ]);

  return (
    <Grid container spacing={2} className={classes.additionalWrapper}>
      <Grid item xs={12}>
        <Controller
          name="tags"
          control={control}
          render={({ value, onChange, onBlur }) => (
            <MultipleSelect
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              fullWidth
              multiple
              label={<FormattedMessage {...inputLabels.eventTagsOptional} />}
              options={tags.toJS()}
              error={!!errors.tags}
              helperText={renderErrorMessage(errors.tags?.message)}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="description"
          control={control}
          render={({ onChange, onBlur, value }) => (
            <TextArea
              value={value}
              label={<FormattedMessage {...inputLabels.eventDescriptionOptional} />}
              variant="outlined"
              autoComplete="none"
              fullWidth
              maxSymbols={1000}
              rows={6}
              onChange={onChange}
              onBlur={onBlur}
              error={!!errors.description}
              helperText={renderErrorMessage(errors?.description?.message)}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={values.preventJoiningAfterDays ? 8 : 12}>
                <Controller
                  name="preventJoiningAfterDays"
                  control={control}
                  render={({ value, onChange, onBlur }) => (
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={value}
                            onBlur={onBlur}
                            onChange={(e, v) => onChange(v)}
                          />
                        }
                        label={<FormattedMessage {...messages.preventJoiningLabel} />}
                      />
                      {errors.preventJoiningAfterDays && (
                        <FormHelperText error>
                          {renderErrorMessage(errors.preventJoiningAfterDays?.message)}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </Grid>
              {!!values.preventJoiningAfterDays && (
                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name="joinRestrictionBeforeDays"
                    render={({ onChange, onBlur, value }) => (
                      <NumberTextField
                        fullWidth
                        label={<FormattedMessage {...inputLabels.daysPriorAmount} />}
                        variant="outlined"
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        error={!!errors.joinRestrictionBeforeDays}
                        helperText={renderErrorMessage(errors.joinRestrictionBeforeDays?.message)}
                        numberFormatProps={defaultNumberFormatProps}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={values.preventCancelingAfterDays ? 8 : 12}>
                <Controller
                  name="preventCancelingAfterDays"
                  control={control}
                  render={({ value, onChange, onBlur }) => (
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={value}
                            onBlur={onBlur}
                            onChange={(e, v) => onChange(v)}
                          />
                        }
                        label={<FormattedMessage {...messages.preventCancellingLabel} />}
                      />
                      {errors.preventCancelingAfterDays && (
                        <FormHelperText error>
                          {renderErrorMessage(errors.preventCancelingAfterDays?.message)}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </Grid>

              {!!values.preventCancelingAfterDays && (
                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name="cancelRestrictionBeforeDays"
                    render={({ onChange, onBlur, value }) => (
                      <NumberTextField
                        fullWidth
                        label={<FormattedMessage {...inputLabels.daysPriorAmount} />}
                        variant="outlined"
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        error={!!errors.cancelRestrictionBeforeDays}
                        helperText={renderErrorMessage(errors.cancelRestrictionBeforeDays?.message)}
                        numberFormatProps={defaultNumberFormatProps}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={values.preventEditingAfterDays ? 8 : 12}>
                <Controller
                  name="preventEditingAfterDays"
                  control={control}
                  render={({ value, onChange, onBlur }) => (
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={value}
                            onBlur={onBlur}
                            onChange={(e, v) => onChange(v)}
                          />
                        }
                        label={<FormattedMessage {...messages.preventEditingLabel} />}
                      />
                      {errors.preventEditingAfterDays && (
                        <FormHelperText error>
                          {renderErrorMessage(errors.preventEditingAfterDays?.message)}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </Grid>

              {!!values.preventEditingAfterDays && (
                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name="editRestrictionBeforeDays"
                    render={({ onChange, onBlur, value }) => (
                      <NumberTextField
                        fullWidth
                        label={<FormattedMessage {...inputLabels.daysPriorAmount} />}
                        variant="outlined"
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        error={!!errors.editRestrictionBeforeDays}
                        helperText={renderErrorMessage(errors.editRestrictionBeforeDays?.message)}
                        numberFormatProps={defaultNumberFormatProps}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <ReminderSection />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <NotifyVariant senderAvailability={senderAvailability} />
        </Grid>
      </>
    </Grid>
  );
};

export default AdditionalSection;
