import React from 'react';
import { Box, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';

import { formatPrice } from 'common/utils';
import { LoadingBackdrop } from 'common/components';

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    width: '1px',
    background: theme.palette.secondary.light,
  },
  itemTitle: {
    marginBottom: theme.spacing(1),
    textAlign: 'center',
  },
}));

interface ITotalItem {
  key: string;
  value: string | number;
  label: string | JSX.Element;
  isPrice?: boolean;
  color?: string;
}

interface IProps {
  items: Array<ITotalItem>;
  isItemsLoading?: boolean;
}

const TotalsBlock = ({ items, isItemsLoading }: IProps): JSX.Element => {
  const theme = useTheme();

  const classes = useStyles();

  const getTotalValueLabel = (value: string | number, isPrice: boolean) => {
    if (Number.isFinite(value)) {
      return isPrice ? formatPrice(value) : value;
    }

    return '-';
  };

  const getTotalValueColor = (value: string | number, color: string) => {
    if (Number.isFinite(value) && value < 0) {
      return theme.palette.error.main;
    }

    if (Number.isFinite(value)) {
      return color || theme.palette.primary.main;
    }

    return 'initial';
  };

  return (
    <Box display="flex" position="relative" padding={3}>
      {items.map(({ label, value, isPrice, color, key }, index) => {
        return (
          <React.Fragment key={key}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              flex="1"
            >
              <Typography variant="body2" color="textSecondary" className={classes.itemTitle}>
                {label}
              </Typography>
              <Typography style={{ color: getTotalValueColor(value, color) }} variant="h4">
                {getTotalValueLabel(value, isPrice)}
              </Typography>
            </Box>

            {items.length - 1 !== index && <Box marginX={2} className={classes.divider} />}
          </React.Fragment>
        );
      })}

      <LoadingBackdrop isLoading={isItemsLoading} />
    </Box>
  );
};

export default TotalsBlock;
