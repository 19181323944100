import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const ignoreErrors = [
  "Failed to execute 'send' on 'XMLHttpRequest': Failed to load 'http://tablet.sigwebtablet.com:47289/SigWeb/LcdRefresh/0,0,0,240,64'.",
];

// For details see docs https://docs.sentry.io/platforms/javascript/session-replay

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing(),
    Sentry.replayIntegration({ maskAllText: false, blockAllMedia: true }),
  ],
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 1.0,
  debug: true,
  release: process.env.REACT_APP_COMMIT_REF,
  environment: process.env.NODE_ENV,
  attachStacktrace: true,
  ignoreErrors,
  beforeSend(event) {
    // Check if it is an exception, if so, show the report dialog
    if (event.exception) {
      Sentry.showReportDialog();
    }
    return event;
  },
});
