import { useEffect, useState } from 'react';

const useComponentUpdate = (timeout?: number): void => {
  const [, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, timeout || 1000);

    return () => clearInterval(intervalId);
  }, [timeout]);
};

export default useComponentUpdate;
