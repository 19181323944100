export interface IChartLineSettings {
  key: string;
  name: string;
  dataKey: string;
  isHidden: boolean;
  fillColor?: string;
}

export enum PeriodFilterType {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}
