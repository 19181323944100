export const RESOLVE_PERSON_MISSING_BILLING_INFO =
  'MEMBER_PORTAL/RESOLVE_PERSON_MISSING_BILLING_INFO';

export const actionTypes = {
  FETCH_PERSON_PAST_DUE_BILLINGS: 'MEMBER_PORTAL/FETCH_PERSON_PAST_DUE_BILLINGS',
  FETCH_PERSON_PAST_DUE_BILLINGS_LOADING: 'MEMBER_PORTAL/FETCH_PERSON_PAST_DUE_BILLINGS_LOADING',

  FETCH_PERSON_UPCOMING_BILLINGS: 'MEMBER_PORTAL/FETCH_PERSON_UPCOMING_BILLINGS',
  FETCH_PERSON_UPCOMING_BILLINGS_LOADING: 'MEMBER_PORTAL/FETCH_PERSON_UPCOMING_BILLINGS_LOADING',
  RESET_PERSON_UPCOMING_BILLINGS: 'MEMBER_PORTAL/RESET_PERSON_UPCOMING_BILLINGS',

  RESET_PAYMENT_METHODS: 'MEMBER_PORTAL/RESET_PAYMENT_METHODS',

  FETCH_STORED_CREDIT_CARDS: 'MEMBER_PORTAL/FETCH_STORED_CREDIT_CARDS',
  ADD_CREDIT_CARD_RESULT_LOADING: 'MEMBER_PORTAL/ADD_CREDIT_CARD_RESULT_LOADING',
  ADD_CREDIT_CARD_RESULT: 'MEMBER_PORTAL/ADD_CREDIT_CARD_RESULT',

  FETCH_PERSON_PAYMENT_METHODS: 'MEMBER_PORTAL/FETCH_PERSON_PAYMENT_METHODS',
  FETCH_PAYMENT_METHODS_MODAL_DATA_LOADING:
    'MEMBER_PORTAL/FETCH_PAYMENT_METHODS_MODAL_DATA_LOADING',

  FETCH_PERSON_SUBSCRIPTIONS: 'MEMBER_PORTAL/FETCH_PERSON_SUBSCRIPTIONS',
  RESET_PERSON_SUBSCRIPTIONS: 'MEMBER_PORTAL/RESET_PERSON_SUBSCRIPTIONS',

  FETCH_CHECKING_SAVINGS_DATA: 'MEMBER_PORTAL/FETCH_CHECKING_SAVINGS_DATA',
  FETCH_CHECKING_SAVINGS_DATA_LOADING: 'MEMBER_PORTAL/FETCH_CHECKING_SAVINGS_DATA_LOADING',
  ADD_CHECKING_SAVINGS_DATA: 'MEMBER_PORTAL/ADD_CHECKING_SAVINGS_DATA',
  ADD_CHECKING_SAVINGS_DATA_LOADING: 'MEMBER_PORTAL/ADD_CHECKING_SAVINGS_DATA_LOADING',
  RESET_CHECKING_SAVINGS_DATA: 'MEMBER_PORTAL/RESET_CHECKING_SAVINGS_DATA',
  ADD_CHECKING_SAVINGS_DATA_ACTION_RESULT: 'MEMBER_PORTAL/ADD_CHECKING_SAVINGS_DATA_ACTION_RESULT',
  DELETE_CHECKING_SAVINGS_ITEM: 'MEMBER_PORTAL/DELETE_CHECKING_SAVINGS_ITEM',
  DELETE_CHECKING_SAVINGS_ITEM_LOADING: 'MEMBER_PORTAL/DELETE_CHECKING_SAVINGS_ITEM_LOADING',
  DELETE_CHECKING_SAVINGS_ITEM_ACTION_RESULT:
    'MEMBER_PORTAL/DELETE_CHECKING_SAVINGS_ITEM_ACTION_RESULT',

  UPDATE_PERSON_SUBSCRIPTIONS: 'MEMBER_PORTAL/UPDATE_PERSON_SUBSCRIPTIONS',
  UPDATE_PERSON_SUBSCRIPTIONS_LOADING: 'MEMBER_PORTAL/UPDATE_PERSON_SUBSCRIPTIONS_LOADING',
  RESET_UPDATE_PERSON_SUBSCRIPTIONS_ACTION_RESULT:
    'MEMBER_PORTAL/RESET_UPDATE_PERSON_SUBSCRIPTIONS_ACTION_RESULT',

  RESOLVE_PERSON_MISSING_BILLING_INFO,

  DELETE_CREDIT_CARD_RESULT: 'MEMBER_PORTAL/DELETE_CREDIT_CARD_RESULT',
  DELETE_CREDIT_CARD_RESULT_LOADING: 'MEMBER_PORTAL/DELETE_CREDIT_CARD_RESULT_LOADING',
  RESET_DELETE_CREDIT_CARD_RESULT: 'MEMBER_PORTAL/RESET_DELETE_CREDIT_CARD_RESULT',
};
