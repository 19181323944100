import { PhoneTypes } from 'common/components/PersonProfile/constants';
import { CameFromType } from 'common/constants';

export const tagsList = {
  data: [
    { id: '1', name: 'GYM', clientId: '1', createdAt: null, updatedAt: null, deletedAt: null },
    {
      id: '2',
      name: 'Fitness',
      clientId: '1',
      createdAt: null,
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: '3',
      name: 'Boxing',
      clientId: '1',
      createdAt: null,
      updatedAt: null,
      deletedAt: null,
    },
  ],
};

export const members = {
  data: [
    {
      id: '1',
      source: 'front desk',
      action: 'allowed',
      result: null,
      checkInType: '',
      person: {
        id: '11',
        personalId: '224442',
        type: 'Active member',
        firstName: 'Joshuah',
        lastName: 'Marvin',
        birthday: '1979-01-27T00:00:00.000000Z',
        photoUrl: '',
        phones: [
          {
            id: '7',
            phoneType: PhoneTypes.Mobile,
            phone: '+1-859-215-8711',
            allowAutomatedCalls: true,
            canReceiveSms: true,
          },
        ],
        salesperson: { id: '8' },
        cameFrom: {
          value: CameFromType.ReferralList,
          additionalInfo: '',
          referralPerson: null,
        },
        email: 'joshuah@gmail.com',
      },
      alerts: [],
      createdAt: '',
      updatedAt: '',
    },
    {
      id: '2',
      source: 'front desk',
      action: 'allowed',
      result: 'inactive',
      checkInType: '',
      person: {
        id: '12',
        personalId: '209282',
        type: 'Active member',
        firstName: 'Jason',
        lastName: 'Toy',
        birthday: '1986-05-19T00:00:00.000000Z',
        photoUrl: '',
        phones: [
          {
            id: '7',
            phoneType: PhoneTypes.Mobile,
            phone: '+1-504-920-6476',
            allowAutomatedCalls: true,
            canReceiveSms: true,
          },
          {
            id: '1',
            phoneType: PhoneTypes.Home,
            phone: '+1-318-392-4978',
            allowAutomatedCalls: true,
            canReceiveSms: true,
          },
        ],
        salesperson: { id: '8' },
        cameFrom: {
          value: CameFromType.ReferralList,
          additionalInfo: '',
          referralPerson: null,
        },
        email: 'jason@gmail.com',
      },
      alerts: [
        {
          id: '1',
          condition: 'missing_photo',
          message: 'Missing photo',
          sound: true,
          type: 'warning',
          appointmentId: null,
          noteId: null,
        },
        {
          id: '2',
          condition: 'missing_email',
          message: 'Missing Email',
          sound: true,
          type: 'warning',
          appointmentId: null,
          noteId: null,
        },
      ],
      createdAt: '',
      updatedAt: '',
    },
    {
      id: '3',
      source: 'front desk',
      action: 'allowed',
      result: 'error',
      checkInType: '',
      person: {
        id: '13',
        personalId: '636822',
        type: 'Active member',
        firstName: 'Alena',
        lastName: "O'Hara",
        birthday: '1994-02-22T00:00:00.000000Z',
        photoUrl: '',
        phones: [
          {
            id: '7',
            phoneType: PhoneTypes.Mobile,
            phone: '+1-336-316-5909',
            allowAutomatedCalls: true,
            canReceiveSms: true,
          },
        ],
        salesperson: { id: '8' },
        cameFrom: {
          value: CameFromType.ReferralList,
          additionalInfo: '',
          referralPerson: null,
        },
        email: 'aleena@gmail.com',
      },
      alerts: [],
      createdAt: '',
      updatedAt: '',
    },
    {
      id: '4',
      source: 'front desk',
      action: 'allowed',
      result: null,
      checkInType: 'barcode',
      person: {
        id: '14',
        personalId: '102973',
        type: 'Guest',
        firstName: 'Carla',
        lastName: 'Virginia',
        birthday: '2000-03-22T00:00:00.000000Z',
        photoUrl: '',
        phones: [
          {
            id: '7',
            phoneType: PhoneTypes.Mobile,
            phone: '+1-864-902-7550',
            allowAutomatedCalls: true,
            canReceiveSms: true,
            allowForSms: false,
          },
        ],
        salesperson: { id: '8' },
        cameFrom: {
          value: CameFromType.ReferralList,
          additionalInfo: '',
          referralPerson: null,
        },
        email: 'carla@gmail.com',
      },
      alerts: [
        {
          id: '0',
          condition: 'birthday',
          message: 'Birthday',
          sound: true,
          type: 'info',
          appointmentId: null,
          noteId: null,
        },
        {
          id: '2',
          condition: 'missing_address',
          message: 'Missing address',
          sound: true,
          type: 'warning',
          appointmentId: null,
          noteId: null,
        },
      ],
      createdAt: '2020-09-21T15:30:31.000000Z',
      updatedAt: '2020-09-21T16:30:31.000000Z',
    },
    {
      id: '5',
      source: 'front desk',
      action: 'allowed',
      result: 'inactive',
      checkInType: 'barcode',
      person: {
        id: '15',
        personalId: '563789',
        type: 'Active member',
        firstName: 'Kiah',
        lastName: 'Simon',
        birthday: '',
        photoUrl: '',
        phones: [
          {
            id: '7',
            phoneType: PhoneTypes.Mobile,
            phone: '+1-216-559-9454',
            allowAutomatedCalls: true,
            canReceiveSms: true,
          },
        ],
        salesperson: { id: '8' },
        cameFrom: {
          value: CameFromType.ReferralList,
          additionalInfo: '',
          referralPerson: null,
        },
        email: 'kiah@gmail.com',
      },
      alerts: [
        {
          id: '1',
          condition: 'missing_photo',
          message: 'Missing photo',
          sound: true,
          type: 'warning',
          appointmentId: null,
          noteId: null,
        },
        {
          id: '3',
          condition: 'missing_birth_day',
          message: 'Missing Birth Date',
          sound: true,
          type: 'warning',
          appointmentId: null,
          noteId: null,
        },
        {
          id: '4',
          condition: 'upcoming_appointment',
          message: 'Upcoming appointment',
          sound: true,
          type: 'warning',
          appointmentId: null,
          noteId: null,
        },
        {
          id: '5',
          condition: 'missed_appointment',
          message: 'Missed appointment',
          sound: true,
          type: 'warning',
          appointmentId: null,
          noteId: null,
        },
      ],
      createdAt: '2020-09-21T15:25:24.000000Z',
      updatedAt: '2020-09-21T15:25:24.000000Z',
    },
    {
      id: '6',
      source: 'front desk',
      action: 'allowed',
      result: 'error',
      checkInType: 'barcode',
      person: {
        id: '16',
        personalId: '992781',
        type: 'Active member',
        firstName: 'Naomi',
        lastName: 'Tri',
        birthday: '1990-01-25T00:00:00.000000Z',
        photoUrl: '',
        phones: [
          {
            id: '7',
            phoneType: PhoneTypes.Mobile,
            phone: '+1-781-290-7092',
            allowAutomatedCalls: true,
            canReceiveSms: true,
          },
        ],
        salesperson: { id: '8' },
        cameFrom: {
          value: CameFromType.ReferralList,
          additionalInfo: '',
          referralPerson: null,
        },
        email: 'naomi@gmail.com',
      },
      alerts: [
        {
          id: '2',
          condition: 'missing_last_name',
          message: 'Missing Last Name',
          sound: true,
          type: 'danger',
          appointmentId: null,
          noteId: null,
        },
        {
          id: '3',
          condition: 'missing_ssn',
          message: 'Missing SSN',
          sound: true,
          type: 'warning',
          appointmentId: null,
          noteId: null,
        },
        {
          id: '5',
          condition: 'member_is_frozen',
          message: 'Member is Frozen until Sep 01, 2021',
          sound: true,
          type: 'warning',
          appointmentId: null,
          noteId: null,
        },
        {
          id: '6',
          condition: 'past_due',
          message: 'Past Due 4 days',
          sound: true,
          type: 'warning',
          appointmentId: null,
          noteId: null,
        },
        {
          id: '7',
          condition: 'not_allowed_at_this_location',
          message: 'Not allowed at this location',
          sound: true,
          type: 'warning',
          appointmentId: null,
          noteId: null,
        },
        {
          id: '8',
          condition: 'inactive_member',
          message: 'Inactive Member',
          sound: true,
          type: 'warning',
          appointmentId: null,
          noteId: null,
        },
        {
          id: '9',
          condition: 'not_allowed_to_use_on_this_day_or_time',
          message: 'Not allowed to use on this day or time',
          sound: true,
          type: 'danger',
          appointmentId: null,
          noteId: null,
        },
      ],
      createdAt: '2020-09-21T15:22:24.000000Z',
      updatedAt: '2020-09-21T15:22:24.000000Z',
    },
    {
      id: '7',
      source: 'front desk',
      action: 'allowed',
      result: 'error',
      checkInType: 'barcode',
      person: {
        id: '17',
        personalId: '267398',
        type: 'Active member',
        firstName: 'Tristan',
        lastName: 'Jack',
        birthday: '1999-02-06T00:00:00.000000Z',
        photoUrl: '',
        phones: [
          {
            id: '7',
            phoneType: PhoneTypes.Home,
            phone: '+1-912-357-7702',
            allowAutomatedCalls: true,
            canReceiveSms: true,
          },
        ],
        salesperson: { id: '8' },
        cameFrom: {
          value: CameFromType.ReferralList,
          additionalInfo: '',
          referralPerson: null,
        },
        email: 'trist@gmail.com',
      },
      alerts: [],
      createdAt: '2020-09-21T15:05:24.000000Z',
      updatedAt: '2020-09-21T15:05:24.000000Z',
    },
    {
      id: '8',
      source: 'front desk',
      action: 'allowed',
      result: null,
      checkInType: 'barcode',
      person: {
        id: '18',
        personalId: '145289',
        type: 'Active member',
        firstName: 'Gordon',
        lastName: 'Booth',
        birthday: '1980-09-01T00:00:00.000000Z',
        photoUrl: '',
        phones: [],
        salesperson: { id: '8' },
        cameFrom: {
          value: CameFromType.ReferralList,
          additionalInfo: '',
          referralPerson: null,
        },
        email: 'gordon@gmail.com',
      },
      alerts: [
        {
          id: '2',
          condition: 'missing_phone_number',
          message: 'Missing Phone Number',
          sound: true,
          type: 'warning',
          appointmentId: null,
          noteId: null,
        },
        {
          id: '1243',
          condition: 'unsigned_waiver',
          message: 'Membership waiver is unsigned',
          sound: true,
          type: 'warning',
          appointmentId: null,
          noteId: null,
          waiver: {
            id: 'waiver-1',
            name: 'Training Waiver',
            code: '1040062',
            content: `
      <p style='line-height: 1;'><span style='font-family: arial, helvetica, sans-serif; color: #000000; font-size: 12pt;'><strong>State</strong></span></p>
      <p style='line-height: 1;'><span style='font-family: arial, helvetica, sans-serif; color: #000000; font-size: 12pt;'>California</span></p>
      <p style='line-height: 1;'><span style='font-family: arial, helvetica, sans-serif; color: #000000; font-size: 12pt;'><strong>Zip</strong></span></p>
      <p style='line-height: 1;'><span style='font-family: arial, helvetica, sans-serif; color: #000000; font-size: 12pt;'>105671</span></p>
      <p style='line-height: 1;'><span style='font-family: arial, helvetica, sans-serif; color: #000000; font-size: 12pt;'><strong>LIABILITY WAIVER AND RELEASE FORM</strong></span></p>
      <p style='line-height: 1;'><span style='font-family: arial, helvetica, sans-serif; color: #000000; font-size: 12pt;'>As a Guest of The Club, I acknowledge that physical activity and use of exercise equipment, club facilities, club programs, child care services, tanning beds, saunas, steam rooms, spas, fitness instruction, tennis programs, and other services could result in illness, injury, or thieft of personal property. I hereby affirm that I am voluntary participant in these activities and assume all associated risks.</span></p>
      <p style='line-height: 1;'><span style='font-family: arial, helvetica, sans-serif; color: #000000; font-size: 12pt;'>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.</span></p>
      <p style='line-height: 1;'><span style='font-family: arial, helvetica, sans-serif; color: #000000; font-size: 12pt;'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</span></p>
    `,
            type: 'Service',
          },
        },
      ],
      createdAt: '2020-09-21T14:49:24.000000Z',
      updatedAt: '2020-09-21T14:49:24.000000Z',
    },
    {
      id: '9',
      source: 'front desk',
      action: 'allowed',
      result: 'inactive',
      checkInType: 'barcode',
      person: {
        id: '19',
        personalId: '830128',
        type: 'Guest',
        firstName: 'Betty',
        lastName: 'Legge',
        birthday: '1983-02-19T00:00:00.000000Z',
        photoUrl: '',
        phones: [
          {
            id: '7',
            phoneType: PhoneTypes.Mobile,
            phone: '+1-646-929-3542',
            allowAutomatedCalls: false,
            canReceiveSms: false,
            allowForSms: false,
          },
        ],
        salesperson: { id: '8' },
        cameFrom: {
          value: CameFromType.ReferralList,
          additionalInfo: '',
          referralPerson: null,
        },
        email: 'bett@gmail.com',
      },
      alerts: [],
      createdAt: '2020-09-21T14:46:24.000000Z',
      updatedAt: '2020-09-21T14:46:24.000000Z',
    },
    {
      id: '10',
      source: 'front desk',
      action: 'allowed',
      result: 'error',
      checkInType: 'barcode',
      person: {
        id: '20',
        personalId: '638901',
        type: 'Active member',
        firstName: 'Kenan',
        lastName: 'Stevenson',
        birthday: '1997-12-08T00:00:00.000000Z',
        photoUrl: '',
        phones: [
          {
            id: '7',
            phoneType: PhoneTypes.Mobile,
            phone: '+1-407-719-3982',
            allowAutomatedCalls: true,
            canReceiveSms: true,
          },
          {
            id: '3',
            phoneType: PhoneTypes.Home,
            phone: '+1-321-279-4049',
            allowAutomatedCalls: true,
            canReceiveSms: true,
          },
        ],
        salesperson: { id: '8' },
        cameFrom: {
          value: CameFromType.ReferralList,
          additionalInfo: '',
          referralPerson: null,
        },
        email: 'kena@gmail.com',
      },
      alerts: [],
      createdAt: '2020-09-21T14:38:24.000000Z',
      updatedAt: '2020-09-21T14:38:24.000000Z',
    },
    {
      id: '11',
      source: 'front desk',
      action: 'allowed',
      result: null,
      checkInType: 'barcode',
      person: {
        id: '21',
        personalId: '667282',
        type: 'Active member',
        firstName: 'Carina',
        lastName: 'Welch',
        birthday: '1987-02-01T00:00:00.000000Z',
        photoUrl: '',
        phones: [
          {
            id: '1',
            phoneType: PhoneTypes.Mobile,
            phone: '+1-843-604-2225',
            allowAutomatedCalls: true,
            canReceiveSms: true,
          },
        ],
        salesperson: { id: '8' },
        cameFrom: {
          value: CameFromType.ReferralList,
          additionalInfo: '',
          referralPerson: null,
        },
        email: 'carina@gmail.com',
      },
      alerts: [],
      createdAt: '2020-09-21T14:33:24.000000Z',
      updatedAt: '2020-09-21T14:33:24.000000Z',
    },
    {
      id: '12',
      source: 'front desk',
      action: 'allowed',
      result: 'inactive',
      checkInType: 'barcode',
      person: {
        id: '22',
        personalId: '883902',
        type: 'Guest',
        firstName: 'Marianna',
        lastName: 'Schmitt',
        birthday: '1999-12-19T00:00:00.000000Z',
        photoUrl: '',
        phones: [
          {
            id: '1',
            phoneType: PhoneTypes.Home,
            phone: '+1-646-578-0390',
            allowAutomatedCalls: true,
            canReceiveSms: true,
          },
        ],
        salesperson: { id: '8' },
        cameFrom: {
          value: CameFromType.ReferralList,
          additionalInfo: '',
          referralPerson: null,
        },
        email: 'marianna@gmail.com',
      },
      alerts: [
        {
          id: '1',
          condition: 'missing_photo',
          message: 'Missing photo',
          sound: true,
          type: 'warning',
          appointmentId: null,
          noteId: null,
        },
      ],
      createdAt: '2020-09-21T14:32:24.000000Z',
      updatedAt: '2020-09-21T14:32:24.000000Z',
    },
    {
      id: '13',
      source: 'front desk',
      action: 'allowed',
      result: 'error',
      checkInType: 'barcode',
      person: {
        id: '23',
        personalId: '637282',
        type: 'Active member',
        firstName: 'Wil',
        lastName: 'Almond',
        birthday: '1997-07-28T00:00:00.000000Z',
        photoUrl: '',
        phones: [
          {
            id: '1',
            phoneType: PhoneTypes.Home,
            phone: '+1-209-825-9093',
            allowAutomatedCalls: false,
            canReceiveSms: true,
            allowForSms: false,
          },
        ],
        salesperson: { id: '8' },
        cameFrom: {
          value: CameFromType.ReferralList,
          additionalInfo: '',
          referralPerson: null,
        },
        email: 'wil@gmail.com',
      },
      alerts: [],
      createdAt: '2020-09-21T14:28:24.000000Z',
      updatedAt: '2020-09-21T14:28:24.000000Z',
    },
    {
      id: '14',
      source: 'front desk',
      action: 'allowed',
      result: null,
      checkInType: 'barcode',
      person: {
        id: '24',
        personalId: '839020',
        type: 'Active member',
        firstName: 'Barak',
        lastName: 'Dickens',
        birthday: '1988-03-01T00:00:00.000000Z',
        photoUrl: '',
        phones: [
          {
            id: '1',
            phoneType: PhoneTypes.Home,
            phone: '+1-352-572-3129',
            allowAutomatedCalls: true,
            canReceiveSms: true,
            allowForSms: false,
          },
        ],
        salesperson: { id: '8' },
        cameFrom: {
          value: CameFromType.ReferralList,
          additionalInfo: '',
          referralPerson: null,
        },
        email: 'barak@gmail.com',
      },
      alerts: [
        {
          id: '4',
          condition: 'missing_first_name',
          message: 'Missing First Name',
          sound: true,
          type: 'danger',
          appointmentId: null,
          noteId: null,
        },
      ],
      createdAt: '2020-09-21T14:25:24.000000Z',
      updatedAt: '2020-09-21T14:25:24.000000Z',
    },
    {
      id: '15',
      source: 'front desk',
      action: 'allowed',
      result: 'inactive',
      checkInType: 'barcode',
      person: {
        id: '25',
        personalId: '777289',
        type: 'Active member',
        firstName: 'Vinnie',
        lastName: 'Hopkins',
        birthday: '1996-10-09T00:00:00.000000Z',
        photoUrl: '',
        phones: [
          {
            id: '1',
            phoneType: PhoneTypes.Home,
            phone: '+1-818-510-8511',
            allowAutomatedCalls: true,
            canReceiveSms: false,
          },
        ],
        salesperson: { id: '8' },
        cameFrom: {
          value: CameFromType.ReferralList,
          additionalInfo: '',
          referralPerson: null,
        },
        email: 'vinnie@gmail.com',
      },
      alerts: [],
      createdAt: '2020-09-21T14:22:24.000000Z',
      updatedAt: '2020-09-21T14:22:24.000000Z',
    },
    {
      id: '16',
      source: 'front desk',
      action: 'allowed',
      result: 'error',
      checkInType: 'barcode',
      person: {
        id: '26',
        personalId: '737737',
        type: 'Active member',
        firstName: 'Junior',
        lastName: 'Austin',
        birthday: '1990-02-28T00:00:00.000000Z',
        photoUrl: '',
        phones: [
          {
            id: '1',
            phoneType: PhoneTypes.Home,
            phone: '+1-904-263-4959',
            allowAutomatedCalls: true,
            canReceiveSms: true,
          },
        ],
        salesperson: { id: '8' },
        cameFrom: {
          value: CameFromType.ReferralList,
          additionalInfo: '',
          referralPerson: null,
        },
        email: 'junior@gmail.com',
      },
      alerts: [],
      createdAt: '2020-09-21T14:28:24.000000Z',
      updatedAt: '2020-09-21T14:28:24.000000Z',
    },
  ],
};
