import * as yup from 'yup';
import { List as ImmutableList } from 'immutable';
import { useIntl } from 'react-intl';

import { regExp } from 'common/constants/regExp';

import { pastOrTodayDateSchema } from 'common/validationSchemas/dateSchemas';
import { getAddressSchema } from 'common/validationSchemas/addressSchema';
import { phoneSchema } from 'common/validationSchemas/phoneSchema';
import { cameFromFieldsSchema } from 'common/validationSchemas/cameFromFieldsSchema';

import message from 'modules/crm/messages/leads';
import inputErrors from 'common/messages/inputErrors';
import { IEmployeeListItemImt } from 'common/interfaces/dictionary';
import { TShortProfileFieldInfoImt } from 'modules/corporate-settings/interfaces';
import {
  FieldNecessity,
  RequiredProfileInfo,
} from 'modules/corporate-settings/constants/requiredFields';

const requiredMessage = () => inputErrors.requiredFieldError;

export const PrimaryInfoStepValidationSchema = (
  employees: ImmutableList<IEmployeeListItemImt>,
  requiredFields: TShortProfileFieldInfoImt,
): yup.AnyObjectSchema => {
  const intl = useIntl();

  return yup.object().shape(
    {
      image: yup
        .object()
        .shape({ id: yup.string() })
        .nullable()
        .when(RequiredProfileInfo.Photo, {
          is: () => requiredFields.get(RequiredProfileInfo.Photo) === FieldNecessity.Required,
          then: schema => schema.required(requiredMessage),
          otherwise: schema => schema,
        }),

      firstName: yup
        .string()
        .trim()
        .matches(regExp.NAME, () => inputErrors.firstNameMatchError)
        .required(requiredMessage),
      lastName: yup
        .string()
        .trim()
        .matches(regExp.NAME, () => inputErrors.lastNameMatchError)
        .required(requiredMessage),
      birthday: pastOrTodayDateSchema.when(RequiredProfileInfo.Birthday, {
        is: () => requiredFields.get(RequiredProfileInfo.Birthday) === FieldNecessity.Required,
        then: schema => schema.required(requiredMessage),
        otherwise: schema => schema,
      }),
      gender: yup
        .string()
        .nullable()
        .required(requiredMessage),

      barcode: yup
        .string()
        .nullable()
        .notRequired()
        .when('barcode', {
          is: val => val?.length,
          then: rule =>
            rule
              .max(20, () => inputErrors.barcodeLengthError)
              .matches(regExp.BARCODE, () => inputErrors.barcodeMatchError),
        }),
      email: yup
        .string()
        .trim()
        .email(() => inputErrors.emailInvalidError)
        .when(RequiredProfileInfo.Email, {
          is: () => requiredFields.get(RequiredProfileInfo.Email) === FieldNecessity.Required,
          then: schema => schema.required(requiredMessage),
          otherwise: schema => schema,
        }),
      address: getAddressSchema(requiredFields),
      phones: yup
        .array(
          yup.object().shape({
            phone: phoneSchema.nullable().required(requiredMessage),
          }),
        )
        .test({
          message: requiredMessage,
          test: arr =>
            requiredFields.get(RequiredProfileInfo.PhoneNumber) !== FieldNecessity.Required ||
            !!arr?.length,
        }),
      salesperson: yup.mixed().when((employ, schema) => {
        if (employ?.id) {
          const isExistEmployee = Boolean(
            employees.find(c => {
              return c.get('id') === employ.id;
            }),
          );

          if (!isExistEmployee) {
            return schema.test(
              'salesperson',
              intl.formatMessage({ ...message.selectedSalespersonNotListAvailable }),
              () => false,
            );
          }
        }

        if (requiredFields.get(RequiredProfileInfo.Salesperson) === FieldNecessity.Required) {
          return schema.notOneOf(['', null], requiredMessage).required(requiredMessage);
        }

        return schema;
      }),
      ...cameFromFieldsSchema,
    },
    [['barcode', 'barcode']],
  );
};
