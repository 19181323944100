import { PeakModules } from 'common/constants/peakModules';
import usePermissionContext from 'common/hooks/context/usePermissionContext';

interface ILeadPermissions {
  hasCreatePermission: boolean;
  hasEditSalespersonPermission: boolean;
  hasEditStatusPermission: boolean;
}

export default function useLeadPermissions(
  module: PeakModules.Crm | PeakModules.PersonalTrainingCrm,
): ILeadPermissions {
  const isCrmModule = module === PeakModules.Crm;
  const isPTCrmModule = module === PeakModules.PersonalTrainingCrm;
  let hasCreatePermission = false;
  let hasEditSalespersonPermission = false;
  let hasEditStatusPermission = false;

  const {
    CRM_LEAD_CREATE,
    CRM_LEAD_EDIT_SALESPERSON,
    CRM_LEAD_EDIT_STATUS,
    PT_CRM_LEAD_CREATE,
    PT_CRM_LEAD_EDIT_SALESPERSON,
    PT_CRM_LEAD_EDIT_STATUS,
  } = usePermissionContext();

  if (isCrmModule) {
    hasCreatePermission = CRM_LEAD_CREATE;
    hasEditSalespersonPermission = CRM_LEAD_EDIT_SALESPERSON;
    hasEditStatusPermission = CRM_LEAD_EDIT_STATUS;
  } else if (isPTCrmModule) {
    hasCreatePermission = PT_CRM_LEAD_CREATE;
    hasEditSalespersonPermission = PT_CRM_LEAD_EDIT_SALESPERSON;
    hasEditStatusPermission = PT_CRM_LEAD_EDIT_STATUS;
  }

  return {
    hasCreatePermission,
    hasEditSalespersonPermission,
    hasEditStatusPermission,
  };
}
