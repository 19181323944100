import * as yup from 'yup';

import { getRequiredMessage } from 'common/constants/globalConstants';

import inputErrors from 'common/messages/inputErrors';

export const NewEmailValidationSchema = yup.object().shape({
  subject: yup
    .string()
    .trim()
    .max(70, () => inputErrors.subjectMaxLengthError)
    .required(getRequiredMessage),
  emailSenderName: yup
    .string()
    .trim()
    .required(getRequiredMessage),
  campaign: yup.string(),
});
