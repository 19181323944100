import { ConstOptions } from 'common/constants/classes';

import messages from 'modules/front-desk/messages/messages';

export const CURRENT_CHECKIN_TIME_MIN = 1;

export const FETCH_CHECK_INS = 'FETCH_CHECK_INS';
export const FETCH_CHECK_INS_LOADING = 'FETCH_CHECK_INS_LOADING';
export const FETCH_CHECK_INS_ERROR = 'FETCH_CHECK_INS_ERROR';

export const SET_SELECTED_CHECK_IN = 'SET_SELECTED_CHECK_IN';
export const SET_MAIN_PANEL_PERSON = 'SET_MAIN_PANEL_PERSON';
export const SET_RIGHT_PANEL_PERSON = 'SET_RIGHT_PANEL_PERSON';

export const FETCH_CLIENT_MEMBER_TAGS = 'FETCH_CLIENT_MEMBER_TAGS';

export const GET_ALERTS_SETTINGS = 'GET_ALERTS_SETTINGS';

export const UPDATE_PERSON_PHOTO = 'UPDATE_PERSON_PHOTO';

export const UPDATE_PERSON_ALERTS = 'UPDATE_PERSON_ALERTS';

export const UPDATE_CURRENTLY_CHECKING_IN = 'UPDATE_CURRENTLY_CHECKING_IN';

export const FETCH_CLUB_ENTRANCES = 'FETCH_CLUB_ENTRANCES';
export const FETCH_CLUB_ENTRANCES_LOADING = 'FETCH_CLUB_ENTRANCES_LOADING';

export const actionTypes = {
  FETCH_CHECK_INS: 'FETCH_CHECK_INS',
  FETCH_CHECK_INS_LOADING: 'FETCH_CHECK_INS_LOADING',
  RESET_CHECK_INS: 'RESET_CHECK_INS',
  FETCH_CHECK_INS_ERROR: 'FETCH_CHECK_INS_ERROR',
  FETCH_LIFE_SOCKET_CHECK_IN: 'FETCH_LIFE_SOCKET_CHECK_IN',
  RESET_CURRENT_CHECK_IN: 'RESET_CURRENT_CHECK_IN',
  SET_CHECK_IN_ACTION_LOADING: 'SET_CHECK_IN_ACTION_LOADING',
  SET_CHECK_IN_ACTION_RESULT: 'SET_CHECK_IN_ACTION_RESULT',
  RESET_CHECK_IN_ACTION_RESULT: 'RESET_CHECK_IN_ACTION_RESULT',

  SET_SELECTED_CHECK_IN: 'SET_SELECTED_CHECK_IN',
  RESET_SELECTED_CHECK_IN: 'RESET_SELECTED_CHECK_IN',
  SET_MAIN_PANEL_PERSON: 'SET_MAIN_PANEL_PERSON',
  SET_RIGHT_PANEL_PERSON: 'SET_RIGHT_PANEL_PERSON',
  RESET_PANEL_PERSON: 'RESET_PANEL_PERSON',

  // Tags
  FETCH_CLIENT_MEMBER_TAGS: 'FETCH_CLIENT_MEMBER_TAGS',
  CREATE_CLIENT_MEMBER_TAG: 'CREATE_CLIENT_MEMBER_TAG',
  CREATE_CLIENT_MEMBER_TAG_LOADING: 'CREATE_CLIENT_MEMBER_TAG_LOADING',
  RESET_CREATE_CLIENT_MEMBER_TAG_SUCCESS: 'RESET_CREATE_CLIENT_MEMBER_TAG_SUCCESS',

  GET_ALERTS_SETTINGS: 'GET_ALERTS_SETTINGS',

  UPDATE_PERSON_PHOTO: 'UPDATE_PERSON_PHOTO',

  UPDATE_PERSON_ALERTS: 'UPDATE_PERSON_ALERTS',

  UPDATE_PERSON_STATUS: 'UPDATE_PERSON_STATUS',

  SET_CURRENT_CHECK_IN: 'SET_CURRENT_CHECK_IN',

  FETCH_CLUB_ENTRANCES: 'FETCH_CLUB_ENTRANCES',
  FETCH_CLUB_ENTRANCES_LOADING: 'FETCH_CLUB_ENTRANCES_LOADING',
  // Create member
  FETCH_CREATE_MEMBER_LOADING: 'FETCH_CREATE_MEMBER_LOADING',
  FETCH_CREATE_MEMBER_RESULT: 'FETCH_CREATE_MEMBER_RESULT',
  RESET_CREATE_MEMBER_RESULT: 'RESET_CREATE_MEMBER_RESULT',

  SET_SELECTED_ENTRANCE_ID: 'SET_SELECTED_ENTRANCE_ID',
  RESET_SELECTED_ENTRANCE_ID: 'RESET_SELECTED_ENTRANCE_ID',
};

export const CHECK_IN_LIST_LARGE_ITEMS_NUMBER = 2;

export const CustomAlertConditions: Array<{ label: string; value: string }> = [
  {
    value: 'upcoming_appointment',
    label: 'Upcoming appointment',
  },
  {
    value: 'missed_appointment',
    label: 'Missed appointment',
  },
  {
    value: 'note_is_present',
    label: 'Note is Present',
  },
  {
    value: 'missing_emergency_contact_info',
    label: 'Missing emergency contact information',
  },
  {
    value: 'missing_billing_info',
    label: 'Missing billing information',
  },
  {
    value: 'missing_insurance_info',
    label: 'Missing insurance information',
  },
  {
    value: 'missing_allergies',
    label: 'Missing allergies',
  },
  {
    value: 'upcoming_salesperson',
    label: 'Missing salesperson',
  },
];

export const StateAbbreviations: Array<{ name: string; abbreviation: string }> = [
  {
    name: 'Alabama',
    abbreviation: 'AL',
  },
  {
    name: 'Alaska',
    abbreviation: 'AK',
  },
  {
    name: 'American Samoa',
    abbreviation: 'AS',
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
  },
  {
    name: 'California',
    abbreviation: 'CA',
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
  },
  {
    name: 'Delaware',
    abbreviation: 'DE',
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC',
  },
  {
    name: 'Federated States Of Micronesia',
    abbreviation: 'FM',
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
  },
  {
    name: 'Guam',
    abbreviation: 'GU',
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI',
  },
  {
    name: 'Idaho',
    abbreviation: 'ID',
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA',
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
  },
  {
    name: 'Marshall Islands',
    abbreviation: 'MH',
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
  },
  {
    name: 'Michigan',
    abbreviation: 'MI',
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
  },
  {
    name: 'New York',
    abbreviation: 'NY',
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
  },
  {
    name: 'Northern Mariana Islands',
    abbreviation: 'MP',
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
  },
  {
    name: 'Palau',
    abbreviation: 'PW',
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
  },
  {
    name: 'Puerto Rico',
    abbreviation: 'PR',
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC',
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
  },
  {
    name: 'Virgin Islands',
    abbreviation: 'VI',
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV',
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
  },
];

export enum AlertTabs {
  PredefinedAlerts = 'PREDEFINED_ALERTS',
  CustomAlerts = 'CUSTOM_ALERTS',
  Preferences = 'PREFERENCES',
}

export const AlertSettingsTabs = new ConstOptions([
  {
    key: AlertTabs.PredefinedAlerts,
    value: AlertTabs.PredefinedAlerts,
    message: messages.predefinedAlertsTabTitle,
  },
  {
    key: AlertTabs.CustomAlerts,
    value: AlertTabs.CustomAlerts,
    message: messages.customAlertsTabTitle,
  },
  {
    key: AlertTabs.Preferences,
    value: AlertTabs.Preferences,
    message: messages.preferencesTabTitle,
  },
]);
