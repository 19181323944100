import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { DialogComponent } from 'common/components/index';
import { useDispatch, useSelector } from 'react-redux';

import timeclockMessages from 'modules/timeclock/messages';
import AddClockInDataForm from './AddClockInDataForm';
import {
  addClockInManuallyData,
  addClockInManuallyDataAction,
  getTimeclockJobPositions,
} from 'modules/timeclock/state/actions';
import {
  selectAddClockInResult,
  selectPayrollJobPositionsLoading,
} from 'modules/timeclock/state/selectors';
import { IAddClockInManuallyData } from 'modules/timeclock/interfaces/timeclock';

interface IProps {
  isOpen: boolean;
  isLoading?: boolean;
  employeeId: string;
  onClose: () => void;
  isProfile?: boolean;
}

const AddClockInDataModal = ({
  isOpen,
  isLoading = false,
  employeeId,
  onClose,
  isProfile = false,
}: IProps): JSX.Element => {
  const dispatch = useDispatch();
  const formId = 'add-clock-in-data-form';

  const jobPositionsOptionsLoading = useSelector(selectPayrollJobPositionsLoading);
  const addClockInResult = useSelector(selectAddClockInResult);

  useEffect(() => {
    dispatch(getTimeclockJobPositions(employeeId, isProfile, false));
  }, [dispatch, employeeId, isProfile]);

  useEffect(() => {
    if (addClockInResult) {
      onClose();
      dispatch(addClockInManuallyDataAction(null));
    }
  }, [addClockInResult, onClose, dispatch]);

  const addClockIn = (data: IAddClockInManuallyData) => {
    dispatch(addClockInManuallyData(employeeId, false, data, isProfile));
  };

  return (
    <DialogComponent
      cancelBtn
      submitBtn
      isOpen={isOpen}
      loading={isLoading || jobPositionsOptionsLoading}
      onClose={onClose}
      title={<FormattedMessage {...timeclockMessages.addClockInTitle} />}
      size="xs"
      supressBottomShadow
      hasShadowsOnScroll={false}
      formId={formId}
    >
      <AddClockInDataForm formId={formId} onSubmit={addClockIn} />
    </DialogComponent>
  );
};

export default AddClockInDataModal;
