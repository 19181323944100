import { combineReducers } from 'redux-immutable';
import daySheetTotalByDateRangeReducer from './daySheetTotalByDateRangeReducer';
import daySheetTotalByEventReducer from './daySheetTotalByEventReducer';
import daySheetTotalBySalespersonReducer from './daySheetTotalBySalespersonReducer';
import daySheetTotalByDayOfWeekReducer from './daySheetTotalByDayOfWeekReducer';
import daySheetMarketSourceTotalReducer from './daySheetMarketSourceTotalReducer';
import daySheetMemberTypeTotalReducer from './daySheetMemberTypeTotalReducer';
import daySheetPaymentMethodTotalReducer from './daySheetPaymentMethodTotalReducer';
import daySheetEventKeysReducer from './daySheetEventKeysReducer';
import daySheetStatusKeysReducer from './daySheetStatusKeysReducer';

export default combineReducers({
  daySheetTotalByDateRangeReducer,
  daySheetTotalByEventReducer,
  daySheetTotalBySalespersonReducer,
  daySheetTotalByDayOfWeekReducer,
  daySheetMarketSourceTotalReducer,
  daySheetMemberTypeTotalReducer,
  daySheetPaymentMethodTotalReducer,
  daySheetEventKeysReducer,
  daySheetStatusKeysReducer,
});
