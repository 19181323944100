import { createSelector } from 'reselect';

const selectUploadFileDomain = (state: any) => state.get('uploadFile');

export const selectUploadedFile = createSelector(selectUploadFileDomain, state =>
  state.get('file'),
);

export const selectUploadedFileDialogId = createSelector(selectUploadFileDomain, state =>
  state.get('dialogId'),
);

export const selectUploadFileLoading = createSelector(selectUploadFileDomain, state =>
  state.get('loading'),
);

export const selectUploadFileError = createSelector(selectUploadFileDomain, state =>
  state.get('error'),
);

export const selectUploadedFileById = (id: string) =>
  createSelector([selectUploadedFile, selectUploadedFileDialogId], (file, dialogId) => {
    if (dialogId === id) {
      return file;
    }
    return null;
  });

export const selectUploadFileLoadingById = (id: string) =>
  createSelector([selectUploadFileLoading, selectUploadedFileDialogId], (isLoading, dialogId) => {
    if (dialogId === id) {
      return isLoading;
    }
    return false;
  });

export const selectUploadFileErrorById = (id: string) =>
  createSelector([selectUploadFileError, selectUploadedFileDialogId], (error, dialogId) => {
    if (dialogId === id) {
      return error;
    }
    return null;
  });
