import { fromJS } from 'immutable';
import { actionTypes } from 'modules/crm/constants/tasks';

const initStateTasks = fromJS({
  list: [],
  meta: {
    page: 0,
    perPage: 25,
    total: 0,
  },
  listLoading: false,
  taskCompleting: false,
  taskAssigning: false,
  tasksActionResult: {},
});

export const tasksTableReducer = (state = initStateTasks, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TASKS_LIST_LOADING:
      return state.set('listLoading', action.payload);

    case actionTypes.FETCH_TASKS_LIST_SUCCESS:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta))
        .set('listLoading', false);

    case actionTypes.TASK_COMPLETING:
      return state.set('taskCompleting', action.payload);

    case actionTypes.TASK_COMPLETED:
      return state.set('tasksActionResult', fromJS(action.payload));

    case actionTypes.SALESPERSON_TASKS_ASSIGNING:
      return state.set('taskAssigning', action.payload);

    case actionTypes.SALESPERSON_TASKS_ASSIGNED:
      return state.set('tasksActionResult', fromJS(action.payload));

    case actionTypes.RESET_TASKS_ACTION_RESULT:
      return state.set('tasksActionResult', fromJS({}));
    default:
      return state;
  }
};
