import GrapesJS from 'grapesjs';

import videoIcon from 'img/editor-icons/block-video.svg';
import { defaultToolbar } from './TextPlugin';
import { IObject } from 'common/interfaces/common';

(GrapesJS as IObject).plugins.add('video-upload', function(editor) {
  function addCommands() {
    editor.Commands.add('edit-video', {
      run() {},
      stop(commandEditor, sender, data) {
        if (data && data.model) {
          if (data.src.includes('youtube.com')) {
            data.model.set({ provider: 'yt' });
          }
          data.model.set({ src: data.src });
        }
      },
    });
  }

  function addComponents() {
    const defaultType = editor.DomComponents.getType('video');
    const { initToolbar } = defaultType.model.prototype;

    editor.DomComponents.addType('video', {
      model: {
        ...defaultType.model,
        defaults: {
          highlightable: true,
        },
        initToolbar(args) {
          initToolbar.apply(this, args);

          this.set('toolbar', defaultToolbar);
        },
      },
      view: {
        ...defaultType.view,
        events: {
          dblclick: 'onDoubleClick',
        },
        onDoubleClick(e) {
          e.stopPropagation();
          this.runEdit(this.model);
        },
        init({ model }) {
          if (!model.get('src')) {
            this.runEdit(model);
          }
        },
        runEdit(model) {
          editor.stopCommand('edit-video');
          editor.runCommand('edit-video', { model });
        },
      },
    });
  }

  function addBlocks() {
    editor.BlockManager.add('video', {
      label: `
        <img class="gjs-block-icon" src="${videoIcon}" alt="video" />
        <div class="gjs-block-title">Video</div>
      `,
      content: {
        type: 'video',
        src: '',
        style: {
          height: '150px',
          width: '250px',
          'max-width': '100%',
        },
      },
    });
  }

  addCommands();
  addComponents();
  addBlocks();
});

export default 'video-upload';
