import React from 'react';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles, Link as MaterialLink } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    link: {
      fontWeight: small => (small ? 'normal' : 500),
      padding: '2px 5px 2px 0',
      '&:active': {
        color: 'red',
      },
      '& button': {
        textAlign: 'left',
      },
    },
  }),
);

interface IReportLinkProps {
  to: string;
  title: string;
  small?: boolean;
  backState?: any;
  className?: string;
}

const ReportLink = ({ to, title, small, backState, className }: IReportLinkProps): JSX.Element => {
  const classes = useStyles(small || false);

  return (
    <Link className={classes.link} state={backState} to={to}>
      <MaterialLink component="button" underline="none" className={className}>
        {title}
      </MaterialLink>
    </Link>
  );
};

export default React.memo(ReportLink);
