import { useState, useEffect } from 'react';
import { Sizes } from 'common/constants/screen';

export interface IScreenSizes {
  isSmallScreen: boolean;
  isMiddleScreen: boolean;
  isLargeScreen: boolean;
}

const useScreenSizes = (): IScreenSizes => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [isSmallScreen, setSmallScreen] = useState<boolean>(false);
  const [isMiddleScreen, setMiddleScreen] = useState<boolean>(false);
  const [isLargeScreen, setLargeScreen] = useState<boolean>(false);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      const isNewSizeSmall = window.innerWidth < Sizes.SMALL_SCREEN;
      const isNewSizeMiddle = window.innerWidth < Sizes.MIDDLE_SCREEN;
      const isNewSizeLarge = window.innerWidth < Sizes.LARGE_SCREEN;

      if (isSmallScreen !== isNewSizeSmall) {
        setSmallScreen(isNewSizeSmall);
      }

      if (isMiddleScreen !== isNewSizeMiddle) {
        setMiddleScreen(isNewSizeMiddle);
      }

      if (isLargeScreen !== isNewSizeLarge) {
        setLargeScreen(isNewSizeSmall);
      }
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [isSmallScreen, isMiddleScreen, isLargeScreen]); // Empty array ensures that effect is only run on mount

  return {
    isSmallScreen,
    isMiddleScreen,
    isLargeScreen,
  };
};

export default useScreenSizes;
