import { List } from 'immutable';

import { IPageMetaImt } from 'common/interfaces/pagination';
import { IActionRequestStatusImt } from 'common/state/interfaces';
import { IEmployeeRoleImt } from 'common/interfaces/employee';

// Role
const rolesListPath = ['permissions', 'roles', 'rolesList'];
const selectRolesListLoading = (state): boolean => state.getIn([...rolesListPath, 'isLoading']);

const selectRolesList = (state): List<IEmployeeRoleImt> => state.getIn([...rolesListPath, 'list']);

const selectRolesListMeta = (state): IPageMetaImt => state.getIn([...rolesListPath, 'meta']);

// Role item
const roleItemPath = ['permissions', 'roles', 'roleItem'];
const selectRoleItemLoading = (state): boolean => state.getIn([...roleItemPath, 'isLoading']);

const selectRoleItemData = (state): IEmployeeRoleImt =>
  state.getIn([...roleItemPath, 'roleItemData']);

const selectRoleItemRequestState = (state): IActionRequestStatusImt =>
  state.getIn([...roleItemPath, 'request']);

export {
  // Role
  selectRolesListLoading,
  selectRolesList,
  selectRolesListMeta,
  // Role item
  selectRoleItemLoading,
  selectRoleItemData,
  selectRoleItemRequestState,
};
