import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { ReactComponent as PermissionIcon } from 'img/icons/permission.svg';
import { ReactComponent as ArrowLeftIcon } from 'img/icons/arrow-left.svg';
import { Button } from 'common/components';
import { CustomTheme } from 'common/ui/interfaces';
import { colors } from 'common/ui/theme/default';
import commonMessages from 'common/messages/messages';
import permissionMessages from 'common/messages/permissions';

const useStyles = makeStyles((theme: CustomTheme) => ({
  accessDeniedContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 35,
    gap: 16,
    height: '100%',
  },
  accessIcon: {
    color: colors.text.mediumGray,
  },
  btnIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: 8,
  },
}));

const AccessDenied: FC = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <Grid container className={classes.accessDeniedContainer}>
      <Grid item>
        <PermissionIcon className={classes.accessIcon} />
      </Grid>

      <Grid item>
        <Typography variant="h2" color="textSecondary">
          <FormattedMessage {...permissionMessages.accessDenied} />
        </Typography>
      </Grid>

      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate(-1)}
          startIcon={<ArrowLeftIcon className={classes.btnIcon} />}
        >
          <FormattedMessage {...commonMessages.backBtn} />
        </Button>
      </Grid>
    </Grid>
  );
};

export default AccessDenied;
