import { defineMessages } from 'react-intl';

export default defineMessages({
  // short labels
  sundayShortLabel: {
    id: 'app.common.dateAndTime.weekdays.sunday.short',
    defaultMessage: 'S',
  },
  sundayLabel: {
    id: 'app.common.dateAndTime.weekdays.sunday.general',
    defaultMessage: 'Sun',
  },
  sundayFullLabel: {
    id: 'app.common.dateAndTime.weekdays.sunday.full',
    defaultMessage: 'Sunday',
  },

  mondayShortLabel: {
    id: 'app.common.dateAndTime.weekdays.monday.short',
    defaultMessage: 'M',
  },
  mondayLabel: {
    id: 'app.common.dateAndTime.weekdays.monday.general',
    defaultMessage: 'Mon',
  },
  mondayFullLabel: {
    id: 'app.common.dateAndTime.weekdays.monday.full',
    defaultMessage: 'Monday',
  },

  tuesdayShortLabel: {
    id: 'app.common.dateAndTime.weekdays.tuesday.short',
    defaultMessage: 'T',
  },
  tuesdayLabel: {
    id: 'app.common.dateAndTime.weekdays.tuesday.general',
    defaultMessage: 'Tue',
  },
  tuesdayFullLabel: {
    id: 'app.common.dateAndTime.weekdays.tuesday.full',
    defaultMessage: 'Tuesday',
  },

  wednesdayShortLabel: {
    id: 'app.common.dateAndTime.weekdays.wednesday.short',
    defaultMessage: 'W',
  },
  wednesdayLabel: {
    id: 'app.common.dateAndTime.weekdays.wednesday.general',
    defaultMessage: 'Wed',
  },
  wednesdayFullLabel: {
    id: 'app.common.dateAndTime.weekdays.wednesday.full',
    defaultMessage: 'Wednesday',
  },

  thursdayShortLabel: {
    id: 'app.common.dateAndTime.weekdays.thursday.short',
    defaultMessage: 'T',
  },
  thursdayLabel: {
    id: 'app.common.dateAndTime.weekdays.thursday.general',
    defaultMessage: 'Thu',
  },
  thursdayFullLabel: {
    id: 'app.common.dateAndTime.weekdays.thursday.full',
    defaultMessage: 'Thursday',
  },

  fridayShortLabel: {
    id: 'app.common.dateAndTime.weekdays.friday.short',
    defaultMessage: 'F',
  },
  fridayLabel: {
    id: 'app.common.dateAndTime.weekdays.friday.general',
    defaultMessage: 'Fri',
  },
  fridayFullLabel: {
    id: 'app.common.dateAndTime.weekdays.full.friday',
    defaultMessage: 'Friday',
  },
  saturdayShortLabel: {
    id: 'app.common.dateAndTime.weekdays.saturday.short',
    defaultMessage: 'S',
  },
  saturdayLabel: {
    id: 'app.common.dateAndTime.weekdays.saturday.general',
    defaultMessage: 'Sat',
  },
  saturdayFullLabel: {
    id: 'app.common.dateAndTime.weekdays.saturday.full',
    defaultMessage: 'Saturday',
  },
});
