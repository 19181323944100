import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

interface ISignFieldContainer {
  messageDescriptor: MessageDescriptor;
  children: JSX.Element;
}

const SignFieldContainer = (props: ISignFieldContainer) => {
  const { children, messageDescriptor } = props;
  return (
    <Grid justifyContent="space-between" container>
      <Grid container item alignItems="center" xs={6}>
        <Typography variant="body1">
          <FormattedMessage {...messageDescriptor} />
        </Typography>
      </Grid>
      <Grid item xs={5}>
        {children}
      </Grid>
    </Grid>
  );
};

export default SignFieldContainer;
