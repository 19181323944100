/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { MenuItem, ListItemIcon, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

import { FilterViewTypes, IFilterOption, IMultipleFilterProps } from 'common/interfaces/filter';

import { Checkbox } from 'common/components';
import DropdownFilter from '../DropdownFilter/DropdownFilter';
import HoverFilter from '../HoverFilter/HoverFilter';
import FilterMenuBody from '../FilterMenuBody/FilterMenuBody';

import messages from '../messages';

const useStyles = makeStyles(() => ({
  menuListItemIcon: {
    marginLeft: '-3px!important',
    marginRight: '11px!important',
    '& .MuiCheckbox-root': {
      width: 18,
      height: 18,
    },
  },
  loader: {
    '& svg': {
      margin: 0,
    },
  },
}));

const MultipleFilter = (props: IMultipleFilterProps): JSX.Element => {
  const classes = useStyles();
  const {
    viewType,
    value,
    settings: { name, title, type, options, isLoading },
    dialog,
    onChange,
  } = props;

  // state

  const initialState = value || [];
  const [selectedOptions, setSelectedOptions] = useState<IFilterOption[]>(initialState);

  // constants

  const selectedTotal = selectedOptions.length;
  const isOptionSelected = (option: IFilterOption): boolean =>
    selectedOptions.findIndex(opt => opt.key === option.key) !== -1;

  // handlers

  const handleOptionClick = (option: IFilterOption): void => {
    let newSelectedOptions: IFilterOption[];

    if (isOptionSelected(option)) {
      newSelectedOptions = selectedOptions.filter(
        selectedOption => selectedOption.key !== option.key,
      );
    } else {
      newSelectedOptions = [...selectedOptions, option];
    }

    // Sort options to optimize compare in container
    newSelectedOptions.sort((a, b) => a.key.localeCompare(b.key));
    setSelectedOptions(newSelectedOptions);
  };

  const handleApplyFilter = useCallback(
    (closeMenu?: () => void): void => {
      const selected = selectedOptions.length > 0 ? selectedOptions : null;
      onChange(
        {
          name,
          type,
          value: selected || [],
        },
        name,
      );
      if (closeMenu) closeMenu();
    },
    [name, type, selectedOptions, onChange],
  );

  const handleClearFilter = useCallback(() => setSelectedOptions([]), [setSelectedOptions]);

  const handleResetFilter = useCallback(() => setSelectedOptions(value || []), [value]);

  useEffect(() => {
    setSelectedOptions(value || []);
  }, [value]);

  // render

  const renderFilterTitle = (): JSX.Element | string | React.ReactNode => {
    if (dialog) {
      return title;
    }

    if (!value || value.length === 0) {
      return (
        <Typography noWrap component="span" color="inherit">
          <FormattedMessage {...messages.filterTitle} values={{ title, filter: 'All' }} />
        </Typography>
      );
    }

    if (value.length === 1) {
      const selectedOption = options?.find(
        (option: IFilterOption) => option.value === value[0].value,
      );
      return isLoading ? (
        <CircularProgress size="16px" color="inherit" className={classes.loader} />
      ) : (
        <Typography noWrap component="span" color="inherit">
          {selectedOption?.label || value[0].label}
        </Typography>
      );
    }

    return title;
  };

  const renderFilterBody = (closeMenu?: () => void) => {
    return (
      <FilterMenuBody
        dialog={dialog}
        showApply
        showClear
        disableClear={selectedTotal === 0}
        onClearFilter={handleClearFilter}
        onApplyFilter={() => handleApplyFilter(closeMenu)}
      >
        {options?.map(option => (
          <MenuItem key={option.key} onClick={() => handleOptionClick(option)} disableRipple>
            <ListItemIcon className={classes.menuListItemIcon}>
              <Checkbox checked={isOptionSelected(option)} indeterminate={false} />
            </ListItemIcon>
            <Typography color="inherit">{option.label}</Typography>
          </MenuItem>
        ))}
      </FilterMenuBody>
    );
  };

  const renderDropdownFilter = () => (
    <DropdownFilter
      dialogMenu={dialog}
      clearBtn
      name={name}
      title={renderFilterTitle()}
      filtersTotal={value ? value.length : 0}
      clearDisabled={selectedTotal === 0}
      renderBody={renderFilterBody}
      onClear={handleClearFilter}
      onClose={handleResetFilter}
      onDialogApply={handleApplyFilter}
    />
  );

  const renderHoverMenuFilter = () => {
    return (
      <HoverFilter
        name={name}
        title={renderFilterTitle()}
        filtersTotal={value ? value.length : 0}
        dialogMenu={dialog}
        clearBtn
        clearDisabled={selectedTotal === 0}
        onClear={handleClearFilter}
        onClose={handleResetFilter}
        renderBody={renderFilterBody}
        onDialogApply={handleApplyFilter}
      />
    );
  };

  return viewType === FilterViewTypes.DROPDOWN ? renderDropdownFilter() : renderHoverMenuFilter();
};

export default React.memo(MultipleFilter);
