import { ConstOptions } from 'common/constants/classes';

import messages from 'common/messages/messages';
import { EmployeePayTypes } from 'common/constants/employee';

export const actionTypes = {
  RESET_EMPLOYEE_FORM: 'EMPLOYEES/RESET_EMPLOYEE_FORM',

  FETCH_CLIENTS_LOADING: 'EMPLOYEES/FETCH_CLIENTS_LOADING',
  FETCH_CLIENTS: 'EMPLOYEES/FETCH_CLIENTS',

  FETCH_ROLES_LOADING: 'EMPLOYEES/FETCH_ROLES_LOADING',
  FETCH_ROLES: 'EMPLOYEES/FETCH_ROLES',

  FETCH_EMPLOYEE_LOADING: 'EMPLOYEES/FETCH_EMPLOYEE_LOADING',
  FETCH_EMPLOYEE: 'EMPLOYEES/FETCH_EMPLOYEE',

  FETCH_EMPLOYEES_LOADING: 'EMPLOYEES/FETCH_EMPLOYEES_LOADING',
  FETCH_EMPLOYEES: 'EMPLOYEES/FETCH_EMPLOYEES',
  RESET_EMPLOYEES_LIST: 'EMPLOYEES/RESET_EMPLOYEES_LIST',

  CREATE_EMPLOYEE_LOADING: 'EMPLOYEES/CREATE_EMPLOYEE_LOADING',
  CREATE_EMPLOYEE: 'EMPLOYEES/CREATE_EMPLOYEE',
  CREATE_EMPLOYEE_ERROR: 'EMPLOYEES/CREATE_EMPLOYEE_ERROR',

  UPDATE_EMPLOYEE_LOADING: 'EMPLOYEES/UPDATE_EMPLOYEE_LOADING',
  UPDATE_EMPLOYEE: 'EMPLOYEES/UPDATE_EMPLOYEE',
  UPDATE_EMPLOYEE_ERROR: 'EMPLOYEES/UPDATE_EMPLOYEE_ERROR',

  EMPLOYEE_CHANGE_STATUS_LOADING: 'EMPLOYEES/EMPLOYEE_CHANGE_STATUS_LOADING',
  EMPLOYEE_CHANGE_STATUS: 'EMPLOYEES/EMPLOYEE_CHANGE_STATUS',
  RESET_EMPLOYEE_CHANGE_STATUS_RESULT: 'EMPLOYEES/RESET_EMPLOYEE_CHANGE_STATUS_RESULT',

  // profile
  FETCH_PROFILE_VIEW_SUCCESS: 'EMPLOYEES/FETCH_PROFILE_VIEW_SUCCESS',
  FETCH_PROFILE_VIEW_LOADING: 'EMPLOYEES/FETCH_PROFILE_VIEW_LOADING',

  FETCH_PROFILE_SUCCESS: 'EMPLOYEES/FETCH_PROFILE_SUCCESS',
  FETCH_PROFILE_LOADING: 'EMPLOYEES/FETCH_PROFILE_LOADING',

  FETCH_PROFILE_PERMISSIONS_SUCCESS: 'EMPLOYEES/FETCH_PROFILE_PERMISSIONS_SUCCESS',
  FETCH_PROFILE_PERMISSIONS_LOADING: 'EMPLOYEES/FETCH_PROFILE_PERMISSIONS_LOADING',

  UPDATE_PROFILE_PERMISSIONS_RESULT: 'EMPLOYEES/UPDATE_PROFILE_PERMISSIONS_RESULT',
  RESET_UPDATE_PROFILE_PERMISSIONS_RESULT: 'EMPLOYEES/RESET_UPDATE_PROFILE_PERMISSIONS_RESULT',

  FETCH_PROFILE_ATTACHMENTS_LOADING: 'EMPLOYEES/FETCH_PROFILE_ATTACHMENTS_LOADING',
  FETCH_PROFILE_ATTACHMENTS_SUCCESS: 'EMPLOYEES/FETCH_PROFILE_ATTACHMENTS_SUCCESS',

  FETCH_PROFILE_JOB_POSITIONS_LOADING: 'EMPLOYEES/FETCH_PROFILE_JOB_POSITIONS_LOADING',
  FETCH_PROFILE_JOB_POSITIONS_SUCCESS: 'EMPLOYEES/FETCH_PROFILE_JOB_POSITIONS_SUCCESS',

  FETCH_PROFILE_JOB_POSITIONS_HISTORY_LOADING:
    'EMPLOYEES/FETCH_PROFILE_JOB_POSITIONS_HISTORY_LOADING',
  FETCH_PROFILE_JOB_POSITIONS_HISTORY_SUCCESS:
    'EMPLOYEES/FETCH_PROFILE_JOB_POSITIONS_HISTORY_SUCCESS',
  RESET_PROFILE_JOB_POSITIONS_HISTORY: 'EMPLOYEES/RESET_PROFILE_JOB_POSITIONS_HISTORY',

  UPDATE_PROFILE_JOB_POSITIONS_RESULT: 'EMPLOYEES/UPDATE_PROFILE_JOB_POSITIONS_RESULT',
  RESET_UPDATE_PROFILE_JOB_POSITIONS_RESULT: 'EMPLOYEES/RESET_UPDATE_PROFILE_JOB_POSITIONS_RESULT',

  FETCH_PROFILE_MODIFICATION_HISTORY: 'EMPLOYEES/FETCH_PROFILE_MODIFICATION_HISTORY',
  FETCH_PROFILE_RECENT_MODIFICATION_HISTORY: 'EMPLOYEES/FETCH_PROFILE_RECENT_MODIFICATION_HISTORY',
  FETCH_PROFILE_MODIFICATION_HISTORY_LOADING:
    'EMPLOYEES/FETCH_PROFILE_MODIFICATION_HISTORY_LOADING',
  FETCH_PROFILE_RECENT_MODIFICATION_HISTORY_LOADING:
    'EMPLOYEES/FETCH_PROFILE_RECENT_MODIFICATION_HISTORY_LOADING',
  FETCH_PROFILE_MODIFICATION_HISTORY_ITEM: 'EMPLOYEES/FETCH_PROFILE_MODIFICATION_HISTORY_ITEM',
  FETCH_PROFILE_MODIFICATION_HISTORY_ITEM_LOADING:
    'EMPLOYEES/FETCH_PROFILE_MODIFICATION_HISTORY_ITEM_LOADING',
  RESET_PROFILE_MODIFICATION_HISTORY_ITEM: 'EMPLOYEES/RESET_PROFILE_MODIFICATION_HISTORY_ITEM',

  CLEAR_PROFILE: 'EMPLOYEES/CLEAR_PROFILE',
};

export enum EmployeePaths {
  EMPLOYEES = '/',
  EMPLOYEE = ':id',
  NEW_EMPLOYEE = 'new',
  EDIT_EMPLOYEE = ':id/edit',
}

export const payTypeLabels = new ConstOptions([
  {
    key: EmployeePayTypes.HOURLY,
    value: EmployeePayTypes.HOURLY,
    message: messages.hourlyLabel,
  },
  {
    key: EmployeePayTypes.SALARY,
    value: EmployeePayTypes.SALARY,
    message: messages.salaryLabel,
  },
  {
    key: EmployeePayTypes.UNIT,
    value: EmployeePayTypes.UNIT,
    message: messages.unitLabel,
  },
]);
