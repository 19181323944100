import moment from 'moment-timezone';

import {
  FilterTypes,
  IDateRangeFilterValue,
  IFilter,
  IMultipleFilterValue,
} from 'common/interfaces/filter';
import { deepEquals } from 'common/utils/deepEquals';
import { DATE_FORMAT } from 'common/hooks/useTimezoneMoment';
import { IObject } from 'common/interfaces/common';

export const makeFilterChangeHandler = (
  filterValues: IFilter[],
  onFiltersChange: (values: IFilter[], changedFilterName?: string) => void,
): ((newFilter: IFilter, changedFilterName?: string) => IFilter[] | null) => {
  return (newFilter: IFilter, changedFilterName?: string): IFilter[] | null => {
    const newValues = [...filterValues];
    const prevFilter = newValues.find(filter => filter.name === newFilter.name);
    const updatingFilterIndex = newValues.indexOf(prevFilter);

    let newFilterValue;
    let prevFilterValue;

    // removing a label because its may be react element
    if (
      newFilter.type === FilterTypes.MULTIPLE ||
      newFilter.type === FilterTypes.MULTIPLE_WITH_PAGINATE
    ) {
      if (Array.isArray(newFilter.value)) {
        newFilterValue = newFilter.value.map(item => ({ ...item, label: '' }));
      }
      if (Array.isArray(prevFilter?.value)) {
        prevFilterValue = prevFilter?.value.map(item => ({ ...item, label: '' }));
      }
    } else {
      newFilterValue = { ...newFilter.value, label: '' };
      prevFilterValue = { ...prevFilter?.value, label: '' };
    }

    if (
      (prevFilter && deepEquals(prevFilterValue, newFilterValue)) ||
      (newFilter.value === null && !prevFilter)
    ) {
      return null;
    }

    if (prevFilter && !newFilter.value) {
      newValues.splice(updatingFilterIndex, 1);
    }

    if (prevFilter && newFilter.value) {
      newValues[updatingFilterIndex] = newFilter;
    }

    if (!prevFilter && newFilter.value) {
      newValues.push(newFilter);
    }

    onFiltersChange(newValues, changedFilterName);

    return newValues;
  };
};

export const convertToBulkEditFilters = <T extends IObject>(filters: IFilter[]): Partial<T> => {
  return filters.reduce((acc, item) => {
    if (item.type === FilterTypes.SINGLE && item.value && 'value' in item.value) {
      return { ...acc, [item.name]: item.value.value };
    }

    if (
      item.type === FilterTypes.DATE_RANGE &&
      item.value &&
      ('startDate' in item.value || 'endDate' in item.value)
    ) {
      let startDate: IDateRangeFilterValue['startDate'] = null;
      let endDate: IDateRangeFilterValue['endDate'] = null;

      if ('startDate' in item.value) {
        startDate = item.value.startDate;
      }

      if ('endDate' in item.value && item.value.endDate && typeof item.value.endDate === 'string') {
        endDate = item.value.endDate;
      }

      const startDateFilterResult = startDate
        ? {
            [`${item.name}StartDate`]: moment(startDate)
              .utc()
              .format(DATE_FORMAT),
          }
        : {};

      const endDateFilterResult = endDate
        ? {
            [`${item.name}EndDate`]: moment(endDate)
              .utc()
              .format(DATE_FORMAT),
          }
        : {};

      return {
        ...acc,
        ...startDateFilterResult,
        ...endDateFilterResult,
      };
    }

    if (
      item.type === FilterTypes.MULTIPLE ||
      (item.type === FilterTypes.MULTIPLE_WITH_PAGINATE && Array.isArray(item.value))
    ) {
      return {
        ...acc,
        [item.name]: (item.value as IMultipleFilterValue).map(filterOption => filterOption.value),
      };
    }

    return acc;
  }, {});
};
