import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Theme } from '@material-ui/core';
import { Button, DialogComponent } from 'common/components/index';

import commonMessages from 'common/messages/messages';
import timeclockMessages from 'modules/timeclock/messages';
import { ReactComponent as LockOpenIcon } from 'img/icons/lock-open.svg';
import UnlockKioskModeForm from 'common/components/UnlockKioskModeModal/UnlockKioskModeForm';
import { IUnlockCredentials } from 'modules/authentication/interfaces';
import { IServerError } from 'common/interfaces/http';

const useStyles = makeStyles((theme: Theme) => ({
  lockBtn: {
    marginBottom: theme.spacing(1),
  },
}));

interface IProps {
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onSubmit: (data: IUnlockCredentials) => void;
  serverError?: IServerError;
  toggleLockMode: () => void;
}

const ClockInEmployeeModal = ({
  isOpen,
  isLoading = false,
  onClose,
  onSubmit,
  serverError,
  toggleLockMode,
}: IProps): JSX.Element => {
  const classes = useStyles();

  const formId = 'clock-in-employee-form';

  return (
    <DialogComponent
      isOpen={isOpen}
      loading={isLoading}
      onClose={onClose}
      title={<FormattedMessage {...timeclockMessages.clockInEmployeeBtn} />}
      hasCustomFooter
      size="xs"
      supressBottomShadow
      hasShadowsOnScroll={false}
      formId={formId}
    >
      <>
        <Button
          variant="contained"
          onClick={toggleLockMode}
          startIcon={<LockOpenIcon />}
          className={classes.lockBtn}
        >
          <FormattedMessage {...commonMessages.lockBtn} />
        </Button>
        <UnlockKioskModeForm
          onSubmit={onSubmit}
          serverError={serverError}
          showCancelBtn
          onClose={onClose}
        />
      </>
    </DialogComponent>
  );
};

export default ClockInEmployeeModal;
