import React from 'react';
import { Controller, Control } from 'react-hook-form';
import { MenuItem } from '@material-ui/core';

// messages
import messages from 'common/components/Steps/SignDocumentStep/messages';
// components
import { Select } from 'common/components';
import SignFieldContainer from './SignFieldContainer';
import { convertPhoneNumber } from 'helpers/common';
// interfaces
import { IPhoneInfo } from 'common/interfaces/contract';

interface ISignViaSmsField {
  control: Control;
  phones: IPhoneInfo[];
  fieldName: string;
}

const SignViaSMSField = (props: ISignViaSmsField) => {
  const { control, phones, fieldName } = props;
  return (
    <SignFieldContainer messageDescriptor={messages.signSendDocumentTo}>
      <Controller
        name={fieldName}
        defaultValue={null}
        control={control}
        render={({ value, onChange }) => (
          <Select fullWidth size="small" value={value} onChange={onChange}>
            {phones.map(item => (
              <MenuItem key={item.phoneId} value={item.phoneId}>
                {convertPhoneNumber(item.phone)}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </SignFieldContainer>
  );
};

export default SignViaSMSField;
