import React from 'react';
import { useSelector } from 'react-redux';

// interfaces
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import {
  DocumentType,
  IPersonDocumentGroupItemImt,
} from 'common/components/PersonProfile/interfaces';
// components
import { SignContractStepContainer } from 'common/containers';
import { DialogComponent } from 'common/components/index';
import { StepContext } from 'common/createContext/stepContext';
import * as actions from 'common/components/PersonProfile/state/actions';
import { useAppDispatch } from 'store/hooks';
import { AlertCondition } from 'common/interfaces/alerts';
import { PeakModules } from 'common/constants/peakModules';
import { useCombinePrintLoading } from 'common/hooks/useCombinePrintLoading';
import { selectPersonDocumentHTMLLoading } from 'common/components/PersonProfile/state/selectors';

interface IProps {
  isOpen: boolean;
  document: IPersonDocumentGroupItemImt;
  onClose: () => void;
  module: PeakModuleForNewPersonType;
  personId?: string;
}

const { Provider: StepProvider } = StepContext;

const SignDocumentModal = ({
  isOpen,
  onClose,
  document,
  module,
  personId,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const isPersonDocumentHTMLLoading = useSelector(selectPersonDocumentHTMLLoading(personId));

  const isPersonDocumentHTMLPrinting = useCombinePrintLoading(isPersonDocumentHTMLLoading);
  const onConfirmSignDocument = () => {
    if (
      personId &&
      document?.get('type') === DocumentType.Waiver &&
      module === PeakModules.FrontDesk
    ) {
      dispatch(
        actions.resolvePersonUnsignedWaiver(
          personId,
          document?.get('id'),
          AlertCondition.UnsignedWaiver,
        ),
      );
    }
    onClose();
  };

  return (
    <DialogComponent
      loading={isPersonDocumentHTMLPrinting}
      isOpen={isOpen}
      onClose={onClose}
      disableBodyPadding
      formId="contract-form"
    >
      <StepProvider
        value={{
          onNext: onConfirmSignDocument,
        }}
      >
        <SignContractStepContainer
          module={module}
          docId={document?.get('id')}
          type={document?.get('type')}
          title={document?.get('title')}
        />
      </StepProvider>
    </DialogComponent>
  );
};

export default SignDocumentModal;
