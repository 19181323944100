import { ITableParams } from 'common/interfaces/table';

export enum TableOrderByParams {
  NAME = 'NAME',
  NUMBER = 'NUMBER',
  BARCODE = 'BARCODE',
  CLUBS_COUNT = 'CLUBS_COUNT',
  ACTIVE = 'ACTIVE',
  RATE = 'RATE',
  CODE = 'CODE',
  DURATION = 'DURATION',
  SERVICE = 'SERVICE',
  SERVICED_BY = 'SERVICED_BY',
  REDEEMED_BY = 'REDEEMED_BY',
  INVENTORY = 'INVENTORY',
  MEMBER = 'MEMBER',
  SERVICE_TYPE = 'SERVICE_TYPE',
  WEBSITE = 'WEBSITE',
  CLUB = 'CLUB',
  CLUBS = 'CLUBS',
  CLUB_NAME = 'CLUB_NAME',
  OPEN = 'OPEN',
  CONDITION = 'CONDITION',
  TYPE = 'TYPE',
  EMPLOYEES = 'EMPLOYEES',
  EMPLOYEE = 'EMPLOYEE',
  EMPLOYEES_COUNT = 'EMPLOYEES_COUNT',
  TERMINATION_DATE = 'TERMINATION_DATE',
  STATUS = 'STATUS',
  SALESPERSON = 'SALESPERSON',
  TITLE = 'TITLE',
  CLUB_LOCATION = 'CLUB_LOCATION',
  POTENTIAL_CLUB_LOCATION = 'POTENTIAL_CLUB_LOCATION',
  PHONE = 'PHONE',
  DEPARTMENT = 'DEPARTMENT',
  PAY_TYPE = 'PAY_TYPE',
  USER_ID = 'USER_ID',
  LOCATION = 'LOCATION',
  COST = 'COST',
  RETAIL_PRICE = 'RETAIL_PRICE',
  REVENUE_CODE = 'REVENUE_CODE',
  RETAIL_PRICE_WITH_TAX = 'RETAIL_PRICE_WITH_TAX',
  REORDER_POINT = 'REORDER_POINT',
  VENDOR = 'VENDOR',
  PRICE_PER_BILLING = 'PRICE_PER_BILLING',
  PACKAGE_TYPE = 'PACKAGE_TYPE',
  MEMBERSHIP = 'MEMBERSHIP',
  CONTENT_TYPE = 'CONTENT_TYPE',
  MODULE_TYPE = 'MODULE_TYPE',
  CREATED_DATE = 'CREATED_DATE',
  DATE = 'DATE',
  OPENED_DATE = 'OPENED_DATE',
  CLOSED_DATE = 'CLOSED_DATE',
  EMPLOYEE_OPENED = 'EMPLOYEE_OPENED',
  EMPLOYEE_CLOSED = 'EMPLOYEE_CLOSED',
  START_AMOUNT = 'START_AMOUNT',
  END_AMOUNT = 'END_AMOUNT',
  LEVEL_TYPE = 'LEVEL_TYPE',
  COUNT = 'COUNT',
  CLOCK_IN = 'CLOCK_IN',
  INITIAL_CLOCK_IN = 'INITIAL_CLOCK_IN',
  UNREDEEMED = 'UNREDEEMED',
  AUTOMATIC = 'AUTOMATIC',
  START_DATE = 'START_DATE',
  UPLOAD_DATE = 'UPLOAD_DATE',
  SIGN_DATE = 'SIGN_DATE',
  CAMPAIGN = 'CAMPAIGN',
}

export const perPageOptions = [10, 25, 50, 100, 200];
export const DEFAULT_TABLE_PARAMS: ITableParams = {
  page: 0,
  perPage: perPageOptions[1],
  order: 'asc',
  filters: [],
  searchStr: '',
  orderBy: TableOrderByParams.NAME,
};
