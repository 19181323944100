import { defineMessages } from 'react-intl';

export default defineMessages({
  feeOneTimeOption: {
    id: 'app.modules.services.fees.types.oneTime',
    defaultMessage: 'One time',
  },
  feeRegularOption: {
    id: 'app.modules.services.fees.types.regular',
    defaultMessage: 'Regular',
  },
  feeNameMinLengthError: {
    id: 'app.modules.services.fees.title.minError',
    defaultMessage: 'Title should be at least 2 symbols',
  },
  feeNameMaxLengthError: {
    id: 'app.modules.services.fees.title.maxError',
    defaultMessage: 'Title should be at less than 100 symbols',
  },
  editFeePageTitle: {
    id: 'app.modules.services.fees.feePage.title.create',
    defaultMessage: 'Edit fee',
  },
  newFeePageTitle: {
    id: 'app.modules.services.fees.feePage.title.create',
    defaultMessage: 'Create new fee',
  },
});
