import { ImmutableObject } from 'common/state/interfaces';

export enum FeeType {
  OneTime = 'ONE_TIME',
  Regular = 'REGULAR',
}

export interface IFee {
  id?: string;
  title: string;
  active: boolean;
  type: FeeType;
}
export type IFeeImt = ImmutableObject<IFee>;
