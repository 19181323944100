import React from 'react';
import { Box, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import messages from '../messages';
import { IEmployeeJobPosition } from 'common/interfaces/employee';
import { CustomTheme } from 'common/ui/interfaces';
import { Button, LoadingBackdrop } from 'common/components';
import { ReactComponent as HistoryIcon } from 'img/icons/clock-rotate_deprecated.svg';
import commonMessages from 'common/messages/messages';
import JobPositions from '../JobPositions/JobPositions';

interface IJobPositionsBlockProps {
  jobPositions: IEmployeeJobPosition[];
  isLoading: boolean;
  EditJobPositions?: JSX.Element;
  onHistoryClick: () => void;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  block: {
    width: '100%',
    border: `1px solid ${theme.palette.borderColor?.main}`,
    borderRadius: '3px',

    // TODO: Add a general solution for the styles
    '& .empty-section-placeholder': {
      height: 'calc(100% - 30px)',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      paddingBottom: theme.spacing(2),

      '& .empty-text': {
        textAlign: 'center',
        fontSize: '0.9rem',
        color: theme.palette.text.secondary,
        lineHeight: '19px',
      },
    },
  },
  blockTitle: {
    textTransform: 'uppercase',
    padding: theme.spacing(2, 0, 2, 0),
  },
  historyButton: {
    '& svg': {
      width: '1rem',
      height: '1rem',
    },
  },
}));

const JobPositionsBlock = (props: IJobPositionsBlockProps): JSX.Element => {
  const { jobPositions, isLoading, EditJobPositions, onHistoryClick } = props;

  const classes = useStyles();

  return (
    <Box
      pr={2}
      pl={2}
      pb={0.5}
      display="flex"
      flexDirection="column"
      position="relative"
      className={classes.block}
    >
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" color="textSecondary" className={classes.blockTitle}>
          <FormattedMessage {...messages.jobPositions} />
        </Typography>
        <Tooltip title={<FormattedMessage {...commonMessages.historyBtn} />}>
          <Button
            className={classes.historyButton}
            onClick={onHistoryClick}
            hasHoverStyle
            color="primary"
          >
            <HistoryIcon />
          </Button>
        </Tooltip>
        {EditJobPositions}
      </Box>
      {jobPositions.length ? (
        <JobPositions jobPositions={jobPositions} />
      ) : (
        <Box className="empty-section-placeholder">
          <Typography className="empty-text">
            <FormattedMessage {...messages.emptyJobPositions} />
          </Typography>
        </Box>
      )}
      <LoadingBackdrop isLoading={isLoading} />
    </Box>
  );
};

export default JobPositionsBlock;
