import axios from 'axios';
import {
  IDaySheetKey,
  IDaySheetTotal,
  IDaySheetTotalByDateRange,
  IDaySheetTotalByDayOfWeek,
  IDaySheetTotalByEvent,
  IDaySheetTotalBySalesperson,
} from 'modules/day-sheet/interfaces/daySheet';
import ApiService from './ApiService';

export default class DaySheetService extends ApiService {
  public getDaySheetsTotalByDateRange(): Promise<IDaySheetTotalByDateRange[]> {
    return axios.get('/api/v1/day-sheets/total-by-date-range');
  }

  public getDaySheetsTotalByEvent(): Promise<IDaySheetTotalByEvent[]> {
    return axios.get('/api/v1/day-sheets/total-by-event');
  }

  public getDaySheetsTotalBySalesperson(): Promise<IDaySheetTotalBySalesperson[]> {
    return axios.get('/api/v1/day-sheets/total-by-salesperson');
  }

  public getDaySheetsTotalByDayOfWeek(): Promise<IDaySheetTotalByDayOfWeek[]> {
    return axios.get('/api/v1/day-sheets/total-by-day-of-week');
  }

  public getDaySheetsMarketSourceTotal(): Promise<IDaySheetTotal[]> {
    return axios.get('/api/v1/day-sheets/market-source-total');
  }

  public getDaySheetsMemberTypeTotal(): Promise<IDaySheetTotal[]> {
    return axios.get('/api/v1/day-sheets/member-type-total');
  }

  public getDaySheetsPaymentMethodTotal(): Promise<IDaySheetTotal[]> {
    return axios.get('/api/v1/day-sheets/payment-method-total');
  }

  public getDaySheetEventKeys(): Promise<IDaySheetKey[]> {
    return axios.get('/api/v1/day-sheets/event-key');
  }

  public getDaySheetStatusKeys(): Promise<IDaySheetKey[]> {
    return axios.get('/api/v1/day-sheets/status-key');
  }
}
