import React, { useMemo, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';

import { CustomTheme } from 'common/ui/interfaces';
import { FieldInfo } from 'common/components/index';
import { FormattedMessage } from 'react-intl';
import inputLabels from 'common/messages/inputLabels';
import commonMessages from 'common/messages/messages';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import {
  IFamilyMemberServiceInstanceDetailsDtoImt,
  IServiceRedeemHistoryImt,
} from 'common/interfaces/service';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';
import { List as ImmutableList } from 'immutable';
import { IHeadCell, ITableRow } from 'common/interfaces/table';
import RedeemingHistory from 'common/components/PersonProfile/components/Services/modals/RedeemingHistory/RedeemingHistory';
import tableHeaders from 'common/messages/tableHeaders';
import RedeemingServiceHistoryModal from '../RedeemingServiceHistoryModal/RedeemingServiceHistoryModal';
import { PeakModules } from 'common/constants/peakModules';

interface IFamilyMemberServiceDetailsViewProps {
  module: PeakModules;
  profileId: string;
  familyMemberServiceInstance: IFamilyMemberServiceInstanceDetailsDtoImt;
  redeemHistory: ImmutableList<IServiceRedeemHistoryImt>;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  detailsContainer: {
    marginBottom: theme.spacing(3),
  },
}));

const headerOptions: IHeadCell[] = [
  { id: 'items', label: <FormattedMessage {...commonMessages.items} />, sort: false },
  { id: 'member', label: <FormattedMessage {...tableHeaders.member} />, sort: false },
  { id: 'date', label: <FormattedMessage {...tableHeaders.date} />, sort: false },
];

const FamilyMemberServiceDetailsView = (
  props: IFamilyMemberServiceDetailsViewProps,
): JSX.Element => {
  const { familyMemberServiceInstance, redeemHistory, module, profileId } = props;

  const [timezoneMoment] = useTimezoneMoment();

  const [isOpenHistoryModal, setIsOpenHistoryModal] = useState(false);

  const classes = useStyles();

  const hasLimited = familyMemberServiceInstance.get('limited');
  const expirationDate = familyMemberServiceInstance.get('expirationDate');
  const expirationDateWithTimezone = expirationDate
    ? timezoneMoment(expirationDate).format(DEFAULT_DATE_TIME_FORMAT)
    : '-';

  const tableRows: ITableRow[] = useMemo(
    () =>
      redeemHistory
        ?.map(redeemItem => ({
          id: redeemItem.get('id'),
          cells: [
            {
              label: `${redeemItem.get('amount')} ${redeemItem.get('type').toLowerCase()}`,
            },
            {
              label: `${redeemItem.getIn(['customer', 'title'])}`,
            },
            {
              label: `${timezoneMoment(redeemItem.get('date')).format('MMM D, YYYY, h:mm A')}`,
            },
          ],
        }))
        .toJS() || [],
    [redeemHistory, timezoneMoment],
  );

  return (
    <>
      <Grid container spacing={2} className={classes.detailsContainer}>
        <Grid item xs={6}>
          <FieldInfo
            label={<FormattedMessage {...inputLabels.expirationDate} />}
            description={expirationDateWithTimezone}
          />
        </Grid>
        <Grid item xs={6}>
          <FieldInfo
            label={<FormattedMessage {...inputLabels.limitation} />}
            description={
              <FormattedMessage
                {...(hasLimited ? commonMessages.limitedLabel : commonMessages.unlimitedLabel)}
              />
            }
          />
        </Grid>
      </Grid>
      <RedeemingHistory
        headerOptions={headerOptions}
        onViewAllHistory={() => setIsOpenHistoryModal(true)}
        tableRows={tableRows}
      />
      {isOpenHistoryModal && (
        <RedeemingServiceHistoryModal
          isOpen={isOpenHistoryModal}
          onClose={() => setIsOpenHistoryModal(false)}
          module={module}
          profileId={profileId}
        />
      )}
    </>
  );
};

export default FamilyMemberServiceDetailsView;
