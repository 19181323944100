import { defineMessages } from 'react-intl';

export default defineMessages({
  noFoundCameraError: {
    id: 'common.camera.no.found.error',
    defaultMessage: 'No found camera error',
  },
  cameraAccessIsRequiredError: {
    id: 'common.camera.access.is.required.error',
    defaultMessage: 'Camera access is required ',
  },
});
