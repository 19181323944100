import { ConstOptions } from 'common/constants/classes';

import menuItems from 'common/messages/menuItems';
import messages from 'common/messages/messages';

export enum LeadStatus {
  Cold = 'COLD',
  Interested = 'INTERESTED',
  Active = 'ACTIVE_TRIALING',
  ActiveTrialExpired = 'ACTIVE_TRIAL_EXPIRED',
  Inactive = 'INACTIVE',
  Closed = 'CLOSED',
  Dead = 'DEAD',
  Empty = 'EMPTY',
}

export enum GenderTypes {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export enum AgeGroups {
  RANGE_18_24 = 'RANGE_18_24',
  RANGE_25_34 = 'RANGE_25_34',
  RANGE_35_44 = 'RANGE_35_44',
  RANGE_45_54 = 'RANGE_45_54',
  RANGE_55_64 = 'RANGE_55_64',
  FROM_65 = 'FROM_65',
}

export const genderTypeLabels = new ConstOptions([
  { key: GenderTypes.MALE, value: GenderTypes.MALE, message: menuItems.male },
  { key: GenderTypes.FEMALE, value: GenderTypes.FEMALE, message: menuItems.female },
  { key: GenderTypes.OTHER, value: GenderTypes.OTHER, message: menuItems.other },
]);

export const ageGroupsLabels = new ConstOptions([
  {
    key: AgeGroups.RANGE_18_24,
    value: AgeGroups.RANGE_18_24,
    message: menuItems.ageGroup_18_24,
  },
  {
    key: AgeGroups.RANGE_25_34,
    value: AgeGroups.RANGE_25_34,
    message: menuItems.ageGroup_25_34,
  },
  {
    key: AgeGroups.RANGE_35_44,
    value: AgeGroups.RANGE_35_44,
    message: menuItems.ageGroup_35_44,
  },
  {
    key: AgeGroups.RANGE_45_54,
    value: AgeGroups.RANGE_45_54,
    message: menuItems.ageGroup_45_54,
  },
  {
    key: AgeGroups.RANGE_55_64,
    value: AgeGroups.RANGE_55_64,
    message: menuItems.ageGroup_55_64,
  },
  {
    key: AgeGroups.FROM_65,
    value: AgeGroups.FROM_65,
    message: menuItems.from65,
  },
]);

export enum MaritalStatuses {
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED',
  WIDOWED = 'WIDOWED',
  DIVORCED = 'DIVORCED',
}

export const maritalStatusesLabels = new ConstOptions([
  { key: MaritalStatuses.SINGLE, value: MaritalStatuses.SINGLE, message: messages.singleLabel },
  { key: MaritalStatuses.MARRIED, value: MaritalStatuses.MARRIED, message: messages.marriedLabel },
  { key: MaritalStatuses.WIDOWED, value: MaritalStatuses.WIDOWED, message: messages.widowedLabel },
  {
    key: MaritalStatuses.DIVORCED,
    value: MaritalStatuses.DIVORCED,
    message: messages.divorcedLabel,
  },
]);
