import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
} from '@material-ui/core';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

import { Select } from 'common/components';
// messages
import inputLabels from 'common/messages/inputLabels';
import employeesMessages from 'common/messages/employeesMessages';
import { payTypeLabels } from 'modules/employees/constants';
import { EmployeePayTypes } from 'common/constants/employee';
import commonMessages from 'common/messages/messages';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

interface IPeakEmployeeJobSectionProps {
  isVisibleTitle?: boolean;
  disableEditableFields?: boolean;
}

function PeakEmployeeJobSection(props: IPeakEmployeeJobSectionProps): JSX.Element {
  const { isVisibleTitle = true, disableEditableFields } = props;

  const { control, errors } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'jobPositions',
    keyName: 'key',
  });

  const renderIntlMessage = useRenderIntlMessage();

  return (
    <Grid container spacing={1}>
      {isVisibleTitle && (
        <Grid item xs={12}>
          <Typography variant="button" color="textSecondary" gutterBottom>
            <FormattedMessage {...employeesMessages.job} />
          </Typography>
        </Grid>
      )}

      {fields.map(({ salary, jobTitle, payType, id, key }, index) => {
        const disableFields = disableEditableFields && Boolean(id);

        return (
          <Grid item xs={12} key={key}>
            <Box pt={isVisibleTitle ? 3 : 0} width="100%">
              <Grid item container xs={12} spacing={1} key={id} wrap="nowrap">
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        control={control}
                        defaultValue={jobTitle}
                        name={`jobPositions[${index}].jobTitle`}
                        variant="outlined"
                        autoComplete="off"
                        fullWidth
                        render={({ value, onChange, onBlur }) => (
                          <TextField
                            disabled={disableFields}
                            variant="outlined"
                            label={<FormattedMessage {...inputLabels.title} />}
                            onBlur={onBlur}
                            onChange={onChange}
                            fullWidth
                            error={!!errors.jobPositions?.[index]?.jobTitle}
                            helperText={renderIntlMessage(
                              errors.jobPositions?.[index]?.jobTitle?.message,
                            )}
                            value={value}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Controller
                        control={control}
                        name={`jobPositions[${index}].payType`}
                        variant="outlined"
                        defaultValue={payType}
                        render={({ onChange, value, onBlur, name }) => (
                          <Select
                            disabled={disableFields}
                            label={<FormattedMessage {...inputLabels.payType} />}
                            value={value}
                            name={name}
                            fullWidth
                            onBlur={onBlur}
                            onChange={onChange}
                            error={!!errors.jobPositions?.[index]?.payType}
                            helperText={renderIntlMessage(
                              errors.jobPositions?.[index]?.payType?.message,
                            )}
                          >
                            {payTypeLabels.values.map(type => (
                              <MenuItem key={type.key} value={type.value}>
                                {payTypeLabels.translate(type.value)}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Controller
                        control={control}
                        name={`jobPositions[${index}].salary`}
                        defaultValue={salary}
                        variant="outlined"
                        render={({ onChange, value, onBlur, name }) => (
                          <FormControl fullWidth variant="outlined">
                            <InputLabel>
                              <FormattedMessage {...inputLabels.salary} />
                            </InputLabel>
                            <OutlinedInput
                              disabled={disableFields}
                              name={name}
                              inputProps={{
                                type: 'number',
                              }}
                              fullWidth
                              onBlur={onBlur}
                              value={value}
                              onChange={onChange}
                              startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              error={!!errors.jobPositions?.[index]?.salary}
                            />
                            <FormHelperText error>
                              {renderIntlMessage(errors.jobPositions?.[index]?.salary?.message)}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid>
                  <Box pt={1}>
                    <IconButton onClick={() => remove(index)} style={{ padding: '6px' }}>
                      <DeleteIcon fontSize="small" color="secondary" />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Button
          color="primary"
          type="button"
          startIcon={<AddIcon />}
          disabled={!!errors.jobPositions}
          onClick={() => append({ salary: null, payType: EmployeePayTypes.SALARY })}
        >
          <FormattedMessage {...commonMessages.addBtn} />
        </Button>
      </Grid>
    </Grid>
  );
}

export default PeakEmployeeJobSection;
