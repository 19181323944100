// libraries
import React from 'react';
import clsx from 'clsx';
// components
import { Box, Avatar, Typography, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
// custom components and interfaces
import { CustomTheme } from 'common/ui/interfaces';
// icons
import { ReactComponent as Incognito } from 'img/spy.svg';
import profilePlaceholder from 'img/photo-placeholder.png';
// helpers
import { getInitials } from 'helpers/common';
// messages
import messages from './messages';

export const userAutocompleteIconAvatarStyles = (theme: CustomTheme): any => ({
  height: theme.spacing(2),
  width: theme.spacing(2),
  marginRight: theme.spacing(1),
});

export const userAutocompleteIconTextStyles = (): any => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const useStyles = makeStyles((theme: CustomTheme) => ({
  item: {
    position: 'absolute',
    display: 'inline-flex',
    bottom: 10,
    left: 14,
    fontSize: theme.typography.h5.fontSize,
    width: 'calc(100% - 42px)',
  },
  iconAvatar: userAutocompleteIconAvatarStyles(theme),
  avatarWrap: {
    position: 'relative',
    flex: 'none',
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.light,
  },
  avatarInitial: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    fontSize: 8,
    fontWeight: 700,
    color: theme.palette.darkBackground?.light,
  },
  avatar: {
    width: '100%',
    height: '100%',
    borderRadius: '3px',
  },
  itemText: userAutocompleteIconTextStyles(),
}));

interface ISelectedUserOptionProps {
  selectedValue: any;
  hideNOS: boolean;
}

const SelectedUserOption: React.FC<ISelectedUserOptionProps> = ({
  hideNOS,
  selectedValue,
}: ISelectedUserOptionProps): JSX.Element => {
  const classes = useStyles();

  const initials = getInitials(selectedValue?.firstName, selectedValue?.lastName);

  if (hideNOS && !selectedValue) {
    return <Box className={classes.item} />;
  }

  if (!selectedValue) {
    return (
      <Box className={classes.item}>
        <Incognito className={classes.iconAvatar} />
        <FormattedMessage {...messages.notInSystem} />
      </Box>
    );
  }

  return (
    <Box className={classes.item}>
      <Box className={clsx(classes.avatarWrap, classes.iconAvatar)}>
        {initials && !selectedValue.imageUrl ? (
          <Box className={classes.avatarInitial}>{initials}</Box>
        ) : (
          <Avatar
            src={selectedValue.imageUrl || profilePlaceholder}
            alt="avatar-icon"
            className={classes.avatar}
          />
        )}
      </Box>

      <Typography align="center" className={classes.itemText}>
        {`${selectedValue.firstName} ${selectedValue.lastName}`}
      </Typography>
    </Box>
  );
};

export default React.memo(SelectedUserOption);
