import { actionTypes } from './constants';
import { fromJS, List } from 'immutable';
import { v4 as uuid } from 'uuid';

const allergiesInitState = fromJS({
  ticketsList: List(),
  ticketsError: null,
  createTicketResult: null,
  ticketsLoading: false,
  ticketLoading: false,
  createTicketLoading: false,
});

const ticketsReducer = (state = allergiesInitState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PERSON_TICKETS_SUCCESS:
      return state.set('ticketsList', fromJS(action.payload)).set('ticketsError', null);
    case actionTypes.SET_PERSON_TICKETS_ERROR:
      return state.set('ticketsError', fromJS(action.payload));
    case actionTypes.FETCH_PERSON_TICKETS_LOADING:
      return state.set('ticketsLoading', action.payload);
    case actionTypes.RESET_PERSON_TICKETS:
      return state.set('ticketsList', List());
    case actionTypes.FETCH_PERSON_TICKET_SUCCESS:
      const comments = action.payload?.comments || [];
      const updatedData = action.payload && {
        ...action.payload,
        comments: comments.map(item => ({ ...item, id: uuid() })),
      };
      return state.set('ticket', fromJS(updatedData)).set('ticketsError', null);
    case actionTypes.FETCH_PERSON_TICKET_LOADING:
      return state.set('ticketLoading', action.payload);
    case actionTypes.RESET_PERSON_TICKET:
      return state.set('ticket', fromJS({}));
    case actionTypes.ADD_PERSON_TICKET_SUCCESS:
      return state.set('createTicketResult', action.payload).set('ticketsError', null);
    case actionTypes.ADD_PERSON_TICKET_LOADING:
      return state.set('createTicketLoading', action.payload);
    case actionTypes.RESET_ADD_PERSON_TICKET_ACTION_RESULT:
      return state.set('createTicketResult', null);

    default:
      return state;
  }
};

export default ticketsReducer;
