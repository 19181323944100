// libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormContext, useWatch, Controller } from 'react-hook-form';
import { createStyles, makeStyles } from '@material-ui/core/styles';
// components
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { TextArea, TextFieldWithReset, ZipCodeMaskedInput } from 'common/components/index';
// messages
import messages from 'common/components/PersonProfile/messages';
import inputLabels from 'common/messages/inputLabels';
// hooks
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
// constants
import { CancelReasons } from 'common/components/PersonProfile/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    reasonWrapper: {
      marginTop: theme.spacing(2),
    },
    groupLabel: {
      marginBottom: theme.spacing(1),
      fontSize: '1rem',
    },
    radioOption: {
      marginTop: theme.spacing(2),
    },
  }),
);

const NotInTermReasonStep = () => {
  const classes = useStyles();

  const { control, errors } = useFormContext();

  const watchReason = useWatch({ control, name: 'reason' });

  const renderIntlMessage = useRenderIntlMessage();

  // form

  return (
    <FormControl
      fullWidth
      component="fieldset"
      error={!!errors.reason}
      className={classes.reasonWrapper}
    >
      <Box mb={1}>
        <Typography variant="h4" className={classes.groupLabel}>
          <FormattedMessage {...messages.cancelMembershipModalCancellationReasonQuestion} />
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <Controller
            name="reason"
            control={control}
            render={({ value, onChange, onBlur }) => (
              <RadioGroup
                aria-label="cancellationReason"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
              >
                <FormControlLabel
                  label={<FormattedMessage {...messages.cancelMembershipReasonRelocationLabel} />}
                  value={CancelReasons.Relocation}
                  control={<Radio color="primary" />}
                />

                <FormControlLabel
                  label={<FormattedMessage {...messages.cancelMembershipReasonDisabilityLabel} />}
                  className={classes.radioOption}
                  value={CancelReasons.Disability}
                  control={<Radio color="primary" />}
                />

                <FormControlLabel
                  label={<FormattedMessage {...messages.cancelMembershipReasonOtherLabel} />}
                  className={classes.radioOption}
                  value={CancelReasons.Other}
                  control={<Radio color="primary" />}
                />
              </RadioGroup>
            )}
          />

          {errors.reason?.message && (
            <FormHelperText>{renderIntlMessage(errors.reason?.message)}</FormHelperText>
          )}
        </Grid>

        <Grid item sm={6} xs={12}>
          {watchReason === CancelReasons.Relocation && (
            <Controller
              name="newZipCode"
              control={control}
              render={({ value, onChange, onBlur }) => (
                <TextFieldWithReset
                  variant="outlined"
                  label={<FormattedMessage {...inputLabels.newZipCode} />}
                  fullWidth
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.newZipCode}
                  helperText={renderIntlMessage(errors.newZipCode?.message)}
                  InputProps={{
                    inputComponent: ZipCodeMaskedInput,
                  }}
                />
              )}
            />
          )}

          {watchReason === CancelReasons.Disability && (
            <Controller
              name="mdLicense"
              control={control}
              render={({ value, onChange, onBlur }) => (
                <TextField
                  fullWidth
                  label={<FormattedMessage {...inputLabels.mdLicence} />}
                  variant="outlined"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.mdLicense}
                  helperText={renderIntlMessage(errors.mdLicense?.message)}
                />
              )}
            />
          )}

          {watchReason === CancelReasons.Other && (
            <Controller
              name="note.text"
              control={control}
              render={({ value, onChange, onBlur }) => (
                <TextArea
                  fullWidth
                  minRows={8}
                  variant="outlined"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  maxSymbols={1000}
                  label={<FormattedMessage {...inputLabels.note} />}
                  error={!!errors.note?.text}
                  helperText={renderIntlMessage(errors.note?.text?.message)}
                />
              )}
            />
          )}
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default React.memo(NotInTermReasonStep);
