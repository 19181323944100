import { createSelector } from 'reselect';
import { QueryPageList } from 'common/constants';

export const queryPageListDomain = 'queryPageList';

export const selectQueryPageList = (queryPage: QueryPageList): any =>
  createSelector(
    (state: any) => state.getIn([queryPageListDomain, queryPage]),
    state => state.get('query'),
  );
