import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { actionTypes } from '../../constants/revenueCodes';

const rCodesListInitialState = fromJS({
  list: [],
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
  isLoading: false,

  changeStatusRegisterLoading: false,
  changeStatusRegisterResult: {},
});

function revenueCodesListReducer(state = rCodesListInitialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_REVENUE_CODES_LIST:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta));
    case actionTypes.FETCH_REVENUE_CODES_LIST_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.REVENUE_CODE_CHANGE_STATUS_LOADING:
      return state.set('changeStatusRegisterLoading', action.payload);
    case actionTypes.REVENUE_CODE_CHANGE_STATUS_SUCCESS:
      return state.set('changeStatusRegisterResult', action.payload);
    case actionTypes.RESET_REVENUE_CODE_CHANGE_STATUS:
      return state.set('changeStatusRegisterResult', {});
    default:
      return state;
  }
}

const rCodeDetailsInitialState = fromJS({
  data: null,
  loading: false,
  error: null,
});

function revenueCodeDetailsReducer(state = rCodeDetailsInitialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_REVENUE_CODE_DETAILS:
      return state.set('data', fromJS(action.payload));
    case actionTypes.FETCH_REVENUE_CODE_DETAILS_LOADING:
      return state.set('loading', action.payload);
    case actionTypes.RESET_REVENUE_CODE_DETAILS:
      return state.set('data', null);
    default:
      return state;
  }
}

const rCodeActionInitialState = fromJS({
  result: null,
  error: null,
  isLoading: false,
});

function createRevenueCodeReducer(state = rCodeActionInitialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_REVENUE_CODE_SUCCESS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.CREATE_REVENUE_CODE_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_CREATE_REVENUE_CODE:
      return rCodeActionInitialState;
    default:
      return state;
  }
}

function editRevenueCodeReducer(state = rCodeActionInitialState, action) {
  switch (action.type) {
    case actionTypes.EDIT_REVENUE_CODE_SUCCESS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.EDIT_REVENUE_CODE_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_EDIT_REVENUE_CODE:
      return rCodeActionInitialState;
    default:
      return state;
  }
}

function removeRevenueCodeReducer(state = rCodeActionInitialState, action) {
  switch (action.type) {
    case actionTypes.DELETE_REVENUE_CODE_SUCCESS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.DELETE_REVENUE_CODE_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_DELETE_REVENUE_CODE:
      return rCodeActionInitialState;
    default:
      return state;
  }
}

const rCodeClubConfigInitialState = fromJS({
  revenueCode: null,
  revenueCodeLoading: false,

  list: [],
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
  isLoading: false,
});
function revenueCodeClubConfigurationsListReducer(state = rCodeClubConfigInitialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_CONFIGURING_REVENUE_CODE:
      return state.set('revenueCode', fromJS(action.payload));
    case actionTypes.FETCH_CONFIGURING_REVENUE_CODE_LOADING:
      return state.set('revenueCodeLoading', action.payload);
    case actionTypes.FETCH_REVENUE_CODE_CLUB_CONFIGURATIONS:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta));
    case actionTypes.RESET_REVENUE_CODE_CLUB_CONFIGURATIONS:
      return rCodeClubConfigInitialState;
    case actionTypes.FETCH_REVENUE_CODE_CLUB_CONFIGURATIONS_LOADING:
      return state.set('isLoading', action.payload);
    default:
      return state;
  }
}

function addRevenueCodeClubConfigurationReducer(state = rCodeActionInitialState, action) {
  switch (action.type) {
    case actionTypes.ADD_CLUB_CONFIGURATIONS_RESULT:
      return state.set('result', fromJS(action.payload));
    case actionTypes.ADD_CLUB_CONFIGURATIONS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_ADD_CLUB_CONFIGURATIONS:
      return rCodeActionInitialState;
    default:
      return state;
  }
}

function editRevenueCodeClubConfigurationReducer(state = rCodeActionInitialState, action) {
  switch (action.type) {
    case actionTypes.EDIT_CLUB_CONFIGURATIONS_RESULT:
      return state.set('result', fromJS(action.payload));
    case actionTypes.EDIT_CLUB_CONFIGURATIONS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_EDIT_CLUB_CONFIGURATIONS:
      return rCodeActionInitialState;
    default:
      return state;
  }
}

function removeRevenueCodeClubConfigurationReducer(state = rCodeActionInitialState, action) {
  switch (action.type) {
    case actionTypes.REMOVE_CLUB_CONFIGURATIONS_RESULT:
      return state.set('result', fromJS(action.payload));
    case actionTypes.REMOVE_CLUB_CONFIGURATIONS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_REMOVE_CLUB_CONFIGURATIONS:
      return rCodeActionInitialState;
    default:
      return state;
  }
}

const revenueCodeActionsReducer = combineReducers({
  createAction: createRevenueCodeReducer,
  editAction: editRevenueCodeReducer,
  removeAction: removeRevenueCodeReducer,
  addClubsConfigurations: addRevenueCodeClubConfigurationReducer,
  editClubsConfigurations: editRevenueCodeClubConfigurationReducer,
  removeClubsConfigurations: removeRevenueCodeClubConfigurationReducer,
});

export default combineReducers({
  revenueCodesList: revenueCodesListReducer,
  revenueCodeDetails: revenueCodeDetailsReducer,
  revenueCodeClubConfigs: revenueCodeClubConfigurationsListReducer,
  revenueCodeActions: revenueCodeActionsReducer,
});
