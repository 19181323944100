import axios from 'axios';

import ApiService from 'services/network/ApiService';
import { DictionaryItem } from 'common/constants/dictionaryConstants';
import {
  CrmCampaignDto,
  IGetCampaignDictionaryEntityRequestPayload,
} from 'common/interfaces/dictionary';
import { PeakModules } from 'common/constants/peakModules';

type ResultItems = CrmCampaignDto;

export default class DictionaryItemService extends ApiService {
  public fetchDictionaryItemByName = (
    dictionary: DictionaryItem,
    param: IGetCampaignDictionaryEntityRequestPayload,
  ): Promise<ResultItems> => {
    switch (dictionary) {
      case DictionaryItem.CAMPAIGN_EMAIL:
        return this.getCampaignEmailDictionaryById(param);
      case DictionaryItem.CAMPAIGN_SMS:
        return this.getCampaignSmsDictionaryById(param);
      case DictionaryItem.CAMPAIGN_PHONE:
        return this.getCampaignPhoneDictionaryById(param);
      default:
        throw new Error(`Unsupported dictionary item: ${dictionary}`);
    }
  };

  public getCampaignEmailDictionaryById = ({
    personId,
    campaignId,
    module,
  }: IGetCampaignDictionaryEntityRequestPayload): Promise<CrmCampaignDto> => {
    if (module === PeakModules.Crm) {
      return axios.get(
        `/api/v1/leads/${personId}/messaging/campaign-dictionary/email/${campaignId}`,
      );
    }

    return axios.get(
      `/api/v1/pt-leads/${personId}/messaging/campaign-dictionary/email/${campaignId}`,
    );
  };

  public getCampaignSmsDictionaryById = ({
    personId,
    campaignId,
    module,
  }: IGetCampaignDictionaryEntityRequestPayload): Promise<CrmCampaignDto> => {
    if (module === PeakModules.Crm) {
      return axios.get(`/api/v1/leads/${personId}/messaging/campaign-dictionary/sms/${campaignId}`);
    }

    return axios.get(
      `/api/v1/pt-leads/${personId}/messaging/campaign-dictionary/sms/${campaignId}`,
    );
  };

  public getCampaignPhoneDictionaryById = ({
    personId,
    campaignId,
    module,
  }: IGetCampaignDictionaryEntityRequestPayload): Promise<CrmCampaignDto> => {
    if (module === PeakModules.Crm) {
      return axios.get(
        `/api/v1/leads/${personId}/messaging/campaign-dictionary/phone/${campaignId}`,
      );
    }

    return axios.get(
      `/api/v1/pt-leads/${personId}/messaging/campaign-dictionary/phone/${campaignId}`,
    );
  };
}
