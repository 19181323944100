import React from 'react';
import { Menu, MenuList } from '@material-ui/core';
import { PopoverProps } from '@material-ui/core/Popover';
import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    minWidth: 95,
  },
  list: {
    padding: theme.spacing(0),
  },
  menuList: {
    padding: theme.spacing(0),
  },
}));

interface IProps {
  id: string;
  anchorEl?: PopoverProps['anchorEl'];
  onClose: () => void;
  dropdownListClassName: string;
  // suppressOnKeyDownEvent prevents onBlur from triggering when working with child input
  suppressOnKeyDownEvent?: boolean;
}

const FilterMenu = (props: React.PropsWithChildren<IProps>) => {
  const classes = useStyles();
  const { id, anchorEl, onClose, children, suppressOnKeyDownEvent, dropdownListClassName } = props;

  const showMenu = Boolean(anchorEl);

  const menuListProps = suppressOnKeyDownEvent
    ? {
        onKeyDown: e => e.stopPropagation(),
      }
    : {};

  return (
    <>
      {showMenu && (
        <Menu
          id={`${id}-filter-menu`}
          keepMounted
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          open={showMenu}
          onClose={onClose}
          classes={{ paper: classes.paper, list: cx(dropdownListClassName, classes.list) }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuList {...menuListProps} className={classes.menuList}>
            {children}
          </MenuList>
        </Menu>
      )}
    </>
  );
};

export default React.memo(FilterMenu);
