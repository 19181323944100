import moment, { Moment } from 'moment-timezone';

import { IDayTimeAvailabilityDto } from 'common/interfaces/common';

// validation

const getMomentTimeFromDate = (time: string): Moment => moment(time, 'HH:mm');

const validateRange = (
  ranges: IDayTimeAvailabilityDto,
  validityRanges: IDayTimeAvailabilityDto,
): boolean => {
  return validityRanges.timeRanges.some(({ startTime: validStart, endTime: validEnd }) => {
    const validStartInstance = getMomentTimeFromDate(validStart as string);
    const validEndInstance = getMomentTimeFromDate(validEnd as string);

    return ranges.timeRanges.every(({ startTime, endTime }) => {
      const start = getMomentTimeFromDate(startTime as string);
      const end = getMomentTimeFromDate(endTime as string);

      return (
        start.isBetween(validStartInstance, validEndInstance, null, '[]') &&
        end.isBetween(validStartInstance, validEndInstance, null, '[]')
      );
    });
  });
};

const validateAvailability = (
  availability: IDayTimeAvailabilityDto,
  validityRanges: IDayTimeAvailabilityDto[],
): boolean => {
  const dayValidityRanges = validityRanges.find(
    rangeItem => rangeItem.weekday === availability.weekday,
  );

  if (!dayValidityRanges) {
    return false;
  }

  if (dayValidityRanges.allDay) {
    return true;
  }

  if (availability.allDay && !dayValidityRanges.allDay) {
    return false;
  }

  return dayValidityRanges.timeRanges.every(() => validateRange(availability, dayValidityRanges));
};

export const validateAvailabilityRanges = (
  availabilities: IDayTimeAvailabilityDto[],
  validAvailabilityRange?: IDayTimeAvailabilityDto[],
): boolean => {
  if (!validAvailabilityRange || !validAvailabilityRange.length) {
    return true;
  }

  return availabilities.every(availability =>
    validateAvailability(availability, validAvailabilityRange),
  );
};
