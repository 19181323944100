import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Menu, Typography, makeStyles } from '@material-ui/core';

import { CustomTheme } from 'common/ui/interfaces';

import tableHeaders from 'common/messages/tableHeaders';

const useStyles = makeStyles((theme: CustomTheme) => ({
  menu: {
    '& .MuiMenuItem-root .MuiListItemIcon-root:last-child': {
      marginLeft: 0,
    },
  },
  iconButton: {
    padding: theme.spacing(0),
    cursor: 'pointer',
    opacity: 0.7,
    fontSize: theme.typography.h5.fontSize,
  },
  iconButtonGutterTop: {
    marginTop: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(2),
  },
}));

interface IProps<T> {
  options: Array<T>;
  renderOption: (option: T) => JSX.Element | string;
}

const ViewMoreItemsMenu = <T,>({ options, renderOption }: IProps<T>): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const classes = useStyles();

  const open = Boolean(anchorEl);

  const handleOpenClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (open) handleCloseMenu();
  };

  const createOption = (item: T, isSingle: boolean): JSX.Element => {
    return (
      <Typography className={!isSingle ? classes.margin : ''} component="div">
        {renderOption(item)}
      </Typography>
    );
  };

  const oneItem = createOption(options[0], true);

  if (options.length === 1) return oneItem;

  return (
    <>
      {oneItem}
      <Box className={classes.iconButton} onClick={handleOpenClick}>
        {`${options?.length - 1} `}
        <FormattedMessage {...tableHeaders.more} />
      </Box>
      <Menu
        id="billing-options-menu"
        className={classes.menu}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        onClick={handleClickMenu}
      >
        {options.map((option: T) => createOption(option, false))}
      </Menu>
    </>
  );
};

export default ViewMoreItemsMenu;
