import { defineMessages } from 'react-intl';

export default defineMessages({
  serviceLimitationLabel: {
    id: 'modules.services.packages.includedService.limitation.label',
    defaultMessage: 'Limitation',
  },
  // Included service labels by type
  limitedSessionsServiceLabel: {
    id: 'modules.services.packages.includedService.limitedSessions.label',
    defaultMessage: 'Sessions',
  },
  limitedDurationServiceLabel: {
    id: 'modules.services.packages.includedService.limitedDuration.label',
    defaultMessage: 'Duration',
  },
  limitedFamilyMembersServiceLabel: {
    id: 'modules.services.packages.includedService.limitedFamilyMembers.label',
    defaultMessage: 'Members',
  },
  limitedServiceGeneralLabel: {
    id: 'modules.services.packages.includedService.limitedGeneral.label',
    defaultMessage: 'Amount',
  },
  limitEmployees: {
    id: 'modules.services.packages.limitEmployees.title',
    defaultMessage: 'Limit who can sell the bundle',
  },
  limitSellerClubs: {
    id: 'modules.services.packages.limitSellerClubs.title',
    defaultMessage: 'Limit where can sell the bundle',
  },
  limitClubs: {
    id: 'modules.services.packages.limitClubs.title',
    defaultMessage: 'Limit availability in clubs',
  },
  limitEmployeesAllAllowed: {
    id: 'modules.services.packages.limitEmployees.all',
    defaultMessage: 'All employees are allowed to sell',
  },
  limitClubsAllAllowed: {
    id: 'modules.services.packages.limitClubs.all',
    defaultMessage: 'Bundle is available in all clubs',
  },
  newPaymentOptionModalTitle: {
    id: 'modules.services.packages.modal.title.newPaymentOption',
    defaultMessage: 'New Payment Option',
  },
  editPaymentOptionModalTitle: {
    id: 'modules.services.packages.modal.title.editPaymentOption',
    defaultMessage: 'Edit Payment Option',
  },
  editFeesModalTitle: {
    id: 'modules.services.packages.modal.title.editFees',
    defaultMessage: 'Edit Fees',
  },
  editGeneralPackageModalTitle: {
    id: 'modules.services.packages.modal.title.editGeneralPackage',
    defaultMessage: 'Edit General Package',
  },
  editOutOfTermBillingModalTitle: {
    id: 'modules.services.packages.modal.title.editOutOfTermBilling',
    defaultMessage: 'Edit Out of term Billing',
  },
});
