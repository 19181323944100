import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';

import { FilterViewTypes, IFilter, IFilterSettings, IFilterValue } from 'common/interfaces/filter';

import Filter from '../Filter/Filter';
import { makeFilterChangeHandler } from 'common/utils/filter';

interface FilterListProps {
  filters: string[];
  settings: IFilterSettings[];
  values: IFilter[];
  onFiltersChange: (values: IFilter[], changedFilterName?: string) => void;
  filterView?: FilterViewTypes;
}

const FilterList = ({
  filters,
  settings,
  values,
  filterView = FilterViewTypes.DROPDOWN,
  onFiltersChange,
}: FilterListProps): JSX.Element => {
  // getters

  const getFilterValue = (filterName: string): IFilterValue => {
    const filterValue = values.find(value => value.name === filterName);
    return filterValue && filterValue.value;
  };

  // handlers

  const handleFilterChange = useMemo(() => makeFilterChangeHandler(values, onFiltersChange), [
    values,
    onFiltersChange,
  ]);

  // render

  const shownSettings = settings.filter(setting => filters.includes(setting.name));

  return (
    <>
      {shownSettings.map(filterSettings => (
        <Grid item key={filterSettings.name}>
          <Filter
            settings={filterSettings}
            value={getFilterValue(filterSettings.name)}
            viewType={filterView}
            handleFilterChange={handleFilterChange}
          />
        </Grid>
      ))}
    </>
  );
};

export default React.memo(FilterList);
