import React from 'react';
import MaskedInput from 'react-text-mask';

import { masks } from 'common/constants';

interface TextMaskCustomProps {
  inputRef: (reference: HTMLInputElement | null) => void;
  value: string;
}

const PinCodeMaskedInput = ({ inputRef, ...other }: TextMaskCustomProps): JSX.Element => (
  <MaskedInput
    {...other}
    ref={(reference: any) => {
      inputRef(reference ? reference.inputElement : null);
    }}
    mask={masks.PIN_CODE}
    placeholderChar={'\u2000'}
    guide={false}
    showMask
  />
);

export default PinCodeMaskedInput;
