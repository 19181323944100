import React from 'react';

import { tasksTypesConstOptions } from '../../constants/tasks';
import { Status } from 'common/components';

const getColor = (name: string) => {
  switch (name.toLocaleUpperCase()) {
    case 'CALL':
      return '#056f7b';
    case 'EMAIL':
      return '#134c07';
    case 'SMS':
      return '#b16a33';
    case 'SMS RECEIVED':
      return '#2d2b6c';
    case 'LEAD PROFILE CREATION':
      return '#127ac9';
    default:
      return '#263b8b';
  }
};

interface IProps {
  name: string;
}

const ActivityTypeChip = (props: IProps): JSX.Element => {
  const { name } = props;
  const color = getColor(name);

  return <Status color={color} label={tasksTypesConstOptions.translate(name)} />;
};

export default ActivityTypeChip;
