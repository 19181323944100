// libraries
import axios from 'axios';
// interfaces
import { ITableParams } from 'common/interfaces/table';
import {
  IPaginatedServicesList,
  IServiceFormData,
  IServiceItem,
} from 'modules/services/interfaces/services';
import {
  IEditPackageDetails,
  IPackageServicesPackagesList,
  IBundleView,
  IPaginatedPackagesList,
} from 'modules/services/interfaces/packages';
import { ISuccessResponse } from 'common/interfaces/http';
// utils
import ApiService from './ApiService';
import { IPaginatedDocumentTemplate } from 'modules/services/interfaces/documentTemplates';
import { TemplatePurpose, TemplateTypes } from 'common/constants/documentTemplate';
import {
  ICreateDocumentTemplateDto,
  IDocumentTemplateDto,
  IUpdateDocumentTemplateStatusRequest,
} from 'common/interfaces/documentTemplate';
import { transformPackageDetailFromDTO } from 'modules/services/utils/serializers';
import { INamedEntity } from 'common/interfaces/common';

export default class ServicesService extends ApiService {
  public getServicesList = (requestOptions?: ITableParams): Promise<IPaginatedServicesList> => {
    const tableParams = this.getSearchFromTableParams(requestOptions);
    return axios.get('/api/v1/services', {
      params: tableParams,
    });
  };

  public createService = (data: IServiceFormData): Promise<IServiceItem> => {
    const body = {
      ...data,
      active: true,
    };
    return axios.post('/api/v1/services', body);
  };

  public updateService = (serviceId: string, data: IServiceFormData): Promise<IServiceItem> => {
    const body = {
      ...data,
    };
    return axios.put(`/api/v1/services/${serviceId}`, body);
  };

  public getServiceById = (serviceId: string): Promise<IServiceItem> => {
    return axios.get(`/api/v1/services/${serviceId}`);
  };

  public getPackageById = (packageId: string): Promise<IEditPackageDetails> => {
    return axios
      .get(`/api/v1/packages/${packageId}`)
      .then(packageDTO => transformPackageDetailFromDTO(packageDTO));
  };

  public getSubBundleConfiguration = (packageId: string): Promise<IBundleView> =>
    axios.get(`/api/v1/packages/${packageId}/sub-bundle-configuration`);

  public getPackageServicesPackagesList = (
    title: string,
    packageId: string,
    relatedPackageIds: string[],
  ): Promise<IPackageServicesPackagesList> => {
    const config = {
      params: { title, packageId, relatedPackageIds: relatedPackageIds.join(',') },
    };
    return axios.get(`/api/v1/packages/services`, config);
  };

  public getInventoryList = (title: string): Promise<INamedEntity[]> => {
    return axios.get(`/api/v1/packages/inventories`, title ? { params: { title } } : undefined);
  };

  public changeServiceStatus = (id: string, isActive: boolean): Promise<IServiceItem> =>
    axios.patch(`/api/v1/services/${id}/change-status`, { active: isActive });

  public getPackagesList = (requestOptions: ITableParams): Promise<IPaginatedPackagesList> => {
    const tableParams = this.getSearchFromTableParams(requestOptions);
    return axios.get('/api/v1/packages', { params: tableParams });
  };

  public changePackageStatus = (packageId: string, isActive: boolean): Promise<ISuccessResponse> =>
    axios.patch(`/api/v1/packages/${packageId}/change-status`, { active: isActive });

  public clonePackage = (packageId: string): Promise<ISuccessResponse> => {
    return axios.post(`/api/v1/packages/${packageId}/duplicate`);
  };

  public getDocumentTemplates = (
    type: TemplateTypes,
    purpose: TemplatePurpose,
    requestOptions?: ITableParams,
  ): Promise<IPaginatedDocumentTemplate> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    const params = {
      documentTypeList: type,
      purposeTypeList: purpose,
      ...searchParams,
    };

    return axios.get('/api/v1/services/document-template', { params });
  };

  public getDocumentTemplate = (id: string): Promise<IDocumentTemplateDto> => {
    return axios.get(`/api/v1/services/document-template/${id}`);
  };

  public saveDocumentTemplate = (
    requestData: ICreateDocumentTemplateDto,
  ): Promise<IDocumentTemplateDto> => {
    return axios.post('/api/v1/services/document-template', requestData);
  };

  public updateDocumentTemplate = (
    requestData: IDocumentTemplateDto,
  ): Promise<IDocumentTemplateDto> => {
    return axios.put(`/api/v1/services/document-template/${requestData.id}`, requestData);
  };

  public updateDocumentStatus = ({
    id,
    active,
  }: IUpdateDocumentTemplateStatusRequest): Promise<IDocumentTemplateDto> => {
    return axios.patch(`/api/v1/services/document-template/${id}/active`, {
      active,
    });
  };
}
