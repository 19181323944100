import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import SlickSlider from 'react-slick';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .slick-track': {
        display: 'flex',

        '& .slick-slide': {
          '& div > div': {
            outline: 'none',
          },
        },
      },

      '& .slick-dots': {
        display: 'flex !important',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: theme.spacing(2),
        borderTop: `1px solid ${theme.palette.secondary.light}`,

        '& li': {
          margin: theme.spacing(0, 0.5),

          '&::marker': {
            content: 'none',
          },

          '& button': {
            width: '10px',
            height: '10px',
            borderRadius: '5px',
            border: 'none',
            textIndent: '-9999999px',
            outline: 'none',
            cursor: 'pointer',
            backgroundColor: theme.palette.secondary.light,
          },
        },
        '& .slick-active': {
          '& button': {
            backgroundColor: theme.palette.primary.main,
          },
        },
      },
    },
  }),
);

interface ISliderProps {
  children: any;
  rows?: number;
}

const Slider = ({ children, rows = 1 }: ISliderProps) => {
  const classes = useStyles();

  return (
    <SlickSlider
      className={classes.root}
      adaptiveHeight
      dots
      arrows={false}
      vertical={false}
      rows={rows}
    >
      {children}
    </SlickSlider>
  );
};

export default Slider;
