import * as yup from 'yup';

import {
  ConditionType,
  AudienceFilterType,
  CampaignCounterType,
  CampaignType,
  CameFromType,
} from 'common/constants';

import {
  getDisablePastTimeSchema,
  getFutureOrTodayDateSchema,
} from 'common/validationSchemas/dateSchemas';

import { getMinErrorMessage, getRequiredMessage } from 'common/constants/globalConstants';

import inputErrors from 'common/messages/inputErrors';

const conditionsTypesWithCounterType = [
  ConditionType.MEMBERS_CARDS_EXPIRING_IN_N_DAYS,
  ConditionType.MEMBERS_EXPIRING_IN_N_DAYS,
  ConditionType.UPCOMING_APPOINTMENTS_IN_N_DAYS_N_HOURS,
];

const conditionsTypesWithCounter = [
  ConditionType.LOW_USAGE_MEMBERS_CONTACTED_AFTER_XX,
  ConditionType.USED_THEIR_PASS_LESS_THAN_X_NUMBER_OF_TIMES_WITHIN_THEIR_PASS_LIMITATIONS,
  ConditionType.REGULAR_EACH_DAY_OR_NUMBER_OF_DAYS,
];

const getAudienceFilterItemValidation = type => {
  return yup.mixed().when('type', {
    is: value => value === type,
    then: yup
      .array()
      .required(getRequiredMessage)
      .min(1, getMinErrorMessage),
  });
};

export const ConditionSchema = yup.object().shape({
  type: yup.string().oneOf(Object.values(ConditionType)),

  counterType: yup.mixed().when('type', {
    is: value => conditionsTypesWithCounterType.includes(value),
    then: yup
      .string()
      .oneOf(Object.values(CampaignCounterType))
      .required(getRequiredMessage),
  }),

  counter: yup
    .mixed()
    .transform(value => (value === '' ? null : value))
    .when('type', {
      is: value => {
        return (
          conditionsTypesWithCounterType.includes(value) ||
          conditionsTypesWithCounter.includes(value)
        );
      },
      then: yup
        .number()
        .nullable()
        .min(1)
        .required(getRequiredMessage),
    }),

  specificDate: yup.mixed().when('type', {
    is: value => value === ConditionType.SPECIFIC_DATE_AND_TIME,
    then: getFutureOrTodayDateSchema('specificTime'),
  }),
  specificTime: yup.mixed().when('type', {
    is: value => value === ConditionType.SPECIFIC_DATE_AND_TIME,
    then: getDisablePastTimeSchema('specificDate'),
  }),
});

export const AudienceFilterSchema = yup.object().shape({
  type: yup.string().oneOf(Object.values(AudienceFilterType)),

  ageGroups: getAudienceFilterItemValidation(AudienceFilterType.AGE_GROUP),

  clubList: getAudienceFilterItemValidation(AudienceFilterType.LOCATIONS),

  genders: getAudienceFilterItemValidation(AudienceFilterType.GENDER),

  leadStatuses: getAudienceFilterItemValidation(AudienceFilterType.LEAD_STATUS),

  serviceList: getAudienceFilterItemValidation(AudienceFilterType.BASED_ON_SERVICES),

  cameFromList: yup.mixed().when('type', {
    is: value => value === AudienceFilterType.CAME_FROM,
    then: yup
      .object()
      .shape({
        cameFromIndicatorId: yup.string().required(getRequiredMessage),
        cameFromValue: yup.mixed().test(
          'isValidCameFromValue',
          () => getRequiredMessage(),
          (value, context) => {
            const ctx: any = context;
            const cameFromType = ctx.from[1].value?.cameFromList.cameFromType;
            const optional = ctx.from[1].value?.cameFromList.optional;

            if (
              cameFromType === CameFromType.ReferralList ||
              cameFromType === CameFromType.NoInput ||
              optional ||
              value
            ) {
              return true;
            }

            return false;
          },
        ),
        referralMember: yup.array(yup.object().required(getRequiredMessage)),
      })
      .required(getRequiredMessage),
  }),
});

export const ValidationSchema = yup.object().shape({
  title: yup
    .string()
    .trim()
    .max(100, () => inputErrors.nameLengthError)
    .required(getRequiredMessage),

  script: yup
    .object({
      id: yup.string().nullable(),
    })
    .when('campaignType', {
      is: type => type === CampaignType.PHONE,
      then: yup.object({
        id: yup
          .string()
          .nullable()
          .required(getRequiredMessage),
      }),
    }),
  campaignType: yup
    .string()
    .oneOf(Object.values(CampaignType))
    .required(getRequiredMessage),

  documentTemplate: yup
    .mixed()
    // .nullable()
    .when('campaignType', {
      is: value => value === CampaignType.SMS || value === CampaignType.EMAIL,
      then: yup
        .mixed()
        .notOneOf(['', null], getRequiredMessage)
        .required(getRequiredMessage),
    }),

  automatic: yup.boolean(),

  runningConditionList: yup.array().of(ConditionSchema),

  audienceFilterList: yup.array().of(AudienceFilterSchema),
});
