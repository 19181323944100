import React from 'react';

import { ServicesRoutes } from 'modules/services/routes';

import { MainModulePage } from 'common/components';

import ReceiptIcon from '@material-ui/icons/Receipt';
import { ReactComponent as Document } from 'img/icons/document.svg';
import { ReactComponent as Box } from 'img/icons/box.svg';
import { ReactComponent as Boxes } from 'img/icons/boxes.svg';

import messages from 'modules/services/messages/messages';
import commonMessages from 'common/messages/messages';

const ServicesModules = [
  {
    id: 'servicesList',
    message: messages.services,
    link: ServicesRoutes.SERVICES_LIST,
    iconComponent: Box,
    viewBox: '0 0 24 24',
  },
  {
    id: 'feesList',
    message: commonMessages.fees,
    link: ServicesRoutes.FEES_LIST,
    iconComponent: ReceiptIcon,
    viewBox: '0 0 24 24',
  },
  {
    id: 'servicePackages',
    message: messages.servicePackages,
    link: ServicesRoutes.SERVICES_PACKAGES,
    iconComponent: Boxes,
    viewBox: '0 0 28 24',
  },
  {
    id: 'documentTemplates',
    message: messages.documentTemplates,
    link: ServicesRoutes.CONTRACT_DOCUMENT_TEMPLATES,
    iconComponent: Document,
    viewBox: '0 0 24 24',
  },
];

const MainPage = (): JSX.Element => {
  return <MainModulePage routes={ServicesModules} pageTitle="Services" />;
};

export default MainPage;
