import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';

import { Button } from 'common/components';
import messages from 'common/messages/messages';

interface IButtonsControl {
  isSignedDocument: boolean;
  isClearBtnDisabled: boolean;
  applySignDocument: () => void;
  clearSignature: () => void;
  cancelTopazConnection: () => void;
  isLoading: boolean;
}

const useStyles = makeStyles(() => ({
  buttonsContainer: {
    width: 'fit-content',
    '&>button:nth-child(2)': {
      margin: '0 6px',
    },
  },
}));

const ButtonsControl = (props: IButtonsControl) => {
  const {
    isSignedDocument,
    clearSignature,
    applySignDocument,
    cancelTopazConnection,
    isClearBtnDisabled,
    isLoading,
  } = props;

  const classes = useStyles();

  return (
    <Grid wrap="nowrap" className={classes.buttonsContainer} container item direction="row">
      <Button
        disabled={isLoading}
        onClick={cancelTopazConnection}
        color="error"
        variant="contained"
      >
        <FormattedMessage {...messages.cancelBtn} />
      </Button>
      <Button
        disabled={isClearBtnDisabled || isLoading}
        onClick={clearSignature}
        color="primary"
        variant="outlined"
      >
        <FormattedMessage {...messages.clearBtn} />
      </Button>
      <Button
        onClick={applySignDocument}
        disabled={isSignedDocument || isLoading}
        color="primary"
        variant="contained"
        startIcon={<DoneIcon />}
      >
        <FormattedMessage {...messages.applyBtn} />
      </Button>
    </Grid>
  );
};

export default ButtonsControl;
