import { createAction } from '@reduxjs/toolkit';
import Services from 'services/network';
import { actionTypes } from '../../constants';

import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from 'common/state/notifications/actions';
import messages from '../../messages/messages';
import { ITableParams } from 'common/interfaces/table';
import { GeneralThunkAction } from 'common/state/interfaces';

const fetchPoliciesListLoading = createAction<boolean>(actionTypes.FETCH_POLICIES_LIST_LOADING);
const fetchPoliciesListAction = createAction<any>(actionTypes.FETCH_POLICIES_LIST);
const fetchPoliciesDictionaryListAction = createAction<any>(
  actionTypes.FETCH_POLICIES_DICTIONARY_LIST,
);
export const resetPoliciesList = createAction(actionTypes.RESET_POLICIES_LIST);

export const fetchPoliciesList = (requestOptions?: ITableParams): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPoliciesListLoading(true));

    try {
      const paginatedData = await Services.Permissions.getPoliciesList(requestOptions);
      dispatch(fetchPoliciesListAction(paginatedData));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPoliciesListLoading(false));
    }
  };
};

// get active policies
export const fetchPoliciesDictionaryList = (): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPoliciesListLoading(true));
    try {
      const policies = await Services.Permissions.getPoliciesDictionaryList();
      dispatch(fetchPoliciesDictionaryListAction(policies));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPoliciesListLoading(false));
    }
  };
};

const fetchPolicyItemLoading = createAction<boolean>(actionTypes.FETCH_POLICY_ITEM_LOADING);
const fetchPolicyItemAction = createAction<any>(actionTypes.FETCH_POLICY_ITEM);

export const fetchPolicyItem = (id: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPolicyItemLoading(true));

    try {
      const response = await Services.Permissions.getPolicyItem(id);
      dispatch(fetchPolicyItemAction(response));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPolicyItemLoading(false));
    }
  };
};

const editPolicyItemAction = createAction<any>(actionTypes.EDIT_POLICY_ITEM_SUCCESS);
export const resetPolicyItemData = createAction(actionTypes.RESET_POLICY_ITEM_DATA);

export const editPolicyItem = (id: string, data: any): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPolicyItemLoading(true));

    try {
      const response = await Services.Permissions.editPolicyItem(id, data);
      dispatch(editPolicyItemAction({ success: !!response }));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPolicyItemLoading(false));
    }
  };
};

export const changeStatusPolicyItem = (id: string, active: boolean): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPolicyItemLoading(true));

    try {
      const response = await Services.Permissions.changePolicyItemStatus(id, active);
      dispatch(editPolicyItemAction({ success: !!response }));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPolicyItemLoading(false));
    }
  };
};

export const resetPolicyItemActionResult = createAction(
  actionTypes.RESET_POLICY_ITEM_ACTION_RESULT,
);

const createPolicyItemAction = createAction<any>(actionTypes.CREATE_POLICY_ITEM_SUCCESS);

export const createPolicyItem = (data: any): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPolicyItemLoading(true));

    try {
      const response = await Services.Permissions.createPolicyItem(data);
      dispatch(createPolicyItemAction({ success: !!response }));
      dispatch(enqueueSuccessNotification(messages.policyItemSuccessCreatedMessage));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPolicyItemLoading(false));
    }
  };
};
