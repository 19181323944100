import { combineReducers } from 'redux-immutable';
import { eventsReducer, eventDetailsReducer, eventServicesReducer } from './events/reducers';
import {
  createResourceReducer,
  resourceChangeStatusReducer,
  resourcesTableReducer,
  updateResourceReducer,
} from './resources/reducers';
import getSenderAvailabilityReducer from './senderAvailability/reducers';

export const rootBookingModuleReducer = combineReducers({
  events: eventsReducer,
  eventDetails: eventDetailsReducer,

  resourcesTable: resourcesTableReducer,
  resourceChangeStatusAction: resourceChangeStatusReducer,
  createResourceForm: createResourceReducer,
  updateResourceForm: updateResourceReducer,

  eventServices: eventServicesReducer,

  senderAvailability: getSenderAvailabilityReducer,
});
