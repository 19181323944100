import { IProfileInfoImt } from 'common/components/PersonProfile/interfaces';
import { SenderAvailabilityTypeList } from 'modules/booking/constants/senderAvailability';
import { TSenderAvailabilityImt } from 'modules/booking/interfaces';
import { AppStoreTypeImt } from 'store';

const profilePath = ['memberPortalProfile', 'profile'];

export const selectMemberProfile = (state): IProfileInfoImt => {
  return state.getIn([...profilePath, 'result']);
};
export const selectMemberProfileLoading = (state): boolean => {
  return state.getIn([...profilePath, 'isLoading']);
};

export const selectPersonAlerts = state => state.getIn([...profilePath, 'alerts']);

export const selectSenderAvailability = (type: SenderAvailabilityTypeList) => (
  state: AppStoreTypeImt,
): TSenderAvailabilityImt => state.getIn([...profilePath, 'sendAvailability', type, 'data']);

export const selectPersonBalanceHistory = state => state.getIn([...profilePath, 'balanceHistory']);

export const selectPersonBalanceHistoryLoading = state =>
  state.getIn([...profilePath, 'balanceHistoryLoading']);
