export const actionTypes = {
  PUSH_REQUEST_ERROR: 'REPORTS/PUSH_REQUEST_ERROR',
  RESET_REQUEST_ERRORS: 'REPORTS/RESET_REQUEST_ERRORS',
  FETCH_INVOICE_HISTORY_LOADING: 'REPORTS/FETCH_INVOICE_HISTORY_LOADING',
  FETCH_INVOICE_HISTORY: 'REPORTS/FETCH_INVOICE_HISTORY',
  FETCH_INVOICE_LOADING: 'REPORTS/FETCH_INVOICE_LOADING',
  FETCH_INVOICE_ITEM: 'REPORTS/FETCH_INVOICE_ITEM',
  RESET_INVOICE_ITEM: 'REPORTS/RESET_INVOICE_ITEM',

  FETCH_INVOICE_INVENTORY_DETAILED_INFO: 'REPORTS/FETCH_INVOICE_INVENTORY_DETAILED_INFO',
  FETCH_INVOICE_INVENTORY_DETAILED_INFO_LOADING:
    'REPORTS/FETCH_INVOICE_INVENTORY_DETAILED_INFO_LOADING',
  RESET_INVOICE_INVENTORY_DETAILED_INFO: 'REPORTS/RESET_INVOICE_INVENTORY_DETAILED_INFO',

  FETCH_INVOICE_PACKAGE_DETAILED_INFO: 'REPORTS/FETCH_INVOICE_PACKAGE_DETAILED_INFO',
  FETCH_INVOICE_PACKAGE_DETAILED_INFO_LOADING:
    'REPORTS/FETCH_INVOICE_PACKAGE_DETAILED_INFO_LOADING',
  RESET_INVOICE_PACKAGE_DETAILED_INFO: 'REPORTS/RESET_INVOICE_PACKAGE_DETAILED_INFO',

  FETCH_INVOICE_TRANSACTIONS_DETAILED_INFO: 'REPORTS/FETCH_INVOICE_TRANSACTIONS_DETAILED_INFO',
  FETCH_INVOICE_TRANSACTIONS_DETAILED_INFO_LOADING:
    'REPORTS/FETCH_INVOICE_TRANSACTIONS_DETAILED_INFO_LOADING',
  RESET_INVOICE_TRANSACTIONS_DETAILED_INFO: 'REPORTS/RESET_INVOICE_TRANSACTIONS_DETAILED_INFO',

  VOID_INVOICE_ITEM_ACTION_LOADING: 'REPORTS/VOID_INVOICE_ITEM_ACTION_LOADING',
  VOID_INVOICE_ITEM_ACTION_RESULT: 'REPORTS/VOID_INVOICE_ITEM_ACTION_RESULT',
  RESET_VOID_INVOICE_ITEM_ACTION_RESULT: 'REPORTS/RESET_VOID_INVOICE_ITEM_ACTION_RESULT',

  ASSIGN_INVOICE_LOADING: 'REPORTS/ASSIGN_INVOICE_LOADING',
  ASSIGN_INVOICE: 'REPORTS/ASSIGN_INVOICE',
  RESET_ASSIGN_INVOICE_SUCCESS: 'REPORTS/RESET_ASSIGN_INVOICE_SUCCESS',
  EXPORT_INVOICE_HISTORY_LOADING: 'REPORTS/EXPORT_INVOICE_HISTORY_LOADING',
  RESET_EXPORT_INVOICE_HISTORY_SUCCESS: 'REPORTS/RESET_EXPORT_INVOICE_HISTORY_SUCCESS',
  EXPORT_INVOICE_HISTORY: 'REPORTS/EXPORT_INVOICE_HISTORY',
  ADJUST_INVOICE_LOADING: 'REPORTS/ADJUST_INVOICE_LOADING',
  ADJUST_INVOICE: 'REPORTS/ADJUST_INVOICE',
  RESET_ADJUST_INVOICE_SUCCESS: 'REPORTS/RESET_ADJUST_INVOICE_SUCCESS',

  INVOICE_HISTORY_SNAPSHOTS_LOADING: 'REPORTS/INVOICE_HISTORY_SNAPSHOTS_LOADING',
  RESET_INVOICE_HISTORY_SNAPSHOTS_SUCCESS: 'REPORTS/RESET_INVOICE_HISTORY_SNAPSHOTS_SUCCESS',
  INVOICE_HISTORY_SNAPSHOTS_ACTION: 'REPORTS/INVOICE_HISTORY_SNAPSHOTS_ACTION',

  INVOICE_STATISTICS_LOADING: 'REPORTS/INVOICE_STATISTICS_LOADING',
  INVOICE_STATISTICS_ACTION: 'REPORTS/INVOICE_STATISTICS_ACTION',
  RESET_INVOICE_STATISTICS_SUCCESS: 'REPORTS/RESET_INVOICE_STATISTICS_SUCCESS',

  CREATE_MEMBER_LOADING: 'REPORTS/CREATE_MEMBER_LOADING',
  CREATE_MEMBER_SUCCESS: 'REPORTS/CREATE_MEMBER_SUCCESS',
  RESET_CREATE_MEMBER: 'REPORTS/RESET_CREATE_MEMBER',

  FETCH_INVOICE_HTML_LOADING: 'REPORTS/FETCH_INVOICE_HTML_LOADING',
};

export const nosMemberElement = {
  key: 'NOS',
  label: 'NOS',
  value: 'NOS',
};

export const textareaSettings = {
  maxSymbols: 1000,
  rows: 3,
};
