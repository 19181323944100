import { ConstOptions } from './classes';
import { colors } from 'common/ui/theme/default';
import { SMSStatus, SMSStatusMessages } from 'common/components/PersonProfile/constants';

export const statusColors = {
  [SMSStatus.Sent]: colors.info,
  [SMSStatus.Sending]: colors.warning,
  [SMSStatus.FailedToSend]: colors.error,
  [SMSStatus.Received]: colors.colorPicker.defaultColor,
  [SMSStatus.SMSSettingsNotConfigured]: colors.background.darkLighter,
};

export const SMSStatuses = new ConstOptions(
  Object.entries(SMSStatusMessages).map(([value, message]) => ({ value, message })),
);
