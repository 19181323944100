export const actionTypes = {
  // Register status report
  FETCH_REGISTER_STATUS_REPORT_LOADING: 'REPORTS/FETCH_REGISTER_STATUS_REPORT_LOADING',
  FETCH_REGISTER_STATUS_REPORT: 'REPORTS/FETCH_REGISTER_STATUS_REPORT',
  // Export
  EXPORT_REGISTER_STATUS_REPORT_LOADING: 'REPORTS/EXPORT_REGISTER_STATUS_REPORT_LOADING',
  EXPORT_REGISTER_STATUS_REPORT: 'REPORTS/EXPORT_REGISTER_STATUS_REPORT',
  RESET_REGISTER_STATUS_EXPORT_SUCCESS: 'REPORTS/RESET_REGISTER_STATUS_EXPORT_SUCCESS',
  // Close register
  CLOSE_REGISTER_ITEM_LOADING: 'REPORTS/CLOSE_REGISTER_ITEM_LOADING',
  CLOSE_REGISTER_ITEM: 'REPORTS/CLOSE_REGISTER_ITEM',
  RESET_CLOSE_REGISTER_ITEM_ACTION_RESULT: 'REPORTS/RESET_CLOSE_REGISTER_ITEM_ACTION_RESULT',
  // Reconcile register sales
  RECONCILE_REGISTER_ITEM_LOADING: 'REPORTS/RECONCILE_REGISTER_ITEM_LOADING',
  RECONCILE_REGISTER_ITEM: 'REPORTS/RECONCILE_REGISTER_ITEM',
  RESET_RECONCILE_REGISTER_ITEM_ACTION_RESULT:
    'REPORTS/RESET_RECONCILE_REGISTER_ITEM_ACTION_RESULT',
  FETCH_INVOICES_COUNT_FROM_REGISTER_LOADING: 'REPORTS/FETCH_INVOICES_COUNT_FROM_REGISTER_LOADING',
  FETCH_INVOICES_COUNT_FROM_REGISTER_SUCCESS: 'REPORTS/FETCH_INVOICES_COUNT_FROM_REGISTER_SUCCESS',
  RESET_INVOICES_COUNT_FROM_REGISTER: 'REPORTS/RESET_INVOICES_COUNT_FROM_REGISTER',
};
