import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import { BooleanLabel, StatusLabel } from 'common/components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainLabel: {
      fontSize: '0.95rem',
      fontWeight: 700,
    },
    caption: {
      fontSize: '0.65rem',
      lineHeight: '1.30rem',
      color: 'rgba(50, 50, 50, 0.3)',
    },
    avatar: {
      fontSize: '0.75rem',
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
  }),
);

interface IProps {
  name: string;
  isActive: boolean;
  inUse: boolean;
}

const SmallScreenCell = (props: IProps) => {
  const classes = useStyles();
  const { name, isActive, inUse } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography className={classes.mainLabel} component="span" variant="h6">
          {name}
        </Typography>
      </Grid>

      <Grid item>
        <Typography className={classes.caption} variant="caption">
          Status
        </Typography>

        <StatusLabel variant="body2" isActive={isActive} />
      </Grid>

      <Grid item>
        <Typography className={classes.caption} variant="caption">
          In Use
        </Typography>
        <BooleanLabel variant="body2" value={inUse} />
      </Grid>
    </Grid>
  );
};

export default React.memo(SmallScreenCell);
