// events list
const eventsListPath = ['bookingModule', 'events'];

const selectEventsListLoading = state => state.getIn([...eventsListPath, 'isLoading']);

const selectEventsList = state => state.getIn([...eventsListPath, 'list']);

// event details
const eventDetailsPath = ['bookingModule', 'eventDetails'];

const selectEventDetails = state => state.getIn([...eventDetailsPath, 'event']);

const selectEventDetailsLoading = state => state.getIn([...eventDetailsPath, 'isEventLoading']);

// event action status
const eventItemStatusPath = ['bookingModule', 'eventDetails', 'eventActionStatus'];

const selectEventActionResult = state => state.getIn([...eventItemStatusPath, 'result']);

const selectEventActionLoading = state => state.getIn([...eventItemStatusPath, 'isLoading']);

// event services
const selectEventServices = state => state.getIn(['bookingModule', 'eventServices', 'list']);

const selectEventServicesLoading = state =>
  state.getIn(['bookingModule', 'eventServices', 'isLoading']);

export {
  // list
  selectEventsListLoading,
  selectEventsList,
  // event details
  selectEventDetails,
  selectEventDetailsLoading,
  // event action status
  selectEventActionResult,
  selectEventActionLoading,
  // event services
  selectEventServices,
  selectEventServicesLoading,
};
