export const actionTypes = {
  SET_IS_LOADING: 'WAIVERS/SET_IS_LOADING',
  GET_WAIVERS: 'WAIVERS/GET_WAIVERS',
  SIGN_WAIVERS: 'WAIVERS/SIGN_WAIVERS',
  APPLY_WAIVERS: 'WAIVERS/APPLY_WAIVERS',
  SET_SIGN_WAIVERS_ACTION_RESULT: 'WAIVERS/SET_SIGN_WAIVERS_ACTION_RESULT',
  SET_APPLY_WAIVER_ACTION_RESULT: 'WAIVERS/SET_APPLY_WAIVER_ACTION_RESULT',
  RESET_APPLY_WAIVER_ACTION_RESULT: 'WAIVERS/RESET_APPLY_WAIVER_ACTION_RESULT',
  SET_ERROR: 'WAIVERS/SET_ERROR',
  RESET_WAIVERS: 'WAIVERS/RESET_WAIVERS',
  FETCH_WAIVER_HTML_LOADING: 'WAIVERS/FETCH_WAIVER_HTML_LOADING',
};
