import GrapesJS from 'grapesjs';

import employeesIcon from 'img/editor-icons/block-employees.svg';
import { defaultToolbar } from './TextPlugin';
import { GrapesCustomCommands, GrapesCustomTypes } from '../constants';
import { removeActions } from '../utils';
import { IObject } from 'common/interfaces/common';

(GrapesJS as IObject).plugins.add(GrapesCustomTypes.EmployeesList, editor => {
  function addCommands() {
    editor.Commands.add(GrapesCustomCommands.SearchEmployees, {
      run() {},
      stop(commandEditor, sender, data) {
        const component = commandEditor.getSelected();

        if (data && data.component) {
          component.components().add({
            type: GrapesCustomTypes.EmployeeItem,
            attributes: {
              employee: data.component,
            },
          });
        }
      },
    });
  }

  function addComponents() {
    const defaultType = editor.DomComponents.getType('default');

    const { initToolbar } = defaultType.model.prototype;

    editor.DomComponents.addType(GrapesCustomTypes.EmployeesList, {
      model: {
        ...defaultType.model,
        defaults: {
          droppable: false,
        },
        initToolbar(args) {
          initToolbar.apply(this, args);

          const toolbar = [
            {
              attributes: { class: 'fa fa-plus' },
              command: GrapesCustomCommands.SearchEmployees,
            },
            ...defaultToolbar,
          ];

          this.set('toolbar', toolbar);
        },
        isComponent(el) {
          if (
            el.hasAttribute === 'function' &&
            el.hasAttribute('data-gjs-type') === GrapesCustomTypes.EmployeesList
          ) {
            return { type: GrapesCustomTypes.EmployeesList };
          }
          return false;
        },
      },
      view: {
        ...defaultType.view,
      },
    });

    editor.DomComponents.addType(GrapesCustomTypes.EmployeeItem, {
      model: {
        ...defaultType.model,
        defaults: {
          draggable: false,
          droppable: false,
          highlightable: false,
        },
        init() {
          const attrs = this.getAttributes();
          const employee = attrs.employee || {};
          const components = this.get('components');

          components.reset(`
            <img class="employees-photo" src="${employee.imageUrl}" alt="${employee.title}" data-employee-id=${employee.id} />
            <div class="employees-info">
              <div class="employees-name" data-employee-id=${employee.id}>${employee.title}</div>
            </div>
          `);

          components.forEach(component => removeActions(component));
          this.addClass(GrapesCustomTypes.EmployeeItem);
        },
        isComponent(el) {
          if (
            el.hasAttribute === 'function' &&
            el.hasAttribute('data-gjs-type') === GrapesCustomTypes.EmployeeItem
          ) {
            return { type: GrapesCustomTypes.EmployeeItem };
          }
          return false;
        },
      },
      view: {
        ...defaultType.view,
        tagName: 'div',
      },
    });
  }

  function addBlocks() {
    editor.BlockManager.add(GrapesCustomTypes.EmployeesList, {
      label: `
        <img class="gjs-block-icon" src="${employeesIcon}" alt="employee" />
        <div class="gjs-block-title">Employees</div>
      `,
      content: `
        <div class="employees" data-gjs-type="employees-list"></div>
        <style>
          /*.employees {*/
          /*  padding: 8px;*/
          /*  min-height: 60px;*/
          /*  display: flex;*/
          /*  flex-direction: row;*/
          /*  flex-wrap: wrap;*/
          /*}*/
          /*.employees-item {*/
          /*  margin: 8px;*/
          /*  padding: 12px;*/
          /*  flex: 1;*/
          /*  flex-basis: 45%;*/
          /*  display: flex;*/
          /*  flex-direction: row;*/
          /*  align-items: center;*/
          /*  border: solid 1px #d6d6d6;*/
          /*  borderRadius: 4px;*/
          /*}*/
          /*.employees-photo {*/
          /*  margin-right: 12px;*/
          /*  width: 66px;*/
          /*  height: 66px;*/
          /*  background-color: lightgrey;*/
          /*  border-radius: 3px;*/
          /*}*/
          /*.employees-name {*/
          /*  margin-bottom: 5px;*/
          /*  font-size: 14px;*/
          /*  font-weight: bold;*/
          /*}*/
          /*.employees-price {*/
          /*  font-size: 13px;*/
          /*  color: #323232;*/
          /*  opacity: 0.7;*/
          /*}*/
        </style>
      `,
    });
  }

  addCommands();
  addComponents();
  addBlocks();
});

export default GrapesCustomTypes.EmployeesList;
