import React from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { Typography, Box, SvgIcon, makeStyles, Theme, Divider, Tooltip } from '@material-ui/core';
import { Edit, EventBusy, EventAvailable, Notifications, Delete } from '@material-ui/icons';

import { ReactComponent as UsersAddIcon } from 'img/icons/users-add.svg';
import { ReactComponent as UsersCancelIcon } from 'img/icons/users-cancel.svg';
import { ReactComponent as WarningIcon } from 'img/icons/warning.svg';

import { IRecentAppointmentItemImt, PersonAppointmentStatus } from 'modules/booking/interfaces';
import bookingMessages from 'modules/booking/messages';
import commonMessages from 'common/messages/messages';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { ActionItem, ActionsMenu } from 'common/components/index';

const useStyles = makeStyles((theme: Theme) => ({
  menu: {
    minWidth: 'fit-content',
  },
  menuBtn: {
    width: '32px',
    height: '32px',
    minWidth: '36px',
    backgroundColor: 'white',
  },
  warningIcon: {
    color: theme.palette.warning.main,
    pointerEvents: 'auto',
    width: 16,
    height: 16,
  },
  editEventMenu: {
    opacity: '1 !important',
    '&:hover': {
      backgroundColor: 'inherit',
      color: 'inherit',
    },
  },
  inactive: {
    opacity: 0.5,
  },
  menuItemIcon: {
    // TODO: remove some styles after replacing icons from the material with project icons
    fontSize: '1.1rem',
    color: theme.palette.text.primary,
    '& path': {
      fill: theme.palette.text.primary,
    },
  },
}));

interface IProps {
  appointment: IRecentAppointmentItemImt;
  onEdit: (appointment: IRecentAppointmentItemImt) => void;
  onRedeem: (appointment: IRecentAppointmentItemImt) => void;
  onDelete: (appointment: IRecentAppointmentItemImt) => void;
  onCancel: (appointment: IRecentAppointmentItemImt) => void;
  onCancelParticipation: (appointment: IRecentAppointmentItemImt) => void;
  onConfirmParticipation: (appointment: IRecentAppointmentItemImt) => void;
  onRemind?: (event: IRecentAppointmentItemImt) => void;
  onRestore?: (event: IRecentAppointmentItemImt) => void;
}

export default function AppointmentMenu({
  appointment,
  onEdit,
  onRedeem,
  onCancel,
  onCancelParticipation,
  onConfirmParticipation,
  onDelete,
  onRemind,
  onRestore,
}: IProps): JSX.Element {
  const classes = useStyles();

  const [timezoneMoment] = useTimezoneMoment();

  const onRemindClick = () => {
    onRemind(appointment);
  };

  const onRedeemClick = () => {
    onRedeem(appointment);
  };

  const onCancelParticipationClick = () => {
    onCancelParticipation(appointment);
  };

  const onConfirmParticipationClick = () => {
    onConfirmParticipation(appointment);
  };

  const eventDate = timezoneMoment(appointment.get('date'));

  const hoursBeforeEvent = eventDate.diff(timezoneMoment(), 'hours');

  const showWarning = hoursBeforeEvent <= 2;

  const onEditClick = () => {
    if (hoursBeforeEvent > 2) {
      onEdit(appointment);
    }
  };

  const onCancelClick = () => {
    onCancel(appointment);
  };

  const onDeleteClick = () => {
    onDelete(appointment);
  };
  const onRestoreClick = () => {
    onRestore(appointment);
  };

  return (
    <ActionsMenu horizontal className={classes.menu}>
      <ActionItem
        disabled={appointment.get('appointmentStatus') === PersonAppointmentStatus.Confirmed}
        icon={<UsersAddIcon className={classes.menuItemIcon} />}
        onClick={onConfirmParticipationClick}
      >
        <FormattedMessage {...bookingMessages.confirmParticipation} />
      </ActionItem>
      <ActionItem
        disabled={appointment.get('appointmentStatus') === PersonAppointmentStatus.Cancelled}
        onClick={onCancelParticipationClick}
        icon={<UsersCancelIcon className={classes.menuItemIcon} />}
      >
        <FormattedMessage {...bookingMessages.cancelParticipation} />
      </ActionItem>
      <ActionItem
        onClick={appointment.get('canceled') ? onRestoreClick : onCancelClick}
        icon={
          <SvgIcon className={classes.menuItemIcon}>
            {appointment.get('canceled') ? <EventAvailable /> : <EventBusy />}
          </SvgIcon>
        }
      >
        <FormattedMessage
          {...(appointment.get('canceled')
            ? bookingMessages.restoreEventBtn
            : bookingMessages.cancelEventBtn)}
        />
      </ActionItem>
      <ActionItem
        onClick={onRemindClick}
        icon={
          <SvgIcon className={classes.menuItemIcon}>
            <Notifications />
          </SvgIcon>
        }
      >
        <FormattedMessage {...bookingMessages.remindParticipantsModalTitle} />
      </ActionItem>
      <ActionItem
        onClick={onRedeemClick}
        disabled={!appointment.get('hasScheduledService')}
        icon={
          <SvgIcon className={classes.menuItemIcon}>
            <EventAvailable />
          </SvgIcon>
        }
      >
        <FormattedMessage {...commonMessages.redeemBtn} />
      </ActionItem>

      <ActionItem
        className={cx({ [classes.editEventMenu]: showWarning })}
        disabled={showWarning}
        onClick={onEditClick}
        icon={
          <SvgIcon className={cx(classes.menuItemIcon, { [classes.inactive]: showWarning })}>
            <Edit />
          </SvgIcon>
        }
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" flex={1}>
          <Typography className={cx({ [classes.inactive]: showWarning })} color="inherit">
            <FormattedMessage {...bookingMessages.editEvent} />
          </Typography>
          {showWarning && (
            <Tooltip
              title={
                <Typography variant="inherit">
                  <FormattedMessage {...bookingMessages.canNotEditEventMsg} />
                </Typography>
              }
            >
              <WarningIcon className={classes.warningIcon} />
            </Tooltip>
          )}
        </Box>
      </ActionItem>

      <Box pt={1} pb={1}>
        <Divider />
      </Box>
      <ActionItem
        onClick={onDeleteClick}
        icon={
          <SvgIcon className={classes.menuItemIcon}>
            <Delete />
          </SvgIcon>
        }
      >
        <FormattedMessage {...bookingMessages.deleteEventBtn} />
      </ActionItem>
    </ActionsMenu>
  );
}
