import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'store/hooks';

import { IBundleViewImt } from 'modules/services/interfaces/packages';

import { DialogComponent, LoadingBackdrop, BundleView } from 'common/components';
import {
  selectMembershipPackage,
  selectMembershipPackageLoading,
} from 'common/components/PersonProfile/state/membership/selectors';
import {
  fetchMembershipPackage,
  resetMembershipPackageAction,
} from 'common/components/PersonProfile/state/membership/actions';
import { PeakModules } from 'common/constants/peakModules';
import PackageInventoryViewInfoModal from './PackageInventoryViewModal';

interface IProps {
  isOpen: boolean;
  module: PeakModules;
  packageId: string;
  onClose: () => void;
  personId: string;
}

const ViewPackageInfoModal = ({
  isOpen,
  onClose,
  personId,
  module,
  packageId,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const packageInfo: IBundleViewImt = useSelector(selectMembershipPackage);
  const isPackageInfoLoading: boolean = useSelector(selectMembershipPackageLoading);

  const [{ inventoryId, membershipPackageId }, setInventoryData] = useState({
    inventoryId: null,
    membershipPackageId: null,
  });

  const transformedPackage = useMemo(() => packageInfo?.toJS(), [packageInfo]);

  useEffect(() => {
    dispatch(fetchMembershipPackage(personId, packageId, module));
  }, [dispatch, personId, packageId, module]);

  useEffect(() => {
    return () => {
      dispatch(resetMembershipPackageAction());
    };
  }, [dispatch]);

  const onOpenInventoryInfo = (invId: string, pcgId: string) => {
    setInventoryData({ inventoryId: invId, membershipPackageId: pcgId });
  };
  const onCloseInventoryInfo = () => {
    setInventoryData({ inventoryId: null, membershipPackageId: null });
  };

  return (
    <DialogComponent
      hideFooterButtons
      title={packageInfo?.get('title')}
      isOpen={isOpen}
      onClose={onClose}
      size="md"
    >
      {!!transformedPackage && (
        <BundleView onViewInventoryInfo={onOpenInventoryInfo} bundle={transformedPackage} />
      )}

      <LoadingBackdrop isLoading={isPackageInfoLoading} />

      {Boolean(inventoryId && membershipPackageId) && (
        <PackageInventoryViewInfoModal
          inventoryId={inventoryId}
          packageId={membershipPackageId}
          isOpen
          module={module}
          onClose={onCloseInventoryInfo}
          personId={personId}
        />
      )}
    </DialogComponent>
  );
};

export default ViewPackageInfoModal;
