import React, { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';

import useTimezoneMoment from 'common/hooks/useTimezoneMoment';

import { ITimeClockEmployeeJobPosition } from 'common/interfaces/employee';
import { CustomTheme } from 'common/ui/interfaces';

import commonMessages from 'common/messages/messages';
import { payTypeLabels } from 'modules/employees/constants';

const useStyles = makeStyles((theme: CustomTheme) => ({
  buttonsWrapper: {
    width: 'initial',
  },
  payTypeLabel: {
    color: theme.palette.secondary.dark,
    marginLeft: theme.spacing(1),
  },
}));

interface IProps {
  position: ITimeClockEmployeeJobPosition;
  onClockInOrOut: (position: ITimeClockEmployeeJobPosition) => void;
}

const TimeclockJobPositionItem = ({ position, onClockInOrOut }: IProps): JSX.Element => {
  const classes = useStyles();
  const [timezoneMoment] = useTimezoneMoment();

  const timeValue = useMemo(() => {
    const currentTime = moment.tz('UTC');
    const clockedInTime = position?.clockIn;
    const diffHours = currentTime.diff(`${clockedInTime}Z`, 'hours');
    const diffMinutes = currentTime.diff(`${clockedInTime}Z`, 'minutes');
    const diffSeconds = currentTime.diff(`${clockedInTime}Z`, 'seconds');

    const minutesLabel = Math.trunc(diffMinutes % 60);
    const secondsLabel = diffSeconds % 60;

    return diffHours ? `${diffHours}h ${minutesLabel}m` : `${minutesLabel}m  ${secondsLabel}s`;
  }, [position]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      className={classes.buttonsWrapper}
    >
      <Box>
        <Box display="flex">
          <Typography variant="h5">{position?.jobTitle}</Typography>
          <Typography variant="h6" className={classes.payTypeLabel}>
            ({payTypeLabels.translate(position?.payType)})
          </Typography>
        </Box>
        <Typography variant="h6" color="textSecondary">
          {position?.department?.title}
        </Typography>
      </Box>

      <Box display="flex">
        {position?.clockEdIn && (
          <Box display="flex" flexDirection="column" mr={0.5}>
            <Typography variant="h6" color="textSecondary">
              {timezoneMoment(position?.clockIn).format('hh:mm A')}
            </Typography>
            <Typography variant="h6" color="primary">
              {timeValue}
            </Typography>
          </Box>
        )}
        <Button
          variant={position?.clockEdIn ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => onClockInOrOut(position)}
        >
          <FormattedMessage
            {...(position?.clockEdIn ? commonMessages.clockOutBtn : commonMessages.clockInBtn)}
          />
        </Button>
      </Box>
    </Box>
  );
};

export default memo(TimeclockJobPositionItem);
