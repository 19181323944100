export const selectWaivers = state => state.getIn(['newPerson', 'personWaivers', 'result']);

export const selectWaiversIsLoading = state =>
  state.getIn(['newPerson', 'personWaivers', 'isLoading']);

export const selectIsWaiverHTMLLoading = state =>
  state.getIn(['newPerson', 'personWaivers', 'isWaiverHTMLLoading']);

export const selectWaiversError = state => state.getIn(['newPerson', 'personWaivers', 'error']);

export const selectSetSignWaiversActionResult = state =>
  state.getIn(['newPerson', 'personWaivers', 'signActionResult']);

export const selectSetApplyWaiverActionResult = state =>
  state.getIn(['newPerson', 'personWaivers', 'applyActionResult']);

export const selectAppliedSignWaiver = state =>
  state.getIn(['newPerson', 'personWaivers', 'appliedSignWaiver']);
