import { ConstOptions } from 'common/constants/classes';

import membersMessages from 'modules/members/messages';
import employeesMessages from 'common/messages/employeesMessages';
import posSettingsMessages from 'modules/pos-settings/messages/messages';
import clubsMessages from 'modules/clubs/messages';
import corporationsMessages from 'modules/corporations/messages';

export enum GlobalSearchGroups {
  Customers = 'customers',
  Employees = 'employees',
  Inventories = 'inventories',
  Clubs = 'clubs',
  Corporations = 'corporations',
}

export const GlobalSearchResultGroups = new ConstOptions([
  {
    key: GlobalSearchGroups.Customers,
    value: GlobalSearchGroups.Customers,
    message: membersMessages.membersTitle,
  },
  {
    key: GlobalSearchGroups.Employees,
    value: GlobalSearchGroups.Employees,
    message: employeesMessages.employeesTitle,
  },
  {
    key: GlobalSearchGroups.Inventories,
    value: GlobalSearchGroups.Inventories,
    message: posSettingsMessages.inventory,
  },
  {
    key: GlobalSearchGroups.Clubs,
    value: GlobalSearchGroups.Clubs,
    message: clubsMessages.clubsTitle,
  },
  {
    key: GlobalSearchGroups.Corporations,
    value: GlobalSearchGroups.Corporations,
    message: corporationsMessages.corporationsTitle,
  },
]);
