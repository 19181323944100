import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { actionTypes } from './constants';
import { initialState } from 'common/constants/initialState';

export const initListState = fromJS({
  list: [],
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
  isLoading: false,
  error: null,
});

function endReasonsListReducer(state = initListState, action) {
  switch (action.type) {
    case actionTypes.FETCH_END_REASONS_LIST_IS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_END_REASONS_LIST:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta));
    case actionTypes.FETCH_END_REASONS_LIST_ERROR:
      return state.set('error', action.payload);
    case actionTypes.RESET_END_REASONS_LIST:
      return initListState;
    default:
      return state;
  }
}

const initItemState = fromJS({
  itemActionRequestState: initialState,
});

function endReasonReducer(state = initItemState, action) {
  switch (action.type) {
    case actionTypes.CREATE_OR_UPDATE_END_REASON_IS_LOADING:
      return state.setIn(['itemActionRequestState', 'isLoading'], action.payload);
    case actionTypes.SET_END_REASON_ACTION_RESULT:
      return state.setIn(['itemActionRequestState', 'result'], action.payload);
    case actionTypes.FETCH_END_REASON_ERROR:
      return state.setIn(['itemActionRequestState', 'error'], fromJS(action.payload));
    case actionTypes.RESET_END_REASON:
      return initItemState;
    default:
      return state;
  }
}

export default combineReducers({
  endReasonsList: endReasonsListReducer,
  endReason: endReasonReducer,
});
