import React from 'react';
import { Box, Typography } from '@material-ui/core';

import { Variant } from '@material-ui/core/styles/createTypography';
import { getInitials } from 'helpers/common';
import { AvatarWithInitials } from 'common/components';

interface IMemberInfoProps {
  imageUrl?: string;
  firstName: string;
  lastName: string;
  titleVariant: Variant | 'inherit';
  width: number;
  height: number;
}

const MemberInfo = ({
  imageUrl,
  firstName,
  lastName,
  titleVariant,
  height,
  width,
}: IMemberInfoProps): JSX.Element => {
  return (
    <Box display="flex" alignItems="center">
      <AvatarWithInitials
        marginRight={1.5}
        width={width}
        height={height}
        variant="square"
        borderRadius="3px"
        initials={getInitials(firstName, lastName)}
        imageUrl={imageUrl}
      />
      <Typography variant={titleVariant}>{`${firstName} ${lastName}`}</Typography>
    </Box>
  );
};

export default MemberInfo;
