import React from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';

import { formatPrice } from 'common/utils';

import { FieldInfo } from 'common/components/index';
import { ReactComponent as CrossIcon } from 'img/icons/times.svg';
import { ReactComponent as CheckIcon } from 'img/icons/check.svg';

import inputLabels from 'common/messages/inputLabels';
import servicesMessages from 'modules/services/messages/messages';
import { IPaymentSplitting } from 'modules/services/interfaces/packages';

const useStyles = makeStyles((theme: Theme) => ({
  splitStatus: {
    display: 'flex',
    alignItems: 'center',
  },
  statusIcon: {
    width: '1rem',
    height: '1rem',
    marginLeft: theme.spacing(1),

    '&.primary': {
      color: theme.palette.primary.main,
    },
    '&.error': {
      color: theme.palette.error.main,
    },
  },
}));

interface IProps {
  splitting: IPaymentSplitting;
}

const SplitPaymentSectionView = ({ splitting }: IProps): JSX.Element => {
  const classes = useStyles();

  const { allow: allowSplitting, paymentEditableNumber, paymentEditableSplits } = splitting;

  return (
    <>
      <Grid item xs={12} className={classes.splitStatus}>
        <Typography component="p" variant="h5">
          <FormattedMessage {...servicesMessages.splitEachPaymentLabel} />
        </Typography>

        {allowSplitting ? (
          <CheckIcon className={cx(classes.statusIcon, 'primary')} />
        ) : (
          <CrossIcon className={cx(classes.statusIcon, 'error')} />
        )}
      </Grid>

      {allowSplitting && (
        <>
          <Grid item xs={4}>
            <FieldInfo
              label={<FormattedMessage {...inputLabels.payments} />}
              description={`${paymentEditableNumber?.value}`}
            />
          </Grid>

          {paymentEditableSplits?.map((splitPaymentItem, index) => {
            return (
              <Grid item xs={4} key={splitPaymentItem.order}>
                <FieldInfo
                  label={`#${index + 1}`}
                  description={formatPrice(splitPaymentItem.value)}
                />
              </Grid>
            );
          })}
        </>
      )}
    </>
  );
};

export default SplitPaymentSectionView;
