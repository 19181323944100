export const FETCH_PERSON_REFERRALS_SUCCESS = 'FETCH_PERSON_REFERRALS_SUCCESS';
export const FETCH_PERSON_REFERRALS_LOADING = 'FETCH_PERSON_REFERRALS_LOADING';
export const RESET_PERSON_REFERRALS = 'RESET_PERSON_REFERRALS';

export const ADD_PERSON_REFERRAL = 'ADD_PERSON_REFERRAL_SUCCESS';
export const ADD_PERSON_REFERRAL_LOADING = 'ADD_PERSON_REFERRAL_LOADING';
export const RESET_ADD_PERSON_REFERRAL_ACTION_RESULT = 'RESET_ADD_PERSON_REFERRAL_ACTION_RESULT';

export const SET_REFERRED_BY = 'SET_REFERRED_BY';
export const SET_REFERRED_BY_LOADING = 'SET_REFERRED_BY_LOADING';

export const SET_PERSON_REFERRALS_ERROR = 'SET_PERSON_REFERRALS_ERROR';
