import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import * as actions from 'modules/crm/state/crmTemplates/actions';
import * as selectors from 'modules/crm/state/crmTemplates/selectors';
import { selectQueryPageList } from 'common/state/queryPage-lists/selectors';

import { useAppDispatch } from 'store/hooks';

import {
  ICreateDocumentTemplateDto,
  IDocumentTemplateDtoImt,
} from 'common/interfaces/documentTemplate';

import { ActionResult, QueryPageList } from 'common/constants';

import { SmsForm } from 'modules/crm/components';
import { LoadingBackdrop } from 'common/components';
import { RouteBackground } from 'components';

import { removeLastRouteFromPath } from 'helpers';
import { convertToEditor } from 'common/utils/draftJS';

import messages from 'modules/crm/messages/templates';
import { PeakModules } from 'common/constants/peakModules';

const EditSMS = (): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const templateLoading: boolean = useSelector(selectors.selectGetTemplateIsLoading);
  const template: IDocumentTemplateDtoImt = useSelector(selectors.selectTemplate);
  const queryDocumentTemplates: string = useSelector(
    selectQueryPageList(QueryPageList.CRM_TEMPLATES),
  );
  const saveTemplateLoading: boolean = useSelector(selectors.selectSaveDocumentTemplateLoading);
  const documentTemplateActionResult: ActionResult = useSelector(
    selectors.selectSaveTemplateActionResult,
  );

  const [defaultEditor, setDefaultEditor] = useState(undefined);

  const location = useLocation();
  const navigate = useNavigate();
  const module =
    location.pathname.indexOf('personal-training-crm') === -1
      ? PeakModules.Crm
      : PeakModules.PersonalTrainingCrm;
  const { id: editId } = useParams();

  // handlers

  const updateEditor = useCallback(() => {
    setDefaultEditor(convertToEditor(template.get('content')));
  }, [template]);

  const onCancel = useCallback(() => {
    navigate(`${removeLastRouteFromPath(location.pathname)}${queryDocumentTemplates}`);
  }, [location.pathname, navigate, queryDocumentTemplates]);

  const onSave = useCallback(
    (value: ICreateDocumentTemplateDto) => {
      const document = {
        ...value,
        id: editId,
      };

      if (editId) {
        dispatch(actions.updateDocumentTemplate(document, module));
      } else {
        dispatch(actions.saveDocumentCreator(document, module));
      }
    },
    [dispatch, editId, module],
  );

  // lifecycle

  useEffect(() => {
    if (editId) {
      dispatch(actions.getCRMTemplate(editId, module));
    }
  }, [dispatch, editId, module]);

  useEffect(() => {
    if (template) {
      updateEditor();
    }

    if (documentTemplateActionResult === ActionResult.SUCCESS_ACTION) {
      dispatch(actions.saveCrmTemplateActionResult(null));

      navigate(`${removeLastRouteFromPath(location.pathname)}${queryDocumentTemplates}`);
    }
  }, [
    dispatch,
    documentTemplateActionResult,
    location.pathname,
    navigate,
    queryDocumentTemplates,
    template,
    updateEditor,
  ]);

  useEffect(() => {
    return () => {
      dispatch(actions.resetCRMTemplate());
    };
  }, [dispatch]);

  return (
    <RouteBackground>
      {((defaultEditor && editId) || !editId) && (
        <SmsForm
          title={<FormattedMessage {...(editId ? messages.editSMSTitle : messages.newSMSTitle)} />}
          isLoading={saveTemplateLoading}
          onCancel={onCancel}
          onSave={onSave}
          defaultEditor={defaultEditor}
          template={template}
        />
      )}
      <LoadingBackdrop isLoading={templateLoading} />
    </RouteBackground>
  );
};

export default EditSMS;
