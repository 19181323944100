import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';
import { v4 as uuid } from 'uuid';

import { actionTypes } from 'modules/reports/constants/crmDashboard';

const initStateCrmDashboardStatsReducer = fromJS({
  data: {},
  isLoading: false,
});

function crmDashboardStatisticsReducer(state = initStateCrmDashboardStatsReducer, action) {
  switch (action.type) {
    case actionTypes.FETCH_CRM_DASHBOARD_STATISTICS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_CRM_DASHBOARD_STATISTICS:
      return state.set('data', fromJS(action.payload)).set('isLoading', false);
    default:
      return state;
  }
}

const initStateCrmAppointmentsReducer = fromJS({
  list: [],
  isLoading: false,
  meta: { page: 0, perPage: 10, total: 0 },
});

function crmAppointmentsReducer(state = initStateCrmAppointmentsReducer, action) {
  switch (action.type) {
    case actionTypes.FETCH_CRM_APPOINTMENTS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_CRM_APPOINTMENTS:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta))
        .set('isLoading', false);
    default:
      return state;
  }
}

const initStateCrmBacklogReducer = fromJS({
  list: [],
  isLoading: false,
  meta: { page: 0, perPage: 10, total: 0 },
});

function crmBacklogReducer(state = initStateCrmBacklogReducer, action) {
  switch (action.type) {
    case actionTypes.FETCH_CRM_BACKLOG_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_CRM_BACKLOG:
      return state
        .set('list', fromJS(action.payload.data.map(item => ({ ...item, id: uuid() }))))
        .set('meta', fromJS(action.payload.meta))
        .set('isLoading', false);
    default:
      return state;
  }
}

const initStateCrmActivityReducer = fromJS({
  list: {},
  isLoading: false,
});

function crmActivityReducer(state = initStateCrmActivityReducer, action) {
  switch (action.type) {
    case actionTypes.FETCH_CRM_ACTIVITY_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_CRM_ACTIVITY:
      return state.set('list', action.payload).set('isLoading', false);
    default:
      return state;
  }
}

const initStateCrmMembershipsSoldReducer = fromJS({
  data: [],
  isLoading: false,
});

function crmMembershipsSoldReducer(state = initStateCrmMembershipsSoldReducer, action) {
  switch (action.type) {
    case actionTypes.FETCH_CRM_MEMBERSHIP_SOLD_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_CRM_MEMBERSHIP_SOLD:
      return state.set('data', fromJS(action.payload)).set('isLoading', false);
    default:
      return state;
  }
}

const initStateCrmConversionValueReducer = fromJS({
  list: [],
  isLoading: false,
});

function crmConversionValueReducer(state = initStateCrmConversionValueReducer, action) {
  switch (action.type) {
    case actionTypes.FETCH_CRM_CONVERSION_VALUE_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_CRM_CONVERSION_VALUE:
      return state.set('list', fromJS(action.payload.data)).set('isLoading', false);
    default:
      return state;
  }
}

export default combineReducers({
  crmStats: crmDashboardStatisticsReducer,
  activity: crmActivityReducer,
  appointments: crmAppointmentsReducer,
  backlog: crmBacklogReducer,
  membershipsSold: crmMembershipsSoldReducer,
  conversionValue: crmConversionValueReducer,
});
