import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Theme, Typography } from '@material-ui/core';

import { Button, UnavailableModule } from 'common/components';
import { ReactComponent as ClosedRegisterIcon } from 'img/icons/cash-register-close.svg';

import messages from 'modules/pos-kiosk/messages/messages';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    gap: theme.spacing(3),
    maxWidth: '100% !important',
    '& > div': {
      padding: 0,
      margin: 0,
    },
  },
  mainText: {
    fontSize: '46px',
    fontWeight: 700,
  },
  bodyText: {
    fontSize: '16px',
    color: theme.palette.secondary.dark,
  },
}));

interface IClosedRegisterProps {
  onOpenRegister: () => void;
}

const ClosedRegister = (props: IClosedRegisterProps): JSX.Element => {
  const { onOpenRegister } = props;

  const classes = useStyles();

  return (
    <UnavailableModule
      className={classes.wrapper}
      hasVisibleBackToBtn={false}
      lockIcon={<ClosedRegisterIcon />}
      title={<FormattedMessage {...messages.closedRegisterText} />}
      description={
        <Typography className={classes.bodyText}>
          <FormattedMessage {...messages.youNeedToOpenRegisterText} />
        </Typography>
      }
      additionalButton={
        <Button variant="contained" color="primary" onClick={onOpenRegister}>
          <FormattedMessage {...messages.openRegister} />
        </Button>
      }
    />
  );
};

export default ClosedRegister;
