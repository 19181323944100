import React, { FC, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { List as ImmutableList } from 'immutable';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';

import { CampaignType } from 'common/constants/campaign';

import { PeakModules } from 'common/constants/peakModules';
import { ICampaignListItemImt } from 'modules/crm/interfaces/campaigns';
import { IBodyCell, IHeadCell, ITableParams, ITableRow } from 'common/interfaces/table';

import { IFilterSettings } from 'common/interfaces/filter';
import { ActionItem, ActionsMenu, BooleanLabel, Table } from 'common/components';
import AddCampaignDropdown from './AddCampaignDropdown/AddCampaignDropdown';
import SmallScreenCell from './SmallScreenCell/SmallScreenCell';
import CampaignStatus from './CampaignStatus/CampaignStatus';
import tableHeaders from 'common/messages/tableHeaders';
import commonMessages from 'common/messages/messages';
import { CampaignTypes } from '../../constants/campaigns';
import { ReactComponent as EditIcon } from 'img/icons/edit.svg';

import common from '../../messages/common';
import menuItems from 'common/messages/menuItems';
import ptCrmMessages from 'modules/personal-training-crm/messages/messages';
import usePermissionContext from 'common/hooks/context/usePermissionContext';

interface IProps {
  items: ImmutableList<ICampaignListItemImt>;
  tableParams: ITableParams;
  tableFilterSettings: IFilterSettings[];
  totalRows: number;
  isLoading: boolean;
  onChangeParams: (tableParams: ITableParams) => void;
  module: PeakModules.Crm | PeakModules.PersonalTrainingCrm;
}

const CampaignsTable: FC<IProps> = ({
  isLoading,
  items,
  totalRows,
  tableParams,
  tableFilterSettings,
  onChangeParams,
  module,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isCrmModule = module === PeakModules.Crm;
  const isPTCrmModule = module === PeakModules.PersonalTrainingCrm;
  let hasCreatePermission = false;
  let hasEditPermission = false;

  const {
    CRM_CAMPAIGN_CREATE,
    CRM_CAMPAIGN_EDIT,
    PT_CRM_CAMPAIGN_CREATE,
    PT_CRM_CAMPAIGN_EDIT,
  } = usePermissionContext();

  if (isCrmModule) {
    hasCreatePermission = CRM_CAMPAIGN_CREATE;
    hasEditPermission = CRM_CAMPAIGN_EDIT;
  } else if (isPTCrmModule) {
    hasCreatePermission = PT_CRM_CAMPAIGN_CREATE;
    hasEditPermission = PT_CRM_CAMPAIGN_EDIT;
  }

  const headerOptions: IHeadCell[] = [
    { id: 'name', label: <FormattedMessage {...tableHeaders.name} />, sort: true },
    { id: 'type', label: <FormattedMessage {...tableHeaders.type} />, sort: true },
    { id: 'script', label: <FormattedMessage {...tableHeaders.script} />, sort: true },
    { id: 'status', label: <FormattedMessage {...tableHeaders.status} />, sort: true },
    { id: 'automatic', label: <FormattedMessage {...commonMessages.automaticLabel} />, sort: true },
  ];

  if (hasEditPermission) {
    headerOptions.push({
      id: 'actions',
      label: <FormattedMessage {...tableHeaders.actions} />,
      sort: false,
      align: 'center',
      padding: 'none',
    });
  }

  const handleEditAction = useCallback(
    (id: string, type: CampaignType) => {
      navigate(`/${location.pathname.split('/')[1]}/campaigns/${id}/edit?type=${type}`);
    },
    [location.pathname, navigate],
  );

  const createRows = (): ITableRow[] => {
    return items
      .map(
        (campaign): ITableRow => {
          const bodyCells: IBodyCell[] = [
            {
              padding: 'default',
              variant: 'subtitle2',
              label: campaign.get('title'),
            },
            {
              label: '-',
              cellComponent: (
                <Typography>{CampaignTypes.translate(campaign.get('campaignType'))}</Typography>
              ),
            },
            {
              label: campaign.getIn(['script', 'title']),
            },
            {
              label: '-',
              cellComponent: <CampaignStatus status={campaign.get('status')} />,
            },
            {
              label: '-',
              cellComponent: <BooleanLabel value={campaign.get('automatic')} />,
            },
          ];

          const bodyCellsMini: IBodyCell[] = [
            {
              label: '-',
              cellComponent: <SmallScreenCell campaign={campaign.toJS()} />,
            },
          ];

          if (hasEditPermission) {
            const actionsMenu = (
              <ActionsMenu horizontal tableActionsMode>
                <ActionItem
                  key="1"
                  onClick={() => handleEditAction(campaign.get('id'), campaign.get('campaignType'))}
                  icon={<EditIcon width={16} height={16} />}
                >
                  <FormattedMessage {...menuItems.edit} />
                </ActionItem>
              </ActionsMenu>
            );

            bodyCells.push({
              label: 'actions',
              align: 'center',
              width: '54px',
              padding: 'none',
              cellComponent: actionsMenu,
            });

            bodyCellsMini.push({
              label: 'actions',
              align: 'center',
              padding: 'none',
              cellComponent: actionsMenu,
            });
          }

          return {
            id: campaign.get('id'),
            cells: bodyCells,
            cellsMini: bodyCellsMini,
          };
        },
      )
      .toJS();
  };

  return (
    <>
      <Table
        title={
          <FormattedMessage {...(isCrmModule ? common.campaigns : ptCrmMessages.ptCampaigns)} />
        }
        addButton={hasCreatePermission ? <AddCampaignDropdown /> : null}
        backRedirectLink={`/${location.pathname.split('/')[1]}`}
        headerOptions={headerOptions}
        showPerPageSelect
        rows={createRows()}
        filters={tableFilterSettings}
        totalRows={totalRows}
        isLoading={isLoading}
        onChangeParams={onChangeParams}
        tableParams={tableParams}
      />
    </>
  );
};

export default CampaignsTable;
