import { useCallback } from 'react';

import { useCheckInHandler } from './useCheckInHandler';
import { useEventsHandler } from './useEventsHandler';
import { useInvoicesHandler } from './useInvoicesHandler';

import { ISocketMessage, SocketMessageType } from 'common/components/SocketListener/interfaces';
import { useInventoriesHandler } from './useInventoriesHandler';
import { useEmailsHandler } from 'common/components/SocketListener/hooks/useEmailsHandler';

export const useSocketsHandler = (clubId: string): ((msg: ISocketMessage<any>) => void) => {
  const checkInHandler = useCheckInHandler(clubId);
  const eventsHandler = useEventsHandler();
  const invoicesHandler = useInvoicesHandler();
  const inventoriesHandler = useInventoriesHandler();
  const emailsHandler = useEmailsHandler();

  return useCallback(
    (msg: ISocketMessage<any>) => {
      switch (msg.type) {
        case SocketMessageType.CHECK_IN:
          return checkInHandler(msg);
        case SocketMessageType.EMAIL:
          return emailsHandler(msg);
        case SocketMessageType.UPDATED_EVENTS:
          return eventsHandler(msg);
        case SocketMessageType.INVOICE_UPDATE:
        case SocketMessageType.INVOICE_REMOVE:
          return invoicesHandler(msg);
        case SocketMessageType.INVENTORY_CLUB_UPDATE:
          return inventoriesHandler(msg);
        default:
          return () => {};
      }
    },
    [checkInHandler, emailsHandler, eventsHandler, inventoriesHandler, invoicesHandler],
  );
};
