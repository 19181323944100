import * as yup from 'yup';

import {
  getDisablePastTimeSchema,
  getFutureOrTodayDateSchema,
} from 'common/validationSchemas/dateSchemas';

import inputErrors from 'common/messages/inputErrors';
import { getRequiredMessage } from 'common/constants/globalConstants';

export const ScheduleTourValidationSchema = yup.object().shape({
  tags: yup.array().of(
    yup.object().shape({
      id: yup.string(),
    }),
  ),
  date: getFutureOrTodayDateSchema('time'),
  time: getDisablePastTimeSchema('date'),
  salesperson: yup
    .mixed()
    .notOneOf(['', null], getRequiredMessage)
    .required(() => inputErrors.requiredFieldError),
  reminderPeriodNumber: yup
    .mixed()
    .nullable()
    .when('reminder', {
      is: reminder => reminder,
      then: yup
        .number()
        .min(1, () => inputErrors.countMinLengthError)
        .max(1000, () => inputErrors.countMaxLengthError),
    }),

  description: yup.string().max(1000, () => inputErrors.descriptionLengthError),
  durationInMinutes: yup.string().required(getRequiredMessage),
});
