import { AudienceFilterType, CameFromType } from 'common/constants';

import { ICampaignForm } from 'modules/crm/interfaces/campaigns';

export const transformCampaignFormValuesToDTO = (formValues): ICampaignForm => {
  const { audienceFilterList } = formValues;

  if (audienceFilterList) {
    const cameFromFilters = audienceFilterList.filter(
      filterItem => filterItem.type === AudienceFilterType.CAME_FROM,
    );

    const otherFilters = audienceFilterList.filter(
      filterItem => filterItem.type !== AudienceFilterType.CAME_FROM,
    );

    const transformedCameFromFilters = cameFromFilters.reduce(
      (list, filterItem) => {
        const {
          cameFromList: { cameFromIndicatorId, cameFromType, cameFromValue, referralMember },
        } = filterItem;

        const cameFromIndicator = { id: cameFromIndicatorId };

        if (cameFromType === CameFromType.NoInput) {
          return {
            ...filterItem,
            cameFromList: [...list.cameFromList, { cameFromIndicator }],
          };
        }

        return {
          ...filterItem,
          cameFromList: [
            ...list.cameFromList,
            {
              cameFromIndicator,
              ...(cameFromType === CameFromType.SingleInput
                ? { values: [{ value: cameFromValue }] }
                : {
                    values:
                      referralMember?.map(member => ({ referralMember: member })) ||
                      cameFromValue?.map(cameFromItem => ({ value: cameFromItem })),
                  }),
            },
          ],
        };
      },
      { type: AudienceFilterType.CAME_FROM, cameFromList: [] },
    );

    return { ...formValues, audienceFilterList: [transformedCameFromFilters, ...otherFilters] };
  }

  return formValues;
};

export const transformCampaignFormValuesFromDTO = (formValues): ICampaignForm => {
  const { audienceFilterList } = formValues;

  const cameFromFilters = audienceFilterList.find(
    filterItem => filterItem.type === AudienceFilterType.CAME_FROM,
  );

  if (cameFromFilters) {
    const otherFilters = audienceFilterList.filter(
      filterItem => filterItem.type !== AudienceFilterType.CAME_FROM,
    );

    const transformedCameFromFilters = cameFromFilters?.cameFromList?.map(cameFromFilter => {
      const {
        cameFromIndicator: { id, type, optional },
        values,
      } = cameFromFilter;

      const baseCameFromDTO = { cameFromIndicatorId: id, cameFromType: type, optional };

      switch (type) {
        case CameFromType.NoInput:
          return {
            id,
            cameFromList: baseCameFromDTO,
            type: AudienceFilterType.CAME_FROM,
          };
        case CameFromType.SingleInput:
          return {
            id,
            cameFromList: { ...baseCameFromDTO, cameFromValue: values[0].value },
            type: AudienceFilterType.CAME_FROM,
          };
        case CameFromType.ReferralList:
          return {
            id,
            cameFromList: {
              ...baseCameFromDTO,
              referralMember: values.map(({ referralMember }) => {
                return {
                  ...referralMember,
                  title: `${referralMember.firstName} ${referralMember.lastName}`,
                };
              }),
            },
            type: AudienceFilterType.CAME_FROM,
          };
        case CameFromType.DropdownSelect:
          return {
            id,
            cameFromList: {
              ...baseCameFromDTO,
              cameFromValue: values.map(({ value }) => value),
            },
            type: AudienceFilterType.CAME_FROM,
          };
        default:
          return cameFromFilter;
      }
    });

    return { ...formValues, audienceFilterList: [...otherFilters, ...transformedCameFromFilters] };
  }

  return formValues;
};
