import { useCallback } from 'react';
import { dispatch as busDispatch } from 'use-bus';

import { actionTypes } from 'common/components/SocketListener/constants';

import { ISocketMessage } from 'common/components/SocketListener/interfaces';

export interface UpdatedEventsSocketDto {
  startDate: Date;
  endDate: Date;
}

export const useEventsHandler = (): ((msg: ISocketMessage<UpdatedEventsSocketDto>) => void) => {
  return useCallback((msg: ISocketMessage<UpdatedEventsSocketDto>) => {
    busDispatch({ type: actionTypes.UPDATED_EVENTS, payload: msg.data });
  }, []);
};
