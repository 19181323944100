import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectCurrentPaymentProcessor,
  selectCurrentUserCorporation,
} from '../../../modules/authentication/state/selectors';
import { PaymentProcessorType } from '../../../modules/corporate-settings/interfaces';
import CommerceHubIFrameModal from './CommerceHubIFrameModal';
import ITransactIFrame from './ITransactIFrame';

interface ITokenizeCreditCardProps {
  memberId: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data) => void;
}

const TokenizeCreditCard = ({
  memberId,
  isOpen,
  onClose,
  onSubmit,
}: ITokenizeCreditCardProps): JSX.Element => {
  const paymentProcessorType = useSelector(selectCurrentPaymentProcessor);
  const currentCorporation = useSelector(selectCurrentUserCorporation);

  const getIFrameComponent = (): JSX.Element => {
    switch (paymentProcessorType) {
      case PaymentProcessorType.COMMERCEHUB:
        // commercehub iFrame
        return (
          <CommerceHubIFrameModal
            memberId={memberId}
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={onSubmit}
          />
        );
      case PaymentProcessorType.ITRANSACT:
        // ITransact iFrame
        return (
          <ITransactIFrame
            memberId={memberId}
            isOpen={isOpen}
            onClose={onClose}
            corpId={currentCorporation.get('id')}
          />
        );
      case PaymentProcessorType.DISABLED:
      default:
        return <></>;
    }
  };
  return getIFrameComponent();
};

export default TokenizeCreditCard;
