import React from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { makeStyles, Theme, Typography, TypographyProps } from '@material-ui/core';

import { Message } from 'common/interfaces/common';

import commonMessages from 'common/messages/messages';

const useStyles = makeStyles((theme: Theme) => ({
  status: {
    '&.isActive': {
      color: theme.palette.success.main,
    },
  },
}));

interface IProps extends TypographyProps {
  isActive: boolean;
  activeOptionMessage?: Message;
  inactiveOptionMessage?: Message;
  inactiveOptionColor?: 'textSecondary' | 'error';
}

const StatusLabel = ({
  isActive,
  activeOptionMessage,
  inactiveOptionMessage,
  inactiveOptionColor = 'textSecondary',
  className,
  children,
  ...other
}: IProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Typography
      variant="body1"
      color={isActive ? 'initial' : inactiveOptionColor}
      className={cx(classes.status, { isActive }, className)}
      component="span"
      {...other}
    >
      {children || (
        <FormattedMessage
          {...(isActive
            ? activeOptionMessage || commonMessages.activeOption
            : inactiveOptionMessage || commonMessages.inactiveOption)}
        />
      )}
    </Typography>
  );
};

export default StatusLabel;
