export const convertHexToRGBA = (hexCode: string, opacity = 1): string => {
  let hex = hexCode.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity})`;
};

// TODO: move
export const appColors = [
  '#66CD00',
  '#C0CA33',
  '#F49B03',
  '#FF2634',
  '#D141FF',
  '#7E57C2',
  '#3F51B5',
  '#2EABFF',
  '#009688',
  '#78909C',
];

export const getRandomColor = (): string => {
  return appColors[Math.floor(Math.random() * appColors.length)];
};

export const getQueueColor = (index: number): string => {
  return appColors[Math.floor(index % appColors.length)];
};
