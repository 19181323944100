import blockIcon from 'img/editor-icons/frame.svg';
import textIcon from 'img/editor-icons/block-text.svg';

export const ExcludedBlocks = [
  // 'sect50',
  // 'sect30',
  'sect37',
  // 'divider',
  'button',
  'image',
  'text-sect',
  'quote',
  'link',
  'video',
  'link-block',
  'grid-items',
  'list-items',
];

export enum GrapesCustomCommands {
  AddImage = 'add-custom-image',
  EditImage = 'edit-custom-image',

  EditVideo = 'edit-video',
  EmbedIframe = 'embed-iframe',

  SearchEmployees = 'search-employees',
  SearchProducts = 'search-products',
  SearchServices = 'search-services',
}

export enum GrapesCustomTypes {
  CustomImage = 'custom-image',
  EmbeddedIframe = 'embedded-iframe',

  EmployeesList = 'employees-list',
  EmployeeItem = 'employee-item',

  ProductsList = 'products-list',
  ProductItem = 'product-item',

  ServicesList = 'services-list',
  ServiceItem = 'service-item',
}

export const EditorBlockViews = {
  sect100: {
    label: `
        <img class="gjs-block-icon" src="${blockIcon}" alt="Add section block" />
        <div class="gjs-block-title">Section</div>
      `,
    attributes: {},
  },
  sect50: {
    label: `
        <img class="gjs-block-icon" src="${blockIcon}" alt="Add section block" />
        <img class="gjs-block-icon" src="${blockIcon}" alt="Add section block" />
        <div class="gjs-block-title">2 Sections</div>
      `,
    attributes: {},
  },
  sect30: {
    label: `
        <img class="gjs-block-icon" src="${blockIcon}" alt="Add section block" />
        <img class="gjs-block-icon" src="${blockIcon}" alt="Add section block" />
        <img class="gjs-block-icon" src="${blockIcon}" alt="Add section block" />
        <div class="gjs-block-title">3 Sections</div>
      `,
    attributes: {},
  },
  text: {
    label: `
        <img class="gjs-block-icon" src="${textIcon}" alt="Add text block" />
        <div class="gjs-block-title">Text</div>
      `,
    attributes: {},
  },
  divider: {
    label: `
        <div class="gjs-block-icon fa fa-minus"></div>
        <div class="gjs-block-title">Divider</div>
      `,
    attributes: {},
  },
};
