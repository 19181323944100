import React, { useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { fromJS, List as ImmutableList } from 'immutable';
import { Grid, Avatar, Typography, Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Controller, useFormContext } from 'react-hook-form';
import MomentAdapter from '@date-io/moment';

// img
import profilePlaceholder from 'img/photo-placeholder.png';
// constants
import { genderTypeLabels } from 'common/constants';
// interfaces
import { PersonSearchParamsType } from 'common/interfaces/http';
import {
  IImmutablePrimaryMemberInfo,
  IPrimaryMemberInfo,
} from 'common/components/PersonProfile/interfaces';
import { ILeadProfile } from 'modules/crm/interfaces/leads';
// components
import {
  AdaptiveDatePicker,
  AvatarCard,
  CreateMemberAutocomplete,
  BarcodeScannerField,
  TooltipTypography,
  Select,
} from 'common/components';
import GuestStatus from 'modules/front-desk/components/GuestStatus/GuestStatus';
import { UploadImageModal, WebcamImageModal } from 'common/modals';
// messages
import inputLabels from 'common/messages/inputLabels';
// common
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { getInitials } from 'helpers/common';
import DeleteImageModal from 'common/modals/DeleteImageModal/DeleteImageModal';
import { convertAttachment } from 'common/utils';
import { TShortProfileFieldInfoImt } from 'modules/corporate-settings/interfaces';
import { RequiredProfileInfo } from 'modules/corporate-settings/constants/requiredFields';
import { IObject } from 'common/interfaces/common';

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    [theme.breakpoints.up('sm')]: {
      flexWrap: 'nowrap',
    },
  },
  photoRow: {
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  datepicker: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 140,
    },
  },
  barcodeHelpText: {
    opacity: 0.5,
  },
  optionInfo: {
    width: 'calc(100% - 40px)',
  },
  avatarWrap: {
    position: 'relative',
    flex: 'none',
    width: 40,
    height: 40,
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.light,
  },
  avatarInitial: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    fontSize: '16px',
    fontWeight: 700,
    color: theme.palette.darkBackground.light,
  },
}));

interface IProps<T> {
  isActiveMembersRestricted?: boolean;
  hideAutocomplete?: boolean;
  selectedMembers?: IObject[];
  getOptionsByValue: (searchString: string, customerSearchSortBy: PersonSearchParamsType) => void;
  setPrimaryInfo?: (person: IImmutablePrimaryMemberInfo) => void;
  persons: T;
  isPersonSearchLoading: boolean;
  clearSearchResults: () => void;
  recommendedFields: TShortProfileFieldInfoImt;
}

const GeneralInfoSection = <T extends ImmutableList<IPrimaryMemberInfo | ILeadProfile>>({
  isActiveMembersRestricted,
  hideAutocomplete,
  selectedMembers,
  setPrimaryInfo,
  getOptionsByValue,
  persons,
  isPersonSearchLoading,
  clearSearchResults,
  recommendedFields,
}: IProps<T>): JSX.Element => {
  const [isAvatarModalOpen, setIsAvatarModalOpen] = useState(false);
  const [isOpenWebcamModal, setIsOpenWebcamModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const renderIntlMessage = useRenderIntlMessage();

  const { control, errors, setValue } = useFormContext();

  const classes = useStyles();

  const onToggleAvatarModal = useCallback(() => setIsAvatarModalOpen(!isAvatarModalOpen), [
    isAvatarModalOpen,
    setIsAvatarModalOpen,
  ]);

  const onToggleWebcamModal = useCallback(() => setIsOpenWebcamModal(!isOpenWebcamModal), [
    isOpenWebcamModal,
    setIsOpenWebcamModal,
  ]);

  const onToggleDeleteModal = useCallback(() => setIsOpenDeleteModal(!isOpenDeleteModal), [
    isOpenDeleteModal,
  ]);

  const renderOption = (person: IPrimaryMemberInfo) => {
    const { firstName, lastName, type, image } = person;
    const initials = getInitials(firstName, lastName);

    return (
      <Grid
        container
        wrap="nowrap"
        spacing={1}
        alignItems="center"
        onClick={() => setPrimaryInfo(fromJS(person))}
      >
        <Grid className={classes.avatarWrap}>
          {initials && !image?.url ? (
            <Box className={classes.avatarInitial}>{initials}</Box>
          ) : (
            <Avatar src={image?.url || profilePlaceholder} alt={firstName} />
          )}
        </Grid>
        <Grid item className={classes.optionInfo}>
          <TooltipTypography
            ellipsized
            variant="subtitle2"
          >{`${firstName} ${lastName}`}</TooltipTypography>
          <GuestStatus type={type} />
        </Grid>
      </Grid>
    );
  };

  const onUploadSubmit = (uploadedImage, isWebcam?: boolean) => {
    setValue('image', convertAttachment(uploadedImage.toJS()));
    (isWebcam ? onToggleWebcamModal : onToggleAvatarModal)();
  };

  const onDeleteSubmit = () => {
    setValue('image', null);
    onToggleDeleteModal();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2} direction="row" className={classes.row}>
          <Grid item className={classes.photoRow}>
            <Grid container justifyContent="center">
              <Grid item>
                <Controller
                  name="image"
                  control={control}
                  render={({ value }) => (
                    <AvatarCard
                      avatarUrl={value?.url}
                      height="164px"
                      onToggleUploadModal={onToggleAvatarModal}
                      onToggleWebcamModal={onToggleWebcamModal}
                      onToggleDeleteModal={onToggleDeleteModal}
                      error={!!errors.image?.message}
                      helperText={renderIntlMessage(errors.image?.message)}
                      recommended={!!recommendedFields.get(RequiredProfileInfo.Photo)}
                    />
                  )}
                />
                {isAvatarModalOpen && (
                  <UploadImageModal
                    id="upload-lead-photo-modal"
                    isOpen={isAvatarModalOpen}
                    onSubmit={onUploadSubmit}
                    onToggle={onToggleAvatarModal}
                  />
                )}
                {isOpenWebcamModal && (
                  <WebcamImageModal
                    id="webcam-lead-photo-modal"
                    isOpen={isOpenWebcamModal}
                    onToggle={onToggleWebcamModal}
                    onSubmit={uploadedImage => onUploadSubmit(uploadedImage, true)}
                  />
                )}

                {isOpenDeleteModal && (
                  <DeleteImageModal
                    isOpen={isOpenDeleteModal}
                    onClose={onToggleDeleteModal}
                    onSubmit={onDeleteSubmit}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ value, onChange, onBlur }) => (
                    <CreateMemberAutocomplete<T>
                      label={<FormattedMessage {...inputLabels.firstName} />}
                      minWidth={176}
                      itemSize={56}
                      fullWidth
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      maxLength={50}
                      freeSolo
                      disableClearable
                      getOptionsByValue={searchString => {
                        getOptionsByValue(searchString, 'FIRST_NAME');
                      }}
                      recommended={false}
                      options={persons}
                      isActiveMembersRestricted={isActiveMembersRestricted}
                      hideAutocomplete={hideAutocomplete}
                      selectedMembers={selectedMembers}
                      renderOption={renderOption}
                      getOptionLabel={(member: IPrimaryMemberInfo | null) => {
                        if (member && member.firstName) {
                          const { firstName } = member;
                          return firstName;
                        }
                        return '';
                      }}
                      loading={isPersonSearchLoading}
                      clearResults={clearSearchResults}
                      error={!!errors.firstName && !!errors.firstName.message}
                      helperText={renderIntlMessage(errors.firstName?.message)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="lastName"
                  render={({ value, onChange, onBlur }) => (
                    <CreateMemberAutocomplete<T>
                      label={<FormattedMessage {...inputLabels.lastName} />}
                      minWidth={176}
                      itemSize={56}
                      fullWidth
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      maxLength={50}
                      freeSolo
                      disableClearable
                      getOptionsByValue={searchString => {
                        getOptionsByValue(searchString, 'LAST_NAME');
                      }}
                      recommended={false}
                      options={persons}
                      isActiveMembersRestricted={isActiveMembersRestricted}
                      hideAutocomplete={hideAutocomplete}
                      selectedMembers={selectedMembers}
                      renderOption={renderOption}
                      getOptionLabel={(member: IPrimaryMemberInfo | null) => {
                        if (member && member.firstName) {
                          const { lastName } = member;
                          return lastName;
                        }
                        return '';
                      }}
                      loading={isPersonSearchLoading}
                      clearResults={clearSearchResults}
                      error={!!errors.lastName && !!errors.lastName.message}
                      helperText={renderIntlMessage(errors.lastName?.message)}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6} sm={12} className={classes.datepicker}>
                <MuiPickersUtilsProvider utils={MomentAdapter}>
                  <Controller
                    name="birthday"
                    control={control}
                    defaultValue={null}
                    render={({ name, value, onChange, onBlur }) => (
                      <AdaptiveDatePicker
                        disableFuture
                        openTo="year"
                        label={<FormattedMessage {...inputLabels.dateOfBirth} />}
                        name={name}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors.birthday}
                        helperText={renderIntlMessage(errors.birthday?.message)}
                        recommended={!!recommendedFields.get(RequiredProfileInfo.Birthday)}
                      />
                    )}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item md={6} sm={12}>
                <Controller
                  control={control}
                  name="gender"
                  render={({ onChange, value, onBlur, name }) => (
                    <Select
                      label={<FormattedMessage {...inputLabels.gender} />}
                      value={value}
                      name={name}
                      fullWidth
                      onBlur={onBlur}
                      onChange={onChange}
                      error={!!errors.gender}
                      helperText={renderIntlMessage(errors.gender?.message)}
                    >
                      {genderTypeLabels.getSelectOptions()}
                    </Select>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <BarcodeScannerField name="barcode" />
          <Box py={1}>
            <Typography className={classes.barcodeHelpText}>
              <FormattedMessage {...inputLabels.barcodeHelpText} />
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default React.memo(GeneralInfoSection) as typeof GeneralInfoSection;
