// libraries
import React from 'react';
import { Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from 'react-hook-form';
// components
import DayTimeAvailabilitySection from './DayTimeAvailabilitySection';
import { IDayTimeAvailabilityDto } from 'common/interfaces/common';
// messages
import { VALID_RANGE_ERROR_TYPE } from 'common/validationSchemas/dayTimeAvailabilitySchema';
import { AvailabilitiesResultBlock } from 'common/components/index';

interface IProps {
  rangesFieldName: string;
  weekdaysFieldName?: string;
  validAvailabilityRange?: IDayTimeAvailabilityDto[];
}

const DayTimeAvailabilitySelector: React.FC<IProps> = ({
  rangesFieldName,
  weekdaysFieldName,
  validAvailabilityRange,
}: IProps): JSX.Element => {
  // form
  const { errors } = useFormContext();

  // renders

  return (
    <Grid container spacing={2}>
      {!!errors && errors[rangesFieldName]?.type === VALID_RANGE_ERROR_TYPE && (
        <Grid item xs={12}>
          <AvailabilitiesResultBlock
            variant="error"
            errorMessage={<FormattedMessage {...errors[rangesFieldName]?.message} />}
            availabilities={validAvailabilityRange}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <DayTimeAvailabilitySection
          rangesFieldName={rangesFieldName}
          weekdaysFieldName={weekdaysFieldName}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(DayTimeAvailabilitySelector);
