import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import messages from 'common/messages/messages';
import invoiceMessages from '../../messages';
import { DialogComponent } from 'common/components/index';
import inputLabels from 'common/messages/inputLabels';
import PaymentField from 'common/components/PaymentField/PaymentField';

interface ITopUpBalanceModal {
  isOpen: boolean;
  handleSubmit: (amountToPay: number) => void;
  onClose: () => void;
}

const TopUpBalanceModal = (props: ITopUpBalanceModal): JSX.Element => {
  const { isOpen, onClose, handleSubmit } = props;

  const [amountToPay, setAmountToPay] = useState(null);

  return (
    <DialogComponent
      size="xs"
      title={<FormattedMessage {...invoiceMessages.accountCredit} />}
      submitBtnTitle={<FormattedMessage {...messages.addBtn} />}
      isOpen={isOpen}
      onSubmit={() => handleSubmit(amountToPay)}
      onClose={onClose}
      disableFullScreenBehavior
    >
      <PaymentField
        fullWidth
        variant="outlined"
        onChange={setAmountToPay}
        onBlur={setAmountToPay}
        label={<FormattedMessage {...inputLabels.amountToPay} />}
      />
    </DialogComponent>
  );
};

export default TopUpBalanceModal;
