import React from 'react';
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps,
  createFilterOptions,
} from '@material-ui/lab';
import { Chip, SvgIcon } from '@material-ui/core';

import { CloseTag } from 'img/icons';
import { deepClone } from 'common/utils';
import { ITagOption } from 'common/interfaces/common';
import { TextField } from 'common/components/index';

interface ITaggedAutocompleteProps {
  label: string | JSX.Element;
  fullWidth: boolean;

  expandable?: boolean;
  newOptionLabel?: string | JSX.Element;

  onChange?: (values: Array<ITagOption>) => void;

  autocompleteProps?: Omit<AutocompleteProps<ITagOption, any, any, any>, 'renderInput'>;

  error?: boolean;
  recommended?: boolean;
  helperText?: string | JSX.Element;
}

const filter = createFilterOptions<ITagOption>();

const TaggedAutocomplete = ({
  label,
  fullWidth,
  error,
  helperText,
  onChange,
  autocompleteProps,
  recommended,
}: ITaggedAutocompleteProps): JSX.Element => {
  // handlers

  const handleOnChange = (event, values) => {
    const updatedValues = deepClone(values);

    values.forEach((val, index) => {
      if (typeof val === 'string') {
        updatedValues[index] = { title: val };
      } else if (val && val.inputValue) {
        updatedValues[index] = { title: val.inputValue };
      }
    });

    onChange(updatedValues);
  };

  const handleOptionsFiltering = (options, params) => {
    const filtered = filter(options, params);

    if (params.inputValue !== '') {
      filtered.push({
        inputValue: params.inputValue,
        title: `Add "${params.inputValue}"`,
      });
    }

    return filtered;
  };

  // renders

  const renderInputComponent = params => (
    <TextField
      {...params}
      label={label}
      variant="outlined"
      fullWidth={fullWidth}
      error={error}
      helperText={helperText}
      recommended={recommended}
    />
  );

  return (
    <MuiAutocomplete<ITagOption, any, any, any>
      {...autocompleteProps}
      getOptionLabel={option => (option ? option.title : '')}
      getOptionSelected={(option, value) => option.title === value.title}
      onChange={handleOnChange}
      renderInput={renderInputComponent}
      filterOptions={handleOptionsFiltering}
      renderTags={(tagValue: { id?: string; title: string }[], getTagProps: any) =>
        tagValue.map(({ title }, index: number) => (
          <Chip
            label={title}
            deleteIcon={<SvgIcon component={CloseTag} />}
            {...getTagProps({ index })}
          />
        ))
      }
    />
  );
};

export default TaggedAutocomplete;
