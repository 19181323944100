import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import messages from 'common/components/PersonProfile/messages';
import inputLabels from 'common/messages/inputLabels';
import commonMessages from 'common/messages/messages';
import { DialogComponent, FieldInfo, LoadingBackdrop } from 'common/components';
import { ITicketDetailedDtoImt } from 'common/components/PersonProfile/interfaces';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';
import { ticketStatuses, topicTypeLabels } from 'common/components/PersonProfile/constants';
import CommentBlock from './CommentBlock';
import { CustomTheme } from 'common/ui/interfaces';

interface ITicketViewModalProps {
  isOpen: boolean;
  onClose: () => void;
  ticket: ITicketDetailedDtoImt;
  isLoading: boolean;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  divider: {
    margin: theme.spacing(2, 0),
    backgroundColor: theme.palette.borderColor.main,
  },
}));

const TicketViewModal = (props: ITicketViewModalProps): JSX.Element => {
  const { isOpen, onClose, ticket, isLoading } = props;

  const [timezoneMoment] = useTimezoneMoment();

  const classes = useStyles();

  const comments = ticket?.get('comments');

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={onClose}
      title={
        isLoading ? (
          <FormattedMessage {...commonMessages.loading} />
        ) : (
          `#${ticket?.get('id')} ${ticket?.get('subject')}`
        )
      }
      size="lg"
      submitBtn={null}
      cancelBtn={null}
    >
      {isLoading ? (
        <LoadingBackdrop isLoading={isLoading} />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FieldInfo
                label={<FormattedMessage {...inputLabels.submitted} />}
                description={`${timezoneMoment(ticket?.get('openedDate')).format(
                  DEFAULT_DATE_TIME_FORMAT,
                )}`}
              />
            </Grid>
            <Grid item xs={4}>
              <FieldInfo
                label={<FormattedMessage {...inputLabels.requester} />}
                description={ticket?.get('requester')}
              />
            </Grid>
            <Grid item xs={4}>
              <FieldInfo
                label={<FormattedMessage {...messages.assignee} />}
                description={ticket?.get('assignee')}
              />
            </Grid>
            <Grid item xs={4}>
              <FieldInfo
                label={<FormattedMessage {...inputLabels.group} />}
                description={ticket?.get('group')}
              />
            </Grid>
            <Grid item xs={4}>
              <FieldInfo
                label={<FormattedMessage {...inputLabels.topic} />}
                description={
                  ticket?.get('topic') ? (
                    topicTypeLabels.translate(ticket?.get('topic'))
                  ) : (
                    <FormattedMessage {...inputLabels.unknown} />
                  )
                }
              />
            </Grid>
            <Grid item xs={4}>
              <FieldInfo
                label={<FormattedMessage {...inputLabels.workedBy} />}
                description={
                  ticket?.get('workedBy') || <FormattedMessage {...inputLabels.unknown} />
                }
              />
            </Grid>
            <Grid item xs={4}>
              <FieldInfo
                label={<FormattedMessage {...inputLabels.status} />}
                description={ticketStatuses.translate(ticket?.get('status'))}
              />
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          {comments?.map((comment, index, arr) => {
            return (
              <CommentBlock key={comment.get('id')} comment={comment} index={arr.size - index} />
            );
          })}
        </>
      )}
    </DialogComponent>
  );
};

export default TicketViewModal;
