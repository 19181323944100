import React from 'react';
import { List as ImmutableList } from 'immutable';
import {
  Box,
  ListItem,
  makeStyles,
  Paper,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import cx from 'classnames';

import { ICheckInImt } from 'modules/front-desk/interfaces';
import { IAlertSettingsImt } from 'common/interfaces/alerts';

import { hasCelebrationAlert, hasErrorAlert, hasWarningAlert } from 'common/utils';
import { getInitials } from 'helpers/common';
import { DEFAULT_DATE_FORMAT } from 'common/constants/dateFormats';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';

import { TimeFromCheckIn } from 'modules/kid-zone/components';
import ProfileAvatar from '../ProfileAvatar/ProfileAvatar';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
    cursor: 'pointer',
    paddingBottom: '6px',
    '&:last-of-type': {
      paddingBottom: 0,
    },
  },
  itemPaper: {
    borderRadius: '3px',
    width: '100%',
    display: 'flex',
    overflow: 'hidden',

    '&:hover': {
      '&:not(.itemActive)': {
        boxShadow: '0 6px 12px 0 rgba(0,0,0,0.16)',
      },
    },
  },
  itemActive: {
    '& .itemAvatar': {
      minWidth: '52px',
      height: '52px',
    },
  },
  itemAvatar: {
    minWidth: '52px',
    height: '52px',
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: theme.spacing(1),
  },
}));

interface IProps {
  checkIn: ICheckInImt;
  isActive: boolean;
  onClick: (personId: string) => void;
  alertsList: ImmutableList<IAlertSettingsImt>;
}

const KidCheckInItem = ({ checkIn, onClick, isActive, alertsList }: IProps): JSX.Element => {
  const [timezoneMoment] = useTimezoneMoment();

  const classes = useStyles();
  const theme = useTheme();

  const alerts = checkIn.get('alerts')?.toJS();
  const initials = getInitials(checkIn.get('firstName'), checkIn.get('lastName'));

  const getItemColor = (): string => {
    if (!isActive) {
      return theme.palette.background.paper;
    }

    if (hasErrorAlert(alertsList, alerts)) {
      return theme.palette.error.main;
    }

    if (hasWarningAlert(alertsList, alerts)) {
      return theme.palette.warning.main;
    }

    if (hasCelebrationAlert(alerts)) {
      return theme.palette.celebration?.main || '';
    }

    return theme.palette.primary.main;
  };

  // handlers

  const handleOnClick = (): void => {
    onClick(checkIn.get('personId'));
  };

  const paperClassName = cx(
    classes.itemPaper,
    isActive && classes.itemActive,
    isActive && 'itemActive',
  );

  return (
    <ListItem className={classes.root} onClick={handleOnClick}>
      <Paper
        className={paperClassName}
        style={{ background: getItemColor(), borderColor: getItemColor() }}
      >
        <ProfileAvatar
          avatarUrl={checkIn.get('imageUrl')}
          className={`${classes.itemAvatar} itemAvatar`}
          initials={initials}
        />

        <Box className={classes.info} flexDirection="row">
          <Typography variant="h5">
            {checkIn.get('firstName')} {checkIn.get('lastName')}
          </Typography>

          <Tooltip title={timezoneMoment(checkIn.get('checkInTime')).format(DEFAULT_DATE_FORMAT)}>
            <TimeFromCheckIn checkIn={checkIn} />
          </Tooltip>
        </Box>
      </Paper>
    </ListItem>
  );
};

export default KidCheckInItem;
