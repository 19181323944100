export function throttle(fn: any, wait: number) {
  let isCalled = false;

  // eslint-disable-next-line func-names
  return function(...args) {
    if (!isCalled) {
      fn(...args);
      isCalled = true;
      setTimeout(() => {
        isCalled = false;
      }, wait);
    }
  };
}
