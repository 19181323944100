import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { formatNumberToPrice } from 'common/utils';

import { CreditCardFeeUnit, CreditCardFeeUnitsSymbols } from 'modules/services/constants/packages';

import { FieldInfo } from 'common/components/index';

import servicesMessages from 'modules/services/messages/messages';
import { ICreditCardServiceFee } from 'modules/services/interfaces/packages';

interface IProps {
  creditCardServiceFee: ICreditCardServiceFee;
}

const CreditCardFeeSectionView = ({ creditCardServiceFee }: IProps): JSX.Element => {
  const { value: creditCardFeeValue, unit: creditCardFeeUnit } = creditCardServiceFee;

  const renderIntlMessage = useRenderIntlMessage();

  return (
    <>
      <Grid item xs={12}>
        <Typography component="p" variant="h5">
          <FormattedMessage {...servicesMessages.creditCardServiceFeeEnabledLabel} />
        </Typography>
      </Grid>

      <Grid item xs={4}>
        <FieldInfo
          label={<FormattedMessage {...servicesMessages.creditCardServiceFeeValueLabel} />}
          description={
            creditCardFeeUnit === CreditCardFeeUnit.FLAT
              ? `$${formatNumberToPrice(creditCardFeeValue)}`
              : `${creditCardFeeValue}${renderIntlMessage(
                  CreditCardFeeUnitsSymbols.message(creditCardFeeUnit),
                )}`
          }
        />
      </Grid>

      {/* <Grid item xs={4}>
        <FieldInfo
          label={<FormattedMessage {...servicesMessages.creditCardFeeUnitLabel} />}
          description={CreditCardFeeUnitsSymbols.translate(creditCardFeeUnit)}
        />
      </Grid> */}
    </>
  );
};

export default CreditCardFeeSectionView;
