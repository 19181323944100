// Libraries
import thunk from 'redux-thunk';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

// Glacial
import rootReducer from 'store/reducers';
import { ImmutableObject } from 'common/state/interfaces';

export type AppStoreType = ReturnType<typeof rootReducer>;
export type AppStoreTypeImt = ImmutableObject<AppStoreType>;

const configureStore = () => {
  const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./reducers', () => {
        store.replaceReducer(rootReducer);
      });
    }
  }

  return store;
};

const store = configureStore();

export type AppDispatch = typeof store.dispatch;

export default store;
