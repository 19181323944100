import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';

// state
import * as contractSelectors from 'common/state/newPerson/contract/selectors';
import {
  selectIsContractHTMLLoading,
  selectSignContractActionResult,
} from 'common/state/newPerson/contract/selectors';
import * as actions from 'common/state/newPerson/contract/actions';
import {
  printContract,
  setSignContractActionResult,
} from 'common/state/newPerson/contract/actions';
import * as waiverActions from 'common/state/newPerson/waiver/actions';
import { setSignWaiversActionResult } from 'common/state/newPerson/waiver/actions';
import { selectMemberId } from 'common/state/newPerson/primaryInfo/selectors';
import { selectContactInfo } from 'common/state/newPerson/contactInfo/selectors';
import { getContactInfoThunk } from 'common/state/newPerson/contactInfo/actions';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { selectCreatedLead } from 'modules/crm/state/leads/selectors';
import {
  selectMainPanelPersonId,
  selectRightPanelPersonId,
} from 'modules/front-desk/state/selectors';
import {
  fetchSenderAvailabilityThunk,
  resetBookingEvents,
} from 'modules/booking/state/senderAvailability/actions';
// interfaces
import { IAttachment } from 'common/interfaces/uploadFile';
import { DocumentType } from 'common/components/PersonProfile/interfaces';
import { IPersonDocumentToSignDto } from 'common/interfaces/contract';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { ILeadProfileImt } from 'modules/crm/interfaces/leads';
// constants
import { SignType } from 'common/constants/contract';
import { PeakModules } from 'common/constants/peakModules';
import { ActionResult } from 'common/constants';
import { SenderAvailabilityTypeList } from 'modules/booking/constants/senderAvailability';
import { ENotificationType } from 'modules/booking/constants/notificationType';
// components
import { SignDocumentStep } from 'common/components';
// messages
import commonFileErrorMessages from 'common/messages/fileErrors';
// hooks
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { useAppDispatch } from 'store/hooks';
// helpers
import { closeSigWebTablet } from 'helpers';
// common
import { StepContext } from 'common/createContext/stepContext';
import { ServerError } from 'common/errors/serverErrors';
import {
  selectSetSignWaiversActionResult,
  selectWaiversIsLoading,
} from 'common/state/newPerson/waiver/selectors';
import { useCombinePrintLoading } from 'common/hooks/useCombinePrintLoading';
import { printDocument } from 'common/components/PersonProfile/state/actions';
import { WaiverStatus } from 'modules/crm/constants/leads';
import isFormDirty from 'common/hooks/isFormDirty';
import useDiscardChangesContext from 'common/hooks/useDiscardChangesContext';
import DiscardChangesModalProvider from 'common/modals/DiscardChangesModal/DiscardChangesModalProvider';

interface ISignContractStepContainerProps {
  module: PeakModuleForNewPersonType;
  docId?: string;
  type?: DocumentType;
  title?: string;
}

interface ISignContractStepFormValues {
  signType: SignType;
  phone?: string;
  email?: string;
  files: IAttachment[];
}

const useStyles = makeStyles(() => ({
  signContractStepForm: {
    height: 'calc(100% - 109px)',
  },
}));

const SignContractStepContainer = ({
  module,
  docId,
  type,
  title,
}: ISignContractStepContainerProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const [isSignedContractData, setIsSignedContractData] = useState<{ [key: string]: boolean }>({});

  const rightPanelPersonId = useSelector(selectRightPanelPersonId);
  const mainPanelPersonId = useSelector(selectMainPanelPersonId);
  const contract = useSelector(contractSelectors.selectContract);
  const contactInfo = useSelector(selectContactInfo);
  const isLoadingContract = useSelector(contractSelectors.selectContractIsLoading);
  const memberProfileId = useSelector(selectMemberId);
  const signContractActionResult = useSelector(selectSignContractActionResult);
  const signWaiversActionResult = useSelector(selectSetSignWaiversActionResult);
  const isLoadingWaiver = useSelector(selectWaiversIsLoading);
  const isContractHTMLLoading = useSelector(selectIsContractHTMLLoading);
  const leadPrimaryInfo: ILeadProfileImt = useSelector(selectCreatedLead);

  const isCrmOrPTCrmModule =
    module === PeakModules.Crm ||
    module === PeakModules.PersonalTrainingCrm ||
    module === PeakModules.Members;
  const personId = isCrmOrPTCrmModule
    ? leadPrimaryInfo.get('id')
    : rightPanelPersonId || memberProfileId || mainPanelPersonId;

  const renderIntlMessage = useRenderIntlMessage();
  const { onNext } = useContext(StepContext);
  const dcContext = useDiscardChangesContext();
  const classes = useStyles();

  const formMethods = useForm({
    defaultValues: {
      email: null,
      phone: null,
      files: [],
      signType: SignType.ViaTopaz,
    },
    shouldUnregister: false,
    mode: 'onBlur',
  });

  const { handleSubmit, setValue, formState } = formMethods;
  const isDirty = isFormDirty(formState);

  useEffect(() => {
    const { email, phones = [] } = contactInfo;

    if (email) {
      setValue('email', email);
    }

    if (phones[0]) {
      setValue('phone', phones[0].phoneId);
    }
  }, [setValue, contactInfo]);

  useEffect(() => {
    if (docId) {
      dispatch(actions.getPersonDocumentById(personId, docId, module));
    } else {
      dispatch(actions.getPersonContractThunk(personId, module));
    }

    dispatch(getContactInfoThunk(personId, module));
  }, [dispatch, personId, module, docId]);

  useEffect(() => {
    if (signContractActionResult === ActionResult.SUCCESS_ACTION) {
      onNext();
      dispatch(setSignContractActionResult(null));
    }

    if (signWaiversActionResult === ActionResult.SUCCESS_ACTION) {
      onNext();
      dispatch(setSignWaiversActionResult(null));
    }
  }, [contract, onNext, signContractActionResult, signWaiversActionResult, dispatch]);

  useEffect(() => {
    return () => {
      closeSigWebTablet({ shouldCallReject: false });
    };
  }, []);

  const onSubmitForm = (values: ISignContractStepFormValues) => {
    const { setError } = formMethods;
    const { signature, documentId, status } = contract;
    const { files, signType, email, phone } = values;
    let sendingData: IPersonDocumentToSignDto;

    const defaultSendingData = {
      attachments: [],
      documentId,
      signType,
      signed: true,
    };

    if (email && signType === SignType.SendViaEmail) {
      sendingData = {
        ...defaultSendingData,
        email,
      };
    }

    if (phone && signType === SignType.SendViaSMS) {
      sendingData = {
        ...defaultSendingData,
        phoneId: phone,
      };
    }

    if (signType === SignType.InPerson) {
      if (files.length) {
        sendingData = {
          ...defaultSendingData,
          attachments: files,
        };
      } else {
        setError('files', { message: renderIntlMessage(commonFileErrorMessages.fileCountError) });
      }
    }

    if (signature?.id && signType === SignType.ViaTopaz) {
      sendingData = {
        ...defaultSendingData,
        signature,
      };
    }

    if (status === WaiverStatus.NO_SIGN) {
      sendingData = defaultSendingData;
    }

    if (sendingData) {
      dispatch(
        !type || type === DocumentType.Contract
          ? actions.signContractThunk(personId, sendingData, module)
          : waiverActions.signWaiversThunk(personId, [sendingData], module),
      );
    } else {
      dispatch(
        enqueueErrorNotification({
          codes: [ServerError.UNSIGNED_DOCUMENT_ERROR],
          message: undefined,
        }),
      );
    }
  };

  const applyContractSignature = (file: File, documentId: string) => {
    dispatch(
      !type || type === DocumentType.Contract
        ? actions.applyContractSignatureThunk(personId, documentId, file, module)
        : waiverActions.applyWaiverThunk(personId, documentId, file, module),
    );
  };

  const getEventContract = useCallback((): ENotificationType => {
    switch (module) {
      case PeakModules.Crm:
        return ENotificationType.CRM_LEAD_CONTRACT_SENDING;
      case PeakModules.PersonalTrainingCrm:
        return ENotificationType.PT_CRM_LEAD_CONTRACT_SENDING;
      case PeakModules.FrontDesk:
        return ENotificationType.FRONTDESK_CONTRACT_SENDING;
      default:
        return ENotificationType.MEMBER_CONTRACT_SENDING;
    }
  }, [module]);

  useEffect(() => {
    dispatch(
      fetchSenderAvailabilityThunk([SenderAvailabilityTypeList.DOCUMENTS], {
        module,
        events: [getEventContract()],
      }),
    );
    return () => {
      dispatch(resetBookingEvents({ type: SenderAvailabilityTypeList.DOCUMENTS }));
    };
  }, [dispatch, getEventContract, module]);

  const isContractHTMLPrinting = useCombinePrintLoading(isContractHTMLLoading);

  const onPrintContract = () => {
    if (docId) {
      dispatch(printDocument(personId, docId, module));
    } else {
      dispatch(printContract(personId, module));
    }
  };

  const isLoading = isLoadingWaiver || isLoadingContract || isContractHTMLPrinting;

  return (
    <>
      <FormProvider {...formMethods}>
        <form
          className={classes.signContractStepForm}
          id="contract-form"
          onSubmit={handleSubmit(onSubmitForm)}
          autoComplete="none"
        >
          <SignDocumentStep
            title={title}
            contactInfo={contactInfo}
            contract={contract}
            onPrint={onPrintContract}
            isLoading={isLoading}
            fetchApplySignature={applyContractSignature}
            isSignedDocument={Boolean(isSignedContractData[contract.documentId])}
            setIsSignedDocumentData={setIsSignedContractData}
          />
        </form>
      </FormProvider>

      {dcContext && isDirty ? <DiscardChangesModalProvider {...dcContext} /> : null}
    </>
  );
};

export default SignContractStepContainer;
