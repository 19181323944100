import React from 'react';

// interfaces
import {
  IBookingEventImt,
  IEventAction,
  IRemindAppointmentDto,
  PersonAppointmentStatus,
} from 'modules/booking/interfaces';
// components
import { Loader } from 'common/components';
import EventDetails from './EventDetails';
// constants
import { PeakModules } from 'common/constants/peakModules';
import { SenderAvailabilityTypeList } from 'modules/booking/constants/senderAvailability';
import { EShortNotificationType } from 'modules/booking/constants/notificationType';
import { ICancelParticipationFormValues } from 'common/components/PersonProfile/components/Appointments/ParticipationModal/ParticipationModal';

interface IProps {
  event?: IBookingEventImt;
  appointmentStatus?: PersonAppointmentStatus;
  isEventDetailsLoading?: boolean;
  isEventActionLoading?: boolean;
  onEdit?: (event: IBookingEventImt) => void;
  onCancel?: (formValues: IEventAction) => void;
  onClose?: () => void;
  onDelete?: (formValues: IEventAction) => void;
  onRemind?: (formValues: IRemindAppointmentDto) => void;
  onRestore?: (event: IBookingEventImt) => void;
  onCancelParticipation?: (formData: ICancelParticipationFormValues) => void;
  onConfirmParticipation?: (formData: ICancelParticipationFormValues) => void;
  onJoinClass?: (formData: ICancelParticipationFormValues) => void;
  handleOverflow?: (value) => void;
  readOnly?: boolean;
  module: PeakModules;
  onDuplicate?: (isDuplicateParticipants: boolean) => void;
  type: SenderAvailabilityTypeList;
  onLoadSenderAvailability?: (
    type: SenderAvailabilityTypeList,
    shortTypeEvent: EShortNotificationType,
  ) => void;
}

const EventDetailsContainer = ({
  module,
  readOnly,
  handleOverflow,
  event,
  isEventDetailsLoading,
  isEventActionLoading,
  onEdit,
  onClose,
  onCancel,
  onDelete,
  onRestore,
  onDuplicate,
  onRemind,
  type,
  appointmentStatus,
  onCancelParticipation,
  onConfirmParticipation,
  onLoadSenderAvailability,
  onJoinClass,
}: IProps): JSX.Element => (
  <>
    {!!isEventDetailsLoading && <Loader />}
    <EventDetails
      module={module}
      event={event}
      onEdit={onEdit}
      onClose={onClose}
      onDelete={onDelete}
      onCancel={onCancel}
      appointmentStatus={appointmentStatus}
      onCancelParticipation={onCancelParticipation}
      onConfirmParticipation={onConfirmParticipation}
      onRestore={onRestore}
      onDuplicate={onDuplicate}
      onRemind={onRemind}
      handleOverflow={handleOverflow}
      readOnly={readOnly}
      isEventActionLoading={isEventActionLoading}
      type={type}
      onLoadSenderAvailability={onLoadSenderAvailability}
      onJoinClass={onJoinClass}
    />
  </>
);

export default EventDetailsContainer;
