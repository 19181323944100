import { styled } from '@material-ui/core/styles';
import { TableCell } from '@material-ui/core';

const EmptyTableCell = styled(TableCell)({
  padding: 0,
  height: 12,
  width: 12,
  minWidth: 12,
  border: 'none',
});

export default EmptyTableCell;
