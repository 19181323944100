import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, FormControlLabel, makeStyles, Typography } from '@material-ui/core';

import { formatNumberToPrice } from 'common/utils';

import { PastDueActions } from 'common/components/PersonProfile/constants';

import { CartUnitTypes, IInvoiceDetailsImt, IInvoiceUnitDTO } from 'common/interfaces/invoices';
import { AlertTypes } from 'common/interfaces/alerts';

import { Alert, Checkbox, DialogComponent } from 'common/components/index';

import commonMessages from 'common/messages/messages';
import posKioskMessages from 'modules/pos-kiosk/messages/messages';
import invoiceMessages from 'common/components/InvoiceOperating/messages';

const useStyles = makeStyles(() => ({
  checkboxLabel: {
    '&.MuiFormControlLabel-root': {
      width: '100%',
    },
    '& .MuiFormControlLabel-label': {
      display: 'flex',
      justifyContent: 'space-between',
      flex: 1,
    },
  },
}));

interface IProps {
  isOpen: boolean;
  onSubmit: (invoiceUnitIds: Array<string>) => void;
  onClose: () => void;
  selectedInvoice: IInvoiceDetailsImt;
}

const ChangeRegisterModal = ({
  isOpen,
  onSubmit,
  onClose,
  selectedInvoice,
}: IProps): JSX.Element => {
  const [selectedUnitIds, setSelectedUnitIds] = useState<Array<string>>([]);
  const [isAllUnitsSelected, setIsAllUnitsSelected] = useState<boolean>(false);

  const classes = useStyles();

  const transformedInvoice = useMemo(() => selectedInvoice?.toJS(), [selectedInvoice]);

  const handleSelectAllUnits = (isAllSelected: boolean) => {
    setIsAllUnitsSelected(isAllSelected);

    if (isAllSelected) {
      const unitIds = transformedInvoice.invoiceUnits.map(invoiceUnit => invoiceUnit.id);

      setSelectedUnitIds(unitIds);
    } else {
      setSelectedUnitIds([]);
    }
  };

  const handleSelectUnit = (unitId: string) => {
    let selectedUnits = [...selectedUnitIds];

    if (selectedUnitIds.includes(unitId)) {
      selectedUnits = selectedUnits.filter(invoiceUnitId => invoiceUnitId !== unitId);
    } else {
      selectedUnits = [...selectedUnitIds, unitId];
    }

    setSelectedUnitIds(selectedUnits);

    if (!selectedUnits?.length && isAllUnitsSelected) {
      setIsAllUnitsSelected(false);
    }

    if (selectedUnits.length === transformedInvoice.invoiceUnits.length && !isAllUnitsSelected) {
      setIsAllUnitsSelected(true);
    }
  };

  const handleSubmitForm = () => {
    onSubmit(selectedUnitIds);
  };

  const getUnitLabel = (invoiceUnit: IInvoiceUnitDTO): JSX.Element | string => {
    const { type } = invoiceUnit;

    switch (type) {
      case CartUnitTypes.TOP_UP_BALANCE: {
        const { topUpBalance } = invoiceUnit;

        return (
          <>
            <Typography component="span" variant="h5">
              <FormattedMessage {...invoiceMessages.accountCredit} />
            </Typography>

            <Typography component="span" variant="h5">
              {`$${formatNumberToPrice(topUpBalance.amount)}`}
            </Typography>
          </>
        );
      }
      case CartUnitTypes.INVENTORY: {
        const { inventory } = invoiceUnit;

        return (
          <>
            <Typography component="span" variant="h5">
              {`${inventory.number} x ${inventory.title}`}
            </Typography>

            <Typography component="span" variant="h5">
              {`$${formatNumberToPrice(inventory.price)}`}
            </Typography>
          </>
        );
      }
      case CartUnitTypes.REACTIVATE_MEMBERSHIP:
      case CartUnitTypes.CANCEL_MEMBERSHIP:
      case CartUnitTypes.MEMBERSHIP_BUNDLE:
      case CartUnitTypes.SERVICE_BUNDLE: {
        const { bundle } = invoiceUnit;

        return (
          <>
            <Typography component="span" variant="h5">
              {bundle.title}
            </Typography>

            <Typography component="span" variant="h5">
              {`$${formatNumberToPrice(bundle.amountToPay)}`}
            </Typography>
          </>
        );
      }
      case CartUnitTypes.BILLING_SCHEDULE: {
        const { billingSchedule } = invoiceUnit;

        return (
          <>
            <Typography component="span" variant="h5">
              {billingSchedule.packageInfo.title}
            </Typography>

            <Typography component="span" variant="h5">
              {`$${formatNumberToPrice(billingSchedule.amount)}`}
            </Typography>
          </>
        );
      }
      case CartUnitTypes.PAST_DUE: {
        const { pastDue, pastDueResolve } = invoiceUnit;

        const isPastDueWaived = pastDueResolve === PastDueActions.Waive;

        return (
          <>
            <Typography component="span" variant="h5">
              {pastDue.package.title}
            </Typography>

            <Typography component="span" variant="h5">
              {`$${formatNumberToPrice(isPastDueWaived ? 0 : pastDue.amount)}`}
            </Typography>
          </>
        );
      }
      default:
        return null;
    }
  };

  return (
    <DialogComponent
      isOpen={isOpen}
      onSubmit={handleSubmitForm}
      onClose={onClose}
      title={<FormattedMessage {...commonMessages.changeRegisterBtn} />}
      submitBtnTitle={<FormattedMessage {...commonMessages.changeBtn} />}
      size="xs"
    >
      <>
        <Alert
          title={
            <FormattedMessage
              {...posKioskMessages.changeRegisterText}
              values={{ invoiceNumber: selectedInvoice?.get('number') }}
            />
          }
          severity={AlertTypes.Warning}
        />

        {!!transformedInvoice.invoiceUnits.length && (
          <>
            <Box mt={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    indeterminate={
                      isAllUnitsSelected &&
                      transformedInvoice.invoiceUnits.length !== selectedUnitIds.length
                    }
                    checked={isAllUnitsSelected}
                    onChange={e => {
                      handleSelectAllUnits(e.target.checked);
                    }}
                  />
                }
                label={<FormattedMessage {...commonMessages.allOption} />}
                className={classes.checkboxLabel}
              />
            </Box>

            {!!transformedInvoice?.invoiceUnits?.length &&
              transformedInvoice.invoiceUnits.map(invoiceUnit => (
                <Box marginY={1} key={invoiceUnit.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={selectedUnitIds.includes(invoiceUnit.id)}
                        onChange={() => handleSelectUnit(invoiceUnit.id)}
                      />
                    }
                    label={getUnitLabel(invoiceUnit)}
                    className={classes.checkboxLabel}
                  />
                </Box>
              ))}
          </>
        )}
      </>
    </DialogComponent>
  );
};

export default ChangeRegisterModal;
