import React from 'react';
import cx from 'classnames';

import { makeStyles, Typography } from '@material-ui/core';
import { CustomerStatus, CustomerStatuses } from 'modules/front-desk/constants/common/constants';
import { Variant } from '@material-ui/core/styles/createTypography';
import { CustomTheme } from 'common/ui/interfaces';

interface Props {
  type: string;
  color?: string;
  variant?: Variant | 'srOnly';
  className?: string;
}

const typeStyles: Map<string, string> = new Map([
  [CustomerStatus.ActiveMember, 'activeUser'],
  [CustomerStatus.Guest, 'guestUser'],
  [CustomerStatus.FrozenMember, 'frozenMember'],
  [CustomerStatus.ExpiredMember, 'expiredMember'],
  [CustomerStatus.DeletedMember, 'deletedMember'],
]);
const defaultTypeStyle = 'allUsers';

const useStyles = makeStyles((theme: CustomTheme) => {
  return {
    guestStatus: {
      marginTop: theme.spacing(0.375),
      '&.activeUser': {
        color: theme.palette.info.main,
      },
      '&.guestUser': {
        color: theme.palette.warning.main,
      },
      '&.allUsers': {
        color: theme.palette.text.primary,
      },
      '&.frozenMember': {
        color: theme.palette.celebration.main,
      },
      '&.expiredMember': {
        color: theme.palette.error.main,
      },
      '&.deletedMember': {
        color: theme.palette.darkBackground.light,
      },
    },
  };
});

const GuestStatus: React.FC<Props> = ({ type, color, variant, className }: Props) => {
  const classes = useStyles();

  const status = CustomerStatuses.find(type);

  const clientTextClass = typeStyles.get(status?.value) || defaultTypeStyle;

  return (
    <Typography
      className={cx(clientTextClass, classes.guestStatus, 'guest-status', className)}
      style={{ color }}
      component="span"
      variant={variant || 'body2'}
    >
      {CustomerStatuses.translate(type) || '-'}
    </Typography>
  );
};

export default GuestStatus;
