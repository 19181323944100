import * as yup from 'yup';

import { CameFromType } from 'common/constants';
import { requiredMessage } from 'common/constants/globalConstants';

export const cameFromFieldsSchema = {
  cameFromValue: yup
    .string()
    .nullable()
    .when(['optional', 'cameFromType', 'cameFromIndicatorId'], {
      is: (optional, type, id) =>
        !!id && !optional && type !== CameFromType.NoInput && type !== CameFromType.ReferralList,
      then: yup
        .string()
        .nullable()
        .required(requiredMessage),
    }),
  cameFromIndicatorId: yup
    .string()
    .nullable()
    .required(requiredMessage),
  referralMember: yup
    .object()
    .nullable()
    .when(['optional', 'cameFromType', 'cameFromIndicatorId'], {
      is: (optional, type, id) => !!id && type === CameFromType.ReferralList && !optional,
      then: yup
        .object()
        .nullable()
        .required(requiredMessage),
    }),
};
