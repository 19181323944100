import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';

import * as IDictionaryItem from 'common/interfaces/dictionary';
import { IPageMeta } from 'common/interfaces/pagination';
import { IDictionaryCommonItem } from 'modules/dictionaries/interfaces/interfaces';
import { INamedEntity, INamedEntityImt } from 'common/interfaces/common';
import { IAccessHoursItem } from 'modules/dictionaries/interfaces/accessHours';

import { DictionaryItem, DictionaryList } from 'common/constants/dictionaryConstants';
import {
  FETCH_DICTIONARY_ITEM_LOADING,
  FETCH_DICTIONARY_ITEM_SUCCESS,
  FETCH_DICTIONARY_LIST_LOADING,
  FETCH_DICTIONARY_LIST_SUCCESS,
  RESET_DICTIONARY_ITEM,
  RESET_DICTIONARY_LIST,
} from 'common/constants/actionTypes';

export type FetchDictionaryListLoadingPayload = {
  dictionary: DictionaryList;
  isLoading: boolean;
};

type FetchDictionaryListLoadingAction = {
  type: typeof FETCH_DICTIONARY_LIST_LOADING;
  payload: FetchDictionaryListLoadingPayload;
};

export type ResetDictionaryListPayload = {
  dictionary: DictionaryList;
};

export type FetchDictionaryListSuccessPayload =
  | {
      dictionary: DictionaryList.RECEIPTS;
      list: IDictionaryItem.IRecieptsListItem[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.REGISTERS;
      list: IDictionaryItem.IBarcodesListItem[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.CAMPAIGNS;
      list: IDictionaryItem.TCampaignsDictionaryImt[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.REPORTS_MEMBERS;
      list: INamedEntityImt[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.REPORTS_INVENTORIES;
      list: INamedEntityImt[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.REPORTS_SERVICES;
      list: INamedEntityImt[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.REPORTS_EMPLOYEES;
      list: INamedEntityImt[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.REVENUE_CODES;
      list: IDictionaryItem.IRevenueCodesListItem[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.CLUBS;
      list: IDictionaryItem.TClubListItem[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.SERVICES;
      list: IDictionaryItem.IServiceTagListItem[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.EMPLOYEES;
      list: IDictionaryItem.IEmployeeListItem[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.CRM_TEMPLATE_ITEMS;
      list: IDictionaryItem.IEmployeeListItem[] | IDictionaryItem.IServiceListItem[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.SALESPERSONS;
      list: IDictionaryItem.ISalesPersonListItem[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.MEMBERS;
      list: IDictionaryItem.IMemberListItem[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.RESOURCE_TAGS;
      list: IDictionaryItem.IResourceListItem[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.EVENT_TAGS;
      list: IDictionaryItem.IEventListItem[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.DOCUMENT_TEMPLATES;
      list: IDictionaryItem.TDocumentTemplateListItem[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.TAGS;
      list: IDictionaryItem.ITagListItem[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.ALLERGIES;
      list: IDictionaryItem.TAllergyListItem[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.INVENTORY_CATEGORIES;
      list: IDictionaryItem.IInventoryCategoriesListItem[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.VENDORS;
      list: IDictionaryItem.IVendorsListItem[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.TAXES;
      list: IDictionaryItem.ITagListItem[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.FALLTHROUGH_REASONS;
      list: IDictionaryItem.IFallthroughReasonListItem[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.PARTICIPANTS;
      list: IDictionaryItem.IParticipantListItem[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.CAME_FROM;
      list: IDictionaryItem.ICameFromIndicatorDictionaryItem[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.DEPARTMENTS;
      list: IDictionaryCommonItem[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.TIMECLOCK_DEPARTMENTS;
      list: IDictionaryCommonItem[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.TIMECLOCK_EMPLOYEES;
      list: IDictionaryCommonItem[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.MESSAGING_CAMPAIGN_PHONE;
      list: INamedEntity[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.MESSAGING_CAMPAIGN_EMAIL;
      list: INamedEntity[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.MESSAGING_CAMPAIGN_SMS;
      list: INamedEntity[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.RESOURCES;
      list: INamedEntity[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.INSURANCE_COMPANY;
      list: IDictionaryCommonItem[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.TIME_ACCESS;
      list: IAccessHoursItem[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.ADDITIONAL_FIELDS;
      list: IDictionaryCommonItem[];
      meta?: IPageMeta;
    };

type FetchDictionaryListSuccessAction = {
  type: typeof FETCH_DICTIONARY_LIST_SUCCESS;
  payload: FetchDictionaryListSuccessPayload;
};

type ResetDictionaryListAction = {
  type: typeof RESET_DICTIONARY_LIST;
  payload: ResetDictionaryListPayload;
};

type DictionaryListsActions =
  | FetchDictionaryListLoadingAction
  | FetchDictionaryListSuccessAction
  | ResetDictionaryListAction;

const initDictionaryList = {
  list: [],
  meta: {
    page: 0,
    perPage: 25,
    total: 0,
  },
  isLoading: false,
};

const initDictionaryListsStateImt = fromJS({
  [DictionaryList.RECEIPTS]: initDictionaryList,
  [DictionaryList.REGISTERS]: initDictionaryList,
  [DictionaryList.CAMPAIGNS]: initDictionaryList,
  [DictionaryList.REPORTS_INVENTORIES]: initDictionaryList,
  [DictionaryList.REPORTS_MEMBERS]: initDictionaryList,
  [DictionaryList.REPORTS_SERVICES]: initDictionaryList,
  [DictionaryList.REPORTS_EMPLOYEES]: initDictionaryList,
  [DictionaryList.REVENUE_CODES]: initDictionaryList,
  [DictionaryList.CLUBS]: initDictionaryList,
  [DictionaryList.EMPLOYEES]: initDictionaryList,
  [DictionaryList.CRM_TEMPLATE_ITEMS]: initDictionaryList,
  [DictionaryList.SALESPERSONS]: initDictionaryList,
  [DictionaryList.MEMBERS]: initDictionaryList,
  [DictionaryList.RESOURCE_TAGS]: initDictionaryList,
  [DictionaryList.EVENT_TAGS]: initDictionaryList,
  [DictionaryList.TAGS]: initDictionaryList,
  [DictionaryList.ALLERGIES]: initDictionaryList,
  [DictionaryList.SERVICES]: initDictionaryList,
  [DictionaryList.DOCUMENT_TEMPLATES]: initDictionaryList,
  [DictionaryList.INVENTORY_CATEGORIES]: initDictionaryList,
  [DictionaryList.VENDORS]: initDictionaryList,
  [DictionaryList.TAXES]: initDictionaryList,
  [DictionaryList.STATES]: initDictionaryList,
  [DictionaryList.COUNTRIES]: initDictionaryList,
  [DictionaryList.FALLTHROUGH_REASONS]: initDictionaryList,
  [DictionaryList.PARTICIPANTS]: initDictionaryList,
  [DictionaryList.CAME_FROM]: initDictionaryList,
  [DictionaryList.DEPARTMENTS]: initDictionaryList,
  [DictionaryList.MESSAGING_CAMPAIGN_SMS]: initDictionaryList,
  [DictionaryList.MESSAGING_CAMPAIGN_PHONE]: initDictionaryList,
  [DictionaryList.MESSAGING_CAMPAIGN_EMAIL]: initDictionaryList,
  [DictionaryList.RESOURCES]: initDictionaryList,
  [DictionaryList.INSURANCE_COMPANY]: initDictionaryList,
  [DictionaryList.TIME_ACCESS]: initDictionaryList,
  [DictionaryList.ADDITIONAL_FIELDS]: initDictionaryList,
});

export type InitDictionaryListsStateImt = typeof initDictionaryListsStateImt;

export const dictionaryListsReducer = (
  state = initDictionaryListsStateImt,
  action: DictionaryListsActions,
): InitDictionaryListsStateImt => {
  switch (action.type) {
    case FETCH_DICTIONARY_LIST_LOADING:
      const { isLoading } = action.payload;

      return state.setIn([action.payload.dictionary, 'isLoading'], isLoading);

    case FETCH_DICTIONARY_LIST_SUCCESS:
      const { list, meta } = action.payload;

      return meta && meta.page > 0
        ? state
            .updateIn([action.payload.dictionary, 'list'], dictionaryList =>
              dictionaryList.concat(fromJS(list)),
            )
            .setIn([action.payload.dictionary, 'meta'], fromJS(meta))
            .setIn([action.payload.dictionary, 'isLoading'], false)
        : state
            .setIn([action.payload.dictionary, 'list'], fromJS(list))
            .setIn([action.payload.dictionary, 'meta'], fromJS(meta))
            .setIn([action.payload.dictionary, 'isLoading'], false);

    case RESET_DICTIONARY_LIST:
      return state.set(action.payload.dictionary, fromJS(initDictionaryList));
    default:
      return state;
  }
};

// Dictionary item
export type FetchDictionaryItemSuccessPayload =
  | {
      dictionary: DictionaryItem.CAMPAIGN_PHONE;
      item: IDictionaryItem.CrmCampaignDto;
    }
  | {
      dictionary: DictionaryItem.CAMPAIGN_EMAIL;
      item: IDictionaryItem.CrmCampaignDto;
    }
  | {
      dictionary: DictionaryItem.CAMPAIGN_SMS;
      item: IDictionaryItem.CrmCampaignDto;
    };

export type FetchDictionaryItemLoadingPayload = {
  dictionary: DictionaryItem;
  isLoading: boolean;
};

export type ResetDictionaryItemPayload = {
  dictionary: DictionaryItem;
};

type FetchDictionaryItemLoadingAction = {
  type: typeof FETCH_DICTIONARY_ITEM_LOADING;
  payload: FetchDictionaryItemLoadingPayload;
};

type FetchDictionaryItemSuccessAction = {
  type: typeof FETCH_DICTIONARY_ITEM_SUCCESS;
  payload: FetchDictionaryItemSuccessPayload;
};

type ResetDictionaryItemAction = {
  type: typeof RESET_DICTIONARY_ITEM;
  payload: ResetDictionaryItemPayload;
};

type DictionaryItemActions =
  | FetchDictionaryItemLoadingAction
  | FetchDictionaryItemSuccessAction
  | ResetDictionaryItemAction;

const initDictionaryItem = {
  item: {},
  isLoading: false,
};

const initDictionaryItemStateImt = fromJS({
  [DictionaryItem.CAMPAIGN_PHONE]: initDictionaryItem,
  [DictionaryItem.CAMPAIGN_SMS]: initDictionaryItem,
  [DictionaryItem.CAMPAIGN_EMAIL]: initDictionaryItem,
});

export type InitDictionaryItemStateImt = typeof initDictionaryItemStateImt;

export const dictionaryItemsReducer = (
  state = initDictionaryItemStateImt,
  action: DictionaryItemActions,
): InitDictionaryItemStateImt => {
  switch (action.type) {
    case FETCH_DICTIONARY_ITEM_LOADING:
      const { isLoading } = action.payload;

      return state.setIn([action.payload.dictionary, 'isLoading'], isLoading);
    case FETCH_DICTIONARY_ITEM_SUCCESS:
      const { item } = action.payload;

      return state.setIn([action.payload.dictionary, 'item'], fromJS(item));
    case RESET_DICTIONARY_ITEM:
      return state.set(action.payload.dictionary, fromJS(initDictionaryItem));
    default:
      return state;
  }
};

export default combineReducers({
  dictionaryLists: dictionaryListsReducer,
  dictionaryItems: dictionaryItemsReducer,
});
