import React from 'react';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import commonMessages from 'common/messages/messages';
import messages from 'common/components/EmployeeProfile/messages';
import { IEmployeeProfileDtoImt } from 'common/interfaces/employee';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { DEFAULT_DATE_FORMAT } from 'common/constants/dateFormats';

interface IBasicInfoProps {
  profile: IEmployeeProfileDtoImt;
}

const useStyles = makeStyles((theme: Theme) => ({
  profileName: {
    paddingBottom: theme.spacing(2),
  },
  generalInfoTitle: {
    paddingRight: theme.spacing(3),
  },
}));

const BasicInfo = (props: IBasicInfoProps): JSX.Element => {
  const { profile } = props;

  const [timezoneMoment] = useTimezoneMoment();
  const renderIntlMessage = useRenderIntlMessage();
  const classes = useStyles();

  const preferredName = profile.get('preferredName');

  let profileName = `${profile.get('firstName')} ${profile.get('lastName')} ${
    preferredName ? `(${preferredName})` : ''
  }`;

  profileName = preferredName ? profileName : profileName.trim();

  return (
    <>
      <Typography className={classes.profileName} variant="h3">
        {profileName}
      </Typography>
      <Box pb={2} display="flex" flexWrap="wrap">
        <Box pr={3}>
          <Typography variant="body1" color={profile.get('active') ? 'primary' : 'textSecondary'}>
            {profile.get('active') ? (
              <FormattedMessage {...commonMessages.activeStatus} />
            ) : (
              <FormattedMessage {...commonMessages.inactiveStatus} />
            )}
          </Typography>
        </Box>

        {profile.get('employeeId') && (
          <Typography component="p" color="textSecondary" className={classes.generalInfoTitle}>
            <FormattedMessage
              {...messages.employeeId}
              values={{ employeeId: profile.get('employeeId') }}
            />
          </Typography>
        )}

        {profile.get('userId') && (
          <Typography component="p" color="textSecondary" className={classes.generalInfoTitle}>
            <FormattedMessage
              {...commonMessages.userId}
              values={{ userId: profile.get('userId') }}
            />
          </Typography>
        )}

        <Typography component="p" color="textSecondary" className={classes.generalInfoTitle}>
          <FormattedMessage
            {...messages.hired}
            values={{ hired: timezoneMoment(profile.get('hireDate')).format(DEFAULT_DATE_FORMAT) }}
          />
        </Typography>

        {profile.get('terminationDate') && (
          <Typography component="p" color="textSecondary" className={classes.generalInfoTitle}>
            <FormattedMessage
              {...messages.term}
              values={{
                term: timezoneMoment(profile.get('terminationDate') || undefined).format(
                  DEFAULT_DATE_FORMAT,
                ),
              }}
            />
          </Typography>
        )}

        <Typography component="p" color="textSecondary" className={classes.generalInfoTitle}>
          <FormattedMessage
            {...messages.exemptions}
            values={{
              exemptions:
                profile.get('exemptionsNumber') ?? renderIntlMessage(commonMessages.noOption),
            }}
          />
        </Typography>

        <Typography component="p" color="textSecondary" className={classes.generalInfoTitle}>
          <FormattedMessage
            {...messages.withholding}
            values={{ withholding: `$${profile.get('federalWithholding')?.toFixed(2) || '0.00'}` }}
          />
        </Typography>

        <Typography component="p" color="textSecondary" className={classes.generalInfoTitle}>
          <FormattedMessage
            {...messages.discount}
            values={{ discount: `${profile.get('posDiscountPercentage') ?? 0}%` }}
          />
        </Typography>
      </Box>
    </>
  );
};

export default BasicInfo;
