import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Typography } from '@material-ui/core';

import { ReactComponent as LockClosedIcon } from 'img/icons/lock-closed.svg';
import { UnavailableModule } from 'common/components';

import messages from 'modules/kid-zone/messages';

const useStyles = makeStyles(() => ({
  label: {
    opacity: 0.5,
  },
}));

const KidZoneNotConfigured = (): JSX.Element => {
  const classes = useStyles();

  return (
    <UnavailableModule
      lockIcon={<LockClosedIcon />}
      title={<FormattedMessage {...messages.kidZoneNotConfiguredTitle} />}
      description={
        <Typography className={classes.label}>
          <FormattedMessage {...messages.kidZoneNotConfiguredLabel} />
        </Typography>
      }
    />
  );
};

export default KidZoneNotConfigured;
