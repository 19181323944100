import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from 'modules/crm/messages/leads';
import commonMessages from 'common/messages/messages';
import { ConstOptions } from 'common/constants/classes';
import { LeadStatus } from 'common/constants/personConstants';

export const actionTypes = {
  FETCH_LEADS_LIST_LOADING: 'FETCH_LEADS_LIST_LOADING',
  FETCH_LEADS_LIST_SUCCESS: 'FETCH_LEADS_LIST_SUCCESS',

  CREATE_LEAD_PROFILE_LOADING: 'CREATE_LEAD_PROFILE_LOADING',
  CREATE_LEAD_PROFILE_SUCCESS: 'CREATE_LEAD_PROFILE_SUCCESS',
  CREATE_LEAD_PROFILE_ACTION_RESULT: 'CREATE_LEAD_PROFILE_ACTION_RESULT',

  UPDATE_LEAD_PROFILE_LOADING: 'UPDATE_LEAD_PROFILE_LOADING',

  FETCH_LEAD_LOADING: 'FETCH_LEAD_LOADING',
  FETCH_LEAD_SUCCESS: 'FETCH_LEAD_SUCCESS',
  RESET_LEAD: 'RESET_LEAD',
  LEAD_ACTION_RESULT: 'LEAD_ACTION_RESULT',
  RESET_LEAD_ACTION_RESULT: 'RESET_LEAD_ACTION_RESULT',

  FETCH_LEAD_PROFILE_SALES_INFO_LOADING: 'FETCH_LEAD_PROFILE_SALES_INFO_LOADING',
  FETCH_LEAD_PROFILE_SALES_INFO_SUCCESS: 'FETCH_LEAD_PROFILE_SALES_INFO_SUCCESS',
  RESET_LEAD_PROFILE_SALES_INFO: 'RESET_LEAD_PROFILE_SALES_INFO',

  SALESPERSON_LEAD_ASSIGN_LOADING: 'SALESPERSON_LEAD_ASSIGN_LOADING',
  SALESPERSON_LEAD_ASSIGN_SUCCESS: 'SALESPERSON_LEAD_ASSIGN_SUCCESS',

  LEAD_STATUS_CHANGE_LOADING: 'LEAD_STATUS_CHANGE_LOADING',
  LEAD_STATUS_CHANGE_SUCCESS: 'LEAD_STATUS_CHANGE_SUCCESS',

  RESET_LEADS_ACTION_RESULT: 'RESET_LEADS_ACTION_RESULT',

  UPDATE_LEAD_AVATAR: 'UPDATE_LEAD_AVATAR',
  UPDATE_LEAD_AVATAR_LOADING: 'UPDATE_LEAD_AVATAR_LOADING',

  GET_LEAD_ACTIVITIES: 'GET_LEAD_ACTIVITIES',
  GET_LEAD_ACTIVITIES_LOADING: 'GET_LEAD_ACTIVITIES_LOADING',
  CREATE_LEAD_ACTIVITY_LOADING: 'CREATE_LEAD_ACTIVITY_LOADING',
  CREATE_LEAD_ACTIVITY_RESULT: 'CREATE_LEAD_ACTIVITY_RESULT',
};

export const leadStatusesArray = [
  {
    key: LeadStatus.Cold,
    value: LeadStatus.Cold,
    message: messages.leadStatusTypeCold,
    color: '#448aff',
  },
  {
    key: LeadStatus.Interested,
    value: LeadStatus.Interested,
    message: messages.leadStatusTypeInterested,
    color: '#ff6d00',
  },
  {
    key: LeadStatus.Active,
    value: LeadStatus.Active,
    message: messages.leadStatusTypeActive,
    color: '#66cd00',
  },
  {
    key: LeadStatus.ActiveTrialExpired,
    value: LeadStatus.ActiveTrialExpired,
    color: '#ff2634',
    message: messages.leadStatusTypeActiveTrialExpired,
  },
  {
    key: LeadStatus.Inactive,
    value: LeadStatus.Inactive,
    message: messages.leadStatusTypeInactive,
    color: '#858585',
  },
  {
    key: LeadStatus.Closed,
    value: LeadStatus.Closed,
    message: messages.leadStatusTypeClosed,
    color: '#d141ff',
  },
  {
    key: LeadStatus.Dead,
    value: LeadStatus.Dead,
    message: messages.leadStatusTypeDead,
    color: '#3D7690',
  },
  {
    key: LeadStatus.Empty,
    value: LeadStatus.Empty,
    message: messages.leadStatusTypeEmpty,
    color: '',
  },
];

export const tourEventColor = '#CE3532';

export const LeadStatuses = new ConstOptions(
  leadStatusesArray.filter(status => status.key !== LeadStatus.Empty),
);

export const MemberCrmStatuses = new ConstOptions(leadStatusesArray);

export enum SalesType {
  WalkIn = 'WALK_IN',
  Phone = 'PHONE',
  Outreach = 'OUTREACH',
}

export const SalesTypesOptions = [
  {
    key: SalesType.WalkIn,
    value: SalesType.WalkIn,
    label: 'Walk In',
    message: messages.leadSalesTypeWalkIn,
  },
  {
    key: SalesType.Phone,
    value: SalesType.Phone,
    label: 'Phone',
    message: messages.leadSalesTypePhone,
  },
  {
    key: SalesType.Outreach,
    value: SalesType.Outreach,
    label: 'Outreach',
    message: messages.leadSalesTypeOutreach,
  },
];

export const SalesTypes = new ConstOptions([
  {
    key: SalesType.WalkIn,
    value: SalesType.WalkIn,
    message: messages.leadSalesTypeWalkIn,
  },
  {
    key: SalesType.Phone,
    value: SalesType.Phone,
    message: messages.leadSalesTypePhone,
  },
  {
    key: SalesType.Outreach,
    value: SalesType.Outreach,
    message: messages.leadSalesTypeOutreach,
  },
]);

export enum NewLeadSteps {
  PrimaryInfo = 'PRIMARY_INFO',
  TrialMembership = 'TRIAL_MEMBERSHIP',
  AdditionalInfo = 'ADDITIONAL_INFO',
  Payment = 'PAYMENT',
  FamilyMembers = 'FAMILY_MEMBERS',
  SignWaivers = 'SIGN_WAIVERS',
  SignContract = 'SIGN_CONTRACT',
  ScheduleTour = 'SCHEDULE_TOUR',
}

export const Channels = [
  {
    key: 'ONLINE_V2',
    label: 'Online through V2',
    value: 'ONLINE_THROUGH_V_2',
  },
  {
    key: 'APP',
    label: 'App (member referral)',
    value: 'APP_MEMBER_REFERRAL',
  },
  {
    key: 'JOIN_ONLINE',
    label: 'Join online (SignUp not completed)',
    value: 'JOIN_ONLINE_SIGN_UP_NOT_COMPLETED',
  },
  {
    key: 'GUEST_FEATURE',
    label: 'Guest feature',
    value: 'GUEST_FEATURE',
  },
  {
    key: 'POS',
    label: 'Via POS module',
    value: 'VIA_POS_MODULE',
  },
];

export enum LeadChannels {
  ONLINE_V2 = 'Online through V2',
  APP = 'App (member referral)',
  JOIN_ONLINE = 'Join online (SignUp not completed)',
  GUEST_FEATURE = 'Guest feature',
  VIA_POS = 'via POS module',
}

export enum LeadValue {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export const LeadValueOptions = new ConstOptions([
  { key: LeadValue.LOW, value: LeadValue.LOW, message: { id: '$', defaultMessage: '$' } },
  { key: LeadValue.MEDIUM, value: LeadValue.MEDIUM, message: { id: '$$', defaultMessage: '$$' } },
  { key: LeadValue.HIGH, value: LeadValue.HIGH, message: { id: '$$$', defaultMessage: '$$$' } },
]);

export enum Communications {
  ANY = 'ANY',
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  PHONE = 'PHONE_CALLS',
  NOTIFICATIONS = 'NOTIFICATION',
}

export const CommunicationsTypes = new ConstOptions([
  { key: Communications.ANY, value: Communications.ANY, message: messages.leadCommunicationsAny },
  {
    key: Communications.EMAIL,
    value: Communications.EMAIL,
    message: messages.leadCommunicationsEmail,
  },
  { key: Communications.SMS, value: Communications.SMS, message: commonMessages.smsLabel },
  {
    key: Communications.PHONE,
    value: Communications.PHONE,
    message: messages.leadCommunicationsPhoneCalls,
  },
  {
    key: Communications.NOTIFICATIONS,
    value: Communications.NOTIFICATIONS,
    message: messages.leadCommunicationsNotifications,
  },
]);

export enum WaiverStatus {
  UNSIGNED = 'UNSIGNED',
  SIGNED = 'SIGNED',
  NO_SIGN = 'NO_SIGN',
}

export const NewLeadStepsLabels = {
  [NewLeadSteps.PrimaryInfo]: <FormattedMessage {...messages.newLeadPrimaryInfoStepTitle} />,
  [NewLeadSteps.TrialMembership]: (
    <FormattedMessage {...messages.newLeadTrialMembershipStepTitle} />
  ),
  [NewLeadSteps.AdditionalInfo]: <FormattedMessage {...messages.newLeadAdditionalInfoStepTitle} />,
  [NewLeadSteps.Payment]: <FormattedMessage {...messages.newLeadPaymentStepTitle} />,
  [NewLeadSteps.FamilyMembers]: <FormattedMessage {...messages.newLeadFamilyMembersStepTitle} />,
  [NewLeadSteps.SignWaivers]: <FormattedMessage {...messages.newLeadSignWaiversStepTitle} />,
  [NewLeadSteps.SignContract]: <FormattedMessage {...messages.newLeadSignContractStepTitle} />,
  [NewLeadSteps.ScheduleTour]: <FormattedMessage {...messages.newLeadScheduleTourStepTitle} />,
};

export const ChangeMembershipStepsLabels = {
  ...NewLeadStepsLabels,
  [NewLeadSteps.TrialMembership]: <FormattedMessage {...messages.changeMembershipStepTitle} />,
};
export const WaiverStatusLabels = {
  [WaiverStatus.UNSIGNED]: <FormattedMessage {...messages.newLeadSignWaiversStepUnsigned} />,
  [WaiverStatus.SIGNED]: <FormattedMessage {...messages.newLeadSignWaiversStepSigned} />,
};
