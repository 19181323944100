import React from 'react';
import MaskedInput from 'react-text-mask';

import { getZipCodeMask } from 'common/utils/mask';

interface TextMaskCustomProps {
  inputRef: (reference: HTMLInputElement | null) => void;
  value: string;
}

const ZipCodeMaskedInput = ({ inputRef, ...other }: TextMaskCustomProps) => (
  <MaskedInput
    {...other}
    ref={(reference: any) => {
      inputRef(reference ? reference.inputElement : null);
    }}
    mask={value => getZipCodeMask(value)}
    placeholderChar={'\u2000'}
    guide={false}
    showMask
  />
);

export default ZipCodeMaskedInput;
