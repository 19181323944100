export const actionTypes = {
  // Campaigns report
  FETCH_DICTIONARY_LOADING: 'DICTIONARIES/FETCH_DICTIONARY_LOADING',
  FETCH_DICTIONARY: 'DICTIONARIES/FETCH_DICTIONARY',
  RESET_DICTIONARY: 'DICTIONARIES/RESET_DICTIONARY',

  // Dictionary item
  FETCH_DICTIONARY_ITEM_LOADING: 'DICTIONARIES/FETCH_DICTIONARY_ITEM_LOADING',
  FETCH_DICTIONARY_ITEM_SUCCESS: 'DICTIONARIES/FETCH_DICTIONARY_ITEM_SUCCESS',
  RESET_DICTIONARY_ITEM_SUCCESS: 'DICTIONARIES/RESET_DICTIONARY_ITEM_SUCCESS',
  CREATE_DICTIONARY_LIST_ITEM_SUCCESS: 'DICTIONARIES/CREATE_DICTIONARY_LIST_ITEM_SUCCESS',
  EDIT_DICTIONARY_LIST_ITEM_SUCCESS: 'DICTIONARIES/EDIT_DICTIONARY_LIST_ITEM_SUCCESS',
  EDIT_DICTIONARY_LIST_ITEM_LOADING: 'DICTIONARIES/EDIT_DICTIONARY_LIST_ITEM_LOADING',
  CREATE_DICTIONARY_LIST_ITEM_LOADING: 'DICTIONARIES/CREATE_DICTIONARY_LIST_ITEM_LOADING',
  RESET_DICTIONARY_ITEM: 'DICTIONARIES/RESET_DICTIONARY_ITEM',
};
