// libraries
import React, { useCallback, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box } from '@material-ui/core';
// services
import TokenService from 'services/auth/TokenService';
// selectors
import { selectIsMemberPortalUserLoggedIn } from 'memberPortalModules/authentication/state/selectors';
// actions
import { memberPortalLogOut } from 'memberPortalModules/authentication/state/actions';
// hooks
import { useAppDispatch } from 'store/hooks';
import useZendeskWidget from 'common/hooks/useZendeskWidget';
// constants
import { SIGN_IN_PATH } from 'common/constants/globalConstants';
import { NavigationContext } from 'common/createContext/navigationContext';
import { fetchInvoiceUnitAmountThunk } from 'memberPortalModules/MemberProfile/state/invoice/actions';
import {
  selectMemberProfile,
  selectMemberProfileLoading,
} from 'memberPortalModules/MemberProfile/state/profile/selectors';
import { fetchProfileInfoView } from 'memberPortalModules/MemberProfile/state/profile/actions';
import {
  selectInvoiceUnitCount,
  selectInvoiceUnitCountLoading,
} from 'memberPortalModules/MemberProfile/state/invoice/selectors';

interface ICustomRouteProps {
  secure?: boolean;
  publicAccess?: boolean;
  hideNavigation?: boolean;
  children: React.ReactElement;
}

const CustomRoute = ({
  secure,
  publicAccess,
  hideNavigation,
  children,
}: ICustomRouteProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const { toggleShowNavigation } = useContext(NavigationContext);

  // state
  const isUserLoggedIn: boolean = useSelector(selectIsMemberPortalUserLoggedIn);
  const isExistProfile = useSelector(selectMemberProfile);
  const isProfileLoading: boolean = useSelector(selectMemberProfileLoading);
  const invoiceUnitAmount = useSelector(selectInvoiceUnitCount);
  const isInvoiceUnitAmountLoading: boolean = useSelector(selectInvoiceUnitCountLoading);

  const routePreActions = useCallback(() => {
    const refreshToken = TokenService.getRefreshToken();
    const isRefreshExpired = TokenService.isTokenExpired(refreshToken);

    if (isRefreshExpired && isUserLoggedIn) {
      dispatch(memberPortalLogOut());
    }

    if (secure && !publicAccess && !isUserLoggedIn) {
      // TODO: Need to add reset role
      navigate(SIGN_IN_PATH);
    }

    if (secure && isUserLoggedIn && !isRefreshExpired) {
      if (!isExistProfile && !isProfileLoading) {
        dispatch(fetchProfileInfoView());
      }

      if (invoiceUnitAmount === null && !isInvoiceUnitAmountLoading) {
        dispatch(fetchInvoiceUnitAmountThunk());
      }
    }
  }, [
    isUserLoggedIn,
    secure,
    publicAccess,
    dispatch,
    navigate,
    isExistProfile,
    isProfileLoading,
    invoiceUnitAmount,
    isInvoiceUnitAmountLoading,
  ]);

  useEffect(() => {
    routePreActions();

    if (hideNavigation) {
      toggleShowNavigation(false);
    }
  }, [hideNavigation, routePreActions, toggleShowNavigation]);

  useEffect(() => {
    if (secure && !isUserLoggedIn && !publicAccess) {
      // TODO: Need to add reset role
      navigate(SIGN_IN_PATH);
    }
  }, [isUserLoggedIn, navigate, secure, publicAccess]);

  useZendeskWidget();

  return <Box height="100%">{isUserLoggedIn && children}</Box>;
};

export default CustomRoute;
