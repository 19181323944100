export const actionTypes = {
  FETCH_PERSON_PAYMENTS: 'MEMBER_PORTAL/FETCH_PERSON_PAYMENTS',
  FETCH_PERSON_PAYMENTS_LOADING: 'MEMBER_PORTAL/FETCH_PERSON_PAYMENTS_LOADING',
  FETCH_RECENT_PERSON_PAYMENTS: 'MEMBER_PORTAL/FETCH_RECENT_PERSON_PAYMENTS',
  FETCH_RECENT_PERSON_PAYMENTS_LOADING: 'MEMBER_PORTAL/FETCH_RECENT_PERSON_PAYMENTS_LOADING',
  FETCH_PAYMENT_DETAILS: 'MEMBER_PORTAL/FETCH_PAYMENT_DETAILS',
  FETCH_PAYMENT_DETAILS_LOADING: 'MEMBER_PORTAL/FETCH_PAYMENT_DETAILS_LOADING',
  VOID_PERSON_INVOICE_ACTION_RESULT: 'MEMBER_PORTAL/VOID_PERSON_INVOICE_ACTION_RESULT',
  VOID_PERSON_INVOICE_LOADING: 'MEMBER_PORTAL/VOID_PERSON_INVOICE_LOADING',
};
