import React from 'react';
import { Avatar, Grid, SvgIcon, Typography, Button } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import cx from 'classnames';

import { ISalesPerson } from 'modules/crm/interfaces/leads';
import { SpyIcon } from 'img/icons';
import { ReactComponent as BuildingIcon } from 'img/icons/sidebar/building.svg';
import SalespersonName from '../SalespersonName/SalespersonName';
import MemberStatusView from 'common/components/MemberStatusView/MemberStatusView';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      paddingTop: '3px',
    },
    avatar: {
      fontSize: '0.75rem',
      width: '24px',
      height: '24px',
    },
    btn: {
      padding: '2px 6px 2px 0',
      textTransform: 'none',
    },
    labelIcon: {
      margin: '0 4px',
      fontSize: '1rem',
      opacity: '0.7',
    },
    labelText: {
      fontSize: '0.75rem',
      opacity: '0.7',
    },
    labelTextName: {
      whiteSpace: 'nowrap',
    },
    caption: {
      display: 'block',
      fontSize: '0.65rem',
      lineHeight: '1.30rem',
      color: 'rgba(50, 50, 50, 0.5)',
    },
  }),
);

interface IProps {
  firstName: string;
  lastName: string;
  photoUrl: string;
  status: string;
  salesperson: ISalesPerson;
  onLeadClick: () => void;
}

const SmallScreenCell = (props: IProps) => {
  const classes = useStyles();
  const { firstName, lastName, photoUrl, status, salesperson, onLeadClick } = props;

  return (
    <Grid className={classes.root} container spacing={2} direction="column">
      <Grid item container spacing={1} alignItems="center">
        <Grid item>
          <Avatar className={classes.avatar} alt={firstName} src={photoUrl} />
        </Grid>
        <Grid item>
          <Button className={classes.btn} onClick={onLeadClick}>
            <Typography variant="subtitle2" noWrap>{`${firstName} ${lastName}`}</Typography>
          </Button>
        </Grid>
        <Grid item>
          <MemberStatusView status={status} />
        </Grid>
      </Grid>

      <Grid item>
        <Grid container spacing={1} wrap="nowrap">
          <Grid item>
            <SvgIcon className={classes.labelIcon}>
              <BuildingIcon />
            </SvgIcon>
          </Grid>

          <Grid item>{/* <ClubInfo className={classes.labelText} club={club} /> */}</Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container spacing={1} wrap="nowrap">
          <Grid item>
            <SvgIcon className={classes.labelIcon}>
              <SpyIcon />
            </SvgIcon>
          </Grid>

          <Grid item>
            <SalespersonName
              className={cx(classes.labelText, classes.labelTextName)}
              firstName={salesperson.firstName}
              lastName={salesperson.lastName}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(SmallScreenCell);
