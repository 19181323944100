import inputErrors from 'common/messages/inputErrors';

import { Message } from 'common/interfaces/common';

export enum ActionResult {
  SUCCESS_ACTION = 'SUCCESS_ACTION',
  FAIL_ACTION = 'FAIL_ACTION',
}

export interface IActionResultData {
  result: ActionResult;
  data: unknown;
}

export const FRONT_DESK_PATH = '/front-desk';
export const HOME_PATH = '/';
export const SIGN_IN_PATH = '/sign-in';
export const PRIVACY_POLICY_PATH = '/privacy-policy';

// TODO: replace with getRequiredMessage
export const requiredMessage = 'Required field';

export const getRequiredMessage = (): Message => inputErrors.requiredFieldError;

export const getMinErrorMessage = (param: { min: number }): Message => ({
  ...inputErrors.valueMinLengthError,
  value: param.min,
});

export const getMaxErrorMessage = (param: { max: number }): Message => ({
  ...inputErrors.valueMaxLengthError,
  value: param.max,
});

export const endTimeErrorMessage = () => inputErrors.endTimeBeforeStartTimeError;

export const MAX_FILE_SIZE = 30000000;
