import * as Sentry from '@sentry/react';
import api from 'store';
import { http, afterResolve } from 'helpers';
import * as clientActions from './client';
import * as personActions from './person';
import { fromJS } from 'immutable';

export const STATE_KEY = 'user';

// Return de-serialized JSON:API fitting existing format/structure. Should be removed as dependent components updated.
const setAuthData = (response: any) => {
  localStorage.setItem('token', response.accessToken);
  Sentry.configureScope(scope => {
    scope.setUser({
      id: response.data.id,
      username: response.data.username
    });
  });

  const person = Object.assign(response.data.person, {
    primaryClientId: response.data.person.client.id
  });

  for (let i = 0; i < person.clients.length; i+1) {
    Object.assign(person.clients[i], {
      title: person.clients[i].clientName,
      id: person.clients[i].id,
      timezone: person.clients[i].timeZone,
      color: person.clients[i].color,
      logo: person.clients[i].logo
    });
  }

  delete response.data.person;
  const client = Object.assign(person.client, {
    title: person.client.clientName,
    id: person.client.id,
    timezone: person.client.timeZone,
    color: person.client.color,
    logo: person.client.logo
  });
  const user = response.data;
  delete person.client;
  localStorage.setItem('activeClient', client.id);
  return { person, client, user };
};

export const login = (username: any, password: any) => (
  dispatch: any,
  getState: any
) => {
  return http()
    .post('auth/login', { username, password })
    .then(async body => {await afterResolve(body)})
    .then((response: any) => {
      dispatch({ type: 'UPDATE_USER', payload: response }); // 'user' state store not yet used, should be in place of 'person'
      return setAuthData(response);
    });
};

export const status = () => (dispatch: any, getState: any) => {
  return http()
    .get('auth/status')
    .then(async body => {await afterResolve(body)})
    .then((response: any) => {
      return setAuthData(response);
    });
};

export const roles = (id: any) => (dispatch: any, getState: any) => {
  return http()
    .get(`users/${id}/roles`)
    .then(async body => {await afterResolve(body)})
    .then((response: any) => {
      return response.data;
    });
};



export const logout = () => (dispatch: any, getState: any) => {
  return http()
    .post('auth/logout')
    .then(async body => {await afterResolve(body)})
    .then((response: any) => {
      dispatch(clear());
      return response;
    });
};

export const getForgot = (lastName: any, payer_number: any) => (
  dispatch: any,
  getState: any
) => {
  return http()
    .get('users/action/forgot', {
      params: { lastName, payer_number }
    })
    .then(async body => {await afterResolve(body)})
    .then((response: any) => {
      return response;
    });
};

export const postReset = (method: any, userId: any, email: any) => (
  dispatch: any,
  getState: any,
  { serialize }: any
) => {
  return http()
    .post(
      'users/action/reset',
      serialize('reset', { method, userId, email })
    )
    .then(async body => {await afterResolve(body)})
    .then((response: any) => {
      return response;
    });
};

export const getReset = (token: any) => (dispatch: any, getState: any) => {
  return http()
    .get(`auth/reset/${token}`)
    .then(async body => {await afterResolve(body)})
    .then((response: any) => {
      return setAuthData(response);
    });
};

export const patch = (id: any, data: any) => (
  dispatch: any,
  getState: any,
  { serialize }: any
) => {
  const request = serialize('user', data, id);
  return http()
    .patch(`users/${id}`, request)
    .then(async body => {await afterResolve(body)})
    .then((response: any) => {
      return response.data;
    });
};

export const clear = () => {
  api.dispatch({ type: 'CLEAR_USER' });
  api.dispatch(personActions.clearPerson());
  api.dispatch(clientActions.clearClient());
};

const initialState = fromJS(null);

export default function reducer(state: any = initialState, action: any) {
  switch (action.type) {
    case 'UPDATE_USER':
      const userObj = {
        ...action.payload.data,
        bearerToken: action.payload.accessToken
      }
      return state === null ? fromJS(userObj) : state.mergeDeep(userObj);
    case 'CLEAR_USER':
      return initialState;
    default:
      return state;
  }
}
