import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FixedSizeList as List } from 'react-window';
import paginate from 'react-window-paginated';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress, ListItemIcon, MenuItem, Typography } from '@material-ui/core';

import inputLabels from 'common/messages/inputLabels';
import messages from 'common/messages/messages';
import { Checkbox } from 'common/components';
import { IFilterOption, IPaginateFilterReturnType } from 'common/interfaces/filter';
import SearchInput from 'common/components/ToolbarSearchInput/ToolbarSearchInput';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

const PaginatedList = paginate(List);

interface IPaginatedFilterBody {
  isOptionSelected: (option: IFilterOption) => boolean;
  handleOptionClick: (option: IFilterOption) => void;
  fetchList: (
    search: string,
    page: number,
    params?: Record<string, string | null>,
  ) => Promise<IPaginateFilterReturnType>;
  elementsPerPage?: number;
  params: Record<string, string | null>;
}

const useStyles = makeStyles(() => ({
  menuListItemIcon: {
    marginLeft: '-3px!important',
    marginRight: '11px!important',
    '& .MuiCheckbox-root': {
      width: 18,
      height: 18,
    },
  },
}));

const ITEM_HEIGHT = 35;
const MAX_LIST_HEIGHT = 300;
const LIST_WIDTH = 300;

const noResultsRenderer = () => {
  return (
    <Box pl={3} width={LIST_WIDTH}>
      <Typography color="secondary">
        <FormattedMessage {...messages.noDataFound} />
      </Typography>
    </Box>
  );
};

const PaginatedFilterBody = (props: IPaginatedFilterBody): JSX.Element => {
  const { isOptionSelected, handleOptionClick, fetchList, elementsPerPage = 20, params } = props;

  const [search, setSearch] = useState('');
  const [height, setHeight] = useState(ITEM_HEIGHT);
  const [isLoading, setIsLoading] = useState(false);

  const renderIntlMessage = useRenderIntlMessage();

  const classes = useStyles();

  const getData = async (pageToFetch: number) => {
    setIsLoading(true);

    const data = await fetchList(search, pageToFetch, params);
    const { pageElements } = data;
    let listSize = pageElements.length * ITEM_HEIGHT;
    listSize = listSize < MAX_LIST_HEIGHT ? listSize : MAX_LIST_HEIGHT;
    listSize = listSize < ITEM_HEIGHT ? ITEM_HEIGHT : listSize;

    setIsLoading(false);
    setHeight(listSize);

    return data;
  };

  return (
    <>
      <Box mt={-0.5} pb={0.5}>
        <SearchInput
          placeholder={renderIntlMessage(inputLabels.search)}
          onChange={value => {
            setSearch(value);
          }}
          value={search}
          hasActiveBorder={false}
        />
      </Box>
      <Box position="relative">
        {isLoading && (
          <Box
            width="100%"
            height="100%"
            position="absolute"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress color="primary" size="1.5rem" />
          </Box>
        )}
        <PaginatedList
          className="List"
          height={height}
          itemSize={ITEM_HEIGHT}
          width={LIST_WIDTH}
          getPage={getData}
          elementsPerPage={elementsPerPage}
          key={search}
          noResultsRenderer={noResultsRenderer}
        >
          {param => {
            const { data, index, style } = param;
            const { key, value, label, isFetching } = data[index];

            const option = {
              key,
              value,
              label,
            };

            return isFetching ? null : (
              <MenuItem
                style={style}
                key={key}
                onClick={() => handleOptionClick(option)}
                disableRipple
              >
                <ListItemIcon className={classes.menuListItemIcon}>
                  <Checkbox checked={isOptionSelected(option)} indeterminate={false} />
                </ListItemIcon>
                <Typography color="inherit">{label}</Typography>
              </MenuItem>
            );
          }}
        </PaginatedList>
      </Box>
    </>
  );
};
export default PaginatedFilterBody;
