export const actionTypes = {
  FETCH_CRM_TEMPLATES_LIST: 'FETCH_CRM_TEMPLATES',
  FETCH_CRM_TEMPLATES_LIST_LOADING: 'FETCH_CRM_TEMPLATES_LIST_LOADING',
  RESET_CRM_TEMPLATES_LIST: 'RESET_CRM_TEMPLATES_LIST',

  GET_CRM_TEMPLATE_LOADING: 'GET_CRM_TEMPLATE_LOADING',
  GET_CRM_TEMPLATE: 'GET_CRM_TEMPLATE',
  RESET_CRM_TEMPLATE: 'RESET_CRM_TEMPLATE',
  SAVE_CRM_TEMPLATE_LOADING: 'SAVE_CRM_TEMPLATE_LOADING',
  SAVE_CRM_TEMPLATE_SUCCESS: 'SAVE_CRM_TEMPLATE_SUCCESS',
  SAVE_CRM_TEMPLATE_ACTION_RESULT: 'SAVE_CRM_TEMPLATE_ACTION_RESULT',
  RESET_SAVE_CRM_TEMPLATE_RESULT: 'RESET_SAVE_CRM_TEMPLATE_RESULT',

  SET_UPDATE_CRM_TEMPLATE_STATUS_IS_LOADING: 'SET_UPDATE_CRM_TEMPLATE_STATUS_IS_LOADING',
  UPDATE_CRM_TEMPLATE_STATUS: 'UPDATE_CRM_TEMPLATE_STATUS',
  UPDATE_CRM_TEMPLATE_STATUS_ACTION_RESULT: 'UPDATE_CRM_TEMPLATE_STATUS_ACTION_RESULT',
};

export enum DocumentTypes {
  DOCUMENT_CONTRACT = 'DOCUMENT_CONTRACT',
  WAIVER = 'Waiver',
  NEW_MEMBERSHIP = 'Membership',
  BOOKING_NOTIFICATION = 'Booking notification',
  PERIODIC_UPDATES = 'Periodic updates',
  SMS = 'SMS',
  EMAIL = 'Email',
}
