import React, { createContext, useContext } from 'react';
import useToggleSearchInvoiceProductsState, {
  IToggleSearchInvoiceProductsProps,
} from 'common/hooks/useToggleSearchInvoiceProducts';

const SearchInvoiceProductsContext = createContext<IToggleSearchInvoiceProductsProps | null>(null);

function SearchInvoiceProductsContextProvider({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  return (
    <SearchInvoiceProductsContext.Provider value={useToggleSearchInvoiceProductsState()}>
      {children}
    </SearchInvoiceProductsContext.Provider>
  );
}

function useSearchInvoiceProductsState(): IToggleSearchInvoiceProductsProps {
  const context = useContext(SearchInvoiceProductsContext);
  if (!context) {
    throw new Error(
      'useSearchInvoiceProductsState must be used within a SearchInvoiceProductsContextProvider',
    );
  }
  return context;
}
export { SearchInvoiceProductsContextProvider, useSearchInvoiceProductsState };
