import { defineMessages } from 'react-intl';

export default defineMessages({
  // GeneralInfoSection
  employeeId: {
    id: 'common.employeeProfile.generalInfoSection.label.employeeId',
    defaultMessage: 'Employee ID: {employeeId}',
  },
  hired: {
    id: 'common.employeeProfile.generalInfoSection.label.hired',
    defaultMessage: 'Hired: {hired}',
  },
  term: {
    id: 'common.employeeProfile.generalInfoSection.label.term',
    defaultMessage: 'Term: {term}',
  },
  exemptions: {
    id: 'common.employeeProfile.generalInfoSection.label.exemptions',
    defaultMessage: 'Exemptions: {exemptions}',
  },
  withholding: {
    id: 'common.employeeProfile.generalInfoSection.label.withholding',
    defaultMessage: 'Withholding: {withholding}',
  },
  discount: {
    id: 'common.employeeProfile.generalInfoSection.label.discount',
    defaultMessage: 'Discount: {discount}',
  },
  // PermissionsBlock
  permissions: {
    id: 'common.employeeProfile.permissionsBlock.title.permissions',
    defaultMessage: 'Permissions',
  },
  emptyPermissions: {
    id: 'common.employeeProfile.permissionsBlock.title.emptyPermissions',
    defaultMessage: 'No permissions added.',
  },
  emptyPermissionsHint: {
    id: 'common.employeeProfile.permissionsBlock.title.emptyPermissionsHint',
    defaultMessage: 'You can edit permissions by pressing the button above.',
  },
  // TimeclockHistoryBlock
  timeclockHistory: {
    id: 'common.employeeProfile.timeclockHistory.title.timeclock',
    defaultMessage: 'Timeclock History',
  },
  // JobPositionsBlock
  jobPositions: {
    id: 'common.employeeProfile.jobPositionsBlock.title.jobPositions',
    defaultMessage: 'Job positions',
  },
  // JobPositions
  salary: {
    id: 'common.employeeProfile.jobPositionsBlock.label.salary',
    defaultMessage: 'Salary: {salary}',
  },
  hourly: {
    id: 'common.employeeProfile.jobPositionsBlock.label.hourly',
    defaultMessage: 'Hourly: {salary}',
  },
  unit: {
    id: 'common.employeeProfile.jobPositionsBlock.label.unit',
    defaultMessage: 'Unit: {salary}',
  },
  previousPositions: {
    id: 'common.employeeProfile.jobPositionsBlock.label.previousPositions',
    defaultMessage: 'Previous Positions',
  },
  emptyJobPositions: {
    id: 'common.employeeProfile.jobPositionsBlock.label.emptyJobPositions',
    defaultMessage: 'No job positions yet',
  },
  // EditMenu
  editMyProfile: {
    id: 'common.employeeProfile.editMenu.title.editMyProfile',
    defaultMessage: 'Edit My Profile',
  },
  changePasswordsAndPin: {
    id: 'common.employeeProfile.editMenu.title.changePasswordsAndPin',
    defaultMessage: 'Change Passwords & PIN',
  },
  resetPasswordsAndPin: {
    id: 'common.employeeProfile.editMenu.title.resetPasswordsAndPin',
    defaultMessage: 'Reset Passwords & PIN',
  },
  editPermission: {
    id: 'common.employeeProfile.editMenu.title.editPermission',
    defaultMessage: 'Edit permission',
  },
  editJobPositions: {
    id: 'common.employeeProfile.editMenu.title.editJobPositions',
    defaultMessage: 'Edit Job Positions',
  },
});
