import { fromJS, List } from 'immutable';
import moment from 'moment-timezone';

import { ICheckIn, ICheckInImt } from '../../interfaces';
import { actionTypes, CURRENT_CHECKIN_TIME_MIN } from 'modules/front-desk/constants';
import { UPDATE_PROFILE_AFTER_EDIT_PERSON_ACTION } from 'common/constants/actionTypes';
import { initialState } from 'common/constants/initialState';

const initialCheckInsState = fromJS({
  list: List<ICheckIn>(),
  listLoading: false,
  currentCheckIn: null,
  selectedCheckIn: null,
  checkInAction: initialState,
});

function checkInsReducer(state = initialCheckInsState, action) {
  let updatedState = state;

  switch (action.type) {
    case actionTypes.FETCH_CHECK_INS:
      return state.set('list', fromJS(action.payload)).set('listLoading', false);
    case actionTypes.FETCH_CHECK_INS_LOADING:
      return state.set('listLoading', action.payload);
    case actionTypes.RESET_CHECK_INS:
      return state.set('list', fromJS([]));
    case actionTypes.RESET_CURRENT_CHECK_IN:
      return state.set('currentCheckIn', null);
    case actionTypes.FETCH_LIFE_SOCKET_CHECK_IN:
      const { timezone, data } = action.payload;

      if (
        moment
          .tz(`${data.checkInTime}Z`, timezone)
          .isAfter(moment.tz(timezone).subtract(CURRENT_CHECKIN_TIME_MIN, 'minute'))
      ) {
        if (updatedState.get('currentCheckIn')) {
          const previousCheckIn = updatedState.get('currentCheckIn');

          if (updatedState.get('list')?.size < 50) {
            const newCheckinList = updatedState
              .get('list')
              .filter(
                (checkin: ICheckInImt) =>
                  checkin.get('personId') === previousCheckIn.get('personId'),
              )
              .push(previousCheckIn);

            updatedState.set('list', newCheckinList);
          } else {
            updatedState = updatedState.set(
              'list',
              updatedState
                .get('list')
                .pop()
                .insert(0, previousCheckIn),
            );
          }
        }
      } else if (updatedState.get('list')?.size < 50) {
        updatedState = updatedState.update('list', list => list.push(fromJS(data)));
      } else {
        updatedState = updatedState.set(
          'list',
          updatedState
            .get('list')
            .pop()
            .insert(0, fromJS(data)),
        );
      }
      return updatedState;

    case actionTypes.SET_SELECTED_CHECK_IN:
      let selectedCheckIn = state.get('list').find(ch => ch.get('personId') === action.payload);

      if (!selectedCheckIn && action.payload === state.getIn(['currentCheckIn', 'personId'])) {
        selectedCheckIn = state.get('currentCheckIn');
      }

      return state.set('selectedCheckIn', selectedCheckIn);
    case actionTypes.RESET_SELECTED_CHECK_IN:
      return state.set('selectedCheckIn', null);

    case actionTypes.SET_CURRENT_CHECK_IN:
      if (state.get('currentCheckIn')) {
        return state
          .set('currentCheckIn', fromJS(action.payload))
          .update('list', list =>
            list
              .filter(checkInItem => checkInItem.get('personId') !== action.payload?.personId)
              .unshift(state.get('currentCheckIn')),
          );
      }

      return state
        .set('currentCheckIn', fromJS(action.payload))
        .update('list', list =>
          list.filter(checkInItem => checkInItem.get('personId') !== action.payload?.personId),
        );

    case actionTypes.SET_CHECK_IN_ACTION_LOADING:
      return state.setIn(['checkInAction', 'isLoading'], action.payload);
    case actionTypes.SET_CHECK_IN_ACTION_RESULT:
      return state.setIn(['checkInAction', 'result'], action.payload);
    case actionTypes.RESET_CHECK_IN_ACTION_RESULT:
      return state.set('checkInAction', fromJS(initialState));

    case actionTypes.UPDATE_PERSON_PHOTO:
      state.get('list').forEach((checkIn, index) => {
        if (checkIn.get('personId') === action.meta.personId) {
          updatedState = updatedState.setIn(
            ['list', index, 'imageUrl'],
            action.payload ? action.payload.url : null,
          );
        }
      });
      const currentCheckIn = state.get('currentCheckIn');
      if (action.meta.personId === currentCheckIn?.get('personId')) {
        updatedState = updatedState.setIn(
          ['currentCheckIn', 'imageUrl'],
          action.payload ? action.payload.url : null,
        );
      }
      return updatedState;

    case UPDATE_PROFILE_AFTER_EDIT_PERSON_ACTION:
      state.get('list').forEach((checkIn, index) => {
        if (checkIn.get('personId') === action.payload.id) {
          updatedState = updatedState
            .setIn(['list', index, 'firstName'], action.payload.firstName)
            .setIn(['list', index, 'lastName'], action.payload.lastName)
            .setIn(['list', index, 'imageUrl'], action.payload.image?.url);
        }
      });
      return updatedState;

    case actionTypes.UPDATE_PERSON_STATUS:
      const { personId } = action.meta;

      if (state.getIn(['currentCheckIn', 'personId']) === personId) {
        updatedState = updatedState.setIn(['currentCheckIn', 'personType'], action.payload);
      } else {
        state.get('list').forEach((checkIn, index) => {
          if (checkIn.get('personId') === personId) {
            updatedState = updatedState.setIn(['list', index, 'personType'], action.payload);
          }
        });
      }

      return updatedState;

    default:
      return state;
  }
}

export default checkInsReducer;
