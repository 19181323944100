import { ConstOptions } from 'common/constants/classes';
import messages from './messages';

export enum TimeclockPaths {
  TIMECLOCK = 'timeclock',
  TIMECLOCK_LOG = 'log',
  PAYROLL_REPORT = 'payroll-report',
  PAYROLL_REPORT_EMPLOYEE = 'payroll-report/:id',
  MY_PAYROLL_REPORT = 'my-payroll-report',
}

export const actionTypes = {
  CLOCK_IN_SUCCESS: 'CLOCK_IN_SUCCESS',
  CLOCK_IN_LOADING: 'CLOCK_IN_LOADING',
  RESET_CLOCK_IN_ACTION_RESULT: 'RESET_CLOCK_IN_ACTION_RESULT',

  GET_TIMECLOCK_LIST_SUCCESS: 'GET_TIMECLOCK_LIST_SUCCESS',
  GET_TIMECLOCK_LIST_LOADING: 'GET_TIMECLOCK_LIST_LOADING',
  GET_TIMECLOCK_LIST_ERROR: 'GET_TIMECLOCK_LIST_ERROR',

  GET_EMPLOYEE_TIMECLOCK_JOB_POSITIONS_SUCCESS: 'GET_EMPLOYEE_TIMECLOCK_JOB_POSITIONS_SUCCESS',
  GET_EMPLOYEE_TIMECLOCK_JOB_POSITIONS_LOADING: 'GET_EMPLOYEE_TIMECLOCK_JOB_POSITIONS_LOADING',
  GET_EMPLOYEE_TIMECLOCK_JOB_POSITIONS_ERROR: 'GET_EMPLOYEE_TIMECLOCK_JOB_POSITIONS_ERROR',

  CLOCK_IN_EMPLOYEE_POSITION_SUCCESS: 'CLOCK_IN_EMPLOYEE_POSITION_SUCCESS',
  CLOCK_IN_EMPLOYEE_POSITION_LOADING: 'CLOCK_IN_EMPLOYEE_POSITION_LOADING',

  CLOCK_OUT_EMPLOYEE_POSITION_SUCCESS: 'CLOCK_OUT_EMPLOYEE_POSITION_SUCCESS',
  CLOCK_OUT_EMPLOYEE_POSITION_LOADING: 'CLOCK_OUT_EMPLOYEE_POSITION_LOADING',

  CLOCK_IN_TIMECLOCK_SIGN_IN_SUCCESS: 'CLOCK_IN_TIMECLOCK_SIGN_IN_SUCCESS',
  CLOCK_IN_TIMECLOCK_SIGN_IN_LOADING: 'CLOCK_IN_TIMECLOCK_SIGN_IN_LOADING',
  CLOCK_IN_TIMECLOCK_SIGN_IN_ERROR: 'CLOCK_IN_TIMECLOCK_SIGN_IN_ERROR',

  GET_FULL_PAYROLL_REPORT_LIST_SUCCESS: 'GET_FULL_PAYROLL_REPORT_LIST_SUCCESS',
  GET_FULL_PAYROLL_REPORT_LIST_LOADING: 'GET_FULL_PAYROLL_REPORT_LIST_LOADING',
  GET_FULL_PAYROLL_REPORT_LIST_ERROR: 'GET_FULL_PAYROLL_REPORT_LIST_ERROR',

  GET_CLOCK_IN_DATA_LIST_SUCCESS: 'GET_CLOCK_IN_DATA_LIST_SUCCESS',
  GET_CLOCK_IN_DATA_LIST_LOADING: 'GET_CLOCK_IN_DATA_LIST_LOADING',
  GET_CLOCK_IN_DATA_LIST_ERROR: 'GET_CLOCK_IN_DATA_LIST_ERROR',

  GET_UNIT_DATA_LIST_SUCCESS: 'GET_UNIT_DATA_LIST_SUCCESS',
  GET_UNIT_DATA_LIST_LOADING: 'GET_UNIT_DATA_LIST_LOADING',
  GET_UNIT_DATA_LIST_ERROR: 'GET_UNIT_DATA_LIST_ERROR',

  GET_MY_FULL_PAYROLL_REPORT_LIST_SUCCESS: 'GET_MY_FULL_PAYROLL_REPORT_LIST_SUCCESS',
  GET_MY_FULL_PAYROLL_REPORT_LIST_LOADING: 'GET_MY_FULL_PAYROLL_REPORT_LIST_LOADING',
  GET_MY_FULL_PAYROLL_REPORT_LIST_ERROR: 'GET_MY_FULL_PAYROLL_REPORT_LIST_ERROR',

  GET_PAYROLL_STATISTICS_SUCCESS: 'GET_PAYROLL_STATISTICS_SUCCESS',
  GET_PAYROLL_STATISTICS_LOADING: 'GET_PAYROLL_STATISTICS_LOADING',
  GET_PAYROLL_STATISTICS_ERROR: 'GET_PAYROLL_STATISTICS_ERROR',

  ADD_CLOCK_IN_MANUALLY_SUCCESS: 'ADD_CLOCK_IN_MANUALLY_SUCCESS',
  ADD_CLOCK_IN_UNIT_MANUALLY_SUCCESS: 'ADD_CLOCK_IN_UNIT_MANUALLY_SUCCESS',
  ADD_CLOCK_IN_MANUALLY_LOADING: 'ADD_CLOCK_IN_MANUALLY_LOADING',

  GET_TIMECLOCK_EVENTS_LOADING: 'GET_TIMECLOCK_EVENTS_LOADING',
  GET_TIMECLOCK_EVENTS_SUCCESS: 'GET_TIMECLOCK_EVENTS_SUCCESS',

  DELETE_TIMECLOCK_BY_ID_LOADING: 'DELETE_TIMECLOCK_BY_ID_LOADING',
  DELETE_TIMECLOCK_BY_ID_SUCCESS: 'DELETE_TIMECLOCK_BY_ID_SUCCESS',

  GET_PAYROLL_PAY_PERIODS_SUCCESS: 'GET_PAYROLL_PAY_PERIODS_SUCCESS',
  SET_CURRENT_PAYROLL_PAY_PERIOD: 'SET_CURRENT_PAYROLL_PAY_PERIOD',
  GET_PAYROLL_PAY_PERIODS_LOADING: 'GET_PAYROLL_PAY_PERIODS_LOADING',

  DELETE_PAYROLL_ITEM_SUCCESS: 'DELETE_PAYROLL_ITEM_SUCCESS',
  DELETE_PAYROLL_ITEM_LOADING: 'DELETE_PAYROLL_ITEM_LOADING',

  DELETE_CLOCK_IN_DATA_ITEM_LOADING: 'DELETE_CLOCK_IN_DATA_ITEM_LOADING',
  DELETE_CLOCK_IN_DATA_ITEM_SUCCESS: 'DELETE_CLOCK_IN_DATA_ITEM_SUCCESS',

  GET_TIMECLOCK_JOB_POSITIONS_SUCCESS: 'GET_TIMECLOCK_JOB_POSITIONS_SUCCESS',
  GET_TIMECLOCK_JOB_POSITIONS_LOADING: 'GET_TIMECLOCK_JOB_POSITIONS_LOADING',

  DELETE_UNIT_DATA_ITEM_SUCCESS: 'DELETE_UNIT_DATA_ITEM_SUCCESS',

  GET_PERSON_EVENT_LOADING: 'GET_PERSON_EVENT_LOADING',
  GET_PERSON_EVENT_SUCCESS: 'GET_PERSON_EVENT_SUCCESS',

  GET_ALL_PROFILE_EVENTS_SUCCESS: 'GET_ALL_PROFILE_EVENTS_SUCCESS',
  GET_ALL_PROFILE_EVENTS_LOADING: 'GET_ALL_PROFILE_EVENTS_LOADING',

  GET_SELECTED_EMPLOYEE_PAYROLL_REPORT: 'GET_SELECTED_EMPLOYEE_PAYROLL_REPORT',

  FETCH_PAYROLL_REPORT_ACTIVITY: 'FETCH_PAYROLL_REPORT_ACTIVITY',
  FETCH_PAYROLL_REPORT_ACTIVITY_LOADING: 'FETCH_PAYROLL_REPORT_ACTIVITY_LOADING',
};

export enum OvertimeOptions {
  WITH_OVERTIME = 'WITH_OVERTIME',
  WITHOUT_OVERTIME = 'WITHOUT_OVERTIME',
}

export enum PayrollReportActivityChartFilters {
  Regular = 'duration',
  Overtime = 'overtime',
  Scheduled = 'scheduled',
}

export const PayrollActivityChartLegendLabels = {
  [PayrollReportActivityChartFilters.Regular]: messages.regularLabel,
  [PayrollReportActivityChartFilters.Overtime]: messages.overtime,
  [PayrollReportActivityChartFilters.Scheduled]: messages.scheduledLabel,
};

export const overtimeOptions = new ConstOptions([
  {
    key: OvertimeOptions.WITH_OVERTIME,
    value: OvertimeOptions.WITH_OVERTIME,
    message: messages.withOvertime,
  },
  {
    key: OvertimeOptions.WITHOUT_OVERTIME,
    value: OvertimeOptions.WITHOUT_OVERTIME,
    message: messages.withoutOvertime,
  },
]);
