import { useState, useCallback } from 'react';

interface IMenuStateProps {
  menuAnchorEl: null | Element;
  handleShowMenu: (event) => void;
  handleCloseMenu: () => void;
}

const useMenuState = (): IMenuStateProps => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  return {
    menuAnchorEl,
    handleShowMenu: useCallback(
      event => {
        setMenuAnchorEl(event.currentTarget);
      },
      [setMenuAnchorEl],
    ),
    handleCloseMenu: useCallback(() => {
      setMenuAnchorEl(null);
    }, [setMenuAnchorEl]),
  };
};

export default useMenuState;
