import React, { useEffect } from 'react';
import { AddressBlock, DialogComponent } from 'common/components';
import * as yup from 'yup';
import { getAddressSchema } from 'common/validationSchemas/addressSchema';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IAddress } from 'common/interfaces/common';
import { AlertCondition } from 'common/interfaces/alerts';
import modalTitles from 'common/messages/modalTitles';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from 'store/hooks';
import { useSelector } from 'react-redux';
import { selectProfileRequiredFields } from 'common/state/settings/selectors';
import { selectIsSubmittedRecommendedFieldsModal } from 'common/state/modals/selectors';
import { useRecommendedFields } from 'common/hooks/useRecommendedFields';
import { showRecommendedFieldsModal } from 'common/state/modals/actions';

const initialAddressValues: IAddress = {
  line1: '',
  line2: '',
  city: '',
  zipCode: '',
  state: null,
  country: null,
};

interface IAddressOldModalProps {
  alert: AlertCondition;
  isOpen: boolean;
  switchingModal: (alertId: string) => void;
  onSubmit: (address: IAddress, alertId: string) => void;
}

const AddressModal = ({
  isOpen,
  onSubmit,
  switchingModal,
  alert,
}: IAddressOldModalProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const profileRequiredFields = useSelector(selectProfileRequiredFields);
  const isSubmitted = useSelector(selectIsSubmittedRecommendedFieldsModal);

  const { checkRecommendedFieldsAreNotEmpty, profileRecommendedFields } = useRecommendedFields();

  const updateAddress = (address: IAddress): void => {
    const hasEmptyRecommendedFields = checkRecommendedFieldsAreNotEmpty({ address });

    if (hasEmptyRecommendedFields) {
      dispatch(showRecommendedFieldsModal(true));
    } else {
      onSubmit(address, alert);
      switchingModal(alert);
    }
  };

  const formMethods = useForm({
    defaultValues: initialAddressValues,
    resolver: yupResolver(
      yup.object().shape({
        address: getAddressSchema(profileRequiredFields),
      }),
    ),
    mode: 'onBlur',
  });
  const { reset, handleSubmit, getValues } = formMethods;

  useEffect(() => {
    if (isSubmitted) {
      const values = getValues();

      onSubmit(values, alert);
      switchingModal(alert);

      dispatch(showRecommendedFieldsModal(false));
    }
  }, [alert, dispatch, getValues, isSubmitted, onSubmit, switchingModal]);

  return (
    <DialogComponent
      size="xs"
      title={<FormattedMessage {...modalTitles.addressInfo} />}
      isOpen={isOpen}
      onClose={() => {
        reset(initialAddressValues);
        switchingModal(alert);
      }}
      onSubmit={handleSubmit(updateAddress)}
    >
      <form>
        <FormProvider {...formMethods}>
          <AddressBlock recommendedFields={profileRecommendedFields} />
        </FormProvider>
      </form>
    </DialogComponent>
  );
};

export default AddressModal;
