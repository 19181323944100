import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Reply as ReplyIcon } from '@material-ui/icons';

import {
  selectPersonSMSDetails,
  selectPersonSMSDetailsLoading,
} from 'common/components/PersonProfile/state/selectors';
import {
  fetchPersonSMSDetails,
  resetPersonSMSDetailsAction,
} from 'common/components/PersonProfile/state/actions';

import { useAppDispatch } from 'store/hooks';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';

import { DialogComponent, LoadingBackdrop } from 'common/components';

import commonMessages from 'common/messages/messages';
import inputLabels from 'common/messages/inputLabels';
import messages from 'common/components/PersonProfile/messages';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { selectCurrentUserPhone } from 'modules/authentication/state/selectors';
import { convertPhoneNumber } from 'helpers/common';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiDialogContent-root': {
      padding: theme.spacing(0),
      display: 'flex',
      flexDirection: 'column',
    },
  },
  header: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerRow: {
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  headerLabel: {
    marginRight: theme.spacing(1),
  },
  contentContainer: {
    lineHeight: '1.5',
  },
  footer: {
    padding: theme.spacing(3, 3, 3, 2),
  },
  emailContent: {
    whiteSpace: 'pre-wrap',
  },
}));

interface IProps {
  smsId?: string;
  isOpen: boolean;
  personId: string;
  onReply: () => void;
  onClose: () => void;
  module: PeakModuleForNewPersonType;
}

const SMSDetailsModal = (props: IProps): JSX.Element => {
  const { isOpen, onReply, onClose, personId, smsId, module } = props;
  const classes = useStyles();

  // global state

  const dispatch = useAppDispatch();

  const smsDetails = useSelector(selectPersonSMSDetails(personId));
  const smsDetailsLoading = useSelector(selectPersonSMSDetailsLoading(personId));
  const fromPhone = useSelector(selectCurrentUserPhone);

  const [timezoneMoment] = useTimezoneMoment();
  // effects

  useEffect(() => {
    if (smsId) {
      dispatch(fetchPersonSMSDetails(personId, smsId, module));
    }

    return () => {
      dispatch(resetPersonSMSDetailsAction(null, personId));
    };
  }, [dispatch, personId, smsId, module]);

  const toPhone = smsDetails?.getIn(['targetPersonPhone', 'title']);
  const campaign = smsDetails?.getIn(['campaign', 'title']) || '-';
  const dateTime = smsDetails
    ? timezoneMoment(smsDetails?.get('date')).format('h:mm A, MMM D')
    : '';
  const text = smsDetails?.get('text');

  // renders

  return (
    <DialogComponent
      className={classes.root}
      title={<FormattedMessage {...commonMessages.smsLabel} />}
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
      subHeader={
        <Box className={classes.header}>
          <Box display="flex" alignItems="center" flexWrap="wrap">
            <Box className={classes.headerRow}>
              <Typography variant="h5" className={classes.headerLabel}>
                <FormattedMessage {...inputLabels.from} />:
              </Typography>
              <Typography variant="body2" className={classes.headerLabel}>
                {convertPhoneNumber(fromPhone)}
              </Typography>
            </Box>
            <Box className={classes.headerRow}>
              <Typography variant="h5" className={classes.headerLabel}>
                <FormattedMessage {...inputLabels.to} />:
              </Typography>
              <Typography variant="body2" className={classes.headerLabel}>
                {convertPhoneNumber(toPhone)}
              </Typography>
            </Box>
            <Box className={classes.headerRow}>
              <Typography variant="h5" className={classes.headerLabel}>
                <FormattedMessage {...inputLabels.campaign} />:
              </Typography>
              <Typography variant="body2" className={classes.headerLabel}>
                {campaign}
              </Typography>
            </Box>
          </Box>
          <Box ml={1} pt={0.5}>
            <Typography variant="body2" color="textSecondary" noWrap>
              {dateTime}
            </Typography>
          </Box>
        </Box>
      }
      footer={
        <Box className={classes.footer}>
          <Button
            disabled={smsDetailsLoading}
            variant="contained"
            onClick={onReply}
            startIcon={<ReplyIcon />}
          >
            <FormattedMessage {...messages.replyTitle} />
          </Button>
        </Box>
      }
      disableFullScreenBehavior
    >
      <Typography variant="body1" className={classes.contentContainer}>
        {text}
      </Typography>
      <LoadingBackdrop isLoading={smsDetailsLoading} />
    </DialogComponent>
  );
};

export default SMSDetailsModal;
