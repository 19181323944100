import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';

import { countPricePerBilling, countTaxedAmount } from 'modules/services/utils/billingUtils';

import { BillingOptionType, FrequencyTypes } from 'modules/services/constants/packages';

import { IEditablePackageConfiguration } from 'common/interfaces/membership';
import { IBillingOption } from 'modules/services/interfaces/packages';

import { FieldInfo } from 'common/components/index';

import servicesMessages from 'modules/services/messages/messages';
import inputLabels from 'common/messages/inputLabels';
import { formatPrice } from 'common/utils';

interface IProps {
  membershipPackage: IEditablePackageConfiguration;
  billingOption: IBillingOption;
  isOutOfTerm: boolean;
}

const AmountSectionView = ({
  billingOption,
  membershipPackage,
  isOutOfTerm,
}: IProps): JSX.Element => {
  const { totalAmount, type: optionType } = billingOption;

  const {
    durationType,
    customDates,
    durationEditableNumber,
    paymentEditableSchedule: paymentEditableSchedulePachege,
    revenueCode: { totalTax },
  } = membershipPackage;

  const paymentEditableSchedule = isOutOfTerm
    ? membershipPackage.outOfTermBillingOption?.paymentEditableSchedule
    : paymentEditableSchedulePachege;

  const taxedTotalAmount =
    totalAmount && totalTax ? countTaxedAmount(totalAmount, totalTax) : totalAmount;
  const countedTaxedPricePerBilling = countPricePerBilling(
    totalAmount,
    totalTax,
    {
      type: durationType,
      amount: durationEditableNumber?.value,
      fromDate: customDates?.startDate,
      toDate: customDates?.endDate,
    },
    paymentEditableSchedule?.value,
  );

  return (
    <>
      <Grid item xs={4}>
        <FieldInfo
          label={<FormattedMessage {...inputLabels.totalAmount} />}
          description={`$${totalAmount?.toFixed(2)}`}
        />
      </Grid>

      <Grid item xs={4}>
        <FieldInfo
          label={<FormattedMessage {...inputLabels.taxedTotalAmount} />}
          description={`$${taxedTotalAmount?.toFixed(2)}`}
        />
      </Grid>

      {optionType !== BillingOptionType.PAID_IN_FULL && (
        <>
          <Grid item xs={4}>
            <FieldInfo
              label={<FormattedMessage {...servicesMessages.paymentScheduleLabel} />}
              description={FrequencyTypes.translate(paymentEditableSchedule?.value)}
            />
          </Grid>

          <Grid item xs={4}>
            <FieldInfo
              label={<FormattedMessage {...servicesMessages.pricePerBillingLabel} />}
              description={formatPrice(countedTaxedPricePerBilling)}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default AmountSectionView;
