import { fromJS } from 'immutable';
import { actionTypes } from 'modules/authentication/state/constants';
import { initialState, initReqStateImt } from 'common/constants/initialState';
import TokenService from 'services/auth/TokenService';

import { INamedEntityImt } from 'common/interfaces/common';
import { IClubGeneralInfo } from 'modules/clubs/interfaces';
import { combineReducers } from 'redux-immutable';
import AppService from 'services/application/AppService';

const signInInitialState = fromJS({
  isUserLogged: Boolean(TokenService.getAccessToken() && AppService.getUserRole() === 'client'),
  isLoading: false,
  error: false,

  corporationsForSign: initialState,
});

export const authenticationReducer = (state = signInInitialState, action) => {
  switch (action.type) {
    case actionTypes.USER_SIGN_IN:
      return state.set('isUserLogged', fromJS(action.payload?.success)).set('error', false);
    case actionTypes.USER_SIGN_IN_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.USER_SIGN_IN_ERROR:
      return state.set('error', action.payload);
    case actionTypes.USER_LOG_OUT:
      return state.set('isUserLogged', false);
    case actionTypes.FETCH_CORPORATIONS_FOR_SIGN_IN_LOADING:
      return state.setIn(['corporationsForSign', 'isLoading'], action.payload);
    case actionTypes.FETCH_CORPORATIONS_FOR_SIGN_IN:
      return state.setIn(['corporationsForSign', 'result'], fromJS(action.payload));
    case actionTypes.RESET_CORPORATIONS_FOR_SIGN_IN:
      return state.set('corporationsForSign', initReqStateImt);
    default:
      return state;
  }
};

const userProfileInitialState = fromJS({
  profile: null,
  isLoading: false,
  error: null,

  selectedOrganization: null,
  selectedOrganizationPrimaryColor: '',
});

const transformClubFormIntoImt = (club: IClubGeneralInfo): INamedEntityImt =>
  fromJS({
    id: club.id,
    title: `${club.code} - ${club.title}`,
    imageUrl: club?.logo?.url,
  });

const updateClubInSecRoles = (securityRoles, club: IClubGeneralInfo) => {
  if (!securityRoles || !securityRoles.size) return securityRoles;

  let updatedSecRoles = securityRoles;

  securityRoles.forEach((role, index) => {
    if (!role.get('clubList') || !role.get('clubList').size) {
      return;
    }

    const clubIndex = role.get('clubList').findIndex(clubImt => clubImt.get('id') === club.id);

    updatedSecRoles = updatedSecRoles.updateIn([index, 'clubList', clubIndex], () =>
      transformClubFormIntoImt(club),
    );
  });

  return updatedSecRoles;
};

const updateClubInSelectedOrg = (organization, club: IClubGeneralInfo) =>
  organization.get('id') === club.id
    ? organization
        .set('id', club.id)
        .set('title', club.code ? `${club.code} - ${club.title}` : club.title)
        .set('imageUrl', club.logo?.url)
    : organization;

export const currentUserReducer = (state = userProfileInitialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_PROFILE:
      return state.set('profile', fromJS(action.payload));
    case actionTypes.SET_USER_AVATAR:
      return state.set('userAvatar', fromJS(action.payload));
    case actionTypes.FETCH_USER_PROFILE_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_USER_PROFILE_ERROR:
      return state.set('error', fromJS(action.payload));
    case actionTypes.SET_ORGANIZATION_PRIMARY_COLOR:
      return state.set('selectedOrganizationPrimaryColor', action.payload);
    case actionTypes.RESET_ORGANIZATION_PRIMARY_COLOR:
      return state.set('selectedOrganizationPrimaryColor', null);
    case actionTypes.SELECT_ORGANIZATION:
      return state
        .set('selectedOrganization', fromJS(action.payload))
        .set('selectedOrganizationPrimaryColor', action.payload.color);
    case actionTypes.RESET_USER_PROFILE_DATA:
      return userProfileInitialState;
    case actionTypes.UPDATE_CLUB_DATA:
      return state
        .updateIn(
          [
            'profile',
            'clubs',
            state.getIn(['profile', 'clubs']).findIndex(club => club?.get('id')),
          ],
          () => transformClubFormIntoImt(action.payload),
        )
        .updateIn(['profile', 'securityRoles'], securityRoles =>
          updateClubInSecRoles(securityRoles, action.payload),
        )
        .update('selectedOrganization', organization =>
          updateClubInSelectedOrg(organization, action.payload),
        );
    case actionTypes.UPDATE_CORPORATION_DATA:
      return state
        .updateIn(['profile', 'corporation'], corp => {
          if (corp.get('id') !== action.payload.id) return corp;

          return corp
            .set('title', action.payload.title)
            .set('imageUrl', action.payload?.logo?.filePath);
        })
        .update('selectedOrganization', organization =>
          updateClubInSelectedOrg(organization, action.payload),
        );
    default:
      return state;
  }
};

export default combineReducers({
  authentication: authenticationReducer,
  currentUser: currentUserReducer,
});
