/* eslint-disable @typescript-eslint/no-explicit-any */
import { MutableRefObject, useEffect, useImperativeHandle } from 'react';
import { IErrorsSteps } from 'modules/services/interfaces/packages';

interface IFormDataPromise {
  isValidForm: boolean;
  formData: any;
}

interface IReturn {
  onSubmit: (values) => void;
  onNext: () => Promise<boolean>;
  onBack: () => Promise<boolean>;
  triggerWithData: () => Promise<IFormDataPromise>;
  handleStepChange: (formData, formError: boolean) => void;
}

export const useStepsLogic = (
  stepSectionName: string,
  ref: MutableRefObject<any> | ((instance: unknown) => void),
  getValues: () => any,
  trigger: (field?: string) => Promise<boolean>,
  formErrors: IErrorsSteps,
  onPackageSubmit: (data, errors?) => void,
  handleChange: (data, errors?) => void,
  submitAttempted: boolean,
  additionalTriggerTargets: string[] = [],
): IReturn => {
  const triggerWithData = (): Promise<IFormDataPromise> => {
    const formData = getValues();

    // TODO: empty trigger doesn't validate form properly (result differs from the resolver validation)
    return Promise.all(
      [null, ...additionalTriggerTargets].map(field => trigger(field)),
    ).then((results: boolean[]) =>
      Promise.resolve({ isValidForm: results.every(Boolean), formData }),
    );
  };

  const handleStepChange = (formData, formError: boolean) => {
    handleChange({ [stepSectionName]: formData }, { [stepSectionName]: formError });
  };

  const onNext = async (): Promise<boolean> => {
    return triggerWithData().then(({ isValidForm, formData }) => {
      handleStepChange(formData, !isValidForm);
      return isValidForm;
    });
  };

  const onBack = async (): Promise<boolean> => {
    return triggerWithData().then(({ isValidForm, formData }) => {
      handleStepChange(formData, !isValidForm);
      return isValidForm;
    });
  };

  const onSubmit = async () => {
    await triggerWithData().then(({ isValidForm, formData }) => {
      onPackageSubmit({ [stepSectionName]: formData }, { [stepSectionName]: !isValidForm });
    });
  };

  useImperativeHandle(ref, () => ({
    getFormData() {
      return triggerWithData().then(({ isValidForm, formData }) => ({
        formData: { [stepSectionName]: formData },
        errors: { [stepSectionName]: !isValidForm },
      }));
    },
  }));

  useEffect(() => {
    if (submitAttempted) {
      trigger();
    }
  }, [submitAttempted, trigger]);

  return { onSubmit, onNext, onBack, triggerWithData, handleStepChange };
};
