import { batch } from 'react-redux';
import { createAction } from '@reduxjs/toolkit';

import Services from 'services/network';
import { actionTypes } from 'common/state/newPerson/contract/constants';

import { enqueueErrorNotification } from 'common/state/notifications/actions';

import { IServerError } from 'common/interfaces/http';
import { IPersonDocumentItemDto, IPersonDocumentToSignDto } from 'common/interfaces/contract';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { GeneralThunkAction } from 'common/state/interfaces';

import { PeakModules } from 'common/constants/peakModules';
import { ActionResult } from 'common/constants';
import { printHTML } from 'common/state/printHTML/actions';

// actions

export const setSignContractActionResult = createAction<ActionResult | null>(
  actionTypes.SIGN_CONTRACT_ACTION_RESULT,
);

// contract
export const getContractAction = createAction<IPersonDocumentItemDto>(actionTypes.GET_CONTRACT);
export const signContractAction = createAction<IPersonDocumentItemDto>(actionTypes.SIGN_CONTRACT);
export const applyContractAction = createAction<IPersonDocumentItemDto>(actionTypes.APPLY_CONTRACT);

export const clearContractDataAction = createAction(actionTypes.CLEAR_CONTRACT);
export const setErrorContractAction = createAction<IServerError>(actionTypes.SET_ERROR);
export const setIsLoadingContractAction = createAction<boolean>(actionTypes.SET_IS_LOADING);

// thunks

// contract
export const signContractThunk = (
  personId: string,
  payload: IPersonDocumentToSignDto,
  module: PeakModuleForNewPersonType = PeakModules.FrontDesk,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(setIsLoadingContractAction(true));

    let contractData: IPersonDocumentItemDto;

    switch (module) {
      case PeakModules.FrontDesk:
        contractData = await Services.FrontDesk.signContract(personId, payload);
        break;
      case PeakModules.Members:
        contractData = await Services.Members.signContract(personId, payload);
        break;
      case PeakModules.Crm:
        contractData = await Services.Leads.signContract(personId, payload);
        break;
      case PeakModules.PersonalTrainingCrm:
        contractData = await Services.PTLeads.signContract(personId, payload);
        break;
      default:
    }

    batch(() => {
      dispatch(signContractAction(contractData));
      dispatch(setSignContractActionResult(ActionResult.SUCCESS_ACTION));
    });
  } catch (e) {
    dispatch(setErrorContractAction(e));
    dispatch(enqueueErrorNotification(e));
  } finally {
    dispatch(setIsLoadingContractAction(false));
  }
};

export const getPersonContractThunk = (
  personId: string,
  module: PeakModuleForNewPersonType = PeakModules.FrontDesk,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(setIsLoadingContractAction(true));

    let contractData: IPersonDocumentItemDto;

    switch (module) {
      case PeakModules.FrontDesk:
        contractData = await Services.FrontDesk.getPersonContract(personId);
        break;
      case PeakModules.Members:
        contractData = await Services.Members.getPersonContract(personId);
        break;
      case PeakModules.Crm:
        contractData = await Services.Leads.getPersonContract(personId);
        break;
      case PeakModules.PersonalTrainingCrm:
        contractData = await Services.PTLeads.getPersonContract(personId);
        break;
      default:
    }

    dispatch(getContractAction(contractData));
  } catch (e) {
    dispatch(setErrorContractAction(e));
    dispatch(enqueueErrorNotification(e));
  } finally {
    dispatch(setIsLoadingContractAction(false));
  }
};

export const getPersonDocumentById = (
  personId: string,
  documentId: string,
  module: PeakModuleForNewPersonType = PeakModules.FrontDesk,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(setIsLoadingContractAction(true));

    let document: IPersonDocumentItemDto;

    switch (module) {
      case PeakModules.FrontDesk:
        document = await Services.FrontDesk.getPersonDocumentById(personId, documentId);
        break;
      case PeakModules.Members:
        document = await Services.Members.getPersonDocumentById(personId, documentId);
        break;
      case PeakModules.Crm:
        document = await Services.Leads.getPersonDocumentById(personId, documentId);
        break;
      case PeakModules.PersonalTrainingCrm:
        document = await Services.PTLeads.getPersonDocumentById(personId, documentId);
        break;
      default:
    }

    dispatch(getContractAction(document));
  } catch (e) {
    dispatch(setErrorContractAction(e));
    dispatch(enqueueErrorNotification(e));
  } finally {
    dispatch(setIsLoadingContractAction(false));
  }
};

export const applyContractSignatureThunk = (
  personId: string,
  documentId: string,
  file: File,
  module: PeakModuleForNewPersonType = PeakModules.FrontDesk,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(setIsLoadingContractAction(true));

    let contractData: IPersonDocumentItemDto;

    switch (module) {
      case PeakModules.FrontDesk:
        contractData = await Services.FrontDesk.applyContractSignature(personId, documentId, file);
        break;
      case PeakModules.Members:
        contractData = await Services.Members.applyContractSignature(personId, documentId, file);
        break;
      case PeakModules.Crm:
        contractData = await Services.Leads.applyContractSignature(personId, documentId, file);
        break;
      case PeakModules.PersonalTrainingCrm:
        contractData = await Services.PTLeads.applyContractSignature(personId, documentId, file);
        break;
      default:
    }

    dispatch(applyContractAction(contractData));
  } catch (e) {
    dispatch(setErrorContractAction(e));
    dispatch(enqueueErrorNotification(e));
  } finally {
    dispatch(setIsLoadingContractAction(false));
  }
};

const fetchContractHTMLLoading = createAction<boolean>(actionTypes.FETCH_CONTRACT_HTML_LOADING);

export const printContract = (
  personId: string,
  module: PeakModuleForNewPersonType = PeakModules.FrontDesk,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(fetchContractHTMLLoading(true));

    let result: string;

    switch (module) {
      case PeakModules.FrontDesk:
        result = await Services.FrontDesk.printContract(personId);
        break;
      case PeakModules.Members:
        result = await Services.Members.printContract(personId);
        break;
      case PeakModules.Crm:
        result = await Services.Leads.printContract(personId);
        break;
      default:
        result = await Services.PTLeads.printContract(personId);
    }

    dispatch(printHTML(result));
  } catch (e) {
    dispatch(setErrorContractAction(e));
    dispatch(enqueueErrorNotification(e));
  } finally {
    dispatch(fetchContractHTMLLoading(false));
  }
};
