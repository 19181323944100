import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, DialogActions } from '@material-ui/core';

import { ButtonWithCircularProgress } from 'common/components';
import { ReactComponent as ArrowCircleLeftIcon } from 'img/icons/arrow-circle-left.svg';
import { ReactComponent as ArrowCircleRightIcon } from 'img/icons/arrow-circle-right.svg';

import { CloseRegisterSteps } from 'modules/pos-kiosk/constants/registers';

import messages from 'common/messages/messages';

interface IProps {
  currentStep: CloseRegisterSteps;
  onBack: () => void;
  onNext: () => void;
  goBack: () => void;
  isLoading: boolean;
}

const Footer = ({ onBack, onNext, currentStep, goBack, isLoading }: IProps): JSX.Element => {
  const isReconcileBillsStep = currentStep === CloseRegisterSteps.ReconcileBills;
  const isReconcileChecksStep = currentStep === CloseRegisterSteps.ReconcileChecks;
  const isReconcileTotalsStep = currentStep === CloseRegisterSteps.ReconcileTotals;
  const isCloseConfirmationStep = currentStep === CloseRegisterSteps.CloseConfirmation;
  const isLinkToReportStep = currentStep === CloseRegisterSteps.LinkToReport;

  const getSubmitBtnTitle = (): JSX.Element => {
    if (isReconcileTotalsStep || isCloseConfirmationStep) {
      return <FormattedMessage {...messages.proceedBtn} />;
    }

    if (isLinkToReportStep) {
      return <FormattedMessage {...messages.okBtn} />;
    }

    return <FormattedMessage {...messages.nextBtn} />;
  };

  return (
    <DialogActions>
      {!isLinkToReportStep && (
        <Button
          color="primary"
          startIcon={
            isReconcileBillsStep || isCloseConfirmationStep ? null : <ArrowCircleLeftIcon />
          }
          onClick={isReconcileBillsStep || isCloseConfirmationStep ? goBack : onBack}
        >
          <FormattedMessage
            {...(isReconcileBillsStep || isCloseConfirmationStep
              ? messages.cancelBtn
              : messages.backBtn)}
          />
        </Button>
      )}

      <ButtonWithCircularProgress
        endIcon={isReconcileBillsStep || isReconcileChecksStep ? <ArrowCircleRightIcon /> : null}
        isSubmitting={isLoading}
        type="submit"
        onClick={isLinkToReportStep ? goBack : onNext}
      >
        {getSubmitBtnTitle()}
      </ButtonWithCircularProgress>
    </DialogActions>
  );
};

export default Footer;
