import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import { Typography } from '@material-ui/core';

import { AlertTypes } from 'common/interfaces/alerts';
import { IDayTimeAvailabilityDto, Weekday } from 'common/interfaces/common';

import { Alert, AvailabilitiesResultBlock } from 'common/components/index';

import personProfileMessages from 'common/components/PersonProfile/messages';

export const checkIsTimeInServiceAvailabilityRange = (
  availability: IDayTimeAvailabilityDto[],
  selectedDate: string,
  selectedTime: string,
): boolean => {
  const utcTime = moment(selectedTime)
    .utc(true)
    .format('HH:mm');

  const selectedDateTime = moment.tz(`${selectedDate} ${utcTime}`, 'YYYY-MM-DD HH:mm', 'UTC');

  const day = selectedDateTime.day();

  let isInAvailabilityRange = false;

  const selectedDateRanges = availability.find(
    availabilityItem => availabilityItem.weekday === Object.values(Weekday)[day],
  );

  if (!selectedDateRanges) {
    return false;
  }

  if (selectedDateRanges.allDay) {
    return true;
  }

  selectedDateRanges.timeRanges?.forEach(rangeItem => {
    if (isInAvailabilityRange) {
      return;
    }

    const startAvailabilityTime = moment.tz(
      `${selectedDate} ${rangeItem.startTime}`,
      'YYYY-MM-DD HH:mm',
      'UTC',
    );
    const endAvailabilityTime = moment.tz(
      `${selectedDate} ${rangeItem.endTime}`,
      'YYYY-MM-DD HH:mm',
      'UTC',
    );

    if (selectedDateTime.isBetween(startAvailabilityTime, endAvailabilityTime)) {
      isInAvailabilityRange = true;
    }
  });

  return isInAvailabilityRange;
};

interface IProps {
  availability: IDayTimeAvailabilityDto[];
}

const ServiceAvailabilityHoursAlert = ({ availability }: IProps): JSX.Element => {
  return (
    <Alert
      severity={AlertTypes.Warning}
      title={
        <Typography>
          <FormattedMessage
            values={{
              ranges: <AvailabilitiesResultBlock availabilities={availability} />,
            }}
            {...personProfileMessages.outOfServiceAvailabilityMessage}
          />
        </Typography>
      }
    />
  );
};

export default ServiceAvailabilityHoursAlert;
