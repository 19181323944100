import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MenuItem } from '@material-ui/core';

import { Message } from '../interfaces/common';
import { IFilterOption } from '../interfaces/filter';

export interface IConstOption<V = string, K = string> {
  // Required
  // if no key, were specified, value will be used for key
  key?: K;
  value: V;
  message: Message;
  // Optional, for different situations
  label?: string;
  color?: string;
}

export interface ISelectOptions {
  formatter?: (message: Message) => JSX.Element | string;
  styleClass?: string;
  onClick?: (value) => void;
  isDisabled?: boolean;
  isDisabledFunc?: (value: string) => boolean;
  value?: string;
}

export interface IFilterOptions {
  labelFormatter?: (message: Message) => JSX.Element | string;
  valueFormatter?: (value: string) => any;
}

const BLANK_JSX_ELEMENT: JSX.Element = <>-</>;

export class ConstOptions {
  readonly values: IConstOption[];

  public color(value: string): string {
    return this.find(value)?.color;
  }

  constructor(values: IConstOption[]) {
    this.values = values;
  }

  public find(value: string): IConstOption {
    return this.values?.find(option => option.value === value);
  }

  public message(value: string): Message {
    return this.find(value)?.message;
  }

  public translate(
    value: string,
    values?: Record<string, string | number>,
    defaultTranslate?: JSX.Element,
  ): JSX.Element {
    const message = this.message(value);
    const messageValues = message && values ? { values } : {};

    return message ? (
      <FormattedMessage {...message} {...messageValues} />
    ) : (
      defaultTranslate || BLANK_JSX_ELEMENT
    );
  }

  public stringValues(): string[] {
    return this.values.map(el => el.value);
  }

  public getSelectOptions<T extends string>({
    formatter = message => <FormattedMessage {...message} />,
    styleClass = undefined,
    onClick = undefined,
    isDisabled = undefined,
    isDisabledFunc = undefined,
    value = undefined,
  }: ISelectOptions = {}): JSX.Element[] {
    return this.values?.map((el: IConstOption<T>) => (
      <MenuItem
        key={el.key || el.value}
        value={el.value}
        className={styleClass}
        onClick={() => onClick && onClick(el.value)}
        disabled={isDisabled || (isDisabledFunc && isDisabledFunc(el.value))}
        selected={el.value === value}
      >
        {formatter(el.message)}
      </MenuItem>
    ));
  }

  public getFilterOptions({
    labelFormatter = message => <FormattedMessage {...message} />,
    valueFormatter = value => value,
  }: IFilterOptions = {}): IFilterOption[] {
    return this.values.map(el => ({
      key: el.key || el.value,
      label: labelFormatter(el.message),
      value: valueFormatter(el.value),
    }));
  }

  public get random(): IConstOption {
    return this.values[Math.floor(Math.random() * this.values.length)];
  }

  public get first(): IConstOption {
    return this.values.shift();
  }
}
