import { defineMessages } from 'react-intl';

export default defineMessages({
  // routes
  overview: {
    id: 'app.modules.reports.routes.overview',
    defaultMessage: 'Overview',
  },
  itemService: {
    id: 'app.components.invoice.header.cell.service.title',
    defaultMessage: 'inventories/service bundles/fees',
  },
  unit: {
    id: 'app.components.invoice.header.cell.unit.title',
    defaultMessage: 'unit',
  },
  currentTotalText: {
    id: 'app.components.invoice.current.total.text',
    defaultMessage: 'Current total',
  },
  totalTitle: {
    id: 'app.components.invoice.header.cell.total.amount.title',
    defaultMessage: 'total',
  },
  unlimited: {
    id: 'app.components.invoice.unlimited',
    defaultMessage: '(Unlimited)',
  },
  register: {
    id: 'app.components.invoice.header.cell.register',
    defaultMessage: 'register',
  },
  employeeOpened: {
    id: 'app.components.invoice.header.cell.employeeOpened',
    defaultMessage: 'Employee Opened',
  },
  timeOpened: {
    id: 'app.components.invoice.header.cell.timeOpened',
    defaultMessage: 'Time Opened',
  },
  timeClosed: {
    id: 'app.components.invoice.header.cell.timeClosed',
    defaultMessage: 'Time Closed',
  },
  employeeClosed: {
    id: 'app.components.invoice.header.cell.employeeClosed',
    defaultMessage: 'Employee Closed',
  },
  net: {
    id: 'app.components.invoice.header.cell.net',
    defaultMessage: 'Net',
  },
  approved: {
    id: 'app.components.transaction.status.title.approved',
    defaultMessage: 'Approved',
  },
  declined: {
    id: 'app.components.transaction.status.title.declined',
    defaultMessage: 'Declined',
  },
  notProcessed: {
    id: 'app.components.transaction.status.title.notProcessed',
    defaultMessage: 'Not Processed',
  },
  paymentMethod: {
    id: 'app.components.invoice.header.cell.paymentMethod',
    defaultMessage: 'Payment method',
  },
  netSales: {
    id: 'app.components.invoice.header.cell.netSales',
    defaultMessage: 'Net Sales',
  },
  variances: {
    id: 'app.components.invoice.header.cell.variances',
    defaultMessage: 'Variances',
  },
  note: {
    id: 'app.components.invoice.header.cell.note',
    defaultMessage: 'Note',
  },
  overShort: {
    id: 'app.components.invoice.header.cell.overShort',
    defaultMessage: 'Over/Short',
  },
  actual: {
    id: 'app.components.invoice.header.cell.actual',
    defaultMessage: 'Actual',
  },
  exempt: {
    id: 'app.components.invoice.header.cell.exempt',
    defaultMessage: 'Exempt',
  },
  expected: {
    id: 'app.components.invoice.header.cell.expected',
    defaultMessage: 'Expected',
  },
  refunds: {
    id: 'app.components.invoice.header.cell.refunds',
    defaultMessage: 'Refunds',
  },
  open: {
    id: 'app.components.invoice.header.cell.open',
    defaultMessage: 'Open',
  },
  qty: {
    id: 'app.components.invoice.header.cell.qty.title',
    defaultMessage: 'qty',
  },
  cashSummaryTitle: {
    id: 'app.components.cashSummaryTitle',
    defaultMessage: 'CASH SUMMARY',
  },
  invoicesSummaryTitle: {
    id: 'app.components.invoicesSummaryTitle',
    defaultMessage: 'INVOICES SUMMARY',
  },
  paymentsSummaryTitle: {
    id: 'app.components.paymentsSummaryTitle',
    defaultMessage: 'PAYMENTS SUMMARY',
  },
  orders: {
    id: 'app.components.orders',
    defaultMessage: 'Orders',
  },
  amount: {
    id: 'app.components.amount',
    defaultMessage: 'Amount',
  },
  sales: {
    id: 'app.components.sales',
    defaultMessage: 'Sales',
  },
  total: {
    id: 'app.components.total',
    defaultMessage: 'Total',
  },
  servicesSummaryTitle: {
    id: 'app.components.servicesSummaryTitle',
    defaultMessage: 'SERVICE MODE SUMMARY',
  },
  taxSummaryTitle: {
    id: 'app.components.taxSummaryTitle',
    defaultMessage: 'TAX SUMMARY',
  },
  salesCategorySummaryTitle: {
    id: 'app.components.salesCategorySummaryTitle',
    defaultMessage: 'SALES CATEGORY SUMMARY',
  },
  summaryTitle: {
    id: 'app.components.summaryTitle',
    defaultMessage: 'Summary',
  },
  registersSummaryTitle: {
    id: 'app.components.registersSummaryTitle',
    defaultMessage: 'REGISTERS SUMMARY',
  },
  revenuCodeSummaryTitle: {
    id: 'app.components.revenuCodeSummaryTitle',
    defaultMessage: 'REVENUE CODE SUMMARY',
  },
  serviceModeSummaryTitle: {
    id: 'app.components.serviceModeSummaryTitle',
    defaultMessage: 'SERVICE MODE SUMMARY',
  },
  paidWithTitle: {
    id: 'app.components.invoice.payment.title',
    defaultMessage: 'Paid with {title}',
  },
  snapshotsTitle: {
    id: 'app.modules.reports.invoice.history.report.snapshots.modal.title',
    defaultMessage: 'Invoice History Report Snapshots',
  },
  viewReport: {
    id: 'app.modules.reports.actions.view.report',
    defaultMessage: 'View Report',
  },
  deleteSnapshotTitle: {
    id: 'app.modules.reports.invoice.history.snapshots.actions.delete.title',
    defaultMessage: 'Delete',
  },
  errorTextSnapshot: {
    id: 'app.modules.reports.invoice.history.snapshots.error.text',
    defaultMessage: 'File unavailable',
  },
  downloadSnapshotTitle: {
    id: 'app.modules.reports.invoice.history.snapshots.actions.download.title',
    defaultMessage: 'Download',
  },
  recipientsTotalsTitle: {
    id: 'app.modules.reports.campaign.report.totals.recipients.title',
    defaultMessage: 'Recipients',
  },
  successfulDeliveriesTotalsTitle: {
    id: 'app.modules.reports.campaign.report.totals.successful.deliveries.title',
    defaultMessage: 'Successful deliveries',
  },
  openRateTotalsTitle: {
    id: 'app.modules.reports.campaign.report.totals.open.rate.title',
    defaultMessage: 'Open rate',
  },
  totalOpensTotalsTitle: {
    id: 'app.modules.reports.campaign.report.totals.total.opens.title',
    defaultMessage: 'Total opens',
  },
  clickRateTotalsTitle: {
    id: 'app.modules.reports.campaign.report.totals.click.rate.title',
    defaultMessage: 'Click rate',
  },
  totalClicksTotalsTitle: {
    id: 'app.modules.reports.campaign.report.totals.clicks.rate.title',
    defaultMessage: 'Total clicks',
  },
  bouncedTotalsTitle: {
    id: 'app.modules.reports.campaign.report.totals.bounced.title',
    defaultMessage: 'Bounced',
  },
  unsubscribedTotalsTitle: {
    id: 'app.modules.reports.campaign.report.totals.unsubscribed.title',
    defaultMessage: 'Unsubscribed',
  },
  salesPosReport: {
    id: 'app.modules.reports.routes.sales',
    defaultMessage: 'Sales',
  },
  zOut: {
    id: 'app.modules.reports.routes.zOutTitle',
    defaultMessage: 'Z-Out',
  },
  scheduledBillings: {
    id: 'app.modules.reports.routes.scheduledBillings',
    defaultMessage: 'Scheduled Billings ',
  },
  unredeemedDate: {
    id: 'app.modules.reports.redemptions.unredeemedDate',
    defaultMessage: 'Unredeemed date: {date}',
  },
  unredeemedBy: {
    id: 'app.modules.reports.redemptions.unredeemedBy',
    defaultMessage: 'Unredeemed by',
  },
  bulkUnredeem: {
    id: 'app.modules.reports.redemptions.bulkUnredeem',
    defaultMessage: 'Bulk unredeem',
  },
  totalRedeemed: {
    id: 'app.modules.reports.redemptions.totalRedeemed',
    defaultMessage: 'Total Redeemed',
  },
  totalUnredeemed: {
    id: 'app.modules.reports.redemptions.totalUnredeemed',
    defaultMessage: 'Total Unredeemed',
  },
  salesOverTimeReport: {
    id: 'app.modules.reports.routes.overTimeReport',
    defaultMessage: 'Over time',
  },
  registerStatusReport: {
    id: 'app.modules.reports.routes.registerStatus',
    defaultMessage: 'Registers status',
  },
  paymentTransactionsReport: {
    id: 'app.modules.reports.routes.transactionsReport',
    defaultMessage: 'Transactions',
  },
  dashboard: {
    id: 'app.modules.reports.routes.dashboard',
    defaultMessage: 'Dashboard',
  },
  campaignReport: {
    id: 'app.modules.reports.routes.campaign',
    defaultMessage: 'Campaigns',
  },
  appointmentsReport: {
    id: 'app.modules.reports.routes.appointments',
    defaultMessage: 'Appointments',
  },
  sessionRedemptionReport: {
    id: 'app.modules.reports.routes.sessionRedemption',
    defaultMessage: 'Redemptions',
  },
  checkInHistory: {
    id: 'app.modules.reports.routes.checkInHistory',
    defaultMessage: 'Check In History',
  },
  membersCheckIn: {
    id: 'app.modules.reports.routes.membersCheckIn',
    defaultMessage: 'Members Check In',
  },
  kidZoneCheckIn: {
    id: 'app.modules.reports.routes.kidZoneCheckIn',
    defaultMessage: 'KidZone Check In',
  },
  kidZoneCheckInHistory: {
    id: 'app.modules.reports.routes.kidZoneCheckInHistory',
    defaultMessage: 'KidZone Check In History',
  },
  dialogAdjustInvoiceTitle: {
    id: 'app.reports.adjust.dialog.title',
    defaultMessage: 'Edit Invoice Total',
  },
  employee: {
    id: 'app.reports.emoloyee',
    defaultMessage: 'Employee',
  },
  member: {
    id: 'app.reports.member',
    defaultMessage: 'Member',
  },
  revCode: {
    id: 'app.reports.revCode',
    defaultMessage: 'Rev Code',
  },
  address: {
    id: 'app.reports.address',
    defaultMessage: 'Address',
  },
  active: {
    id: 'app.reports.active',
    defaultMessage: 'Active',
  },
  completed: {
    id: 'app.reports.completed',
    defaultMessage: 'Completed',
  },
  archived: {
    id: 'app.reports.archived',
    defaultMessage: 'Archived',
  },
  inactive: {
    id: 'app.reports.inactive',
    defaultMessage: 'Inactive',
  },
  canceled: {
    id: 'app.reports.canceled',
    defaultMessage: 'Canceled',
  },
  phone: {
    id: 'app.reports.phone',
    defaultMessage: 'Phone',
  },
  email: {
    id: 'app.reports.email',
    defaultMessage: 'Email',
  },
  sms: {
    id: 'app.reports.sms',
    defaultMessage: 'Sms',
  },
  salesperson: {
    id: 'app.reports.salesperson',
    defaultMessage: 'Salesperson',
  },
  customer: {
    id: 'app.reports.customer',
    defaultMessage: 'Customer',
  },
  createdDatetime: {
    id: 'app.reports.createdDatetime',
    defaultMessage: 'Created Datetime',
  },
  paidDatetime: {
    id: 'app.reports.paidDatetime',
    defaultMessage: 'Paid Datetime',
  },
  subtotal: {
    id: 'app.reports.subtotal',
    defaultMessage: 'Subtotal',
  },
  totalItem: {
    id: 'app.reports.total',
    defaultMessage: 'Total',
  },
  discounts: {
    id: 'app.reports.discounts',
    defaultMessage: 'Discounts',
  },
  taxes: {
    id: 'app.reports.taxes',
    defaultMessage: 'Taxes',
  },
  activity: {
    id: 'app.reports.activity',
    defaultMessage: 'Activity',
  },
  appointments: {
    id: 'app.reports.appointments',
    defaultMessage: 'APPOINTMENTS',
  },
  service: {
    id: 'app.reports.service',
    defaultMessage: 'SERVICE',
  },
  inventory: {
    id: 'app.reports.inventory',
    defaultMessage: 'INVENTORY',
  },
  servicedBy: {
    id: 'app.reports.servicedBy',
    defaultMessage: 'SERVICED BY',
  },
  redeemedBy: {
    id: 'app.reports.redeemedBy',
    defaultMessage: 'Redeemed By',
  },
  custom: {
    id: 'app.reports.custom',
    defaultMessage: 'Custom',
  },
  familyMember: {
    id: 'app.reports.familyMember',
    defaultMessage: 'Family member',
  },
  personalTraining: {
    id: 'app.reports.personalTraining',
    defaultMessage: 'Personal training',
  },
  dayCare: {
    id: 'app.reports.dayCare',
    defaultMessage: 'Day care',
  },
  backlog: {
    id: 'app.reports.backlog',
    defaultMessage: 'BACKLOG',
  },
  voidReason: {
    id: 'app.reports.voidReason',
    defaultMessage: 'Void Reason',
  },
  conversionValue: {
    id: 'app.reports.conversion.value',
    defaultMessage: 'Conversion value',
  },
  membershipsSold: {
    id: 'app.reports.memberships.sold',
    defaultMessage: 'Memberships sold',
  },
  leadsContactedStat: {
    id: 'app.reports.crm.dashboard.stat.leads.contacted',
    defaultMessage: 'Leads contacted',
  },
  leadsAppointmentsStat: {
    id: 'app.reports.crm.dashboard.stat.leads.appointments',
    defaultMessage: 'Leads appointments',
  },
  membersContactedStat: {
    id: 'app.reports.crm.dashboard.stat.members.contacted',
    defaultMessage: 'Members contacted',
  },
  membersAppointmentsStat: {
    id: 'app.reports.crm.dashboard.stat.members.appointments',
    defaultMessage: 'Members appointments',
  },
  salesStat: {
    id: 'app.reports.crm.dashboard.stat.sales',
    defaultMessage: 'Sales',
  },
  averageDaysToSellStat: {
    id: 'app.reports.crm.dashboard.stat.average.days.to.sell',
    defaultMessage: 'Avg. days to sell amount',
  },
  targetAudienceFilteringTitle: {
    id: 'app.modules.reports.campaign.report.target.audience.filtering.title',
    defaultMessage: 'TARGET AUDIENCE FILTERING',
  },
  runningConditionsTitle: {
    id: 'app.modules.reports.campaign.report.running.conditions.title',
    defaultMessage: 'RUNNING CONDITIONS',
  },
  listOfRecipientsTitle: {
    id: 'app.modules.reports.campaign.report.list.of.recipients.title',
    defaultMessage: 'LIST OF RECIPIENTS',
  },
  exportReportTitle: {
    id: 'app.reports.exportReportTitle',
    defaultMessage: 'Export report',
  },
  assignMemberTitle: {
    id: 'app.reports.assignMemberTitle',
    defaultMessage: 'Assign member',
  },
  sessionType: {
    id: 'app.reports.serviceTypeTitle',
    defaultMessage: 'Service type',
  },
  income: {
    id: 'app.reports.incomeTitle',
    defaultMessage: 'Income',
  },
  paid: {
    id: 'app.reports.paid',
    defaultMessage: 'Paid',
  },
  pastDue: {
    id: 'app.reports.pastDueTitle',
    defaultMessage: 'Past due',
  },
  billingSchedule: {
    id: 'app.reports.billingScheduleTitle',
    defaultMessage: 'Scheduled billing',
  },
  checkout: {
    id: 'app.reports.status.checkout.title',
    defaultMessage: 'Check out',
  },
  openStatus: {
    id: 'app.reports.status.open.title',
    defaultMessage: 'Open',
  },
  pending: {
    id: 'app.reports.status.pending.title',
    defaultMessage: 'Pending',
  },
  unpaid: {
    id: 'app.reports.status.unpaid.title',
    defaultMessage: 'Unpaid',
  },
  voided: {
    id: 'app.reports.status.voided.title',
    defaultMessage: 'Voided',
  },
  deferred: {
    id: 'app.reports.status.deferred.title',
    defaultMessage: 'Deferred',
  },
  inProgress: {
    id: 'app.reports.status.in.progress.title',
    defaultMessage: 'In Progress',
  },
  saleInternalStatusTypeTitle: {
    id: 'app.reports.status.in.saleInternalStatusTypeTitle.sale.title',
    defaultMessage: 'Sale',
  },
  creditInternalStatusTypeTitle: {
    id: 'app.reports.status.in.creditInternalStatusTypeTitle.credit.title',
    defaultMessage: 'Credit',
  },
  successfulInternalStatus: {
    id: 'app.reports.status.in.internalStatus.successful.title',
    defaultMessage: 'Successful',
  },
  failureInternalStatus: {
    id: 'app.reports.status.in.internalStatus.failure.title',
    defaultMessage: 'Failure',
  },
  appointmentReportCancelledStatus: {
    id: 'app.reports.status.appointments.statuses.cancelled',
    defaultMessage: 'Cancelled',
  },
  appointmentReportDeletedStatus: {
    id: 'app.reports.status.appointments.statuses.deleted',
    defaultMessage: 'Deleted',
  },
  // total overview labels
  totalMembersLabel: {
    id: 'app.reports.overviewReport.totalsBlock.totalMembers.label',
    defaultMessage: 'Total members',
  },
  totalRevenueLabel: {
    id: 'app.reports.overviewReport.totalsBlock.totalRevenue.label',
    defaultMessage: 'Total revenue',
  },
  membersThatHaveLeftOverLabel: {
    id: 'app.reports.overviewReport.totalsBlock.membersThatHaveLeftOver.label',
    defaultMessage: 'Members that have left over the period of time',
  },
  clubUsageLabel: {
    id: 'app.reports.overviewReport.totalsBlock.clubUsage.label',
    defaultMessage: 'Club usage',
  },
  redeemedSessionsNumberLabel: {
    id: 'app.reports.overviewReport.totalsBlock.redeemedSessionsNumber.label',
    defaultMessage: 'Number of sessions redeemed',
  },
  salesDrivenLabel: {
    id: 'app.reports.overviewReport.chart.salesDriven.label',
    defaultMessage: 'Sales Driven',
  },
  memberCountLabel: {
    id: 'app.reports.overviewReport.chart.memberCount.label',
    defaultMessage: 'Member Count',
  },
  membershipMixLabel: {
    id: 'app.reports.overviewReport.chart.membershipMix.label',
    defaultMessage: 'Membership Mix',
  },
  memberSalesLabel: {
    id: 'app.reports.overviewReport.chart.legend.memberSales.label',
    defaultMessage: 'Member Sales',
  },
  ptSalesLabel: {
    id: 'app.reports.overviewReport.chart.legend.ptSales.label',
    defaultMessage: 'PT Sales',
  },
  gainingLabel: {
    id: 'app.reports.overviewReport.chart.legend.gaining.label',
    defaultMessage: 'Gaining',
  },
  loosingLabel: {
    id: 'app.reports.overviewReport.chart.legend.loosing.label',
    defaultMessage: 'Loosing',
  },
  financedInTermLabel: {
    id: 'app.reports.overviewReport.chart.legend.financedInTerm.label',
    defaultMessage: 'Financed in term',
  },
  financedOutOfTermLabel: {
    id: 'app.reports.overviewReport.chart.legend.financedOutOfTerm.label',
    defaultMessage: 'Financed out of term',
  },

  // Scheduled billings report

  billingsLabel: {
    id: 'app.reports.scheduledBillingsReport.billings.label',
    defaultMessage: 'Billings',
  },
  paidBillingSchedulesLabel: {
    id: 'app.reports.scheduledBillingsReport.totalsBlock.paidBillingSchedules.label',
    defaultMessage: 'Paid (Billing Schedules)',
  },
  unpaidBillingSchedulesLabel: {
    id: 'app.reports.scheduledBillingsReport.totalsBlock.unpaidBillingSchedules.label',
    defaultMessage: 'Unpaid (Billing Schedules)',
  },
  totalBillingSchedulesLabel: {
    id: 'app.reports.scheduledBillingsReport.totalsBlock.totalBillingSchedules.label',
    defaultMessage: 'Total Billing Schedules',
  },
  paidPastDuesLabel: {
    id: 'app.reports.scheduledBillingsReport.totalsBlock.paidPastDues.label',
    defaultMessage: 'Paid (Past Dues)',
  },
  unpaidPastDuesLabel: {
    id: 'app.reports.scheduledBillingsReport.totalsBlock.unpaidPastDues.label',
    defaultMessage: 'Unpaid (Past Dues)',
  },
  totalPastDuesLabel: {
    id: 'app.reports.scheduledBillingsReport.totalsBlock.totalPastDues.label',
    defaultMessage: 'Total Past Dues',
  },
});
