import React from 'react';
import { Grid, makeStyles, Box, IconButton, Typography } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { Theme } from '@material-ui/core/styles';

import useSmallScreen from 'common/hooks/useSmallScreen';
import { GridSpacing } from '@material-ui/core/Grid/Grid';

const useStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1, 0),
  },
  backButton: {
    display: 'inline-block',
    marginRight: theme.spacing(1),
    padding: 0,
    borderRadius: '50%',
    color: '#fff',
    backgroundColor: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.text.primary,
    },
  },
}));

export interface IToolbarProps {
  title: string | JSX.Element;
  className?: string;
  addButton?: JSX.Element;
  onBackButtonClick?: () => void;
  spacing?: GridSpacing;
}

const PageHeader = (props: IToolbarProps): JSX.Element => {
  const classes = useStyles();
  const isSmallScreen = useSmallScreen(1024);

  const { title, addButton, className, onBackButtonClick, spacing = 2 } = props;

  const renderAddButton = () => {
    return addButton ? <Grid item>{addButton}</Grid> : <></>;
  };

  return (
    <Box className={className}>
      <Grid container justifyContent="space-between" alignItems="center" spacing={spacing}>
        <Grid item>
          <Box className={classes.titleContainer}>
            {onBackButtonClick && (
              <IconButton
                color="primary"
                className={classes.backButton}
                aria-label="back"
                onClick={onBackButtonClick}
              >
                <ArrowBackIcon style={{ fontSize: 16 }} />
              </IconButton>
            )}

            <Typography component="h1" variant="h2">
              {title}
            </Typography>
          </Box>
        </Grid>

        {isSmallScreen && renderAddButton()}
      </Grid>
    </Box>
  );
};

export default PageHeader;
