// libraries
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import { Box, Divider, makeStyles, Typography } from '@material-ui/core';
// interfaces
import { IHeadCell, ITableParams, ITableRow } from 'common/interfaces/table';
import { IRequestResultImt } from 'common/components/PersonProfile/interfaces';
import { IPackageListItemImt, IPaymentOption } from '../../interfaces/packages';
import { IPageMetaImt } from 'common/interfaces/pagination';
import { FilterTypes, IFilterSettings } from 'common/interfaces/filter';
// constants
import {
  QueryPageList,
  ActiveInactiveStatus,
  TableOrderByParams,
  ActionResult,
  YesNoStatus,
} from 'common/constants';
import { PackageTypes } from 'modules/services/constants/packages';
// components
import { ServicesPageWrapper } from 'modules/services/components';
import {
  ActionItem,
  ActionsMenu,
  DialogComponent,
  ScrollBox,
  StatusLabel,
  Table,
  ViewMoreItemsMenu,
} from 'common/components';
// icons
import { ReactComponent as XDeleteIcon } from 'img/icons/times.svg';
import { ReactComponent as EditIcon } from 'img/icons/edit.svg';
import { ReactComponent as CopyIcon } from 'img/icons/copy.svg';
import { ReactComponent as CheckIcon } from 'img/icons/check.svg';
// utils
import { updateQueryParams } from 'common/state/queryPage-lists/actions';
import { makeTableParams, pushQueryToUrl } from 'common/utils/http';
import { useAppDispatch } from 'store/hooks';
// redux
import {
  selectChangeStatusRequestState,
  selectCloneRequestState,
  selectPackagesList,
  selectPackagesListLoading,
  selectPackagesListMeta,
} from 'modules/services/state/packages/selectors';
import * as actions from 'modules/services/state/packages/actions';
// messages
import tableHeaders from 'common/messages/tableHeaders';
import commonMessages from 'common/messages/messages';
import menuItems from 'common/messages/menuItems';
import messages from 'modules/services/messages/messages';
import inputLabels from 'common/messages/inputLabels';
import { formatNumberToPrice } from 'common/utils';

const headerOptions: IHeadCell[] = [
  {
    id: TableOrderByParams.TITLE,
    label: <FormattedMessage {...tableHeaders.name} />,
    sort: true,
    padding: 'none',
  },
  {
    id: 'paymentOptions',
    label: <FormattedMessage {...tableHeaders.paymentOptions} />,
    sort: false,
  },
  {
    id: TableOrderByParams.PACKAGE_TYPE,
    label: <FormattedMessage {...tableHeaders.packageType} />,
    sort: true,
  },
  {
    id: TableOrderByParams.MEMBERSHIP,
    label: <FormattedMessage {...tableHeaders.membership} />,
    sort: true,
  },
  {
    id: TableOrderByParams.ACTIVE,
    label: <FormattedMessage {...tableHeaders.status} />,
    sort: true,
  },
  {
    id: 'menu',
    label: <FormattedMessage {...tableHeaders.actions} />,
    sort: false,
    align: 'center',
    padding: 'none',
  },
];

const tableFilters: IFilterSettings[] = [
  {
    name: 'active',
    title: <FormattedMessage {...inputLabels.status} />,
    type: FilterTypes.SINGLE,
    options: ActiveInactiveStatus.map(status => ({
      key: status.key,
      label: status.label,
      value: status.value,
    })),
    defaultValue: ActiveInactiveStatus[0],
  },
  {
    name: 'packageType',
    title: <FormattedMessage {...inputLabels.type} />,
    type: FilterTypes.SINGLE,
    options: PackageTypes.values.map(packageType => ({
      key: packageType.key,
      label: <FormattedMessage {...packageType.message} />,
      value: packageType.value,
    })),
  },
  {
    name: 'membership',
    title: <FormattedMessage {...commonMessages.membership} />,
    type: FilterTypes.SINGLE,
    options: YesNoStatus.map(status => ({
      key: status.key,
      label: status.label,
      value: status.value,
    })),
  },
];

const useStyles = makeStyles({
  icon: {
    width: 16,
    height: 16,
  },
});

const PackagesList = (): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const packagesList: ImmutableList<IPackageListItemImt> = useSelector(selectPackagesList());
  const packagesListMeta: IPageMetaImt = useSelector(selectPackagesListMeta());
  const isPackagesListLoading: boolean = useSelector(selectPackagesListLoading());
  const changeStatusRequestState: IRequestResultImt = useSelector(selectChangeStatusRequestState());
  const cloneRequestState: IRequestResultImt = useSelector(selectCloneRequestState());

  const location = useLocation();
  const navigate = useNavigate();

  const [selectedPackage, setSelectedPackage] = useState(null);
  const [showCloneDialog, setShowCloneDialog] = useState(false);
  const [showChangeStatusDialog, setShowChangeStatusDialog] = useState(false);
  const [tableParams, setTableParams] = useState(() =>
    makeTableParams(tableFilters, location.search, {
      orderBy: TableOrderByParams.TITLE,
    }),
  );

  const classes = useStyles();

  const isActivePackage = !!selectedPackage && selectedPackage.get('active');
  const packageTitle = !!selectedPackage && selectedPackage.get('title');

  // handlers
  const handleChangeStatusResult = useCallback(() => {
    setShowChangeStatusDialog(false);

    dispatch(actions.resetChangePackageStatus());
    dispatch(actions.fetchPackagesList(tableParams));
  }, [dispatch, tableParams]);

  const handleClonePackageResult = useCallback(() => {
    setShowCloneDialog(false);

    dispatch(actions.resetClonePackage());
    dispatch(actions.fetchPackagesList(tableParams));
  }, [dispatch, tableParams]);

  const handleToggleStatusDialog = useCallback((sPackage: IPackageListItemImt): void => {
    setShowChangeStatusDialog(true);
    setSelectedPackage(sPackage);
  }, []);

  const handleToggleCloneDialog = useCallback((sPackage: IPackageListItemImt): void => {
    setShowCloneDialog(true);
    setSelectedPackage(sPackage);
  }, []);

  const updateQueryFunction = useCallback(
    query => dispatch(updateQueryParams({ page: QueryPageList.SERVICE_PACKAGES, query })),
    [dispatch],
  );

  const handleChangeTableProps = useCallback(
    (tableProps: ITableParams): void => {
      setTableParams(tableProps);
      pushQueryToUrl(navigate, tableProps, updateQueryFunction);
    },
    [navigate, updateQueryFunction],
  );

  // lifecycle

  useEffect(() => {
    dispatch(actions.fetchPackagesList(tableParams));
  }, [dispatch, tableParams]);

  useEffect(() => {
    if (changeStatusRequestState?.get('result')) {
      handleChangeStatusResult();
    }

    if (cloneRequestState?.get('result') === ActionResult.SUCCESS_ACTION) {
      handleClonePackageResult();
    }
  }, [
    changeStatusRequestState,
    cloneRequestState,
    handleChangeStatusResult,
    handleClonePackageResult,
  ]);

  useEffect(() => {
    return () => {
      dispatch(actions.resetChangePackageStatus());
      dispatch(actions.resetClonePackage());
    };
  }, [dispatch]);

  const renderBillingOption = useCallback((option: IPaymentOption) => {
    return (
      <>
        {option.frequency === null ? (
          <>
            {`${option.title}, $${formatNumberToPrice(option.totalAmount)} `}
            <FormattedMessage {...tableHeaders.total} />
          </>
        ) : (
          <>
            {`${option.title}, ${option.frequency.toLowerCase()}, $${formatNumberToPrice(
              option.perPayment,
            )} `}
            <FormattedMessage {...tableHeaders.perPayment} />
            {`, $${formatNumberToPrice(option.totalAmount)} `}
            <FormattedMessage {...tableHeaders.total} />
          </>
        )}
      </>
    );
  }, []);

  const tableRows = useMemo(
    () =>
      packagesList
        .map(
          (sPackage: IPackageListItemImt): ITableRow => ({
            id: sPackage.get('id'),
            cells: [
              {
                label: sPackage.get('title'),
                variant: 'h5',
                ellipsis: true,
              },
              {
                label: '',
                cellComponent: sPackage.get('billingOptions').size ? (
                  <ViewMoreItemsMenu
                    options={sPackage.get('billingOptions').toJS()}
                    renderOption={renderBillingOption}
                  />
                ) : (
                  '-'
                ),
              },
              {
                label: `${PackageTypes.translate(sPackage.get('type'))}`,
                cellComponent: (
                  <Typography>{PackageTypes.translate(sPackage.get('type'))}</Typography>
                ),
              },
              {
                label: 'membership',
                cellComponent: (
                  <Typography>
                    <FormattedMessage
                      {...(sPackage.get('membership')
                        ? commonMessages.yesOption
                        : commonMessages.noOption)}
                    />
                  </Typography>
                ),
              },
              {
                label: 'status',
                cellComponent: <StatusLabel isActive={sPackage.get('active')} />,
              },
              {
                label: 'menu',
                align: 'center',
                padding: 'none',
                width: '54px',
                cellComponent: (
                  <ActionsMenu horizontal tableActionsMode>
                    <ActionItem
                      key={`duplicate-${sPackage.get('id')}-package`}
                      icon={<CopyIcon className={classes.icon} />}
                      onClick={() => handleToggleCloneDialog(sPackage)}
                    >
                      <FormattedMessage {...commonMessages.duplicateOption} />
                    </ActionItem>
                    <ActionItem
                      key={`package-${sPackage.get('id')}-configuration`}
                      icon={<EditIcon className={classes.icon} />}
                      onClick={() => navigate(`/services/service-packages/${sPackage.get('id')}`)}
                    >
                      <FormattedMessage {...commonMessages.editBtn} />
                    </ActionItem>
                    <Divider key={`package-${sPackage.get('id')}-divider`} />
                    <ActionItem
                      key={`delete-${sPackage.get('id')}-package`}
                      icon={
                        sPackage.get('active') ? (
                          <XDeleteIcon className={classes.icon} />
                        ) : (
                          <CheckIcon className={classes.icon} />
                        )
                      }
                      onClick={() => handleToggleStatusDialog(sPackage)}
                    >
                      <FormattedMessage
                        {...(sPackage.get('active') ? menuItems.deactivate : menuItems.activate)}
                      />
                    </ActionItem>
                  </ActionsMenu>
                ),
              },
            ],
          }),
        )
        .toJS(),
    [
      handleToggleCloneDialog,
      handleToggleStatusDialog,
      navigate,
      packagesList,
      renderBillingOption,
      classes.icon,
    ],
  );

  const modals = (
    <>
      {showChangeStatusDialog && (
        <DialogComponent
          title={
            packageTitle ? (
              <FormattedMessage
                values={{ name: packageTitle }}
                {...(isActivePackage ? commonMessages.deactivateItem : commonMessages.activateItem)}
              />
            ) : (
              ''
            )
          }
          submitBtnTitle={
            <FormattedMessage
              {...(isActivePackage ? commonMessages.deactivateBtn : commonMessages.activateBtn)}
            />
          }
          isOpen
          loading={!!changeStatusRequestState && changeStatusRequestState.get('isLoading')}
          disableFullScreenBehavior
          onSubmit={() =>
            dispatch(actions.changePackageStatus(selectedPackage.get('id'), !isActivePackage))
          }
          onClose={() => {
            setShowChangeStatusDialog(false);
            setSelectedPackage(null);
          }}
        />
      )}

      {showCloneDialog && (
        <DialogComponent
          isOpen
          title={
            <FormattedMessage values={{ name: packageTitle }} {...messages.duplicatePackageTitle} />
          }
          submitBtnTitle={<FormattedMessage {...commonMessages.duplicateOption} />}
          loading={!!cloneRequestState && cloneRequestState.get('isLoading')}
          disableFullScreenBehavior
          onSubmit={() => dispatch(actions.clonePackage(selectedPackage.get('id')))}
          onClose={() => {
            setShowCloneDialog(false);
            setSelectedPackage(null);
          }}
        />
      )}
    </>
  );

  return (
    <ServicesPageWrapper backRedirectLink="/services" titleMessage={messages.servicePackages}>
      <>
        <ScrollBox>
          <Box style={{ minWidth: 500, height: '100%', overflow: 'hidden' }}>
            <Table
              addButtonRedirect="/services/service-packages/new"
              suppressSmartBehavior
              suppressFiltersMobilePadding
              showPerPageSelect
              filters={tableFilters}
              headerOptions={headerOptions}
              isLoading={isPackagesListLoading}
              rows={tableRows}
              totalRows={packagesListMeta.get('total') || 0}
              onChangeParams={handleChangeTableProps}
              tableParams={tableParams}
            />
          </Box>
        </ScrollBox>

        {modals}
      </>
    </ServicesPageWrapper>
  );
};

export default PackagesList;
