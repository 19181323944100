import { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import { useSelector } from 'react-redux';
import { IInvoiceDetailsImt } from 'common/interfaces/invoices';
import { selectUpdatedInvoiceResult } from 'memberPortalModules/MemberProfile/state/invoice/selectors';
import { resetUpdateInvoiceWithSync } from 'memberPortalModules/MemberProfile/state/invoice/actions';

export const useUpdatePaymentData = (onClose: () => void): IInvoiceDetailsImt | null => {
  const dispatch = useAppDispatch();

  const updatedInvoiceResult = useSelector(selectUpdatedInvoiceResult);

  useEffect(() => {
    if (updatedInvoiceResult) {
      dispatch(resetUpdateInvoiceWithSync());
      onClose();
    }

    return () => {
      dispatch(resetUpdateInvoiceWithSync());
    };
  }, [dispatch, onClose, updatedInvoiceResult]);

  return updatedInvoiceResult;
};
