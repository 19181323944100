import React, { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, makeStyles, MenuItem, Typography, Theme } from '@material-ui/core';

import useEntranceWorkingHours, { EntranceStatus } from './useEntranceWorkingHours';

import { IEntranceItemImt } from 'modules/clubs/interfaces';

import { ReactComponent as LockIcon } from 'img/icons/lock-closed.svg';
import { ReactComponent as ClockIcon } from 'img/icons/clock.svg';

import commonMessages from 'common/messages/messages';

const useStyles = makeStyles((theme: Theme) => ({
  entranceItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
  workingHours: {
    fontSize: '0.75rem',
  },
}));

interface IProps {
  entrance: IEntranceItemImt;
  onEntranceSelect: (entranceId: string) => void;
  selectedEntranceId: string;
}

const EntranceItem = forwardRef(
  ({ entrance, onEntranceSelect, selectedEntranceId }: IProps): JSX.Element => {
    const [entranceStatus, entranceWorkingHoursRangesLabel] = useEntranceWorkingHours(entrance);

    const classes = useStyles();

    const renderEntranceWorkingHours = () => {
      switch (entranceStatus) {
        case EntranceStatus.OPEN:
        case EntranceStatus.DO_NOT_WORKING_HOURS:
        case EntranceStatus.ALL_DAY:
          return (
            <Box display="flex" alignItems="center">
              <ClockIcon className={classes.icon} />
              <Box>
                {entranceWorkingHoursRangesLabel.map(labelItem => (
                  <Typography color="inherit" className={classes.workingHours}>
                    {labelItem}
                  </Typography>
                ))}
              </Box>
            </Box>
          );
        case EntranceStatus.CLOSED:
          return (
            <>
              <LockIcon className={classes.icon} />
              <Typography color="inherit">
                <FormattedMessage {...commonMessages.notTodayStatus} />
              </Typography>
            </>
          );
        case EntranceStatus.INACTIVE:
          return (
            <>
              <LockIcon className={classes.icon} />
              <Typography color="inherit">
                <FormattedMessage {...commonMessages.inactiveStatus} />
              </Typography>
            </>
          );
        default:
          return (
            <>
              <ClockIcon className={classes.icon} />
              <Typography className={classes.workingHours}>12:00 AM - 11:59 PM</Typography>
            </>
          );
      }
    };

    const getColor = () => {
      if (
        entranceStatus === EntranceStatus.CLOSED ||
        entranceStatus === EntranceStatus.DO_NOT_WORKING_HOURS
      ) {
        return 'error.main';
      }
      return 'secondary.dark';
    };

    const isEntranceDisabled =
      entranceStatus === EntranceStatus.CLOSED ||
      entranceStatus === EntranceStatus.DO_NOT_WORKING_HOURS ||
      entranceStatus === EntranceStatus.INACTIVE;

    return (
      <MenuItem
        onClick={() => onEntranceSelect(entrance.get('id'))}
        selected={entrance.get('id') === selectedEntranceId}
        disabled={isEntranceDisabled}
        className={classes.entranceItem}
      >
        <Box alignSelf="flex-start">
          <Typography color="inherit">{entrance.get('title')}</Typography>
        </Box>

        <Box display="flex" alignItems="center" ml={1.5} width="190px" color={getColor()}>
          {renderEntranceWorkingHours()}
        </Box>
      </MenuItem>
    );
  },
);

export default EntranceItem;
