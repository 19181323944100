export const actionTypes = {
  FETCH_FEES_LIST: 'FETCH_FEES_LIST',
  FETCH_FEES_LIST_LOADING: 'FETCH_FEES_LIST_LOADING',
  RESET_FEES_LIST: 'RESET_FEES_LIST',

  FETCH_FEE_DETAILS: 'FETCH_FEE_DETAILS',
  FETCH_FEE_DETAILS_LOADING: 'FETCH_FEE_DETAILS_LOADING',
  RESET_FEE_DETAILS: 'RESET_FEE_DETAILS',

  CREATE_FEE_RESULT: 'CREATE_FEE_RESULT',
  CREATE_FEE_LOADING: 'CREATE_FEE_LOADING',
  RESET_CREATE_FEE: 'RESET_CREATE_FEE',

  UPDATE_FEE_RESULT: 'UPDATE_FEE_RESULT',
  UPDATE_FEE_LOADING: 'UPDATE_FEE_LOADING',
  RESET_UPDATE_FEE: 'RESET_UPDATE_FEE',

  CHANGE_FEE_STATUS_RESULT: 'CHANGE_FEE_STATUS_RESULT',
  CHANGE_FEE_STATUS_LOADING: 'CHANGE_FEE_STATUS_LOADING',
  RESET_CHANGE_FEE_STATUS: 'RESET_CHANGE_FEE_STATUS',
};
