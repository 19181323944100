import { fromJS } from 'immutable';

// interfaces
import { ISenderAvailability, TSenderAvailabilityImt } from 'modules/booking/interfaces';
import { ImmutableObject } from 'common/state/interfaces';
// constants
import {
  FETCH_SENDER_AVAILABILITY,
  FETCH_SENDER_AVAILABILITY_LOADING,
  RESET_SENDER_AVAILABILITY,
} from 'modules/booking/constants';
import { SenderAvailabilityTypeList } from '../../constants/senderAvailability';

export type FetchSenderAvailabilityLoadingPayload = {
  type: SenderAvailabilityTypeList;
  isLoading: boolean;
};

type FetchSenderAvailabilityLoadingAction = {
  type: typeof FETCH_SENDER_AVAILABILITY_LOADING;
  payload: FetchSenderAvailabilityLoadingPayload;
};

export type ResetSenderAvailabilityPayload = {
  type: SenderAvailabilityTypeList;
};

export type FetchSenderAvailabilitySuccessPayload =
  | {
      type: SenderAvailabilityTypeList.APPOINTMENT;
      data: ISenderAvailability;
    }
  | {
      type: SenderAvailabilityTypeList.POS;
      data: ISenderAvailability;
    }
  | {
      type: SenderAvailabilityTypeList.DOCUMENTS;
      data: ISenderAvailability;
    }
  | {
      type: SenderAvailabilityTypeList.TOUR;
      data: ISenderAvailability;
    }
  | {
      type: SenderAvailabilityTypeList.COMMON;
      data: ISenderAvailability;
    }
  | {
      type: SenderAvailabilityTypeList.REMIND;
      data: ISenderAvailability;
    };

type FetchSenderAvailabilitySuccessAction = {
  type: typeof FETCH_SENDER_AVAILABILITY;
  payload: FetchSenderAvailabilitySuccessPayload;
};

type ResetSenderAvailabilityAction = {
  type: typeof RESET_SENDER_AVAILABILITY;
  payload: ResetSenderAvailabilityPayload;
};

type SenderAvailabilityActions =
  | FetchSenderAvailabilityLoadingAction
  | FetchSenderAvailabilitySuccessAction
  | ResetSenderAvailabilityAction;

interface ISenderAvailabilityState {
  data: TSenderAvailabilityImt;
  isLoading: boolean;
}

export type ISenderAvailabilityStateImt = ImmutableObject<ISenderAvailabilityState>;

const initSenderAvailabilityItem = {
  data: {
    emailAvailable: true,
    notificationAvailable: true,
    smsAvailable: true,
  },
  isLoading: false,
};

const initialSenderAvailabilityStateImt = fromJS({
  [SenderAvailabilityTypeList.APPOINTMENT]: initSenderAvailabilityItem,
  [SenderAvailabilityTypeList.DOCUMENTS]: initSenderAvailabilityItem,
  [SenderAvailabilityTypeList.COMMON]: initSenderAvailabilityItem,
  [SenderAvailabilityTypeList.TOUR]: initSenderAvailabilityItem,
  [SenderAvailabilityTypeList.POS]: initSenderAvailabilityItem,
  [SenderAvailabilityTypeList.REMIND]: initSenderAvailabilityItem,
});

export type InitialSenderAvailabilityStateImt = typeof initialSenderAvailabilityStateImt;

export default function getSenderAvailabilityReducer(
  state = initialSenderAvailabilityStateImt,
  action: SenderAvailabilityActions,
): InitialSenderAvailabilityStateImt {
  switch (action.type) {
    case FETCH_SENDER_AVAILABILITY_LOADING: {
      const { isLoading, type } = action.payload;

      return state.setIn([type, 'isLoading'], isLoading);
    }
    case FETCH_SENDER_AVAILABILITY: {
      const { data, type } = action.payload;
      return state.setIn([type, 'data'], fromJS(data)).setIn([type, 'isLoading'], false);
    }
    case RESET_SENDER_AVAILABILITY:
      return state.set(action.payload.type, fromJS(initSenderAvailabilityItem));
    default:
      return state;
  }
}
