import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import moment from 'moment-timezone';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';

import { ActionItem, ActionsMenu, Alert, DialogComponent } from 'common/components';
import { ReactComponent as BarcodeIcon } from 'img/icons/barcode.svg';
import { ReactComponent as CakeIcon } from 'img/icons/cake.svg';
import messages from 'modules/kid-zone/messages';
import commonMessages from 'common/messages/messages';
import {
  IFoundMembersToCheckinByBarcodeErrorImt,
  IKidZoneBarcodeCheckinDtoImt,
} from 'modules/kid-zone/interfaces';
import Guardian from './Guardian';
import Children from './Children';
import MemberInfo from './MemberInfo';
import menuItems from 'common/messages/menuItems';
import { FormattedMessageByCode } from 'common/errors/serverErrors';
import { parseErrorArgs } from 'common/utils/http';
import { AlertTypes } from 'common/interfaces/alerts';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { ICheckInState } from 'modules/kid-zone/KidZone';

interface IProps {
  error?: IFoundMembersToCheckinByBarcodeErrorImt;
  membersToCheckin: IKidZoneBarcodeCheckinDtoImt;
  isOpen: boolean;
  onAddBirthday: Dispatch<SetStateAction<{ id: string }>>;
  setCheckInState: Dispatch<SetStateAction<Partial<ICheckInState>>>;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  barcodeIcon: {
    width: '24px',
    height: '24px',
    marginRight: theme.spacing(1),
  },
  cakeIcon: {
    width: '16px',
    height: '16px',
  },
  sectionTitle: {
    color: theme.palette.darkBackground.light,
  },
  menu: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: theme.spacing(0.25),
  },
  checkedInAlert: {
    marginBottom: theme.spacing(2),
  },
}));

const getTitle = (message: MessageDescriptor, align: 'left' | 'center' = 'left') => {
  return (
    <Box display="flex" justifyContent={align === 'left' ? 'flex-start' : 'center'} mb={1}>
      <Typography color="textSecondary">
        <FormattedMessage {...message} />
      </Typography>
    </Box>
  );
};

const CheckInMembersFoundByBarcodeModal = ({
  isOpen,
  onClose,
  membersToCheckin,
  onAddBirthday,
  setCheckInState,
  error,
}: IProps): JSX.Element => {
  const classes = useStyles();

  const isExistBirthday = Boolean(membersToCheckin?.get('birthday'));
  const age = isExistBirthday
    ? moment().diff(moment(membersToCheckin.get('birthday'), 'YYYY-MM-DD'), 'years')
    : null;

  const isGuardian = isExistBirthday && age >= 18;
  const isChildren = isExistBirthday && age < 18;

  const guardian = membersToCheckin?.get('guardian');
  const children = membersToCheckin?.get('children');
  const currentMember = membersToCheckin?.get('member');
  const isMemberCheckedIn = membersToCheckin?.get('checkedIn');

  const [selectedGuardian, setSelectedGuardian] = useState(null);
  const [selectedChildren, setSelectedChildren] = useState<Partial<Record<string, string>>>({});

  const transformedSelectedChildren = Object.values(selectedChildren);
  const isEmptySelectedChildren = !transformedSelectedChildren.length;

  const [timezoneMoment] = useTimezoneMoment();

  useEffect(() => {
    if (isChildren && guardian?.size) {
      setSelectedGuardian(guardian.getIn(['0', 'id']));
    }
  }, [guardian, setSelectedGuardian, isGuardian, isChildren]);

  const renderedGuardians = isChildren && Boolean(guardian?.size) && (
    <Guardian
      selectedGuardian={selectedGuardian}
      setSelectedGuardian={setSelectedGuardian}
      guardianList={guardian}
    />
  );
  const renderedChildren = isGuardian && Boolean(children?.size) && (
    <Children
      setSelectedChildren={setSelectedChildren}
      selectedChildren={selectedChildren}
      childrenList={children}
    />
  );

  const renderedMembers = renderedChildren || renderedGuardians;

  const onSubmit = () => {
    setCheckInState(
      selectedGuardian
        ? { guardianId: selectedGuardian }
        : {
            guardianId: membersToCheckin.getIn(['member', 'id']),
            childrenIds: transformedSelectedChildren,
          },
    );
  };

  const errorArgs = error?.getIn(['error', 'args']);
  const errorValues = errorArgs && parseErrorArgs(errorArgs);
  const isDisabled =
    (isGuardian && isEmptySelectedChildren) ||
    (isChildren && !selectedGuardian) ||
    isMemberCheckedIn;

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={onClose}
      submitBtn={isExistBirthday}
      submitBtnTitle={
        <FormattedMessage
          {...(isGuardian ? commonMessages.checkInBtn : commonMessages.broughtBtn)}
        />
      }
      title={<FormattedMessage {...messages.barcodeCheckInTitle} />}
      disabled={isDisabled}
      onSubmit={onSubmit}
    >
      {(membersToCheckin || error) && (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            mb={isExistBirthday ? 2.5 : 0}
          >
            <Box display="flex">
              {error ? (
                <Box pr={2}>
                  <Typography noWrap={false} color="textSecondary">
                    {FormattedMessageByCode(error.getIn(['error', 'codes', '0']), errorValues)}
                  </Typography>
                </Box>
              ) : (
                <Box pr={isExistBirthday ? 0 : 1}>
                  <MemberInfo
                    width={24}
                    height={24}
                    titleVariant="subtitle1"
                    firstName={currentMember.get('firstName')}
                    lastName={currentMember.get('lastName')}
                    imageUrl={currentMember.get('imageUrl')}
                  />
                </Box>
              )}

              {isExistBirthday || Boolean(error) || (
                <ActionsMenu className={classes.menu} horizontal>
                  <ActionItem
                    icon={<CakeIcon className={classes.cakeIcon} />}
                    onClick={() => onAddBirthday({ id: currentMember.get('id') })}
                  >
                    <FormattedMessage {...menuItems.addDOB} />
                  </ActionItem>
                </ActionsMenu>
              )}
            </Box>
            <Box display="flex" alignItems="center">
              <BarcodeIcon className={classes.barcodeIcon} />
              <Typography>
                {error ? error.get('barcode') : membersToCheckin.get('barcode')}
              </Typography>
            </Box>
          </Box>

          {isMemberCheckedIn && (
            <Alert
              severity={AlertTypes.Warning}
              extraClassName={classes.checkedInAlert}
              title={
                <Typography>
                  <FormattedMessage
                    {...messages.childAlreadyCheckedInText}
                    values={{
                      checkInTime: (
                        <Typography component="span" variant="h5">
                          {timezoneMoment(membersToCheckin.get('date')).format('LT')}
                        </Typography>
                      ),
                    }}
                  />
                </Typography>
              }
            />
          )}

          {isExistBirthday &&
            ((isGuardian && Boolean(children?.size)) || (isChildren && Boolean(guardian?.size))) &&
            getTitle(isGuardian ? messages.selectChildTitle : messages.selectGuardianTitle)}

          {isExistBirthday &&
            isGuardian &&
            !children?.size &&
            getTitle(messages.noChildrenText, 'center')}
          {isExistBirthday &&
            isChildren &&
            !guardian?.size &&
            getTitle(messages.noGuardiansText, 'center')}

          {renderedMembers && <Box>{renderedMembers}</Box>}

          {!!membersToCheckin?.get('other')?.size && (
            <Box mt={3} width="100%">
              <Typography variant="button" component="p" className={classes.sectionTitle}>
                <FormattedMessage {...menuItems.other} />
              </Typography>

              <Box>
                {membersToCheckin.get('other').map(otherItem => (
                  <Box display="flex" alignItems="center" justifyContent="space-between" mt={2}>
                    <MemberInfo
                      width={32}
                      height={32}
                      titleVariant="subtitle1"
                      firstName={otherItem.get('firstName')}
                      lastName={otherItem.get('lastName')}
                      imageUrl={otherItem.get('imageUrl')}
                    />

                    <ActionsMenu horizontal>
                      <ActionItem
                        icon={<CakeIcon className={classes.cakeIcon} />}
                        onClick={() => onAddBirthday({ id: otherItem.get('id') })}
                      >
                        <FormattedMessage {...menuItems.addDOB} />
                      </ActionItem>
                    </ActionsMenu>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </>
      )}
    </DialogComponent>
  );
};

export default CheckInMembersFoundByBarcodeModal;
