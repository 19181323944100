import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';
import { actionTypes } from 'common/state/alerts/constants';

const initStateAlertsList = fromJS({
  preferences: {},
  settings: [],
  sounds: [],
  isAlertsListLoading: false,
  isActionAlertLoading: false,
});

function alertsListReducer(state = initStateAlertsList, action) {
  const alertsList = state.get('settings').toJS();
  const preferences = state.get('preferences').toJS();

  switch (action.type) {
    case actionTypes.FETCH_ALERTS_LIST_LOADING:
      return state.set('isAlertsListLoading', action.payload);
    case actionTypes.UPDATE_ALERT_LOADING:
    case actionTypes.CREATE_ALERT_LOADING:
    case actionTypes.DELETE_ALERT_LOADING:
    case actionTypes.UPDATE_PREFERENCE_LOADING:
      return state.set('isActionAlertLoading', action.payload);
    case actionTypes.FETCH_ALERTS_LIST_SUCCESS:
      return state
        .set('settings', fromJS(action.payload.settings))
        .set('preferences', fromJS(action.payload.preferences))
        .set('sounds', fromJS(action.payload.sounds))
        .set('isLoading', false);
    case actionTypes.UPDATE_ALERT_SUCCESS:
    case actionTypes.UPDATE_ALERT_ERROR:
      return state.set(
        'settings',
        fromJS(
          alertsList.map(alertItem => {
            if (alertItem.id === action.payload.alertId) {
              return action.payload.data;
            }

            return alertItem;
          }),
        ),
      );
    case actionTypes.UPDATE_PREFERENCE_SUCCESS:
    case actionTypes.UPDATE_PREFERENCE_ERROR:
      return state.set('preferences', fromJS({ ...preferences, ...action.payload }));
    case actionTypes.CREATE_ALERT_SUCCESS:
      return state.set('settings', fromJS([...alertsList, action.payload]));
    case actionTypes.DELETE_ALERT_SUCCESS:
      return state.set('settings', fromJS(alertsList.filter(alert => alert.id !== action.payload)));
    case actionTypes.RESET_ALERTS_LIST:
      return initStateAlertsList;
    default:
      return state;
  }
}

export default combineReducers({
  alertsList: alertsListReducer,
});
