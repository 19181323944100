import { createSelector } from 'reselect';
import { List as ImmutableList, List } from 'immutable';
import { selectSelectedRegisterId } from 'modules/pos-kiosk/state/register/selectors';
import {
  IBalanceImt,
  IInvoiceDetailsImt,
  IPaymentMethodCustomResponseImt,
  IRewardsImt,
  ISearchGiftCardImt,
  ISearchPackagesResultImt,
} from 'common/interfaces/invoices';
import { IPaymentMethodsPosSettingsImt } from 'modules/pos-settings/interfaces/paymentMethods';
import { IPaymentAccountImt } from '../../components/PersonProfile/interfaces';

// Data Users List
const selectUsersListDomain = (state: any) => state.getIn(['invoice', 'invoiceUsersList']);

export const selectUsersListLoading = () =>
  createSelector(selectUsersListDomain, state => state.get('isLoading'));

export const selectSalesPersonData = () =>
  createSelector(selectUsersListDomain, state => state.get('salespersonList'));

export const selectCustomerData = () =>
  createSelector(selectUsersListDomain, state => state.get('customerList'));

// Search Inventory
const selectSearchInventoryPath = ['invoice', 'invoiceSearchInventory'];

export const selectSearchInventoryLoading = (state): boolean =>
  state.getIn([...selectSearchInventoryPath, 'isLoading']);

export const selectSearchInventoryData = state =>
  state.getIn([...selectSearchInventoryPath, 'data']);

// Search Services
const selectSearchPackagesPath = ['invoice', 'invoiceSearchPackages'];

export const selectSearchPackagesLoading = state =>
  state.getIn([...selectSearchPackagesPath, 'isLoading']);

export const selectSearchPackagesData = (state): ISearchPackagesResultImt =>
  state.getIn([...selectSearchPackagesPath, 'data']);

// Search gift cards
const selectSearchGiftCardsPath = ['invoice', 'invoiceSearchGiftCards'];

export const selectSearchGiftCardsLoading = state =>
  state.getIn([...selectSearchGiftCardsPath, 'isLoading']);

export const selectSearchGiftCardsData = (state): ImmutableList<ISearchGiftCardImt> =>
  state.getIn([...selectSearchGiftCardsPath, 'data']);

// Invoices operating
const invoicesOperatingDomain = state => state.getIn(['invoice', 'invoicesOperating']);

export const selectInvoicesList = state => state.getIn(['invoice', 'invoicesOperating', 'list']);

export const selectInvoicesForRegisterList = createSelector<
  any,
  any,
  List<IInvoiceDetailsImt> | null
>(
  [state => state.getIn(['invoice', 'invoicesOperating', 'list']), selectSelectedRegisterId],
  (invoices, registerId) => {
    return invoices?.filter(item => item.getIn(['register', 'id']) === registerId);
  },
);

export const selectIsFetchedInvoiceList = (state): boolean => {
  return Boolean(state.getIn(['invoice', 'invoicesOperating', 'list']));
};

export const selectInvoicesListLoading = () =>
  createSelector(invoicesOperatingDomain, state => state.get('isListLoading'));
export const selectReceiptLoading = state => {
  return state.getIn(['invoice', 'invoicesOperating', 'isFetchReceiptLoading']);
};

export const selectInvoiceId = state => {
  return state.getIn(['invoice', 'invoicesOperating', 'selectedInvoiceId']);
};

export const selectCurrentInvoice = createSelector(
  [selectInvoicesList, selectInvoiceId],
  (invoiceList, selectedInvoiceId): IInvoiceDetailsImt => {
    if (!selectedInvoiceId) return null;

    return invoiceList?.find(invoice => invoice.get('id') === selectedInvoiceId);
  },
);

export const selectCancelResult = () =>
  createSelector(invoicesOperatingDomain, state => state.get('actionCancelResult'));
export const selectCancelResultLoading = () =>
  createSelector(invoicesOperatingDomain, state => state.get('isCancelResultLoading'));
export const selectCreateInvoiceActionLoading = () =>
  createSelector(invoicesOperatingDomain, state => state.get('isCreateInvoiceLoading'));
export const selectAddInvoiceUnitActionLoading = () =>
  createSelector(invoicesOperatingDomain, state => state.get('isAddInvoiceUnitActionLoading'));

export const selectIsUpdateInvoiceUnitLoading = state =>
  state.getIn(['invoice', 'invoicesOperating', 'isUpdateInvoiceUnitLoading']);

export const selectIsUpdateInvoiceLoading = state =>
  state.getIn(['invoice', 'invoicesOperating', 'isUpdateInvoiceLoading']);

// Balance
export const selectCustomerBalance = (state): IBalanceImt | null => {
  return state.getIn(['invoice', 'invoicesOperating', 'balance']);
};
export const selectCustomerBalanceLoading = (state): boolean => {
  return state.getIn(['invoice', 'invoicesOperating', 'isFetchBalanceLoading']);
};

// custom payment method

export const selectBarcodePaymentMethod = (state): IPaymentMethodCustomResponseImt =>
  state.getIn(['invoice', 'invoiceCustomPaymentMethod', 'result']);
export const selectBarcodePaymentMethodError = state =>
  state.getIn(['invoice', 'invoiceCustomPaymentMethod', 'error']);
export const selectBarcodePaymentMethodLoading = state =>
  state.getIn(['invoice', 'invoiceCustomPaymentMethod', 'isLoading']);

// available payment methods

export const selectAvailablePaymentMethods = (state): List<IPaymentMethodsPosSettingsImt> =>
  state.getIn(['invoice', 'invoicePaymentMethods', 'data']);

export const selectAvailablePaymentMethodsLoading = (state): boolean =>
  state.getIn(['invoice', 'invoicePaymentMethods', 'isLoading']);

// update sync

export const selectUpdatedInvoiceResult = (state): IInvoiceDetailsImt =>
  state.getIn(['invoice', 'invoiceSyncAction', 'data']);

export const selectUpdatedInvoiceLoading = (state): boolean =>
  state.getIn(['invoice', 'invoiceSyncAction', 'isLoading']);

export const selectUpdatedInvoiceError = (state): any =>
  state.getIn(['invoice', 'invoiceSyncAction', 'error']);

// checkout

export const selectInvoiceCheckoutResult = (state): IInvoiceDetailsImt =>
  state.getIn(['invoice', 'invoiceCheckoutResult']);

export const selectStoredCreditCardsLoading = state =>
  state.getIn(['invoice', 'invoiceStoredCreditCards', 'isLoading']);
export const selectStoredCreditCardsResult = state =>
  state.getIn(['invoice', 'invoiceStoredCreditCards', 'creditCards']);
export const selectAddCreditCardLoading = state =>
  state.getIn(['invoice', 'invoiceStoredCreditCards', 'addCreditCardLoading']);
export const selectAddCreditCardResult = state =>
  state.getIn(['invoice', 'invoiceStoredCreditCards', 'addCreditCardResult']);
export const selectCommerceHubCredentials = state =>
  state.getIn(['invoice', 'invoiceStoredCreditCards', 'commerceHubCredentials']);

// Checking
export const selectInvoiceCheckingSavingsData = (state): List<IPaymentAccountImt> =>
  state.getIn(['invoice', 'invoiceCheckingSavings', 'list']);
export const selectAddInvoiceCheckingSavingsDataLoading = state =>
  state.getIn(['invoice', 'invoiceCheckingSavings', 'addCheckingSavingsLoading']);
export const selectAddInvoiceCheckingSavingsDataActionResult = state =>
  state.getIn(['invoice', 'invoiceCheckingSavings', 'addCheckingSavingsActionResult']);

// Balance
export const selectCustomerRewards = (state): IRewardsImt | null => {
  return state.getIn(['invoice', 'invoicesOperating', 'rewards']);
};

export const selectCustomerRewardsLoading = (state): boolean => {
  return state.getIn(['invoice', 'invoicesOperating', 'isFetchRewardsLoading']);
};
