export const actionTypes = {
  // contract
  SET_IS_LOADING: 'CONTRACT/SET_IS_LOADING',
  SIGN_CONTRACT: 'CONTRACT/SIGN_CONTRACT',
  SIGN_CONTRACT_ACTION_RESULT: 'CONTRACT/SIGN_CONTRACT_ACTION_RESULT',
  GET_CONTRACT: 'CONTRACT/GET_CONTRACT',
  APPLY_CONTRACT: 'CONTRACT/APPLY_CONTRACT',
  CLEAR_CONTRACT: 'CONTRACT/CLEAR_CONTRACT',
  SET_ERROR: 'CONTRACT/SET_ERROR',
  FETCH_CONTRACT_HTML_LOADING: 'CONTRACT/FETCH_CONTRACT_HTML_LOADING',
};
