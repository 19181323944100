import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Accept } from 'react-dropzone';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { IAttachment, IUploaderCustomError } from 'common/interfaces/uploadFile';
import FilesDropzone from '../FilesDropzone/FilesDropzone';
import { WebcamModal } from 'common/modals';
import { getAccessByPropPath } from 'common/utils/errorObject';

interface IFormFilesUploaderProps {
  fieldName?: string;
  shouldBeVisibleTableHeader?: boolean;
  hasVisibleTakePhotoBtn?: boolean;
  hasVisibleScanDocumentBtn?: boolean;
  acceptFileTypes?: Accept;
  dropzoneClassName?: string;
  className?: string;
}

const FormFilesUploader = (props: IFormFilesUploaderProps) => {
  const {
    fieldName = 'attachments',
    shouldBeVisibleTableHeader,
    hasVisibleTakePhotoBtn,
    hasVisibleScanDocumentBtn,
    acceptFileTypes,
    dropzoneClassName,
    className,
  } = props;

  const {
    setValue,
    setError,
    clearErrors,
    errors: formErrors,
    control,
    getValues,
  } = useFormContext();
  const error = getAccessByPropPath(formErrors, fieldName);

  const renderIntlMessage = useRenderIntlMessage();

  const [isOpenWebcamModal, setIsOpenWebcamModal] = useState(false);

  const onToggleWebcamModal = () => setIsOpenWebcamModal(!isOpenWebcamModal);

  const onUploadWebcamFileImg = (fileData: File) => {
    onToggleWebcamModal();
    if (error) {
      clearErrors(fieldName);
    }
    const files = getValues(fieldName);

    setValue(fieldName, [...files, fileData]);
  };

  const onRejected = (errors: IUploaderCustomError[]) => {
    // One file is always added
    const { customError } = errors[0];

    setError(fieldName, { message: renderIntlMessage(customError) || '' });
  };

  const onUploadFiles = (
    currentFiles: IAttachment[],
    uploadFiles: File[],
    deletedFile?: IAttachment,
  ) => {
    if (error) {
      clearErrors(fieldName);
    }

    if (deletedFile) {
      setValue(fieldName, currentFiles);
    } else {
      setValue(fieldName, [...currentFiles, ...uploadFiles]);
    }
  };

  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        render={({ value }) => {
          return (
            <FilesDropzone<IAttachment>
              className={className}
              dropzoneClassName={dropzoneClassName}
              acceptFileTypes={acceptFileTypes}
              files={value}
              onUploadFiles={onUploadFiles}
              onToggleWebcamModal={onToggleWebcamModal}
              height="73px"
              error={error}
              onRejected={onRejected}
              showTableHeader={shouldBeVisibleTableHeader}
              hasVisibleTakePhotoBtn={hasVisibleTakePhotoBtn}
              hasVisibleScanDocumentBtn={hasVisibleScanDocumentBtn}
            />
          );
        }}
      />
      {isOpenWebcamModal && (
        <WebcamModal
          isOpen={isOpenWebcamModal}
          onToggle={onToggleWebcamModal}
          onUpload={onUploadWebcamFileImg}
        />
      )}
    </>
  );
};

export default FormFilesUploader;
