import { List as ImmutableList } from 'immutable';

// interfaces
import { ImmutableObject } from 'common/state/interfaces';
import { IClub, IClubImt } from 'common/interfaces/clients';
import { IServiceItemImt } from 'modules/services/interfaces/services';
import {
  IDayTimeAvailabilityDto,
  INamedEntity,
  INamedEntityImt,
  IShortPerson,
  IShortPersonImt,
  ReminderPeriodType,
  Weekday,
} from 'common/interfaces/common';
import { IParticipantListItem } from 'common/interfaces/dictionary';
import {
  IAuthor,
  IAuthorImt,
  IModificationHistory,
  IModificationHistoryImt,
} from 'common/components/PersonProfile/interfaces';
// constants
import { PeakModules } from 'common/constants/peakModules';
import { ENotificationType } from './constants/notificationType';
import { AppointmentReportStatuses } from 'modules/reports/interfaces/appointmentsReport';

export enum EventType {
  TOUR = 'TOUR',
  SALES_SIT_DOWN = 'SALES_SIT_DOWN',
  TELEPHONE = 'TELEPHONE',
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

export enum EventRepeatTypes {
  NONE = 'NONE',
  CUSTOM = 'CUSTOM',
}

export enum EventRepeatFrequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  SEMI_ANNUALLY = 'SEMI_ANNUALLY',
  ANNUALLY = 'ANNUALLY',
}

export enum EventRepeatEnd {
  FOREVER = 'FOREVER',
  N_TIMES = 'N_TIMES',
  ON_DATE = 'ON_DATE',
}

export enum EventAttendeeType {
  MEMBER = 'MEMBER',
  PROSPECT = 'PROSPECT',
  EMPLOYEE = 'EMPLOYEE',
  RESOURCE_EMPLOYEE = 'RESOURCE_EMPLOYEE',
}

export enum EventPersonType {
  EMPLOYEE = 'EMPLOYEE',
  RESOURCE_EMPLOYEE = 'RESOURCE_EMPLOYEE',
  CUSTOMER = 'CUSTOMER',
}

export enum EventOverbookingType {
  DO_NOT_ALLOW_OVERBOOKING = 'DO_NOT_ALLOW_OVERBOOKING',
  WAIT_LIST = 'WAIT_LIST',
  DUPLICATE_EVENT_FOR_EXTRA_PARTICIPANTS = 'DUPLICATE_EVENT_FOR_EXTRA_PARTICIPANTS',
}

export enum PersonAttendanceType {
  Default = 'DEFAULT',
  OneTime = 'ONE_TIME',
}

export enum PersonAppointmentStatus {
  Attended = 'ATTENDED',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  Confirmed = 'CONFIRMED',
  Missed = 'MISSED',
}

// interfaces

export interface IEventResource extends INamedEntity {
  employee?: IShortPerson;
}

export interface IEventResourceImt extends ImmutableObject<IEventResource> {
  get<K extends keyof IEventResource>(key: K): IEventResource[K];

  get(key: 'employee'): IShortPersonImt;
}

export interface IEventPerson extends IShortPerson {
  type?: EventAttendeeType;
  active?: boolean;
}

export type IEventPersonImt = ImmutableObject<IEventPerson>;

// TODO: Recheck the necessity of fields 'confirmationType' , 'confirmationEmailId', 'confirmationSmsId', 'waitlist'
export interface IEventPersonEntity {
  type?: EventPersonType;
  id?: string; // TODO refactor after all modals will be integrated
  joinedDate: string;
  eventDate?: string; // needed for OneTime participants
  resourceId?: string;

  confirmationType?: string;
  confirmationEmailId?: string;
  confirmationSmsId?: string;

  customer?: IEventPerson;
  salesperson?: IEventPerson;

  attendanceType?: PersonAttendanceType;
  status?: PersonAppointmentStatus;

  waitlist?: boolean;
}

export interface IEventPersonFormValue {
  id?: string;
  joinedDate: string;
  eventDate?: string;
  resourceId?: string;
  status?: PersonAppointmentStatus;
  attendanceType?: PersonAttendanceType;
  customer?: IEventPerson;
  salesperson?: IEventPerson;
  type: EventPersonType;
}

export interface IEventPersonEntityImt extends ImmutableObject<IEventPersonEntity> {
  get<K extends keyof IEventPersonEntity>(key: K): IEventPersonEntity[K];

  get(key: 'customer'): IEventPersonImt;

  get(key: 'salesperson'): IEventPersonImt;
}

interface IEventClub extends INamedEntity {
  timezone?: string;
}

export interface IBookingEventListItem {
  id: string;
  title: string;
  color: string;
  date: string;
  durationInMinutes: number;
  logos: IAuthor[];

  participantsAdded?: number;
  spots?: number;

  canceled: boolean;
  club: IEventClub;

  // needed for booking calendar
  utcDate?: string;
  bookedSpots: number;
  spotLimited: boolean;
  bookedSpotsCounter?: string;
}

export interface IBookingEventListItemImt extends ImmutableObject<IBookingEventListItem> {
  get<K extends keyof IBookingEventListItem>(key: K): IBookingEventListItem[K];

  get(key: 'logos'): ImmutableList<IAuthorImt>;
}

export interface IOverbookingResourceDTO {
  id?: string;
  resources: IShortResource[];
}

export interface IOverbookingResourceDTOImt extends ImmutableObject<IOverbookingResourceDTO> {
  get<K extends keyof IOverbookingResourceDTO>(key: K): IOverbookingResourceDTO[K];
}

export interface IBookingEvent {
  id?: string;
  title?: string;
  color?: string;
  date?: string;
  time?: string;
  durationInMinutes?: number;
  changeDuration?: boolean;
  appointmentStatus?: PersonAppointmentStatus;

  type?: EventType;
  description?: string;

  repeated?: boolean;
  repeatingFrequency?: EventRepeatFrequency;
  repeatingWeekdays?: Weekday[];
  repeatingDurationType?: EventRepeatEnd;
  repeatingTimesNumber?: number;
  repeatingEndDate?: string;

  allowSelfBooking?: boolean;
  spotLimited?: boolean;
  spotAmount?: number;
  allowedParticipantTypes?: Array<EventAttendeeType>;

  overbookingType?: EventOverbookingType;
  overbookingResources?: IOverbookingResourceDTO[];

  preventJoiningAfterDays?: boolean;
  joinRestrictionBeforeDays?: number;
  preventCancelingAfterDays?: boolean;
  cancelRestrictionBeforeDays?: number;
  preventEditingAfterDays?: boolean;
  editRestrictionBeforeDays?: number;

  canceled?: boolean;

  reminder?: boolean; // Frontend-specific field for tracking
  reminderPeriodType?: ReminderPeriodType;
  reminderPeriodNumber?: number;
  remindViaEmail?: boolean;
  remindViaNotification?: boolean;
  remindViaSms?: boolean;

  notifyViaEmail?: boolean;
  notifyViaNotification?: boolean;
  notifyViaSms?: boolean;

  club: IEventClub;
  service?: INamedEntity;
  tags?: Array<INamedEntity>;

  resources?: Array<INamedEntity>;
  persons?: Array<IEventPersonEntity>;

  modificationHistory?: IModificationHistory[];
  // appointmentId - additional field for unique id. Solve bug with same key when appointment repeated
  appointmentId?: string;
  bookedSpots?: number;
}

export interface IUpdatedEventDto extends IBookingEvent {
  updateType?: EventUpdateType;
  oldDate?: string;
}

export enum EventUpdateType {
  OnlyCurrentInstance = 'ONLY_CURRENT_INSTANCE',
  CurrentAndAllNext = 'CURRENT_AND_ALL_NEXT',
  All = 'ALL',
}

export interface IEventFormValues extends Omit<IBookingEvent, 'persons' | 'durationInMinutes'> {
  persons?: Array<IEventPersonFormValue>;
  durationInMinutes?: number | string;
}

export interface IEventFormValuesImt extends ImmutableObject<IEventFormValues> {
  get<K extends keyof IEventFormValues>(key: K): IEventFormValues[K];
}

export interface IBookingEventImt extends ImmutableObject<IBookingEvent> {
  get<K extends keyof IBookingEvent>(key: K): IBookingEvent[K];

  get(key: 'repeatingWeekdays'): ImmutableList<Weekday>;

  get(key: 'club'): IClubImt;

  get(key: 'service'): IServiceItemImt;

  get(key: 'resources'): ImmutableList<IEventResourceImt>;

  get(key: 'persons'): ImmutableList<IEventPersonEntityImt>;

  get(key: 'overbookingResources'): ImmutableList<IOverbookingResourceDTOImt>;

  get(key: 'modificationHistory'): ImmutableList<IModificationHistoryImt>;

  get(key: 'attendeeType'): ImmutableList<EventAttendeeType>;

  get(key: 'allowedParticipantTypes'): ImmutableList<EventAttendeeType>;

  get(key: 'tags'): ImmutableList<INamedEntityImt>;
}

export type IParticipantImt = ImmutableObject<IParticipantListItem>;

export interface IEventAction extends INotifyVariant {
  reason: string;
  updateType: EventUpdateType;
}

export interface INotifyVariant {
  notifyViaEmail: boolean;
  notifyViaNotification: boolean;
  notifyViaSms: boolean;
}

export interface ITag {
  id: string;
  name: string;
  imageUrl?: string;
}

export type ITagImt = ImmutableObject<ITag>;

export interface IEmployeeResource {
  firstName: string;
  lastName: string;
  imageUrl: string;
}

export interface IAvailability {
  startDate: string;
  endDate: string;
  clubs: Array<IClub>;
  dayTimeAvails?: IDayTimeAvailabilityDto[];
}

export interface IResourceForm {
  label: string;
  active: boolean;
  deleted: boolean;
  clubs: Array<string>;
  resourceTags: Array<INamedEntity>;
  eventTags: Array<INamedEntity>;

  employee: IEmployeeResource[];
  availability: IAvailability[];
}

export interface IResource extends IResourceForm {
  id: string;
}

export interface IResourceImt extends ImmutableObject<IResource> {
  get<K extends keyof IResource>(key: K): IResource[K];
}

export interface IShortResource extends INamedEntity {
  employee: IShortPerson;
}

export interface IShortResourceImt extends ImmutableObject<IShortResource> {
  get<K extends keyof IShortResource>(key: K): IShortResource[K];

  get(key: 'employee'): IShortPersonImt;
}

export interface IResourceListItem {
  id: string;
  label: string;
  active: boolean;
  clubs: Array<string>;
  employee: IEmployeeResource;
}

export interface IResourceListItemImt extends ImmutableObject<IResourceListItem> {
  get<K extends keyof IResourceListItem>(key: K): IResourceListItem[K];
}

export interface ISenderAvailability {
  emailAvailable: boolean;
  smsAvailable: boolean;
  notificationAvailable: boolean;
  emailMessage: string | null;
  notificationMessage: string | null;
  smsMessage: string | null;
  type: ENotificationType;
}

export interface IListSenderAvailability {
  availabilityItemDtoList: ISenderAvailability[];
}

export type TSenderAvailabilityImt = ImmutableObject<ISenderAvailability>;

export interface IFetchSenderAvailabilityData {
  module?: PeakModules;
  events?: ENotificationType[];
}

export interface IRemindAppointmentDto extends INotifyVariant {
  date: string;
}

export interface IRestoreAppointmentsParams {
  personId: string;
  appointmentId: string;
  date: string;
}

export interface IRestoredAppointment {
  action: string;
  changedBy?: INamedEntity;
  date: string;
  eventId: string;
  id: string;
}

export interface IRecentAppointmentItem {
  appointmentStatus: PersonAppointmentStatus;
  canceled: boolean;
  club: IEventClub;
  color: string;
  date: string;
  description: string;
  durationInMinutes: number;
  id: string;
  logos: IAuthor[];
  participantsAdded: number;
  repeated: boolean;
  spots: number;
  title: string;
  hasScheduledService: boolean;
  serviceId: string;

  // appointmentId - additional field for unique id. Solve bug with same key when appointment repeated
  appointmentId?: string;
}

export interface IRecentAppointmentItemImt extends ImmutableObject<IRecentAppointmentItem> {
  get<K extends keyof IRecentAppointmentItem>(key: K): IRecentAppointmentItem[K];
}

export interface IAppointmentHistoryItem {
  club: INamedEntity;
  date: string;
  description: string;
  id: string;
  status: PersonAppointmentStatus;
  title: string;
  type: AppointmentReportStatuses;
  tags: INamedEntity[];
  resources: IShortResource[];

  // appointmentId - additional field for unique id. Solve bug with same key when appointment repeated
  appointmentId?: string;
}

export interface IAppointmentHistoryItemImt extends ImmutableObject<IAppointmentHistoryItem> {
  get<K extends keyof IAppointmentHistoryItem>(key: K): IAppointmentHistoryItem[K];

  get(key: 'resources'): ImmutableList<IShortResourceImt>;
  get(key: 'tags'): ImmutableList<INamedEntityImt>;
}
