// contract
export const selectContract = state => state.getIn(['newPerson', 'personContract', 'result']);

export const selectSignContractActionResult = state =>
  state.getIn(['newPerson', 'personContract', 'actionResult']);

export const selectContractIsLoading = state =>
  state.getIn(['newPerson', 'personContract', 'isLoading']);

export const selectIsContractHTMLLoading = state =>
  state.getIn(['newPerson', 'personContract', 'isContractHTMLLoading']);

export const selectContractError = state => state.getIn(['newPerson', 'personContract', 'error']);
