const alertsListPath = ['alerts', 'alertsList'];

const selectAlertsListLoading = state => state.getIn([...alertsListPath, 'isAlertsListLoading']);

const selectActionAlertLoading = state => state.getIn([...alertsListPath, 'isActionAlertLoading']);

const selectAlertsList = state => state.getIn([...alertsListPath, 'settings']);

const selectPreferences = state => state.getIn([...alertsListPath, 'preferences']);

const selectAlertSounds = state => state.getIn([...alertsListPath, 'sounds']);

export {
  selectAlertsList,
  selectAlertsListLoading,
  selectActionAlertLoading,
  selectPreferences,
  selectAlertSounds,
};
