import React from 'react';
import { Box, Typography, makeStyles, Theme } from '@material-ui/core';

import { getInitials } from 'helpers/common';

import { AvatarWithInitials } from 'common/components/index';

const useStyles = makeStyles((theme: Theme) => ({
  customerInitial: {
    marginLeft: theme.spacing(1),
  },
}));

interface IProps {
  isChild?: boolean;
  firstName: string;
  lastName: string;
  imageUrl: string;
}

const CustomerCell = ({ firstName, lastName, imageUrl, isChild }: IProps): JSX.Element => {
  const classes = useStyles();

  const initials = getInitials(firstName, lastName);

  return (
    <>
      {initials ? (
        <Box display="flex" alignItems="center">
          <AvatarWithInitials initials={initials} imageUrl={imageUrl} />

          <Typography
            variant={isChild ? 'h6' : 'body1'}
            component="span"
            className={classes.customerInitial}
          >
            {`${firstName} ${lastName}`}
          </Typography>
        </Box>
      ) : (
        '-'
      )}
    </>
  );
};

export default CustomerCell;
