import { List as ImmutableList } from 'immutable';
import {
  IFamilyMember,
  IFamilyMemberCreateDTO,
  IFamilyMemberImt,
} from 'common/components/PersonProfile/interfaces';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { useAppDispatch } from 'store/hooks';

import FamilyMember from './FamilyMember';
import messages from 'common/components/PersonProfile/messages';
import AddFamilyMemberModal from './AddFamilyMemberModal/AddFamilyMemberModal';
import CheckInBlock from '../CheckInBlock/CheckInBlock';
import * as actions from 'common/components/PersonProfile/state/familyMembers/actions';
import {
  makeSelectPersonFamilyMembers,
  makeSelectPersonFamilyMembersLoading,
} from 'common/components/PersonProfile/state/familyMembers/selectors';
import { Button, LoadingBackdrop } from 'common/components/index';
import { PeakModules } from 'common/constants/peakModules';
import { ReactComponent as AddIcon } from 'img/icons/add_deprecated.svg';
import * as actionsMembers from 'common/state/newPerson/familyMembers/actions';
import { ActionResult } from 'common/constants';
import { selectAddFamilyMemberResult } from 'common/state/newPerson/familyMembers/selectors';
import { resetAddFamilyMemberActionResult } from 'common/state/newPerson/familyMembers/actions';
import { selectUserSelectedClubId } from 'modules/authentication/state/selectors';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: '100%',
  },
  list: {
    maxHeight: '350px',
    overflowY: 'auto',
  },
}));

interface IFamilyMembersProps {
  personId: string;
  selectedClubId?: string;
  memberFirstName: string;
  memberLastName: string;
  familyMembers?: ImmutableList<IFamilyMemberImt>;
  module: PeakModules.FrontDesk | PeakModules.Members;
}

const FamilyMembers = ({ personId, memberFirstName, module }: IFamilyMembersProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const addFamilyMemberActionResult = useSelector(selectAddFamilyMemberResult);
  const clubId = useSelector(selectUserSelectedClubId);

  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [familyMembers, setFamilyMembers] = useState<ImmutableList<IFamilyMemberImt>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [familyMembersSelector, familyMembersLoadingSelector] = useSelector(state => [
    makeSelectPersonFamilyMembers()(state, { personId }),
    makeSelectPersonFamilyMembersLoading()(state, { personId }),
  ]);

  const classes = useStyles();

  const fetchFamilyMembers = useCallback(() => {
    dispatch(actions.fetchPersonFamilyMembers(personId, module));
  }, [dispatch, personId, module]);

  useEffect(() => {
    fetchFamilyMembers();
  }, [fetchFamilyMembers]);

  useEffect(() => {
    if (addFamilyMemberActionResult === ActionResult.SUCCESS_ACTION) {
      dispatch(resetAddFamilyMemberActionResult());

      setIsAddModalOpen(false);
    }
  }, [addFamilyMemberActionResult, dispatch, module, personId]);

  useEffect(() => setFamilyMembers(familyMembersSelector), [familyMembersSelector]);
  useEffect(() => setIsLoading(familyMembersLoadingSelector), [familyMembersLoadingSelector]);

  const removeFamilyMember = (familyMemberId: string) => {
    dispatch(actions.deleteFamilyMember(personId, familyMemberId, module));
  };

  const addFamilyMember = useCallback(
    (formValues: IFamilyMember) => {
      const redeemServiceDTO: IFamilyMemberCreateDTO = {
        ...formValues,
        clubId,
        familyMemberId: formValues.rightCustomer.id,
        familyMemberServiceInstanceIds: formValues.services.length
          ? [formValues.services[0].packageServiceInstanceId]
          : [],
      };

      dispatch(actionsMembers.addFamilyMember(personId, redeemServiceDTO, module));
    },
    [dispatch, module, personId, clubId],
  );

  return (
    <Box position="relative">
      <LoadingBackdrop isLoading={isLoading} />
      <CheckInBlock
        title={<FormattedMessage {...messages.familyMembersBlockTitle} />}
        buttonTitle={
          <Button color="primary" hasHoverStyle>
            <AddIcon />
          </Button>
        }
        className={classes.wrapper}
        onClickButton={() => setIsAddModalOpen(true)}
      >
        {!!familyMembers && familyMembers.size > 0 ? (
          <Grid container spacing={2} className={classes.list}>
            {familyMembers.map((memberItem, index) => (
              <FamilyMember
                // eslint-disable-next-line react/no-array-index-key
                key={`${memberItem.get('id')}-${index}`}
                removeFamilyMember={removeFamilyMember}
                personId={personId}
                member={memberItem}
              />
            ))}
          </Grid>
        ) : (
          <Box className="empty-section-placeholder">
            <Typography className="empty-text">
              <FormattedMessage {...messages.emptyFamilyMembersListTextMessage} />
            </Typography>
            <Typography className="empty-text">
              <FormattedMessage {...messages.emptyFamilyMembersListTextDescription} />
            </Typography>
          </Box>
        )}

        {isAddModalOpen && (
          <AddFamilyMemberModal
            module={module}
            personId={personId}
            isOpen={isAddModalOpen}
            memberFirstName={memberFirstName}
            familyMembers={familyMembers}
            onClose={() => setIsAddModalOpen(false)}
            onSubmit={addFamilyMember}
          />
        )}
      </CheckInBlock>
    </Box>
  );
};

export default FamilyMembers;
