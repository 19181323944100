import { IRoutes } from 'common/interfaces/routes';
import messages from '../messages/messages';
import commonMessages from 'common/messages/messages';

export const routes: IRoutes[] = [
  {
    type: 'link',
    path: '/reports/overview',
    message: messages.overview,
  },
  {
    type: 'bookmark',
    message: commonMessages.pos,
  },
  {
    type: 'link',
    path: '/reports/sales-pos',
    message: messages.salesPosReport,
  },
  {
    type: 'link',
    path: '/reports/invoice-history',
    message: commonMessages.invoicesTitle,
  },
  {
    type: 'link',
    path: '/reports/z-out',
    message: messages.zOut,
  },
  {
    type: 'link',
    path: '/reports/scheduled-billings',
    message: messages.scheduledBillings,
  },
  {
    type: 'link',
    path: '/reports/sales-over-time',
    message: messages.salesOverTimeReport,
  },
  {
    type: 'link',
    path: '/reports/register-status',
    message: messages.registerStatusReport,
  },
  {
    type: 'link',
    path: '/reports/payment-transactions',
    message: messages.paymentTransactionsReport,
  },
  {
    type: 'link',
    path: '/reports/used-sessions',
    message: messages.sessionRedemptionReport,
  },
  {
    type: 'bookmark',
    message: commonMessages.crm,
  },
  {
    type: 'link',
    path: '/reports/crm-dashboard',
    message: messages.dashboard,
  },
  {
    type: 'link',
    path: '/reports/campaigns',
    message: messages.campaignReport,
  },
  {
    type: 'link',
    path: '/reports/appointments',
    message: messages.appointmentsReport,
  },
  {
    type: 'bookmark',
    message: commonMessages.generalLabel,
  },
  {
    type: 'link',
    path: '/reports/check-in-history',
    message: messages.checkInHistory,
  },
  {
    type: 'link',
    path: '/reports/kidzone-check-in-history',
    message: messages.kidZoneCheckInHistory,
  },
];
