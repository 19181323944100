import { defineMessages } from 'react-intl';

export default defineMessages({
  filterTitle: {
    id: 'common.toolbarFilter.filterTitle',
    defaultMessage: '{title}: {filter}',
  },
  clearLabel: {
    id: 'common.toolbarFilter.clearLabel',
    defaultMessage: 'Clear Selected',
  },
});
