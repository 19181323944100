import React from 'react';
import { FormattedMessage } from 'react-intl';
// messages
import taxRateMessages from 'modules/pos-settings/messages/taxRates';

export const actionTypes = {
  // Tax rates
  FETCH_TAX_RATES_LOADING: 'FETCH_TAX_RATES_LOADING',
  FETCH_TAX_RATES: 'FETCH_TAX_RATES',
  CHANGE_STATUS_TAX_RATE_LOADING: 'CHANGE_STATUS_TAX_RATE_LOADING',
  CHANGE_STATUS_TAX_RATE: 'CHANGE_STATUS_TAX_RATE',
  RESET_CHANGE_STATUS_TAX_RATE_RESULT: 'RESET_CHANGE_STATUS_TAX_RATE_RESULT',
  // Tax rates item
  FETCH_TAX_RATE_BY_ID_LOADING: 'FETCH_TAX_RATE_BY_ID_LOADING',
  FETCH_TAX_RATE_BY_ID: 'FETCH_TAX_RATE_BY_ID',
  RESET_TAX_RATE_BY_ID: 'RESET_TAX_RATE_BY_ID',
  UPDATE_TAX_RATE_LOADING: 'UPDATE_TAX_RATE_LOADING',
  UPDATE_TAX_RATE: 'UPDATE_TAX_RATE',
  CREATE_TAX_RATE_LOADING: 'CREATE_TAX_RATE_LOADING',
  CREATE_TAX_RATE: 'CREATE_TAX_RATE',
  RESET_UPDATE_TAX_RATE_RESULT: 'RESET_UPDATE_TAX_RATE_RESULT',
};

export enum TaxRateType {
  Single = 'SINGLE',
  Bundle = 'BUNDLE',
}

export const TaxRateTypeLabels: Record<TaxRateType, JSX.Element> = {
  [TaxRateType.Single]: <FormattedMessage {...taxRateMessages.singleLabel} />,
  [TaxRateType.Bundle]: <FormattedMessage {...taxRateMessages.bundleLabel} />,
};
