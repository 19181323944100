import { array, boolean, number, object, string } from 'yup';
import {
  getMinMaxNumberSchema,
  minMaxNumberSchema,
} from 'common/validationSchemas/minMaxNumberSchema';
import { CreditCardFeeUnit, DurationType, FrequencyType } from '../../../constants/packages';
import inputErrors from 'common/messages/inputErrors';
import { nullableStringNumberSchema } from 'common/validationSchemas/stringNumberSchema';
import { getPriceFromNumber } from 'common/utils/calculate';
import messages from 'modules/services/messages/messages';

const requiredFieldMessage = () => inputErrors.requiredFieldError;
const greaterThanZeroFieldMessage = () => inputErrors.greaterThanZero;

export const SPLIT_SUM_ERROR = 'splitSumViolation';

const paymentGraceSchema = object().shape({
  allow: boolean(),
  duration: string()
    .oneOf(Object.values(DurationType))
    .when('allow', {
      is: true,
      then: string()
        .oneOf(Object.values(DurationType))
        .required(requiredFieldMessage),
    }),
  editableDurationNumber: object().when('allow', {
    is: true,
    then: getMinMaxNumberSchema(1),
    otherwise: object().nullable(),
  }),
});

const paymentFreezeSchema = object().shape({
  allow: boolean(),
  editableDaysNumber: object().when('allow', {
    is: true,
    then: getMinMaxNumberSchema(1),
    otherwise: object().nullable(),
  }),
});

const creditCardFeeSchema = object().shape({
  enabled: boolean(),
  value: number()
    .nullable()
    .when('enabled', {
      is: true,
      then: nullableStringNumberSchema
        .min(1, greaterThanZeroFieldMessage)
        .required(requiredFieldMessage),
    }),
  unit: string()
    .nullable()
    .when('enabled', {
      is: true,
      then: string()
        .oneOf(Object.values(CreditCardFeeUnit))
        .nullable()
        .required(requiredFieldMessage),
    }),
});

// const billingInitialFeeSchema = object().shape({
//   active: boolean(),
//   immediatePayment: boolean(),
//   editableAmount: object()
//     .nullable()
//     .when('active', {
//       is: true,
//       then: getMinMaxNumberSchema(),
//     }),
//   editableNumber: object()
//     .nullable()
//     .when(['active', 'immediatePayment'], {
//       is: (active, immediatePayment) => active && !immediatePayment,
//       then: minMaxNumberSchema.required(requiredFieldMessage),
//     }),
//   paymentEditablePeriodInDays: object()
//     .nullable()
//     .when(['active', 'immediatePayment'], {
//       is: (active, immediatePayment) => active && !immediatePayment,
//       then: minMaxNumberSchema.required(requiredFieldMessage),
//     }),
// });

const orderedValueSchema = object().shape({
  value: nullableStringNumberSchema.required(requiredFieldMessage),
  order: string(),
});

const paymentSplittingSchema = object().shape({
  allow: boolean(),
  splitPaymentEditableNumber: object().when('allow', {
    is: true,
    then: getMinMaxNumberSchema(1),
    otherwise: object().nullable(),
  }),
  splitPaymentSchedule: string()
    .nullable()
    .when('allow', {
      is: true,
      then: string()
        .oneOf(Object.values(FrequencyType))
        .required(requiredFieldMessage),
    }),
});

const downPaymentSchema = object().shape({
  allow: boolean(),
  editableAmount: object()
    .nullable()
    .when('allow', {
      is: true,
      then: minMaxNumberSchema.required(requiredFieldMessage),
    }),
  editableNumber: object()
    .nullable()
    .when('allow', {
      is: true,
      then: getMinMaxNumberSchema(1).required(requiredFieldMessage),
    }),
  editableSplits: array()
    .nullable()
    .when('allow', {
      is: true,
      then: array()
        .of(orderedValueSchema)
        .required(requiredFieldMessage)
        .when('editableAmount.value', amount =>
          array().test(
            SPLIT_SUM_ERROR,
            () => inputErrors.splitsSumNotEqualDownError,
            splits => {
              if (!splits || !splits.length) return true;

              const splitsTotal = splits.reduce((count, split) => count + Number(split.value), 0);

              return getPriceFromNumber(splitsTotal) === getPriceFromNumber(amount);
            },
          ),
        ),
    }),
});

export const billingOptionSchema = object().shape({
  title: string().required(requiredFieldMessage),
  totalAmount: number()
    .min(1, greaterThanZeroFieldMessage)
    .transform((v, o) => (o === '' ? null : v))
    .nullable()
    .required(requiredFieldMessage),
  paymentSplitting: paymentSplittingSchema,
  downPayment: downPaymentSchema,

  paymentGrace: paymentGraceSchema,
  freeze: paymentFreezeSchema,
  creditCardServiceFee: creditCardFeeSchema,
  // initialFee: billingInitialFeeSchema,
});

export const billingSchema = object().shape({
  revenueCode: object()
    .nullable()
    .required(requiredFieldMessage),
  billingOptions: array()
    .of(billingOptionSchema)
    .min(1, () => inputErrors.billingOptionsCountError),
  defaultBillingOptionPresent: boolean().test(
    'isDefaultOptionExist',
    messages.setDefaultBillingOptionLabel,
    function() {
      const { billingOptions } = this.parent;
      const defaultBillingOption = billingOptions.find(option => option.useAsDefault);

      if (!defaultBillingOption) {
        return this.createError({ message: messages.setDefaultBillingOptionLabel });
      }

      return true;
    },
  ),
  outOfTermBillingOption: object().test(
    'isOutOftermOptionExist',
    messages.setOutOfTermBillingOptionLabel,
    function() {
      const { outOfTermBillingOption } = this.parent;

      if (!outOfTermBillingOption) {
        return this.createError({ message: messages.setOutOfTermBillingOptionLabel });
      }

      return true;
    },
  ),
});
