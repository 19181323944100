const charactersCountPerSMS = 160;
const charactersCountPerSMSUCS2 = 70;

const isGSMAlphabet = (text: string): boolean => {
  const regexp = new RegExp(
    '^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!"#$%&\'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$',
  );
  return regexp.test(text.toString());
};

export const getSegmentsCount = (message: string) => {
  const correctCharactersCountPerSMS =
    message.length > charactersCountPerSMS ? 153 : charactersCountPerSMS;
  const correctCharactersCountPerSMSUCS2 =
    message.length > charactersCountPerSMSUCS2 ? 67 : charactersCountPerSMSUCS2;
  return Math.ceil(
    message.length /
      (isGSMAlphabet(message) ? correctCharactersCountPerSMS : correctCharactersCountPerSMSUCS2),
  );
};
