import React, { forwardRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// validationSchema
import { feesSchema } from './feesValidationSchema';
// interfaces
import { IPackageStepProps, IFeesSection } from 'modules/services/interfaces/packages';
// components
import { ScrollBox } from 'common/components';
import FeesStepForm from './FeesStepForm';
// constants
import { useStepsLogic } from 'modules/services/hooks/useStepLogics';
import { defaultEditPackageFormData, DurationType } from 'modules/services/constants/packages';

interface IFeesStepProps extends IPackageStepProps<IFeesSection> {
  durationType: DurationType;
  durationEditableNumber: number;
}

const FeesStep = forwardRef(
  (
    {
      editPackageSectionFormData,
      renderFooter,
      handleChange,
      onPackageSubmit,
      formErrors,
      submitAttempted,
      durationType,
      durationEditableNumber,
    }: IFeesStepProps,
    ref,
  ): JSX.Element => {
    // form

    const formMethods = useForm({
      defaultValues: {
        ...defaultEditPackageFormData.feesSection,
        ...editPackageSectionFormData,
      },
      resolver: yupResolver(feesSchema),
      mode: 'all',
    });

    const { trigger, getValues } = formMethods;

    // effects

    const { onSubmit, onBack } = useStepsLogic(
      'feesSection',
      ref,
      getValues,
      trigger,
      formErrors,
      onPackageSubmit,
      handleChange,
      submitAttempted,
    );

    // renders

    return (
      <FormProvider {...formMethods}>
        <ScrollBox hasShadowsOnScroll>
          <form autoComplete="none">
            <FeesStepForm
              formMethods={formMethods}
              durationType={durationType}
              durationEditableNumber={durationEditableNumber}
            />
          </form>
        </ScrollBox>
        {renderFooter(onSubmit, undefined, onBack)}
      </FormProvider>
    );
  },
);

export default React.memo(FeesStep);
