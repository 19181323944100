import { defineMessages } from 'react-intl';

export default defineMessages({
  specificDateAndTime: {
    id: 'app.modules.crm.conditions.specificDateAndTime',
    defaultMessage: 'Specific date and time',
  },
  membersExpiringIn: {
    id: 'app.modules.crm.conditions.membersExpiringIn',
    defaultMessage: 'Members expiring in',
  },
  membersCardsExpiringIn: {
    id: 'app.modules.crm.conditions.membersCardsExpiringIn',
    defaultMessage: 'Members cards expiring in',
  },
  upcomingAppointmentsIn: {
    id: 'app.modules.crm.conditions.upcomingAppointmentsIn',
    defaultMessage: 'Upcoming appointments in',
  },
  updateOfScheduledAppointment: {
    id: 'app.modules.crm.conditions.updateOfScheduledAppointment',
    defaultMessage: 'Update of scheduled appointment',
  },
  cancellationOfAppointment: {
    id: 'app.modules.crm.conditions.cancellationOfAppointment',
    defaultMessage: 'Cancellation of appointment',
  },
  sessionRedeemed: {
    id: 'app.modules.crm.conditions.sessionRedeemed',
    defaultMessage: 'Session redeemed',
  },
  appointmentMissed: {
    id: 'app.modules.crm.conditions.appointmentMissed',
    defaultMessage: 'Appointment missed',
  },
  newAppointmentScheduled: {
    id: 'app.modules.crm.conditions.newAppointmentScheduled',
    defaultMessage: 'New appointment scheduled',
  },
  newMemberSignsUp: {
    id: 'app.modules.crm.conditions.newMemberSignsUp',
    defaultMessage: 'New member signs up',
  },
  newGuestSignsUp: {
    id: 'app.modules.crm.conditions.newGuestSignsUp',
    defaultMessage: 'New guest signs up',
  },
  lowUsageMembersContactedAfter: {
    id: 'app.modules.crm.conditions.lowUsageMembersContactedAfter',
    defaultMessage: 'Low usage members contacted after X days',
  },
  usedTheirPassLessThan: {
    id: 'app.modules.crm.conditions.usedTheirPassLessThan',
    defaultMessage: 'Used their pass less than X number of times within their pass limitations',
  },
  regular: {
    id: 'app.modules.crm.conditions.regular',
    defaultMessage: 'Regular (each day or number of days)',
  },
  checkIn: {
    id: 'app.modules.crm.conditions.checkIn',
    defaultMessage: 'Check in',
  },
  tagAdded: {
    id: 'app.modules.crm.conditions.tagAdded',
    defaultMessage: 'Tag added',
  },
  pointSalePurchase: {
    id: 'app.modules.crm.conditions.pointSalePurchase',
    defaultMessage: 'Point of sale purchase',
  },
  guestPassIssued: {
    id: 'app.modules.crm.conditions.guestPassIssued',
    defaultMessage: 'Guest pass (trial) issued',
  },
});
