import { combineReducers } from 'redux-immutable';

import salesPOSReportReducer from './salesPOS/reducers';
import salesOverTimeReducer from './salesOverTime/reducers';
import campaignsReportReducer from './campaignsReport/reducers';
import invoiceHistoryReducer from './invoiceHistory/reducers';
import paymentTransactionsReducer from './paymentTransactionsReport/reducers';
import registerStatusReducer from './registerStatusReport/reducers';
import usedSessionsReducer from './usedSessionsReport/reducers';
import zOutReportReducer from './zOutReport/reducers';
import crmDashboardReducer from './crmDashboard/reducers';
import appointmentsReportReducer from './appointmentsReport/reducers';
import reportsOverviewReducer from './reportsOverview/reducers';
import scheduledBillingsReportReducer from './scheduledBillingsReport/reducers';
import checkInHistoryReportReducer from './checkInHistoryReport/reducers';
import kidzoneCheckInHistoryReportReducer from './kidzoneCheckInHistoryReport/reducers';

export default combineReducers({
  invoiceHistory: invoiceHistoryReducer,
  salesPOSReport: salesPOSReportReducer,
  salesOverTime: salesOverTimeReducer,
  campaignsReport: campaignsReportReducer,
  paymentTransactionsReport: paymentTransactionsReducer,
  registerStatusReport: registerStatusReducer,
  usedSessionsReport: usedSessionsReducer,
  zOutReport: zOutReportReducer,
  crmDashboard: crmDashboardReducer,
  appointmentsReport: appointmentsReportReducer,
  reportsOverview: reportsOverviewReducer,
  scheduledBillingsReport: scheduledBillingsReportReducer,
  checkInHistoryReport: checkInHistoryReportReducer,
  kidzoneCheckInHistoryReport: kidzoneCheckInHistoryReportReducer,
});
