import { createContext } from 'react';

import { IBaseStepProps } from 'common/interfaces/common';

export const StepContext = createContext<IBaseStepProps>({
  onBack: () => {},
  onNext: () => {},
  done: () => {},
  renderFooter: () => null,
  helperData: {},
});
