import { combineReducers } from 'redux-immutable';

import registerList from './register/reducers';
import inventoryItemsList from './inventoryItems/reducers';
import receiptsList from './receipts/reducers';
import revenueCodesReducer from './revenue-codes/reducers';
import taxRatesReducer from './taxRates/reducers';
import paymentMethodsReducer from './paymentMethods/reducers';
import vendorsReducer from './vendors/reducers';

export default combineReducers({
  registerPosSettings: registerList,
  inventoryItemsPosSettings: inventoryItemsList,
  receiptsPosSettings: receiptsList,
  revenueCodes: revenueCodesReducer,
  taxRates: taxRatesReducer,
  paymentMethods: paymentMethodsReducer,
  vendors: vendorsReducer,
});
