import { defineMessages } from 'react-intl';

export default defineMessages({
  activate: {
    id: 'app.common.menuItems.activate',
    defaultMessage: 'Activate',
  },
  deactivate: {
    id: 'app.common.menuItems.deactivate',
    defaultMessage: 'Deactivate',
  },
  edit: {
    id: 'app.common.menuItems.edit',
    defaultMessage: 'Edit',
  },
  delete: {
    id: 'app.common.menuItems.delete',
    defaultMessage: 'Delete',
  },
  active: {
    id: 'app.common.menuItems.active',
    defaultMessage: 'Active',
  },
  inactive: {
    id: 'app.common.menuItems.inactive',
    defaultMessage: 'Inactive',
  },
  view: {
    id: 'app.common.menuItems.view',
    defaultMessage: 'View',
  },
  male: {
    id: 'app.common.menuItems.male',
    defaultMessage: 'Male',
  },
  female: {
    id: 'app.common.menuItems.female',
    defaultMessage: 'Female',
  },
  other: {
    id: 'app.common.menuItems.other',
    defaultMessage: 'Other',
  },
  payPeriods: {
    id: 'app.common.menuItems.payPeriods',
    defaultMessage: 'Pay Periods',
  },
  hourly: {
    id: 'app.common.menuItems.hourly',
    defaultMessage: 'Hourly',
  },
  salary: {
    id: 'app.common.menuItems.salary',
    defaultMessage: 'Salary',
  },
  unit: {
    id: 'app.common.menuItems.unit',
    defaultMessage: 'Unit',
  },
  ageGroup_18_24: {
    id: 'app.common.menuItems.ageGroup_18_24',
    defaultMessage: '18-24',
  },
  ageGroup_25_34: {
    id: 'app.common.menuItems.ageGroup_25_34',
    defaultMessage: '25-34',
  },
  ageGroup_35_44: {
    id: 'app.common.menuItems.ageGroup_35_44',
    defaultMessage: '35-44',
  },
  ageGroup_45_54: {
    id: 'app.common.menuItems.ageGroup_45_54',
    defaultMessage: '45-54',
  },
  ageGroup_55_64: {
    id: 'app.common.menuItems.ageGroup_55_64',
    defaultMessage: '55-64',
  },
  from65: {
    id: 'app.common.menuItems.from65',
    defaultMessage: 'From 65',
  },
  settings: {
    id: 'app.common.menuItems.settings',
    defaultMessage: 'Settings',
  },
  signOut: {
    id: 'app.common.menuItems.signOut',
    defaultMessage: 'Sign out',
  },
  addDOB: {
    id: 'app.common.menuItems.addDOB',
    defaultMessage: 'Add DOB',
  },
  lastYear: {
    id: 'app.common.menuItems.lastYear',
    defaultMessage: 'Last year',
  },
  thisYear: {
    id: 'app.common.menuItems.thisYear',
    defaultMessage: 'This year',
  },
});
