import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles, TextField as MuiTextField, TextFieldProps, Theme } from '@material-ui/core';
import cx from 'classnames';

import inputErrors from 'common/messages/inputErrors';

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    '& .MuiFormHelperText-root.MuiFormHelperText-contained': {
      color: ({ recommended, error }: TTextFieldProps) => {
        if (recommended && !error) {
          return theme.palette.warning.main;
        }

        if (error) {
          return theme.palette.error.main;
        }

        return 'none';
      },
      opacity: 1,
    },
  },
}));

type TTextFieldProps = TextFieldProps & {
  recommended?: boolean;
};

const TextField = (props: TTextFieldProps): JSX.Element => {
  const { recommended, helperText, error, className, ...rest } = props;

  const classes = useStyles(props);

  return (
    <MuiTextField
      {...rest}
      helperText={
        recommended && !error ? (
          <FormattedMessage {...inputErrors.recommendedFieldLabel} />
        ) : (
          helperText
        )
      }
      error={error}
      className={cx(classes.textField, className)}
    />
  );
};

export default TextField;
