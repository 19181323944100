import * as yup from 'yup';
import {
  FieldNecessity,
  RequiredProfileInfo,
} from 'modules/corporate-settings/constants/requiredFields';
import { getRequiredMessage } from 'common/constants/globalConstants';
import { TShortProfileFieldInfoImt } from 'modules/corporate-settings/interfaces';

export const getAllergiesValidationSchema = (requiredFields: TShortProfileFieldInfoImt) => {
  return {
    allergies: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string(),
        }),
      )
      .when('allergies1', {
        is: () => {
          return requiredFields.get(RequiredProfileInfo.Allergies) === FieldNecessity.Required;
        },
        then: schema => schema.min(1, getRequiredMessage),
        otherwise: schema => schema,
      }),
  };
};
