import * as yup from 'yup';

import { denominations } from 'modules/pos-kiosk/constants/registers';
import { getRequiredMessage } from 'common/constants/globalConstants';

const schema = {};

denominations.forEach(denominationItem => {
  schema[denominationItem.key] = yup
    .number()
    .typeError(getRequiredMessage)
    .required(getRequiredMessage);
});

export const ReconcileBillsValidationSchema = yup.object().shape(schema);
