import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as XDeleteIcon } from 'img/icons/times.svg';
import { ReactComponent as PaymentCardIcon } from 'img/icons/payment-method-card.svg';
import { ReactComponent as UserIcon } from 'img/icons/user.svg';
import { InvoiceStatus } from 'common/interfaces/invoices';
import messages from 'common/messages/messages';
import reportsMessages from 'modules/reports/messages/messages';
import { ActionItem, ActionsMenu } from 'common/components';

interface IActionsMenuProps {
  invoiceId: string;
  invoiceStatus: InvoiceStatus;
  isNOSCustomer: boolean;
  onVoidInvoice: (invoiceId: string) => void;
  onAssignSalesPerson: (invoiceId: string) => void;
  onOpenTransactionsModal: () => void;
}

const useStyles = makeStyles({
  icon: {
    width: 16,
    height: 16,
  },
});

const InvoiceActionsMenu = (props: IActionsMenuProps): JSX.Element => {
  const {
    invoiceId,
    invoiceStatus,
    onVoidInvoice,
    onAssignSalesPerson,
    onOpenTransactionsModal,
    isNOSCustomer,
  } = props;

  const classes = useStyles();

  const handleAssignClick = () => {
    onAssignSalesPerson(invoiceId);
  };
  const handleVoidClick = () => {
    onVoidInvoice(invoiceId);
  };

  return (
    <ActionsMenu horizontal tableActionsMode>
      <ActionItem
        icon={<UserIcon className={classes.icon} />}
        disabled={!isNOSCustomer}
        onClick={handleAssignClick}
      >
        <FormattedMessage {...reportsMessages.assignMemberTitle} />
      </ActionItem>
      {invoiceStatus === InvoiceStatus.IN_PROGRESS && (
        <ActionItem icon={<XDeleteIcon className={classes.icon} />} onClick={handleVoidClick}>
          <FormattedMessage {...messages.voidInvoice} />
        </ActionItem>
      )}
      <ActionItem
        icon={<PaymentCardIcon className={classes.icon} />}
        onClick={onOpenTransactionsModal}
      >
        <FormattedMessage {...messages.viewTransactionsBtn} />
      </ActionItem>
    </ActionsMenu>
  );
};

export default InvoiceActionsMenu;
