export const actionTypes = {
  SETTINGS_SET_SELECTED_LOCALE: 'SETTINGS_SET_SELECTED_LOCALE',
  SETTINGS_SET_IS_SIDEBAR_OPEN: 'SETTINGS_SET_IS_SIDEBAR_OPEN',
  SETTINGS_SET_TIMEZONE_VIEW: 'SETTINGS_SET_TIMEZONE_VIEW',
  FETCH_PEAK_SYSTEM_SETTINGS: 'SETTINGS/FETCH_PEAK_SYSTEM_SETTINGS',
  UPDATE_PEAK_SYSTEM_SETTINGS: 'SETTINGS/UPDATE_PEAK_SYSTEM_SETTINGS',
  FETCH_PROFILE_FIELDS_INFO: 'SETTINGS/FETCH_PROFILE_FIELDS_INFO',
  UPDATE_PROFILE_FIELDS_INFO: 'SETTINGS/UPDATE_PROFILE_FIELDS_INFO',
};

export enum LanguageLocales {
  EN = 'en',
  FR = 'fr',
}

export enum LanguageLocalesAbbreviations {
  en = 'EN',
  fr = 'FR',
}

export const LanguageLocalesOptions = [
  {
    key: LanguageLocales.EN,
    label: LanguageLocalesAbbreviations.en,
    value: LanguageLocales.EN,
  },
  {
    key: LanguageLocales.FR,
    label: LanguageLocalesAbbreviations.fr,
    value: LanguageLocales.FR,
  },
];
