import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, createStyles, makeStyles, Typography, Theme } from '@material-ui/core';

import useTimezoneMoment from 'common/hooks/useTimezoneMoment';

import { getInitials } from 'helpers/common';

import { INoteImt } from 'common/components/PersonProfile/interfaces';

import { AvatarWithInitials, DialogComponent } from 'common/components';

import messages from 'modules/front-desk/messages/messages';
import commonMessages from 'common/messages/messages';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noteCreatedDate: {
      color: theme.palette.secondary.dark,
    },
    noteText: {
      padding: theme.spacing(2, 3),
      // TODO delete after update MUI
      backgroundColor: '#F7F8F8',
      borderRadius: theme.spacing(0, 2, 2, 2),
    },
  }),
);

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  note: INoteImt;
}

const ViewNoteModal = ({ onClose, isOpen, note }: IProps): JSX.Element => {
  const [timezoneMoment] = useTimezoneMoment();

  const classes = useStyles();

  const initials = getInitials(
    note.getIn(['author', 'firstName']),
    note.getIn(['author', 'lastName']),
  );

  return (
    <DialogComponent
      size="sm"
      title={<FormattedMessage {...messages.noteTitle} />}
      submitBtnTitle={<FormattedMessage {...commonMessages.saveBtn} />}
      cancelBtn={false}
      submitBtn={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={1.5}>
        <Box display="flex" alignItems="center">
          <AvatarWithInitials
            width="16px"
            height="16px"
            initials={initials}
            imageUrl={note.getIn(['author', 'imageUrl'])}
          />

          <Typography>
            {`${note.getIn(['author', 'firstName'])} ${note.getIn(['author', 'lastName'])}`}
          </Typography>
        </Box>

        <Typography className={classes.noteCreatedDate}>
          {timezoneMoment(note.get('createdDate')).format('h:mm A, ddd D')}
        </Typography>
      </Box>

      <Box className={classes.noteText}>{note.get('text')}</Box>
    </DialogComponent>
  );
};

export default ViewNoteModal;
