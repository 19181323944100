import { List } from 'immutable';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Card, Grid, makeStyles } from '@material-ui/core';
import cn from 'classnames';

import {
  AvatarCard,
  BasicInfo,
  GeneralInfoSection,
  JobPositionsBlock,
  LoadingBackdrop,
  PermissionsBlock,
  ScrollBox,
  AttachmentsBlock,
} from 'common/components';
import { getInitials } from 'helpers/common';
import {
  IEmployeeJobPositionImt,
  IEmployeeProfileDtoImt,
  ISecurityRoleToClubListDtoImt,
} from 'common/interfaces/employee';
import { UploadImageModal, WebcamImageModal } from 'common/modals';
import { IAttachment, IAttachmentImt } from 'common/interfaces/uploadFile';
import { CustomBrakePoints } from 'common/components/PersonProfile/constants';
import { throttle } from 'common/utils';
import { clearUploadFile } from 'common/state/uploadFile/actions';
import { useAppDispatch } from 'store/hooks';
import { TimeclockHistoryBlock } from 'common/components/EmployeeProfile';
import { ITimeclockHistoryItemImt } from 'modules/timeclock/interfaces/timeclock';
import DeleteImageModal from 'common/modals/DeleteImageModal/DeleteImageModal';

type LoadersType = {
  attachmentsIsLoading: boolean;
  jobPositionsIsLoading: boolean;
  permissionsIsLoading: boolean;
  myProfileIsLoading: boolean;
  recentTimeclockHistoryLoading?: boolean;
};

interface IEmployeeProfileProps {
  EditMenu: JSX.Element;
  EditJobPositions?: JSX.Element;
  EditPermissions?: JSX.Element;
  ModificationHistory?: JSX.Element;
  profile: IEmployeeProfileDtoImt;
  profilePermissions: List<ISecurityRoleToClubListDtoImt> | null;
  profileAttachments: List<IAttachmentImt> | null;
  profileJobPositions: List<IEmployeeJobPositionImt> | null;
  profileRecentTimeclockHistory?: List<ITimeclockHistoryItemImt> | null;
  loaders: LoadersType;
  onUploadProfileAttachments: (attachments: IAttachment[]) => void;
  onDeleteProfileAttachment: (attachmentId: string) => void;
  onUpdateAvatar: (attachment: IAttachmentImt) => void;
  onDeleteAvatar: () => void;
  onJobPositionsHistoryClick: () => void;
  hideProfileClubCount?: boolean;
}

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    overflow: 'hidden',
    height: '100%',
    position: 'relative',
  },
  profile: {
    padding: theme.spacing(2),
  },
  leftContainer: {
    width: theme.spacing(26.625),
  },
  sections: {
    width: `calc(100% - ${theme.spacing(26.625)}px)`,
  },
  profileAvatar: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  profileAvatarSmall: {
    height: '100%',
  },
  profileAvatarXs: {
    marginRight: 0,
  },
  fullContent: {
    width: '100%',
  },
}));
const EmployeeProfile = (props: IEmployeeProfileProps): JSX.Element => {
  const {
    profile,
    profilePermissions,
    profileAttachments,
    profileJobPositions,
    profileRecentTimeclockHistory,
    loaders,
    hideProfileClubCount,
    onUploadProfileAttachments,
    onDeleteProfileAttachment,
    onJobPositionsHistoryClick,
    onUpdateAvatar,
    onDeleteAvatar,
    EditMenu,
    EditJobPositions,
    EditPermissions,
    ModificationHistory,
  } = props;

  const {
    attachmentsIsLoading,
    myProfileIsLoading,
    permissionsIsLoading,
    jobPositionsIsLoading,
    recentTimeclockHistoryLoading = false,
  } = loaders;

  const dispatch = useAppDispatch();
  const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
  const [isOpenWebcamModal, setIsOpenWebcamModal] = useState(false);
  const [isOpenDeleteAvatarModal, setIsOpenDeleteAvatarModal] = useState(false);
  const [blockSize, setBlockSize] = useState<number>();

  const resizeElementRef = useRef<Element>(null);

  const classes = useStyles();

  const permissions = useMemo(() => profilePermissions?.toJS(), [profilePermissions]);
  const attachments = useMemo(() => profileAttachments?.toJS(), [profileAttachments]);
  const jobPositions = useMemo(() => profileJobPositions?.toJS(), [profileJobPositions]);
  const recentTimeclockHistory = useMemo(() => profileRecentTimeclockHistory?.toJS(), [
    profileRecentTimeclockHistory,
  ]);

  const isLessThanDesktop = blockSize ? blockSize < CustomBrakePoints.desktop : null;
  const isLessThanTablet = blockSize ? blockSize < CustomBrakePoints.tablet : null;
  const isLessThanXs = blockSize ? blockSize < CustomBrakePoints.xs : null;

  const onToggleUploadModal = useCallback(() => {
    setIsOpenUploadModal(!isOpenUploadModal);
  }, [isOpenUploadModal]);
  const onToggleWebcamModal = useCallback(() => {
    setIsOpenWebcamModal(!isOpenWebcamModal);
  }, [isOpenWebcamModal]);
  const onToggleDeleteAvatarModal = useCallback(() => {
    setIsOpenDeleteAvatarModal(!isOpenDeleteAvatarModal);
  }, [isOpenDeleteAvatarModal]);

  const attachmentFieldId = `employee-${profile.get('id')}-attachments`;

  useEffect(() => {
    const resizeElement: Element | null = resizeElementRef.current;
    const throttledSetState = throttle(setBlockSize, 300);
    const resizeObserver = new ResizeObserver(entries => {
      const boxSize = entries[0].contentRect.width;
      throttledSetState(boxSize);
    });

    if (resizeElement) {
      resizeObserver.observe(resizeElement);
    }

    return () => {
      if (resizeElement) {
        resizeObserver.unobserve(resizeElement);
      }

      dispatch(clearUploadFile(attachmentFieldId));
    };
  }, [setBlockSize, dispatch, attachmentFieldId]);

  const uploadImageAvatar = (attachment: IAttachmentImt) => {
    onUpdateAvatar(attachment);
    onToggleUploadModal();
  };

  const uploadWebcamAvatar = (attachment: IAttachmentImt) => {
    onUpdateAvatar(attachment);
    onToggleWebcamModal();
  };

  const deleteAvatar = () => {
    onDeleteAvatar();
    onToggleDeleteAvatarModal();
  };

  const size = isLessThanDesktop ? '100%' : '50%';
  const isLessThanTabletSizeOnly = isLessThanTablet && !isLessThanXs;
  const isLessThanTabletOrXsSize = isLessThanXs || isLessThanTablet;

  const sections = (
    <Box
      display="flex"
      flexDirection="column"
      className={cn(classes.sections, {
        [classes.fullContent]: isLessThanTabletOrXsSize,
      })}
    >
      {isLessThanTabletOrXsSize || (
        <Box display="flex" justifyContent="space-between" pb={4}>
          <GeneralInfoSection profile={profile} />
          {EditMenu}
        </Box>
      )}
      <Box display="flex" flexWrap="wrap">
        <Box width={size} pr={isLessThanDesktop ? 0 : 1}>
          {permissions && (
            <PermissionsBlock
              EditPermissions={EditPermissions}
              hideProfileClubCount={hideProfileClubCount}
              isLoading={permissionsIsLoading}
              profilePermissions={permissions}
            />
          )}
        </Box>
        <Box width={size} pt={isLessThanDesktop ? 2 : 0} pl={isLessThanDesktop ? 0 : 1}>
          {attachments && (
            <AttachmentsBlock
              fieldId={attachmentFieldId}
              onUploadProfileAttachments={onUploadProfileAttachments}
              onDeleteProfileAttachment={onDeleteProfileAttachment}
              attachments={attachments}
              attachmentsIsLoading={attachmentsIsLoading}
            />
          )}
        </Box>
        <Box width="100%" mt={2}>
          {recentTimeclockHistory && (
            <TimeclockHistoryBlock
              recentTimeclockHistory={recentTimeclockHistory}
              isLoading={recentTimeclockHistoryLoading}
            />
          )}
        </Box>
        {ModificationHistory && (
          <Box width="100%" mt={2}>
            {ModificationHistory}
          </Box>
        )}
      </Box>
    </Box>
  );

  return (
    <Card ref={resizeElementRef} className={classes.card}>
      <ScrollBox>
        <Box className={classes.profile} display="flex">
          <Grid
            className={cn(classes.leftContainer, {
              [classes.fullContent]: isLessThanTabletOrXsSize,
            })}
            item
            container
            direction="column"
          >
            <Grid item className={classes.fullContent}>
              <Box display="flex" justifyContent="center" flexDirection="row">
                <Box
                  display="flex"
                  className={cn(classes.profileAvatar, classes.fullContent, {
                    [classes.profileAvatarSmall]: isLessThanTabletOrXsSize,
                    [classes.profileAvatarXs]: isLessThanXs,
                  })}
                >
                  <AvatarCard
                    isLessThanTablet={isLessThanTablet}
                    isLessThanXs={isLessThanXs}
                    withPlaceholder
                    initials={getInitials(profile.get('firstName'), profile.get('lastName'))}
                    avatarUrl={profile.get('image')?.get('url')}
                    onToggleUploadModal={onToggleUploadModal}
                    onToggleWebcamModal={onToggleWebcamModal}
                    onToggleDeleteModal={onToggleDeleteAvatarModal}
                  />
                </Box>
                {isLessThanTabletSizeOnly && (
                  <Box display="flex">
                    <Box>
                      <BasicInfo profile={profile} />
                    </Box>
                    {EditMenu}
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item>
              {isLessThanTabletOrXsSize && (
                <Box display="flex" pb={2}>
                  <GeneralInfoSection
                    shouldBeHiddenBasicInfo={isLessThanTabletSizeOnly}
                    profile={profile}
                  />
                  {isLessThanXs && EditMenu}
                </Box>
              )}
              {jobPositions && (
                <Box pr={isLessThanTabletOrXsSize ? 0 : 2} pb={2}>
                  <JobPositionsBlock
                    onHistoryClick={onJobPositionsHistoryClick}
                    EditJobPositions={EditJobPositions}
                    isLoading={jobPositionsIsLoading}
                    jobPositions={jobPositions}
                  />
                </Box>
              )}
              {isLessThanTabletOrXsSize && sections}
            </Grid>
          </Grid>
          {isLessThanTabletOrXsSize || sections}
          {isOpenUploadModal && (
            <UploadImageModal
              id={`upload-employee-${profile.get('id')}-photo-modal`}
              isOpen={isOpenUploadModal}
              onSubmit={uploadImageAvatar}
              onToggle={onToggleUploadModal}
            />
          )}
          {isOpenWebcamModal && (
            <WebcamImageModal
              id={`webcam-employee-photo-modal${profile.get('id')}`}
              isOpen={isOpenWebcamModal}
              onToggle={onToggleWebcamModal}
              onSubmit={uploadWebcamAvatar}
            />
          )}

          {isOpenDeleteAvatarModal && (
            <DeleteImageModal
              isOpen={isOpenDeleteAvatarModal}
              onClose={onToggleDeleteAvatarModal}
              onSubmit={deleteAvatar}
            />
          )}
        </Box>
      </ScrollBox>
      <LoadingBackdrop isLoading={myProfileIsLoading} />
    </Card>
  );
};

export default EmployeeProfile;
