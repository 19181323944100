import * as yup from 'yup';
import { stringNumberSchema } from 'common/validationSchemas/stringNumberSchema';
import inputErrors from 'common/messages/inputErrors';

export const getClientRewardsValidationSchema = (rewards: number): yup.AnyObjectSchema => {
  return yup.object().shape({
    amount: stringNumberSchema
      .max(rewards, inputErrors.amountHigherThanClientRewards)
      .min(1, inputErrors.checksAmountMinError)
      .typeError(inputErrors.requiredFieldError)
      .required(() => inputErrors.requiredFieldError),
  });
};
