import dompurify from 'dompurify';

const getSignatureBlock = () =>
  `\n<div style="display: inline-block;margin: 10px 0;width: 167px;border: 1px dashed #66cd00;position: relative;border-radius: 3px;height: 59px;padding: 32px 4px 8px;background-color: rgba(102, 205, 0, 0.08);"><div style="top: 8px;left: 4px;color: #66cd00;position: absolute;font-size: 10px;font-weight: bold;line-height: 1;letter-spacing: normal;text-transform: uppercase;">Customer Signature</div><div style="width: calc(100% - 8px);bottom: 8px;position: absolute;border-bottom: 1px solid #323232;" /></div>\n`;

export const replaceSignatureTag = (documentText: string) => {
  const signatureBlock = getSignatureBlock();

  try {
    const sanitizer = dompurify.sanitize;

    return sanitizer(documentText.replace('[[SIGNATURE]]', signatureBlock), {
      ALLOWED_ATTR: ['href', 'target', 'style'],
    });
  } catch (e) {
    return '';
  }
};

export const replaceSignatureImg = (documentText: string, signatureImgId: string) => {
  const signatureBlock = getSignatureBlock();

  const sanitizer = dompurify.sanitize;

  const divEl = document.createElement('div');
  const divSignatureWrapper = document.createElement('div');

  divSignatureWrapper.innerHTML = signatureBlock;
  divEl.innerHTML = documentText;

  const imgEls = divEl.querySelectorAll(`img[data-signature-id="${signatureImgId}"]`);

  if (imgEls) {
    for (let i = 0, ln = imgEls.length; i < ln; i += 1) {
      imgEls[i].parentNode.insertBefore(divSignatureWrapper, imgEls[i]);
      imgEls[i].remove();
    }
  }

  return sanitizer(divEl.innerHTML, { ALLOWED_ATTR: ['href', 'target', 'style'] });
};
