import React from 'react';
import { FormattedMessage } from 'react-intl';
import commonMessages from 'common/messages/messages';
import { ConstOptions } from 'common/constants/classes';

export enum StatusType {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Archived = 'ARCHIVED',
}

export const StatusTypes = new ConstOptions([
  {
    key: StatusType.Active,
    message: commonMessages.activeOption,
    value: 'true',
  },
  {
    key: StatusType.Inactive,
    message: commonMessages.inactiveOption,
    value: 'false',
  },
]);

export const OpenedClosedStatusTypes = new ConstOptions([
  {
    key: 'opened',
    message: commonMessages.opened,
    value: 'true',
  },
  {
    key: 'closed',
    message: commonMessages.closed,
    value: 'false',
  },
]);

export const ActiveInactiveStatus = [
  {
    key: StatusType.Active,
    label: <FormattedMessage {...commonMessages.activeOption} />,
    value: true,
  },
  {
    key: StatusType.Inactive,
    label: <FormattedMessage {...commonMessages.inactiveOption} />,
    value: false,
  },
];

export const YesNoStatus = [
  {
    key: 'YES_OPTION',
    label: <FormattedMessage {...commonMessages.yesOption} />,
    value: true,
  },
  {
    key: 'NO_OPTION',
    label: <FormattedMessage {...commonMessages.noOption} />,
    value: false,
  },
];

export const ActiveArchivedStatus = [
  {
    key: StatusType.Active,
    label: <FormattedMessage {...commonMessages.activeOption} />,
    value: true,
  },
  {
    key: StatusType.Archived,
    label: <FormattedMessage {...commonMessages.archivedOption} />,
    value: false,
  },
];
