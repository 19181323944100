import { ITableParams } from 'common/interfaces/table';

import ApiService from './ApiService';
import { IPaginatedData } from 'common/interfaces/pagination';
import axios from 'axios';
import { ICampaignDto } from 'common/interfaces/campaign';
import { ICampaignForm } from 'modules/crm/interfaces/campaigns';

export type IPaginatedCampaigns = IPaginatedData<ICampaignDto>;

export default class PtCampaignsService extends ApiService {
  public getCampaigns = (requestOptions?: ITableParams): Promise<IPaginatedCampaigns> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get(`api/v1/pt-campaign`, { params: searchParams });
  };

  public getCampaignById = (id: string): Promise<ICampaignDto> => {
    return axios.get(`api/v1/pt-campaign/${id}`);
  };

  public createCampaign = (requestData: ICampaignForm): Promise<ICampaignDto> => {
    return axios.post(`api/v1/pt-campaign`, { ...requestData });
  };

  public updateCampaign = (id: string, requestData: ICampaignForm): Promise<ICampaignDto> => {
    return axios.put(`api/v1/pt-campaign/${id}`, { ...requestData });
  };
}
