import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import commonMessages from 'common/messages/messages';
import QuestionnaireModal from 'common/modals/QuestionnaireModal/QuestionnaireModal';
import { selectIsOpenRecommendedFieldsModal } from 'common/state/modals/selectors';
import { useAppDispatch } from 'store/hooks';
import {
  setIsSubmittedRecommendedFieldsModal,
  showRecommendedFieldsModal,
} from 'common/state/modals/actions';
import { Button } from 'common/components';

const RecommendedFields = () => {
  const dispatch = useAppDispatch();

  const isOpen = useSelector(selectIsOpenRecommendedFieldsModal);

  const onSubmit = () => {
    dispatch(setIsSubmittedRecommendedFieldsModal(true));
  };

  const onClose = () => {
    dispatch(showRecommendedFieldsModal(false));
  };

  return (
    <QuestionnaireModal
      title={<FormattedMessage {...commonMessages.saveConfirmation} />}
      body={<FormattedMessage {...commonMessages.skipRecommendedFields} />}
      submitBtn={false}
      cancelBtn={false}
      additionalActionButtons={[
        <Button color="primary" onClick={onSubmit}>
          <FormattedMessage {...commonMessages.skipBtn} />
        </Button>,
        <Button color="primary" variant="contained" onClick={onClose}>
          <FormattedMessage {...commonMessages.okBtn} />
        </Button>,
      ]}
      onClose={onClose}
      isOpen={isOpen}
    />
  );
};

export default RecommendedFields;
