import { DurationUnit } from 'common/interfaces/common';

export function priceRounding(value: number): number {
  return Math.round(value * 100) / 100;
}

export function getPriceAmountString(price: number, amount: number): number {
  return priceRounding(price * amount);
}

export function calculateDuration(type: string, redeemedNumber: number): string {
  switch (type) {
    case DurationUnit.Mins:
      if (redeemedNumber) {
        const minutes = redeemedNumber % 60;
        const hours = (redeemedNumber - minutes) / 60;

        return `${hours}h ${minutes}m`;
      }
      return '0';
    case DurationUnit.Hours:
      return `${redeemedNumber}h`;
    case DurationUnit.Days:
      return `${redeemedNumber} day${redeemedNumber > 1 ? 's' : ''}`;
    default:
      return '';
  }
}

export const getPriceFromNumber = (amount: number | string): number => {
  if (Number.isNaN(amount)) return NaN;

  return parseFloat(Number(amount).toFixed(2));
};

const dollarCoinsAndBanknotes: number[] = [0.1, 0.25, 0.5, 1, 2, 5, 10, 20, 50, 100];
export const calculateTenderedPropositions = (tenderedPrice: number, amount = 4): number[] => {
  const resultSet = new Set<number>();

  const tenderedCents = tenderedPrice * 100;

  for (let i = 0; i < dollarCoinsAndBanknotes.length && resultSet.size < amount; i += 1) {
    const roundingCents = dollarCoinsAndBanknotes[i] * 100;
    const approx = tenderedCents % roundingCents;
    const suggestion = tenderedCents + roundingCents - approx;

    if (approx !== 0 && suggestion / 100 !== tenderedPrice) {
      resultSet.add(suggestion / 100);
    }
  }

  return Array.from(resultSet);
};
