import React from 'react';
import { FormHelperText, Grid } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';

import { TimePicker } from 'common/components';
import timeclockMessages from 'modules/timeclock/messages';
import inputErrors from 'common/messages/inputErrors';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { FormattedMessage } from 'react-intl';

export const CLOCK_IN_CLOCK_OUT_TIME = 'clockInClockOutTime';

const ClockInClockOutField = (): JSX.Element => {
  const { control, errors, watch, trigger } = useFormContext();

  const renderIntlMessage = useRenderIntlMessage();

  const { clockIn, clockOut } = watch(['clockIn', 'clockOut']);

  const errorType = errors.clockIn?.type || errors.clockOut?.type;

  const hasClockInClockOutError = Boolean(errorType === CLOCK_IN_CLOCK_OUT_TIME);

  return (
    <Grid item xs={8}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Controller
            name="clockIn"
            control={control}
            defaultValue={null}
            render={({ onChange, onBlur, value, name }) => (
              <TimePicker
                onBlur={onBlur}
                value={value}
                onChange={v => {
                  onChange(v);
                  if (clockOut) trigger('clockOut');
                }}
                labelMessageDescriptor={timeclockMessages.clockInOption}
                name={name}
                error={hasClockInClockOutError || Boolean(errors.clockIn)}
                helperText={
                  hasClockInClockOutError ? '' : renderIntlMessage(errors.clockIn?.message)
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="clockOut"
            control={control}
            defaultValue={null}
            render={({ onChange, onBlur, value, name }) => (
              <TimePicker
                onBlur={onBlur}
                value={value}
                onChange={v => {
                  onChange(v);
                  if (clockIn) trigger('clockIn');
                }}
                labelMessageDescriptor={timeclockMessages.clockOut}
                name={name}
                error={hasClockInClockOutError || Boolean(errors.clockOut)}
                helperText={
                  hasClockInClockOutError ? '' : renderIntlMessage(errors.clockOut?.message)
                }
              />
            )}
          />
        </Grid>
      </Grid>
      {hasClockInClockOutError && (
        <FormHelperText error>
          <FormattedMessage {...inputErrors.clockInClockOutTimeError} />
        </FormHelperText>
      )}
    </Grid>
  );
};

export default ClockInClockOutField;
