import axios from 'axios';
import ApiService from 'services/network/ApiService';

import {
  IBannerAdvertisementFormData,
  IBannersAdvertisementListItem,
  IPaginatedBannersAdvertisementList,
  IPaginatedPushNotificationsList,
  IPushNotificationFormData,
  IPushNotificationsListItem,
} from 'modules/communication/interfaces';
import { ITableParams } from 'common/interfaces/table';

export default class CommunicationService extends ApiService {
  public getBannerAdvertisementsList = (
    requestOptions?: ITableParams,
  ): Promise<IPaginatedBannersAdvertisementList> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get('/api/v1/banner-advertisement', { params: searchParams });
  };

  public createBannerAdvertisement = (
    requestData: IBannerAdvertisementFormData,
  ): Promise<IBannersAdvertisementListItem> =>
    axios.post('/api/v1/banner-advertisement', requestData);

  public updateBannerAdvertisement = (
    banerId: string,
    requestData: IBannerAdvertisementFormData,
  ): Promise<IBannersAdvertisementListItem> =>
    axios.put(`/api/v1/banner-advertisement/${banerId}`, requestData);

  public updateBannerAdvertisementStatus = (banerId: string, active: boolean) =>
    axios.patch(`/api/v1/banner-advertisement/${banerId}`, { active });

  public getPushNotificationsList = (
    requestOptions?: ITableParams,
  ): Promise<IPaginatedPushNotificationsList> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get('/api/v1/push-notification', { params: searchParams });
  };

  public createPushNotification = (
    requestData: IPushNotificationFormData,
  ): Promise<IPushNotificationsListItem> => axios.post('/api/v1/push-notification', requestData);

  public updatePushNotification = (
    banerId: string,
    requestData: IPushNotificationFormData,
  ): Promise<IPushNotificationFormData> =>
    axios.put(`/api/v1/push-notification/${banerId}`, requestData);

  public updtaePushNotificationStatus = (banerId: string, active: boolean) =>
    axios.patch(`/api/v1/push-notification/${banerId}`, { active });
}
