import React from 'react';
import { TextField } from '@material-ui/core';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
// common
import { DialogComponent } from 'common/components';
// messages
import { FormattedMessage } from 'react-intl';
import messages from 'common/messages/messages';
import reportsMessages from 'modules/reports/messages/messages';
import inputLabels from 'common/messages/inputLabels';

interface ISendReportModalProps {
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onSubmit: (data) => void;
}

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid Email')
    .required('Required field'),
});

const SendReportModal = ({
  isOpen,
  onClose,
  onSubmit,
  isLoading,
}: ISendReportModalProps): JSX.Element => {
  const initFormValues = {
    email: '',
  };

  const formMethods = useForm({
    defaultValues: initFormValues,
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });
  const { control, handleSubmit, errors, reset } = formMethods;

  return (
    <DialogComponent
      title={<FormattedMessage {...reportsMessages.exportReportTitle} />}
      submitBtnTitle={<FormattedMessage {...messages.exportBtn} />}
      size="xs"
      isOpen={isOpen}
      onClose={() => {
        reset({});
        onClose();
      }}
      onSubmit={handleSubmit(onSubmit)}
      disabled={isLoading}
    >
      <form>
        <Controller
          name="email"
          control={control}
          render={({ name, value, onBlur, onChange }) => (
            <TextField
              fullWidth
              name={name}
              variant="outlined"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              label={<FormattedMessage {...inputLabels.email} />}
              error={!!errors.email}
              helperText={errors.email?.message}
              inputProps={{
                autoComplete: 'off',
              }}
            />
          )}
        />
      </form>
    </DialogComponent>
  );
};

export default React.memo(SendReportModal);
