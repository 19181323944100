import { fromJS } from 'immutable';

import { actionTypes } from 'common/state/globalSearch/constants';

const initSearchResultsState = fromJS({
  searchResults: {},
  isSearchResultsLoading: false,
});

function searchResultsReducer(state = initSearchResultsState, action) {
  switch (action.type) {
    case actionTypes.GET_SEARCH_RESULTS:
      return state.set('searchResults', fromJS(action.payload));
    case actionTypes.GET_SEARCH_RESULTS_LOADING:
      return state.set('isSearchResultsLoading', action.payload);
    case actionTypes.RESET_SEARCH_RESULTS:
      return initSearchResultsState;
    default:
      return state;
  }
}

export default searchResultsReducer;
