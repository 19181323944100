import { IRoutes } from 'common/interfaces/routes';

import commonMessages from 'common/messages/messages';

export enum PeakSettingsRoutes {
  PEAK_SETTINGS = 'peak-settings',
  PEAK_SETTINGS_INTEGRATIONS = 'integrations',
  PEAK_SETTINGS_GENERAL = 'general',
}

export const routes: IRoutes[] = [
  {
    type: 'link',
    path: `/${PeakSettingsRoutes.PEAK_SETTINGS}/${PeakSettingsRoutes.PEAK_SETTINGS_INTEGRATIONS}`,
    message: commonMessages.integrations,
  },
  {
    type: 'link',
    path: `/${PeakSettingsRoutes.PEAK_SETTINGS}/${PeakSettingsRoutes.PEAK_SETTINGS_GENERAL}`,
    message: commonMessages.generalLabel,
  },
];

export const actionTypes = {
  // Corporation action types
  FETCH_PEAK_SETTINGS_SECTION_INFO: 'FETCH_PEAK_SETTINGS_SECTION_INFO',
  FETCH_PEAK_SETTINGS_SECTION_INFO_LOADING: 'FETCH_PEAK_SETTINGS_SECTION_INFO_LOADING',

  UPDATE_PEAK_SETTINGS_SECTION_LOADING: 'UPDATE_PEAK_SETTINGS_SECTION_LOADING',
  UPDATE_PEAK_SETTINGS_SECTION: 'UPDATE_PEAK_SETTINGS_SECTION',
  RESET_PEAK_SETTINGS_SECTION_UPDATE_ACTION_RESULT:
    'RESET_PEAK_SETTINGS_SECTION_UPDATE_ACTION_RESULT',

  // General
  PEAK_SETTINGS_PRIMARY_COLOR_LOADING: 'FETCH_PEAK_SETTINGS_PRIMARY_COLOR_LOADING',
  PEAK_SETTINGS_PRIMARY_COLOR_RESULT: 'FETCH_PEAK_SETTINGS_PRIMARY_COLOR_RESULT',
};
