import React from 'react';
import { FormattedMessage } from 'react-intl';

import { QuestionnaireModal } from 'common/modals';

import { ReasonType } from 'common/interfaces/dialog';

import messages from 'common/messages/messages';

interface IProps {
  isOpen: boolean;
  onClose: (e: React.BaseSyntheticEvent | unknown, reason: ReasonType) => void;
  onSubmit: () => void;
}

const QuestionnaireFirstPaymentModal = ({ isOpen, onClose, onSubmit }: IProps): JSX.Element => {
  return (
    <QuestionnaireModal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      body={<FormattedMessage {...messages.makeFirstPaymentBody} />}
      submitBtnTitle={<FormattedMessage {...messages.yesOption} />}
      cancelBtnTitle={<FormattedMessage {...messages.noOption} />}
    />
  );
};

export default QuestionnaireFirstPaymentModal;
