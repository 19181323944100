import React, { useEffect } from 'react';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DialogComponent, PhoneNumberInput, TextField } from 'common/components';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';
import * as yup from 'yup';

import messages from 'common/components/PersonProfile/messages';
import commonMessages from 'common/messages/messages';
import inputLabels from 'common/messages/inputLabels';
import {
  ICustomerEmergencyContactDto,
  TCustomerEmergencyContactDtoImt,
} from 'common/interfaces/additionalInfo';
import { getEmergencyContactValidationSchema } from 'common/validationSchemas/emergencyContactValidationSchema';
import { useSelector } from 'react-redux';
import { selectProfileRequiredFields } from 'common/state/settings/selectors';
import { RequiredProfileInfo } from 'modules/corporate-settings/constants/requiredFields';
import { selectIsSubmittedRecommendedFieldsModal } from 'common/state/modals/selectors';
import { useRecommendedFields } from 'common/hooks/useRecommendedFields';
import { showRecommendedFieldsModal } from 'common/state/modals/actions';
import { useAppDispatch } from 'store/hooks';

interface IEmergencyContactModalProps {
  emergencyContact: TCustomerEmergencyContactDtoImt;
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (values: ICustomerEmergencyContactDto) => void;
}

const FORM_ID = 'emergency-contact-form';

const EmergencyContactModal = (props: IEmergencyContactModalProps) => {
  const { isOpen, onClose, onSubmit, isLoading, emergencyContact } = props;

  const dispatch = useAppDispatch();

  const profileRequiredFields = useSelector(selectProfileRequiredFields);
  const isSubmitted = useSelector(selectIsSubmittedRecommendedFieldsModal);

  const { checkRecommendedFieldsAreNotEmpty, profileRecommendedFields } = useRecommendedFields();

  const renderIntlMessage = useRenderIntlMessage();

  const formMethods = useForm({
    defaultValues: {
      emergencyAddress1: '',
      emergencyAddress2: '',
      emergencyName: '',
      emergencyPhone: '',
    },
    resolver: yupResolver(
      yup.object().shape(getEmergencyContactValidationSchema(profileRequiredFields)),
    ),
    mode: 'onBlur',
  });

  const { errors, control, reset, handleSubmit, getValues } = formMethods;

  useEffect(() => {
    if (isSubmitted) {
      const values = getValues();

      dispatch(showRecommendedFieldsModal(false));
      onSubmit(values);
    }
  }, [dispatch, getValues, isSubmitted, onSubmit]);

  useEffect(() => {
    if (emergencyContact?.size) {
      const {
        emergencyAddress1,
        emergencyAddress2,
        emergencyName,
        emergencyPhone,
      } = emergencyContact.toJS();

      reset({
        emergencyAddress1,
        emergencyAddress2,
        emergencyName,
        emergencyPhone,
      });
    }
  }, [emergencyContact, reset]);

  const onSave = (values: ICustomerEmergencyContactDto) => {
    const hasEmptyRecommendedFields = checkRecommendedFieldsAreNotEmpty({
      emergencyContactDto: values,
    });

    if (hasEmptyRecommendedFields) {
      dispatch(showRecommendedFieldsModal(true));
    } else {
      onSubmit(values);
    }
  };

  return (
    <DialogComponent
      size="xs"
      formId={FORM_ID}
      title={<FormattedMessage {...messages.emergencyContactBlockTitle} />}
      submitBtnTitle={<FormattedMessage {...commonMessages.saveBtn} />}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSave)}
      disableFullScreenBehavior
      loading={isLoading}
    >
      <FormProvider {...formMethods}>
        <form id={FORM_ID} onSubmit={handleSubmit(onSave)}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="emergencyName"
                defaultValue=""
                render={({ value, onChange, onBlur }) => (
                  <TextField
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    label={renderIntlMessage(inputLabels.name)}
                    variant="outlined"
                    fullWidth
                    error={!!errors.emergencyName?.message}
                    helperText={renderIntlMessage(errors.emergencyName?.message)}
                    recommended={Boolean(
                      profileRecommendedFields?.get(RequiredProfileInfo.EmergencyContactInfo),
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="emergencyPhone"
                control={control}
                render={({ value, onChange, name, onBlur }) => (
                  <PhoneNumberInput
                    error={!!errors.emergencyPhone}
                    helperText={renderIntlMessage(errors.emergencyPhone?.message)}
                    onChange={onChange}
                    value={value}
                    name={name}
                    onBlur={onBlur}
                    recommended={Boolean(
                      profileRecommendedFields?.get(RequiredProfileInfo.EmergencyContactInfo),
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="emergencyAddress1"
                defaultValue=""
                render={({ value, onChange, onBlur }) => (
                  <TextField
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    label={renderIntlMessage(inputLabels.address1)}
                    variant="outlined"
                    fullWidth
                    error={!!errors.emergencyAddress1?.message}
                    helperText={renderIntlMessage(errors.emergencyAddress1?.message)}
                    recommended={Boolean(
                      profileRecommendedFields?.get(RequiredProfileInfo.EmergencyContactInfo),
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                as={TextField}
                control={control}
                name="emergencyAddress2"
                label={renderIntlMessage(inputLabels.address2)}
                variant="outlined"
                fullWidth
                defaultValue=""
                error={!!errors.emergencyAddress2?.message}
                helperText={renderIntlMessage(errors.emergencyAddress2?.message)}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </DialogComponent>
  );
};

export default EmergencyContactModal;
