import React from 'react';
import DatePicker from 'react-datepicker';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import moment from 'moment-timezone';
import { Box, TextField, makeStyles, createStyles, Theme } from '@material-ui/core';

import useTimezoneDate from 'common/hooks/useTimezoneDate';

import { getHoursAndMinutesFromDate } from 'common/utils/time';

import inputLabels from 'common/messages/inputLabels';
import cn from 'classnames';
import { IObject } from 'common/interfaces/common';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    timePicker: {
      width: '100%',
      '& .react-datepicker-wrapper': {
        width: '100%',
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
        transform: ({ small }: ITimePickerProps) => {
          return small
            ? `translate(${theme.spacing(1.75)}px, ${theme.spacing(1)}px) scale(1)`
            : undefined;
        },
        display: ({ value }: ITimePickerProps) => (value ? 'none' : 'block'),
      },
    },
    popper: {
      '& .react-datepicker__time-container': {
        minWidth: 104,
        width: 'auto',
        '& .react-datepicker__header': {
          display: 'none',
        },
        '& .react-datepicker__time': {
          minWidth: 104,

          '& .react-datepicker__time-box': {
            minWidth: 104,
            width: 'auto',
            overflow: 'auto',
            backgroundColor: theme.palette.background.paper,
            '& ul.react-datepicker__time-list': {
              paddingTop: theme.spacing(1),
              maxHeight: 300,
              minWidth: 104,
              listStyle: 'none',
              overflowY: 'scroll',

              '& .react-datepicker__time-list-item': {
                color: theme.palette.text.primary,
                padding: theme.spacing(1, 3),
                fontSize: 14,
                '&:hover': {
                  color: '#fff',
                  backgroundColor: theme.palette.primary.main,
                },
                '&--selected': {
                  backgroundColor: 'transparent',
                  color: theme.palette.primary.main,
                  fontWeight: 'normal',
                  '&:hover': {
                    color: '#fff',
                    backgroundColor: theme.palette.primary.main,
                  },
                },
              },
            },
          },
        },
      },
      zIndex: 1000,
      '& .react-datepicker': {
        border: 'none',
        boxShadow: theme.shadows[5],
        borderRadius: 0,
        background: 'red',
      },
    },
  }),
);

interface ITimePickerProps {
  value: string | Date;
  name: string;
  labelMessageDescriptor?: MessageDescriptor;
  error?: boolean;
  helperText?: string | JSX.Element | React.ReactNode;
  style?: IObject;
  small?: boolean;
  className?: string;
  disabled?: boolean;
  onChange: (value: string | null, sourceDate?: Date) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

const TimePicker = (props: ITimePickerProps): JSX.Element => {
  const {
    onChange,
    onBlur,
    onFocus,
    disabled,
    className,
    error,
    helperText,
    style,
    value,
    name,
    labelMessageDescriptor,
    small,
  } = props;

  const convertToDate = useTimezoneDate();

  const classes = useStyles(props);

  const selectedDate = moment(value).isValid()
    ? convertToDate(moment(value).toDate() as Date)
    : null;

  return (
    <Box className={cn(classes.timePicker, className)} style={style}>
      <DatePicker
        name={name}
        autoComplete="none"
        customInput={
          <TextField
            size={small ? 'small' : null}
            variant="outlined"
            helperText={helperText}
            label={<FormattedMessage {...labelMessageDescriptor} />}
            InputProps={{
              autoComplete: 'off',
            }}
            error={error}
            fullWidth
          />
        }
        disabled={disabled}
        popperProps={{
          positionFixed: true,
        }}
        popperPlacement="center"
        popperModifiers={{
          flip: {
            behavior: ['bottom'],
          },
          preventOverflow: {
            enabled: false,
          },
          hide: {
            enabled: false,
          },
        }}
        popperClassName={classes.popper}
        showPopperArrow={false}
        onChange={date => {
          if (moment(date).isValid()) {
            const { hours, minutes } = getHoursAndMinutesFromDate(date);

            const utcDate = (value ? moment(value) : moment())
              .set({ hours, minutes })
              .utc()
              .format();

            onChange(utcDate, date);
          } else {
            onChange(null);
          }
        }}
        onBlur={onBlur}
        onFocus={onFocus}
        selected={selectedDate}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption={false}
        dateFormat="h:mm aa"
      />
    </Box>
  );
};

TimePicker.defaultProps = {
  labelMessageDescriptor: inputLabels.time,
};

export default TimePicker;
