import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';

import { DialogComponent } from 'common/components/index';
import messages from 'common/components/EmployeeProfile/messages';
import commonMessages from 'common/messages/messages';
import PersonalSection from './PersonalSection';
import AccountSection from './AccountSection';
import ContactSection from './ContactSection';
import { MaritalStatuses } from 'common/constants/personConstants';
import * as yup from 'yup';
import { getRequiredMessage, requiredMessage } from 'common/constants/globalConstants';
import {
  exemptionsNumberSchema,
  idSchema,
  literalNameSchema,
  nameSchema,
} from 'components/EmployeeForm/schemas/schemas';
import {
  futureOrTodayDateSchema,
  pastDateSchema,
  pastOrTodayDateSchema,
} from 'common/validationSchemas/dateSchemas';
import { addressSchema } from 'common/validationSchemas/addressSchema';
import { IEmployeeDto, IEmployeeDtoImt } from 'common/interfaces/employee';
import { phoneSchema } from 'common/validationSchemas/phoneSchema';
import inputErrors from 'common/messages/inputErrors';

interface IEditProfileModalProps {
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  onSubmit: (data: IEmployeeDto) => void;
  profile: IEmployeeDtoImt;
}

const validationSchema = yup.object().shape({
  id: yup.string(),
  firstName: literalNameSchema.required(getRequiredMessage),
  lastName: literalNameSchema.required(getRequiredMessage),
  preferredName: nameSchema,
  gender: yup
    .string()
    .nullable()
    .required(getRequiredMessage),
  birthday: pastDateSchema,
  maritalStatus: yup
    .string()
    .nullable()
    .required(getRequiredMessage),
  exemptionsNumber: exemptionsNumberSchema,
  federalWithholding: exemptionsNumberSchema,

  address: addressSchema,

  hireDate: pastOrTodayDateSchema.required(getRequiredMessage),
  terminationDate: futureOrTodayDateSchema.required(getRequiredMessage),
  email: yup
    .string()
    .trim()
    .email(() => inputErrors.emailInvalidError)
    .required(requiredMessage),
  userId: idSchema,
  employeeId: idSchema,
  phone: phoneSchema.required(getRequiredMessage),
});

const initialValues = {
  image: null,

  firstName: '',
  lastName: '',
  preferredName: '',
  gender: '',
  birthday: null,
  maritalStatus: MaritalStatuses.SINGLE,
  exemptionsNumber: 0,
  federalWithholding: 0,

  address: {
    city: '',
    country: null,
    line1: '',
    line2: '',
    state: null,
    zipCode: '',
  },
  phone: '',

  hireDate: null,
  terminationDate: null,
  email: '',
  userId: '',
  employeeId: '',

  posDiscountPercentage: 0,
  barcode: '',
};

const EditProfileModal = (props: IEditProfileModalProps): JSX.Element => {
  const { isOpen, onSubmit, onClose, profile, isLoading } = props;

  const formMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });
  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    if (profile) {
      const convertedProfile = profile.toJS();
      const { deleted, phones, ...formData } = convertedProfile;

      reset({
        ...initialValues,
        ...formData,
      });
    }
  }, [profile, reset]);

  const formId = 'edit-profile-form';

  return (
    <DialogComponent
      isOpen={isOpen}
      size="md"
      title={<FormattedMessage {...messages.editMyProfile} />}
      submitBtnTitle={<FormattedMessage {...commonMessages.saveBtn} />}
      onClose={onClose}
      loading={isLoading}
      formId={formId}
    >
      <FormProvider {...formMethods}>
        <form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <PersonalSection />
            </Grid>

            <Grid item xs={12}>
              <ContactSection />
            </Grid>

            <Grid item xs={12}>
              <AccountSection />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </DialogComponent>
  );
};

export default EditProfileModal;
