import { IEmployeeJobPosition } from 'common/interfaces/employee';

export const getPreparedTimeclockPositionsData = (positions: IEmployeeJobPosition[]) => {
  const subArrays = [];
  const idMap = new Map();

  positions.forEach(item => {
    const id = item?.club?.id;

    if (idMap.has(id)) {
      idMap.get(id).push(item);
    } else {
      idMap.set(id, [item]);
    }
  });

  idMap.forEach(value => subArrays.push(value));

  const modifiedSubArrays = subArrays.map(el => ({
    clubName: el[0]?.club?.title,
    id: el[0]?.club?.id,
    items: el,
  }));

  return modifiedSubArrays;
};
