import { initReqStateImt, initialState } from 'common/constants/initialState';
import { actionTypes } from './constants';
import { fromJS } from 'immutable';

const defaultServiceSectionState = fromJS({
  membershipInfo: {
    inventories: [],
    services: [],
  },
  posInfo: {
    inventories: [],
    services: [],
  },
  familyMembershipServices: [],
});

const allergiesInitState = fromJS({
  servicesData: defaultServiceSectionState,
  servicesLoading: false,
  selectedServiceItemId: null,
  selectedServiceItemDetails: initialState,
  isDetailsShown: false,
  isRedeemShown: false,

  selectedInventoryId: null,
  selectedInventoryDetails: initialState,
  isInventoryDetailsShown: false,
});

const servicesReducer = (state = allergiesInitState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PERSON_SERVICES_SUCCESS:
      return state.set('servicesData', fromJS(action.payload));
    case actionTypes.FETCH_PERSON_SERVICES_LOADING:
      return state.set('servicesLoading', action.payload);
    case actionTypes.RESET_PERSON_SERVICES:
      return state.set('servicesData', defaultServiceSectionState);
    case actionTypes.FETCH_SERVICE_ITEM_DETAILS:
      return state.setIn(['selectedServiceItemDetails', 'result'], fromJS(action.payload));
    case actionTypes.FETCH_SERVICE_ITEM_DETAILS_LOADING:
      return state.setIn(['selectedServiceItemDetails', 'isLoading'], action.payload);
    case actionTypes.RESET_SERVICE_ITEM_DETAILS:
      return state.set('selectedServiceItemDetails', initReqStateImt);
    case actionTypes.SET_SELECTED_SERVICE_ITEM:
      return state.set('selectedServiceItemId', action.payload);
    case actionTypes.SET_DETAILS_MODAL_SHOWN:
      return state.set('isDetailsShown', action.payload);
    case actionTypes.SET_REDEEM_MODAL_SHOWN:
      return state.set('isRedeemShown', action.payload);
    case actionTypes.RESET_REDEEM_SERVICE_ITEM:
      return state.set('redeemAction', initReqStateImt);

    case actionTypes.SET_SELECTED_INVENTORY_ID:
      return state.set('selectedInventoryId', action.payload);
    case actionTypes.FETCH_INVENTORY_DETAILS_SUCCESS:
      return state.setIn(['selectedInventoryDetails', 'result'], fromJS(action.payload));
    case actionTypes.FETCH_INVENTORY_DETAILS_LOADING:
      return state.setIn(['selectedInventoryDetails', 'isLoading'], action.payload);
    case actionTypes.RESET_INVENTORY_DETAILS:
      return state.set('selectedInventoryDetails', initReqStateImt);
    case actionTypes.SET_INVENTORY_DETAILS_MODAL_SHOWN:
      return state.set('isInventoryDetailsShown', action.payload);

    default:
      return state;
  }
};

export default servicesReducer;
