import { IEditablePackageConfiguration } from 'common/interfaces/membership';

export const checkIsGracePeriodAllow = (
  packageItem: IEditablePackageConfiguration,
  isPaymentGraceAllow = false,
): boolean => {
  if (packageItem.paymentGrace?.allow || isPaymentGraceAllow) {
    return true;
  }

  let isAllow = isPaymentGraceAllow;

  for (const serviceItem of packageItem.services) {
    if (serviceItem.package !== null) {
      isAllow = checkIsGracePeriodAllow(serviceItem.package, isPaymentGraceAllow);
      break;
    }
  }

  return isAllow;
};
