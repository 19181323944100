import React, { useMemo, useState } from 'react';
import {
  TextField as MuiTextField,
  IconButton,
  InputAdornment,
  TextFieldProps,
  SvgIcon,
  Typography,
  FormHelperText,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Close as CloseIcon,
  Check as CheckIcon,
} from '@material-ui/icons';

import { PasswordError, PasswordErrors } from 'common/components/PasswordField/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ruleWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(0.5),
      fontSize: 'inherit',

      '&:not(:last-child)': {
        marginBottom: theme.spacing(0.5),
      },
    },
    ruleText: {
      marginLeft: theme.spacing(0.5),
      fontSize: 'inherit',
    },
    passwordHelperText: {
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);

type PasswordFieldProps = TextFieldProps & {
  showPasswordValidationHint?: boolean;
};

const PasswordField = ({
  autoComplete,
  name,
  onChange,
  value,
  error,
  helperText,
  showPasswordValidationHint,
  InputProps,
  ...otherProps
}: PasswordFieldProps): JSX.Element => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const classes = useStyles();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const rules = useMemo(() => {
    if (!value) return {};

    const trimmedValue = (value as string).replace(/\s/g, '');

    return {
      [PasswordError.PASSWORD_LENGTH_ERROR]: {
        isValid: trimmedValue.length > 8 && trimmedValue.length < 20,
      },
      [PasswordError.PASSWORD_DIGIT_ERROR]: {
        isValid: /^(?=.*[0-9])/.test(trimmedValue),
      },
      [PasswordError.PASSWORD_LOWERCASE_ERROR]: {
        isValid: /^(?=.*[a-z])/.test(trimmedValue),
      },
      [PasswordError.PASSWORD_UPPERCASE_ERROR]: {
        isValid: /^(?=.*[A-Z])/.test(trimmedValue),
      },
      [PasswordError.PASSWORD_SPECIAL_CHARACTER_ERROR]: {
        isValid: /^(?=.*[!@#&()_–:;'`,?/*~$^+=<>%.])/.test(trimmedValue),
      },
    };
  }, [value]);

  return (
    <>
      <MuiTextField
        {...otherProps}
        type={showPassword ? 'text' : 'password'}
        onChange={onChange}
        value={value}
        error={error}
        helperText={showPasswordValidationHint ? null : helperText}
        inputProps={{
          autoComplete,
        }}
        data-tip
        data-for={value}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          ...InputProps,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? (
                  <VisibilityIcon fontSize="small" />
                ) : (
                  <VisibilityOffIcon fontSize="small" />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      {error && showPasswordValidationHint && (
        <FormHelperText className={classes.passwordHelperText} component="div" error={error}>
          {PasswordErrors.values.map(errorItem => {
            const isRuleValid = rules[errorItem.value]?.isValid;

            return (
              <Typography
                color={isRuleValid ? 'primary' : 'error'}
                key={errorItem.key}
                className={classes.ruleWrapper}
              >
                <SvgIcon fontSize="inherit" component={isRuleValid ? CheckIcon : CloseIcon} />
                <Typography className={classes.ruleText} component="span">
                  {PasswordErrors.translate(errorItem.value)}
                </Typography>
              </Typography>
            );
          })}
        </FormHelperText>
      )}
    </>
  );
};

PasswordField.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  autoComplete: 'new-password',
  // eslint-disable-next-line react/default-props-match-prop-types
  name: 'password',
  showPasswordValidationHint: false,
};

export default PasswordField;
