import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Typography } from '@material-ui/core';

import PeakLogoImg from 'img/icons/peak-logo.png';
import { CustomTheme } from 'common/ui/interfaces';

interface ILoginSelectionProps {
  onSelect: (loginView: 'client' | 'member') => void;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  headTitle: {
    fontWeight: 900,
    fontSize: '2rem',
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: '1.5rem',
  },
  button: {
    color: theme.palette.error.main,
    borderColor: theme.palette.borderColor.main,
    backgroundColor: theme.palette.background.paper,
  },
}));

const LoginSelection = (props: ILoginSelectionProps) => {
  const { onSelect } = props;

  const classes = useStyles();

  return (
    <Box>
      <Box mb={4}>
        <img src={PeakLogoImg} alt="test" />
      </Box>
      <Typography className={classes.headTitle}>Peak Payment Solutions</Typography>
      <Typography color="secondary" className={classes.title}>
        {/* Todo: Need to add translations */}
        Billing, Software, Made Easy
      </Typography>
      <Box mt={4} display="flex" justifyContent="space-between">
        <Button variant="outlined" className={classes.button} onClick={() => onSelect('client')}>
          Client login
        </Button>
        <Button variant="outlined" className={classes.button} onClick={() => onSelect('member')}>
          Member login
        </Button>
      </Box>
    </Box>
  );
};

export default LoginSelection;
