// libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import { Controller, UseFormMethods, useWatch } from 'react-hook-form';
// components
import CheckBoxWithReset from 'common/components/CheckBoxWithReset/CheckBoxWithReset';
import { IBillingOption } from 'modules/services/interfaces/packages';
// messages
import messages from 'modules/services/messages/messages';
// utils
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
// constants
import { MinMaxNumberInput, Select } from 'common/components';
import { FrequencyTypes } from 'modules/services/constants/packages';

// custom interfaces

interface ISplitPaymentSectionProps {
  formMethods: UseFormMethods<IBillingOption>;
  defaultValues?: IBillingOption;
}

const SplitPaymentSection: React.FC<ISplitPaymentSectionProps> = ({
  formMethods,
  defaultValues,
}: ISplitPaymentSectionProps): JSX.Element => {
  const renderIntlMessage = useRenderIntlMessage();

  const { control, errors, trigger } = formMethods;

  // form
  const isAllowedSplitting = useWatch<boolean>({ control, name: 'paymentSplitting.allow' });

  // renders

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="paymentSplitting.allow"
            defaultValue={defaultValues?.paymentSplitting?.allow}
            render={({ value, onChange }) => (
              <CheckBoxWithReset
                label={
                  <Typography variant="subtitle2">
                    <FormattedMessage {...messages.allowSplitEachPaymentLabel} />
                  </Typography>
                }
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Grid>

        {isAllowedSplitting && (
          <>
            <Grid item xs={4}>
              <MinMaxNumberInput
                control={control}
                errors={errors}
                trigger={trigger}
                baseName="paymentSplitting.splitPaymentEditableNumber"
                label={<FormattedMessage {...messages.paymentsLabel} />}
                numberFormatProps={{ maxLength: 3 }}
                baseDefaultValue={defaultValues.paymentSplitting.splitPaymentEditableNumber}
              />
            </Grid>

            <Grid item xs={4}>
              <Controller
                name="paymentSplitting.splitPaymentSchedule"
                control={control}
                defaultValue={defaultValues.paymentSplitting.splitPaymentSchedule}
                render={({ name, value, onChange, onBlur }) => (
                  <Select
                    fullWidth
                    label={<FormattedMessage {...messages.paymentScheduleLabel} />}
                    variant="outlined"
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.paymentSplitting?.splitPaymentSchedule}
                    helperText={renderIntlMessage(
                      errors.paymentSplitting?.splitPaymentSchedule?.message,
                    )}
                  >
                    {FrequencyTypes.getSelectOptions()}
                  </Select>
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default SplitPaymentSection;
