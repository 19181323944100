import { List, fromJS } from 'immutable';
import { actionTypes } from './constants';
import {
  IBillingImt,
  IPackageInstanceSubscriptionImt,
  IPaymentAccount,
  IPaymentAccountImt,
} from 'common/components/PersonProfile/interfaces';
import { IPaymentMethodItemImt } from 'modules/pos-settings/interfaces/paymentMethods';
import { initReqStateImt } from 'common/constants/initialState';
import { IActionResultData } from 'common/constants/globalConstants';

const initialPaymentMethodsState = {
  cards: List<IPaymentAccount>(),
  deleteCardResult: null,
  deleteCardResultLoading: null,
  addCardResult: null,
  addCardResultLoading: false,
  list: List<IPaymentMethodItemImt>(),
  paymentMethodsModalDataLoading: false,
  subscriptions: List<IPackageInstanceSubscriptionImt>(),
  checkingSavings: List<IPaymentAccountImt>(),
  addCheckingSavingsLoading: false,
  addCheckingSavingsActionResult: null,
  fetchCheckingSavingsLoading: false,
  deleteCheckingSavingsLoading: false,
  deleteCheckingSavingsActionResult: null,
  updateSubscriptionsAction: initReqStateImt,
};

const billingActionsInitState = fromJS({
  pastDueBillings: List<IBillingImt>(),
  pastDueBillingsLoading: false,
  upcomingBillings: List<IBillingImt>(),
  upcomingBillingsLoading: false,
  paymentMethods: initialPaymentMethodsState,
});

const billingActionsReducer = (state = billingActionsInitState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PERSON_PAST_DUE_BILLINGS:
      return state.set('pastDueBillings', fromJS(action.payload));
    case actionTypes.FETCH_PERSON_PAST_DUE_BILLINGS_LOADING:
      return state.set('pastDueBillingsLoading', action.payload);

    case actionTypes.FETCH_PERSON_UPCOMING_BILLINGS:
      return state.set('upcomingBillings', fromJS(action.payload));
    case actionTypes.FETCH_PERSON_UPCOMING_BILLINGS_LOADING:
      return state.set('upcomingBillingsLoading', action.payload);
    case actionTypes.RESET_PERSON_UPCOMING_BILLINGS:
      return state.set('upcomingBillings', fromJS([]));

    case actionTypes.RESET_PAYMENT_METHODS:
      return state.set(['paymentMethods'], fromJS(initialPaymentMethodsState));
    case actionTypes.FETCH_PAYMENT_METHODS_MODAL_DATA_LOADING:
      return state.setIn(['paymentMethods', 'paymentMethodsModalDataLoading'], action.payload);

    case actionTypes.FETCH_STORED_CREDIT_CARDS:
      return state.setIn(['paymentMethods', 'cards'], fromJS(action.payload));
    case actionTypes.ADD_CREDIT_CARD_RESULT:
      return state.setIn(['paymentMethods', 'addCardResult'], fromJS(action.payload));
    case actionTypes.ADD_CREDIT_CARD_RESULT_LOADING:
      return state.setIn(['paymentMethods', 'addCardResultLoading'], action.payload);
    case actionTypes.DELETE_CREDIT_CARD_RESULT:
      const { payload }: { payload: IActionResultData } = action;
      return state
        .setIn(['paymentMethods', 'deleteCardResult'], payload.result)
        .updateIn(['paymentMethods', 'cards'], list =>
          list.filter(card => card.get('id') !== payload.data),
        );
    case actionTypes.RESET_DELETE_CREDIT_CARD_RESULT:
      return state.setIn(['paymentMethods', 'deleteCardResult'], null);
    case actionTypes.DELETE_CREDIT_CARD_RESULT_LOADING:
      return state.setIn(['paymentMethods', 'deleteCardResultLoading'], action.payload);

    case actionTypes.FETCH_PERSON_SUBSCRIPTIONS:
      return state.setIn(['paymentMethods', 'subscriptions'], fromJS(action.payload));
    case actionTypes.RESET_PERSON_SUBSCRIPTIONS:
      return state.setIn(['paymentMethods', 'subscriptions'], fromJS([]));
    case actionTypes.UPDATE_PERSON_SUBSCRIPTIONS_LOADING:
      return state.setIn(
        ['paymentMethods', 'updateSubscriptionsAction', 'isLoading'],
        action.payload,
      );
    case actionTypes.UPDATE_PERSON_SUBSCRIPTIONS:
      return state.setIn(['paymentMethods', 'updateSubscriptionsAction', 'result'], action.payload);
    case actionTypes.RESET_UPDATE_PERSON_SUBSCRIPTIONS_ACTION_RESULT:
      return state.setIn(['paymentMethods', 'updateSubscriptionsAction'], initReqStateImt);

    case actionTypes.FETCH_CHECKING_SAVINGS_DATA:
      return state.setIn(['paymentMethods', 'checkingSavings'], fromJS(action.payload));
    case actionTypes.FETCH_CHECKING_SAVINGS_DATA_LOADING:
      return state.set(['paymentMethods', 'fetchCheckingSavingsLoading'], action.payload);

    case actionTypes.ADD_CHECKING_SAVINGS_DATA:
      return state.updateIn(['paymentMethods', 'checkingSavings'], data => {
        const routingNumberExists = data
          .toJS()
          .some(el => el.routingNumber === action.payload.routingNumber);
        if (routingNumberExists) {
          return data;
        }
        return fromJS([...(data || []), action.payload]);
      });
    case actionTypes.ADD_CHECKING_SAVINGS_DATA_LOADING:
      return state.setIn(['paymentMethods', 'addCheckingSavingsLoading'], action.payload);
    case actionTypes.ADD_CHECKING_SAVINGS_DATA_ACTION_RESULT:
      return state.setIn(['paymentMethods', 'addCheckingSavingsActionResult'], action.payload);
    case actionTypes.DELETE_CHECKING_SAVINGS_ITEM:
      return state.updateIn(['paymentMethods', 'checkingSavings'], data => {
        const filteredData = data.filter(item => item.get('id') !== action.payload);
        return fromJS(filteredData);
      });
    case actionTypes.DELETE_CHECKING_SAVINGS_ITEM_LOADING:
      return state.setIn(['paymentMethods', 'deleteCheckingSavingsLoading'], action.payload);
    case actionTypes.DELETE_CHECKING_SAVINGS_ITEM_ACTION_RESULT:
      return state.setIn(['paymentMethods', 'deleteCheckingSavingsActionResult'], action.payload);

    case actionTypes.FETCH_PERSON_PAYMENT_METHODS:
      return state.setIn(['paymentMethods', 'list'], fromJS(action.payload));

    default:
      return state;
  }
};

export default billingActionsReducer;
