import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Accept } from 'react-dropzone';

// components
import employeesMessages from 'common/messages/employeesMessages';
import { FormFilesToServerUploader } from 'common/components';

const useStyles = makeStyles({
  dropzone: {
    width: '100%',
    height: '100%',
  },
});

interface IProps {
  acceptFileTypes?: Accept;
  name?: string;
  fieldId?: string;
}

function AttachmentsSection(props: IProps): JSX.Element {
  const { name } = props;

  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="button" color="textSecondary" gutterBottom>
          <FormattedMessage {...employeesMessages.attachedFiles} />
        </Typography>
      </Grid>
      <FormFilesToServerUploader
        className={classes.dropzone}
        hasVisibleScanDocumentBtn={false}
        hasVisibleTakePhotoBtn={false}
        fieldName={name}
      />
    </Grid>
  );
}

export default AttachmentsSection;
