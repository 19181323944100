import React, { useMemo, useEffect, useRef, useState } from 'react';
import SockJsClient from 'react-stomp';
import { debounce } from 'common/utils';
import tokenService from 'services/auth/TokenService';

const URL = `${process.env.REACT_APP_API_URL}/ws`;

interface IProps {
  topics: string[];
  // Main functions
  onMessage: (msg) => void;
  // Support functions
  onConnect?: () => void;
  onFailure?: (error) => void;
}

const RETRY_COUNT = 5;

const SocketListener = ({ topics, onMessage, onFailure, onConnect }: IProps): JSX.Element => {
  const [retryCounter, setRetryCounter] = useState<number>(0);
  const setRetryCounterRef = useRef(null);

  useEffect(() => {
    setRetryCounterRef.current = () => {
      setRetryCounter(prevState => prevState + 1);
    };
  });

  const debouncedSetRetryCounter = useMemo(() => {
    return debounce(() => {
      if (setRetryCounterRef) {
        setRetryCounterRef.current();
      }
    }, 1000);
  }, []);

  const onConnectHandler = () => {
    setRetryCounter(0);
    if (onConnect) {
      onConnect();
    }
  };

  const onFailureHandler = (error: any) => {
    if (retryCounter < RETRY_COUNT) {
      debouncedSetRetryCounter();
    }
    if (onFailure) {
      onFailure(error);
    }
  };

  const token = tokenService.getAccessToken();

  return (
    <>
      {token && retryCounter < RETRY_COUNT && (
        <SockJsClient
          autoReconnect={false}
          key={retryCounter}
          url={URL}
          topics={topics}
          onMessage={onMessage}
          onConnect={onConnectHandler}
          onConnectFailure={onFailureHandler}
          headers={{ Authorization: token }}
        />
      )}
    </>
  );
};

export default SocketListener;
