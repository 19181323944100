export const actionTypes = {
  // General Summary
  FETCH_SALES_POS_SUMMARY_LOADING: 'FETCH_SALES_POS_SUMMARY_LOADING',
  FETCH_SALES_POS_SUMMARY: 'FETCH_SALES_POS_SUMMARY',
  FETCH_SALES_POS_SUMMARY_ERROR: 'FETCH_SALES_POS_SUMMARY_ERROR',

  // Cash Summary
  FETCH_CASH_SUMMARY_LOADING: 'FETCH_CASH_SUMMARY_LOADING',
  FETCH_CASH_SUMMARY: 'FETCH_CASH_SUMMARY',
  FETCH_CASH_SUMMARY_ERROR: 'FETCH_CASH_SUMMARY_ERROR',

  // Payments Summary
  FETCH_PAYMENTS_SUMMARY_LOADING: 'FETCH_PAYMENTS_SUMMARY_LOADING',
  FETCH_PAYMENTS_SUMMARY: 'FETCH_PAYMENTS_SUMMARY',
  FETCH_PAYMENTS_SUMMARY_ERROR: 'FETCH_PAYMENTS_SUMMARY_ERROR',

  // Service Summary
  FETCH_SERVICE_SUMMARY_LOADING: 'FETCH_SERVICE_SUMMARY_LOADING',
  FETCH_SERVICE_SUMMARY: 'FETCH_SERVICE_SUMMARY',
  FETCH_SERVICE_SUMMARY_ERROR: 'FETCH_SERVICE_SUMMARY_ERROR',

  // Invoices Summary
  FETCH_INVOICES_SUMMARY_LOADING: 'FETCH_INVOICES_SUMMARY_LOADING',
  FETCH_INVOICES_SUMMARY: 'FETCH_INVOICES_SUMMARY',
  FETCH_INVOICES_SUMMARY_ERROR: 'FETCH_INVOICES_SUMMARY_ERROR',

  // Sales Category Summary
  FETCH_SALES_SUMMARY_LOADING: 'FETCH_SALES_SUMMARY_LOADING',
  FETCH_SALES_SUMMARY: 'FETCH_SALES_SUMMARY',
  FETCH_SALES_SUMMARY_ERROR: 'FETCH_SALES_SUMMARY_ERROR',

  // Registers Summary
  FETCH_REGISTERS_SUMMARY_LOADING: 'FETCH_REGISTERS_SUMMARY_LOADING',
  FETCH_REGISTERS_SUMMARY: 'FETCH_REGISTERS_SUMMARY',
  FETCH_REGISTERS_SUMMARY_ERROR: 'FETCH_REGISTERS_SUMMARY_ERROR',

  // Tax Summary
  FETCH_TAX_SUMMARY_LOADING: 'FETCH_TAX_SUMMARY_LOADING',
  FETCH_TAX_SUMMARY: 'FETCH_TAX_SUMMARY',
  FETCH_TAX_SUMMARY_ERROR: 'FETCH_TAX_SUMMARY_ERROR',

  // Revenue Code Summary
  FETCH_REVENUE_SUMMARY_LOADING: 'FETCH_REVENUE_SUMMARY_LOADING',
  FETCH_REVENUE_SUMMARY: 'FETCH_REVENUE_SUMMARY',
  FETCH_REVENUE_SUMMARY_ERROR: 'FETCH_REVENUE_SUMMARY_ERROR',

  // Export
  EXPORT_SALES_POS_REPORT: 'EXPORT_SALES_POS_REPORT',
  EXPORT_SALES_POS_REPORT_LOADING: 'EXPORT_SALES_POS_REPORT_LOADING',
  RESET_EXPORT_SALES_POS_REPORT: 'RESET_EXPORT_SALES_POS_REPORT',
};
