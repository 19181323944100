import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Grid, Typography } from '@material-ui/core';

import { Button } from 'common/components';

const CatchAll = (): JSX.Element => {
  const navigate = useNavigate();

  Sentry.captureMessage('Got 404 and loaded CatchAll.');

  return (
    <Grid
      style={{ paddingTop: '75px' }}
      spacing={3}
      container
      direction="column"
      justifyContent="flex-end"
      alignItems="center"
    >
      <Grid item style={{ paddingBottom: '0px' }}>
        <Typography variant="h2">404</Typography>
      </Grid>
      <Grid item>
        <Typography>Page Not Found!</Typography>
      </Grid>
      <Grid item>
        <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </Grid>
    </Grid>
  );
};

export default CatchAll;
