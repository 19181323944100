import React from 'react';
import { makeStyles, Theme, Tooltip } from '@material-ui/core';
import { Text } from 'recharts';

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    fill: theme.palette.secondary.main,
  },
}));

const CustomAxisTick = (props: any): JSX.Element => {
  const { x, y, payload, formatter } = props;

  const classes = useStyles();

  const content = formatter ? formatter(payload.value) : payload.value;

  return (
    <Tooltip title={content}>
      <Text
        x={x}
        y={y}
        width={65}
        textAnchor="end"
        verticalAnchor="middle"
        maxLines={6}
        className={classes.label}
      >
        {content}
      </Text>
    </Tooltip>
  );
};

export default CustomAxisTick;
