import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { actionTypes } from '../../constants/receipts';

const receiptsListInitialState = fromJS({
  list: [],
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
  isLoading: false,
});
function receiptsListReducer(state = receiptsListInitialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_RECEIPTS_LIST:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta));
    case actionTypes.FETCH_RECEIPTS_LIST_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_RECEIPTS_LIST:
      return receiptsListInitialState;
    default:
      return state;
  }
}

const receiptChangeStatusInitialState = fromJS({
  result: null,
  isLoading: false,
  error: null,
});
function receiptChangeStatusReducer(state = receiptChangeStatusInitialState, action) {
  switch (action.type) {
    case actionTypes.CHANGE_RECEIPT_STATUS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.CHANGE_RECEIPT_STATUS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_RECEIPT_CHANGE_STATUS:
      return receiptChangeStatusInitialState;
    default:
      return state;
  }
}

const receiptDetailedInitialState = fromJS({
  data: null,
  isLoading: false,
});
function receiptDetailedReducer(state = receiptDetailedInitialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_DETAILED_RECEIPT:
      return state.set('data', fromJS(action.payload));
    case actionTypes.FETCH_DETAILED_RECEIPT_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_DETAILED_RECEIPT:
      return receiptDetailedInitialState;
    default:
      return state;
  }
}

const receiptUpsertInitialState = fromJS({
  result: null,
  isLoading: false,
  error: null,
});
function receiptUpsertReducer(state = receiptUpsertInitialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_RECEIPT:
    case actionTypes.UPDATE_RECEIPT:
      return state.set('result', fromJS(action.payload));
    case actionTypes.CREATE_RECEIPT_LOADING:
    case actionTypes.UPDATE_RECEIPT_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_CREATE_RECEIPT:
    case actionTypes.RESET_UPDATE_RECEIPT:
      return receiptUpsertInitialState;
    default:
      return state;
  }
}

// Receipt Clubs

const receiptClubsInitialState = fromJS({
  simpleReceipt: null,

  list: [],
  isLoading: false,
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },

  unavailableClubIds: [],
});
function receiptClubsReducer(state = receiptClubsInitialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_SIMPLE_RECEIPT:
      return state.set('simpleReceipt', fromJS(action.payload));
    case actionTypes.RESET_SIMPLE_RECEIPT:
      return state.set('simpleReceipt', null);

    case actionTypes.FETCH_RECEIPT_CLUBS:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta))
        .set('unavailableClubIds', fromJS(action.payload.unavailableClubIds));
    case actionTypes.FETCH_RECEIPT_CLUBS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_RECEIPT_CLUBS:
      return receiptClubsInitialState;
    default:
      return state;
  }
}

// Add club availabilities

function receiptAddClubsReducer(state = receiptChangeStatusInitialState, action) {
  switch (action.type) {
    case actionTypes.ADD_RECEIPT_CLUBS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.ADD_RECEIPT_CLUBS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_ADD_RECEIPT_CLUBS:
      return receiptChangeStatusInitialState;
    default:
      return state;
  }
}

// Remove clubs action

function receiptDeleteClubsReducer(state = receiptChangeStatusInitialState, action) {
  switch (action.type) {
    case actionTypes.DELETE_RECEIPT_CLUBS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.DELETE_RECEIPT_CLUBS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_DELETE_RECEIPT_CLUBS:
      return receiptChangeStatusInitialState;
    default:
      return state;
  }
}

export default combineReducers({
  receiptsList: receiptsListReducer,
  receiptChangeStatusAction: receiptChangeStatusReducer,

  receiptDetailed: receiptDetailedReducer,
  receiptUpsertAction: receiptUpsertReducer,

  receiptClubAvailabilityList: receiptClubsReducer,
  receiptAddClubsAction: receiptAddClubsReducer,
  receiptDeleteClubsAction: receiptDeleteClubsReducer,
});
