import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Button, Collapse, Grid, makeStyles, Theme, Typography } from '@material-ui/core';

import useScrollIntoView from 'common/hooks/useScrollIntoView';
import { getQuantityMessage } from 'common/utils/displayServiceQuantity';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import {
  IncludedPackageBillingType,
  IncludedPaidPackageBillingTypes,
} from 'modules/services/constants/packages';

import { IBundleView } from 'modules/services/interfaces/packages';

import { FieldInfo } from 'common/components/index';
import BillingInfoView from '../BillingInfoView/BillingInfoView';
import FeesView from '../FeesView/FeesView';
import IncludedInventoryView from '../IncludedInventoryView/IncludedInventoryView';
import AvailabilitiesResultBlock from 'common/components/AvailabilityScheduleSelector/AvailabilitiesResultBlock';
import { ReactComponent as AngleDownIcon } from 'img/icons/angle-down.svg';
import { ReactComponent as AngleRightIcon } from 'img/icons/angle-right.svg';

import personProfileMessages from 'common/components/PersonProfile/messages';
import servicesMessages from 'modules/services/messages/messages';
import inputLabels from 'common/messages/inputLabels';

const useStyles = makeStyles((theme: Theme) => ({
  sectionBtn: {
    padding: 0,
    color: theme.palette.text.secondary,

    '& svg': {
      width: '0.75rem',
      height: '0.75rem',
      marginRight: theme.spacing(1),
    },
  },
  serviceWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  renewalStatus: {
    display: 'flex',
    alignItems: 'center',
  },
  statusIcon: {
    width: '1rem',
    height: '1rem',
    marginRight: theme.spacing(0.5),

    '&.primary': {
      color: theme.palette.primary.main,
    },
    '&.error': {
      color: theme.palette.error.main,
    },
  },
}));

interface IProps {
  bundle: IBundleView;
  onViewInventoryInfo?: (inventoryId: string, packageId: string) => void;
}

const IncludedServicesView: FC<IProps> = ({ bundle, onViewInventoryInfo }) => {
  const [showContent, setShowContent] = useState<boolean>(true);

  const { scrollRef } = useScrollIntoView(showContent);

  const renderIntlMessage = useRenderIntlMessage();

  const classes = useStyles();

  const { services } = bundle;

  if (!services?.length) {
    return null;
  }

  return (
    <Box>
      <Button
        onClick={() => setShowContent(!showContent)}
        startIcon={showContent ? <AngleDownIcon /> : <AngleRightIcon />}
        className={classes.sectionBtn}
      >
        <FormattedMessage {...personProfileMessages.includedServicesTitle} />
      </Button>

      <Collapse in={showContent} ref={scrollRef}>
        {services.map(servicePackage => (
          <Box className={classes.serviceWrapper}>
            <Typography component="p" variant="h5">
              {servicePackage.service?.title || servicePackage.package?.title}
            </Typography>

            <Grid item xs={12}>
              <FieldInfo
                label={<FormattedMessage {...servicesMessages.renewalsLabel} />}
                description={(servicePackage.package?.renewalNumber || 0).toString()}
              />
            </Grid>

            {!!servicePackage.service && (
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <FieldInfo
                    label={<FormattedMessage {...inputLabels.daysAndHoursForAccess} />}
                    description={
                      servicePackage.hasCustomDayTimeAvailability ? (
                        <AvailabilitiesResultBlock
                          availabilities={servicePackage.dayTimeAvailDtoList}
                        />
                      ) : (
                        <FormattedMessage
                          {...personProfileMessages.includedServicesDayAndHoursAccessSameAsParent}
                        />
                      )
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <FieldInfo
                    label={<FormattedMessage {...inputLabels.limitation} />}
                    description={`${servicePackage.limitedRedeemNumber ?? ''} ${renderIntlMessage(
                      getQuantityMessage(
                        servicePackage.service.type,
                        servicePackage.service.redeemType,
                        servicePackage.service.redeemDurationUnit,
                        servicePackage.limited,
                      ),
                    )}`}
                  />
                </Grid>
              </Grid>
            )}

            {!!servicePackage.package && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <FieldInfo
                      label={
                        <FormattedMessage
                          {...personProfileMessages.includedServicesPaymentTypeTitle}
                        />
                      }
                      description={IncludedPaidPackageBillingTypes.translate(
                        servicePackage.billingType,
                      )}
                    />
                  </Grid>

                  {!!servicePackage.billingAmount && (
                    <Grid item xs={4}>
                      <FieldInfo
                        label={
                          <FormattedMessage
                            {...personProfileMessages.includedServicesPaymentAmountTitle}
                          />
                        }
                        description={`$ ${servicePackage.billingAmount}`}
                      />
                    </Grid>
                  )}
                </Grid>

                {servicePackage.billingType === IncludedPackageBillingType.OwnBillingSettings && (
                  <Box pl={3}>
                    <BillingInfoView billingOption={servicePackage.package.billingOption} />
                  </Box>
                )}

                {servicePackage.billingType === IncludedPackageBillingType.OwnBillingSettings && (
                  <Box pl={3}>
                    <BillingInfoView
                      billingOption={servicePackage.package.outOfTermBillingOption}
                      isOutOfTerm
                    />
                  </Box>
                )}

                {!!servicePackage.package?.services?.length && (
                  <Box pl={3}>
                    <IncludedServicesView
                      onViewInventoryInfo={onViewInventoryInfo}
                      bundle={servicePackage.package}
                    />
                  </Box>
                )}

                {!!servicePackage.package?.inventories?.length && (
                  <Box pl={3}>
                    <IncludedInventoryView
                      bundle={servicePackage.package}
                      onViewInventoryInfo={onViewInventoryInfo}
                    />
                  </Box>
                )}

                {!!servicePackage.package?.fees?.length && (
                  <Box pl={3}>
                    <FeesView bundle={servicePackage.package} />
                  </Box>
                )}
              </>
            )}
          </Box>
        ))}
      </Collapse>
    </Box>
  );
};

export default IncludedServicesView;
