import { useCallback, useMemo, useLayoutEffect, useState } from 'react';
import { debounce } from '../utils';

export default function useWindowWidth(withDebounce = false) {
  const [width, setWidth] = useState<number>(0);
  const updateWidth = useCallback(() => setWidth(window.innerWidth), []);

  const debouncedUpdateWidth = useMemo(() => {
    return debounce(() => setWidth(window.innerWidth), 100);
  }, []);

  const updateFunc = withDebounce ? debouncedUpdateWidth : updateWidth;

  useLayoutEffect(() => {
    updateFunc();
    window.addEventListener('resize', updateFunc);

    return () => window.removeEventListener('resize', updateFunc);
  }, [updateFunc]);

  return width;
}
