import GrapesJS from 'grapesjs';

import videoIcon from 'img/editor-icons/block-video.svg';
import { GrapesCustomTypes, GrapesCustomCommands } from '../constants';
import { IObject } from 'common/interfaces/common';

const embedIframeToolbar = [
  {
    attributes: { class: 'fa fa-arrows' },
    command: 'tlb-move',
  },
  {
    attributes: { class: 'fa fa-edit' },
    command: GrapesCustomCommands.EmbedIframe,
  },
  {
    attributes: { class: 'fa fa-trash-o' },
    command: 'tlb-delete',
  },
];

(GrapesJS as IObject).plugins.add(GrapesCustomTypes.EmbeddedIframe, function(editor) {
  function addCommands() {
    editor.Commands.add(GrapesCustomCommands.EmbedIframe, {
      stop(commandEditor, sender, { model, embeddingCode }) {
        if (model) {
          model.components().reset();
          model.components().add(embeddingCode);

          model.setAttributes({
            'data-status': 'set',
          });
        }
      },
    });
  }

  function addComponents() {
    const defaultType = editor.DomComponents.getType('default');
    const { initToolbar } = defaultType.model.prototype;

    editor.DomComponents.addType(GrapesCustomTypes.EmbeddedIframe, {
      // extends: 'video',
      model: {
        defaults: {
          ...defaultType.model,
          tagName: 'div',
          highlightable: true,
          droppable: false,
          draggable: true,
          attributes: {
            'data-gjs-type': GrapesCustomTypes.EmbeddedIframe,
            'data-status': 'empty',
          },
          components: null,
        },
        isComponent: el => {
          if (el.hasAttribute('data-gjs-type') === GrapesCustomTypes.EmbeddedIframe) {
            return { type: GrapesCustomTypes.EmbeddedIframe };
          }
          return false;
        },
        initToolbar(args) {
          initToolbar.apply(this, args);

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (this as any).set('toolbar', embedIframeToolbar);
        },
        init() {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const attributes = (this as any).getAttributes();
          if (attributes['data-status'] === 'empty') {
            editor.runCommand(GrapesCustomCommands.EmbedIframe, { model: this });
          }
        },
      },
    });
  }

  function addBlocks() {
    editor.BlockManager.add(GrapesCustomTypes.EmbeddedIframe, {
      label: `
        <img class="gjs-block-icon" src="${videoIcon}" alt="Embed iframe block"/>
        <div class="gjs-block-title">Embed Iframe</div>
      `,
      content: {
        type: GrapesCustomTypes.EmbeddedIframe,
      },
    });
  }

  addCommands();
  addComponents();
  addBlocks();
});

export default GrapesCustomTypes.EmbeddedIframe;
