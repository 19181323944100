const checkinsPath = ['frontDesk', 'checkins'];

export const selectRecentCheckInsList = state => state.getIn([...checkinsPath, 'list']);

export const selectRecentCheckInsLoading = state => state.getIn([...checkinsPath, 'listLoading']);

export const selectCurrentCheckIn = state => state.getIn([...checkinsPath, 'currentCheckIn']);

export const selectSelectedCheckIn = state => state.getIn([...checkinsPath, 'selectedCheckIn']);

export const selectCheckInActionLoading = state =>
  state.getIn([...checkinsPath, 'checkInAction', 'isLoading']);

export const selectCheckInActionResult = state =>
  state.getIn([...checkinsPath, 'checkInAction', 'result']);
