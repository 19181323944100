import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

// messages
import inputLabels from 'common/messages/inputLabels';
// interfaces
import { TSenderAvailabilityImt } from 'modules/booking/interfaces';
// state
import { selectSenderAvailability } from 'modules/booking/state/senderAvailability/selectors';
// constants
import { SenderAvailabilityTypeList } from 'modules/booking/constants/senderAvailability';
import NotifyControlFields from 'common/components/ReactHookForm/NotifyControlFields/NotifyControlFields';

const useStyles = makeStyles((theme: Theme) => ({
  receiptViaTitle: {
    color: theme.palette.secondary.dark,
  },
}));

const CheckOutResponsePaymentNotifyControl = (): JSX.Element => {
  const senderAvailability: TSenderAvailabilityImt = useSelector(
    selectSenderAvailability(SenderAvailabilityTypeList.POS),
  );

  const classes = useStyles();

  return (
    <NotifyControlFields
      label={<FormattedMessage {...inputLabels.sendReceipt} />}
      labelClassName={classes.receiptViaTitle}
      emailFieldName="sendByEmail"
      smsFieldName="sendByText"
      notificationName="sendNotification"
      senderAvailability={senderAvailability}
      fullContent
      showPrintField
    />
  );
};

export default CheckOutResponsePaymentNotifyControl;
