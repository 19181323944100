import { fromJS } from 'immutable';
import { ActionPrimaryInfoTypes } from 'common/state/newPerson/primaryInfo/actions';
import { actionTypes } from 'common/state/newPerson/additionalInfo/constants';

const initialState = fromJS({
  result: null,
  updateAdditionalInfoLoading: false,
  updateAdditionalInfoResult: '',
  additionalInfoLoading: false,
});

const additionalInfoReducer = (state = initialState, { type, payload }: ActionPrimaryInfoTypes) => {
  switch (type) {
    case actionTypes.UPDATE_ADDITIONAL_INFO_LOADING:
      return state.set('updateAdditionalInfoLoading', payload);
    case actionTypes.UPDATE_ADDITIONAL_INFO_RESULT:
      return state.set('updateAdditionalInfoResult', payload);
    case actionTypes.FETCH_ADDITIONAL_INFO_LOADING:
      return state.set('additionalInfoLoading', payload);
    case actionTypes.FETCH_ADDITIONAL_INFO:
      return state.set('result', fromJS(payload));
    default:
      return state;
  }
};

export default additionalInfoReducer;
