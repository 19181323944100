import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';

import { Button, DialogComponent } from 'common/components';

import messages from 'modules/booking/messages';
import commonMessages from 'common/messages/messages';

const useStyles = makeStyles((theme: Theme) => ({
  optionButton: {
    margin: theme.spacing(0, 2),
  },
  copyText: {
    textAlign: 'center',
    fontSize: '1rem',
    fontWeight: 600,
  },
}));

interface IProps {
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onDuplicate: (isDuplicateParticipants: boolean) => void;
}

const DuplicateParticipantsModal = ({
  isOpen,
  onClose,
  onDuplicate,
  isLoading,
}: IProps): JSX.Element => {
  const classes = useStyles();

  const renderModalFooter = () => (
    <Box display="flex" justifyContent="flex-end" p={2}>
      <Button color="primary" onClick={onClose}>
        <FormattedMessage {...commonMessages.cancelBtn} />
      </Button>

      <Button
        variant="outlined"
        color="primary"
        className={classes.optionButton}
        onClick={() => onDuplicate(true)}
      >
        <FormattedMessage {...commonMessages.yesOption} />
      </Button>

      <Button variant="contained" color="primary" onClick={() => onDuplicate(false)}>
        <FormattedMessage {...commonMessages.noOption} />
      </Button>
    </Box>
  );

  return (
    <DialogComponent
      loading={isLoading}
      isOpen={isOpen}
      onClose={onClose}
      title={<FormattedMessage {...messages.duplicateParticipantsModalTitle} />}
      hasCustomFooter
      footer={renderModalFooter()}
    >
      <Typography color="secondary" className={classes.copyText}>
        <FormattedMessage {...messages.copyParticipantsListMsg} />
      </Typography>
    </DialogComponent>
  );
};

export default DuplicateParticipantsModal;
