import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Autocomplete as MuiAutocomplete, AutocompleteProps } from '@material-ui/lab';
import { Box, TextField, Typography } from '@material-ui/core';

import Services from 'services/network';

import { PeakModules } from 'common/constants/peakModules';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import { IFamilyMemberService } from 'common/components/PersonProfile/interfaces';
import { TFamilyMemberFormItem } from 'common/components/Steps/FamilyMembersStep/FamilyMembersStep';

import messages from 'common/components/PersonProfile/messages';
import inputLabels from 'common/messages/inputLabels';

interface IProps
  extends Omit<AutocompleteProps<any, any, any, any>, 'options' | 'onChange' | 'renderInput'> {
  memberId: string;
  module: PeakModules;
  onChange: (options: IFamilyMemberService[]) => void;
  familyMembers: TFamilyMemberFormItem[];
  isServicesSection?: boolean;
}

const MemberServicesAutocomplete = ({
  memberId,
  module,
  onChange,
  familyMembers,
  isServicesSection,
  ...autocompleteProps
}: IProps): JSX.Element => {
  const [isServiceOptionsLoading, setIsServiceOptionsLoading] = useState<boolean>(false);
  const [serviceOptions, setServiceOptions] = useState<IFamilyMemberService[]>([]);

  const renderIntlMessage = useRenderIntlMessage();

  const getMemberServices = useCallback(async () => {
    setIsServiceOptionsLoading(true);

    let services;

    if (isServicesSection) {
      switch (module) {
        case PeakModules.FrontDesk:
          services = await Services.FrontDeskRedeem.getMemberServices(memberId);
          break;
        default:
          services = await Services.MembersRedeem.getMemberServices(memberId);
      }
    } else {
      switch (module) {
        case PeakModules.FrontDesk:
          services = await Services.FrontDesk.getMemberServices(memberId);
          break;
        case PeakModules.Crm:
          services = await Services.Leads.getMemberServices(memberId);
          break;
        default:
          services = await Services.PTLeads.getMemberServices(memberId);
      }
    }

    setServiceOptions(services);
    setIsServiceOptionsLoading(false);
  }, [isServicesSection, memberId, module]);

  const getAvailableSpotsNumber = (option: IFamilyMemberService): number => {
    const usedServicesCount = familyMembers.reduce(
      (usedServices, familyMember) =>
        usedServices +
        Number(
          !!familyMember.services.find(
            serviceItem => serviceItem.packageServiceInstanceId === option.packageServiceInstanceId,
          ),
        ),
      0,
    );

    if (!usedServicesCount) {
      return option.leftSpotNumber;
    }
    return option.leftSpotNumber ? option.limitedSpotNumber - usedServicesCount : 0;
  };

  return (
    <MuiAutocomplete
      {...autocompleteProps}
      onChange={(_, option) => onChange(option)}
      multiple
      fullWidth
      onOpen={getMemberServices}
      getOptionLabel={option => option.title}
      getOptionSelected={(option, selected) =>
        option?.packageServiceInstanceId === selected?.packageServiceInstanceId
      }
      renderOption={option => {
        return (
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            justifyContent="space-between"
            color="inherit"
          >
            {option.limited ? (
              <>
                <Typography color="inherit">{option.title}</Typography>
                <Typography color="inherit">
                  {renderIntlMessage(messages.amountLeftShortLabel, {
                    available: getAvailableSpotsNumber(option),
                    total: option.limitedSpotNumber,
                  })}
                </Typography>
              </>
            ) : (
              `${option.title}`
            )}
          </Box>
        );
      }}
      getOptionDisabled={option => option.limited && !getAvailableSpotsNumber(option)}
      loading={isServiceOptionsLoading}
      options={serviceOptions}
      onClose={() => setServiceOptions([])}
      renderInput={params => (
        <TextField
          {...params}
          label={<FormattedMessage {...inputLabels.linkToService} />}
          variant="outlined"
          fullWidth
        />
      )}
    />
  );
};

export default MemberServicesAutocomplete;
