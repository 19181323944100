// libraries
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Theme } from '@material-ui/core';
import cx from 'classnames';

// interfaces
import { ReactivateMembershipData } from 'common/components/PersonProfile/interfaces';

// components
import PaymentStep from 'common/components/PersonProfile/components/PaymentStep/PaymentStep';
import { DialogComponent, Stepper } from 'common/components';
import PackageSelectionStep from './PackageSelectionStep/PackageSelectionStep';
import PastDuesStep from '../CancelMembershipModal/PastDuesStep/PastDuesStep';
import Footer from 'common/components/PersonProfile/modals/ReactivateMembershipModal/Footer';
// actions
import * as memberActions from 'common/components/PersonProfile/state/actions';
import { resolvePastDueAlert } from 'common/components/PersonProfile/state/actions';
import { updatePersonStatus } from 'modules/front-desk/state/checkins/actions';
// constants
import { ReactivateMembershipSteps, ReactivateMembershipStepsLabels } from './constants';
import { StepContext } from 'common/createContext/stepContext';
import { PeakModules } from 'common/constants/peakModules';
import { CustomerStatus } from 'modules/front-desk/constants/common/constants';

import { useAppDispatch } from 'store/hooks';

// messages
import messages from '../../messages';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { CartUnitTypes, IInvoiceDetailsDto, InvoiceStatus } from 'common/interfaces/invoices';
import { addInvoiceUnit, resetUpdateInvoiceWithSync } from 'common/state/invoice/actions';
import { EXECUTE_MEMBERSHIP_ACTIONS } from 'common/constants/delayedActionsKeys';
import { selectSelectedRegisterId } from 'modules/pos-kiosk/state/register/selectors';
import StorageServices from 'services/storage';
import { fetchServices } from 'common/components/PersonProfile/state/services/actions';
import { AlertCondition } from 'common/interfaces/alerts';
import { selectCancelResult, selectUpdatedInvoiceResult } from 'common/state/invoice/selectors';
import { selectCurrentUserId } from 'modules/authentication/state/selectors';
import { PackageSelectionType } from '../../constants';

const useStyles = makeStyles((theme: Theme) => ({
  stepper: {
    padding: theme.spacing(2, 0, 3, 0),

    '&.noPadding': {
      padding: theme.spacing(0, 2),
    },
  },
  customPaper: {
    '& .MuiDialog-container>.MuiDialog-paperScrollPaper': {
      overflowY: 'hidden',
    },
  },
}));

const { addDelayedAction } = StorageServices.delayedActions;

const steps: ReactivateMembershipSteps[] = [
  ReactivateMembershipSteps.PackagesSelection,
  ReactivateMembershipSteps.PastDues,
  ReactivateMembershipSteps.Payment,
];

const { Provider: StepProvider } = StepContext;

interface IProps {
  isOpen: boolean;
  personId: string;
  onSubmit: () => void;
  onCancel: () => void;
  module: PeakModuleForNewPersonType;
  handlePOSPanelChange: (isOpen: boolean) => void;
  handleNewMemberPanelChange: (isOpen: boolean) => void;
  handleChangePackagePlanChange: (isOpen: boolean) => void;
  isOpenPOSPanel: boolean;
  disablePosBtn: boolean;
  isMembershipCard?: boolean;
}

const ReactivateMembershipModal = ({
  isOpen,
  onCancel,
  personId,
  module,
  disablePosBtn,
  isOpenPOSPanel,
  handlePOSPanelChange,
  handleNewMemberPanelChange,
  handleChangePackagePlanChange,
  isMembershipCard,
}: IProps): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const selectedRegisterId: string = useSelector(selectSelectedRegisterId);
  const updatedInvoiceResult = useSelector(selectUpdatedInvoiceResult);
  const cancelInvoiceResult = useSelector(selectCancelResult());
  const currentUserId = useSelector(selectCurrentUserId);

  const [currentStep, setCurrentStep] = useState(ReactivateMembershipSteps.PackagesSelection);
  const [reactivateData, setReactivateData] = useState({});

  const classes = useStyles();

  const isPaymentStep = currentStep === ReactivateMembershipSteps.Payment;

  useEffect(() => {
    if (updatedInvoiceResult) {
      dispatch(memberActions.fetchProfileInfoView(personId, module));
      dispatch(fetchServices(personId, module));

      const status = updatedInvoiceResult.get('status');
      if (status === InvoiceStatus.PAID) {
        dispatch(resetUpdateInvoiceWithSync());

        if (module === PeakModules.FrontDesk) {
          // update status in check ins list after reactivation
          dispatch(updatePersonStatus(CustomerStatus.ActiveMember, personId));
        }
      }
    }
  }, [dispatch, module, onCancel, personId, updatedInvoiceResult]);

  const currentStepIndex: number = steps.indexOf(currentStep);
  const nextStep: ReactivateMembershipSteps =
    currentStepIndex + 1 < steps.length ? steps[currentStepIndex + 1] : null;
  const prevStep: ReactivateMembershipSteps =
    currentStepIndex - 1 < 0 ? null : steps[currentStepIndex - 1];

  useEffect(() => {
    if (cancelInvoiceResult?.get('success') && cancelInvoiceResult.get('shouldGoBack')) {
      setCurrentStep(prevStep);
    }
  }, [cancelInvoiceResult, setCurrentStep, prevStep]);

  // handlers

  const handleOnSubmitStep = async (data: ReactivateMembershipData): Promise<void> => {
    if (nextStep === ReactivateMembershipSteps.Payment) {
      const handleCreateInvoice = (invoicesList: IInvoiceDetailsDto[], registerId: string) => {
        const openedInvoiceForSelectedCustomer = invoicesList?.find(
          (invoiceItem: IInvoiceDetailsDto) => {
            return (
              invoiceItem.status === InvoiceStatus.OPEN &&
              invoiceItem.customer?.id === personId &&
              invoiceItem.salesperson?.id === currentUserId
            );
          },
        );

        if (openedInvoiceForSelectedCustomer) {
          dispatch(
            addInvoiceUnit(
              openedInvoiceForSelectedCustomer.id,
              {
                reactivateBundleDto: {
                  ...reactivateData,
                  ...data,
                },
                memberId: personId,
                registerId: selectedRegisterId,
              },
              isMembershipCard
                ? CartUnitTypes.REACTIVATE_MEMBERSHIP
                : CartUnitTypes.REACTIVATE_NON_MEMBERSHIP,
              module,
              true,
              true,
            ),
          );
        } else {
          dispatch(
            addInvoiceUnit(
              null,
              {
                createNewInvoice: true,
                reactivateBundleDto: {
                  ...reactivateData,
                  ...data,
                },
                memberId: personId,
                registerId,
              },
              isMembershipCard
                ? CartUnitTypes.REACTIVATE_MEMBERSHIP
                : CartUnitTypes.REACTIVATE_NON_MEMBERSHIP,
              module,
              true,
              true,
            ),
          );
        }
      };

      addDelayedAction(EXECUTE_MEMBERSHIP_ACTIONS, handleCreateInvoice);

      if (disablePosBtn || isOpenPOSPanel) {
        if (isOpenPOSPanel && handlePOSPanelChange) {
          handlePOSPanelChange(false);
        }

        if (handleNewMemberPanelChange) {
          handleNewMemberPanelChange(false);
        }

        if (handleChangePackagePlanChange) {
          handleChangePackagePlanChange(false);
        }
      }

      setCurrentStep(nextStep);
    } else {
      setCurrentStep(nextStep);
      setReactivateData(prevState => ({ ...prevState, ...data }));
    }
  };

  const handleClosePaymentStep = useCallback(() => {
    setCurrentStep(prevStep);
  }, [prevStep]);

  const onPaymentStepSubmit = useCallback(() => {
    dispatch(resolvePastDueAlert(AlertCondition.PastDuePayments, personId));
    dispatch(memberActions.fetchProfileInfoView(personId, module));

    onCancel();
  }, [dispatch, module, onCancel, personId]);

  const handleOnCancelStep = (): void => {
    if (prevStep === null) {
      onCancel();
    } else {
      setCurrentStep(prevStep);
    }
  };

  const renderFooter = (onBack, onNext, disabled) => {
    return (
      <Footer
        currentStep={currentStep}
        onBack={onBack}
        onNext={onNext}
        goBack={onCancel}
        disabled={disabled}
      />
    );
  };

  return (
    <DialogComponent
      className={classes.customPaper}
      isOpen={isOpen}
      title={
        <FormattedMessage
          {...(isMembershipCard
            ? messages.reactivateMembershipModalTitle
            : messages.reactivateBundleTitle)}
        />
      }
      cancelBtn={false}
      submitBtn={false}
      hasCustomFooter
      size="lg"
      disableBodyPadding={isPaymentStep}
      onClose={onCancel}
    >
      <Stepper
        className={cx(classes.stepper, { noPadding: isPaymentStep })}
        currentStep={currentStepIndex}
        steps={steps.map(step => ({ title: ReactivateMembershipStepsLabels[step] }))}
      />

      <StepProvider
        value={{
          onBack: handleOnCancelStep,
          onNext: handleOnSubmitStep,
          renderFooter,
        }}
      >
        {currentStep === ReactivateMembershipSteps.PackagesSelection && (
          <PackageSelectionStep
            personId={personId}
            module={module}
            packageSelectionType={
              isMembershipCard
                ? PackageSelectionType.REACTIVATE_MEMBERSHIP
                : PackageSelectionType.REACTIVATE_SERVICE
            }
          />
        )}

        {currentStep === ReactivateMembershipSteps.PastDues && (
          <PastDuesStep personId={personId} module={module} />
        )}

        {currentStep === ReactivateMembershipSteps.Payment && (
          <PaymentStep
            personId={personId}
            disableInvoiceCreation
            disableInvoiceSelection
            module={module}
            onNext={onPaymentStepSubmit}
            onCancel={handleClosePaymentStep}
          />
        )}
      </StepProvider>
    </DialogComponent>
  );
};

export default ReactivateMembershipModal;
