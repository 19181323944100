export const actionTypes = {
  // Came from indicator
  FETCH_CAME_FROM_INDICATOR: 'DICTIONARIES/FETCH_CAME_FROM_INDICATOR',
  FETCH_CAME_FROM_INDICATOR_IS_LOADING: 'DICTIONARIES/FETCH_CAME_FROM_INDICATOR_IS_LOADING',
  FETCH_CAME_FROM_INDICATOR_ERROR: 'DICTIONARIES/FETCH_CAME_FROM_INDICATOR_ERROR',
  RESET_CAME_FROM_INDICATOR: 'DICTIONARIES/RESET_CAME_FROM_INDICATOR',
  SET_CAME_FROM_INDICATOR_ACTION_RESULT: 'DICTIONARIES/SET_CAME_FROM_INDICATOR_ACTION_RESULT',
  UPDATE_STATUS_CAME_FROM_INDICATOR: 'DICTIONARIES/UPDATE_STATUS_CAME_FROM_INDICATOR',
  CREATE_OR_UPDATE_CAME_FROM_INDICATOR_IS_LOADING:
    'DICTIONARIES/CREATE_OR_UPDATE_CAME_FROM_INDICATOR_IS_LOADING',

  // Came from indicators
  FETCH_CAME_FROM_INDICATORS: 'DICTIONARIES/FETCH_CAME_FROM_INDICATORS',
  FETCH_CAME_FROM_INDICATORS_IS_LOADING: 'DICTIONARIES/FETCH_CAME_FROM_INDICATORS_IS_LOADING',
  FETCH_CAME_FROM_INDICATORS_ERROR: 'DICTIONARIES/FETCH_CAME_FROM_INDICATORS_ERROR',
  RESET_CAME_FROM_INDICATORS: 'DICTIONARIES/RESET_CAME_FROM_INDICATORS',
};
