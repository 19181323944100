import WebHidApiService from './WebHidApiService';
import { IDeviseIds, Subscriber } from 'common/interfaces/webHid';

enum CashDrawerEvents {
  ConnectCashDrawer = 'connectCashDrawer',
  DisconnectCashDrawer = 'disconnectCashDrawer',
  OpenCashDrawer = 'openCashDrawer',
  OpenCashDrawerError = 'openCashDrawerError',
}

export default class CashDrawerService extends WebHidApiService {
  private connectedDevices: HIDDevice[] = [];

  private openedDevices: HIDDevice[] = [];

  // TODO: filterDevices, reportId and openDrawerCommand - Required data for operation with the device
  private filterDevices: IDeviseIds[] = [{ vendorId: 0, productId: 0 }];

  private reportId = 0;

  // example new Uint8Array([0x00, 0x01, 0x01])
  private openDrawerCommand: Uint8Array;

  constructor() {
    super();
    (async () => {
      try {
        const { openedDevices, connectedDevices } = await this.lookingForDevices(
          this.filterDevices,
        );

        this.connectedDevices = connectedDevices;

        this.openedDevices = openedDevices;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error({ err });
      }
    })();
  }

  get getOpenedDevices() {
    return [...this.openedDevices];
  }

  get getIsAllScannersAreOpen() {
    return this.openedDevices.length === this.filterDevices.length;
  }

  get getConnectedDevices() {
    return [...this.connectedDevices];
  }

  protected handleConnectCashDrawer({ device }: HIDConnectionEvent) {
    const connectedDevice = this.addDevice(this.connectedDevices, this.filterDevices, device);

    if (connectedDevice) {
      this.triggerEvent(CashDrawerEvents.ConnectCashDrawer, connectedDevice);
    }
  }

  protected handleDisconnectCashDrawer({ device }: HIDConnectionEvent): void | HIDDevice[] {
    let removedDevice = this.removeDevice(this.connectedDevices, device);

    if (!removedDevice) {
      removedDevice = this.removeDevice(this.openedDevices, device);
    }

    if (removedDevice) {
      this.triggerEvent(CashDrawerEvents.DisconnectCashDrawer, removedDevice);
    }
  }

  public subscribeOnConnectDevice = (callback: Subscriber<HIDDevice>) => {
    this.addSubscriber(CashDrawerEvents.ConnectCashDrawer, callback);
  };

  public unsubscribeOnConnectDevice = (callback: Subscriber<HIDDevice>) => {
    this.removeSubscriber(CashDrawerEvents.ConnectCashDrawer, callback);
  };

  public subscribeOnDisconnectDevice = (callback: Subscriber<HIDDevice>) => {
    this.addSubscriber(CashDrawerEvents.DisconnectCashDrawer, callback);
  };

  public unsubscribeOnDisconnectDevice = (callback: Subscriber<HIDDevice>) => {
    this.removeSubscriber(CashDrawerEvents.DisconnectCashDrawer, callback);
  };

  public subscribeOnOpenCashDrawer = (callback: Subscriber<HIDDevice>) => {
    this.addSubscriber(CashDrawerEvents.OpenCashDrawer, callback);
  };

  public unsubscribeOnOpenCashDrawer = (callback: Subscriber<HIDDevice>) => {
    this.removeSubscriber(CashDrawerEvents.OpenCashDrawer, callback);
  };

  public subscribeOnOpenCashDrawerError = (callback: Subscriber) => {
    this.addSubscriber(CashDrawerEvents.OpenCashDrawerError, callback);
  };

  public unsubscribeOnOpenOpenCashDrawerError = (callback: Subscriber) => {
    this.removeSubscriber(CashDrawerEvents.OpenCashDrawerError, callback);
  };

  public openCashDrawer = () => {
    this.sendReportToDevice(this.openedDevices[0], this.reportId, this.openDrawerCommand);
  };

  // replaced device from connected to opened
  private replaceState = (device: HIDDevice) => {
    this.removeDevice(this.connectedDevices, device);
    this.addDevice(this.openedDevices, this.filterDevices, device);
  };

  public init = async () => {
    try {
      const device = await this.requestPairScanner(this.filterDevices);

      if (device) {
        const openedDevice = await this.openHidDevice(null, device);

        if (openedDevice) {
          this.replaceState(openedDevice);
          this.triggerEvent(CashDrawerEvents.OpenCashDrawer, openedDevice);
        }
      }
    } catch (e) {
      this.triggerEvent(CashDrawerEvents.OpenCashDrawerError, e);
    }
  };
}
