import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from 'modules/corporations/messages';
import clubMessages from 'modules/clubs/messages';
import commonMessages from 'common/messages/messages';
import corporateSettingsLabels from 'modules/corporate-settings/messages';
import tableHeadersLabels from 'common/messages/tableHeaders';
import {
  FeeReturnRatesTypes,
  ClubFeeSingleRatesTypes,
  ClubProcessingFeeRatesTypes,
  ClubFeeMonthlyRatesTypes,
  ClubFeeTransactionRatesCategorys,
} from 'modules/clubs/constants/constants';

export const actionTypes = {
  // Corporation action types
  FETCH_CORPORATIONS_LIST_LOADING: 'FETCH_CORPORATIONS_LIST_LOADING',
  FETCH_CORPORATIONS_LIST_SUCCESS: 'FETCH_CORPORATIONS_LIST_SUCCESS',
  RESET_CORPORATIONS_LIST: 'RESET_CORPORATIONS_LIST',

  CREATE_CORPORATION_LOADING: 'CREATE_CORPORATION_LOADING',
  CREATE_CORPORATION_SUCCESS: 'CREATE_CORPORATION_SUCCESS',
  RESET_CREATED_CORPORATION: 'RESET_CREATED_CORPORATION',

  FETCH_CORPORATION_GENERAL_INFO_VIEW: 'FETCH_CORPORATION_GENERAL_INFO_VIEW',
  FETCH_CORPORATION_GENERAL_INFO_VIEW_LOADING: 'FETCH_CORPORATION_GENERAL_INFO_VIEW_LOADING',
  RESET_CORPORATION_GENERAL_INFO_VIEW: 'RESET_CORPORATION_GENERAL_INFO_VIEW',

  FETCH_CORPORATION_GENERAL_INFO: 'FETCH_CORPORATION_GENERAL_INFO',
  FETCH_CORPORATION_GENERAL_INFO_LOADING: 'FETCH_CORPORATION_GENERAL_INFO_LOADING',
  RESET_CORPORATION_GENERAL_INFO: 'RESET_CORPORATION_GENERAL_INFO',

  FETCH_CORPORATION_STEP_INFO: 'FETCH_CORPORATION_STEP_INFO',
  FETCH_CORPORATION_STEP_INFO_LOADING: 'FETCH_CORPORATION_STEP_INFO_LOADING',
  RESET_CORPORATION_STEP_INFO: 'RESET_CORPORATION_STEP_INFO',

  FETCH_CORPORATION_BANK_INFO_VIEW: 'FETCH_CORPORATION_BANK_INFO_VIEW',
  FETCH_CORPORATION_BANK_INFO_VIEW_LOADING: 'FETCH_CORPORATION_BANK_INFO_VIEW_LOADING',
  RESET_CORPORATION_BANK_INFO_VIEW: 'RESET_CORPORATION_BANK_INFO_VIEW',

  UPDATE_CORPORATION_STEP_INFO: 'UPDATE_CORPORATION_STEP_INFO',
  UPDATE_CORPORATION_STEP_INFO_LOADING: 'UPDATE_CORPORATION_STEP_INFO_LOADING',

  UPDATE_REQUIRED_PROFILE_INFO: 'UPDATE_REQUIRED_PROFILE_INFO',

  UPDATE_CORPORATION_GENERAL_INFO_ACTION_RESULT: 'UPDATE_CORPORATION_GENERAL_INFO_ACTION_RESULT',

  UPDATE_CORPORATION_GENERAL_STEP_INFO: 'UPDATE_CORPORATION_GENERAL_STEP_INFO',
  UPDATE_CORPORATION_BANK_INFO_STEP: 'UPDATE_CORPORATION_BANK_INFO_STEP',
  UPDATE_CORPORATION_INTEGRATIONS_STEP: 'UPDATE_CORPORATION_INTEGRATIONS_STEP',

  RESET_UPDATED_CORPORATION_STEP_INFO_RESULT: 'RESET_UPDATED_CORPORATION_STEP_INFO_RESULT',

  CHANGE_CORPORATION_STATUS: 'CHANGE_CORPORATION_STATUS',
  CHANGE_CORPORATION_STATUS_LOADING: 'CHANGE_CORPORATION_STATUS_LOADING',
  RESET_CORPORATION_CHANGE_STATUS_RESULT: 'RESET_CORPORATION_CHANGE_STATUS_RESULT',

  FETCH_CORPORATIONS_PAY_PERIOD_LOADING: 'FETCH_CORPORATIONS_PAY_PERIOD_LOADING',
  FETCH_CORPORATIONS_PAY_PERIOD_SUCCESS: 'FETCH_CORPORATIONS_PAY_PERIOD_SUCCESS',
  RESET_CORPORATIONS_PAY_PERIOD: 'RESET_CORPORATIONS_PAY_PERIOD',

  FETCH_ALL_PAY_PERIOD_SETTINGS_LOADING: 'FETCH_ALL_PAY_PERIOD_SETTINGS_LOADING',
  FETCH_ALL_PAY_PERIOD_SETTINGS_SUCCESS: 'FETCH_ALL_PAY_PERIOD_SETTINGS_SUCCESS',
  RESET_ALL_PAY_PERIOD_SETTINGS: 'RESET_ALL_PAY_PERIOD_SETTINGS',

  CREATE_PAY_PERIOD_SETTING_LOADING: 'CREATE_PAY_PERIOD_SETTING_LOADING',
  CREATE_PAY_PERIOD_SETTING_SUCCESS: 'CREATE_PAY_PERIOD_SETTING_SUCCESS',
  RESET_CREATE_PAY_PERIOD_SETTING: 'RESET_CREATE_PAY_PERIOD_SETTING',

  GET_PAY_PERIOD_SETTING_INFO_LOADING: 'GET_PAY_PERIOD_SETTING_INFO_LOADING',
  GET_PAY_PERIOD_SETTING_INFO_SUCCESS: 'GET_PAY_PERIOD_SETTING_INFO_SUCCESS',
  RESET_GET_PAY_PERIOD_SETTING_INFO: 'RESET_GET_PAY_PERIOD_SETTING_INFO',

  UPDATE_PAY_PERIOD_SETTING_INFO_LOADING: 'UPDATE_PAY_PERIOD_SETTING_INFO_LOADING',
  UPDATE_PAY_PERIOD_SETTING_INFO_SUCCESS: 'UPDATE_PAY_PERIOD_SETTING_INFO_SUCCESS',
  RESET_UPDATE_PAY_PERIOD_SETTING_INFO: 'RESET_UPDATE_PAY_PERIOD_SETTING_INFO',

  GET_PAY_PERIOD_BY_ID_LOADING: 'GET_PAY_PERIOD_BY_ID_LOADING',
  GET_PAY_PERIOD_BY_ID_SUCCESS: 'GET_PAY_PERIOD_BY_ID_SUCCESS',
  RESET_GET_PAY_PERIOD_BY_ID: 'RESET_GET_PAY_PERIOD_BY_ID',

  UPDATE_PAY_PERIOD_BY_ID_LOADING: 'UPDATE_PAY_PERIOD_BY_ID_LOADING',
  UPDATE_PAY_PERIOD_BY_ID_SUCCESS: 'UPDATE_PAY_PERIOD_BY_ID_SUCCESS',
  RESET_UPDATE_PAY_PERIOD_BY_ID: 'RESET_GET_PAY_PERIOD_BY_ID',

  CHANGE_PAY_PERIOD_STATUS_LOADING: 'CHANGE_PAY_PERIOD_STATUS_LOADING',
  CHANGE_PAY_PERIOD_STATUS_SUCCESS: 'CHANGE_PAY_PERIOD_STATUS_SUCCESS',
  RESET_CHANGE_PAY_PERIOD_STATUS: 'RESET_CHANGE_PAY_PERIOD_STATUS',

  DEACTIVATE_PAY_PERIODS_LOADING: 'DEACTIVATE_PAY_PERIODS_LOADING',
  DEACTIVATE_PAY_PERIODS_SUCCESS: 'DEACTIVATE_PAY_PERIODS_SUCCESS',

  DELETE_PAY_PERIODS_LOADING: 'DELETE_PAY_PERIODS_LOADING',
  DELETE_PAY_PERIODS_SUCCESS: 'DELETE_PAY_PERIODS_SUCCESS',

  TRANSFER_OWNERSHIP_LOADING: 'TRANSFER_OWNERSHIP_LOADING',
  TRANSFER_OWNERSHIP: 'TRANSFER_OWNERSHIP',
  RESET_TRANSFER_OWNERSHIP_ACTION_RESULT: 'RESET_TRANSFER_OWNERSHIP_ACTION_RESULT',

  FETCH_CORPORATION_FEE_RATES_INFO: 'FETCH_CORPORATION_FEE_RATES_INFO',
  FETCH_CORPORATION_FEE_RATES_LOADING: 'FETCH_CORPORATION_FEE_RATES_LOADING',
  UPDATE_CORPORATION_FEE_RATES_STEP: 'UPDATE_CORPORATION_FEE_RATES_STEP',
  FETCH_PEAK_LEVEL_FEE_RATES: 'FETCH_PEAK_LEVEL_FEE_RATES',
};

export enum CorporationsPaths {
  CORPORATIONS = 'corporations',
  NEW_CORPORATION = 'new',

  CORPORATION_EDIT_BANK_INFO = ':corporationId/bank-info',
  CORPORATION_EDIT_BILLING = ':corporationId/billing',
  CORPORATION_EDIT_FEE_RATES = ':corporationId/fee-rates',
  CORPORATION_EDIT_PROCESSOR_CONFIG = ':corporationId/processor-config',
  CORPORATION_EDIT_TAXPAYER = ':corporationId/taxpayer',
  CORPORATION_EDIT_INTEGRATIONS = ':corporationId/integrations',
  CORPORATION_EDIT_OTHER = ':corporationId/other',
}

export enum CorporationSteps {
  GENERAL_INFORMATION = 'GENERAL_INFORMATION',
  BANK_INFO = 'BANK_INFO',
  BILLING = 'BILLING',
  FEE_RATES = 'FEE_RATES',
  PROCESSOR_CONFIGURATION = 'PROCESSOR_CONFIGURATION',
  TAXPAYER = 'TAXPAYER',
  INTEGRATIONS = 'INTEGRATIONS',
  OTHER = 'OTHER',
  PAY_PERIOD = 'PAY_PERIOD',
  REQUIRED_FIELDS = 'REQUIRED_FIELDS',
}

export const NewCorporationStepsLabels = {
  [CorporationSteps.GENERAL_INFORMATION]: (
    <FormattedMessage {...messages.generalInformationLabel} />
  ),
  [CorporationSteps.BANK_INFO]: <FormattedMessage {...messages.bankInfoLabel} />,
  [CorporationSteps.BILLING]: <FormattedMessage {...commonMessages.billingLabel} />,
  [CorporationSteps.FEE_RATES]: <FormattedMessage {...messages.feeRatesLabel} />,
  [CorporationSteps.PROCESSOR_CONFIGURATION]: (
    <FormattedMessage {...messages.processorConfigInfoLabel} />
  ),
  [CorporationSteps.TAXPAYER]: <FormattedMessage {...messages.taxpayerLabel} />,
  [CorporationSteps.INTEGRATIONS]: <FormattedMessage {...commonMessages.integrations} />,
  [CorporationSteps.OTHER]: <FormattedMessage {...clubMessages.otherLabel} />,
};

export const clubProcessingFeeRatesLabels = {
  [ClubProcessingFeeRatesTypes.DISCOUNT]: (
    <FormattedMessage {...corporateSettingsLabels.discountLabel} />
  ),
  [ClubProcessingFeeRatesTypes.VISA]: <FormattedMessage {...corporateSettingsLabels.visaLabel} />,
  [ClubProcessingFeeRatesTypes.MASTERCARD]: (
    <FormattedMessage {...corporateSettingsLabels.mastercardLabel} />
  ),
  [ClubProcessingFeeRatesTypes.DISCOVER]: (
    <FormattedMessage {...corporateSettingsLabels.discoverLabel} />
  ),
  [ClubProcessingFeeRatesTypes.AMEX]: <FormattedMessage {...corporateSettingsLabels.amexLabel} />,
  [ClubProcessingFeeRatesTypes.ECHECK]: (
    <FormattedMessage {...corporateSettingsLabels.echeckLabel} />
  ),
  [ClubProcessingFeeRatesTypes.CASH]: <FormattedMessage {...corporateSettingsLabels.cashLabel} />,
  [ClubProcessingFeeRatesTypes.CHECK]: <FormattedMessage {...corporateSettingsLabels.checkLabel} />,
  [ClubProcessingFeeRatesTypes.MONEY_ORDER]: (
    <FormattedMessage {...corporateSettingsLabels.moneyOrderLabel} />
  ),
  [ClubProcessingFeeRatesTypes.COLLECTIONS_TARGET]: (
    <FormattedMessage {...corporateSettingsLabels.collectionsTargetLabel} />
  ),
  [ClubProcessingFeeRatesTypes.COLLECTIONS_ADDITION]: (
    <FormattedMessage {...corporateSettingsLabels.collectionsAdditionLabel} />
  ),
};

export enum FeeRatesType {
  PROCESSING = 'PROCESSING',
  SINGLE = 'SINGLE',
  LATE = 'LATE',
  RETURN = 'RETURN',
  MONTHLY = 'MONTHLY',
  TRANSACTION = 'TRANSACTION',
}

export const feeRatesTypelist: FeeRatesType[] = [
  FeeRatesType.PROCESSING,
  FeeRatesType.SINGLE,
  FeeRatesType.LATE,
  FeeRatesType.RETURN,
  FeeRatesType.MONTHLY,
  FeeRatesType.TRANSACTION,
];

export const clubFeeSingleRatesLabels = {
  [ClubFeeSingleRatesTypes.CHARGEBACK_REPRESENTMENT]: (
    <FormattedMessage {...corporateSettingsLabels.chargebackRepresentmentLabel} />
  ),
  [ClubFeeSingleRatesTypes.REFUND_ISSUE]: (
    <FormattedMessage {...corporateSettingsLabels.refundIssueLabel} />
  ),
  [ClubFeeSingleRatesTypes.CONVENIENCE_FEE]: (
    <FormattedMessage {...corporateSettingsLabels.convenienceFeeLabel} />
  ),
  [ClubFeeSingleRatesTypes.WAIVE_FEE]: (
    <FormattedMessage {...corporateSettingsLabels.waiveFeeLabel} />
  ),
};

export const clubFeeReturnRatesLabels = {
  [FeeReturnRatesTypes.CREDIT_CARD]: <FormattedMessage {...commonMessages.creditCardLabel} />,
  [FeeReturnRatesTypes.ECHECK]: <FormattedMessage {...corporateSettingsLabels.echeckLabel} />,
};

export const clubFeeMonthlyRatesLabels = {
  [ClubFeeMonthlyRatesTypes.SOFTWARE]: (
    <FormattedMessage {...corporateSettingsLabels.softwareLabel} />
  ),
  [ClubFeeMonthlyRatesTypes.RESUBMITS]: (
    <FormattedMessage {...corporateSettingsLabels.resubmitsLabel} />
  ),
  [ClubFeeMonthlyRatesTypes.CLEAR_OWN_CREDIT_CARDS]: (
    <FormattedMessage {...corporateSettingsLabels.clearOwnCreditCardsLabel} />
  ),
  [ClubFeeMonthlyRatesTypes.DATA_STORAGE]: (
    <FormattedMessage {...corporateSettingsLabels.dataStorageLabel} />
  ),
  [ClubFeeMonthlyRatesTypes.PIF_STORAGE]: (
    <FormattedMessage {...corporateSettingsLabels.pifStorageLabel} />
  ),
  [ClubFeeMonthlyRatesTypes.FUNDING]: (
    <FormattedMessage {...corporateSettingsLabels.fundingLabel} />
  ),
  [ClubFeeMonthlyRatesTypes.SPLITS]: <FormattedMessage {...corporateSettingsLabels.splitsLabel} />,
  [ClubFeeMonthlyRatesTypes.TURNSTILE_DOOR_OPEN]: (
    <FormattedMessage {...corporateSettingsLabels.turnstileDoorOpenLabel} />
  ),
  [ClubFeeMonthlyRatesTypes.PCI_COMPLIANCE]: (
    <FormattedMessage {...corporateSettingsLabels.pciComplianceLabel} />
  ),
  [ClubFeeMonthlyRatesTypes.PCI_TOKENIZATION]: (
    <FormattedMessage {...corporateSettingsLabels.pciTokenizationLabel} />
  ),
};

export const clubTransactionFeeRatesLabels = {
  [ClubFeeTransactionRatesCategorys.REGULAR]: <FormattedMessage {...tableHeadersLabels.regular} />,
  [ClubFeeTransactionRatesCategorys.POS]: <FormattedMessage {...commonMessages.pos} />,
  [ClubFeeTransactionRatesCategorys.TOKENIZER]: (
    <FormattedMessage {...corporateSettingsLabels.tokenizerLabel} />
  ),
  [ClubFeeTransactionRatesCategorys.CARD_ACCOUNT_UPDATER]: (
    <FormattedMessage {...corporateSettingsLabels.cardAccountUpdaterLabel} />
  ),
};
