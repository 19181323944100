import React, { useCallback, useEffect, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import dompurify from 'dompurify';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  printContent: {
    display: 'none',
    '@media print': {
      display: 'inline-block',
    },
  },
});

interface IPrintComponentProps {
  html: string;
  getRef: (ref: React.ReactInstance) => void;
}

const PrintComponent = ({ html, getRef }: IPrintComponentProps): JSX.Element => {
  const sanitizer = dompurify.sanitize;
  const classes = useStyles();

  const refProps = { ref: getRef };

  return (
    <Box
      {...refProps}
      className={classes.printContent}
      dangerouslySetInnerHTML={{
        __html: sanitizer(html),
      }}
    />
  );
};

interface IReactToPrintReceipt {
  printingHtml: string;
  onBeforePrint?: () => void;
  onAfterPrint?: () => void;
}

const ReactToPrintHTML = ({
  printingHtml,
  onBeforePrint,
  onAfterPrint,
}: IReactToPrintReceipt): JSX.Element => {
  const reactToPrintRef = useRef<ReactToPrint>(null);
  const printComponentRef = useRef<React.ReactInstance>(null);

  const reactToPrintContent = useCallback(() => {
    return printComponentRef.current;
  }, []);

  useEffect(() => {
    if (printingHtml && reactToPrintRef.current) {
      reactToPrintRef.current.handlePrint();
    }
  });

  return (
    <>
      <ReactToPrint
        ref={reactToPrintRef}
        onBeforePrint={onBeforePrint}
        onAfterPrint={onAfterPrint}
        content={reactToPrintContent}
        documentTitle="AwesomeFileName"
        removeAfterPrint
      />
      <PrintComponent
        html={printingHtml}
        getRef={ref => {
          printComponentRef.current = ref;
        }}
      />
    </>
  );
};

export default ReactToPrintHTML;
