import React, { useContext, Dispatch, SetStateAction } from 'react';
import { List as ImmutableList } from 'immutable';
import { FormattedMessage } from 'react-intl';
import { Grid, Box, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

// messages
import messages from 'modules/crm/messages/leads';
// components
import { ScrollBox } from 'common/components/index';
import WaiverItem from 'common/components/Steps/SignWaiversStep/WaiverItem/WaiverItem';
// common
import { StepContext } from 'common/createContext/stepContext';
import {
  IContactInfo,
  IPersonDocumentItemDto,
  IPersonDocumentToSignDto,
} from 'common/interfaces/contract';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1, 2),
    flex: 1,
    height: '100%',
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  subTitle: {
    marginBottom: theme.spacing(1.5),
    display: 'inline-block',
  },
}));

interface ISignWaiversStepProps {
  waivers: ImmutableList<IPersonDocumentToSignDto>;
  fetchApplySignature: (file: File, documentId: string) => void;
  contactInfo: Partial<IContactInfo>;
  isSignedWaiverData: { [key: string]: boolean };
  setIsSignedWaiverData: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  onPrintWaiver: (waiverId: string) => void;
  isLoading: boolean;
}

const SignWaiversStep = ({
  waivers,
  fetchApplySignature,
  contactInfo,
  isSignedWaiverData,
  setIsSignedWaiverData,
  onPrintWaiver,
  isLoading,
}: ISignWaiversStepProps): JSX.Element => {
  const classes = useStyles();

  const waiversList: IPersonDocumentItemDto[] = waivers.toJS();

  const { onBack, renderFooter } = useContext(StepContext);

  const onHandleBack = () => {
    onBack({ waivers: waiversList });
  };

  return (
    <>
      <ScrollBox hasShadowsOnScroll>
        <Box className={classes.root}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {waiversList && waiversList.length ? (
                waiversList.map(w => (
                  <WaiverItem
                    key={w.documentId}
                    fetchApplySignature={fetchApplySignature}
                    onPrintWaiver={onPrintWaiver}
                    waiver={w}
                    contactInfo={contactInfo}
                    isSignedWaiver={Boolean(isSignedWaiverData[w.documentId])}
                    setIsSignedWaiverData={setIsSignedWaiverData}
                  />
                ))
              ) : (
                <Typography color="textSecondary">
                  <FormattedMessage {...messages.newLeadSignWaiversStepNoWaivers} />
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </ScrollBox>

      {!!renderFooter && renderFooter(onHandleBack, null, isLoading)}
    </>
  );
};

export default SignWaiversStep;
