import { createAction } from '@reduxjs/toolkit';
import Services from 'services/network';

import { actionTypes } from 'common/state/alerts/constants';

import { GeneralThunkAction } from 'common/state/interfaces';
import { IAlertConfigurations, IAlertSettings, IPreference } from 'common/interfaces/alerts';

import { enqueueErrorNotification } from 'common/state/notifications/actions';

const fetchAlertsListLoadingAction = createAction<boolean>(actionTypes.FETCH_ALERTS_LIST_LOADING);

const fetchAlertsListSuccessAction = createAction<IAlertConfigurations>(
  actionTypes.FETCH_ALERTS_LIST_SUCCESS,
);

export const fetchAlertsList = (): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchAlertsListLoadingAction(true));

    try {
      const alerts = await Services.Alerts.getAlertsList();

      dispatch(fetchAlertsListSuccessAction(alerts));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchAlertsListLoadingAction(false));
    }
  };
};

const updateAlertLoadingAction = createAction<boolean>(actionTypes.UPDATE_ALERT_LOADING);
const updateAlertSuccessAction = createAction<{ data: IAlertSettings; alertId: string }>(
  actionTypes.UPDATE_ALERT_SUCCESS,
);
const updateAlertError = createAction<{ data: IAlertSettings; alertId: string }>(
  actionTypes.UPDATE_ALERT_ERROR,
);

export const updateAlertSettings = (
  alertId: string,
  optimisticData: IAlertSettings,
  currentData: IAlertSettings,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updateAlertLoadingAction(true));
    dispatch(updateAlertSuccessAction({ data: optimisticData, alertId }));

    try {
      const updatedAlert = await Services.Alerts.updateAlertSettings(alertId, optimisticData);

      if (updatedAlert.id !== alertId) {
        dispatch(updateAlertSuccessAction({ data: updatedAlert, alertId }));
      }
    } catch (error) {
      dispatch(updateAlertError({ data: currentData, alertId }));
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(updateAlertLoadingAction(false));
    }
  };
};

const updatePreferenceLoadingAction = createAction<boolean>(actionTypes.UPDATE_PREFERENCE_LOADING);
const updatePreferenceSuccessAction = createAction<Partial<IPreference>>(
  actionTypes.UPDATE_PREFERENCE_SUCCESS,
);
const updatePreferenceError = createAction<Partial<IPreference>>(
  actionTypes.UPDATE_PREFERENCE_ERROR,
);

export const updatePreference = (
  optimisticData: Partial<IPreference>,
  currentData: Partial<IPreference>,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updatePreferenceLoadingAction(true));
    dispatch(updatePreferenceSuccessAction(optimisticData));

    try {
      await Services.Alerts.updatePreference(optimisticData);
    } catch (error) {
      dispatch(updatePreferenceError(currentData));
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(updatePreferenceLoadingAction(false));
    }
  };
};

const createAlertLoadingAction = createAction<boolean>(actionTypes.UPDATE_ALERT_LOADING);
const createAlertSuccessAction = createAction<IAlertSettings>(actionTypes.CREATE_ALERT_SUCCESS);

export const createAlert = (requestData: IAlertSettings): GeneralThunkAction => {
  return async dispatch => {
    dispatch(createAlertLoadingAction(true));

    try {
      const alert = await Services.Alerts.createAlert(requestData);
      dispatch(createAlertSuccessAction(alert));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(createAlertLoadingAction(false));
    }
  };
};

const deleteAlertLoadingAction = createAction<boolean>(actionTypes.DELETE_ALERT_LOADING);
const deleteAlertSuccessAction = createAction<string>(actionTypes.DELETE_ALERT_SUCCESS);

export const deleteAlert = (alertId: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(deleteAlertLoadingAction(true));

    try {
      await Services.Alerts.deleteAlert(alertId);
      dispatch(deleteAlertSuccessAction(alertId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(deleteAlertLoadingAction(false));
    }
  };
};
