import React, { useEffect, useMemo } from 'react';
import { IBillingOption, IBillingRCode } from 'modules/services/interfaces/packages';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { defaultBillingOption } from 'modules/services/constants/packages';
import { FormattedMessage } from 'react-intl';

import commonMessages from 'common/messages/messages';
import { DialogComponent } from 'common/components';
import EditBillingOptionForm from 'modules/services/components/EditPackageForm/BillingStep/EditBillingOptionModal/EditBillingOptionForm';
import messages from 'modules/services/messages/packages';
import { IDurationOptions } from 'modules/services/utils/billingUtils';
import { billingOptionSchema } from 'modules/services/components/EditPackageForm/BillingStep/billingValidationSchema';

interface IEditBillingOptionModal {
  billingOption: IBillingOption;
  revenueCode: IBillingRCode;
  durationOption: IDurationOptions;
  open: boolean;
  onSubmit: (billingOption: IBillingOption) => void;
  onClose: () => void;
  isOutOfTerm?: boolean;
}

const EditBillingOptionModal = ({
  billingOption,
  revenueCode,
  durationOption,
  open,
  onSubmit,
  onClose,
  isOutOfTerm,
}: IEditBillingOptionModal): JSX.Element => {
  const defaultValues = useMemo(
    () => ({
      ...defaultBillingOption,
      ...billingOption,
    }),
    [billingOption],
  );
  const formMethods = useForm<IBillingOption>({
    defaultValues,
    resolver: yupResolver(billingOptionSchema),
    mode: 'all',
  });

  const { handleSubmit, reset } = formMethods;

  const handleDialogSubmitAction = handleSubmit(data =>
    onSubmit({ ...data, isOutOfTerm, id: billingOption?.id }),
  );

  useEffect(() => {
    reset({
      ...defaultBillingOption,
      ...billingOption,
    });
  }, [billingOption, reset]);

  const titleMsg = billingOption
    ? messages.editPaymentOptionModalTitle
    : messages.newPaymentOptionModalTitle;

  return (
    <DialogComponent
      isOpen={open}
      size="md"
      title={
        <FormattedMessage {...(isOutOfTerm ? messages.editOutOfTermBillingModalTitle : titleMsg)} />
      }
      submitBtnTitle={<FormattedMessage {...commonMessages.doneBtn} />}
      onSubmit={handleDialogSubmitAction}
      onClose={onClose}
    >
      <EditBillingOptionForm
        formMethods={formMethods}
        defaultValues={defaultValues}
        revenueCode={revenueCode}
        durationOption={durationOption}
        isOutOfTerm={isOutOfTerm}
      />
    </DialogComponent>
  );
};

export default EditBillingOptionModal;
