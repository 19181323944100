import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { FormControlLabel, Grid, Switch, Typography } from '@material-ui/core';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { getAccessByPropPath } from 'common/utils/errorObject';

import { DurationTypes } from 'modules/services/constants/packages';

import { NumberTextField, Select } from 'common/components';

import servicesMessages from 'modules/services/messages/messages';

const GracePeriodSection = ({ isOutOfTerm }: { isOutOfTerm: boolean }): JSX.Element => {
  const { control, watch, errors } = useFormContext();

  const renderIntlMessage = useRenderIntlMessage();

  const valuePath = isOutOfTerm ? 'outOfTermBillingOption.' : '';

  const isAllow = watch(`${valuePath}paymentGrace.allow`);

  const paymentGraceDurationNumberError = getAccessByPropPath(
    errors,
    `${valuePath}paymentGrace.editableDurationNumber.value`,
  );

  return (
    <>
      <Grid item xs={12}>
        <Controller
          control={control}
          name={`${valuePath}paymentGrace.allow`}
          render={({ onChange, value }) => (
            <FormControlLabel
              labelPlacement="start"
              style={{ marginLeft: 0 }}
              label={
                <Typography variant="h5">
                  <FormattedMessage {...servicesMessages.paymentGracePeriodLabel} />
                </Typography>
              }
              control={
                <Switch
                  size="small"
                  color="primary"
                  checked={value}
                  onChange={(e, checked) => onChange(checked)}
                />
              }
            />
          )}
        />
      </Grid>

      {!!isAllow && (
        <>
          <Grid item xs={6} sm={4}>
            <Controller
              control={control}
              name={`${valuePath}paymentGrace.duration`}
              variant="outlined"
              fullWidth
              render={({ name, value, onChange, onBlur }) => (
                <Select
                  disabled
                  fullWidth
                  variant="outlined"
                  label={<FormattedMessage {...servicesMessages.durationLabel} />}
                  name={name}
                  value={value || ''}
                  onChange={onChange}
                  onBlur={onBlur}
                >
                  {DurationTypes.getSelectOptions().slice(2, 6)}
                </Select>
              )}
            />
          </Grid>

          <Grid item xs={6} sm={4}>
            <Controller
              name={`${valuePath}paymentGrace.editableDurationNumber`}
              control={control}
              render={({ name, value: durationNumber, onChange, onBlur }) => (
                <NumberTextField
                  name={name}
                  value={durationNumber?.value}
                  onBlur={onBlur}
                  onChange={value => onChange({ ...durationNumber, value })}
                  fullWidth
                  variant="outlined"
                  numberFormatProps={{
                    maxLength: 10,
                  }}
                  label={<FormattedMessage {...servicesMessages.amountLabel} />}
                  disabled={!durationNumber?.editable}
                  error={!!paymentGraceDurationNumberError}
                  helperText={renderIntlMessage(paymentGraceDurationNumberError?.message, {
                    value:
                      paymentGraceDurationNumberError?.type === 'max'
                        ? durationNumber?.maxValue
                        : durationNumber?.minValue,
                  })}
                />
              )}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default React.memo(GracePeriodSection);
