import { ConstOptions } from 'common/constants/classes';
import { ContentType, ModuleType } from 'modules/dictionaries/interfaces/helpInfo';

import messages from 'modules/dictionaries/messages/messages';
import commonMessages from 'common/messages/messages';
import corporationsMessages from 'modules/corporations/messages';
import inputLabels from 'common/messages/inputLabels';
import menuItems from 'common/messages/menuItems';
import { PermissionLevels } from 'common/constants/permissions';

export const ContentTypes = new ConstOptions([
  { key: ContentType.VIDEO, value: ContentType.VIDEO, message: messages.videoOption },
  { key: ContentType.DOCUMENT, value: ContentType.DOCUMENT, message: messages.documentOption },
  { key: ContentType.HTML_PAGE, value: ContentType.HTML_PAGE, message: messages.htmlPageOption },
]);

const clubAndCorporateModulesOptions = [
  { key: ModuleType.BOOKING, value: ModuleType.BOOKING, message: commonMessages.booking },
  { key: ModuleType.CRM, value: ModuleType.CRM, message: commonMessages.crm },
  { key: ModuleType.MEMBERS, value: ModuleType.MEMBERS, message: commonMessages.members },
  { key: ModuleType.EMPLOYEES, value: ModuleType.EMPLOYEES, message: inputLabels.employeesLabel },
  { key: ModuleType.REPORTS, value: ModuleType.REPORTS, message: commonMessages.reports },
  { key: ModuleType.SERVICES, value: ModuleType.SERVICES, message: commonMessages.services },
  {
    key: ModuleType.POS_CONTENT,
    value: ModuleType.POS_CONTENT,
    message: commonMessages.posContent,
  },
  {
    key: ModuleType.DICTIONARIES,
    value: ModuleType.DICTIONARIES,
    message: commonMessages.dictionaries,
  },
  {
    key: ModuleType.PERMISSIONS,
    value: ModuleType.PERMISSIONS,
    message: commonMessages.permissions,
  },
  {
    key: ModuleType.CLUBS,
    value: ModuleType.CLUBS,
    message: commonMessages.clubsLabel,
  },
  {
    key: ModuleType.CORPORATE_SETTINGS,
    value: ModuleType.CORPORATE_SETTINGS,
    message: commonMessages.corporateSettings,
  },
  {
    key: ModuleType.FRONT_DESK,
    value: ModuleType.FRONT_DESK,
    message: commonMessages.frontDesk,
  },
  {
    key: ModuleType.KID_ZONE,
    value: ModuleType.KID_ZONE,
    message: commonMessages.kidZone,
  },
  {
    key: ModuleType.POS,
    value: ModuleType.POS,
    message: commonMessages.pos,
  },
];

const peakModulesOptions = [
  {
    key: ModuleType.DICTIONARIES,
    value: ModuleType.DICTIONARIES,
    message: commonMessages.dictionaries,
  },
  {
    key: ModuleType.PERMISSIONS,
    value: ModuleType.PERMISSIONS,
    message: commonMessages.permissions,
  },
  { key: ModuleType.EMPLOYEES, value: ModuleType.EMPLOYEES, message: inputLabels.employeesLabel },
  {
    key: ModuleType.ORGANIZATIONS,
    value: ModuleType.ORGANIZATIONS,
    message: corporationsMessages.corporationsTitle,
  },
  {
    key: ModuleType.SETTINGS,
    value: ModuleType.SETTINGS,
    message: menuItems.settings,
  },
];

export const ClubAndCorporateModuleTypes = new ConstOptions(clubAndCorporateModulesOptions);
export const PeakModuleTypes = new ConstOptions(peakModulesOptions);
export const ModuleTypes = new ConstOptions([
  ...clubAndCorporateModulesOptions,
  {
    key: ModuleType.ORGANIZATIONS,
    value: ModuleType.ORGANIZATIONS,
    message: corporationsMessages.corporationsTitle,
  },
  {
    key: ModuleType.SETTINGS,
    value: ModuleType.SETTINGS,
    message: menuItems.settings,
  },
]);

export const PermissionLevelsTypes = new ConstOptions([
  {
    key: PermissionLevels.PEAK,
    value: PermissionLevels.PEAK,
    message: commonMessages.peakTitle,
  },
  {
    key: PermissionLevels.CORPORATE,
    value: PermissionLevels.CORPORATE,
    message: commonMessages.corporateTitle,
  },
]);
