import messages from 'modules/reports/messages/messages';
import { ConstOptions } from './classes';

export enum ConditionType {
  POINT_OF_SALE_PURCHASE = 'POINT_OF_SALE_PURCHASE',
  MEMBERS_CARDS_EXPIRING_IN_N_DAYS = 'MEMBERS_CARDS_EXPIRING_IN_N_DAYS',
  SESSION_REDEEMED = 'SESSION_REDEEMED',
  MEMBERS_EXPIRING_IN_N_DAYS = 'MEMBERS_EXPIRING_IN_N_DAYS',
  REGULAR_EACH_DAY_OR_NUMBER_OF_DAYS = 'REGULAR_EACH_DAY_OR_NUMBER_OF_DAYS',
  NEW_GUEST_SIGNS_UP = 'NEW_GUEST_SIGNS_UP',
  NEW_APPOINTMENT_SCHEDULED = 'NEW_APPOINTMENT_SCHEDULED',
  LOW_USAGE_MEMBERS_CONTACTED_AFTER_XX = 'LOW_USAGE_MEMBERS_CONTACTED_AFTER_XX',
  CANCELLATION_OF_APPOINTMENT = 'CANCELLATION_OF_APPOINTMENT',
  UPCOMING_APPOINTMENTS_IN_N_DAYS_N_HOURS = 'UPCOMING_APPOINTMENTS_IN_N_DAYS_N_HOURS',
  UPDATE_OF_SCHEDULED_APPOINTMENT = 'UPDATE_OF_SCHEDULED_APPOINTMENT',
  CHECK_IN = 'CHECK_IN',
  NEW_MEMBER_SIGNS_UP = 'NEW_MEMBER_SIGNS_UP',
  USED_THEIR_PASS_LESS_THAN_X_NUMBER_OF_TIMES_WITHIN_THEIR_PASS_LIMITATIONS = 'USED_THEIR_PASS_LESS_THAN_X_NUMBER_OF_TIMES_WITHIN_THEIR_PASS_LIMITATIONS',
  SPECIFIC_DATE_AND_TIME = 'SPECIFIC_DATE_AND_TIME',
  APPOINTMENT_MISSED = 'APPOINTMENT_MISSED',
  TAG_ADDED = 'TAG_ADDED',
  GUEST_PASS_TRIAL_ISSUED = 'GUEST_PASS_TRIAL_ISSUED',
}

export const defaultOrder = 'asc';
export const defaultPerPage = 10;

export enum CampaignStatus {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Canceled = 'CANCELED',
  Archived = 'ARCHIVED',
  Inactive = 'INACTIVE',
}

export enum CampaignType {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  PHONE = 'PHONE',
}

export enum AudienceFilterType {
  GENDER = 'GENDER',
  BASED_ON_SERVICES = 'BASED_ON_SERVICES',
  LEAD_STATUS = 'LEAD_STATUS',
  LOCATIONS = 'LOCATIONS',
  AGE_GROUP = 'AGE_GROUP',
  CAME_FROM = 'CAME_FROM',
}

export enum CampaignCounterType {
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  DAY = 'DAY',
  MINUTE = 'MINUTE',
}

export const statusesCampaignList = new ConstOptions([
  {
    value: CampaignStatus.Active,
    key: CampaignStatus.Active,
    message: messages.active,
  },
  {
    value: CampaignStatus.Completed,
    key: CampaignStatus.Completed,
    message: messages.completed,
  },
  {
    value: CampaignStatus.Canceled,
    key: CampaignStatus.Canceled,
    message: messages.canceled,
  },
  {
    value: CampaignStatus.Archived,
    key: CampaignStatus.Archived,
    message: messages.archived,
  },
  {
    value: CampaignStatus.Inactive,
    key: CampaignStatus.Inactive,
    message: messages.inactive,
  },
]);

export const campaignTypeList = new ConstOptions([
  {
    value: CampaignType.EMAIL,
    key: CampaignType.EMAIL,
    message: messages.email,
  },
  {
    value: CampaignType.SMS,
    key: CampaignType.SMS,
    message: messages.sms,
  },
  {
    value: CampaignType.PHONE,
    key: CampaignType.PHONE,
    message: messages.phone,
  },
]);
