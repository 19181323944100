import { List as ImmutableList } from 'immutable';

import { INamedEntity } from 'common/interfaces/common';
import {
  IOverbookingResourceDTO,
  IShortResource,
  IShortResourceImt,
} from 'modules/booking/interfaces';

export const getAvailableResources = (
  formResources: IShortResource[],
  resources?: ImmutableList<IShortResourceImt>,
  overbookingResources?: IOverbookingResourceDTO[],
): INamedEntity[] => {
  const convertedResources = resources?.toJS() || [];

  return convertedResources.filter(resourceItem => {
    const currentFormResource = formResources.find(item => item.id === resourceItem.id);
    const overbookingEvent = overbookingResources?.find(eventItem => {
      return eventItem.resources.find(item => item.id === resourceItem.id);
    });

    return !currentFormResource && !overbookingEvent;
  });
};
