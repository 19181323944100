export const actionTypes = {
  // item
  FETCH_ADDITIONAL_GUEST_FIELD_ERROR: 'DICTIONARIES/FETCH_ADDITIONAL_GUEST_FIELD_ERROR',
  RESET_ADDITIONAL_GUEST_FIELD: 'DICTIONARIES/RESET_ADDITIONAL_GUEST_FIELD',
  SET_ADDITIONAL_GUEST_FIELD_ACTION_RESULT: 'DICTIONARIES/SET_ADDITIONAL_GUEST_FIELD_ACTION_RESULT',
  UPDATE_STATUS_ADDITIONAL_GUEST_FIELD: 'DICTIONARIES/UPDATE_STATUS_ADDITIONAL_GUEST_FIELD',
  CREATE_OR_UPDATE_ADDITIONAL_GUEST_FIELD_IS_LOADING:
    'DICTIONARIES/CREATE_OR_UPDATE_ADDITIONAL_GUEST_FIELD_IS_LOADING',

  // list
  FETCH_ADDITIONAL_GUEST_FIELDS_LIST: 'DICTIONARIES/FETCH_ADDITIONAL_GUEST_FIELDS_LIST',
  FETCH_ADDITIONAL_GUEST_FIELDS_LIST_IS_LOADING:
    'DICTIONARIES/FETCH_ADDITIONAL_GUEST_FIELDS_LIST_IS_LOADING',
  FETCH_ADDITIONAL_GUEST_FIELDS_LIST_ERROR: 'DICTIONARIES/FETCH_ADDITIONAL_GUEST_FIELDS_LIST_ERROR',
  RESET_ADDITIONAL_GUEST_FIELDS_LIST: 'DICTIONARIES/RESET_ADDITIONAL_GUEST_FIELDS_LIST',
};
