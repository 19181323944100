import axios from 'axios';
import ApiService from './ApiService';
import { ITableParams } from 'common/interfaces/table';
import { IPaginatedData } from 'common/interfaces/pagination';
import { IFee } from 'modules/services/interfaces/fees';

export default class FeesService extends ApiService {
  public getFeesList = (requestOptions?: ITableParams): Promise<IPaginatedData<IFee>> => {
    const tableParams = this.getSearchFromTableParams(requestOptions);

    return axios.get('/api/v1/fees', {
      params: tableParams,
    });
  };

  public changeFeeStatus = (id: string, active: boolean): Promise<IFee> =>
    axios.patch(`/api/v1/fees/${id}/change-status`, { active });

  public getFeeDetails = (id: string): Promise<IFee> => axios.get(`/api/v1/fees/${id}`);

  public createFee = (createData: IFee): Promise<IFee> => axios.post('api/v1/fees', createData);

  public updateFee = (id: string, updatedData: IFee): Promise<IFee> =>
    axios.put(`/api/v1/fees/${id}`, updatedData);

  public getPackageFeesList = (title: string): Promise<IFee[]> =>
    axios.get(`/api/v1/packages/fees`, { params: { title } });
}
