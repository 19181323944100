import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  selectCurrentUserCorporation,
  selectUserSelectedOrganization,
} from 'modules/authentication/state/selectors';

import { INamedEntityImt } from 'common/interfaces/common';
import { IUserOrganizationImt } from 'common/interfaces/clients';
import { BookingPaths } from 'modules/booking/constants';
import { selectLeadId } from 'modules/crm/state/leads/selectors';

export const useTopics = (): string[] => {
  const corporation: INamedEntityImt = useSelector(selectCurrentUserCorporation);
  const selectedOrg: IUserOrganizationImt = useSelector(selectUserSelectedOrganization);
  const leadId = useSelector(selectLeadId);

  return useMemo(() => {
    const topics = [
      `/topic/corporations/${corporation?.get('id')}/check-ins`, // check-ins
      `/topic/corporations/${corporation?.get('id')}/invoices`, // invoice
      `/topic/corporations/${corporation?.get('id')}/inventories`, // inventory
    ];

    if (window.location.pathname === BookingPaths.BookingEvents) {
      topics.push(`/topic/events/clubs/${selectedOrg?.get('id')}`);
    }

    if (leadId) {
      topics.push(`/topic/employees/${leadId}/emails`);
    }

    return topics;
  }, [corporation, selectedOrg, leadId]);
};
