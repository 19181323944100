import moment from 'moment-timezone';

import Services from 'services/network';
import { FilterTypes, IPaginateFilterReturnType } from 'common/interfaces/filter';
import { IPeriodItem } from 'modules/corporations/interfaces';
import { ITableParams } from 'common/interfaces/table';
import { makeFilterChangeHandler } from 'common/utils/filter';

export const employeeTimeclockCommonFilter = {
  perPage: 20,
  fetchItemTitle: async (id: string): Promise<string | null> => {
    try {
      const employee = await Services.Employees.getEmployeeById(id);
      return `${employee.firstName} ${employee.lastName}`;
    } catch (e) {
      return null;
    }
  },
  fetchList: async (search: string, page: number): Promise<IPaginateFilterReturnType> => {
    try {
      const { meta, data } = await Services.DictionaryLists.getTimeclockEmployees({
        search,
        page,
        perPage: 20,
      });

      return {
        pageElements: data.map(item => ({ key: item.id, label: item.title, value: item.id })),
        itemCount: meta.total,
      };
    } catch (e) {
      return { pageElements: [], itemCount: 0 };
    }
  },
};

export const getCurrentPayPeriod = (data: IPeriodItem[]) => {
  let currentPayPeriod: IPeriodItem | null = null;

  for (let i = 0, ln = data.length; i < ln; i += 1) {
    const { startDate, endDate } = data[i];

    const currentDate = moment();
    const startMomentDate = moment(startDate, 'YYYY-MM-DD');
    const endMomentDate = moment(endDate, 'YYYY-MM-DD');

    if (
      currentDate.isSameOrAfter(startMomentDate, 'date') &&
      currentDate.isSameOrBefore(endMomentDate, 'date')
    ) {
      currentPayPeriod = data[i];
    }

    if (currentPayPeriod) {
      break;
    }
  }

  return currentPayPeriod;
};

export const getNewTableParams = (
  requestOptions?: ITableParams,
  currentPayPeriod?: IPeriodItem,
) => {
  const dateRangeFilterValue = {
    name: 'range',
    type: FilterTypes.DATE_RANGE,
    value: currentPayPeriod
      ? {
          startDate: moment(currentPayPeriod.startDate, 'YYYY-MM-DD').toDate(),
          endDate: moment(currentPayPeriod.endDate, 'YYYY-MM-DD').toDate(),
        }
      : null,
  };

  const getFilters = makeFilterChangeHandler(requestOptions?.filters || [], () => {});

  return { ...requestOptions, filters: getFilters(dateRangeFilterValue) };
};
