import { ConstOptions } from 'common/constants/classes';
import { AppointmentReportStatuses } from '../interfaces/appointmentsReport';
import messages from '../messages/messages';

export const actionTypes = {
  PUSH_REQUEST_ERROR: 'PUSH_REQUEST_ERROR',
  RESET_REQUEST_ERRORS: 'RESET_REQUEST_ERRORS',
  // Appointments
  FETCH_APPOINTMENTS_LOADING: 'FETCH_APPOINTMENTS_LOADING',
  FETCH_APPOINTMENTS: 'FETCH_APPOINTMENTS',
  // Appointment
  FETCH_APPOINTMENT_LOADING: 'FETCH_APPOINTMENT_LOADING',
  FETCH_APPOINTMENT: 'FETCH_APPOINTMENT',
  RESET_APPOINTMENT: 'RESET_APPOINTMENT',
  // Export
  EXPORT_APPOINTMENTS_REPORT_LOADING: 'EXPORT_APPOINTMENTS_REPORT_LOADING',
  EXPORT_APPOINTMENTS_REPORT: 'EXPORT_APPOINTMENTS_REPORT',
  RESET_APPOINTMENTS_EXPORT_SUCCESS: 'RESET_APPOINTMENTS_EXPORT_SUCCESS',
};

export const appointmentReportStatuses = new ConstOptions([
  {
    key: AppointmentReportStatuses.CANCELLED,
    value: AppointmentReportStatuses.CANCELLED,
    message: messages.appointmentReportCancelledStatus,
  },
  {
    key: AppointmentReportStatuses.DELETED,
    value: AppointmentReportStatuses.DELETED,
    message: messages.appointmentReportDeletedStatus,
  },
]);
