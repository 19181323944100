export const actionTypes = {
  USER_SIGN_IN: 'AUTHENTICATION/USER_SIGN_IN',
  USER_SIGN_IN_LOADING: 'AUTHENTICATION/USER_SIGN_IN_LOADING',
  USER_SIGN_IN_ERROR: 'AUTHENTICATION/USER_SIGN_IN_ERROR',

  USER_LOG_OUT: 'AUTHENTICATION/USER_LOG_OUT',

  FETCH_USER_PROFILE: 'AUTHENTICATION/FETCH_USER_PROFILE',
  FETCH_USER_PROFILE_LOADING: 'AUTHENTICATION/FETCH_USER_PROFILE_LOADING',
  FETCH_USER_PROFILE_ERROR: 'AUTHENTICATION/FETCH_USER_PROFILE_ERROR',
  RESET_USER_PROFILE_DATA: 'AUTHENTICATION/RESET_USER_PROFILE_DATA',

  SELECT_ORGANIZATION: 'AUTHENTICATION/SELECT_ORGANIZATION',
  SET_ORGANIZATION_PRIMARY_COLOR: 'AUTHENTICATION/SET_ORGANIZATION_PRIMARY_COLOR',
  RESET_ORGANIZATION_PRIMARY_COLOR: 'AUTHENTICATION/RESET_ORGANIZATION_PRIMARY_COLOR',

  SET_IS_SIDEBAR_OPEN: 'AUTHENTICATION/SET_IS_SIDEBAR_OPEN',

  SET_USER_AVATAR: 'AUTHENTICATION/SET_USER_AVATAR',

  UPDATE_CLUB_DATA: 'AUTHENTICATION/UPDATE_CLUB_DATA',
  UPDATE_CORPORATION_DATA: 'AUTHENTICATION/UPDATE_CORPORATION_DATA',

  FETCH_CORPORATIONS_FOR_SIGN_IN: 'AUTHENTICATION/FETCH_CORPORATIONS_FOR_SIGN_IN',
  FETCH_CORPORATIONS_FOR_SIGN_IN_LOADING: 'AUTHENTICATION/FETCH_CORPORATIONS_FOR_SIGN_IN_LOADING',
  RESET_CORPORATIONS_FOR_SIGN_IN: 'AUTHENTICATION/RESET_CORPORATIONS_FOR_SIGN_IN',
};
