import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';

import { selectDictionaryList } from 'common/state/dictionary/selectors';

import { DictionaryList } from 'common/constants';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import { ICustomerInsuranceDto, TCustomerInsuranceDtoImt } from 'common/interfaces/additionalInfo';
import { IDictionaryCommonItemImt } from 'modules/dictionaries/interfaces/interfaces';

import { DialogComponent, MultipleSelect, TextField } from 'common/components';

import { insuranceValidationSchema } from 'common/validationSchemas/InsuranceValidationSchema';

import commonMessages from 'common/messages/messages';
import messages from 'common/components/PersonProfile/messages';
import inputLabels from 'common/messages/inputLabels';
import { selectProfileRequiredFields } from 'common/state/settings/selectors';
import { RequiredProfileInfo } from 'modules/corporate-settings/constants/requiredFields';
import { selectIsSubmittedRecommendedFieldsModal } from 'common/state/modals/selectors';
import { useRecommendedFields } from 'common/hooks/useRecommendedFields';
import { showRecommendedFieldsModal } from 'common/state/modals/actions';
import { useAppDispatch } from 'store/hooks';

interface InsuranceInfoModalProps {
  isOpen: boolean;
  isLoading: boolean;
  insuranceInfo: TCustomerInsuranceDtoImt;
  onClose: () => void;
  onSubmit: (values: Omit<ICustomerInsuranceDto, 'id'>) => void;
}

const initialValues = {
  insuranceCompany: null,
  insuranceMemberId: '',
};

const InsuranceInfoModal = ({
  isOpen,
  onClose,
  onSubmit,
  isLoading,
  insuranceInfo,
}: InsuranceInfoModalProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const insuranceCompanies: ImmutableList<IDictionaryCommonItemImt> = useSelector(
    selectDictionaryList(DictionaryList.INSURANCE_COMPANY),
  );
  const profileRequiredFields = useSelector(selectProfileRequiredFields);
  const isSubmitted = useSelector(selectIsSubmittedRecommendedFieldsModal);

  const { checkRecommendedFieldsAreNotEmpty, profileRecommendedFields } = useRecommendedFields();

  const renderIntlMessage = useRenderIntlMessage();

  const formMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(yup.object().shape(insuranceValidationSchema(profileRequiredFields))),
    mode: 'onBlur',
  });

  const { errors, control, reset, handleSubmit, getValues } = formMethods;

  useEffect(() => {
    if (isSubmitted) {
      const values = getValues();

      dispatch(showRecommendedFieldsModal(false));
      onSubmit(values);
    }
  }, [dispatch, getValues, isSubmitted, onSubmit]);

  useEffect(() => {
    if (insuranceInfo?.size) {
      reset({
        ...initialValues,
        ...insuranceInfo.toJS(),
      });
    }
  }, [insuranceInfo, reset]);

  const onSave = (values: Omit<ICustomerInsuranceDto, 'id'>) => {
    const hasEmptyRecommendedFields = checkRecommendedFieldsAreNotEmpty({ insuranceDto: values });

    if (hasEmptyRecommendedFields) {
      dispatch(showRecommendedFieldsModal(true));
    } else {
      onSubmit(values);
    }
  };

  return (
    <DialogComponent
      size="xs"
      title={<FormattedMessage {...messages.insuranceInfoBlockTitle} />}
      submitBtnTitle={<FormattedMessage {...commonMessages.saveBtn} />}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSave)}
      disableFullScreenBehavior
      loading={isLoading}
    >
      <form onSubmit={handleSubmit(onSave)} autoComplete="none">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Controller
              name="insuranceCompany"
              control={control}
              render={({ value, onChange, onBlur }) => (
                <MultipleSelect
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  fullWidth
                  disableClearable
                  options={insuranceCompanies?.toJS()}
                  label={renderIntlMessage(inputLabels.insuranceCompany)}
                  error={!!errors.insuranceCompany?.message}
                  helperText={renderIntlMessage(errors.insuranceCompany?.message)}
                  recommended={Boolean(
                    profileRecommendedFields?.get(RequiredProfileInfo.InsuranceInfo),
                  )}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="insuranceMemberId"
              defaultValue=""
              render={({ value, onChange, onBlur }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  label={renderIntlMessage(inputLabels.id)}
                  variant="outlined"
                  fullWidth
                  error={!!errors.insuranceMemberId?.message}
                  helperText={renderIntlMessage(errors.insuranceMemberId?.message)}
                  recommended={Boolean(
                    profileRecommendedFields?.get(RequiredProfileInfo.InsuranceInfo),
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
      </form>
    </DialogComponent>
  );
};

export default InsuranceInfoModal;
