import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import moment from 'moment-timezone';
import cx from 'classnames';
import { Box, Button, FormHelperText, IconButton, makeStyles, Theme } from '@material-ui/core';

import { getAccessByPropPath } from 'common/utils/errorObject';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import { TimePicker } from 'common/components/index';
import { ReactComponent as DeleteIcon } from 'img/icons/trash_deprecated.svg';
import { ReactComponent as AddIcon } from 'img/icons/add.svg';

import inputLabels from 'common/messages/inputLabels';

const useStyles = makeStyles((theme: Theme) => ({
  disabledColor: {
    '& .MuiInputBase-input.Mui-disabled': {
      opacity: 0.4,
    },
  },
  deleteButton: {
    marginLeft: theme.spacing(0.5),
  },
  addIcon: {
    width: 16,
    height: 16,
  },
  timePicker: {
    flex: 1,
  },
}));

interface IProps {
  name: string;
}

const TimeAvailabilitySelector = ({ name }: IProps): JSX.Element => {
  const { control, errors, watch, setValue, trigger, clearErrors } = useFormContext();
  const { fields: timeRanges, append, remove } = useFieldArray({
    control,
    name: `${name}.timeRanges`,
  });

  const renderIntlMessage = useRenderIntlMessage();

  const isAllDay = watch(`${name}.allDay`);
  const editableRangeIndex = watch(`${name}.editableRangeIndex`);

  const classes = useStyles();
  const handleAddRange = () => {
    setValue(`${name}.editableRangeIndex`, timeRanges.length);
    append({ startTime: null, endTime: null });
  };

  return (
    <>
      {timeRanges.map((range, index) => {
        const startTimeError = getAccessByPropPath(errors, `${name}.timeRanges.${index}.startTime`);
        const endTimeError = getAccessByPropPath(errors, `${name}.timeRanges.${index}.endTime`);
        const overlapError = getAccessByPropPath(errors, `${name}.timeRanges`);

        return (
          <Box display="flex" alignItems="center" key={range.id}>
            <Box display="flex" gridGap={8} width="50%" marginY={1.5} flexWrap="wrap">
              <Box display="flex" gridGap={8}>
                <Controller
                  name={`${name}.timeRanges.${index}.startTime`}
                  control={control}
                  defaultValue={range.startTime}
                  render={({ value, onChange, onBlur, name: pickerName }) => (
                    <TimePicker
                      small
                      name={pickerName}
                      onChange={async time => {
                        onChange(moment(time).format('HH:mm'));
                        await trigger(`${name}.timeRanges`);
                      }}
                      onBlur={onBlur}
                      value={value ? moment(value, 'HH:mm').format() : ''}
                      onFocus={() => setValue(`${name}.editableRangeIndex`, index)}
                      className={cx(classes.timePicker, {
                        [classes.disabledColor]: isAllDay,
                      })}
                      labelMessageDescriptor={inputLabels.startTime}
                      error={!!startTimeError}
                      helperText={renderIntlMessage(startTimeError?.message)}
                    />
                  )}
                />

                <Controller
                  name={`${name}.timeRanges.${index}.endTime`}
                  control={control}
                  defaultValue={range.endTime}
                  render={({ value, onChange, onBlur, name: pickerName }) => (
                    <TimePicker
                      small
                      onChange={async time => {
                        onChange(moment(time).format('HH:mm'));
                        await trigger(`${name}.timeRanges`);
                      }}
                      onBlur={onBlur}
                      name={pickerName}
                      value={value ? moment(value, 'HH:mm').toDate() : ''}
                      onFocus={() => setValue(`${name}.editableRangeIndex`, index)}
                      className={cx(classes.timePicker, {
                        [classes.disabledColor]: isAllDay,
                      })}
                      labelMessageDescriptor={inputLabels.endTime}
                      error={!!endTimeError}
                      helperText={
                        endTimeError?.type !== 'min' && renderIntlMessage(endTimeError?.message)
                      }
                    />
                  )}
                />
              </Box>

              {(endTimeError?.type === 'min' ||
                (overlapError?.type === 'overlap' && index === editableRangeIndex)) && (
                <FormHelperText error>
                  {renderIntlMessage((endTimeError || overlapError)?.message)}
                </FormHelperText>
              )}
            </Box>

            {!!index && (
              <IconButton
                color="primary"
                size="small"
                onClick={() => {
                  setValue(`${name}.editableRangeIndex`, timeRanges.length - 2);
                  clearErrors(`${name}.timeRanges`);
                  remove(index);
                }}
                disabled={isAllDay}
                className={classes.deleteButton}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        );
      })}

      {!isAllDay && (
        <Button
          startIcon={<AddIcon className={classes.addIcon} />}
          color="primary"
          onClick={handleAddRange}
          disabled={isAllDay || getAccessByPropPath(errors, `${name}.timeRanges`)}
        >
          <FormattedMessage {...inputLabels.time} />
        </Button>
      )}
    </>
  );
};
export default TimeAvailabilitySelector;
