import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';

import {
  checkIsPaymentScheduleOptionDisabled,
  countTaxedAmount,
} from 'modules/services/utils/billingUtils';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import { FieldInfo, Select } from 'common/components/index';

import {
  BillingOptionType,
  FrequencyType,
  FrequencyTypes,
} from 'modules/services/constants/packages';
import { IEditablePackageConfiguration } from 'common/interfaces/membership';
import { IBillingOption } from 'modules/services/interfaces/packages';

import inputLabels from 'common/messages/inputLabels';
import servicesMessages from 'modules/services/messages/messages';

interface IAmountSection {
  membershipPackage: IEditablePackageConfiguration;
  billingOption: IBillingOption;
  isOutOfTerm: boolean;
}

const AmountSection = ({
  membershipPackage,
  billingOption,
  isOutOfTerm,
}: IAmountSection): JSX.Element => {
  const { control, errors } = useFormContext();

  const renderIntlMessage = useRenderIntlMessage();

  const { totalAmount, type: optionType } = billingOption;
  const {
    revenueCode: { totalTax },
    durationType,
    durationEditableNumber,
  } = membershipPackage;

  const taxedTotalAmount =
    totalAmount && totalTax ? countTaxedAmount(totalAmount, totalTax) : totalAmount;

  const valuePath = isOutOfTerm ? 'outOfTermBillingOption.' : '';

  return (
    <>
      <Grid item xs={6}>
        <FieldInfo
          inputMode
          grayedOut
          label={<FormattedMessage {...inputLabels.totalAmount} />}
          description={`$${totalAmount?.toFixed(2)}`}
        />
      </Grid>

      <Grid item xs={6}>
        <FieldInfo
          inputMode
          grayedOut
          label={<FormattedMessage {...inputLabels.taxedTotalAmount} />}
          description={`$${taxedTotalAmount?.toFixed(2)}`}
        />
      </Grid>

      {optionType !== BillingOptionType.PAID_IN_FULL && (
        <>
          <Grid item xs={6}>
            <Controller
              name={`${valuePath}paymentEditableSchedule`}
              control={control}
              render={({ value: paymentEditableSchedule, onChange }) => (
                <Select
                  label={<FormattedMessage {...servicesMessages.paymentScheduleLabel} />}
                  variant="outlined"
                  error={!!errors.paymentEditableSchedule}
                  helperText={renderIntlMessage(errors.paymentEditableSchedule?.message)}
                  fullWidth
                  disabled={!paymentEditableSchedule?.editable}
                  value={paymentEditableSchedule?.value || ''}
                  onChange={scheduleValue => {
                    onChange({ ...paymentEditableSchedule, value: scheduleValue });
                  }}
                >
                  {FrequencyTypes.getSelectOptions({
                    isDisabledFunc: (type: FrequencyType) =>
                      checkIsPaymentScheduleOptionDisabled(
                        durationType,
                        durationEditableNumber?.value,
                        type,
                      ),
                  })}
                </Select>
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Controller
              name="pricePerBilling"
              control={control}
              defaultValue={0}
              render={({ value }) => (
                <FieldInfo
                  inputMode
                  grayedOut
                  label={<FormattedMessage {...servicesMessages.pricePerBillingLabel} />}
                  description={`$${value?.toFixed(2)}`}
                />
              )}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default AmountSection;
