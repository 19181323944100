import { createAction } from '@reduxjs/toolkit';

import Services from 'services/network';

import { enqueueErrorNotification } from 'common/state/notifications/actions';

import { actionTypes } from 'modules/reports/constants/appointmentsReport';

import { ITableParams } from 'common/interfaces/table';
import { IPaginatedAppointments } from 'modules/reports/interfaces/appointmentsReport';
import { IBookingEvent } from 'modules/booking/interfaces';
import { GeneralThunkAction } from 'common/state/interfaces';
import { IObject } from 'common/interfaces/common';

const fetchAppointmentsLoading = createAction<boolean>(actionTypes.FETCH_APPOINTMENTS_LOADING);
const fetchAppointmentsAction = createAction<IPaginatedAppointments>(
  actionTypes.FETCH_APPOINTMENTS,
);

export const fetchAppointmentsList = (requestOptions?: ITableParams): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchAppointmentsLoading(true));

    try {
      const paginatedData = await Services.Reports.getAppointments(requestOptions);
      dispatch(fetchAppointmentsAction(paginatedData));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchAppointmentsLoading(false));
    }
  };
};

const exportAppointmentsReportLoading = createAction<boolean>(
  actionTypes.EXPORT_APPOINTMENTS_REPORT_LOADING,
);
const exportAppointmentsReportAction = createAction<any>(actionTypes.EXPORT_APPOINTMENTS_REPORT);
export const resetAppointmentsExportSuccess = createAction(
  actionTypes.RESET_APPOINTMENTS_EXPORT_SUCCESS,
);

export const exportAppointmentsReport = (
  data: IObject,
  tableParams: ITableParams,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(exportAppointmentsReportLoading(true));

    try {
      const response = await Services.Reports.exportAppointmentsReport(data, tableParams);
      dispatch(exportAppointmentsReportAction(response));
    } catch (error) {
      // dispatch(pushRequestError((error as Error).message));
    } finally {
      dispatch(exportAppointmentsReportLoading(false));
    }
  };
};

const fetchAppointmentByIdLoadingAction = createAction<boolean>(
  actionTypes.FETCH_APPOINTMENT_LOADING,
);
const fetchAppointmentByIdAction = createAction<IBookingEvent>(actionTypes.FETCH_APPOINTMENT);

export const fetchAppointmentById = (eventId: string, eventDate: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchAppointmentByIdLoadingAction(true));

    try {
      const appointment = await Services.Reports.getAppointmentById(eventId, eventDate);
      dispatch(fetchAppointmentByIdAction(appointment));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchAppointmentByIdLoadingAction(false));
    }
  };
};

export const resetAppointmentReportEvent = createAction(actionTypes.RESET_APPOINTMENT);
