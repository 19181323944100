import { CashTypes, ServiceModeTypes } from 'modules/reports/interfaces/salesPOS';

export const reportsOverview = {
  membersCountReport: [
    {
      date: '2022-10-16T14:12:04.943Z',
      gaining: 906,
      loosing: 899,
    },
    {
      date: '2022-11-16T14:12:04.943Z',
      gaining: 974,
      loosing: 181,
    },
    {
      date: '2022-12-16T14:12:04.943Z',
      gaining: 583,
      loosing: 588,
    },
    {
      date: '2023-01-16T14:12:04.943Z',
      gaining: 452,
      loosing: 166,
    },
    {
      date: '2023-02-16T14:12:04.943Z',
      gaining: 508,
      loosing: 443,
    },
    {
      date: '2023-03-16T14:12:04.943Z',
      gaining: 279,
      loosing: 52,
    },
    {
      date: '2023-04-16T14:12:04.943Z',
      gaining: 742,
      loosing: 952,
    },
    {
      date: '2023-05-16T14:12:04.943Z',
      gaining: 698,
      loosing: 435,
    },
    {
      date: '2023-06-16T14:12:04.943Z',
      gaining: 911,
      loosing: 931,
    },
    {
      date: '2023-07-16T14:12:04.943Z',
      gaining: 744,
      loosing: 141,
    },
    {
      date: '2023-08-16T14:12:04.943Z',
      gaining: 166,
      loosing: 445,
    },
    {
      date: '2023-09-16T14:12:04.943Z',
      gaining: 271,
      loosing: 819,
    },
    {
      date: '2023-10-16T14:12:04.943Z',
      gaining: 664,
      loosing: 991,
    },
    {
      date: '2023-11-16T14:12:04.943Z',
      gaining: 737,
      loosing: 910,
    },
    {
      date: '2023-12-16T14:12:04.943Z',
      gaining: 644,
      loosing: 438,
    },
    {
      date: '2024-01-16T14:12:04.943Z',
      gaining: 619,
      loosing: 610,
    },
    {
      date: '2024-02-16T14:12:04.943Z',
      gaining: 666,
      loosing: 567,
    },
    {
      date: '2024-03-16T14:12:04.943Z',
      gaining: 993,
      loosing: 411,
    },
    {
      date: '2024-04-16T14:12:04.943Z',
      gaining: 450,
      loosing: 103,
    },
    {
      date: '2024-05-16T14:12:04.943Z',
      gaining: 475,
      loosing: 961,
    },
    {
      date: '2024-06-16T14:12:04.943Z',
      gaining: 862,
      loosing: 335,
    },
    {
      date: '2024-07-16T14:12:04.943Z',
      gaining: 466,
      loosing: 713,
    },
  ],
  membershipMixReport: [
    {
      date: '2022-10-16T14:12:04.943Z',
      paidInFull: 6947,
      financedInTerm: 4233,
      financedOutOfTerm: 217,
    },
    {
      date: '2022-11-16T14:12:04.943Z',
      paidInFull: 974,
      financedInTerm: 7019,
      financedOutOfTerm: 5434,
    },
    {
      date: '2022-12-16T14:12:04.943Z',
      paidInFull: 2056,
      financedInTerm: 3709,
      financedOutOfTerm: 6174,
    },
    {
      date: '2023-01-16T14:12:04.943Z',
      paidInFull: 7612,
      financedInTerm: 9774,
      financedOutOfTerm: 1842,
    },
    {
      date: '2023-02-16T14:12:04.943Z',
      paidInFull: 7515,
      financedInTerm: 1541,
      financedOutOfTerm: 6635,
    },
    {
      date: '2023-03-16T14:12:04.943Z',
      paidInFull: 4176,
      financedInTerm: 6155,
      financedOutOfTerm: 3978,
    },
    {
      date: '2023-04-16T14:12:04.943Z',
      paidInFull: 8530,
      financedInTerm: 6538,
      financedOutOfTerm: 3267,
    },
    {
      date: '2023-05-16T14:12:04.943Z',
      paidInFull: 5186,
      financedInTerm: 2826,
      financedOutOfTerm: 6430,
    },
    {
      date: '2023-06-16T14:12:04.943Z',
      paidInFull: 2707,
      financedInTerm: 1993,
      financedOutOfTerm: 1796,
    },
    {
      date: '2023-07-16T14:12:04.943Z',
      paidInFull: 8017,
      financedInTerm: 802,
      financedOutOfTerm: 5251,
    },
    {
      date: '2023-08-16T14:12:04.943Z',
      paidInFull: 4116,
      financedInTerm: 2925,
      financedOutOfTerm: 2763,
    },
    {
      date: '2023-09-16T14:12:04.943Z',
      paidInFull: 3339,
      financedInTerm: 3919,
      financedOutOfTerm: 8413,
    },
    {
      date: '2023-10-16T14:12:04.943Z',
      paidInFull: 103,
      financedInTerm: 4745,
      financedOutOfTerm: 7910,
    },
    {
      date: '2023-11-16T14:12:04.943Z',
      paidInFull: 2756,
      financedInTerm: 5723,
      financedOutOfTerm: 9205,
    },
    {
      date: '2023-12-16T14:12:04.943Z',
      paidInFull: 913,
      financedInTerm: 9845,
      financedOutOfTerm: 7983,
    },
    {
      date: '2024-01-16T14:12:04.943Z',
      paidInFull: 6018,
      financedInTerm: 3079,
      financedOutOfTerm: 4026,
    },
    {
      date: '2024-02-16T14:12:04.943Z',
      paidInFull: 6122,
      financedInTerm: 287,
      financedOutOfTerm: 5341,
    },
    {
      date: '2024-03-16T14:12:04.943Z',
      paidInFull: 3723,
      financedInTerm: 2879,
      financedOutOfTerm: 4790,
    },
    {
      date: '2024-04-16T14:12:04.943Z',
      paidInFull: 6116,
      financedInTerm: 1528,
      financedOutOfTerm: 363,
    },
    {
      date: '2024-05-16T14:12:04.943Z',
      paidInFull: 9027,
      financedInTerm: 8724,
      financedOutOfTerm: 7836,
    },
    {
      date: '2024-06-16T14:12:04.943Z',
      paidInFull: 6405,
      financedInTerm: 5099,
      financedOutOfTerm: 2371,
    },
    {
      date: '2024-07-16T14:12:04.943Z',
      paidInFull: 2199,
      financedInTerm: 4895,
      financedOutOfTerm: 3719,
    },
  ],
};

export const salesPOSReport = {
  serviceSummary: {
    data: [
      {
        id: '1',
        type: 'Net Sales' as ServiceModeTypes,
        amount: 17053,
      },
      {
        id: '2',
        type: 'Total Members/Leads' as ServiceModeTypes,
        amount: 17053,
      },
      {
        id: '3',
        type: 'Average by Member/Lead' as ServiceModeTypes,
        amount: 0,
      },
      {
        id: '4',
        type: 'Total Payments' as ServiceModeTypes,
        amount: 0,
      },
      {
        id: '5',
        type: 'Average Payment' as ServiceModeTypes,
        amount: 0,
      },
      {
        id: '6',
        type: 'Total Orders' as ServiceModeTypes,
        amount: 0,
      },
      {
        id: '7',
        type: 'Average Invoice' as ServiceModeTypes,
        amount: 0,
      },
    ],
    totals: { amount: 34106 },
  },
  cashSummary: {
    data: [
      {
        id: '1',
        type: 'Expected CloseoutCash' as CashTypes,
        amount: 17053,
      },
      {
        id: '2',
        type: 'Actual Closeout Cash' as CashTypes,
        amount: 17053,
      },
      {
        id: '3',
        type: 'Cash Overage/Shortage' as CashTypes,
        amount: 0,
      },
      {
        id: '4',
        type: 'Expected Deposit' as CashTypes,
        amount: 0,
      },
      {
        id: '5',
        type: 'Actual Deposit' as CashTypes,
        amount: 0,
      },
      {
        id: '6',
        type: 'Deposit Overage/Shortage' as CashTypes,
        amount: 0,
      },
    ],
    totals: { amount: 34106 },
  },
};

export const invoiceHistorySnapshots = {
  data: [
    {
      id: '1',
      name: 'Snapshot automate system',
      createdDate: '2021-11-17T03:22:45.940Z',
      link: '/1',
      author: {
        title: 'System',
        imageUrl: null,
        id: '1',
      },
    },
    {
      id: '2',
      name: 'Snapshot J.Doe(1)',
      createdDate: '2022-05-11T19:37:33.426Z',
      link: '/2',
      author: {
        title: 'John Doe',
        imageUrl: null,
        id: '2',
      },
    },
    {
      id: '3',
      name: 'Snapshot A.Abasto',
      createdDate: '2021-11-17T03:22:45.940Z',
      link: '/3',
      author: {
        title: 'Albert Abasto',
        imageUrl: null,
        id: '3',
      },
    },
    {
      id: '4',
      name: 'Snapshot J.Doe',
      createdDate: '2022-05-11T19:37:33.426Z',
      link: null,
      author: {
        title: 'John Doe',
        imageUrl: null,
        id: '2',
      },
    },
  ],
};
