export const actionTypes = {
  // Revenue codes list
  FETCH_REVENUE_CODES_LIST: 'FETCH_REVENUE_CODES_LIST',
  FETCH_REVENUE_CODES_LIST_LOADING: 'FETCH_REVENUE_CODES_LIST_LOADING',

  REVENUE_CODE_CHANGE_STATUS_SUCCESS: 'REVENUE_CODE_CHANGE_STATUS_SUCCESS',
  REVENUE_CODE_CHANGE_STATUS_LOADING: 'REVENUE_CODE_CHANGE_STATUS_LOADING',
  RESET_REVENUE_CODE_CHANGE_STATUS: 'RESET_REVENUE_CODE_CHANGE_STATUS',

  FETCH_REVENUE_CODE_DETAILS: 'FETCH_REVENUE_CODE_DETAILS',
  FETCH_REVENUE_CODE_DETAILS_LOADING: 'FETCH_REVENUE_CODE_DETAILS_LOADING',
  RESET_REVENUE_CODE_DETAILS: 'RESET_REVENUE_CODE_DETAILS',

  CREATE_REVENUE_CODE_LOADING: 'CREATE_REVENUE_CODE_LOADING',
  CREATE_REVENUE_CODE_SUCCESS: 'CREATE_REVENUE_CODE_SUCCESS',
  RESET_CREATE_REVENUE_CODE: 'RESET_CREATE_REVENUE_CODE',

  EDIT_REVENUE_CODE_LOADING: 'EDIT_REVENUE_CODE_LOADING',
  EDIT_REVENUE_CODE_SUCCESS: 'EDIT_REVENUE_CODE_SUCCESS',
  RESET_EDIT_REVENUE_CODE: 'RESET_EDIT_REVENUE_CODE',

  DELETE_REVENUE_CODE_LOADING: 'DELETE_REVENUE_CODE_LOADING',
  DELETE_REVENUE_CODE_SUCCESS: 'DELETE_REVENUE_CODE_SUCCESS',
  RESET_DELETE_REVENUE_CODE: 'RESET_DELETE_REVENUE_CODE',

  FETCH_REVENUE_CODE_CLUB_CONFIGURATIONS: 'FETCH_REVENUE_CODE_CLUB_CONFIGURATIONS',
  RESET_REVENUE_CODE_CLUB_CONFIGURATIONS: 'RESET_REVENUE_CODE_CLUB_CONFIGURATIONS',
  FETCH_REVENUE_CODE_CLUB_CONFIGURATIONS_LOADING: 'FETCH_REVENUE_CODE_CLUB_CONFIGURATIONS_LOADING',

  ADD_CLUB_CONFIGURATIONS_LOADING: 'ADD_CLUB_CONFIGURATIONS_LOADING',
  ADD_CLUB_CONFIGURATIONS_RESULT: 'ADD_CLUB_CONFIGURATIONS_RESULT',
  RESET_ADD_CLUB_CONFIGURATIONS: 'RESET_ADD_CLUB_CONFIGURATIONS',

  EDIT_CLUB_CONFIGURATIONS_LOADING: 'EDIT_CLUB_CONFIGURATIONS_LOADING',
  EDIT_CLUB_CONFIGURATIONS_RESULT: 'EDIT_CLUB_CONFIGURATIONS_RESULT',
  RESET_EDIT_CLUB_CONFIGURATIONS: 'RESET_EDIT_CLUB_CONFIGURATIONS',

  REMOVE_CLUB_CONFIGURATIONS_LOADING: 'REMOVE_CLUB_CONFIGURATIONS_LOADING',
  REMOVE_CLUB_CONFIGURATIONS_RESULT: 'REMOVE_CLUB_CONFIGURATIONS_RESULT',
  RESET_REMOVE_CLUB_CONFIGURATIONS: 'RESET_REMOVE_CLUB_CONFIGURATIONS',

  FETCH_CONFIGURING_REVENUE_CODE: 'FETCH_CONFIGURING_REVENUE_CODE',
  FETCH_CONFIGURING_REVENUE_CODE_LOADING: 'FETCH_CONFIGURING_REVENUE_CODE_LOADING',
};
