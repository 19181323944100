// libraries
import React from 'react';
import { Cancel } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { Grid, IconButton, Typography } from '@material-ui/core';
// custom interfaces
import { CustomTheme } from 'common/ui/interfaces';
import { IPaymentMethodCustomResponseImt } from 'common/interfaces/invoices';
// custom components
import { FieldInfo } from 'common/components';
import { ReactComponent as BarcodeIcon } from 'img/icons/barcode.svg';
// utils
import { formatPrice } from 'common/utils';
// messages
import inputLabels from 'common/messages/inputLabels';
import invoiceOperatingMessages from 'common/components/InvoiceOperating/messages';

interface IBarcodeCardProps {
  barcodePaymentMethod: IPaymentMethodCustomResponseImt;

  onClose: () => void;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  card: {
    borderRadius: theme.shape.borderRadius,
    border: `${theme.spacing(0.125)}px solid ${alpha(theme.palette.secondary.main, 0.2)}`,
    width: '100%',
    padding: theme.spacing(1.5),
    gap: theme.spacing(1),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  closeCross: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
}));

const BarcodeCard: React.FC<IBarcodeCardProps> = (props: IBarcodeCardProps): JSX.Element => {
  const classes = useStyles(props);
  const { barcodePaymentMethod, onClose } = props;

  // renders

  return (
    <Grid container spacing={0} className={classes.card}>
      <Grid item xs={12} className={classes.header}>
        <Typography variant="h4">{barcodePaymentMethod.get('name')}</Typography>
        <IconButton size="small" onClick={onClose}>
          <Cancel />
        </IconButton>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body1" color="textSecondary">
          <BarcodeIcon />
          &nbsp;{barcodePaymentMethod.get('barcode')}
        </Typography>
      </Grid>

      <Grid item md={6} xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FieldInfo
              label={<FormattedMessage {...inputLabels.amount} />}
              description={formatPrice(barcodePaymentMethod.get('amount'))}
            />
          </Grid>

          <Grid item xs={4}>
            <FieldInfo
              label={<FormattedMessage {...inputLabels.left} />}
              description={formatPrice(
                barcodePaymentMethod.get('leftAmount') ?? barcodePaymentMethod.get('amount'),
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <FieldInfo
              label={<FormattedMessage {...invoiceOperatingMessages.maxTotalInvoice} />}
              description={`${barcodePaymentMethod.getIn([
                'paymentMethod',
                'invoiceAmountPercentage',
              ])}%`}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BarcodeCard;
