export enum HeaderId {
  HeaderBtnClubLogo = 'headerBtnClubLogo',
  HeaderBtnClub = 'headerBtnClub',
  HeaderActionBtnProfile = 'headerActionBtnProfile',
  HeaderActionBtnSignOut = 'headerActionBtnSignOut',
}

export enum SidebarId {
  SidebarBtnFrontDesk = 'sidebarBtnFrontDesk',
  SidebarBtnPos = 'sidebarBtnPos',
  SidebarBtnKidZone = 'sidebarBtnKidZone',
  SidebarBtnBooking = 'sidebarBtnBooking',
  SidebarBtnCrm = 'sidebarBtnCrm',
  SidebarBtnPTCRM = 'sidebarBtnPersonalTrainingCRM',
  SidebarBtnMembers = 'sidebarBtnMembers',
  SidebarBtnEmployees = 'sidebarBtnEmployees',
  SidebarBtnTimeclock = 'sidebarBtnTimeclock',
  SidebarBtnDaySheet = 'sidebarBtnDaySheet',
  SidebarBtnReports = 'sidebarBtnReports',
  SidebarBtnClubSettings = 'sidebarBtnClubSettings',
  SidebarBtnServices = 'sidebarBtnServices',
  SidebarBtnPosSettings = 'sidebarBtnPosSettings',
  SidebarBtnDictionaries = 'sidebarBtnDictionaries',
  SidebarBtnPermissions = 'sidebarBtnPermissions',
  SidebarBtnClubs = 'sidebarBtnClubs',
  SidebarBtnCorporateSettings = 'sidebarBtnCorporateSettings',
  SidebarBtnCommunication = 'sidebarBtnCommunication',
  SidebarBtnPeakEmployees = 'sidebarBtnPeakEmployees',
  SidebarBtnCorporations = 'sidebarBtnCorporations',
  SidebarBtnSettings = 'sidebarBtnSettings',
}

export enum FrontDeskId {
  FrontDeskBtnPos = 'frontDeskBtnPos',
  FrontDeskBtnLock = 'frontDeskBtnLock',
  FrontDeskBtnSettings = 'frontDeskBtnSettings',
}

export enum ProfileActionId {
  ProfileActionBtnEdit = 'profileActionBtnEdit',
  ProfileActionBtnView = 'profileActionBtnView',
}
