import { regExp } from 'common/constants';
import { requiredMessage } from 'common/constants/globalConstants';
import { EmployeePayTypes } from 'common/constants/employee';
import { PermissionLevels } from 'common/constants/permissions';
import * as yup from 'yup';

export const genderSchema = yup.string().oneOf(['MALE', 'FEMALE', 'OTHER'], requiredMessage);

export const nameSchema = yup
  .string()
  .nullable()
  .max(32, 'Value must be 32 characters or less');

export const literalNameSchema = nameSchema.matches(regExp.NAME, 'Value must contain only letters');

export const maritalStatusSchema = yup.string().oneOf(['SINGLE', 'MARRIED', 'WIDOWED', 'DIVORCED']);

export const exemptionsNumberSchema = yup
  .number()
  .transform(v => (v || v === 0 ? v : -1))
  .min(0, 'Value must be greater than or equal to 0')
  .max(99, 'Value must be less than 99');

export const idSchema = yup
  .string()
  .nullable()
  .max(50, 'Value must be 50 characters or less');

export const jobPositionBaseSchema = {
  jobTitle: yup
    .string()
    .trim()
    .required(requiredMessage),
  salary: yup
    .number()
    .typeError(requiredMessage)
    .min(0, 'Salary should be greater than 0'),
  payType: yup
    .string()
    .oneOf(Object.values(EmployeePayTypes))
    .required(requiredMessage),
};

export const employeeJobPositionsSchema = yup
  .array()
  .of(
    yup.object().shape({
      ...jobPositionBaseSchema,
      club: yup
        .object({
          title: yup.string().required(requiredMessage),
        })
        .nullable()
        .required(requiredMessage),
      department: yup
        .object({
          title: yup.string().required(requiredMessage),
        })
        .nullable()
        .required(requiredMessage),
    }),
  )
  .min(0);

export const peakEmployeeJobPositionsSchema = yup
  .array()
  .of(yup.object().shape(jobPositionBaseSchema))
  .min(0);

export const EmployeeRolesSchema = yup
  .array()
  .of(
    yup.object().shape({
      securityRole: yup
        .object()
        .nullable()
        .required(requiredMessage),
      clubList: yup.array().when(['securityRole'], {
        is: securityRole => securityRole?.level === PermissionLevels.CLIENT,
        then: yup
          .array()
          .nullable()
          .min(1, requiredMessage),
      }),
    }),
  )
  .min(0);
