/* eslint-disable react/no-array-index-key */
import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { CustomTheme } from '../../ui/interfaces';
import { StepProgress } from 'common/components';

interface IStepperProps {
  steps: { title: string | JSX.Element }[];
  currentStep: number;

  className?: any;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  stepDescription: {
    paddingTop: theme.spacing(1.5),
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  stepNumberBox: {
    marginRight: theme.spacing(1),
    width: '20px',
    height: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
  },
  stepNumber: {
    fontWeight: 700,
    color: 'white',
  },
}));

const Stepper: React.FunctionComponent<IStepperProps> = (props: IStepperProps): JSX.Element => {
  const classes = useStyles(props);
  const { steps, currentStep, className } = props;

  const currentStepTitle = steps[currentStep].title;

  return (
    <Box className={cx(classes.root, className)}>
      <StepProgress done={currentStep + 1} total={steps.length} size="medium" />
      <Box className={classes.stepDescription}>
        {/* <StepLabel StepIconProps={{ active: true }} icon={currentStep + 1}> */}
        <Box className={classes.stepNumberBox}>
          <Typography className={classes.stepNumber}>{currentStep + 1}</Typography>
        </Box>
        <Typography variant="h4">{currentStepTitle}</Typography>
        {/* </StepLabel> */}
      </Box>
    </Box>
  );
};

export default React.memo(Stepper);
