import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { actionTypes } from 'modules/clubs/constants/constants';
import { initialState } from 'common/constants/initialState';

const initStateClubsList = fromJS({
  list: [],
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
  isLoading: false,
});

function clubsListReducer(state = initStateClubsList, action) {
  switch (action.type) {
    case actionTypes.FETCH_CLUBS_LIST_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_CLUBS_LIST_SUCCESS:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta))
        .set('isLoading', false);
    case actionTypes.RESET_CLUBS_LIST:
      return initStateClubsList;
    default:
      return state;
  }
}

const initStateClubCreateForm = fromJS(initialState);

export const createClubReducer = (state = initStateClubCreateForm, action) => {
  switch (action.type) {
    case actionTypes.RESET_CREATED_CLUB:
      return initStateClubCreateForm;
    case actionTypes.CREATE_CLUB_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.CREATE_CLUB_SUCCESS:
      return state.set('result', fromJS(action.payload));
    default:
      return state;
  }
};

const initStateClubInfo = fromJS({
  clubGeneralInfo: {},
  clubGeneralInfoUpdateActionResult: null,
  isClubGeneralInfoLoading: false,

  clubGeneralInfoView: {},
  isClubGeneralInfoViewLoading: false,

  clubStepInfo: {},
  isClubStepInfoLoading: false,

  clubBankInfoView: {},
  isClubBankInfoViewLoading: false,

  updateClubStepInfo: initialState,

  changeClubStatus: initialState,
  upperLevelProcessingFee: [],

  clubFeeRates: {},
});

const clubInfoReducer = (state = initStateClubInfo, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CLUB_GENERAL_INFO_LOADING:
      return state.set('isClubGeneralInfoLoading', action.payload);
    case actionTypes.FETCH_CLUB_GENERAL_INFO:
      return state.set('clubGeneralInfo', fromJS(action.payload));
    case actionTypes.RESET_CLUB_GENERAL_INFO:
      return state.set('clubGeneralInfo', fromJS({}));

    case actionTypes.FETCH_CLUB_GENERAL_INFO_VIEW_LOADING:
      return state.set('isClubGeneralInfoViewLoading', action.payload);
    case actionTypes.FETCH_CLUB_GENERAL_INFO_VIEW:
      return state.set('clubGeneralInfoView', fromJS(action.payload));
    case actionTypes.RESET_CLUB_GENERAL_INFO_VIEW:
      return state.set('clubGeneralInfoView', fromJS({}));

    case actionTypes.FETCH_CLUB_STEP_INFO_LOADING:
      return state.set('isClubStepInfoLoading', action.payload);
    case actionTypes.FETCH_CLUB_STEP_INFO:
      return state.set('clubStepInfo', fromJS(action.payload || {}));
    case actionTypes.RESET_CLUB_STEP_INFO:
      return state.set('clubStepInfo', fromJS({}));

    case actionTypes.FETCH_CLUB_BANK_INFO_VIEW_LOADING:
      return state.set('isClubBankInfoViewLoading', action.payload);
    case actionTypes.FETCH_CLUB_BANK_INFO_VIEW:
      return state.set('clubBankInfoView', fromJS(action.payload || {}));
    case actionTypes.RESET_CLUB_BANK_INFO_VIEW:
      return state.set('clubBankInfoView', fromJS({}));

    case actionTypes.UPDATE_CLUB_STEP_INFO_LOADING:
      return state.setIn(['updateClubStepInfo', 'isLoading'], action.payload);
    case actionTypes.UPDATE_CLUB_STEP_INFO:
      return state.setIn(['updateClubStepInfo', 'result'], fromJS(action.payload));
    case actionTypes.UPDATE_CLUB_BANK_INFO_STEP:
      return state
        .setIn(['updateClubStepInfo', 'result'], fromJS(action.payload))
        .update('clubStepInfo', stepInfo => fromJS({ ...stepInfo.toJS(), ...action.payload }));
    case actionTypes.UPDATE_CLUB_GENERAL_STEP_INFO:
      return state
        .set('clubGeneralInfo', fromJS(action.payload))
        .setIn(['updateClubStepInfo', 'result'], fromJS(action.payload));
    case actionTypes.UPDATE_CLUB_GENERAL_INFO_ACTION_RESULT:
      return state.set('clubGeneralInfoUpdateActionResult', action.payload);
    case actionTypes.RESET_UPDATED_CLUB_STEP_INFO_RESULT:
      return state.setIn(['updateClubStepInfo', 'result'], fromJS({}));

    case actionTypes.CHANGE_CLUB_STATUS_LOADING:
      return state.setIn(['changeClubStatus', 'isLoading'], action.payload);
    case actionTypes.CHANGE_CLUB_STATUS:
      return state
        .setIn(['changeClubStatus', 'result'], fromJS(action.payload))
        .update('clubGeneralInfo', generalInfo => generalInfo.set('active', action.payload.active));
    case actionTypes.RESET_CLUB_CHANGE_STATUS_RESULT:
      return state.setIn(['changeClubStatus', 'result'], fromJS({}));

    case actionTypes.FETCH_CLUB_FEE_RATES_INFO:
      return state.setIn(['clubFeeRates', action.payload.type, 'data'], action.payload.data);

    case actionTypes.FETCH_CLUB_FEE_RATES_LOADING:
      return state.setIn(['clubFeeRates', action.payload.type, 'isLoading'], action.payload.value);

    case actionTypes.FETCH_CORPORATION_LEVEL_FEE_RATES:
      return state.setIn(
        ['clubFeeRates', action.payload.type, 'peakFeeRates'],
        action.payload.data,
      );
    case actionTypes.UPDATE_CLUB_FEE_RATES_STEP:
      return action.payload.type
        ? state
            .setIn(['clubFeeRates', action.payload.type, 'data'], action.payload.data)
            .setIn(['updateClubStepInfo', 'result'], fromJS(action.payload))
        : state.setIn(['updateClubStepInfo', 'result'], fromJS(action.payload));

    default:
      return state;
  }
};

export default combineReducers({
  createdClub: createClubReducer,
  clubsList: clubsListReducer,
  clubStepInfo: clubInfoReducer,
});
