import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { actionTypes } from '../constants';
import profileReducer from './profile/reducers';

const initStateRoles = fromJS({
  list: [],
  listLoading: false,
});

function rolesReducer(state = initStateRoles, action) {
  switch (action.type) {
    case actionTypes.FETCH_ROLES_LOADING:
      return state.set('listLoading', action.payload);
    case actionTypes.FETCH_ROLES:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta))
        .set('listLoading', false);
    default:
      return state;
  }
}

const initStateEmployees = fromJS({
  list: [],
  meta: {},
  listLoading: false,
  employeeChangeStatusLoading: false,
  employeeChangeStatusResult: {},
});

function employeesReducer(state = initStateEmployees, action) {
  switch (action.type) {
    case actionTypes.FETCH_EMPLOYEES_LOADING:
      return state.set('listLoading', action.payload);
    case actionTypes.FETCH_EMPLOYEES:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta));
    case actionTypes.EMPLOYEE_CHANGE_STATUS_LOADING:
      return state.set('employeeChangeStatusLoading', action.payload);
    case actionTypes.EMPLOYEE_CHANGE_STATUS:
      return state.set('employeeChangeStatusResult', fromJS(action.payload));
    case actionTypes.RESET_EMPLOYEE_CHANGE_STATUS_RESULT:
      return state.set('employeeChangeStatusResult', fromJS({}));
    case actionTypes.RESET_EMPLOYEES_LIST:
      return initStateEmployees;

    default:
      return state;
  }
}

const initStateEmployeeCreateForm = fromJS({
  createdEmployee: null,
  employeeCreating: false,
});

function createEmployeeReducer(state = initStateEmployeeCreateForm, action) {
  switch (action.type) {
    case actionTypes.RESET_EMPLOYEE_FORM:
      return initStateEmployeeCreateForm;
    case actionTypes.CREATE_EMPLOYEE_LOADING:
      return state.set('employeeCreating', action.payload);
    case actionTypes.CREATE_EMPLOYEE:
      return state.set('createdEmployee', fromJS(action.payload)).set('employeeCreating', false);
    default:
      return state;
  }
}

const initStateEmployeeUpdateForm = fromJS({
  employee: {},
  employeeLoading: false,
  updatedEmployee: null,
  employeeUpdating: false,
  updateEmployeeError: null,
});

function updateEmployeeReducer(state = initStateEmployeeUpdateForm, action) {
  switch (action.type) {
    case actionTypes.RESET_EMPLOYEE_FORM:
      return initStateEmployeeUpdateForm;
    case actionTypes.FETCH_EMPLOYEE_LOADING:
      return state.set('employeeLoading', action.payload);
    case actionTypes.FETCH_EMPLOYEE:
      return state.set('employee', fromJS(action.payload)).set('employeeLoading', false);
    case actionTypes.UPDATE_EMPLOYEE_LOADING:
      return state.set('employeeUpdating', action.payload);
    case actionTypes.UPDATE_EMPLOYEE:
      return state.set('updatedEmployee', fromJS(action.payload)).set('employeeUpdating', false);
    case actionTypes.UPDATE_EMPLOYEE_ERROR:
      return state.set('updateEmployeeError', action.payload);
    default:
      return state;
  }
}

export default combineReducers({
  roles: rolesReducer,
  employeeProfile: profileReducer,
  employees: employeesReducer,
  createEmployeeForm: createEmployeeReducer,
  updateEmployeeForm: updateEmployeeReducer,
});
