import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as EditIcon } from 'img/icons/edit.svg';
import { ReactComponent as ChangeIcon } from 'img/icons/change.svg';
import leadsMessages from 'modules/crm/messages/leads';
import ptCrmMessages from 'modules/personal-training-crm/messages/messages';
import { PeakModules } from 'common/constants/peakModules';
import { ActionItem, ActionsMenu } from 'common/components';
import useLeadPermissions from 'modules/crm/hooks/useLeadPermissions';

const useStyles = makeStyles({
  icon: {
    width: 16,
    height: 16,
  },
});

interface IProps {
  leadId: string;
  module: PeakModules.Crm | PeakModules.PersonalTrainingCrm;
  onChangeSalesperson: (leadId: string) => void;
  onChangeStatus: (leadId: string) => void;
}

const LeadsActionsMenu: FC<IProps> = ({ leadId, module, onChangeSalesperson, onChangeStatus }) => {
  const classes = useStyles();
  const isCrmModule = module === PeakModules.Crm;
  const { hasEditSalespersonPermission, hasEditStatusPermission } = useLeadPermissions(module);

  const handleChangeStatusClick = () => {
    onChangeStatus(leadId);
  };

  const handleChangeSalespersonClick = () => {
    onChangeSalesperson(leadId);
  };

  if (!hasEditSalespersonPermission && !hasEditStatusPermission) {
    return null;
  }

  return (
    <ActionsMenu horizontal tableActionsMode>
      {hasEditStatusPermission ? (
        <ActionItem
          key="1"
          icon={<ChangeIcon className={classes.icon} />}
          onClick={handleChangeStatusClick}
        >
          <FormattedMessage
            {...(isCrmModule
              ? leadsMessages.changeStatusLeadProfileTitle
              : ptCrmMessages.changeStatusPtLeadProfileTitle)}
          />
        </ActionItem>
      ) : null}

      {hasEditSalespersonPermission ? (
        <ActionItem
          key="2"
          icon={<EditIcon className={classes.icon} />}
          onClick={handleChangeSalespersonClick}
        >
          <FormattedMessage {...leadsMessages.changeSalesTitle} />
        </ActionItem>
      ) : null}
    </ActionsMenu>
  );
};

export default LeadsActionsMenu;
