import * as yup from 'yup';

import { FallthroughReasons } from 'common/components/PersonProfile/constants';
import inputErrors from 'common/messages/inputErrors';
import { dateSchema } from 'common/validationSchemas/dateSchemas';
import { getRequiredMessage } from 'common/constants/globalConstants';

export const ValidationSchema = yup.object().shape({
  fallthroughReasonId: yup.string().required(() => inputErrors.requiredFieldError),
  fallthroughReason: yup
    .string()
    .when('fallthroughReasonId', {
      is: value => value === FallthroughReasons.OtherReason,
      then: yup.string().required(() => inputErrors.requiredFieldError),
    })
    .max(1000, () => inputErrors.reasonLengthError),
  createdDate: dateSchema.required(getRequiredMessage),
  createdTime: dateSchema.required(getRequiredMessage),
});
