import React from 'react';

import { PaymentsType } from 'common/interfaces/invoices';

// icons
import { ReactComponent as CardIcon } from 'img/icons/payment-method-card.svg';
import { ReactComponent as CashIcon } from 'img/icons/payment-method-cash.svg';
import { ReactComponent as CheckingSavingIcon } from 'img/icons/payment-method-checkings-savings.svg';
import { ReactComponent as OnAccountIcon } from 'img/icons/payment-method-on-account.svg';
import { ReactComponent as MemberRewardsIcon } from 'img/icons/star.svg';

import { CardGiftcard } from '@material-ui/icons';
import { ConstOptions } from 'common/constants/classes';

import messages from './messages';

export const getPayTypeIcon = (type: PaymentsType): React.FC<React.SVGProps<SVGSVGElement>> => {
  switch (type) {
    case PaymentsType.CASH:
      return CashIcon;
    case PaymentsType.CREDIT_CARD:
      return CardIcon;
    case PaymentsType.CHECKING_SAVINGS:
      return CheckingSavingIcon;
    case PaymentsType.ON_ACCOUNT:
    case PaymentsType.TAB_ON_A_CORPORATE_ACCOUNT:
      return OnAccountIcon;
    case PaymentsType.MEMBER_REWARDS:
      return MemberRewardsIcon;
    case PaymentsType.CUSTOM:
      return CardGiftcard;
    default:
      return CashIcon;
  }
};

export enum PMethodSteps {
  SelectMethodStep = 'SelectMethodStep',
  CashPayment = 'CashPayment',
  CreditCardPayment = 'CreditCardPayment',
  OtherTypePayment = 'OtherTypePayment',
  CheckingSavings = 'CheckingSavings',
  OnAccount = 'OnAccount',
  ClientRewards = 'ClientRewards',
}

export enum InvoicePaymentSplitStatus {
  PAID = 'PAID',
  FAILURE = 'FAILURE',
  REFUNDED = 'REFUNDED',
  ACH_PREPARED = 'ACH_PREPARED',
}

export const AS_PAID_INVOICE_PAYMENT_SPLIT_STATUS: InvoicePaymentSplitStatus[] = [
  InvoicePaymentSplitStatus.PAID,
  InvoicePaymentSplitStatus.ACH_PREPARED,
];

export enum PaymentOptionType {
  PaymentTerminal = 'PAYMENT_TERMINAL',
  MagneticStripe = 'MAGNETIC_STRIPE',
  StoredCard = 'STORED_CARD',
  ImmediateCreditCardPayment = 'IMMEDIATE_CREDIT_CARD_PAYMENT',
}

export const PaymentOptionTypes = new ConstOptions([
  {
    key: PaymentOptionType.PaymentTerminal,
    value: PaymentOptionType.PaymentTerminal,
    message: messages.paymentOptionTypeViaTerminal,
  },
  {
    key: PaymentOptionType.MagneticStripe,
    value: PaymentOptionType.MagneticStripe,
    message: messages.paymentOptionTypeViaMagneticStripe,
  },
  {
    key: PaymentOptionType.StoredCard,
    value: PaymentOptionType.StoredCard,
    message: messages.paymentOptionTypeViaStoreCard,
  },
  {
    key: PaymentOptionType.ImmediateCreditCardPayment,
    value: PaymentOptionType.ImmediateCreditCardPayment,
    message: messages.paidOptionTypeViaImmediateCreditCard,
  },
]);

export const PMethodStepsTitles = new ConstOptions([
  {
    key: PMethodSteps.SelectMethodStep,
    value: PMethodSteps.SelectMethodStep,
    message: messages.selectPaymentMethodStepTitle,
  },
  {
    key: PMethodSteps.CashPayment,
    value: PMethodSteps.CashPayment,
    message: messages.cashPaymentStepTitle,
  },
  {
    key: PMethodSteps.CreditCardPayment,
    value: PMethodSteps.CreditCardPayment,
    message: messages.creditCardPaymentStepTitle,
  },
  {
    key: PMethodSteps.OtherTypePayment,
    value: PMethodSteps.OtherTypePayment,
    message: messages.otherTypePaymentStepTitle,
  },
  {
    key: PMethodSteps.CheckingSavings,
    value: PMethodSteps.CheckingSavings,
    message: messages.otherTypePaymentStepTitle,
  },
]);
