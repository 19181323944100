import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import commonMessages from 'common/messages/messages';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 0),
    lineHeight: 'normal',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
      padding: theme.spacing(0.625, 1.625),
      '& .MuiSvgIcon-root': {
        fontSize: 14,
      },
    },
  },
  smallButton: {
    fontSize: '12px',
    padding: theme.spacing(0.625, 1.625),
  },
  smallIcon: {
    fontSize: '14px !important',
  },
}));

interface IProps {
  disabled?: boolean;
  small?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ApplyFilterButton = ({ disabled, small, onClick }: IProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Button
      disableRipple
      aria-label="reset"
      variant="contained"
      color="primary"
      className={classnames(classes.root, {
        [classes.smallButton]: small,
      })}
      disabled={disabled}
      onClick={onClick}
    >
      <FormattedMessage {...commonMessages.applyBtn} />
    </Button>
  );
};

export default React.memo(ApplyFilterButton);
