import React from 'react';
import { Box, ListItem, makeStyles, Typography } from '@material-ui/core';

import { TooltipTypography } from 'common/components';
import { CustomTheme } from 'common/ui/interfaces';
import { ITicketShortDtoImt } from 'common/components/PersonProfile/interfaces';
import { ticketStatuses } from 'common/components/PersonProfile/constants';

const useStyles = makeStyles((theme: CustomTheme) => ({
  listItem: {
    display: 'block',
    cursor: 'pointer',

    // hovering styles
    width: `calc(100% + ${theme.spacing(1.5)}px)`,
    margin: theme.spacing(0, -0.75),
    '& > *': {
      padding: theme.spacing(0, 0.75),
    },
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  ticketTitle: {
    marginBottom: theme.spacing(0.5),
    fontWeight: 700,
  },
  ticketNumberTitle: {
    maxWidth: '65%',
  },
}));

interface ITicketListItemProps {
  handleOnClick: (id: number) => void;
  ticket: ITicketShortDtoImt;
}

const TicketListItem = (props: ITicketListItemProps): JSX.Element => {
  const { handleOnClick, ticket } = props;

  const classes = useStyles();

  const ticketId = ticket.get('id');

  const ticketStatus = ticketStatuses.find(ticket.get('status'));

  return (
    <ListItem disableGutters className={classes.listItem} onClick={() => handleOnClick(ticketId)}>
      <TooltipTypography ellipsized className={classes.ticketTitle}>
        {ticket.get('subject') || '-'}
      </TooltipTypography>

      <Box display="flex" justifyContent="space-between" width="100%">
        <TooltipTypography ellipsized className={classes.ticketNumberTitle} color="textSecondary">
          #{ticketId}
        </TooltipTypography>
        <Typography style={{ color: ticketStatus?.color }}>
          {ticketStatuses.translate(ticket.get('status'))}
        </Typography>
      </Box>
    </ListItem>
  );
};

export default TicketListItem;
