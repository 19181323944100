import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';

import { IPosKioskReconcileDTO } from 'modules/pos-kiosk/interfaces/registers';

import { StepContext } from 'common/createContext/stepContext';
import { ReconcileSteps } from 'modules/pos-kiosk/constants/registers';
import { PeakModules } from 'common/constants/peakModules';

import ReconcileBillsStep from './Steps/ReconcileBillsStep/ReconcileBillsStep';
import ReconcileChecksStep from './Steps/ReconcileChecksStep/ReconcileChecksStep';
import ReconcileTotalsStep from './Steps/ReconcileTotalsStep/ReconcileTotalsStep';
import Footer from './Footer';
import { DialogComponent } from 'common/components';

import messages from 'modules/pos-kiosk/messages/messages';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-paper': {
      overflowY: 'hidden',
      paddingBottom: theme.spacing(9),
      position: 'relative',
      '& .MuiDialogActions-root': {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        paddingRight: theme.spacing(2),
      },
    },
  },
}));

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: Partial<IPosKioskReconcileDTO>) => void;
  module: PeakModules;
  isReconcileActionLoading: boolean;
  registerId: string;
}

const steps: ReconcileSteps[] = Object.values(ReconcileSteps);

const { Provider: StepProvider } = StepContext;

const ReconcileModal = ({
  isOpen,
  onClose,
  onSubmit,
  module,
  isReconcileActionLoading,
  registerId,
}: IProps): JSX.Element => {
  const [currentStep, setCurrentStep] = useState(ReconcileSteps.ReconcileBills);
  const [reconcileData, setReconcileData] = useState<Partial<IPosKioskReconcileDTO>>({});

  const currentStepIndex: number = steps.indexOf(currentStep);
  const nextStep: ReconcileSteps =
    currentStepIndex + 1 < steps.length ? steps[currentStepIndex + 1] : null;
  const prevStep: ReconcileSteps = currentStepIndex - 1 < 0 ? null : steps[currentStepIndex - 1];

  const classes = useStyles();

  const goNextStep = useCallback(
    (data: IPosKioskReconcileDTO) => {
      if (currentStep === ReconcileSteps.ReconcileTotals) {
        onSubmit({ ...reconcileData, ...data });
      } else {
        setReconcileData(prevState => ({
          ...prevState,
          ...data,
          cashDesk: { ...prevState?.cashDesk, ...data.cashDesk },
        }));
        setCurrentStep(nextStep);
      }
    },
    [currentStep, nextStep, onSubmit, reconcileData],
  );

  const goPrevStep = useCallback(() => {
    if (prevStep) {
      setCurrentStep(prevStep);
    } else {
      onClose();
    }
  }, [onClose, prevStep]);

  const renderFooter = useCallback(
    (onBack, onNext) => {
      return (
        <Footer
          currentStep={currentStep}
          onBack={onBack}
          onNext={onNext}
          goBack={onClose}
          isLoading={isReconcileActionLoading}
        />
      );
    },
    [currentStep, isReconcileActionLoading, onClose],
  );

  return (
    <DialogComponent
      size="xs"
      title={<FormattedMessage {...messages.reconcileSales} />}
      footer={<></>}
      isOpen={isOpen}
      onClose={onClose}
      className={classes.root}
      supressBottomShadow
    >
      <StepProvider value={{ onNext: goNextStep, onBack: goPrevStep, renderFooter }}>
        {currentStep === ReconcileSteps.ReconcileBills && (
          <ReconcileBillsStep registerId={registerId} reconcileData={reconcileData} />
        )}

        {currentStep === ReconcileSteps.ReconcileChecks && <ReconcileChecksStep />}

        {currentStep === ReconcileSteps.ReconcileTotals && (
          <ReconcileTotalsStep actual={reconcileData} module={module} registerId={registerId} />
        )}
      </StepProvider>
    </DialogComponent>
  );
};

export default ReconcileModal;
