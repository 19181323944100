import { defineMessages } from 'react-intl';

export default defineMessages({
  membersTitle: {
    id: 'app.routes.Members.membersTitle',
    defaultMessage: 'Members',
  },
  newMemberTitle: {
    id: 'app.routes.NewCampaign.newMemberTitle',
    defaultMessage: 'New Member',
  },
  memberStatusTypeEmpty: {
    id: 'app.modules.crm.leads.memberStatus.empty',
    defaultMessage: 'Empty',
  },
});
