/* eslint @typescript-eslint/no-unused-vars: 0 */
import React, { Component, createRef } from 'react';
import { List } from 'immutable';
import {
  Box,
  Card,
  createStyles,
  Grid,
  IconButton,
  StyledComponentProps,
  SvgIcon,
  Theme,
  withStyles,
} from '@material-ui/core';
import { ResizeObserver } from 'resize-observer';
import cx from 'classnames';

// state
import * as memberActions from './state/actions';
import * as frontDeskActions from 'modules/front-desk/state/actions';
import { fetchDictionaryList } from 'common/state/dictionary/actions';
import { selectActionUpdateResult } from 'common/state/newPerson/primaryInfo/selectors';
// constants
import { CustomBrakePoints } from './constants';
import { ActionResult, DictionaryList } from 'common/constants';
import { PeakModules } from 'common/constants/peakModules';
import { TagType } from 'modules/dictionaries/constants/constants';
// interfaces
import { DispatchFunction } from 'common/state/interfaces';
import { IAdditionalFieldsImt, IBlocksSectionProps, IProfileInfoImt, ITag } from './interfaces';
import { IAttachmentImt, IStoredFileSimpleDto } from 'common/interfaces/uploadFile';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { IProfileDataProps } from './components/GeneralInfoSection/ProfileGeneralInfo/ProfileGeneralInfo';
import { IKidProfileDataProps } from 'modules/kid-zone/components/KidProfileGeneralInfo/KidProfileGeneralInfo';
// components
import { Connect } from 'common/decorators';
import { getInitials } from 'helpers/common';
import { ReactComponent as CloseIcon } from 'img/closeIcon.svg';
import { UploadImageModal, WebcamImageModal } from 'common/modals';
import { AvatarCard, LoadingBackdrop, ScrollBox } from 'common/components';
import SalespersonInfo from './components/GeneralInfoSection/SalespersonInfo/SalespersonInfo';
import DeleteImageModal from 'common/modals/DeleteImageModal/DeleteImageModal';
import { selectCheckInActionLoading } from 'modules/front-desk/state/checkins/selectors';
import { convertAttachment } from 'common/utils';
import { IAlertsSectionProps } from './components/MemberAlertsSection/MemberAlertsSection';

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      height: '100%',
      position: 'relative',
      borderRadius: '3px',
      overflow: 'hidden',
    },
    root: {
      padding: theme.spacing(2, 2, 2.5, 2),
      boxSizing: 'border-box',
      '&.hasCloseIcon': {
        padding: theme.spacing(4.25, 2, 2.5, 2),
        borderWidth: '0 !important',
      },
    },
    boxWithMobilePadding: {
      [theme.breakpoints.down('md')]: {
        paddingBottom: theme.spacing(13),
      },
    },
    item: {
      minWidth: '212px',
    },
    main: {
      height: '100%',
      flex: 1,
      minWidth: 0,
      paddingLeft: theme.spacing(3.25),
      '&.medium': {
        paddingLeft: theme.spacing(2),
      },
      '&.small': {
        paddingLeft: 0,
        paddingTop: theme.spacing(3.25),
      },
    },
    profileWrapper: {
      '&:nth-child(n+2)': {
        // marginTop: theme.spacing(3),
      },
    },
    profileAvatar: {
      maxWidth: '100%',
      margin: '0 auto',
      '&.profileAvatar_mediumScreen': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '102px',
      },
      '&.profileAvatar_smallScreen': {
        width: '302px',
      },
    },
    profileDataWrapper: {
      width: '100%',
    },
    scrollable: {
      maxHeight: `calc(359px + ${theme.spacing(2)}px)`,
    },
    closeCross: {
      position: 'absolute',
      padding: '5px',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.text.secondary,
      opacity: 0.5,
      '& svg': {
        fontSize: '0.875rem',
      },
    },
  });

export interface IPersonProfileProps extends StyledComponentProps {
  module?: PeakModuleForNewPersonType;
  clientId: string;
  personId: string;
  additionalFields?: IAdditionalFieldsImt;
  closable?: boolean;

  profile?: IProfileInfoImt;
  profileLoading?: boolean;
  ServicesSection?: any;
  BlocksSection?: React.FC<IBlocksSectionProps<IProfileInfoImt>>;
  AdditionalInfoSection?: React.FC<IBlocksSectionProps<IProfileInfoImt>>;
  ProfileDataSection?: React.FC<IProfileDataProps | IKidProfileDataProps>;
  AlertsSection?: React.FC<IAlertsSectionProps>;

  handleMemberCheckIn?: () => void;

  fetchClientMemberTags?: () => void;
  fetchPersonSalesInfo?: (personId: string, module: PeakModuleForNewPersonType) => void;
  fetchPersonDocuments?: (personId: string, module: PeakModuleForNewPersonType) => void;
  updateProfileState?: ActionResult;
  checkInActionLoading?: boolean;
  resetManualCheckinProfile?: () => void;
  onClose?: () => void;

  updatePersonTags?: (
    personId: string,
    tags: Array<ITag>,
    module: PeakModuleForNewPersonType,
  ) => void;
  createClientMemberTag?: (tag: ITag) => void;
  updatePersonPhoto?: (
    clientId: string,
    image: IStoredFileSimpleDto,
    module: PeakModuleForNewPersonType,
  ) => void;
  deletePersonPhoto?: (clientId: string, module: PeakModuleForNewPersonType) => void;

  openPOSPanel?: () => void;
  isOpenPOSPanel?: boolean;
  openEditProfile?: (id: string) => void;
  onStatusChange?: (status: string) => void;
  openChangePackagePlan?: (id: string) => void;

  // TODO: It's necessary to remove
  hideTags?: boolean;
  hideAvatarActions?: boolean;
  hideProfileMenu?: boolean;
}

export interface IPersonProfileState {
  isOpenUploadModal: boolean;
  isOpenWebcamModal: boolean;
  isOpenDeletePhotoModal: boolean;
  checkInBlockSize: number;
}

@(withStyles(styles) as any)
@(Connect({
  mapDispatchToProps: (dispatch: DispatchFunction) => ({
    updatePersonTags: (personId: string, tags: List<ITag>, module: PeakModuleForNewPersonType) => {
      dispatch(memberActions.updatePersonTags(personId, tags, module));
    },

    fetchClientMemberTags: () =>
      dispatch(
        fetchDictionaryList(DictionaryList.FRONTDESK_PROFILES, {
          tagType: TagType.FRONTDESK_PROFILE,
        }),
      ),

    createClientMemberTag: (tag: ITag) => dispatch(frontDeskActions.createClientMemberTag(tag)),
    fetchPersonSalesInfo: (personId: string, module: PeakModuleForNewPersonType) =>
      dispatch(memberActions.fetchPersonSalesInfo(personId, module)),
    fetchPersonDocuments: (personId: string, module: PeakModuleForNewPersonType) =>
      dispatch(memberActions.fetchPersonDocuments(personId, module)),
  }),
  mapStateToProps: () => ({
    updateProfileState: selectActionUpdateResult,
    checkInActionLoading: selectCheckInActionLoading,
  }),
}) as any)
class PersonProfile extends Component<IPersonProfileProps, IPersonProfileState> {
  private resizeElement = createRef<HTMLDivElement>();

  private resizeObserver = null;

  constructor(props: IPersonProfileProps) {
    super(props);

    this.state = {
      isOpenUploadModal: false,
      isOpenWebcamModal: false,
      checkInBlockSize: null,
      isOpenDeletePhotoModal: false,
    };

    const { fetchClientMemberTags, hideTags } = this.props;

    if (!hideTags) {
      // TODO: Need to move to componentDidMount
      fetchClientMemberTags();
    }
  }

  // getters
  protected get hasErrorAlert(): boolean {
    const { profile } = this.props;

    // TODO add alerts integration if it will be necessary
    return false;
  }

  protected get hasWarningAlert(): boolean {
    const { profile } = this.props;

    // TODO add alerts integration if it will be necessary
    return false;
  }

  protected get hasCelebrationAlert(): boolean {
    const { profile } = this.props;

    // TODO add alerts integration if it will be necessary
    return false;
  }

  protected get itemBorder(): string {
    if (this.hasErrorAlert) {
      return 'error.main';
    }

    if (this.hasWarningAlert) {
      return 'warning.main';
    }

    if (this.hasCelebrationAlert) {
      return 'celebration.main';
    }

    return 'primary.main';
  }

  protected get isLessThanDesktop(): boolean {
    const { checkInBlockSize } = this.state;
    return checkInBlockSize ? checkInBlockSize < CustomBrakePoints.desktop : null;
  }

  protected get isLessThanLaptop(): boolean {
    const { checkInBlockSize } = this.state;
    return checkInBlockSize ? checkInBlockSize < CustomBrakePoints.laptop : null;
  }

  protected get isLessThanTablet(): boolean {
    const { checkInBlockSize } = this.state;
    return checkInBlockSize ? checkInBlockSize < CustomBrakePoints.tablet : null;
  }

  protected get isBiggerThanXs(): boolean {
    const { checkInBlockSize } = this.state;
    return checkInBlockSize ? checkInBlockSize > CustomBrakePoints.xs : null;
  }

  protected get isLessThanXs(): boolean {
    const { checkInBlockSize } = this.state;
    return checkInBlockSize ? checkInBlockSize < CustomBrakePoints.xs : null;
  }

  // lifecycle

  componentDidMount(): void {
    const { fetchPersonSalesInfo, personId, module } = this.props;

    if (
      module === PeakModules.Crm ||
      module === PeakModules.Members ||
      module === PeakModules.PersonalTrainingCrm
    ) {
      fetchPersonSalesInfo(personId, module);
    }

    this.resizeObserver = new ResizeObserver(entries => {
      const boxSize = entries[0].contentRect.width;
      this.setState({ checkInBlockSize: boxSize });
    });
    this.resizeObserver.observe(this.resizeElement.current);
  }

  componentDidUpdate(): void {
    const {
      updateProfileState,
      personId,
      module,
      fetchPersonSalesInfo,
      fetchPersonDocuments,
    } = this.props;

    if (updateProfileState === ActionResult.SUCCESS_ACTION) {
      if (
        module === PeakModules.Crm ||
        module === PeakModules.Members ||
        module === PeakModules.PersonalTrainingCrm
      ) {
        fetchPersonSalesInfo(personId, module);
      }

      if (module !== PeakModules.KidZone) {
        fetchPersonDocuments(personId, module);
      }
    }
  }

  componentWillUnmount(): void {
    const { closable, resetManualCheckinProfile } = this.props;

    this.resizeObserver.unobserve(this.resizeElement.current);

    if (closable && resetManualCheckinProfile) {
      resetManualCheckinProfile();
    }
  }

  // handlers

  private onUpdateTags = (tags: Array<ITag>): void => {
    const { personId, updatePersonTags, module } = this.props;
    updatePersonTags(personId, tags, module);
  };

  private onCreateTag = (tag: ITag): void => {
    const { createClientMemberTag } = this.props;
    createClientMemberTag(tag);
  };

  private onChangeAvatarClick = (): void => {
    this.setState({ isOpenUploadModal: true });
  };

  private onDeleteAvatarClick = (): void => {
    this.setState({ isOpenDeletePhotoModal: true });
  };

  private onToggleWebcamModal = () => {
    const { isOpenWebcamModal } = this.state;
    this.setState({ isOpenWebcamModal: !isOpenWebcamModal });
  };

  private handleToggleUploadModal = (): void =>
    this.setState(prevState => ({ isOpenUploadModal: !prevState.isOpenUploadModal }));

  private handleSubmitUpdatePhoto = (uploadedImage: IAttachmentImt): void => {
    const { profile, updatePersonPhoto, module } = this.props;

    // TODO: alert currently not send
    // const alert = profile
    //   .get('alerts')
    //   .find(al => al.get('condition') === AlertConditions.MissingPhoto);
    updatePersonPhoto(profile.get('id'), convertAttachment(uploadedImage.toJS()), module);
    // TODO: add error and loading handlers
    this.setState({ isOpenUploadModal: false });
  };

  private handleSubmitWebcamPhoto = (uploadedImage: IAttachmentImt): void => {
    const { profile, updatePersonPhoto, module } = this.props;

    updatePersonPhoto(profile.get('id'), convertAttachment(uploadedImage.toJS()), module);
    // TODO: add error and loading handlers
    this.setState({ isOpenWebcamModal: false });
  };

  private handleSubmitDeletePhoto = (): void => {
    const { profile, deletePersonPhoto, module } = this.props;
    deletePersonPhoto(profile.get('id'), module);
  };

  private onCloseDeleteModal = (): void => {
    this.setState({ isOpenDeletePhotoModal: false });
  };

  // renders
  private renderCloseButton = (): JSX.Element => {
    const { classes, onClose } = this.props;

    return (
      <IconButton aria-label="close" className={classes.closeCross} onClick={onClose}>
        <SvgIcon>
          <CloseIcon />
        </SvgIcon>
      </IconButton>
    );
  };

  private renderUpdatePhotoModal = (): JSX.Element => {
    const { personId } = this.props;
    const { isOpenUploadModal } = this.state;

    return (
      <UploadImageModal
        id={`upload-person-${personId}-photo-modal`}
        isOpen={isOpenUploadModal}
        onSubmit={this.handleSubmitUpdatePhoto}
        onToggle={this.handleToggleUploadModal}
      />
    );
  };

  private renderWebcamModal = (): JSX.Element => {
    const { personId } = this.props;
    const { isOpenWebcamModal } = this.state;

    return (
      <WebcamImageModal
        id={`webcam-person-${personId}-photo-modal`}
        isOpen={isOpenWebcamModal}
        onToggle={this.onToggleWebcamModal}
        onSubmit={this.handleSubmitWebcamPhoto}
      />
    );
  };

  private renderDeletePhotoModal = (): JSX.Element => {
    const { personId, module } = this.props;
    const { isOpenDeletePhotoModal } = this.state;

    return (
      <DeleteImageModal
        module={module}
        isOpen={isOpenDeletePhotoModal}
        onClose={this.onCloseDeleteModal}
        personId={personId}
        onSubmit={this.handleSubmitDeletePhoto}
      />
    );
  };

  render(): JSX.Element {
    const {
      clientId,
      classes,
      closable,
      profileLoading,
      checkInActionLoading,
      profile,
      ServicesSection,
      BlocksSection,
      additionalFields,
      AdditionalInfoSection,
      ProfileDataSection,
      AlertsSection,
      openEditProfile,
      openPOSPanel,
      onStatusChange,
      handleMemberCheckIn,
      openChangePackagePlan,
      isOpenPOSPanel,
      module,
      hideTags,
      hideProfileMenu,
      hideAvatarActions,
    } = this.props;
    const { isOpenUploadModal, isOpenDeletePhotoModal, isOpenWebcamModal } = this.state;

    return (
      <Box className={classes.paper} {...{ ref: this.resizeElement }}>
        <LoadingBackdrop isLoading={!!profileLoading || checkInActionLoading} />

        {profile && (
          <ScrollBox>
            <Box className={classes.boxWithMobilePadding}>
              <Card>
                <Box
                  className={cx(classes.root, { hasCloseIcon: closable })}
                  borderTop={module === PeakModules.FrontDesk ? 4 : 0}
                  borderColor={this.itemBorder}
                >
                  {closable && this.renderCloseButton()}
                  {AlertsSection && (
                    <AlertsSection
                      openPOSPanel={openPOSPanel}
                      isOpenPOSPanel={isOpenPOSPanel}
                      module={module}
                      profile={profile}
                      small={this.isLessThanTablet}
                      medium={this.isLessThanLaptop}
                      onChangeAvatarModalToggle={this.handleToggleUploadModal}
                      onOpenChangePackagePlan={openChangePackagePlan}
                    />
                  )}
                  <Grid
                    className={classes.profileWrapper}
                    style={{ position: this.isLessThanLaptop ? 'relative' : 'static' }}
                  >
                    <Box
                      display="flex"
                      flexDirection={this.isLessThanTablet ? 'column' : 'row'}
                      alignItems={this.isLessThanTablet ? 'center' : 'flex-start'}
                    >
                      {this.isLessThanTablet && !this.isLessThanXs && (
                        <Grid item className={classes.profileDataWrapper}>
                          <ProfileDataSection
                            hideProfileMenu={hideProfileMenu}
                            hideTags={hideTags}
                            clientId={clientId}
                            profile={profile}
                            additionalFields={additionalFields}
                            isLessThanXs={this.isLessThanXs}
                            isLessThanTablet={this.isLessThanTablet}
                            isLessThanLaptop={this.isLessThanLaptop}
                            onCreateTag={this.onCreateTag}
                            onChangeTags={this.onUpdateTags}
                            onCheckInMember={handleMemberCheckIn}
                            openEditProfile={openEditProfile}
                            onStatusChange={onStatusChange}
                            module={module}
                          />
                        </Grid>
                      )}
                      <Box
                        className={classes.item}
                        style={{ width: this.isLessThanTablet ? '100%' : '212px' }}
                      >
                        <Grid item>
                          <Grid container item spacing={2}>
                            <Grid item style={{ width: '100%', maxWidth: '100%' }}>
                              <Box
                                className={cx(classes.profileAvatar, {
                                  profileAvatar_mediumScreen:
                                    this.isLessThanTablet && !this.isLessThanXs,
                                  profileAvatar_smallScreen: this.isLessThanXs,
                                })}
                              >
                                <AvatarCard
                                  hideAvatarActions={hideAvatarActions}
                                  withPlaceholder
                                  initials={getInitials(
                                    profile.get('firstName'),
                                    profile.get('lastName'),
                                  )}
                                  isLessThanTablet={this.isLessThanTablet}
                                  isLessThanXs={this.isLessThanXs}
                                  avatarUrl={profile?.get('image')?.get('url')}
                                  onToggleUploadModal={this.onChangeAvatarClick}
                                  onToggleWebcamModal={this.onToggleWebcamModal}
                                  onToggleDeleteModal={this.onDeleteAvatarClick}
                                  width="212px"
                                  height="212px"
                                />
                              </Box>
                            </Grid>
                            {this.isLessThanXs && (
                              <Grid item className={classes.profileDataWrapper}>
                                <ProfileDataSection
                                  hideProfileMenu={hideProfileMenu}
                                  hideTags={hideTags}
                                  clientId={clientId}
                                  profile={profile}
                                  additionalFields={additionalFields}
                                  isLessThanXs={this.isLessThanXs}
                                  isLessThanTablet={this.isLessThanTablet}
                                  isLessThanLaptop={this.isLessThanLaptop}
                                  onCreateTag={this.onCreateTag}
                                  onChangeTags={this.onUpdateTags}
                                  onCheckInMember={handleMemberCheckIn}
                                  openEditProfile={openEditProfile}
                                  onStatusChange={onStatusChange}
                                  module={module}
                                />
                              </Grid>
                            )}
                            {!!ServicesSection && (
                              <Grid item xs={12}>
                                <ServicesSection
                                  profile={profile}
                                  isBiggerThanXs={this.isBiggerThanXs}
                                  isLessThanTablet={this.isLessThanTablet}
                                />
                              </Grid>
                            )}
                            {(module === PeakModules.Crm ||
                              module === PeakModules.Members ||
                              module === PeakModules.PersonalTrainingCrm) &&
                              !!this.isLessThanTablet && (
                                <Grid item xs={12}>
                                  <SalespersonInfo
                                    personId={profile.get('id')}
                                    module={module}
                                    primaryInfo={profile}
                                  />
                                </Grid>
                              )}
                          </Grid>
                        </Grid>
                      </Box>

                      <Grid
                        item
                        container
                        className={cx(classes.main, {
                          medium: this.isLessThanLaptop,
                          small: this.isLessThanTablet,
                        })}
                        spacing={2}
                      >
                        {!this.isLessThanTablet && (
                          <>
                            <Grid item className={classes.profileDataWrapper}>
                              <ProfileDataSection
                                hideProfileMenu={hideProfileMenu}
                                hideTags={hideTags}
                                clientId={clientId}
                                additionalFields={additionalFields}
                                profile={profile}
                                isLessThanXs={this.isLessThanXs}
                                isLessThanTablet={this.isLessThanTablet}
                                isLessThanLaptop={this.isLessThanLaptop}
                                onCreateTag={this.onCreateTag}
                                onChangeTags={this.onUpdateTags}
                                onCheckInMember={handleMemberCheckIn}
                                openEditProfile={openEditProfile}
                                onStatusChange={onStatusChange}
                                module={module}
                              />
                            </Grid>

                            {(module === PeakModules.Crm ||
                              module === PeakModules.Members ||
                              module === PeakModules.PersonalTrainingCrm) && (
                              <Grid item>
                                <SalespersonInfo
                                  personId={profile.get('id')}
                                  module={module}
                                  primaryInfo={profile}
                                />
                              </Grid>
                            )}
                          </>
                        )}

                        {AdditionalInfoSection && (
                          <Grid container item spacing={2}>
                            <AdditionalInfoSection
                              profile={profile}
                              isLessThanDesktop={this.isLessThanDesktop}
                              isLessThanLaptop={this.isLessThanLaptop}
                              isLessThanTablet={this.isLessThanTablet}
                              module={module}
                            />
                          </Grid>
                        )}

                        {!!BlocksSection && (
                          <BlocksSection
                            profile={profile}
                            openPOSPanel={openPOSPanel}
                            isOpenPOSPanel={isOpenPOSPanel}
                            isLessThanDesktop={this.isLessThanDesktop}
                            isLessThanLaptop={this.isLessThanLaptop}
                            isLessThanTablet={this.isLessThanTablet}
                            module={module}
                          />
                        )}
                      </Grid>
                    </Box>
                  </Grid>
                </Box>
                {isOpenUploadModal && this.renderUpdatePhotoModal()}
                {isOpenWebcamModal && this.renderWebcamModal()}
                {isOpenDeletePhotoModal && this.renderDeletePhotoModal()}
              </Card>
            </Box>
          </ScrollBox>
        )}
      </Box>
    );
  }
}

export default PersonProfile;
