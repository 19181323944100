import { fromJS, List } from 'immutable';
import { combineReducers } from 'redux-immutable';

import { initialState, initReqStateImt } from 'common/constants/initialState';
import { actionTypes } from 'modules/employees/constants';
import { ActionResult } from 'common/constants';
import { IModificationHistoryImt } from 'common/components/PersonProfile/interfaces';

const profileReducer = (state = initReqStateImt, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PROFILE_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_PROFILE_SUCCESS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.CLEAR_PROFILE:
      return initReqStateImt;
    default:
      return state;
  }
};

const profileViewReducer = (state = initReqStateImt, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PROFILE_VIEW_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_PROFILE_VIEW_SUCCESS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.CLEAR_PROFILE:
      return initReqStateImt;
    default:
      return state;
  }
};

const initPermissionsState = fromJS({
  permissions: initialState,
  updatePermissionsResult: null,
});

const profilePermissionsReducer = (state = initPermissionsState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PROFILE_PERMISSIONS_LOADING:
      return state.setIn(['permissions', 'isLoading'], action.payload);
    case actionTypes.FETCH_PROFILE_PERMISSIONS_SUCCESS:
      return state.setIn(['permissions', 'result'], fromJS(action.payload));
    case actionTypes.UPDATE_PROFILE_PERMISSIONS_RESULT:
      return state.set('updatePermissionsResult', ActionResult.SUCCESS_ACTION);
    case actionTypes.RESET_UPDATE_PROFILE_PERMISSIONS_RESULT:
      return state.set('updatePermissionsResult', null);
    case actionTypes.CLEAR_PROFILE:
      return initReqStateImt;
    default:
      return state;
  }
};

const profileAttachmentsReducer = (state = initReqStateImt, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PROFILE_ATTACHMENTS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_PROFILE_ATTACHMENTS_SUCCESS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.CLEAR_PROFILE:
      return initReqStateImt;
    default:
      return state;
  }
};

const initJobPositionsState = fromJS({
  jobPositions: initialState,
  jobPositionsHistory: initialState,
  updateJobPositionsResult: null,
});

const profileJobPositionsReducer = (state = initJobPositionsState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PROFILE_JOB_POSITIONS_LOADING:
      return state.setIn(['jobPositions', 'isLoading'], action.payload);
    case actionTypes.FETCH_PROFILE_JOB_POSITIONS_HISTORY_LOADING:
      return state.setIn(['jobPositionsHistory', 'isLoading'], action.payload);
    case actionTypes.FETCH_PROFILE_JOB_POSITIONS_SUCCESS:
      return state.setIn(['jobPositions', 'result'], fromJS(action.payload));
    case actionTypes.FETCH_PROFILE_JOB_POSITIONS_HISTORY_SUCCESS:
      return state.setIn(['jobPositionsHistory', 'result'], fromJS(action.payload));
    case actionTypes.UPDATE_PROFILE_JOB_POSITIONS_RESULT:
      return state.set('updateJobPositionsResult', ActionResult.SUCCESS_ACTION);
    case actionTypes.RESET_UPDATE_PROFILE_JOB_POSITIONS_RESULT:
      return state.set('updateJobPositionsResult', null);
    case actionTypes.RESET_PROFILE_JOB_POSITIONS_HISTORY:
      return state.set('jobPositionsHistory', fromJS(initialState));
    case actionTypes.CLEAR_PROFILE:
      return initReqStateImt;
    default:
      return state;
  }
};

const initModificationHistoryState = fromJS({
  recentModificationHistory: List<IModificationHistoryImt>(),
  recentModificationHistoryLoading: false,
  modificationHistory: List<IModificationHistoryImt>(),
  modificationHistoryLoading: false,
  modificationHistoryMeta: fromJS({ page: 0, perPage: 5, total: 0 }),
  modificationHistoryItem: {},
  modificationHistoryItemLoading: false,
});

const modificationHistoryReducer = (state = initModificationHistoryState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PROFILE_MODIFICATION_HISTORY:
      return state
        .set('modificationHistory', fromJS(action.payload.data))
        .set('modificationHistoryMeta', fromJS(action.payload.meta));
    case actionTypes.FETCH_PROFILE_MODIFICATION_HISTORY_LOADING:
      return state.set('modificationHistoryLoading', action.payload);
    case actionTypes.FETCH_PROFILE_RECENT_MODIFICATION_HISTORY:
      return state.set('recentModificationHistory', fromJS(action.payload));
    case actionTypes.FETCH_PROFILE_RECENT_MODIFICATION_HISTORY_LOADING:
      return state.set('recentModificationHistoryLoading', action.payload);
    case actionTypes.FETCH_PROFILE_MODIFICATION_HISTORY_ITEM:
      return state.set('modificationHistoryItem', fromJS(action.payload));
    case actionTypes.FETCH_PROFILE_MODIFICATION_HISTORY_ITEM_LOADING:
      return state.set('modificationHistoryItemLoading', action.payload);
    case actionTypes.RESET_PROFILE_MODIFICATION_HISTORY_ITEM:
      return state.set('modificationHistoryItem', fromJS({}));
    default:
      return state;
  }
};

export default combineReducers({
  profile: profileReducer,
  profileView: profileViewReducer,
  profileModificationHistory: modificationHistoryReducer,
  profilePermissions: profilePermissionsReducer,
  profileAttachments: profileAttachmentsReducer,
  profileJobPositions: profileJobPositionsReducer,
});
