import { ConstOptions } from 'common/constants/classes';

import { PeriodFilterType } from 'modules/reports/interfaces/common';

import messages from 'modules/reports/messages/messages';
import commonMessages from 'common/messages/messages';
import servicesMessages from 'modules/services/messages/messages';

export const PeriodFilterTypeLabels = new ConstOptions([
  { key: PeriodFilterType.DAY, value: PeriodFilterType.DAY, message: commonMessages.dayLabel },
  { key: PeriodFilterType.WEEK, value: PeriodFilterType.WEEK, message: commonMessages.weekLabel },
  {
    key: PeriodFilterType.MONTH,
    value: PeriodFilterType.MONTH,
    message: commonMessages.monthLabel,
  },
  { key: PeriodFilterType.YEAR, value: PeriodFilterType.YEAR, message: commonMessages.yearLabel },
]);

export enum ReportType {
  SalesDriven = 'SALES_DRIVEN',
  MemberCount = 'MEMBER_COUNT',
  MembershipMix = 'MEMBERSHIP_MIX',
}

export enum SalesDrivenChartFilters {
  MemberSales = 'MEMBER_SALES',
  PosSales = 'POS_SALES',
  PtSales = 'PT_SALES',
}

export enum MemberCountChartFilters {
  Gaining = 'GAINING',
  Loosing = 'LOOSING',
}

export enum MembershipMixChartFilters {
  PaidInFull = 'PAID_IN_FULL',
  FinancedInTerm = 'FINANCED_IN_TERM',
  FinancedOutTerm = 'FINANCED_OUT_TERM',
}

export const ChartLegendLabels = {
  [SalesDrivenChartFilters.MemberSales]: messages.memberSalesLabel,
  [SalesDrivenChartFilters.PosSales]: commonMessages.pos,
  [SalesDrivenChartFilters.PtSales]: messages.ptSalesLabel,

  [MemberCountChartFilters.Gaining]: messages.gainingLabel,
  [MemberCountChartFilters.Loosing]: messages.loosingLabel,

  [MembershipMixChartFilters.PaidInFull]: servicesMessages.paidInFullBillingOptionType,

  [MembershipMixChartFilters.FinancedInTerm]: messages.financedInTermLabel,

  [MembershipMixChartFilters.FinancedOutTerm]: messages.financedOutOfTermLabel,
};

export const TotalOverviewItemsLabels = new ConstOptions([
  { key: 'memberCount', value: 'memberCount', message: messages.totalMembersLabel },
  { key: 'revenueAmount', value: 'revenueAmount', message: messages.totalRevenueLabel },
  { key: 'leftCount', value: 'leftCount', message: messages.membersThatHaveLeftOverLabel },
  { key: 'checkinCount', value: 'checkinCount', message: messages.clubUsageLabel },
  { key: 'redeemedCount', value: 'redeemedCount', message: messages.redeemedSessionsNumberLabel },
]);

export const actionTypes = {
  FETCH_SALES_DRIVEN_REPORT: 'FETCH_SALES_DRIVEN_REPORT',
  FETCH_SALES_DRIVEN_REPORT_LOADING: 'FETCH_SALES_DRIVEN_REPORT_LOADING',

  FETCH_MEMBERS_COUNT_REPORT: 'FETCH_MEMBERS_COUNT_REPORT',
  FETCH_MEMBERS_COUNT_REPORT_LOADING: 'FETCH_MEMBERS_COUNT_REPORT_LOADING',

  FETCH_MEMBERSHIP_MIX_REPORT: 'FETCH_MEMBERSHIP_MIX_REPORT',
  FETCH_MEMBERSHIP_MIX_REPORT_LOADING: 'FETCH_MEMBERSHIP_MIX_REPORT_LOADING',

  FETCH_TOTAL_OVERVIEW_STATISTICS_LOADING: 'FETCH_TOTAL_OVERVIEW_STATISTICS_LOADING',
  FETCH_TOTAL_OVERVIEW_STATISTICS: 'FETCH_TOTAL_OVERVIEW_STATISTICS',
};
