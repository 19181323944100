import React, { useEffect, useState } from 'react';
import qz from 'qz-tray';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import { DialogComponent } from 'common/components';
import messages from 'common/messages/messages';
import { EventUpdateType } from 'modules/booking/interfaces';
import { useAppDispatch } from 'store/hooks';
import { setPrinterModalState } from 'common/state/printHTML/actions';
import { selectSetPrinterModalState } from 'common/state/printHTML/selectors';
import { endConnection, findPrinters } from 'common/utils/print';

interface ISelectPrinterModalProps {
  onSetPrinter: (printer: string) => void;
}

const SetPrinterModal = (props: ISelectPrinterModalProps): JSX.Element => {
  const { onSetPrinter } = props;

  const dispatch = useAppDispatch();

  const { isOpenSetPrinterModal } = useSelector(selectSetPrinterModalState);

  const [currentPrinter, setCurrentPrinter] = useState<string | null>(null);
  const [printers, setPrinters] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpenSetPrinterModal) {
      setIsLoading(true);

      findPrinters()
        .then((data: string[]) => {
          setPrinters(data);
        })
        .catch(() => {
          setPrinters([]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setCurrentPrinter(null);
      setPrinters([]);
    }
  }, [dispatch, isOpenSetPrinterModal]);

  useEffect(() => {
    if (printers.length) {
      setCurrentPrinter(printers[0]);
    }
  }, [printers]);

  const onClose = () => {
    dispatch(setPrinterModalState({ isOpenSetPrinterModal: false, printSessionExists: false }));
    if (!currentPrinter && qz.websocket.isActive()) {
      endConnection().catch(() => {
        setCurrentPrinter(null);
        setPrinters([]);
      });
    }
  };

  const onSubmit = () => {
    if (currentPrinter) {
      onSetPrinter(currentPrinter.replace(/\\/g, '\\\\'));
    }
    dispatch(setPrinterModalState({ isOpenSetPrinterModal: false, printSessionExists: true }));
  };

  return (
    <DialogComponent
      size="xs"
      title="Select printer"
      submitBtnTitle={<FormattedMessage {...messages.applyBtn} />}
      isOpen={isOpenSetPrinterModal}
      onClose={onClose}
      onSubmit={onSubmit}
      disableFullScreenBehavior
      disabled={!currentPrinter}
      loading={isLoading}
    >
      {isOpenSetPrinterModal && printers.length ? (
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="recurringEventUpdateType"
            name="eventUpdateType"
            value={currentPrinter}
            onChange={e => setCurrentPrinter(e.target.value as EventUpdateType)}
          >
            {printers.map(printer => {
              return (
                <FormControlLabel
                  key={printer}
                  value={printer}
                  control={<Radio />}
                  label={printer}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      ) : (
        <Typography>
          <FormattedMessage {...messages.noDataFound} />
        </Typography>
      )}
    </DialogComponent>
  );
};

export default SetPrinterModal;
