import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';
import { actionTypes } from '../../constants/packages';

const initialPackagesListState = fromJS({
  list: [],
  meta: {},
  isLoading: false,
});

function packagesListReducer(state = initialPackagesListState, action) {
  switch (action.type) {
    case actionTypes.FETCH_PACKAGES_LIST:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta));
    case actionTypes.FETCH_PACKAGES_LIST_LOADING:
      return state.set('isLoading', action.payload);
    default:
      return state;
  }
}

const initialPackageState = fromJS({
  package: null,
  packageLoading: false,
  createUpdatePackageLoading: false,
  createUpdatePackageActionResult: null,
});

function initialPackageStateReducer(state = initialPackageState, action) {
  switch (action.type) {
    case actionTypes.FETCH_PACKAGE:
      return state.set('package', action.payload);
    case actionTypes.FETCH_PACKAGE_LOADING:
      return state.set('packageLoading', action.payload);
    case actionTypes.CREATE_UPDATE_PACKAGE_LOADING:
      return state.set('createUpdatePackageLoading', action.payload);
    case actionTypes.CREATE_UPDATE_PACKAGE_RESULT:
      return state.set('createUpdatePackageActionResult', action.payload);
    case actionTypes.RESET_PACKAGE_STATE:
      return initialPackageState;
    default:
      return state;
  }
}

const initialRequestState = fromJS({
  result: null,
  error: null,
  loading: false,
});

function changeStatusReducer(state = initialRequestState, action) {
  switch (action.type) {
    case actionTypes.CHANGE_PACKAGE_STATUS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.CHANGE_PACKAGE_STATUS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_CHANGE_PACKAGE_STATUS:
      return initialRequestState;
    default:
      return state;
  }
}

function clonePackageReducer(state = initialRequestState, action) {
  switch (action.type) {
    case actionTypes.CLONE_PACKAGE:
      return state.set('result', fromJS(action.payload));
    case actionTypes.CLONE_PACKAGE_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_CLONE_PACKAGE:
      return initialRequestState;
    default:
      return state;
  }
}

const initListRequestState = fromJS({
  data: [],
  loading: false,
  error: null,
});

function revenueCodesListReducer(state = initListRequestState, action) {
  switch (action.type) {
    case actionTypes.FETCH_PACKAGE_RCODES:
      return state.set('data', fromJS(action.payload));
    case actionTypes.FETCH_PACKAGE_RCODES_LOADING:
      return state.set('loading', action.payload);
    case actionTypes.RESET_PACKAGE_RCODES:
      return initListRequestState;
    default:
      return state;
  }
}

function employeesListReducer(state = initListRequestState, action) {
  switch (action.type) {
    case actionTypes.FETCH_PACKAGE_EMPLOYEES:
      return state.set('data', fromJS(action.payload));
    case actionTypes.FETCH_PACKAGE_EMPLOYEES_LOADING:
      return state.set('loading', action.payload);
    case actionTypes.RESET_PACKAGE_EMPLOYEES:
      return initListRequestState;
    default:
      return state;
  }
}

function inventoryListReducer(state = initListRequestState, action) {
  switch (action.type) {
    case actionTypes.FETCH_PACKAGE_INVENTORY:
      return state.set('data', fromJS(action.payload));
    case actionTypes.FETCH_PACKAGE_INVENTORY_LOADING:
      return state.set('loading', action.payload);
    case actionTypes.RESET_PACKAGE_INVENTORY:
      return initListRequestState;
    default:
      return state;
  }
}

function servicesListReducer(state = initialRequestState, action) {
  switch (action.type) {
    case actionTypes.FETCH_PACKAGE_SERVICES_PACKAGES:
      return state.set('result', fromJS(action.payload));
    case actionTypes.FETCH_PACKAGE_SERVICES_PACKAGES_LOADING:
      return state.set('loading', action.payload);
    case actionTypes.RESET_PACKAGE_SERVICES_PACKAGES:
      return initialRequestState;
    default:
      return state;
  }
}

function feesListReducer(state = initialRequestState, action) {
  switch (action.type) {
    case actionTypes.FETCH_PACKAGE_FEES:
      return state.set('result', fromJS(action.payload));
    case actionTypes.FETCH_PACKAGE_FEES_LOADING:
      return state.set('loading', action.payload);
    case actionTypes.RESET_PACKAGE_FEES:
      return initialRequestState;
    default:
      return state;
  }
}

function packageInfoReducer(state = initialRequestState, action) {
  switch (action.type) {
    case actionTypes.FETCH_PACKAGE_INFO:
      return state.set('result', action.payload); // TODO: replace with immutable
    case actionTypes.FETCH_PACKAGE_INFO_LOADING:
      return state.set('loading', action.payload);
    case actionTypes.RESET_PACKAGE_INFO:
      return initialRequestState;
    default:
      return state;
  }
}

const initialBillingOptionsState = fromJS({
  billingOptions: [],
  isBillingOptionsLoading: false,
  billingOption: null,
  isBillingOptionLoading: false,
});

function billingOptionsReducer(state = initialBillingOptionsState, action) {
  switch (action.type) {
    case actionTypes.FETCH_BILLING_OPTIONS:
      return state.set('billingOptions', fromJS(action.payload));
    case actionTypes.FETCH_BILLING_OPTIONS_LOADING:
      return state.set('isBillingOptionsLoading', action.payload);
    case actionTypes.FETCH_BILLING_OPTION:
      return state.set('billingOption', fromJS(action.payload));
    case actionTypes.FETCH_BILLING_OPTION_LOADING:
      return state.set('isBillingOptionLoading', action.payload);
    case actionTypes.RESET_BILLING_OPTION:
      return state.set('billingOption', null);
    case actionTypes.RESET_BILLING_OPTIONS:
      return state.set('billingOptions', fromJS([]));
    default:
      return state;
  }
}

export default combineReducers({
  packagesList: packagesListReducer,
  package: initialPackageStateReducer,
  changeStatusState: changeStatusReducer,
  cloneState: clonePackageReducer,

  revenueCodes: revenueCodesListReducer,
  employees: employeesListReducer,
  inventory: inventoryListReducer,
  services: servicesListReducer,
  fees: feesListReducer,
  billingOptions: billingOptionsReducer,

  packageInfo: packageInfoReducer,
});
