import messages from '../../messages/messages';
import { ConstOptions } from 'common/constants/classes';

export enum CustomerStatus {
  Guest = 'GUEST',
  ActiveMember = 'ACTIVE_MEMBER',
  FrozenMember = 'FROZEN_MEMBER',
  ExpiredMember = 'EXPIRED_MEMBER',
  DeletedMember = 'DELETED_MEMBER',
  Empty = 'EMPTY',
}

export const CustomerStatuses = new ConstOptions([
  {
    value: CustomerStatus.Guest,
    message: messages.memberStatusGuest,
  },
  {
    value: CustomerStatus.ActiveMember,
    message: messages.memberStatusActiveMember,
  },
  {
    value: CustomerStatus.FrozenMember,
    message: messages.memberStatusFrozenMember,
  },
  {
    value: CustomerStatus.ExpiredMember,
    message: messages.memberStatusExpiredMember,
  },
  {
    value: CustomerStatus.DeletedMember,
    message: messages.memberStatusDeletedMember,
  },
  {
    value: CustomerStatus.Empty,
    message: messages.memberStatusEmpty,
  },
]);

export enum NewMemberSteps {
  PrimaryInfo = 'PRIMARY_INFO',
  Membership = 'MEMBERSHIP',
  AdditionalInfo = 'ADDITIONAL_INFO',
  Payment = 'PAYMENT',
  FamilyMembers = 'FAMILY_MEMBERS',
  Waivers = 'WAIVERS',
  SignContract = 'SIGN_CONTRACT',
  ScheduleTour = 'SCHEDULE_TOUR',
}

export const ChangeMembershipStepsLabels = {
  [NewMemberSteps.Membership]: 'changeMembershipStepTitle',
  [NewMemberSteps.AdditionalInfo]: 'newMemberAdditionalInfoStepTitle',
  [NewMemberSteps.Payment]: 'newMemberPaymentStepTitle',
  [NewMemberSteps.FamilyMembers]: 'newMemberFamilyMembersStepTitle',
  [NewMemberSteps.Waivers]: 'newMemberWaiversStepTitle',
  [NewMemberSteps.SignContract]: 'newMemberSignContractStepTitle',
  [NewMemberSteps.ScheduleTour]: 'newMemberScheduleTourStepTitle',
};

export const NewMemberStepsLabels = {
  [NewMemberSteps.PrimaryInfo]: 'newMemberPrimaryInfoStepTitle',
  [NewMemberSteps.Membership]: 'newMemberMembershipStepTitle',
  [NewMemberSteps.AdditionalInfo]: 'newMemberAdditionalInfoStepTitle',
  [NewMemberSteps.Payment]: 'newMemberPaymentStepTitle',
  [NewMemberSteps.FamilyMembers]: 'newMemberFamilyMembersStepTitle',
  [NewMemberSteps.Waivers]: 'newMemberWaiversStepTitle',
  [NewMemberSteps.SignContract]: 'newMemberSignContractStepTitle',
  [NewMemberSteps.ScheduleTour]: 'newMemberScheduleTourStepTitle',
};
