import ApiService from './ApiService';
import axios from 'axios';
import { ITableParams } from 'common/interfaces/table';
import { IRoleFormData } from 'modules/permissions/interfaces/roles';
import { IPolicyFormData, IPolicyListItem } from 'modules/permissions/interfaces/permissions';
import { IEmployeeRole } from 'common/interfaces/employee';
import { IPaginatedData } from 'common/interfaces/pagination';
import { ISecurityPolicyDto } from 'common/interfaces/permissions';
import { PoliciesPermissionSetItems } from 'common/constants/permissions';

export default class PermissionsService extends ApiService {
  public getPoliciesList = (
    requestOptions?: ITableParams,
  ): Promise<IPaginatedData<IPolicyListItem>> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get('/api/v1/security/policy', { params: searchParams });
  };

  public getPoliciesDictionaryList = (): Promise<IPaginatedData<IPolicyListItem>> => {
    return axios.get('/api/v1/security/policy', { params: { active: true } });
  };

  public getPermissions = (): Promise<PoliciesPermissionSetItems[]> => {
    return axios.get('/api/v1/security/policy/permissions');
  };

  public getPolicyItem = (id: string): Promise<ISecurityPolicyDto> => {
    return axios.get(`/api/v1/security/policy/${id}`);
  };

  public editPolicyItem = (id: string, data: IPolicyFormData): Promise<ISecurityPolicyDto> => {
    const json = JSON.stringify(data);
    return axios.put(`/api/v1/security/policy/${id}`, json);
  };

  public changePolicyItemStatus = (id: string, active: boolean): Promise<any> => {
    return axios.patch(`/api/v1/security/policy/${id}/change-status`, {
      active: active.toString(),
    });
  };

  public createPolicyItem = (data: IPolicyFormData): Promise<ISecurityPolicyDto> => {
    const json = JSON.stringify(data);
    return axios.post('/api/v1/security/policy', json);
  };

  public getRolesList = (requestOptions?: ITableParams): Promise<IEmployeeRole> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get('/api/v1/security/role', { params: searchParams });
  };

  public getRoleItem = (id: string): Promise<IEmployeeRole> => {
    return axios.get(`/api/v1/security/role/${id}`);
  };

  public createRoleItem = (data: IRoleFormData): Promise<IEmployeeRole> => {
    return axios.post('/api/v1/security/role', data);
  };

  public changeRoleItemStatus = (id: string, active: boolean): Promise<any> => {
    return axios.patch(`/api/v1/security/role/${id}/change-status`, {
      active: active.toString(),
    });
  };

  public editRoleItem = (id: string, data: IRoleFormData): Promise<IEmployeeRole> => {
    return axios.put(`/api/v1/security/role/${id}`, data);
  };
}
