import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'store/hooks';
import { InventoryViewModal } from 'common/components';
import {
  fetchPackageInventory,
  resetPackageInventoryAction,
} from 'common/state/newPerson/membership/actions';
import {
  selectMembershipInventory,
  selectMembershipInventoryLoading,
} from 'common/state/newPerson/membership/selectors';
import { IPackageInventoryViewDtoImt } from 'common/interfaces/membership';
import { PeakModules } from 'common/constants/peakModules';

interface IProps {
  isOpen: boolean;
  module: PeakModules;
  onClose: () => void;
  inventoryId: string;
  packageId: string;
}

const PackageInventoryViewInfoModal = ({
  inventoryId,
  module,
  packageId,
  isOpen,
  onClose,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const isInventoryInfoLoading = useSelector(selectMembershipInventoryLoading);
  const inventoryInfo = useSelector(selectMembershipInventory);

  useEffect(() => {
    dispatch(fetchPackageInventory(packageId, inventoryId, module));
  }, [dispatch, inventoryId, packageId, module]);

  useEffect(() => {
    return () => {
      dispatch(resetPackageInventoryAction());
    };
  }, [dispatch]);

  return (
    <InventoryViewModal<IPackageInventoryViewDtoImt>
      inventoryInfo={inventoryInfo}
      onClose={onClose}
      isOpen={isOpen}
      isLoading={isInventoryInfoLoading}
    />
  );
};

export default PackageInventoryViewInfoModal;
