import React, { useEffect } from 'react';
import { List as ImmutableList } from 'immutable';
import { useSelector } from 'react-redux';

import {
  IImmutablePrimaryMemberInfo,
  IPrimaryMemberInfo,
} from 'common/components/PersonProfile/interfaces';
import * as memberActions from 'common/state/newPerson/primaryInfo/actions';
import { selectDictionaryList } from 'common/state/dictionary/selectors';
import { ActionResult, DictionaryList } from 'common/constants';
import {
  ICameFromIndicatorDictionaryItemImt,
  IEmployeeListItemImt,
} from 'common/interfaces/dictionary';
import { MemberPrimaryInfoStep } from 'common/components';
import * as memberSelectors from 'common/state/newPerson/primaryInfo/selectors';
import { fetchDictionaryList } from 'common/state/dictionary/actions';
import { CustomerStatus } from 'modules/front-desk/constants/common/constants';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import {
  selectCurrentUser,
  selectUserSelectedClubId,
} from 'modules/authentication/state/selectors';
import { selectPrimaryInfo } from 'common/state/newPerson/primaryInfo/selectors';
import { useAppDispatch } from 'store/hooks';

interface IPrimaryInfoStepContainerProps {
  setRightPanelPerson: (id: string) => void;
  switchNewMemberPanel: () => void;
  module: PeakModuleForNewPersonType;
}

const PrimaryInfoStepContainer = ({
  switchNewMemberPanel,
  setRightPanelPerson,
  module,
}: IPrimaryInfoStepContainerProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const clubId = useSelector(selectUserSelectedClubId);
  const primaryInfo = useSelector(selectPrimaryInfo);
  const currentUser = useSelector(selectCurrentUser);
  const actionCreateResult = useSelector(memberSelectors.selectActionCreateResult);
  const actionUpdateResult = useSelector(memberSelectors.selectActionUpdateResult);
  const employees: ImmutableList<IEmployeeListItemImt> = useSelector(
    selectDictionaryList(DictionaryList.EMPLOYEES),
  );
  const cameFromIndicators: ImmutableList<ICameFromIndicatorDictionaryItemImt> = useSelector(
    selectDictionaryList(DictionaryList.CAME_FROM),
  );

  useEffect(() => {
    dispatch(fetchDictionaryList(DictionaryList.EMPLOYEES, { module }));
    dispatch(fetchDictionaryList(DictionaryList.CAME_FROM, { module }));
  }, [dispatch, module]);

  useEffect(() => {
    if (actionCreateResult === ActionResult.SUCCESS_ACTION) {
      dispatch(memberActions.resetCreateMemberResult());
    }

    if (actionUpdateResult === ActionResult.SUCCESS_ACTION) {
      dispatch(memberActions.resetUpdateMemberResult());
    }
  }, [actionCreateResult, actionUpdateResult, dispatch]);

  const handleSelectPerson = (person: IImmutablePrimaryMemberInfo) => {
    if (person.get('type') === CustomerStatus.ActiveMember) {
      setRightPanelPerson(person.get('id'));
      switchNewMemberPanel();
    } else {
      dispatch(memberActions.setMember(person.toJS()));
    }
  };

  const handleOnSubmitPrimaryInfoStep = (data: IPrimaryMemberInfo): void => {
    if (primaryInfo?.get('id')) {
      // ToDo: when connected api need uncomment it's
      // let updatesData = {};
      // Object.keys(data).forEach(key => {
      //   if (!deepEquals(data[key], primaryInfo.toJS()[key]))
      //     updatesData = { ...updatesData, [key]: data[key] };
      // });
      // if (Object.keys(updatesData).length > 0) {
      //   updateMember(primaryInfo.get('id'), updatesData);
      // } else {
      //   this.goNextStep();
      // }
      dispatch(memberActions.updateMember(primaryInfo.get('id'), data, module));
    } else {
      dispatch(memberActions.createMember({ ...data, initialClubId: clubId }, module));
    }
  };

  return (
    <MemberPrimaryInfoStep
      cameFromIndicators={cameFromIndicators}
      currentUser={currentUser}
      employees={employees}
      primaryInfo={primaryInfo}
      setPrimaryInfo={handleSelectPerson}
      isResponseSuccess={(actionCreateResult || actionUpdateResult) === ActionResult.SUCCESS_ACTION}
      handleOnSubmit={handleOnSubmitPrimaryInfoStep}
      module={module}
    />
  );
};

export default PrimaryInfoStepContainer;
