import * as actionTypes from './constants';
import { GeneralThunkAction } from 'common/state/interfaces';
import Services from 'services/network';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import {
  IReferralCreateDto,
  IReferralShortDto,
  ISetReferredDto,
} from 'common/components/PersonProfile/interfaces';
import { createPersonPrepAction } from 'common/components/PersonProfile/state/actions';
import { ActionResult } from 'common/constants';

export const setPersonReferralsErrorAction = createPersonPrepAction<Error>(
  actionTypes.SET_PERSON_REFERRALS_ERROR,
);

const fetchPersonReferralsSuccessAction = createPersonPrepAction<IReferralShortDto>(
  actionTypes.FETCH_PERSON_REFERRALS_SUCCESS,
);
const fetchPersonReferralsLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_REFERRALS_LOADING,
);
export const resetPersonReferrals = createPersonPrepAction(actionTypes.RESET_PERSON_REFERRALS);

export const fetchPersonReferrals = (personId: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPersonReferralsLoadingAction(true, personId));

    try {
      const referrals = await Services.FrontDesk.getPersonReferrals(personId);

      dispatch(fetchPersonReferralsSuccessAction(referrals, personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
      dispatch(setPersonReferralsErrorAction(e, personId));
    } finally {
      dispatch(fetchPersonReferralsLoadingAction(false, personId));
    }
  };
};

export const addPersonReferralAction = createPersonPrepAction<ActionResult | null>(
  actionTypes.ADD_PERSON_REFERRAL,
);
const addPersonReferralLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.ADD_PERSON_REFERRAL_LOADING,
);

export const addPersonReferral = (
  personId: string,
  dto: IReferralCreateDto,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(addPersonReferralLoadingAction(true, personId));

    try {
      await Services.FrontDesk.createPersonReferral(personId, dto);

      dispatch(addPersonReferralAction(ActionResult.SUCCESS_ACTION, personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
      dispatch(addPersonReferralAction(ActionResult.FAIL_ACTION, personId));
    } finally {
      dispatch(addPersonReferralLoadingAction(false, personId));
    }
  };
};

export const setReferralAction = createPersonPrepAction<ActionResult | null>(
  actionTypes.SET_REFERRED_BY,
);
const setReferralLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.SET_REFERRED_BY_LOADING,
);

export const setReferral = (personId: string, data: ISetReferredDto): GeneralThunkAction => {
  return async dispatch => {
    dispatch(setReferralLoadingAction(true, personId));

    try {
      await Services.FrontDesk.setReferredBy(personId, data);

      dispatch(setReferralAction(ActionResult.SUCCESS_ACTION, personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
      dispatch(setReferralAction(ActionResult.FAIL_ACTION, personId));
    } finally {
      dispatch(setReferralLoadingAction(false, personId));
    }
  };
};
