import { List as ImmutableList } from 'immutable';
import {
  IRecipientListItemImt,
  IStatistics,
  ITotalsItem,
} from 'modules/reports/interfaces/campaignsReport';
import { reportStatistics, StatisticsNames } from 'modules/reports/constants/campaignsReport';

export const addFullNameToRecipients = (recipientsList: ImmutableList<IRecipientListItemImt>) =>
  recipientsList?.map((recipient: IRecipientListItemImt) =>
    recipient.set('name', `${recipient.get('firstName')} ${recipient.get('lastName')}`),
  );

export const prepareStatistics = (stats: IStatistics): ITotalsItem[] => {
  const recipientsItem = reportStatistics.find(el => el.id === StatisticsNames.RECIPIENTS);
  recipientsItem.amount = stats.get('recipientsCount');

  const successfulDeliveriesItem = reportStatistics.find(
    el => el.id === StatisticsNames.SUCCESSFUL_DELIVERIES,
  );
  successfulDeliveriesItem.amount = (stats.get('totalDelivered') / stats.get('totalSend')) * 100;

  const openRateItem = reportStatistics.find(el => el.id === StatisticsNames.OPEN_RATE);
  openRateItem.amount = (stats.get('totalOpened') / stats.get('totalSend')) * 100;

  const totalClicksItem = reportStatistics.find(el => el.id === StatisticsNames.TOTAL_CLICKS);
  totalClicksItem.amount = stats.get('totalClicked');

  const clickRateItem = reportStatistics.find(el => el.id === StatisticsNames.CLICK_RATE);
  clickRateItem.amount = (stats.get('totalClicked') / stats.get('totalSend')) * 100;

  const totalOpensItem = reportStatistics.find(el => el.id === StatisticsNames.TOTAL_OPENS);
  totalOpensItem.amount = stats.get('totalOpened');

  const bouncedItem = reportStatistics.find(el => el.id === StatisticsNames.BOUNCED);
  bouncedItem.amount = (stats.get('totalBounced') / stats.get('totalSend')) * 100;

  const unsubscribedItem = reportStatistics.find(el => el.id === StatisticsNames.UNSUBSCRIBED);
  unsubscribedItem.amount = (stats.get('totalUnsubscribed') / stats.get('totalSend')) * 100;

  return reportStatistics;
};
