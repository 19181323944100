import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { actionTypes } from 'modules/peak-settings/constants';
import { initialState, initReqStateImt } from 'common/constants/initialState';
import { colors } from 'common/ui/theme/default';

const initPeakSettingsSectionState = fromJS({
  peakSettingsSectionInfo: {},
  isPeakSettingsSectionInfoLoading: false,

  primaryColor: colors.primary,
  primaryColorLoading: false,

  updatePeakSettingsSectionInfo: initialState,
});
const peakSettingsReducer = (state = initPeakSettingsSectionState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PEAK_SETTINGS_SECTION_INFO:
      return state.set('peakSettingsSectionInfo', fromJS(action.payload));
    case actionTypes.FETCH_PEAK_SETTINGS_SECTION_INFO_LOADING:
      return state.set('isPeakSettingsSectionInfoLoading', action.payload);
    case actionTypes.UPDATE_PEAK_SETTINGS_SECTION:
      return state
        .setIn(['updatePeakSettingsSectionInfo', 'result'], fromJS(action.payload))
        .set(
          'peakSettingsSectionInfo',
          state.get('peakSettingsSectionInfo').merge(fromJS(action.payload)),
        );
    case actionTypes.UPDATE_PEAK_SETTINGS_SECTION_LOADING:
      return state.setIn(['updatePeakSettingsSectionInfo', 'isLoading'], action.payload);
    case actionTypes.RESET_PEAK_SETTINGS_SECTION_UPDATE_ACTION_RESULT:
      return state.set('updatePeakSettingsSectionInfo', initReqStateImt);

    case actionTypes.PEAK_SETTINGS_PRIMARY_COLOR_RESULT:
      return state.set('primaryColor', action.payload);
    case actionTypes.PEAK_SETTINGS_PRIMARY_COLOR_LOADING:
      return state.set('primaryColorLoading', action.payload);

    default:
      return state;
  }
};

export default combineReducers({
  peakSettingsSection: peakSettingsReducer,
});
