import { fromJS } from 'immutable';
import { combineReducers } from 'redux';

import { actionTypes } from '../../constants/registers';
import AppService from 'services/application/AppService';
import {
  IRegisterPosCategory,
  IRegisterPosCategoryImt,
  IRegisterPosClubItem,
} from '../../../pos-settings/interfaces/register';

const selectedRegisters = AppService.getAppSelectedRegisters();
const initStatePosKioskRegisterData = fromJS({
  // data
  registerData: {},
  isLoading: false,
  // totals
  registerTotals: {},
  isTotalsLoading: false,
  // cash desk info
  cashDeskInfo: null,
  isCashDeskInfoLoading: false,
  // actions
  isOpenRegisterLoading: false,
  isCloseRegisterLoading: false,
  isReconcileRegisterLoading: false,
  // actions results
  openRegisterResult: {},
  closeRegisterResult: {},
  reconcileRegisterResult: {},
  // close register invoices
  closeRegisterInvoicesResult: {},
  closeRegisterInvoicesIsLoading: false,
  // register selection process
  registerSelectionLoading: false,
});

function registerDataReducer(state = initStatePosKioskRegisterData, action) {
  switch (action.type) {
    // data
    case actionTypes.FETCH_REGISTER_DATA_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_REGISTER_DATA:
      return state.set('registerData', fromJS(action.payload));
    case actionTypes.RESET_REGISTER_DATA:
      return initStatePosKioskRegisterData.set(
        'registerSelectionLoading',
        state.get('registerSelectionLoading'),
      );
    // totals
    case actionTypes.FETCH_REGISTER_TOTALS_LOADING:
      return state.set('isTotalsLoading', action.payload);
    case actionTypes.FETCH_REGISTER_TOTALS:
      return state.set('registerTotals', fromJS(action.payload));
    case actionTypes.RESET_REGISTER_TOTALS:
      return state.set('registerTotals', fromJS({}));
    // cash desk info
    case actionTypes.FETCH_REGISTER_CASH_DESK_INFO_LOADING:
      return state.set('isCashDeskInfoLoading', action.payload);
    case actionTypes.FETCH_REGISTER_CASH_DESK_INFO:
      return state.set('cashDeskInfo', fromJS(action.payload));
    // actions
    // open register
    case actionTypes.OPEN_REGISTER_ITEM_LOADING:
      return state.set('isOpenRegisterLoading', action.payload);
    case actionTypes.OPEN_REGISTER_ITEM:
      return state.set('openRegisterResult', fromJS(action.payload));
    case actionTypes.RESET_OPEN_REGISTER_ITEM_ACTION_RESULT:
      return state.set('openRegisterResult', fromJS({}));
    // close register
    case actionTypes.CLOSE_REGISTER_ITEM_LOADING:
      return state.set('isCloseRegisterLoading', action.payload);
    case actionTypes.CLOSE_REGISTER_ITEM:
      return state.set('closeRegisterResult', fromJS(action.payload));
    case actionTypes.RESET_CLOSE_REGISTER_ITEM_ACTION_RESULT:
      return state.set('closeRegisterResult', fromJS({}));
    // reconcile register
    case actionTypes.RECONCILE_REGISTER_ITEM_LOADING:
      return state.set('isReconcileRegisterLoading', action.payload);
    case actionTypes.RECONCILE_REGISTER_ITEM:
      return state.set('reconcileRegisterResult', fromJS(action.payload));
    case actionTypes.RESET_RECONCILE_REGISTER_ITEM_ACTION_RESULT:
      return state.set('reconcileRegisterResult', fromJS({}));
    // close register invoices
    case actionTypes.CLOSE_REGISTER_INVOICES_RESULT:
      return state.set('closeRegisterInvoicesResult', fromJS(action.payload));
    case actionTypes.CLOSE_REGISTER_INVOICES_LOADING:
      return state.set('closeRegisterInvoicesIsLoading', action.payload);
    // register selection process
    case actionTypes.REGISTER_SELECTION_PROCESS_LOADING:
      return state.set('registerSelectionLoading', action.payload);
    case actionTypes.UPDATE_REGISTER_INVENTORIES_DATA:
      const updatedInventories: IRegisterPosClubItem[] = action.payload;

      return state.updateIn(
        ['registerData', 'registerPosButton'],
        (registerPosButton: IRegisterPosCategoryImt) =>
          fromJS(updateInventoryFunction(registerPosButton, updatedInventories)),
      );
    default:
      return state;
  }
}

const updateInventoryFunction = (
  posCategory: IRegisterPosCategoryImt,
  updatedInventories: IRegisterPosClubItem[],
): IRegisterPosCategory => {
  if (!posCategory) {
    return undefined;
  }

  const updatedPosCategory = { ...posCategory?.toJS() };

  updatedPosCategory.posButtonInventoryClubList = posCategory
    .get('posButtonInventoryClubList')
    ?.toJS()
    ?.map(currInventory => {
      const updatedInventory = updatedInventories.find(
        inv => inv.id === currInventory.inventoryClub.id,
      );
      return updatedInventory
        ? { ...currInventory, inventoryClub: updatedInventory }
        : currInventory;
    });

  updatedPosCategory.childButtonList = posCategory
    .get('childButtonList')
    ?.map(childPosCategory => updateInventoryFunction(childPosCategory, updatedInventories))
    ?.toJS();

  return updatedPosCategory;
};

const initStateSelectedRegisters = fromJS(selectedRegisters);

function selectedRegistersReducer(state = initStateSelectedRegisters, action) {
  switch (action.type) {
    case actionTypes.SET_SELECTED_REGISTER_ID:
      return state ? state.merge(action.payload) : fromJS(action.payload);
    case actionTypes.RESET_SELECTED_REGISTER_ID:
      return state.delete(action.payload, null);
    default:
      return state;
  }
}

export default combineReducers({
  selectedRegister: registerDataReducer,
  selectedRegistersByClubList: selectedRegistersReducer,
});
