import React, { useEffect, useState } from 'react';
import { batch, useSelector } from 'react-redux';
import { List as ImmutableList } from 'immutable';
import { Box, Grid, TextField } from '@material-ui/core';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';

import {
  ICustomerShort,
  IFamilyMember,
  IFamilyMemberImt,
  IImmutablePrimaryMemberInfo,
} from 'common/components/PersonProfile/interfaces';

import NewMemberModal from '../NewMemberModal/NewMemberModal';
import { CustomerStatus } from 'modules/front-desk/constants/common/constants';
import { DialogComponent, MemberAutocomplete } from 'common/components';
import * as actionsMembers from 'common/state/newPerson/familyMembers/actions';
import * as actions from 'common/components/PersonProfile/state/familyMembers/actions';
import {
  selectAddFamilyMemberActionLoading,
  selectAddFamilyMemberResult,
  selectCreateMemberForFamilyMemberLoading,
  selectCreateMemberForFamilyMemberResult,
} from 'common/state/newPerson/familyMembers/selectors';
import { ActionResult, requiredMessage } from 'common/constants/globalConstants';
import msg from 'common/components/PersonProfile/messages';
import commonMessages from 'common/messages/messages';
import { useAppDispatch } from 'store/hooks';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import MemberServicesAutocomplete from 'common/components/Steps/FamilyMembersStep/MemberServicesAutocomplete/MemberServicesAutocomplete';

interface IProps {
  isLoading?: boolean;
  personId: string;
  module: PeakModuleForNewPersonType;
  isOpen: boolean;
  isServicesSection?: boolean;
  title?: JSX.Element | string;
  familyMembers?: ImmutableList<IFamilyMemberImt>;
  memberFirstName?: string;
  onClose: () => void;
  onSubmit: (formValues: IFamilyMember) => void;
}

const schema = yup.object().shape({
  rightCustomer: yup
    .object()
    .nullable()
    .required(requiredMessage),
  leftRightRelation: yup.string().required(requiredMessage),
});

const initialState = {
  isNewModalOpen: false,
  refresh: 0,
};

const AddFamilyMemberModal = ({
  personId,
  isOpen,
  isServicesSection,
  title,
  memberFirstName,
  onClose,
  module,
  onSubmit,
  isLoading,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const addFamilyMemberResult = useSelector(selectAddFamilyMemberResult);
  const createMemberResult = useSelector(selectCreateMemberForFamilyMemberResult);
  const createMemberLoading = useSelector(selectCreateMemberForFamilyMemberLoading);
  const isAddFamilyMembersActionLoading: boolean = useSelector(selectAddFamilyMemberActionLoading);

  const [isNewModalOpen, setIsNewModalOpen] = useState<boolean>(initialState.isNewModalOpen);
  const [refresh, setRefresh] = useState(initialState.refresh);

  useEffect(() => {
    if (isOpen) {
      setIsNewModalOpen(initialState.isNewModalOpen);
      setRefresh(initialState.refresh);
    }
  }, [isOpen]);

  const formMethods = useForm({
    defaultValues: {
      rightCustomer: null,
      leftRightRelation: '',
      rightLeftRelation: '',
      services: [],
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const {
    control,
    handleSubmit,
    errors,
    watch,
    setValue,
    clearErrors,
    trigger,
    getValues,
  } = formMethods;
  const selectedFamilyMember: ICustomerShort = watch('rightCustomer');

  const getOptionDisabled = opt => {
    return selectedFamilyMember?.id === opt.id || personId === opt.id;
  };
  const onCloseNewModal = () => {
    setIsNewModalOpen(false);
  };

  const onOpenNewModal = () => {
    setIsNewModalOpen(true);
  };

  const onSubmitNewModal = newMember => {
    dispatch(
      actionsMembers.createMemberForFamilyMember(newMember, personId, module, isServicesSection),
    );
  };

  useEffect(() => {
    if (createMemberResult) {
      setRefresh(prevState => prevState + 1);
      onCloseNewModal();
      setValue('rightCustomer', createMemberResult.toJS());

      batch(() => {
        dispatch(actionsMembers.createMemberForFamilyMemberResultAction(null));
        dispatch(actionsMembers.resetCreateFamilyMemberResult());
      });
    }
  }, [createMemberResult, setValue, dispatch, trigger]);

  useEffect(() => {
    if (ActionResult.SUCCESS_ACTION === addFamilyMemberResult) {
      dispatch(actions.fetchPersonFamilyMembers(personId, module));

      batch(() => {
        dispatch(actionsMembers.resetCreateFamilyMemberResult());
        dispatch(actionsMembers.addFamilyMemberActionResult(null));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addFamilyMemberResult, onClose]);

  const onSelectPersonInModal = (person: IImmutablePrimaryMemberInfo) => {
    if (person.get('type') !== CustomerStatus.ActiveMember) {
      const newMember = {
        rightCustomer: {
          id: person.get('id'),
          firstName: person.get('firstName'),
        },
      } as IFamilyMember;
      setValue('rightCustomer', newMember);
    }
    onCloseNewModal();
  };

  return (
    <DialogComponent
      title={title || <FormattedMessage {...msg.dlgTitle} />}
      submitBtnTitle={
        <FormattedMessage {...(isServicesSection ? commonMessages.redeemBtn : msg.dlgAddBtn)} />
      }
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      size="xs"
      loading={isLoading || isAddFamilyMembersActionLoading}
      disableFullScreenBehavior
    >
      <FormProvider {...formMethods}>
        <form id="family-members-form" autoComplete="none">
          <Box overflow="hidden">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Controller
                  name="rightCustomer"
                  render={({ value, onChange }) => (
                    <MemberAutocomplete
                      module={module}
                      isSearchFamilyMembers
                      isServicesSection={isServicesSection}
                      // without spread not works
                      value={value ? { ...value } : null}
                      onChange={(e, v) => {
                        if (!selectedFamilyMember) clearErrors();
                        onChange(v);
                      }}
                      refresh={refresh}
                      personId={personId}
                      label={<FormattedMessage {...msg.dlgAutocompleteLabel} />}
                      getOptionDisabled={getOptionDisabled}
                      onCreateNew={onOpenNewModal}
                      fullWidth
                      error={errors.rightCustomer?.message}
                    />
                  )}
                />
              </Grid>

              {!!selectedFamilyMember && (
                <>
                  <Grid item xs={12}>
                    <Controller
                      as={TextField}
                      name="leftRightRelation"
                      control={control}
                      label={
                        <FormattedMessage
                          {...msg.relationMemberToPerson}
                          values={{
                            memberFName: selectedFamilyMember?.firstName,
                            personFName: memberFirstName,
                          }}
                        />
                      }
                      variant="outlined"
                      fullWidth
                      error={!!errors.leftRightRelation}
                      helperText={errors.leftRightRelation?.message}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      as={TextField}
                      name="rightLeftRelation"
                      control={control}
                      label={
                        <FormattedMessage
                          {...msg.relationPersonToMember}
                          values={{
                            fromPersonFName: memberFirstName,
                            toPersonFName: selectedFamilyMember?.firstName,
                          }}
                        />
                      }
                      error={!!errors.rightLeftRelation}
                      helperText={errors.rightLeftRelation?.message}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      name="services"
                      control={control}
                      defaultValue={[]}
                      render={({ value, onChange, onBlur }) => (
                        <MemberServicesAutocomplete
                          memberId={personId}
                          module={module}
                          value={value}
                          isServicesSection={isServicesSection}
                          onChange={onChange}
                          onBlur={onBlur}
                          familyMembers={[getValues()]}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </form>

        {isNewModalOpen && (
          <NewMemberModal
            isOpen={isNewModalOpen}
            isLoading={createMemberLoading}
            onClose={onCloseNewModal}
            onSubmit={onSubmitNewModal}
            module={module}
            onSelectMember={onSelectPersonInModal}
          />
        )}
      </FormProvider>
    </DialogComponent>
  );
};

export default React.memo(AddFamilyMemberModal);
