import axios from 'axios';

import ApiService from './ApiService';

import {
  ICameFromIndicatorDto,
  ICameFromIndicatorShortDto,
  ICreateCameFromIndicator,
} from 'common/interfaces/cameFrom';
import { IPaginatedData } from 'common/interfaces/pagination';
import { ITableParams } from 'common/interfaces/table';

export default class CameFromService extends ApiService {
  public getCameFromIndicators = (
    requestOptions?: ITableParams,
  ): Promise<IPaginatedData<ICameFromIndicatorShortDto>> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get('/api/v1/came-from', { params: searchParams });
  };

  public getCameFromIndicator = (id: string): Promise<ICameFromIndicatorDto> => {
    return axios.get(`/api/v1/came-from/${id}`);
  };

  public createCameFromIndicator = (
    data: ICreateCameFromIndicator,
  ): Promise<ICameFromIndicatorDto> => {
    return axios.post('/api/v1/came-from', data);
  };

  public updateCameFromIndicator = (
    data: ICameFromIndicatorDto,
  ): Promise<ICameFromIndicatorDto> => {
    return axios.put(`/api/v1/came-from/${data.id}`, data);
  };

  public updateStatusCameFromIndicator = (
    id: string,
    isActive: boolean,
  ): Promise<ICameFromIndicatorDto> => {
    return axios.patch(`/api/v1/came-from/${id}/change-status`, { active: isActive });
  };
}
