import { defineMessages } from 'react-intl';

export default defineMessages({
  signIn: {
    id: 'app.routes.SignIn.signIn',
    defaultMessage: 'Sign In',
  },
  signUp: {
    id: 'app.routes.SignIn.signUp',
    defaultMessage: 'Sign Up',
  },
  forgotPassword: {
    id: 'app.routes.SignIn.forgotPassword',
    defaultMessage: 'Forgot password?',
  },
  needAccount: {
    id: 'app.routes.SignIn.needAccount',
    defaultMessage: "Don't have an account? Sign Up!",
  },
  continue: {
    id: 'app.routes.SignIn.continue',
    defaultMessage: 'Continue',
  },
});
