export const actionTypes = {
  SET_DETAILS_MODAL_SHOWN: 'MEMBER_PORTAL/SET_DETAILS_MODAL_SHOWN',
  FETCH_SERVICE_ITEM_DETAILS: 'MEMBER_PORTAL/FETCH_SERVICE_ITEM_DETAILS',
  FETCH_SERVICE_ITEM_DETAILS_LOADING: 'MEMBER_PORTAL/FETCH_SERVICE_ITEM_DETAILS_LOADING',
  RESET_SERVICE_ITEM_DETAILS: 'MEMBER_PORTAL/RESET_SERVICE_ITEM_DETAILS',
  FETCH_PERSON_SERVICES_LOADING: 'MEMBER_PORTAL/FETCH_PERSON_SERVICES_LOADING',
  FETCH_PERSON_SERVICES_SUCCESS: 'MEMBER_PORTAL/FETCH_PERSON_SERVICES_SUCCESS',
  RESET_PERSON_SERVICES: 'MEMBER_PORTAL/RESET_PERSON_SERVICES',
  SET_SELECTED_SERVICE_ITEM: 'MEMBER_PORTAL/SET_SELECTED_SERVICE_ITEM',

  SET_REDEEM_MODAL_SHOWN: 'MEMBER_PORTAL/SET_REDEEM_MODAL_SHOWN',
  RESET_REDEEM_SERVICE_ITEM: 'RESET_REDEEM_SERVICE_ITEM',

  REDEEM_SERVICE_ITEM: 'REDEEM_SERVICE_ITEM',
  REDEEM_SERVICE_ITEM_LOADING: 'REDEEM_SERVICE_ITEM_LOADING',

  SET_SELECTED_INVENTORY_ID: 'MEMBER_PORTAL/SET_SELECTED_INVENTORY_ID',
  SET_INVENTORY_DETAILS_MODAL_SHOWN: 'MEMBER_PORTAL/SET_INVENTORY_DETAILS_MODAL_SHOWN',
  FETCH_INVENTORY_DETAILS_SUCCESS: 'MEMBER_PORTAL/FETCH_INVENTORY_DETAILS_SUCCESS',
  FETCH_INVENTORY_DETAILS_LOADING: 'MEMBER_PORTAL/FETCH_INVENTORY_DETAILS_LOADING',
  RESET_INVENTORY_DETAILS: 'MEMBER_PORTAL/RESET_INVENTORY_DETAILS',
};
