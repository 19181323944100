import React, { useCallback, useMemo, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { formatPrice, getQueueColor } from 'common/utils';

import {
  ChartLegendLabels,
  MemberCountChartFilters,
  MembershipMixChartFilters,
  ReportType,
  SalesDrivenChartFilters,
} from 'modules/reports/constants/overviewReport';

import { IChartLineSettings, PeriodFilterType } from 'modules/reports/interfaces/common';

import OverviewChartLegend from '../OverviewChartLegend/OverviewChartLegend';
import CustomAxisTick from 'modules/reports/components/CustomAxisTick/CustomAxisTick';
import { ChartPeriodFilter, LoadingBackdrop } from 'common/components';

import commonMessages from 'common/messages/messages';
import { colors } from 'common/ui/theme/default';
import { DEFAULT_DATE_FORMAT } from 'common/constants/dateFormats';

interface IProps {
  reportType: ReportType;
  chartData: any;
  isChartDataLoading: boolean;
  title: string | JSX.Element;
  onPeriodChange: (chart: ReportType, period: PeriodFilterType) => void;
  selectedPeriod: PeriodFilterType;
}

const getReportLines = (reportType: ReportType): string[] => {
  switch (reportType) {
    case ReportType.SalesDriven:
      return Object.values(SalesDrivenChartFilters);
    case ReportType.MemberCount:
      return Object.values(MemberCountChartFilters);
    case ReportType.MembershipMix:
      return Object.values(MembershipMixChartFilters);
    default:
      return [];
  }
};

const OverviewChart = ({
  reportType,
  chartData,
  title,
  isChartDataLoading,
  onPeriodChange,
  selectedPeriod,
}: IProps): JSX.Element => {
  const [chartFilters, setChartFilters] = useState<string[]>(() => getReportLines(reportType));

  const [timezoneMoment] = useTimezoneMoment();

  const renderIntlMessage = useRenderIntlMessage();

  const handleChangeReportLegendFilters = useCallback(
    (legendFilters: string[]): void => setChartFilters(legendFilters),
    [],
  );

  const handleChangePeriodFilter = useCallback(
    (period: PeriodFilterType) => {
      onPeriodChange(reportType, period);
    },
    [onPeriodChange, reportType],
  );

  const chartLineSettings: IChartLineSettings[] = useMemo(() => {
    switch (reportType) {
      case ReportType.SalesDriven:
        return [
          {
            key: SalesDrivenChartFilters.MemberSales,
            name: renderIntlMessage(ChartLegendLabels[SalesDrivenChartFilters.MemberSales]),
            dataKey: SalesDrivenChartFilters.MemberSales,
            isHidden: !chartFilters.includes(SalesDrivenChartFilters.MemberSales),
            fillColor: getQueueColor(0),
          },
          {
            key: SalesDrivenChartFilters.PosSales,
            name: renderIntlMessage(ChartLegendLabels[SalesDrivenChartFilters.PosSales]),
            dataKey: SalesDrivenChartFilters.PosSales,
            isHidden: !chartFilters.includes(SalesDrivenChartFilters.PosSales),
            fillColor: getQueueColor(1),
          },
          {
            key: SalesDrivenChartFilters.PtSales,
            name: renderIntlMessage(ChartLegendLabels[SalesDrivenChartFilters.PtSales]),
            dataKey: SalesDrivenChartFilters.PtSales,
            isHidden: !chartFilters.includes(SalesDrivenChartFilters.PtSales),
            fillColor: getQueueColor(2),
          },
        ];
      case ReportType.MemberCount:
        return [
          {
            key: MemberCountChartFilters.Gaining,
            name: renderIntlMessage(ChartLegendLabels[MemberCountChartFilters.Gaining]),
            dataKey: MemberCountChartFilters.Gaining,
            isHidden: !chartFilters.includes(MemberCountChartFilters.Gaining),
            fillColor: getQueueColor(0),
          },
          {
            key: MemberCountChartFilters.Loosing,
            name: renderIntlMessage(ChartLegendLabels[MemberCountChartFilters.Loosing]),
            dataKey: MemberCountChartFilters.Loosing,
            isHidden: !chartFilters.includes(MemberCountChartFilters.Loosing),
            fillColor: getQueueColor(1),
          },
        ];
      case ReportType.MembershipMix:
        return [
          {
            key: MembershipMixChartFilters.PaidInFull,
            name: renderIntlMessage(ChartLegendLabels[MembershipMixChartFilters.PaidInFull]),
            dataKey: MembershipMixChartFilters.PaidInFull,
            isHidden: !chartFilters.includes(MembershipMixChartFilters.PaidInFull),
            fillColor: getQueueColor(0),
          },
          {
            key: MembershipMixChartFilters.FinancedInTerm,
            name: renderIntlMessage(ChartLegendLabels[MembershipMixChartFilters.FinancedInTerm]),
            dataKey: MembershipMixChartFilters.FinancedInTerm,
            isHidden: !chartFilters.includes(MembershipMixChartFilters.FinancedInTerm),
            fillColor: getQueueColor(1),
          },
          {
            key: MembershipMixChartFilters.FinancedOutTerm,
            name: renderIntlMessage(ChartLegendLabels[MembershipMixChartFilters.FinancedOutTerm]),
            dataKey: MembershipMixChartFilters.FinancedOutTerm,
            isHidden: !chartFilters.includes(MembershipMixChartFilters.FinancedOutTerm),
            fillColor: getQueueColor(2),
          },
        ];
      default:
        return [];
    }
  }, [chartFilters, renderIntlMessage, reportType]);

  const isMemberCountReportType = reportType === ReportType.MemberCount;

  const getTickFormatter = () => {
    switch (selectedPeriod) {
      case PeriodFilterType.DAY:
        return (date: string) => timezoneMoment(date, 'YYYY-MM-DD').format('MMM D');
      case PeriodFilterType.WEEK:
        return (date: string) =>
          `${renderIntlMessage(commonMessages.weekLabel)} ${Number(date.slice(4))}`;
      case PeriodFilterType.MONTH:
        return (date: string) => timezoneMoment(date, 'MMM YYYY').format('MMM');
      default:
        return (date: string) => date;
    }
  };

  const getTooltipFormatter = (date: string) => {
    switch (selectedPeriod) {
      case PeriodFilterType.DAY:
        return timezoneMoment(date, 'YYYY-MM-DD').format(DEFAULT_DATE_FORMAT);
      case PeriodFilterType.WEEK:
        return `${renderIntlMessage(commonMessages.weekLabel)} ${Number(date.slice(4))}`;
      default:
        return date;
    }
  };

  return (
    <Box p={2} position="relative">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography variant="h4" noWrap>
            {title}
          </Typography>

          <ChartPeriodFilter onPeriodChange={handleChangePeriodFilter} period={selectedPeriod} />
        </Box>

        <OverviewChartLegend reportType={reportType} onChange={handleChangeReportLegendFilters} />
      </Box>

      <ResponsiveContainer width="100%" minHeight="300px">
        <AreaChart
          width={500}
          height={368}
          data={chartData}
          margin={{
            top: 24,
            right: 8,
            left: 0,
            bottom: 6,
          }}
          style={{ fontSize: 12 }}
        >
          <CartesianGrid fill={colors.input.disabled.backgroundColor} strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            interval="preserveStartEnd"
            tickFormatter={getTickFormatter()}
            axisLine={false}
          />
          <YAxis
            tick={
              <CustomAxisTick
                formatter={price => (isMemberCountReportType ? price : formatPrice(price))}
              />
            }
            axisLine={false}
          />
          <Tooltip
            formatter={value => (isMemberCountReportType ? value : formatPrice(value as number))}
            labelFormatter={getTooltipFormatter}
          />
          {chartLineSettings.map(({ dataKey, isHidden, fillColor, name }, index) => {
            return (
              <Area
                type="monotone"
                dataKey={dataKey}
                stackId={isMemberCountReportType ? index : '1'}
                strokeWidth="2px"
                activeDot={{ r: 5 }}
                fill={fillColor}
                hide={isHidden}
                name={name}
                stroke={getQueueColor(index)}
                key={dataKey}
              />
            );
          })}
        </AreaChart>
      </ResponsiveContainer>

      <LoadingBackdrop isLoading={isChartDataLoading} />
    </Box>
  );
};

export default OverviewChart;
