import { AlertCondition } from 'common/interfaces/alerts';

import { AddressModal } from 'modules/front-desk/components';
import { UploadImageModal } from 'common/modals';
import { PastDuesResolutionModal } from 'common/components/PersonProfile/modals';
import {
  AllergiesModal,
  BirthdayModal,
  EmailModal,
  EmergencyContactModal,
  FirstNameModal,
  InsuranceInfoModal,
  LastNameModal,
  PhoneModal,
  SalespersonModal,
} from 'modules/front-desk/components/Modals';

export const ModalAlerts = {
  [AlertCondition.Birthday]: {
    isOpenName: '',
    component: null,
  },
  [AlertCondition.Anniversary]: {
    isOpenName: '',
    component: null,
  },
  [AlertCondition.MissingPhoto]: {
    isOpenName: 'isOpenUploadModal',
    component: UploadImageModal,
  },
  [AlertCondition.MissingFirstName]: {
    isOpenName: 'isOpenFirstNameModal',
    component: FirstNameModal,
  },
  [AlertCondition.MissingLastName]: {
    isOpenName: 'isOpenLastNameModal',
    component: LastNameModal,
  },
  [AlertCondition.MissingBirthDate]: {
    isOpenName: 'isOpenBirthdayModal',
    component: BirthdayModal,
  },
  [AlertCondition.MissingSSN]: {
    isOpenName: 'isOpenPersonalModal',
    component: null,
  },
  [AlertCondition.MissingPhone]: {
    isOpenName: 'isOpenPhoneModal',
    component: PhoneModal,
  },
  [AlertCondition.MissingEmail]: {
    isOpenName: 'isOpenEmailModal',
    component: EmailModal,
  },
  [AlertCondition.MissingAddress]: {
    isOpenName: 'isOpenAddressModal',
    component: AddressModal,
  },
  [AlertCondition.NotRegisteredForClubLife]: {
    isOpenName: '',
    component: null,
  },
  [AlertCondition.CreditCardExpired]: {
    isOpenName: 'isOpenCreditCardModal',
    component: null,
  },
  [AlertCondition.UnsignedWaiver]: {
    isOpenName: 'isOpenWaiverModal',
    component: null,
  },
  [AlertCondition.MemberWillExpireSoon]: {
    isOpenName: '',
    component: null,
  },
  [AlertCondition.MemberIsFrozen]: {
    isOpenName: '',
    component: null,
  },
  [AlertCondition.PastDuePayments]: {
    isOpenName: 'isOpenPastDuesModal',
    component: PastDuesResolutionModal,
  },
  [AlertCondition.NotAllowedAtThisLocation]: {
    isOpenName: '',
    component: null,
  },
  [AlertCondition.NotAllowedAtThisDayTime]: {
    isOpenName: '',
    component: null,
  },
  [AlertCondition.InactiveMember]: {
    isOpenName: '',
    component: null,
  },
  [AlertCondition.UpcomingAppointment]: {
    isOpenName: '',
    component: null,
  },
  [AlertCondition.MissedAppointment]: {
    isOpenName: '',
    component: null,
  },
  [AlertCondition.MissingInsuranceInfo]: {
    isOpenName: 'isOpenInsuranceInfoModal',
    component: InsuranceInfoModal,
  },
  [AlertCondition.MissingAllergies]: {
    isOpenName: 'isOpenAllergiesModal',
    component: AllergiesModal,
  },
  [AlertCondition.MissingEmergencyContactInfo]: {
    isOpenName: 'isOpenEmergencyContactInfoModal',
    component: EmergencyContactModal,
  },
  [AlertCondition.MissingBillingInfo]: {
    isOpenName: 'isOpenCreditCardModal',
    component: null,
  },
  [AlertCondition.Note]: {
    isOpenName: 'isOpenNoteModal',
    component: null,
  },
  [AlertCondition.MissingSalesperson]: {
    isOpenName: 'isOpenSalespersonModal',
    component: SalespersonModal,
  },
};
