import { AppStoreTypeImt } from 'store';

const selectCRMTemplatesLoading = (state: AppStoreTypeImt) =>
  state.getIn(['crmModule', 'documentTemplates', 'templates', 'isLoading']);
const selectCRMTemplatesList = (state: AppStoreTypeImt) =>
  state.getIn(['crmModule', 'documentTemplates', 'templates', 'list']);
const selectCRMTemplatesListMeta = (state: AppStoreTypeImt) =>
  state.getIn(['crmModule', 'documentTemplates', 'templates', 'meta']);
const selectTemplate = (state: AppStoreTypeImt) =>
  state.getIn(['crmModule', 'documentTemplates', 'template', 'result']);
const selectGetTemplateIsLoading = (state: AppStoreTypeImt) =>
  state.getIn(['crmModule', 'documentTemplates', 'template', 'isLoading']);
const selectSaveDocumentTemplateLoading = (state: AppStoreTypeImt) =>
  state.getIn(['crmModule', 'documentTemplates', 'template', 'saveTemplateLoading']);
const selectSaveTemplateActionResult = (state: AppStoreTypeImt) =>
  state.getIn(['crmModule', 'documentTemplates', 'template', 'saveTemplateActionResult']);
const selectUpdateCrmTemplateStatusIsLoading = (state: AppStoreTypeImt) =>
  state.getIn(['crmModule', 'documentTemplates', 'templates', 'updateStatusIsLoading']);
const selectUpdateCrmTemplateStatusActionResult = (state: AppStoreTypeImt) =>
  state.getIn(['crmModule', 'documentTemplates', 'templates', 'updateStatusActionResult']);

export {
  selectGetTemplateIsLoading,
  selectCRMTemplatesLoading,
  selectCRMTemplatesList,
  selectCRMTemplatesListMeta,
  selectTemplate,
  selectSaveDocumentTemplateLoading,
  selectSaveTemplateActionResult,
  selectUpdateCrmTemplateStatusIsLoading,
  selectUpdateCrmTemplateStatusActionResult,
};
