import { ErrorCode } from 'react-dropzone';

import { ConstOptions } from 'common/constants/classes';

import commonFileErrorMessages from 'common/messages/fileErrors';

export const FileErrorTypes = new ConstOptions([
  {
    key: ErrorCode.FileInvalidType,
    value: ErrorCode.FileInvalidType,
    message: commonFileErrorMessages.fileInvalidTypeError,
  },
  {
    key: ErrorCode.FileTooLarge,
    value: ErrorCode.FileTooLarge,
    message: commonFileErrorMessages.fileTooLargeWithSizeError,
  },
  {
    key: ErrorCode.FileTooSmall,
    value: ErrorCode.FileTooSmall,
    message: commonFileErrorMessages.fileTooSmallError,
  },
  {
    key: ErrorCode.TooManyFiles,
    value: ErrorCode.TooManyFiles,
    message: commonFileErrorMessages.tooManyFilesError,
  },
]);
