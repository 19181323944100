export enum ReportType {
  Sales = 'SALES',
  Orders = 'ORDERS',
}

export const actionTypes = {
  // Sales Over Time
  FETCH_SALES_OVER_TIME_LOADING: 'FETCH_SALES_OVER_TIME_LOADING',
  FETCH_SALES_OVER_TIME: 'FETCH_SALES_OVER_TIME',
  FETCH_SALES_OVER_TIME_ERROR: 'FETCH_SALES_OVER_TIME_ERROR',
  FETCH_SALES_OVER_TIME_ACTIVITY_LOADING: 'FETCH_SALES_OVER_TIME_ACTIVITY_LOADING',
  FETCH_SALES_OVER_TIME_ACTIVITY: 'FETCH_SALES_OVER_TIME_ACTIVITY',
  FETCH_SALES_OVER_TIME_ACTIVITY_ERROR: 'FETCH_SALES_OVER_TIME_ACTIVITY_ERROR',
};
