import { useState } from 'react';
import { useSelector } from 'react-redux';

import useComponentDidUpdate from 'common/hooks/useComponentDidUpdate';
import { selectPrintHTMLLoading } from 'common/state/printHTML/selectors';

export const useCombinePrintLoading = (isLoading: boolean) => {
  const isPrintLoading: boolean = useSelector(selectPrintHTMLLoading);

  const [isPrinting, setIsPrinting] = useState(false);

  // start request
  useComponentDidUpdate(() => {
    if (isLoading) {
      setIsPrinting(true);
    }
  }, [isLoading]);

  // finish printing
  useComponentDidUpdate(() => {
    if (!isPrintLoading) {
      setIsPrinting(false);
    }
  }, [isPrintLoading]);

  return isPrinting;
};
