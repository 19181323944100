import React from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import { DurationTypes } from 'modules/services/constants/packages';

import { FieldInfo } from 'common/components/index';
import { ReactComponent as CrossIcon } from 'img/icons/times.svg';
import { ReactComponent as CheckIcon } from 'img/icons/check.svg';

import servicesMessages from 'modules/services/messages/messages';
import { IPaymentGrace } from 'modules/services/interfaces/packages';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gracePeriodStatus: {
      display: 'flex',
      alignItems: 'center',
    },
    statusIcon: {
      width: '1rem',
      height: '1rem',
      marginLeft: theme.spacing(1),

      '&.primary': {
        color: theme.palette.primary.main,
      },
      '&.error': {
        color: theme.palette.error.main,
      },
    },
  }),
);

interface IProps {
  paymentGrace: IPaymentGrace;
}

const GracePeriodSectionView = ({ paymentGrace }: IProps): JSX.Element => {
  const renderIntlMessage = useRenderIntlMessage();

  const classes = useStyles();

  const {
    allow: paymentGraceAllow,
    duration: paymentGraceDuration,
    editableDurationNumber: { value: editableDurationNumber },
  } = paymentGrace;

  return (
    <>
      <Grid item xs={12} className={classes.gracePeriodStatus}>
        <Typography component="p" variant="h5">
          <FormattedMessage {...servicesMessages.paymentGracePeriodLabel} />
        </Typography>

        {paymentGraceAllow ? (
          <CheckIcon className={cx(classes.statusIcon, 'primary')} />
        ) : (
          <CrossIcon className={cx(classes.statusIcon, 'error')} />
        )}
      </Grid>

      {paymentGraceAllow && (
        <Grid item xs={4}>
          <FieldInfo
            label={<FormattedMessage {...servicesMessages.durationLabel} />}
            description={`${editableDurationNumber} ${renderIntlMessage(
              DurationTypes.message(paymentGraceDuration),
            )}`}
          />
        </Grid>
      )}
    </>
  );
};

export default GracePeriodSectionView;
