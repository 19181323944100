import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';
import * as actionTypes from '../../constants/packageActionTypes';

const initialServicePackagesState = fromJS({
  list: [],
  isLoading: false,
});

export function servicePackagesReducer(state = initialServicePackagesState, action) {
  switch (action.type) {
    case actionTypes.GET_SERVICE_PACKAGES: {
      return state.set('list', fromJS(action.payload.data));
    }
    case actionTypes.GET_SERVICE_PACKAGES_LOADING: {
      return state.set('isLoading', action.payload);
    }
    case actionTypes.CLEAR_SERVICE_PACKAGES: {
      return initialServicePackagesState;
    }
    default: {
      return state;
    }
  }
}

const initialMembershipPackagesState = fromJS({
  list: [],
  isLoading: false,
});

export function membershipPackagesReducer(state = initialMembershipPackagesState, action) {
  switch (action.type) {
    case actionTypes.GET_MEMBERSHIP_PACKAGES: {
      return state.set('list', fromJS(action.payload.data));
    }
    case actionTypes.GET_MEMBERSHIP_PACKAGES_LOADING: {
      return state.set('isLoading', action.payload);
    }
    case actionTypes.CLEAR_MEMBERSHIP_PACKAGES: {
      return initialMembershipPackagesState;
    }
    default: {
      return state;
    }
  }
}

export default combineReducers({
  servicePackages: servicePackagesReducer,
  membershipPackages: membershipPackagesReducer,
});
