export const actionTypes = {
  FETCH_KIDZONE_CHECK_IN_HISTORY_STATISTICS: 'REPORTS/FETCH_KIDZONE_CHECK_IN_HISTORY_STATISTICS',
  FETCH_KIDZONE_CHECK_IN_HISTORY_STATISTICS_LOADING:
    'REPORTS/FETCH_KIDZONE_CHECK_IN_HISTORY_STATISTICS_LOADING',
  RESET_KIDZONE_CHECK_IN_HISTORY_STATISTICS: 'REPORTS/RESET_KIDZONE_CHECK_IN_HISTORY_STATISTICS',

  FETCH_KIDZONE_CHECK_IN_HISTORY_ACTIVITY: 'REPORTS/FETCH_KIDZONE_CHECK_IN_HISTORY_ACTIVITY',
  SET_KIDZONE_CHECK_IN_HISTORY_CHART_DATA: 'REPORTS/SET_KIDZONE_CHECK_IN_HISTORY_CHART_DATA',
  FETCH_KIDZONE_CHECK_IN_HISTORY_ACTIVITY_LOADING:
    'REPORTS/FETCH_KIDZONE_CHECK_IN_HISTORY_ACTIVITY_LOADING',
  RESET_KIDZONE_CHECK_IN_HISTORY_ACTIVITY: 'REPORTS/RESET_KIDZONE_CHECK_IN_HISTORY_ACTIVITY',

  FETCH_KIDZONE_CHECK_IN_HISTORY_REPORT_ITEMS:
    'REPORTS/FETCH_KIDZONE_CHECK_IN_HISTORY_REPORT_ITEMS',
  FETCH_KIDZONE_CHECK_IN_HISTORY_REPORT_ITEMS_LOADING:
    'REPORTS/FETCH_KIDZONE_CHECK_IN_HISTORY_REPORT_ITEMS_LOADING',
  RESET_KIDZONE_CHECK_IN_HISTORY_REPORT_ITEMS:
    'REPORTS/RESET_KIDZONE_CHECK_IN_HISTORY_REPORT_ITEMS',
};
