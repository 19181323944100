export enum SocketMessageType {
  CHECK_IN = 'CHECK_IN',
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  UPDATED_EVENTS = 'UPDATED_EVENTS',
  DASHBOARD_EVENT = 'DASHBOARD_EVENT',
  INVOICE_UPDATE = 'INVOICE_UPDATE',
  INVOICE_REMOVE = 'INVOICE_REMOVE',
  INVENTORY_CLUB_UPDATE = 'INVENTORY_CLUB_UPDATE',
}

export interface ISocketMessage<T> {
  data: T;
  type: SocketMessageType;
}
