import { AppStoreTypeImt } from 'store';
import { personsListPath } from 'common/components/PersonProfile/state/selectors';
import { ActionResult } from 'common/constants';
import {
  ITicketDetailedDtoImt,
  TicketShortDtoImtList,
} from 'common/components/PersonProfile/interfaces';

export const selectPersonTickets = (personId: string) => (
  state: AppStoreTypeImt,
): TicketShortDtoImtList => {
  return state.getIn([...personsListPath, personId, 'tickets']);
};
export const selectPersonTicketsLoading = (personId: string) => (
  state: AppStoreTypeImt,
): boolean => {
  return state.getIn([...personsListPath, personId, 'ticketsLoading']);
};

export const selectPersonTicket = (personId: string) => (
  state: AppStoreTypeImt,
): ITicketDetailedDtoImt => {
  return state.getIn([...personsListPath, personId, 'ticket']);
};
export const selectPersonTicketLoading = (personId: string) => (
  state: AppStoreTypeImt,
): boolean => {
  return state.getIn([...personsListPath, personId, 'ticketLoading']);
};

export const selectAddPersonTicketActionResult = (personId: string) => (
  state: AppStoreTypeImt,
): ActionResult => {
  return state.getIn([...personsListPath, personId, 'createTicketResult']);
};
export const selectAddPersonTicketLoading = (personId: string) => (
  state: AppStoreTypeImt,
): boolean => state.getIn([...personsListPath, personId, 'createTicketLoading']);
export const selectTicketsError = (personId: string) => (state: AppStoreTypeImt): Error | null =>
  state.getIn([...personsListPath, personId, 'ticketsError']);
