import { createAction } from '@reduxjs/toolkit';

import { ActionResult } from 'common/constants';
import { actionTypes } from './constants';
import { ITicketCreateDto } from 'common/components/PersonProfile/interfaces';
import { GeneralThunkAction } from 'common/state/interfaces';
import Services from 'services/network';
import { enqueueErrorNotification } from 'common/state/notifications/actions';

export const addTicketSuccessAction = createAction<ActionResult | null>(
  actionTypes.ADD_TICKET_SUCCESS,
);
const addTicketLoadingAction = createAction<boolean>(actionTypes.ADD_TICKET_LOADING);

export const addTicket = (attachments: File[], dto: ITicketCreateDto): GeneralThunkAction => {
  return async dispatch => {
    dispatch(addTicketLoadingAction(true));

    try {
      await Services.PeakContacts.createTicket(attachments, dto);

      dispatch(addTicketSuccessAction(ActionResult.SUCCESS_ACTION));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(addTicketLoadingAction(false));
    }
  };
};
