import { Subscriber } from 'common/interfaces/webHid';

export default class SubscribingService {
  private subscribers: { [key: string]: Subscriber[] } = {};

  protected addSubscriber(eventName: string, subscriber: Subscriber): void {
    if (!this.subscribers[eventName]) {
      this.subscribers[eventName] = [];
    }
    this.subscribers[eventName].push(subscriber);
  }

  protected removeSubscriber(eventName: string, subscriber: Subscriber): void {
    if (!this.subscribers[eventName]) {
      return;
    }

    const index = this.subscribers[eventName].indexOf(subscriber);
    if (index < 0) {
      return;
    }

    this.subscribers[eventName].splice(index, 1);
  }

  protected triggerEvent(eventName: string, data: any) {
    const subscribers = this.subscribers[eventName];
    if (!subscribers) return;

    for (let i = 0, ln = subscribers.length; i < ln; i += 1) {
      subscribers[i](data);
    }
  }
}
