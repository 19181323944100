// libraries
import React, { useCallback, useEffect } from 'react';
import clsx from 'clsx';
// components
import {
  Box,
  ButtonBase,
  InputBase,
  MenuItem,
  Paper,
  Popper,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  ArrowDropDown as DownIcon,
  ArrowDropUp as UpIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete, { AutocompleteCloseReason } from '@material-ui/lab/Autocomplete';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Incognito } from 'img/spy.svg';
// components
import { ListboxComponent } from 'common/components';
// messages
import messages from './messages';
import commonMessages from 'common/messages/messages';
// styles
import { CustomTheme } from 'common/ui/interfaces';
import SelectedUserOption, {
  userAutocompleteIconAvatarStyles,
  userAutocompleteIconTextStyles,
} from './SelectedUserOption';

const useStyles = makeStyles((theme: CustomTheme) => ({
  search: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 200,
    position: 'relative',
    height: 36,
  },
  wrapIcon: {
    marginLeft: 14,
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
  wrapSearchIcon: {
    pointerEvents: 'none',
  },
  searchIconIcon: {
    width: '16px',
    height: '16px',
    color: theme.palette.text.secondary,
    opacity: 0.7,
  },
  inputBase: {
    width: '100%',
    borderBottom: '1px solid #dfe2e5',
    '& input': {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: '8px 8px 8px 37px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontSize: 14,
    },
  },
  itemInput: {
    pointerEvents: 'none',
    '& .MuiOutlinedInput-input': {
      opacity: 0,
    },
  },
  button: {
    width: '100%',
    textAlign: 'left',
    paddingBottom: 0,
  },
  dropIcon: {
    position: 'absolute',
    right: 8,
  },
  popper: {
    border: '1px solid #d6d6d6',
    boxShadow: theme.shadows[5],
    width: '100%',
    zIndex: 10,
    fontSize: 14,
    color: '#d6d6d6',
    backgroundColor: theme.palette.background.paper,
    marginTop: -2,
    marginLeft: 1,
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
  },
  paper: {
    margin: 0,
    color: '#586069',
    fontSize: 13,
    boxShadow: 'none',
  },
  option: {
    minHeight: 'auto',
    padding: '8px 8px 8px 14px',
    alignItems: 'center',
    '&[data-focus="true"] .guest-status': {
      color: theme.palette.primary.contrastText,
    },
  },
  popperDisablePortal: {
    position: 'relative',
  },
  listBox: {
    borderTop: `1px solid ${theme.palette.secondary.light}`,
  },
  color: {
    width: 14,
    height: 14,
    flexShrink: 0,
    borderRadius: 3,
    marginRight: 8,
    marginTop: 2,
  },
  addOption: {
    padding: `${theme.spacing(1.5, 2)}!important`,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: theme.spacing(1),
    '&.MuiMenuItem-root.Mui-selected': {
      color: theme.palette.background.paper,
      fill: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
    },
    '&.MuiMenuItem-root:hover,&.MuiMenuItem-root:active': {
      color: `${theme.palette.background.paper}!important`,
      fill: theme.palette.background.paper,
      '& >div, >p': {
        color: `${theme.palette.background.paper}!important`,
      },
    },
  },
  notInSystem: {
    marginBottom: 0,
    borderBottom: 'unset',
    marginTop: theme.spacing(1),
  },
  addOptionIcon: {
    marginRight: theme.spacing(1),
    width: '16px',
    height: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.4rem',
    color: theme.palette.primary.main,
  },
  addOptionText: {
    fontWeight: 500,
  },

  iconAvatar: userAutocompleteIconAvatarStyles(theme),
  itemText: userAutocompleteIconTextStyles(),
}));

interface ISearchUsersAutocompleteProps {
  searchPlaceholder?: string;
  label?: string | JSX.Element;
  loading?: boolean;
  selectedValue: any;
  onChange?: (event) => void;
  getOptionsByValue: (event) => void;
  resetOptions?: () => void;
  renderOption: (any) => React.ReactNode;
  options: Array<any>;
  onCreateNew?: () => void;
  disabled?: boolean;
  hideNOS?: boolean;
  isNOSOptionDisabled?: boolean;
  hasStartSearchMode?: boolean;
  error?: boolean;
}

let timeoutId = null;

const SearchUsersAutocompleteSelect = (props: ISearchUsersAutocompleteProps): JSX.Element => {
  const {
    selectedValue,
    options,
    renderOption,
    searchPlaceholder,
    label,
    getOptionsByValue,
    loading,
    onChange,
    onCreateNew,
    disabled,
    hideNOS,
    isNOSOptionDisabled,
    hasStartSearchMode,
    resetOptions,
    error,
  } = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [valueState, setValueState] = React.useState<any>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.ChangeEvent<any>, reason: AutocompleteCloseReason) => {
    if (reason === 'toggleInput') {
      return;
    }
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
    setValueState(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'github-label' : undefined;

  const loadOptions = useCallback(
    value => {
      getOptionsByValue(value);
    },
    [getOptionsByValue],
  );

  const onChangeSearch = e => {
    const { value } = e.target;
    setValueState(value);
    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => loadOptions(value), 500);
  };

  useEffect(() => {
    if (open && !valueState && !hasStartSearchMode) {
      loadOptions('');
    }
  }, [loadOptions, open, valueState, hasStartSearchMode]);

  const ListboxProps = {
    itemSize: 45,
    limitCount: 8,
  };

  const DropIcon = open ? (
    <UpIcon className={classes.dropIcon} />
  ) : (
    <DownIcon className={classes.dropIcon} />
  );

  // renders

  return (
    <div style={{ position: 'relative' }}>
      <ButtonBase
        disableRipple
        className={classes.button}
        aria-describedby={id}
        onClick={handleClick}
        disabled={disabled}
      >
        <TextField
          variant="outlined"
          label={label}
          value="1"
          style={{ color: 'white' }}
          className={classes.itemInput}
          fullWidth
          error={error}
        />

        <SelectedUserOption hideNOS={hideNOS} selectedValue={selectedValue} />

        {disabled || DropIcon}
      </ButtonBase>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        className={classes.popper}
        disablePortal
      >
        <Autocomplete
          open
          fullWidth
          onClose={handleClose}
          classes={{
            paper: classes.paper,
            option: classes.option,
            popperDisablePortal: classes.popperDisablePortal,
            listbox: classes.listBox,
          }}
          onChange={(event, newValue) => {
            onChange(newValue);
            if (anchorEl) {
              anchorEl.focus();
            }
            setAnchorEl(null);
          }}
          disableCloseOnSelect
          ListboxComponent={ListboxComponent}
          ListboxProps={ListboxProps}
          disablePortal
          options={options}
          renderTags={() => null}
          noOptionsText={<FormattedMessage {...messages.noDataLabel} />}
          renderOption={renderOption}
          getOptionLabel={option => `${option.firstName} ${option.lastName}`}
          loading={loading}
          renderInput={params => (
            <Box className={classes.search}>
              <Box className={`${classes.wrapIcon} ${classes.wrapSearchIcon}`}>
                <SearchIcon className={classes.searchIconIcon} />
              </Box>
              <InputBase
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                placeholder={searchPlaceholder}
                className={classes.inputBase}
                onChange={onChangeSearch}
                onBlur={() => resetOptions?.()}
                onFocus={e => {
                  if (hasStartSearchMode) onChangeSearch(e);
                }}
                autoFocus
              />
            </Box>
          )}
          PaperComponent={({ children, ...other }) => {
            return (
              <Paper {...other}>
                {!valueState && !hideNOS && !hasStartSearchMode ? (
                  <>
                    {!hideNOS && (
                      <MenuItem
                        className={clsx(classes.addOption, classes.notInSystem)}
                        onMouseDown={e => e.preventDefault()}
                        onClick={() => {
                          onChange(undefined);
                          setAnchorEl(null);
                        }}
                        //  selected={!selectedValue}
                        disabled={isNOSOptionDisabled}
                      >
                        <Incognito className={classes.iconAvatar} />
                        <Typography align="center" className={classes.itemText}>
                          <FormattedMessage {...messages.notInSystem} />
                        </Typography>
                      </MenuItem>
                    )}
                    {onCreateNew && (
                      <MenuItem
                        className={classes.addOption}
                        onMouseDown={e => e.preventDefault()}
                        onClick={onCreateNew}
                      >
                        <Box className={classes.addOptionIcon}>+</Box>
                        <Typography className={classes.addOptionText} color="primary">
                          <FormattedMessage {...commonMessages.newBtn} />
                        </Typography>
                      </MenuItem>
                    )}
                  </>
                ) : (
                  children
                )}
              </Paper>
            );
          }}
        />
      </Popper>
    </div>
  );
};

export default SearchUsersAutocompleteSelect;
