// libraries
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import { List } from 'immutable';
import classnames from 'classnames';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { Button, Grid, Typography, makeStyles } from '@material-ui/core';
import { CalendarApi, DatesSetArg } from '@fullcalendar/common';
// custom interfaces
import { CustomTheme } from 'common/ui/interfaces';
import { IServiceEventsCalendarParams } from 'common/components/PersonProfile/interfaces';
import { IUserOrganizationImt } from 'common/interfaces/clients';

import { BookingCalendar } from 'modules/booking/components';

import * as actions from 'common/components/PersonProfile/state/servicesModals/actions';
import * as selectors from 'common/components/PersonProfile/state/servicesModals/selectors';
import { selectUserSelectedOrganization } from 'modules/authentication/state/selectors';

import { PeakModules } from 'common/constants/peakModules';

import useComponentDidUpdate from 'common/hooks/useComponentDidUpdate';
import { useAppDispatch } from 'store/hooks';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';

import messages from 'modules/booking/messages';
import { snackbar } from 'common/utils/snackbarUtils';

interface IServiceEventsCalendarProps {
  serviceId: string;
  personId: string;
  module: PeakModules;
  onSelectEvent: (eventId: string, eventDateTime: string) => void;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    maxHeight: '300px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  btn: {
    padding: theme.spacing(1, 1.5),
  },
  btnFirst: {
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
  },
  btnLast: {
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
  },
  btnCenter: {
    borderRadius: '0',
  },
  navBtn: {
    minWidth: '32px',
    width: '32px',
    height: '32px',
  },
}));

/* Represents a calendar with all event for a selected service */
const ServiceEventsCalendar: React.FC<IServiceEventsCalendarProps> = ({
  serviceId,
  personId,
  onSelectEvent,
  module,
}: IServiceEventsCalendarProps): JSX.Element => {
  const selectedClub: IUserOrganizationImt = useSelector(selectUserSelectedOrganization);
  const clubTimezone = selectedClub.get('timezone');

  const calendarRef = useRef(null);

  const [timezoneMoment] = useTimezoneMoment();

  const [calendarParams, setCalendarParams] = useState<IServiceEventsCalendarParams>({
    serviceId,
    rangeStartDate: timezoneMoment()
      .startOf('day')
      .utc(true)
      .format(),
    rangeEndDate: timezoneMoment()
      .endOf('day')
      .utc(true)
      .format(),
  });

  // state

  const dispatch = useAppDispatch();

  const events = useSelector(selectors.selectServiceEvents);
  const isEventsLoading: boolean = useSelector(selectors.selectServiceEventsLoading);

  const classes = useStyles();

  // handlers

  const handleEventClick = ({ eventId, startDate }) => {
    const eventDate = moment.utc(startDate);

    const hoursBeforeEvent = eventDate.diff(moment(), 'hours');

    if (hoursBeforeEvent < 2) {
      onSelectEvent(eventId, startDate);
    } else {
      snackbar.toast(<FormattedMessage {...messages.canNotRedeemEventMsg} />);
    }
  };

  const handleCalendarNav = (direction: string): void => {
    const calendarApi: CalendarApi = calendarRef.current.getApi();

    switch (direction) {
      case 'prev':
        return calendarApi.prev();
      case 'next':
        return calendarApi.next();
      default:
        return calendarApi.today();
    }
  };

  const handleDateRangeChange = useCallback(({ timeZone, startStr, endStr }: DatesSetArg) => {
    setCalendarParams(prevState => ({
      ...prevState,
      rangeStartDate: moment
        .tz(startStr, timeZone)
        .utc(true)
        .format(),
      rangeEndDate: moment
        .tz(endStr, timeZone)
        .utc(true)
        .format(),
    }));
  }, []);

  // effects

  useComponentDidUpdate(() => {
    dispatch(actions.fetchServiceEvents(personId, calendarParams, module));
  }, [calendarParams, dispatch, personId]);

  useEffect(() => {
    return () => {
      dispatch(actions.resetServiceEvents());
      dispatch(actions.resetServiceEventDetails());
    };
  }, [dispatch]);

  // renders

  const renderHeader = () => (
    <Grid container className={classes.header}>
      <Grid item>
        <Button
          className={classes.btn}
          variant="contained"
          onClick={() => handleCalendarNav('today')}
        >
          <FormattedMessage {...messages.todayLabel} />
        </Button>
      </Grid>

      <Grid item>
        <Typography variant="h3">
          {timezoneMoment(calendarParams.rangeStartDate.slice(0, -1))
            .utc(false)
            .format('MMMM Do')}
        </Typography>
      </Grid>

      <Grid item>
        <Grid container spacing={1} wrap="nowrap">
          <Grid item>
            <Button
              className={classnames(classes.btn, classes.navBtn)}
              variant="contained"
              onClick={() => handleCalendarNav('prev')}
            >
              <ChevronLeft />
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classnames(classes.btn, classes.navBtn)}
              variant="contained"
              onClick={() => handleCalendarNav('next')}
            >
              <ChevronRight />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Grid item xs={12}>
        {renderHeader()}
      </Grid>
      <Grid item xs={12} className={classes.container}>
        <BookingCalendar
          ref={calendarRef}
          data={events || List()}
          initialView="timeGridDay"
          isLoading={isEventsLoading}
          onEventClick={handleEventClick}
          onDatesRangeChange={handleDateRangeChange}
          timezone={clubTimezone}
        />
      </Grid>
    </>
  );
};

export default ServiceEventsCalendar;
