import { ConstOptions } from './classes';
import { EAutomaticManualType } from 'common/interfaces/common';
import messages from 'common/messages/messages';

const automaticManual = new ConstOptions([
  { value: EAutomaticManualType[0], message: messages.automaticLabel },
  { value: EAutomaticManualType[1], message: messages.manualLabel },
]);

const transformAutomaticManualToBoolean = (value: string): boolean =>
  value === EAutomaticManualType[0];

export const automaticManualOptions = automaticManual.getFilterOptions({
  valueFormatter: transformAutomaticManualToBoolean,
});
