import { PoliciesPermissionSetItems } from 'common/constants/permissions';
import { TPermissionContext } from 'common/contexts/PermissionContext';

export function getInitialPermissionContext(): TPermissionContext {
  const permissionContext: Partial<TPermissionContext> = {};

  for (const pKey of Object.keys(PoliciesPermissionSetItems)) {
    permissionContext[pKey] = false;
  }

  return permissionContext as TPermissionContext;
}
