import React, { useState } from 'react';
import { BlockPicker, BlockPickerProps } from 'react-color';
import cx from 'classnames';
import { Box, FormHelperText, makeStyles, Popover } from '@material-ui/core';
import { colors as themeColors } from 'common/ui/theme/default';

const useStyles = makeStyles(() => ({
  picker: {
    display: 'flex',
    alignItems: 'center',
  },
  pickerBtn: {
    width: '16px',
    height: '16px',
    borderRadius: '50%',
  },
  pickerContainer: {
    '&>div:nth-last-of-type(n+2)': {
      display: 'none!important',
    },
    '& span>div': {
      borderRadius: '100%!important',
    },
  },
}));

interface IProps extends BlockPickerProps {
  value: any;
  onChange: (value) => void;
  className?: string;
  error?: boolean;
  helperText?: JSX.Element | string;
}

const ColorPicker = (props: IProps): JSX.Element => {
  const { onChange, value, className, error, helperText } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const colors = [
    themeColors.colorPicker.plumPerfect,
    themeColors.colorPicker.dogwoodRose,
    themeColors.colorPicker.rossoCorsa,
    themeColors.colorPicker.geraldine,

    themeColors.colorPicker.scarletIbis,
    themeColors.colorPicker.royalFlycatcherCrest,
    themeColors.colorPicker.saffronGold,
    themeColors.colorPicker.goldenLock,

    themeColors.colorPicker.superLemon,
    themeColors.colorPicker.fluorescentLime,
    themeColors.colorPicker.sparklingApple,
    themeColors.colorPicker.buddhaGreen,

    themeColors.colorPicker.pharmaceuticalGreen,
    themeColors.colorPicker.intenseTeal,
    themeColors.colorPicker.pervenche,
    themeColors.colorPicker.seljukBlue,

    themeColors.colorPicker.earlySpringNight,
    themeColors.colorPicker.violetsAreBlue,
    themeColors.colorPicker.violetVelvet,
    themeColors.colorPicker.softPurple,

    themeColors.colorPicker.passionatePurple,
    themeColors.colorPicker.vandyckBrown,
    themeColors.colorPicker.shadesOn,
    themeColors.colorPicker.foggyNight,

    themeColors.colorPicker.coralReef,
    themeColors.colorPicker.oceanBreeze,
    themeColors.colorPicker.lavenderMist,
    themeColors.colorPicker.electricSunrise,

    themeColors.colorPicker.defaultColor,
    themeColors.colorPicker.solarFlare,
  ];

  const onOpenPicker = (e: React.MouseEvent<any>): void => {
    setAnchorEl(e.currentTarget);
  };

  const onClosePicker = () => {
    setAnchorEl(null);
  };

  const onChangeColor = updatedColor => {
    onChange(updatedColor.hex);
  };

  return (
    <Box className={cx(classes.picker, className)}>
      <Box
        className={classes.pickerBtn}
        style={{ backgroundColor: value }}
        onClick={onOpenPicker}
      />

      <Popover
        id="event-color-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={onClosePicker}
      >
        <BlockPicker
          className={classes.pickerContainer}
          color={value}
          onChange={onChangeColor}
          onChangeComplete={() => {
            setAnchorEl(null);
          }}
          styles={{
            default: {
              card: {
                width: '202px',
              },
              input: {
                display: 'none',
              },
              body: {
                paddingBottom: 0,
              },
            },
          }}
          colors={colors}
        />
      </Popover>

      {!!error && <FormHelperText error>{helperText}</FormHelperText>}
    </Box>
  );
};

export default ColorPicker;
