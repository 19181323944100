import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, makeStyles, Typography } from '@material-ui/core';

import { CustomTheme } from 'common/ui/interfaces';

const useStyles = makeStyles((theme: CustomTheme) => ({
  searchResultItem: {
    margin: theme.spacing(0, -1),
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  avatar: {
    fontWeight: 700,
    color: theme.palette.darkBackground.light,
    backgroundColor: theme.palette.secondary.light,
  },
  itemLabel: {
    fontWeight: 600,
    marginBottom: theme.spacing(0.5),
  },
  itemDescription: {
    lineHeight: '1',
    opacity: 1,
  },
}));

interface IProps {
  label: string;
  imageUrl?: string;
  viewDetailsPath?: string;
  defaultIcon?: JSX.Element;
  description?: string | JSX.Element;
  additionalElement?: JSX.Element;
}

const getPersonAvatarAlt = (personTitle: string) => {
  const names = personTitle.split(' ');

  return `${names[0].charAt(0).toUpperCase()}${names[1].charAt(0).toUpperCase()}`;
};

const SearchResultItem = ({
  label,
  description,
  imageUrl,
  defaultIcon,
  additionalElement,
  viewDetailsPath,
}: IProps): JSX.Element => {
  const navigate = useNavigate();

  const classes = useStyles();

  return (
    <Box
      display="flex"
      p={1}
      mt={1.75}
      onClick={() => navigate(viewDetailsPath)}
      className={classes.searchResultItem}
    >
      <Avatar variant="rounded" src={imageUrl} alt={label} className={classes.avatar}>
        {defaultIcon || getPersonAvatarAlt(label)}
      </Avatar>

      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" ml={1.5}>
        <Box display="flex" flexDirection="column" maxWidth="305px">
          <Typography className={classes.itemLabel}>{label}</Typography>
          {React.isValidElement(description) ? (
            description
          ) : (
            <Typography
              noWrap
              variant="caption"
              color="textSecondary"
              className={classes.itemDescription}
            >
              {description}
            </Typography>
          )}
        </Box>

        <Box>{additionalElement}</Box>
      </Box>
    </Box>
  );
};

export default SearchResultItem;
