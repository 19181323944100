import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';

import { ICampaignListItemImt } from 'modules/crm/interfaces/campaigns';
import { ITableParams } from 'common/interfaces/table';
import { FilterTypes, IFilterSettings } from 'common/interfaces/filter';
import { IPageMetaImt } from 'common/interfaces/pagination';

import { QueryPageList, YesNoStatus } from 'common/constants';
import { PeakModules } from 'common/constants/peakModules';
import filtersMessages from 'common/messages/tableFilters';
import commonMessages from 'common/messages/messages';
import { CampaignStatuses, CampaignTypes } from 'modules/crm/constants/campaigns';

import { RouteBackground } from 'components';
import { CampaignsTable } from 'modules/crm/components';

import { updateQueryParams } from 'common/state/queryPage-lists/actions';

import { makeTableParams, pushQueryToUrl } from 'common/utils/http';
import { useAppDispatch } from 'store/hooks';

import * as actions from 'modules/crm/state/campaigns/actions';

import * as selectors from 'modules/crm/state/campaigns/selectors';

const tableFilters: IFilterSettings[] = [
  {
    name: 'type',
    title: <FormattedMessage {...filtersMessages.type} />,
    type: FilterTypes.SINGLE,
    options: CampaignTypes.getFilterOptions(),
  },
  {
    name: 'status',
    title: <FormattedMessage {...filtersMessages.status} />,
    type: FilterTypes.SINGLE,
    options: CampaignStatuses.getFilterOptions(),
  },
  {
    name: 'automatic',
    title: <FormattedMessage {...commonMessages.automaticLabel} />,
    type: FilterTypes.SINGLE,
    options: YesNoStatus.map(status => ({
      key: status.key,
      label: status.label,
      value: status.value,
    })),
  },
];

const Campaigns = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const campaigns: ImmutableList<ICampaignListItemImt> = useSelector(selectors.selectCampaigns());
  const campaignsMeta: IPageMetaImt = useSelector(selectors.selectCampaignsMeta());
  const isCampaignsLoading: boolean = useSelector(selectors.selectCampaignsLoading());

  const location = useLocation();
  const navigate = useNavigate();
  const module =
    location.pathname.indexOf('personal-training-crm') === -1
      ? PeakModules.Crm
      : PeakModules.PersonalTrainingCrm;

  const [tableParams, setTableParams] = useState(() =>
    makeTableParams(tableFilters, location.search),
  );

  // lifecycle

  useEffect(() => {
    dispatch(actions.fetchCampaigns(tableParams, module));
  }, [dispatch, module, tableParams]);

  const updateQueryFunction = useCallback(
    query => dispatch(updateQueryParams({ page: QueryPageList.CAMPAIGNS, query })),
    [dispatch],
  );

  const handleChangeTableProps = useCallback(
    (tableProps: ITableParams): void => {
      setTableParams(tableProps);
      pushQueryToUrl(navigate, tableProps, updateQueryFunction);
    },
    [navigate, updateQueryFunction],
  );

  return (
    <RouteBackground>
      <CampaignsTable
        items={campaigns}
        totalRows={campaignsMeta.get('total')}
        isLoading={isCampaignsLoading}
        onChangeParams={handleChangeTableProps}
        tableFilterSettings={tableFilters}
        tableParams={tableParams}
        module={module}
      />
    </RouteBackground>
  );
};

export default Campaigns;
