import { actionTypes } from 'memberPortalModules/MemberProfile/constants';
import { fromJS } from 'immutable';
import { initReqStateImt } from 'common/constants/initialState';
import { RESOLVE_PERSON_MISSING_BILLING_INFO } from '../billingActions/constants';

export const profileReducer = (state = initReqStateImt, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PROFILE_INFO:
      return state.set('result', fromJS(action.payload));
    case actionTypes.FETCH_PROFILE_INFO_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_PROFILE_INFO:
      return initReqStateImt;

    case RESOLVE_PERSON_MISSING_BILLING_INFO:
      return state.update('alerts', alerts =>
        alerts?.filter(alertItem => alertItem.get('condition') !== action.payload),
      );

    case actionTypes.FETCH_PERSON_ALERTS:
      return state.set('alerts', fromJS(action.payload));
    case actionTypes.FETCH_PERSON_ALERTS_LOADING:
      return state.set('alertsLoading', action.payload);

    case actionTypes.RESOLVE_PAST_DUE_ALERT:
      return state.update('alerts', alerts =>
        alerts?.filter(alertItem => alertItem.get('condition') !== action.payload),
      );

    case actionTypes.FETCH_SENDER_AVAILABILITY_LOADING: {
      const { isLoading, type } = action.payload;

      return state.setIn(['sendAvailability', type, 'isLoading'], isLoading);
    }
    case actionTypes.FETCH_SENDER_AVAILABILITY: {
      const { data, type } = action.payload;
      return state.setIn(['sendAvailability', type, 'data'], fromJS(data));
    }

    case actionTypes.FETCH_BALANCE_HISTORY_LOADING:
      return state.set('balanceHistoryLoading', action.payload);

    case actionTypes.FETCH_BALANCE_HISTORY:
      return state.set('balanceHistory', action.payload);

    default:
      return state;
  }
};
