import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { Box, makeStyles, MenuItem, Typography } from '@material-ui/core';
import moment, { Moment } from 'moment-timezone';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { FormattedMessage } from 'react-intl';

import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { IPeriodItem } from 'modules/corporations/interfaces';
import commonMessages from 'common/messages/messages';
import { DEFAULT_DATE_FORMAT } from 'common/constants/dateFormats';
import { colors } from 'common/ui/theme/default';
import { DropdownFilter, TablePaginationActions } from 'common/components';
import tableHeaders from 'common/messages/tableHeaders';

interface IProps {
  data: IPeriodItem[];
  selectedPayPeriod?: IPeriodItem | null;
  onChangePayPeriodItem: (value?: IPeriodItem) => void;
  currentPayPeriod: IPeriodItem | null;
  setSelectedPayPeriod: Dispatch<SetStateAction<IPeriodItem>>;
}

const useStyles = makeStyles({
  payPeriodsSelect: {
    width: 280,
  },
  currentLabel: {
    color: colors.primary,
  },
  buttonWrapper: {
    '& button': {
      maxWidth: 'none',
      color: colors.primary,
    },
  },
  dropdownList: {
    maxHeight: 320,
  },
});

const getRenderData = (
  timezoneMoment: (date?: string | Date, format?: string) => Moment,
  startDate: string,
  endDate: string,
) => {
  const currentDate = timezoneMoment();
  const startMomentDate = timezoneMoment(startDate, 'YYYY-MM-DD');
  const endMomentDate = timezoneMoment(endDate, 'YYYY-MM-DD');

  if (
    currentDate.isSameOrAfter(startMomentDate, 'date') &&
    currentDate.isSameOrBefore(endMomentDate, 'date')
  ) {
    return {
      startDate: startMomentDate,
      endDate: endMomentDate,
      currentLabel: <FormattedMessage {...commonMessages.currentLabel} />,
    };
  }

  return {
    startDate: startMomentDate,
    endDate: endMomentDate,
    currentLabel: null,
  };
};

const PayPeriodsSelectWithPaginate = (props: IProps): JSX.Element => {
  const {
    data,
    selectedPayPeriod,
    onChangePayPeriodItem,
    setSelectedPayPeriod,
    currentPayPeriod,
  } = props;
  const {
    id: selectedPayPeriodId,
    startDate: selectedPayPeriodStartDate,
    endDate: selectedPayPeriodEndDate,
  } = selectedPayPeriod || {};

  const [timezoneMoment] = useTimezoneMoment();

  const location = useLocation();

  const onChangeRef = useRef(null);
  const isLoadedPayPeriodsRef = useRef(false);

  const classes = useStyles();

  const onChange = (id?: string) => {
    const selectedPayPeriodItem = id && data.find(period => period.id === id);
    onChangePayPeriodItem(selectedPayPeriodItem);
  };

  onChangeRef.current = onChange;

  useEffect(() => {
    const payPeriodList = data || [];

    if (!payPeriodList.length) {
      return;
    }

    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    const { rangeStartDate, rangeEndDate } = params;

    let startDateFromParams: string | null = null;
    let endDateFromParams: string | null = null;

    if (rangeStartDate && rangeEndDate) {
      startDateFromParams = moment(new Date(Number(rangeStartDate)))
        .utc(true)
        .format('YYYY-MM-DD');
      endDateFromParams = moment(new Date(Number(rangeEndDate)))
        .utc(true)
        .format('YYYY-MM-DD');
    }

    const { id: currentPayPeriodId } = currentPayPeriod || {};

    for (let i = 0, ln = payPeriodList.length; i < ln; i += 1) {
      const { startDate, endDate, id } = payPeriodList[i];

      if (startDateFromParams && endDateFromParams && currentPayPeriodId !== id) {
        const isSameStartDate = startDateFromParams === startDate;

        const isSameEndDate = endDateFromParams === endDate;

        if (isSameStartDate && isSameEndDate) {
          if (isLoadedPayPeriodsRef.current) {
            setSelectedPayPeriod(payPeriodList[i]);
          } else {
            onChangeRef.current(id);
          }
          break;
        }
      }

      if (!startDateFromParams && !endDateFromParams && id === currentPayPeriodId) {
        onChangeRef.current(id);
        break;
      }
    }
    isLoadedPayPeriodsRef.current = true;
  }, [location, data, timezoneMoment, currentPayPeriod, setSelectedPayPeriod]);

  const getMenuItem = (
    currentPeriodStartDate: string,
    currentPeriodEndDate: string,
    currentPeriodTitle: string,
  ) => {
    const { currentLabel, startDate, endDate } = getRenderData(
      timezoneMoment,
      currentPeriodStartDate,
      currentPeriodEndDate,
    );

    const title = (
      <Typography component="span" color="inherit">
        {currentPeriodTitle}
      </Typography>
    );

    const dateLabel = (
      <Typography component="span" color="inherit">{`(${startDate.format(
        DEFAULT_DATE_FORMAT,
      )} - ${endDate.format(DEFAULT_DATE_FORMAT)})`}</Typography>
    );

    return (
      <Box display="flex">
        {title}&nbsp;
        {dateLabel}
        {currentLabel && (
          <>
            &nbsp;
            <Typography variant="subtitle2" className={classes.currentLabel}>
              {currentLabel}
            </Typography>
          </>
        )}
      </Box>
    );
  };

  const getLabel = (currentPeriodStartDate: string, currentPeriodEndDate: string) => {
    const { currentLabel, startDate, endDate } = getRenderData(
      timezoneMoment,
      currentPeriodStartDate,
      currentPeriodEndDate,
    );

    const dateLabel = (
      <Typography component="span" color="inherit">
        {currentLabel ? (
          <>
            <FormattedMessage {...tableHeaders.payPeriod} />:
          </>
        ) : (
          `${startDate.format(DEFAULT_DATE_FORMAT)} - ${endDate.format(DEFAULT_DATE_FORMAT)}`
        )}
      </Typography>
    );

    return (
      <Box display="flex">
        {dateLabel}
        {currentLabel && (
          <>
            &nbsp;
            <Typography variant="subtitle2" className={classes.currentLabel}>
              {currentLabel}
            </Typography>
          </>
        )}
      </Box>
    );
  };

  const renderBody = (onCloseMenu: () => void) => {
    const onClick = (id: string) => {
      onChange(id);
      onCloseMenu();
    };

    return (
      <>
        <MenuItem selected={!selectedPayPeriodId} onClick={() => onClick('none')} key="none">
          <FormattedMessage {...commonMessages.noneOption} />
        </MenuItem>
        {data?.map(period => {
          return (
            <MenuItem
              selected={selectedPayPeriodId === period.id}
              onClick={() => onClick(period.id)}
              key={period.id}
            >
              {getMenuItem(period.startDate, period.endDate, period.title)}
            </MenuItem>
          );
        })}
      </>
    );
  };

  const onChangePeriod = (event: React.BaseSyntheticEvent, index: number) => {
    if (data && data.length !== 0) {
      const currentPeriod = index === 0 ? null : data[index - 1];

      onChange(currentPeriod?.id);
    }
  };

  // Added "+1" because we're using the 'none' value.
  const payPeriodIndex = selectedPayPeriod
    ? data.findIndex(item => selectedPayPeriod.id === item.id) + 1
    : 0;

  return (
    <Box display="flex">
      <DropdownFilter
        className={classes.buttonWrapper}
        dropdownListClassName={classes.dropdownList}
        name="pay-period"
        title={
          selectedPayPeriodId ? (
            getLabel(selectedPayPeriodStartDate, selectedPayPeriodEndDate)
          ) : (
            <FormattedMessage {...commonMessages.noneOption} />
          )
        }
        renderBody={renderBody}
      />
      <TablePaginationActions
        count={data?.length ? data.length + 1 : 1}
        page={payPeriodIndex}
        onChangePage={onChangePeriod}
        rowsPerPage={1}
        hideCountOfPages
      />
    </Box>
  );
};

export default PayPeriodsSelectWithPaginate;
