// Libraries
import React, { useEffect, useMemo } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Box, Grid, makeStyles, TextField, debounce } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { PhoneNumberInput } from 'common/components';

import { IPersonSearchBarValues } from './interfaces';

import inputLabels from 'common/messages/inputLabels';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    halfWideInput: {
      [theme.breakpoints.up('desktop')]: {
        maxWidth: '50%',
      },
    },
  }),
);

interface IProps {
  needToRefetch?: boolean;
  onSearchFieldChange: (search: IPersonSearchBarValues) => void;
  setIsSearchTyped: (isDirty: boolean) => void;
}
const PersonSearchBar = ({
  needToRefetch = false,
  onSearchFieldChange,
  setIsSearchTyped,
}: IProps): JSX.Element => {
  const classes = useStyles();

  const formMethods = useForm<IPersonSearchBarValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
    },
    mode: 'all',
  });

  const {
    control,
    watch,
    trigger,
    formState: { isDirty },
  } = formMethods;

  const handleSearchFieldChange = useMemo(
    () => debounce(() => onSearchFieldChange(watch()), 1000),
    [onSearchFieldChange, watch],
  );
  useEffect(() => {
    if (needToRefetch) {
      handleSearchFieldChange();
    }
  }, [needToRefetch, handleSearchFieldChange]);

  useEffect(() => setIsSearchTyped(isDirty), [isDirty, setIsSearchTyped]);

  return (
    <Box boxShadow={25} pl={2} pr={2} pb={2}>
      <FormProvider {...formMethods}>
        <form autoComplete="none">
          <Grid container spacing={2}>
            <Grid item xs={6} className={classes.halfWideInput}>
              <Controller
                name="firstName"
                control={control}
                render={({ onChange, value }) => (
                  <TextField
                    value={value}
                    label={<FormattedMessage {...inputLabels.firstName} />}
                    variant="outlined"
                    name="firstName"
                    fullWidth
                    autoComplete="disabled"
                    onChange={v => {
                      onChange(v);
                      handleSearchFieldChange();
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6} className={classes.halfWideInput}>
              <Controller
                name="lastName"
                render={({ onChange, value }) => (
                  <TextField
                    value={value}
                    label={<FormattedMessage {...inputLabels.lastName} />}
                    variant="outlined"
                    name="lastName"
                    fullWidth
                    autoComplete="disabled"
                    onChange={v => {
                      onChange(v);
                      trigger('lastName');
                      handleSearchFieldChange();
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="phoneNumber"
                control={control}
                render={({ value, onChange, onBlur }) => (
                  <PhoneNumberInput
                    value={value}
                    onChange={v => {
                      onChange(v);
                      handleSearchFieldChange();
                    }}
                    onBlur={onBlur}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Box>
  );
};

export default PersonSearchBar;
