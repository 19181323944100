import { fromJS } from 'immutable';
import { actionTypes } from 'modules/members/state/insuranceInfo/constants';

const insuranceInfoInitState = fromJS({
  result: {},
  isLoading: false,
});

const insuranceInfoReducer = (state = insuranceInfoInitState, action) => {
  switch (action.type) {
    case actionTypes.SET_INSURANCE_INFO_IS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_INSURANCE_INFO:
    case actionTypes.UPDATE_INSURANCE_INFO:
      return state.set('result', fromJS(action.payload));
    case actionTypes.RESET_INSURANCE_INFO:
      return insuranceInfoInitState;
    default:
      return state;
  }
};

export default insuranceInfoReducer;
