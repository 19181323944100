import { defineMessages } from 'react-intl';

export default defineMessages({
  // Primary Info Step
  newMemberPrimaryInfoScanId: {
    id: 'modules.front.desk.new.member.primary.info.scan.id',
    defaultMessage: 'Scan id to get data',
  },
  newMemberPrimaryInfoBrowse: {
    id: 'modules.front.desk.new.member.primary.info.browse',
    defaultMessage: 'Browse',
  },
  newMemberPrimaryInfoSalesInfo: {
    id: 'modules.front.desk.new.member.primary.info.salesInfo',
    defaultMessage: 'Sales Info',
  },
  // Labels
  newMemberPrimaryInfoFirstNameLabel: {
    id: 'modules.front.desk.new.member.primary.info.first.name.label',
    defaultMessage: 'First Name',
  },
  newMemberPrimaryInfoLastNameLabel: {
    id: 'modules.front.desk.new.member.primary.last.name.label',
    defaultMessage: 'Last Name',
  },
  newMemberPrimaryInfoEmailLabel: {
    id: 'modules.front.desk.new.member.primary.info.email.label',
    defaultMessage: 'Email',
  },
  newMemberPrimaryInfoPhoneLabel: {
    id: 'modules.front.desk.new.member.primary.phone.number.label',
    defaultMessage: 'Phone Number',
  },
  newMemberPrimaryInfoTypeLabel: {
    id: 'modules.front.desk.new.member.primary.info.type.label',
    defaultMessage: 'Type',
  },
  newMemberPrimaryInfoFreePassDaysLabel: {
    id: 'modules.front.desk.new.member.primary.info.free.pass.days.label',
    defaultMessage: 'Free Pass Days (Optional)',
  },
  newMemberPrimaryInfoSalespersonLabel: {
    id: 'modules.front.desk.new.member.primary.info.salesperson.label',
    defaultMessage: 'Salesperson',
  },
  newMemberPrimaryInfoReferralLabel: {
    id: 'modules.front.desk.new.member.primary.info.referral.label',
    defaultMessage: 'Referral (Optional)',
  },
  newMemberPrimaryInfoSocialMediaLabel: {
    id: 'modules.front.desk.new.member.primary.info.social.media.label',
    defaultMessage: 'Social media (Optional)',
  },
  newMemberPrimaryInfoOtherLabel: {
    id: 'modules.front.desk.new.member.primary.info.other.label',
    defaultMessage: 'Other (Optional)',
  },
  // Checkboxes
  newMemberPrimaryInfoAllowAutomatedCalls: {
    id: 'modules.front.desk.new.member.primary.info.allow.automated.calls',
    defaultMessage: 'Allow automated calls',
  },
  newMemberPrimaryInfoCanReceiveTextMessages: {
    id: 'modules.front.desk.new.member.primary.info.receive.text.messages',
    defaultMessage: 'Can receive text messages',
  },
  // Selects
  newMemberPrimaryInfoUnassignedSelect: {
    id: 'modules.front.desk.new.member.primary.info.unassigned.select',
    defaultMessage: 'Unassigned',
  },
  newMemberPrimaryInfoInstagramSelect: {
    id: 'modules.front.desk.new.member.primary.info.instagram.select',
    defaultMessage: 'Instagram',
  },
  newMemberPrimaryInfoFacebookSelect: {
    id: 'modules.front.desk.new.member.primary.info.facebook.select',
    defaultMessage: 'Facebook',
  },
});
