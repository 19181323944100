import React, { useEffect, useMemo, useState } from 'react';
import { Autocomplete as MuiAutocomplete, AutocompleteProps } from '@material-ui/lab';
import { Avatar, Box, Paper, TextField, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

import Services from 'services/network';
import { debounce } from 'common/utils';
import { getInitials } from 'helpers/common';
import { ICustomerShort, IFamilyMemberSearch } from '../PersonProfile/interfaces';
import commonMessages from 'common/messages/messages';
import { ListboxComponent } from 'common/components';
import { PeakModules } from 'common/constants/peakModules';
import profilePlaceholder from 'img/photo-placeholder.png';

// After extend from AutocompleteProps, we inherit required params as 'renderInput' and 'options', but we override them
// in this component, then instead of pass them as null we exclude them with Omit from this interface
interface IMemberAutocompleteProps
  extends Omit<AutocompleteProps<any, any, any, any>, 'renderInput' | 'options'> {
  isSearchFamilyMembers?: boolean;
  messageForTheAddOption?: JSX.Element;
  isServicesSection?: boolean;
  label: string | JSX.Element;
  personId?: string;
  fullWidth: boolean;
  error?: string;
  onCreateNew?: () => void;
  warningText?: React.ReactNode;
  isShowWarningText?: boolean;
  refresh?: number;
  module?: PeakModules;
}

const useStyles = makeStyles((theme: Theme) => ({
  optionAvatar: {
    width: '100%',
    height: '100%',
  },
  optionName: {
    fontSize: '0.9rem',
    fontWeight: 500,
  },

  avatarWrap: {
    position: 'relative',
    flex: 'none',
    width: '36px',
    height: '36px',
    backgroundColor: theme.palette.secondary.light,
    borderRadius: '50%',
  },
  avatarInitial: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    fontSize: '16px',
    fontWeight: 700,
    color: theme.palette.darkBackground?.light,
  },
  addOption: {
    padding: theme.spacing(1.5, 2),
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
    cursor: 'pointer',
  },
  addOptionIcon: {
    marginRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.4rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  warningText: {
    padding: theme.spacing(1.5, 1.5, 0),
    color: theme.palette.secondary.contrastText,
    fontWeight: 500,
  },
}));

const MemberAutocomplete = (props: IMemberAutocompleteProps): JSX.Element => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<ICustomerShort[]>([]);
  const [unavailableIds, setUnavailableIds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    isSearchFamilyMembers,
    isServicesSection,
    warningText,
    isShowWarningText,
    label,
    fullWidth,
    error,
    getOptionDisabled,
    onCreateNew,
    messageForTheAddOption,
    personId,
    refresh = 0,
    module,
    ...autocompleteProps
  } = props;

  const getOptionDisabledHandler = (opt: ICustomerShort) => {
    return (getOptionDisabled && getOptionDisabled(opt)) || unavailableIds?.includes(opt.id);
  };

  const loadMembers = useMemo(() => {
    return debounce(async value => {
      setIsLoading(true);
      if (isSearchFamilyMembers) {
        let searchMethod: (searchStr: string, id: string) => Promise<IFamilyMemberSearch>;

        if (isServicesSection) {
          switch (module) {
            case PeakModules.FrontDesk:
              searchMethod = Services.FrontDeskRedeem.searchFamilyMembers;
              break;
            default:
              searchMethod = Services.MembersRedeem.searchFamilyMembers;
          }
        } else {
          switch (module) {
            case PeakModules.FrontDesk:
              searchMethod = Services.FrontDesk.searchFamilyMembers;
              break;
            case PeakModules.Crm:
              searchMethod = Services.Leads.searchFamilyMembers;
              break;
            case PeakModules.PersonalTrainingCrm:
              searchMethod = Services.PTLeads.searchFamilyMembers;
              break;
            default:
              searchMethod = Services.Members.searchFamilyMembers;
          }
        }

        const members = await searchMethod(value, personId);

        setOptions(members.data);
        setUnavailableIds(members.unavailableIds);
      } else {
        const members = await Services.Reports.searchMembers(value);
        setOptions(members);
      }
      setIsLoading(false);
    });
  }, [module, isSearchFamilyMembers, isServicesSection, personId]);

  const onChangeSearch = e => {
    loadMembers(e.target.value);
  };

  useEffect(() => {
    if (refresh !== 0) {
      loadMembers('');
    }
  }, [refresh, loadMembers]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
      setUnavailableIds([]);
    } else {
      loadMembers('');
    }
  }, [open, loadMembers]);

  const renderUserOption = ({ imageUrl, firstName, lastName, id }: ICustomerShort) => {
    const initials = getInitials(firstName, lastName);

    return (
      <Box display="flex" key={id}>
        <Box className={classes.avatarWrap}>
          {initials && !imageUrl ? (
            <Box className={classes.avatarInitial}>{initials}</Box>
          ) : (
            <Avatar
              src={imageUrl || profilePlaceholder}
              className={classes.optionAvatar}
              alt={firstName}
            />
          )}
        </Box>
        <Box ml={1}>
          <Typography className={classes.optionName}>{`${firstName} ${lastName}`}</Typography>
        </Box>
      </Box>
    );
  };

  const ListboxProps = {
    itemSize: 50,
    limitCount: 6,
  };

  return (
    <MuiAutocomplete
      {...autocompleteProps}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      getOptionSelected={(option, value) => option?.id === value?.id}
      getOptionLabel={option => (option ? `${option.firstName} ${option.lastName}` : '')}
      getOptionDisabled={getOptionDisabledHandler}
      renderOption={renderUserOption}
      options={options}
      loading={isLoading}
      ListboxComponent={ListboxComponent}
      ListboxProps={ListboxProps}
      renderInput={params => {
        return (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            fullWidth={fullWidth}
            onChange={onChangeSearch}
            error={!!error}
            helperText={error}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
              form: {
                autoComplete: 'off',
              },
            }}
          />
        );
      }}
      PaperComponent={({ children, className }) => {
        return (
          <Paper className={className}>
            <Box
              className={classes.addOption}
              onMouseDown={e => e.preventDefault()}
              onClick={onCreateNew}
            >
              <Box className={classes.addOptionIcon}>+</Box>
              <Typography variant="subtitle2" color="primary">
                {messageForTheAddOption || (
                  <FormattedMessage {...commonMessages.newMemberCommonTitle} />
                )}
              </Typography>
            </Box>
            {isShowWarningText && (
              <Box className={classes.warningText} textAlign="center">
                <Typography component="span">{warningText}</Typography>
              </Box>
            )}
            {children}
          </Paper>
        );
      }}
    />
  );
};

export default MemberAutocomplete;
