import { defineMessages } from 'react-intl';

export default defineMessages({
  payrollReportLabel: {
    id: 'app.modules.timeclock.timeclockHeader.payrollReport.button.label',
    defaultMessage: 'Payroll Report',
  },
  payrollLabel: {
    id: 'app.modules.timeclock.payrollActivityChart.label',
    defaultMessage: 'Payroll',
  },
  timeclockLabel: {
    id: 'app.modules.timeclock.timeclockHeader.timeclock.button.label',
    defaultMessage: 'Timeclock',
  },
  myTimeclockLabel: {
    id: 'app.modules.timeclock.timeclockHeader.timeclockLog.button.label',
    defaultMessage: 'My Timeclock',
  },
  clockInEmployeeBtn: {
    id: 'app.modules.timeclock.timeclockHeader.clockInEmployeeBtn.button.label',
    defaultMessage: 'Clock In Employee',
  },
  clockInDataLabel: {
    id: 'app.modules.timeclock.timeclockHeader.clockInDataLabel',
    defaultMessage: 'Clock In Data',
  },
  addClockInDataBtn: {
    id: 'app.modules.timeclock.timeclockHeader.addClockInDataBtn.button.label',
    defaultMessage: 'Add Clock in',
  },
  unitDataLabel: {
    id: 'app.modules.timeclock.timeclockHeader.unitDataLabel',
    defaultMessage: 'Unit Data',
  },
  addUnitDataBtn: {
    id: 'app.modules.timeclock.timeclockHeader.addUnitDataBtn.button.label',
    defaultMessage: 'Add Unit',
  },
  myPayrollReportLabel: {
    id: 'app.modules.timeclock.timeclockHeader.myPayrollReportLabel',
    defaultMessage: 'My Payroll Report',
  },
  withOvertime: {
    id: 'app.modules.timeclock.timeclockHeader.withOvertime.option.label',
    defaultMessage: 'With Overtime',
  },
  withoutOvertime: {
    id: 'app.modules.timeclock.timeclockHeader.withoutOvertime.option.label',
    defaultMessage: 'Without Overtime',
  },
  addClockInTitle: {
    id: 'app.modules.timeclock.timeclockHeader.addClockInTitle.modal.title',
    defaultMessage: 'Add Clock In',
  },
  addUnitTitle: {
    id: 'app.modules.timeclock.timeclockHeader.addUnitTitle.modal.title',
    defaultMessage: 'Add Unit',
  },
  clockInOption: {
    id: 'app.modules.timeclock.timeclockHeader.clockInOption.option.title',
    defaultMessage: 'Clock in',
  },
  unitOption: {
    id: 'app.modules.timeclock.timeclockHeader.unitOption.option.title',
    defaultMessage: 'Unit',
  },
  clockOut: {
    id: 'app.modules.timeclock.timeclockHeader.clockOut.option.title',
    defaultMessage: 'Clock out',
  },
  totalClockInTime: {
    id: 'app.modules.timeclock.timeclockHeader.totalClockInTime',
    defaultMessage: 'Total Clock In Time',
  },
  totalEvents: {
    id: 'app.modules.timeclock.timeclockHeader.totalEvents',
    defaultMessage: 'Total Events',
  },
  overtime: {
    id: 'app.modules.timeclock.timeclockHeader.overtime',
    defaultMessage: 'Overtime',
  },
  regularLabel: {
    id: 'app.modules.timeclock.payrollActivityChart.legend.regular.label',
    defaultMessage: 'Regular',
  },
  scheduledLabel: {
    id: 'app.modules.timeclock.payrollActivityChart.legend.scheduled.label',
    defaultMessage: 'Scheduled',
  },
});
