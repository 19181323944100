import { createSelector } from 'reselect';
import { AppStoreTypeImt } from 'store';

// Scripts
const selectScriptsDomain = (state: AppStoreTypeImt) => {
  return state.getIn(['crmModule', 'scriptsTable']);
};

const selectScripts = () => createSelector(selectScriptsDomain, state => state.get('list'));

const selectScriptsMeta = () => createSelector(selectScriptsDomain, state => state.get('meta'));

const selectScriptsLoading = () =>
  createSelector(selectScriptsDomain, state => state.get('listLoading'));

// change script status
const selectChangeScriptStatusLoading = () =>
  createSelector(selectScriptsDomain, state => state.get('changeScriptStatusLoading'));
const selectChangeScriptStatusResult = () =>
  createSelector(selectScriptsDomain, state => state.get('changeScriptStatusResult'));

// Create Script
const selectScriptCreateFormDomain = (state: AppStoreTypeImt) => {
  return state.getIn(['crmModule', 'createScriptForm']);
};

const selectCreatedScript = () => {
  return createSelector(selectScriptCreateFormDomain, state => state.get('createdScript'));
};

const selectScriptCreating = () =>
  createSelector(selectScriptCreateFormDomain, state => state.get('scriptCreating'));

// Update script
const selectScriptUpdateFormDomain = (state: AppStoreTypeImt) => {
  return state.getIn(['crmModule', 'updateScriptForm']);
};

const selectScript = () =>
  createSelector(selectScriptUpdateFormDomain, state => state.get('script'));

const selectScriptLoading = () =>
  createSelector(selectScriptUpdateFormDomain, state => state.get('scriptLoading'));

const selectScriptUpdating = () =>
  createSelector(selectScriptUpdateFormDomain, state => state.get('scriptUpdating'));

const selectUpdatedScript = () =>
  createSelector(selectScriptUpdateFormDomain, state => state.get('updatedScript'));

export {
  // Scripts
  selectScripts,
  selectScriptsMeta,
  selectScriptsLoading,
  // Create script
  selectCreatedScript,
  selectScriptCreating,
  // Update script
  selectScript,
  selectScriptLoading,
  selectUpdatedScript,
  selectScriptUpdating,
  // Archive script
  selectChangeScriptStatusLoading,
  selectChangeScriptStatusResult,
};
