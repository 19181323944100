import { createAction } from '@reduxjs/toolkit';
import moment from 'moment-timezone';

import AppService from 'services/application/AppService';
import Services from 'services/network';

import { enqueueErrorNotification } from 'common/state/notifications/actions';

import { actionTypes, LanguageLocales } from './constants';

import { GeneralThunkAction } from 'common/state/interfaces';
import { IPeakMessagingSettings } from 'modules/peak-settings/interfaces';
import { IRequiredFieldInfo } from 'modules/corporate-settings/interfaces';

const setLocaleAction = createAction<LanguageLocales>(actionTypes.SETTINGS_SET_SELECTED_LOCALE);
export const setSelectedLocale = (locale: LanguageLocales): GeneralThunkAction => {
  AppService.setAppLocale(locale);
  return dispatch => dispatch(setLocaleAction(locale));
};

const setIsSidebarOpenAction = createAction<boolean>(actionTypes.SETTINGS_SET_IS_SIDEBAR_OPEN);
export const setIsSidebarOpen = (isOpen: boolean): GeneralThunkAction => {
  AppService.setAppIsSidebarOpen(isOpen);
  return dispatch => dispatch(setIsSidebarOpenAction(isOpen));
};

const setTimezoneViewAction = createAction<string>(actionTypes.SETTINGS_SET_TIMEZONE_VIEW);

export const setTimezoneView = (timezone: string): GeneralThunkAction => {
  AppService.setAppSelectedTimezone(timezone);
  moment.tz.setDefault(timezone);
  return dispatch => dispatch(setTimezoneViewAction(timezone));
};

const fetchPeakSystemSettingsAction = createAction<IPeakMessagingSettings>(
  actionTypes.FETCH_PEAK_SYSTEM_SETTINGS,
);
export const updatePeakSystemSettingsAction = createAction<IPeakMessagingSettings>(
  actionTypes.UPDATE_PEAK_SYSTEM_SETTINGS,
);

export const fetchPeakSystemSettings = (): GeneralThunkAction => {
  return async dispatch => {
    try {
      const settings = await Services.Corporations.getPeakSystemSettings();

      dispatch(fetchPeakSystemSettingsAction(settings));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    }
  };
};

export const fetchProfileFieldsInfoAction = createAction<Array<IRequiredFieldInfo>>(
  actionTypes.FETCH_PROFILE_FIELDS_INFO,
);

export const fetchProfileFieldsInfo = (corporationId: string): GeneralThunkAction => {
  return async dispatch => {
    try {
      const settings = await Services.Corporations.getProfileFieldsInfo(corporationId);

      dispatch(fetchProfileFieldsInfoAction(settings));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    }
  };
};

export const updateProfileFieldsInfoAction = createAction<IRequiredFieldInfo>(
  actionTypes.UPDATE_PROFILE_FIELDS_INFO,
);
