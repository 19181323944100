import React, { useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
// interfaces
import { IInvoiceDetailsImt } from 'common/interfaces/invoices';

import { CustomTheme } from 'common/ui/interfaces';
// state

// components
import { InvoiceFooter } from './InvoiceFooter';
import { priceRounding } from 'common/utils';
// import { AS_PAID_INVOICE_PAYMENT_SPLIT_STATUS } from '../constants';
import { AS_PAID_INVOICE_PAYMENT_SPLIT_STATUS } from 'common/components/InvoiceOperating/constants';
import InvoiceCreationCart from './InvoiceCreationCart/InvoiceCreationCart';
import InvoicePaymentWizard from './InvoicePaymentWizard/InvoicePaymentWizard';

const useStyles = makeStyles((theme: CustomTheme) => ({
  invoiceHeader: {
    flex: '0 1 auto',
    padding: theme.spacing(1, 2, 2, 2),
    boxShadow: theme.shadows[2],
  },
  invoiceSelectorWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  newInvoiceButton: {
    '& svg': {
      width: '1rem',
      height: '1rem',
    },
  },
  errorAlertsWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  warningIcon: {
    width: '1rem',
    height: '1rem',
  },
  alert: {
    paddingRight: theme.spacing(1),
    color: theme.palette.error.main,
    lineHeight: '14px',
  },
  invoiceCreationBlock: {
    flex: '1 1 auto',
  },
}));

interface IInvoicesForm {
  setIsCancelInvoiceModalOpen: (value) => void;
  setIsVoidInvoiceModalOpen: (value) => void;
  memberInvoice: IInvoiceDetailsImt;
}

export default function InvoicesForm({
  setIsCancelInvoiceModalOpen,
  setIsVoidInvoiceModalOpen,
  memberInvoice,
}: IInvoicesForm): JSX.Element {
  const classes = useStyles();

  // local state
  const [isInvoicePaymentWizardOpen, setIsInvoicePaymentWizardOpen] = useState<boolean>(false);

  // constants

  const totalInvoicePayments =
    memberInvoice
      ?.get('invoicePaymentSplits')
      ?.filter(ips => AS_PAID_INVOICE_PAYMENT_SPLIT_STATUS.includes(ips.get('status')))
      .reduce((acc, value) => {
        return acc + Number(value.get('paymentAmount'));
      }, 0) || 0;

  const leftToPay = priceRounding(memberInvoice?.get('totalAmount') - totalInvoicePayments);

  const memberId = memberInvoice?.getIn(['customer', 'id']);

  // handlers

  const onOpenPaymentWizard = () => setIsInvoicePaymentWizardOpen(true);

  const handleCancelInvoiceBtnClick = () => {
    setIsCancelInvoiceModalOpen(true);
  };

  const handleVoidInvoiceBtnClick = () => {
    setIsVoidInvoiceModalOpen(true);
  };

  const handleInvoicePaymentWizardClose = () => setIsInvoicePaymentWizardOpen(false);

  // renders

  return (
    <>
      <Box display="flex" flexDirection="column" height="100%" width="100%" overflow="auto">
        <Box className={classes.invoiceCreationBlock} display="flex" flexDirection="column">
          <InvoiceCreationCart memberInvoice={memberInvoice} />
          <InvoiceFooter
            invoice={memberInvoice}
            handleCancelInvoice={handleCancelInvoiceBtnClick}
            handleVoidInvoice={handleVoidInvoiceBtnClick}
            handleInvoiceActionClick={onOpenPaymentWizard}
            memberId={memberId}
          />
        </Box>
      </Box>

      <InvoicePaymentWizard
        isOpen={isInvoicePaymentWizardOpen}
        registerId={memberInvoice.getIn(['register', 'id'])}
        leftToPay={leftToPay}
        memberId={memberId}
        onClose={handleInvoicePaymentWizardClose}
      />
    </>
  );
}
