import { ActivityTypes } from '../interfaces/crmDashboard';
import { ConstOptions } from 'common/constants/classes';

import commonMessages from 'common/messages/messages';

export const actionTypes = {
  // Dashboard Summary
  FETCH_CRM_DASHBOARD_STATISTICS_LOADING: 'FETCH_CRM_DASHBOARD_STATISTICS_LOADING',
  FETCH_CRM_DASHBOARD_STATISTICS: 'FETCH_CRM_DASHBOARD_STATISTICS',
  FETCH_CRM_DASHBOARD_STATISTICS_ERROR: 'FETCH_CRM_DASHBOARD_STATISTICS_ERROR',

  // Appointments
  FETCH_CRM_APPOINTMENTS_LOADING: 'FETCH_CRM_APPOINTMENTS_LOADING',
  FETCH_CRM_APPOINTMENTS: 'FETCH_CRM_APPOINTMENTS',
  FETCH_CRM_APPOINTMENTS_ERROR: 'FETCH_CRM_APPOINTMENTS_ERROR',

  // Backlog
  FETCH_CRM_BACKLOG_LOADING: 'FETCH_CRM_BACKLOG_LOADING',
  FETCH_CRM_BACKLOG: 'FETCH_CRM_BACKLOG',
  FETCH_CRM_BACKLOG_ERROR: 'FETCH_CRM_BACKLOG_ERROR',

  // Activity
  FETCH_CRM_ACTIVITY_LOADING: 'FETCH_CRM_ACTIVITY_LOADING',
  FETCH_CRM_ACTIVITY: 'FETCH_CRM_ACTIVITY',
  FETCH_CRM_ACTIVITY_ERROR: 'FETCH_CRM_ACTIVITY_ERROR',

  // Membership Sold
  FETCH_CRM_MEMBERSHIP_SOLD_LOADING: 'FETCH_CRM_MEMBERSHIP_SOLD_LOADING',
  FETCH_CRM_MEMBERSHIP_SOLD: 'FETCH_CRM_MEMBERSHIP_SOLD',
  FETCH_CRM_MEMBERSHIP_SOLD_ERROR: 'FETCH_CRM_MEMBERSHIP_SOLD_ERROR',

  // Conversion Value
  FETCH_CRM_CONVERSION_VALUE_LOADING: 'FETCH_CRM_CONVERSION_VALUE_LOADING',
  FETCH_CRM_CONVERSION_VALUE: 'FETCH_CRM_CONVERSION_VALUE',
  FETCH_CRM_CONVERSION_VALUE_ERROR: 'FETCH_CRM_CONVERSION_VALUE_ERROR',
};

export const ActivityTypesLabels = new ConstOptions([
  { key: ActivityTypes.SMS, value: ActivityTypes.SMS, message: commonMessages.smsLabel },
  { key: ActivityTypes.EMAILS, value: ActivityTypes.EMAILS, message: commonMessages.emailsLabel },
  { key: ActivityTypes.CALLS, value: ActivityTypes.CALLS, message: commonMessages.callsLabel },
]);

export const activityData = [
  {
    id: ActivityTypes.EMAILS,
    type: ActivityTypes.EMAILS,
    data: [],
  },
  {
    id: ActivityTypes.CALLS,
    type: ActivityTypes.CALLS,
    data: [],
  },
  {
    id: ActivityTypes.SMS,
    type: ActivityTypes.SMS,
    data: [],
  },
];
