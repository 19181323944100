import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, DialogActions } from '@material-ui/core';

import { ButtonWithCircularProgress } from 'common/components';
import { ReactComponent as ArrowCircleLeftIcon } from 'img/icons/arrow-circle-left.svg';
import { ReactComponent as ArrowCircleRightIcon } from 'img/icons/arrow-circle-right.svg';

import { OpenRegisterSteps } from 'modules/pos-kiosk/constants/registers';

import messages from 'common/messages/messages';

interface IProps {
  currentStep: OpenRegisterSteps;
  onBack: () => void;
  onNext: () => void;
  goBack: () => void;
  isLoading: boolean;
}

const Footer = ({ onBack, onNext, currentStep, isLoading }: IProps): JSX.Element => {
  const isReconcileBillsStep = currentStep === OpenRegisterSteps.ReconcileBills;

  return (
    <DialogActions>
      <Button
        color="primary"
        startIcon={isReconcileBillsStep ? null : <ArrowCircleLeftIcon />}
        onClick={onBack}
      >
        <FormattedMessage {...(isReconcileBillsStep ? messages.cancelBtn : messages.backBtn)} />
      </Button>

      <ButtonWithCircularProgress
        endIcon={isReconcileBillsStep ? null : <ArrowCircleRightIcon />}
        isSubmitting={isLoading}
        type="submit"
        onClick={onNext}
      >
        <FormattedMessage {...(!isReconcileBillsStep ? messages.openBtn : messages.nextBtn)} />
      </ButtonWithCircularProgress>
    </DialogActions>
  );
};

export default Footer;
