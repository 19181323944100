import {
  EventOverbookingType,
  EventRepeatEnd,
  EventRepeatFrequency,
  EventUpdateType,
  IEventAction,
  IEventFormValues,
} from 'modules/booking/interfaces';
import { ReminderPeriodType } from 'common/interfaces/common';

import { colors } from 'common/ui/theme/default';

export const initialValuesEvent: IEventFormValues = {
  title: '',
  color: colors.primary,
  durationInMinutes: '',
  changeDuration: false,

  repeated: false,
  repeatingFrequency: EventRepeatFrequency.DAILY,
  repeatingWeekdays: [],
  repeatingDurationType: EventRepeatEnd.FOREVER,
  repeatingTimesNumber: 1,
  repeatingEndDate: null,

  club: null,
  service: null,
  resources: [],
  tags: [],

  allowSelfBooking: false,
  spotLimited: false,
  spotAmount: 0,
  allowedParticipantTypes: [],

  overbookingType: EventOverbookingType.DO_NOT_ALLOW_OVERBOOKING,
  overbookingResources: [{ resources: [] }],
  persons: [],

  description: '',

  preventJoiningAfterDays: false,
  joinRestrictionBeforeDays: 0,

  preventCancelingAfterDays: false,
  cancelRestrictionBeforeDays: 0,

  preventEditingAfterDays: false,
  editRestrictionBeforeDays: 0,

  canceled: false,

  reminder: false, // Frontend-specific field for tracking
  reminderPeriodType: ReminderPeriodType.Days,
  reminderPeriodNumber: 0,
  remindViaEmail: false,
  remindViaNotification: false,
  remindViaSms: false,

  notifyViaEmail: false,
  notifyViaNotification: false,
  notifyViaSms: false,
};

export const initialValuesEventAction: IEventAction = {
  notifyViaEmail: false,
  notifyViaNotification: false,
  notifyViaSms: false,

  updateType: EventUpdateType.OnlyCurrentInstance,
  reason: '',
};
