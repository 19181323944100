import { ImmutableObject } from 'common/state/interfaces';
import { List as ImmutableList } from 'immutable';
import { INamedEntity, INamedEntityImt } from 'common/interfaces/common';
import { IAttachment } from 'common/interfaces/uploadFile';

// Receipts list

export interface IReceiptListItem {
  id: string;
  title: string;
  active: boolean;
  clubsCount: number;
}
export type IReceiptListItemImt = ImmutableObject<IReceiptListItem>;

// Receipt entity

export enum IReceiptDistributionMethod {
  All = 'ALL',
  Reprint = 'REPRINT',
  Email = 'EMAIL',
}

export enum ReceiptFieldType {
  Text = 'TEXT',
  Image = 'IMAGE',
  DiscountQRCode = 'DISCOUNT_QR_CODE',
  CustomPaymentMethod = 'CUSTOM_PAYMENT_METHOD',
  ClubName = 'CLUB_NAME',
  ClubAddress = 'CLUB_ADDRESS',
  Customer = 'CUSTOMER',
  Email = 'EMAIL',
  Phone = 'PHONE',
  Address = 'ADDRESS',
  Salesperson = 'SALESPERSON',
  InsuranceCompany = 'INSURANCE_COMPANY',
  InsuranceMemberId = 'INSURANCE_MEMBER_ID',
  Membership = 'MEMBERSHIP',
  Waiver = 'WAIVER',
}

export interface IReceiptField {
  id?: string;
  top: boolean;
  fieldType: ReceiptFieldType;
  order?: number;
  value?: string;
  file?: IAttachment;
}
export type IReceiptFieldImt = ImmutableObject<IReceiptField>;

export interface IReceiptDetailed {
  active: boolean;
  deletedDate: string;
  id: string;
  receiptClubList: Array<INamedEntity>;
  receiptFieldList: Array<IReceiptField>;
  recipientDistributionMethod: IReceiptDistributionMethod;
  signatureLine: boolean;
  title: string;
}
export interface IReceiptDetailedImt extends ImmutableObject<IReceiptDetailed> {
  get<K extends keyof IReceiptDetailed>(key: K): IReceiptDetailed[K];

  get(key: 'receiptClubList'): ImmutableList<INamedEntityImt>;
  get(key: 'receiptFieldList'): ImmutableList<IReceiptFieldImt>;
}

// Old stuff

export interface IReceiptItemClubsFormData {
  id?: string;
  club?: INamedEntity;
  clubs?: Array<INamedEntity>;
}

export interface IReceiptItemClubsFormDataImt extends ImmutableObject<IReceiptItemClubsFormData> {
  get<K extends keyof IReceiptItemClubsFormData>(key: K): IReceiptItemClubsFormData[K];

  get(key: 'club'): INamedEntityImt;
  get(key: 'clubs'): ImmutableList<Array<INamedEntityImt>>;
}

export interface IFieldsTopBottom {
  id?: string | number;
  type: string;
  name: string;
  value?: string;
}

export type IFieldsTopBottomImt = ImmutableObject<IFieldsTopBottom>;

export interface IReceiptItemFormData {
  name?: string;
  status?: string;
  signatureLine?: boolean;
  distributionMethod?: string;
  topFields?: Array<IFieldsTopBottom>;
  bottomFields?: Array<IFieldsTopBottom>;
  clubs?: Array<INamedEntity>;
}

export interface IReceiptItemFormDataImt extends ImmutableObject<IReceiptItemFormData> {
  get<K extends keyof IReceiptItemFormData>(key: K): IReceiptItemFormData[K];

  get(key: 'topFields'): ImmutableList<Array<IFieldsTopBottomImt>>;
  get(key: 'bottomFields'): ImmutableList<Array<IFieldsTopBottomImt>>;
  get(key: 'clubs'): ImmutableList<Array<INamedEntityImt>>;
}
