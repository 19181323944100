// libraries
import { fromJS, List } from 'immutable';
import { createSelector } from 'reselect';
// interfaces
import { PermissionLevels } from 'common/constants/permissions';
import { INamedEntityImt } from 'common/interfaces/common';
import { ISingleFilterValue } from 'common/interfaces/filter';
import { IUserOrganization, IUserOrganizationImt } from 'common/interfaces/clients';
import { IUserAvatarImt, IUserProfileInfoImt } from 'modules/authentication/interfaces';
import { IServerError } from 'common/interfaces/http';
import { PaymentProcessorType } from '../../corporate-settings/interfaces';

// Authentication
export const selectIsUserLoggedIn = state => {
  return state.getIn(['clientPortalAuthentication', 'authentication', 'isUserLogged']);
};

export const selectSignInLoading = state =>
  state.getIn(['clientPortalAuthentication', 'authentication', 'isLoading']);

export const selectSignInError = (state): IServerError =>
  state.getIn(['clientPortalAuthentication', 'authentication', 'error']);

export const selectCorporationsForSignIn = state =>
  state.getIn(['clientPortalAuthentication', 'authentication', 'corporationsForSign', 'result']);
export const selectCorporationsForSignInLoading = (state): boolean =>
  state.getIn(['clientPortalAuthentication', 'authentication', 'corporationsForSign', 'isLoading']);

// Current User Data

const currentUserDomain = state => state.getIn(['clientPortalAuthentication', 'currentUser']);
const selectedOrgDomain = state =>
  state.getIn(['clientPortalAuthentication', 'currentUser', 'selectedOrganization']);

export const selectCurrentUser = (state): IUserProfileInfoImt =>
  state.getIn(['clientPortalAuthentication', 'currentUser', 'profile']);

export const selectCurrentUserId = (state): string =>
  state.getIn(['clientPortalAuthentication', 'currentUser', 'profile', 'id']);

export const selectIsExistCurrentUser = (state): boolean =>
  Boolean(state.getIn(['clientPortalAuthentication', 'currentUser', 'profile']));

export const selectUserAvatar = (state): IUserAvatarImt => {
  return state.getIn(['clientPortalAuthentication', 'currentUser', 'userAvatar']);
};

export const selectCurrentUserLoading = () =>
  createSelector(currentUserDomain, state => state.get('isLoading'));

export const selectCurrentUserError = () =>
  createSelector(currentUserDomain, state => state.get('error'));

export const selectCurrentUserEmail = (state): string =>
  state.getIn(['clientPortalAuthentication', 'currentUser', 'profile', 'email']);

export const selectCurrentUserPhone = (state): string =>
  state.getIn(['clientPortalAuthentication', 'currentUser', 'profile', 'phone']);

// corporation
export const selectCurrentUserCorporation = (state): INamedEntityImt =>
  state.getIn(['clientPortalAuthentication', 'currentUser', 'profile', 'corporation']);

export const selectCurrentPaymentProcessor = (state): PaymentProcessorType =>
  state.getIn([
    'clientPortalAuthentication',
    'currentUser',
    'profile',
    'corporation',
    'paymentProcessorType',
  ]);
// club or corporation selection

export const selectUserSelectedOrganization = (state): IUserOrganizationImt =>
  state.getIn(['clientPortalAuthentication', 'currentUser', 'selectedOrganization']);

export const selectUserSelectedOrganizationId = (state): string =>
  state.getIn(['clientPortalAuthentication', 'currentUser', 'selectedOrganization', 'id']);

export const selectSelectedOrganizationColor = (state): string =>
  state.getIn(['clientPortalAuthentication', 'currentUser', 'selectedOrganizationPrimaryColor']);

export const selectCurrentUserSelectedLevel = (state): PermissionLevels =>
  state.getIn([
    'clientPortalAuthentication',
    'currentUser',
    'selectedOrganization',
    'permissionsLevel',
  ]) || state.getIn(['clientPortalAuthentication', 'currentUser', 'profile', 'level']);

// select permissions level, setted in profile on backend
export const selectCurrentUserProfileLevel = (state): PermissionLevels =>
  state.getIn(['clientPortalAuthentication', 'currentUser', 'profile', 'level']);

export const selectUserSelectedClubId = createSelector<any, any, string | null>(
  [selectedOrgDomain],
  org => {
    if (org?.get('permissionsLevel') === PermissionLevels.CLIENT) {
      return org.get('id');
    }

    return null;
  },
);

export const selectCurrentUserOrganizationsByRoles = createSelector<
  any,
  any,
  List<IUserOrganizationImt>
>([currentUserDomain], user => {
  return fromJS(
    user
      .getIn(['profile', 'securityRoles'])
      ?.map(role => {
        if (role.get('clubList').isEmpty())
          return user
            .getIn(['profile', 'corporation'])
            .set('permissionsLevel', PermissionLevels.CORPORATE);

        return role
          .get('clubList')
          .map(club => club.set('permissionsLevel', PermissionLevels.CLIENT));
      })
      .toJS()
      .flat()
      .sort((org: IUserOrganization) =>
        org.permissionsLevel === PermissionLevels.CORPORATE ? -1 : 1,
      ),
  );
});

// table filters and default value

export const selectCurrentUserAvailableClubs = createSelector<any, any, List<INamedEntityImt>>(
  [currentUserDomain, selectCurrentUserOrganizationsByRoles],
  (user, currentUserOrganizationsByRoles) => {
    if (user.getIn(['selectedOrganization', 'permissionsLevel']) === PermissionLevels.CORPORATE) {
      return user.getIn(['profile', 'clubs']);
    }

    if (currentUserOrganizationsByRoles?.size) {
      return currentUserOrganizationsByRoles?.filter(
        org => !(org.get('permissionsLevel') === PermissionLevels.CORPORATE),
      );
    }

    return fromJS([]);
  },
);

// selects all clubs of corporation if user has corp level in profile
export const selectCurrentCorporateUserAvailableClubs = createSelector<
  any,
  any,
  List<INamedEntityImt>
>(
  [selectCurrentUserProfileLevel, currentUserDomain, selectCurrentUserOrganizationsByRoles],
  (level: PermissionLevels, user, currentUserOrganizationsByRoles) => {
    if (level === PermissionLevels.CORPORATE) {
      return user.getIn(['profile', 'clubs']);
    }

    if (currentUserOrganizationsByRoles?.size) {
      return currentUserOrganizationsByRoles?.filter(
        org => !(org.get('permissionsLevel') === PermissionLevels.CORPORATE),
      );
    }

    return fromJS([]);
  },
);

export const selectFilterByClubDefaultValue = createSelector<any, any, ISingleFilterValue>(
  [selectedOrgDomain],
  selectedOrg => {
    if (!selectedOrg || selectedOrg.get('permissionsLevel') === PermissionLevels.CORPORATE) {
      return null;
    }

    return {
      key: selectedOrg?.get('id'),
      label: selectedOrg?.get('title'),
      value: selectedOrg?.get('id'),
    };
  },
);

export const selectCurrentUserClubs = state =>
  state.getIn(['clientPortalAuthentication', 'currentUser', 'profile', 'clubs']);
