import React from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as Tasks } from 'img/icons/tasks.svg';
import { ReactComponent as Leads } from 'img/icons/leads.svg';
import { ReactComponent as Scripts } from 'img/icons/scripts.svg';
import { ReactComponent as Comment } from 'img/icons/comment.svg';
import { ReactComponent as Campaigns } from 'img/icons/campaigns.svg';
import messages from 'modules/crm/messages/common';
import ptMessages from 'modules/personal-training-crm/messages/messages';
import { IMainModulePageRouteItem } from 'common/components/MainModulePage/MainModulePage';
import { MainModulePage } from 'common/components';
import { useResetInvoiceData } from 'common/hooks/useResetInvoiceData';
import usePermissionContext from 'common/hooks/context/usePermissionContext';

const MainPage = (): JSX.Element => {
  const location = useLocation();
  const isCrm = location.pathname === '/crm';
  const isPTCrm = location.pathname === '/personal-training-crm';
  const mainTitle = isPTCrm ? 'Personal training CRM' : 'CRM';
  const crmModules: IMainModulePageRouteItem[] = [];
  let hasTaskViewPermission = false;
  let hasLeadViewPermission = false;
  let hasScriptViewPermission = false;
  let hasCampaignViewPermission = false;
  useResetInvoiceData();

  const {
    CRM_TASK_VIEW,
    CRM_LEAD_VIEW,
    CRM_SCRIPT_VIEW,
    CRM_CAMPAIGN_VIEW,
    PT_CRM_TASK_VIEW,
    PT_CRM_LEAD_VIEW,
    PT_CRM_SCRIPT_VIEW,
    PT_CRM_CAMPAIGN_VIEW,
  } = usePermissionContext();

  if (isCrm) {
    hasTaskViewPermission = CRM_TASK_VIEW;
    hasLeadViewPermission = CRM_LEAD_VIEW;
    hasScriptViewPermission = CRM_SCRIPT_VIEW;
    hasCampaignViewPermission = CRM_CAMPAIGN_VIEW;
  } else if (isPTCrm) {
    hasTaskViewPermission = PT_CRM_TASK_VIEW;
    hasLeadViewPermission = PT_CRM_LEAD_VIEW;
    hasScriptViewPermission = PT_CRM_SCRIPT_VIEW;
    hasCampaignViewPermission = PT_CRM_CAMPAIGN_VIEW;
  }

  if (hasTaskViewPermission) {
    crmModules.push({
      id: 'tasks',
      message: isPTCrm ? ptMessages.ptTasks : messages.tasks,
      link: isPTCrm ? '/personal-training-crm/tasks' : '/crm/tasks',
      iconComponent: Tasks,
      viewBox: '0 0 18 24',
    });
  }

  if (hasLeadViewPermission) {
    crmModules.push({
      id: 'leads',
      message: isPTCrm ? ptMessages.ptLeads : messages.leads,
      link: isPTCrm ? '/personal-training-crm/leads' : '/crm/leads',
      iconComponent: Leads,
      viewBox: '0 0 34 24',
    });
  }

  if (hasScriptViewPermission) {
    crmModules.push({
      id: 'scripts',
      message: isPTCrm ? ptMessages.ptScripts : messages.scripts,
      link: isPTCrm ? '/personal-training-crm/scripts' : '/crm/scripts',
      iconComponent: Scripts,
      viewBox: '0 0 29 24',
    });
  }

  if (hasCampaignViewPermission) {
    crmModules.push({
      id: 'campaigns',
      message: isPTCrm ? ptMessages.ptCampaigns : messages.campaigns,
      link: isPTCrm ? '/personal-training-crm/campaigns' : '/crm/campaigns',
      iconComponent: Campaigns,
      viewBox: '0 0 27 24',
    });
  }

  crmModules.push({
    id: 'documentTemplates',
    message: isPTCrm ? ptMessages.ptDocumentTemplates : messages.templates,
    link: isPTCrm ? '/personal-training-crm/templates/sms' : '/crm/templates/sms',
    iconComponent: Comment,
    viewBox: '0 0 24 24',
  });

  return <MainModulePage routes={crmModules} pageTitle={mainTitle} />;
};

export default MainPage;
