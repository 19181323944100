import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { selectTimezone } from 'common/state/settings/selectors';

const useTimezoneDate = (): ((date: Date) => Date) => {
  const timezone: string = useSelector(selectTimezone);

  return useCallback(
    (date: Date) => {
      const tzDate = new Date(
        date.toLocaleString('en-US', {
          timeZone: timezone,
        }),
      );

      const diff = date.getTime() - tzDate.getTime();

      return new Date(date.getTime() - diff);
    },
    [timezone],
  );
};

export default useTimezoneDate;
