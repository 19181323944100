import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Box, Typography } from '@material-ui/core';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { formatPrice, getQueueColor } from 'common/utils';

import {
  IScheduledBillingActivityImt,
  ScheduledBillingsActivityType,
} from 'modules/reports/interfaces/scheduledBillingsReport';
import { IChartLineSettings, PeriodFilterType } from 'modules/reports/interfaces/common';

import { ChartPeriodFilter, LoadingBackdrop } from 'common/components';
import BillingsChartLegend from '../BillingsChartLegend/BillingsChartLegend';
import { CustomAxisTick } from 'modules/reports/components/index';

import messages from 'modules/reports/messages/messages';
import commonMessages from 'common/messages/messages';
import { colors } from 'common/ui/theme/default';
import { DEFAULT_DATE_FORMAT } from 'common/constants/dateFormats';

interface IProps {
  chartData: ImmutableList<IScheduledBillingActivityImt>;
  isChartDataLoading: boolean;
  selectedPeriod: PeriodFilterType;
  onPeriodChange: (period: PeriodFilterType) => void;
}

const BillingsChart = ({
  chartData,
  isChartDataLoading,
  selectedPeriod,
  onPeriodChange,
}: IProps): JSX.Element => {
  const [chartFilters, setChartFilters] = useState<ScheduledBillingsActivityType[]>(() =>
    Object.values(ScheduledBillingsActivityType),
  );

  const [timezoneMoment] = useTimezoneMoment();

  const renderIntlMessage = useRenderIntlMessage();

  const transformedChartData = useMemo(() => chartData?.toJS() || [], [chartData]);

  const chartLineSettings: IChartLineSettings[] = useMemo(
    () => [
      {
        key: ScheduledBillingsActivityType.PAID_BILLING,
        name: renderIntlMessage(messages.paidBillingSchedulesLabel),
        dataKey: ScheduledBillingsActivityType.PAID_BILLING,
        isHidden: !chartFilters.includes(ScheduledBillingsActivityType.PAID_BILLING),
        fillColor: getQueueColor(0),
      },
      {
        key: ScheduledBillingsActivityType.UNPAID_BILLING,
        name: renderIntlMessage(messages.unpaidBillingSchedulesLabel),
        dataKey: ScheduledBillingsActivityType.UNPAID_BILLING,
        isHidden: !chartFilters.includes(ScheduledBillingsActivityType.UNPAID_BILLING),
        fillColor: getQueueColor(1),
      },
      {
        key: ScheduledBillingsActivityType.PAID_PAST_DUE,
        name: renderIntlMessage(messages.paidPastDuesLabel),
        dataKey: ScheduledBillingsActivityType.PAID_PAST_DUE,
        isHidden: !chartFilters.includes(ScheduledBillingsActivityType.PAID_PAST_DUE),
        fillColor: getQueueColor(2),
      },
      {
        key: ScheduledBillingsActivityType.UNPAID_PAST_DUE,
        name: renderIntlMessage(messages.unpaidPastDuesLabel),
        dataKey: ScheduledBillingsActivityType.UNPAID_PAST_DUE,
        isHidden: !chartFilters.includes(ScheduledBillingsActivityType.UNPAID_PAST_DUE),
        fillColor: getQueueColor(3),
      },
    ],
    [chartFilters, renderIntlMessage],
  );

  const getTickFormatter = () => {
    switch (selectedPeriod) {
      case PeriodFilterType.DAY:
        return (date: string) => timezoneMoment(date, 'YYYY-MM-DD').format('MMM D');
      case PeriodFilterType.WEEK:
        return (date: string) =>
          `${renderIntlMessage(commonMessages.weekLabel)} ${Number(date?.slice(4))}`;
      case PeriodFilterType.MONTH:
        return (date: string) => timezoneMoment(date, 'MMM YYYY').format('MMM');
      default:
        return (date: string) => date;
    }
  };

  const getTooltipFormatter = (date: string) => {
    switch (selectedPeriod) {
      case PeriodFilterType.DAY:
        return timezoneMoment(date, 'YYYY-MM-DD').format(DEFAULT_DATE_FORMAT);
      case PeriodFilterType.WEEK:
        return `${renderIntlMessage(commonMessages.weekLabel)} ${Number(date.slice(4))}`;
      default:
        return date;
    }
  };

  const handleChangePeriodFilter = useCallback(
    (period: PeriodFilterType) => {
      onPeriodChange(period);
    },
    [onPeriodChange],
  );

  const handleChangeReportLegendFilters = useCallback(
    (legendFilters: ScheduledBillingsActivityType[]): void => setChartFilters(legendFilters),
    [],
  );

  return (
    <Box p={2} position="relative">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography variant="h4" noWrap>
            <FormattedMessage {...messages.billingsLabel} />
          </Typography>

          <ChartPeriodFilter onPeriodChange={handleChangePeriodFilter} period={selectedPeriod} />
        </Box>

        <BillingsChartLegend onChange={handleChangeReportLegendFilters} />
      </Box>

      <ResponsiveContainer width="100%" minHeight="300px">
        <AreaChart
          width={500}
          height={368}
          data={transformedChartData}
          margin={{
            top: 24,
            right: 8,
            left: 0,
            bottom: 6,
          }}
          style={{ fontSize: 12 }}
        >
          <CartesianGrid fill={colors.input.disabled.backgroundColor} strokeDasharray="3 3" />

          <YAxis
            tick={<CustomAxisTick formatter={price => `${formatPrice(price)}`} />}
            axisLine={false}
          />
          <XAxis
            dataKey="date"
            axisLine={false}
            interval="preserveStartEnd"
            tickFormatter={getTickFormatter()}
          />

          <Tooltip
            formatter={value => formatPrice(value as number)}
            labelFormatter={getTooltipFormatter}
          />

          {chartLineSettings.map(({ dataKey, name, fillColor, isHidden }, index) => (
            <Area
              type="monotone"
              dataKey={dataKey}
              name={name}
              stackId={index}
              strokeWidth="2px"
              activeDot={{ r: 5 }}
              hide={isHidden}
              fill={fillColor}
              stroke={getQueueColor(index)}
              key={dataKey}
            />
          ))}
        </AreaChart>
      </ResponsiveContainer>

      <LoadingBackdrop isLoading={isChartDataLoading} />
    </Box>
  );
};

export default BillingsChart;
