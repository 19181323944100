import { createAction } from '@reduxjs/toolkit';
import { Action } from 'redux';
import { batch } from 'react-redux';

import Services from 'services/network';

import { IPrimaryMemberInfo, IProfileInfo } from 'common/components/PersonProfile/interfaces';
import { actionTypes } from './constants';
import { PersonSearchParamsType } from 'common/interfaces/http';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { PeakModules } from 'common/constants/peakModules';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { ILeadProfile } from 'modules/crm/interfaces/leads';
import { GeneralThunkAction } from 'common/state/interfaces';
import { INamedEntity } from 'common/interfaces/common';
import { createPersonPrepAction } from 'common/components/PersonProfile/state/actions';
import { ActionResult } from 'common/constants';
import { mapToAddressLine } from 'common/utils/responseMapper';

interface ISetPrimaryInfo extends Action {
  payload: IPrimaryMemberInfo;
}

interface ISetErrorAction extends Action {
  payload: string;
}

interface ISetLoadingAction extends Action {
  payload: boolean;
}

export type ActionPrimaryInfoTypes = ISetPrimaryInfo | ISetErrorAction | ISetLoadingAction;

// Actions
export const setMemberAction = createAction<IPrimaryMemberInfo>(actionTypes.SET_PRIMARY_INFO);
export const getMembersAction = createAction<any[]>(actionTypes.GET_PERSONS);
export const clearNewMemberDataAction = createAction(actionTypes.CLEAR_NEW_PERSON_DATA);
const setLoading = createAction<boolean>(actionTypes.SET_LOADING_VALUE);
const setCreateMemberActionResult = createAction<ActionResult>(
  actionTypes.SET_CREATE_PERSON_ACTION_RESULT,
);
const setUpdateMemberActionResult = createAction<ActionResult>(
  actionTypes.SET_UPDATE_PERSON_ACTION_RESULT,
);
export const resetCreateMemberResult = createAction(actionTypes.RESET_CREATE_PERSON_RESULT);
export const resetUpdateMemberResult = createAction(actionTypes.RESET_UPDATE_PERSON_RESULT);
const updateProfileAfterEditPersonAction = createPersonPrepAction<IPrimaryMemberInfo>(
  actionTypes.UPDATE_PROFILE_AFTER_EDIT_PERSON_ACTION,
);

// Action creators
export const getMembers = (value: string): GeneralThunkAction => {
  return async dispatch => {
    const members = await Services.Members.getMembers(value, null);
    dispatch(getMembersAction(members));
  };
};

export const clearMembers = (): GeneralThunkAction => {
  return async dispatch => {
    dispatch(getMembersAction([]));
  };
};

export const getMember = (
  id: string,
  module: PeakModuleForNewPersonType = PeakModules.FrontDesk,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(setLoading(true));
    try {
      let response: IProfileInfo;
      switch (module) {
        case PeakModules.FrontDesk:
          response = await Services.FrontDesk.getMember(id);
          break;
        case PeakModules.KidZone:
          response = await Services.KidZone.getKidProfileEdit(id);
          break;

        default:
          response = await Services.Members.getMember(id);
      }
      dispatch(setMemberAction(response));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const setMember = (data: IPrimaryMemberInfo): GeneralThunkAction => {
  return async dispatch => {
    await dispatch(clearNewMemberDataAction());
    await dispatch(setMemberAction(data));
  };
};

export const createMember = (data: IPrimaryMemberInfo, module: PeakModules): GeneralThunkAction => {
  return async dispatch => {
    dispatch(setLoading(true));

    try {
      let response: IPrimaryMemberInfo;

      switch (module) {
        case PeakModules.FrontDesk:
          response = await Services.FrontDesk.createMember(data);
          break;
        case PeakModules.PosKiosk:
          response = await Services.PosKiosk.createMember(data);
          break;
        default:
          response = await Services.Members.createMember(data);
      }

      batch(() => {
        dispatch(setCreateMemberActionResult(ActionResult.SUCCESS_ACTION));
        dispatch(setMemberAction(response));
      });
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const updateMember = (
  memberId: string,
  data: IPrimaryMemberInfo,
  module: PeakModuleForNewPersonType = PeakModules.FrontDesk,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(setLoading(true));

    try {
      let response: IPrimaryMemberInfo;

      switch (module) {
        case PeakModules.FrontDesk:
          response = await Services.FrontDesk.updateCustomer(memberId, data);
          break;
        case PeakModules.Members:
          response = await Services.Members.updateCustomer(memberId, data);
          break;
        default:
          response = await Services.KidZone.updateKidProfile(memberId, data);
      }

      const profileData = response.address
        ? { ...response, addressLine: mapToAddressLine(response.address) }
        : response;

      batch(() => {
        dispatch(setMemberAction(response));
        dispatch(updateProfileAfterEditPersonAction(profileData, memberId));
        dispatch(setUpdateMemberActionResult(ActionResult.SUCCESS_ACTION));
      });
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

// search persons list
const searchPersonsLoading = createAction<boolean>(actionTypes.FETCH_PERSONS_LOADING);
const searchPersonsError = createAction<boolean>(actionTypes.FETCH_PERSONS_ERROR);
export const searchPersonsAction = createAction<
  IPrimaryMemberInfo[] | ILeadProfile[] | INamedEntity[]
>(actionTypes.FETCH_PERSONS_SUCCESS);
export const resetPersonsFound = createAction(actionTypes.RESET_PERSONS_FOUND);

export const searchMembers = (
  searchStr: string,
  field: PersonSearchParamsType,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(searchPersonsLoading(true));
    try {
      let membersList: IPrimaryMemberInfo[];

      switch (module) {
        case PeakModules.Crm:
          membersList = await Services.Leads.getSearchLeads(searchStr, field);
          break;
        case PeakModules.PersonalTrainingCrm:
          membersList = await Services.PTLeads.getSearchLeads(searchStr, field);
          break;
        case PeakModules.FrontDesk:
          membersList = await Services.FrontDesk.getSearchMembers(searchStr, field);
          break;
        default:
          membersList = await Services.Members.getSearchMembers(searchStr, field);
      }
      dispatch(searchPersonsAction(membersList));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
      dispatch(searchPersonsError(error));
    } finally {
      dispatch(searchPersonsLoading(false));
    }
  };
};

export const searchLeads = (
  searchStr: string,
  field: PersonSearchParamsType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(searchPersonsLoading(true));
    try {
      const leads = await Services.Leads.getSearchLeads(searchStr, field);
      dispatch(searchPersonsAction(leads));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(searchPersonsLoading(false));
    }
  };
};

export const searchReferralMembers = (
  searchStr: string,
  module: PeakModules = PeakModules.FrontDesk,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(searchPersonsLoading(true));
    try {
      let response: INamedEntity[];

      switch (module) {
        case PeakModules.Crm:
          response = await Services.Leads.getReferralLeads(searchStr);
          break;
        case PeakModules.Members:
          response = await Services.Members.getReferralMembers(searchStr);
          break;
        default:
          response = await Services.FrontDesk.getReferralMembers(searchStr);
      }

      dispatch(searchPersonsAction(response));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(searchPersonsLoading(false));
    }
  };
};
