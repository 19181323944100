import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { List as ImmutableList } from 'immutable';
import { FormattedMessage } from 'react-intl';

import * as actions from 'common/components/PersonProfile/state/actions';
import { resetCheckInPersonActionResult } from 'modules/front-desk/state/checkins/actions';
import * as selectors from 'common/components/PersonProfile/state/selectors';
import { selectMainPanelPersonId } from 'modules/front-desk/state/selectors';
import {
  selectCheckInActionResult,
  selectCurrentCheckIn,
} from 'modules/front-desk/state/checkins/selectors';

import { PeakModules } from 'common/constants/peakModules';
import { ActionResult } from 'common/constants';

import { ICheckInHistoryImt } from 'common/components/PersonProfile/interfaces';
import { ICheckInImt } from 'modules/front-desk/interfaces';

import { useAppDispatch } from 'store/hooks';

import {
  CheckInBlock,
  CheckInsHistoryModal,
  CheckInsHistoryTable,
} from 'common/components/PersonProfile/components/index';

import commonMessages from 'common/messages/messages';
import permissionsMessages from 'modules/permissions/messages/messages';

interface IProps {
  personId: string;
  module: PeakModules;
}

const CheckInsHistory = ({ personId, module }: IProps): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const recentCheckInsHistoryList: ImmutableList<ICheckInHistoryImt> = useSelector(
    selectors.selectRecentCheckInsHistoryList(personId),
  );
  const isRecentCheckInsHistoryLoading: boolean = useSelector(
    selectors.selectRecentCheckInsHistoryLoading(personId),
  );
  const mainPanelPersonId: string = useSelector(selectMainPanelPersonId);
  const currentCheckIn: ICheckInImt = useSelector(selectCurrentCheckIn);
  const checkInActionResult: ActionResult = useSelector(selectCheckInActionResult);

  const [isCheckInsHistoryModalOpen, setIsCheckInsHistoryModalOpen] = useState(false);

  useEffect(() => {
    dispatch(actions.fetchRecentCheckInsHistory(personId, module));
  }, [dispatch, personId, module]);

  useEffect(() => {
    if (
      module === PeakModules.FrontDesk &&
      mainPanelPersonId === currentCheckIn?.get('personId') &&
      checkInActionResult === ActionResult.SUCCESS_ACTION
    ) {
      dispatch(actions.fetchRecentCheckInsHistory(personId, module));
      dispatch(resetCheckInPersonActionResult());
    }
  }, [checkInActionResult, currentCheckIn, dispatch, mainPanelPersonId, module, personId]);

  return (
    <>
      <CheckInBlock
        headerDivider
        title={<FormattedMessage {...commonMessages.checkinHistoryTitle} />}
        buttonTitle={<FormattedMessage {...permissionsMessages.viewAll} />}
        onClickButton={() => setIsCheckInsHistoryModalOpen(true)}
      >
        <CheckInsHistoryTable
          disableSorting
          checkInsHistory={recentCheckInsHistoryList}
          isCheckInsHistoryLoading={isRecentCheckInsHistoryLoading}
          hidePagination
          hideSearchInput
        />
      </CheckInBlock>

      {isCheckInsHistoryModalOpen && (
        <CheckInsHistoryModal
          isOpen={isCheckInsHistoryModalOpen}
          onClose={() => setIsCheckInsHistoryModalOpen(false)}
          personId={personId}
          module={module}
        />
      )}
    </>
  );
};

export default CheckInsHistory;
