import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Box, Grid, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { useFormContext, useWatch } from 'react-hook-form';

// helpers
import {
  clearSigWebTablet,
  closeSigWebTablet,
  setSignatureConfig,
  startSigWebTablet,
  getFieldName,
} from 'helpers';
// utils
import { formatBase64ToFile, generateFileName } from 'common/utils';
// constants
import { SIGNATURE_Y_SIZE } from 'common/components/Steps/SignDocumentStep/constants';
import { ServerError } from 'common/errors/serverErrors';
import { SignType } from 'common/constants/contract';
// hooks
import useWindowWidth from 'common/hooks/useWindowWidth';
// components
import {
  ButtonsControl,
  SigningTypeSelector,
  ChosenSignatureField,
  Contract,
  ContractStatus,
} from 'common/components/Steps/SignDocumentStep/components';
import { Button, TooltipTypography } from 'common/components';
// icons
import { ReactComponent as PrinterIcon } from 'img/icons/printer_deprecated.svg';
// messages
import commonMessages from 'common/messages/messages';
// interfaces
import { CustomTheme } from 'common/ui/interfaces';
import { IContactInfo, IPersonDocumentItemDto } from 'common/interfaces/contract';
import { IAttachment } from 'common/interfaces/uploadFile';
// actions
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { StepContext } from 'common/createContext/stepContext';
import { WaiverStatus } from 'modules/crm/constants/leads';

export interface ISignDocumentStepProps {
  isSignedDocument: boolean;
  setIsSignedDocumentData: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  contract: Partial<IPersonDocumentItemDto>;
  contactInfo: Partial<IContactInfo>;
  isLoading?: boolean;
  fetchApplySignature: (file: File, documentId: string) => void;
  onPrint?: () => void;
  hasHiddenFooter?: boolean;
  hasHiddenHeader?: boolean;
  onApplyTopazIsSigned?: (isSigned: boolean) => void;
  additionalFieldName?: string;
  extraClassName?: string;
  title?: string;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  contractStep: {
    height: '100%',
    padding: theme.spacing(0, 2),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
  },
  contractHeader: {
    marginBottom: theme.spacing(0.625),
  },
  controlBlock: {
    padding: theme.spacing(3, 0, 1.5),
  },
  contractTitle: {
    maxWidth: theme.spacing(18.75),
    display: 'inline-block',
  },
  signViaTopaz: {
    marginBottom: theme.spacing(1),
  },
  signViaSMS: {
    marginBottom: theme.spacing(2.875),
  },
  signInPerson: {
    display: 'contents',
  },
  signWrapper: {
    position: 'relative',
  },
  footer: {
    '&>div': {
      padding: theme.spacing(2, 0),
    },
  },
}));

const SignDocumentStep = (props: ISignDocumentStepProps): JSX.Element => {
  const {
    contract,
    contactInfo,
    fetchApplySignature,
    hasHiddenFooter,
    hasHiddenHeader,
    isSignedDocument,
    setIsSignedDocumentData,
    additionalFieldName = '',
    extraClassName,
    onPrint,
    title,
    isLoading = false,
  } = props;

  const { documentText, code, signature, documentId } = contract;

  const { phones = [] } = contactInfo;

  const dispatch = useDispatch();
  // Topaz
  const [widthSigTopaz, setWidthSigTopaz] = useState<number>(0);
  const [isConnectedTopaz, setIsConnectedTopaz] = useState(false);
  const [captureImgBase64, setCaptureImgBase64] = useState<string>();
  const [isSigWebLoading, setIsSigWebLoading] = useState(false);
  const [canClearSignature, setCanClearSignature] = useState(false);

  const ref = useRef<HTMLElement>();

  const { onBack, renderFooter } = useContext(StepContext);

  const width = useWindowWidth();

  const classes = useStyles();

  const needToSign = contract.status !== WaiverStatus.NO_SIGN;

  useEffect(() => {
    const padding = 16;

    const { clientWidth } = ref.current;

    const widthSignatureTopaz = clientWidth - padding * 2;

    setWidthSigTopaz(widthSignatureTopaz);

    const config = {
      displayXSize: clientWidth,
      displayYSize: SIGNATURE_Y_SIZE,
    };

    setSignatureConfig({ config });
  }, [width]);

  const setIsSignedData = useCallback(
    (isSigned: boolean) => (prevState: { [key: string]: boolean }) => {
      return Boolean(prevState[documentId]) !== isSigned
        ? {
            ...prevState,
            [documentId]: isSigned,
          }
        : prevState;
    },
    [documentId],
  );

  useEffect(() => {
    if (signature?.id) {
      setIsSignedDocumentData(setIsSignedData(true));
    }
  }, [signature, setIsSignedDocumentData, documentId, setIsSignedData]);

  const { control } = useFormContext();

  const signTypeFieldName = getFieldName('signType', additionalFieldName);
  const signInPersonFieldName = getFieldName('files', additionalFieldName);

  const signType: SignType = useWatch({
    control,
    name: signTypeFieldName,
  });

  const files: IAttachment[] | undefined = useWatch({
    control,
    name: signInPersonFieldName,
  });

  useEffect(() => {
    if (files?.length) {
      if (signType === SignType.InPerson && !isSignedDocument) {
        setIsSignedDocumentData(setIsSignedData(true));
      }
    } else if (signType === SignType.InPerson) {
      setIsSignedDocumentData(setIsSignedData(false));
    }

    if (signType === SignType.SendViaSMS || signType === SignType.SendViaEmail) {
      setIsSignedDocumentData(setIsSignedData(true));
    }

    if (signType === SignType.ViaTopaz) {
      setIsSignedDocumentData(setIsSignedData(false));
    }
  }, [files, isSignedDocument, setIsSignedDocumentData, signType, documentId, setIsSignedData]);

  const applySignDocument = () => {
    if (captureImgBase64) {
      const url = `data:image/png;base64,${captureImgBase64}`;
      const file = formatBase64ToFile(url, generateFileName('image/png'));

      setCanClearSignature(false);
      fetchApplySignature(file, documentId);
    }
  };

  const clearSignature = () => {
    setIsSigWebLoading(true);
    clearSigWebTablet()
      .catch(() => {
        dispatch(
          enqueueErrorNotification({
            codes: [ServerError.UNABLE_COMMUNICATE_SIGWEB],
            message: undefined,
          }),
        );
      })
      .finally(() => {
        setIsSigWebLoading(false);
        setCanClearSignature(true);
        setCaptureImgBase64(undefined);
        setIsSignedDocumentData(setIsSignedData(false));
      });
  };

  const cancelTopazConnection = () => {
    setIsSigWebLoading(true);
    closeSigWebTablet()
      .catch(() => {
        dispatch(
          enqueueErrorNotification({
            codes: [ServerError.UNABLE_COMMUNICATE_SIGWEB],
            message: undefined,
          }),
        );
      })
      .finally(() => {
        setIsConnectedTopaz(false);
        setIsSigWebLoading(false);
        setCanClearSignature(true);
        setCaptureImgBase64(undefined);
        setIsSignedDocumentData(setIsSignedData(false));
      });
  };

  const onStartSigning = () => {
    setIsSigWebLoading(true);
    startSigWebTablet({ setSigCaptured: setCaptureImgBase64 })
      .then(() => {
        setIsConnectedTopaz(true);
      })
      .catch(e => {
        dispatch(
          e.codes
            ? enqueueErrorNotification(e)
            : enqueueErrorNotification({
                codes: [ServerError.UNABLE_COMMUNICATE_SIGWEB],
                message: undefined,
              }),
        );
      })
      .finally(() => {
        setIsSigWebLoading(false);
      });
  };

  const isSignContractLoading =
    SignType.ViaTopaz === signType ? isSignedDocument && isLoading : isLoading;

  return (
    <Box className={classNames(classes.contractStep, extraClassName)} {...{ ref }}>
      {hasHiddenHeader || (
        <Grid
          container
          item
          spacing={2}
          wrap="nowrap"
          justifyContent="space-between"
          alignItems="center"
          direction="row"
          className={classes.contractHeader}
        >
          <Grid container item spacing={2} wrap="nowrap" direction="row">
            <Grid item>
              <TooltipTypography
                className={classes.contractTitle}
                ellipsized
                color="textPrimary"
                variant="button"
              >
                {title || <FormattedMessage {...commonMessages.membership} />}
              </TooltipTypography>
            </Grid>
            <Grid item>
              <Typography variant="button" color="textSecondary">
                #{code}
              </Typography>
            </Grid>
            <Grid item container alignItems="center">
              <ContractStatus status={contract.status} />
            </Grid>
          </Grid>
          <Grid item>
            <Button
              type="button"
              variant="text"
              size="small"
              startIcon={<PrinterIcon />}
              onClick={onPrint}
              color="primary"
            />
          </Grid>
        </Grid>
      )}
      <Contract
        signatureId={signature?.id}
        canClearSignature={canClearSignature}
        documentText={documentText}
      />
      {needToSign && (
        <Grid
          className={classes.controlBlock}
          container
          item
          spacing={1}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <SigningTypeSelector fieldName={signTypeFieldName} />
          {isConnectedTopaz && (
            <ButtonsControl
              isLoading={isSigWebLoading}
              isClearBtnDisabled={!canClearSignature}
              isSignedDocument={isSignedDocument}
              applySignDocument={applySignDocument}
              clearSignature={clearSignature}
              cancelTopazConnection={cancelTopazConnection}
            />
          )}
        </Grid>
      )}
      {needToSign && (
        <Grid
          item
          className={classNames(classes.signWrapper, {
            [classes.signViaSMS]: signType === SignType.SendViaSMS,
            [classes.signViaTopaz]: signType === SignType.ViaTopaz,
            [classes.signInPerson]: signType === SignType.InPerson,
          })}
        >
          <ChosenSignatureField
            additionalFieldName={additionalFieldName}
            contractSignType={signType}
            isConnectedTopaz={isConnectedTopaz}
            isSigWebLoading={isSigWebLoading}
            onStartSigning={onStartSigning}
            widthSigTopaz={widthSigTopaz}
            phones={phones}
          />
        </Grid>
      )}
      {hasHiddenFooter || (
        <Box className={classes.footer} position="relative" zIndex="6">
          {Boolean(renderFooter) && renderFooter(onBack, null, isSignContractLoading)}
        </Box>
      )}
    </Box>
  );
};

export default SignDocumentStep;
