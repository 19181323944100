import { defineMessages } from 'react-intl';

export default defineMessages({
  guardiansTitle: {
    id: 'app.modules.kid-zone.guardiansTitle',
    defaultMessage: 'Guardians',
  },
  noGuardiansText: {
    id: 'app.modules.kid-zone.manualCheckIn.guardiansBlock.noGuardiansText',
    defaultMessage: 'No guardians',
  },
  childAge: {
    id: 'app.modules.kid-zone.manualCheckIn.childrenBlock.children',
    defaultMessage: 'Child, {year} old ({birthday})',
  },
  noChildrenText: {
    id: 'app.modules.kid-zone.manualCheckIn.childrenBlock.children',
    defaultMessage: 'No children',
  },
  childrenTitle: {
    id: 'app.modules.kid-zone.manualCheckIn.childrenBlock.childrenTitle',
    defaultMessage: 'Children',
  },
  barcodeCheckInTitle: {
    id: 'app.modules.kid-zone.modals.barcodeScannerNameTitle',
    defaultMessage: 'Barcode Check-in',
  },
  selectChildTitle: {
    id: 'app.modules.kid-zone.modals.selectChildTitle',
    defaultMessage: 'Select the child',
  },
  selectGuardianTitle: {
    id: 'app.modules.kid-zone.modals.selectGuardianTitle',
    defaultMessage: 'Select the guardian',
  },
  checkInAllTitle: {
    id: 'app.modules.kid-zone.modals.checkInAllTitle',
    defaultMessage: 'Check-in All',
  },
  backToDashboardBtn: {
    id: 'app.modules.kid-zone.modals.checkInAllTitle',
    defaultMessage: 'Back To Dashboard',
  },
  kidZoneNotConfiguredTitle: {
    id: 'app.modules.kid-zone.modals.kidZoneNotConfiguredTitle',
    defaultMessage: 'KidZone service unavailable in this club',
  },
  kidZoneNotConfiguredLabel: {
    id: 'app.modules.kid-zone.modals.kidZoneNotConfiguredLabel',
    defaultMessage:
      'Please contact your administrator to enable it or our Peak sales department for purchase service',
  },
  kidZoneOutsideWorkingHoursTitle: {
    id: 'app.modules.kid-zone.modals.kidZoneOutsideWorkingHoursTitle',
    defaultMessage: 'KidZone outside working hours',
  },
  kidZoneOutsideWorkingHoursInactiveLabel: {
    id: 'app.modules.kid-zone.modals.kidZoneOutsideWorkingHoursInactiveLabel',
    defaultMessage: '{title} (INACTIVE)',
  },
  daycareRedeemTitle: {
    id: 'app.modules.kid-zone.modals.daycareRedeemModal.title',
    defaultMessage: 'Daycare redeem',
  },
  noPurchasedPackagesLabel: {
    id: 'app.modules.kid-zone.modals.daycareRedeemModal.noPurchasedPackagesLabel',
    defaultMessage: 'You have no purchased packages',
  },
  childAlreadyCheckedInText: {
    id: 'app.modules.kid-zone.modals.checkInMembersByBarcodeModal.childAlreadyCheckedInText',
    defaultMessage:
      'Child already checked in at {checkInTime}. You need to do check out first before brought.',
  },
});
