import React from 'react';
import { useSelector } from 'react-redux';
import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

import { selectKidZoneStatus } from 'modules/kid-zone/state/selectors';

import useComponentUpdate from 'common/hooks/useComponentUpdate';
import useTimeFromCheckIn from 'modules/kid-zone/hooks/useTimeFromCheckIn';

import { IKidZoneStatusImt } from 'modules/kid-zone/interfaces';

import { ReactComponent as ClockIcon } from 'img/icons/clock.svg';
import { ICheckInImt } from 'modules/front-desk/interfaces';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    clockIcon: {
      marginLeft: theme.spacing(0.5),
    },
  }),
);

interface IProps {
  checkIn: ICheckInImt;
}

const WARNING_PERCENTAGE = 0.8;

const TimeFromCheckIn = ({ checkIn }: IProps): JSX.Element => {
  // state
  const kidZoneStatus: IKidZoneStatusImt = useSelector(selectKidZoneStatus);

  useComponentUpdate();

  const classes = useStyles();

  const kidZoneDuration = kidZoneStatus.get('maxDuration');

  const { elapsedTimeLabel, minutes: minutesFromCheckIn } = useTimeFromCheckIn(
    checkIn.get('checkInTime'),
  );

  const maxDuration = checkIn.getIn(['serviceLimitation', 'limited'])
    ? Math.max(kidZoneDuration, checkIn.getIn(['serviceLimitation', 'leftNumber']))
    : kidZoneDuration;

  const getClockColor = (): string => {
    if (minutesFromCheckIn > maxDuration) {
      return 'error.main';
    }

    if (minutesFromCheckIn / maxDuration > WARNING_PERCENTAGE && minutesFromCheckIn < maxDuration) {
      return 'warning.main';
    }

    return 'darkBackground.light';
  };

  return (
    <Box display="flex" alignItems="center" color={getClockColor()}>
      <Typography color="inherit">{elapsedTimeLabel}</Typography>

      <ClockIcon className={classes.clockIcon} />
    </Box>
  );
};

export default TimeFromCheckIn;
