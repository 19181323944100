import React from 'react';
import { Box, FormControlLabel } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import inputLabels from 'common/messages/inputLabels';
import { Checkbox } from 'common/components';

const LeadSettingsSection = (): JSX.Element => {
  const { control } = useFormContext();

  return (
    <Box display="flex">
      <Box pr={2}>
        <Controller
          render={({ onChange, value }) => (
            <FormControlLabel
              control={
                <Checkbox
                  size="medium"
                  checked={value}
                  onChange={e => onChange(e.target.checked)}
                  color="primary"
                />
              }
              label={<FormattedMessage {...inputLabels.lead} />}
            />
          )}
          control={control}
          name="leadMember"
        />
      </Box>
      <Controller
        render={({ onChange, value }) => (
          <FormControlLabel
            control={
              <Checkbox
                size="medium"
                checked={value}
                onChange={e => onChange(e.target.checked)}
                color="primary"
              />
            }
            label={<FormattedMessage {...inputLabels.ptLead} />}
          />
        )}
        control={control}
        name="ptLeadMember"
      />
    </Box>
  );
};

export default LeadSettingsSection;
