import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import useTimezoneMoment from 'common/hooks/useTimezoneMoment';

import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';
import { IPageMetaImt } from 'common/interfaces/pagination';

import { IHeadCell, ITableParams, ITableRow, Order } from 'common/interfaces/table';

import { Table } from 'common/components';
import tableHeaders from 'common/messages/tableHeaders';
import { CheckInHistoryReportItemsImt } from 'modules/reports/interfaces/checkInHistory';
import { TableOrderByParams } from 'common/constants';

interface IProps {
  checkInHistoryReportItems: CheckInHistoryReportItemsImt;
  isCheckInHistoryReportItemsLoading: boolean;
  checkInHistoryReportItemsMeta: IPageMetaImt;
  onChangeTableParams: (tableParams: ITableParams) => void;
  currentPage: number;
  defaultOrderBy: TableOrderByParams;
  defaultOrder: Order;
}

const headerOptions: IHeadCell[] = [
  { id: 'date', label: <FormattedMessage {...tableHeaders.date} />, sort: true },
  { id: 'club', label: <FormattedMessage {...tableHeaders.club} />, sort: true },
  { id: 'entrance', label: <FormattedMessage {...tableHeaders.entrance} />, sort: true },
  { id: 'member', label: <FormattedMessage {...tableHeaders.member} />, sort: true },
];

const CheckInHistoryReportItemsTable = ({
  checkInHistoryReportItems,
  isCheckInHistoryReportItemsLoading,
  checkInHistoryReportItemsMeta,
  onChangeTableParams,
  currentPage,
  defaultOrderBy,
  defaultOrder,
}: IProps): JSX.Element => {
  const [timezoneMoment] = useTimezoneMoment();

  const handleChangeTableProps = useCallback(
    (tableProps: ITableParams): void => {
      onChangeTableParams(tableProps);
    },
    [onChangeTableParams],
  );

  const rows = useMemo(
    () =>
      checkInHistoryReportItems
        .map(
          (item): ITableRow => ({
            id: item.get('id'),
            cells: [
              {
                label: item.get('checkinDate')
                  ? timezoneMoment(item.get('checkinDate')).format(DEFAULT_DATE_TIME_FORMAT)
                  : '-',
              },
              {
                label: item.get('club') ? item.getIn(['club', 'title']) : '-',
              },
              {
                label: item.get('entrance') ? item.getIn(['entrance', 'title']) : '-',
              },
              {
                label: item.get('customer')
                  ? `${item.getIn(['customer', 'firstName'])} ${item.getIn([
                      'customer',
                      'lastName',
                    ])}`
                  : '-',
              },
            ],
          }),
        )
        .toJS(),
    [checkInHistoryReportItems, timezoneMoment],
  );

  return (
    <Table
      defaultOrderBy={defaultOrderBy}
      defaultOrder={defaultOrder}
      showPerPageSelect
      rows={rows}
      headerOptions={headerOptions}
      hideSearchInput
      isLoading={isCheckInHistoryReportItemsLoading}
      page={currentPage}
      totalRows={checkInHistoryReportItemsMeta?.get('total')}
      onChangeParams={handleChangeTableProps}
    />
  );
};

export default CheckInHistoryReportItemsTable;
