import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import { ICampaignListItem } from 'modules/crm/interfaces/campaigns';
import { BooleanLabel } from 'common/components';
import CampaignStatus from '../CampaignStatus/CampaignStatus';

const useStyles = makeStyles(() =>
  createStyles({
    mainLabel: {
      fontSize: '0.95rem',
      fontWeight: 700,
    },
    caption: {
      display: 'block',
      fontSize: '0.65rem',
      lineHeight: '1.30rem',
      color: 'rgba(50, 50, 50, 0.3)',
    },
    avatar: {
      fontSize: '0.75rem',
      width: 20,
      height: 20,
    },
  }),
);

interface IProps {
  campaign: ICampaignListItem;
}

const SmallScreenCell = (props: IProps) => {
  const classes = useStyles();
  const {
    campaign: { title, campaignType, script, status, automatic },
  } = props;

  return (
    <Grid container spacing={2} wrap="nowrap">
      <Grid item>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Typography className={classes.mainLabel} component="span" variant="h6">
              {title}
            </Typography>
          </Grid>

          <Grid item>
            <Typography className={classes.caption} variant="caption">
              Type
            </Typography>
            <Typography variant="body2">{campaignType}</Typography>
          </Grid>

          <Grid item>
            <Typography className={classes.caption} variant="caption">
              Script
            </Typography>
            <Typography variant="body2">{script?.title || '-'}</Typography>
          </Grid>

          <Grid item>
            <Typography className={classes.caption} variant="caption">
              Status
            </Typography>
            <CampaignStatus variant="body2" status={status} />
          </Grid>

          <Grid item>
            <Typography className={classes.caption} variant="caption">
              Automatic
            </Typography>
            <BooleanLabel variant="body2" value={automatic} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(SmallScreenCell);
