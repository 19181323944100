import { createAction } from '@reduxjs/toolkit';

import Services from 'services/network';

import { actionTypes } from 'modules/front-desk/constants';

import { ITag } from 'common/components/PersonProfile/interfaces';
import { GeneralThunkAction } from 'common/state/interfaces';
import { TagType } from 'modules/dictionaries/constants/constants';

import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { IEntranceItem } from 'modules/clubs/interfaces';
import AppService from 'services/application/AppService';

export const setMainPanelPersonAction = createAction<string>(actionTypes.SET_MAIN_PANEL_PERSON);
export const setRightPanelPersonAction = createAction<string>(actionTypes.SET_RIGHT_PANEL_PERSON);
export const resetRightPanelPersonAction = createAction<string>(actionTypes.RESET_PANEL_PERSON);

const fetchClientMemberTagsAction = createAction<ITag[]>(actionTypes.FETCH_CLIENT_MEMBER_TAGS);
export const fetchClientMemberTags = (clientId: string): GeneralThunkAction => {
  return async dispatch => {
    try {
      const tags = await Services.Persons.getClientMemberTags(clientId);
      dispatch(fetchClientMemberTagsAction(tags));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    }
  };
};

const createClientMemberTagAction = createAction<Partial<ITag>>(
  actionTypes.CREATE_CLIENT_MEMBER_TAG,
);

const createClientMemberTagLoadingAction = createAction<boolean>(
  actionTypes.CREATE_CLIENT_MEMBER_TAG_LOADING,
);

export const resetCreateClientMemberTagActionSuccess = createAction(
  actionTypes.RESET_CREATE_CLIENT_MEMBER_TAG_SUCCESS,
);

export const createClientMemberTag = (tag: ITag): GeneralThunkAction => {
  return async dispatch => {
    dispatch(createClientMemberTagLoadingAction(true));
    try {
      const createdTag = await Services.Dictionaries.createTag(TagType.FRONTDESK_PROFILE, tag);
      dispatch(createClientMemberTagAction(createdTag));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(createClientMemberTagLoadingAction(false));
    }
  };
};

const fetchClubEntrancesAction = createAction<IEntranceItem[]>(actionTypes.FETCH_CLUB_ENTRANCES);
const fetchClubEntrancesLoadingAction = createAction<boolean>(
  actionTypes.FETCH_CLUB_ENTRANCES_LOADING,
);
export const fetchClubEntrances = (clubId: string): GeneralThunkAction => {
  return async dispatch => {
    try {
      dispatch(fetchClubEntrancesLoadingAction(true));
      const entrances = await Services.FrontDesk.getClubEntrances(clubId);
      dispatch(fetchClubEntrancesAction(entrances));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchClubEntrancesLoadingAction(false));
    }
  };
};

const setSelectedEntranceIdAction = createAction<string>(actionTypes.SET_SELECTED_ENTRANCE_ID);

export const resetSelectedEntranceIdAction = createAction(actionTypes.RESET_SELECTED_ENTRANCE_ID);

export const setSelectedEntranceId = (entranceId: string): GeneralThunkAction => {
  return dispatch => {
    AppService.setAppSelectedEntrance(entranceId);
    dispatch(setSelectedEntranceIdAction(entranceId));
  };
};

export const resetSelectedEntranceId = (): GeneralThunkAction => {
  return dispatch => {
    AppService.clearAppSelectedEntrance();
    dispatch(resetSelectedEntranceIdAction());
  };
};
