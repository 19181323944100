export const actionTypes = {
  FETCH_PROFILE_INFO: 'MEMBER_PORTAL/FETCH_PROFILE_INFO',
  FETCH_PROFILE_INFO_LOADING: 'MEMBER_PORTAL/FETCH_PROFILE_INFO_LOADING',
  RESET_PROFILE_INFO: 'MEMBER_PORTAL/RESET_PROFILE_INFO',
  SET_USER_AVATAR: 'MEMBER_PORTAL/SET_USER_AVATAR',
  FETCH_PERSON_ALERTS: 'MEMBER_PORTAL/FETCH_PERSON_ALERTS',
  FETCH_PERSON_ALERTS_LOADING: 'MEMBER_PORTAL/FETCH_PERSON_ALERTS_LOADING',
  RESOLVE_PAST_DUE_ALERT: 'MEMBER_PORTAL/RESOLVE_PAST_DUE_ALERT',
  FETCH_SENDER_AVAILABILITY_LOADING: 'MEMBER_PORTAL/FETCH_SENDER_AVAILABILITY_LOADING',
  FETCH_SENDER_AVAILABILITY: 'MEMBER_PORTAL/FETCH_SENDER_AVAILABILITY',
  FETCH_BALANCE_HISTORY_LOADING: 'MEMBER_PORTAL/FETCH_BALANCE_HISTORY_LOADING',
  FETCH_BALANCE_HISTORY: 'MEMBER_PORTAL/FETCH_BALANCE_HISTORY',
};
