import React from 'react';
import { FormattedMessage } from 'react-intl';

import commonMessages from 'common/messages/messages';
import QuestionnaireModal from '../QuestionnaireModal/QuestionnaireModal';

interface IChangeActiveStatusModalProps {
  isActive: boolean;
  title: string;
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const ChangeActiveStatusModal = (props: IChangeActiveStatusModalProps): JSX.Element => {
  const { isActive, title, isLoading, isOpen, onClose, onSubmit } = props;

  if (!isOpen) {
    return <></>;
  }
  return (
    <QuestionnaireModal
      title={
        <FormattedMessage
          values={{ name: title }}
          {...(isActive ? commonMessages.deactivateItem : commonMessages.activateItem)}
        />
      }
      submitBtnTitle={
        <FormattedMessage
          {...(isActive ? commonMessages.deactivateBtn : commonMessages.activateBtn)}
        />
      }
      onClose={onClose}
      onSubmit={onSubmit}
      isOpen={isOpen}
      isLoading={isLoading}
    />
  );
};

export default ChangeActiveStatusModal;
