import {
  IPackageBilling,
  IPastDue,
  ISelectableBilling,
} from 'common/components/PersonProfile/interfaces';

export const getBillingItemDescription = (item?: IPackageBilling): string => {
  const result = item?.title;

  // TODO
  /* if (item.pricePerBilling) {
    result += ` $${formatNumberToPrice(item.pricePerBilling)}/mo`;
  } */

  return result || '';
};

export const getCurrentPageList = (
  items: Array<IPastDue | ISelectableBilling>,
  page: number,
  pageSize: number,
): Array<any> => {
  const originalData = [...items];

  const startIndex = page * pageSize;
  const endIndex = startIndex + pageSize;

  return originalData.slice(startIndex, endIndex);
};
