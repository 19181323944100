import React from 'react';
import { Typography, Box, ListItem } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

import useTimezoneMoment from 'common/hooks/useTimezoneMoment';

import { IEmailImt } from 'common/components/PersonProfile/interfaces';
import { ActionsMenu, ActionItem } from 'common/components';
import messages from 'common/components/PersonProfile/messages';
import { EmailStatusMessages } from 'common/components/PersonProfile/constants';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'block',
    padding: theme.spacing(0, 2, 0, 0),
    cursor: 'pointer',
    '-webkit-tap-highlight-color': 'transparent',
  },
  itemInner: {
    borderTop: `1px solid ${theme.palette.secondary.light}`,
    padding: theme.spacing(1.5, 0, 2, 0),
  },
  subject: {
    fontWeight: 500,
  },
  small: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  status: {
    marginLeft: theme.spacing(2),
    whiteSpace: 'nowrap',
    fontSize: '0.625rem',
    textTransform: 'uppercase',
  },
  deleteIcon: {
    fontSize: '1.1rem',
    color: theme.palette.text.primary,
  },
}));

interface IEmailsItemProps {
  email: IEmailImt;
  personId: string;
  onEmailClick: (id: string) => void;
  onReply: (id: string) => void;
}

const EmailsItem = ({ email, onEmailClick, onReply }: IEmailsItemProps): JSX.Element => {
  const [timezoneMoment] = useTimezoneMoment();

  const classes = useStyles();

  return (
    <ListItem className={classes.root} onClick={() => onEmailClick(email.get('id'))}>
      <Box display="flex" justifyContent="space-between" className={classes.itemInner}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="space-between"
          flex="1"
          mt={0.75}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            flex={1}
            mb={0.5}
          >
            <Box display="flex" alignItems="center">
              <Typography component="span" variant="body2">
                {email.get('receiverEmail')}
              </Typography>
            </Box>

            <Typography variant="body2" color="textSecondary">
              {timezoneMoment(email.get('date')).format(DEFAULT_DATE_TIME_FORMAT)}
            </Typography>
          </Box>

          <Box display="flex" justifyContent="space-between">
            <Typography className={classes.subject} component="span" variant="body1">
              {email.get('subject')}
            </Typography>
            <Typography className={classes.status} component="span" color="textSecondary">
              <FormattedMessage {...EmailStatusMessages[email.get('status')]} />
            </Typography>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="flex-start" ml={0.5}>
          <ActionsMenu horizontal>
            {/* not needed for now */}

            {/* <ActionItem */}
            {/*   onClick={onDeleteClick} */}
            {/*   icon={<DeleteIcon className={classes.deleteIcon} />} */}
            {/* > */}
            {/*   <FormattedMessage {...commonMessages.deleteBtn} /> */}
            {/* </ActionItem> */}
            <ActionItem onClick={() => onEmailClick(email.get('id'))}>
              <FormattedMessage {...messages.viewTitle} />
            </ActionItem>
            <ActionItem onClick={() => onReply(email.get('id'))}>
              <FormattedMessage {...messages.replyTitle} />
            </ActionItem>
          </ActionsMenu>
        </Box>
      </Box>
    </ListItem>
  );
};

export default EmailsItem;
