import moment from 'moment-timezone';
import * as yup from 'yup';

import { getMinMaxNumberSchema } from 'common/validationSchemas/minMaxNumberSchema';
import { getRequiredMessage } from 'common/constants/globalConstants';
import {
  DurationType,
  PackageCostTypes,
  PackageType,
  PackageTypes,
} from '../../../constants/packages';
import { dateSchema } from 'common/validationSchemas/dateSchemas';
import inputErrors from 'common/messages/inputErrors';
import { nullableStringNumberSchema } from 'common/validationSchemas/stringNumberSchema';

export const generalInfoValidationSchema = yup.object().shape({
  title: yup
    .string()
    .required(getRequiredMessage)
    .min(2, () => inputErrors.generalTitleMinLength)
    .max(100, () => inputErrors.generalTitleMinLength),
  active: yup.string().required(getRequiredMessage),

  durationType: yup
    .string()
    .oneOf(Object.values(DurationType))
    .required(getRequiredMessage),
  durationEditableNumber: getMinMaxNumberSchema(1),

  renewalNumber: nullableStringNumberSchema
    .min(0, () => inputErrors.cannotBeNegative)
    .required(getRequiredMessage),

  customDates: yup
    .object()
    .nullable()
    .when('durationType', {
      is: DurationType.Custom,
      then: yup
        .object()
        .shape({
          startDate: dateSchema.required(getRequiredMessage),
          endDate: dateSchema.required(getRequiredMessage),
        })
        .required(getRequiredMessage),
    }),

  type: yup.string().oneOf(PackageTypes.stringValues()),
  costType: yup.string().when('type', {
    is: PackageType.Trial,
    then: yup
      .string()
      .oneOf(PackageCostTypes.stringValues())
      .required(getRequiredMessage),
  }),
  startDate: dateSchema
    .required(getRequiredMessage)
    .test('startDate', { ...inputErrors.startDateAfterEndDateError }, function(date) {
      if (!this.parent.startTime || !this.parent.endDate || !this.parent.endTime) {
        return true;
      }

      const startDate = moment(`${date} ${this.parent.startTime}`, 'YYYY-MM-DD HH:mm');
      const endDate = moment(`${this.parent.endDate} ${this.parent.endTime}`, 'YYYY-MM-DD HH:mm');
      const isSameDay = endDate.isSame(startDate, 'day');

      return isSameDay || endDate.isAfter(startDate);
    }),
  startTime: dateSchema
    .required(getRequiredMessage)
    .test('startTime', { ...inputErrors.startTimeAfterEndTimeError }, function(time) {
      if (!this.parent.startDate || !this.parent.endDate || !this.parent.endTime) {
        return true;
      }

      const startDate = moment(`${this.parent.startDate} ${time}`, 'YYYY-MM-DD HH:mm');
      const endDate = moment(`${this.parent.endDate} ${this.parent.endTime}`, 'YYYY-MM-DD HH:mm');
      const isSameDay = endDate.isSame(startDate, 'day');

      return isSameDay ? endDate.isAfter(startDate) || endDate.isSame(startDate) : true;
    }),
  endDate: dateSchema
    .required(getRequiredMessage)
    .test('endDate', { ...inputErrors.endDateBeforeStartDateError }, function(date) {
      if (!this.parent.endTime || !this.parent.startDate || !this.parent.startTime) {
        return true;
      }

      const startDate = moment(
        `${this.parent.startDate} ${this.parent.startTime}`,
        'YYYY-MM-DD HH:mm',
      );
      const endDate = moment(`${date} ${this.parent.endTime}`, 'YYYY-MM-DD HH:mm');
      const isSameDay = endDate.isSame(startDate, 'day');

      return isSameDay || endDate.isAfter(startDate);
    }),
  endTime: dateSchema
    .required(getRequiredMessage)
    .test('endTime', { ...inputErrors.endTimeBeforeStartTimeError }, function(time) {
      if (!this.parent.endDate || !this.parent.startDate || !this.parent.startTime) {
        return true;
      }

      const startDate = moment(
        `${this.parent.startDate} ${this.parent.startTime}`,
        'YYYY-MM-DD HH:mm',
      );
      const endDate = moment(`${this.parent.endDate} ${time}`, 'YYYY-MM-DD HH:mm');
      const isSameDay = endDate.isSame(startDate, 'day');

      return isSameDay ? endDate.isAfter(startDate) || endDate.isSame(startDate) : true;
    }),
});
