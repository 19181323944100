import { IUploadFileResult, IAttachment } from 'common/interfaces/uploadFile';
import axios from 'axios';

export default class FileUploadService {
  public uploadFile = (file: File): Promise<IUploadFileResult> => {
    const data = new FormData();
    data.append('file', Array.isArray(file) ? file[0] : file);
    return axios.post(`/api/v1/files/attachments`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };

  public uploadImage = (file: File, isPublicImage): Promise<IAttachment> => {
    const data = new FormData();
    data.append('file', file);
    return axios.post(`/api/v1/files/images`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
      params: { isPublic: isPublicImage },
    });
  };
}
