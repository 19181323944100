import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';

import commonMessages from 'common/messages/messages';
import { FormFilesToServerUploader } from 'common/components/FilesUploader';

const AttachmentsSection = (): JSX.Element => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="button" color="textSecondary">
          <FormattedMessage {...commonMessages.attachmentsLabel} />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <FormFilesToServerUploader />
      </Grid>
    </Grid>
  );
};

export default AttachmentsSection;
