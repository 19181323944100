// libraries
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { UseFormMethods } from 'react-hook-form/dist/types/form';
// custom interfaces
import { CustomTheme } from 'common/ui/interfaces';
import { IFeesSection, IFormStepStyle, IPackageFee } from '../../../interfaces/packages';
import { useFieldArray } from 'react-hook-form';
import { IFee } from '../../../interfaces/fees';
import { Box, CardContent, Divider, Grid } from '@material-ui/core';
import FeesSelector from './FeeSelector/FeesSelector';
import { DurationType, FrequencyType } from '../../../constants/packages';
import IncludedFeeItem from './IncludedFeeItem/IncludedFeeItem';

interface IFeesStepFormProps extends IFormStepStyle {
  formMethods: UseFormMethods<IFeesSection>;
  durationType: DurationType;
  durationEditableNumber: number;
}

const defaultPackageFee: IPackageFee = {
  excludable: false,
  fee: null,

  totalAmount: {
    editable: false,
    value: 0,
    minValue: 0,
    maxValue: 0,
  },

  chargeAfterDays: {
    editable: false,
    value: 0,
    minValue: 0,
    maxValue: 0,
  },

  allowSplitting: false,
  paymentSchedule: FrequencyType.Monthly,
  splitPaymentSchedule: FrequencyType.Monthly,
  paymentsNumber: {
    editable: false,
    value: 2,
    minValue: 0,
    maxValue: 0,
  },
};

const useStyles = makeStyles((theme: CustomTheme) => ({
  contentWrapper: {
    flex: 1,
    minHeight: 0,
    overflow: 'hidden',
  },
  hidden: {
    display: 'none',
  },
  cardContent: {
    padding: ({ smallPaddingContent }: IFeesStepFormProps) =>
      smallPaddingContent ? theme.spacing(1, 0) : theme.spacing(1, 2),
    '&:last-child': {
      padding: ({ smallPaddingContent }: IFeesStepFormProps) =>
        smallPaddingContent ? theme.spacing(1, 0) : theme.spacing(1, 2),
    },
  },
}));

const FeesStepForm: React.FC<IFeesStepFormProps> = (props: IFeesStepFormProps): JSX.Element => {
  const classes = useStyles(props);
  const {
    formMethods: { control, errors, trigger, watch },
    durationType,
    durationEditableNumber,
  } = props;

  // form

  const { fields, append, remove } = useFieldArray<IPackageFee, 'includedFeeKey'>({
    control,
    name: 'includedFees',
    keyName: 'includedFeeKey',
  });

  // handlers

  const handleOnInclude = (fee: IFee): void => {
    const isAlreadyAdded = fields.findIndex(field => field.fee?.id === fee.id) >= 0;
    if (isAlreadyAdded) return;

    append({ ...defaultPackageFee, fee });
  };

  // renders

  return (
    <Box className={classes.contentWrapper}>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <FeesSelector onInclude={handleOnInclude} />
              </Grid>

              {fields.map((field, index) => (
                <React.Fragment key={field.includedFeeKey}>
                  <Grid item xs={12}>
                    <IncludedFeeItem
                      control={control}
                      watch={watch}
                      trigger={trigger}
                      errors={errors}
                      index={index}
                      field={field}
                      onRemove={remove}
                      durationType={durationType}
                      durationEditableNumber={durationEditableNumber}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Box>
  );
};

export default FeesStepForm;
