import React from 'react';
import { Avatar, Box, ListItem, makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { CustomTheme } from 'common/ui/interfaces';
import { INote, INoteImt } from 'common/components/PersonProfile/interfaces';
import menuItems from 'common/messages/menuItems';
import { colors } from 'common/ui/theme/default';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { ReactComponent as DeleteIcon } from 'img/icons/trash.svg';
import { ReactComponent as EditIcon } from 'img/icons/edit.svg';
import { ActionItem, ActionsMenu } from 'common/components';

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    position: 'relative',
    display: 'block',
    padding: theme.spacing(0, 2, 0, 0),
  },
  itemInner: {
    borderTop: '1px solid #ededed',
    paddingBottom: theme.spacing(2),
  },
  date: {
    color: colors.text.mediumGray,
    marginRight: theme.spacing(1.5),
  },
  menu: {
    minWidth: 'fit-content',
  },
  avatar: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  text: {
    opacity: 0.7,
    paddingLeft: theme.spacing(3),
    paddingRight: '50px',
    wordWrap: 'break-word',
    lineHeight: 1.33,
  },
  icon: {
    width: 16,
    height: 16,
  },
}));

interface INoteItemProps {
  note: INoteImt;
  onEditClick: (note: INote) => void;
  onDeleteClick: (note: INote) => void;
}

const NotesItem = React.memo(({ note, onEditClick, onDeleteClick }: INoteItemProps) => {
  const [timezoneMoment] = useTimezoneMoment();

  const classes = useStyles();

  return (
    <ListItem className={classes.root}>
      <Box className={classes.itemInner}>
        <Box
          pt={0.5}
          pb={0.5}
          mb={1}
          mt={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center" justifyContent="flex-start">
            <Avatar src={note.getIn(['author', 'imageUrl'])} className={classes.avatar} />
            <Typography component="span" variant="body2">
              {note.getIn(['author', 'firstName'])} {note.getIn(['author', 'lastName'])}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography component="span" variant="body2" className={classes.date}>
              {timezoneMoment(note.get('createdDate')).format(DEFAULT_DATE_TIME_FORMAT)}
            </Typography>
            <ActionsMenu horizontal className={classes.menu}>
              <ActionItem
                key="1"
                icon={<EditIcon className={classes.icon} />}
                onClick={() => onEditClick(note.toJS())}
              >
                <FormattedMessage {...menuItems.edit} />
              </ActionItem>
              <ActionItem
                key="2"
                icon={<DeleteIcon className={classes.icon} />}
                onClick={() => onDeleteClick(note.toJS())}
              >
                <FormattedMessage {...menuItems.delete} />
              </ActionItem>
            </ActionsMenu>
          </Box>
        </Box>
        <Box pt="4px">
          <Typography component="p" variant="body2" className={classes.text}>
            {note.get('text')}
          </Typography>
        </Box>
      </Box>
    </ListItem>
  );
});

export default NotesItem;
