import { EditorState, ContentState, Modifier } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

export function addBlocksFromHtml(editorState, htmlString: string): any {
  const blocks = htmlToDraft(htmlString);
  const contentState = Modifier.replaceWithFragment(
    editorState.getCurrentContent(),
    editorState.getSelection(),
    ContentState.createFromBlockArray(blocks.contentBlocks, blocks.entityMap).getBlockMap(),
  );
  return EditorState.push(editorState, contentState, 'insert-fragment');
}

export const convertToEditor = (html: string) => {
  if (html) {
    const contentState = ContentState.createFromBlockArray(htmlToDraft(html).contentBlocks);
    return EditorState.createWithContent(contentState);
  }
  return undefined;
};

const decodeHtmlEntities = (text: string) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = text;
  return txt.value;
};

export const convertToText = editorState => {
  return decodeHtmlEntities(editorState.getCurrentContent().getPlainText());
};
