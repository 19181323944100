import inputErrors from 'common/messages/inputErrors';
import * as yup from 'yup';

export const loginToConfirmValidationSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email(() => inputErrors.emailInvalidError)
    .required(() => inputErrors.requiredFieldError),
  password: yup
    .string()
    .trim()
    .required(() => inputErrors.requiredFieldError),
});
