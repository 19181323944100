import React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  sectionTitle: {
    marginBottom: theme.spacing(1),
  },
}));

interface ISectionTitleProps {
  title: JSX.Element;
}

const SectionTitle = ({ title }: ISectionTitleProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Typography variant="button" color="textSecondary" className={classes.sectionTitle}>
      {title}
    </Typography>
  );
};

export default SectionTitle;
