import { fromJS } from 'immutable';
import { v4 as uuid } from 'uuid';

import { actionTypes } from 'modules/reports/constants/salesOverTime';
import { getQueueColor } from 'common/utils';

const initStateSalesOverTimeReducer = fromJS({
  data: {},
  statistics: [],
  isLoading: false,
  total: {},
  isLoadingActivity: false,
});

const salesOverTimeReducer = (state = initStateSalesOverTimeReducer, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SALES_OVER_TIME_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_SALES_OVER_TIME:
      const { statistics, orderAmount, totalAmount } = action.payload;
      const total = {
        orderAmount,
        totalAmount,
      };
      const data = statistics.map((item, index) => ({
        ...item,
        id: uuid(),
        color: getQueueColor(index),
        order: index,
      }));
      return state
        .set('statistics', fromJS(data))
        .set('total', fromJS(total))
        .set('isLoading', false);
    case actionTypes.FETCH_SALES_OVER_TIME_ACTIVITY:
      return state.set('data', fromJS(action.payload)).set('isLoadingActivity', false);
    case actionTypes.FETCH_SALES_OVER_TIME_ACTIVITY_LOADING:
      return state.set('isLoadingActivity', fromJS(action.payload));
    default:
      return state;
  }
};

export default salesOverTimeReducer;
