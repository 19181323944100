import React from 'react';
import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { ScrollBox, ModuleSidebar } from 'common/components';
import { RouteBackground } from 'components';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages/messages';
import { routes } from '../../constants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
  },
  body: {
    flex: 1,
    minHeight: 0,
    width: '100%',
  },
  contentWrapper: {
    flex: 1,
    height: '100%',
    minWidth: 0,
    [theme.breakpoints.down('md')]: {
      minWidth: 'auto',
    },
  },
  sidebarWrapper: {
    height: '100%',
    width: 210,
    minWidth: 210,
    paddingRight: theme.spacing(2),
  },
}));

interface IPermissionsContainerProps {
  className?: string;
  title?: string;
  hideSidebar?: boolean;
  children: JSX.Element;
}

export default function PermissionsContainer({
  className,
  children,
  title,
  hideSidebar,
}: IPermissionsContainerProps): JSX.Element {
  const classes = useStyles();

  return (
    <RouteBackground hasMobilePadding>
      <ScrollBox suppressScrollY>
        <Box overflow="hidden" height="100%" minWidth={650}>
          <Grid container spacing={2} direction="column" className={`${classes.root} ${className}`}>
            <Grid item container spacing={1} justifyContent="space-between">
              <Grid item>
                <Box display="flex" alignItems="center">
                  <Typography variant="h2">
                    {title || <FormattedMessage {...messages.permissions} />}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid item className={classes.body}>
              <Box display="flex" style={{ width: '100%', height: '100%' }}>
                {!hideSidebar && (
                  <Box className={classes.sidebarWrapper}>
                    <ScrollBox suppressScrollX>
                      <ModuleSidebar routes={routes} />
                    </ScrollBox>
                  </Box>
                )}
                <Box className={`${classes.contentWrapper}`}>{children}</Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ScrollBox>
    </RouteBackground>
  );
}
