import { fromJS } from 'immutable';
import { v4 as uuid } from 'uuid';
import { actionTypes } from './constants';
import { initialState, initReqStateImt } from 'common/constants/initialState';
import { ImmutableObject } from 'common/state/interfaces';

interface IBaseModalsState {
  isDetailsShown: boolean;
  isRedeemShown: boolean;
  redeemAction: typeof initialState;
}

export interface IServiceModalsState extends IBaseModalsState {
  selectedServiceItemId: string;
  selectedServiceItemDetails: typeof initialState;
  redeemHistory: typeof initialState;
}

export interface IInventoryModalsState extends IBaseModalsState {
  selectedInventoryId: string;
  selectedInventoryDetails: typeof initialState;
  redeemHistory: typeof initialState;
}

export interface IFamilyMemberServiceModalsState extends Omit<IBaseModalsState, 'redeemAction'> {
  selectedFamilyMemberServiceId: string;
  selectedFamilyMemberServiceDetails: typeof initialState;
}

export type IServiceModalsStateImt = ImmutableObject<IServiceModalsState>;
export type IInventoryModalsStateImt = ImmutableObject<IInventoryModalsState>;
export type IFamilyMemberServiceModalsStateImt = ImmutableObject<IFamilyMemberServiceModalsState>;

const initialServiceModalsReducer = fromJS({
  selectedServiceItemId: null,

  selectedServiceItemDetails: initialState,

  isDetailsShown: false,
  isRedeemShown: false,

  redeemAction: initialState,

  redeemRecentHistory: initialState,
  redeemHistory: initialState,
});

export function serviceModalsReducer(
  state: IServiceModalsStateImt = initialServiceModalsReducer,
  action,
) {
  switch (action.type) {
    case actionTypes.SET_SELECTED_SERVICE_ITEM:
      return state.set('selectedServiceItemId', action.payload);

    case actionTypes.SET_DETAILS_MODAL_SHOWN:
      return state.set('isDetailsShown', action.payload);
    case actionTypes.SET_REDEEM_MODAL_SHOWN:
      return state.set('isRedeemShown', action.payload);

    case actionTypes.FETCH_SERVICE_REDEEM_RECENT_HISTORY_SUCCESS:
      return state.setIn(['redeemRecentHistory', 'result'], fromJS(action.payload));
    case actionTypes.FETCH_SERVICE_REDEEM_RECENT_HISTORY_LOADING:
      return state.setIn(['redeemRecentHistory', 'isLoading'], action.payload);
    case actionTypes.RESET_SERVICE_REDEEM_RECENT_HISTORY:
      return state.set('redeemRecentHistory', initReqStateImt);

    case actionTypes.FETCH_SERVICE_REDEEM_HISTORY_SUCCESS:
      return state.setIn(['redeemHistory', 'result'], fromJS(action.payload));
    case actionTypes.FETCH_SERVICE_REDEEM_HISTORY_LOADING:
      return state.setIn(['redeemHistory', 'isLoading'], action.payload);
    case actionTypes.RESET_SERVICE_REDEEM_HISTORY:
      return state.set('redeemHistory', initReqStateImt);

    case actionTypes.FETCH_SERVICE_ITEM_DETAILS:
      return state.setIn(['selectedServiceItemDetails', 'result'], fromJS(action.payload));
    case actionTypes.FETCH_SERVICE_ITEM_DETAILS_LOADING:
      return state.setIn(['selectedServiceItemDetails', 'isLoading'], action.payload);
    case actionTypes.RESET_SERVICE_ITEM_DETAILS:
      return state.set('selectedServiceItemDetails', initReqStateImt);

    case actionTypes.REDEEM_SERVICE_ITEM:
      return state.setIn(['redeemAction', 'result'], action.payload);
    case actionTypes.REDEEM_SERVICE_ITEM_LOADING:
      return state.setIn(['redeemAction', 'isLoading'], action.payload);
    case actionTypes.RESET_REDEEM_SERVICE_ITEM:
      return state.set('redeemAction', initReqStateImt);
    default:
      return state;
  }
}

const initialInventoryModalsReducer = fromJS({
  selectedInventoryId: null,

  selectedInventoryDetails: initialState,

  isDetailsShown: false,
  isRedeemShown: false,

  redeemAction: initialState,

  redeemRecentHistory: initialState,
  redeemHistory: initialState,
});

export function inventoryModalsReducer(
  state: IInventoryModalsStateImt = initialInventoryModalsReducer,
  action,
) {
  switch (action.type) {
    case actionTypes.SET_SELECTED_INVENTORY_ID:
      return state.set('selectedInventoryId', action.payload);

    case actionTypes.SET_INVENTORY_DETAILS_MODAL_SHOWN:
      return state.set('isDetailsShown', action.payload);
    case actionTypes.SET_REDEEM_INVENTORY_MODAL_SHOWN:
      return state.set('isRedeemShown', action.payload);

    case actionTypes.FETCH_INVENTORY_REDEEM_RECENT_HISTORY_SUCCESS:
      return state.setIn(
        ['redeemRecentHistory', 'result'],
        fromJS(action.payload.map(item => ({ ...item, id: uuid() }))),
      );
    case actionTypes.FETCH_INVENTORY_REDEEM_RECENT_HISTORY_LOADING:
      return state.setIn(['redeemRecentHistory', 'isLoading'], action.payload);
    case actionTypes.RESET_INVENTORY_REDEEM_RECENT_HISTORY:
      return state.set('redeemRecentHistory', initReqStateImt);

    case actionTypes.FETCH_INVENTORY_REDEEM_HISTORY_SUCCESS:
      return state.setIn(
        ['redeemHistory', 'result'],
        fromJS({
          data: action.payload.data.map(item => ({ ...item, id: uuid() })),
          meta: action.payload.meta,
        }),
      );
    case actionTypes.FETCH_INVENTORY_REDEEM_HISTORY_LOADING:
      return state.setIn(['redeemHistory', 'isLoading'], action.payload);
    case actionTypes.RESET_INVENTORY_REDEEM_HISTORY:
      return state.set('redeemHistory', initReqStateImt);

    case actionTypes.FETCH_INVENTORY_DETAILS_SUCCESS:
      return state.setIn(['selectedInventoryDetails', 'result'], fromJS(action.payload));
    case actionTypes.FETCH_INVENTORY_DETAILS_LOADING:
      return state.setIn(['selectedInventoryDetails', 'isLoading'], action.payload);
    case actionTypes.RESET_INVENTORY_DETAILS:
      return state.set('selectedInventoryDetails', initReqStateImt);

    case actionTypes.REDEEM_INVENTORY:
      return state.setIn(['redeemAction', 'result'], action.payload);
    case actionTypes.REDEEM_INVENTORY_LOADING:
      return state.setIn(['redeemAction', 'isLoading'], action.payload);
    case actionTypes.RESET_REDEEM_INVENTORY:
      return state.set('redeemAction', initReqStateImt);
    default:
      return state;
  }
}

const initialFamilyMemberServiceModalsReducer = fromJS({
  selectedFamilyMemberServiceId: null,

  selectedFamilyMemberServiceDetails: initialState,

  familyMembers: initialState,

  redeemAction: initialState,

  isDetailsShown: false,
  isRedeemShown: false,
});

export function familyMemberServiceModalsReducer(
  state: IFamilyMemberServiceModalsStateImt = initialFamilyMemberServiceModalsReducer,
  action,
) {
  switch (action.type) {
    case actionTypes.SET_SELECTED_FAMILY_MEMBER_SERVICE_ID:
      return state.set('selectedFamilyMemberServiceId', action.payload);

    case actionTypes.SET_FAMILY_MEMBER_SERVICE_DETAILS_MODAL_SHOWN:
      return state.set('isDetailsShown', action.payload);
    case actionTypes.SET_REDEEM_FAMILY_MEMBER_SERVICE_MODAL_SHOWN:
      return state.set('isRedeemShown', action.payload);

    case actionTypes.FETCH_FAMILY_MEMBER_SERVICE_DETAILS_SUCCESS:
      return state.setIn(['selectedFamilyMemberServiceDetails', 'result'], fromJS(action.payload));
    case actionTypes.FETCH_FAMILY_MEMBER_SERVICE_DETAILS_LOADING:
      return state.setIn(['selectedFamilyMemberServiceDetails', 'isLoading'], action.payload);
    case actionTypes.RESET_FAMILY_MEMBER_SERVICE_DETAILS:
      return state.set('selectedFamilyMemberServiceDetails', initReqStateImt);
    case actionTypes.FETCH_FAMILY_MEMBERS_TO_REDEEM_SUCCESS:
      return state.setIn(['familyMembers', 'result'], fromJS(action.payload));
    case actionTypes.FETCH_FAMILY_MEMBERS_TO_REDEEM_LOADING:
      return state.setIn(['familyMembers', 'isLoading'], action.payload);
    case actionTypes.RESET_FAMILY_MEMBERS_TO_REDEEM:
      return state.set('familyMembers', initReqStateImt);
    case actionTypes.REDEEM_FAMILY_MEMBERS_ACTION_RESULT:
      return state.setIn(['redeemAction', 'result'], action.payload);
    case actionTypes.REDEEM_FAMILY_MEMBERS_LOADING:
      return state.setIn(['redeemAction', 'isLoading'], action.payload);
    case actionTypes.RESET_REDEEM_FAMILY_MEMBERS:
      return state.set('redeemAction', initReqStateImt);
    default:
      return state;
  }
}

export function serviceEventsReducer(state = initReqStateImt, action) {
  switch (action.type) {
    case actionTypes.FETCH_SERVICE_EVENTS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.FETCH_SERVICE_EVENTS_LOADING:
      return state.set('isLoading', fromJS(action.payload));
    case actionTypes.RESET_SERVICE_EVENTS:
      return initReqStateImt;
    default:
      return state;
  }
}

export function serviceResourceTagsReducer(state = initReqStateImt, action) {
  switch (action.type) {
    case actionTypes.FETCH_SERVICE_RESOURCE_TAGS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.FETCH_SERVICE_RESOURCE_TAGS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_SERVICE_RESOURCE_TAGS:
      return initReqStateImt;
    default:
      return state;
  }
}

export function serviceEventDetailsReducer(state = initReqStateImt, action) {
  switch (action.type) {
    case actionTypes.FETCH_SERVICE_EVENT_DETAILS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.FETCH_SERVICE_EVENT_DETAILS_LOADING:
      return state.set('isLoading', fromJS(action.payload));
    case actionTypes.RESET_SERVICE_EVENT_DETAILS:
      return initReqStateImt;
    default:
      return state;
  }
}
