import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Tooltip, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as WarningIcon } from 'img/icons/warning.svg';
import commonMessages from 'common/messages/messages';
import { CustomTheme } from 'common/ui/interfaces';

const useStyles = makeStyles((theme: CustomTheme) => ({
  warningIcon: {
    color: theme.palette.warning.main,
    pointerEvents: 'auto',
  },
}));

interface IWarningTooltipProps {
  availableToSellByClub: boolean;
  availableToSellByEmployee: boolean;
}

const WarningTooltip = ({
  availableToSellByClub,
  availableToSellByEmployee,
}: IWarningTooltipProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Tooltip
      title={
        <Box display="flex" flexDirection="column">
          {availableToSellByClub || (
            <Typography variant="inherit">
              <FormattedMessage {...commonMessages.bundleCanNotBeSoldInSelectedClub} />
            </Typography>
          )}
          {availableToSellByEmployee || (
            <Typography variant="inherit">
              <FormattedMessage {...commonMessages.bundleCanNotBeSoldByEmployee} />
            </Typography>
          )}
        </Box>
      }
    >
      <WarningIcon onClick={e => e.stopPropagation()} className={classes.warningIcon} />
    </Tooltip>
  );
};

export default WarningTooltip;
