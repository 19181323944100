import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import { Box, Grid, GridSize } from '@material-ui/core';
// components
import MultipleSelect from '../MultipleSelect/MultipleSelect';
import { TextFieldWithReset, ZipCodeMaskedInput } from 'common/components';
// hooks
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import useBoxSize from 'common/hooks/useBoxSize';
import { useAppDispatch } from 'store/hooks';
// interfaces
import { IAddress, IStateItemImt } from 'common/interfaces/common';
// constants
import { DictionaryList } from 'common/constants';
// state
import { selectDictionaryList } from 'common/state/dictionary/selectors';
import { fetchDictionaryList } from 'common/state/dictionary/actions';
// messages
import inputLabels from 'common/messages/inputLabels';
import { TShortProfileFieldInfoImt } from 'modules/corporate-settings/interfaces';
import { RequiredProfileInfo } from 'modules/corporate-settings/constants/requiredFields';

interface IAddressBlockProps {
  // if pass - will turn into resettable block
  defaultValues?: IAddress;
  isMultiEdit?: boolean;
  profileMode?: boolean;
  name?: string;
  children?: React.ReactNode;
  recommendedFields?: TShortProfileFieldInfoImt;
}

const AddressBlock = ({
  defaultValues,
  isMultiEdit,
  name,
  profileMode,
  children,
  recommendedFields,
}: IAddressBlockProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const statesList: ImmutableList<IStateItemImt> = useSelector(
    selectDictionaryList(DictionaryList.STATES),
  );

  const {
    control,
    errors,
    formState: { isDirty },
  } = useFormContext();

  const boxRef = useRef();

  const { width } = useBoxSize(boxRef);

  const renderIntlMessage = useRenderIntlMessage();

  const isWideBlockWidth = width > 580;
  const isSmallBlockWidth = width < 400;

  useEffect(() => {
    dispatch(fetchDictionaryList(DictionaryList.STATES));
    dispatch(fetchDictionaryList(DictionaryList.COUNTRIES));
  }, [dispatch]);

  const isDefaultCanResetFlag = isDirty !== undefined && !isDirty;

  const cityFieldSmSize: GridSize = profileMode ? 4 : 6;

  let stateFieldSmSize: GridSize = isSmallBlockWidth ? 6 : 3;
  stateFieldSmSize = profileMode ? 4 : stateFieldSmSize;

  let zipCodeFieldSmSize: GridSize = isSmallBlockWidth ? 6 : 3;
  zipCodeFieldSmSize = profileMode ? 4 : zipCodeFieldSmSize;

  return (
    <Box>
      <Grid container spacing={1} ref={boxRef}>
        <Grid item xs={12} sm={isWideBlockWidth && !profileMode ? 6 : 12}>
          <Controller
            control={control}
            name={`${name}.line1`}
            render={({ name: inpName, value, onChange, onBlur }) => (
              <TextFieldWithReset
                fullWidth
                variant="outlined"
                autoComplete="off"
                name={inpName}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                label={<FormattedMessage {...inputLabels.addressLine1} />}
                isDefaultCanResetFlag={isDefaultCanResetFlag}
                reset={defaultValues?.line1}
                multi={isMultiEdit}
                error={!!errors.address?.line1}
                helperText={renderIntlMessage(errors.address?.line1?.message)}
                recommended={!!recommendedFields?.get(RequiredProfileInfo.Address)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={isWideBlockWidth && !profileMode ? 6 : 12}>
          <Controller
            control={control}
            name={`${name}.line2`}
            render={({ value, onChange, onBlur }) => (
              <TextFieldWithReset
                isDefaultCanResetFlag={isDefaultCanResetFlag}
                value={value}
                onChange={onChange}
                label={<FormattedMessage {...inputLabels.addressLine2} />}
                variant="outlined"
                autoComplete="off"
                onBlur={onBlur}
                fullWidth
                reset={defaultValues?.line2}
                multi={isMultiEdit}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={cityFieldSmSize}>
          <Controller
            control={control}
            name={`${name}.city`}
            render={({ value, onChange, onBlur }) => (
              <TextFieldWithReset
                variant="outlined"
                isDefaultCanResetFlag={isDefaultCanResetFlag}
                label={<FormattedMessage {...inputLabels.city} />}
                onBlur={onBlur}
                onChange={onChange}
                fullWidth
                error={!!errors.address?.city}
                helperText={renderIntlMessage(errors.address?.city?.message)}
                value={value}
                reset={defaultValues?.city}
                multi={isMultiEdit}
                recommended={!!recommendedFields?.get(RequiredProfileInfo.Address)}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} sm={stateFieldSmSize}>
          <Controller
            name={`${name}.state`}
            control={control}
            render={({ value, onChange }) => {
              const options = statesList ? statesList.toJS() : [];

              return (
                <MultipleSelect
                  disableTagsRender
                  disableClearable
                  onChange={onChange}
                  label={<FormattedMessage {...inputLabels.state} />}
                  value={value}
                  options={options}
                  fullWidth
                  error={!!errors.address?.state}
                  helperText={renderIntlMessage(errors.address?.state?.message) || ''}
                  resetValue={defaultValues?.state}
                  canReset={isMultiEdit}
                  recommended={!!recommendedFields?.get(RequiredProfileInfo.Address)}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={6} sm={zipCodeFieldSmSize}>
          <Controller
            control={control}
            name={`${name}.zipCode`}
            render={({ value, onChange, onBlur }) => (
              <TextFieldWithReset
                variant="outlined"
                isDefaultCanResetFlag={isDefaultCanResetFlag}
                label={<FormattedMessage {...inputLabels.zipCode} />}
                onBlur={onBlur}
                onChange={onChange}
                fullWidth
                error={!!errors.address?.zipCode}
                helperText={renderIntlMessage(errors.address?.zipCode?.message)}
                value={value}
                InputProps={{
                  inputComponent: ZipCodeMaskedInput,
                  onBlur,
                }}
                reset={defaultValues?.zipCode}
                multi={isMultiEdit}
                recommended={!!recommendedFields?.get(RequiredProfileInfo.Address)}
              />
            )}
          />
        </Grid>

        {/* // TODO uncomment after adding countries */}
        {/*  <Grid item sm={6} xs={12}>
          <Controller
            name={`${name}.country`}
            control={control}
            render={({ value, onChange }) => (
              <CountrySelect
                value={value}
                onChange={onChange}
                error={!!errors.address?.country}
                helperText={renderIntlMessage(errors.address?.country?.message)}
                resetValue={defaultValues?.country}
                canReset={isMultiEdit}
              />
            )}
          />
        </Grid> */}

        {children}
      </Grid>
    </Box>
  );
};

AddressBlock.defaultProps = {
  name: 'address',
};

export default AddressBlock;
