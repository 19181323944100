import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';

import { RedeemType, ServiceType } from 'common/constants/service';
import { MINUTES_IN_DAY, MINUTES_IN_HOUR, MINUTES_IN_WEEK } from 'common/constants/times';

import { DurationUnit, DurationUnits, Message } from 'common/interfaces/common';

import messages from 'common/components/PersonProfile/messages';
import commonMessages from 'common/messages/messages';

const getLimitedQuantityMessage = (
  type: ServiceType,
  redeemType: RedeemType,
  durationUnit: DurationUnit,
): Message => {
  if (type === ServiceType.FamilyMember) {
    return messages.includedServicesDurationMembers;
  }

  switch (redeemType) {
    case RedeemType.Session:
      return messages.includedServicesDurationSessions;
    case RedeemType.Duration:
      return DurationUnits.message(durationUnit);
    case RedeemType.Amount:
    default:
      return messages.includedServicesDurationAmount;
  }
};

const getQuantityMessage = (
  type: ServiceType,
  redeemType: RedeemType,
  durationUnit: DurationUnit,
  limited: boolean,
): Message => {
  return limited
    ? getLimitedQuantityMessage(type, redeemType, durationUnit)
    : commonMessages.unlimitedLabel;
};

const getLimitedQuantityLabel = (
  type: ServiceType,
  redeemType: RedeemType,
  durationUnit: DurationUnit,
): JSX.Element => {
  return <FormattedMessage {...getLimitedQuantityMessage(type, redeemType, durationUnit)} />;
};

const getQuantityLabel = (
  type: ServiceType,
  redeemType: RedeemType,
  durationUnit: DurationUnit,
  limited: boolean,
): JSX.Element => {
  return <FormattedMessage {...getQuantityMessage(type, redeemType, durationUnit, limited)} />;
};

const getProgressDescriptionLabel = (
  redeemType: RedeemType,
  redeemUnit: DurationUnit,
  availableToRedeem: number,
  totalToRedeem: number,
  limited: boolean,
): JSX.Element => {
  if (!limited) {
    return <FormattedMessage {...commonMessages.unlimitedLabel} />;
  }

  if (redeemType === RedeemType.Duration && redeemUnit) {
    let leftMinutes = availableToRedeem;

    const weeks = Math.floor(leftMinutes / MINUTES_IN_WEEK);
    leftMinutes -= weeks * MINUTES_IN_WEEK;

    const days = Math.floor(leftMinutes / MINUTES_IN_DAY);
    leftMinutes -= days * MINUTES_IN_DAY;

    const hours = Math.floor(leftMinutes / MINUTES_IN_HOUR);
    leftMinutes -= hours * MINUTES_IN_HOUR;

    const minutes = leftMinutes;

    const amountOfRedeemUnits: Array<{ amount: number; label: string }> = [
      { amount: weeks, label: 'w' },
      { amount: days, label: 'd' },
      { amount: hours, label: 'h' },
      { amount: minutes, label: 'm' },
    ];

    const redeemUnitsLabel = amountOfRedeemUnits.reduce(
      (acc, { amount, label }) => (amount ? `${acc} ${amount}${label}` : acc),
      '',
    );

    return (
      <FormattedMessage
        {...messages.durationAmountLeftLabel}
        values={{
          available: redeemUnitsLabel.length ? redeemUnitsLabel : 0,
          total: totalToRedeem,
          durationUnit: (
            <Typography
              component="span"
              color="inherit"
              style={{ textTransform: 'lowercase', fontSize: 'inherit' }}
            >
              {DurationUnits.translate(redeemUnit)}
            </Typography>
          ),
        }}
      />
    );
  }

  if (redeemType === RedeemType.Session) {
    return (
      <FormattedMessage
        {...messages.sessionsAmountLeftLabel}
        values={{
          available: availableToRedeem,
          total: totalToRedeem,
        }}
      />
    );
  }

  return (
    <FormattedMessage
      {...messages.amountLeftShortLabel}
      values={{
        available: availableToRedeem,
        total: totalToRedeem,
      }}
    />
  );
};

export {
  getQuantityMessage,
  getQuantityLabel,
  getLimitedQuantityMessage,
  getLimitedQuantityLabel,
  getProgressDescriptionLabel,
};
