import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, Avatar, Typography } from '@material-ui/core';

import { IResourceListItem } from 'modules/booking/interfaces';
import { FormattedMessage } from 'react-intl';
import commonMessages from 'common/messages/messages';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainLabel: {
      fontSize: '0.95rem',
      fontWeight: 700,
    },
    caption: {
      fontSize: '0.65rem',
      lineHeight: '1.30rem',
      color: 'rgba(50, 50, 50, 0.3)',
    },
    avatar: {
      fontSize: '0.75rem',
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
  }),
);

interface IProps {
  resource: IResourceListItem;
  clubs: Array<string>;
}

const SmallScreenCell = (props: IProps) => {
  const classes = useStyles();
  const { resource, clubs } = props;

  const { label, employee } = resource;

  return (
    <Grid container wrap="nowrap" direction="column" alignItems="flex-start" spacing={1}>
      <Grid item>
        <Typography className={classes.mainLabel} component="span" variant="h6">
          {label}
        </Typography>
      </Grid>

      <Grid item>
        <Typography className={classes.caption} variant="caption">
          <FormattedMessage {...commonMessages.employeeLabel} />
        </Typography>
        {employee ? (
          <Grid container wrap="nowrap" alignItems="center" spacing={1}>
            <Grid item>
              <Avatar
                alt={employee.firstName || 'Avatar'}
                src={employee.imageUrl || ''}
                className={classes.avatar}
              />
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {`${employee.firstName || ''} ${employee.lastName || ''}`}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid container wrap="nowrap" alignItems="center" spacing={1}>
            <Grid item>
              <Typography variant="body2">-</Typography>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid item>
        <Typography className={classes.caption} variant="caption">
          <FormattedMessage {...commonMessages.clubsLabel} />
        </Typography>
        {clubs.map(club => (
          <Typography key={club}>{club.toUpperCase()}</Typography>
        ))}
      </Grid>
    </Grid>
  );
};

export default React.memo(SmallScreenCell);
