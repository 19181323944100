import React from 'react';
import cn from 'classnames';
import { Paper, InputBase, makeStyles, Theme } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  search: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
  searchIconIcon: {
    width: '20px',
    height: '20px',
    color: theme.palette.text.secondary,
    opacity: 0.7,
  },
  searchInputRoot: {
    width: '100%',
  },
  searchInput: {
    padding: '10px 16px',
    fontSize: '0.865rem',
    paddingLeft: theme.spacing(4),
  },
}));

interface IProps {
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  autoFocus?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  className?: string;
  inputRef?: React.Ref<HTMLInputElement>;
}

export default function SearchInput(props: IProps): JSX.Element {
  const classes = useStyles();
  const { placeholder, value, onChange, autoFocus, onKeyDown, className, onBlur, inputRef } = props;

  return (
    <Paper className={cn(classes.search, className)} elevation={2}>
      <div className={classes.searchIcon}>
        <SearchIcon className={classes.searchIconIcon} />
      </div>
      <InputBase
        inputRef={inputRef}
        onBlur={onBlur}
        autoFocus={autoFocus}
        placeholder={placeholder}
        classes={{
          root: classes.searchInputRoot,
          input: classes.searchInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
        value={value}
        onChange={e => onChange(e.target.value)}
        onKeyDown={onKeyDown}
      />
    </Paper>
  );
}
