import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { actionTypes } from '../../constants';
import { initialState } from 'common/constants/initialState';

const initStateRolesList = fromJS({
  list: [],
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
  isLoading: false,
});

function rolesListReducer(state = initStateRolesList, action) {
  switch (action.type) {
    case actionTypes.FETCH_ROLES_LIST_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_ROLES_LIST:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta));
    case actionTypes.RESET_ROLES_LIST:
      return initStateRolesList;
    default:
      return state;
  }
}

const initStateRoleItem = fromJS({
  roleItemData: {},
  isLoading: false,
  request: initialState,
});

function roleItemReducer(state = initStateRoleItem, action) {
  switch (action.type) {
    case actionTypes.FETCH_ROLE_ITEM_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_ROLE_ITEM:
      return state.set('roleItemData', fromJS(action.payload));
    case actionTypes.CREATE_ROLE_ITEM_LOADING:
      return state.setIn(['request', 'isLoading'], fromJS(action.payload));
    case actionTypes.CREATE_ROLE_ITEM_SUCCESS:
      return state.setIn(['request', 'result'], fromJS(action.payload));
    case actionTypes.EDIT_ROLE_ITEM_SUCCESS:
      return state.setIn(['request', 'result'], fromJS(action.payload));
    case actionTypes.RESET_ROLE_ITEM_DATA:
      return state.set('roleItemData', fromJS({}));
    case actionTypes.RESET_ROLE_ITEM_ACTION_RESULT:
      return state.set('request', fromJS(initialState));
    default:
      return state;
  }
}

export default combineReducers({
  rolesList: rolesListReducer,
  roleItem: roleItemReducer,
});
