import * as yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';

export const phoneSchema = yup.string().test('phone-valid', 'Invalid Phone', value => {
  if (value?.length > 0) {
    return isValidPhoneNumber(value);
  }

  return true;
});
