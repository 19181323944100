import { List as ImmutableList, Map } from 'immutable';
import { ImmutableObject } from 'common/state/interfaces';
import {
  AlertTypes,
  BalanceType,
  CancelReasons,
  EmailStatus,
  FallthroughReasons,
  FreezePackageType,
  PastDueActions,
  PhoneTypes,
  ReasonType,
  SMSStatus,
  TicketStatus,
  TicketTopic,
} from './constants';
import {
  IMembershipInfo,
  IMembershipInfoImt,
  IMembershipInvoicePackage,
  IShortPackage,
  IShortPackageImt,
} from '../../interfaces/membership';
import { Communications, LeadValue, SalesType, WaiverStatus } from 'modules/crm/constants/leads';
import {
  IAddress,
  IAddressImt,
  INamedEntity,
  INamedEntityImt,
  IShortPerson,
  IShortPersonImt,
  ITimeRange,
} from 'common/interfaces/common';
import { CustomerStatus } from 'modules/front-desk/constants/common/constants';
import {
  IAttachment,
  IAttachmentImt,
  IStoredFileSimpleDto,
  IStoredFileSimpleDtoImt,
} from '../../interfaces/uploadFile';
import {
  IScriptDetailedImt,
  IScriptStepDtoList,
  IScriptStepDtoListImt,
} from 'modules/crm/interfaces/scripts';
import { AlertCondition } from 'common/interfaces/alerts';
import { IClubImt } from 'common/interfaces/clients';
import { ICommonPhoneItem } from 'common/interfaces/phone';
import { CameFromType } from 'common/constants';
import { ICameFromIndicatorDto } from 'common/interfaces/cameFrom';
import { FrequencyType } from 'modules/services/constants/packages';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { InvoiceStatus, MenuItemTypes, PaymentAccountType } from 'common/interfaces/invoices';
import { IEmployeesSearchResult } from 'common/interfaces/search';
import { ICheckinResponseMember, ICheckinResponseMemberImt } from 'modules/front-desk/interfaces';
import { ICampaignDto, ICampaignDtoImt } from 'common/interfaces/campaign';
import { IClubInfo } from 'modules/crm/interfaces/leads';
import { CreditCardTypes } from 'common/components/CreditCardData/interfaces';
import { IPackageInstanceDetails } from 'common/interfaces/service';
import { IPaymentMethodItem } from 'modules/pos-settings/interfaces/paymentMethods';
import {
  EventUpdateType,
  IRecentAppointmentItemImt,
  PersonAppointmentStatus,
} from 'modules/booking/interfaces';
import { PeakModules } from 'common/constants/peakModules';
import { IRegisterListItem } from 'modules/pos-settings/interfaces/register';
import { IEmployeeCommon } from 'modules/employees/interfaces';
import { IPackageRCodeListItem } from 'modules/pos-settings/interfaces/revenueCodes';
import { CheckingSavingsTypes } from '../../constants/invoices';

export type PersonTypes =
  | 'Guest'
  | 'Inactive Guest'
  | 'Active member'
  | 'Frozen member'
  | 'Inactive Member'
  | 'Expired Member'
  | 'Deleted Member';

export enum CheckInTypes {
  MANUAL = 'MANUAL',
  BARCODE = 'BARCODE',
}

export interface IAlert {
  id: string;
  condition: AlertCondition;
  message: string;
  sound: boolean;
  type: AlertTypes;
  appointmentId: string | null;
  noteId: string | null;
}

export type IAlertImt = ImmutableObject<IAlert>; // TODO delete after alerts integrate with api

export interface ITag {
  id: string;
  clientId: number;
  createdAt: string | null;
  deletedAt: string | null;
  updatedAt: string | null;
  name: string;
}

export type ITagImt = ImmutableObject<ITag>;

export interface IMemberPhoneItem extends ICommonPhoneItem {
  allowAutomatedCalls: boolean;
  canReceiveSms: boolean;
  useAsDefault: boolean;
}

export interface IPersonPinCode {
  pinCode: string;
}

export type IMemberPhoneItemImt = ImmutableObject<IMemberPhoneItem>;

export interface IProfileAdditional {
  field: INamedEntity;
  value: string;
}

export interface IProfileAdditionalPayload {
  id: string;
  value: string;
}

export type IProfileAdditionalImt = ImmutableObject<IProfileAdditional>;

export interface IFacility {
  id: string;
  title: string;
  address: IAddress;
}

export interface IFacilityImt extends ImmutableObject<IFacility> {
  get<K extends keyof IFacility>(key: K): IFacility[K];

  get(key: 'address'): IAddressImt;
}

export interface ICountry {
  id: string;
  imageUrl: string;
  title: string;
}

export type ICountryImt = ImmutableObject<ICountry>;

export interface IState {
  id: string;
  imageUrl: string;
  title: string;
}

export type IStateImt = ImmutableObject<IState>;

export interface IPackage {
  id: string;
  name: string;
  billingFrequencyType: FrequencyType.Monthly | FrequencyType.Annually;
  billingPrice: string;
  startDate: number;
  endDate: number;
  autoRenew: boolean;
  status: 'payed' | 'past due';
  nextPaymentDate: number;
  nextPaymentAmount: string;
  cardType: string;
  cardLast: string;
  cardExp: string;
  holderFirstName: string;
  holderLastName: string;
}

export type IPackageImt = ImmutableObject<IPackage>;

export interface IPersonInfo {
  id?: string;
  firstName: string;
  lastName: string;
  imageUrl?: string;
}

export type IPersonInfoImt = ImmutableObject<IPersonInfo>;

export type ISalesPerson = IPersonInfo;

export type ISalesPersonImt = IPersonInfoImt;

export interface IFallthroughCaptureForm {
  fallthroughReason: string;
  fallthroughReasonId: string | FallthroughReasons.OtherReason;
  createdDate: string;
  createdTime?: string;
}

export interface IFallthroughCapture extends IFallthroughCaptureForm {
  id: string;
}

export type IFallthroughCaptureImt = ImmutableObject<IFallthroughCapture>;

export interface ICheckinDTO {
  id?: string;
  date: string;
  type?: CheckInTypes;
}

export type ICheckinDTOImt = ImmutableObject<ICheckinDTO>;

export interface IReferralPerson extends ICustomerShort {
  barcode: string;
  personalCode: number;
}

export interface IProfileInfo {
  id?: string;
  personalId?: string;
  firstName?: string;
  lastName?: string;
  preferredName?: string;
  personalCode?: number;
  birthday?: string;
  gender?: string;
  photoUrl?: string | null;
  image?: IStoredFileSimpleDto;
  barcode?: string;
  balance?: string;
  rewardPointNumber?: number;
  email?: string;
  joinDate?: string;
  hasMobileSignIn?: string;
  type?: CustomerStatus;
  address?: IAddress;
  addressLine?: string;
  phones?: Array<IMemberPhoneItem>;
  homeClub?: IClubInfo;
  packages?: Array<IPackage>;
  lastCheckinDto?: ICheckinDTO;
  checkInId?: string;
  checkInTime?: string;
  checkInType?: CheckInTypes;
  alerts?: AlertCondition[];
  tags?: Array<string>;
  status?: string;
  assignedSalesperson?: ISalesPerson;
  additionalFields?: Array<IProfileAdditional>;
  fallthroughCapture?: IFallthroughCapture;
  membership?: IMembershipInfo;
  salesType?: SalesType;
  cameFromValue?: string;
  cameFromIndicator?: ICameFromIndicatorDto;
  referralMember?: IReferralPerson;
  cameFromIndicatorId?: string;
  referralMemberId?: string;
  leadMember?: boolean;
  ptLeadMember?: boolean;
}

export interface IProfileInfoImt extends ImmutableObject<IProfileInfo> {
  get<K extends keyof IProfileInfo>(key: K): IProfileInfo[K];

  get(key: 'image'): IStoredFileSimpleDtoImt;

  get(key: 'phones'): ImmutableList<IMemberPhoneItemImt>;

  get(key: 'homeClub'): IFacilityImt;

  get(key: 'packages'): ImmutableList<IPackageImt>;

  get(key: 'alerts'): AlertCondition[];

  get(key: 'tags'): ImmutableList<string>;

  get(key: 'additionalFields'): ImmutableList<IProfileAdditionalImt>;

  get(key: 'assignedSalesperson'): ISalesPersonImt;

  get(key: 'fallthroughCapture'): IFallthroughCaptureImt;

  get(key: 'membership'): IMembershipInfoImt;

  get(
    key: 'doNotContactHours',
  ): ImmutableObject<{
    from: Date;
    to: Date;
  }>;

  get(key: 'lastCheckinDto'): ICheckinDTOImt;
}

export type IAdditionalFieldsImt = ImmutableList<IProfileAdditionalImt>;

export interface INewMemberProfileData extends IProfileInfo {
  additionalInfo?: any;
  familyMembers?: any;
  waivers?: any;
  event?: any;
}

export interface IAuthor {
  firstName: string;
  id?: string;
  imageUrl: string;
  lastName: string;
}

export interface INoteCode {
  id: string;
  active: true;
  title: string;
}

export interface INote {
  id?: string;
  text: string;
  alertAtNextCheckIn?: boolean;
  noteCode: INoteCode;
  author?: IAuthor;
  createdDate?: string;
}

export interface INoteCodeCreateDto {
  alertAtNextCheckIn: boolean;
  noteCodeId: string;
  text: string;
}

export interface INoteCodeRequestPayload extends INoteCodeCreateDto {
  id?: string;
  customerId?: string;
}

export type IAuthorImt = ImmutableObject<IAuthor>;

export interface INoteImt extends ImmutableObject<INote> {
  get<K extends keyof INote>(key: K): INote[K];

  get(key: 'author'): IAuthorImt;
}

export type INoteCodeImt = ImmutableObject<INoteCode>;

export interface IFamilyMember {
  id?: string;
  leftRightRelation: string;
  rightLeftRelation: string;
  rightCustomer: ICustomerShort;
  leftCustomer?: ICustomerShort;
  services: Partial<IFamilyMemberService>[];
}

export interface IFamilyMemberImt extends ImmutableObject<IFamilyMember> {
  get<K extends keyof IFamilyMember>(key: K): IFamilyMember[K];

  get(key: 'rightCustomer'): ICustomerShortImt;

  get(key: 'leftCustomer'): ICustomerShortImt;
}

export interface IFamilyMemberCreateDTO {
  id?: string;
  familyMemberId: string;
  leftRightRelation: string;
  rightLeftRelation: string;
  clubId?: string;
  familyMemberServiceInstanceIds: string[];
}

export type FamilyMemberEditItemDto = Omit<IFamilyMemberCreateDTO, 'clubId'>;

export interface IFamilyMemberEditDTO {
  clubId?: string;
  familyMembers: FamilyMemberEditItemDto[];
}

export interface IPayment {
  createdDate: string;
  invoiceId: string;
  invoiceStatus: InvoiceStatus;
  number: number;
  register: IRegisterListItem;
  salesperson: IEmployeeCommon;
  paymentDate: string;
  totalAmount: number;
  subtotalAmount: number;
  taxAmount: number;
  rewardAmount: number;
}

export type IPaymentImt = ImmutableObject<IPayment>;

export enum RevisionType {
  ADD = 'ADD',
  MOD = 'MOD',
  DEL = 'DEL',
}

export interface IModificationHistory {
  changedFields: string[];
  lastModifiedBy: IEmployeesSearchResult;
  revisionId: number;
  revisionType: RevisionType;
  timestamp: string;
}

interface IChangedField {
  name: string;
  newValue: string;
  previousValue: string;
}

export type IChangedFieldImt = ImmutableObject<IChangedField>;

export interface IModificationHistoryItem {
  changedFields: IChangedField[];
  currentRevisionNumber: number;
  entityId: string;
}

export interface IModificationHistoryItemImt extends ImmutableObject<IModificationHistoryItem> {
  get<K extends keyof IModificationHistoryItem>(key: K): IModificationHistoryItem[K];

  get(key: 'changedFields'): ImmutableList<IChangedFieldImt>;
}

export type IModificationHistoryImt = ImmutableObject<IModificationHistory>;

export interface ICampaign {
  id: string;
  name: string;
  status: 'Active' | 'Archived';
  script?: IScriptDetailedImt;
}

export interface ICampaignImt extends ImmutableObject<ICampaign> {
  get<K extends keyof ICampaign>(key: K): ICampaign[K];

  get(key: 'script'): IScriptDetailedImt;
}

export interface ICallNoteDto {
  id?: string;
  callScriptSteps: IScriptStepDtoList[];
  salesperson: INamedEntity;
  salespersonPhone: string;
  leadPhone?: INamedEntity;
  date: string;
  time: string;
  campaign: INamedEntity | string;
  note: string;
  script: INamedEntity;
  lead: INamedEntity;
}

export interface ICallNoteDtoImt extends ImmutableObject<ICallNoteDto> {
  get<K extends keyof ICallNoteDto>(key: K): ICallNoteDto[K];

  get(key: 'salesperson'): INamedEntityImt;

  get(key: 'leadPhone'): INamedEntityImt;

  get(key: 'script'): INamedEntityImt;

  get(key: 'lead'): INamedEntityImt;

  get(key: 'campaign'): INamedEntityImt | string;

  get(key: 'callScriptSteps'): IScriptStepDtoListImt;
}

export interface ILeadCallHistoryDto {
  id?: string;
  leadPhone: string;
  date: string;
  campaign: INamedEntity;
  salesperson: IShortPerson;
  salespersonPhone: string;
  automatic: boolean;
}

export interface ILeadCallHistoryDtoImt extends ImmutableObject<ILeadCallHistoryDto> {
  get<K extends keyof ILeadCallHistoryDto>(key: K): ILeadCallHistoryDto[K];

  get(key: 'salesperson'): IShortPersonImt;

  get(key: 'campaign'): INamedEntityImt;
}

export interface ISendEmailRequestPayload {
  emailBody: string;
  senderTitle: string;
  subject: string;
  attachmentFiles?: string;
  campaign?: INamedEntity;
}

export interface ISendSmsRequestPayload {
  campaign: INamedEntity;
  targetPersonPhone: INamedEntity;
  text: string;
}

export interface IEmail {
  id: string;
  attachmentFiles: string;
  campaign: INamedEntity;
  date: string;
  deleted: true;
  emailBody: string;
  lead: INamedEntity;
  salesperson: INamedEntity;
  emailSenderName: string;
  receiverEmail: string;
  senderEmail: string;
  status: EmailStatus;
  subject: string;
}

export interface IEmailImt extends ImmutableObject<IEmail> {
  get<K extends keyof IEmail>(key: K): IEmail[K];

  get(key: 'campaign'): INamedEntityImt;

  get(key: 'lead'): INamedEntityImt;

  get(key: 'salesperson'): INamedEntityImt;
}

export interface ISMS {
  id: string;
  attachmentsImageUrl: string;
  campaign: INamedEntity;
  club: INamedEntity;
  clubPerson: INamedEntity;
  clubPhone: string;
  date: string;
  reasonType: ReasonType;
  smsStatus: SMSStatus;
  targetPerson: INamedEntity;
  targetPersonPhone: INamedEntity;
  text: string;
}

export interface ISMSImt extends ImmutableObject<ISMS> {
  get<K extends keyof ISMS>(key: K): ISMS[K];

  get(key: 'salesperson'): ISalesPersonImt;

  get(key: 'club'): INamedEntityImt;

  get(key: 'campaign'): INamedEntityImt;

  get(key: 'clubPerson'): INamedEntityImt;

  get(key: 'targetPerson'): INamedEntityImt;

  get(key: 'targetPersonPhone'): INamedEntityImt;
}

export interface IReminderRecent {
  id?: string;
  dueDate: string;
  dueTime: string;
  text: string;
  salesperson: ISalesPerson;
}

export interface IReminderRecentImt extends ImmutableObject<IReminderRecent> {
  get<K extends keyof IReminderRecent>(key: K): IReminderRecent[K];

  get(key: 'salesperson'): ISalesPersonImt;
}

export interface IReminderDto {
  id: string;
  dueTime: string;
  dueDate: string;
  text: string;
  salesperson: IShortPerson;
}

export interface IReminderDtoImt extends ImmutableObject<IReminderDto> {
  get<K extends keyof IReminderDto>(key: K): IReminderDto[K];

  get(key: 'salesperson'): IShortPersonImt;
}

export interface ReminderHistoryDto {
  id: string;
  createdDate: string;
  dueDate: string;
  note: string;
  salesperson: IShortPerson;
}

export interface ReminderHistoryDtoImt extends ImmutableObject<ReminderHistoryDto> {
  get<K extends keyof ReminderHistoryDto>(key: K): ReminderHistoryDto[K];

  get(key: 'salesperson'): IShortPersonImt;
}

export interface IWaiverInfo {
  code: string;
  text: string;
  email: string;
  type: string;
  files: Array<File> | null;
}

export interface IPersonAdditionalFormFields {
  cameFromType: CameFromType;
  optional: boolean;
}

export interface IPrimaryMemberInfo {
  id?: string;
  photoUrl?: string;
  alerts?: AlertCondition[];
  firstName?: string;
  lastName?: string;
  email?: string;
  birthday?: string;
  gender?: string;
  type?: string;
  address?: IAddress;
  attachments?: IAttachment[];
  phones?: Array<IMemberPhoneItem>;
  freePassDays?: string;
  salesperson?: any;
  assignedSalespersonId?: string;
  barcode?: string | number;
  preferableWayOfCommunication?: string;
  doNotContactHours?: {
    from: Date;
    to: Date;
  };
  leadType?: SalesType;
  membership?: IShortPackage;
  initialClubId?: string;
  cameFromValue?: string;
  cameFromIndicator?: ICameFromIndicatorDto;
  referralMember?: IReferralPerson;
  cameFromIndicatorId?: string;
  referralMemberId?: string;
  image?: IStoredFileSimpleDto;
  leadMember?: boolean;
  ptLeadMember?: boolean;
}

export interface IImmutablePrimaryMemberInfo extends Map<string, any> {
  toJS(): IPrimaryMemberInfo;

  get<K extends keyof IPrimaryMemberInfo>(key: K): IPrimaryMemberInfo[K];

  get(key: 'homeClub'): IClubImt;

  get(key: 'membership'): IShortPackageImt;

  get(key: 'phones'): ImmutableList<IMemberPhoneItemImt>;

  get(key: 'image'): IStoredFileSimpleDtoImt;
}

export interface IFamilyMemberService {
  leftSpotNumber: number;
  limited: boolean;
  limitedSpotNumber: number;
  packageServiceId: string;
  packageServiceInstanceId: string;
  scheduled: boolean;
  serviceId: string;
  title: string;
}

export interface IServiceEventsCalendarParams {
  serviceId: string;
  rangeStartDate: string;
  rangeEndDate: string;
}

export enum ServiceRedeemStatus {
  Redeemed = 'REDEEMED',
  NoShown = 'NO_SHOWN',
}

export interface IScheduledServiceRedeemDTO {
  clubId: string;
  eventDateTime: string;
  eventId: string;
  note: INoteCodeRequestPayload;
  pinCode: string;
  status: ServiceRedeemStatus;
}

export interface IRedeemResourceAvailability {
  availability: ITimeRange;
  resource: INamedEntity;
}

export interface IRedeemResourceAvailabilityImt
  extends ImmutableObject<IRedeemResourceAvailability> {
  get<K extends keyof IRedeemResourceAvailability>(key: K): IRedeemResourceAvailability[K];
}

export interface IRedeemResourceTag {
  resources: Array<IRedeemResourceAvailability>;
  tag: INamedEntity;
}

export interface IRedeemResourceTagImt extends ImmutableObject<IRedeemResourceTag> {
  get<K extends keyof IRedeemResourceTag>(key: K): IRedeemResourceTag[K];

  get(key: 'resources'): ImmutableList<IRedeemResourceAvailabilityImt>;
}

interface IRedeemResourceDTO {
  endTime: string;
  id: string;
  startTime: string;
}

export interface IUnscheduledServiceRedeemDTO {
  clubId: string;
  date: string;
  durationInMinutes: number;
  note: INoteCodeRequestPayload;
  pinCode: string;
  redeemResourceCreateDtoList: Array<IRedeemResourceDTO>;
  time: string;
}

// billing items

export interface ISelectable {
  selected: boolean;
}

export interface FreezePackageInfo {
  type: FreezePackageType;
  reason: string;
  priceDuringFreeze: number;
  amountInDays: number;
  startDate: string;
  endDate: string;
}

export interface IFreezeItem {
  packageInstanceId: string;
  title: string;
  startDate: string;
  endDate: string;
  pricePerBilling: number;
  paymentSchedule: FrequencyType;
  type: MenuItemTypes;
  allowToFreeze: boolean;
  freezeDaysNumber: number;

  freezePackages: FreezePackageInfo[];

  // technical property
  selected: boolean;
}

export interface IPackageBilling {
  id: string;
  title: string;
  pricePerBilling: number;
  startDate: string;
  endDate: string;
  paymentSchedule: FrequencyType;
}

export type IPackageBillingImt = ImmutableObject<IPackageBilling>;

export interface IBilling {
  id?: string;
  amount: number;
  paymentDate: string;
  packageInfo: IPackageBilling;
  revenueCode: IPackageRCodeListItem;
}

export interface IBillingImt extends ImmutableObject<IBilling> {
  get<K extends keyof IBilling>(key: K): IBilling[K];

  get(key: 'packageInfo'): IPackageBillingImt;
}

export type ISelectableBilling = IBilling & ISelectable;

export type ISelectableBillingItem = IPackageBilling & ISelectable;

export interface IServicePaymentMethod {
  id: string;
  startDate: string | Date;
  endDate: string | Date;
  price: number;
  service: {
    id: string;
    name: string;
  };
  payment: {
    method: string;
    details: string;
  };
}

export type IServicePaymentMethodImt = ImmutableObject<IServicePaymentMethod>;

// Past dues

export interface IPastDue {
  id?: string;
  amount: number;
  daysSinceOldestPaymentDate?: number;
  paymentDate?: string;
  package?: IPackageBilling;
  revenueCode: IPackageRCodeListItem;
}

export interface IPastDueImt extends ImmutableObject<IPastDue> {
  get<K extends keyof IPastDue>(key: K): IPastDue[K];

  get(key: 'package'): IPackageBillingImt;
}

// Membership actions

export interface IBillingResolution {
  id: string;
  resolve: PastDueActions;
}

export interface CancelMembershipData {
  billingSchedules: Array<IBilling>;
  cancellationDate: string;
  cancellationTime: string;
  mdLicense: string;
  newZipCode: string;
  note: INoteCodeRequestPayload;
  reason: CancelReasons;
  pastDues: Array<string>;
  resaleOptionAccepted: boolean;
  packageId?: string;

  // needed for package selection step
  packageInstanceId?: string;
}

export type CancelMembershipDataDTO = Omit<CancelMembershipData, 'pastDues'> & {
  pastDues: Array<string>;
  membershipId: string;
};

export interface ReactivateMembershipData {
  packageInstanceId: string;
  familyMemberIds: Array<string>;
  pastDues: Array<string>;

  // needed for create invoice with package
  configurationDto?: IMembershipInvoicePackage;
}

export interface ReactivateMembershipDataDTO {
  packageInstanceId: string;
  familyMemberIds: Array<string>;
  pastDues: Array<string>;
}

export interface FreezeMembershipItem {
  packageInstanceId: string;
  pricePerBilling: number;

  // technical property
  selected: boolean;
}

export interface FreezeMembershipData {
  reason: string;
  startDate: string;
  endDate: string;
  packageServices: FreezeMembershipItem[];
}

export interface IRequestResult {
  result: any;
  error: any;
  isLoading: boolean;
}

export type IRequestResultImt = ImmutableObject<IRequestResult>;

// Reactivation modal

export type ISelectableFamilyMember = IFamilyMember & ISelectable;

export interface ICustomerShort {
  id: string;
  imageUrl: string;
  firstName: string;
  lastName: string;
  type: CustomerStatus;
}

export type ICustomerShortImt = ImmutableObject<ICustomerShort>;

export interface IFamilyMemberSearch {
  data: ICustomerShort[];
  unavailableIds: string[];
}

export interface IMemberAutocompleteSearch extends ICustomerShort {
  alerts: AlertCondition[];
  checkInTime: string;
  checkInType: CheckInTypes;
}

export interface IBlocksSectionProps<T> {
  profile?: T;
  openPOSPanel?: () => void;
  isOpenPOSPanel?: boolean;
  isLessThanDesktop: boolean | null;
  isLessThanLaptop: boolean | null;
  isLessThanTablet: boolean | null;

  module?: PeakModuleForNewPersonType;
}

export type IMemberAutocompleteSearchImt = ImmutableObject<IMemberAutocompleteSearch>;

export interface IDoNotContactHours {
  from: string;
  to: string;
}

export interface IPersonSalesInfo {
  activities: INamedEntity[];
  assignedSalesperson: INamedEntity;
  cameFromTitle: string;
  cameFromIndicator: ICameFromIndicatorDto;
  cameFromValue: string;
  createdDate: string;
  limitations: string;
  doNotContactHours: IDoNotContactHours;
  preferableWayOfCommunication: Communications;
  salesValue: LeadValue;
  referralMember: INamedEntity;
  potentialClub: INamedEntity;
}

export interface IPersonSalesInfoImt extends ImmutableObject<IPersonSalesInfo> {
  get<K extends keyof IPersonSalesInfo>(key: K): IPersonSalesInfo[K];

  get(key: 'doNotContactHours'): ImmutableObject<IDoNotContactHours>;

  get(key: 'activities'): ImmutableList<INamedEntityImt>;
}

export enum DocumentType {
  Waiver = 'WAIVER',
  Contract = 'CONTRACT',
}

export interface IPersonDocumentGroupItem {
  url: string;
  code: number;
  createdDate: Date;
  id: string;
  signed: boolean;
  title: string;
  type: DocumentType;
  status: WaiverStatus;
}

export type IPersonDocumentGroupItemImt = ImmutableObject<IPersonDocumentGroupItem>;

interface IPersonDocumentGroup {
  id?: string;
  documents: IPersonDocumentGroupItem[];
  packageTemplate: INamedEntity;
}

export interface IPersonDocumentGroupImt extends ImmutableObject<IPersonDocumentGroup> {
  get<K extends keyof IPersonDocumentGroup>(key: K): IPersonDocumentGroup[K];

  get(key: 'documents'): ImmutableList<IPersonDocumentGroupItemImt>;
}

export interface IPersonDocuments {
  attachments: IAttachment[];
  documentGroups: IPersonDocumentGroup[];
}

// referrals
export interface IReferralShortDto {
  referrals: IReferralDto[];
  referredBy: INamedEntity | null;
}

export interface IReferralDto {
  accepted: boolean;
  club: IReferralClub;
  customer: INamedEntity;
  id?: string;
}

export interface IReferralClub {
  addressLine: string;
  id: string;
  title: string;
}

export interface IReferralCreateDto {
  email: string;
  firstName: string;
  lastName: string;
  phone: IReferralPhoneDto;
  salespersonId: string;
  note?: string;
}

export interface ISetReferredDto {
  referredBy: string;
}

export interface IReferralPhoneDto {
  allowAutomatedCalls: boolean;
  canReceiveSms: boolean;
  phone: string;
  phoneType: PhoneTypes;
  useAsDefault: boolean;
}
export interface IReferralFormFields {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  salespersonId: string;
  note: string;
  smsEnabled: boolean;
}

// tickets
export interface IZendeskCommentDto {
  id?: string;
  attachments: IAttachment[];
  author: string;
  body: string;
  createdDate: string;
  htmlBody: string;
  internalNote: boolean;
}

export interface ITicketDetailedDto {
  id: number;
  assignee: string;
  comments: IZendeskCommentDto[];
  group: string;
  openedDate: string;
  requester: string;
  status: TicketStatus;
  subject: string;
  topic: TicketTopic;
  workedBy: string;
}

export interface ITicketShortDto {
  id: number;
  closedDate: string;
  club: string;
  openedDate: string;
  status: TicketStatus;
  subject: string;
}

export interface ITicketCreateDto<T = string> {
  clubId: number;
  description: string;
  memberIds: T[];
  subject: string;
  topic: TicketTopic;
}

export interface ICustomerSimpleDto {
  id: string;
  imageUrl: string;
  personalCode: number;
  firstName: string;
  lastName: string;
}

export interface IZendeskAttachmentDto {
  id: number;
  contentType: string;
  fileName: string;
  size: number;
  url: string;
}

export interface IZendeskAttachmentDtoImt extends ImmutableObject<IZendeskAttachmentDto> {
  get<K extends keyof IZendeskAttachmentDto>(key: K): IZendeskAttachmentDto[K];
}

export interface IZendeskCommentDtoImt extends ImmutableObject<IZendeskCommentDto> {
  get<K extends keyof IZendeskCommentDto>(key: K): IZendeskCommentDto[K];

  get(key: 'attachments'): ImmutableList<IZendeskAttachmentDtoImt>;
}

export interface ITicketDetailedDtoImt extends ImmutableObject<ITicketDetailedDto> {
  get<K extends keyof ITicketDetailedDto>(key: K): ITicketDetailedDto[K];

  get(key: 'comments'): ImmutableList<IZendeskCommentDtoImt>;
}

export interface ITicketShortDtoImt extends ImmutableObject<ITicketShortDto> {
  get<K extends keyof ITicketShortDto>(key: K): ITicketShortDto[K];
}

export type TicketShortDtoImtList = ImmutableList<ITicketShortDtoImt>;

export interface ICustomerSimpleDtoImt extends ImmutableObject<ICustomerSimpleDto> {
  get<K extends keyof ICustomerSimpleDto>(key: K): ICustomerSimpleDto[K];
}

export type CustomerSimpleDtoImtList = ImmutableList<ICustomerSimpleDtoImt>;

export interface IPersonDocumentsImt extends ImmutableObject<IPersonDocuments> {
  get<K extends keyof IPersonDocuments>(key: K): IPersonDocuments[K];

  get(key: 'attachments'): ImmutableList<IAttachmentImt>;

  get(key: 'documentGroups'): ImmutableList<IPersonDocumentGroupImt>;
}

export interface ICheckInHistory {
  id: string;
  club: INamedEntity;
  entrance: INamedEntity;
  checkinDate: string;
}

export interface IKidZoneCheckInHistory {
  id: string;
  broughtCustomer: ICheckinResponseMember;
  pickedUpCustomer: ICheckinResponseMember;
  customer: ICheckinResponseMember;
  checkinDate: string;
  pickedUpDate: string;
}

export interface IKidZoneCheckInHistoryImt extends ImmutableObject<IKidZoneCheckInHistory> {
  get<K extends keyof IKidZoneCheckInHistory>(key: K): IKidZoneCheckInHistory[K];

  get(key: 'customer'): ICheckinResponseMemberImt;

  get(key: 'broughtCustomer'): ICheckinResponseMemberImt;

  get(key: 'pickedUpCustomer'): ICheckinResponseMemberImt;
}

export interface ICheckInHistoryImt extends ImmutableObject<ICheckInHistory> {
  get<K extends keyof ICheckInHistory>(key: K): ICheckInHistory[K];

  get(key: 'club'): INamedEntityImt;

  get(key: 'entrance'): INamedEntityImt;
}

export interface IDocumentsHistory extends IPersonDocumentGroupItem {
  signedDate: string;
  uploadDate: string;
}

export interface IDocumentsHistoryImt extends ImmutableObject<IDocumentsHistory> {
  get<K extends keyof IDocumentsHistory>(key: K): IDocumentsHistory[K];
}

export interface ICampaigns {
  pastCampaigns: ICampaignDto;
  plannedCampaigns: ICampaignDto;
  matchCriteriaCampaigns: ICampaignDto;
}

export interface ICampaignsImt extends ImmutableObject<ICampaigns> {
  get<K extends keyof ICampaigns>(key: K): ICampaigns[K];

  get(key: 'pastCampaigns'): ICampaignDtoImt;

  get(key: 'plannedCampaigns'): ICampaignDtoImt;

  get(key: 'matchCriteriaCampaigns'): ICampaignDtoImt;
}

export interface IFreezeHistoryPackageItem {
  startDate: string;
  endDate: string;
  reason: string;
  pricePerBilling: number;
}

export type IFreezeHistoryPackageItemImt = ImmutableObject<IFreezeHistoryPackage>;

export interface IFreezeHistoryPackage {
  packageInstanceId: string;
  title: string;
  type: MenuItemTypes;
  items: IFreezeHistoryPackageItem[];
}

export interface IFreezeHistoryPackageImt extends ImmutableObject<IFreezeHistoryPackage> {
  get<K extends keyof IFreezeHistoryPackage>(key: K): IFreezeHistoryPackage[K];

  get(key: 'items'): IFreezeHistoryPackageItemImt[];
}

export interface IPaymentAccount {
  id: string;
  inUse?: boolean;
  deleted: boolean;
  paymentAccountType: PaymentAccountType;

  // credit card data
  lastFour: string;
  creditCardType: CreditCardTypes;
  cardNumber: string;
  cardHolderName: string;
  expDate: string;
  expired: boolean;

  // checking/saving data
  ownerName: string;
  accountNumber: string;
  routingNumber: string;
  checkType: CheckingSavingsTypes;
}

export type IPaymentAccountImt = ImmutableObject<IPaymentAccount>;

export interface IPackageInstanceSubscription {
  paymentAccount: Partial<IPaymentAccount>;
  packageInstance: Partial<IPackageInstanceDetails>;
  paymentMethod: Partial<IPaymentMethodItem>;

  // field for checking/savings select
  checking?: Partial<IPaymentAccount>;
}

export interface IPackageInstanceSubscriptionImt
  extends ImmutableObject<IPackageInstanceSubscription> {
  get<K extends keyof IPackageInstanceSubscription>(key: K): IPackageInstanceSubscription[K];
}

export interface IPackageInstanceSubscriptionUpdateDTO {
  paymentAccountId: string;
  packageInstanceId: string;
  paymentMethodId: string;
}

export interface IAppointmentsState {
  selectedAppointmentId: string;
  selectedAppointmentDate: string;
  selectedAppointment: IRecentAppointmentItemImt;
  showCancelModal: boolean;
  showCancelParticipationModal: boolean;
  showConfirmParticipationModal: boolean;
  showDeleteModal: boolean;
  showRedeemModal: boolean;
  showRemindModal: boolean;
  showHistoryModal: boolean;
  appointmentStatus: null | PersonAppointmentStatus;
  detailsAnchorEl: null | HTMLElement;
  editAnchorEl: null | HTMLElement;
}

export interface ICancelAppointmentParticipation {
  personId: string;
  appointmentsState: IAppointmentsState;
  updateType?: EventUpdateType;
  module?: PeakModules;
}

export type IConfirmAppointmentParticipation = ICancelAppointmentParticipation;

export interface IMembershipActionDTO {
  canCancel: boolean;
  canChangePlan: boolean;
  canFreeze: boolean;
  canReactivate: boolean;
}

export type IMembershipActionDTOImt = ImmutableObject<IMembershipActionDTO>;

export type ITopUpRewardsData = { note: string; points: number };

export type ICommerceHubCredentials = { keyId: string; publicKey: string; accessToken: string };

export interface IBalanceHistoryItem {
  amount: number;
  createdAt: string;
  id: number;
  type: BalanceType;
  invoiceId: string;
  invoiceNumber: number;
}

export interface IBalanceHistoryItemImt extends ImmutableList<IBalanceHistoryItem[]> {
  get<K extends keyof IBalanceHistoryItem>(key: K): IBalanceHistoryItem[K];
}
