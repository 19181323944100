export const actionTypes = {
  // Event action types
  GET_BOOKING_EVENTS: 'MEMBER_PORTAL/GET_BOOKING_EVENTS',
  GET_BOOKING_EVENTS_LOADING: 'MEMBER_PORTAL/GET_BOOKING_EVENTS_LOADING',
  RESET_BOOKING_EVENTS: 'MEMBER_PORTAL/RESET_BOOKING_EVENTS',
  RESET_BOOKING_EVENT_DETAILS: 'RESET_BOOKING_EVENT_DETAILS',

  GET_BOOKING_EVENT: 'MEMBER_PORTAL/GET_BOOKING_EVENT',
  GET_BOOKING_EVENT_LOADING: 'MEMBER_PORTAL/GET_BOOKING_EVENT_LOADING',
  RESET_BOOKING_EVENT: 'MEMBER_PORTAL/RESET_BOOKING_EVENT',

  BOOKING_EVENT_ACTION: 'MEMBER_PORTAL/BOOKING_EVENT_ACTION',
  BOOKING_EVENT_ACTION_LOADING: 'MEMBER_PORTAL/BOOKING_EVENT_ACTION_LOADING',

  RESET_EVENT_ACTION_RESULT: 'MEMBER_PORTAL/RESET_EVENT_ACTION_RESULT',

  GET_RESOURCES_LIST: 'MEMBER_PORTAL/GET_RESOURCES_LIST',

  GET_CLUBS_LIST: 'MEMBER_PORTAL/GET_CLUBS_LIST',

  GET_EVETN_TAGS_LIST: 'MEMBER_PORTAL/GET_EVETN_TAGS_LIST',

  RESET_DICTIONARIES: 'MEMBER_PORTAL/RESET_DICTIONARIES',
};
