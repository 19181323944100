import { defineMessages } from 'react-intl';

export default defineMessages({
  modalUploadImageTitle: {
    id: 'common.modals.upload.image.modal.title',
    defaultMessage: 'Upload Photo',
  },
  modalUploadImageDropzoneErrorMessage: {
    id: 'common.modals.upload.image.modal.dropzone.error.message',
    defaultMessage:
      "Your photo couldn't be uploaded. The photo should be less than 15 MB and saved as JPG, JPEG, or PNG files.",
  },
  modalUploadImageUploadBtn: {
    id: 'common.modals.upload.image.modal.upload.button',
    defaultMessage: 'Upload image',
  },
});
