export const actionTypes = {
  FETCH_PEAK_EMPLOYEE_LOADING: 'FETCH_PEAK_EMPLOYEE_LOADING',
  FETCH_PEAK_EMPLOYEE: 'FETCH_PEAK_EMPLOYEE',

  FETCH_PEAK_EMPLOYEES_LOADING: 'FETCH_PEAK_EMPLOYEES_LOADING',
  FETCH_PEAK_EMPLOYEES: 'FETCH_PEAK_EMPLOYEES',

  CREATE_PEAK_EMPLOYEE_LOADING: 'CREATE_PEAK_EMPLOYEE_LOADING',
  CREATE_PEAK_EMPLOYEE_RESULT: 'CREATE_PEAK_EMPLOYEE',
  RESET_PEAK_EMPLOYEE_FORM: 'RESET_PEAK_EMPLOYEE_FORM',

  UPDATE_PEAK_EMPLOYEE_LOADING: 'UPDATE_PEAK_EMPLOYEE_LOADING',
  UPDATE_PEAK_EMPLOYEE_RESULT: 'UPDATE_PEAK_EMPLOYEE_RESULT',
  RESET_UPDATE_PEAK_EMPLOYEE_RESULT: 'RESET_UPDATE_PEAK_EMPLOYEE_RESULT',
  RESET_CREATE_PEAK_EMPLOYEE_RESULT: 'RESET_CREATE_PEAK_EMPLOYEE_RESULT',

  PEAK_EMPLOYEE_CHANGE_STATUS_LOADING: 'PEAK_EMPLOYEE_CHANGE_STATUS_LOADING',
  PEAK_EMPLOYEE_CHANGE_STATUS: 'PEAK_EMPLOYEE_CHANGE_STATUS',
  RESET_PEAK_EMPLOYEE_CHANGE_STATUS_RESULT: 'RESET_PEAK_EMPLOYEE_CHANGE_STATUS_RESULT',
};

export enum PeakEmployeesRoutes {
  PEAK_EMPLOYEE_LIST = '/peak-employees',
  PEAK_MAIN_PAGE = '/',
  NEW_PEAK_EMPLOYEE = 'new',
  PEAK_EMPLOYEE = ':id',
  EDIT_PEAK_EMPLOYEE = ':id/edit',
}
