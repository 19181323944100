import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as Barcode } from 'img/icons/barcode.svg';
import { FormattedMessage } from 'react-intl';
import messages from '../../../messages';
import { IClubInfoImt } from 'modules/crm/interfaces/leads';

const useStyles = makeStyles({
  body2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      marginRight: '5px',
    },
  },
  data: {
    lineHeight: 1.33,
  },
});

interface IProps {
  homeClub: IClubInfoImt;
  barcode: string;
}

const CheckInInfo = React.memo(
  ({ homeClub, barcode }: IProps): JSX.Element => {
    const classes = useStyles();

    const addressLine: string = homeClub?.get('addressLine');
    const title: string = homeClub?.get('title');

    return (
      <Box>
        {title || addressLine ? (
          <>
            {title && (
              <Typography style={{ marginBottom: '9px' }} variant="h5" align="center">
                {title || '-'}
              </Typography>
            )}
            {addressLine && (
              <Typography
                gutterBottom
                variant="body2"
                component="p"
                align="center"
                className={classes.data}
              >
                {addressLine}
              </Typography>
            )}
          </>
        ) : (
          <Typography variant="h5" align="center">
            <FormattedMessage {...messages.facilityNotSet} />
          </Typography>
        )}
        <Typography gutterBottom variant="body2" component="p" className={classes.body2}>
          <Barcode />
          {` ${barcode || '-'}`}
        </Typography>
      </Box>
    );
  },
);

export default CheckInInfo;
