import { fromJS } from 'immutable';

import { actionTypes } from 'modules/crm/constants/leads';

const initialStateLeads = fromJS({
  list: [],
  meta: {
    page: 0,
    perPage: 25,
    total: 0,
  },
  listLoading: false,
  leadsStatusChangeLoading: false,
  leadsAssigninLoading: false,
  leadsActionResult: {},
});

export const leadsTableReducer = (state = initialStateLeads, action) => {
  switch (action.type) {
    case actionTypes.FETCH_LEADS_LIST_LOADING:
      return state.set('listLoading', action.payload);
    case actionTypes.FETCH_LEADS_LIST_SUCCESS:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta))
        .set('listLoading', false);

    case actionTypes.SALESPERSON_LEAD_ASSIGN_LOADING:
      return state.set('leadsAssigninLoading', action.payload);

    case actionTypes.SALESPERSON_LEAD_ASSIGN_SUCCESS:
      return state.set('leadsActionResult', fromJS(action.payload));

    case actionTypes.LEAD_STATUS_CHANGE_LOADING:
      return state.set('leadsStatusChangeLoading', action.payload);
    case actionTypes.LEAD_STATUS_CHANGE_SUCCESS:
      return state.set('leadsActionResult', fromJS(action.payload));

    case actionTypes.RESET_LEADS_ACTION_RESULT:
      return state.set('leadsActionResult', fromJS({}));
    default:
      return state;
  }
};

export const initStateLead = fromJS({
  lead: null,
  leadFormSalesInfo: {},
  leadFormSalesInfoLoading: false,
  leadActivities: [],
  leadActivitiesLoading: false,
  leadActivityActionLoading: false,
  createdLead: null,
  createLeadActionResult: null,
  updateLoading: false,
  loading: false,
  resultAction: {},
});

export const leadReducer = (state = initStateLead, action) => {
  switch (action.type) {
    case actionTypes.CREATE_LEAD_PROFILE_LOADING:
    case actionTypes.UPDATE_LEAD_PROFILE_LOADING:
      return state.set('updateLoading', fromJS(action.payload));
    case actionTypes.FETCH_LEAD_LOADING:
      return state.set('loading', fromJS(action.payload));
    case actionTypes.FETCH_LEAD_SUCCESS:
      return state.set('lead', fromJS(action.payload));
    case actionTypes.FETCH_LEAD_PROFILE_SALES_INFO_LOADING:
      return state.set('leadFormSalesInfoLoading', action.payload);
    case actionTypes.FETCH_LEAD_PROFILE_SALES_INFO_SUCCESS:
      return state.set('leadFormSalesInfo', fromJS(action.payload));
    case actionTypes.GET_LEAD_ACTIVITIES_LOADING:
      return state.set('leadActivitiesLoading', action.payload);
    case actionTypes.CREATE_LEAD_ACTIVITY_LOADING:
      return state.set('leadActivityActionLoading', action.payload);
    case actionTypes.GET_LEAD_ACTIVITIES:
      return state.set('leadActivities', fromJS(action.payload));
    case actionTypes.RESET_LEAD_PROFILE_SALES_INFO:
      return state.set('leadFormSalesInfo', fromJS({}));
    case actionTypes.CREATE_LEAD_PROFILE_SUCCESS:
      return state.set('createdLead', fromJS(action.payload));
    case actionTypes.CREATE_LEAD_PROFILE_ACTION_RESULT:
      return state.set('createLeadActionResult', action.payload);
    case actionTypes.LEAD_STATUS_CHANGE_SUCCESS:
      if (action.payload.newStatus) {
        return state.setIn(['lead', 'salesStatus'], action.payload.newStatus);
      }
      return state;
    case actionTypes.LEAD_ACTION_RESULT:
    case actionTypes.CREATE_LEAD_ACTIVITY_RESULT:
      return state.set('resultAction', fromJS(action.payload));
    case actionTypes.RESET_LEAD_ACTION_RESULT:
      return state.set('resultAction', fromJS({}));
    case actionTypes.RESET_LEAD:
      return initStateLead;
    case actionTypes.UPDATE_LEAD_AVATAR:
      return state.setIn(['lead', 'image'], action.payload);
    case actionTypes.UPDATE_LEAD_AVATAR_LOADING:
      return state.setIn(['lead', 'imageLoading'], action.payload);
    default:
      return state;
  }
};
