import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { actionTypes } from './constants';
import { initialState } from 'common/constants/initialState';

export const initStateDictionaries = fromJS({
  list: [],
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
  isLoading: false,
});

function dictionariesReducer(state = initStateDictionaries, action) {
  switch (action.type) {
    case actionTypes.FETCH_DICTIONARY_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_DICTIONARY:
      if (Array.isArray(action.payload)) {
        return state.set('list', fromJS(action.payload));
      }

      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta));
    case actionTypes.RESET_DICTIONARY:
      return initStateDictionaries;
    default:
      return state;
  }
}

const initStateDictionaryItem = fromJS({
  dictionary: {},
  isDictionaryItemLoading: false,
  dictionaryItemActionsStatus: initialState,
});

function dictionaryItemReducer(state = initStateDictionaryItem, action) {
  switch (action.type) {
    case actionTypes.FETCH_DICTIONARY_ITEM_LOADING:
      return state.set('isDictionaryItemLoading', action.payload);
    case actionTypes.FETCH_DICTIONARY_ITEM_SUCCESS:
      return state.set('dictionary', fromJS(action.payload));
    case actionTypes.CREATE_DICTIONARY_LIST_ITEM_SUCCESS:
    case actionTypes.EDIT_DICTIONARY_LIST_ITEM_SUCCESS:
      return state.setIn(['dictionaryItemActionsStatus', 'result'], fromJS(action.payload));
    case actionTypes.EDIT_DICTIONARY_LIST_ITEM_LOADING:
    case actionTypes.CREATE_DICTIONARY_LIST_ITEM_LOADING:
      return state.setIn(['dictionaryItemActionsStatus', 'isLoading'], action.payload);
    case actionTypes.RESET_DICTIONARY_ITEM:
      return initStateDictionaryItem;
    default:
      return state;
  }
}

export default combineReducers({
  dictionariesList: dictionariesReducer,
  dictionaryItem: dictionaryItemReducer,
});
