import React from 'react';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import { Box, makeStyles, Paper, Typography } from '@material-ui/core';

import { PeakModules } from 'common/constants/peakModules';

import { ICheckInImt } from 'modules/front-desk/interfaces';

import { AllowedTo, ScrollBox } from 'common/components/index';
import CurrentCheckIn from './components/CurrentCheckIn/CurrentCheckIn';
import { KidCurrentCheckIn } from 'common/components/CheckInList/components';
import CheckIns from './components/CheckIns/CheckIns';

import messages from 'modules/front-desk/messages/messages';

interface IProps {
  currentCheckIn: ICheckInImt;
  selectedCheckIn: ICheckInImt;
  recentCheckInsList: ImmutableList<ICheckInImt>;
  isRecentCheckInsListLoading: boolean;
  onSelectCheckInPerson: (personId: string) => void;
  module: PeakModules;
  onClickPOSBtn?: (personId: string) => void;
}

const useStyles = makeStyles(() => ({
  emptyListPaper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyListMessage: {
    opacity: 0.3,
    textAlign: 'center',
  },
  scrollBox: {
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));

const CheckInList = ({
  currentCheckIn,
  selectedCheckIn,
  recentCheckInsList,
  isRecentCheckInsListLoading,
  onSelectCheckInPerson,
  module,
  onClickPOSBtn,
}: IProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Box style={{ flex: 1, minHeight: 0 }}>
      <ScrollBox suppressScrollX scrollToTopTrigger={currentCheckIn} className={classes.scrollBox}>
        {currentCheckIn || recentCheckInsList.size > 0 ? (
          <>
            <AllowedTo perform={!!currentCheckIn?.size}>
              <Box mb={1}>
                {module === PeakModules.FrontDesk ? (
                  <CurrentCheckIn
                    selectedCheckInId={selectedCheckIn?.get('id')}
                    checkIn={currentCheckIn}
                    onClick={onSelectCheckInPerson}
                    onClickPOSBtn={onClickPOSBtn}
                  />
                ) : (
                  <KidCurrentCheckIn
                    checkIn={currentCheckIn}
                    selectedCheckInId={selectedCheckIn?.get('id')}
                    onClick={onSelectCheckInPerson}
                  />
                )}
              </Box>
            </AllowedTo>

            <CheckIns
              recentCheckInsList={recentCheckInsList}
              loading={isRecentCheckInsListLoading}
              currentCheckIn={currentCheckIn}
              selectedCheckInId={selectedCheckIn && selectedCheckIn.get('id')}
              selectCheckInPerson={onSelectCheckInPerson}
              module={module}
              onClickPOSBtn={onClickPOSBtn}
            />
          </>
        ) : (
          <Paper className={classes.emptyListPaper}>
            <Typography variant="h3" className={classes.emptyListMessage}>
              <FormattedMessage {...messages.noRecentCheckInsText} />
            </Typography>
          </Paper>
        )}
      </ScrollBox>
    </Box>
  );
};

export default CheckInList;
