import { fromJS } from 'immutable';

import { actionTypes } from 'common/state/modals/constants';

const modalsState = fromJS({
  recommendedFieldsModalState: {
    isOpenRecommendedFieldsModal: false,
    isSubmitted: false,
  },
});

export const modalsReducer = (state = modalsState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_RECOMMENDED_FIELDS_MODAL:
      return state
        .setIn(['recommendedFieldsModalState', 'isOpenRecommendedFieldsModal'], action.payload)
        .setIn(['recommendedFieldsModalState', 'isSubmitted'], false);
    case actionTypes.SET_IS_SUBMITTED_RECOMMENDED_FIELDS_MODAL:
      return state.setIn(['recommendedFieldsModalState', 'isSubmitted'], action.payload);
    default:
      return state;
  }
};
