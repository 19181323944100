import React, { useState } from 'react';
import { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import { Autocomplete as MuiAutocomplete, createFilterOptions } from '@material-ui/lab';

interface ICreateDictionaryItemAutocompleteProps extends AutocompleteProps<any, any, any, any> {
  isFasCreateAndSelect?: boolean;
  onCreateDictionaryItem: (data) => void;
  onAutocompleteChange: (value: Record<string, unknown> | string) => void;
  onBlur: () => void;
}

export default function CreateDictionaryItemAutocomplete({
  value,
  options,
  onAutocompleteChange,
  onBlur,
  onCreateDictionaryItem,
  renderInput,
  isFasCreateAndSelect,
}: ICreateDictionaryItemAutocompleteProps): JSX.Element {
  const [inputValue, setInputValue] = useState<string>();

  const filter = createFilterOptions();

  return (
    <MuiAutocomplete
      options={options}
      renderOption={option => option.name}
      getOptionLabel={option => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.name;
      }}
      getOptionSelected={(option, val) =>
        val.name ? option.name === val.name : val.id === option.id
      }
      value={value}
      onBlur={() => {
        onBlur();
        if (!isFasCreateAndSelect && !value.id) {
          onCreateDictionaryItem(value);
        }
      }}
      onChange={(e, newValue) => {
        if (typeof newValue === 'string') {
          onAutocompleteChange({
            name: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          onAutocompleteChange({
            name: newValue.inputValue,
          });
          if (isFasCreateAndSelect) {
            onCreateDictionaryItem({
              name: newValue.inputValue,
            });
          }
        } else {
          onAutocompleteChange(newValue);
        }
      }}
      onInputChange={(e, searchString) => {
        setInputValue(searchString);
      }}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          onCreateDictionaryItem({
            name: inputValue,
          });
        }
      }}
      filterOptions={(autocompleteOptions, params) => {
        const filtered = filter(autocompleteOptions, params);

        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            name: `Add "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      freeSolo
      fullWidth
      renderInput={renderInput}
    />
  );
}
