import axios from 'axios';
import ApiService from './ApiService';
import { IPaginatedData } from 'common/interfaces/pagination';
import { ITableParams } from 'common/interfaces/table';
import { IDictionaryReasonItem } from 'modules/dictionaries/interfaces/interfaces';

export default class FallThroughReasonsService extends ApiService {
  public getFallThroughReasons = (
    requestOptions?: ITableParams,
  ): Promise<IPaginatedData<IDictionaryReasonItem>> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get('/api/v1/fallthrough-reason', { params: searchParams });
  };

  public createFallThroughReason = (
    data: IDictionaryReasonItem,
  ): Promise<IDictionaryReasonItem> => {
    return axios.post('/api/v1/fallthrough-reason', data);
  };

  public updateFallThroughReason = (
    data: IDictionaryReasonItem,
  ): Promise<IDictionaryReasonItem> => {
    return axios.put(`/api/v1/fallthrough-reason/${data.id}`, data);
  };

  public updateStatusFallThroughReason = (
    id: string,
    isActive: boolean,
  ): Promise<IDictionaryReasonItem> => {
    return axios.patch(`/api/v1/fallthrough-reason/${id}/change-status`, { active: isActive });
  };
}
