import { fromJS } from 'immutable';

import * as IDictionaryItem from 'common/interfaces/dictionary';
import { IPageMeta } from 'common/interfaces/pagination';
import { IDictionaryCommonItem } from 'modules/dictionaries/interfaces/interfaces';

import { DictionaryList } from 'common/constants/dictionaryConstants';
import {
  FETCH_DICTIONARY_LIST_LOADING,
  FETCH_DICTIONARY_LIST_SUCCESS,
  RESET_DICTIONARY_LIST,
} from './constants';

export type FetchDictionaryListLoadingPayload = {
  dictionary: DictionaryList;
  isLoading: boolean;
};

type FetchDictionaryListLoadingAction = {
  type: typeof FETCH_DICTIONARY_LIST_LOADING;
  payload: FetchDictionaryListLoadingPayload;
};

export type ResetDictionaryListPayload = {
  dictionary: DictionaryList;
};

export type FetchDictionaryListSuccessPayload =
  | {
      dictionary: DictionaryList.ALLERGIES;
      list: IDictionaryItem.TAllergyListItem[];
      meta?: IPageMeta;
    }
  | {
      dictionary: DictionaryList.INSURANCE_COMPANY;
      list: IDictionaryCommonItem[];
      meta?: IPageMeta;
    };

type FetchDictionaryListSuccessAction = {
  type: typeof FETCH_DICTIONARY_LIST_SUCCESS;
  payload: FetchDictionaryListSuccessPayload;
};

type ResetDictionaryListAction = {
  type: typeof RESET_DICTIONARY_LIST;
  payload: ResetDictionaryListPayload;
};

type DictionaryListsActions =
  | FetchDictionaryListLoadingAction
  | FetchDictionaryListSuccessAction
  | ResetDictionaryListAction;

const initDictionaryList = {
  list: [],
  meta: {
    page: 0,
    perPage: 25,
    total: 0,
  },
  isLoading: false,
};

const initDictionaryListsStateImt = fromJS({
  [DictionaryList.ALLERGIES]: initDictionaryList,
  [DictionaryList.INSURANCE_COMPANY]: initDictionaryList,
});

export type InitDictionaryListsStateImt = typeof initDictionaryListsStateImt;

export const dictionariesReducer = (
  state = initDictionaryListsStateImt,
  action: DictionaryListsActions,
): InitDictionaryListsStateImt => {
  switch (action.type) {
    case FETCH_DICTIONARY_LIST_LOADING:
      const { isLoading } = action.payload;

      return state.setIn([action.payload.dictionary, 'isLoading'], isLoading);

    case FETCH_DICTIONARY_LIST_SUCCESS:
      const { list, meta } = action.payload;

      return meta && meta.page > 0
        ? state
            .updateIn([action.payload.dictionary, 'list'], dictionaryList =>
              dictionaryList.concat(fromJS(list)),
            )
            .setIn([action.payload.dictionary, 'meta'], fromJS(meta))
            .setIn([action.payload.dictionary, 'isLoading'], false)
        : state
            .setIn([action.payload.dictionary, 'list'], fromJS(list))
            .setIn([action.payload.dictionary, 'meta'], fromJS(meta))
            .setIn([action.payload.dictionary, 'isLoading'], false);

    case RESET_DICTIONARY_LIST:
      return state.set(action.payload.dictionary, fromJS(initDictionaryList));
    default:
      return state;
  }
};

export default dictionariesReducer;
