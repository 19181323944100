import axios from 'axios';

import ApiService from 'services/network/ApiService';

import { IGlobalSearchResult } from 'common/interfaces/search';

export default class GlobalSearchService extends ApiService {
  public getGlobalSearchResults = (search: string): Promise<IGlobalSearchResult> =>
    axios.get('/api/v1/global-search', { params: { search, perPage: 10 } });

  public getGlobalSearchPeakResults = (search: string): Promise<IGlobalSearchResult> =>
    axios.get('/api/v1/global-search/peak', { params: { search, perPage: 10 } });
}
