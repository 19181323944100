import React from 'react';
import { Avatar, Box, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { CustomTheme } from 'common/ui/interfaces';

interface IProps {
  avatarUrl: string;
  className?: string;
  initials?: string;
  isLargeItem?: boolean;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  avatarWrapper: {
    position: 'relative',
    backgroundColor: (props: IProps) =>
      props.initials && !props.avatarUrl ? theme.palette.secondary.light : ``,
  },
  avatarImage: {
    width: '100%',
    height: '100%',
    borderRadius: 0,
  },
  avatarInitial: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    fontSize: (props: IProps) => (props.isLargeItem ? '50px' : '20px'),
    color: theme.palette.darkBackground?.light,
    fontWeight: 700,
  },
}));

const ProfileAvatar = ({ avatarUrl, className, initials, isLargeItem }: IProps): JSX.Element => {
  const classes = useStyles({ avatarUrl, initials, isLargeItem });
  return (
    <Box className={classNames(className, classes.avatarWrapper)}>
      {avatarUrl && <Avatar src={avatarUrl} className={classes.avatarImage} />}
      {initials && !avatarUrl && <Box className={classes.avatarInitial}>{initials}</Box>}
    </Box>
  );
};

export default ProfileAvatar;
