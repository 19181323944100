import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import moment from 'moment-timezone';
import { Typography } from '@material-ui/core';

import { IKidZoneCheckInHistoryImt } from 'common/components/PersonProfile/interfaces';
import { ITableParams, ITableRow } from 'common/interfaces/table';
import { IFilterSettings } from 'common/interfaces/filter';
import { IPageMetaImt } from 'common/interfaces/pagination';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';

import { Table } from 'common/components/index';
import CustomerCell from './CustomerCell/CustomerCell';

import tableHeaders from 'common/messages/tableHeaders';
import inputLabels from 'common/messages/inputLabels';
import commonMessages from 'common/messages/messages';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';

interface IProps {
  title?: string | JSX.Element;
  backRedirectLink?: string;
  checkInsHistory: ImmutableList<IKidZoneCheckInHistoryImt>;
  checkInsHistoryMeta?: IPageMetaImt;
  isCheckInsHistoryLoading: boolean;
  hideSearchInput?: boolean;
  hidePagination?: boolean;
  tableFilterSettings?: IFilterSettings[];
  tableParams?: ITableParams;
  onChangeTableParams?: (tableParams: ITableParams) => void;
  isCommonCheckInsHistory?: boolean;
  disableSorting?: boolean;
}

const getCheckInDuration = (broughtDate: string, pickedUpDate: string) => {
  if (broughtDate && pickedUpDate) {
    const hours = moment(pickedUpDate).diff(broughtDate, 'hours');
    const minutes = moment(pickedUpDate).diff(broughtDate, 'minutes') % 60;

    return hours ? `${hours}H ${minutes}M` : `${minutes}M`;
  }

  return '-';
};

const KidZoneCheckInsHistoryTable = ({
  title,
  backRedirectLink,
  checkInsHistory,
  checkInsHistoryMeta,
  isCheckInsHistoryLoading,
  hidePagination,
  hideSearchInput,
  onChangeTableParams,
  tableFilterSettings,
  isCommonCheckInsHistory,
  tableParams,
  disableSorting = false,
}: IProps): JSX.Element => {
  const [timezoneMoment] = useTimezoneMoment();

  const headerOptions = useMemo(
    () => [
      ...(isCommonCheckInsHistory
        ? [
            {
              id: 'child',
              label: <FormattedMessage {...commonMessages.childLabel} />,
              sort: !disableSorting && false,
            },
          ]
        : [
            {
              id: 'club',
              label: <FormattedMessage {...tableHeaders.club} />,
              sort: !disableSorting,
            },
          ]),
      {
        id: 'date',
        label: <FormattedMessage {...tableHeaders.date} />,
        sort: !disableSorting,
      },
      {
        id: 'broughtCustomer',
        label: <FormattedMessage {...tableHeaders.dropOff} />,
        sort: !disableSorting && false,
      },
      {
        id: 'checkOut',
        label: <FormattedMessage {...tableHeaders.checkOut} />,
        sort: !disableSorting && false,
      },
      {
        id: 'pickedUp',
        label: <FormattedMessage {...tableHeaders.pickedUp} />,
        sort: !disableSorting && false,
      },
      ...(!isCommonCheckInsHistory
        ? [
            {
              id: 'duration',
              label: <FormattedMessage {...inputLabels.duration} />,
              sort: !disableSorting && false,
            },
          ]
        : []),
    ],
    [isCommonCheckInsHistory, disableSorting],
  );

  const rows = useMemo(
    () =>
      checkInsHistory
        .map(
          (history: IKidZoneCheckInHistoryImt): ITableRow => ({
            id: history.get('id'),
            cells: [
              ...(isCommonCheckInsHistory
                ? [
                    {
                      label: '',
                      cellComponent: (
                        <CustomerCell
                          isChild
                          firstName={history.getIn(['customer', 'firstName'])}
                          lastName={history.getIn(['customer', 'lastName'])}
                          imageUrl={history.getIn(['customer', 'imageUrl'])}
                        />
                      ),
                    },
                  ]
                : [
                    {
                      label: history.getIn(['club', 'title']) || '-',
                    },
                  ]),
              {
                label: `${timezoneMoment(history.get('checkinDate')).format(
                  DEFAULT_DATE_TIME_FORMAT,
                )}`,
              },
              ...(isCommonCheckInsHistory
                ? [
                    {
                      label: '',
                      cellComponent: (
                        <CustomerCell
                          firstName={history.getIn(['broughtCustomer', 'firstName'])}
                          lastName={history.getIn(['broughtCustomer', 'lastName'])}
                          imageUrl={history.getIn(['broughtCustomer', 'imageUrl'])}
                        />
                      ),
                    },
                  ]
                : [
                    {
                      label: '',
                      cellComponent: (
                        <Typography color="primary">
                          {`${history.getIn(['broughtCustomer', 'firstName'])} ${history.getIn([
                            'broughtCustomer',
                            'lastName',
                          ])}`}
                        </Typography>
                      ),
                    },
                  ]),
              {
                label: `${
                  history.get('pickedUpDate')
                    ? timezoneMoment(history.get('pickedUpDate')).format(DEFAULT_DATE_TIME_FORMAT)
                    : '-'
                }`,
              },
              {
                label: '',
                cellComponent: (
                  <>
                    {history.get('pickedUpCustomer') ? (
                      <>
                        {isCommonCheckInsHistory ? (
                          <CustomerCell
                            firstName={history.getIn(['pickedUpCustomer', 'firstName'])}
                            lastName={history.getIn(['pickedUpCustomer', 'lastName'])}
                            imageUrl={history.getIn(['pickedUpCustomer', 'imageUrl'])}
                          />
                        ) : (
                          <Typography color="primary">
                            {`${history.getIn(['pickedUpCustomer', 'firstName'])} ${history.getIn([
                              'pickedUpCustomer',
                              'lastName',
                            ])}`}
                          </Typography>
                        )}
                      </>
                    ) : (
                      '-'
                    )}
                  </>
                ),
              },
              ...(!isCommonCheckInsHistory
                ? [
                    {
                      label: `${getCheckInDuration(
                        history.get('checkinDate'),
                        history.get('pickedUpDate'),
                      )}`,
                    },
                  ]
                : []),
            ],
          }),
        )
        .toJS(),
    [checkInsHistory, isCommonCheckInsHistory, timezoneMoment],
  );

  return (
    <Table
      title={title}
      tableParams={tableParams}
      backRedirectLink={backRedirectLink}
      rows={rows}
      headerOptions={headerOptions}
      hideToolbar
      hideSearchInput={hideSearchInput}
      showPerPageSelect={!hidePagination}
      hidePagination={hidePagination}
      isLoading={isCheckInsHistoryLoading}
      page={checkInsHistoryMeta?.get('page')}
      totalRows={checkInsHistoryMeta?.get('total')}
      onChangeParams={onChangeTableParams}
      filters={tableFilterSettings}
    />
  );
};

export default KidZoneCheckInsHistoryTable;
