export const FETCH_PERSON_TICKETS_SUCCESS = 'FETCH_PERSON_TICKETS_SUCCESS';
export const FETCH_PERSON_TICKETS_LOADING = 'FETCH_PERSON_TICKETS_LOADING';
export const RESET_PERSON_TICKETS = 'RESET_PERSON_TICKETS';

export const FETCH_PERSON_TICKET_SUCCESS = 'FETCH_PERSON_TICKET_SUCCESS';
export const FETCH_PERSON_TICKET_LOADING = 'FETCH_PERSON_TICKET_LOADING';
export const RESET_PERSON_TICKET = 'RESET_PERSON_TICKET';

export const ADD_PERSON_TICKET_SUCCESS = 'ADD_PERSON_TICKET_SUCCESS';
export const ADD_PERSON_TICKET_LOADING = 'ADD_PERSON_TICKET_LOADING';
export const RESET_ADD_PERSON_TICKET_ACTION_RESULT = 'RESET_ADD_PERSON_TICKET_ACTION_RESULT';

export const SET_PERSON_TICKETS_ERROR = 'SET_PERSON_TICKETS_ERROR';
