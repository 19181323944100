import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { actionTypes, MemberCountChartFilters } from '../../constants/overviewReport';
import { initReqStateImt } from 'common/constants/initialState';

const transformOverviewChartData = data => {
  return Object.keys(data.activity).map(fieldKey => ({
    date: fieldKey,
    ...data.activity[fieldKey].reduce(
      (acc, item) => ({
        ...acc,
        [item.type]:
          item.type === MemberCountChartFilters.Loosing
            ? Number(item.amount ? -item.amount : item.amount)
            : item.amount,
      }),
      {},
    ),
  }));
};

function salesDrivenReportReducer(state = initReqStateImt, action) {
  switch (action.type) {
    case actionTypes.FETCH_SALES_DRIVEN_REPORT:
      return state.set('result', fromJS(transformOverviewChartData(action.payload)));
    case actionTypes.FETCH_SALES_DRIVEN_REPORT_LOADING:
      return state.set('isLoading', action.payload);
    default:
      return state;
  }
}

function memberCountReportReducer(state = initReqStateImt, action) {
  switch (action.type) {
    case actionTypes.FETCH_MEMBERS_COUNT_REPORT:
      return state.set('result', fromJS(transformOverviewChartData(action.payload)));
    case actionTypes.FETCH_MEMBERS_COUNT_REPORT_LOADING:
      return state.set('isLoading', action.payload);
    default:
      return state;
  }
}

function membershipMixReportReducer(state = initReqStateImt, action) {
  switch (action.type) {
    case actionTypes.FETCH_MEMBERSHIP_MIX_REPORT:
      return state.set('result', fromJS(transformOverviewChartData(action.payload)));
    case actionTypes.FETCH_MEMBERSHIP_MIX_REPORT_LOADING:
      return state.set('isLoading', action.payload);
    default:
      return state;
  }
}

function totalOverviewStatisticsReducer(state = initReqStateImt, action) {
  switch (action.type) {
    case actionTypes.FETCH_TOTAL_OVERVIEW_STATISTICS:
      return state.set('result', fromJS(action.payload));
    case actionTypes.FETCH_TOTAL_OVERVIEW_STATISTICS_LOADING:
      return state.set('isLoading', action.payload);
    default:
      return state;
  }
}

export default combineReducers({
  salesDrivenReport: salesDrivenReportReducer,
  membersCountReport: memberCountReportReducer,
  membershipMixReport: membershipMixReportReducer,
  totalOverviewStatistics: totalOverviewStatisticsReducer,
});
