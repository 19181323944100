import React, { useMemo } from 'react';
import { Box, Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { List as ImmutableList } from 'immutable';
import {
  ICampaignItemImt,
  IRecipientListItem,
  IRecipientListItemImt,
} from 'modules/reports/interfaces/campaignsReport';
import moment from 'moment-timezone';
import { LoadingBackdrop, ScrollBox } from 'common/components';
import TableSection, {
  ITableHeaderColumn,
  ITableSectionColumn,
} from 'modules/reports/components/TableSection/TableSection';
import { IPageMetaImt } from 'common/interfaces/pagination';
import { addFullNameToRecipients, prepareStatistics } from 'modules/reports/utils/campaignReport';
import { FormattedMessage } from 'react-intl';
import { AudienceFilterType, defaultOrder, defaultPerPage } from 'common/constants/campaign';
import messages from 'modules/reports/messages/messages';
import commonMessages from 'common/messages/messages';
import inputLabels from 'common/messages/inputLabels';
import { conditionList } from 'common/constants/condition';
import { audienceTypesValues, durations } from 'modules/reports/constants/campaignsReport';
import { DEFAULT_DATE_FORMAT } from 'common/constants/dateFormats';
import { CampaignTypes } from 'modules/crm/constants/campaigns';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2.5, 2, 2, 2),
    overflow: 'hidden',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  mainInfo: {
    fontSize: '1rem',
  },
  customGrid: {
    margin: theme.spacing(-1),
    width: `calc(100% + ${theme.spacing(2)}px)`,
    alignItems: 'stretch',
  },
  customGridItem: {
    padding: theme.spacing(1),
    width: 'calc(100% / 8)',
    minWidth: 140,
    borderRight: `1px solid ${theme.palette.secondary.light}`,
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% / 4)',
    },
    '&:last-child': {
      borderRight: `none`,
    },
  },
  itemWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'center',
    padding: theme.spacing(2, 1),
  },
  totalsAmount: {
    fontSize: '1.125rem',
    marginTop: theme.spacing(0.5),
    lineHeight: 1,
    fontWeight: 500,
  },
  totalsTitle: {
    lineHeight: 1,
    opacity: 0.7,
  },
  recipientsBlock: {
    border: `1px solid ${theme.palette.secondary.light}`,
    padding: theme.spacing(2, 2),
  },
  infoBlock: {
    border: `1px solid ${theme.palette.secondary.light}`,
    padding: theme.spacing(0, 2),
  },
  infolockTitle: {
    padding: theme.spacing(2, 0),
  },
  infolockRow: {
    borderTop: `1px solid ${theme.palette.secondary.light}`,
    padding: theme.spacing(1.5, 0),
  },
}));

interface ICampaignsContainerProps {
  campaign: ICampaignItemImt;
  isLoading: boolean;
  recipients: ImmutableList<IRecipientListItemImt>;
  recipientsMeta: IPageMetaImt;
  isRecipientsLoading: boolean;
  handleChangeTableProps: (tableProps) => void;
}

const columnSettings: ITableSectionColumn[] = [
  {
    name: 'name',
    label: <FormattedMessage {...inputLabels.name} />,
  },
  {
    name: 'email',
    label: <FormattedMessage {...commonMessages.emailLabel} />,
  },
];

const headerSettings: ITableHeaderColumn<IRecipientListItem>[] = [
  {
    name: 'name',
    label: <FormattedMessage {...inputLabels.name} />,
    sort: true,
  },
  {
    name: 'email',
    label: <FormattedMessage {...commonMessages.emailLabel} />,
    sort: true,
  },
];

const CampaignContainer = ({
  campaign,
  isLoading,
  recipients,
  recipientsMeta,
  isRecipientsLoading,
  handleChangeTableProps,
}: ICampaignsContainerProps): JSX.Element => {
  const classes = useStyles();

  const onChangeTableProps = (p): void => {
    handleChangeTableProps({ page: p, perPage: 10 });
  };

  const modifiedRecipients = useMemo(() => addFullNameToRecipients(recipients), [recipients]);

  const renderTotals = (): JSX.Element[] | null => {
    if (!campaign.get('statistics')) return null;

    const statistics = prepareStatistics(campaign.get('statistics'));
    return statistics.map(item => (
      <Grid item className={classes.customGridItem} key={item.id}>
        <Box className={classes.itemWrapper}>
          <Typography variant="body2" component="p" className={classes.totalsTitle}>
            <FormattedMessage {...item.title} />
          </Typography>
          <Typography color="primary" component="p" className={classes.totalsAmount}>
            {`${!Number.isNaN(item.amount) ? item.amount : 0} ${item.units}`}
          </Typography>
        </Box>
      </Grid>
    ));
  };

  const createTemplateForAudience = (item, audienceType, itemValue): JSX.Element => {
    const name = audienceTypesValues.find(el => el.type === audienceType).title;
    return (
      <Box display="flex">
        <FormattedMessage {...name} />:
        <Box marginLeft="5px">
          {item
            .get(itemValue)
            .toJS()
            .map(el => `${el}, `)}
        </Box>
      </Box>
    );
  };

  const getRunningCondition = (item): JSX.Element => {
    const condition = conditionList.find(el => el.type === item.get('type'));
    const counter = item.get('counter');
    const cType = item.get('counterType');
    const counterType = cType ? durations.find(el => el.value === cType)?.message : null;

    return (
      <Box display="flex">
        <FormattedMessage {...condition.message} />
        &nbsp;{counter && counter}&nbsp;
        {counterType && <FormattedMessage {...counterType} />}
      </Box>
    );
  };

  const getAudienceFilterList = item => {
    switch (item.get('type')) {
      case AudienceFilterType.CAME_FROM:
        return createTemplateForAudience(item, AudienceFilterType.CAME_FROM, 'cameFromList');

      case AudienceFilterType.GENDER:
        return createTemplateForAudience(item, AudienceFilterType.GENDER, 'genders');

      case AudienceFilterType.BASED_ON_SERVICES:
        return createTemplateForAudience(item, AudienceFilterType.BASED_ON_SERVICES, 'serviceList');

      case AudienceFilterType.LEAD_STATUS:
        return createTemplateForAudience(item, AudienceFilterType.LEAD_STATUS, 'leadStatuses');

      case AudienceFilterType.AGE_GROUP:
        return createTemplateForAudience(item, AudienceFilterType.AGE_GROUP, 'ageGroups');

      case AudienceFilterType.LOCATIONS:
        return createTemplateForAudience(item, AudienceFilterType.LOCATIONS, 'clubList');

      default:
        return '';
    }
  };

  return (
    <ScrollBox>
      <Paper className={classes.paper}>
        <Grid container className={classes.customGrid}>
          {renderTotals()}
        </Grid>
      </Paper>
      <Paper className={classes.paper}>
        {isLoading && <LoadingBackdrop />}
        {!!campaign.size && (
          <Grid container spacing={2} direction="column" wrap="nowrap">
            <Grid item container spacing={4}>
              <Grid item>
                <Typography component="p">
                  <Typography color="textSecondary" component="span" className={classes.mainInfo}>
                    Type:&nbsp;
                  </Typography>
                  <Typography component="span" className={classes.mainInfo}>
                    {CampaignTypes.translate(campaign.get('campaignType'))}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item>
                <Typography component="p">
                  <Typography color="textSecondary" component="span" className={classes.mainInfo}>
                    Template:&nbsp;
                  </Typography>
                  <Typography component="span" className={classes.mainInfo}>
                    {campaign.getIn(['documentTemplate', 'title']) ?? '-'}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item>
                <Typography component="p">
                  <Typography color="textSecondary" component="span" className={classes.mainInfo}>
                    Salesperson:&nbsp;
                  </Typography>
                  <Typography component="span" className={classes.mainInfo}>
                    {campaign.get('salesPerson') ?? '-'}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item>
                <Typography component="p">
                  <Typography color="textSecondary" component="span" className={classes.mainInfo}>
                    Start:&nbsp;
                  </Typography>
                  <Typography component="span" className={classes.mainInfo}>
                    {moment(campaign.get('startDate')).format(DEFAULT_DATE_FORMAT)}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Grid item container spacing={2}>
                  <Grid item xs={12}>
                    <Box className={classes.infoBlock}>
                      <Box className={classes.infolockTitle}>
                        <Typography variant="h5" color="textSecondary">
                          <FormattedMessage {...messages.runningConditionsTitle} />
                        </Typography>
                      </Box>
                      {campaign.get('runningConditionList')?.map(item => {
                        return (
                          <Box key={item.get('id')} className={classes.infolockRow}>
                            <Typography variant="body1" component="p">
                              {getRunningCondition(item)}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className={classes.infoBlock}>
                      <Box className={classes.infolockTitle}>
                        <Typography variant="h5" color="textSecondary">
                          <FormattedMessage {...messages.targetAudienceFilteringTitle} />
                        </Typography>
                      </Box>
                      {campaign.get('audienceFilterList')?.map(item => {
                        return (
                          <Box key={item.get('id')} className={classes.infolockRow}>
                            <Typography variant="body1" component="p">
                              {getAudienceFilterList(item)}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box className={classes.recipientsBlock}>
                  <TableSection<IRecipientListItem, IRecipientListItemImt>
                    id="recepients"
                    title={<FormattedMessage {...messages.listOfRecipientsTitle} />}
                    columns={columnSettings}
                    headerColumns={headerSettings}
                    data={modifiedRecipients}
                    dataLoading={isRecipientsLoading}
                    page={recipientsMeta?.get('page')}
                    total={recipientsMeta?.get('total')}
                    order={defaultOrder}
                    perPage={defaultPerPage}
                    onSettingsChanged={onChangeTableProps}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Paper>
    </ScrollBox>
  );
};

export default CampaignContainer;
