import { CustomTheme } from 'common/ui/interfaces';

export const getStepStyles = (theme: CustomTheme) => ({
  body: {
    marginTop: theme.spacing(0.5),
  },
  footerActions: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
});
