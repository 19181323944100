import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '@material-ui/core';
import messages from '../messages';
import { WaiverStatus } from 'modules/crm/constants/leads';
import { useTheme } from '@material-ui/core/styles';

interface ISignStatusProps {
  status: WaiverStatus;
}

const ContractStatus = ({ status }: ISignStatusProps): JSX.Element => {
  const theme = useTheme();

  switch (status) {
    case WaiverStatus.NO_SIGN:
      return (
        <Typography style={{ color: theme.palette.warning.main }}>
          <FormattedMessage {...messages.contractStatusNoSign} />
        </Typography>
      );

    case WaiverStatus.UNSIGNED:
      return (
        <Typography color="error">
          <FormattedMessage {...messages.contractStatusUnsigned} />
        </Typography>
      );

    default:
      return (
        <Typography color="primary">
          <FormattedMessage {...messages.contractStatusSigned} />
        </Typography>
      );
  }
};

export default ContractStatus;
