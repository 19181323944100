// libraries
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// custom interfaces and components
import { Box } from '@material-ui/core';
import { Done, Close } from '@material-ui/icons';
import { TooltipTypography } from 'common/components';

interface ICheckboxInfoProps {
  label: string | JSX.Element;
  value: boolean;
}

const useStyles = makeStyles(() => ({
  checkboxInfoBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
  },
}));

const CheckboxInfo: React.FC<ICheckboxInfoProps> = (props: ICheckboxInfoProps): JSX.Element => {
  const classes = useStyles(props);
  const { label, value } = props;

  // renders

  return (
    <Box className={classes.checkboxInfoBox}>
      {value ? <Done color="primary" /> : <Close color="error" />}
      <TooltipTypography ellipsized variant="body1" color="textPrimary">
        {label}
      </TooltipTypography>
    </Box>
  );
};

export default CheckboxInfo;
