import { ImmutableObject } from '../state/interfaces';
import { MessageDescriptor } from 'react-intl';

export enum FileType {
  ROOT = 'ACTION',
  IMAGE = 'DATE_TIME',
  TEMP = 'TIME_BEFORE',
  ATTACHMENT = 'NUMBER',
  ALERT_SOUND = 'ALERT_SOUND',
  TMP_IMAGE = 'TMP_IMAGE',
}

export enum ImageFormatType {
  WEBP = 'image/webp',
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
  BMP = 'image/bmp',
}

// New API for files
export interface IAttachment {
  filePath?: string;
  id: string;
  name: string;
  sizeInBytes: number;
  type: FileType;
}

export interface IAttachmentImt extends ImmutableObject<IAttachment> {
  get<K extends keyof IAttachment>(key: K): IAttachment[K];
}

export interface IUploadImagePayload {
  dialogId: string;
  image: IAttachment;
}

export interface IUploadFileResult extends IAttachment {
  url?: string;
  filename?: string;
  fileSize?: number;
}

export interface IUploadFileResultImt extends Map<string, any> {
  toJS(): IUploadFileResult;

  get<K extends keyof IUploadFileResult>(key: K): IUploadFileResult[K];
}

export interface IUploadFilePayload {
  dialogId: string | null;
  file: IUploadFileResult | null;
}

export interface IUploadMultipleFilePayload {
  dialogId: string;
  files: IUploadFileResult[];
}

export interface ICropResult {
  blob: Blob;
  url: string;
}

export interface IUploaderCustomError {
  id: string;
  error: string;
  customError: MessageDescriptor;
}

export interface IStoredFileSimpleDto {
  id: string;
  url?: string;
}

export interface IStoredFileSimpleDtoImt extends ImmutableObject<IStoredFileSimpleDto> {
  get<K extends keyof IStoredFileSimpleDto>(key: K): IStoredFileSimpleDto[K];
}
