import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      borderRadius: '14px',
      backgroundColor: theme.palette.primary.main,
    },
  }),
);

interface IProps {
  offsetKey: string;
  children: any;
}

const EditorTag = (props: IProps) => {
  const { offsetKey, children } = props;
  const classes = useStyles();

  return (
    <span className={classes.root} data-offset-key={offsetKey}>
      {children}
    </span>
  );
};

export default EditorTag;
