import React, { FC, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { List as ImmutableList } from 'immutable';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import {
  IBodyCell,
  IHeadCell,
  IMultipleSelectData,
  ITableParams,
  ITableRow,
} from 'common/interfaces/table';
import { IFilterSettings } from 'common/interfaces/filter';
import {
  ILeadBulkSalespersonDto,
  ILeadBulkSalesStatusDto,
  ILeadFilterDto,
  ILeadImt,
  TViewType,
} from 'modules/crm/interfaces/leads';
import { TableOrderByParams } from 'common/constants/table';
import { LeadStatus } from 'common/constants/personConstants';
import { MemberName, Table } from 'common/components';
import LeadsActionsMenu from './ActionsMenu/ActionsMenu';
import SalespersonName from './SalespersonName/SalespersonName';
import SmallScreenCell from './SmallScreenCell/SmallScreenCell';
import { LeadStatusComponent, LeadsPipeline } from 'modules/crm/components';
import TableSwitch from './TableSwitch/TableSwitch';
import ChangeStatusModal from './ChangeStatusModal/ChangeStatusModal';
import { ISalesPerson } from '../../interfaces/tasks';
import ChangeSalespersonModal from './ChangeSalespersonModal/ChangeSalespersonModal';
// icons
import { ReactComponent as EditIcon } from 'img/icons/edit.svg';
import { ReactComponent as UserIcon } from 'img/icons/user.svg';
import { ReactComponent as AddIcon } from 'img/icons/add.svg';
// messages
import tableHeaders from 'common/messages/tableHeaders';
import tableFilters from 'common/messages/tableFilters';
import messages from 'common/messages/messages';
import { GuestStatus } from 'modules/front-desk/components';
import { PeakModules } from 'common/constants/peakModules';
import ptCrmMessages from 'modules/personal-training-crm/messages/messages';
import crmMessages from 'modules/crm/messages/common';
import leadsMessages from 'modules/crm/messages/leads';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';
import { convertToBulkEditFilters } from 'common/utils/filter';
import useLeadPermissions from 'modules/crm/hooks/useLeadPermissions';
import { IObject } from 'common/interfaces/common';

interface IProps {
  items: ImmutableList<ILeadImt>;
  tableParams: ITableParams;
  tableFilterSettings: IFilterSettings[];
  totalRows: number;
  isLoading: boolean;
  leadsActionResult: Record<string, boolean>;
  viewType: TViewType;
  setViewType: (viewType: TViewType) => void;
  onChangeParams: (tableParams: ITableParams) => void;
  onAssignSalespersonLeads: (options: ILeadBulkSalespersonDto) => void;
  onChangeLeadsStatus: (options: ILeadBulkSalesStatusDto) => void;
  resetLeadsActionResult: () => void;
  module: PeakModules.Crm | PeakModules.PersonalTrainingCrm;
}

const useStyles = makeStyles({
  icon: {
    width: 16,
    height: 16,
  },
  scrollBox: {
    overflow: 'visible',
  },
});

const LeadsTable: FC<IProps> = ({
  isLoading,
  items,
  totalRows,
  tableParams,
  tableFilterSettings,
  leadsActionResult,
  onChangeParams,
  resetLeadsActionResult,
  onChangeLeadsStatus,
  onAssignSalespersonLeads,
  viewType,
  setViewType,
  module,
}) => {
  const [isChangeStatusModalOpen, setIsChangeStatusModalOpen] = useState<boolean>(false);
  const [isChangeSpModalOpen, setIsChangeSpModalOpen] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectedRowsData, setSelectedRowsData] = useState<IMultipleSelectData>({
    excludedIds: [],
    includedIds: [],
    tableParams: {
      tableFilters: tableParams.filters,
      searchStr: tableParams.searchStr,
      sortBy: String(tableParams.orderBy),
      sortDirection: tableParams.order,
      page: tableParams.page,
      perPage: tableParams.perPage,
    },
    isAllSelected: false,
  });

  const [timezoneMoment] = useTimezoneMoment();
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const isCrmModule = module === PeakModules.Crm;

  const {
    hasCreatePermission,
    hasEditSalespersonPermission,
    hasEditStatusPermission,
  } = useLeadPermissions(module);

  const hasActions = hasEditSalespersonPermission || hasEditStatusPermission;

  const headerOptions: IHeadCell[] = [
    { id: TableOrderByParams.NAME, label: <FormattedMessage {...tableHeaders.name} />, sort: true },
    {
      id: TableOrderByParams.STATUS,
      label: (
        <FormattedMessage
          {...(isCrmModule ? leadsMessages.crmStatusTitle : ptCrmMessages.ptCrmStatusTitle)}
        />
      ),
      sort: true,
    },
    {
      id: 'membershipStatus',
      label: <FormattedMessage {...tableFilters.membershipStatus} />,
      sort: false,
    },
    {
      id: TableOrderByParams.SALESPERSON,
      label: <FormattedMessage {...tableHeaders.salesperson} />,
      sort: true,
    },
    {
      id: TableOrderByParams.CREATED_DATE,
      label: <FormattedMessage {...tableHeaders.created} />,
      sort: true,
    },
    {
      id: TableOrderByParams.CLUB_LOCATION,
      label: <FormattedMessage {...tableHeaders.homeClub} />,
      sort: true,
    },
    {
      id: TableOrderByParams.POTENTIAL_CLUB_LOCATION,
      label: <FormattedMessage {...tableHeaders.potentialClub} />,
      sort: true,
    },
  ];

  if (hasActions) {
    headerOptions.push({
      id: 'actions',
      label: <FormattedMessage {...tableHeaders.actions} />,
      sort: false,
      align: 'center',
      padding: 'none',
    });
  }

  const handleLeadClick = useCallback(
    (leadId: string) => {
      navigate(`/${location.pathname.split('/')[1]}/leads/${leadId}`);
    },
    [location.pathname, navigate],
  );

  const onOpenChangeStatus = useCallback(
    (selected: string | string[]) => {
      setSelectedRows(Array.isArray(selected) ? selected : [selected]);
      setIsChangeStatusModalOpen(true);
    },
    [setSelectedRows, setIsChangeStatusModalOpen],
  );

  const onCloseChangeStatus = useCallback(() => {
    setIsChangeStatusModalOpen(false);
    setSelectedRows([]);
  }, [setIsChangeStatusModalOpen]);

  const onSubmitChangeStatus = useCallback(
    (salesStatus: LeadStatus) => {
      const { includedIds, excludedIds, isAllSelected } = selectedRowsData;

      const copyTableParams = { ...selectedRowsData.tableParams };

      const search = copyTableParams.searchStr;
      delete copyTableParams.searchStr;
      const { tableFilters: filters, ...params } = copyTableParams;

      const convertedFilters = convertToBulkEditFilters<
        Omit<ILeadFilterDto, 'page' | 'perPage' | 'sortBy' | 'sortDirection' | 'search'>
      >(filters);

      const tableFilter = { ...convertedFilters, ...params, search: search || '' };

      const payload: ILeadBulkSalesStatusDto = {
        salesStatus,
        tableFilter,
        includedIds: selectedRows,
      };

      if (!selectedRows.length) {
        payload.excludedIds = excludedIds;
        payload.allSelected = isAllSelected;
        payload.includedIds = includedIds;
      }

      onChangeLeadsStatus(payload);
    },
    [selectedRowsData, selectedRows, onChangeLeadsStatus],
  );

  const onOpenChangeSp = useCallback(
    (selected: string | string[]) => {
      setSelectedRows(Array.isArray(selected) ? selected : [selected]);
      setIsChangeSpModalOpen(true);
    },
    [setSelectedRows, setIsChangeSpModalOpen],
  );

  const onCloseChangeSp = useCallback(() => {
    setIsChangeSpModalOpen(false);
    setSelectedRows([]);
  }, [setIsChangeSpModalOpen]);

  const onSubmitChangeSp = useCallback(
    (person: ISalesPerson) => {
      const { includedIds, excludedIds, isAllSelected } = selectedRowsData;

      const copyTableParams = { ...selectedRowsData.tableParams };

      const search = copyTableParams.searchStr;
      delete copyTableParams.searchStr;
      const { tableFilters: filters, ...params } = copyTableParams;

      const convertedFilters = convertToBulkEditFilters<
        Omit<ILeadFilterDto, 'page' | 'perPage' | 'sortBy' | 'sortDirection' | 'search'>
      >(filters);

      const tableFilter = { ...convertedFilters, ...params, search: search || '' };

      const payload: ILeadBulkSalespersonDto = {
        salespersonId: person?.id,
        tableFilter,
        includedIds: selectedRows,
      };

      if (!selectedRows.length) {
        payload.excludedIds = excludedIds;
        payload.allSelected = isAllSelected;
        payload.includedIds = includedIds;
      }

      onAssignSalespersonLeads(payload);
    },
    [selectedRowsData, selectedRows, onAssignSalespersonLeads],
  );

  useEffect(() => {
    if (leadsActionResult.success) {
      onCloseChangeStatus();
      onCloseChangeSp();
      resetLeadsActionResult();
    }
  }, [leadsActionResult, onCloseChangeStatus, onCloseChangeSp, resetLeadsActionResult]);

  const createRows = (): ITableRow[] => {
    return items
      .map(
        (lead: ILeadImt): ITableRow => {
          const potentialClub = lead.get('potentialClub');
          const homeClub = lead.get('homeClub');

          const bodyCells: IBodyCell[] = [
            {
              label: '-',
              width: '14.2%',
              cellComponent: (
                <MemberName
                  firstName={lead.get('firstName')}
                  lastName={lead.get('lastName')}
                  photoUrl={lead.get('imageUrl')}
                  onClick={() => handleLeadClick(lead.get('id'))}
                />
              ),
            },
            {
              label: '-',
              width: '14.2%',
              cellComponent: <LeadStatusComponent status={lead.get('salesStatus')} />,
            },
            {
              label: '-',
              width: '14.2%',
              cellComponent: <GuestStatus variant="body1" type={lead.get('type')} />,
            },
            {
              label: '-',
              width: '14.2%',
              cellComponent: (
                <SalespersonName
                  firstName={lead.get('assignedSalesperson')?.get('firstName')}
                  lastName={lead.get('assignedSalesperson')?.get('lastName')}
                />
              ),
            },
            {
              label: '-',
              width: '14.2%',
              cellComponent: (
                <Typography>
                  {timezoneMoment(lead.get('createdDate')).format(DEFAULT_DATE_TIME_FORMAT)}
                </Typography>
              ),
            },
            {
              label: '-',
              width: '14.2%',
              cellComponent: <Typography>{homeClub ? homeClub.get('title') : '-'}</Typography>,
            },
            {
              label: '-',
              width: '14.2%',
              cellComponent: potentialClub ? (
                <Typography>{potentialClub.get('title')}</Typography>
              ) : (
                '-'
              ),
            },
          ];

          const bodyCellsMini: IBodyCell[] = [
            {
              label: '-',
              cellComponent: (
                <SmallScreenCell
                  firstName={lead.get('firstName')}
                  lastName={lead.get('lastName')}
                  photoUrl={lead.get('photoUrl')}
                  status={lead.get('status')}
                  salesperson={lead.get('assignedSalesperson')?.toJS()}
                  onLeadClick={() => handleLeadClick(lead.get('id'))}
                />
              ),
            },
          ];

          if (hasActions) {
            bodyCells.push({
              label: 'actions',
              align: 'center',
              width: '54px',
              padding: 'none',
              cellComponent: (
                <LeadsActionsMenu
                  module={module}
                  leadId={lead.get('id')}
                  onChangeStatus={onOpenChangeStatus}
                  onChangeSalesperson={onOpenChangeSp}
                />
              ),
            });

            bodyCellsMini.push({
              label: 'actions',
              align: 'center',
              cellComponent: (
                <LeadsActionsMenu
                  module={module}
                  leadId={lead.get('id')}
                  onChangeStatus={onOpenChangeStatus}
                  onChangeSalesperson={onOpenChangeSp}
                />
              ),
            });
          }

          return {
            id: `${lead.get('id')}`,
            cells: bodyCells,
            cellsMini: bodyCellsMini,
          };
        },
      )
      .toJS();
  };

  const renderBody = () => (
    <LeadsPipeline
      items={items}
      module={module}
      onChangeStatus={onOpenChangeStatus}
      onChangeSalesperson={onOpenChangeSp}
      onLoadMore={() => {}}
    />
  );

  const renderHeaderRight = () => {
    return <TableSwitch isLoading={isLoading} viewType={viewType} onChangeViewType={setViewType} />;
  };

  const selectedLeads = items.filter(l => selectedRows.includes(l.get('id')));

  const handleSelect = useCallback(
    (selectedData: IObject) => {
      const { isAllSelected, selectedIds, excludedIds, tableParams: params } = selectedData;

      setSelectedRowsData({
        isAllSelected,
        tableParams: params,
        includedIds: selectedIds,
        excludedIds,
      });
    },
    [setSelectedRowsData],
  );

  const multipleSelectActions = [
    {
      id: 'changeStatus',
      tooltip: (
        <FormattedMessage
          {...(isCrmModule
            ? leadsMessages.changeStatusLeadProfileTitle
            : ptCrmMessages.changeStatusPtLeadProfileTitle)}
        />
      ),
      className: classes.icon,
      ButtonIcon: EditIcon,
      onClick: onOpenChangeStatus,
    },
    {
      id: 'changeSalesperson',
      tooltip: <FormattedMessage {...leadsMessages.changeSalesTitle} />,
      className: classes.icon,
      ButtonIcon: UserIcon,
      onClick: onOpenChangeSp,
    },
  ];

  const isPipeline = viewType === 'pipeline';
  let addButton: JSX.Element | null;

  const handleLeadCreationClick = useCallback(() => {
    navigate(`/${location.pathname.split('/')[1]}/leads/new`);
  }, [location.pathname, navigate]);

  if (hasCreatePermission) {
    addButton = (
      <Button
        variant="contained"
        color="primary"
        onClick={handleLeadCreationClick}
        startIcon={<AddIcon className={classes.icon} />}
      >
        <FormattedMessage {...messages.newBtn} />
      </Button>
    );
  }

  return (
    <>
      <Table
        title={<FormattedMessage {...(isCrmModule ? crmMessages.leads : ptCrmMessages.ptLeads)} />}
        filters={tableFilterSettings}
        addButton={addButton}
        backRedirectLink={`/${location.pathname.split('/')[1]}`}
        headerOptions={headerOptions}
        activeSelect
        isLoading={isLoading}
        rows={createRows()}
        totalRows={totalRows}
        onChangeParams={onChangeParams}
        showPerPageSelect
        scrollBoxClassName={classes.scrollBox}
        multipleSelectActions={multipleSelectActions}
        onSelect={handleSelect}
        renderBody={isPipeline && renderBody}
        renderHeaderRight={renderHeaderRight}
        successAction={leadsActionResult}
        tableParams={tableParams}
      />
      {isChangeStatusModalOpen && (
        <ChangeStatusModal
          openModal={isChangeStatusModalOpen}
          selectedLeads={selectedLeads}
          onClose={onCloseChangeStatus}
          onSubmit={onSubmitChangeStatus}
          module={module}
        />
      )}
      {isChangeSpModalOpen && (
        <ChangeSalespersonModal
          module={module}
          openModal={isChangeSpModalOpen}
          selectedLeads={selectedLeads}
          onClose={onCloseChangeSp}
          onSubmit={onSubmitChangeSp}
        />
      )}
    </>
  );
};

export default LeadsTable;
