import React from 'react';
import cx from 'classnames';
import { Box, makeStyles, Typography } from '@material-ui/core';

import { TooltipTypography } from 'common/components';
import { CustomTheme } from 'common/ui/interfaces';
import { Variant } from '@material-ui/core/styles/createTypography';

interface IFieldInfoProps {
  label: string | JSX.Element;
  description?: string | JSX.Element;
  content?: string | JSX.Element;
  labelVariant?: Variant;
  descriptionVariant?: Variant;
  labelColor?:
    | 'initial'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error';
  className?: string;
  descriptionClassName?: string;
  labelClassName?: string;
  hasNotEllipsesDescription?: boolean;
  inputMode?: boolean;
  grayedOut?: boolean;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  inputView: {
    padding: theme.spacing(0.875, 1.5),
  },
  captionLabel: {
    marginBottom: theme.spacing(0.5),
  },
  grayedOut: {
    backgroundColor: theme.palette.background.default,
  },
}));

const FieldInfo = (props: IFieldInfoProps): JSX.Element => {
  const {
    label,
    labelVariant = 'body2',
    descriptionVariant = 'body1',
    labelColor = 'textSecondary',
    description,
    content,
    className,
    descriptionClassName,
    labelClassName,
    hasNotEllipsesDescription = false,
    inputMode = false,
    grayedOut = false,
  } = props;

  const classes = useStyles();

  return (
    <Box
      className={cx({ [classes.inputView]: inputMode, [classes.grayedOut]: grayedOut }, className)}
    >
      <Typography
        variant={labelVariant}
        color={labelColor}
        className={cx(classes.captionLabel, labelClassName)}
      >
        {label}
      </Typography>
      {!!description && (
        <TooltipTypography
          className={descriptionClassName}
          ellipsized={!hasNotEllipsesDescription}
          variant={descriptionVariant}
          color="textPrimary"
        >
          {description}
        </TooltipTypography>
      )}

      {!!content && content}
    </Box>
  );
};

export default FieldInfo;
