import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { actionTypes } from '../../constants/taxRates';

const initStateTaxRatesList = fromJS({
  list: [],
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
  isListLoading: false,
  isChangeStatusTaxRateLoading: false,
  changeStatusTaxRateResult: {},
});

function taxRatesListReducer(state = initStateTaxRatesList, action) {
  switch (action.type) {
    case actionTypes.FETCH_TAX_RATES_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_TAX_RATES:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta))
        .set('listLoading', false);
    case actionTypes.CHANGE_STATUS_TAX_RATE_LOADING:
      return state.set('isChangeStatusTaxRateLoading', action.payload);
    case actionTypes.CHANGE_STATUS_TAX_RATE:
      return state.set('changeStatusTaxRateResult', fromJS(action.payload));
    case actionTypes.RESET_CHANGE_STATUS_TAX_RATE_RESULT:
      return state.set('changeStatusTaxRateResult', fromJS({}));
    default:
      return state;
  }
}

const initStateTaxRate = fromJS({
  taxRate: {},
  isLoading: false,
  isSaveLoading: false,
  saveTaxRateResult: {},
});

function taxRateByIdReducer(state = initStateTaxRate, action) {
  switch (action.type) {
    case actionTypes.FETCH_TAX_RATE_BY_ID_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_TAX_RATE_BY_ID:
      return state.set('taxRate', fromJS(action.payload));
    case actionTypes.CREATE_TAX_RATE_LOADING:
      return state.set('isSaveLoading', action.payload);
    case actionTypes.CREATE_TAX_RATE:
      return state.set('saveTaxRateResult', fromJS(action.payload));
    case actionTypes.UPDATE_TAX_RATE_LOADING:
      return state.set('isSaveLoading', action.payload);
    case actionTypes.UPDATE_TAX_RATE:
      return state.set('saveTaxRateResult', fromJS(action.payload));
    case actionTypes.RESET_UPDATE_TAX_RATE_RESULT:
      return state.set('saveTaxRateResult', fromJS({}));
    case actionTypes.RESET_TAX_RATE_BY_ID:
      return initStateTaxRate;
    default:
      return state;
  }
}

export default combineReducers({
  taxRatesList: taxRatesListReducer,
  taxRateItem: taxRateByIdReducer,
});
