export enum ServiceType {
  Custom = 'CUSTOM',
  FamilyMember = 'FAMILY_MEMBER',
  PersonalTraining = 'PERSONAL_TRAINING',
  DayCare = 'DAY_CARE',
}

export enum RedeemType {
  Session = 'SESSION',
  Duration = 'DURATION',
  Amount = 'AMOUNT',
}

export enum PaymentMethods {
  Card = 'Card',
  Receipt = 'Receipt',
}

export enum ResourceTypes {
  Trainer = 'Trainer',
  Gym = 'Gym',
}
