import { defineMessages } from 'react-intl';

export default defineMessages({
  scripts: {
    id: 'app.modules.crm.scripts.tableTitle',
    defaultMessage: 'Scripts',
  },
  changeStatus: {
    id: 'app.modules.crm.scripts.changeStatus',
    defaultMessage: 'Change script status',
  },
  changeScriptStatusText: {
    id: 'app.modules.crm.scripts.changeScriptStatusText',
    defaultMessage: 'Are you sure you want to change {name} status?',
  },
  newScript: {
    id: 'app.modules.crm.newScript',
    defaultMessage: 'New script',
  },
  editScript: {
    id: 'app.modules.crm.editScript',
    defaultMessage: 'Edit script',
  },
  scriptCreatedMessage: {
    id: 'app.modules.crm.scripts.scriptCreatedMessage',
    defaultMessage: 'New script has been created',
  },
});
