import { fromJS } from 'immutable';
import moment from 'moment-timezone';

import AppService from 'services/application/AppService';

import { actionTypes, LanguageLocales } from './constants';

const locale = AppService.getAppLocale();

const initialState = fromJS({
  locale: locale || LanguageLocales.EN,
  isSidebarOpen: AppService.getAppIsSidebarOpen() || false,
  timezone: AppService.getAppSelectedTimezone() || moment.tz.guess(),
  peakSettings: {},
  profileFieldsInfo: [],
});

function intlProviderReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SETTINGS_SET_SELECTED_LOCALE:
      return state.set('locale', action.payload);
    case actionTypes.SETTINGS_SET_IS_SIDEBAR_OPEN:
      return state.set('isSidebarOpen', action.payload);
    case actionTypes.SETTINGS_SET_TIMEZONE_VIEW:
      return state.set('timezone', action.payload);
    case actionTypes.FETCH_PEAK_SYSTEM_SETTINGS:
    case actionTypes.UPDATE_PEAK_SYSTEM_SETTINGS:
      return state.set('peakSettings', fromJS(action.payload));
    case actionTypes.FETCH_PROFILE_FIELDS_INFO:
      return state.set('profileFieldsInfo', fromJS(action.payload));
    case actionTypes.UPDATE_PROFILE_FIELDS_INFO:
      const updatedData = state.get('profileFieldsInfo').map(info => {
        return info.get('id') === action.payload.id ? fromJS(action.payload) : info;
      });
      return state.set('profileFieldsInfo', updatedData);

    default:
      return state;
  }
}

export default intlProviderReducer;
