import { defineMessages } from 'react-intl';

export default defineMessages({
  tasks: {
    id: 'app.modules.crm.mainPage.tasks',
    defaultMessage: 'Tasks',
  },
  leads: {
    id: 'app.modules.crm.mainPage.leads',
    defaultMessage: 'Leads',
  },
  scripts: {
    id: 'app.modules.crm.mainPage.scripts',
    defaultMessage: 'Scripts',
  },
  campaigns: {
    id: 'app.modules.crm.mainPage.campaigns',
    defaultMessage: 'Campaigns',
  },
  templates: {
    id: 'app.modules.crm.mainPage.documentTemplates',
    defaultMessage: 'SMS & Email Template',
  },
});
