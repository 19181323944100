import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { actionTypes } from '../../constants/paymentTransactionsReport';

const initStatePaymentTransactionsReport = fromJS({
  list: [],
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
  isLoading: false,
  totals: {},
  exportPaymentTransactionsReportListLoading: false,
  exportPaymentTransactionsReportListResult: {},
});

function paymentTransactionsReportReducer(state = initStatePaymentTransactionsReport, action) {
  switch (action.type) {
    case actionTypes.FETCH_PAYMENT_TRANSACTIONS_REPORT_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_PAYMENT_TRANSACTIONS_REPORT:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta))
        .set('listLoading', false);
    case actionTypes.EXPORT_PAYMENT_TRANSACTIONS_REPORT_LIST_LOADING:
      return state.set('exportPaymentTransactionsReportListLoading', fromJS(action.payload));
    case actionTypes.EXPORT_PAYMENT_TRANSACTIONS_REPORT_LIST:
      return state.set('exportPaymentTransactionsReportListResult', fromJS(action.payload));
    case actionTypes.RESET_PAYMENT_TRANSACTIONS_EXPORT_SUCCESS:
      return state.set('exportPaymentTransactionsReportListResult', fromJS({}));
    default:
      return state;
  }
}

export default combineReducers({
  paymentTransactionsList: paymentTransactionsReportReducer,
});
