import React, { PropsWithChildren, useCallback, useState } from 'react';

export interface INavigationContext {
  showNavigation: boolean;
  toggleShowNavigation: (isShown: boolean) => void;
}

const defaultNavigationContext = {
  showNavigation: false,
  toggleShowNavigation: () => {},
};

export const NavigationContext = React.createContext<INavigationContext>(defaultNavigationContext);

export const NavigationProvider: React.FC<PropsWithChildren<any>> = ({
  children,
}: PropsWithChildren<any>): JSX.Element => {
  const isLockMode = localStorage.getItem('showNavigation');

  const [showNavigation, setShowNavigation] = useState(() =>
    isLockMode === null ? true : JSON.parse(isLockMode),
  );

  const handleShowNavigation = useCallback((isShown: boolean) => {
    localStorage.setItem('showNavigation', JSON.stringify(isShown));
    setShowNavigation(isShown);
  }, []);

  const providerValue = {
    showNavigation,
    toggleShowNavigation: handleShowNavigation,
  };

  return <NavigationContext.Provider value={providerValue}>{children}</NavigationContext.Provider>;
};
