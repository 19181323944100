import { fromJS } from 'immutable';
import { actionTypes } from 'modules/members/state/allergies/constants';

const allergiesInitState = fromJS({
  list: [],
  isLoading: false,
});

const allergiesReducer = (state = allergiesInitState, action) => {
  switch (action.type) {
    case actionTypes.SET_ALLERGIES_IS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_ALLERGIES:
    case actionTypes.UPDATE_ALLERGIES:
      return state.set('list', fromJS(action.payload));
    case actionTypes.RESET_ALLERGIES:
      return allergiesInitState;
    default:
      return state;
  }
};

export default allergiesReducer;
