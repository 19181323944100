import { fromJS } from 'immutable';
import { actionTypes } from 'common/state/userRole/constants';
import AppService from 'services/application/AppService';

const initialState = fromJS({
  role: AppService.getUserRole(),
});

export const userRoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_ROLE:
      return state.set('role', action.payload);
    case actionTypes.RESET_USER_ROLE:
      return state.set('role', null);
    default:
      return state;
  }
};
