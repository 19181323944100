import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@material-ui/core';

import { campaignTypesOptions } from 'modules/crm/constants/campaigns';
import { CampaignType } from 'common/constants/campaign';
import { ReactComponent as AddIcon } from 'img/icons/add.svg';
import { ActionItem, ActionsMenu } from 'common/components';
import messages from 'common/messages/messages';

function AddCampaignDropdown(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  const onAddNewDocument = type => {
    switch (CampaignType[type]) {
      case CampaignType.EMAIL:
      case CampaignType.PHONE:
      case CampaignType.SMS:
        navigate(`/${location.pathname.split('/')[1]}/campaigns/new?type=${CampaignType[type]}`);
        break;
      default:
        navigate(`/${location.pathname.split('/')[1]}/campaigns`);
    }
  };

  return (
    <ActionsMenu
      moreActionsBtn={
        <Button variant="contained" color="primary" startIcon={<AddIcon width={16} height={16} />}>
          <FormattedMessage {...messages.newBtn} />
        </Button>
      }
      menuAlign="left"
    >
      {Object.keys(CampaignType).map(type => (
        <ActionItem onClick={() => onAddNewDocument(type)}>
          {campaignTypesOptions.translate(type)}
        </ActionItem>
      ))}
    </ActionsMenu>
  );
}

export default AddCampaignDropdown;
