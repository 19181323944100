import { fromJS } from 'immutable';
import * as actionTypes from '../../constants/daySheetActionTypes';

const initialState = fromJS({
  list: [],
  isLoading: false,
});

export function daySheetStatusKeysReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_DS_STATUS_KEYS: {
      return state.set('list', fromJS(action.payload));
    }
    case actionTypes.GET_DS_STATUS_KEYS_LOADING: {
      return state.set('isLoading', action.payload);
    }
    case actionTypes.CLEAR_DS_STATUS_KEYS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}

export default daySheetStatusKeysReducer;
