import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, SvgIcon, Tooltip, makeStyles } from '@material-ui/core';

import { ActionItem, ActionsMenu, Button } from 'common/components/index';
import { ReactComponent as AddIcon } from 'img/icons/add.svg';
import { ReactComponent as UploadIcon } from 'img/icons/upload-solid.svg';
import { ReactComponent as CameraIcon } from 'img/icons/camera.svg';
import { ReactComponent as ImageIcon } from 'img/editor-icons/image.svg';
import { ReactComponent as HistoryIcon } from 'img/icons/clock-rotate_deprecated.svg';

import commonMessages from 'common/messages/messages';
import { snackbar } from 'common/utils/snackbarUtils';

const useStyles = makeStyles({
  actionButton: {
    '& svg': {
      width: '20px',
      height: '20px',
    },
  },
  inputFile: {
    display: 'none',
  },
});

interface IProps {
  onOpenBrowseForFilesModal: () => void;
  onOpenWebcamModal: () => void;
  onOpenDocumentsHistoryModal: () => void;
  getFileInputProps: () => any;
}

const DocumentsSectionActions = ({
  onOpenBrowseForFilesModal,
  onOpenWebcamModal,
  onOpenDocumentsHistoryModal,
  getFileInputProps,
}: IProps): JSX.Element => {
  const classes = useStyles();

  const throwNotImplementedNotification = () =>
    snackbar.warning(<FormattedMessage {...commonMessages.notImplementedYet} />);

  return (
    <Box display="flex" alignItems="center">
      <Tooltip title={<FormattedMessage {...commonMessages.historyBtn} />}>
        <Button
          type="button"
          onClick={onOpenDocumentsHistoryModal}
          className={classes.actionButton}
          hasHoverStyle
          color="primary"
        >
          <HistoryIcon />
        </Button>
      </Tooltip>

      <ActionsMenu
        icon={
          <Button hasHoverStyle color="primary">
            <AddIcon width={20} height={20} />
          </Button>
        }
      >
        <ActionItem key="0" icon={<UploadIcon />} onClick={onOpenBrowseForFilesModal}>
          <input type="file" {...getFileInputProps()} className={classes.inputFile} />
          <FormattedMessage {...commonMessages.browseForFilesBtn} />
        </ActionItem>

        <ActionItem key="1" icon={<CameraIcon />} onClick={onOpenWebcamModal}>
          <FormattedMessage {...commonMessages.takePhotoBtn} />
        </ActionItem>

        <ActionItem
          key="2"
          icon={<SvgIcon component={ImageIcon} />}
          onClick={throwNotImplementedNotification}
        >
          <FormattedMessage {...commonMessages.scanDocumentBtn} />
        </ActionItem>
      </ActionsMenu>
    </Box>
  );
};

export default DocumentsSectionActions;
