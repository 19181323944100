import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles, MenuItem } from '@material-ui/core';

import { CustomTheme } from 'common/ui/interfaces';

import { Select } from 'common/components/index';

import messages from 'common/messages/messages';

const useStyles = makeStyles((theme: CustomTheme) => ({
  countSelect: {
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      padding: theme.spacing(0.5, 4, 0.5, 1.5),
    },
    marginRight: theme.spacing(1),
  },
}));

interface IProps {
  onDelete: () => void;
  disabled: boolean;
}

const RemoveInvoiceItemController = ({ onDelete, disabled }: IProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Select
      value="1"
      onChange={count => !Number.isInteger(count) && onDelete()}
      size="small"
      className={classes.countSelect}
      disabled={disabled}
    >
      <MenuItem key="1" value="remove">
        <FormattedMessage {...messages.removeBtn} />
      </MenuItem>
      <MenuItem key="2" value="1">
        1
      </MenuItem>
    </Select>
  );
};

export default RemoveInvoiceItemController;
