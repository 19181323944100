import { fromJS } from 'immutable';

import * as actionTypes from './constants';
import { initialState, initReqStateImt } from 'common/constants/initialState';

const initialMembershipState = {
  cancelMembership: initialState,

  freezeMembership: initialState,

  freezableItems: [],
  freezableItemsLoading: false,

  freezeHistory: [],
  freezeHistoryLoading: false,

  reactivateItems: [],
  reactivateItemsLoading: false,

  cancelItems: [],
  cancelItemsLoading: false,

  subMembersList: [],
  subMembersListLoading: false,

  availableMembershipActions: initialState,

  package: initialState,

  packageInventory: initialState,
};

export default function membershipReducer(state = fromJS(initialMembershipState), action) {
  switch (action.type) {
    case actionTypes.CANCEL_PERSON_MEMBERSHIP:
      return state.setIn(['cancelMembership', 'result'], action.payload);
    case actionTypes.CANCEL_PERSON_MEMBERSHIP_LOADING:
      return state.setIn(['cancelMembership', 'isLoading'], action.payload);
    case actionTypes.RESET_CANCEL_PERSON_MEMBERSHIP:
      return state.set('cancelMembership', initReqStateImt);

    case actionTypes.FREEZE_PERSONS_MEMBERSHIP:
      return state.setIn(['freezeMembership', 'result'], action.payload);
    case actionTypes.FREEZE_PERSONS_MEMBERSHIP_LOADING:
      return state.setIn(['freezeMembership', 'isLoading'], action.payload);
    case actionTypes.FREEZE_PERSONS_MEMBERSHIP_ERROR:
      return state.setIn(['freezeMembership', 'error'], fromJS(action.payload));
    case actionTypes.RESET_FREEZE_PERSONS_MEMBERSHIP:
      return state.set('freezeMembership', initReqStateImt);

    case actionTypes.FETCH_FREEZING_ITEMS:
      return state.set('freezableItems', fromJS(action.payload));
    case actionTypes.RESET_FREEZING_ITEMS:
      return state.set('freezableItems', fromJS([]));
    case actionTypes.FETCH_FREEZING_ITEMS_LOADING:
      return state.set('freezableItemsLoading', action.payload);
    case actionTypes.FETCH_FREEZE_HISTORY:
      return state.set('freezeHistory', fromJS(action.payload));
    case actionTypes.FETCH_FREEZE_HISTORY_LOADING:
      return state.set('freezeHistoryLoading', fromJS(action.payload));

    case actionTypes.FETCH_REACTIVATE_ITEMS:
      return state.set('reactivateItems', fromJS(action.payload));
    case actionTypes.FETCH_REACTIVATE_ITEMS_LOADING:
      return state.set('reactivateItemsLoading', action.payload);
    case actionTypes.RESET_REACTIVATE_ITEMS:
      return state.set('reactivateItems', fromJS([]));

    case actionTypes.FETCH_CANCEL_ITEMS:
      return state.set('cancelItems', fromJS(action.payload));
    case actionTypes.FETCH_CANCEL_ITEMS_LOADING:
      return state.set('cancelItemsLoading', action.payload);
    case actionTypes.RESET_CANCEL_ITEMS:
      return state.set('cancelItems', fromJS([]));

    case actionTypes.FETCH_SUB_MEMBERS_LIST:
      return state.set('subMembersList', fromJS(action.payload));
    case actionTypes.FETCH_SUB_MEMBERS_LIST_LOADING:
      return state.set('subMembersListLoading', action.payload);

    case actionTypes.FETCH_AVAILABLE_MEMBERSHIP_PACKAGE_ACTIONS:
      return state.setIn(['availableMembershipActions', 'result'], fromJS(action.payload));
    case actionTypes.FETCH_AVAILABLE_MEMBERSHIP_PACKAGE_ACTIONS_LOADING:
      return state.setIn(['availableMembershipActions', 'isLoading'], action.payload);
    case actionTypes.RESET_AVAILABLE_MEMBERSHIP_PACKAGE_ACTIONS:
      return state.set('availableMembershipActions', initReqStateImt);
    case actionTypes.FETCH_MEMBERSHIP_SUCCESS:
      return state.setIn(['package', 'result'], fromJS(action.payload));
    case actionTypes.FETCH_MEMBERSHIP_LOADING:
      return state.setIn(['package', 'isLoading'], action.payload);
    case actionTypes.RESET_MEMBERSHIP:
      return state.set('package', initReqStateImt);
    case actionTypes.FETCH_PERSON_MEMBERSHIP_PACKAGE_INVENTORY_SUCCESS:
      return state.setIn(['packageInventory', 'result'], fromJS(action.payload));
    case actionTypes.FETCH_PERSON_MEMBERSHIP_PACKAGE_INVENTORY_LOADING:
      return state.setIn(['packageInventory', 'isLoading'], action.payload);
    case actionTypes.RESET_PERSON_MEMBERSHIP_PACKAGE_INVENTORY:
      return state.set('packageInventory', initReqStateImt);

    default:
      return state;
  }
}
