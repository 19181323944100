import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages';

export enum ReactivateMembershipSteps {
  PackagesSelection = 'PACKAGES_SELECTION',
  PastDues = 'PAST_DUES',
  Payment = 'PAYMENT',
}

export const ReactivateMembershipStepsLabels = {
  [ReactivateMembershipSteps.PackagesSelection]: (
    <FormattedMessage {...messages.servicesBlockTitle} />
  ),
  [ReactivateMembershipSteps.PastDues]: <FormattedMessage {...messages.pastDuesModalTitle} />,
  [ReactivateMembershipSteps.Payment]: (
    <FormattedMessage {...messages.cancelMembershipPaymentStepTitle} />
  ),
};
