import React from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@material-ui/core';

import { CustomTheme } from 'common/ui/interfaces';
import { createTheme, colors, createPalette } from 'common/ui/theme/default';
import { selectSelectedOrganizationColor } from 'modules/authentication/state/selectors';
import * as selectors from 'modules/peak-settings/state/selectors';

interface IProps {
  children: React.ReactNode;
}

const ThemeProviderWrapper = ({ children }: IProps): JSX.Element => {
  const orgPrimaryColor = useSelector(selectSelectedOrganizationColor);
  const peakPrimaryColor: string = useSelector(selectors.selectPeakPrimaryColor);

  const palette = createPalette(orgPrimaryColor || peakPrimaryColor || colors.primary);
  const theme: CustomTheme = createTheme(palette);

  return <ThemeProvider<CustomTheme> theme={theme}>{children}</ThemeProvider>;
};

export default ThemeProviderWrapper;
