import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import {
  Box,
  Typography,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Popover,
  Divider,
  withStyles,
  createStyles,
  StyledComponentProps,
  FormHelperText,
  Avatar,
  Button,
} from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';

import { CustomTheme } from 'common/ui/interfaces';

import { ReactComponent as CameraIcon } from 'img/icons/camera.svg';
import { ReactComponent as TrashIcon } from 'img/icons/trash_deprecated.svg';
import { ReactComponent as UploadIcon } from 'img/icons/upload-solid.svg';

import commonMessages from 'common/messages/messages';
import inputErrors from 'common/messages/inputErrors';
import messages from './messages';

interface IPropsAvatarCard extends StyledComponentProps {
  avatarUrl?: string;
  withPlaceholder?: boolean;
  hideAvatarActions?: boolean;
  height?: string;
  width?: string;
  initials?: string;

  onToggleWebcamModal?: () => void;
  onToggleUploadModal: () => void;
  onToggleDeleteModal: () => void;
  isLessThanTablet?: boolean | null;
  isLessThanXs?: boolean | null;

  recommended?: boolean;
  error?: boolean;
  helperText?: string;
  hideTackeAPhotoBtn?: boolean;
}

const styles = (theme: CustomTheme) =>
  createStyles({
    avatarWrapper: {
      position: 'relative',
      display: 'flex',
      flexWrap: 'wrap',
      height: (props: IPropsAvatarCard) => props.height || '100%',
      width: (props: IPropsAvatarCard) => props.width || props.height || '100%',
      paddingBottom: (props: IPropsAvatarCard) => (props.initials && !props.avatarUrl ? '100%' : 0),
      borderRadius: '3px',
      backgroundSize: 'cover',
      backgroundPosition: 'top center',
      cursor: 'pointer',
      overflow: 'hidden',
      backgroundColor: (props: IPropsAvatarCard) =>
        props.initials && !props.avatarUrl ? theme.palette.secondary.light : ``,
    },
    avatarImage: { width: '100%', height: '100%', borderRadius: 0 },
    avatarInitial: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
      fontSize: '50px',
      fontWeight: 700,
      color: theme.palette.darkBackground?.light,
    },
    editIcon: {
      position: 'absolute',
      right: theme.spacing(1),
      bottom: theme.spacing(1),
      width: 36,
      height: 36,
      cursor: 'pointer',
      background: 'rgba(50, 50, 50, 0.24)',
      borderRadius: '100%',
      padding: theme.spacing(1),
      '&.smallIcon': {
        width: 24,
        height: 24,
      },

      '&.smallIcon svg': {
        fontSize: '1rem',
      },

      '& svg': {
        fontSize: '1.5rem',
        color: theme.palette.primary.contrastText,
      },
    },
    photoArea: {
      padding: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      height: (props: IPropsAvatarCard) => props.height || '100%',
      width: (props: IPropsAvatarCard) => props.width || props.height || '100%',
      border: `1px dashed ${theme.palette.borderColor?.main}`,
      borderRadius: '5px',
      cursor: 'pointer',
      color: theme.palette.text.primary,
    },
    photoAreaTitle: {
      textAlign: 'center',
      width: '70%',
    },
    showModalBtn: {
      color: theme.palette.primary.main,
      marginTop: '12px !important',
      textTransform: 'lowercase',
    },
    anchor: {
      position: 'absolute',
      right: 0,
      bottom: '-5px',
    },
    icon: {
      display: 'inline-flex',
      alignItems: 'self-start',
      color: theme.palette.text.primary,
      opacity: '0.7',
      '& svg': {
        marginRight: '8px',
      },
    },
    warning: {
      color: theme.palette.warning.main,
    },
  });

const AvatarCard = React.memo(
  withStyles(styles)(
    ({
      avatarUrl,
      initials,
      withPlaceholder,
      onToggleUploadModal,
      onToggleWebcamModal,
      onToggleDeleteModal,
      classes,
      isLessThanTablet,
      isLessThanXs,
      recommended,
      hideAvatarActions,
      error,
      helperText,
      hideTackeAPhotoBtn,
    }: IPropsAvatarCard) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [anchorEl, setAnchorEl] = useState(null);
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const anchorRef = useRef<HTMLSpanElement>(null);

      if (withPlaceholder || !!avatarUrl) {
        const onClick = () => {
          setAnchorEl(anchorRef.current);
        };

        const onCloseMenu = () => {
          setAnchorEl(null);
        };

        const onShowUploadModal = () => {
          onToggleUploadModal();
          setAnchorEl(null);
        };

        const onShowWebcamModal = () => {
          if (onToggleWebcamModal) onToggleWebcamModal();
          setAnchorEl(null);
        };

        const onShowDeleteModal = () => {
          onToggleDeleteModal();
          setAnchorEl(null);
        };

        const isOpenPopover = Boolean(anchorEl);

        return (
          <>
            <Box className={classes?.avatarWrapper}>
              {avatarUrl && <Avatar src={avatarUrl} className={classes?.avatarImage} />}
              {initials && !avatarUrl && <Box className={classes?.avatarInitial}>{initials}</Box>}
              {hideAvatarActions || (
                <>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    className={classNames(classes?.editIcon, {
                      smallIcon: isLessThanTablet && !isLessThanXs,
                    })}
                    onClick={onClick}
                  >
                    <MoreHoriz />
                    <span ref={anchorRef} className={classes?.anchor} />
                  </Box>
                  <Popover
                    open={isOpenPopover}
                    anchorEl={anchorEl}
                    onClose={onCloseMenu}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={onCloseMenu}>
                        <MenuList role="menu">
                          <MenuItem onClick={onShowUploadModal}>
                            <div className={classNames(classes?.icon, 'menu-item')}>
                              <UploadIcon />
                              <FormattedMessage {...commonMessages.browseForFilesBtn} />
                            </div>
                          </MenuItem>

                          {!hideTackeAPhotoBtn && (
                            <MenuItem onClick={onShowWebcamModal}>
                              <div className={classNames(classes?.icon, 'menu-item')}>
                                <CameraIcon />
                                <FormattedMessage {...commonMessages.takePhotoBtn} />
                              </div>
                            </MenuItem>
                          )}
                          {Boolean(avatarUrl) && (
                            <div>
                              <Divider />
                              <MenuItem onClick={onShowDeleteModal}>
                                <div className={classNames(classes?.icon, 'menu-item')}>
                                  <TrashIcon />
                                  <FormattedMessage {...messages.avatarCardDeletePhotoTitle} />
                                </div>
                              </MenuItem>
                            </div>
                          )}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Popover>
                </>
              )}
            </Box>
            {recommended && (
              <FormHelperText className={classes?.warning}>
                <FormattedMessage {...inputErrors.recommendedFieldLabel} />
              </FormHelperText>
            )}
          </>
        );
      }

      return (
        <Box>
          <Box className={classes?.photoArea}>
            <Button
              onClick={onToggleUploadModal}
              variant="text"
              color="primary"
              type="button"
              className={`btn-text ${classes.showModalBtn}`}
            >
              <Typography variant="body1" color="inherit">
                <FormattedMessage {...commonMessages.browseForFilesBtn} />
              </Typography>
            </Button>
            {!hideTackeAPhotoBtn && (
              <Button
                onClick={onToggleWebcamModal}
                variant="text"
                color="primary"
                type="button"
                className={`btn-text ${classes.showModalBtn}`}
              >
                <Typography variant="body1" color="inherit">
                  <FormattedMessage {...commonMessages.takePhotoBtn} />
                </Typography>
              </Button>
            )}
          </Box>

          {recommended && (
            <FormHelperText className={classes?.warning}>
              <FormattedMessage {...inputErrors.recommendedFieldLabel} />
            </FormHelperText>
          )}

          {error && <FormHelperText error>{helperText}</FormHelperText>}
        </Box>
      );
    },
  ),
);

export default AvatarCard;
