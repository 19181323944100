import { List as ImmutableList } from 'immutable';

import { ImmutableObject } from 'common/state/interfaces';
import { INamedEntity, IShortPerson, IShortPersonImt } from 'common/interfaces/common';
import { EventType } from 'modules/booking/interfaces';

export interface ICrmDashboardStats {
  leadsContacted: number;
  leadAppointments: number;
  membersContacted: number;
  memberAppointments: number;
  sales: number;
  averageDaysToSell: number;
}

export interface ICrmDashboardStatsImt extends ImmutableObject<ICrmDashboardStats> {
  get<K extends keyof ICrmDashboardStats>(key: K): ICrmDashboardStats[K];
}

export enum ConversionRateStage {
  INTRO = 'Intro',
  TOUR = 'Tour',
  CLOSE = 'Close',
}

export interface IConversionRateItem {
  employee: INamedEntity;
  numberOfSales: number;
  totalAmountSold: number;
}

export type IConversionRateItemImt = ImmutableObject<IConversionRateItem>;

export interface IMembershipsSoldItem {
  title: string;
  count: number;
  id: string;
}

export interface IMembershipsSoldItemImt extends ImmutableObject<IMembershipsSoldItem> {
  get<K extends keyof IMembershipsSoldItem>(key: K): IMembershipsSoldItem[K];
}

export enum ActivityTypes {
  EMAILS = 'EMAILS',
  CALLS = 'CALLS',
  SMS = 'SMS',
}

// Appointments

export interface IAppointmentSimpleDto {
  id: string;
  type: EventType;
  date: string;
  salesperson: IShortPerson;
}

export interface IAppointmentSimpleDtoImt extends ImmutableObject<IAppointmentSimpleDto> {
  get<K extends keyof IAppointmentSimpleDto>(key: K): IAppointmentSimpleDto[K];

  get(key: 'salesperson'): IShortPersonImt;
}

// Backlog

export interface IBacklogItem {
  id: string;
  type: ActivityTypes;
  date: string;
  salesperson: IShortPerson;
  total: number;
}

export interface IBacklogItemImt extends ImmutableObject<IBacklogItem> {
  get<K extends keyof IBacklogItem>(key: K): IBacklogItem[K];

  get(key: 'salesperson'): IShortPersonImt;
}

export interface IBacklog {
  data: IBacklogItem[];
}

export interface IBacklogImt extends ImmutableObject<IBacklog> {
  get<K extends keyof IBacklog>(key: K): IBacklog[K];

  get(key: 'data'): ImmutableList<IBacklogItemImt>;
}

// Activity

export interface ICrmActivityItem {
  id: string;
  type: ActivityTypes;
  amount: number;
  date: string;
}

export type ICrmActivityItemImt = ImmutableObject<ICrmActivityItem>;

export interface ICrmActivityRecord {
  id: string;
  type: ActivityTypes;
  color?: string;
  data: ICrmActivityItem[];
}

export interface ICrmActivityRecordImt extends ImmutableObject<ICrmActivityRecord> {
  get<K extends keyof ICrmActivityRecord>(key: K): ICrmActivityRecord[K];

  get(key: 'data'): ImmutableList<ICrmActivityItemImt>;
}

export interface ICrmActivity {
  data: ICrmActivityRecord[];
}

export interface ICrmActivityImt extends ImmutableObject<ICrmActivity> {
  get<K extends keyof ICrmActivity>(key: K): ICrmActivity[K];

  get(key: 'data'): ImmutableList<ICrmActivityRecordImt>;
}
