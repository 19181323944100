// libraries
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles, Typography } from '@material-ui/core';
// custom interfaces and components
import ServicesPageWrapper from '../../../components/ServicesPageWrapper/ServicesPageWrapper';
import { ActionItem, ActionsMenu, Button, StatusLabel, Table } from 'common/components';
import { IHeadCell, ITableParams, ITableRow } from 'common/interfaces/table';
// state
import * as actions from '../../../state/fees/actions';
import * as selectors from '../../../state/fees/selectors';
// messages
import commonMessages from 'common/messages/messages';
import tableHeaders from 'common/messages/tableHeaders';
import { makeTableParams, pushQueryToUrl } from 'common/utils/http';
import { updateQueryParams } from 'common/state/queryPage-lists/actions';
import { ActiveInactiveStatus, QueryPageList } from 'common/constants';
import { FilterTypes, IFilterSettings } from 'common/interfaces/filter';
import tableFilters from 'common/messages/tableFilters';
import { FeeTypeOptions } from '../../../constants/fees';
import menuItems from 'common/messages/menuItems';
import { ReactComponent as XDeleteIcon } from 'img/icons/times.svg';
import { ReactComponent as CheckIcon } from 'img/icons/check.svg';
import { ReactComponent as EditIcon } from 'img/icons/edit.svg';
import { ReactComponent as AddIcon } from 'img/icons/add.svg';

import { IFeeImt } from '../../../interfaces/fees';
import FeeChangeStatusModal from './FeeChangeStatusModal';
import { useAppDispatch } from 'store/hooks';

const headerOptions: IHeadCell[] = [
  {
    id: 'title',
    label: <FormattedMessage {...tableHeaders.name} />,
    sort: true,
    padding: 'none',
  },
  {
    id: 'type',
    label: <FormattedMessage {...tableHeaders.type} />,
    sort: true,
  },
  {
    id: 'active',
    label: <FormattedMessage {...tableHeaders.status} />,
    sort: true,
  },
  {
    id: 'actions',
    label: <FormattedMessage {...tableHeaders.actions} />,
    sort: false,
    align: 'center',
    padding: 'none',
  },
];

const useStyles = makeStyles({
  icon: {
    width: 16,
    height: 16,
  },
});

const FeesList: React.FC = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();

  // constants

  const filterSettings = useMemo(
    (): IFilterSettings[] => [
      {
        name: 'active',
        title: <FormattedMessage {...tableFilters.status} />,
        type: FilterTypes.SINGLE,
        options: ActiveInactiveStatus.map(status => ({
          key: status.key,
          label: status.label,
          value: status.value,
        })),
        defaultValue: ActiveInactiveStatus[0],
      },
      {
        name: 'type',
        title: <FormattedMessage {...tableFilters.type} />,
        type: FilterTypes.SINGLE,
        options: FeeTypeOptions.getFilterOptions(),
      },
    ],
    [],
  );

  // local state

  const [changeStatusFee, setChangeStatusFee] = useState(null);
  const [tableParams, setTableParams] = useState<ITableParams>(() =>
    makeTableParams(filterSettings, location.search, { orderBy: 'title' }),
  );

  // global state
  const dispatch = useAppDispatch();

  const fees = useSelector(selectors.selectFeesList);
  const feesMeta = useSelector(selectors.selectFeesListMeta);
  const isFeesListLoading = useSelector(selectors.selectFeesListLoading);

  const classes = useStyles();

  // updates query params for page in redux
  const updateQueryFunction = useCallback(
    query => dispatch(updateQueryParams({ page: QueryPageList.FEES, query })),
    [dispatch],
  );

  const onChangeParams = useCallback((updParams: ITableParams): void => {
    setTableParams(params => ({ ...params, ...updParams }));
  }, []);

  const onEditFee = useCallback((id: string): void => navigate(`/services/fees/${id}`), [navigate]);

  const onDeactivationSuccess = useCallback(() => {
    dispatch(actions.fetchFeesList(tableParams));
    setChangeStatusFee(null);
  }, [dispatch, tableParams]);

  // effects

  useEffect(() => {
    dispatch(actions.fetchFeesList(tableParams));
  }, [dispatch, tableParams]);

  useEffect(() => {
    return () => {
      dispatch(actions.resetFeesList());
    };
  }, [dispatch]);

  useEffect(() => {
    pushQueryToUrl(navigate, tableParams, updateQueryFunction, true);
  }, [navigate, tableParams, updateQueryFunction]);

  // renders
  const createRows = (): ITableRow[] =>
    fees
      .map(
        (fee: IFeeImt): ITableRow => ({
          id: fee.get('id'),
          cells: [
            {
              padding: 'none',
              label: fee.get('title'),
              align: 'left',
              variant: 'h5',
            },
            {
              label: '',
              cellComponent: (
                <Typography variant="body1">{FeeTypeOptions.translate(fee.get('type'))}</Typography>
              ),
            },
            {
              label: 'status',
              cellComponent: <StatusLabel isActive={fee.get('active')} />,
            },
            {
              label: 'menu',
              align: 'center',
              padding: 'none',
              width: '54px',
              cellComponent: (
                <ActionsMenu horizontal tableActionsMode>
                  <ActionItem
                    key="edit"
                    icon={<EditIcon className={classes.icon} />}
                    onClick={() => onEditFee(fee.get('id'))}
                  >
                    <FormattedMessage {...menuItems.edit} />
                  </ActionItem>
                  <ActionItem
                    key="2"
                    icon={
                      fee.get('active') ? (
                        <XDeleteIcon className={classes.icon} />
                      ) : (
                        <CheckIcon className={classes.icon} />
                      )
                    }
                    onClick={() => setChangeStatusFee(fee)}
                  >
                    {fee.get('active') ? (
                      <FormattedMessage {...menuItems.deactivate} />
                    ) : (
                      <FormattedMessage {...menuItems.activate} />
                    )}
                  </ActionItem>
                </ActionsMenu>
              ),
            },
          ],
        }),
      )
      .toJS();

  return (
    <ServicesPageWrapper titleMessage={commonMessages.fees} backRedirectLink="/services">
      <>
        <Table
          addButton={
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon className={classes.icon} />}
              onClick={() => {
                navigate('/services/fees/new');
              }}
            >
              <FormattedMessage {...commonMessages.newBtn} />
            </Button>
          }
          suppressSmartBehavior
          suppressFiltersMobilePadding
          showPerPageSelect
          headerOptions={headerOptions}
          isLoading={isFeesListLoading}
          rows={createRows()}
          totalRows={feesMeta.get('total')}
          onChangeParams={onChangeParams}
          filters={filterSettings}
          tableParams={tableParams}
        />
        <FeeChangeStatusModal fee={changeStatusFee} onDone={onDeactivationSuccess} />
      </>
    </ServicesPageWrapper>
  );
};

export default FeesList;
