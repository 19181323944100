import React from 'react';
import { Avatar, Grid, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import inputLabels from 'common/messages/inputLabels';
import { IssueFrequencyType, IssueFrequencyTypes } from 'modules/services/constants/packages';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { DialogComponent, FieldInfo, LoadingBackdrop } from 'common/components';
import { ReactComponent as DefaultIcon } from 'img/icons/default-icon.svg';

export interface IInventoryInfoImt {
  size: number;

  get(key: 'expirationDate'): string;

  get(key: 'title'): string;

  get(key: 'barcode'): string;

  get(key: 'category'): string;

  get(key: 'imageUrl'): string;

  get(key: 'description'): string;

  get(key: 'frequency'): IssueFrequencyType;
}

interface IInventoryViewModalProps<T> {
  onClose: () => void;
  isOpen: boolean;
  inventoryInfo: T;
  isLoading?: boolean;
}

const useStyles = makeStyles({
  productImage: {
    width: 176,
    height: 176,
  },
});

const InventoryViewModal = <T extends IInventoryInfoImt>(props: IInventoryViewModalProps<T>) => {
  const { onClose, isOpen, inventoryInfo, isLoading } = props;

  const [timezoneMoment] = useTimezoneMoment();

  const classes = useStyles();

  const expirationDate = inventoryInfo?.get('expirationDate');
  const expirationDateWithTimezone = expirationDate
    ? timezoneMoment(expirationDate).format(DEFAULT_DATE_TIME_FORMAT)
    : '-';

  return (
    <DialogComponent
      hideFooterButtons
      title={inventoryInfo?.get('title')}
      isOpen={isOpen}
      onClose={onClose}
    >
      {!!inventoryInfo?.size && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FieldInfo
              label={<FormattedMessage {...inputLabels.expirationDate} />}
              description={expirationDateWithTimezone}
            />
          </Grid>

          <Grid item xs={6}>
            <FieldInfo
              label={<FormattedMessage {...inputLabels.limitation} />}
              description={IssueFrequencyTypes.translate(inventoryInfo.get('frequency'))}
            />
          </Grid>

          <Grid item xs={6}>
            <FieldInfo
              label={<FormattedMessage {...inputLabels.barcode} />}
              description={inventoryInfo.get('barcode') || '-'}
            />
          </Grid>

          <Grid item xs={6}>
            <FieldInfo
              label={<FormattedMessage {...inputLabels.categories} />}
              description={inventoryInfo.get('category') || '-'}
            />
          </Grid>

          <Grid item xs={6}>
            <Avatar
              className={classes.productImage}
              src={inventoryInfo.get('imageUrl')}
              alt={inventoryInfo.get('title')}
              variant="square"
            >
              <DefaultIcon />
            </Avatar>
          </Grid>

          <Grid item xs={12}>
            <FieldInfo
              label={<FormattedMessage {...inputLabels.description} />}
              description={inventoryInfo.get('description')}
              hasNotEllipsesDescription
            />
          </Grid>
        </Grid>
      )}

      <LoadingBackdrop isLoading={isLoading} />
    </DialogComponent>
  );
};

export default InventoryViewModal;
