import { defineMessages } from 'react-intl';

export default defineMessages({
  corporationsTitle: {
    id: 'app.modules.corporations.Corporations.corporationsTitle',
    defaultMessage: 'Corporations',
  },
  // Steps
  generalInformationLabel: {
    id: 'app.modules.corporations.newCorporation.step.generalInformationLabel',
    defaultMessage: 'General information',
  },
  bankInfoLabel: {
    id: 'app.modules.corporations.newCorporation.step.bankInfoLabel',
    defaultMessage: 'Bank Info',
  },
  processorConfigInfoLabel: {
    id: 'app.modules.corporations.newCorporation.step.processorConfigInfoLabel',
    defaultMessage: 'Processor Configuration',
  },
  feeRatesLabel: {
    id: 'app.modules.corporations.newCorporation.step.feeRatesLabel',
    defaultMessage: 'Fee Rates',
  },
  taxpayerLabel: {
    id: 'app.modules.corporations.newCorporation.step.taxpayerLabel',
    defaultMessage: 'Taxpayer',
  },
  // New corporation

  newCorporationTitle: {
    id: 'app.modules.corporations.newCorporation.title',
    defaultMessage: 'New Corporation',
  },

  // Edit corporation

  editCorporationTitle: {
    id: 'app.modules.corporations.editCorporation.title',
    defaultMessage: 'Edit Corporation',
  },
  // Bodies
  noteForCreateBody: {
    id: 'app.modules.corporations.newCorporation.bodies.noteForCreateBody',
    defaultMessage:
      "Owner account will be created with full rights for managing organization and it's clubs. Note: Owner will be required to create password on first sign-in",
  },
});
