import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { List as ImmutableList } from 'immutable';
import { Box } from '@material-ui/core';

import { IScriptDetailedImt } from 'modules/crm/interfaces/scripts';
import { ICampaignForm } from 'modules/crm/interfaces/campaigns';
import { TDocumentTemplateListItemImt } from 'common/interfaces/dictionary';
import { ActionResult, DictionaryList } from 'common/constants';

import { LoadingBackdrop } from 'common/components';
import { RouteBackground } from 'components';
import { CampaignForm } from 'modules/crm/components';

import { selectDictionaryList } from 'common/state/dictionary/selectors';

import { fetchDictionaryList } from 'common/state/dictionary/actions';
import { fetchServicesList } from 'modules/services/state/services/actions';

import * as campaignSelectors from 'modules/crm/state/campaigns/selectors';
import * as campaignActions from 'modules/crm/state/campaigns/actions';
import * as scriptsSelectors from 'modules/crm/state/scripts/selectors';
import * as scriptsActions from 'modules/crm/state/scripts/actions';

import { useAppDispatch } from 'store/hooks';

import { CrmSubModules, PeakModules } from 'common/constants/peakModules';
import { TemplatePurpose, TemplateTypes } from 'common/constants/documentTemplate';
import { ICampaignDtoImt } from 'common/interfaces/campaign';
import { CampaignType } from 'common/constants/campaign';
import { CRMTemplatesRoutes } from 'modules/crm/routes';
import { PtCrmRoutes } from 'modules/personal-training-crm/routes';
import { transformCampaignFormValuesToDTO } from 'modules/crm/components/CampaignForm/utils';

const EditCampaign = (): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const campaign: ICampaignDtoImt = useSelector(campaignSelectors.selectCampaign());
  const scripts: ImmutableList<IScriptDetailedImt> = useSelector(scriptsSelectors.selectScripts());
  const documentTemplates: ImmutableList<TDocumentTemplateListItemImt> = useSelector(
    selectDictionaryList(DictionaryList.DOCUMENT_TEMPLATES),
  );
  const isCampaignLoading: boolean = useSelector(campaignSelectors.selectCampaignLoading());
  const isCampaignUpdating: boolean = useSelector(campaignSelectors.selectCampaignUpdating());
  const updateCampaignActionResult: ActionResult = useSelector(
    campaignSelectors.selectUpdateCampaignActionResult(),
  );

  const { id: campaignId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const module =
    location.pathname.indexOf('personal-training-crm') === -1
      ? PeakModules.Crm
      : PeakModules.PersonalTrainingCrm;

  // lifecycle

  useEffect(() => {
    dispatch(campaignActions.fetchCampaignById(campaignId, null, module));
    dispatch(scriptsActions.fetchActiveScripts(module));
    dispatch(fetchServicesList());
    dispatch(fetchDictionaryList(DictionaryList.CAME_FROM, { module: CrmSubModules.Campaigns }));
  }, [campaignId, dispatch, module]);

  useEffect(() => {
    return () => {
      dispatch(campaignActions.resetCampaignForm());
    };
  }, [dispatch]);

  useEffect(() => {
    if (updateCampaignActionResult === ActionResult.SUCCESS_ACTION) {
      navigate(
        `/${module === PeakModules.Crm ? CRMTemplatesRoutes.CRM : PtCrmRoutes.PT_CRM}/${
          CRMTemplatesRoutes.CAMPAIGNS
        }`,
      );
    }
  }, [module, navigate, updateCampaignActionResult]);

  // handlers

  const handleSubmitForm = useCallback(
    (formValues: ICampaignForm): void => {
      dispatch(
        campaignActions.updateCampaign(
          campaignId,
          transformCampaignFormValuesToDTO(formValues),
          module,
        ),
      );
    },
    [campaignId, dispatch, module],
  );

  const handleCampaignTypeChange = useCallback(
    (type: CampaignType): void => {
      if (type === CampaignType.PHONE) return;

      dispatch(
        fetchDictionaryList(DictionaryList.DOCUMENT_TEMPLATES, {
          type: type === CampaignType.EMAIL ? TemplateTypes.Email : TemplateTypes.SMS,
          purpose: TemplatePurpose.CampaignTemplate,
          module: PeakModules.Crm,
        }),
      );
    },
    [dispatch],
  );

  return (
    <RouteBackground>
      <Box display="flex" flexDirection="column" height="100%" pt={1}>
        <CampaignForm
          campaign={campaign}
          scripts={scripts}
          documentTemplates={documentTemplates}
          onCampaignTypeChange={handleCampaignTypeChange}
          onSubmit={handleSubmitForm}
          isLoading={isCampaignUpdating}
        />

        <LoadingBackdrop isLoading={isCampaignLoading} />
      </Box>
    </RouteBackground>
  );
};

export default EditCampaign;
