import { MutableRefObject, useRef } from 'react';
import useComponentDidUpdate from './useComponentDidUpdate';

interface IScrollStateProps {
  scrollRef: MutableRefObject<any>;
}

const useScrollIntoView = (show: boolean): IScrollStateProps => {
  const scrollRef = useRef(null);

  useComponentDidUpdate(() => {
    if (show && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollRef, show]);

  return {
    scrollRef,
  };
};

export default useScrollIntoView;
