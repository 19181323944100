import axios from 'axios';
import { ITableParams } from 'common/interfaces/table';
import { ISuccessResponse } from '../../common/interfaces/http';
import {
  IApiCommissionRulesHistory,
  ICommissionRule,
  IReqCommissionRule,
  IReqPutCommissionRule,
} from 'modules/day-sheet/interfaces/commissionRule';
import ApiService from './ApiService';

export default class CommissionRuleService extends ApiService {
  public getCommissionRules(requestOptions?: ITableParams): Promise<ICommissionRule[]> {
    const params = this.getSearchFromTableParams(requestOptions);

    if (params.rangeStartDate) {
      params.rangeStartDate = String(params.rangeStartDate).substring(0, 10);
    }

    if (params.rangeEndDate) {
      params.rangeEndDate = String(params.rangeEndDate).substring(0, 10);
    }

    return axios.get('/api/v1/commission-rules', { params });
  }

  public getCommissionRule(id: string): Promise<ICommissionRule> {
    return axios.get(`/api/v1/commission-rules/${id}`);
  }

  public getCommissionRulesHistory(
    requestOptions?: ITableParams,
  ): Promise<IApiCommissionRulesHistory[]> {
    const params = this.getSearchFromTableParams(requestOptions);

    if (params.rangeStartDate) {
      params.rangeStartDate = String(params.rangeStartDate).substring(0, 10);
    }

    if (params.rangeEndDate) {
      params.rangeEndDate = String(params.rangeEndDate).substring(0, 10);
    }

    return axios.get('/api/v1/commission-rules/history', { params });
  }

  public postCommissionRule(commissionRule: IReqCommissionRule): Promise<ISuccessResponse> {
    return axios.post('/api/v1/commission-rules', commissionRule);
  }

  public putCommissionRule(
    id: string,
    commissionRule: IReqPutCommissionRule,
  ): Promise<ISuccessResponse> {
    return axios.put(`/api/v1/commission-rules/${id}`, commissionRule);
  }

  public deleteCommissionRule(id: string): Promise<ISuccessResponse> {
    return axios.delete(`/api/v1/commission-rules/${id}`);
  }
}
