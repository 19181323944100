import { defineMessages } from 'react-intl';

export default defineMessages({
  notInSystem: {
    id: 'app.common.components.searchUserAutocomplete.notInSystem',
    defaultMessage: 'Not in System',
  },
  noDataLabel: {
    id: 'app.common.components.searchUserAutocomplete.noDataLabel',
    defaultMessage: 'No Data',
  },
  searchSalesperson: {
    id: 'app.common.components.searchUserAutocomplete.searchSalesperson',
    defaultMessage: 'Search salesperson',
  },
  searchMembers: {
    id: 'app.common.components.searchUserAutocomplete.searchMembers',
    defaultMessage: 'Search Members',
  },
});
