import React from 'react';
import { Box, FormHelperText } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import { DraftEditor, EditorMenu } from 'common/components';
import EditorTag from './EditorTag';
import EditorSignature from './EditorSignature';
import { regExp } from 'common/constants';

const handleTagStrategy = (contentBlock, callback) => {
  const text = contentBlock.getText();

  let matchArr;
  let start;
  // eslint-disable-next-line no-cond-assign
  while ((matchArr = regExp.DOCUMENT_TAG_REGEX.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
};

const handleSignatureStrategy = (contentBlock, callback) => {
  const text = contentBlock.getText();

  let matchArr;
  let start;
  // eslint-disable-next-line no-cond-assign
  while ((matchArr = regExp.DOCUMENT_SIGNATURE_REGEX.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
};

export const decorators = [
  {
    strategy: handleTagStrategy,
    component: EditorTag,
  },
  {
    strategy: handleSignatureStrategy,
    component: EditorSignature,
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editor: {
      marginBottom: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflow: 'hidden',

      '& .draftEditorInner': {
        flex: 1,
        padding: theme.spacing(1, 2, 2, 2),
        overflow: 'auto',
        '& .DraftEditor-root': {
          height: '95%',
        },
      },
    },
  }),
);

interface IWaiverEditorProps {
  editorState: any;
  hasError?: boolean;
  helperText?: string;
  onChangeEditorState: (state) => void;
  onBlur?: () => void;
  hasHiddenAddSignatureMenu?: boolean;
  hasHiddenAddContractLinkMenu?: boolean;
  hasHiddenAddContractNameMenu?: boolean;
  hasHiddenAddWaiverLinkMenu?: boolean;
  hasHiddenAddWaiverNameMenu?: boolean;
}

const TemplateEditor = (props: IWaiverEditorProps): JSX.Element => {
  const classes = useStyles();
  const {
    editorState,
    onChangeEditorState,
    onBlur,
    hasError,
    helperText,
    hasHiddenAddSignatureMenu,
    hasHiddenAddContractLinkMenu,
    hasHiddenAddContractNameMenu,
    hasHiddenAddWaiverLinkMenu,
    hasHiddenAddWaiverNameMenu,
  } = props;

  return (
    <Box className={classes.editor}>
      <DraftEditor
        editorState={editorState}
        onEditorStateChange={onChangeEditorState}
        decorators={decorators}
        toolbarCustomButtons={[
          <EditorMenu
            hasHiddenAddContractLinkMenu={hasHiddenAddContractLinkMenu}
            hasHiddenAddContractNameMenu={hasHiddenAddContractNameMenu}
            hasHiddenAddWaiverLinkMenu={hasHiddenAddWaiverLinkMenu}
            hasHiddenAddWaiverNameMenu={hasHiddenAddWaiverNameMenu}
            hasHiddenAddSignatureMenu={hasHiddenAddSignatureMenu}
            hasHiddenAddSignedContractMenu
            hasHiddenAddSignedWaiversMenu
            hasHiddenAddUnsignedContractMenu
            hasHiddenAddUnsignedWaiversMenu
          />,
        ]}
        hasError={hasError}
        onBlur={onBlur}
      />
      {hasError && <FormHelperText error={hasError}>{helperText}</FormHelperText>}
    </Box>
  );
};

export default TemplateEditor;
