import { createAction } from '@reduxjs/toolkit';

import Services from 'services/network';

import { IBookingEvent } from 'modules/booking/interfaces';
import { GeneralThunkAction } from 'common/state/interfaces';

import { enqueueErrorNotification } from 'common/state/notifications/actions';

import { actionTypes } from 'common/state/newPerson/scheduleTour/constants';
import { ActionResult } from 'common/constants';
import { PeakModules } from 'common/constants/peakModules';

const setIsLoading = createAction<boolean>(actionTypes.SET_IS_LOADING);
const saveTourActionResult = createAction<ActionResult>(actionTypes.SAVE_TOUR_ACTION_RESULT);
export const resetSaveTourActionResult = createAction(actionTypes.RESET_SAVE_TOUR_ACTION_RESULT);

export const saveTourStepThunk = (
  data: IBookingEvent,
  personId: string,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(setIsLoading(true));

    try {
      switch (module) {
        case PeakModules.FrontDesk:
          await Services.FrontDesk.createPersonBookATour(personId, data);
          break;
        case PeakModules.Crm:
          await Services.Leads.createLeadAppointment(personId, data);
          break;
        case PeakModules.PersonalTrainingCrm:
          await Services.PTLeads.createLeadAppointment(personId, data);
          break;
        case PeakModules.Members:
          await Services.Members.createMemberAppointment(personId, data);
          break;
        default:
      }

      dispatch(saveTourActionResult(ActionResult.SUCCESS_ACTION));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
