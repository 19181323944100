import SubscribingService from 'services/webHidApi/SubscribingService';
import { IDeviseIds } from 'common/interfaces/webHid';

const { hid } = navigator as Navigator & HID;

export default class WebHidApiService extends SubscribingService {
  constructor() {
    super();
    if (hid) {
      hid.addEventListener('connect', this.handleConnectDevice.bind(this));
      hid.addEventListener('disconnect', this.handleDisconnectDevice.bind(this));
    }
  }

  // overridden methods
  // eslint-disable-next-line
  protected handleConnectMagneticStripeScanner(e: HIDConnectionEvent) {}

  // eslint-disable-next-line
  protected handleConnectBarcodeScanner(e: HIDConnectionEvent) {}

  // eslint-disable-next-line
  protected handleConnectCashDrawer(e: HIDConnectionEvent) {}

  // eslint-disable-next-line
  protected handleDisconnectMagneticStripeScanner(e: HIDConnectionEvent) {}

  // eslint-disable-next-line
  protected handleDisconnectBarcodeScanner(e: HIDConnectionEvent) {}

  // eslint-disable-next-line
  protected handleDisconnectCashDrawer(e: HIDConnectionEvent) {}

  protected handleConnectDevice(e: HIDConnectionEvent) {
    this.handleConnectMagneticStripeScanner(e);
    this.handleConnectBarcodeScanner(e);
    this.handleConnectCashDrawer(e);
  }

  protected handleDisconnectDevice(e: HIDConnectionEvent) {
    this.handleDisconnectMagneticStripeScanner(e);
    this.handleDisconnectBarcodeScanner(e);
    this.handleDisconnectCashDrawer(e);
  }

  protected removeDevice = (devices: HIDDevice[], device: HIDDevice): HIDDevice | null => {
    let removedDevice: HIDDevice;
    for (let i = 0, ln = devices.length; i < ln; i += 1) {
      const { vendorId, productId } = devices[i];

      if (vendorId === device.vendorId && productId === device.productId) {
        devices.splice(i, 1);
        // eslint-disable-next-line no-param-reassign
        device.oninputreport = null;
        removedDevice = device;
        return removedDevice;
      }
    }
    return null;
  };

  protected addDevice = (
    connectedDevices: HIDDevice[],
    filterDevices: IDeviseIds[],
    device: HIDDevice,
  ): HIDDevice | null => {
    let addedDevice: HIDDevice;
    for (let i = 0, ln = filterDevices.length; i < ln; i += 1) {
      const { vendorId, productId } = filterDevices[i];

      if (vendorId === device.vendorId && productId === device.productId) {
        connectedDevices.push(device);

        addedDevice = device;
        return addedDevice;
      }
    }
    return null;
  };

  protected sendReportToDevice = (device: HIDDevice, reportId: number, command: Uint8Array) => {
    device.sendReport(reportId, command);
  };

  protected openHidDevice = async (
    reportCallback: ((e: HIDInputReportEvent) => void) | null,
    device: HIDDevice,
  ) => {
    if (device.opened) {
      return undefined;
    }

    await device.open();

    // eslint-disable-next-line no-param-reassign
    device.oninputreport = (e: HIDInputReportEvent) => reportCallback && reportCallback(e);

    return device;
  };

  protected requestPairScanner = async (filters: IDeviseIds[]) => {
    if (!hid) {
      return undefined;
    }

    const selected = await hid.requestDevice({ filters });

    if (!selected || selected.length === 0) {
      return undefined;
    }

    const [device] = selected;

    return device;
  };

  protected lookingForDevices = async (filterDevices: IDeviseIds[]) => {
    const devices: { openedDevices: HIDDevice[]; connectedDevices: HIDDevice[] } = {
      openedDevices: [],
      connectedDevices: [],
    };

    if (!hid) {
      return devices;
    }

    const allDevices: HIDDevice[] = await hid.getDevices();

    const { openedDevices, connectedDevices } = devices;

    for (let i = 0, ln = allDevices.length; i < ln; i += 1) {
      const device = allDevices[i];
      for (let j = 0, lng = filterDevices.length; j < lng; j += 1) {
        const { productId, vendorId } = filterDevices[j];
        if (productId === device.productId && vendorId === device.vendorId) {
          if (device.opened) {
            openedDevices.push(device);
          } else {
            connectedDevices.push(device);
          }
        }
      }
    }

    return devices;
  };
}
