import React from 'react';
import { Box, ListItem, makeStyles, Typography, Avatar } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

import useTimezoneMoment from 'common/hooks/useTimezoneMoment';

import { IReminderRecentImt } from 'common/components/PersonProfile/interfaces';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'block',
    padding: theme.spacing(0, 2, 0, 0),
    cursor: 'pointer',
  },
  itemInner: {
    borderTop: '1px solid #ededed',
    padding: theme.spacing(1.5, 0, 2, 0),
  },
  avatar: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  firstNameText: {
    marginRight: theme.spacing(0.5),
  },
  text: {
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    opacity: 0.7,
    paddingLeft: theme.spacing(3),
    paddingRight: '50px',
    wordWrap: 'break-word',
    lineHeight: 1.33,
  },
}));

interface IProps {
  reminder: IReminderRecentImt;
  onClick: (reminderItem: IReminderRecentImt) => void;
}

const ReminderItem = React.memo(({ reminder, onClick }: IProps) => {
  const [timezoneMoment] = useTimezoneMoment();

  const classes = useStyles();

  const firstName = reminder.getIn(['salesperson', 'firstName']);
  const lastName = reminder.getIn(['salesperson', 'lastName']);
  const imageUrl = reminder.getIn(['salesperson', 'imageUrl']);

  return (
    <ListItem className={classes.root} onClick={() => onClick(reminder)}>
      <Box className={classes.itemInner}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" justifyContent="flex-start">
            <Avatar className={classes.avatar} alt={firstName} src={imageUrl} />
            <Typography component="span" variant="body2" className={classes.firstNameText}>
              {firstName}
            </Typography>
            <Typography component="span" variant="body2">
              {lastName}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography variant="body2" color="textSecondary">
              {timezoneMoment(
                `${reminder.get('dueDate')} ${reminder.get('dueTime')}`,
                'YYYY-MM-DD HH:mm',
                true,
              ).format(DEFAULT_DATE_TIME_FORMAT)}
            </Typography>
          </Box>
        </Box>
        <Box pt="4px">
          <Typography component="p" variant="body2" className={classes.text}>
            {reminder.get('text')}
          </Typography>
        </Box>
      </Box>
    </ListItem>
  );
});

export default ReminderItem;
