import { createSelector } from 'reselect';
import { AppStoreTypeImt } from 'store';
import { IEmployeeProfileImt } from 'common/interfaces/employee';

// Roles
const selectRolesDomain = (state: AppStoreTypeImt) => state.getIn(['employeesModule', 'roles']);

const selectRoles = createSelector(selectRolesDomain, state => state.get('list'));

const selectRolesLoading = () =>
  createSelector(selectRolesDomain, state => state.get('listLoading'));

// Employees list
const selectEmployeesDomain = (state: AppStoreTypeImt) => {
  return state.getIn(['employeesModule', 'employees']);
};

const selectEmployees = () => createSelector(selectEmployeesDomain, state => state.get('list'));

const selectEmployeesMeta = () => createSelector(selectEmployeesDomain, state => state.get('meta'));

const selectEmployeesLoading = () =>
  createSelector(selectEmployeesDomain, state => state.get('listLoading'));

const selectEmployeeChangeStatusLoading = () =>
  createSelector(selectEmployeesDomain, state => state.get('employeeChangeStatusLoading'));

const selectEmployeeChangeStatusResult = () =>
  createSelector(selectEmployeesDomain, state => state.get('employeeChangeStatusResult'));

// Create employee
const selectEmployeeCreateFormDomain = (state: AppStoreTypeImt) =>
  state.getIn(['employeesModule', 'createEmployeeForm']);

const selectCreatedEmployee = () =>
  createSelector(selectEmployeeCreateFormDomain, state => state.get('createdEmployee'));

const selectEmployeeCreating = createSelector(selectEmployeeCreateFormDomain, state =>
  state.get('employeeCreating'),
);

// Update employee
const selectEmployeeUpdateFormDomain = ['employeesModule', 'updateEmployeeForm'];

const selectEmployee = (state): IEmployeeProfileImt | null =>
  state.getIn([...selectEmployeeUpdateFormDomain, 'employee']);

const selectEmployeeLoading = (state): boolean =>
  state.getIn([...selectEmployeeUpdateFormDomain, 'employeeLoading']);

const selectEmployeeUpdating = state =>
  state.getIn([...selectEmployeeUpdateFormDomain, 'employeeUpdating']);

const selectUpdatedEmployee = state =>
  state.getIn([...selectEmployeeUpdateFormDomain, 'updatedEmployee']);

const selectUpdateEmployeeError = state =>
  state.getIn([...selectEmployeeUpdateFormDomain, 'updateEmployeeError']);

export {
  // Roles
  selectRolesLoading,
  selectRoles,
  // Employee list
  selectEmployeesLoading,
  selectEmployeeChangeStatusLoading,
  selectEmployeeChangeStatusResult,
  selectEmployees,
  selectEmployeesMeta,
  // Create employee
  selectCreatedEmployee,
  selectEmployeeCreating,
  // Update employee
  selectEmployee,
  selectEmployeeLoading,
  selectUpdatedEmployee,
  selectEmployeeUpdating,
  selectUpdateEmployeeError,
};
