import * as yup from 'yup';
import { dateSchema, futureDateSchema } from 'common/validationSchemas/dateSchemas';
import moment from 'moment-timezone';
import { getRequiredMessage, endTimeErrorMessage } from 'common/constants/globalConstants';
import { availabilityValidationSchema } from 'common/validationSchemas/dayTimeAvailabilitySchema';
import inputErrors from 'common/messages/inputErrors';

export const AvailabilitySchema = yup.object().shape({
  startDate: dateSchema.required(getRequiredMessage),
  endDate: yup.string().when({
    is: value => !!value,
    then: futureDateSchema.test('endDate', endTimeErrorMessage, function(endDate) {
      return !this.parent.startDate || moment(this.parent.startDate).isBefore(endDate);
    }),
    otherwise: yup.string().nullable(),
  }),
  clubs: yup
    .array()
    .min(1, getRequiredMessage)
    .required(getRequiredMessage),
  weekdays: yup
    .array()
    .of(yup.object().shape({}))
    .min(1, () => inputErrors.weekdaysMinLengthError),
  dayTimeAvails: yup.array().of(availabilityValidationSchema),
});

export const ResourceValidationSchema = yup.object().shape({
  label: yup
    .string()
    .max(32, 'Name must be 32 characters or less')
    .required(getRequiredMessage),
  employee: yup.object().nullable(),
  resourceTag: yup
    .array()
    .min(1, getRequiredMessage)
    .required(getRequiredMessage),
  eventTag: yup
    .array()
    .min(1, getRequiredMessage)
    .required(getRequiredMessage),
  availability: yup.array().of(AvailabilitySchema),
});
