import { useCallback, useState } from 'react';

type IBrowsersOpenFullScreenFunctions = HTMLElement & {
  mozRequestFullScreen(): Promise<void>;
  webkitRequestFullscreen(): Promise<void>;
  msRequestFullscreen(): Promise<void>;
};

type IBrowsersFullScreenExitFunctions = Document & {
  mozCancelFullScreen(): Promise<void>;
  webkitExitFullscreen(): Promise<void>;
  msExitFullscreen(): Promise<void>;
};

const openFullScreen = async () => {
  const documentElement = document.documentElement as IBrowsersOpenFullScreenFunctions;

  if (documentElement.requestFullscreen) {
    await documentElement.requestFullscreen();
  } else if (documentElement.webkitRequestFullscreen) {
    /* Safari */
    await documentElement.webkitRequestFullscreen();
  } else if (documentElement.msRequestFullscreen) {
    /* IE11 */
    await documentElement.msRequestFullscreen();
  }
};

const closeFullScreen = async () => {
  const documentExitFunctions = document as IBrowsersFullScreenExitFunctions;
  if (!document.fullscreenElement) return;

  if (documentExitFunctions.exitFullscreen) {
    await documentExitFunctions.exitFullscreen();
  } else if (documentExitFunctions.webkitExitFullscreen) {
    /* Safari */
    await documentExitFunctions.webkitExitFullscreen();
  } else if (documentExitFunctions.msExitFullscreen) {
    /* IE11 */
    await documentExitFunctions.msExitFullscreen();
  }
};

const useToggleFullScreen = (): {
  isFullScreenMode: boolean;
  toggleFullScreenMode: (isFullScreenMode: boolean) => Promise<void>;
} => {
  const [isFullScreenMode, setIsFullScreenMode] = useState(false);

  const toggleFullScreenMode = useCallback(async isFullScreen => {
    setIsFullScreenMode(isFullScreen);

    if (isFullScreen) {
      await openFullScreen();
    } else {
      await closeFullScreen();
    }
  }, []);

  return { isFullScreenMode, toggleFullScreenMode };
};

export default useToggleFullScreen;
