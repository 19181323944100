import { List as ImmutableList } from 'immutable';
import { AppStoreTypeImt } from 'store';
import { IImmutablePrimaryMemberInfo } from 'common/components/PersonProfile/interfaces';
import { IShortPackageImt } from 'common/interfaces/membership';
import { ActionResult } from 'common/constants/globalConstants';
import { IServerError } from 'common/interfaces/http';
import { ILeadImt } from 'modules/crm/interfaces/leads';

export const selectError = (state: AppStoreTypeImt): IServerError =>
  state.getIn(['newPerson', 'person', 'primaryInfo', 'error']);
export const selectLoading = (state: AppStoreTypeImt): boolean =>
  state.getIn(['newPerson', 'person', 'primaryInfo', 'loading']);
export const selectMembers = (state: AppStoreTypeImt): ImmutableList<ILeadImt> =>
  state.getIn(['newPerson', 'person', 'primaryInfo', 'persons']);
export const selectActionCreateResult = (state: AppStoreTypeImt): ActionResult =>
  state.getIn(['newPerson', 'person', 'primaryInfo', 'actionCreateResult']);
export const selectActionUpdateResult = (state: AppStoreTypeImt): ActionResult =>
  state.getIn(['newPerson', 'person', 'primaryInfo', 'actionUpdateResult']);
export const selectPrimaryInfo = (state: AppStoreTypeImt): IImmutablePrimaryMemberInfo =>
  state.getIn(['newPerson', 'person', 'primaryInfo', 'result']);
export const selectMemberId = (state: AppStoreTypeImt): string =>
  state.getIn(['newPerson', 'person', 'primaryInfo', 'result', 'id']);
export const selectMembershipPackage = (state: AppStoreTypeImt): IShortPackageImt =>
  state.getIn(['newPerson', 'person', 'primaryInfo', 'result', 'membership']);
export const selectMembershipPackageId = (state: AppStoreTypeImt): string =>
  state.getIn(['newPerson', 'person', 'primaryInfo', 'result', 'membership', 'id']);

// search members list
export const selectPersonsFoundList = (state: AppStoreTypeImt) =>
  state.getIn(['newPerson', 'person', 'personsFound', 'result']);
export const selectPersonsFoundListLoading = (state: AppStoreTypeImt): boolean =>
  state.getIn(['newPerson', 'person', 'personsFound', 'isLoading']);
