import React from 'react';
import { Box, Divider, makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { CustomTheme } from 'common/ui/interfaces';
import { IEmployeeJobPosition } from 'common/interfaces/employee';
import { EmployeePayTypes } from 'common/constants/employee';
import messages from 'common/components/EmployeeProfile/messages';
import commonMessages from 'common/messages/messages';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

const useStyles = makeStyles((theme: CustomTheme) => ({
  jobPositionTitle: {
    paddingBottom: theme.spacing(1),
  },
  datePeriodTitle: {
    paddingBottom: theme.spacing(2),
  },
}));

interface IJobPositionsProps {
  jobPositions: IEmployeeJobPosition[];
}

const JobPositions = (props: IJobPositionsProps) => {
  const { jobPositions } = props;

  const [timezoneMoment] = useTimezoneMoment();
  const renderIntlMessage = useRenderIntlMessage();

  const classes = useStyles();

  return (
    <>
      {jobPositions.map(jobPosition => {
        const departmentTitle = jobPosition.department?.title;
        const address = jobPosition.club?.addressLine;
        const { payType } = jobPosition;

        const startDate = timezoneMoment(jobPosition.startDate).format('MMM D, YYYY');
        const isToday =
          !jobPosition.endDate ||
          timezoneMoment().isSame(timezoneMoment(jobPosition.endDate), 'day');
        const endDate = isToday
          ? renderIntlMessage(commonMessages.now)
          : timezoneMoment(jobPosition.endDate).format('MMM D, YYYY');

        let payTypeMessage = messages.salary;

        if (payType === EmployeePayTypes.HOURLY) {
          payTypeMessage = messages.hourly;
        }

        if (payType === EmployeePayTypes.UNIT) {
          payTypeMessage = messages.unit;
        }

        return (
          <Box display="flex" flexDirection="column" key={jobPosition.id}>
            <Divider />
            <Box pt={2}>
              <Typography variant="h5" gutterBottom>
                {jobPosition.jobTitle}
              </Typography>
              {departmentTitle && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={classes.jobPositionTitle}
                >
                  {departmentTitle}
                </Typography>
              )}
              {address && (
                <Typography variant="body2" className={classes.jobPositionTitle}>
                  {address}
                </Typography>
              )}
              <Typography variant="body2" noWrap className={classes.jobPositionTitle}>
                <FormattedMessage
                  {...payTypeMessage}
                  values={{
                    salary: (
                      <Typography
                        variant="h5"
                        component="span"
                      >{`$${jobPosition.salary}`}</Typography>
                    ),
                  }}
                />
              </Typography>
              <Typography className={classes.datePeriodTitle} color="textSecondary" variant="body2">
                {`${startDate} - ${endDate}`}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default JobPositions;
