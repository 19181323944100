import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import cx from 'classnames';

import {
  Appointments,
  CallsBlock,
  CampaignsBlock,
  EmailsBlock,
  Reminders,
  ModificationHistory,
  SMSBlock,
} from 'common/components/PersonProfile/components';
import { IBlocksSectionProps, IProfileInfoImt } from 'common/components/PersonProfile/interfaces';
import { CustomTheme } from 'common/ui/interfaces';
import { selectUserSelectedClubId } from 'modules/authentication/state/selectors';

const useStyles = makeStyles((theme: CustomTheme) => ({
  section: {
    padding: theme.spacing(1),
  },
  block: {
    maxHeight: `calc(359px + ${theme.spacing(2)}px)`,
  },
  blockSmall: {
    maxHeight: '100%',
  },
}));

const BlocksSection = (props: IBlocksSectionProps<IProfileInfoImt>): JSX.Element => {
  const { isLessThanDesktop, isLessThanLaptop, profile, module } = props;

  const personId = profile.get('id');

  const selectedClubId = useSelector(selectUserSelectedClubId);

  const classes = useStyles();

  const smallSize = isLessThanDesktop ? 6 : 4;
  const colSize = isLessThanLaptop ? 12 : smallSize;
  const blockClass = cx(classes.block, { [classes.blockSmall]: isLessThanLaptop });

  return (
    <Grid className={classes.section} container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item className={blockClass} xs={colSize}>
            <CallsBlock
              personId={personId}
              module={module}
              personPhones={profile.get('phones')}
              isMobile={isLessThanLaptop}
              selectedClubId={selectedClubId}
            />
          </Grid>
          <Grid item className={blockClass} xs={colSize}>
            <EmailsBlock
              module={module}
              personId={personId}
              personEmail={profile.get('email')}
              isMobile={isLessThanLaptop}
              selectedClubId={selectedClubId}
            />
          </Grid>
          <Grid item className={blockClass} xs={isLessThanDesktop ? 12 : 4}>
            <SMSBlock
              module={module}
              selectedClubId={selectedClubId}
              personId={personId}
              personPhones={profile.get('phones')}
              isMobile={isLessThanLaptop}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item className={blockClass} xs={colSize}>
            <Reminders
              module={module}
              selectedClubId={selectedClubId}
              personId={personId}
              isMobile={isLessThanLaptop}
            />
          </Grid>
          <Grid item className={blockClass} xs={colSize}>
            <Appointments personId={personId} isMobile={isLessThanLaptop} module={module} />
          </Grid>
          <Grid item className={classes.block} xs={isLessThanDesktop ? 12 : 4}>
            <CampaignsBlock personId={personId} selectedClubId={selectedClubId} module={module} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <ModificationHistory personId={personId} module={module} />
      </Grid>
    </Grid>
  );
};

export default BlocksSection;
