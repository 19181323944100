interface IDelayedAction {
  execute: (...params) => void;
}

export class DelayedActionsService {
  private delayedActions: { [key: string]: IDelayedAction } = {};

  addDelayedAction = (key: string, doAction: (...params) => void): void => {
    if (this.delayedActions[key]) {
      delete this.delayedActions[key];
    }

    this.delayedActions[key] = { execute: doAction };
  };

  execute = <T extends Array<any>>(key: string, params: T): void => {
    if (this.delayedActions[key]) {
      this.delayedActions[key].execute(...params);
      delete this.delayedActions[key];
    }
  };

  cancelDelayedAction = (key: string): void => {
    if (this.delayedActions[key]) {
      delete this.delayedActions[key];
    }
  };

  isExitDelayedAction = (key: string): boolean => {
    return Boolean(this.delayedActions[key]);
  };
}
