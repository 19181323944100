import { combineReducers } from 'redux-immutable';

import {
  requestErrorsReducer,
  scriptsTableReducer,
  updateScriptReducer,
  createScriptReducer,
} from './scripts/reducers';
import { tasksTableReducer } from './tasks/reducers';
import { leadsTableReducer, leadReducer } from './leads/reducers';
import {
  campaignsTableReducer,
  createCampaignReducer,
  updateCampaignReducer,
} from './campaigns/reducers';
import documentTemplatesReducer from 'modules/crm/state/crmTemplates/reducers';

export const crmModuleReducer = combineReducers({
  requestErrors: requestErrorsReducer,
  scriptsTable: scriptsTableReducer,
  tasksTable: tasksTableReducer,
  leadsTable: leadsTableReducer,
  leadForm: leadReducer,
  campaignsTable: campaignsTableReducer,
  createCampaignForm: createCampaignReducer,
  updateCampaignForm: updateCampaignReducer,
  updateScriptForm: updateScriptReducer,
  createScriptForm: createScriptReducer,
  documentTemplates: documentTemplatesReducer,
});
