import { useCallback, useState } from 'react';

export interface IToggleSearchInvoiceProductsProps {
  showSearchProducts: {
    isShowAddServicePackages: boolean;
    isShowAddInventories: boolean;
    isShowAddGiftCards: boolean;
  };
  toggleShowAddServicePackages: (isShow: boolean) => void;
  toggleShowAddInventories: (isShow: boolean) => void;
  toggleShowAddGiftCards: (isShow: boolean) => void;
}

const useToggleSearchInvoiceProductsState = (): IToggleSearchInvoiceProductsProps => {
  const [showSearchProducts, setShowSearchProducts] = useState({
    isShowAddServicePackages: false,
    isShowAddInventories: false,
    isShowAddGiftCards: false,
  });

  return {
    showSearchProducts,
    toggleShowAddServicePackages: useCallback(isShow => {
      setShowSearchProducts({
        isShowAddServicePackages: isShow,
        isShowAddInventories: false,
        isShowAddGiftCards: false,
      });
    }, []),
    toggleShowAddInventories: useCallback(isShow => {
      setShowSearchProducts({
        isShowAddServicePackages: false,
        isShowAddInventories: isShow,
        isShowAddGiftCards: false,
      });
    }, []),
    toggleShowAddGiftCards: useCallback(isShow => {
      setShowSearchProducts({
        isShowAddServicePackages: false,
        isShowAddInventories: false,
        isShowAddGiftCards: isShow,
      });
    }, []),
  };
};

export default useToggleSearchInvoiceProductsState;
