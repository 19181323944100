import React from 'react';
import { Box, makeStyles, MenuItem, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import messages from 'common/messages/messages';
import invoiceMessages from '../messages';
import { ITopUpBalance } from 'common/interfaces/invoices';
import { Select } from 'common/components';
import { CustomTheme } from 'common/ui/interfaces';
import { formatPrice } from 'common/utils';

interface InvoiceTopUpBalanceItemProps {
  onDelete?: () => void;
  topUpBalance: ITopUpBalance;
  disabled: boolean;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  countSelect: {
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      padding: theme.spacing(0.5, 4, 0.5, 1.5),
    },
    marginRight: theme.spacing(1),
  },
}));

const InvoiceTopUpBalanceItem = (props: InvoiceTopUpBalanceItemProps): JSX.Element => {
  const { onDelete, topUpBalance, disabled } = props;

  const classes = useStyles();

  return (
    <Box marginY={1.5} display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        {!!onDelete && (
          <Select
            value="1"
            onChange={count => !Number.isInteger(count) && onDelete()}
            size="small"
            className={classes.countSelect}
            disabled={disabled}
          >
            <MenuItem key="1" value="remove">
              <FormattedMessage {...messages.removeBtn} />
            </MenuItem>
            <MenuItem key="2" value="1">
              1
            </MenuItem>
          </Select>
        )}

        <Typography variant="h5">
          <FormattedMessage {...invoiceMessages.accountCredit} />
        </Typography>
      </Box>
      <Typography variant="h5">{formatPrice(topUpBalance.amount)}</Typography>
    </Box>
  );
};

export default InvoiceTopUpBalanceItem;
