export const actionTypes = {
  // item
  FETCH_DEPARTMENT_ERROR: 'DICTIONARIES/FETCH_DEPARTMENT_ERROR',
  RESET_DEPARTMENT: 'DICTIONARIES/RESET_DEPARTMENT',
  SET_DEPARTMENT_ACTION_RESULT: 'DICTIONARIES/SET_DEPARTMENT_ACTION_RESULT',
  UPDATE_STATUS_DEPARTMENT: 'DICTIONARIES/UPDATE_STATUS_DEPARTMENT',
  CREATE_OR_UPDATE_DEPARTMENT_IS_LOADING: 'DICTIONARIES/CREATE_OR_UPDATE_DEPARTMENT_IS_LOADING',

  // list
  FETCH_DEPARTMENTS_LIST: 'DICTIONARIES/FETCH_DEPARTMENTS_LIST',
  FETCH_DEPARTMENTS_LIST_IS_LOADING: 'DICTIONARIES/FETCH_DEPARTMENTS_LIST_IS_LOADING',
  FETCH_DEPARTMENTS_LIST_ERROR: 'DICTIONARIES/FETCH_DEPARTMENTS_LIST_ERROR',
  RESET_DEPARTMENTS_LIST: 'DICTIONARIES/RESET_DEPARTMENTS_LIST',
};
