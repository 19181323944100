import { List, fromJS } from 'immutable';

import { IBookingEvent, IShortResource } from 'modules/booking/interfaces';

import { initialState } from 'common/constants/initialState';
import { actionTypes } from './constants';
import { combineReducers } from 'redux';
import { IDictionaryCommonItem } from 'modules/dictionaries/interfaces/interfaces';

const initialStateEvents = fromJS({
  list: List<IBookingEvent>([]),
  isLoading: false,

  resourcesList: List<IShortResource>([]),

  clubsList: List<IShortResource>([]),

  eventTagsList: List<IDictionaryCommonItem>([]),
});

export const eventsReducer = (state = initialStateEvents, action) => {
  switch (action.type) {
    case actionTypes.GET_BOOKING_EVENTS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.GET_BOOKING_EVENTS:
      return state.set('list', fromJS(action.payload));
    case actionTypes.RESET_BOOKING_EVENTS:
      return initialStateEvents;

    case actionTypes.GET_RESOURCES_LIST:
      return state.set('resourcesList', fromJS(action.payload));

    case actionTypes.GET_CLUBS_LIST:
      return state.set('clubsList', fromJS(action.payload));

    case actionTypes.GET_EVETN_TAGS_LIST:
      return state.set('eventTagsList', fromJS(action.payload));

    case actionTypes.RESET_DICTIONARIES:
      return state
        .set('clubsList', List<IShortResource>([]))
        .set('resourcesList', List<IShortResource>([]))
        .set('eventTagsList', List<IDictionaryCommonItem>([]));
    default:
      return state;
  }
};

const initialStateEventDetails = fromJS({
  event: {},
  isEventLoading: false,
  eventActionStatus: initialState,
});

export const eventDetailsReducer = (state = initialStateEventDetails, action) => {
  switch (action.type) {
    case actionTypes.GET_BOOKING_EVENT_LOADING:
      return state.set('isEventLoading', action.payload);
    case actionTypes.GET_BOOKING_EVENT:
      return state.set('event', fromJS(action.payload));

    case actionTypes.RESET_BOOKING_EVENT_DETAILS:
      return state.set('event', fromJS({}));

    case actionTypes.RESET_EVENT_ACTION_RESULT:
      return state.set('eventActionStatus', fromJS(initialState));

    case actionTypes.BOOKING_EVENT_ACTION:
      return state.setIn(['eventActionStatus', 'result'], fromJS(action.payload));

    case actionTypes.BOOKING_EVENT_ACTION_LOADING:
      return state.setIn(['eventActionStatus', 'isLoading'], action.payload);
    default:
      return state;
  }
};

export const memberPortalBookingReducer = combineReducers({
  events: eventsReducer,
  eventDetails: eventDetailsReducer,
});
