import { combineReducers } from 'redux-immutable';
import servicesReducer from './services/reducers';
import feesReducer from './fees/reducers';
import packagesReducer from './packages/reducers';
import documentTemplateReducers from 'modules/services/state/documentTemplates/reducers';

export default combineReducers({
  services: servicesReducer,
  fees: feesReducer,
  packages: packagesReducer,
  documentTemplates: documentTemplateReducers,
});
