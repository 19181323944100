import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Theme, Typography } from '@material-ui/core';

import { Button, UnavailableModule } from 'common/components';
import { ReactComponent as RegisterIcon } from 'img/icons/sidebar/cash-register.svg';

import commonMessages from 'common/messages/messages';

const useStyles = makeStyles((theme: Theme) => ({
  noFoundText: {
    fontSize: '16px',
    color: theme.palette.secondary.dark,
  },
}));

const NoRegistersFound = (): JSX.Element => {
  const navigate = useNavigate();

  const classes = useStyles();

  return (
    <UnavailableModule
      lockIcon={<RegisterIcon />}
      description={
        <Typography className={classes.noFoundText}>
          <FormattedMessage {...commonMessages.noRegistersText} />
        </Typography>
      }
      additionalButton={
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/pos-settings/register/new')}
        >
          <FormattedMessage {...commonMessages.createNewOneBtn} />
        </Button>
      }
    />
  );
};

export default NoRegistersFound;
