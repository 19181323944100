import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { fromJS } from 'immutable';
import moment from 'moment-timezone';
import { Box, Grid, SvgIcon, Typography, makeStyles } from '@material-ui/core';
import { Event, Schedule } from '@material-ui/icons';
import { FontWeightProperty } from 'csstype';

import * as selectors from 'modules/authentication/state/selectors';

import { CustomTheme } from 'common/ui/interfaces';
import { IBookingEventImt } from 'modules/booking/interfaces';
import { IUserOrganizationImt } from 'common/interfaces/clients';
import { IServiceItemDetailsImt } from 'common/interfaces/service';

import ParticipantsList from 'modules/booking/components/EventDetails/ParticipantsList/ParticipantsList';
import { ReactComponent as Building } from 'img/icons/sidebar/building.svg';
import { ReactComponent as Package } from 'img/box.svg';
import ServiceAvailabilityHoursAlert, {
  checkIsTimeInServiceAvailabilityRange,
} from 'common/components/PersonProfile/components/Services/ServiceAvailabilityHoursAlert/ServiceAvailabilityHoursAlert';

import commonMessages from 'common/messages/messages';
import { DEFAULT_DATE_FORMAT } from 'common/constants/dateFormats';

interface IEventDataProps {
  event: IBookingEventImt;
  serviceInstance: IServiceItemDetailsImt;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: theme.spacing(1.25),
    margin: theme.spacing(1, 0),
  },
  dataBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },
  rowIcon: {
    marginRight: theme.spacing(1),
    fontSize: '1.1rem',
    color: theme.palette.text.primary,
    opacity: '0.4',
  },
  resourcesBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    flexWrap: 'wrap',
    margin: theme.spacing(1, 0),
  },
  resourceItem: {
    margin: theme.spacing(0, 0.625, 0.625, 0),
    padding: theme.spacing(0.75, 1),
    borderRadius: theme.spacing(1.75),
    backgroundColor: '#9b9b9b',
  },
  resourceItemText: {
    fontSize: '0.75rem',
    fontWeight: theme.typography.fontWeightMedium as FontWeightProperty,
    textTransform: 'uppercase',
    color: 'white',
  },
}));

/* Represents a selected to redeem event details */
const EventData: React.FC<IEventDataProps> = ({
  event,
  serviceInstance,
}: IEventDataProps): JSX.Element => {
  const selectedClub: IUserOrganizationImt = useSelector(selectors.selectUserSelectedOrganization);
  const clubTimezone = selectedClub.get('timezone');
  const clubDate = moment.tz(clubTimezone);
  const clubZoneOffset = clubDate.utcOffset();

  const classes = useStyles();

  // constants

  const eventDate = moment(`${event.get('date')} ${event.get('time')}`, 'YYYY-MM-DD HH:mm')
    .utc(true)
    .utcOffset(clubZoneOffset);

  const resources = event.get('resources');

  const trainersResources = resources
    ?.filter(resourceItem => !!resourceItem.get('employee'))
    .map(resourceItem =>
      fromJS({
        salesperson: resourceItem.get('employee').toJS(),
        joinedDate: null,
      }),
    );

  const otherResources = resources?.filter(resourceItem => !resourceItem.get('employee'));

  const transformedServiceInstance = useMemo(() => serviceInstance.toJS(), [serviceInstance]);

  const isServiceAvailabilityHoursAlertShow =
    !transformedServiceInstance.hasSameAsClubAvailability &&
    !checkIsTimeInServiceAvailabilityRange(
      transformedServiceInstance.customAvailability,
      event.get('date'),
      moment(`${event.get('time')}`, 'HH:mm')
        .utc(true)
        .utcOffset(clubZoneOffset)
        .format(),
    );

  // renders

  return (
    <Grid item xs={12}>
      {isServiceAvailabilityHoursAlertShow && (
        <Box marginY={2}>
          <ServiceAvailabilityHoursAlert
            availability={transformedServiceInstance.customAvailability}
          />
        </Box>
      )}

      <Box className={classes.container}>
        <Box className={classes.dataBlock}>
          <Event className={classes.rowIcon} />
          <Typography color="textPrimary">{eventDate.format(DEFAULT_DATE_FORMAT)}</Typography>
        </Box>
        <Box className={classes.dataBlock}>
          <Schedule className={classes.rowIcon} />
          <Typography color="textPrimary">
            {`${eventDate.format('LT')} - ${eventDate
              .add(event.get('durationInMinutes'), 'minutes')
              .format('LT')}`}
          </Typography>
        </Box>

        {!!event.get('club') && (
          <Box className={classes.dataBlock}>
            <SvgIcon className={classes.rowIcon}>
              <Building />
            </SvgIcon>
            <Typography color="textPrimary">{event.getIn(['club', 'title'])}</Typography>
          </Box>
        )}
      </Box>

      {!!otherResources?.size && (
        <Box className={classes.resourcesBlock}>
          <SvgIcon className={classes.rowIcon}>
            <Package />
          </SvgIcon>

          {otherResources.map(resource => (
            <Box className={classes.resourceItem} key={resource.get('id')}>
              <Typography className={classes.resourceItemText}>{resource.get('title')}</Typography>
            </Box>
          ))}
        </Box>
      )}

      {!!trainersResources.size && (
        <ParticipantsList
          title={<FormattedMessage {...commonMessages.trainersLabel} />}
          data={trainersResources}
        />
      )}
    </Grid>
  );
};

export default EventData;
