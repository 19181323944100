import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { actionTypes } from '../../constants/inventoryItems';

const initStateInventoryItemPos = fromJS({
  list: [],
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
  isLoading: false,
  totals: {},
  changeStatusInventoryItemsLoading: false,
  changeStatusInventoryItemsResult: {},
  error: null,
});

function inventoryItemsPosReducer(state = initStateInventoryItemPos, action) {
  switch (action.type) {
    case actionTypes.FETCH_INVENTORY_ITEMS_POS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_INVENTORY_ITEMS_POS:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta))
        .set('listLoading', false);
    case actionTypes.FETCH_INVENTORY_ITEMS_POS_CHANGE_STATUS_LOADING:
      return state.set('changeStatusInventoryItemsLoading', fromJS(action.payload));
    case actionTypes.FETCH_INVENTORY_ITEMS_POS_CHANGE_STATUS:
      return state.set('changeStatusInventoryItemsResult', fromJS(action.payload));
    case actionTypes.RESET_CHANGE_STATUS_SUCCESS:
      return state.set('changeStatusInventoryItemsResult', fromJS({}));
    default:
      return state;
  }
}

const initStateInventoryItemById = fromJS({
  inventoryItem: {},
  isInventoryItemLoading: false,
  actionResult: {},
  actionClubsResult: {},
  actionCreateClubsResult: {},
  actionUpdateClubsResult: {},
  actionDeleteClubsResult: {},
  updateInventoryItemLoading: false,
  list: [],
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
  multiEditClubs: [],
  unavailable: [],
  error: null,
});

function inventoryItemByIdReducer(state = initStateInventoryItemById, action) {
  switch (action.type) {
    case actionTypes.FETCH_INVENTORY_ITEMS_POS_BY_ID_LOADING:
      return state.set('isInventoryItemLoading', action.payload);
    case actionTypes.FETCH_INVENTORY_ITEMS_POS_BY_ID:
      return state
        .set('inventoryItem', fromJS(action.payload.values))
        .set('list', fromJS(action.payload.data.data))
        .set('meta', fromJS(action.payload.data.meta))
        .set('unavailable', fromJS(action.payload.data.unavailable));
    case actionTypes.FETCH_INVENTORY_ITEMS_POS_BY_ID_PAGINATE:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta))
        .set('unavailable', fromJS(action.payload.unavailable));
    case actionTypes.CREATE_INVENTORY_ITEMS_POS_BY_ID_CLUBS:
      return state.set('actionCreateClubsResult', fromJS(action.payload));
    case actionTypes.UPDATE_INVENTORY_ITEMS_POS_BY_ID_CLUBS:
      return state.set('actionUpdateClubsResult', fromJS(action.payload));
    case actionTypes.DELETE_INVENTORY_ITEMS_POS_BY_ID_CLUBS:
      return state.set('actionDeleteClubsResult', fromJS(action.payload));
    case actionTypes.RESET_INVENTORY_ITEMS_POS_BY_ID_CLUBS_ACTION:
      return state
        .set('actionCreateClubsResult', fromJS({}))
        .set('actionUpdateClubsResult', fromJS({}))
        .set('actionDeleteClubsResult', fromJS({}));
    case actionTypes.CREATE_INVENTORY_ITEMS_POS_BY_ID:
    case actionTypes.UPDATE_INVENTORY_ITEMS_POS_BY_ID:
      return state.set('actionResult', fromJS(action.payload));
    case actionTypes.UPDATE_INVENTORY_ITEMS_POS_BY_ID_LOADING:
      return state.set('updateInventoryItemLoading', action.payload);
    case actionTypes.FETCH_INVENTORY_ITEMS_POS_BY_ID_CLUBS_MULTI_EDIT:
      return state.set('multiEditClubs', fromJS(action.payload));
    case actionTypes.RESET_INVENTORY_ITEMS_POS_BY_ID_CLUBS_MULTI_EDIT:
      return state.set('multiEditClubs', fromJS([]));
    case actionTypes.RESET_INVENTORY_ITEMS_BY_ID:
      return initStateInventoryItemById;
    default:
      return state;
  }
}

const initStateInventoryItemByIdConfiguration = fromJS({
  inventoryItemConfiguration: {},
  isInventoryItemConfigurationLoading: false,
  listClubs: [],
  listClubsLoading: false,
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
  totals: {},
  actionCreateResult: {},
  actionUpdateResult: {},
  actionDeleteResult: {},
  multiEditClubsLoading: false,
  multiEditClubs: [],
  error: null,
});

function inventoryItemByIdConfigurationReducer(
  state = initStateInventoryItemByIdConfiguration,
  action,
) {
  switch (action.type) {
    case actionTypes.FETCH_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_LOADING:
      return state.set('isInventoryItemConfigurationLoading', action.payload);
    case actionTypes.FETCH_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION:
      return state.set('inventoryItemConfiguration', fromJS(action.payload));
    case actionTypes.FETCH_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_CLUBS_LOADING:
      return state.set('listClubsLoading', action.payload);
    case actionTypes.FETCH_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_CLUBS:
      return state
        .set('listClubs', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta))
        .set('listClubsLoading', false);
    case actionTypes.FETCH_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_CLUBS_ACTION_LOADING:
      return state.set('isActionLoading', action.payload);
    case actionTypes.CREATE_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_CLUBS:
      return state.set('actionCreateResult', fromJS(action.payload));
    case actionTypes.UPDATE_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_CLUBS:
      return state.set('actionUpdateResult', fromJS(action.payload));
    case actionTypes.DELETE_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_CLUBS:
      return state.set('actionDeleteResult', fromJS(action.payload));
    case actionTypes.RESET_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_CLUBS_RESULT:
      return state
        .set('actionCreateResult', fromJS({}))
        .set('actionUpdateResult', fromJS({}))
        .set('actionDeleteResult', fromJS({}));
    case actionTypes.FETCH_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_CLUBS_MULTI_EDIT_LOADING:
      return state.set('multiEditClubsLoading', action.payload);
    case actionTypes.FETCH_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_CLUBS_MULTI_EDIT:
      return state.set('multiEditClubs', fromJS(action.payload));
    case actionTypes.RESET_INVENTORY_ITEMS_POS_BY_ID_CONFIGURATION_CLUBS_MULTI_EDIT:
      return state.set('multiEditClubs', fromJS([]));
    case actionTypes.RESET_INVENTORY_ITEMS_BY_ID_CONFIGURATION:
      return initStateInventoryItemByIdConfiguration;
    default:
      return state;
  }
}

export default combineReducers({
  inventoryItemsList: inventoryItemsPosReducer,
  inventoryItemItem: inventoryItemByIdReducer,
  inventoryItemItemConfiguration: inventoryItemByIdConfigurationReducer,
});
