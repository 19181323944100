import React, { forwardRef, PropsWithChildren } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Theme } from '@material-ui/core';

import inputLabels from 'common/messages/inputLabels';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { TextField } from 'common/components/index';

const useStyles = makeStyles((theme: Theme) => ({
  phoneInput: {
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(70px, 8px) scale(0.675)',
    },

    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      paddingLeft: theme.spacing(8.75),
    },
  },
}));

const CustomPhoneNumberInput = forwardRef((props: PropsWithChildren<any>, ref) => {
  const { name, helperText, error, recommended, label, ...otherProps } = props;

  const { errors } = useFormContext();

  const renderIntlMessage = useRenderIntlMessage();

  const classes = useStyles();

  return (
    <TextField
      {...{ ...otherProps, name }}
      inputRef={ref}
      fullWidth
      label={label || <FormattedMessage {...inputLabels.phone} />}
      variant="outlined"
      className={classes.phoneInput}
      error={error || !!errors[name]}
      helperText={renderIntlMessage(helperText || errors[name]?.message)}
      recommended={recommended}
    />
  );
});

export default CustomPhoneNumberInput;
