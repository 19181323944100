import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import { Box, makeStyles, Switch, Theme, Typography } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import moment from 'moment-timezone';
import { yupResolver } from '@hookform/resolvers/yup';

// interfaces
import { IEmployeeListItemImt } from 'common/interfaces/dictionary';
import { ITourStep } from 'modules/front-desk/interfaces';
import { IUserProfileInfoImt } from 'modules/authentication/interfaces';
import {
  EventAttendeeType,
  EventType,
  IBookingEvent,
  ITagImt,
  PersonAppointmentStatus,
  PersonAttendanceType,
} from 'modules/booking/interfaces';
import { ImmutableObject } from 'common/state/interfaces';
import {
  INamedEntity,
  INamedEntityImt,
  IShortPerson,
  ReminderPeriodType,
} from 'common/interfaces/common';
import { IPrimaryMemberInfo } from 'common/components/PersonProfile/interfaces';
import { ILeadProfile } from 'modules/crm/interfaces/leads';
// constants
import { tourEventColor } from 'modules/crm/constants/leads';
import { StepContext } from 'common/createContext/stepContext';
import { SenderAvailabilityTypeList } from 'modules/booking/constants/senderAvailability';
import { ENotificationType } from 'modules/booking/constants/notificationType';
import { PeakModules } from 'common/constants/peakModules';
// state
import * as actionsMembers from 'common/state/newPerson/scheduleTour/actions';
import {
  fetchSenderAvailabilityThunk,
  resetBookingEvents,
} from 'modules/booking/state/senderAvailability/actions';
// components
import { ScrollBox } from 'common/components/index';
import CallEventSection from 'common/components/PersonProfile/components/CallsBlock/modals/NewCallModal/CallEventSection';
// hooks
import { useAppDispatch } from 'store/hooks';
// ValidationSchema
import { ScheduleTourValidationSchema } from 'common/components/Steps/ScheduleTourStep/ValidationSchema/ValidationSchema';
// messages
import bookingMessages from 'modules/booking/messages';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import isFormDirty from 'common/hooks/isFormDirty';
import useDiscardChangesContext from 'common/hooks/useDiscardChangesContext';
import DiscardChangesModalProvider from 'common/modals/DiscardChangesModal/DiscardChangesModalProvider';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1, 2),
    flex: 1,
    height: '100%',
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  createAppointment: {
    marginRight: theme.spacing(0.5),
  },
}));

interface IProps {
  employees: ImmutableList<IEmployeeListItemImt>;
  person: ImmutableObject<ILeadProfile | IPrimaryMemberInfo>;
  event?: ITourStep;
  club?: INamedEntityImt;
  isLoading: boolean;
  currentUser: IUserProfileInfoImt;
  module: PeakModules;
  eventTags?: ImmutableList<ITagImt>;
}

interface ITourStepForm extends Omit<ITourStep, 'salesperson'> {
  salesperson: INamedEntity;
}

const initialValues: ITourStep = {
  durationInMinutes: '',
  reminder: false,
  reminderPeriodNumber: 1,
  reminderPeriodType: ReminderPeriodType.Days,
  salesperson: null,
  description: '',
  tags: [],
};

const ScheduleTourStep = ({
  event,
  club,
  employees,
  person,
  isLoading,
  currentUser,
  module,
  eventTags,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [isShowCreateAppointmentForm, setIsShowCreateAppointmentForm] = useState(false);
  const renderIntlMessage = useRenderIntlMessage();
  const classes = useStyles();
  const { onBack, renderFooter, done } = useContext(StepContext);
  const dcContext = useDiscardChangesContext();
  const convertedPerson = useMemo(() => person?.toJS(), [person]);

  const { date, time } = useMemo(() => {
    const nowDate = moment()
      .utc()
      .add(10, 'minutes');
    return {
      date: nowDate.format('YYYY-MM-DD'),
      time: nowDate.format('HH:mm'),
    };
  }, []);

  const formMethods = useForm<ITourStepForm>({
    defaultValues: {
      ...initialValues,
      date,
      time,
      ...event,
      salesperson: {
        id: currentUser.get('id'),
        title: `${currentUser.get('firstName')} ${currentUser.get('lastName')}`,
      },
    },
    resolver: yupResolver(ScheduleTourValidationSchema),
    mode: 'all',
  });

  const { handleSubmit, getValues, formState } = formMethods;
  const isDirty = isFormDirty(formState);

  const formatEventData = (eventDto: ITourStepForm): IBookingEvent => {
    const selectedDate = moment
      .utc(`${eventDto.date} ${eventDto.time}`, 'YYYY-MM-DD HH:mm')
      .format();

    const { salesperson } = eventDto;
    // TODO: Need to change the format of the salesperson in form. Need to change the 'title' property to 'firstName' and 'lastName'
    const formattedSalesPerson: IShortPerson = {
      id: salesperson.id,
      firstName: salesperson.title.split(' ')[0],
      lastName: salesperson.title.split(' ')[1],
    };

    return {
      persons: [
        {
          joinedDate: selectedDate,
          eventDate: selectedDate,
          customer: {
            id: convertedPerson.id,
            firstName: convertedPerson.firstName,
            lastName: convertedPerson.lastName,
            type: EventAttendeeType.MEMBER,
          },
          attendanceType: PersonAttendanceType.OneTime,
          status: PersonAppointmentStatus.Pending,
        },
        {
          joinedDate: selectedDate,
          eventDate: selectedDate,
          salesperson: formattedSalesPerson,
          attendanceType: PersonAttendanceType.OneTime,
          status: PersonAppointmentStatus.Pending,
        },
      ],
      title: renderIntlMessage(bookingMessages.appointmentNewMemberTourTitle, {
        name: `${convertedPerson.firstName} ${convertedPerson.lastName}`,
      }),
      date: eventDto.date,
      time: eventDto.time,
      tags: eventDto.tags,
      description: eventDto.description,
      durationInMinutes: moment.duration(eventDto.durationInMinutes).asMinutes(),
      color: tourEventColor,
      club: club?.toJS(),
      type: EventType.TOUR,
      repeated: false,
      notifyViaEmail: eventDto.notifyViaEmail,
      notifyViaNotification: eventDto.notifyViaNotification,
      notifyViaSms: eventDto.notifyViaSms,
      ...(eventDto.reminder
        ? {
            reminderPeriodNumber: eventDto.reminderPeriodNumber,
            reminderPeriodType: eventDto.reminderPeriodType,
          }
        : {}),
    };
  };

  const onSubmit = (data: ITourStepForm) => {
    dispatch(actionsMembers.saveTourStepThunk(formatEventData(data), convertedPerson.id, module));
  };

  const onHandleBack = () => {
    onBack(getValues());
  };

  const getEventTour = useCallback((): ENotificationType => {
    switch (module) {
      case PeakModules.Crm:
        return ENotificationType.CRM_LEAD_BOOK_A_TOUR_CREATED;
      case PeakModules.PersonalTrainingCrm:
        return ENotificationType.PT_CRM_LEAD_BOOK_A_TOUR_CREATED;
      case PeakModules.FrontDesk:
      default:
        return ENotificationType.FRONTDESK_BOOK_A_TOUR_CREATED;
    }
  }, [module]);

  useEffect(() => {
    dispatch(
      fetchSenderAvailabilityThunk(
        [SenderAvailabilityTypeList.TOUR, SenderAvailabilityTypeList.REMIND],
        {
          module,
          events: [getEventTour(), ENotificationType.EVENT_REMIND],
        },
      ),
    );
    return () => {
      dispatch(resetBookingEvents({ type: SenderAvailabilityTypeList.TOUR }));
    };
  }, [dispatch, getEventTour, module]);

  return (
    <>
      <FormProvider {...formMethods}>
        <ScrollBox hasShadowsOnScroll>
          <form className={classes.root} autoComplete="none">
            <Box display="flex" alignItems="center" mb={2}>
              <Typography className={classes.createAppointment}>
                <FormattedMessage {...bookingMessages.createAppointment} />
              </Typography>

              <Switch
                color="primary"
                size="small"
                checked={isShowCreateAppointmentForm}
                onChange={(e, value) => {
                  setIsShowCreateAppointmentForm(value);
                }}
              />
            </Box>

            {isShowCreateAppointmentForm && (
              <CallEventSection employees={employees} eventTags={eventTags} />
            )}
          </form>
        </ScrollBox>

        {!!renderFooter &&
          renderFooter(
            onHandleBack,
            isShowCreateAppointmentForm ? handleSubmit(onSubmit) : done,
            isLoading,
          )}
      </FormProvider>

      {dcContext && isDirty ? <DiscardChangesModalProvider {...dcContext} /> : null}
    </>
  );
};

export default ScheduleTourStep;
