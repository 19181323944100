import { validators } from 'common/constants/validators';
import inputErrors from 'common/messages/inputErrors';
import * as yup from 'yup';
import { TShortProfileFieldInfoImt } from 'modules/corporate-settings/interfaces';
import {
  FieldNecessity,
  RequiredProfileInfo,
} from 'modules/corporate-settings/constants/requiredFields';

const requiredMessage = () => inputErrors.requiredFieldError;

export const addressSchema = yup.object().shape({
  // TODO uncomment after adding countries
  /* country: yup
    .mixed()
    .nullable()
    .notOneOf(['', null, undefined], requiredMessage)
    .required(requiredMessage), */
  state: yup
    .mixed()
    .nullable()
    .notOneOf(['', null], requiredMessage)
    .required(requiredMessage),
  city: yup
    .string()
    .nullable()
    .required(requiredMessage),
  line1: yup
    .string()
    .nullable()
    .required(requiredMessage),
  zipCode: validators.ZIP_CODE,
});

export const getAddressSchema = (requiredFields: TShortProfileFieldInfoImt) =>
  yup.object().shape({
    state: yup
      .mixed()
      .nullable()
      .when(RequiredProfileInfo.Address, {
        is: () => requiredFields.get(RequiredProfileInfo.Address) === FieldNecessity.Required,
        then: schema => schema.required(requiredMessage),
        otherwise: schema => schema,
      }),
    city: yup
      .string()
      .nullable()
      .when(RequiredProfileInfo.Address, {
        is: () => requiredFields.get(RequiredProfileInfo.Address) === FieldNecessity.Required,
        then: schema => schema.required(requiredMessage),
        otherwise: schema => schema,
      }),
    line1: yup
      .string()
      .nullable()
      .when(RequiredProfileInfo.Address, {
        is: () => requiredFields.get(RequiredProfileInfo.Address) === FieldNecessity.Required,
        then: schema => schema.required(requiredMessage),
        otherwise: schema => schema,
      }),
    // zipCode: validators.ZIP_CODE.when(RequiredProfileInfo.Address, {
    //   is: () => requiredFields.get(RequiredProfileInfo.Address) === FieldNecessity.Required,
    //   then: schema => schema.required(requiredMessage),
    //   otherwise: schema => schema.trim(),
    // zipCode: yup
    //   .string()
    //   .nullable()
    //   .when(RequiredProfileInfo.Address, {
    //     is: () => requiredFields.get(RequiredProfileInfo.Address) === FieldNecessity.Required,
    //     then: schema => validators.ZIP_CODE.required(requiredMessage),
    //     otherwise: schema => validators.ZIP_CODE.trim(),
    //   }),
    zipCode: yup
      .string()
      .nullable()
      .when((val, schema) => {
        if (requiredFields.get(RequiredProfileInfo.Address) === FieldNecessity.Required) {
          return validators.ZIP_CODE.required(requiredMessage);
        }

        if (val?.trim() === '') {
          return schema;
        }

        return validators.ZIP_CODE;
      }),
  });
