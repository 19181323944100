import WebHidApiService from './WebHidApiService';
import { IDeviseIds, Subscriber } from 'common/interfaces/webHid';
import { regExp } from 'common/constants';

enum BarcodeScannerEvents {
  BarcodeScannerString = 'barcodeScannerString',
  ConnectBarcodeScanner = 'connectBarcodeScanner',
  DisconnectBarcodeScanner = 'disconnectBarcodeScanner',
  OpenBarcodeScanner = 'openBarcodeScanner',
  OpenBarcodeScannerError = 'openBarcodeScannerError',
}

export default class BarcodeScannerService extends WebHidApiService {
  private connectedDevices: HIDDevice[] = [];

  private openedDevices: HIDDevice[] = [];

  private filterDevices: IDeviseIds[] = [{ vendorId: 0x05e0, productId: 0x1300 }];

  private isDisabledAutoCheckin = false;

  constructor() {
    super();
    (async () => {
      try {
        const { openedDevices, connectedDevices } = await this.lookingForDevices(
          this.filterDevices,
        );

        this.connectedDevices = connectedDevices;

        this.openedDevices = openedDevices;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error({ err });
      }
    })();
  }

  get getOpenedDevices() {
    return [...this.openedDevices];
  }

  get getIsAllScannersAreOpen() {
    return this.openedDevices.length === this.filterDevices.length;
  }

  get getConnectedDevices() {
    return [...this.connectedDevices];
  }

  get getIsDisabledAutoCheckin() {
    return this.isDisabledAutoCheckin;
  }

  set setIsDisabledAutoCheckin(bool: boolean) {
    this.isDisabledAutoCheckin = bool;
  }

  private getDecodeBarcodeScan = (e): string => {
    return new TextDecoder('utf-8')
      .decode(e.data)
      .replace(regExp.REPLACE_BARCODE, '')
      .trim();
  };

  protected handleScannerString = (e: HIDInputReportEvent) => {
    try {
      const decodeString = this.getDecodeBarcodeScan(e);

      this.triggerEvent(BarcodeScannerEvents.BarcodeScannerString, decodeString);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error({ err });
    }
  };

  protected handleConnectBarcodeScanner({ device }: HIDConnectionEvent) {
    const connectedDevice = this.addDevice(this.connectedDevices, this.filterDevices, device);

    if (connectedDevice) {
      this.triggerEvent(BarcodeScannerEvents.ConnectBarcodeScanner, connectedDevice);
    }
  }

  protected handleDisconnectBarcodeScanner({ device }: HIDConnectionEvent): void | HIDDevice[] {
    let removedDevice = this.removeDevice(this.connectedDevices, device);

    if (!removedDevice) {
      removedDevice = this.removeDevice(this.openedDevices, device);
    }

    if (removedDevice) {
      this.triggerEvent(BarcodeScannerEvents.DisconnectBarcodeScanner, removedDevice);
    }
  }

  public subscribeOnConnectDevice = (callback: Subscriber<HIDDevice>) => {
    this.addSubscriber(BarcodeScannerEvents.ConnectBarcodeScanner, callback);
  };

  public unsubscribeOnConnectDevice = (callback: Subscriber<HIDDevice>) => {
    this.removeSubscriber(BarcodeScannerEvents.ConnectBarcodeScanner, callback);
  };

  public subscribeOnDisconnectDevice = (callback: Subscriber<HIDDevice>) => {
    this.addSubscriber(BarcodeScannerEvents.DisconnectBarcodeScanner, callback);
  };

  public unsubscribeOnDisconnectDevice = (callback: Subscriber<HIDDevice>) => {
    this.removeSubscriber(BarcodeScannerEvents.DisconnectBarcodeScanner, callback);
  };

  public subscribeOnScannerString = (callback: Subscriber<string>) => {
    this.addSubscriber(BarcodeScannerEvents.BarcodeScannerString, callback);
  };

  public unsubscribeOnScannerString = (callback: Subscriber<string>) => {
    this.removeSubscriber(BarcodeScannerEvents.BarcodeScannerString, callback);
  };

  public subscribeOnOpenBarcodeScanner = (callback: Subscriber<HIDDevice>) => {
    this.addSubscriber(BarcodeScannerEvents.OpenBarcodeScanner, callback);
  };

  public unsubscribeOnOpenBarcodeScanner = (callback: Subscriber<HIDDevice>) => {
    this.removeSubscriber(BarcodeScannerEvents.OpenBarcodeScanner, callback);
  };

  public subscribeOnOpenBarcodeScannerError = (callback: Subscriber) => {
    this.addSubscriber(BarcodeScannerEvents.OpenBarcodeScannerError, callback);
  };

  public unsubscribeOnOpenBarcodeScannerError = (callback: Subscriber) => {
    this.removeSubscriber(BarcodeScannerEvents.OpenBarcodeScannerError, callback);
  };

  // replaced device from connected to opened
  private replaceState = (device: HIDDevice) => {
    this.removeDevice(this.connectedDevices, device);
    this.addDevice(this.openedDevices, this.filterDevices, device);
  };

  // is used to manually open the device from the component
  public openDevice = async (device: HIDDevice) => {
    try {
      const openedDevice = await this.openHidDevice(this.handleScannerString, device);

      if (openedDevice) {
        this.replaceState(openedDevice);
        this.triggerEvent(BarcodeScannerEvents.OpenBarcodeScanner, device);
      }
    } catch (e) {
      this.triggerEvent(BarcodeScannerEvents.OpenBarcodeScannerError, e);
    }
  };

  public init = async () => {
    try {
      const device = await this.requestPairScanner(this.filterDevices);

      if (device) {
        const openedDevice = await this.openHidDevice(this.handleScannerString, device);

        if (openedDevice) {
          this.replaceState(openedDevice);
          this.triggerEvent(BarcodeScannerEvents.OpenBarcodeScanner, openedDevice);
        }
      }
    } catch (e) {
      this.triggerEvent(BarcodeScannerEvents.OpenBarcodeScannerError, e);
    }
  };
}
