import WebHidApiService from 'services/webHidApi/WebHidApiService';
import { IDeviseIds, Subscriber } from 'common/interfaces/webHid';

enum MagneticStripeScannerEvents {
  ConnectMagneticStripeScanner = 'connectMagneticStripeScanner',
  DisconnectMagneticStripeScanner = 'disconnectMagneticStripeScanner',
  MagneticStripeScannerString = 'magneticStripeScannerString',
  OpenMagneticStripeScanner = 'openMagneticStripeScanner',
  OpenMagneticStripeScannerError = 'openMagneticStripeScannerError',
}

export default class MagneticStripeScannerService extends WebHidApiService {
  private connectedDevices: HIDDevice[] = [];

  private openedDevices: HIDDevice[] = [];

  private filterDevices: IDeviseIds[] = [{ vendorId: 0x0801, productId: 0x0002 }];

  constructor() {
    super();
    (async () => {
      try {
        const { openedDevices, connectedDevices } = await this.lookingForDevices(
          this.filterDevices,
        );

        this.connectedDevices = connectedDevices;

        this.openedDevices = openedDevices;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error({ err });
      }
    })();
  }

  get getOpenedDevices() {
    return [...this.openedDevices];
  }

  get getIsAllScannersAreOpen() {
    return this.openedDevices.length === this.filterDevices.length;
  }

  get getConnectedDevices() {
    return [...this.connectedDevices];
  }

  private getDecodeMagneticStripeScan = (e): string => {
    return new TextDecoder('utf-8').decode(e.data);
  };

  protected handleScannerString = (e: HIDInputReportEvent) => {
    try {
      const decodeString = this.getDecodeMagneticStripeScan(e);

      this.triggerEvent(MagneticStripeScannerEvents.MagneticStripeScannerString, decodeString);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error({ err });
    }
  };

  protected handleConnectMagneticStripeScanner({ device }: HIDConnectionEvent) {
    const connectedDevice = this.addDevice(this.connectedDevices, this.filterDevices, device);

    if (connectedDevice) {
      this.triggerEvent(MagneticStripeScannerEvents.ConnectMagneticStripeScanner, connectedDevice);
    }
  }

  protected handleDisconnectMagneticStripeScanner({
    device,
  }: HIDConnectionEvent): void | HIDDevice[] {
    let removedDevice = this.removeDevice(this.connectedDevices, device);

    if (!removedDevice) {
      removedDevice = this.removeDevice(this.openedDevices, device);
    }

    if (removedDevice) {
      this.triggerEvent(MagneticStripeScannerEvents.DisconnectMagneticStripeScanner, removedDevice);
    }
  }

  public subscribeOnConnectDevice = (callback: Subscriber<HIDDevice>) => {
    this.addSubscriber(MagneticStripeScannerEvents.ConnectMagneticStripeScanner, callback);
  };

  public unsubscribeOnConnectDevice = (callback: Subscriber<HIDDevice>) => {
    this.removeSubscriber(MagneticStripeScannerEvents.ConnectMagneticStripeScanner, callback);
  };

  public subscribeOnDisconnectDevice = (callback: Subscriber<HIDDevice>) => {
    this.addSubscriber(MagneticStripeScannerEvents.DisconnectMagneticStripeScanner, callback);
  };

  public unsubscribeOnDisconnectDevice = (callback: Subscriber<HIDDevice>) => {
    this.removeSubscriber(MagneticStripeScannerEvents.DisconnectMagneticStripeScanner, callback);
  };

  public subscribeOnScannerString = (callback: Subscriber<string>) => {
    this.addSubscriber(MagneticStripeScannerEvents.MagneticStripeScannerString, callback);
  };

  public unsubscribeOnScannerString = (callback: Subscriber<string>) => {
    this.removeSubscriber(MagneticStripeScannerEvents.MagneticStripeScannerString, callback);
  };

  public subscribeOnOpenMagneticStripeScanner = (callback: Subscriber<HIDDevice>) => {
    this.addSubscriber(MagneticStripeScannerEvents.OpenMagneticStripeScanner, callback);
  };

  public unsubscribeOnOpenMagneticStripeScanner = (callback: Subscriber<HIDDevice>) => {
    this.removeSubscriber(MagneticStripeScannerEvents.OpenMagneticStripeScanner, callback);
  };

  public subscribeOnOpenMagneticStripeScannerError = (callback: Subscriber) => {
    this.addSubscriber(MagneticStripeScannerEvents.OpenMagneticStripeScannerError, callback);
  };

  public unsubscribeOnOpenMagneticStripeScannerError = (callback: Subscriber) => {
    this.removeSubscriber(MagneticStripeScannerEvents.OpenMagneticStripeScannerError, callback);
  };

  public init = async () => {
    try {
      const device = await this.requestPairScanner(this.filterDevices);

      if (device) {
        const openedDevice = await this.openHidDevice(this.handleScannerString, device);

        if (openedDevice) {
          this.removeDevice(this.connectedDevices, openedDevice);
          this.addDevice(this.openedDevices, this.filterDevices, openedDevice);
          this.triggerEvent(MagneticStripeScannerEvents.OpenMagneticStripeScanner, openedDevice);
        }
      }
    } catch (e) {
      this.triggerEvent(MagneticStripeScannerEvents.OpenMagneticStripeScannerError, e);
    }
  };
}
