import React, { useState } from 'react';
import { Box } from '@material-ui/core';

import { ImageIcon } from 'img/editor-icons';
import { UploadImageModal } from 'common/modals';
import { IAttachmentImt } from 'common/interfaces/uploadFile';

interface IProps {
  onChange: (url, height, width, alt) => void;
}

const ImageTool = ({ onChange }: IProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  const onSubmit = (uploadedImage: IAttachmentImt) => {
    onChange(uploadedImage.get('filePath'), 'auto', 'auto', '');
    onClose();
  };

  return (
    <Box aria-label="rdw-color-picker">
      <Box className="rdw-option-wrapper" onClick={() => setIsOpen(true)}>
        <img src={ImageIcon} alt="tool-icon" />
      </Box>

      {!!isOpen && (
        <UploadImageModal
          id="upload-new-member-photo"
          isOpen={isOpen}
          onSubmit={onSubmit}
          onToggle={onClose}
        />
      )}
    </Box>
  );
};

export default ImageTool;
