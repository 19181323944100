import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import { Box, List, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// interfaces
import {
  EventAttendeeType,
  EventPersonType,
  IBookingEvent,
  IBookingEventImt,
  IEventAction,
  IEventFormValues,
  IRecentAppointmentItemImt,
  IRemindAppointmentDto,
  IRestoreAppointmentsParams,
  PersonAppointmentStatus,
  PersonAttendanceType,
} from 'modules/booking/interfaces';
import { IAppointmentsState, IProfileInfoImt } from 'common/components/PersonProfile/interfaces';
// state
import * as actions from 'common/components/PersonProfile/state/appointments/actions';
import { fetchSenderAvailabilityThunk } from 'modules/booking/state/senderAvailability/actions';
import * as selectors from 'common/components/PersonProfile/state/appointments/selectors';
import { selectPersonProfile } from 'common/components/PersonProfile/state/selectors';
// constants
import { PeakModules } from 'common/constants/peakModules';
import { SenderAvailabilityTypeList } from 'modules/booking/constants/senderAvailability';
import {
  BookingNotificationsEvents,
  DefaultNotificationsEvents,
  ENotificationType,
  EShortNotificationType,
  FrontDeskAppointmentNotificationsEvents,
} from 'modules/booking/constants/notificationType';
// hooks
import { useAppDispatch } from 'store/hooks';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';

// components
import { CheckInBlock, Slider } from 'common/components/PersonProfile/components/index';
import AppointmentsItem from 'common/components/PersonProfile/components/Appointments/AppointmentsItem/AppointmentsItem';
import { Button, LoadingBackdrop, ScrollBox } from 'common/components/index';
import {
  CancelEventModal,
  DeleteEventModal,
  EditEventModal,
  EventDetailsModal,
  RemindParticipantsModal,
} from 'modules/booking/components';
import RedeemModal from 'common/components/PersonProfile/components/Appointments/RedeemModal/RedeemModal';
import { ReactComponent as AddIcon } from 'img/icons/add.svg';
import { ReactComponent as HistoryIcon } from 'img/icons/clock-rotate.svg';
// messages
import messages from 'common/components/PersonProfile/messages';
import ParticipationModal from './ParticipationModal/ParticipationModal';
import bookingMessages from 'modules/booking/messages';
import AppointmentsHistoryModal from './AppointmentsHistoryModal/AppointmentsHistoryModal';

const useStyles = makeStyles(() => ({
  appointmentsWrapper: {
    height: '100%',
    minHeight: '150px',
  },
  list: {
    padding: 0,
    height: 'calc(100% - 30px)',
    overflow: 'hidden',
    marginRight: '-16px',
  },
  disabled: {
    '&::before': {
      content: '""',
      position: 'absolute',
      zIndex: 1,
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      cursor: 'not-allowed',
    },
  },
}));

interface IProps {
  module?: PeakModules;

  personId: string;
  isDisabled?: boolean;
  isMobile?: boolean;
}

const initialModalsState: IAppointmentsState = {
  selectedAppointmentId: null,
  selectedAppointmentDate: null,
  appointmentStatus: null,
  selectedAppointment: null,
  showCancelModal: false,
  showCancelParticipationModal: false,
  showConfirmParticipationModal: false,
  showDeleteModal: false,
  showRedeemModal: false,
  showRemindModal: false,
  showHistoryModal: false,
  detailsAnchorEl: null,
  editAnchorEl: null,
};

const Appointments = ({ isDisabled, isMobile, module, personId }: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const [timezoneMoment] = useTimezoneMoment();

  // selectors
  const personProfile: IProfileInfoImt = useSelector(selectPersonProfile(personId));

  const appointments: ImmutableList<IRecentAppointmentItemImt> = useSelector(
    selectors.selectRecentPersonAppointments(personId),
  );
  const isAppointmentsLoading: boolean = useSelector(
    selectors.selectRecentPersonAppointmentsLoading(personId),
  );

  const appointmentActionResult: Partial<IBookingEventImt> = useSelector(
    selectors.selectPersonAppointmentsActionResult(personId),
  );
  const appointmentActionLoading: boolean = useSelector(
    selectors.selectPersonAppointmentsActionLoading(personId),
  );

  const appointmentDetails: IBookingEventImt = useSelector(
    selectors.selectPersonAppointmentDetails(personId),
  );

  const isAppointmentDetailsLoading: boolean = useSelector(
    selectors.selectPersonAppointmentDetailsLoading(personId),
  );

  // state
  const [modalsState, setModalsState] = useState<IAppointmentsState>(initialModalsState);
  const [isRepeatedEvent, setIsRepeatedEvent] = useState<boolean>(false);

  const classes = useStyles();

  const initialEventData: Partial<IEventFormValues> = useMemo(() => {
    const employeeType =
      module === PeakModules.FrontDesk || module === PeakModules.Members
        ? EventAttendeeType.MEMBER
        : EventAttendeeType.PROSPECT;

    return {
      allowedParticipantTypes: [employeeType],
      persons: personProfile?.size
        ? [
            {
              attendanceType: PersonAttendanceType.Default,
              joinedDate: new Date().toISOString(),
              customer: {
                id: personProfile.get('id'),
                imageUrl:
                  personProfile.getIn(['image', 'filePath']) ||
                  personProfile.getIn(['image', 'url']),
                firstName: personProfile.get('firstName'),
                lastName: personProfile.get('lastName'),
                type: employeeType,
              },
              status: PersonAppointmentStatus.Pending,
              type: EventPersonType.CUSTOMER,
            },
          ]
        : [],
    };
  }, [module, personProfile]);

  // lifecycle

  useEffect(() => {
    dispatch(actions.fetchRecentPersonAppointments(personId, module));
  }, [dispatch, module, personId]);

  useEffect(() => {
    if (appointmentActionResult?.get('date')) {
      dispatch(actions.fetchRecentPersonAppointments(personId, module));
      dispatch(actions.resetUpdateAppointmentResult(appointmentActionResult, personId));

      setModalsState(initialModalsState);
    }
  }, [appointmentActionResult, dispatch, module, personId]);

  // handlers

  const onNewAppointmentClick = (): void => {
    setModalsState(
      (prevState): IAppointmentsState => ({
        ...prevState,
        selectedAppointmentId: null,
        selectedAppointmentDate: null,
        editAnchorEl: document.body,
      }),
    );
  };

  const onOpenAppointmentDetails = useCallback((appointment): void => {
    setModalsState(
      (prevState): IAppointmentsState => ({
        ...prevState,
        selectedAppointmentId: appointment.get('id'),
        selectedAppointmentDate: appointment.get('date'),
        appointmentStatus: appointment.get('appointmentStatus'),
        detailsAnchorEl: document.body,
      }),
    );
  }, []);

  const onCloseAppointmentDetailsModal = (): void => {
    if (modalsState.showHistoryModal) {
      setModalsState({ ...initialModalsState, showHistoryModal: true });
    } else {
      setModalsState(initialModalsState);
    }
  };

  const onRestoreAppointment = useCallback(
    (appointment: IBookingEventImt): void => {
      const restoreData: IRestoreAppointmentsParams = {
        personId,
        appointmentId: appointment.get('id'),
        date: appointment.get('time')
          ? timezoneMoment(
              `${appointment.get('date')} ${appointment.get('time')}`,
              'YYYY-MM-DD HH:mm',
            )
              .utc(true)
              .format()
          : appointment.get('date'),
      };

      dispatch(actions.restorePersonAppointment(restoreData, module));
    },
    [timezoneMoment, personId, dispatch, module],
  );

  const onOpenAppointmentEdit = (appointment: IBookingEventImt): void => {
    setModalsState(
      (prevState): IAppointmentsState => ({
        ...prevState,
        selectedAppointmentId: appointment.get('id'),
        selectedAppointmentDate: appointment.get('date'),
        editAnchorEl: document.body,
      }),
    );
  };

  const onEditEventMenuClick = (appointment: IBookingEventImt): void => {
    const appointmentDate = timezoneMoment(
      `${appointment.get('date')} ${appointment.get('time')}`,
      'YYYY-MM-DD HH:mm',
    )
      .utc(true)
      .format();

    setModalsState(
      (prevState): IAppointmentsState => ({
        ...prevState,
        selectedAppointmentId: appointment.get('id'),
        selectedAppointmentDate: appointmentDate,
        editAnchorEl: document.body,
      }),
    );
  };

  const onCloseAppointmentEditModal = (): void => {
    setModalsState(initialModalsState);
  };

  const onOpenCancelModal = (appointment: IBookingEventImt): void => {
    setModalsState(
      (prevState): IAppointmentsState => ({
        ...prevState,
        selectedAppointmentId: appointment.get('id'),
        selectedAppointmentDate: appointment.get('date'),
        showCancelModal: true,
      }),
    );
    setIsRepeatedEvent(appointment.get('repeated'));
  };

  const onCloseCancelModal = (): void => {
    setModalsState(initialModalsState);
  };

  const onOpenCancelParticipationModal = (appointment: IBookingEventImt): void => {
    setModalsState(
      (prevState): IAppointmentsState => ({
        ...prevState,
        selectedAppointmentId: appointment.get('id'),
        selectedAppointmentDate: appointment.get('date'),
        showCancelParticipationModal: true,
      }),
    );
    setIsRepeatedEvent(appointment.get('repeated'));
  };

  const onOpenConfirmParticipationModal = (appointment: IBookingEventImt): void => {
    setModalsState(
      (prevState): IAppointmentsState => ({
        ...prevState,
        selectedAppointmentId: appointment.get('id'),
        selectedAppointmentDate: appointment.get('date'),
        showConfirmParticipationModal: true,
      }),
    );
    setIsRepeatedEvent(appointment.get('repeated'));
  };

  const onCloseCancelParticipationModal = (): void => {
    setModalsState(initialModalsState);
  };

  const onCloseConfirmParticipationModal = (): void => {
    setModalsState(initialModalsState);
  };

  const onOpenDeleteModal = (appointment: IBookingEventImt): void => {
    setModalsState(
      (prevState): IAppointmentsState => ({
        ...prevState,
        selectedAppointmentId: appointment.get('id'),
        selectedAppointmentDate: appointment.get('date'),
        showDeleteModal: true,
      }),
    );
    setIsRepeatedEvent(appointment.get('repeated'));
  };

  const onCloseDeleteModal = (): void => {
    setModalsState(initialModalsState);
  };

  const getEventAppointment = useCallback(
    (shortTypeEvent: EShortNotificationType): ENotificationType => {
      switch (module) {
        case PeakModules.Booking:
          return BookingNotificationsEvents[shortTypeEvent];
        case PeakModules.FrontDesk:
          return FrontDeskAppointmentNotificationsEvents[shortTypeEvent];
        default:
          return DefaultNotificationsEvents[shortTypeEvent];
      }
    },
    [module],
  );

  const onLoadSenderAvailability = useCallback(
    (type: SenderAvailabilityTypeList, shortTypeEvent: EShortNotificationType) => {
      dispatch(
        fetchSenderAvailabilityThunk([type], {
          module,
          events: [getEventAppointment(shortTypeEvent)],
        }),
      );
    },
    [dispatch, getEventAppointment, module],
  );

  const onOpenRedeemModal = (appointment: IRecentAppointmentItemImt): void => {
    setModalsState(
      (prevState): IAppointmentsState => ({
        ...prevState,
        selectedAppointment: appointment,
        showRedeemModal: true,
      }),
    );
  };

  const onOpenRemindModal = (appointment: IRecentAppointmentItemImt): void => {
    setModalsState(
      (prevState): IAppointmentsState => ({
        ...prevState,
        selectedAppointmentId: appointment.get('id'),
        selectedAppointment: appointment,
        showRemindModal: true,
      }),
    );
  };

  const onCloseRedeemModal = useCallback((): void => {
    setModalsState(initialModalsState);
  }, []);

  const getAppointmentDetails = useCallback(() => {
    if (modalsState.selectedAppointmentId) {
      dispatch(
        actions.fetchPersonAppointmentById(
          modalsState.selectedAppointmentId,
          modalsState.selectedAppointmentDate,
          personId,
          module,
        ),
      );
    }
  }, [dispatch, modalsState, module, personId]);

  const resetAppointmentDetails = useCallback(() => {
    dispatch(actions.resetPersonAppointmentDetails(null, personId));
  }, [dispatch, personId]);

  const onCreateEditAppointment = (formValues: IBookingEvent) => {
    if (!modalsState.selectedAppointmentId) {
      dispatch(actions.createAppointment(formValues, personId, module));
    } else {
      dispatch(
        actions.updatePersonAppointment(
          modalsState.selectedAppointmentId,
          modalsState.selectedAppointmentDate,
          personId,
          formValues,
          module,
        ),
      );
    }
  };

  const onCancelAppointment = (payload: IEventAction): void => {
    dispatch(
      actions.cancelAppointment(
        modalsState.selectedAppointmentId,
        modalsState.selectedAppointmentDate,
        personId,
        payload,
        module,
      ),
    );
  };
  const onCancelParticipation = ({ updateType }): void => {
    dispatch(
      actions.cancelAppointmentParticipation({
        personId,
        appointmentsState: modalsState,
        updateType,
        module,
      }),
    );
  };

  const onConfirmParticipation = ({ updateType }): void => {
    dispatch(
      actions.confirmAppointmentParticipation({
        personId,
        appointmentsState: modalsState,
        updateType,
        module,
      }),
    );
  };

  const onDeleteAppointment = (payload: IEventAction): void => {
    dispatch(
      actions.deleteAppointment(
        modalsState.selectedAppointmentId,
        modalsState.selectedAppointmentDate,
        personId,
        payload,
        module,
      ),
    );
  };

  const onRemindParticipants = (payload: IRemindAppointmentDto): void => {
    dispatch(
      actions.remindAppointment(modalsState.selectedAppointmentId, personId, payload, module),
    );
  };

  const onCloseHistoryModal = useCallback(() => {
    setModalsState(prevState => ({ ...prevState, showHistoryModal: false }));
  }, []);

  const appointmentListItems = appointments?.map(appointment => (
    <AppointmentsItem
      key={appointment.get('appointmentId')}
      appointment={appointment}
      onClick={onOpenAppointmentDetails}
      onEdit={onOpenAppointmentEdit}
      onRedeem={onOpenRedeemModal}
      onCancel={onOpenCancelModal}
      onCancelParticipation={onOpenCancelParticipationModal}
      onConfirmParticipation={onOpenConfirmParticipationModal}
      onDelete={onOpenDeleteModal}
      onRemind={onOpenRemindModal}
      onRestore={onRestoreAppointment}
      isDisabled={isDisabled}
    />
  ));

  const renderActionButtons = () => (
    <Box display="flex" gridGap={4}>
      <Button
        color="primary"
        hasHoverStyle
        onClick={() => setModalsState(prevState => ({ ...prevState, showHistoryModal: true }))}
      >
        <HistoryIcon width={20} height={20} />
      </Button>

      {!isDisabled && (
        <Button color="primary" hasHoverStyle onClick={onNewAppointmentClick}>
          <AddIcon width={20} height={20} />
        </Button>
      )}
    </Box>
  );

  return (
    <CheckInBlock
      title={<FormattedMessage {...messages.appointmentsBlockTitle} />}
      className={classes.appointmentsWrapper}
      buttonGroup={renderActionButtons()}
    >
      <LoadingBackdrop isLoading={isAppointmentsLoading} />

      {appointments?.size ? (
        <List className={classes.list}>
          {isMobile === true && <Slider rows={3}>{appointmentListItems}</Slider>}
          {isMobile === false && <ScrollBox>{appointmentListItems}</ScrollBox>}
        </List>
      ) : (
        <Box className="empty-section-placeholder">
          <Typography className="empty-text">
            <FormattedMessage {...messages.emptyAppointmentsListTextMessage} />
          </Typography>
        </Box>
      )}

      {modalsState.showRedeemModal && (
        <RedeemModal
          isOpen={modalsState.showRedeemModal}
          onClose={onCloseRedeemModal}
          isUpdateEventLoading={appointmentActionLoading}
          personId={personId}
          appointmentId={modalsState.selectedAppointment.get('id')}
          appointmentStartDate={modalsState.selectedAppointment.get('date')}
          serviceId={modalsState.selectedAppointment.get('serviceId')}
          module={module}
        />
      )}

      {modalsState.showCancelModal && (
        <CancelEventModal
          isOpen={modalsState.showCancelModal}
          onSubmit={onCancelAppointment}
          onClose={onCloseCancelModal}
          isRepeatedEvent={isRepeatedEvent}
          isUpdateEventLoading={appointmentActionLoading}
          type={SenderAvailabilityTypeList.APPOINTMENT}
          onLoadSenderAvailability={onLoadSenderAvailability}
        />
      )}

      {modalsState.showCancelParticipationModal && (
        <ParticipationModal
          dialogTitle={<FormattedMessage {...bookingMessages.cancelParticipation} />}
          isRepeatedEvent={isRepeatedEvent}
          isOpen={modalsState.showCancelParticipationModal}
          onSubmit={onCancelParticipation}
          onClose={onCloseCancelParticipationModal}
          isUpdateEventLoading={appointmentActionLoading}
        />
      )}

      {modalsState.showConfirmParticipationModal && (
        <ParticipationModal
          dialogTitle={<FormattedMessage {...bookingMessages.confirmParticipation} />}
          isRepeatedEvent={isRepeatedEvent}
          isOpen={modalsState.showConfirmParticipationModal}
          onSubmit={onConfirmParticipation}
          onClose={onCloseConfirmParticipationModal}
          isUpdateEventLoading={appointmentActionLoading}
        />
      )}

      {modalsState.showDeleteModal && (
        <DeleteEventModal
          isOpen={modalsState.showDeleteModal}
          onSubmit={onDeleteAppointment}
          onClose={onCloseDeleteModal}
          isRepeatedEvent={isRepeatedEvent}
          isUpdateEventLoading={appointmentActionLoading}
          type={SenderAvailabilityTypeList.APPOINTMENT}
          onLoadSenderAvailability={onLoadSenderAvailability}
        />
      )}

      {modalsState.showRemindModal && (
        <RemindParticipantsModal
          isOpen={modalsState.showRemindModal}
          onClose={() => setModalsState(initialModalsState)}
          onRemind={onRemindParticipants}
          module={module}
          type={SenderAvailabilityTypeList.REMIND}
          event={modalsState.selectedAppointment}
        />
      )}

      {!!modalsState.detailsAnchorEl && (
        <EventDetailsModal
          isDialog
          eventId={modalsState.selectedAppointmentId}
          eventDate={modalsState.selectedAppointmentDate}
          appointmentStatus={modalsState.appointmentStatus}
          module={module}
          anchorEl={modalsState.detailsAnchorEl}
          eventDetails={appointmentDetails}
          isEventDetailsLoading={isAppointmentDetailsLoading}
          isEventActionLoading={appointmentActionLoading}
          onLoadEvent={getAppointmentDetails}
          onEdit={onEditEventMenuClick}
          onDelete={onDeleteAppointment}
          onClose={onCloseAppointmentDetailsModal}
          onCancel={onCancelAppointment}
          onCancelParticipation={onCancelParticipation}
          onConfirmParticipation={onConfirmParticipation}
          onRestore={onRestoreAppointment}
          onRemind={onRemindParticipants}
          onResetEvent={resetAppointmentDetails}
          type={SenderAvailabilityTypeList.APPOINTMENT}
          onLoadSenderAvailability={onLoadSenderAvailability}
        />
      )}

      {!!modalsState.editAnchorEl && (
        <EditEventModal
          isDialog
          eventId={modalsState.selectedAppointmentId}
          eventDate={modalsState.selectedAppointmentDate}
          module={module}
          personId={personId}
          anchorEl={modalsState.editAnchorEl}
          initialData={initialEventData}
          event={appointmentDetails}
          onClose={onCloseAppointmentEditModal}
          onSubmit={onCreateEditAppointment}
          onResetEvent={resetAppointmentDetails}
          onLoadEvent={getAppointmentDetails}
          isEventActionLoading={appointmentActionLoading}
          isEventLoading={isAppointmentDetailsLoading}
          type={SenderAvailabilityTypeList.APPOINTMENT}
        />
      )}

      {!!modalsState.showHistoryModal && (
        <AppointmentsHistoryModal
          isOpen
          onClose={onCloseHistoryModal}
          onEventClick={onOpenAppointmentDetails}
          module={module}
          personId={personId}
        />
      )}
    </CheckInBlock>
  );
};

export default Appointments;
