import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { IAttachment } from 'common/interfaces/uploadFile';
import { FilesUploader } from 'common/components';
import { CustomTheme } from 'common/ui/interfaces';
import commonMessages from 'common/messages/messages';

interface IAttachmentsBlockProps {
  attachments: IAttachment[];
  fieldId: string;
  attachmentsIsLoading: boolean;
  onUploadProfileAttachments: (attachments: IAttachment[]) => void;
  onDeleteProfileAttachment: (attachmentId: string) => void;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  block: {
    width: '100%',
    border: `1px solid ${theme.palette.borderColor?.main}`,
    borderRadius: '3px',
  },
  blockTitle: {
    textTransform: 'uppercase',
    padding: theme.spacing(2, 0, 2, 0),
  },
}));

const AttachmentsBlock = (props: IAttachmentsBlockProps): JSX.Element => {
  const {
    attachments,
    onUploadProfileAttachments,
    onDeleteProfileAttachment,
    attachmentsIsLoading,
    fieldId,
  } = props;

  const classes = useStyles();

  return (
    <Box className={classes.block} pr={2} pl={2} pb={2.5} minWidth="50%">
      <Typography variant="h5" color="textSecondary" className={classes.blockTitle}>
        <FormattedMessage {...commonMessages.attachmentsLabel} />
      </Typography>
      <FilesUploader
        fieldId={fieldId}
        onDeleteAttachment={onDeleteProfileAttachment}
        onUploadAttachments={onUploadProfileAttachments}
        attachments={attachments}
        attachmentsIsLoading={attachmentsIsLoading}
        hasVisibleScanDocumentBtn={false}
        hasVisibleTakePhotoBtn={false}
      />
    </Box>
  );
};

export default AttachmentsBlock;
