import moment from 'moment-timezone';
import { IAttachment, IStoredFileSimpleDto } from 'common/interfaces/uploadFile';

export const generateFileName = (imageType: string, file?: File) => {
  const imageFormat = file ?? imageType.replace('image/', '.');
  return (
    file?.name ||
    `${moment()
      .unix()
      .toString()}${imageFormat}`
  );
};

export const convertAttachment = (data?: IAttachment): IStoredFileSimpleDto | null => {
  if (!data) {
    return null;
  }

  return { id: data.id, url: data.filePath };
};
