export enum ClubSettingsPaths {
  CLUB_SETTINGS = 'club-settings',

  CLUB_SETTINGS_BANK_INFO = 'bank-info',
  CLUB_SETTINGS_BILLING = 'billing',
  CLUB_SETTINGS_FEE_RATES = 'fee-rates',
  CLUB_SETTINGS_PROCESSOR_CONFIG = 'processor-config',
  CLUB_SETTINGS_TAXPAYER = 'taxpayer',
  CLUB_SETTINGS_OTHER = 'other',
  CLUB_SETTINGS_WORKING_HOURS = 'working-hours',
  CLUB_SETTINGS_KID_ZONE_SETTINGS = 'kid-zone-settings',
}
