import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

// interfaces
import {
  IFallthroughCaptureForm,
  IFallthroughCaptureImt,
} from 'common/components/PersonProfile/interfaces';
import {
  IFallthroughReasonListItem,
  IFallthroughReasonListItemImt,
} from 'common/interfaces/dictionary';
// components
import { DateTimePicker, DialogComponent, Select } from 'common/components';
// state
import { fetchDictionaryList } from 'common/state/dictionary/actions';

import { selectDictionaryList } from 'common/state/dictionary/selectors';
// constants
import { ValidationSchema } from './FallthroughCaptureValidationSchema';
import { FallthroughReasons } from 'common/components/PersonProfile/constants';
import { DictionaryList } from 'common/constants';
// hooks
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { useAppDispatch } from 'store/hooks';
// messages
import messages from 'common/components/PersonProfile/messages';
import commonMessages from 'common/messages/messages';
import modalTitles from 'common/messages/modalTitles';
import inputLabels from 'common/messages/inputLabels';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';

const useStyles = makeStyles((theme: Theme) => ({
  noteInput: {
    marginBottom: theme.spacing(1),

    '& textarea': {
      minHeight: '113px',
      maxHeight: '200px',
      overflowY: 'auto!important',
    },
  },
  noteSymbols: {
    textAlign: 'right',
    opacity: 0.4,
  },
}));

interface IProps {
  fallthroughCapture?: IFallthroughCaptureImt;
  openModal: boolean;
  onClose: () => void;
  onSubmit: (formValues: IFallthroughCaptureForm) => void;
  isSubmitting: boolean;
}

const defaultFormValues: IFallthroughCaptureForm = {
  createdDate: '',
  createdTime: null,
  fallthroughReason: '',
  fallthroughReasonId: '',
};

const FallthroughCaptureModal = ({
  openModal,
  fallthroughCapture,
  onClose,
  onSubmit,
  isSubmitting,
}: IProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const renderIntlMessage = useRenderIntlMessage();
  const [timezoneMoment] = useTimezoneMoment();

  const { createdDate, createdTime } = useMemo(() => {
    const utcDate = timezoneMoment().utc();
    return {
      createdDate: utcDate.format('YYYY-MM-DD'),
      createdTime: utcDate.format('HH:mm'),
    };
  }, [timezoneMoment]);

  const formMethods = useForm<IFallthroughCaptureForm>({
    defaultValues: { ...defaultFormValues, createdDate, createdTime },
    resolver: yupResolver(ValidationSchema),
    mode: 'all',
  });

  const fallthroughReasons: ImmutableList<IFallthroughReasonListItemImt> = useSelector(
    selectDictionaryList(DictionaryList.FALLTHROUGH_REASONS),
  );

  const { control, handleSubmit, errors, reset, watch } = formMethods;

  const values = watch(['fallthroughReason', 'fallthroughReasonId']);

  useEffect(() => {
    if (fallthroughCapture && openModal) {
      const convertedFallthroughCapture = fallthroughCapture.toJS();

      reset({
        ...defaultFormValues,
        ...convertedFallthroughCapture,
      });
    }
  }, [fallthroughCapture, openModal, reset]);

  useEffect(() => {
    dispatch(fetchDictionaryList(DictionaryList.FALLTHROUGH_REASONS));
  }, [dispatch]);

  const handleSubmitForm = (data: IFallthroughCaptureForm) => {
    onSubmit({
      ...data,
      ...(data.fallthroughReasonId === FallthroughReasons.OtherReason && {
        fallthroughReasonId: null,
      }),
    });
  };

  return (
    <DialogComponent
      title={<FormattedMessage {...modalTitles.setFallthroughCapture} />}
      submitBtnTitle={<FormattedMessage {...commonMessages.saveBtn} />}
      size="xs"
      isOpen={openModal}
      onSubmit={handleSubmit(handleSubmitForm)}
      onClose={onClose}
      loading={isSubmitting}
    >
      <FormProvider {...formMethods}>
        <form autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="fallthroughReasonId"
                render={({ onChange, value }) => (
                  <Select
                    label={<FormattedMessage {...messages.cancelMembershipReasonStepTitle} />}
                    value={value}
                    onChange={onChange}
                    fullWidth
                    error={!!errors.fallthroughReasonId}
                    helperText={renderIntlMessage(errors?.fallthroughReasonId?.message)}
                  >
                    {fallthroughReasons.toJS().map((reason: IFallthroughReasonListItem) => (
                      <MenuItem key={reason.id} value={reason.id}>
                        {reason.name}
                      </MenuItem>
                    ))}
                    <MenuItem
                      key={FallthroughReasons.OtherReason}
                      value={FallthroughReasons.OtherReason}
                    >
                      <FormattedMessage {...inputLabels.otherReason} />
                    </MenuItem>
                  </Select>
                )}
              />
            </Grid>

            {values.fallthroughReasonId === FallthroughReasons.OtherReason && (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="fallthroughReason"
                  render={({ onChange, value }) => (
                    <TextField
                      onChange={onChange}
                      value={value}
                      className={classes.noteInput}
                      label={renderIntlMessage(inputLabels.otherReason)}
                      placeholder={renderIntlMessage(inputLabels.reason)}
                      variant="outlined"
                      autoComplete="off"
                      inputProps={{ maxLength: 1000 }}
                      multiline
                      fullWidth
                      error={!!errors.fallthroughReason}
                      helperText={renderIntlMessage(errors?.fallthroughReason?.message)}
                    />
                  )}
                />

                <Typography className={classes.noteSymbols} component="p" variant="body2">
                  <FormattedMessage
                    {...commonMessages.enteredSymbolsBody}
                    values={{
                      enteredSymbolsCount: values.fallthroughReason.length,
                      symbolsCount: 1000,
                    }}
                  />
                </Typography>
              </Grid>
            )}

            <Grid item xs={12}>
              <DateTimePicker
                datePickerName="createdDate"
                timePickerName="createdTime"
                spacing={2}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </DialogComponent>
  );
};

export default FallthroughCaptureModal;
