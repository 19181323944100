import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { IconButtonProps } from '@material-ui/core/IconButton/IconButton';

const useStyles = makeStyles((theme: Theme) => ({
  actionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiButton-root': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    '& .MuiButton-root:last-child': {
      marginRight: 0,
      marginLeft: ({ hideCountOfPages }: { hideCountOfPages?: boolean }) => {
        return hideCountOfPages ? 0 : '';
      },
    },
  },
  root: {
    padding: theme.spacing(1.25, 0.75),
    minWidth: 'auto',
    backgroundColor: 'white',
  },
  startIcon: {
    margin: 0,
  },
  contained: {
    boxShadow: theme.shadows[3],
    '&.Mui-disabled': {
      boxShadow: theme.shadows[3],
      opacity: 1,
      backgroundColor: 'white',
    },
  },
}));

interface IProps {
  count: number;
  onChangePage: (event, page: number) => void;
  page: number;
  rowsPerPage: number;
  hideCountOfPages?: boolean;
  backIconButtonProps?: Partial<IconButtonProps>;
  nextIconButtonProps?: Partial<IconButtonProps>;
}

const TablePaginationActions = (props: IProps): JSX.Element => {
  const {
    count,
    page,
    rowsPerPage,
    onChangePage,
    hideCountOfPages,
    nextIconButtonProps,
    backIconButtonProps,
  } = props;

  const { disabled: disabledNextButton } = nextIconButtonProps || {};
  const { disabled: disabledBackButton } = backIconButtonProps || {};

  const theme = useTheme();

  const classes = useStyles({ hideCountOfPages });

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const MuiButtonClasses = {
    root: classes.root,
    startIcon: classes.startIcon,
    contained: classes.contained,
  };

  const remainderOfNumber = count % rowsPerPage;
  const numberOfFullPages = count / rowsPerPage;
  const hasNoReminder = remainderOfNumber === 0;

  const countOfPages = hasNoReminder ? numberOfFullPages : Math.trunc(numberOfFullPages) + 1;

  return (
    <Box className={classes.actionsWrapper}>
      <Button
        classes={MuiButtonClasses}
        variant="contained"
        onClick={handleBackButtonClick}
        disabled={disabledBackButton || page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </Button>
      {!hideCountOfPages && (
        <Typography variant="body2">{`${page + 1}/${countOfPages}`}</Typography>
      )}
      <Button
        classes={MuiButtonClasses}
        variant="contained"
        onClick={handleNextButtonClick}
        disabled={disabledNextButton || page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </Button>
    </Box>
  );
};

export default TablePaginationActions;
