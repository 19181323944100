import React from 'react';
import { Controller, Control } from 'react-hook-form';

import messages from '../messages';
import { Input } from 'common/components';
import SignFieldContainer from './SignFieldContainer';

interface ISignViaSmsField {
  control: Control;
  fieldName: string;
}

const SignViaEmailField = (props: ISignViaSmsField) => {
  const { control, fieldName } = props;
  return (
    <SignFieldContainer messageDescriptor={messages.signSendDocumentTo}>
      <Controller
        name={fieldName}
        defaultValue={null}
        control={control}
        render={({ value, onChange }) => (
          <Input
            variant="outlined"
            disabled
            fullWidth
            size="small"
            value={value}
            onChange={onChange}
          />
        )}
      />
    </SignFieldContainer>
  );
};

export default SignViaEmailField;
