import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { actionTypes } from '../../constants/register';

const initStateRegisterPos = fromJS({
  list: [],
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
  isLoading: false,
  totals: {},
  changeStatusRegisterLoading: false,
  changeStatusRegisterResult: {},
});

function registerPosReducer(state = initStateRegisterPos, action) {
  switch (action.type) {
    case actionTypes.FETCH_REGISTERS_LIST_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_REGISTERS_LIST:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta))
        .set('listLoading', false);
    case actionTypes.RESET_REGISTERS_LIST:
      return initStateRegisterPos;
    case actionTypes.FETCH_REGISTER_POS_CHANGE_STATUS_LOADING:
      return state.set('changeStatusRegisterLoading', fromJS(action.payload));
    case actionTypes.FETCH_REGISTER_POS_CHANGE_STATUS:
      return state.set('changeStatusRegisterResult', fromJS(action.payload));
    case actionTypes.RESET_CHANGE_STATUS_SUCCESS:
      return state.set('changeStatusRegisterResult', fromJS({}));
    default:
      return state;
  }
}

const initStateRegisterById = fromJS({
  register: {},
  isRegisterLoading: false,
  actionResult: {},
});

function registerByIdReducer(state = initStateRegisterById, action) {
  switch (action.type) {
    case actionTypes.FETCH_REGISTER_POS_BY_ID_LOADING:
      return state.set('isRegisterLoading', action.payload);
    case actionTypes.FETCH_REGISTER_POS_BY_ID:
      return state.set('register', fromJS(action.payload));
    case actionTypes.CREATE_REGISTER_POS_BY_ID:
    case actionTypes.UPDATE_REGISTER_POS_BY_ID:
      return state.set('actionResult', fromJS(action.payload));
    case actionTypes.RESET_REGISTER_BY_ID:
      return initStateRegisterById;
    default:
      return state;
  }
}

const initDataSearchState = fromJS({
  data: null,
  isLoading: false,
});

function registerItemsByNameSearchReducer(state = initDataSearchState, action) {
  switch (action.type) {
    case actionTypes.FETCH_SEARCH_ITEMS_BY_NAME:
      return state.set('data', fromJS(action.payload));
    case actionTypes.FETCH_SEARCH_ITEMS_BY_LOADING:
      return state.set('isLoading', action.payload);
    default:
      return state;
  }
}

function registerItemsByRcodeSearchReducer(state = initDataSearchState, action) {
  switch (action.type) {
    case actionTypes.FETCH_SEARCH_ITEMS_BY_CODE:
      return state.set('data', fromJS(action.payload));
    case actionTypes.FETCH_SEARCH_ITEMS_BY_LOADING:
      return state.set('isLoading', action.payload);
    default:
      return state;
  }
}

function registerRcodesByNameSearchReducer(state = initDataSearchState, action) {
  switch (action.type) {
    case actionTypes.FETCH_RCODES_BY_NAME:
      return state.set('data', fromJS(action.payload));
    case actionTypes.FETCH_RCODES_BY_NAME_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_RCODES_BY_NAME:
      return initDataSearchState;
    default:
      return state;
  }
}

export default combineReducers({
  registerList: registerPosReducer,
  registerItem: registerByIdReducer,

  registerItemsByNameList: registerItemsByNameSearchReducer,
  registerItemsByRcodeList: registerItemsByRcodeSearchReducer,
  registerRcodesByNameList: registerRcodesByNameSearchReducer,
});
