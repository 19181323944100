import { IntlShape, MessageDescriptor } from 'react-intl';

class IntlService {
  private intl: IntlShape;

  public init(intl: IntlShape): void {
    this.intl = intl;
  }

  public formatMessage(message: MessageDescriptor): string {
    return this.intl.formatMessage(message);
  }
}

export default new IntlService();
