import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from 'common/messages/messages';
import profileMessages from '../../messages';
import notesMessages from '../../components/Notes/messages/notes';

import { DialogComponent, NumberTextField, TextArea } from 'common/components/index';
import { Controller, useForm } from 'react-hook-form';
import { defaultNumberFormatProps } from 'common/components/NumberController/NumberController';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { Box } from '@material-ui/core';
import { ITopUpRewardsData } from '../../interfaces';
import { useDispatch } from 'react-redux';
import { updatePersonRewardPoints } from '../../state/actions';
import { usePersonSelectorTemplate } from '../../hooks/usePersonSelector';
import { selectIsUpdateRewardsLoading } from '../../state/selectors';

interface ITopUpRewardsModal {
  isOpen: boolean;
  onClose: () => void;
  personId: string;
  personRewards: number;
}

const TopUpRewardsModal = ({
  isOpen,
  onClose,
  personId,
  personRewards,
}: ITopUpRewardsModal): JSX.Element => {
  const renderIntlMessage = useRenderIntlMessage();
  const dispatch = useDispatch();
  const usePersonSelector = usePersonSelectorTemplate(personId);

  const isUpdateRewardsLoading: boolean = usePersonSelector(selectIsUpdateRewardsLoading);

  const onSubmit = (data: ITopUpRewardsData) => {
    dispatch(updatePersonRewardPoints(personId, data, onClose));
  };

  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      note: '',
      points: personRewards,
    },
    mode: 'onSubmit',
  });

  return (
    <DialogComponent
      size="xs"
      title={<FormattedMessage {...profileMessages.rewardsTitle} />}
      submitBtnTitle={<FormattedMessage {...messages.saveBtn} />}
      isOpen={isOpen}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
      disableFullScreenBehavior
      loading={isUpdateRewardsLoading}
      disabled={isUpdateRewardsLoading}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="points"
          render={({ value, onChange, onBlur }) => (
            <NumberTextField
              value={value}
              onChange={onChange}
              variant="outlined"
              onBlur={onBlur}
              fullWidth
              label={<FormattedMessage {...profileMessages.amountRewardsLabel} />}
              numberFormatProps={{
                ...defaultNumberFormatProps,
                maxLength: 11,
                allowNegative: true,
              }}
              error={!!errors.points}
              helperText={renderIntlMessage(errors.points?.message)}
            />
          )}
        />
        <Box mt={2}>
          <Controller
            name="note"
            control={control}
            render={({ value, onChange, onBlur }) => (
              <TextArea
                value={value}
                label={<FormattedMessage {...notesMessages.note} />}
                variant="outlined"
                autoComplete="none"
                fullWidth
                minRows={1}
                maxRows={6}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.note}
                helperText={renderIntlMessage(errors?.note?.message)}
                maxSymbols={255}
              />
            )}
          />
        </Box>
      </form>
    </DialogComponent>
  );
};

export default TopUpRewardsModal;
