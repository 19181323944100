import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { initialState } from 'common/constants/initialState';
import { actionTypes } from 'modules/timeclock/constants';
import { actionTypes as kioskModeActionTypes } from 'common/state/kioskMode/constants';
import {
  IPayrollReportActivityDTO,
  TPayrollActivityReport,
} from 'modules/timeclock/interfaces/timeclock';

const initTimeclockActions = fromJS({
  clockInAction: initialState,
  timeclockJobPositions: initialState,

  timeclockList: initialState,
  clockedInActionResult: null,

  unlockModeTimeclockResult: null,
  isUnlockModeTimeclockLoading: false,
  unlockModeTimeclockError: null,

  clockInEmployeeActionResult: null,
  clockInEmployeeError: null,
  deleteTimeclockByIdResult: null,

  eventDetails: null,
  eventDetailsLoading: false,

  allEvents: [],
  allEventsLoading: false,
});

function timeclockReducer(state = initTimeclockActions, action) {
  switch (action.type) {
    case actionTypes.CLOCK_IN_SUCCESS:
      return state.setIn(['clockInAction', 'result'], action.payload);
    case actionTypes.CLOCK_IN_LOADING:
      return state.setIn(['clockInAction', 'isLoading'], action.payload);
    case actionTypes.RESET_CLOCK_IN_ACTION_RESULT:
      return state.setIn(['clockInAction', 'result'], null);

    case actionTypes.GET_EMPLOYEE_TIMECLOCK_JOB_POSITIONS_SUCCESS:
      return state.setIn(['timeclockJobPositions', 'result'], action.payload);
    case actionTypes.GET_EMPLOYEE_TIMECLOCK_JOB_POSITIONS_LOADING:
      return state.setIn(['timeclockJobPositions', 'isLoading'], action.payload);
    case actionTypes.GET_EMPLOYEE_TIMECLOCK_JOB_POSITIONS_ERROR:
      return state.setIn(['timeclockJobPositions', 'error'], action.payload);

    case actionTypes.GET_TIMECLOCK_LIST_SUCCESS:
      return state.setIn(['timeclockList', 'result'], action.payload);
    case actionTypes.GET_TIMECLOCK_LIST_LOADING:
      return state.setIn(['timeclockList', 'isLoading'], action.payload);
    case actionTypes.GET_TIMECLOCK_LIST_ERROR:
      return state.setIn(['timeclockList', 'error', action.payload]);

    case actionTypes.CLOCK_IN_EMPLOYEE_POSITION_SUCCESS:
    case actionTypes.CLOCK_OUT_EMPLOYEE_POSITION_SUCCESS:
      return state.set('clockedInActionResult', action.payload);

    case kioskModeActionTypes.UNLOCK_KIOSK_MODE:
      return state.set('unlockModeTimeclockResult', action.payload);
    case kioskModeActionTypes.UNLOCK_KIOSK_MODE_LOADING:
      return state.set('isUnlockModeTimeclockLoading', action.payload);
    case kioskModeActionTypes.UNLOCK_KIOSK_MODE_ERROR:
      return state.set('unlockModeTimeclockError', action.payload);

    case actionTypes.CLOCK_IN_TIMECLOCK_SIGN_IN_SUCCESS:
      return state.set('clockInEmployeeActionResult', action.payload);
    case actionTypes.CLOCK_IN_TIMECLOCK_SIGN_IN_ERROR:
      return state.set('clockInEmployeeError', action.payload);

    case actionTypes.DELETE_TIMECLOCK_BY_ID_SUCCESS:
      return state.set('deleteTimeclockByIdResult', action.payload);

    case actionTypes.GET_PERSON_EVENT_SUCCESS:
      return state.set('eventDetails', fromJS(action.payload));
    case actionTypes.GET_PERSON_EVENT_LOADING:
      return state.set('eventDetailsLoading', action.payload);

    case actionTypes.GET_ALL_PROFILE_EVENTS_SUCCESS:
      return state.set('allEvents', action.payload.data);
    case actionTypes.GET_ALL_PROFILE_EVENTS_LOADING:
      return state.set('allEventsLoading', action.payload);
    default:
      return state;
  }
}

const initPayrollReportActions = fromJS({
  payrollReportList: initialState,
  clockInDataList: initialState,
  unitDataList: initialState,
  myPayrollReportList: initialState,
  payrollStatistics: initialState,
  addClockInResult: null,
  addUnitResult: null,
  getAllTimeclockEvents: initialState,
  payrollPayPeriods: initialState,
  currentPayPeriod: null,
  payrollJobPositions: initialState,
  deleteClockInItemResult: null,
  deletePayrollItemResult: null,
  deleteUnitDataItemResult: null,
  selectedEmployeePayrollReport: null,

  payrollReportActivity: initialState,
});

const transformPayrollActivityChartData = (
  data: IPayrollReportActivityDTO,
): TPayrollActivityReport[] => {
  return Object.keys(data.activity).map(fieldKey => ({
    date: fieldKey,
    ...data.activity[fieldKey],
  }));
};

// TODO: Convert all data to immutable !!!
function payrollReportReducer(state = initPayrollReportActions, action) {
  switch (action.type) {
    case actionTypes.GET_FULL_PAYROLL_REPORT_LIST_SUCCESS:
      return state.setIn(['payrollReportList', 'result'], action.payload);
    case actionTypes.GET_FULL_PAYROLL_REPORT_LIST_LOADING:
      return state.setIn(['payrollReportList', 'isLoading'], action.payload);

    case actionTypes.GET_CLOCK_IN_DATA_LIST_SUCCESS:
      return state.setIn(['clockInDataList', 'result'], action.payload);
    case actionTypes.GET_CLOCK_IN_DATA_LIST_LOADING:
      return state.setIn(['clockInDataList', 'isLoading'], action.payload);

    case actionTypes.GET_UNIT_DATA_LIST_SUCCESS:
      return state.setIn(['unitDataList', 'result'], action.payload);
    case actionTypes.GET_UNIT_DATA_LIST_LOADING:
      return state.setIn(['unitDataList', 'isLoading'], action.payload);

    case actionTypes.GET_MY_FULL_PAYROLL_REPORT_LIST_SUCCESS:
      return state.setIn(['myPayrollReportList', 'result'], action.payload);
    case actionTypes.GET_MY_FULL_PAYROLL_REPORT_LIST_LOADING:
      return state.setIn(['myPayrollReportList', 'isLoading'], action.payload);

    case actionTypes.GET_PAYROLL_STATISTICS_SUCCESS:
      return state.setIn(['payrollStatistics', 'result'], action.payload);
    case actionTypes.GET_PAYROLL_STATISTICS_LOADING:
      return state.setIn(['payrollStatistics', 'isLoading'], action.payload);

    case actionTypes.ADD_CLOCK_IN_MANUALLY_SUCCESS:
      return state.set('addClockInResult', action.payload);
    case actionTypes.ADD_CLOCK_IN_UNIT_MANUALLY_SUCCESS:
      return state.set('addUnitResult', action.payload);

    case actionTypes.GET_TIMECLOCK_EVENTS_SUCCESS:
      return state.setIn(['getAllTimeclockEvents', 'result'], action.payload);
    case actionTypes.GET_TIMECLOCK_EVENTS_LOADING:
      return state.setIn(['getAllTimeclockEvents', 'isLoading'], action.payload);

    case actionTypes.GET_PAYROLL_PAY_PERIODS_SUCCESS:
      return state.setIn(['payrollPayPeriods', 'result'], action.payload);
    case actionTypes.SET_CURRENT_PAYROLL_PAY_PERIOD:
      return state.set('currentPayPeriod', action.payload);
    case actionTypes.GET_PAYROLL_PAY_PERIODS_LOADING:
      return state.setIn(['payrollPayPeriods', 'isLoading'], action.payload);

    case actionTypes.GET_TIMECLOCK_JOB_POSITIONS_SUCCESS:
      return state.setIn(['payrollJobPositions', 'result'], action.payload);
    case actionTypes.GET_TIMECLOCK_JOB_POSITIONS_LOADING:
      return state.setIn(['payrollJobPositions', 'isLoading'], action.payload);

    case actionTypes.DELETE_PAYROLL_ITEM_SUCCESS:
      return state.set('deletePayrollItemResult', action.payload);

    case actionTypes.DELETE_CLOCK_IN_DATA_ITEM_SUCCESS:
      return state.set('deleteClockInItemResult', action.payload);

    case actionTypes.DELETE_UNIT_DATA_ITEM_SUCCESS:
      return state.set('deleteUnitDataItemResult', action.payload);

    case actionTypes.GET_SELECTED_EMPLOYEE_PAYROLL_REPORT:
      return state.set('selectedEmployeePayrollReport', action.payload);
    case actionTypes.FETCH_PAYROLL_REPORT_ACTIVITY:
      return state.setIn(
        ['payrollReportActivity', 'result'],
        fromJS(transformPayrollActivityChartData(action.payload)),
      );
    case actionTypes.FETCH_PAYROLL_REPORT_ACTIVITY_LOADING:
      return state.setIn(['payrollReportActivity', 'isLoading'], action.payload);
    default:
      return state;
  }
}

export default combineReducers({
  timeclockActions: timeclockReducer,
  payrollReportActions: payrollReportReducer,
});
