/* eslint func-names: 0 */
/* eslint @typescript-eslint/ban-types: 0 */
/* eslint @typescript-eslint/no-this-alias: 0 */
/* eslint @typescript-eslint/no-implied-eval: 0 */

export type Procedure = (...args: any[]) => void;

export type Options = {
  isImmediate: boolean;
};

const DEBOUNCE_DELAY = 500;

export function debounce<F extends Procedure>(
  func: F,
  waitMilliseconds = DEBOUNCE_DELAY,
  options: Options = {
    isImmediate: false,
  },
): F {
  let timeoutId: number | undefined;

  return function(this: any, ...args: any[]) {
    const context = this;

    const doLater: Function = function() {
      timeoutId = undefined;
      if (!options.isImmediate) {
        func.apply(context, args);
      }
    };

    const shouldCallNow = options.isImmediate && timeoutId === undefined;

    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }

    timeoutId = window.setTimeout(doLater, waitMilliseconds);

    if (shouldCallNow) {
      func.apply(context, args);
    }
  } as any;
}
