import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { actionTypes } from '../../constants';
import { initialState } from 'common/constants/initialState';

const initStatePoliciesList = fromJS({
  list: [],
  dictionaryList: [],
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
  isLoading: false,
});

function policiesListReducer(state = initStatePoliciesList, action) {
  switch (action.type) {
    case actionTypes.FETCH_POLICIES_LIST_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_POLICIES_LIST:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta))
        .set('listLoading', false);
    case actionTypes.FETCH_POLICIES_DICTIONARY_LIST:
      return state.set('dictionaryList', fromJS(action.payload.data)).set('listLoading', false);
    case actionTypes.RESET_POLICIES_LIST:
      return initStatePoliciesList;
    default:
      return state;
  }
}

const initStatePolicyItem = fromJS({
  policyItemData: {},
  isLoading: false,
  request: initialState,
});

function policyItemReducer(state = initStatePolicyItem, action) {
  switch (action.type) {
    case actionTypes.FETCH_POLICY_ITEM_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_POLICY_ITEM:
      return state.set('policyItemData', fromJS(action.payload));
    case actionTypes.CREATE_POLICY_ITEM_LOADING:
      return state.setIn(['request', 'isLoading'], fromJS(action.payload));
    case actionTypes.EDIT_POLICY_ITEM_SUCCESS:
      return state.setIn(['request', 'result'], fromJS(action.payload));
    case actionTypes.CREATE_POLICY_ITEM_SUCCESS:
      return state.setIn(['request', 'result'], fromJS(action.payload));
    case actionTypes.RESET_POLICY_ITEM_DATA:
      return initStatePolicyItem;
    case actionTypes.RESET_POLICY_ITEM_ACTION_RESULT:
      return state.set('request', fromJS(initialState));
    default:
      return state;
  }
}

export default combineReducers({
  policiesList: policiesListReducer,
  policyItem: policyItemReducer,
});
