import { IDayTimeAvailabilityDto, Weekday } from '../../../common/interfaces/common';

export function sortAvailabilities(
  availabilities: IDayTimeAvailabilityDto[],
): IDayTimeAvailabilityDto[] {
  const sortedAvailabilities: IDayTimeAvailabilityDto[] = [];

  Object.values(Weekday).forEach(weekDay => {
    const availability = availabilities.find(item => item.weekday === weekDay);

    if (availability) {
      sortedAvailabilities.push(availability);
    }
  });

  return sortedAvailabilities;
}
