import * as actions from './user';
import { http, afterResolve } from 'helpers';
import { fromJS } from 'immutable';

export const STATE_KEY = 'person';

export const verify = (lastName: any, payer_number: any) => (
  dispatch: any,
  getState: any
) => {
  return http()
    .get('people/action/verify', {
      params: {
        lastName,
        payer_number
      }
    })
    .then(async body => await afterResolve(body))
    .then((response: any) => {
      return response.data;
    });
};

export const charges = (id: any, include: any) => (
  dispatch: any,
  getState: any
) => {
  return http()
    .get(`people/${id}/charges`, {
      params: { include }
    })
    .then(async body => await afterResolve(body))
    .then((response: any) => {
      return response.data;
    });
};

export const notes = (id: any, include: any) => (
  dispatch: any,
  getState: any
) => {
  return http()
    .get(`people/${id}/notes`, {
      params: { include }
    })
    .then(async body => await afterResolve(body))
    .then((response: any) => {
      return response.data;
    });
};

export const services = (id: any, include: any) => (
  dispatch: any,
  getState: any
) => {
  return http()
    .get(`people/${id}/services`, {
      params: { include }
    })
    .then(async body => await afterResolve(body))
    .then((response: any) => {
      return response.data;
    });
};

export const postNote = (id: any, data: any) => (
  dispatch: any,
  getState: any,
  { serialize }: any
) => {
  const request = serialize('note', data);
  return http()
    .post(`people/${id}/notes`, request)
    .then(async body => await afterResolve(body))
    .then((response: any) => {
      return response.data;
    });
};

export const postAddress = (id: any, data: any) => (
  dispatch: any,
  getState: any,
  { serialize }: any
) => {
  const request = serialize('address', data);
  return http()
    .post(`people/${id}/addresses`, request)
    .then(async body => await afterResolve(body))
    .then((response: any) => {
      return response.data;
    });
};

export const postOrPatchAddress = (id: any, data: any, address_id: any) => (
  dispatch: any,
  getState: any,
  { serialize }: any
) => {
  const request = serialize('address', data, id);

  if (address_id) {
    return http()
      .patch(`addresses/${id}`, request)
      .then(async body => await afterResolve(body))
      .then((response: any) => {
        return response.meta;
      });
  }
    return http()
      .post(`people/${id}/addresses`, request)
      .then(async body => await afterResolve(body))
      .then((response: any) => {
        return response.meta;
      });

};
export const postPhone = (id: any, data: any) => (
  dispatch: any,
  getState: any,
  { serialize }: any
) => {
  const request = serialize('phone', data);
  return http()
    .post(`people/${id}/phones`, request)
    .then(async body => await afterResolve(body))
    .then((response: any) => {
      return response.data;
    });
};

export const patch = (id: any, data: any) => (
  dispatch: any,
  getState: any,
  { serialize }: any
) => {
  const request = serialize('person', data, id);
  return http()
    .patch(`people/${id}`, request)
    .then(async body => await afterResolve(body))
    .then((response: any) => {
      return response.meta;
    });
};

export const correctAnswers = (
  lastName: any,
  payer_number: any,
  data: any
) => (dispatch: any, getState: any, { serialize }: any) => {
  const request = serialize('question', data, null, {
    meta: { lastName, payer_number }
  });
  return http()
    .patch('people/action/verify', request)
    .then(async body => await afterResolve(body))
    .then((response: any) => {
      return response.meta;
    });
};

// Due to JSON:API requirements this method unfortunately must make 3 sequential API requests.
// In conjunction with additional backend updates this should later become a single POST to /{new_accounts_endpoint)
// https://app.asana.com/0/0/1174196823960403/f
export const signUp = (state: any) => (
  dispatch: any,
  getState: any,
  { serialize }: any
) => {
  actions.clear();
  return http()
    .patch(
      'people/action/account',
      serialize('person', { email: state.email }, state.personId, {
        meta: { personId: state.personId }
      })
    )
    .then(async body => await afterResolve(body))
    .then(() => {
      return http()
        .post(
          'people/action/account',
          serialize(
            'phone',
            {
              phone: state.phone,
              permissionForSms: state.receiveTexts,
              permissionForAutomatedCall: state.receiveTexts,
              phoneType: 'mobile',
              state: 'valid',
              canReceiveSms: true
            },
            null,
            {
              meta: { personId: state.personId }
            }
          )
        )
        .then(() => {
          return http()
            .post(
              'people/action/account',
              serialize(
                'user',
                {
                  username: state.username,
                  password: state.password
                },
                null,
                {
                  meta: { personId: state.personId }
                }
              )
            )
            .then((response: any) => {
              return response.data;
            });
        });
    });
};

export const get = (id: any, include: any) => (
  dispatch: any,
  getState: any
) => {
  return http()
    .get(`people/${id}`, {
      params: { include }
    })
    .then(async body => await afterResolve(body))
    .then((response: any) => {
      return response;
    });
};

export const updatePerson = (values: any) => {
  if (values.token) {
    localStorage.setItem('token', values.token);
  }
  return {
    type: 'UPDATE_PERSON',
    payload: values
  };
};

export const clearPerson = () => {
  localStorage.removeItem('token');
  return {
    type: 'CLEAR_PERSON'
  };
};

const initialState = fromJS(null);

export default function reducer(state: any = initialState, action: any) {
  switch (action.type) {
    case 'GET_PERSON':
      return state === null
        ? fromJS(action.payload)
        : state.mergeDeep(action.payload);
    case 'UPDATE_PERSON':
      return state === null
        ? fromJS(action.payload)
        : state.mergeDeep(action.payload);
    case 'CLEAR_PERSON':
      return initialState;
    default:
      return state;
  }
}
