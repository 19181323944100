import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, makeStyles, Box, IconButton, Typography } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { ReactComponent as BackArrowIcon } from 'img/arrow-circle-left.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleGridItem: {
      marginRight: 'auto',
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    backButton: {
      padding: 0,
      marginRight: theme.spacing(1),
      backgroundColor: 'transparent',
    },
    backArrowIcon: {
      width: 18,
      height: 18,
      color: theme.palette.text.primary,
    },
  }),
);

export interface IToolbarProps {
  title: string | JSX.Element;
  showAddButton?: boolean;
  customAddButton?: React.ReactNode;
  backRedirectLink?: string;
  renderHeaderRight?: () => React.ReactNode;
}

const TablePageTitle = (props: IToolbarProps): JSX.Element => {
  const classes = useStyles();
  const { title, showAddButton, customAddButton, backRedirectLink, renderHeaderRight } = props;

  const renderBackButton = () => {
    return (
      backRedirectLink && (
        <Link to={backRedirectLink}>
          <IconButton className={classes.backButton}>
            <BackArrowIcon className={classes.backArrowIcon} />
          </IconButton>
        </Link>
      )
    );
  };

  const renderAddButton = () => {
    return showAddButton && customAddButton ? <Grid item>{customAddButton}</Grid> : <></>;
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
      <Grid item className={classes.titleGridItem}>
        <Box className={classes.titleContainer}>
          {renderBackButton()}

          <Typography component="h1" variant="h2">
            {title}
          </Typography>
        </Box>
      </Grid>
      {renderAddButton()}
      {!!renderHeaderRight && <Grid item>{renderHeaderRight()}</Grid>}
    </Grid>
  );
};

export default TablePageTitle;
