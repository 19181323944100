import { createSelector } from 'reselect';
import { AppStoreTypeImt } from 'store';

// Campaigns
const selectCampaignDomain = (state: AppStoreTypeImt) => {
  return state.getIn(['crmModule', 'campaignsTable']);
};

const selectCampaigns = () => createSelector(selectCampaignDomain, state => state.get('list'));

const selectCampaignsMeta = () => createSelector(selectCampaignDomain, state => state.get('meta'));

const selectCampaignsLoading = () =>
  createSelector(selectCampaignDomain, state => state.get('listLoading'));

const selectArchiveCampaignsLoading = () =>
  createSelector(selectCampaignDomain, state => state.get('archiveCampaignsLoading'));

const selectArchiveCampaignsResult = () =>
  createSelector(selectCampaignDomain, state => state.get('archiveCampaignsResult'));

// Create campaign
const selectEmployeeCreateFormDomain = (state: AppStoreTypeImt) =>
  state.getIn(['crmModule', 'createCampaignForm']);

const selectCreatedCampaign = () =>
  createSelector(selectEmployeeCreateFormDomain, state => state.get('createdCampaign'));

const selectCampaignCreating = () =>
  createSelector(selectEmployeeCreateFormDomain, state => state.get('campaignCreating'));

// Update campaign
const selectCampaignUpdateFormDomain = (state: AppStoreTypeImt) =>
  state.getIn(['crmModule', 'updateCampaignForm']);

const selectCampaignLoading = () =>
  createSelector(selectCampaignUpdateFormDomain, state => state.get('campaignLoading'));

const selectCampaign = () =>
  createSelector(selectCampaignUpdateFormDomain, state => state.get('campaign'));

const selectCampaignUpdating = () =>
  createSelector(selectCampaignUpdateFormDomain, state => state.get('campaignUpdating'));

const selectUpdateCampaignActionResult = () =>
  createSelector(selectCampaignUpdateFormDomain, state => state.get('updateCampaignResult'));

export {
  // Campaigns
  selectCampaigns,
  selectCampaignsMeta,
  selectCampaignsLoading,
  selectArchiveCampaignsLoading,
  selectArchiveCampaignsResult,
  // Create campaign
  selectCreatedCampaign,
  selectCampaignCreating,
  // Update campaign
  selectCampaign,
  selectCampaignLoading,
  selectCampaignUpdating,
  selectUpdateCampaignActionResult,
};
