import React from 'react';
import { DialogComponent } from 'common/components';
import { Box, Typography } from '@material-ui/core';
import { ReasonType } from 'common/interfaces/dialog';

export interface IQuestionnaireModal {
  isOpen: boolean;
  onClose: (e: React.BaseSyntheticEvent | unknown, reason: ReasonType) => void;
  onSubmit?: () => void;
  title?: JSX.Element | string;
  body?: JSX.Element | string;
  submitBtnTitle?: JSX.Element | string;
  cancelBtnTitle?: JSX.Element | string;
  additionalActionButtons?: JSX.Element | JSX.Element[];
  submitBtn?: boolean;
  cancelBtn?: boolean;
  isLoading?: boolean;
}

const QuestionnaireModal = (props: IQuestionnaireModal): JSX.Element => {
  const {
    isOpen,
    onClose,
    onSubmit,
    title,
    submitBtnTitle,
    cancelBtnTitle,
    additionalActionButtons,
    cancelBtn,
    submitBtn,
    body,
    isLoading = false,
  } = props;

  return (
    <DialogComponent
      size="xs"
      title={title}
      submitBtnTitle={submitBtnTitle}
      cancelBtnTitle={cancelBtnTitle}
      cancelBtn={cancelBtn}
      submitBtn={submitBtn}
      additionalActionButtons={additionalActionButtons}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      disableFullScreenBehavior
      loading={isLoading}
    >
      {body && (
        <Box display="flex" alignItems="center">
          <Typography component="p">
            <Typography component="span">{body}</Typography>
          </Typography>
        </Box>
      )}
    </DialogComponent>
  );
};

export default QuestionnaireModal;
