import React from 'react';

import { ReactComponent as VisaIcon } from 'img/card-icons/visa.svg';
import { ReactComponent as MasterCardIcon } from 'img/card-icons/master-card.svg';
import { ReactComponent as AmexIcon } from 'img/card-icons/amex.svg';
import { ReactComponent as DiscoverIcon } from 'img/card-icons/discover.svg';
import { ReactComponent as DefaultCard } from 'img/icons/payment-method-card.svg';

import { CreditCardTypes, PDCreditCardType } from 'common/components/CreditCardData/interfaces';

export const getCreditCardIcon = (
  type?: CreditCardTypes,
): React.FC<React.SVGProps<SVGSVGElement>> => {
  switch (type) {
    case CreditCardTypes.Visa:
      return VisaIcon;
    case CreditCardTypes.MasterCard:
      return MasterCardIcon;
    case CreditCardTypes.Amex:
      return AmexIcon;
    case CreditCardTypes.Discover:
      return DiscoverIcon;
    default:
      return DefaultCard;
  }
};

const types = {
  [PDCreditCardType.visa]: CreditCardTypes.Visa,
  [PDCreditCardType.amex]: CreditCardTypes.Amex,
  [PDCreditCardType.mastercard]: CreditCardTypes.MasterCard,
  [PDCreditCardType.discover]: CreditCardTypes.Discover,
};

// get credit card icon by PaymentDependency value
export const getCreditCardIconByPDValue = (
  paymentDependencyType: string,
): React.FC<React.SVGProps<SVGSVGElement>> => {
  const type = types[paymentDependencyType];
  return getCreditCardIcon(type);
};
