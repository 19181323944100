import React, { useMemo } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';

import useTimezoneMoment from 'common/hooks/useTimezoneMoment';

import { IReminderRecent } from 'common/components/PersonProfile/interfaces';

import {
  DATE_PICKER_DATE_FORMAT,
  DATE_PICKER_TIME_FORMAT,
} from 'common/components/DateTimePicker/DatePickerLayer';

import {
  getDisablePastTimeSchema,
  getFutureDateTimeSchema,
} from 'common/validationSchemas/dateSchemas';

import { DateTimePicker, DialogComponent, TextArea } from 'common/components/index';

import messages from 'common/components/PersonProfile/messages';
import inputLabels from 'common/messages/inputLabels';
import { getRequiredMessage } from 'common/constants/globalConstants';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (reminder: IReminderRecent) => void;
}

const initReminderValues: IReminderRecent = {
  text: '',
  dueDate: null,
  dueTime: null,
  salesperson: null,
};

const ReminderValidationSchema = yup.object().shape({
  dueDate: getFutureDateTimeSchema('dueTime'),
  dueTime: getDisablePastTimeSchema('dueDate'),
  text: yup
    .string()
    .nullable()
    .trim()
    .required(getRequiredMessage),
});

const ReminderModal = ({ isOpen, onClose, onSubmit }: IProps): JSX.Element => {
  const [timezoneMoment] = useTimezoneMoment();
  const renderErrorMessage = useRenderIntlMessage();

  const { dueDate, dueTime } = useMemo(() => {
    const utcDate = timezoneMoment()
      .add(10, 'minutes')
      .utc();
    return {
      dueDate: utcDate.format(DATE_PICKER_DATE_FORMAT),
      dueTime: utcDate.format(DATE_PICKER_TIME_FORMAT),
    };
  }, [timezoneMoment]);

  const formMethods = useForm({
    defaultValues: { ...initReminderValues, dueDate, dueTime },
    mode: 'all',
    resolver: yupResolver(ReminderValidationSchema),
  });

  const { control, handleSubmit, errors } = formMethods;

  const formId = 'reminder-form';

  return (
    <DialogComponent
      size="xs"
      title={<FormattedMessage {...messages.addReminderMessage} />}
      isOpen={isOpen}
      onClose={onClose}
      formId={formId}
    >
      <FormProvider {...formMethods}>
        <form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DateTimePicker datePickerName="dueDate" timePickerName="dueTime" disablePast />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="text"
                control={control}
                render={({ value, onChange }) => (
                  <TextArea
                    value={value}
                    onChange={onChange}
                    maxSymbols={1000}
                    fullWidth
                    rows={7}
                    variant="outlined"
                    label={<FormattedMessage {...inputLabels.text} />}
                    helperText={renderErrorMessage(errors?.text?.message)}
                    error={Boolean(errors?.text)}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </DialogComponent>
  );
};

export default ReminderModal;
