import { useCallback } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

type IFieldErrorReturnFunc = (
  message: MessageDescriptor | string | null | undefined,
  values?: { [key: string]: string | number },
) => string | null;

export const useRenderIntlMessage = (): IFieldErrorReturnFunc => {
  const intl = useIntl();

  return useCallback(
    (
      message: MessageDescriptor | string | null | undefined,
      values: { [key: string]: string | number },
    ): string | null => {
      if (typeof message === 'string') return message;
      if (message?.id) return intl.formatMessage(message, values);
      return null;
    },
    [intl],
  );
};
