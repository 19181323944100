import React from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import cx from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import { Theme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

import { Checkbox } from 'common/components/index';
import { ReactComponent as InfoIcon } from 'img/icons/info.svg';
import commonMessages from 'common/messages/messages';
import { TSenderAvailabilityImt } from 'modules/booking/interfaces';
import inputLabels from 'common/messages/inputLabels';

const useStyles = makeStyles((theme: Theme) => ({
  notifyGroupLabel: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  notifyItem: {
    marginRight: theme.spacing(0.5),
  },
  infoWrapper: {
    lineHeight: 0,
  },
  group: {
    justifyContent: 'space-between',
  },
  icon: {
    width: 16,
    height: 16,
  },
}));

interface INotifyControlProps {
  senderAvailability: TSenderAvailabilityImt;
  showPrintField?: boolean;
  fullContent?: boolean;
  emailFieldName: string;
  smsFieldName: string;
  notificationName: string;
  printName?: string;
  labelClassName?: string;
  label: string | JSX.Element;
}

const NotifyControlFields = (props: INotifyControlProps) => {
  const classes = useStyles();

  const { control } = useFormContext();

  const {
    senderAvailability,
    showPrintField,
    fullContent = false,
    printName = 'print',
    smsFieldName,
    emailFieldName,
    notificationName,
    labelClassName,
    label,
  } = props;

  const smsMessage = senderAvailability?.get('smsMessage');
  const emailMessage = senderAvailability?.get('emailMessage');
  const notificationMessage = senderAvailability?.get('notificationMessage');

  return (
    <FormControl fullWidth>
      <Typography className={cx(classes.notifyGroupLabel, labelClassName)}>{label}</Typography>

      <FormGroup row className={cx({ [classes.group]: fullContent })}>
        <Controller
          name={emailFieldName}
          control={control}
          render={({ value, onChange, onBlur }) => (
            <Box display="flex" alignItems="center" pr={1}>
              <FormControlLabel
                className={classes.notifyItem}
                control={
                  <Checkbox
                    checked={value}
                    onBlur={onBlur}
                    onChange={(e, v) => onChange(v)}
                    disabled={!senderAvailability?.get('emailAvailable')}
                  />
                }
                label={<FormattedMessage {...commonMessages.emailLabel} />}
              />
              {emailMessage && (
                <Tooltip title={emailMessage}>
                  <Typography className={classes.infoWrapper} color="textSecondary">
                    <InfoIcon className={classes.icon} />
                  </Typography>
                </Tooltip>
              )}
            </Box>
          )}
        />

        <Controller
          name={smsFieldName}
          control={control}
          render={({ value, onChange, onBlur }) => (
            <Box display="flex" alignItems="center" pr={1}>
              <FormControlLabel
                className={classes.notifyItem}
                control={
                  <Checkbox
                    checked={value}
                    onBlur={onBlur}
                    onChange={(e, v) => onChange(v)}
                    disabled={!senderAvailability?.get('smsAvailable')}
                  />
                }
                label={<FormattedMessage {...commonMessages.smsLabel} />}
              />
              {smsMessage && (
                <Tooltip title={smsMessage}>
                  <Typography className={classes.infoWrapper} color="textSecondary">
                    <InfoIcon className={classes.icon} />
                  </Typography>
                </Tooltip>
              )}
            </Box>
          )}
        />

        <Controller
          name={notificationName}
          control={control}
          render={({ value, onChange, onBlur }) => (
            <Box display="flex" alignItems="center" pr={showPrintField ? 1 : 0}>
              <FormControlLabel
                className={classes.notifyItem}
                control={
                  <Checkbox
                    checked={value}
                    onBlur={onBlur}
                    onChange={(e, v) => onChange(v)}
                    disabled={!senderAvailability?.get('notificationAvailable')}
                  />
                }
                label={<FormattedMessage {...commonMessages.notificationLabel} />}
              />
              {notificationMessage && (
                <Tooltip title={notificationMessage}>
                  <Typography className={classes.infoWrapper} color="textSecondary">
                    <InfoIcon className={classes.icon} />
                  </Typography>
                </Tooltip>
              )}
            </Box>
          )}
        />

        {showPrintField && (
          <Controller
            render={({ onChange, value }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={value}
                    onChange={e => onChange(e.target.checked)}
                  />
                }
                label={<FormattedMessage {...inputLabels.printReceipt} />}
              />
            )}
            control={control}
            name={printName}
            variant="outlined"
          />
        )}
      </FormGroup>
    </FormControl>
  );
};

export default NotifyControlFields;
