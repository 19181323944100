export const actionTypes = {
  // Scripts action types
  PUSH_REQUEST_ERROR: 'REQUEST_ERRORS',
  RESET_REQUEST_ERRORS: 'RESET_REQUEST_ERRORS',

  FETCH_SCRIPTS_LIST_LOADING: 'FETCH_SCRIPTS_LOADING',
  FETCH_SCRIPTS_LIST_SUCCESS: 'FETCH_SCRIPTS_SUCCESS',

  RESET_SCRIPT_FORM: 'RESET_SCRIPT_FORM',

  FETCH_SCRIPT_LOADING: 'FETCH_SCRIPT_LOADING',
  FETCH_SCRIPT_SUCCESS: 'FETCH_SCRIPT_SUCCESS',

  CREATE_SCRIPT_LOADING: 'CREATE_SCRIPT_LOADING',
  CREATE_SCRIPT_SUCCESS: 'CREATE_SCRIPT_SUCCESS',

  UPDATE_SCRIPT_LOADING: 'UPDATE_SCRIPT_LOADING',
  UPDATE_SCRIPT_SUCCESS: 'UPDATE_SCRIPT_SUCCESS',

  DELETE_SCRIPT_LOADING: 'DELETE_SCRIPT_LOADING',
  DELETE_SCRIPT_SUCCESS: 'DELETE_SCRIPT_SUCCESS',

  CHANGE_SCRIPT_STATUS_LOADING: 'CHANGE_SCRIPT_STATUS_LOADING',
  CHANGE_SCRIPT_STATUS_SUCCESS: 'CHANGE_SCRIPT_STATUS_SUCCESS',
  RESET_CHANGE_SCRIPT_STATUS_RESULT: 'RESET_CHANGE_SCRIPT_STATUS_RESULT',
};
