import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Chip, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { CheckInBlock } from 'common/components/PersonProfile/components';
import messages from 'common/components/PersonProfile/messages';
import { ReactComponent as EditIcon } from 'img/icons/pencil_deprecated.svg';
import { TAllergyImtList } from 'common/interfaces/additionalInfo';
import { CustomTheme } from 'common/ui/interfaces';
import commonMessages from 'common/messages/messages';
import { Button } from 'common/components/index';

interface IAllergiesProps {
  allergies?: TAllergyImtList;
  onOpenModal: () => void;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  chip: {
    background: theme.palette.secondary.light,
    border: 'none',
    margin: theme.spacing(0, 0.625, 0.625, 0),
  },
  editIcon: {
    width: '15px',
    height: '15px',
  },
}));

const Allergies = (props: IAllergiesProps) => {
  const { allergies, onOpenModal } = props;

  const classes = useStyles();

  return (
    <CheckInBlock
      title={<FormattedMessage {...messages.allergiesBlockTitle} />}
      buttonTitle={
        <Button hasHoverStyle color="primary">
          <EditIcon className={classes.editIcon} />
        </Button>
      }
      onClickButton={onOpenModal}
    >
      {allergies.size ? (
        allergies.map(allergy => (
          <Chip
            className={classes.chip}
            variant="outlined"
            size="small"
            label={allergy?.get('title')}
          />
        ))
      ) : (
        <Typography variant="body2" color="textSecondary">
          <FormattedMessage {...commonMessages.notSpecified} />
        </Typography>
      )}
    </CheckInBlock>
  );
};

export default Allergies;
