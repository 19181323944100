import { combineReducers } from 'redux-immutable';

import dictionaryReducer from './dictionary/reducers';
import cameFromIndicatorsReducer from './cameFromIndicators/reducers';
import additionalGuestFieldsReducer from './additionalGuestFields/reducers';
import noteCodesReducer from './noteCode/reducers';
import fallThroughReasonReducer from './fallThroughReason/reducers';
import departmentsReducer from './departments/reducers';
import endReasonReducer from './endReason/reducers';

export default combineReducers({
  cameFrom: cameFromIndicatorsReducer,
  dictionary: dictionaryReducer,
  additionalGuestFields: additionalGuestFieldsReducer,
  noteCodes: noteCodesReducer,
  fallThroughReasons: fallThroughReasonReducer,
  departments: departmentsReducer,
  endReasons: endReasonReducer,
});
