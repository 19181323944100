export enum DocumentTemplateTypes {
  DOCUMENT_CONTRACT = 'DOCUMENT_CONTRACT',
  DOCUMENT_WAIVER = 'DOCUMENT_WAIVER',
  EMAIL_EVENT_NEW = 'EMAIL_EVENT_NEW', //
  EMAIL_EVENT_EDIT = 'EMAIL_EVENT_EDIT', //
  EMAIL_EVENT_CANCEL = 'EMAIL_EVENT_CANCEL', //
  EMAIL_EVENT_REDEEM = 'EMAIL_EVENT_REDEEM', //
  EMAIL_EVENT_REMIND = 'EMAIL_EVENT_REMIND', //
  EMAIL_EVENT_DELETE = 'EMAIL_EVENT_DELETE', //
  EMAIL_CAMPAIGN = 'EMAIL_CAMPAIGN', //
  EMAIL_INVOICE = 'EMAIL_INVOICE', //
  EMAIL_CONTRACT = 'EMAIL_CONTRACT', //
  SMS_EVENT_NEW = 'SMS_EVENT_NEW',
  EMAIL_WAIVER = 'EMAIL_WAIVER',
  SMS_EVENT_EDIT = 'SMS_EVENT_EDIT',
  SMS_EVENT_CANCEL = 'SMS_EVENT_CANCEL',
  SMS_EVENT_REMIND = 'SMS_EVENT_REMIND',
  SMS_EVENT_REDEEM = 'SMS_EVENT_REDEEM',
  SMS_EVENT_DELETE = 'SMS_EVENT_DELETE',
  SMS_INVOICE = 'SMS_INVOICE',
  SMS_CAMPAIGN = 'SMS_CAMPAIGN',
  SMS_WAIVER = 'SMS_WAIVER',
  SMS_CONTRACT = 'SMS_CONTRACT',
}

export const documentTemplateForSmsTypes = [
  DocumentTemplateTypes.SMS_EVENT_NEW,
  DocumentTemplateTypes.SMS_EVENT_EDIT,
  DocumentTemplateTypes.SMS_EVENT_CANCEL,
  DocumentTemplateTypes.SMS_EVENT_REMIND,
  DocumentTemplateTypes.SMS_EVENT_REDEEM,
  DocumentTemplateTypes.SMS_EVENT_DELETE,
  DocumentTemplateTypes.SMS_CAMPAIGN,
  DocumentTemplateTypes.SMS_INVOICE,
  DocumentTemplateTypes.SMS_CONTRACT,
  DocumentTemplateTypes.SMS_WAIVER,
];

export const documentTemplateForEmailTypes = [
  DocumentTemplateTypes.EMAIL_EVENT_NEW,
  DocumentTemplateTypes.EMAIL_EVENT_EDIT,
  DocumentTemplateTypes.EMAIL_EVENT_CANCEL,
  DocumentTemplateTypes.EMAIL_EVENT_REDEEM,
  DocumentTemplateTypes.EMAIL_EVENT_REMIND,
  DocumentTemplateTypes.EMAIL_EVENT_DELETE,
  DocumentTemplateTypes.EMAIL_CAMPAIGN,
  DocumentTemplateTypes.EMAIL_INVOICE,
  DocumentTemplateTypes.EMAIL_CONTRACT,
  DocumentTemplateTypes.EMAIL_WAIVER,
];

export const documentTemplateForContractTypes = [DocumentTemplateTypes.DOCUMENT_CONTRACT];

export const documentTemplateForWaiverTypes = [DocumentTemplateTypes.DOCUMENT_WAIVER];

export enum DocumentUrls {
  contract = 'Contract',
  waiver = 'Waiver',
  membership = 'Membership',
  booking_notification = 'Booking notification',
  periodic_updates = 'Periodic updates',
  sms = 'SMS',
  email = 'Email',
}

export enum DocumentStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum TemplateTypes {
  Document = 'DOCUMENT',
  SMS = 'SMS',
  Email = 'EMAIL',
  Notification = 'NOTIFICATION',
}

export enum TemplatePurpose {
  ServiceContractTemplate = 'SERVICE_CONTRACT_TEMPLATE',
  ServiceWaiverTemplate = 'SERVICE_WAIVER_TEMPLATE',
  CampaignTemplate = 'CAMPAIGN_TEMPLATE',
  TriggerTemplate = 'TRIGGER_TEMPLATE',
}

export enum DocumentClubTags {
  clubName = 'Club Name',
  clubAddress = 'Club Address',
}

export enum DocumentCustomerTags {
  firstName = 'First Name',
  lastName = 'Last Name',
  email = 'Email',
  phone = 'Phone',
  address = 'Address',
  salesperson = 'Salesperson',
  insuranceCompany = 'Insurance Company',
  insuranceMemberId = 'Insurance Member',
  membership = 'Membership',
  waiverLink = 'Waiver link',
  waiver = 'Waiver name',
  contractLink = 'Contract link',
  contract = 'Contract name',
  signedWaivers = 'Signed waiver names',
  unsignedWaivers = 'Unsigned waiver names',
  signedContract = 'Signed contract names',
  unsignedContract = 'Unsigned contract names',
}
