import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Grid, SvgIcon, makeStyles } from '@material-ui/core';

import { CancelMembershipSteps } from 'common/components/PersonProfile/modals/CancelMembershipModal/constants';

import { CustomTheme } from 'common/ui/interfaces';

import { ArrowCircleIcon, ArrowCircleLeftIcon } from 'img/icons';
import { ButtonWithCircularProgress } from 'common/components/index';

import messages from 'common/messages/messages';

const useStyles = makeStyles((theme: CustomTheme) => ({
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 0),
  },
  btnIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
}));

interface IProps {
  currentStep: CancelMembershipSteps;
  onBack: () => void;
  onNext: () => void;
  goBack: () => void;
  isLoading: boolean;
  isLastStep: boolean;
  disabled: boolean;
}

const Footer = ({
  currentStep,
  onNext,
  goBack,
  onBack,
  isLoading,
  isLastStep,
  disabled,
}: IProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid container spacing={1} className={classes.footer}>
      <Grid item>
        <Button color="primary" onClick={goBack}>
          <FormattedMessage
            {...(currentStep === CancelMembershipSteps.Reason
              ? messages.notNowBtn
              : messages.cancelBtn)}
          />
        </Button>
      </Grid>

      {currentStep !== CancelMembershipSteps.Reason && (
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={onBack}
            startIcon={
              <SvgIcon className={classes.btnIcon}>
                <ArrowCircleLeftIcon />
              </SvgIcon>
            }
          >
            <FormattedMessage {...messages.backBtn} />
          </Button>
        </Grid>
      )}

      <Grid item>
        {currentStep === CancelMembershipSteps.PastDues ? (
          <ButtonWithCircularProgress type="submit" onClick={onNext} isSubmitting={isLoading}>
            <FormattedMessage
              {...(isLastStep ? messages.confirmAndCancelBtn : messages.proceedToPaymentBtn)}
            />
          </ButtonWithCircularProgress>
        ) : (
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={onNext}
            disabled={disabled}
            endIcon={
              <SvgIcon className={classes.btnIcon}>
                <ArrowCircleIcon />
              </SvgIcon>
            }
          >
            <FormattedMessage {...messages.nextBtn} />
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default Footer;
