import * as yup from 'yup';

const requiredFieldMessage = 'Termination date is required';

export const ChangeSatusSchema = yup.object().shape({
  terminationDate: yup
    .string()
    .nullable()
    .trim()
    .required(requiredFieldMessage),
});
