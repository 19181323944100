import { AppStoreTypeImt } from 'store';
import { ActionResult } from 'common/constants';

export const selectAddTicketLoading = (state: AppStoreTypeImt): boolean => {
  return state.getIn(['peakContactsReducer', 'isLoading']);
};

export const selectAddTicketActionResult = (state: AppStoreTypeImt): ActionResult => {
  return state.getIn(['peakContactsReducer', 'result']);
};
