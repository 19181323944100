import { defineMessages } from 'react-intl';

export default defineMessages({
  dialogDefaultSubmitTitle: {
    id: 'common.dialog.default.submit.title',
    defaultMessage: 'Save',
  },
  dialogDefaultCancelTitle: {
    id: 'common.dialog.default.cancel.title',
    defaultMessage: 'Cancel',
  },
  dialogDefaultResetTitle: {
    id: 'common.dialog.default.reset.title',
    defaultMessage: 'Reset',
  },
});
