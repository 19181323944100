export enum QueryPageList {
  RESOURCES = 'RESOURCES',
  BOOKING = 'BOOKING',
  TASKS = 'TASKS',
  LEADS = 'LEADS',
  SCRIPTS = 'SCRIPTS',
  CRM_TEMPLATES = 'CRM_TEMPLATES',
  CAMPAIGNS = 'CAMPAIGNS',
  EMPLOYEES = 'EMPLOYEES',
  REGISTER_SETTINGS = 'REGISTER_SETTINGS',
  TAX_RATES_SETTINGS = 'TAX_RATES_SETTINGS',
  REVENUE_CODES_SETTINGS = 'REVENUE_CODES_SETTINGS',
  VENDORS_SETTINGS = 'VENDORS_SETTINGS',
  INVENTORY_SETTINGS = 'INVENTORY_SETTINGS',
  INVENTORY_CONFIGURATION_SETTINGS = 'INVENTORY_CONFIGURATION_SETTINGS',
  RECEIPTS_SETTINGS = 'RECEIPTS_SETTINGS',
  RECEIPTS_CONFIGURATION_SETTINGS = 'RECEIPTS_CONFIGURATION_SETTINGS',
  PAYMENT_METHODS_DEFAULT_SETTINGS = 'PAYMENT_METHODS_DEFAULT_SETTINGS',
  PAYMENT_METHODS_CUSTOM_SETTINGS = 'PAYMENT_METHODS_CUSTOM_SETTINGS',
  PAYMENT_METHODS_CUSTOM_LIST_SETTINGS = 'PAYMENT_METHODS_CUSTOM_LIST_SETTINGS',
  PAYMENT_METHODS_CUSTOM_ITEM_CONFIGURATION_SETTINGS = 'PAYMENT_METHODS_CUSTOM_ITEM_CONFIGURATION_SETTINGS',
  INVOICE_HISTORY_REPORT = 'INVOICE_HISTORY_REPORT',
  PAYMENT_TRANSACTIONS_REPORT = 'PAYMENT_TRANSACTIONS_REPORT',
  Z_OUT_REPORT = 'Z_OUT_REPORT',
  CAMPAIGNS_REPORT = 'CAMPAIGNS_REPORT',
  DOCUMENT_TEMPLATES = 'DOCUMENT_TEMPLATES',
  SERVICES = 'SERVICES',
  SERVICE_PACKAGES = 'SERVICE_PACKAGES',
  SERVICE_DOCUMENT_TEMPLATES = 'SERVICE_DOCUMENT_TEMPLATES',
  CORPORATIONS = 'CORPORATIONS',
  CLUBS = 'CLUBS',
  FEES = 'FEES',
  PAY_PERIODS = 'PAY_PERIODS',
  TIME_CLOCK = 'TIME_CLOCK',
  BANNER_ADVERTISEMENTS = 'BANNER_ADVERTISEMENTS',
  COMMISSION_RULES = 'COMMISSION_RULES',
}
