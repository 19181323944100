/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
  FilterViewTypes,
  IDateRangeFilter,
  IDateRangeFilterProps,
  IDateRangeFilterValue,
} from 'common/interfaces/filter';

import { DateRange } from 'common/components';
import DropdownFilter from '../DropdownFilter/DropdownFilter';
import HoverFilter from '../HoverFilter/HoverFilter';
import FilterMenuBody from '../FilterMenuBody/FilterMenuBody';
import messages from '../messages';

const DateRangeFilter = ({
  viewType,
  value,
  settings: { name, title, type, options, defaultValue },
  dialog,
  onChange,
}: IDateRangeFilterProps): JSX.Element => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const initialState = value || defaultValue || { startDate: null, endDate: null };

  const [dateRangeValue, setDateRangeValue] = useState<IDateRangeFilterValue>(initialState);

  const isExistOnlyDefaultValue = Boolean(!value && defaultValue);
  const isExistValueWithDefault = Boolean(value && defaultValue);
  const isSameSelectedValueWithDefault =
    isExistValueWithDefault &&
    moment(value.startDate).isSame(defaultValue?.startDate, 'year') &&
    moment(value.endDate).isSame(defaultValue?.endDate, 'day') &&
    moment(value.startDate).isSame(defaultValue?.startDate, 'day');

  const filtersTotal = isExistOnlyDefaultValue || isSameSelectedValueWithDefault || !value ? 0 : 1;

  // effects

  useEffect(() => {
    setDateRangeValue(value || defaultValue || { startDate: null, endDate: null });
  }, [value]);

  // handlers

  const handleFilterOptionsChange = (closePicker?: () => void) => {
    const changedValue =
      !dateRangeValue.startDate && !dateRangeValue.endDate
        ? null
        : {
            startDate: dateRangeValue.startDate,
            endDate: dateRangeValue.endDate,
          };

    onChange(
      {
        name,
        type,
        value: changedValue,
      } as IDateRangeFilter,
      name,
    );

    if (closePicker) closePicker();
  };

  const handleChangeCancel = useCallback(() => {
    setDateRangeValue(value || { startDate: null, endDate: null });
  }, [value]);

  const handleFilterReset = useCallback(() => {
    setDateRangeValue(defaultValue || { startDate: null, endDate: null });
  }, [setDateRangeValue]);

  const renderFilterTitle = (): JSX.Element | React.ReactNode | string => {
    if (dialog) {
      return title;
    }

    const { startDate, endDate } = value || defaultValue || { startDate: null, endDate: null };

    if (startDate === null) {
      return <FormattedMessage {...messages.filterTitle} values={{ title, filter: 'All' }} />;
    }

    const strStartDate = moment(startDate).format('MMM Do');

    if (endDate === null) {
      return `From ${strStartDate}`;
    }

    const strEndDate = moment(endDate).format('MMM Do');

    return strStartDate === strEndDate ? strStartDate : `${strStartDate} - ${strEndDate}`;
  };

  const renderFilterBody = (closeFilterBody?: () => void) => {
    const onChangeDateRange = (dateRange: IDateRangeFilterValue) => {
      setDateRangeValue(prevState => ({ ...prevState, ...dateRange }));
    };

    return (
      <FilterMenuBody
        showClear={!dialog}
        showApply={!dialog}
        disableClear={!dateRangeValue.startDate}
        onClearFilter={handleFilterReset}
        onApplyFilter={() => handleFilterOptionsChange(closeFilterBody)}
      >
        <Box width="100%" display="flex" justifyContent="center">
          <DateRange
            value={dateRangeValue}
            months={isSmallScreen ? 1 : 2}
            onChange={onChangeDateRange}
            maxDate={options.maxDate}
            minDate={options.minDate}
          />
        </Box>
      </FilterMenuBody>
    );
  };

  const renderDropdownFilter = () => {
    return (
      <div>
        <DropdownFilter
          name={name}
          title={renderFilterTitle()}
          filtersTotal={filtersTotal}
          dialogMenu={dialog}
          onClose={handleChangeCancel}
          renderBody={renderFilterBody}
          onDialogApply={handleFilterOptionsChange}
        />
      </div>
    );
  };

  const renderHoverMenuFilter = () => {
    return (
      <HoverFilter
        name={name}
        title={renderFilterTitle()}
        filtersTotal={filtersTotal}
        dialogMenu={dialog}
        onClose={handleChangeCancel}
        renderBody={renderFilterBody}
        onDialogApply={handleFilterOptionsChange}
      />
    );
  };

  return viewType === FilterViewTypes.DROPDOWN ? renderDropdownFilter() : renderHoverMenuFilter();
};

export default React.memo(DateRangeFilter);
