import { List as ImmutableList } from 'immutable';

import { ImmutableObject } from 'common/state/interfaces';
import { IPaginatedData } from 'common/interfaces/pagination';
import { IEventPersonEntityImt } from 'modules/booking/interfaces';
import { INamedEntity, INamedEntityImt } from 'common/interfaces/common';

export enum AppointmentReportStatuses {
  CANCELLED = 'CANCELLED',
  DELETED = 'DELETED',
}

interface IAppointmentResourceItem extends INamedEntity {
  employee: INamedEntity;
}

export interface IAppointmentResourceItemImt extends ImmutableObject<IAppointmentResourceItem> {
  get<K extends keyof IAppointmentResourceItem>(key: K): IAppointmentResourceItem[K];

  get(key: 'employee'): INamedEntityImt;
}

export interface IAppointmentItem {
  date: string;
  id: string;
  name: string;
  participants: number;
  resources: IAppointmentResourceItem[];
  spotAmount: number;
  spotLimited: boolean;
  status: AppointmentReportStatuses;
  attended: number;
  tags: INamedEntity[];
}

export interface IAppointmentItemImt extends ImmutableObject<IAppointmentItem> {
  get<K extends keyof IAppointmentItem>(key: K): IAppointmentItem[K];

  get(key: 'participants'): IEventPersonEntityImt;
  get(key: 'resources'): ImmutableList<IAppointmentResourceItemImt>;
  get(key: 'tags'): ImmutableList<INamedEntityImt>;
}

export type IPaginatedAppointments = IPaginatedData<IAppointmentItem>;
