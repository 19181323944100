import React from 'react';
import { Select, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { LeadStatuses } from 'modules/crm/constants/leads';

interface IStyleProps {
  color: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    '& .MuiSelect-root': {
      padding: '2px 22px 2px 6px',
      fontWeight: 'bold',
      color: (props: IStyleProps) => props.color,
      textTransform: 'uppercase',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: (props: IStyleProps) => props.color || theme.palette.secondary.light,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: (props: IStyleProps) => props.color || theme.palette.secondary.light,
    },
    '& .MuiSelect-icon': {
      right: 0,
    },
    '& .MuiMenuItem-root': {
      textTransform: 'uppercase',
    },
  },
  selectOption: {
    textTransform: 'uppercase',
  },
}));

interface IProps {
  onChangeStatus: (status) => void;
  status: string;
}

const StatusSelect = React.memo(
  ({ status, onChangeStatus }: IProps): JSX.Element => {
    const { color } = LeadStatuses.find(status);
    const classes = useStyles({ color });

    return (
      <Select
        className={classes.select}
        variant="outlined"
        value={status}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onChangeStatus(event.target.value);
          return event;
        }}
      >
        {LeadStatuses.getSelectOptions({ styleClass: classes.selectOption })}
      </Select>
    );
  },
);

export default StatusSelect;
