import { ConstOptions } from 'common/constants/classes';
import { AlertTypes, Preferences, AlertCondition } from 'common/interfaces/alerts';

import messages from 'common/messages/alerts';

export const AlertConditions = new ConstOptions([
  {
    key: AlertCondition.Anniversary,
    value: AlertCondition.Anniversary,
    message: messages.alertConditionsAnniversary,
  },
  {
    key: AlertCondition.Birthday,
    value: AlertCondition.Birthday,
    message: messages.alertConditionsBirthday,
  },
  {
    key: AlertCondition.MissingPhoto,
    value: AlertCondition.MissingPhoto,
    message: messages.alertConditionsMissingPhoto,
  },
  {
    key: AlertCondition.MissingFirstName,
    value: AlertCondition.MissingFirstName,
    message: messages.alertConditionsMissingFirstName,
  },
  {
    key: AlertCondition.MissingLastName,
    value: AlertCondition.MissingLastName,
    message: messages.alertConditionsMissingLastName,
  },
  {
    key: AlertCondition.MissingBirthDate,
    value: AlertCondition.MissingBirthDate,
    message: messages.alertConditionsMissingBirthday,
  },
  {
    key: AlertCondition.MissingSSN,
    value: AlertCondition.MissingSSN,
    message: messages.alertConditionsMissingSSN,
  },
  {
    key: AlertCondition.MissingPhone,
    value: AlertCondition.MissingPhone,
    message: messages.alertConditionsMissingPhone,
  },
  {
    key: AlertCondition.MissingEmail,
    value: AlertCondition.MissingEmail,
    message: messages.alertConditionsMissingEmail,
  },
  {
    key: AlertCondition.MissingAddress,
    value: AlertCondition.MissingAddress,
    message: messages.alertConditionsMissingAddress,
  },
  {
    key: AlertCondition.NotRegisteredForClubLife,
    value: AlertCondition.NotRegisteredForClubLife,
    message: messages.alertConditionsNotRegisteredForClubLife,
  },
  {
    key: AlertCondition.CreditCardExpired,
    value: AlertCondition.CreditCardExpired,
    message: messages.alertConditionsCreditCardExpired,
  },
  {
    key: AlertCondition.UnsignedWaiver,
    value: AlertCondition.UnsignedWaiver,
    message: messages.alertConditionsUnsignedWaiver,
  },
  {
    key: AlertCondition.MemberWillExpireSoon,
    value: AlertCondition.MemberWillExpireSoon,
    message: messages.alertConditionsMemberWillExpireSoon,
  },
  {
    key: AlertCondition.MemberIsFrozen,
    value: AlertCondition.MemberIsFrozen,
    message: messages.alertConditionsMemberIsFrozen,
  },
  {
    key: AlertCondition.PastDuePayments,
    value: AlertCondition.PastDuePayments,
    message: messages.alertConditionsPastDuePayments,
  },
  {
    key: AlertCondition.NotAllowedAtThisLocation,
    value: AlertCondition.NotAllowedAtThisLocation,
    message: messages.alertConditionsNotAllowedAtThisLocation,
  },
  {
    key: AlertCondition.NotAllowedAtThisDayTime,
    value: AlertCondition.NotAllowedAtThisDayTime,
    message: messages.alertConditionsNotAllowedAtThisDayTime,
  },
  {
    key: AlertCondition.InactiveMember,
    value: AlertCondition.InactiveMember,
    message: messages.alertConditionsInactiveMember,
  },
  // custom alerts
  {
    key: AlertCondition.UpcomingAppointment,
    value: AlertCondition.UpcomingAppointment,
    message: messages.alertConditionsUpcomingAppointment,
  },
  {
    key: AlertCondition.MissedAppointment,
    value: AlertCondition.MissedAppointment,
    message: messages.alertConditionsMissedAppointment,
  },
  {
    key: AlertCondition.Note,
    value: AlertCondition.Note,
    message: messages.alertConditionsNote,
  },
  {
    key: AlertCondition.MissingEmergencyContactInfo,
    value: AlertCondition.MissingEmergencyContactInfo,
    message: messages.alertConditionsMissingEmergencyContactInfo,
  },
  {
    key: AlertCondition.MissingBillingInfo,
    value: AlertCondition.MissingBillingInfo,
    message: messages.alertConditionsMissingBillingInfo,
  },
  {
    key: AlertCondition.MissingInsuranceInfo,
    value: AlertCondition.MissingInsuranceInfo,
    message: messages.alertConditionsMissingInsuranceInfo,
  },
  {
    key: AlertCondition.MissingAllergies,
    value: AlertCondition.MissingAllergies,
    message: messages.alertConditionsMissingAllergies,
  },
  {
    key: AlertCondition.MissingSalesperson,
    value: AlertCondition.MissingSalesperson,
    message: messages.alertConditionsMissingSalesperson,
  },
]);

const CUSTOM_ALERT_CONDITIONS: string[] = [
  AlertCondition.UpcomingAppointment,
  AlertCondition.MissedAppointment,
  AlertCondition.Note,
  AlertCondition.MissingEmergencyContactInfo,
  AlertCondition.MissingBillingInfo,
  AlertCondition.MissingInsuranceInfo,
  AlertCondition.MissingAllergies,
  AlertCondition.MissingSalesperson,
];

export const CustomAlertConditions = new ConstOptions(
  AlertConditions.values.filter(alert => CUSTOM_ALERT_CONDITIONS.includes(alert.value)),
);

export const AlertTypesOptions = new ConstOptions([
  {
    key: AlertTypes.Danger,
    value: AlertTypes.Danger,
    message: messages.danger,
  },
  {
    key: AlertTypes.Warning,
    value: AlertTypes.Warning,
    message: messages.warning,
  },
  {
    key: AlertTypes.Info,
    value: AlertTypes.Info,
    message: messages.info,
  },
]);

export const AlertPreferences = new ConstOptions([
  {
    key: Preferences.UsualCheckInsSound,
    value: Preferences.UsualCheckInsSound,
    message: messages.usualCheckInsSound,
  },
  {
    key: Preferences.StopAtTheDoorCheckInsSound,
    value: Preferences.StopAtTheDoorCheckInsSound,
    message: messages.stopAtTheDoorCheckInsSound,
  },
  {
    key: Preferences.DisableFrontDeskCheckInsSound,
    value: Preferences.DisableFrontDeskCheckInsSound,
    message: messages.disableFrontDeskCheckInsSound,
  },
]);
