import { defineMessages } from 'react-intl';

export default defineMessages({
  // Additional Info Step
  newMemberAdditionalInfoInsurance: {
    id: 'modules.frontDesk.newMember.additionalInfo.insurance',
    defaultMessage: 'Insurance Information',
  },
  newMemberAdditionalInfoEmergency: {
    id: 'modules.frontDesk.newMember.additionalInfo.emergency',
    defaultMessage: 'Emergency Contact Information',
  },
  newMemberAdditionalInfoAllergies: {
    id: 'modules.frontDesk.newMember.additionalInfo.allergies',
    defaultMessage: 'Allergies',
  },
});
