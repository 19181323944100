import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { actionTypes } from './constants';
import { initialState } from 'common/constants/initialState';

export const initListState = fromJS({
  list: [],
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
  isLoading: false,
  error: null,
});

function noteCodesListReducer(state = initListState, action) {
  switch (action.type) {
    case actionTypes.FETCH_NOTE_CODES_LIST_IS_LOADING:
      return state.set('isLoading', action.payload).set('error', null);
    case actionTypes.FETCH_NOTE_CODES_LIST:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta));
    case actionTypes.FETCH_NOTE_CODES_LIST_ERROR:
      return state.set('error', action.payload);
    case actionTypes.RESET_NOTE_CODES_LIST:
      return initListState;
    default:
      return state;
  }
}

const initItemState = fromJS({
  itemActionRequestState: initialState,
});

function noteCodeReducer(state = initItemState, action) {
  switch (action.type) {
    case actionTypes.CREATE_OR_UPDATE_NOTE_CODE_IS_LOADING:
      return state.setIn(['itemActionRequestState', 'isLoading'], action.payload);
    case actionTypes.SET_NOTE_CODE_ACTION_RESULT:
      return state.setIn(['itemActionRequestState', 'result'], action.payload);
    case actionTypes.FETCH_NOTE_CODE_ERROR:
      return state.setIn(['itemActionRequestState', 'error'], fromJS(action.payload));
    case actionTypes.RESET_NOTE_CODE:
      return initItemState;
    default:
      return state;
  }
}

export default combineReducers({
  noteCodesList: noteCodesListReducer,
  noteCode: noteCodeReducer,
});
