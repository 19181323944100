import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Button as MaterialButton, ButtonProps, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  btn: {
    position: 'relative',
    '& .MuiButton-endIcon, & .MuiButton-startIcon': {
      opacity: isSubmitting => (isSubmitting ? 0 : 1),
    },
  },
  childWrapper: {
    opacity: isSubmitting => (isSubmitting ? 0 : 1),
  },
  loaderWrapper: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translateY(-50%) translateX(-50%)',
    color: theme.palette.primary.contrastText,
  },
  loader: {
    '& svg': {
      margin: 0,
    },
  },
}));

export interface IButtonProps extends Omit<ButtonProps, 'color'> {
  isSubmitting: boolean;
  children: React.ReactNode;
  disableBackground?: boolean;
  disabled?: boolean;
  type?: 'button' | 'reset' | 'submit';
}

const Button = ({
  children,
  isSubmitting,
  disabled,
  disableBackground,
  type = 'submit',
  ...props
}: IButtonProps): JSX.Element => {
  const classes = useStyles(!!isSubmitting);

  return (
    <MaterialButton
      className={classes.btn}
      disabled={disabled || isSubmitting}
      type={type}
      variant="contained"
      color={disableBackground ? 'secondary' : 'primary'}
      style={{ minWidth: 94 }}
      {...props}
    >
      <Box className={classes.loaderWrapper} display="flex" alignItems="center">
        {!!isSubmitting && (
          <CircularProgress size="16px" color="inherit" className={classes.loader} />
        )}
      </Box>
      <Box className={classes.childWrapper}>{children}</Box>
    </MaterialButton>
  );
};

export default Button;
