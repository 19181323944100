import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box } from '@material-ui/core';

import { IHeadCell, ITableRow } from 'common/interfaces/table';
import { CheckInBlock } from 'common/components/PersonProfile/components/index';
import { Table } from 'common/components';

// messages
import commonMessages from 'common/messages/messages';
import permissionsMessages from 'modules/permissions/messages/messages';

interface IRedeemingHistoryProps {
  tableRows: ITableRow[];
  headerOptions: IHeadCell[];
  onViewAllHistory: () => void;
}

const RedeemingHistory: React.FC<IRedeemingHistoryProps> = ({
  tableRows,
  headerOptions,
  onViewAllHistory,
}: IRedeemingHistoryProps): JSX.Element => {
  return (
    <Box mt={1}>
      <CheckInBlock
        headerDivider
        title={<FormattedMessage {...commonMessages.historyBtn} />}
        buttonTitle={<FormattedMessage {...permissionsMessages.viewAll} />}
        onClickButton={onViewAllHistory}
      >
        <Table
          hidePagination
          hideSearchInput
          headerOptions={headerOptions}
          rows={tableRows}
          isLoading={false}
        />
      </CheckInBlock>
    </Box>
  );
};

export default RedeemingHistory;
