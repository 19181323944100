// libraries
import React from 'react';
// constants
import { EditPackageStep, StepToggleAction } from 'modules/services/constants/packages';
// interfaces
import { IEditPackageDetails, IErrorsSteps } from 'modules/services/interfaces/packages';
// components
import GeneralInfoStep from './GeneralInfoStep/GeneralInfoStep';
import BillingStep from './BillingStep/BillingStep';
import InventoryStep from './InventoryStep/InventoryStep';
import MembershipStep from './MembershipStep/MembershipStep';
import ServicesStep from './ServicesStep/ServicesStep';
import FeesStep from './FeesStep/FeesStep';

interface INewPackageFormProps {
  currentStep: EditPackageStep;
  editPackageFormData: Partial<IEditPackageDetails>;
  errorsStep: IErrorsSteps;
  disabledSteps: Array<EditPackageStep>;
  toggleStep: (step: EditPackageStep, action: StepToggleAction) => void;
  renderFooter?: (onSubmit?, onNext?, onBack?, disabled?) => JSX.Element;
  handleChange: (data, errors?) => void;
  onPackageSubmit: (data, submitErrors) => void;
  refToStepForm: any;
  submitAttempted: boolean;
}

const EditPackageForm = ({
  editPackageFormData,
  errorsStep,
  currentStep,
  renderFooter,
  disabledSteps,
  toggleStep,
  handleChange,
  onPackageSubmit,
  refToStepForm,
  submitAttempted,
}: INewPackageFormProps): JSX.Element => {
  const showStep = () => {
    switch (currentStep) {
      case EditPackageStep.General:
        return (
          <GeneralInfoStep
            ref={refToStepForm}
            editPackageSectionFormData={editPackageFormData.generalSection}
            renderFooter={renderFooter}
            handleChange={handleChange}
            disabledSteps={disabledSteps}
            toggleStep={toggleStep}
            onPackageSubmit={onPackageSubmit}
            formErrors={errorsStep}
            submitAttempted={submitAttempted}
          />
        );
      case EditPackageStep.Billing:
        return (
          <BillingStep
            ref={refToStepForm}
            editPackageSectionFormData={editPackageFormData.billingSection}
            renderFooter={renderFooter}
            handleChange={handleChange}
            disabledSteps={disabledSteps}
            onPackageSubmit={onPackageSubmit}
            formErrors={errorsStep}
            submitAttempted={submitAttempted}
            durationType={editPackageFormData.generalSection?.durationType}
            durationEditableNumber={
              editPackageFormData.generalSection?.durationEditableNumber?.value
            }
            startDate={editPackageFormData.generalSection?.customDates?.startDate as string}
            endDate={editPackageFormData.generalSection?.customDates?.endDate as string}
          />
        );
      case EditPackageStep.Membership:
        return (
          <MembershipStep
            ref={refToStepForm}
            editPackageSectionFormData={editPackageFormData.membershipSection}
            renderFooter={renderFooter}
            handleChange={handleChange}
            onPackageSubmit={onPackageSubmit}
            formErrors={errorsStep}
            submitAttempted={submitAttempted}
          />
        );
      case EditPackageStep.Inventory:
        return (
          <InventoryStep
            ref={refToStepForm}
            editPackageSectionFormData={editPackageFormData.inventorySection}
            renderFooter={renderFooter}
            handleChange={handleChange}
            onPackageSubmit={onPackageSubmit}
            formErrors={errorsStep}
            submitAttempted={submitAttempted}
          />
        );
      case EditPackageStep.Services:
        return (
          <ServicesStep
            ref={refToStepForm}
            packageId={editPackageFormData?.id}
            editPackageSectionFormData={editPackageFormData.serviceSection}
            renderFooter={renderFooter}
            handleChange={handleChange}
            onPackageSubmit={onPackageSubmit}
            formErrors={errorsStep}
            submitAttempted={submitAttempted}
            validAvailabilityRange={editPackageFormData.generalSection?.dayTimeAvailDtoList}
          />
        );
      case EditPackageStep.Fees:
        return (
          <FeesStep
            ref={refToStepForm}
            editPackageSectionFormData={editPackageFormData.feesSection}
            durationType={editPackageFormData.generalSection?.durationType}
            durationEditableNumber={
              editPackageFormData.generalSection?.durationEditableNumber?.value
            }
            renderFooter={renderFooter}
            handleChange={handleChange}
            onPackageSubmit={onPackageSubmit}
            formErrors={errorsStep}
            submitAttempted={submitAttempted}
          />
        );
      default:
        return <></>;
    }
  };

  return showStep();
};
export default React.memo(EditPackageForm);
