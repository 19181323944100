import { List as ImmutableList } from 'immutable';
import { useIntl } from 'react-intl';
import * as yup from 'yup';

import { regExp } from 'common/constants/regExp';
import { pastOrTodayDateSchema } from 'common/validationSchemas/dateSchemas';
import { requiredMessage } from 'common/constants/globalConstants';
import { phoneSchema } from 'common/validationSchemas/phoneSchema';
import { getAddressSchema } from 'common/validationSchemas/addressSchema';
import inputErrors from 'common/messages/inputErrors';
import message from 'modules/crm/messages/leads';
import { cameFromFieldsSchema } from 'common/validationSchemas/cameFromFieldsSchema';
import { IEmployeeListItemImt } from 'common/interfaces/dictionary';
import { TShortProfileFieldInfoImt } from 'modules/corporate-settings/interfaces';
import {
  FieldNecessity,
  RequiredProfileInfo,
} from 'modules/corporate-settings/constants/requiredFields';

export const PrimaryInfoStepValidationSchema = (
  employees: ImmutableList<IEmployeeListItemImt>,
  requiredFields: TShortProfileFieldInfoImt,
): yup.AnyObjectSchema => {
  // TODO: Refactoring is needed
  const intl = useIntl();
  return yup.object().shape({
    firstName: yup
      .string()
      .trim()
      .matches(regExp.NAME, 'Must contain only letters')
      .required(requiredMessage),
    lastName: yup
      .string()
      .trim()
      .matches(regExp.NAME, 'Must contain only letters')
      .required(requiredMessage),
    birthday: pastOrTodayDateSchema.when(RequiredProfileInfo.Birthday, {
      is: () => requiredFields.get(RequiredProfileInfo.Birthday) === FieldNecessity.Required,
      then: schema => schema.required(requiredMessage),
      otherwise: schema => schema,
    }),
    email: yup
      .string()
      .trim()
      .email('Invalid Email')
      .when(RequiredProfileInfo.Email, {
        is: () => requiredFields.get(RequiredProfileInfo.Email) === FieldNecessity.Required,
        then: schema => schema.required(requiredMessage),
        otherwise: schema => schema,
      }),
    address: getAddressSchema(requiredFields),
    pinCode: yup
      .string()
      .nullable()
      .notRequired()
      .when({
        is: val => val?.length,
        then: rule => rule.min(4, () => inputErrors.pinCodeLengthError),
      }),
    phones: yup
      .array(
        yup.object().shape({
          phone: phoneSchema.when(RequiredProfileInfo.PhoneNumber, {
            is: () =>
              requiredFields.get(RequiredProfileInfo.PhoneNumber) === FieldNecessity.Required,
            then: schema => schema.required(requiredMessage),
            otherwise: schema => schema.trim(),
          }),
        }),
      )
      .required(requiredMessage),
    limitations: yup
      .string()
      .max(1000, () => inputErrors.salesInfoLengthError)
      .nullable(),
    assignedSalesperson: yup.mixed().when((employ, schema) => {
      if (employ?.id) {
        const isExistEmployee = Boolean(
          employees.find(c => {
            return c.get('id') === employ.id;
          }),
        );

        if (!isExistEmployee) {
          return schema.test(
            'salesperson',
            intl.formatMessage({ ...message.selectedSalespersonNotListAvailable }),
            () => false,
          );
        }
      }

      if (requiredFields.get(RequiredProfileInfo.Salesperson) === FieldNecessity.Required) {
        return schema.notOneOf(['', null], requiredMessage).required(requiredMessage);
      }

      return schema;
    }),
    preferableWayOfCommunication: yup
      .string()
      .nullable()
      .required(requiredMessage),
    image: yup
      .object()
      .nullable()
      .when(RequiredProfileInfo.Photo, {
        is: () => requiredFields.get(RequiredProfileInfo.Photo) === FieldNecessity.Required,
        then: schema => schema.required(requiredMessage),
        otherwise: schema => schema,
      }),
    ...cameFromFieldsSchema,
  });
};
