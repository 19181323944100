import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Button, Divider, IconButton, makeStyles, Typography } from '@material-ui/core';

import { PaymentsTypeLabels } from 'modules/pos-settings/constants/paymentMethods';
import { AS_PAID_INVOICE_PAYMENT_SPLIT_STATUS, PaymentOptionTypes } from '../constants';
import { IInvoicePaymentSplit, PaymentsType } from 'common/interfaces/invoices';
import { CustomTheme } from 'common/ui/interfaces';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { formatNumberToPrice } from 'common/utils/format';

import { ReactComponent as EditIcon } from 'img/icons/pencil_deprecated.svg';

import messages from 'common/messages/messages';
import componentMessages from '../messages';
import { snackbar } from 'common/utils/snackbarUtils';

const useStyles = makeStyles((theme: CustomTheme) => ({
  totalsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
  },
  totalLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  editTotalButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.secondary.main,

    '& svg': {
      width: 16,
      height: 16,
    },
  },
  footerTotalText: {
    fontSize: '1.125rem',
    fontWeight: 700,
  },
  footerTotalAmount: {
    fontSize: '1.125rem',
    fontWeight: 700,
  },
  addTaxButton: {
    fontWeight: 300,
  },
}));

interface IInvoiceTotalSubtotalComponent {
  payments: Array<IInvoicePaymentSplit>;
  discount?: string;
  leftToPay?: number;
  subtotal: number;
  tax: number;
  total: number;
  isCheckOutResponse?: boolean;
  onEditTotal?: () => void;
}

export default function InvoiceTotals({
  payments,
  discount,
  leftToPay,
  subtotal,
  tax,
  total,
  isCheckOutResponse = false,
  onEditTotal,
}: IInvoiceTotalSubtotalComponent): JSX.Element {
  const renderIntlMessage = useRenderIntlMessage();
  const classes = useStyles(isCheckOutResponse);

  const renderTotalItem = (totalLabel: string, value: string) => (
    <Typography component="p" className={classes.totalLabel}>
      <Typography component="span" color="textSecondary">
        {totalLabel}
      </Typography>

      <Typography component="span">{value}</Typography>
    </Typography>
  );

  return (
    <Box className={classes.totalsWrapper}>
      {renderTotalItem(
        `${renderIntlMessage(componentMessages.subTotal)}:`,
        `$${formatNumberToPrice(subtotal)}`,
      )}
      {renderTotalItem(
        `${renderIntlMessage(componentMessages.tax)}:`,
        `$${formatNumberToPrice(tax || 0)}`,
      )}
      {Boolean(!isCheckOutResponse || (isCheckOutResponse && discount)) && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography color="textSecondary">
            <FormattedMessage {...componentMessages.discount} />
          </Typography>

          <Button
            className={`btn-text ${classes.addTaxButton}`}
            variant="text"
            color="primary"
            type="button"
            onClick={() =>
              snackbar.warning('Not in current scope', {
                autoHideDuration: 7000,
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
              })
            }
          >
            <FormattedMessage {...messages.addBtn} />
          </Button>

          {!!discount && <Typography color="textSecondary">${discount}</Typography>}
        </Box>
      )}
      <Box className={classes.totalLabel}>
        <Typography component="p" color="textSecondary" className={classes.footerTotalText}>
          {`${renderIntlMessage(componentMessages.total)}:`}
        </Typography>

        <Box display="flex" alignItems="center">
          {!!onEditTotal && (
            <IconButton onClick={onEditTotal} size="small" className={classes.editTotalButton}>
              <EditIcon />
            </IconButton>
          )}

          <Typography component="span" className={classes.footerTotalAmount}>
            ${formatNumberToPrice(total || 0)}
          </Typography>
        </Box>
      </Box>
      <Divider />
      {!!payments?.length && (
        <Box className={classes.totalsWrapper}>
          {payments
            .filter(p => AS_PAID_INVOICE_PAYMENT_SPLIT_STATUS.includes(p.status))
            .map(payment => (
              <Typography component="p" className={classes.totalLabel} key={payment.id}>
                <Typography component="span" color="textSecondary">
                  <FormattedMessage
                    {...componentMessages.paidWithTitle}
                    values={{
                      title: PaymentsTypeLabels[payment.type],
                      additionalInfo: PaymentOptionTypes[payment.creditCardPaymentOptionType],
                    }}
                  />
                </Typography>

                <Typography component="span">
                  {payment.type === PaymentsType.MEMBER_REWARDS
                    ? payment.rewardAmount
                    : `$${formatNumberToPrice(payment.paymentAmount)}`}
                </Typography>
              </Typography>
            ))}

          {!isCheckOutResponse &&
            leftToPay >= 0 &&
            renderTotalItem(
              `${renderIntlMessage(componentMessages.leftToPay)}:`,
              `$${formatNumberToPrice(leftToPay)}`,
            )}
        </Box>
      )}
    </Box>
  );
}
