import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Box, Button, Collapse, Grid, makeStyles, TextField, Theme } from '@material-ui/core';

import useScrollIntoView from 'common/hooks/useScrollIntoView';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import { IPosKioskPayMethodItemImt } from 'modules/pos-kiosk/interfaces/registers';
import { PaymentsType } from 'common/interfaces/invoices';

import { ReactComponent as AngleDownIcon } from 'img/icons/angle-down.svg';
import { ReactComponent as AngleRightIcon } from 'img/icons/angle-right.svg';

import { PaymentTypeLabels } from 'common/constants/invoices';
import TotalsItem from './TotalsItem';

import inputLabels from 'common/messages/inputLabels';
import commonMessages from 'common/messages/messages';

const useStyles = makeStyles((theme: Theme) => ({
  sectionBtn: {
    padding: 0,
    margin: theme.spacing(2, 0),
    color: theme.palette.darkBackground.light,

    '& .MuiButton-label': {
      display: 'flex',
      justifyContent: 'flex-start',
    },

    '& svg': {
      width: 12,
      height: 12,
      marginRight: theme.spacing(1),
    },
  },
  varianceNote: {
    marginTop: theme.spacing(3),
  },
}));

interface IProps {
  item: IPosKioskPayMethodItemImt;
}

const TotalSalesItem = ({ item }: IProps): JSX.Element => {
  const [showContent, setShowContent] = useState<boolean>(true);

  const { scrollRef } = useScrollIntoView(showContent);

  const { control, errors } = useFormContext();

  const classes = useStyles();

  const renderIntlMessage = useRenderIntlMessage();

  return (
    <>
      <Box>
        <Button
          onClick={() => setShowContent(!showContent)}
          startIcon={showContent ? <AngleDownIcon /> : <AngleRightIcon />}
          className={classes.sectionBtn}
        >
          {PaymentTypeLabels.translate(item.get('paymentMethodTitle'))}
        </Button>
      </Box>

      <Collapse in={showContent} ref={scrollRef}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TotalsItem
              title={<FormattedMessage {...commonMessages.netSales} />}
              amount={item.get('netSales')}
            />
          </Grid>

          <Grid item xs={12}>
            <TotalsItem
              title={<FormattedMessage {...commonMessages.discounts} />}
              amount={item.get('discountAmount')}
            />
          </Grid>

          <Grid item xs={12}>
            <TotalsItem
              title={<FormattedMessage {...commonMessages.refunds} />}
              amount={item.get('refundAmount')}
            />
          </Grid>

          <Grid item xs={12}>
            <TotalsItem
              title={<FormattedMessage {...commonMessages.taxes} />}
              amount={item.get('taxAmount')}
            />
          </Grid>

          <Grid item xs={12}>
            <TotalsItem
              title={<FormattedMessage {...commonMessages.expected} />}
              amount={item.get('expectedAmount')}
              isBoldFont
            />
          </Grid>

          <Grid item xs={12}>
            <TotalsItem
              title={<FormattedMessage {...commonMessages.actual} />}
              amount={item.get('actualAmount')}
              isBoldFont
            />
          </Grid>

          <Grid item xs={12}>
            <TotalsItem
              title={<FormattedMessage {...commonMessages.variance} />}
              amount={item.get('variance')}
              isErrorFont={!!item.get('variance')}
            />
          </Grid>

          {!!item.get('variance') && item.get('paymentMethodTitle') === PaymentsType.CASH && (
            <Grid item xs={12} className={classes.varianceNote}>
              <Controller
                name="cashVarianceNote"
                control={control}
                defaultValue=""
                render={({ value, onChange }) => (
                  <TextField
                    fullWidth
                    label={<FormattedMessage {...inputLabels.varianceNote} />}
                    value={value}
                    onChange={onChange}
                    variant="outlined"
                    error={!!errors.cashVarianceNote}
                    helperText={renderIntlMessage(errors.cashVarianceNote?.message)}
                  />
                )}
              />
            </Grid>
          )}

          {!!item.get('variance') &&
            item.get('paymentMethodTitle') === PaymentsType.CHECKING_SAVINGS && (
              <Grid item xs={12} className={classes.varianceNote}>
                <Controller
                  name="checkVarianceNote"
                  control={control}
                  defaultValue=""
                  render={({ value, onChange }) => (
                    <TextField
                      fullWidth
                      label={<FormattedMessage {...inputLabels.varianceNote} />}
                      value={value}
                      onChange={onChange}
                      variant="outlined"
                      error={!!errors.checkVarianceNote}
                      helperText={renderIntlMessage(errors.checkVarianceNote?.message)}
                    />
                  )}
                />
              </Grid>
            )}
        </Grid>
      </Collapse>
    </>
  );
};

export default TotalSalesItem;
