import { ImmutableObject } from 'common/state/interfaces';
import { List as ImmutableList } from 'immutable';
import { INamedEntity, INamedEntityImt } from 'common/interfaces/common';
import { IStoredFileSimpleDto, IStoredFileSimpleDtoImt } from 'common/interfaces/uploadFile';
import { TagType } from 'modules/dictionaries/constants/constants';

// Inventory Items list

export interface IInventoryItemsListItem {
  id: string;
  name: string;
  status?: string;
  clubs: string;
  barcode: string;
  imageUrl: string;
  active: boolean;
  clubsCount?: number;
}

export interface IInventoryItemsPosSettingsImt extends ImmutableObject<IInventoryItemsListItem> {
  get<K extends keyof IInventoryItemsListItem>(key: K): IInventoryItemsListItem[K];
}

export interface IInventoryItemClubsFormData {
  id?: string;
  name?: string;
  club?: INamedEntity;
  clubs?: Array<INamedEntity>;
  location?: string;
  vendor?: Partial<INamedEntity>;
  // vendorId?: string;
  vendorCatalog?: string;
  cost?: string;
  retailPrice?: string;
  revenueCode?: Partial<INamedEntity>;
  // revenueCodeId?: string;
  onHand?: string;
  idealStock?: string;
  autoCount?: boolean | null;
  showInSuggestedProducts?: boolean | null;
  reorderPoint?: string;
}

export interface IInventoryClubsListItemData extends IInventoryItemClubsFormData {
  retailPriceWithTax: string;
}

export interface IInventoryItemClubsFormDataImt
  extends ImmutableObject<IInventoryItemClubsFormData> {
  get<K extends keyof IInventoryItemClubsFormData>(key: K): IInventoryItemClubsFormData[K];

  get(key: 'club'): INamedEntityImt;

  get(key: 'clubs'): ImmutableList<Array<INamedEntityImt>>;
}

export interface IInventoryViewDto {
  id: string;
  active: boolean;
  barcode: string;
  category: string;
  description: string;
  imageUrl: string;
  title: string;
}

interface ITagDto {
  id: string;
  name: string;
  tagType: TagType;
}

export interface ITagDtoImt extends ImmutableObject<ITagDto> {
  get<K extends keyof ITagDto>(key: K): ITagDto[K];
}

export interface IInventoryDto {
  id: string;
  active: boolean;
  barcode: string;
  category: ITagDto;
  description: string;
  image: IStoredFileSimpleDto;
  inventoryClubList: Array<IInventoryItemClubsFormData>;
  name: string;
}

export interface IInventoryDtoImt extends ImmutableObject<IInventoryDto> {
  get<K extends keyof IInventoryDto>(key: K): IInventoryDto[K];

  get(key: 'category'): ITagDtoImt;

  get(key: 'image'): IStoredFileSimpleDtoImt;

  get(key: 'inventoryClubList'): ImmutableList<Array<IInventoryItemClubsFormDataImt>>;
}

export interface IInventoryViewDtoImt extends ImmutableObject<IInventoryViewDto> {
  get<K extends keyof IInventoryViewDto>(key: K): IInventoryViewDto[K];
}

export interface IInventoryItemClubsEdit {
  ids?: Array<string | number>;
  clubIds?: Array<string | number>;
  inventoryClubDto: IInventoryItemClubsFormData;
}

export enum IInventoryItemClubsChangingFields {
  VENDOR = 'vendor',
  REVENUE_CODE = 'revenueCode',
  VENDOR_ID = 'vendorId',
  REVENUE_CODE_ID = 'revenueCodeId',
}

export interface ISimpleClubInventoryItem {
  id: string;
  title: string;
  imageUrl: string;
  retailPrice: number;
  taxAmount: number;
  revenueCodeId: string;
  onHandAmount?: number;
}

export type ISimpleClubInventoryItemImt = ImmutableObject<ISimpleClubInventoryItem>;
