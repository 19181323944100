export interface IBulkSelectItem {
  id: string;
  name?: string;
  title: string;
}

export const BULK_STRING = 'BULK_MULTIPLE_VALUE';
export const BULK_BOOLEAN = null;
export const BULK_PHONE = '+1XXXXXXXXXX';
export const BULK_OPTION: IBulkSelectItem = {
  id: BULK_STRING,
  name: BULK_STRING,
  title: 'Multiple',
};
export const BULK_VALUES: Array<any> = [BULK_STRING, BULK_PHONE, BULK_OPTION];

export const isBulkConstant = (value: any): boolean =>
  [BULK_STRING, BULK_BOOLEAN, BULK_PHONE, BULK_OPTION, BULK_VALUES].includes(value);
