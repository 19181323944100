import { useRef } from 'react';
import useFirst from './useFirst';

export default function useInitial<T>(callback: () => T): T {
  const isFirstRender = useFirst();
  const initial = useRef<T>();

  if (isFirstRender) {
    initial.current = callback();
  }

  return initial.current as T;
}
