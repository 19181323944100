// libraries
import React from 'react';
import cx from 'classnames';
import {
  Avatar,
  Box,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';
import PersonIconStatus from '../PersonIconStatus/PersonIconStatus';

import { Close } from '@material-ui/icons';
import { ReactComponent as UserCalendar } from 'img/icons/user-calendar.svg';
import profilePlaceholder from 'img/photo-placeholder.png';
// utils
import { getInitials } from 'helpers/common';
// custom interfaces
import { IEventPersonFormValue, PersonAttendanceType } from '../../../interfaces';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface IEventParticipantProps {
  index: number;

  participant: IEventPersonFormValue;
  repeatEvent: boolean;

  onChangeAttendanceType: (index: number, attendanceType: PersonAttendanceType) => void;
  onRemove: (index: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  avatarWrap: {
    position: 'relative',
    minWidth: 'auto',
    backgroundColor: theme.palette.secondary.light,
    borderRadius: theme.spacing(0.3),
  },
  optionAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    margin: theme.spacing(0, 1, 0, 0),
  },
  avatarInitial: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    fontSize: '12px',
    fontWeight: 700,
    color: theme.palette.darkBackground.light,
  },
  actionButtonIcon: {
    height: theme.spacing(2),
    width: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
}));

const EventParticipant: React.FC<IEventParticipantProps> = (
  props: IEventParticipantProps,
): JSX.Element => {
  const classes = useStyles();
  const { index, participant, onRemove, onChangeAttendanceType, repeatEvent } = props;

  // handlers

  const handleChangeAttendanceType = () => {
    const { attendanceType } = participant;
    const newAttendanceType =
      attendanceType === PersonAttendanceType.OneTime
        ? PersonAttendanceType.Default
        : PersonAttendanceType.OneTime;

    onChangeAttendanceType(index, newAttendanceType);
  };

  const handleRemove = () => onRemove(index);

  const person = participant.customer || participant.salesperson;

  const initials = getInitials(person.firstName, person.lastName);

  const imageUrl = person?.imageUrl;

  // renders

  return (
    <ListItem dense disableGutters>
      <ListItemAvatar className={cx(classes.avatarWrap, classes.optionAvatar)}>
        <>
          {initials && !imageUrl ? (
            <Box className={classes.avatarInitial}>{initials}</Box>
          ) : (
            <Avatar className={classes.optionAvatar} src={imageUrl || profilePlaceholder} />
          )}
        </>
      </ListItemAvatar>

      <ListItemText>
        <Typography variant="caption" className={classes.titleContainer}>
          {`${person.firstName} ${person.lastName}`}
          {participant?.status && <PersonIconStatus personStatus={participant.status} />}
        </Typography>
      </ListItemText>

      <ListItemSecondaryAction>
        {repeatEvent && (
          <Tooltip
            title={
              participant.attendanceType === PersonAttendanceType.OneTime
                ? 'Make regular'
                : 'Make one-time'
            }
          >
            <IconButton
              className={cx('participant-action', {
                'participant-action-visible':
                  participant.attendanceType === PersonAttendanceType.OneTime,
              })}
              size="small"
              disableRipple
              onClick={handleChangeAttendanceType}
            >
              <UserCalendar className={classes.actionButtonIcon} />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip title="Remove">
          <IconButton
            className="participant-action"
            size="small"
            disableRipple
            onClick={handleRemove}
          >
            <Close className={classes.actionButtonIcon} />
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default React.memo(EventParticipant);
