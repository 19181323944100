import { ConstOptions } from 'common/constants/classes';
import tasksMessages from '../messages/tasks';
import commonMessages from 'common/messages/messages';

export const actionTypes = {
  // Task types
  FETCH_TASKS_LIST_LOADING: 'FETCH_TASKS_LIST_LOADING',
  FETCH_TASKS_LIST_SUCCESS: 'FETCH_TASKS_LIST_SUCCESS',

  SALESPERSON_TASKS_ASSIGNING: 'SALESPERSON_TASKS_ASSIGNING',
  SALESPERSON_TASKS_ASSIGNED: 'SALESPERSON_TASKS_ASSIGNED',

  TASK_COMPLETING: 'TASK_COMPLETING',
  TASK_COMPLETED: 'TASK_COMPLETED',

  RESET_TASKS_ACTION_RESULT: 'RESET_TASKS_ACTION_RESULT',

  FETCH_TASK_LOADING: 'FETCH_TASK_LOADING',
  FETCH_TASK_SUCCESS: 'FETCH_TASK_SUCCESS',
};

export const tasksTypes = {
  SMS: 'SMS',
  EMAIL: 'EMAIL',
  CALL: 'CALL',
  LEAD_PROFILE_CREATION: 'LEAD_PROFILE_CREATION',
  SMS_RECEIVED: 'SMS_RECEIVED',
  EMAIL_RECEIVED: 'EMAIL_RECEIVED',
  APPOINTMENT: 'APPOINTMENT',
  REMINDER: 'REMINDER',
};

export const tasksTypesConstOptions = new ConstOptions([
  {
    key: tasksTypes.SMS,
    value: tasksTypes.SMS,
    message: commonMessages.smsLabel,
  },
  {
    key: tasksTypes.EMAIL,
    value: tasksTypes.EMAIL,
    message: tasksMessages.email,
  },
  {
    key: tasksTypes.CALL,
    value: tasksTypes.CALL,
    message: tasksMessages.call,
  },
  {
    key: tasksTypes.LEAD_PROFILE_CREATION,
    value: tasksTypes.LEAD_PROFILE_CREATION,
    message: tasksMessages.leadProfileCreation,
  },
  {
    key: tasksTypes.SMS_RECEIVED,
    value: tasksTypes.SMS_RECEIVED,
    message: tasksMessages.smsReceived,
  },
  {
    key: tasksTypes.EMAIL_RECEIVED,
    value: tasksTypes.EMAIL_RECEIVED,
    message: tasksMessages.emailReceived,
  },
  {
    key: tasksTypes.APPOINTMENT,
    value: tasksTypes.APPOINTMENT,
    message: tasksMessages.appointment,
  },
  {
    key: tasksTypes.REMINDER,
    value: tasksTypes.REMINDER,
    message: tasksMessages.reminder,
  },
]);

export const TASK_DATE_FORMAT = 'DD MMM, hh:mm A';
