import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { ActionItem, ActionsMenu, Table } from 'common/components';
import { ReactComponent as DeleteIcon } from 'img/icons/trash.svg';
import PersonSimpleView from 'common/components/PersonSimpleView/PersonSimpleView';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { deleteTimeclockById } from 'modules/timeclock/state/actions';

import { IFilterSettings } from 'common/interfaces/filter';
import { IHeadCell, ITableParams, ITableRow } from 'common/interfaces/table';

import tableHeaders from 'common/messages/tableHeaders';
import tableFilters from 'common/messages/tableFilters';
import inputLabels from 'common/messages/inputLabels';
import commonMessages from 'common/messages/messages';

import { payTypeLabels } from 'modules/employees/constants';
import { ITimeclockListItem } from 'modules/timeclock/interfaces/timeclock';
import { IPageMeta } from 'common/interfaces/pagination';
import { calculateDuration } from 'common/utils/calculate';
import { DurationUnit } from 'common/interfaces/common';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';

const headerOptions: IHeadCell[] = [
  {
    id: 'employee',
    label: <FormattedMessage {...tableHeaders.employee} />,
    sort: true,
  },
  {
    id: 'club',
    label: <FormattedMessage {...tableHeaders.club} />,
    sort: true,
  },
  {
    id: 'department',
    label: <FormattedMessage {...tableFilters.department} />,
    sort: true,
  },
  {
    id: 'clock_in',
    label: <FormattedMessage {...tableHeaders.clockIn} />,
    sort: true,
  },
  {
    id: 'clock_out',
    label: <FormattedMessage {...tableHeaders.clockOut} />,
    sort: true,
  },
  {
    id: 'duration',
    label: <FormattedMessage {...inputLabels.duration} />,
    sort: false,
  },
  {
    id: 'pay_type',
    label: <FormattedMessage {...inputLabels.payType} />,
    sort: true,
  },
  {
    id: 'unit',
    label: <FormattedMessage {...tableHeaders.unit} />,
    sort: true,
  },
  {
    id: 'actions',
    label: <FormattedMessage {...tableHeaders.actions} />,
    sort: false,
    align: 'center',
    padding: 'none',
  },
];

interface IProps {
  tableFilterSettings: IFilterSettings[];
  onChangeTableParams?: (tableParams: ITableParams) => void;
  isLoading: boolean;
  data: ITimeclockListItem[];
  meta?: IPageMeta;
  tableParams?: ITableParams;
  onEventClick: (event) => void;
}

const TimeclockLogTable = ({
  tableFilterSettings,
  onChangeTableParams,
  isLoading,
  data,
  meta,
  tableParams,
  onEventClick,
}: IProps): JSX.Element => {
  const [timezoneMoment] = useTimezoneMoment();
  const dispatch = useDispatch();

  const rows: ITableRow[] = useMemo(
    () =>
      data?.map(
        (item: ITimeclockListItem): ITableRow => ({
          id: item.id,
          cells: [
            {
              label: '',
              cellComponent: (
                <PersonSimpleView
                  bold
                  firstName={item.employee.title}
                  lastName=""
                  imageUrl={item.employee.imageUrl}
                />
              ),
            },
            {
              label: '',
              cellComponent: <Typography variant="body1">{item.clubTitle}</Typography>,
            },
            {
              label: '',
              cellComponent: <Typography variant="body1">{item.departmentTitle}</Typography>,
            },
            {
              label: '',
              cellComponent: (
                <Typography variant="body1">
                  {timezoneMoment(item.clockIn).format(DEFAULT_DATE_TIME_FORMAT)}
                </Typography>
              ),
            },
            {
              label: '',
              cellComponent: (
                <Typography variant="body1">
                  {item.clockOut
                    ? timezoneMoment(item.clockOut).format(DEFAULT_DATE_TIME_FORMAT)
                    : '-'}
                </Typography>
              ),
            },
            {
              label: '',
              cellComponent: (
                <Typography variant="body1">
                  {item.durationInMinutes
                    ? calculateDuration(DurationUnit.Mins, item.durationInMinutes)
                    : '-'}
                </Typography>
              ),
            },
            {
              label: '',
              cellComponent: (
                <Typography variant="body1">{payTypeLabels.translate(item.payType)}</Typography>
              ),
            },
            {
              label: '',
              cellComponent: item?.eventPerson?.eventPersonId ? (
                <Box onClick={() => onEventClick(item?.eventPerson)}>
                  <Typography variant="body1">{item?.eventPerson?.title}</Typography>
                </Box>
              ) : (
                <>-</>
              ),
            },
            {
              label: 'actions',
              align: 'center',
              padding: 'none',
              width: '54px',
              cellComponent: (
                <>
                  <ActionsMenu horizontal tableActionsMode>
                    <ActionItem
                      key="1"
                      icon={<DeleteIcon width={16} height={16} />}
                      onClick={() => dispatch(deleteTimeclockById(item.id))}
                    >
                      <FormattedMessage {...commonMessages.deleteBtn} />
                    </ActionItem>
                  </ActionsMenu>
                </>
              ),
            },
          ],
        }),
      ),
    [data, timezoneMoment, onEventClick, dispatch],
  );

  return (
    <Table
      isLoading={isLoading}
      headerOptions={headerOptions}
      filters={tableFilterSettings}
      rows={rows || []}
      onChangeParams={onChangeTableParams}
      hideSearchInput
      showPerPageSelect
      page={meta?.page}
      tableParams={tableParams}
    />
  );
};

export default TimeclockLogTable;
