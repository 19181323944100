import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import { Box, Typography, makeStyles } from '@material-ui/core';

import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';

import { IFallthroughCaptureImt } from 'common/components/PersonProfile/interfaces';
import { IFallthroughReasonListItemImt } from 'common/interfaces/dictionary';

import { DictionaryList } from 'common/constants';

import { ActionItem, ActionsMenu } from 'common/components/index';

import { selectDictionaryList } from 'common/state/dictionary/selectors';

import useTimezoneMoment from 'common/hooks/useTimezoneMoment';

import messages from 'common/components/PersonProfile/messages';
import commonMessages from 'common/messages/messages';

const useStyles = makeStyles(theme => ({
  card: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.secondary.light}`,
  },
  rejectedMessage: {
    marginTop: theme.spacing(1.5),
    fontSize: '1rem',
  },
  reason: {
    margin: theme.spacing(1, 0),
    fontSize: '1rem',
  },
}));

interface IProps {
  onRemove: (captureId: string) => void;
  fallthroughCapture: IFallthroughCaptureImt;
}

const FallthroughCaptureCard = ({ fallthroughCapture, onRemove }: IProps): JSX.Element => {
  const [timezoneMoment] = useTimezoneMoment();

  const classes = useStyles();

  const fallthroughReasons: ImmutableList<IFallthroughReasonListItemImt> = useSelector(
    selectDictionaryList(DictionaryList.FALLTHROUGH_REASONS),
  );

  const reason = useMemo(() => {
    if (fallthroughReasons && fallthroughCapture) {
      return (
        fallthroughCapture.get('fallthroughReason') ||
        fallthroughReasons
          .find(
            reasonItem => reasonItem.get('id') === fallthroughCapture.get('fallthroughReasonId'),
          )
          ?.get('name')
      );
    }

    return null;
  }, [fallthroughCapture, fallthroughReasons]);

  const handleRemoveFallthroughCapture = () => {
    onRemove(fallthroughCapture.get('id'));
  };

  return (
    <Box className={classes.card}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="button" color="textSecondary">
          <FormattedMessage {...commonMessages.membership} />
        </Typography>

        <ActionsMenu horizontal tableActionsMode menuAlign="right">
          <ActionItem onClick={handleRemoveFallthroughCapture}>
            <Typography>
              <FormattedMessage {...commonMessages.removeRejectionBtn} />
            </Typography>
          </ActionItem>
        </ActionsMenu>
      </Box>

      <Typography color="error" variant="h4" className={classes.rejectedMessage}>
        <FormattedMessage {...messages.membershipIsRejectedTextMessage} />
      </Typography>

      <Typography className={classes.reason}>{reason}</Typography>

      <Typography variant="body2" color="textSecondary">
        {timezoneMoment(fallthroughCapture.get('createdDate')).format(DEFAULT_DATE_TIME_FORMAT)}
      </Typography>
    </Box>
  );
};

export default React.memo(FallthroughCaptureCard);
