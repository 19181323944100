import { useEffect, useRef } from 'react';
import qz from 'qz-tray';
import { createConfig, printHtml, subscribeToCertificate } from 'common/utils/print';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { useAppDispatch } from 'store/hooks';
import { IPrintConfig } from 'common/interfaces/print';

const useSilentPrintHTML = (
  printingHtml: string | null,
): { setPrinter: (printer: string) => void } => {
  const dispatch = useAppDispatch();
  const configRef = useRef<IPrintConfig>(null);

  useEffect(() => {
    subscribeToCertificate();

    configRef.current = createConfig();
  }, []);

  useEffect(() => {
    if (printingHtml) {
      printHtml(printingHtml, configRef.current)
        .catch(error => {
          dispatch(enqueueErrorNotification(error));
        })
        .finally(() => {
          qz.websocket.disconnect();
        });
    }
  });

  const setPrinter = (printer: string) => {
    configRef.current.setPrinter(printer);
  };

  return { setPrinter };
};

export default useSilentPrintHTML;
