// libraries
import React from 'react';
import { useSelector } from 'react-redux';
// interfaces
import { IUserOrganizationImt } from 'common/interfaces/clients';
// constants
import { PermissionLevels } from 'common/constants/permissions';
// hooks
import { useSocketsHandler } from './hooks/useSocketsHandler';
import { useTopics } from './hooks/useTopics';
// state
import {
  selectIsUserLoggedIn,
  selectUserSelectedOrganization,
} from 'modules/authentication/state/selectors';
// listeners
import SocketListener from 'common/components/SocketListener/SocketListener';
import { selectIsMemberPortalUserLoggedIn } from 'memberPortalModules/authentication/state/selectors';

const SocketsHandler = (): JSX.Element => {
  // state
  const selectedOrg: IUserOrganizationImt = useSelector(selectUserSelectedOrganization);
  // We need this selector, to just re-render this component, when sign in result changed.
  useSelector(selectIsUserLoggedIn);
  useSelector(selectIsMemberPortalUserLoggedIn);

  const clubId =
    selectedOrg?.get('permissionsLevel') === PermissionLevels.CLIENT ? selectedOrg.get('id') : null;

  const onMessageHandler = useSocketsHandler(clubId);

  const topics = useTopics();

  // renders

  return <>{clubId && <SocketListener topics={topics} onMessage={onMessageHandler} />}</>;
};

export default SocketsHandler;
