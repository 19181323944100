import { defineMessages } from 'react-intl';

export default defineMessages({
  // Buttons
  newNote: {
    id: 'app.components.notes.newNote',
    defaultMessage: 'New note',
  },
  addNewNote: {
    id: 'app.components.notes.addNewNote',
    defaultMessage: '+ NEW',
  },
  editNote: {
    id: 'app.components.notes.editNote',
    defaultMessage: 'Edit note',
  },
  note: {
    id: 'app.components.notes.note',
    defaultMessage: 'Note',
  },
  notes: {
    id: 'app.components.notes.notes',
    defaultMessage: 'Notes',
  },
  alertAtNextCheckIn: {
    id: 'app.components.notes.alertAtNextCheckIn',
    defaultMessage: 'Show alert at next check-in',
  },
  deleteNote: {
    id: 'app.components.notes.deactivateItem',
    defaultMessage: 'Delete this note?',
  },
  noteCode: {
    id: 'app.components.notes.noteCode',
    defaultMessage: 'Note code',
  },
});
