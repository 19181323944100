export const actionTypes = {
  FETCH_ALERTS_LIST_LOADING: 'FETCH_ALERTS_LIST_LOADING',
  FETCH_ALERTS_LIST_SUCCESS: 'FETCH_ALERTS_LIST_SUCCESS',
  RESET_ALERTS_LIST: 'RESET_ALERTS_LIST',

  UPDATE_ALERT_LOADING: 'UPDATE_ALERT_LOADING',
  UPDATE_ALERT_SUCCESS: 'UPDATE_ALERT_SUCCESS',
  UPDATE_ALERT_ERROR: 'UPDATE_ALERT_ERROR',

  UPDATE_PREFERENCE_LOADING: 'UPDATE_PREFERENCE_LOADING',
  UPDATE_PREFERENCE_SUCCESS: 'UPDATE_PREFERENCE_SUCCESS',
  UPDATE_PREFERENCE_ERROR: 'UPDATE_PREFERENCE_ERROR',

  CREATE_ALERT_LOADING: 'CREATE_ALERT_LOADING',
  CREATE_ALERT_SUCCESS: 'CREATE_ALERT_SUCCESS',
  CREATE_ALERT_ERROR: 'CREATE_ALERT_ERROR',

  DELETE_ALERT_LOADING: 'DELETE_ALERT_LOADING',
  DELETE_ALERT_SUCCESS: 'DELETE_ALERT_SUCCESS',
  DELETE_ALERT_ERROR: 'DELETE_ALERT_ERROR',
};
