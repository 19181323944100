import * as yup from 'yup';
import moment from 'moment-timezone';

import { getRequiredMessage } from 'common/constants/globalConstants';

import inputErrors from 'common/messages/inputErrors';

const startTodayDate = moment().startOf('day');
const endTodayDate = moment().endOf('day');

export const dateSchema = yup
  .string()
  .nullable()
  .test('date-test', 'Invalid date', date => {
    if (moment(date, 'HH:mm', true)?.isValid()) {
      return true;
    }

    return !date || moment(date).isValid();
  });

export const futureDateSchema = dateSchema.test(
  'future-date-test',
  'Select a date in the future',
  date =>
    !date ||
    moment(date)
      .local()
      .isAfter(endTodayDate),
);

export const futureOrTodayDateSchema = dateSchema.test(
  'future-date-test',
  'Select a date in future or today',
  date =>
    !date ||
    moment(date)
      .local()
      .isSameOrAfter(startTodayDate),
);

export const pastDateSchema = dateSchema.test(
  'past-date-test',
  'Select a date in the past',
  date =>
    !date ||
    moment(date)
      .local()
      .isBefore(startTodayDate),
);

export const pastOrTodayDateSchema = dateSchema
  .test(
    'past-date-test',
    'Select a date in the past or today',
    date =>
      !date ||
      moment(date)
        .local()
        .isSameOrBefore(endTodayDate),
  )
  .test('past-min-date-test', 'Select a date more than 1930 year', date => {
    const minDate = moment('1930-01-01');
    const currentDate = moment(date);

    return !currentDate.isBefore(minDate);
  });

// Schemas for DateTimePicker
export const getDisablePastTimeSchema = (datePickerName?: string): yup.StringSchema => {
  return dateSchema
    .required(getRequiredMessage)
    .test('time', { ...inputErrors.timeMatchFutureError }, function(time) {
      if (datePickerName && !this.parent[datePickerName]) {
        return true;
      }

      const dateWithTimezone = moment
        .utc(`${this.parent[datePickerName]} ${time}`, 'YYYY-MM-DD HH:mm')
        .tz((moment as any).defaultZone.name);

      const isToday = moment().isSame(dateWithTimezone, 'day');

      return isToday ? dateWithTimezone.isAfter(moment(), 'minutes') : true;
    });
};

export const getFutureOrTodayDateSchema = (timePickerName?: string): yup.StringSchema => {
  return dateSchema
    .required(getRequiredMessage)
    .test('date', { ...inputErrors.dateInFeatureOrToday }, function(date) {
      if (timePickerName && !this.parent[timePickerName]) {
        return true;
      }

      const dateWithTimezone = moment
        .utc(`${date} ${this.parent[timePickerName]}`, 'YYYY-MM-DD HH:mm')
        .tz((moment as any).defaultZone.name);

      return dateWithTimezone.isSameOrAfter(moment().startOf('day'));
    });
};

export const getFutureDateTimeSchema = (timePickerName?: string): yup.StringSchema => {
  return dateSchema
    .required(getRequiredMessage)
    .test('date', { ...inputErrors.dateInFeatureOrToday }, function(date) {
      if (timePickerName && !this.parent[timePickerName]) {
        return true;
      }

      const dateWithTimezone = moment
        .utc(`${date} ${this.parent[timePickerName]}`, 'YYYY-MM-DD HH:mm')
        .tz((moment as any).defaultZone.name);

      const isToday = moment().isSame(dateWithTimezone, 'day');

      return isToday || dateWithTimezone.isAfter(moment());
    });
};
