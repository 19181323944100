import React, { FC } from 'react';
import moment from 'moment-timezone';
import { Box, Typography, Avatar } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import { Person } from '@material-ui/icons';

import { IBookingEventListItem } from 'modules/booking/interfaces';

interface IEventBlockProps {
  event: {
    title: string;
    start: string;
    extendedProps: IBookingEventListItem;
  };
}

const EventBlock: FC<IEventBlockProps> = (props: IEventBlockProps): JSX.Element => {
  const {
    event: { title, extendedProps },
  } = props;
  const { logos, utcDate, bookedSpotsCounter } = extendedProps || {};

  return (
    <Box p="3px">
      <Box mb="4px" display="flex" alignItems="center">
        <Typography
          className="fcTitle"
          style={{
            flex: 1,
            marginRight: '5px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
          variant="body2"
          color="inherit"
        >
          {title}
        </Typography>
        <Typography style={{ fontSize: '0.6rem' }} color="textSecondary" variant="body2">
          {moment(utcDate.includes('Z') ? utcDate : `${utcDate}Z`).format('h:mm A')}
        </Typography>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className="fcParticipants"
      >
        <Box display="flex" alignItems="center">
          <Person style={{ marginRight: '3px', fontSize: '0.8rem' }} />
          <Typography
            style={{
              marginRight: '5px',
              fontSize: '0.6rem',
            }}
            variant="body2"
          >
            {bookedSpotsCounter}
          </Typography>
        </Box>

        {!!logos?.length && (
          <AvatarGroup max={2} className="fcAvatarGroup">
            {logos.map(({ firstName, lastName, imageUrl }) => (
              <Avatar
                key={imageUrl}
                style={{ width: '20px', height: '20px' }}
                alt={`${firstName} ${lastName}`}
                src={imageUrl}
              />
            ))}
          </AvatarGroup>
        )}
      </Box>
    </Box>
  );
};

export default EventBlock;
