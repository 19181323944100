import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Button, Collapse, Grid, makeStyles, Theme, Typography } from '@material-ui/core';

import useScrollIntoView from 'common/hooks/useScrollIntoView';
import { countTaxedAmount } from 'modules/services/utils/billingUtils';
import { formatNumberToPrice, formatPrice } from 'common/utils';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import {
  BillingOptionType,
  CreditCardFeeUnit,
  CreditCardFeeUnitsSymbols,
  DurationTypes,
  FrequencyTypes,
} from 'modules/services/constants/packages';

import { IBillingOptionView } from 'modules/services/interfaces/packages';

import { ReactComponent as AngleDownIcon } from 'img/icons/angle-down.svg';
import { ReactComponent as AngleRightIcon } from 'img/icons/angle-right.svg';
import { FieldInfo } from 'common/components/index';

import personProfileMessages from 'common/components/PersonProfile/messages';
import inputLabels from 'common/messages/inputLabels';
import commonMessages from 'common/messages/messages';
import servicesMessages from 'modules/services/messages/messages';

const useStyles = makeStyles((theme: Theme) => ({
  sectionBtn: {
    padding: 0,
    color: theme.palette.text.secondary,

    '& svg': {
      width: '0.75rem',
      height: '0.75rem',
      marginRight: theme.spacing(1),
    },
  },
  sectionWrapper: {
    marginTop: theme.spacing(1),
  },
}));

interface IProps {
  billingOption: IBillingOptionView;
  isOutOfTerm?: boolean;
}

const BillingInfoView = ({ billingOption, isOutOfTerm }: IProps): JSX.Element => {
  const [showContent, setShowContent] = useState<boolean>(true);

  const { scrollRef } = useScrollIntoView(showContent);

  const renderIntlMessage = useRenderIntlMessage();

  const classes = useStyles();

  const {
    title,
    revenueCode,
    totalAmount,
    billingOptionType,
    paymentSchedule,
    taxPerBilling,
    freeze,
    splitting,
    paymentGrace,
    creditCardServiceFee,
  } = { ...billingOption };

  const taxedTotalAmount =
    totalAmount && revenueCode?.totalTax
      ? countTaxedAmount(totalAmount, revenueCode.totalTax)
      : totalAmount;

  return (
    <Box>
      <Button
        onClick={() => setShowContent(!showContent)}
        startIcon={showContent ? <AngleDownIcon /> : <AngleRightIcon />}
        className={classes.sectionBtn}
      >
        <FormattedMessage
          {...(isOutOfTerm ? servicesMessages.outOfTermSectionLabel : commonMessages.billingLabel)}
        />
      </Button>

      <Collapse in={showContent} ref={scrollRef}>
        <Grid container spacing={2} className={classes.sectionWrapper}>
          <Grid item xs={4}>
            <FieldInfo
              label={<FormattedMessage {...servicesMessages.reVenueCodeTaxLabel} />}
              description={`${revenueCode?.totalTax}%`}
            />
          </Grid>

          <Grid item xs={4}>
            <FieldInfo
              label={<FormattedMessage {...servicesMessages.billingOptionLabel} />}
              description={title}
            />
          </Grid>

          <Grid item xs={4}>
            <FieldInfo
              label={<FormattedMessage {...inputLabels.totalAmount} />}
              description={`$${totalAmount?.toFixed(2)}`}
            />
          </Grid>

          <Grid item xs={4}>
            <FieldInfo
              label={<FormattedMessage {...inputLabels.taxedTotalAmount} />}
              description={`$${taxedTotalAmount?.toFixed(2)}`}
            />
          </Grid>

          {billingOptionType !== BillingOptionType.PAID_IN_FULL && (
            <>
              <Grid item xs={4}>
                <FieldInfo
                  label={<FormattedMessage {...servicesMessages.paymentScheduleLabel} />}
                  description={FrequencyTypes.translate(paymentSchedule)}
                />
              </Grid>

              <Grid item xs={4}>
                <FieldInfo
                  label={<FormattedMessage {...servicesMessages.pricePerBillingLabel} />}
                  description={formatPrice(taxPerBilling)}
                />
              </Grid>
            </>
          )}

          {!!freeze && (
            <>
              <Grid item xs={12}>
                <Typography component="p" variant="h5">
                  <FormattedMessage {...personProfileMessages.freezeMembershipModalSuccessBtn} />
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <FieldInfo
                  label={<FormattedMessage {...inputLabels.duration} />}
                  description={`${freeze.daysNumber} ${renderIntlMessage(inputLabels.daysAmount)}`}
                />
              </Grid>
            </>
          )}

          {!!splitting?.allow && (
            <>
              <Grid item xs={12}>
                <Typography component="p" variant="h5">
                  <FormattedMessage {...servicesMessages.splitEachPaymentLabel} />
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <FieldInfo
                  label={<FormattedMessage {...inputLabels.payments} />}
                  description={`${splitting.splits.length}`}
                />
              </Grid>

              {splitting.splits.map((splitPaymentItem, index) => {
                return (
                  <Grid item xs={4} key={splitPaymentItem.order}>
                    <FieldInfo
                      label={`#${index + 1}`}
                      description={formatPrice(splitPaymentItem.value)}
                    />
                  </Grid>
                );
              })}
            </>
          )}

          {!!paymentGrace?.allow && (
            <>
              <Grid item xs={12}>
                <Typography component="p" variant="h5">
                  <FormattedMessage {...servicesMessages.paymentGracePeriodLabel} />
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <FieldInfo
                  label={<FormattedMessage {...servicesMessages.durationLabel} />}
                  description={`${paymentGrace.durationNumber} ${renderIntlMessage(
                    DurationTypes.message(paymentGrace.duration),
                  )}`}
                />
              </Grid>
            </>
          )}

          {!!creditCardServiceFee?.enabled && (
            <>
              <Grid item xs={12}>
                <Typography component="p" variant="h5">
                  <FormattedMessage {...servicesMessages.creditCardServiceFeeEnabledLabel} />
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <FieldInfo
                  label={<FormattedMessage {...servicesMessages.creditCardServiceFeeValueLabel} />}
                  description={
                    creditCardServiceFee.unit === CreditCardFeeUnit.FLAT
                      ? `$${formatNumberToPrice(creditCardServiceFee.value)}`
                      : `${creditCardServiceFee.value}${renderIntlMessage(
                          CreditCardFeeUnitsSymbols.message(creditCardServiceFee.unit),
                        )}`
                  }
                />
              </Grid>
            </>
          )}
        </Grid>
      </Collapse>
    </Box>
  );
};

export default BillingInfoView;
