import { fromJS, List as ImmutableList } from 'immutable';
import { createSelector } from 'reselect';

import { AppStoreTypeImt } from 'store';
import { IZendeskSettingsDtoImt } from 'modules/peak-settings/interfaces';
import {
  IRequiredFieldInfoImt,
  TShortProfileFieldInfoImt,
} from 'modules/corporate-settings/interfaces';

import { FieldNecessity } from 'modules/corporate-settings/constants/requiredFields';

// const selectIntl = (state: AppStoreTypeImt) => state.get('i18n');

const selectLocale = (state: AppStoreTypeImt) => state.getIn(['settings', 'locale']);
export const selectIsSidebarOpen = (state: AppStoreTypeImt): boolean =>
  state.getIn(['settings', 'isSidebarOpen']);
const selectTimezone = (state: AppStoreTypeImt) => state.getIn(['settings', 'timezone']);

const selectPeakSettings = (state: AppStoreTypeImt): IZendeskSettingsDtoImt => {
  return state.getIn(['settings', 'peakSettings']);
};

const selectProfileFieldsInfo = (state: AppStoreTypeImt): ImmutableList<IRequiredFieldInfoImt> => {
  return state.getIn(['settings', 'profileFieldsInfo']);
};

const selectProfileRequiredFields = createSelector<
  AppStoreTypeImt,
  ImmutableList<IRequiredFieldInfoImt>,
  TShortProfileFieldInfoImt
>([selectProfileFieldsInfo], fields => {
  return fromJS(
    fields.toJS().reduce((acc, field) => {
      return field.necessity === FieldNecessity.Required
        ? { ...acc, [field.fieldType]: field.necessity }
        : acc;
    }, {}),
  );
});

const selectProfileRecommendedFields = createSelector<
  AppStoreTypeImt,
  ImmutableList<IRequiredFieldInfoImt>,
  TShortProfileFieldInfoImt
>([selectProfileFieldsInfo], fields => {
  return fromJS(
    fields.toJS().reduce((acc, field) => {
      return field.necessity === FieldNecessity.Recommended
        ? { ...acc, [field.fieldType]: field.necessity }
        : acc;
    }, {}),
  );
});

export {
  selectLocale,
  selectTimezone,
  selectPeakSettings,
  selectProfileFieldsInfo,
  selectProfileRequiredFields,
  selectProfileRecommendedFields,
};
