import React from 'react';
import { OptionsWithExtraProps, ProviderContext, useSnackbar, VariantType } from 'notistack';

let useSnackbarRef: ProviderContext;
export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

export const snackbar = {
  success(msg: string | JSX.Element, options?: OptionsWithExtraProps<VariantType>) {
    this.toast(msg, { ...options, variant: 'success' });
  },
  warning(msg: string | JSX.Element, options?: OptionsWithExtraProps<VariantType>) {
    this.toast(msg, { ...options, variant: 'warning' });
  },
  info(msg: string | JSX.Element, options?: OptionsWithExtraProps<VariantType>) {
    this.toast(msg, { ...options, variant: 'info' });
  },
  error(msg: string | JSX.Element, options?: OptionsWithExtraProps<VariantType>) {
    this.toast(msg, { ...options, variant: 'error' });
  },
  toast(msg: string | JSX.Element, options?: OptionsWithExtraProps<VariantType>) {
    useSnackbarRef.enqueueSnackbar(msg, { ...options, variant: 'default' });
  },
};
