import { Navigate, Route, Routes as SwitchRoutes } from 'react-router-dom';
import React, { lazy } from 'react';

import CustomRoute from 'memberPortalModules/authentication/containers/CustomRoute/CustomRoute';
import { HOME_PATH } from 'common/constants/globalConstants';

const MemberProfileRoute = lazy(() => import('memberPortalModules/MemberProfile/MemberProfile'));
const BookingRoute = lazy(() => import('memberPortalModules/booking/BookingEvents'));

const CatchAllRoute = lazy(() => import('../CatchAll'));

export const MemberPortalRoutes = () => {
  return (
    <SwitchRoutes>
      <Route path={HOME_PATH} element={<Navigate to="profile" />} />
      <Route
        path="/profile"
        element={
          <CustomRoute secure>
            <MemberProfileRoute />
          </CustomRoute>
        }
      />
      <Route
        path="/booking"
        element={
          <CustomRoute secure>
            <BookingRoute />
          </CustomRoute>
        }
      />
      <Route element={<CatchAllRoute />} />
    </SwitchRoutes>
  );
};

export default MemberPortalRoutes;
