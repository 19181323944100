// Libraries
import { createAction } from '@reduxjs/toolkit';
// Services
import Service from 'services/network';
// Interfaces
import { GeneralThunkAction } from 'common/state/interfaces';
// Constants
import {
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_ERROR,
  UPLOAD_IMAGE_LOADING,
  RESET_UPLOAD_IMAGE,
} from 'common/state/uploadImage/constants';
import { IUploadImagePayload, IAttachment } from 'common/interfaces/uploadFile';

// Actions
const uploadAction = createAction<IUploadImagePayload>(UPLOAD_IMAGE);
const uploadLoadingAction = createAction<boolean>(UPLOAD_IMAGE_LOADING);
const uploadErrorAction = createAction<any>(UPLOAD_IMAGE_ERROR);
const resetUploadAction = createAction(RESET_UPLOAD_IMAGE);

// Action creators
export const uploadImage = (
  dialogId: string,
  file: File,
  isPublicImage = false,
): GeneralThunkAction => {
  return async dispatch => {
    try {
      dispatch(uploadLoadingAction(true));
      const imageUploadResult: IAttachment = await Service.FileUpload.uploadImage(
        file,
        isPublicImage,
      );
      dispatch(uploadAction({ dialogId, image: imageUploadResult }));
    } catch (error) {
      dispatch(uploadErrorAction(error));
    }
  };
};

export const resetUploadImage = (): GeneralThunkAction => {
  return dispatch => dispatch(resetUploadAction());
};
