export const removeLastRouteFromPath = (path: string) => {
  const splitPath = path.split('/');
  const isLastSymbolSlash = path[path.length - 1] === '/';

  if (isLastSymbolSlash) {
    splitPath.pop();
  }

  splitPath.pop();

  return splitPath.join('/')
}
