import React, { useCallback, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { List as ImmutableList } from 'immutable';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';

import { FrequencyTypes } from 'modules/services/constants/packages';
import { ActionResult } from 'common/constants';

import { CustomTheme } from 'common/ui/interfaces';
import { ITableRow } from 'common/interfaces/table';
import { PaymentsType } from 'common/interfaces/invoices';
import {
  IPackageInstanceSubscription,
  IPaymentAccountImt,
} from 'common/components/PersonProfile/interfaces';
import { IPaymentMethodItemImt } from 'modules/pos-settings/interfaces/paymentMethods';
import { IPackageInstanceDetails } from 'common/interfaces/service';

import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { DEFAULT_DATE_FORMAT } from 'common/constants/dateFormats';
import { Table } from 'common/components/index';
import PaymentMethodField from './PaymentMethodField';
import PaymentAccountField from './PaymentAccountField';
import CheckingField from 'common/components/PersonProfile/modals/PaymentMethodsModal/CheckingField';
import { useAppDispatch } from 'store/hooks';
import { deleteCheckingSavingsItemActionResult } from 'common/components/PersonProfile/state/actions';

const useStyles = makeStyles((theme: CustomTheme) => ({
  tableWrapper: {
    marginTop: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.borderColor?.main}`,
    borderTop: `1px solid ${theme.palette.borderColor?.main}`,
  },
  packageTitle: {
    marginRight: theme.spacing(1.5),
  },
}));

const PackageNameCell = ({
  packageInstance,
}: {
  packageInstance: Partial<IPackageInstanceDetails>;
}): JSX.Element => {
  const classes = useStyles();

  const { startDate, endDate, title, paymentSchedule, pricePerBilling } = packageInstance;

  const [timezoneMoment] = useTimezoneMoment();

  const renderIntlMessage = useRenderIntlMessage();

  const period = `(${timezoneMoment(startDate).format(DEFAULT_DATE_FORMAT)} - ${timezoneMoment(
    endDate,
  ).format(DEFAULT_DATE_FORMAT)})`;

  return (
    <Typography>
      <Typography variant="h5" component="span" className={classes.packageTitle}>
        {title}
      </Typography>
      <Typography component="span">
        {`$${pricePerBilling?.toFixed(2)}/${renderIntlMessage(
          FrequencyTypes.message(paymentSchedule),
        )} ${period}`}
      </Typography>
    </Typography>
  );
};

interface IProps {
  isNewCardInputDisabled: boolean;
  paymentMethods: ImmutableList<IPaymentMethodItemImt>;
  storedCreditCards: ImmutableList<IPaymentAccountImt>;
  checkingSavingsData: ImmutableList<IPaymentAccountImt>;
  deleteCardActionResult: ActionResult;
  checkingSavingsDeleteActionResult?: ActionResult;
  personId: string;
  setIsOpenAddCreditCardModal: (isOpen: boolean) => void;
  setIsOpenCheckingSavingsModal: (isOpen: boolean) => void;
}

const SubscriptionsTable = ({
  paymentMethods,
  storedCreditCards,
  isNewCardInputDisabled,
  deleteCardActionResult,
  checkingSavingsData,
  checkingSavingsDeleteActionResult,
  personId,
  setIsOpenAddCreditCardModal,
  setIsOpenCheckingSavingsModal,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { control, watch, setValue } = useFormContext();

  const classes = useStyles();

  const subscriptions: IPackageInstanceSubscription[] = watch('subscriptions');

  // TODO after refactoring, this seems to much code for so simple check, need to prettify it
  const checkIsCardPaymentOption = useCallback(
    (index: number): boolean => {
      return subscriptions[index]?.paymentMethod?.type === PaymentsType.CREDIT_CARD;
    },
    [subscriptions],
  );

  useEffect(() => {
    if (deleteCardActionResult === ActionResult.SUCCESS_ACTION && subscriptions.length) {
      subscriptions.forEach((subscription, index) => {
        const creditCardType = subscription.paymentAccount?.creditCardType;
        const isCreditCardExist = storedCreditCards.find(
          card => card.get('id') === subscription.paymentAccount?.id,
        );

        if (checkIsCardPaymentOption(index) && creditCardType && !isCreditCardExist) {
          setValue(`subscriptions.${index}.paymentAccount`, {
            ...subscription.paymentAccount,
            deleted: true,
          });
        }
      });
    }
  }, [
    checkIsCardPaymentOption,
    deleteCardActionResult,
    setValue,
    storedCreditCards,
    subscriptions,
  ]);

  useEffect(() => {
    if (checkingSavingsDeleteActionResult === ActionResult.SUCCESS_ACTION && subscriptions.length) {
      subscriptions.forEach((subscription, index) => {
        const checkingId = subscription.checking?.id;

        const isCheckingExist = checkingSavingsData.some(item => {
          return checkingId === item.get('id');
        });

        if (checkingId && !isCheckingExist) {
          setValue(`subscriptions.${index}.checking`, {
            ...subscription.checking,
            deleted: true,
          });
        }
      });

      dispatch(deleteCheckingSavingsItemActionResult(null, personId));
    }
  }, [
    checkingSavingsDeleteActionResult,
    subscriptions,
    checkingSavingsData,
    setValue,
    dispatch,
    personId,
  ]);

  const tableRows: ITableRow[] = subscriptions.map(
    (subscription: IPackageInstanceSubscription, i) => {
      const isCheckType = subscription.paymentMethod?.type === PaymentsType.CHECKING_SAVINGS;

      return {
        id: subscription.packageInstance?.packageInstanceId,
        cells: [
          {
            align: 'left',
            label: '-',
            maxWidth: '200px',
            cellComponent: (
              <Controller
                name={`subscriptions.${i}.packageInstance`}
                control={control}
                defaultValue={subscription.packageInstance}
                render={({ value }) => <PackageNameCell packageInstance={value} />}
              />
            ),
          },
          {
            label: '',
            cellComponent: (
              <Grid container spacing={1}>
                <Grid item xs={isCheckType || checkIsCardPaymentOption(i) ? 6 : 12}>
                  <PaymentMethodField
                    paymentMethods={paymentMethods}
                    subscription={subscription}
                    isNewCardInputDisabled={isNewCardInputDisabled}
                    index={i}
                  />
                </Grid>

                {isCheckType && (
                  <Grid item xs={6}>
                    <CheckingField
                      checkingSavingsData={checkingSavingsData}
                      subscription={subscription}
                      index={i}
                      setIsOpenCheckingSavingsModal={setIsOpenCheckingSavingsModal}
                    />
                  </Grid>
                )}

                {checkIsCardPaymentOption(i) && (
                  <Grid item xs={6}>
                    <PaymentAccountField
                      subscription={subscription}
                      storedCreditCards={storedCreditCards}
                      isNewCardInputDisabled={isNewCardInputDisabled}
                      index={i}
                      setIsOpenAddCreditCardModal={setIsOpenAddCreditCardModal}
                    />
                  </Grid>
                )}
              </Grid>
            ),
          },
        ],
      };
    },
  );

  return (
    <Box className={classes.tableWrapper}>
      <Table hideToolbar hideSearchInput isLoading={false} rows={tableRows} hidePagination />
    </Box>
  );
};

export default SubscriptionsTable;
