import { createAction } from '@reduxjs/toolkit';

import Services from 'services/network';
import { actionTypes } from 'modules/peak-settings/constants';

import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { updatePeakSystemSettingsAction } from 'common/state/settings/actions';

import { GeneralThunkAction } from 'common/state/interfaces';
import { batch } from 'react-redux';
import { IntegrateServices, PaymentProcessorType } from 'modules/corporate-settings/interfaces';
import { IPeakMessagingSettings } from '../interfaces';

const fetchPeakIntegrationServicesAction = createAction<IPeakMessagingSettings>(
  actionTypes.FETCH_PEAK_SETTINGS_SECTION_INFO,
);
const fetchPeakIntegrationServicesActionLoading = createAction<boolean>(
  actionTypes.FETCH_PEAK_SETTINGS_SECTION_INFO_LOADING,
);

export const fetchPeakIntegrationServicesSettings = (): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPeakIntegrationServicesActionLoading(true));

    try {
      const settings = await Services.PeakMessagingSettings.getPeakMessageSettings();

      dispatch(fetchPeakIntegrationServicesAction(settings));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPeakIntegrationServicesActionLoading(false));
    }
  };
};

const updatePeakSettingsSectionActionLoading = createAction<boolean>(
  actionTypes.UPDATE_PEAK_SETTINGS_SECTION_LOADING,
);
const updatePeakSettingsSectionAction = createAction<IPeakMessagingSettings>(
  actionTypes.UPDATE_PEAK_SETTINGS_SECTION,
);
export const resetPeakSettingsSectionUpdateActionResult = createAction(
  actionTypes.RESET_PEAK_SETTINGS_SECTION_UPDATE_ACTION_RESULT,
);

export const updatePeakIntegrationServiceSettings = (
  data: Partial<IPeakMessagingSettings>,
  service: IntegrateServices,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updatePeakSettingsSectionActionLoading(true));

    let updatedSettings;

    try {
      switch (service) {
        case IntegrateServices.ZENDESK_API:
          updatedSettings = await Services.PeakMessagingSettings.updatePeakZendeskApiSettings(data);
          break;
        case IntegrateServices.ZENDESK_WIDGET:
          updatedSettings = await Services.PeakMessagingSettings.updatePeakZendeskWidgetSettings(
            data,
          );
          break;
        case IntegrateServices.PAYEEZY:
          updatedSettings = await Services.PeakMessagingSettings.updatePeakPayeezySettings(data);
          break;
        case IntegrateServices.ITRANSACT:
          updatedSettings = await Services.PeakMessagingSettings.updatePeakITransactSettings(data);
          break;
        case IntegrateServices.COMMERCEHUB:
          updatedSettings = await Services.PeakMessagingSettings.updateCommercehubSettings(data);
          break;
        default:
          updatedSettings = null;
      }

      batch(() => {
        dispatch(updatePeakSettingsSectionAction(updatedSettings));
        dispatch(updatePeakSystemSettingsAction(updatedSettings));
      });
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(updatePeakSettingsSectionActionLoading(false));
    }
  };
};

export const changePeakIntegrationServiceStatus = (
  isActive: boolean,
  service: IntegrateServices,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updatePeakSettingsSectionActionLoading(true));

    try {
      let updatedSettings;

      switch (service) {
        case IntegrateServices.ZENDESK_API:
          updatedSettings = await Services.PeakMessagingSettings.changeZendeskApiStatus(isActive);
          break;
        case IntegrateServices.ZENDESK_WIDGET:
          updatedSettings = await Services.PeakMessagingSettings.changeZendeskWidgetStatus(
            isActive,
          );
          break;
        case IntegrateServices.PAYEEZY:
          updatedSettings = await Services.PeakMessagingSettings.changePayeezyStatus(isActive);
          break;
        default:
          updatedSettings = null;
      }

      batch(() => {
        dispatch(updatePeakSettingsSectionAction(updatedSettings));
        dispatch(updatePeakSystemSettingsAction(updatedSettings));
      });
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(updatePeakSettingsSectionActionLoading(false));
    }
  };
};

export const updatePeakPaymentProcessorType = (
  paymentProcessorType: PaymentProcessorType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updatePeakSettingsSectionActionLoading(true));

    try {
      const updatedSettings = await Services.PeakMessagingSettings.updatePaymentProcessorType(
        paymentProcessorType,
      );

      batch(() => {
        dispatch(updatePeakSettingsSectionAction(updatedSettings));
        dispatch(updatePeakSystemSettingsAction(updatedSettings));
      });
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(updatePeakSettingsSectionActionLoading(false));
    }
  };
};

const peakPrimaryColorAction = createAction<string>(actionTypes.PEAK_SETTINGS_PRIMARY_COLOR_RESULT);
const peakPrimaryColorLoadingAction = createAction<boolean>(
  actionTypes.PEAK_SETTINGS_PRIMARY_COLOR_LOADING,
);

export const fetchPeakPrimaryColor = (isPrivate?: boolean): GeneralThunkAction => {
  return async dispatch => {
    dispatch(peakPrimaryColorLoadingAction(true));

    let color: string;

    try {
      if (isPrivate) {
        color = await Services.PeakMessagingSettings.getGeneralSettings();
      } else {
        color = await Services.PeakMessagingSettings.getPeakPrimaryColor();
      }

      dispatch(peakPrimaryColorAction(color));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(peakPrimaryColorLoadingAction(false));
    }
  };
};

export const updatePeakPrimaryColor = (color: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(peakPrimaryColorLoadingAction(true));

    try {
      const primaryColor = await Services.PeakMessagingSettings.updateGeneralSettings(color);

      dispatch(peakPrimaryColorAction(primaryColor));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(peakPrimaryColorLoadingAction(false));
    }
  };
};
