import { defineMessages } from 'react-intl';

export default defineMessages({
  // Choice sign
  signViaTopaz: {
    id: 'components.signContractStep.signViaTopaz',
    defaultMessage: 'Sign now (via Topaz)',
  },
  signInPerson: {
    id: 'components.signContractStep.signInPerson',
    defaultMessage: 'Sign offline (In-person)',
  },
  signViaEmail: {
    id: 'components.signContractStep.signViaEmail',
    defaultMessage: 'Sign later (Send via Email)',
  },
  signViaSMS: {
    id: 'components.signContractStep.signViaSMS',
    defaultMessage: 'Sign later (Send via SMS)',
  },
  // Contract status
  contractStatusSigned: {
    id: 'components.signContractStep.contractStatusSigned',
    defaultMessage: 'Signed',
  },
  contractStatusUnsigned: {
    id: 'components.signContractStep.contractStatusUnsigned',
    defaultMessage: 'Unsigned',
  },
  contractStatusNoSign: {
    id: 'components.signContractStep.contractStatusNoSign',
    defaultMessage: 'No sign',
  },
  contractStatusWaiting: {
    id: 'components.signContractStep.contractStatusWaiting',
    defaultMessage: 'Waiting for signing',
  },
  // Sign contract step. Sign Via SMS
  changeTypeQuestion: {
    id: 'components.signContractStep.changeTypeQuestion',
    defaultMessage: 'Are you sure you want to change your signature type?',
  },
  changeTypeQuestionnaireModalTitle: {
    id: 'components.signContractStep.changeTypeQuestionnaireModalTitle',
    defaultMessage: 'Change signature type',
  },

  signSendDocumentTo: {
    id: 'components.signContractStep.signSendDocumentTo',
    defaultMessage: 'Document will be sent to',
  },
});
