export const actionTypes = {
  // item
  FETCH_FALL_THROUGH_REASON_ERROR: 'DICTIONARIES/FETCH_FALL_THROUGH_REASON_ERROR',
  RESET_FALL_THROUGH_REASON: 'DICTIONARIES/RESET_FALL_THROUGH_REASON',
  SET_FALL_THROUGH_REASON_ACTION_RESULT: 'DICTIONARIES/SET_FALL_THROUGH_REASON_ACTION_RESULT',
  UPDATE_STATUS_FALL_THROUGH_REASON: 'DICTIONARIES/UPDATE_STATUS_FALL_THROUGH_REASON',
  CREATE_OR_UPDATE_FALL_THROUGH_REASON_IS_LOADING:
    'DICTIONARIES/CREATE_OR_UPDATE_FALL_THROUGH_REASON_IS_LOADING',

  // list
  FETCH_FALL_THROUGH_REASONS_LIST: 'DICTIONARIES/FETCH_FALL_THROUGH_REASONS_LIST',
  FETCH_FALL_THROUGH_REASONS_LIST_IS_LOADING:
    'DICTIONARIES/FETCH_FALL_THROUGH_REASONS_LIST_IS_LOADING',
  FETCH_FALL_THROUGH_REASONS_LIST_ERROR: 'DICTIONARIES/FETCH_FALL_THROUGH_REASONS_LIST_ERROR',
  RESET_FALL_THROUGH_REASONS_LIST: 'DICTIONARIES/RESET_FALL_THROUGH_REASONS_LIST',
};
