import React from 'react';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-block',
      minWidth: '24px',
      height: '24px',
      padding: theme.spacing(0, 1),
      fontWeight: 700,
      lineHeight: '24px',
      borderRadius: '12px',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  }),
);

interface IButtonClubsCountContainerProps {
  href?: string;
  count: string | number;
}

const RoundCountButton = ({ href, count }: IButtonClubsCountContainerProps): JSX.Element => {
  const classes = useStyles();

  const renderCount = () => (
    <Typography className={classes.root} component="span">
      {count}
    </Typography>
  );

  return <>{href ? <Link to={href}>{renderCount()}</Link> : renderCount()}</>;
};

export default RoundCountButton;
