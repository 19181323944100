import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';

import { getQueueColor } from 'common/utils';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import { ChartFilter } from 'modules/reports/components/index';
import {
  PayrollActivityChartLegendLabels,
  PayrollReportActivityChartFilters,
} from 'modules/timeclock/constants';

const payrollActivityActivityTypes = Object.values(PayrollReportActivityChartFilters);

const legendColors = {
  [PayrollReportActivityChartFilters.Regular]: getQueueColor(0),
  [PayrollReportActivityChartFilters.Overtime]: getQueueColor(2),
  [PayrollReportActivityChartFilters.Scheduled]: getQueueColor(4),
};

interface IProps {
  onChange: (filters: PayrollReportActivityChartFilters[]) => void;
}

const BillingsChartLegend = ({ onChange }: IProps): JSX.Element => {
  const [filters, setFilters] = useState<PayrollReportActivityChartFilters[]>(
    payrollActivityActivityTypes,
  );

  const renderIntlMessage = useRenderIntlMessage();

  // effects

  useEffect(() => {
    onChange(filters);
  }, [filters, onChange]);

  // handlers
  const handleFilterStateChange = (filterType: PayrollReportActivityChartFilters): void => {
    if (filters.includes(filterType)) {
      setFilters(filters.filter(f => f !== filterType));
    } else {
      setFilters([...filters, filterType]);
    }
  };

  return (
    <Grid container spacing={3} justifyContent="flex-end">
      {payrollActivityActivityTypes.map((type: PayrollReportActivityChartFilters) => (
        <Grid item key={type}>
          <ChartFilter
            label={renderIntlMessage(PayrollActivityChartLegendLabels[type]) || ''}
            color={legendColors[type]}
            isSelected={filters.includes(type)}
            onChange={() => handleFilterStateChange(type)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default BillingsChartLegend;
