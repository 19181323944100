import { fromJS } from 'immutable';
import { ActionPrimaryInfoTypes } from 'common/state/newPerson/primaryInfo/actions';
import { actionTypes } from 'common/state/newPerson/familyMembers/constants';

const initialState = fromJS({
  result: [],
  fetchFamilyMembersLoading: false,
  addFamilyMemberResult: null,
  addFamilyMembersActionLoading: false,
  createMemberForFamilyMemberLoading: false,
  createMemberForFamilyMemberResult: null,
  createMemberForFamilyMemberStepLoading: false,
  createMemberForFamilyMemberStepResult: null,
});

const familyMembersReducer = (state = initialState, { type, payload }: ActionPrimaryInfoTypes) => {
  switch (type) {
    case actionTypes.SET_FAMILY_MEMBERS:
      return state.set('result', fromJS(payload));
    case actionTypes.ADD_FAMILY_MEMBERS_ACTION_RESULT:
      return state.set('addFamilyMemberResult', payload);
    case actionTypes.ADD_FAMILY_MEMBERS_ACTION_LOADING:
      return state.set('addFamilyMembersActionLoading', payload);
    case actionTypes.FETCH_FAMILY_MEMBERS_LOADING:
      return state.set('fetchFamilyMembersLoading', payload);
    case actionTypes.RESET_ADD_FAMILY_MEMBERS_ACTION_RESULT:
      return state.set('addFamilyMemberResult', null);
    case actionTypes.CREATE_MEMBER_FOR_FAMILY_MEMBER_LOADING:
      return state.set('createMemberForFamilyMemberLoading', payload);
    case actionTypes.CREATE_MEMBER_FOR_FAMILY_MEMBER_RESULT:
      return state.set('createMemberForFamilyMemberResult', fromJS(payload));
    case actionTypes.CREATE_MEMBER_FOR_FAMILY_MEMBER_STEP_LOADING:
      return state.set('createMemberForFamilyMemberStepLoading', payload);
    case actionTypes.CREATE_MEMBER_FOR_FAMILY_MEMBER_STEP_RESULT:
      return state.set('createMemberForFamilyMemberStepResult', fromJS(payload));
    case actionTypes.RESET_CREATE_FAMILY_MEMBER_RESULT:
      return initialState;
    default:
      return state;
  }
};

export default familyMembersReducer;
