export const masks = {
  PHONE: ['+', /[1]/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  SSN: [/[1-9]/, /[1-9]/, /[1-9]/, '-', /[1-9]/, /[1-9]/, /[1-9]/, '-', /[1-9]/, /[1-9]/, /[1-9]/],
  ZIP_CODE_SHORT: [/\d/, /\d/, /\d/, /\d/, /\d/],
  ZIP_CODE: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  PIN_CODE: [/\d/, /\d/, /\d/, /\d/],
  USD_MONEY: ['$', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  CLUB_CODE: [/\d/, /\d/, /\d/, /\d/],
  DEFAULT_CARD_NUMBER: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  AMEX_CARD_NUMBER: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  EXPIRED_CARD_DATE: [/\d/, /\d/, '/', /\d/, /\d/],
  CVV_CODE_DEFAULT: [/\d/, /\d/, /\d/],
  CVV_CODE_AMEX: [/\d/, /\d/, /\d/, /\d/],
};
