import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  makeStyles,
  SvgIcon,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
// icons
import { TimesCircleIcon } from 'img/icons';
// state
import * as memberActions from 'common/state/newPerson/familyMembers/actions';
import { createMemberForFamilyMemberStepResultAction } from 'common/state/newPerson/familyMembers/actions';
// interfaces
import {
  ICustomerShort,
  IFamilyMember,
  IFamilyMemberEditDTO,
  IFamilyMemberService,
  IImmutablePrimaryMemberInfo,
} from 'common/components/PersonProfile/interfaces';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
// components
import { LoadingBackdrop, ScrollBox } from 'common/components/index';
import NewMemberModal from 'common/components/PersonProfile/components/FamilyMembers/NewMemberModal/NewMemberModal';
import GuestStatus from 'modules/front-desk/components/GuestStatus/GuestStatus';
import MemberServicesAutocomplete from './MemberServicesAutocomplete/MemberServicesAutocomplete';
import MemberSearchInput from './MemberSearchInput/MemberSearchInput';
// ValidationSchema
import { FamilyMembersStepValidationSchema } from './ValidationSchema/ValidationSchema';
// hooks
import { useAppDispatch } from 'store/hooks';
import useComponentDidUpdate from 'common/hooks/useComponentDidUpdate';
// common
import { StepContext } from 'common/createContext/stepContext';

import { getInitials } from 'helpers/common';

import messages from 'common/components/PersonProfile/messages';
import isFormDirty from 'common/hooks/isFormDirty';
import useDiscardChangesContext from 'common/hooks/useDiscardChangesContext';
import DiscardChangesModalProvider from 'common/modals/DiscardChangesModal/DiscardChangesModalProvider';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1, 2),
    flex: 1,
    height: '100%',
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  memberContainer: {
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(1.5),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  memberAvatar: {
    width: '40px',
    height: '40px',
  },
  avatarWrap: {
    position: 'relative',
    flex: 'none',
    borderRadius: '3px',
    backgroundColor: theme.palette.secondary.light,
  },
  avatarInitial: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    fontSize: '16px',
    fontWeight: 700,
    color: theme.palette.darkBackground?.light,
  },
  memberName: {
    fontSize: '0.9rem',
    fontWeight: 500,
  },
  removeBtn: {
    padding: 0,

    '& .MuiSvgIcon-root': {
      fontSize: '1.4rem',
    },
  },
}));

export type TFamilyMemberFormItem = Omit<IFamilyMember, 'services'> & {
  services: IFamilyMemberService[];
};

export interface IFamilyMembersFormValues {
  familyMembers: Array<TFamilyMemberFormItem>;
}

interface IProps {
  initialFamilyMemberId?: string;
  familyMembers?: IFamilyMember[];
  memberFirstName?: string;
  isLoading: boolean;
  isSubmitting: boolean;
  module: PeakModuleForNewPersonType;
  clubId?: string;

  memberInfoId?: string;
  createMemberResult: IImmutablePrimaryMemberInfo;
  createMemberLoading: boolean;
  onHandleSubmit: (data: IFamilyMemberEditDTO) => void;
}

const transformFormValues = (
  formValues: IFamilyMembersFormValues,
  clubId: string = null,
): IFamilyMemberEditDTO => {
  if (!formValues.familyMembers) {
    return { clubId, familyMembers: [] };
  }

  return {
    clubId,
    familyMembers: formValues.familyMembers.map(member => {
      const memberServiceIds = member.services.map(service => service.packageServiceInstanceId);

      return {
        ...member,
        familyMemberId: member.rightCustomer?.id || member.leftCustomer?.id,
        familyMemberServiceInstanceIds: memberServiceIds,
      };
    }),
  };
};

const FamilyMembersStep = ({
  initialFamilyMemberId,
  familyMembers,
  memberInfoId,
  memberFirstName,
  createMemberResult,
  createMemberLoading,
  onHandleSubmit,
  isLoading,
  isSubmitting,
  clubId,
  module,
}: IProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const isCalledRef = useRef(false);
  const dcContext = useDiscardChangesContext();

  const [isNewModalOpen, setIsNewModalOpen] = useState<boolean>(false);

  const formMethods = useForm({
    defaultValues: {
      familyMembers: [],
    },
    resolver: yupResolver(FamilyMembersStepValidationSchema),
    mode: 'onChange',
  });

  const { onBack, renderFooter } = useContext(StepContext);
  const { control, errors, handleSubmit, reset, watch, formState } = formMethods;
  const isDirty = isFormDirty(formState);

  const { fields: members, append, remove } = useFieldArray({
    control,
    name: 'familyMembers',
    keyName: 'key',
  });

  const onSelectMember = (v: ICustomerShort) => {
    append({
      rightCustomer: {
        id: v.id,
        firstName: v.firstName,
        lastName: v.lastName,
        type: v.type,
        imageUrl: v.imageUrl,
      },
      leftRightRelation: '',
      rightLeftRelation: '',
      services: [],
    });
  };

  const getOptionDisabled = opt => {
    return (
      (!!members && !!members.find(member => member?.rightCustomer.id === opt.id)) ||
      memberInfoId === opt.id
    );
  };

  const onCloseNewModal = () => {
    setIsNewModalOpen(false);
  };

  const onOpenNewModal = () => {
    setIsNewModalOpen(true);
  };

  const onSubmitNewModal = newMember => {
    dispatch(
      memberActions.createMemberForFamilyMemberStep(newMember, initialFamilyMemberId, module),
    );
  };

  const onSubmitForm = (formValues: IFamilyMembersFormValues) => {
    onHandleSubmit(transformFormValues(formValues, clubId));
  };

  useComponentDidUpdate(() => {
    if (!isCalledRef.current) {
      reset({
        familyMembers: familyMembers || [],
      });
      isCalledRef.current = true;
    }
  }, [familyMembers, reset]);

  useEffect(() => {
    if (createMemberResult) {
      const person = createMemberResult.toJS();
      append({
        rightCustomer: {
          id: person.id,
          firstName: person.firstName,
          lastName: person.lastName,
          type: person.type,
          imageUrl: person.image?.url,
        },
        leftRightRelation: '',
        rightLeftRelation: '',
        services: [],
      });
      onCloseNewModal();
      createMemberForFamilyMemberStepResultAction(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createMemberResult]);

  const onSelectPersonInModal = (person: IImmutablePrimaryMemberInfo) => {
    append({
      rightCustomer: { ...person.toJS() },
      leftRightRelation: '',
      rightLeftRelation: '',
      services: [],
    });
    onCloseNewModal();
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <ScrollBox hasShadowsOnScroll>
          <form id="family-members-form" className={classes.root} autoComplete="none">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <MemberSearchInput
                  module={module}
                  personId={initialFamilyMemberId}
                  onChange={onSelectMember}
                  getOptionDisabled={getOptionDisabled}
                  onCreateNew={onOpenNewModal}
                  fullWidth
                />
              </Grid>

              {!!members && !!members.length && (
                <Grid item xs={12}>
                  {members.map((familyMember, i) => {
                    const {
                      key,
                      id,
                      leftCustomer,
                      rightCustomer,
                      leftRightRelation,
                      rightLeftRelation,
                      services,
                    } = familyMember;
                    const currentIsLeftCustomer =
                      leftCustomer?.id === initialFamilyMemberId ||
                      rightCustomer?.id === initialFamilyMemberId
                        ? leftCustomer?.id === initialFamilyMemberId
                        : true;
                    const relatedMemberName = currentIsLeftCustomer
                      ? `familyMembers[${i}].rightCustomer`
                      : `familyMembers[${i}].leftCustomer`;
                    const { imageUrl, firstName = '', lastName = '', type } = currentIsLeftCustomer
                      ? rightCustomer
                      : leftCustomer;
                    const initials = getInitials(firstName, lastName);

                    return (
                      <Grid key={key} container className={classes.memberContainer} spacing={2}>
                        <Grid item xs={12}>
                          <Box display="flex" justifyContent="space-between" alignItems="center">
                            {id && (
                              <Controller
                                control={control}
                                name={`familyMembers[${i}].id`}
                                defaultValue={id}
                                render={null}
                              />
                            )}
                            <Controller
                              name={relatedMemberName}
                              control={control}
                              defaultValue={currentIsLeftCustomer ? rightCustomer : leftCustomer}
                              as={
                                <Box display="flex">
                                  <Box className={cx(classes.memberAvatar, classes.avatarWrap)}>
                                    {initials && !imageUrl ? (
                                      <Box className={classes.avatarInitial}>{initials}</Box>
                                    ) : (
                                      <Avatar
                                        className={classes.memberAvatar}
                                        src={imageUrl}
                                        alt={firstName}
                                      />
                                    )}
                                  </Box>
                                  <Box ml={1}>
                                    <Typography
                                      className={classes.memberName}
                                    >{`${firstName} ${lastName}`}</Typography>
                                    <GuestStatus type={type} />
                                  </Box>
                                </Box>
                              }
                            />

                            <IconButton className={classes.removeBtn} onClick={() => remove(i)}>
                              <SvgIcon color="disabled">
                                <TimesCircleIcon />
                              </SvgIcon>
                            </IconButton>
                          </Box>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <Controller
                                as={TextField}
                                control={control}
                                name={`familyMembers[${i}].leftRightRelation`}
                                label={
                                  <FormattedMessage
                                    {...messages.stepRelationMemberToPerson}
                                    values={{
                                      memberFName: firstName,
                                      personFName: memberFirstName,
                                    }}
                                  />
                                }
                                variant="outlined"
                                fullWidth
                                defaultValue={leftRightRelation}
                                error={!!errors.familyMembers?.[i]?.leftRightRelation}
                                helperText={errors.familyMembers?.[i]?.leftRightRelation?.message}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Controller
                                as={TextField}
                                control={control}
                                name={`familyMembers[${i}].rightLeftRelation`}
                                label={
                                  <FormattedMessage
                                    {...messages.stepRelationPersonToMember}
                                    values={{
                                      memberFName: firstName,
                                      personFName: memberFirstName,
                                    }}
                                  />
                                }
                                variant="outlined"
                                fullWidth
                                defaultValue={rightLeftRelation}
                                error={!!errors.familyMembers?.[i]?.rightLeftRelation}
                                helperText={errors.familyMembers?.[i]?.rightLeftRelation?.message}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <Controller
                            name={`familyMembers[${i}].services`}
                            control={control}
                            defaultValue={services || []}
                            render={({ value, onBlur, onChange }) => (
                              <MemberServicesAutocomplete
                                memberId={initialFamilyMemberId}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                module={module}
                                familyMembers={watch(`familyMembers`) as TFamilyMemberFormItem[]}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </Grid>
          </form>

          <NewMemberModal
            isOpen={isNewModalOpen}
            isLoading={createMemberLoading}
            onClose={onCloseNewModal}
            onSubmit={onSubmitNewModal}
            onSelectMember={onSelectPersonInModal}
            selectedMembers={members}
            module={module}
          />
        </ScrollBox>

        <LoadingBackdrop isLoading={isLoading} />

        {!!renderFooter && renderFooter(onBack, handleSubmit(onSubmitForm), isSubmitting)}
      </FormProvider>

      {dcContext && isDirty ? <DiscardChangesModalProvider {...dcContext} /> : null}
    </>
  );
};

export default FamilyMembersStep;
