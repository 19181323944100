import { object, string, array, boolean } from 'yup';
import inputErrors from 'common/messages/inputErrors';
import { minMaxNumberSchema } from 'common/validationSchemas/minMaxNumberSchema';
import { ConditionForPurchaseType } from '../../../constants/packages';

const requiredFieldMessage = () => inputErrors.requiredFieldError;

const freezeSchema = object().shape({
  allow: boolean(),
  editableDaysNumber: object()
    .nullable()
    .when('allow', {
      is: true,
      then: minMaxNumberSchema,
    }),
});

const membershipInitialFeeSchema = object().shape({
  active: boolean(),
  override: boolean(),
  purchaseCondition: string()
    .nullable()
    .when('active', {
      is: true,
      then: string().oneOf(Object.values(ConditionForPurchaseType)),
    }),
  immediatePayment: boolean(),
  editableAmount: object()
    .nullable()
    .when(['active', 'override'], {
      is: true,
      then: minMaxNumberSchema.required(requiredFieldMessage),
    }),
  editableNumber: object()
    .nullable()
    .when(['active', 'override', 'immediatePayment'], {
      is: (active, override, immediatePayment) => active && override && !immediatePayment,
      then: minMaxNumberSchema.required(requiredFieldMessage),
    }),
  paymentEditablePeriodInDays: object()
    .nullable()
    .when(['active', 'override', 'immediatePayment'], {
      is: (active, override, immediatePayment) => active && override && !immediatePayment,
      then: minMaxNumberSchema.required(requiredFieldMessage),
    }),
});

const initialFeesSchema = array().of(membershipInitialFeeSchema);

export const membershipSchema = object().shape({
  documentTemplate: object()
    .required(requiredFieldMessage)
    .nullable(),

  freeze: freezeSchema,
  initialFees: initialFeesSchema,
});
