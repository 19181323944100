import * as yup from 'yup';

const requiredFieldMessage = 'Required field';

export const EmailTemplateSchema = yup.object().shape({
  name: yup
    .string()
    .max(32, 'Name must be 32 characters or less')
    .required(requiredFieldMessage),
  subject: yup
    .string()
    .max(70, 'Subject must be 70 characters or less')
    .required(requiredFieldMessage),
  from: yup
    .string()
    .email('Invalid email')
    .required(requiredFieldMessage),
  fromName: yup
    .string()
    .max(32, 'From name must be 32 characters or less')
    .required(requiredFieldMessage),
});
