import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { getRequiredMessage } from 'common/constants/globalConstants';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import { IProfileInfo } from 'common/components/PersonProfile/interfaces';
import { AlertCondition } from 'common/interfaces/alerts';

import { DialogComponent, TextField } from 'common/components';

import inputLabels from 'common/messages/inputLabels';
import inputErrors from 'common/messages/inputErrors';
import { useAppDispatch } from 'store/hooks';
import { useSelector } from 'react-redux';
import { selectProfileRequiredFields } from 'common/state/settings/selectors';
import { selectIsSubmittedRecommendedFieldsModal } from 'common/state/modals/selectors';
import { useRecommendedFields } from 'common/hooks/useRecommendedFields';
import {
  FieldNecessity,
  RequiredProfileInfo,
} from 'modules/corporate-settings/constants/requiredFields';
import { showRecommendedFieldsModal } from 'common/state/modals/actions';

interface IProps {
  alert: AlertCondition | null;
  isOpen: boolean;
  onSubmit: (info: IProfileInfo, alertId: string) => void;
  switchingModal: (alertCondition: AlertCondition) => void;
}

const EmailModal = ({ isOpen, switchingModal, onSubmit, alert }: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const profileRequiredFields = useSelector(selectProfileRequiredFields);
  const isSubmitted = useSelector(selectIsSubmittedRecommendedFieldsModal);

  const { checkRecommendedFieldsAreNotEmpty, profileRecommendedFields } = useRecommendedFields();

  const formMethods = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        email: yup
          .string()
          .trim()
          .email(() => inputErrors.emailInvalidError)
          .when(RequiredProfileInfo.Email, {
            is: () => {
              return (
                profileRequiredFields.get(RequiredProfileInfo.Email) === FieldNecessity.Required
              );
            },
            then: schema => schema.required(getRequiredMessage),
            otherwise: schema => schema,
          }),
      }),
    ),
    mode: 'onBlur',
  });

  const { control, handleSubmit, errors, getValues } = formMethods;

  const renderIntlMessage = useRenderIntlMessage();

  useEffect(() => {
    if (isSubmitted) {
      const values = getValues();

      onSubmit(values, alert);
      switchingModal(alert);

      dispatch(showRecommendedFieldsModal(false));
    }
  }, [alert, dispatch, getValues, isSubmitted, onSubmit, switchingModal]);

  const onSubmitForm = (values: Partial<IProfileInfo>): void => {
    const hasEmptyRecommendedFields = checkRecommendedFieldsAreNotEmpty(values);

    if (hasEmptyRecommendedFields) {
      dispatch(showRecommendedFieldsModal(true));
    } else {
      onSubmit(values, alert);
      switchingModal(alert);
    }
  };

  return (
    <DialogComponent
      size="xs"
      title={<FormattedMessage {...inputLabels.email} />}
      isOpen={isOpen}
      onClose={() => switchingModal(alert)}
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <Controller
        name="email"
        control={control}
        render={({ value, onBlur, onChange }) => (
          <TextField
            variant="outlined"
            label={<FormattedMessage {...inputLabels.email} />}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            fullWidth
            error={!!errors.email}
            helperText={renderIntlMessage(errors.email?.message)}
            recommended={Boolean(profileRecommendedFields?.get(RequiredProfileInfo.Email))}
          />
        )}
      />
    </DialogComponent>
  );
};

export default EmailModal;
