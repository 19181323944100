import axios from 'axios';
import ApiService from './ApiService';

import { ISignInResponse, IUserProfileInfo } from 'modules/authentication/interfaces';
import { IAttachment } from 'common/interfaces/uploadFile';
import {
  IEmployeeCredentialsUpdateDto,
  IEmployeeDto,
  IEmployeeJobPosition,
  IEmployeeProfileDto,
  ISecurityRoleToClubListDto,
} from 'common/interfaces/employee';
import { ITableParams } from 'common/interfaces/table';
import { IPaginatedTimeclockList } from 'modules/timeclock/interfaces/timeclock';
import { INamedEntity } from 'common/interfaces/common';

export default class AuthenticationService extends ApiService {
  public REFRESH_TOKEN_URL = 'api/v1/auth/token/refresh';

  public signIn(email: string, password: string, corporationId: string): Promise<ISignInResponse> {
    return axios.post('api/v1/auth/signIn', { email, password, corporationId });
  }

  public refreshToken(refreshToken: string): Promise<ISignInResponse> {
    return axios.post(this.REFRESH_TOKEN_URL, {}, { headers: { 'X-JWT-Token': refreshToken } });
  }

  public getCorporationsForSignIn(email: string): Promise<Array<INamedEntity>> {
    return axios.post('/api/v1/auth/corporations', { email });
  }

  public signOut(): Promise<void> {
    return axios.put('api/v1/profile/logout');
  }

  public async getProfile(): Promise<IUserProfileInfo> {
    return axios.get('/api/v1/profile');
  }

  public deletePhoto = (): Promise<void> => {
    return axios.delete(`/api/v1/profile/avatar`);
  };

  public uploadPhoto = async (file: IAttachment): Promise<void> => {
    await axios.put(`/api/v1/profile/avatar`, file);
  };

  public getProfileJobPositions = (): Promise<IEmployeeJobPosition[]> => {
    return axios.get(`/api/v1/profile/job-positions`);
  };

  public getProfileJobPositionsHistory = (): Promise<IEmployeeJobPosition[]> => {
    return axios.get(`/api/v1/profile/job-positions?active=false`);
  };

  public getProfileTimeclockHistory = (
    requestOptions?: ITableParams,
  ): Promise<IPaginatedTimeclockList> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get(`/api/v1/profile/time-clock/all`, { params: searchParams });
  };

  public getProfileRecentTimeclockHistory = (): Promise<IPaginatedTimeclockList> => {
    return axios.get(`/api/v1/profile/time-clock`);
  };

  public getProfilePermissions = (): Promise<ISecurityRoleToClubListDto[]> => {
    return axios.get(`/api/v1/profile/permissions`);
  };

  public updateProfileCredentials = (credentials: IEmployeeCredentialsUpdateDto): Promise<void> => {
    return axios.put(`/api/v1/profile/credentials`, credentials);
  };

  public getFullProfile = (): Promise<IEmployeeDto> => {
    return axios.get(`/api/v1/profile/full`);
  };

  public getFullProfileView = (): Promise<IEmployeeProfileDto> => {
    return axios.get(`/api/v1/profile/full/view`);
  };

  public updateFullProfile = (data: IEmployeeDto): Promise<IEmployeeProfileDto> => {
    return axios.put(`/api/v1/profile/full`, data);
  };

  public getProfileAttachments = (): Promise<IAttachment[]> => {
    return axios.get(`/api/v1/profile/attachments`);
  };

  public deleteProfileAttachment = (attachmentId: string): Promise<void> => {
    return axios.delete(`/api/v1/profile/attachments/${attachmentId}`);
  };

  public updateProfileAttachments = (attachments: IAttachment[]): Promise<void> => {
    return axios.put(`/api/v1/profile/attachments`, attachments);
  };
}
