import {
  IRegisterPosCategory,
  IRegisterPosItem,
  IRegisterProductItem,
} from '../interfaces/register';
import { MenuItemTypes } from 'common/interfaces/invoices';
import { ISimpleClubInventoryItem } from '../interfaces/inventoryItems';

export const actionTypes = {
  // Errors
  PUSH_REQUEST_ERROR: 'PUSH_REQUEST_ERROR',
  RESET_REQUEST_ERRORS: 'RESET_REQUEST_ERRORS',
  // Register status
  FETCH_REGISTERS_LIST_LOADING: 'FETCH_REGISTERS_LIST_LOADING',
  FETCH_REGISTERS_LIST: 'FETCH_REGISTERS_LIST',
  FETCH_REGISTER_POS_CHANGE_STATUS_LOADING: 'FETCH_REGISTER_POS_CHANGE_STATUS_LOADING',
  RESET_REGISTERS_LIST: 'RESET_REGISTERS_LIST',
  FETCH_REGISTER_POS_CHANGE_STATUS: 'FETCH_REGISTER_POS_CHANGE_STATUS',
  RESET_CHANGE_STATUS_SUCCESS: 'RESET_CHANGE_STATUS_SUCCESS',
  // Register status by id
  FETCH_REGISTER_POS_BY_ID_LOADING: 'FETCH_REGISTER_POS_BY_ID_LOADING',
  FETCH_REGISTER_POS_BY_ID: 'FETCH_REGISTER_POS_BY_ID',
  CREATE_REGISTER_POS_BY_ID: 'CREATE_REGISTER_POS_BY_ID',
  UPDATE_REGISTER_POS_BY_ID: 'UPDATE_REGISTER_POS_BY_ID',
  RESET_REGISTER_BY_ID: 'RESET_REGISTER_BY_ID',
  // search items
  FETCH_SEARCH_ITEMS_BY_LOADING: 'FETCH_SEARCH_ITEMS_BY_LOADING',

  FETCH_SEARCH_ITEMS_BY_NAME: 'FETCH_SEARCH_ITEMS_BY_NAME',
  RESET_ITEMS_BY_NAME: 'RESET_ITEMS_BY_NAME',

  FETCH_SEARCH_ITEMS_BY_CODE: 'FETCH_SEARCH_ITEMS_BY_CODE',
  RESET_ITEMS_BY_RCODE: 'RESET_ITEMS_BY_RCODE',

  FETCH_RCODES_BY_NAME: 'FETCH_RCODES_BY_NAME',
  FETCH_RCODES_BY_NAME_LOADING: 'FETCH_RCODES_BY_NAME_LOADING',
  RESET_RCODES_BY_NAME: 'RESET_RCODES_BY_NAME',
};

export const sortMenuItems = (menuItems: IRegisterProductItem[]): IRegisterProductItem[] =>
  menuItems.map((item, index) => ({
    ...item,
    order: index,
    subItems: item.subItems && sortMenuItems(item.subItems),
  }));

export const convertPosCategoryIntoMenuItems = (
  posCategory: IRegisterPosCategory,
): IRegisterProductItem[] => {
  if (!posCategory) return [];
  const { id, childButtonList, posButtonInventoryClubList } = posCategory;
  const categories: IRegisterProductItem[] = childButtonList
    ? childButtonList
        .map(
          (button): IRegisterProductItem => ({
            id: button.id,
            order: button.order,
            title: button.title,
            type: MenuItemTypes.Category,
            parentId: id,
            subItems: convertPosCategoryIntoMenuItems(button),
          }),
        )
        .sort((a: IRegisterProductItem, b: IRegisterProductItem) => a.order - b.order)
    : [];

  const items: IRegisterProductItem[] = posButtonInventoryClubList
    ? posButtonInventoryClubList
        .map(
          (inventoryItem): IRegisterProductItem => ({
            id: inventoryItem.inventoryClub.id,
            inventoryId: inventoryItem.inventoryClub.id,
            order: inventoryItem.order,
            title: inventoryItem.inventoryClub.title,
            active: inventoryItem.inventoryClub.active,
            imageUrl: inventoryItem.inventoryClub.imageUrl,
            onHandAmount: inventoryItem.inventoryClub.onHandAmount,
            type: MenuItemTypes.Item,
            price: inventoryItem.inventoryClub.retailPrice,
            parentId: id,
            revenueCodeId: inventoryItem.inventoryClub.revenueCodeId,
            taxAmount: inventoryItem.inventoryClub.taxAmount,
          }),
        )
        .sort((a: IRegisterProductItem, b: IRegisterProductItem) => a.order - b.order)
    : [];

  return [...categories, ...items].sort(
    (a: IRegisterProductItem, b: IRegisterProductItem) => a.order - b.order,
  );
};

export const convertMenuItemsIntoPosCategory = (
  menuItems: IRegisterProductItem[],
  parentCategory?: IRegisterProductItem,
  parentCategoryOrder?: number,
  isFirstNestingLevel = true,
): IRegisterPosCategory => {
  return {
    id: isFirstNestingLevel ? menuItems[0]?.parentId : parentCategory?.id,
    title: parentCategory?.title || 'Home',
    order: parentCategoryOrder || 0,
    childButtonList: menuItems
      .filter(item => item.type === MenuItemTypes.Category)
      .map(categoryItem =>
        convertMenuItemsIntoPosCategory(
          categoryItem.subItems,
          categoryItem,
          categoryItem.order,
          false,
        ),
      ),
    posButtonInventoryClubList: menuItems
      .filter(item => item.type === MenuItemTypes.Item)
      .map(
        (item): IRegisterPosItem => ({
          id: item.inventoryId,
          order: item.order,
          inventoryClub: {
            ...item,
            retailPrice: item.price,
            taxAmount: item.price,
          },
        }),
      ),
  };
};

export const filterMenuItemsByRcodes = (
  menuItems: IRegisterProductItem[],
  rcodesIds: string[],
): IRegisterProductItem[] =>
  menuItems
    ? menuItems
        .map(item => ({
          ...item,
          subItems: filterMenuItemsByRcodes(item.subItems, rcodesIds),
        }))
        .filter(item => item.type !== MenuItemTypes.Item || rcodesIds.includes(item.revenueCodeId))
    : [];

export const convertSearchInventoryItemsIntoIntoMenuItems = (
  menuItems: ISimpleClubInventoryItem[],
  type: MenuItemTypes,
): IRegisterProductItem[] =>
  menuItems.map(inventoryItem => ({
    ...inventoryItem,
    type,
    price: inventoryItem.retailPrice,
    order: 0,
    parentId: '',
  }));
