import * as yup from 'yup';

export const FamilyMembersStepValidationSchema = yup.object().shape({
  familyMembers: yup.array().of(
    yup.object().shape({
      leftRightRelation: yup
        .string()
        .nullable()
        .max(100, 'Field must be 100 characters or less')
        .required('Required field'),
      rightLeftRelation: yup.string().max(100, 'Field must be 100 characters or less'),
    }),
  ),
});
