import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { fetchPosKioskRegisterTotals } from 'modules/pos-kiosk/state/register/actions';
import * as selectors from 'modules/pos-kiosk/state/register/selectors';

import { PeakModules } from 'common/constants/peakModules';
import { StepContext } from 'common/createContext/stepContext';

import {
  IPosKioskReconcileDTO,
  IPosKioskRegisterTotalsImt,
} from 'modules/pos-kiosk/interfaces/registers';
import { PaymentsType } from 'common/interfaces/invoices';

import { LoadingBackdrop } from 'common/components';
import TotalSalesItem from './TotalSalesItem';

import { useAppDispatch } from 'store/hooks';
import { getRequiredMessage } from 'common/constants/globalConstants';

import messages from 'modules/pos-kiosk/messages/messages';
import inputErrors from 'common/messages/inputErrors';

interface IProps {
  actual: Partial<IPosKioskReconcileDTO>;
  module: PeakModules;
  registerId: string;
}

const getValidationSchema = (cashVariance, checkVariance) => {
  return yup.object().shape({
    cashVarianceNote: cashVariance
      ? yup
          .string()
          .trim()
          .max(1000, () => inputErrors.varianceMaxLengthError)
          .required(getRequiredMessage)
      : yup.string(),
    checkVarianceNote: checkVariance
      ? yup
          .string()
          .trim()
          .max(1000, () => inputErrors.varianceMaxLengthError)
          .required(getRequiredMessage)
      : yup.string(),
  });
};

const ReconcileTotalsStep = ({ actual, module, registerId }: IProps): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const registerTotals: IPosKioskRegisterTotalsImt = useSelector(
    selectors.selectPosKioskRegisterTotals,
  );
  const isRegisterTotalsLoading: boolean = useSelector(
    selectors.selectPosKioskRegisterTotalsLoading,
  );

  const { onBack, renderFooter, onNext } = useContext(StepContext);

  const {
    cashDesk: { cashAmount, checkAmount },
  } = actual;

  useEffect(() => {
    dispatch(fetchPosKioskRegisterTotals(registerId, cashAmount, checkAmount, module));
  }, [cashAmount, checkAmount, dispatch, module, registerId]);

  const cashVariance = registerTotals
    .get('totalSalesItemList')
    ?.find(payItem => payItem.get('paymentMethodTitle') === PaymentsType.CASH)
    ?.get('variance');

  const checkVariance = registerTotals
    .get('totalSalesItemList')
    ?.find(payItem => payItem.get('paymentMethodTitle') === PaymentsType.CHECKING_SAVINGS)
    ?.get('variance');

  const formMethods = useForm<IPosKioskReconcileDTO>({
    resolver: yupResolver(getValidationSchema(cashVariance, checkVariance)),
    mode: 'onBlur',
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = formMethods;

  const onSubmit = data => {
    onNext(data);
  };

  return (
    <FormProvider {...formMethods}>
      <form>
        {registerTotals.get('totalSalesItemList')?.map(totalSalesItem => (
          <TotalSalesItem item={totalSalesItem} />
        ))}

        {!isValid && (
          <Box mt={2}>
            <Alert severity="warning" icon={false}>
              <FormattedMessage {...messages.enterVarianceText} />
            </Alert>
          </Box>
        )}

        <LoadingBackdrop isLoading={isRegisterTotalsLoading} />

        {renderFooter(onBack, handleSubmit(onSubmit))}
      </form>
    </FormProvider>
  );
};

export default ReconcileTotalsStep;
