import { createPersonSelectorFactory } from 'common/components/PersonProfile/state/selectors';

export const makeSelectPersonFamilyMembers = createPersonSelectorFactory('familyMembers');
export const makeSelectPersonFamilyMembersLoading = createPersonSelectorFactory(
  'familyMembersLoading',
);
export const makeSelectPersonAddFamilyMemberLoading = createPersonSelectorFactory(
  'addFamilyMemberLoading',
);
export const makeSelectPersonAddFamilyMemberActionResult = createPersonSelectorFactory(
  'addFamilyMemberActionResult',
);
