import React, { useState } from 'react';
import Leads from './Leads';
import { useLocation } from 'react-router-dom';

export interface IViewTypeData {
  table: { locationSearch: string; selected: boolean; isInitialized: boolean };
  pipeline: { locationSearch: string; selected: boolean; isInitialized: boolean };
}

const LeadsContainer = () => {
  const location = useLocation();

  const [viewTypeData, setViewTypeData] = useState<IViewTypeData>({
    table: { locationSearch: location.search, selected: false, isInitialized: false },
    pipeline: { locationSearch: location.search, selected: true, isInitialized: false },
  });

  const viewType = viewTypeData.table.selected ? 'table' : 'pipeline';
  const { locationSearch, isInitialized } = viewTypeData[viewType];

  return (
    <Leads
      key={viewType}
      viewType={viewType}
      locationSearch={locationSearch}
      setViewTypeData={setViewTypeData}
      viewTypeData={viewTypeData}
      isTableInitialized={isInitialized}
    />
  );
};

export default LeadsContainer;
