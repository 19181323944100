import React, { useMemo } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Box, CardContent, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
// messages
import messages from 'modules/services/messages/messages';
// interfaces
import { IFormStepStyle, IMembershipSection } from 'modules/services/interfaces/packages';
// components
import { MultipleSelect } from 'common/components';
import ConditionsForPurchaseSelector from './ConditionsForPurchaseSelector/ConditionsForPurchaseSelector';
import { List as ImmutableList } from 'immutable';
import { TDocumentTemplateListItemImt } from 'common/interfaces/dictionary';
import { useSelector } from 'react-redux';
import * as dictionarySelectors from 'common/state/dictionary/selectors';
import { DictionaryList } from 'common/constants';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    flex: 1,
    minHeight: 0,
    overflow: 'hidden',
  },
  hidden: {
    display: 'none',
  },
  cardContent: {
    padding: ({ smallPaddingContent }: IFormStepStyle) =>
      smallPaddingContent ? theme.spacing(1, 0) : theme.spacing(1, 2),
    '&:last-child': {
      padding: ({ smallPaddingContent }: IFormStepStyle) =>
        smallPaddingContent ? theme.spacing(1, 0) : theme.spacing(1, 2),
    },
  },
  durationEditableWrap: {
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      padding: 0,
    },
    '& >div .MuiOutlinedInput-root': {
      width: 'calc(100% + 1px)',
    },
    '& >div:last-child .MuiOutlinedInput-root': {
      width: '100%',
    },
  },
}));

interface IMembershipStepProps extends IFormStepStyle {
  formMethods: UseFormMethods<IMembershipSection>;
  multi?: string;
}

const MembershipStepForm = ({
  formMethods,
  smallPaddingContent,
}: IMembershipStepProps): JSX.Element => {
  const classes = useStyles({ smallPaddingContent });
  const renderIntlMessage = useRenderIntlMessage();

  const documentsList: ImmutableList<TDocumentTemplateListItemImt> = useSelector(
    dictionarySelectors.selectDictionaryList(DictionaryList.DOCUMENT_TEMPLATES),
  );

  const documentsOptions = useMemo(() => documentsList.toJS(), [documentsList]);

  const { control, errors } = formMethods;

  return (
    <Box className={classes.contentWrapper}>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="documentTemplate"
              render={({ value, onChange, onBlur }) => (
                <MultipleSelect
                  options={documentsOptions}
                  fullWidth
                  label={<FormattedMessage {...messages.waiverTemplateLabel} />}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.documentTemplate}
                  helperText={renderIntlMessage(errors.documentTemplate?.message)}
                />
              )}
            />
          </Grid>

          <ConditionsForPurchaseSelector formMethods={formMethods} />
        </Grid>
      </CardContent>
    </Box>
  );
};

export default React.memo(MembershipStepForm);
