import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';

import { FilterTypes, FilterViewTypes, IFilter, IFilterSettings } from 'common/interfaces/filter';
import { deepEquals } from 'common/utils';

import FilterList from '../FilterList/FilterList';
import DropdownFilter from '../DropdownFilter/DropdownFilter';

interface IProps {
  title: string;
  filters: string[];
  settings: IFilterSettings[];
  values: IFilter[];
  onFiltersChange: (values: IFilter[], changedFilterName?: string) => void;
}

const MoreFilters = ({
  title,
  filters,
  settings,
  values,
  onFiltersChange,
}: React.PropsWithChildren<IProps>) => {
  // state

  const [filtersState, setFiltersState] = useState<IFilter[]>(values);
  const moreFiltersTotal = filtersState.filter(filterState => filters.includes(filterState.name))
    .length;

  const mapFilterArray = (array: IFilter[]) => {
    return array.map((filterItem: IFilter) => {
      if (
        filterItem.type === FilterTypes.MULTIPLE ||
        filterItem.type === FilterTypes.MULTIPLE_WITH_PAGINATE
      ) {
        if (Array.isArray(filterItem.value)) {
          return filterItem.value.map(item => ({ ...item, label: '' }));
        }
        return [];
      }
      return { ...filterItem.value, label: '' };
    });
  };
  // effects

  useEffect(() => {
    if (!deepEquals(mapFilterArray(filtersState), mapFilterArray(values))) {
      setFiltersState(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  // handlers

  const handleCloseFiltersMenu = useCallback(() => {
    if (!deepEquals(mapFilterArray(filtersState), mapFilterArray(values))) {
      onFiltersChange(filtersState, null);
    }
  }, [filtersState, onFiltersChange, values]);

  const handleOnFiltersChange = (
    updatedFilterState,
    closeMenu?: () => void,
    changedFilterName?: string,
  ): void => {
    if (!deepEquals(mapFilterArray(updatedFilterState), mapFilterArray(values))) {
      onFiltersChange(updatedFilterState, changedFilterName);
      if (closeMenu) closeMenu();
    }
  };

  // renders

  const renderFilterBody = (closeMenu?: () => void): JSX.Element => (
    <FilterList
      filters={filters}
      settings={settings}
      values={filtersState}
      onFiltersChange={(newFilterState, changedFilterName) => {
        handleOnFiltersChange(newFilterState, closeMenu, changedFilterName);
      }}
      filterView={FilterViewTypes.HOVER_MENU}
    />
  );

  return (
    <Grid item>
      <DropdownFilter
        titleBold
        title={title}
        name="hidden-filters"
        filtersTotal={moreFiltersTotal}
        onClose={handleCloseFiltersMenu}
        renderBody={renderFilterBody}
      />
    </Grid>
  );
};

export default React.memo(MoreFilters);
