import { combineReducers } from 'redux-immutable';
import contactInfo from './contactInfo/reducer';
import personContract from './contract/reducer';
import membership from './membership/reducer';
import additionalInfo from './additionalInfo/reducer';
import familyMembers from './familyMembers/reducer';
import personWaivers from './waiver/reducer';
import person from './primaryInfo/reducer';
import scheduleTour from './scheduleTour/reducer';

export default combineReducers({
  personContract,
  personWaivers,
  membership,
  additionalInfo,
  familyMembers,
  contactInfo,
  person,
  scheduleTour,
});
