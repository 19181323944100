export const regExp = {
  NAME: /^([^0-9]*)$/,
  EMAIL: /^([A-Za-z0-9_-]+\.)*[A-Za-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/,
  SSN: /^(X|\d){3}-(X|\d){2}-\d{4}$/,
  BARCODE: /^[A-Za-z0-9_-]+$/,
  PHONE: /^\+?\d{1,3}?[- .]?\(?(?:\d{2,3})\)?[- .]?\d{3}[- .]?\d{4}$/, // TODO delete
  REPLACE_BARCODE: /[^a-zA-Z0-9\\:_\- ]/g,
  ZIP_CODE: /^[0-9]{5}(?:-[0-9]{4})?$/,
  DOCUMENT_TAG_REGEX: /\{(.?)\{\s?(\w+)\s?\}(.?)\}/gi,
  DOCUMENT_SIGNATURE_REGEX: /\[(.?)\[\s?(SIGNATURE)\s?\](.?)\]/gi,
  ONLY_FOUR_NUMBER_OR_LETTERS: /^[A-Za-z0-9]{4}$/,
};
