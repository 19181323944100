import { defineMessages } from 'react-intl';

export default defineMessages({
  addressInfo: {
    id: 'app.common.modalTitles.addressInfo',
    defaultMessage: 'Address Information',
  },
  personalInfo: {
    id: 'app.common.modalTitles.personalInfo',
    defaultMessage: 'Personal Information',
  },
  phoneNumber: {
    id: 'app.common.modalTitles.phoneNumber',
    defaultMessage: 'Phone Number',
  },
  pinCode: {
    id: 'app.common.modalTitles.pinCode',
    defaultMessage: 'PIN code',
  },
  note: {
    id: 'app.common.modalTitles.note',
    defaultMessage: 'Note',
  },
  setFallthroughCapture: {
    id: 'app.common.modalTitles.setFallthroughCapture',
    defaultMessage: 'Set Fallthrough Capture',
  },
  changePackagePlan: {
    id: 'app.common.modalTitles.ChangePackagePlan',
    defaultMessage: 'Change bundle plan',
  },
  addMembership: {
    id: 'app.common.modalTitles.addMembership',
    defaultMessage: 'Add Membership',
  },
  newSmsTitle: {
    id: 'app.common.modalTitles.newSmsTitle',
    defaultMessage: 'New SMS',
  },
  smsHistory: {
    id: 'app.common.modalTitles.smsHistory',
    defaultMessage: 'SMS History',
  },
  emailHistory: {
    id: 'app.common.modalTitles.emailHistory',
    defaultMessage: 'Email History',
  },
  remindersHistory: {
    id: 'app.common.modalTitles.remindersHistory',
    defaultMessage: 'Reminders History',
  },
  callHistory: {
    id: 'app.common.modalTitles.remindersHistory',
    defaultMessage: 'Call History',
  },
  campaignsHistory: {
    id: 'app.common.modalTitles.campaignsHistory',
    defaultMessage: 'Campaigns History',
  },
  replyTitle: {
    id: 'app.common.modalTitles.replyTitle',
    defaultMessage: 'Reply',
  },
  shareTitle: {
    id: 'app.common.modalTitles.shareTitle',
    defaultMessage: 'Share',
  },
  signInToConfirmTitle: {
    id: 'app.common.modalTitles.signInToConfirmTitle',
    defaultMessage: 'Sign in to confirm',
  },
  addLinkTitle: {
    id: 'app.common.modalTitles.addLinkTitle',
    defaultMessage: 'Add Link',
  },
  redeemAppointmentTitle: {
    id: 'app.common.modalTitles.redeemAppointmentTitle',
    defaultMessage: 'Redeem Appointment',
  },
});
