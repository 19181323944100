import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';
import { actionTypes } from '../constants';

const initialBannersListState = fromJS({
  list: [],
  meta: {},
  isLoading: false,
  updateCreateBannerLoading: false,
  updateStatusLoading: false,
  updateBannerResult: null,
  isListUpdated: false,
});

function bannersAdvertisementReducer(state = initialBannersListState, action) {
  switch (action.type) {
    case actionTypes.FETCH_BANNERS_ADVERTISEMENT_LIST:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta));

    case actionTypes.FETCH_BANNERS_ADVERTISEMENT_LIST_LOADING:
      return state.set('isLoading', action.payload);

    case actionTypes.SET_IS_BANNERS_ADVERTISEMENT_LIST_UPDATED:
    case actionTypes.CREATE_BANNER_ADVERTISEMENT:
    case actionTypes.UPDATE_BANNER_ADVERTISEMENT_STATUS:
      return state.set('isListUpdated', action.payload);

    case actionTypes.UPDATE_CREATE_BANNER_ADVERTISEMENT_LOADING:
      return state.set('updateCreateBannerLoading', action.payload);

    case actionTypes.UPDATE_BANNER_ADVERTISEMENT:
      return state.update('list', list => {
        return list.map(item => (item.get('id') === action.payload.id ? action.payload : item));
      });

    case actionTypes.UPDATE_BANNER_RESULT:
      return state.set('updateBannerResult', action.payload);

    case actionTypes.UPDATE_BANNER_ADVERTISEMENT_STATUS_LOADING:
      return state.set('updateStatusLoading', action.payload);

    default:
      return state;
  }
}

const initialNotificationsListState = fromJS({
  list: [],
  meta: {},
  isLoading: false,
  updateCreateNotificationtLoading: false,
  updateStatusLoading: false,
  isListUpdated: false,
  updateNotificationtResult: null,
});

function pushNotificationsReducer(state = initialNotificationsListState, action) {
  switch (action.type) {
    case actionTypes.FETCH_PUSH_NOTIFICATIONS_LIST:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta));

    case actionTypes.FETCH_PUSH_NOTIFICATIONS_LIST_LOADING:
      return state.set('isLoading', action.payload);

    case actionTypes.SET_IS_PUSH_NOTIFICATIONS_LIST_UPDATED:
    case actionTypes.CREATE_PUSH_NOTIFICATION:
    case actionTypes.UPDATE_PUSH_NOTIFICATION_STATUS:
      return state.set('isListUpdated', action.payload);

    case actionTypes.UPDATE_CREATE_PUSH_NOTIFICATION_LOADING:
      return state.set('updateCreateNotificationtLoading', action.payload);

    case actionTypes.UPDATE_PUSH_NOTIFICATION:
      return state.update('list', list => {
        return list.map(item => (item.get('id') === action.payload.id ? action.payload : item));
      });

    case actionTypes.UPDATE_PUSH_NOTIFICATION_RESULT:
      return state.set('updateNotificationtResult', action.payload);

    case actionTypes.UPDATE_PUSH_NOTIFICATION_STATUS_LOADING:
      return state.set('updateStatusLoading', action.payload);

    default:
      return state;
  }
}

export default combineReducers({
  bannersAdvertisement: bannersAdvertisementReducer,
  pushNotifications: pushNotificationsReducer,
});
