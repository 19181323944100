import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import clsx from 'clsx';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
// state
import * as selectors from 'common/state/invoice/selectors';
import { fetchSenderAvailabilityThunk } from 'modules/booking/state/senderAvailability/actions';

// hooks
import { useAppDispatch } from 'store/hooks';
// interfaces
import { IInvoiceDetailsImt, INotifications, InvoiceStatus } from 'common/interfaces/invoices';
// constants
import { PeakModules } from 'common/constants/peakModules';
import inputLabels from 'common/messages/inputLabels';
import commonMessages from 'common/messages/messages';
import { fetchReceipt } from 'common/state/invoice/actions';
import { ENotificationType } from 'modules/booking/constants/notificationType';
import { SenderAvailabilityTypeList } from 'modules/booking/constants/senderAvailability';
import { InvoiceStatuses } from 'common/constants/invoices';
import PrintKeysService from 'services/PrintKeyStorageService/PrintKeyStorageService';
// components
import { ReactComponent as PrintIcon } from 'img/icons/printer_deprecated.svg';
import { Button, ButtonWithCircularProgress, DialogComponent } from 'common/components';
import CheckOutResponseNamesInfo from 'common/components/InvoiceOperating/InvoiceModals/CheckOutResponseModal/CheckOutResponseNamesInfo';
import CheckOutResponsePaymentInfo from 'common/components/InvoiceOperating/InvoiceModals/CheckOutResponseModal/CheckOutResponsePaymentInfo';
import CheckOutResponsePaymentNotifyControl from 'common/components/InvoiceOperating/InvoiceModals/CheckOutResponseModal/CheckOutResponsePaymentNotifyControl';
import { useCombinePrintLoading } from 'common/hooks/useCombinePrintLoading';
import useComponentDidUpdate from 'common/hooks/useComponentDidUpdate';
import { setPrinterModalState } from 'common/state/printHTML/actions';
import { selectSetPrinterModalState } from 'common/state/printHTML/selectors';
import { SavePrintKeysModal } from 'common/modals';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    overflow: 'hidden',
  },
  statusWrap: {
    display: 'inline-flex',
    minWidth: 24,
    height: 24,
    textAlign: 'center',
    padding: '3px 7px 3px 7px',
    borderRadius: 3,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    fontSize: theme.typography.h5.fontSize,
    marginLeft: theme.spacing(1),
  },
  pending: {
    backgroundColor: theme.palette.warning.main,
  },
  printIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
}));

interface ICheckOutNotificationResponse {
  checkoutData: IInvoiceDetailsImt;
  notificationData: INotifications;
}

interface ICheckOutResponseModal {
  onSubmit: (value: ICheckOutNotificationResponse) => void;
  module: PeakModules;
  event: ENotificationType;
  isCustomer: boolean;
  checkOutResponse: IInvoiceDetailsImt;
}

const CheckOutResponseModal = ({
  onSubmit,
  module,
  event,
  isCustomer,
  checkOutResponse,
}: ICheckOutResponseModal): JSX.Element => {
  const classes = useStyles();

  // global state
  const dispatch = useAppDispatch();

  const isCheckOutLoading: boolean = useSelector(selectors.selectUpdatedInvoiceLoading);
  const isReceiptLoading: boolean = useSelector(selectors.selectReceiptLoading);
  const isUpdateInvoiceLoading = useSelector(selectors.selectIsUpdateInvoiceLoading);
  const { isOpenSetPrinterModal, printSessionExists } = useSelector(selectSetPrinterModalState);

  const [isOpenModal, setIsOpenModal] = useState(false);
  // form

  const formMethods = useForm<INotifications>({
    defaultValues: {
      print: false,
      sendByEmail: false,
      sendByText: false,
      sendNotification: false,
    },
    mode: 'onBlur',
  });

  const { handleSubmit, reset, getValues } = formMethods;

  // handlers

  const handlePrintReceipt = useCallback(
    (isSilentPrint?: boolean) => {
      dispatch(
        fetchReceipt({ module, invoiceId: checkOutResponse.get('id'), isCustomer, isSilentPrint }),
      );
    },
    [dispatch, module, checkOutResponse, isCustomer],
  );

  const onSubmitModal = (data: INotifications) => {
    const resultData: ICheckOutNotificationResponse = {
      checkoutData: checkOutResponse,
      notificationData: data,
    };

    if (data.print) {
      if (PrintKeysService.getPrivateKey() && PrintKeysService.getDigitalCertificateKey()) {
        dispatch(setPrinterModalState({ isOpenSetPrinterModal: true }));
      } else {
        setIsOpenModal(true);
      }
    } else {
      onSubmit(resultData);
    }
  };

  // effect

  const isPrinting = useCombinePrintLoading(isReceiptLoading);

  useEffect(() => {
    reset({
      print: false,
      sendByEmail: false,
      sendByText: false,
      sendNotification: false,
    });
  }, [reset]);

  useEffect(() => {
    dispatch(
      fetchSenderAvailabilityThunk([SenderAvailabilityTypeList.POS], {
        module,
        events: [event],
      }),
    );
  }, [dispatch, event, module]);

  useComponentDidUpdate(() => {
    if (!isOpenSetPrinterModal && printSessionExists) {
      const data: INotifications = getValues();
      const resultData: ICheckOutNotificationResponse = {
        checkoutData: checkOutResponse,
        notificationData: data,
      };

      dispatch(setPrinterModalState({ printSessionExists: false }));
      handlePrintReceipt(true);
      onSubmit(resultData);
    }
  }, [isOpenSetPrinterModal, printSessionExists, getValues, checkOutResponse, onSubmit]);

  useComponentDidUpdate(() => {
    if (
      !isOpenModal &&
      PrintKeysService.getPrivateKey() &&
      PrintKeysService.getDigitalCertificateKey()
    ) {
      dispatch(setPrinterModalState({ isOpenSetPrinterModal: true }));
    }
  }, [isOpenModal, dispatch]);

  return (
    <DialogComponent
      size="xs"
      title={
        <Box display="flex" alignItems="center">
          {checkOutResponse.size ? `Invoice #${checkOutResponse.get('number')}` : ''}
          <Box
            className={clsx(
              classes.statusWrap,
              checkOutResponse.get('status') === InvoiceStatus.PAID ? '' : classes.pending,
            )}
          >
            <Typography color="inherit">
              {InvoiceStatuses.translate(checkOutResponse.get('status'))}
            </Typography>
          </Box>
        </Box>
      }
      submitBtnTitle="Ok"
      isOpen={checkOutResponse.size > 0}
      disableFullScreenBehavior
      cancelBtn={false}
      submitBtn={false}
      onClose={() => {}}
      additionalActionButtons={[
        <Button
          variant="outlined"
          fullWidth
          disabled={isPrinting}
          color="primary"
          startIcon={<PrintIcon className={classes.printIcon} />}
          type="button"
          onClick={() => handlePrintReceipt()}
        >
          <FormattedMessage {...inputLabels.printReceipt} />
        </Button>,
        <ButtonWithCircularProgress
          // TODO set autoFocus={true} when material will be V5
          autoFocus={false}
          fullWidth
          type="submit"
          disabled={isPrinting}
          isSubmitting={isCheckOutLoading || isUpdateInvoiceLoading}
          onClick={handleSubmit(onSubmitModal)}
        >
          <FormattedMessage {...commonMessages.okBtn} />
        </ButtonWithCircularProgress>,
      ]}
      closeCross={false}
    >
      <FormProvider {...formMethods}>
        <form>
          <Box className={classes.wrapper} pb={3}>
            {checkOutResponse.size > 0 && (
              <>
                <CheckOutResponseNamesInfo checkOutData={checkOutResponse} />

                <CheckOutResponsePaymentInfo checkOutData={checkOutResponse} />

                <CheckOutResponsePaymentNotifyControl />
              </>
            )}
          </Box>
        </form>
      </FormProvider>
      {isOpenModal && <SavePrintKeysModal isOpen setIsOpenModal={setIsOpenModal} />}
    </DialogComponent>
  );
};

export default CheckOutResponseModal;
