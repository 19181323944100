import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TableRow, TableCell, Typography, TableFooter as MuiTableFooter } from '@material-ui/core';
import cx from 'classnames';
import { IFooterCell, IFooterOptions } from 'common/interfaces/table';
import EmptyTableCell from '../EmptyTableCell/EmptyTableCell';

const useStyles = makeStyles(() =>
  createStyles({
    footerCell: {
      borderBottom: 'none',
      '&.stickyCell': {
        position: 'sticky',
        bottom: 0,
        backgroundColor: '#fff',
      },
    },
  }),
);

interface IProps {
  className?: string;
  footerOptions?: IFooterOptions;
  hidePadding?: boolean;
}

const TableFooter = ({ className, footerOptions, hidePadding }: IProps): JSX.Element => {
  const classes = useStyles();
  const { cells, sticky } = footerOptions;
  return (
    <MuiTableFooter className={className}>
      <TableRow tabIndex={-1}>
        {!hidePadding && (
          <EmptyTableCell className={cx(classes.footerCell, { stickyCell: sticky })} />
        )}
        {cells.map((item: IFooterCell) => {
          const { id, label, align, cellComponent, variant } = item;
          return (
            <TableCell
              key={id}
              align={align || 'left'}
              className={cx(classes.footerCell, { stickyCell: sticky })}
            >
              {cellComponent || <Typography variant={variant || 'body2'}>{label}</Typography>}
            </TableCell>
          );
        })}
        {!hidePadding && (
          <EmptyTableCell className={cx(classes.footerCell, { stickyCell: sticky })} />
        )}
      </TableRow>
    </MuiTableFooter>
  );
};

export default TableFooter;
