import { actionTypes } from './constants';
import { fromJS } from 'immutable';

const paymentsReducer = (state = fromJS({}), action) => {
  switch (action.type) {
    case actionTypes.FETCH_PERSON_PAYMENTS:
      return state
        .set('paymentList', fromJS(action.payload.data))
        .set('paymentsMeta', fromJS(action.payload.meta));
    case actionTypes.VOID_PERSON_INVOICE_ACTION_RESULT:
      return state.set(['void', 'invoiceResult'], action.payload);
    case actionTypes.VOID_PERSON_INVOICE_LOADING:
      return state.set(['void', 'invoicisLoadingeResult'], action.payload);
    case actionTypes.FETCH_PERSON_PAYMENTS_LOADING:
      return state.set('paymentsLoading', action.payload);
    case actionTypes.FETCH_RECENT_PERSON_PAYMENTS:
      return state.set('recentPayments', fromJS(action.payload));
    case actionTypes.FETCH_RECENT_PERSON_PAYMENTS_LOADING:
      return state.set('recentPaymentsLoading', action.payload);
    case actionTypes.FETCH_PAYMENT_DETAILS:
      return state.set('paymentDetails', fromJS(action.payload));
    case actionTypes.FETCH_PAYMENT_DETAILS_LOADING:
      return state.set('paymentDetailsLoading', action.payload);

    default:
      return state;
  }
};

export default paymentsReducer;
