import React from 'react';

import { ConstOptions } from 'common/constants/classes';
import { Weekday, Weekdays } from 'common/interfaces/common';

export const getWeekdaysLabel = (
  weekdays: Weekday[],
  constOptions: ConstOptions = Weekdays,
): JSX.Element => {
  return (
    <>
      {weekdays.map((weekday, index) => (
        <React.Fragment key={weekday}>
          {constOptions.translate(weekday)}
          {index === weekdays.length - 1 ? '' : ', '}
        </React.Fragment>
      ))}
    </>
  );
};
