export const actionTypes = {
  // Errors
  PUSH_REQUEST_ERROR: 'PUSH_REQUEST_ERROR',
  RESET_REQUEST_ERRORS: 'RESET_REQUEST_ERRORS',
  // Campaigns report
  FETCH_USED_SESSIONS_REPORT_LOADING: 'FETCH_USED_SESSIONS_REPORT_LOADING',
  FETCH_USED_SESSIONS_REPORT: 'FETCH_USED_SESSIONS_REPORT',
  //
  SESSION_UNREDEEM_LOADING: 'SESSION_UNREDEEM_LOADING',
  SESSION_UNREDEEM: 'SESSION_UNREDEEM',
  RESET_UNREDEEM_SUCCESS: 'RESET_UNREDEEM_SUCCESS',

  FETCH_USED_SESSIONS_TOTALS: 'FETCH_USED_SESSIONS_TOTALS',
  FETCH_USED_SESSIONS_TOTALS_LOADING: 'FETCH_USED_SESSIONS_TOTALS_LOADING',
};
