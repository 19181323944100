import React from 'react';
import { FormattedMessage } from 'react-intl';

import { CancelReasons } from '../../constants';

import messages from 'common/components/PersonProfile/messages';
import commonMessages from 'common/messages/messages';

export enum CancelMembershipSteps {
  Reason = 'REASON',
  PackagesSelection = 'PACKAGES_SELECTION',
  Billing = 'BILLING',
  PastDues = 'PAST_DUES',
  Payment = 'PAYMENT',
}

export const CancellationReasonLabels = {
  [CancelReasons.Relocation]: (
    <FormattedMessage {...messages.cancelMembershipReasonRelocationLabel} />
  ),
  [CancelReasons.Disability]: (
    <FormattedMessage {...messages.cancelMembershipReasonDisabilityLabel} />
  ),
  [CancelReasons.Other]: <FormattedMessage {...messages.cancelMembershipReasonOtherLabel} />,
};

export const CancelMembershipStepsLabels = {
  [CancelMembershipSteps.Reason]: (
    <FormattedMessage {...messages.cancelMembershipReasonStepTitle} />
  ),
  [CancelMembershipSteps.PackagesSelection]: <FormattedMessage {...messages.servicesBlockTitle} />,
  [CancelMembershipSteps.Billing]: <FormattedMessage {...commonMessages.billingLabel} />,
  [CancelMembershipSteps.PastDues]: <FormattedMessage {...messages.pastDuesModalTitle} />,
  [CancelMembershipSteps.Payment]: (
    <FormattedMessage {...messages.cancelMembershipPaymentStepTitle} />
  ),
};
