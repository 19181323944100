export const GET_COMMISSION_RULES = 'GET_COMMISSION_RULES';
export const GET_COMMISSION_RULES_LOADING = 'GET_COMMISSION_RULES_LOADING';
export const CLEAR_COMMISSION_RULES = 'CLEAR_COMMISSION_RULES';

export const GET_COMMISSION_RULE = 'GET_COMMISSION_RULE';
export const GET_COMMISSION_RULE_LOADING = 'GET_COMMISSION_RULE_LOADING';
export const CLEAR_COMMISSION_RULE = 'CLEAR_COMMISSION_RULE';

export const GET_COMMISSION_RULES_HISTORY = 'GET_COMMISSION_RULES_HISTORY';
export const GET_COMMISSION_RULES_HISTORY_LOADING = 'GET_COMMISSION_RULES_HISTORY_LOADING';
export const CLEAR_COMMISSION_RULES_HISTORY = 'CLEAR_COMMISSION_RULES_HISTORY';

export const POST_COMMISSION_RULE_LOADING = 'POST_COMMISSION_RULE_LOADING';
export const PUT_COMMISSION_RULE_LOADING = 'PUT_COMMISSION_RULE_LOADING';
export const DELETE_COMMISSION_RULE_LOADING = 'DELETE_COMMISSION_RULE_LOADING';
export const REMOVE_COMMISSION_RULE = 'REMOVE_COMMISSION_RULE';
