import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { actionTypes } from '../../constants/appointmentsReport';

const initStateAppointments = fromJS({
  list: [],
  meta: {
    total: 0,
  },
  isLoading: false,
  totals: {},
  exportAppointmentsReportLoading: false,
  exportAppointmentsReportResult: {},
});

function appointmentsReducer(state = initStateAppointments, action) {
  switch (action.type) {
    case actionTypes.FETCH_APPOINTMENTS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_APPOINTMENTS:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta))
        .set('totals', fromJS(action.payload.totals));
    case actionTypes.EXPORT_APPOINTMENTS_REPORT_LOADING:
      return state.set('exportAppointmentsReportLoading', fromJS(action.payload));
    case actionTypes.EXPORT_APPOINTMENTS_REPORT:
      return state.set('exportAppointmentsReportResult', fromJS(action.payload));
    case actionTypes.RESET_APPOINTMENTS_EXPORT_SUCCESS:
      return state.set('exportAppointmentsReportResult', fromJS({}));
    default:
      return state;
  }
}

const initialStateAppointment = fromJS({
  appointment: {},
  appointmentLoading: false,
});

function appointmentReducer(state = initialStateAppointment, action) {
  switch (action.type) {
    case actionTypes.FETCH_APPOINTMENT_LOADING:
      return state.set('appointmentLoading', action.payload);

    case actionTypes.FETCH_APPOINTMENT:
      return state.set('appointment', fromJS(action.payload));

    case actionTypes.RESET_APPOINTMENT:
      return initialStateAppointment;

    default:
      return state;
  }
}
export default combineReducers({
  appointmentsList: appointmentsReducer,
  appointmentItem: appointmentReducer,
});
