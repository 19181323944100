import { createAction } from '@reduxjs/toolkit';
import { batch } from 'react-redux';

import { ITableParams } from 'common/interfaces/table';
import Services from 'services/network';
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from 'common/state/notifications/actions';
import { actionTypes } from 'modules/crm/constants/crmTemplates';
import { IPaginatedCRMTemplate } from 'modules/crm/interfaces/crmTemplates';
import commonMessages from 'common/messages/messages';
import { TemplatePurpose, TemplateTypes } from 'common/constants/documentTemplate';
import {
  ICreateDocumentTemplateDto,
  IDocumentTemplateDto,
  IUpdateDocumentTemplateStatusRequest,
} from 'common/interfaces/documentTemplate';
import { GeneralThunkAction } from 'common/state/interfaces';
import { ActionResult } from 'common/constants';
import { PeakModules } from 'common/constants/peakModules';

const fetchCRMTemplatesAction = createAction<IPaginatedCRMTemplate>(
  actionTypes.FETCH_CRM_TEMPLATES_LIST,
);
const fetchCRMTemplatesLoadingAction = createAction<boolean>(
  actionTypes.FETCH_CRM_TEMPLATES_LIST_LOADING,
);
export const resetCRMTemplates = createAction(actionTypes.RESET_CRM_TEMPLATES_LIST);
const saveDocumentLoadingAction = createAction<boolean>(actionTypes.SAVE_CRM_TEMPLATE_LOADING);

export const fetchCRMTemplates = (
  type: TemplateTypes,
  purpose: TemplatePurpose,
  requestOptions?: ITableParams,
  module?: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchCRMTemplatesLoadingAction(true));

    try {
      let paginatedData: IPaginatedCRMTemplate;
      switch (module) {
        case PeakModules.Crm:
          paginatedData = await Services.CRMTemplates.getTemplates(type, purpose, requestOptions);
          break;
        case PeakModules.PersonalTrainingCrm:
          paginatedData = await Services.PtCRMTemplates.getTemplates(type, purpose, requestOptions);
          break;
        default:
          paginatedData = null;
      }

      dispatch(fetchCRMTemplatesAction(paginatedData));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchCRMTemplatesLoadingAction(false));
    }
  };
};

export const saveCrmTemplateActionResult = createAction<ActionResult>(
  actionTypes.SAVE_CRM_TEMPLATE_ACTION_RESULT,
);

export const saveDocumentCreator = (
  params: ICreateDocumentTemplateDto,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(saveDocumentLoadingAction(true));
    try {
      switch (module) {
        case PeakModules.Crm:
          await Services.CRMTemplates.saveDocumentTemplate(params);
          break;
        case PeakModules.PersonalTrainingCrm:
          await Services.PtCRMTemplates.saveDocumentTemplate(params);
          break;
        default:
      }

      batch(() => {
        dispatch(saveCrmTemplateActionResult(ActionResult.SUCCESS_ACTION));
        dispatch(enqueueSuccessNotification(commonMessages.templateCreatedMessage));
      });
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(saveDocumentLoadingAction(false));
    }
  };
};

export const updateDocumentTemplate = (
  params: IDocumentTemplateDto,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(saveDocumentLoadingAction(true));
    try {
      switch (module) {
        case PeakModules.Crm:
          await Services.CRMTemplates.updateDocumentTemplate(params);
          break;
        case PeakModules.PersonalTrainingCrm:
          await Services.PtCRMTemplates.updateDocumentTemplate(params);
          break;
        default:
      }

      batch(() => {
        dispatch(saveCrmTemplateActionResult(ActionResult.SUCCESS_ACTION));
        dispatch(enqueueSuccessNotification(commonMessages.templateUpdatedMessage));
      });
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(saveDocumentLoadingAction(false));
    }
  };
};

const getCRMTemplateLoading = createAction<boolean>(actionTypes.GET_CRM_TEMPLATE_LOADING);
const getCRMTemplateAction = createAction<IDocumentTemplateDto>(actionTypes.GET_CRM_TEMPLATE);
export const resetCRMTemplate = createAction(actionTypes.RESET_CRM_TEMPLATE);

export const getCRMTemplate = (id: string, module: PeakModules): GeneralThunkAction => {
  return async dispatch => {
    dispatch(getCRMTemplateLoading(true));
    try {
      let document: IDocumentTemplateDto;
      switch (module) {
        case PeakModules.Crm:
          document = await Services.CRMTemplates.getDocumentTemplate(id);
          break;
        case PeakModules.PersonalTrainingCrm:
          document = await Services.PtCRMTemplates.getDocumentTemplate(id);
          break;
        default:
          document = null;
      }

      dispatch(getCRMTemplateAction(document));
    } catch (error) {
      enqueueErrorNotification(error);
    } finally {
      dispatch(getCRMTemplateLoading(false));
    }
  };
};

const setUpdateCrmTemplateStatusIsLoading = createAction<boolean>(
  actionTypes.SET_UPDATE_CRM_TEMPLATE_STATUS_IS_LOADING,
);
const updateCrmTemplateStatus = createAction<IUpdateDocumentTemplateStatusRequest>(
  actionTypes.UPDATE_CRM_TEMPLATE_STATUS,
);
export const updateCrmTemplateStatusActionResult = createAction<ActionResult>(
  actionTypes.UPDATE_CRM_TEMPLATE_STATUS_ACTION_RESULT,
);

export const updateDocumentTemplateStatus = (
  params: IUpdateDocumentTemplateStatusRequest,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(setUpdateCrmTemplateStatusIsLoading(true));
    try {
      switch (module) {
        case PeakModules.Crm:
          await Services.CRMTemplates.updateDocumentStatus(params);
          break;
        case PeakModules.PersonalTrainingCrm:
          await Services.PtCRMTemplates.updateDocumentStatus(params);
          break;
        default:
      }

      batch(() => {
        dispatch(updateCrmTemplateStatus(params));
        dispatch(updateCrmTemplateStatusActionResult(ActionResult.SUCCESS_ACTION));
      });
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(setUpdateCrmTemplateStatusIsLoading(false));
    }
  };
};
